import { PerfectScrollbarConfigInterface, } from 'ngx-perfect-scrollbar';
import { DashboardFoldersComponent } from './components/dashboard-folders/dashboard-folders.component';
import { FolderInfoComponent } from './components/folder-info/folder-info.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true,
};
export var COMPONENTS = [
    DashboardComponent,
    FolderInfoComponent,
    DashboardFoldersComponent,
];
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var DashboardModule = /** @class */ (function () {
    function DashboardModule() {
    }
    return DashboardModule;
}());
export { DashboardModule };
export { ɵ0 };
