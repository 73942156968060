import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  DashboardActionTypes,
  LoadClinicMedication,
  LoadClinicMedicationError,
  LoadClinicMedicationSuccess,
  LoadClinicNonTimedTriggerMedication,
  LoadClinicNonTimedTriggerMedicationError,
  LoadClinicNonTimedTriggerMedicationSuccess,
  LoadClinicStatus,
  LoadClinicStatusError,
  LoadClinicStatusSuccess,
  LoadClinicSummary,
  LoadClinicSummaryError,
  LoadClinicSummarySuccess,
  RefreshClinicDashboard,
  RefreshClinicStatus
} from '../actions/dashboard.actions';
import { ClinicStatusService } from '../services/clinic-status.service';
import { DashboardService } from '../services/dashboard.service';

@Injectable()
export class DashboardEffects {
  @Effect()
  refreshClinicStatus$ = this.actions$.pipe(
    ofType<RefreshClinicStatus>(DashboardActionTypes.RefreshClinicStatus),
    mergeMap(() => [new LoadClinicStatus()])
  );

  @Effect()
  loadClinicStatus$ = this.actions$.pipe(
    ofType<LoadClinicStatus>(DashboardActionTypes.LoadClinicStatus),
    switchMap(() => {
      return this.clinicStatusService.getClinicStatus().pipe(
        map((summary) => {
          return new LoadClinicStatusSuccess(summary);
        }),
        catchError((error) => {
          return of(new LoadClinicStatusError(error));
        })
      );
    })
  );

  @Effect()
  refreshDashboard$ = this.actions$.pipe(
    ofType<RefreshClinicDashboard>(DashboardActionTypes.RefreshClinicDashboard),
    mergeMap(() => [
      // new LoadClinicSummary(),
      new LoadClinicMedication(),
      new LoadClinicNonTimedTriggerMedication()
    ])
  );

  @Effect()
  loadClinicSummary$ = this.actions$.pipe(
    ofType<LoadClinicSummary>(DashboardActionTypes.LoadClinicSummary),
    switchMap(() => {
      return this.dashboardService.getClinicSummary().pipe(
        map((summary) => {
          return new LoadClinicSummarySuccess(summary);
        }),
        catchError((error) => {
          return of(new LoadClinicSummaryError(error));
        })
      );
    })
  );

  // @Effect()
  // loadClinicMedication$ = this.actions$.pipe(
  //   ofType<LoadClinicMedication>(DashboardActionTypes.LoadClinicMedication),
  //   switchMap((action) => {
  //     return this.dashboardService.getClinicMedications().pipe(
  //       map((meds) => {
  //         return new LoadClinicMedicationSuccess(meds);
  //       }),
  //       catchError((error) => {
  //         return of(new LoadClinicMedicationError(error));
  //       }),
  //     );
  //   }),
  // );

  @Effect()
  loadClinicNonTimedTriggerMedication$ = this.actions$.pipe(
    ofType<LoadClinicNonTimedTriggerMedication>(
      DashboardActionTypes.LoadClinicNonTimedTriggerMedication
    ),
    switchMap((action) => {
      return this.dashboardService.getClinicNonTimedTriggerMedications().pipe(
        map((meds) => {
          return new LoadClinicNonTimedTriggerMedicationSuccess(meds);
        }),
        catchError((error) => {
          return of(new LoadClinicNonTimedTriggerMedicationError(error));
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private clinicStatusService: ClinicStatusService,
    private _error: ErrorHandler
  ) {}
}
