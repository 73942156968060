import { ControlValueAccessor } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material';
import clamp from 'lodash/clamp';
import { removeNonNumbers } from '../../utils/generic';
import { detectPreferredHoursType, formatHour, to12Hour, to24Hour, } from '../../utils/time';
var MIN_MINUTES = 0;
var MAX_MINUTES = 59;
/**
 * Allows the user to set a time that is returned in the form "hh:mm".
 */
var TimePickerComponent = /** @class */ (function () {
    function TimePickerComponent() {
        this.disabled = false;
        /**
         * Whether or not the control is using 12 or 24 hours clock
         */
        this.hoursType = detectPreferredHoursType();
        this.period = 'AM';
        this.touched = false;
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(TimePickerComponent.prototype, "minHours", {
        /**
         * The lowest acceptable hour for the current clock (mainly for template)
         */
        get: function () {
            return this.hoursType === '12H' ? 1 : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimePickerComponent.prototype, "maxHours", {
        /**
         * The highest acceptable hour for the current clock (mainly for template)
         */
        get: function () {
            return this.hoursType === '12H' ? 12 : 23;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * When the user leaves the hours field we can replace the value
     * they've entered to fit with the 12/24 hours clock better.
     * For example when using the 24 hour clock and they leave a "9"
     * we can format this to be "09".
     */
    TimePickerComponent.prototype.handleHoursBlur = function () {
        var hoursAsNumber = parseInt(this.hours, 10);
        // If they removed all input we can just put whatever
        // makes sense for current clock
        if (isNaN(hoursAsNumber)) {
            hoursAsNumber = this.hoursType === '12H' ? 12 : 0;
        }
        if (this.hoursType === '12H' && hoursAsNumber === 0) {
            hoursAsNumber = 12;
        }
        this.hours = formatHour(hoursAsNumber, this.hoursType);
        this.updateValue();
        this.markAsTouched();
        this.onChange(this.value);
    };
    /**
     * Responsible for parsing the hours inputted by the user.
     * It attempts to try and preserve what the user has entered
     * unless it is drastically wrong.
     */
    TimePickerComponent.prototype.handleHoursInput = function (e) {
        var input = e.target;
        // Strip out anything that isn't a number
        input.value = removeNonNumbers(input.value);
        // If the user has removed everything just wait for now
        if (input.value.length === 0) {
            this.hours = input.value;
            return;
        }
        var valueAsNumber = parseInt(input.value, 10);
        // If somehow we have non-numbers reset to the minimum
        if (isNaN(valueAsNumber)) {
            // Using "12" here for 12 hours clock as that's actually the
            // first usable hour.
            valueAsNumber = this.hoursType === '12H' ? 12 : 0;
        }
        // When the user enters the second digit we can check if it's
        // over the maximum amount of hours and cap it.
        if (valueAsNumber >= 10) {
            valueAsNumber = Math.min(valueAsNumber, this.maxHours);
            this.hours = valueAsNumber.toString();
        }
        else {
            // A single digit is OK for now
            this.hours = input.value;
        }
        this.updateValue();
        this.onChange(this.value);
    };
    TimePickerComponent.prototype.handleHoursTypeChange = function (e) {
        this.hoursType = e.value;
        if (this.hours == null || this.hours === '') {
            return;
        }
        var hoursAsNumber = parseInt(this.hours, 10);
        if (this.hoursType === '12H') {
            var _a = to12Hour(hoursAsNumber), newHour = _a[0], newPeriod = _a[1];
            this.hours = newHour.toString();
            this.period = newPeriod;
        }
        else {
            var newHour = to24Hour(hoursAsNumber, this.period);
            this.hours = newHour.toString().padStart(2, '0');
        }
        this.updateValue();
        this.markAsTouched();
        this.onChange(this.value);
    };
    /**
     * Responsible for formatting what the user entered when they leave
     * the field to make it look "nicer" and fit the currently used
     * clock better. For example "07" would be changed to "7".
     */
    TimePickerComponent.prototype.handleMinutesBlur = function () {
        var minutesAsNumber = parseInt(this.minutes, 10);
        // If they removed all input we can just put "0" minutes
        if (isNaN(minutesAsNumber)) {
            minutesAsNumber = 0;
        }
        this.minutes = this.formatMinutes(minutesAsNumber);
        this.updateValue();
        this.markAsTouched();
        this.onChange(this.value);
    };
    /**
     * Responsible for parsing the minutes inputted by the user.
     * It attempts to try and preserve what the user has entered
     * unless it is drastically wrong.
     */
    TimePickerComponent.prototype.handleMinutesInput = function (e) {
        var input = e.target;
        // Strip out anything that isn't a number
        input.value = removeNonNumbers(input.value);
        if (input.value.length === 0) {
            this.minutes = input.value;
            return;
        }
        var valueAsNumber = parseInt(input.value, 10);
        if (isNaN(valueAsNumber)) {
            valueAsNumber = 0;
        }
        // As they type the second digit clamp to maximum possible hours
        // for better UX
        if (valueAsNumber >= 10) {
            valueAsNumber = clamp(valueAsNumber, MIN_MINUTES, MAX_MINUTES);
            this.minutes = valueAsNumber.toString();
        }
        else {
            this.minutes = input.value;
        }
        this.updateValue();
        this.onChange(this.value);
    };
    TimePickerComponent.prototype.handlePeriodChange = function (e) {
        this.period = e.target.value;
        this.updateValue();
        this.markAsTouched();
        this.onChange(this.value);
    };
    /**
     * Parses and corrects the value passed by any form the control is in.
     */
    TimePickerComponent.prototype.writeValue = function (value) {
        // Ignore any strange input. The form will still see the initial
        // value returned in validation but as soon as the user touches
        // our control we can correct it
        if (value == null || value === '' || !value.match(/^\d\d:\d\d$/)) {
            this.hours = '';
            this.minutes = '';
            this.value = null;
            return;
        }
        var newValue = value.trim();
        var _a = newValue.split(':'), hours = _a[0], minutes = _a[1];
        var hoursAsNumber = parseInt(hours, 10);
        var minsAsNumber = parseInt(minutes, 10);
        if (this.hoursType === '12H') {
            var _b = to12Hour(hoursAsNumber), hour = _b[0], period = _b[1];
            this.hours = formatHour(hour, this.hoursType);
            this.period = period;
        }
        else {
            this.hours = formatHour(hoursAsNumber, this.hoursType);
        }
        this.minutes = this.formatMinutes(minsAsNumber);
        this.updateValue();
    };
    TimePickerComponent.prototype.registerOnChange = function (onChange) {
        this.onChange = onChange;
    };
    TimePickerComponent.prototype.registerOnTouched = function (onTouched) {
        this.onTouched = onTouched;
    };
    TimePickerComponent.prototype.setDisabledState = function (disabled) {
        this.disabled = disabled;
    };
    TimePickerComponent.prototype.selectAll = function (e) {
        e.target.select();
    };
    TimePickerComponent.prototype.markAsTouched = function () {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    };
    TimePickerComponent.prototype.formatMinutes = function (minutes) {
        return clamp(minutes, MIN_MINUTES, MAX_MINUTES).toString().padStart(2, '0');
    };
    TimePickerComponent.prototype.updateValue = function () {
        // If we have something we can't put a value together for
        // just hold on until we do.
        if (this.hours == null ||
            this.hours === '' ||
            this.minutes == null ||
            this.minutes === '') {
            this.value = null;
            return;
        }
        var hoursAsNumber = parseInt(this.hours, 10);
        var minutesAsNumber = parseInt(this.minutes, 10);
        // Shouldn't happen but we may have junk for both of these.
        if (isNaN(hoursAsNumber)) {
            hoursAsNumber = this.hoursType === '12H' ? 12 : 0;
        }
        if (isNaN(minutesAsNumber)) {
            minutesAsNumber = 0;
        }
        var hoursStr = (this.hoursType === '12H'
            ? to24Hour(hoursAsNumber, this.period)
            : hoursAsNumber)
            .toString()
            .padStart(2, '0');
        var minsStr = this.formatMinutes(minutesAsNumber);
        this.value = hoursStr + ":" + minsStr;
    };
    return TimePickerComponent;
}());
export { TimePickerComponent };
