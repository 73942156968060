import { Component } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';

@Component({
  selector: 'portal-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
})
export class NoContentComponent {
  public sectionText$: Observable<any>;

  public clinicId$: Observable<string>;

  constructor(
    private _navigationService: NavigationService,
    private _store: Store<fromRoot.State>,
  ) {
    this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NoContent')),
    );
  }

  goToAddContent(): void {
    this._navigationService.navigate(['content', 'list', 0]);
  }
}
