import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import React from 'react';
import { CharacterCount } from './CharacterCount';
import { InputLabel } from './InputLabel';
export var TextInput = React.forwardRef(function (_a, ref) {
    var id = _a.id, label = _a.label, placeholder = _a.placeholder, value = _a.value, maxLength = _a.maxLength, showCharCount = _a.showCharCount, rest = tslib_1.__rest(_a, ["id", "label", "placeholder", "value", "maxLength", "showCharCount"]);
    return (React.createElement(React.Fragment, null,
        label && React.createElement(InputLabel, { htmlFor: id }, label),
        React.createElement(TextField, tslib_1.__assign({ inputRef: ref, id: id, placeholder: placeholder, value: value, variant: "outlined", inputProps: { maxLength: maxLength } }, rest)),
        showCharCount && value != null && (React.createElement(StyledCharCount, null,
            React.createElement(CharacterCount, { length: value.length, maxLength: maxLength })))));
});
var StyledCharCount = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 2px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 2px;\n"])));
var templateObject_1;
