import { MessagesState } from '.';
import { AlterMessageActionTypes } from '../actions/alter-message.actions';
import {
  getMessageFromState,
  removeMessageFromState,
  replaceMessageInState,
} from './utils';

export function reducer(state: MessagesState, action): MessagesState {
  switch (action.type) {
    case AlterMessageActionTypes.MarkUnreadSuccess: {
      const { id } = action.payload;
      const message = getMessageFromState(state, id);
      return {
        ...state,
        messageList: replaceMessageInState(state, message, {
          ...message,
          Read: false,
        }),
      };
    }

    case AlterMessageActionTypes.MarkResolvedSuccess:
    case AlterMessageActionTypes.MarkUnresolvedSuccess: {
      return {
        ...state,
        messageList: removeMessageFromState(state, action.payload.id),
      };
    }

    case AlterMessageActionTypes.ChangeLabelSuccess: {
      const { messageId, messageTypeId, messageType } = action.payload.data;
      const message = getMessageFromState(state, messageId);
      return {
        ...state,
        messageList:
          state.messageTypeFilter.messageTypeId < 0
            ? replaceMessageInState(state, message, {
                ...message,
                MessageType: messageType,
                MessageTypeId: messageTypeId,
              })
            : removeMessageFromState(state, messageId),
      };
    }
    case AlterMessageActionTypes.MarkStarred:
      const um = getMessageFromState(state, action.payload.message.MessageId);

      return {
        ...state,
        messageList: replaceMessageInState(state, um, {
          ...um,
          MessageAction: 'STARRING',
        }),
      };

    case AlterMessageActionTypes.MarkStarredError:
      const markStarredErrorMessage = getMessageFromState(
        state,
        action.payload.messageId,
      );

      return {
        ...state,
        messageList: replaceMessageInState(state, markStarredErrorMessage, {
          ...markStarredErrorMessage,
          IsStarred: false,
        }),
      };

    case AlterMessageActionTypes.MarkUnstarred:
      const markUnstarredMessage = getMessageFromState(
        state,
        action.payload.message.MessageId,
      );

      return {
        ...state,
        messageList: replaceMessageInState(state, markUnstarredMessage, {
          ...markUnstarredMessage,
          MessageAction: 'UNSTARRING',
        }),
      };

    case AlterMessageActionTypes.MarkUnstarredError:
      const markUnstarredErrorMessage = getMessageFromState(
        state,
        action.payload.messageId,
      );

      return {
        ...state,
        messageList: replaceMessageInState(state, markUnstarredErrorMessage, {
          ...markUnstarredErrorMessage,
          IsStarred: true,
        }),
      };

    case AlterMessageActionTypes.MarkUnstarredSuccess:
      const muNewStarredCount = state.messageCountSummary.starred - 1;
      const muNewUnstarredCount = state.messageCountSummary.unstarred + 1;

      return {
        ...state,
        messageCountSummary: {
          ...state.messageCountSummary,
          starred: muNewStarredCount <= 0 ? 0 : muNewStarredCount,
          unstarred: muNewUnstarredCount <= 0 ? 0 : muNewUnstarredCount,
        },
        messageList: removeMessageFromState(
          state,
          action.payload.message.MessageId,
        ),
      };

    case AlterMessageActionTypes.MarkStarredSuccess:
      const msNewStarredCount = state.messageCountSummary.starred + 1;
      const msNewUnstarredCount = state.messageCountSummary.unstarred - 1;
      return {
        ...state,
        messageCountSummary: {
          ...state.messageCountSummary,
          starred: msNewStarredCount <= 0 ? 0 : msNewStarredCount,
          unstarred: msNewUnstarredCount <= 0 ? 0 : msNewUnstarredCount,
        },
        messageList: removeMessageFromState(
          state,
          action.payload.message.MessageId,
        ),
      };

    default:
      return state;
  }
}
