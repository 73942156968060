import { useApiClient } from '@react/lib/api';
import { ConversationFilters } from '@react/lib/api/types';
import { useInfiniteQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { filtersToApi, formatConversation } from '../lib';
import { FilterState } from '../types';

export function useConversationListQuery(filters: FilterState, q?: string) {
  const apiClient = useApiClient().portal;

  const apiFilters: ConversationFilters = filtersToApi({
    ...filters,
    q,
  });

  return useInfiniteQuery(
    [queryCacheKey.CONVERSATIONS, apiFilters],
    async ({ pageParam: pageNumber }: { pageParam?: number }) => {
      const response = await apiClient.fetchConversations({
        pageNumber,
        ...apiFilters,
      });
      return { ...response, data: response.data.map(formatConversation) };
    },
    {
      getNextPageParam(lastPage, pages) {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
      refetchInterval: POLLING_INTERVAL_MS,
    },
  );
}
