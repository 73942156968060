<div>
  <div *ngIf="viewAsPatient" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-toolbar
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="search-container"
    >
      <div fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxFlex="grow"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <mat-icon>search</mat-icon>
          <div class="input-container" fxFlex="grow">
            <input
              class="text-input"
              flex="grow"
              type="text"
              placeholder="{{ text.SearchAssigned }}"
              [formControl]="filterValue"
            />
          </div>
        </div>
        <div *ngIf="filterActive" fxLayout="row" fxLayoutAlign="end center">
          <button
            mat-button
            mat-icon-button
            aria-label="Clear"
            (click)="clearFilter()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>
    <perfect-scrollbar
      class="scroll-container"
      fxFlex="auto"
      [scrollIndicators]="true"
    >
      <div
        class="patient-view-container"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <div
          *ngFor="let folder of patientViewContent"
          class="item-row"
          fxLayout="column"
          fxLayoutAlign="start stretch"
        >
          <div
            (click)="openPatientFolder.emit(folder.categoryId)"
            class="patient-view-folder-name"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>
              {{ folder.categoryName }}
            </div>
            <mat-icon
              *ngIf="openFolderId !== folder.categoryId && !filterActive"
            >
              expand_more
            </mat-icon>
            <mat-icon
              *ngIf="openFolderId === folder.categoryId && !filterActive"
            >
              expand_less
            </mat-icon>
          </div>
          <div
            *ngIf="openFolderId === folder.categoryId || filterActive"
            fxLayout="column"
            fxLayoutAlign="start stretch"
          >
            <div
              *ngFor="let folderItem of folder.contents"
              class="folder-item"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              {{ folderItem.contentName }}
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div
    *ngIf="!viewAsPatient && activeTab === contentTabOptions.PACKS"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <mat-toolbar
      class="toolbar"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div>
        {{ text.Packs }}
      </div>
    </mat-toolbar>
    <div
      *ngIf="
        openPacks &&
        (!assignedPacks || (assignedPacks && assignedPacks.length === 0))
      "
      class="sub-list empty"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      {{ text.NoAssignedPacks }}
    </div>
    <perfect-scrollbar
      *ngIf="assignedPacks && assignedPacks.length > 0"
      class="sub-list"
      [class.open]="openPackId"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      [scrollIndicators]="true"
    >
      <div
        *ngFor="let pack of assignedPacks"
        [class.hidden]="openPackId && pack.packId !== openPackId"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <div
          *ngIf="!openPackId || pack.packId === openPackId"
          class="list-header no-padding"
          [class.open]="pack.packId === openPackId"
          [attr.disabled]="pack.isTransferring ? '' : null"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          (click)="handleOpenPack(pack.packId)"
        >
          <div
            *ngIf="pack.isTransferring"
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="2px"
          >
            <mat-spinner diameter="20"></mat-spinner>
          </div>
          <div *ngIf="pack.isDefault" class="icon-placeholder"></div>
          <mat-icon
            *ngIf="!pack.isDefault && !pack.isTransferring"
            [matTooltip]="text.RemovePack"
            (click)="handleRemovePack(pack)"
            portalStopClickPropagation
          >
            remove
          </mat-icon>
          <div
            fxFlex="grow"
            [matTooltip]="!openPackId ? text.ViewPackContents : ''"
          >
            {{ pack.packName }}
          </div>
          <mat-icon *ngIf="!openPackId" [matTooltip]="text.ViewPackContents">
            expand_more
          </mat-icon>
          <mat-icon
            *ngIf="pack.packId === openPackId"
            [matTooltip]="text.ViewPackContents"
          >
            expand_less
          </mat-icon>
        </div>
        <perfect-scrollbar
          *ngIf="pack.packId === openPackId && pack.contents.length > 0"
          class="assigned-pack-list"
          fxLayout="column"
          fxLayoutAlign="column stretch"
          [scrollIndicators]="true"
        >
          <div *ngFor="let packItem of pack.contents">
            <div
              class="pack-item"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="15px"
              [attr.disabled]="
                packItem.isTransferring || pack.isTransferring ? '' : null
              "
            >
              <div
                *ngIf="packItem.isTransferring"
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="2px"
              >
                <mat-spinner diameter="20"></mat-spinner>
              </div>
              <mat-icon
                *ngIf="!packItem.isTransferring"
                [matTooltip]="text.RemovePackItem"
                (click)="addExclusion(pack.packId, packItem)"
                portalStopClickPropagation
              >
                remove
              </mat-icon>
              <div>
                {{ packItem.contentName }}
              </div>
            </div>
          </div>
        </perfect-scrollbar>
        <div
          *ngIf="pack.packId === openPackId && pack.contents.length === 0"
          class="assigned-pack-list empty"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          {{ text.AllItemsExcluded }}
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div
    *ngIf="!viewAsPatient && activeTab === contentTabOptions.FOLDERS"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <mat-toolbar
      class="toolbar"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div>
        {{ text.Items }}
      </div>
    </mat-toolbar>
    <perfect-scrollbar
      class="sub-list"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      [scrollIndicators]="true"
    >
      <div
        *ngFor="let content of assignedContent"
        class="list-item"
        [class.remove]="!content.fromMeditex"
        [attr.disabled]="content.isTransferring ? '' : null"
        [class.sync]="content.fromMeditex"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="removeIndividualItem(content)"
      >
        <div
          *ngIf="content.isTransferring"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="2px"
        >
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        {{ content.contentName }}
        <mat-icon *ngIf="!content.fromMeditex">remove</mat-icon>
        <mat-icon *ngIf="content.fromMeditex">lock</mat-icon>
      </div>
    </perfect-scrollbar>
  </div>
</div>
