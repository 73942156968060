import { useApiClient } from '@react/lib/api';
import { ConversationUpdate } from '@react/lib/api/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';

export function useConversationUpdateMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    (conversation: ConversationUpdate) =>
      apiClient.updateConversation(conversation),
    {
      onSuccess() {
        Object.keys(queryCacheKey).forEach((cacheKey) => {
          queryClient.invalidateQueries(cacheKey);
        });
      },
    },
  );
}

export function useConversationPartialUpdateMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    (conversation: ConversationUpdate) =>
      apiClient.updateConversation(conversation, true),
    {
      onSuccess() {
        Object.keys(queryCacheKey).forEach((cacheKey) => {
          queryClient.invalidateQueries(cacheKey);
        });
      },
    },
  );
}
