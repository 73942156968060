import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery } from 'react-query';
export function usePatientsInfiniteQuery(filters) {
    var apiClient = useApiClient().portal;
    return useInfiniteQuery(['PATIENTS_INFINITE', filters], function (_a) {
        var pageNumber = _a.pageParam;
        return apiClient.fetchPatients(tslib_1.__assign({ pageNumber: pageNumber }, filters));
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
        keepPreviousData: true,
    });
}
