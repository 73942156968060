import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Md5HashService } from 'src/app/core/services/md5-hash.service';
import { FileUpload } from 'src/app/core/services/s3.service';
import { AddVideoComponent } from '../add-video/add-video.component';

export interface Delta {
  ops: { [key: string]: any }[];
}

export interface OnContentChangedEvent {
  editor: any;
  html: string;
  text: string;
  content: Delta;
  delta: Delta;
  oldDelta: Delta;
  source: string;
}

@Component({
  selector: 'portal-content-edit-body',
  templateUrl: './content-edit-body.component.html',
  styleUrls: ['./content-edit-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentEditBodyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() text: any;
  @Input() introLength: number;
  @Input() videoText: any;
  @Input() introIsEmpty: boolean;
  @Input() contentEditorEnabled: boolean;
  @Output() introductionError = new EventEmitter<boolean>();

  // Introduction edior configuration
  public editorConfig = {
    toolbar: [
      ['bold'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  public introError$ = new BehaviorSubject(false);
  public introText = '';
  public videoPreview$ = new BehaviorSubject<SafeHtml>('');
  public showRemoveVideoButton$ = new BehaviorSubject<boolean>(false);

  public selectedFiles: FileList;
  public sectionsForm: FormGroup;

  public _subs = new Subscription();

  get requiredSections(): FormArray {
    return this.sectionsForm.get('requiredSections') as FormArray;
  }

  get introduction(): FormControl {
    return this.requiredSections.value.find((s) => s.title === 'Introduction');
  }

  constructor(
    private _controlContainer: ControlContainer,
    public dialog: MatDialog,
    public change: ChangeDetectorRef,
    private _s3: FileUpload,
    private _MD5: Md5HashService,
  ) {}

  ngOnInit() {
    this.sectionsForm = this._controlContainer.control as FormGroup;
    if (!this.contentEditorEnabled) {
      this.editorConfig = {
        toolbar: [],
      };
    }
    const video = this.requiredSections.value.find((f) => f.type === 'video');
    const html = video.content;
    if (html) {
      this.submitHTML(html);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.introIsEmpty) {
      this.introError$.next(changes.introIsEmpty.currentValue);
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public getIntroductionText(editorContent: OnContentChangedEvent) {
    this._validateIntroduction(editorContent.text);
  }

  public editorCreated(editorCreatedEvt: any) {
    const introText = editorCreatedEvt.container.innerText;
    this._validateIntroduction(introText);
  }

  private _validateIntroduction(introduction: string) {
    this.introText = introduction;
    if (
      introduction.length > 1000 ||
      introduction.length === 0 ||
      !introduction
    ) {
      this.introError$.next(true);
      this.introductionError.emit(true);
    } else {
      this.introError$.next(false);
      this.introductionError.emit(false);
    }
    this.change.markForCheck();
    this.change.detectChanges();
  }

  public openVideoDialog(type): Promise<string> {
    return new Promise((res, rej) => {
      const validAccepts = this._s3.fetchAccepts(type);
      const fileName = this._MD5.generateFileName();
      const videoDialog = this.dialog.open(AddVideoComponent, {
        data: {
          fileName,
          accept: validAccepts,
          text: this.videoText,
          public: true,
          patientOnly: false,
          patientId: null,
        },
      });
      videoDialog.afterClosed().subscribe((file: { html: string }) => {
        if (file && file.html) {
          res(file.html);
        } else {
          res(null);
        }
      });
    });
  }
  public async addVideo() {
    const html = await this.openVideoDialog('video');
    if (html) {
      this.requiredSections.at(2).patchValue({
        content: html,
      });
      this.submitHTML(html);

      this.change.markForCheck();
      this.change.detectChanges();
    }
  }

  public removeVideo() {
    this.requiredSections.at(2).patchValue({
      content: '',
    });
    this.submitHTML('');
    this.change.markForCheck();
    this.change.detectChanges();
  }

  public submitHTML(html) {
    this.showRemoveVideoButton$.next(html.length > 0);
    this.videoPreview$.next(html);
  }

  public setFocus(event) {
    event.target.focus();
  }
}
