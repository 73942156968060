export var NewMessageActionTypes;
(function (NewMessageActionTypes) {
    /**
     * these actions allow us to trigger the toast
     * before sending the message and reflecting
     * message set status in the displayed toast
     */
    NewMessageActionTypes["SendOutboundMessage"] = "[NewMessage] Send new outbound message";
    // Toast to allow rerouting
    NewMessageActionTypes["ShowRerouteToast"] = "[Toast] Showing Reroute Toast";
    NewMessageActionTypes["StoreDraft"] = "[NewMessage] Store Draft";
    /**
     * new message involves the sending a new message
     * to an existing conversation
     */
    NewMessageActionTypes["SendNewMessage"] = "[NewMessage] Send New Message";
    /**
     * A new thread involves sending a new message in
     * a brand new thread / conversation.
     */
    NewMessageActionTypes["SendNewThread"] = "[NewMessage] Send New Thread";
    NewMessageActionTypes["SendNewMessageSuccess"] = "[NewMessage] Send New Message Or Thread Success";
    NewMessageActionTypes["SendNewMessageError"] = "[NewMessage] Send New Message Or Thread Error";
    // React compatible toast
    NewMessageActionTypes["ShowNewConvoToastReact"] = "[Toast] Showing New Convo Toast React";
})(NewMessageActionTypes || (NewMessageActionTypes = {}));
// =============== Client Side Actions =============== //
var SendOutboundMessage = /** @class */ (function () {
    function SendOutboundMessage(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.SendOutboundMessage;
    }
    return SendOutboundMessage;
}());
export { SendOutboundMessage };
var ShowNewConvoToastReact = /** @class */ (function () {
    function ShowNewConvoToastReact(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.ShowNewConvoToastReact;
    }
    return ShowNewConvoToastReact;
}());
export { ShowNewConvoToastReact };
var ShowRerouteToast = /** @class */ (function () {
    function ShowRerouteToast(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.ShowRerouteToast;
    }
    return ShowRerouteToast;
}());
export { ShowRerouteToast };
// =============== Server Side Actions =============== //
var StoreDraft = /** @class */ (function () {
    function StoreDraft(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.StoreDraft;
    }
    return StoreDraft;
}());
export { StoreDraft };
var SendNewMessage = /** @class */ (function () {
    function SendNewMessage(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.SendNewMessage;
    }
    return SendNewMessage;
}());
export { SendNewMessage };
var SendNewThread = /** @class */ (function () {
    function SendNewThread(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.SendNewThread;
    }
    return SendNewThread;
}());
export { SendNewThread };
var SendNewMessageSuccess = /** @class */ (function () {
    function SendNewMessageSuccess(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.SendNewMessageSuccess;
    }
    return SendNewMessageSuccess;
}());
export { SendNewMessageSuccess };
var SendNewMessageError = /** @class */ (function () {
    function SendNewMessageError(payload) {
        this.payload = payload;
        this.type = NewMessageActionTypes.SendNewMessageError;
    }
    return SendNewMessageError;
}());
export { SendNewMessageError };
