import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery } from 'react-query';
export function useDrugTypesQuery(_a) {
    var searchTerm = _a.searchTerm;
    var apiClient = useApiClient().portal;
    return useInfiniteQuery(['drugTypes', { searchTerm: searchTerm }], function (_a) {
        var pageParam = _a.pageParam;
        return apiClient.fetchDrugTypes({ pageParam: pageParam, query: searchTerm });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
    });
}
