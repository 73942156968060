import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { ConfirmActionComponent } from './../../../core/components/confirm-action/confirm-action.component';
import { ClearFilter, ContentPackActionTypes, DeleteContentPack, FilterContent, GetContentPackDetail, RemoveFromContentPack, } from './../../actions/packs.actions';
import { EditFolderNameComponent } from './../../components/edit-folder-name/edit-folder-name.component';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromContent from '../../reducers';
import { Actions, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { OpenDirectorySection } from '../../actions/content.actions';
import { GetContentPacks } from '../../actions/packs.actions';
import { CreateCategoryComponent } from '../../components/create-category/create-category.component';
import { CreatePackComponent } from '../../components/create-pack/create-pack.component';
import { EditPackNameComponent } from '../../components/edit-pack-name/edit-pack-name.component';
var ContentPackDetailContainer = /** @class */ (function () {
    function ContentPackDetailContainer(_navigationService, _route, _store, dialog, updates$) {
        this._navigationService = _navigationService;
        this._route = _route;
        this._store = _store;
        this.dialog = dialog;
        this.updates$ = updates$;
        // Observables used in content-directory
        this.categories$ = of([]);
        this.templates$ = of([]);
        this.contentPacks$ = of([]);
        this.routeType$ = of('packs');
        this.otherPacks$ = of([]);
        // Observables for content list - i.e. the content present in pack
        this.contentList$ = of([]);
        this.contentToDisplay$ = of([]);
        // Is Loading content Pack Detail
        this.loadingPackDetail$ = of(false);
        // Subscription to allow easy clean up
        this._subs = new Subscription();
    }
    ContentPackDetailContainer.prototype.ngOnInit = function () {
        var _this = this;
        // Global Portal Observable
        this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        // Content editor group Observables
        this.contentEditorEnabled$ = this._store.pipe(select(fromContent.getContentEditorEnabled));
        this._store.dispatch(new GetContentPacks());
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('NewContentList')));
        this.directoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentDirectory')));
        this.categoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateCategory')));
        this._subs.add(this.isGlobalPortal$.subscribe(function (value) {
            _this.isGlobalPortal = value;
        }));
        // Content editor group, wrapped in a feature flag
        this._subs.add(this.contentEditorEnabled$.subscribe(function (t) { return (_this.contentEditorEnabled = t); }));
        // Capture text for use in Dialogs
        this._subs.add(this.sectionText$.subscribe(function (t) { return (_this.sectionText = t); }));
        this._subs.add(this.categoryText$.subscribe(function (t) { return (_this.categoryText = t); }));
        // is pack detail loading
        this.loadingPackDetail$ = this._store.pipe(select(fromContent.isLoadingPackDetail));
        // Observables for searchbar
        this.isFilterActive$ = this._store.pipe(select(fromContent.isPacksListFilterActive));
        this.currentFilter$ = this._store.pipe(select(fromContent.getPacksListFilter));
        // content-directoru observables
        this.categories$ = this._store.pipe(select(fromContent.getGeneralCategories));
        this.templates$ = this._store.pipe(select(fromContent.getTemplates));
        this.contentPacks$ = this._store.pipe(select(fromContent.getPacks));
        this.contentPackId$ = this._route.params.pipe(map(function (params) { return +params.id; }));
        this.currentPack$ = combineLatest(this.contentPacks$, this.contentPackId$).pipe(map(function (_a) {
            var packs = _a[0], packId = _a[1];
            return packs.find(function (p) { return p.id === packId; });
        }));
        this.activePack$ = this.currentPack$.pipe(map(function (pack) {
            return (pack && pack.packName) || '';
        }));
        this._subs.add(combineLatest(this.loadingPackDetail$, this.contentPackId$)
            .pipe(filter(function (_a) {
            var loading = _a[0];
            return !loading;
        }))
            .subscribe(function (_a) {
            var _ = _a[0], packId = _a[1];
            if (!_this.currentPackId || _this.currentPackId !== packId) {
                _this.currentPackId = packId;
                _this._store.dispatch(new GetContentPackDetail(packId));
            }
        }));
        this.otherPacks$ = combineLatest(this.contentPacks$, this.contentPackId$).pipe(map(function (_a) {
            var packs = _a[0], id = _a[1];
            return packs.filter(function (c) { return c.id !== id; });
        }));
        // pack list observables
        this.contentList$ = this.contentPackId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromContent.getActivePackDetails(id)));
        }));
        this.contentToDisplay$ = combineLatest(this.contentList$, this.isFilterActive$, this.currentFilter$).pipe(map(function (_a) {
            var content = _a[0], filterActive = _a[1], currentFilter = _a[2];
            return filterActive
                ? content.filter(function (c) {
                    return c.contentName.toLowerCase().includes(currentFilter);
                })
                : content || [];
        }));
        // Which section of directory is open?
        this.openDirectory$ = this._store.pipe(select(fromContent.activeDirectorySection));
        // watch for successful deletion of a folder
        // and navigate to first pack
        this._subs.add(this.updates$
            .pipe(ofType(ContentPackActionTypes.DeleteContentPackSuccess), switchMap(function (action) {
            return _this.contentPacks$.pipe(
            // TODO: Fix types
            // @ts-ignore
            filter(function (p) { return p.id !== action.payload.packId; }));
        }))
            .subscribe(function (packs) {
            var id = packs.length > 0 ? packs[0].id : 0;
            _this._navigationService.navigate(['content', 'pack', id]);
        }));
    };
    ContentPackDetailContainer.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    ContentPackDetailContainer.prototype.goToContent = function (packDetail) {
        this._navigationService.navigate([
            'content',
            'doc',
            'edit',
            packDetail.contentCategoryId,
            packDetail.contentEntryHeaderId,
        ]);
    };
    ContentPackDetailContainer.prototype.navigateTo = function (url) {
        this._navigationService.navigateByUrl(url);
    };
    ContentPackDetailContainer.prototype.updateFilter = function (filterString) {
        if (!filterString || filterString === '') {
            this._store.dispatch(new ClearFilter());
        }
        else {
            this._store.dispatch(new FilterContent(filterString));
        }
    };
    ContentPackDetailContainer.prototype.openDirectorySection = function (section) {
        this._store.dispatch(new OpenDirectorySection(section));
    };
    ContentPackDetailContainer.prototype.createNewFolder = function () {
        this.dialog.open(CreateCategoryComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    ContentPackDetailContainer.prototype.changeFolderName = function (category) {
        this.dialog.open(EditFolderNameComponent, {
            data: {
                category: category,
                text: this.sectionText,
            },
            width: '550px',
        });
    };
    ContentPackDetailContainer.prototype.createNewPack = function () {
        this.dialog.open(CreatePackComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    ContentPackDetailContainer.prototype.editPackName = function (pack) {
        this.dialog.open(EditPackNameComponent, {
            data: {
                pack: pack,
                text: this.sectionText,
            },
            width: '550px',
        });
    };
    ContentPackDetailContainer.prototype.deletePack = function (packId) {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.sectionText.DeletePackConfirmation,
                text: {
                    Cancel: this.sectionText.Cancel,
                    Confirm: this.sectionText.Delete,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this._store.dispatch(new DeleteContentPack({ packId: packId }));
            }
        });
    };
    ContentPackDetailContainer.prototype.removeFromPack = function (itemsToRemove) {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.sectionText.RemovePackItemsConfirmation,
                text: {
                    Cancel: this.sectionText.Cancel,
                    Confirm: this.sectionText.Remove,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this._store.dispatch(new RemoveFromContentPack(itemsToRemove));
            }
        });
    };
    return ContentPackDetailContainer;
}());
export { ContentPackDetailContainer };
