import * as Sentry from '@sentry/browser';
import { useRef, useState } from 'react';

export function createExportDownloadHandler(
  request: () => Promise<any>,
  onDownloadSuccess: () => void,
  onDownloadFailure: () => void,
) {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  async function download(fileNameSuffix: string) {
    try {
      const { data } = (await request()) as any;
      const blob = new Blob([data.csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      setFileUrl(url);
      setFileName(`${data.filename} ${fileNameSuffix}`);
      if (ref.current) {
        ref.current.click();
      } else {
        throw new Error('ref is required');
      }
      onDownloadSuccess();
      URL.revokeObjectURL(url);
    } catch (error) {
      Sentry.captureException(error);
      onDownloadFailure();
    }
  }

  return { download, fileUrl, ref, fileName };
}
