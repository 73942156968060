import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  ChangeLanguage = '[Settings] Change Language',
}

// Client Side Actions
// ===================================================
export class ChangeLanguage implements Action {
  readonly type = SettingsActionTypes.ChangeLanguage;

  constructor(public payload: string) {}
}

// Settings Action Types
// ===================================================
export type SettingsActionsUnion = ChangeLanguage;
