import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
var ContentAssignmentService = /** @class */ (function () {
    function ContentAssignmentService(_error, _httpService) {
        this._error = _error;
        this._httpService = _httpService;
    }
    // READ for Content Entries
    // ===================================================
    // Read
    ContentAssignmentService.prototype.getContentEntryHeaders = function () {
        return this._httpService
            .get(EndpointType.Content, "content/groupedentries/assign")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // GET all
    // READ for Categories
    // ===================================================
    // Read
    ContentAssignmentService.prototype.getContentCategories = function () {
        return this._httpService
            .get(EndpointType.Content, "content/categories")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // CRUD for ContentAssignmentEntries
    // ===================================================
    // Read
    ContentAssignmentService.prototype.getUserAssignedContentById = function (id) {
        return this._httpService
            .get(EndpointType.Content, "content/assign/" + id)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentAssignmentService.prototype.getAllUserAssignedContent = function (id) {
        return this._httpService
            .get(EndpointType.Content, "content/assign/" + id + "/all")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentAssignmentService.prototype.getPatientViewOfContent = function (id) {
        return this._httpService
            .get(EndpointType.Content, "content/entries/patient/" + id)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Delete
    ContentAssignmentService.prototype.deleteUserAssignedContent = function (id) {
        return this._httpService
            .delete(EndpointType.Content, "content/assign/" + id)
            .pipe(map(function (res) { return res.success; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Create
    ContentAssignmentService.prototype.createUserAssignedContent = function (newAssigned, id) {
        return this._httpService
            .post(EndpointType.Content, "content/assign/" + id, newAssigned)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // CRUD for PatientContentAssignmentEntries
    // ===================================================
    // Read
    ContentAssignmentService.prototype.getPatientOnlyAssignedContent = function (patientId) {
        return this._httpService
            .get(EndpointType.Content, "content/patient/" + patientId + "/1")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Create
    ContentAssignmentService.prototype.createPatientOnlyAssignedContent = function (body, patientId) {
        // TODO: Not sure how this is invoked
        return this._httpService
            .post(EndpointType.Content, "content/patient/" + patientId, body)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
        // return this._contentApiProvider.callApi().pipe(
        //   switchMap(([endpoint, defaultHttpOptions]) => {
        //     const uri = `${endpoint}content/patient/${patientId}`;
        //     return this.http
        //       .post<PatientOnlyAssignContentItem>(uri, body, defaultHttpOptions)
        //       .pipe(
        //         map((res: PatientOnlyAssignContentItem) => res),
        //         catchError((err) => {
        //           return throwError(err);
        //         })
        //       );
        //   })
        // );
    };
    // Update
    ContentAssignmentService.prototype.updatePatientOnlyAssignedContent = function (updateInfo, assignmentId) {
        return this._httpService
            .put(EndpointType.Content, "content/patient/" + assignmentId, updateInfo)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Delete
    ContentAssignmentService.prototype.deletePatientOnlyAssignedContent = function (deleteInfo) {
        return this._httpService
            .delete(EndpointType.Content, "content/patient/" + deleteInfo.assignmentid)
            .pipe(map(function (res) { return res.success; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    return ContentAssignmentService;
}());
export { ContentAssignmentService };
