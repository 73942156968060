import { SideBarMenuItem } from 'src/app/models/SideBarMenuItem';
import {
  LayoutActionsUnion,
  LayoutActionTypes,
} from '../actions/layout.actions';

export interface State {
  showSidenav: boolean;
  menuItems: SideBarMenuItem[];
  activeMenuItem: SideBarMenuItem;
}

export const initialState: State = {
  showSidenav: false,
  menuItems: [],
  activeMenuItem: null,
};

// Main layout reducer
export function reducer(
  state: State = initialState,
  action: LayoutActionsUnion,
): State {
  // console.log(action);
  switch (action.type) {
    case LayoutActionTypes.CloseSidenav:
      return {
        ...state,
        showSidenav: false,
      };

    case LayoutActionTypes.OpenSidenav:
      return {
        ...state,
        showSidenav: true,
      };

    case LayoutActionTypes.ActivateMenuItem:
      return {
        ...state,
        activeMenuItem: action.payload,
      };

    default:
      return state;
  }
}

// Selectors
export const getShowSidenav = (state: State) => state.showSidenav;
export const getMenuItems = (state: State) => state.menuItems;
export const getActiveMenuItem = (state: State) => state.activeMenuItem;
