import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable, of, Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import {
  FilterTargetLink,
  GetAvailableToLinkContent,
  LinkContent,
  LinkContentActionTypes,
  LinkContentSuccess,
  SelectedContentForLinking,
} from '../../actions/link-content.actions';
import * as fromContent from '../../reducers';
import {
  ContentEntryNames,
  LinkContentDialogData,
} from './../../models/linked-content';

@Component({
  selector: 'portal-link-content',
  templateUrl: './link-content.component.html',
  styleUrls: ['./link-content.component.scss'],
})
export class LinkContentComponent implements OnInit, OnDestroy {
  public scrollConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true,
  };
  public availableItemsToLinkTo$: Observable<ContentEntryNames[]> = of([]);
  public loadingAvailableItemsError$: Observable<boolean>;
  public loadingAvailableItems$: Observable<boolean>;
  public creatingLink$: Observable<boolean>;
  public creatingLinkError$: Observable<boolean>;
  public text: any;
  public selectedItems: number[];
  public contentCategoryId: number;
  public success: boolean;
  public itemSelected: string;

  public filterForm: FormGroup;
  public contentToLinkForm: FormGroup;

  public _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates$: Actions,
    public dialogRef: MatDialogRef<LinkContentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: LinkContentDialogData,
  ) {
    this._store.dispatch(
      new GetAvailableToLinkContent({
        contentCategory: data.contentCategoryId,
      }),
    );

    this.contentToLinkForm = new FormGroup({
      contentBeingLinked: new FormControl(data.selectedItems),
      contentToLinkTo: new FormControl('', Validators.required),
    });

    this.filterForm = new FormGroup({
      target: new FormControl(''),
    });

    this._subs.add(
      this._updates$
        .pipe(
          ofType<LinkContentSuccess>(LinkContentActionTypes.LinkContentSuccess),
        )
        .subscribe(() => {
          this.success = true;
          this.closeOnSuccess();
        }),
    );

    this._subs.add(
      this.filterForm
        .get('target')
        .valueChanges.subscribe((val) => this.updateTarget(val)),
    );

    this._subs.add(this.contentToLinkForm.valueChanges.subscribe());
  }

  ngOnInit() {
    this.availableItemsToLinkTo$ = this._store.pipe(
      select(fromContent.getFilteredAvailableContent),
    );
    this.loadingAvailableItems$ = this._store.pipe(
      select(fromContent.isLoadingAvailableToLinkContent),
    );
    this.loadingAvailableItemsError$ = this._store.pipe(
      select(fromContent.getLinkError('availableContentToLinkTo')),
    );
    this.creatingLink$ = this._store.pipe(select(fromContent.isCreatingLink));
    this.creatingLinkError$ = this._store.pipe(
      select(fromContent.getLinkError('creatingLink')),
    );
    this._store.dispatch(
      new SelectedContentForLinking(
        this.data.selectedItems.map((item) => item.id),
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public clearTarget() {
    this.filterForm.setValue({
      target: '',
    });
  }

  public updateTarget(val: string) {
    this._store.dispatch(new FilterTargetLink(val));
  }

  public cancel() {
    this._store.dispatch(new FilterTargetLink(''));
    this.dialogRef.close(false);
  }

  public closeOnSuccess() {
    setTimeout(() => {
      this._store.dispatch(new FilterTargetLink(''));
      this.dialogRef.close(true);
    }, 1500);
  }

  public linkContent() {
    const { contentBeingLinked, contentToLinkTo } =
      this.contentToLinkForm.value;
    const payload = {
      contentToShow: contentToLinkTo,
      contentToLink: contentBeingLinked.map((c) => c.id),
    };
    this._store.dispatch(new LinkContent(payload));
  }

  public selectItem(entry: ContentEntryNames) {
    this.itemSelected = entry.patientname || entry.clinicname;
    this.contentToLinkForm.patchValue({
      contentToLinkTo: entry.id,
    });
  }

  public clearSelectedItem() {
    this.itemSelected = null;
    this.contentToLinkForm.patchValue({
      contentToLinkTo: '',
    });
  }
}
