/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./loading-dialog.component";
import * as i8 from "@angular/material/dialog";
var styles_LoadingDialogComponent = [i0.styles];
var RenderType_LoadingDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingDialogComponent, data: {} });
export { RenderType_LoadingDialogComponent as RenderType_LoadingDialogComponent };
export function View_LoadingDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "loading-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(4, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_4 = 36; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.loadingText; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 4).diameter; var currVal_3 = i1.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_LoadingDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-loading-dialog", [], null, null, null, View_LoadingDialogComponent_0, RenderType_LoadingDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.LoadingDialogComponent, [i8.MAT_DIALOG_DATA], null, null)], null, null); }
var LoadingDialogComponentNgFactory = i1.ɵccf("portal-loading-dialog", i7.LoadingDialogComponent, View_LoadingDialogComponent_Host_0, {}, {}, []);
export { LoadingDialogComponentNgFactory as LoadingDialogComponentNgFactory };
