import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { GetContentTypes } from 'src/app/content/actions/content.actions';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { FileUpload } from '../../../core/services/s3.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import {
  CreatePatientOnlyAssignments,
  DeletePatientOnlyAssignments,
  GetContentEntries,
  GetPatientAssignedContent,
  GetPatientOnlyAssignments,
  SetContentSelectFilter,
  UpdatePatientOnlyAssignments,
} from '../../actions/content-assignment.actions';
import { DetailedPatient } from '../../models/DetailedPatient';
import { ClinicPatientResponse } from '../../models/responses/clinic-patient.response';
import { PatientOnlyAssignContentItem } from '../../models/UserContent';
import * as fromContentAssignment from '../../reducers';
import { FileInStorage } from './../../../content/models/file-in-storage';

@Component({
  selector: 'portal-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.scss'],
})
export class PatientDocumentsComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};

  // Observables
  // ================================================
  // Text
  public contentSwingText$: Observable<any>;
  // content entries
  public activeContentTypeId$: Observable<number>;
  // content-select
  public csFilterActive$: Observable<boolean>;
  public csFilterString$: Observable<string>;
  // assigned-content
  public acFilterActive$: Observable<boolean>;
  public acFilterString$: Observable<string>;
  public patientId$: Observable<number>;
  // patient only content
  public activePatientOnlyItems$: Observable<PatientOnlyAssignContentItem[]>;
  public inactivePatientOnlyItems$: Observable<PatientOnlyAssignContentItem[]>;
  public filteredActivePatientOnlyItems$: Observable<
    PatientOnlyAssignContentItem[]
  >;
  public filteredInactivePatientOnlyItems$: Observable<
    PatientOnlyAssignContentItem[]
  >;
  public isCategoryPatientOnly$: Observable<boolean>;

  // current patient
  public currentPatient$: Observable<DetailedPatient>;
  public patientName$: Observable<string>;

  // Subscriptions container
  private _subs = new Subscription();

  private _patientId: number;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private dialog: MatDialog,
    public change: ChangeDetectorRef,
    private _s3: FileUpload,
  ) {
    // fetch user assigned content and update types and entries
    this._store.dispatch(new GetContentEntries());
    this._store.dispatch(new GetContentTypes());
    this.patientId$ = this._route.params.pipe(
      map((params: Params) => +params.patientId),
    );

    // check router for changes to target patient
    this._subs.add(
      this.patientId$.subscribe((id) => {
        this._patientId = id;
        this._store.dispatch(new GetPatientAssignedContent(this._patientId));
        this._store.dispatch(
          new GetPatientOnlyAssignments({
            patientId: this._patientId,
          }),
        );
      }),
    );
    // fetch text from store
    this.contentSwingText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentSwing')),
    );

    // define observables to fetch data from store
    this.activeContentTypeId$ = this._store.pipe(
      select(fromContentAssignment.getActiveContentType),
    );
    this.csFilterActive$ = this._store.pipe(
      select(fromContentAssignment.isContentSelectFilterActive),
    );
    this.csFilterString$ = this._store.pipe(
      select(fromContentAssignment.getContentSelectFilterString),
    );
    this.acFilterActive$ = this._store.pipe(
      select(fromContentAssignment.isAssignedContentFilterActive),
    );
    this.acFilterString$ = this._store.pipe(
      select(fromContentAssignment.getAssignedContentFilterString),
    );
    // Patient specific files
    this.activePatientOnlyItems$ = this._store.pipe(
      select(fromContentAssignment.getActiveFilteredPatientOnlyContent),
    );
    this.inactivePatientOnlyItems$ = this._store.pipe(
      select(fromContentAssignment.getInactiveFilteredPatientOnlyContent),
    );
    this.isCategoryPatientOnly$ = this._store.pipe(
      select(fromContentAssignment.isCategoryPatientOnly),
    );

    // fetch current patient
    this.currentPatient$ = this.patientId$.pipe(
      switchMap((id) =>
        this._store.pipe(select(fromContentAssignment.getPatientById(id))),
      ),
      filter(
        (clinicPatientResponse: ClinicPatientResponse) =>
          !!clinicPatientResponse && !!clinicPatientResponse.Patient,
      ),
      map(
        (clinicPatientResponse: ClinicPatientResponse) =>
          clinicPatientResponse.Patient,
      ),
    );
    this.patientName$ = this.currentPatient$.pipe(
      map(
        (patient: DetailedPatient) =>
          `${patient.FirstName} ${patient.LastName}`,
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public setContentSelectFilter(str: string): void {
    this._store.dispatch(new SetContentSelectFilter(str.toLowerCase()));
  }

  public createNewFile(): void {
    const validAccepts = this._s3.fetchAccepts('file');

    const fileDialog = this.dialog.open(AddFileComponent, {
      data: {
        accept: validAccepts,
        public: false,
        patientOnly: true,
        patientId: this._patientId,
      },
    });
    fileDialog
      .afterClosed()
      .subscribe((file: { attachment: FileInStorage }) => {
        if (file && file.attachment) {
          this._store.dispatch(
            new CreatePatientOnlyAssignments({
              patientId: this._patientId,
              body: {
                contentfileid: file.attachment.id,
                origintypeid: 1,
              },
            }),
          );
        }
      });
  }

  public setPatientFileToActive(file: PatientOnlyAssignContentItem) {
    file.isTransferring = true;
    this._store.dispatch(
      new UpdatePatientOnlyAssignments({
        assignmentId: file.assignmentid,
        updateInfo: {
          isactive: true,
        },
      }),
    );
  }

  public setPatientFileToInactive(file: PatientOnlyAssignContentItem) {
    file.isTransferring = true;
    this._store.dispatch(
      new UpdatePatientOnlyAssignments({
        assignmentId: file.assignmentid,
        updateInfo: {
          isactive: false,
        },
      }),
    );
  }

  public deletePatientFile(file: PatientOnlyAssignContentItem) {
    this._store.dispatch(
      new DeletePatientOnlyAssignments({
        deleteInfo: {
          assignmentid: file.assignmentid,
        },
      }),
    );
  }
}
