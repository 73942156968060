import { EventEmitter, } from '@angular/core';
import { TabOptions } from '../../models/ContentPacks';
var ContentTypeFilterComponent = /** @class */ (function () {
    function ContentTypeFilterComponent() {
        this.setPackId = new EventEmitter();
        this.setContentTypeId = new EventEmitter();
        this.selectTab = new EventEmitter();
        this.tabOptions = TabOptions;
        this.config = {};
    }
    return ContentTypeFilterComponent;
}());
export { ContentTypeFilterComponent };
