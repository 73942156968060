import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { Button, InputLabel, MessageEditor, PatientInput, Select, TextInput, } from '@react/components';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useMemo, useRef, useState } from 'react';
import { MESSAGE_MAX_LENGTH, SUBJECT_MAX_LENGTH } from '../constants';
import { useFoldersListQuery } from '../hooks';
import { useCreateConversationMutation } from '../hooks/useCreateConversationMutation';
import { useNewConversationToast } from '../hooks/useToastMessages';
export var NewMessageModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, _b = _a.dialogWrapper, dialogWrapper = _b === void 0 ? true : _b, refreshPageOnLinkClicked = _a.refreshPageOnLinkClicked, _c = _a.patientInputProps, patientInputProps = _c === void 0 ? {} : _c;
    var t = useTranslations().t;
    var _d = useState(patientInputProps.defaultPatient || null), selectedPatient = _d[0], setSelectedPatient = _d[1];
    var _e = useState(''), selectedFolder = _e[0], setSelectedFolder = _e[1];
    var _f = useState(''), subjectValue = _f[0], setSubjectValue = _f[1];
    var _g = useState(''), messageValue = _g[0], setMessageValue = _g[1];
    var cacheValues = useRef({
        selectedPatient: selectedPatient,
        selectedFolder: selectedFolder,
        subjectValue: subjectValue,
        messageValue: messageValue,
    }).current;
    var foldersResult = useFoldersListQuery();
    var folders = foldersResult.data && foldersResult.data.data;
    var _h = useCreateConversationMutation(), createConversation = _h.mutateAsync, isSendingError = _h.isError, resetQuery = _h.reset;
    var showNewConversationToast = useNewConversationToast();
    useErrorToast(isSendingError, {
        title: t.MessageToast.ErrorEncountered,
        message: t.MessageToast.ErrorSending(selectedPatient
            ? selectedPatient.firstname + " " + selectedPatient.lastname
            : ''),
    });
    function resetState() {
        setSelectedPatient(null);
        setSelectedFolder('');
        setSubjectValue('');
        setMessageValue('');
        resetQuery();
    }
    function handleClose() {
        onClose({ hasValues: hasValues(), hasChanged: hasChanged() });
    }
    function onPatientChange(patient) {
        setSelectedPatient(patient);
    }
    function onMessageChange(value) {
        setMessageValue(value);
    }
    function onSelectedFolderChange(event) {
        setSelectedFolder(String(event.target.value));
    }
    function onSubjectChange(event) {
        setSubjectValue(event.target.value);
    }
    function onSendClicked() {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newConversationResponse;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (selectedFolder === '' || selectedPatient == null) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, createConversation({
                                patientid: selectedPatient.id,
                                messagetypeid: Number(selectedFolder),
                                messagesubject: subjectValue.length > 0 ? subjectValue : null,
                                content: messageValue,
                            })];
                    case 1:
                        newConversationResponse = _a.sent();
                        showNewConversationToast({
                            title: t.MessageToast.OutboundSentTitle,
                            message: t.MessageToast.OutboundSentBody(selectedPatient.firstname + " " + selectedPatient.lastname),
                            folderId: Number(selectedFolder),
                            conversation: newConversationResponse.data,
                            refreshPageOnLinkClicked: refreshPageOnLinkClicked,
                        });
                        resetState();
                        onClose({ hasValues: false, hasChanged: false });
                        return [2 /*return*/];
                }
            });
        });
    }
    var validPatient = selectedPatient != null;
    var validFolder = selectedFolder.length > 0;
    var validSubject = subjectValue.length <= SUBJECT_MAX_LENGTH;
    var validMessage = messageValue.length > 0 && messageValue.length <= MESSAGE_MAX_LENGTH;
    function isFormValid() {
        return validPatient && validFolder && validSubject && validMessage;
    }
    function hasValues() {
        return (validPatient ||
            validFolder ||
            (validSubject && subjectValue.length > 0) ||
            validMessage);
    }
    function hasChanged() {
        return (selectedPatient !== cacheValues.selectedPatient ||
            selectedFolder !== cacheValues.selectedFolder ||
            subjectValue !== cacheValues.subjectValue ||
            messageValue !== cacheValues.messageValue);
    }
    var folderSelectionOptions = useMemo(function () {
        return folders
            ? folders.map(function (folder) { return ({
                value: String(folder.id),
                label: folder.name,
            }); })
            : [];
    }, [folders]);
    var wrapperProps = {
        open: open,
        dialogWrapper: dialogWrapper,
        onClose: handleClose,
    };
    return (React.createElement(Wrapper, tslib_1.__assign({}, wrapperProps),
        React.createElement(StyledContainer, null,
            React.createElement(Typography, { className: "newMessageModal-title", variant: "subtitle1" }, t.NewMessage.Title),
            React.createElement(IconButton, { className: "newMessageModal-close", "aria-label": "Close dialog", onClick: handleClose, size: "small", role: "button" },
                React.createElement(IconClose, { color: "primary" })),
            React.createElement("div", { className: "newMessageModal-input" },
                React.createElement(PatientInput, tslib_1.__assign({ id: "newMessageModal-patient", label: t.NewMessage.Patient, onChange: onPatientChange }, patientInputProps))),
            React.createElement("div", { className: "newMessageModal-input" },
                React.createElement(Select, { id: "newMessageModal-folders", label: t.NewMessage.FolderFieldLabel, value: selectedFolder, onChange: onSelectedFolderChange, fullWidth: true, placeholder: t.NewMessage.FolderFieldPlaceholder, options: folderSelectionOptions })),
            React.createElement("div", { className: "newMessageModal-input newMessageModal-withCharCount" },
                React.createElement(TextInput, { id: "newMessageModal-subject", label: t.NewMessage.SubjectFieldLabel, placeholder: t.NewMessage.SubjectFieldPlaceholder, value: subjectValue, onChange: onSubjectChange, maxLength: SUBJECT_MAX_LENGTH, fullWidth: true, showCharCount: true })),
            React.createElement("div", { className: "newMessageModal-input" },
                React.createElement(InputLabel, { htmlFor: "newMessageModal-content" }, t.NewMessage.ContentFieldLabel),
                React.createElement(MessageEditor, { inputId: "newMessageModal-content", patientId: selectedPatient && selectedPatient.id, value: messageValue, onChange: onMessageChange, maxLength: MESSAGE_MAX_LENGTH, maxRows: 6 })),
            React.createElement("div", { className: "newMessageModal-sendButton" },
                React.createElement(Button, { onClick: onSendClicked, color: "primary", size: "small", variant: "contained", disabled: !isFormValid() }, t.NewMessage.Send)))));
};
var Wrapper = function (_a) {
    var children = _a.children, dialogWrapper = _a.dialogWrapper, onClose = _a.onClose, open = _a.open;
    if (!dialogWrapper) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(Dialog, { open: open, onClose: onClose }, children));
};
var ɵ0 = Wrapper;
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  box-sizing: border-box;\n  width: 600px;\n  padding: 20px;\n  position: relative;\n\n  .newMessageModal-title {\n    text-align: center;\n    margin-bottom: 16px;\n  }\n\n  .newMessageModal-close {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n  }\n\n  .newMessageModal-input {\n    margin-bottom: 20px;\n\n    &.newMessageModal-withCharCount {\n      margin-bottom: 5px;\n    }\n  }\n\n  .newMessageModal-sendButton {\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 600px;\n  padding: 20px;\n  position: relative;\n\n  .newMessageModal-title {\n    text-align: center;\n    margin-bottom: 16px;\n  }\n\n  .newMessageModal-close {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n  }\n\n  .newMessageModal-input {\n    margin-bottom: 20px;\n\n    &.newMessageModal-withCharCount {\n      margin-bottom: 5px;\n    }\n  }\n\n  .newMessageModal-sendButton {\n    display: flex;\n    justify-content: flex-end;\n  }\n"])));
export { ɵ0 };
var templateObject_1;
