import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { GetContentEntries } from './../actions/content.actions';
import {
  AddToContentPack,
  AddToContentPackError,
  AddToContentPackSuccess,
  AddToMultipleContentPacks,
  AddToMultipleContentPacksSuccess,
  ContentPackActionTypes,
  CreateContentPack,
  CreateContentPackError,
  CreateContentPackSuccess,
  DeleteContentPack,
  DeleteContentPackError,
  DeleteContentPackSuccess,
  EditContentPack,
  EditContentPackError,
  EditContentPackSuccess,
  GetContentPackDetail,
  GetContentPackDetailError,
  GetContentPackDetailSuccess,
  GetContentPacks,
  GetContentPacksError,
  GetContentPacksSuccess,
  RemoveFromContentPack,
  RemoveFromContentPackError,
  RemoveFromContentPackSuccess,
} from './../actions/packs.actions';

import { PacksService } from './../services/packs.service';

import {
  AddToMultiplePacksResponse,
  AddToPackResponse,
  CreateContentPackResponse,
  DeletePackResponse,
  EditContentPackResponse,
  GetContentPackDetailResponse,
  GetContentPacksResponse,
  RemoveFromPackResponse,
} from './../models/content-packs';

@Injectable()
export class PacksEffects {
  @Effect()
  getContentPacks$ = this._actions$.pipe(
    ofType<GetContentPacks>(ContentPackActionTypes.GetContentPacks),
    switchMap(() =>
      this._packsService.getContentPacks().pipe(
        map((res: GetContentPacksResponse) => new GetContentPacksSuccess(res)),
        catchError((err) => {
          return of(new GetContentPacksError(err));
        }),
      ),
    ),
  );

  @Effect()
  getContentPackDetail$ = this._actions$.pipe(
    ofType<GetContentPackDetail>(ContentPackActionTypes.GetContentPackDetail),
    switchMap((action) =>
      this._packsService.getContentPackDetail(action.payload).pipe(
        map(
          (res: GetContentPackDetailResponse) =>
            new GetContentPackDetailSuccess(res),
        ),
        catchError((err) => {
          return of(new GetContentPackDetailError(err));
        }),
      ),
    ),
  );

  @Effect()
  createContentPack$ = this._actions$.pipe(
    ofType<CreateContentPack>(ContentPackActionTypes.CreateContentPack),
    switchMap((action) =>
      this._packsService.createContentPack(action.payload).pipe(
        map(
          (res: CreateContentPackResponse) => new CreateContentPackSuccess(res),
        ),
        catchError((err) => {
          return of(new CreateContentPackError(err));
        }),
      ),
    ),
  );

  @Effect()
  editContentPack$ = this._actions$.pipe(
    ofType<EditContentPack>(ContentPackActionTypes.EditContentPack),
    switchMap((action) =>
      this._packsService.editContentPack(action.payload).pipe(
        map((res: EditContentPackResponse) => new EditContentPackSuccess(res)),
        catchError((err) => {
          return of(new EditContentPackError(err));
        }),
      ),
    ),
  );

  @Effect()
  deleteContentPack$ = this._actions$.pipe(
    ofType<DeleteContentPack>(ContentPackActionTypes.DeleteContentPack),
    switchMap((action) =>
      this._packsService.deleteContentPack(action.payload).pipe(
        map((res: DeletePackResponse) => new DeleteContentPackSuccess(res)),
        catchError((err) => {
          return of(new DeleteContentPackError(err));
        }),
      ),
    ),
  );

  @Effect()
  addToContentPack$ = this._actions$.pipe(
    ofType<AddToContentPack>(ContentPackActionTypes.AddToContentPack),
    switchMap((action) =>
      this._packsService.addToContentPack(action.payload).pipe(
        mergeMap((res: AddToPackResponse) => [
          new GetContentEntries(),
          new AddToContentPackSuccess(res),
        ]),
        catchError((err) => {
          return of(new AddToContentPackError(err));
        }),
      ),
    ),
  );

  @Effect()
  addToContentMultiplePacks$ = this._actions$.pipe(
    ofType<AddToMultipleContentPacks>(
      ContentPackActionTypes.AddToMultipleContentPacks,
    ),
    switchMap((action) =>
      this._packsService.addToMultipleContentPacks(action.payload).pipe(
        mergeMap((res: AddToMultiplePacksResponse) => [
          new GetContentEntries(),
          new AddToMultipleContentPacksSuccess(res),
        ]),
        catchError((err) => {
          return of(new AddToContentPackError(err));
        }),
      ),
    ),
  );

  @Effect()
  removeFromContentPack$ = this._actions$.pipe(
    ofType<RemoveFromContentPack>(ContentPackActionTypes.RemoveFromContentPack),
    switchMap((action) =>
      this._packsService.removeFromContentPack(action.payload).pipe(
        map(
          (res: RemoveFromPackResponse) =>
            new RemoveFromContentPackSuccess(res),
        ),
        catchError((err) => {
          return of(new RemoveFromContentPackError(err));
        }),
      ),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _packsService: PacksService,
  ) {}
}
