import { useApiClient } from '@react/lib/api';
import { Conversation } from '@react/types';
import { useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatConversation } from '../lib';

export function useFetchConversationById(id?: Conversation['messageid']) {
  const apiClient = useApiClient().portal;

  return useQuery(
    [queryCacheKey.CONVERSATION, id],
    async () => {
      // tslint:disable-next-line: no-non-null-assertion
      const { data } = await apiClient.fetchConversationById(id!);
      return formatConversation(data);
    },
    { enabled: !!id },
  );
}
