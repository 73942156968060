import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as AuthActions from '../../actions/auth.actions';
import {
  CognitoError,
  ForgottenPasswordForm,
  SubmitNewPasswordForm,
} from '../../models/LoginSubmission';
import * as fromAuth from '../../reducers';

@Component({
  selector: 'portal-forgot-password-container',
  template: `
    <portal-forgot-password
      [isCore]="isCoreClinic$ | async"
      [text]="authText$ | async"
      [generatingCode]="generatingCode$ | async"
      [resettingPassword]="resettingPassword$ | async"
      [codeGenerated]="codeGenerated$ | async"
      [errorMessage]="error$ | async"
      [clinicId]="clinicId$ | async"
      (requestPasswordCode)="requestNewPasswordCode($event)"
      (submitNewPassword)="submitNewPassword($event)"
    ></portal-forgot-password>
  `,
})
export class ForgotPasswordContainer {
  public isCoreClinic$: Observable<boolean>;
  public clinicId$: Observable<string>;
  public loggingIn$: Observable<boolean>;
  public error$: Observable<CognitoError>;
  public authText$: Observable<any>;
  public generatingCode$: Observable<boolean>;
  public resettingPassword$: Observable<boolean>;
  public codeGenerated$: Observable<boolean>;

  constructor(
    private _store: Store<fromAuth.State>,
    private _storeRoot: Store<fromRoot.State>,
  ) {
    this.authText$ = this._storeRoot.pipe(
      select(fromSettings.getSectionTranslations('Authentication')),
    );
    this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
    this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
    this.error$ = this._store.pipe(select(fromAuth.getLoginPageError));
    this.generatingCode$ = this._store.pipe(select(fromAuth.isGeneratingCode));
    this.resettingPassword$ = this._store.pipe(
      select(fromAuth.isResettingPassword),
    );
    this.codeGenerated$ = this._store.pipe(select(fromAuth.hasGeneratedCode));
  }

  submitNewPassword(formData: SubmitNewPasswordForm) {
    this._store.dispatch(new AuthActions.ResetPassword(formData));
  }

  requestNewPasswordCode(formData: ForgottenPasswordForm) {
    this._store.dispatch(new AuthActions.GenerateResetCode(formData));
  }
}
