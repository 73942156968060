import { CircularProgress, Typography } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { LoaderWrapper, StyledKey, StyledList } from './index.styled';

interface IProps<T> {
  data: T[];
  isActive: (key: T) => boolean;
  onClick: (key: T) => void;
  id: string;
  getLabel: (key: T) => string;
}

interface IInfiniteData<T> {
  data: T[];
}
interface IInfiniteProps<T> {
  data: IInfiniteData<T>[];
  isActive: (key: T) => boolean;
  onClick: (key: T) => void;
  getLabel: (key: T) => string;
  loadMore: () => void;
  loading: boolean;
  id: string;
}

export function List<T>({ data, isActive, onClick, id, getLabel }: IProps<T>) {
  return (
    <>
      <StyledList>
        {data.map((item: T, index: number) => {
          return (
            <div
              className={`listItem ${isActive(item) === true ? 'active' : ''}`}
              onClick={() => onClick(item)}
              key={`${id}-${index}`}
            >
              <StyledKey>
                <Typography>{getLabel(item)}</Typography>
                {isActive(item) === true && <Done />}
              </StyledKey>
            </div>
          );
        })}
      </StyledList>
    </>
  );
}

export function InfiniteList<T>({
  data,
  isActive,
  onClick,
  id,
  getLabel,
  loadMore,
  loading,
}: IInfiniteProps<T>) {
  return (
    <>
      <StyledList>
        {data.map((page: IInfiniteData<T>) => {
          return page.data.map((item: T, index: number) => {
            return (
              <div
                className={`listItem ${
                  isActive(item) === true ? 'active' : ''
                }`}
                onClick={() => onClick(item)}
                key={`${id}-${index}`}
              >
                <StyledKey>
                  <Typography>{getLabel(item)}</Typography>
                  {isActive(item) === true && <Done />}
                </StyledKey>
              </div>
            );
          });
        })}
        {loading && (
          <LoaderWrapper data-testid="Loader">
            <CircularProgress />
          </LoaderWrapper>
        )}
        <Waypoint onEnter={loadMore} />
      </StyledList>
    </>
  );
}
