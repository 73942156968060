<div
  *ngIf="videoSrc == null; else video"
  class="video-preview"
  [innerHtml]="videoPreview"
></div>
<ng-template #video>
  <div *ngIf="videoSrc" class="video-preview">
    <video controls preload="metadata" src="{{ videoSrc }}"></video>
  </div>
</ng-template>
