import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClearChildren, DeleteAllContentLinks, DeleteContentLink, DeleteSomeContentLinks, LinkContentActionTypes, LoadChildren, } from './../../actions/link-content.actions';
import * as fromSettings from '../../../settings/reducers';
import * as fromContent from '../../reducers';
import { ConfirmActionComponent } from 'src/app/core/components/confirm-action/confirm-action.component';
var ViewChildrenContainer = /** @class */ (function () {
    function ViewChildrenContainer(_store, _updates$, dialogRef, dialog, data) {
        this._store = _store;
        this._updates$ = _updates$;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.deleteLinkSuccess$ = new BehaviorSubject(false);
        this.deleteLinksSuccess$ = new BehaviorSubject(false);
        this._subs = new Subscription();
        this._store.dispatch(new LoadChildren({ headerId: data.parentId }));
    }
    ViewChildrenContainer.prototype.ngOnInit = function () {
        var _this = this;
        this.text$ = this._store.pipe(select(fromSettings.getSectionTranslations('NewContentList')));
        this.parentInfo$ = this._store.pipe(select(fromContent.getParentForModal(this.data.parentId, this.data.categoryId)));
        this.loadingChildren$ = this._store.pipe(select(fromContent.isLoadingChildren));
        this.children$ = this._store.pipe(select(fromContent.getChildren), map(function (children) {
            if (_this.data.contentId) {
                var sortedArray = [];
                // put chosen child at top of list
                for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
                    var child = children_1[_i];
                    if (child.id === _this.data.contentId) {
                        sortedArray.unshift(child);
                    }
                    else {
                        sortedArray.push(child);
                    }
                }
                return sortedArray;
            }
            else {
                return children;
            }
        }));
        this.deletingLink$ = this._store.pipe(select(fromContent.isDeletingLink));
        this.deleteLinkError$ = this._store.pipe(select(fromContent.getLinkError('deletingLink')));
        this.deletingLinks$ = this._store.pipe(select(fromContent.isDeletingLinks));
        this.deleteLinksError$ = this._store.pipe(select(fromContent.getLinkError('deletingLinks')));
        this._subs.add(this.text$.subscribe(function (t) { return (_this.text = t); }));
        // Watch for individual link deletion success
        this._subs.add(this._updates$
            .pipe(ofType(LinkContentActionTypes.DeleteContentLinkSuccess))
            .subscribe(function () {
            _this.deleteLinkSuccess$.next(true);
            _this._store.dispatch(new LoadChildren({ headerId: _this.data.parentId }));
            setTimeout(function () {
                _this.deleteLinkSuccess$.next(false);
            }, 1000);
        }));
        // Watch for some links deletion success
        this._subs.add(this._updates$
            .pipe(ofType(LinkContentActionTypes.DeleteSomeContentLinksSuccess))
            .subscribe(function () {
            _this.deleteLinksSuccess$.next(true);
            _this._store.dispatch(new LoadChildren({ headerId: _this.data.parentId }));
            setTimeout(function () {
                _this.deleteLinksSuccess$.next(false);
            }, 1000);
        }));
        // Watch for all links deletion success
        this._subs.add(this._updates$
            .pipe(ofType(LinkContentActionTypes.DeleteAllContentLinksSuccess))
            .subscribe(function () {
            _this.deleteLinksSuccess$.next(true);
            setTimeout(function () {
                _this.closeModal();
            }, 1000);
        }));
        this._subs.add(this.children$.subscribe(function (c) { return (_this.children = c); }));
    };
    ViewChildrenContainer.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    ViewChildrenContainer.prototype.closeModal = function () {
        this.dialogRef.close(this.children);
        this._store.dispatch(new ClearChildren());
    };
    ViewChildrenContainer.prototype.handleLinkDeletion = function (linkDeletionParams) {
        var _this = this;
        var type = linkDeletionParams.type, ids = linkDeletionParams.ids;
        var _a = this._defineConfirmText(type, this.text), message = _a.message, confirm = _a.confirm;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: message,
                text: {
                    Cancel: this.text.Cancel,
                    Confirm: confirm,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this._triggerDelete(type, ids);
            }
        });
    };
    ViewChildrenContainer.prototype.deleteLink = function (childId) {
        this._store.dispatch(new DeleteContentLink(childId));
    };
    ViewChildrenContainer.prototype.deleteSomeLinks = function (childIds) {
        this._store.dispatch(new DeleteSomeContentLinks(childIds));
    };
    ViewChildrenContainer.prototype.deleteAllLinks = function (parentId) {
        this._store.dispatch(new DeleteAllContentLinks(parentId));
    };
    ViewChildrenContainer.prototype._triggerDelete = function (type, ids) {
        switch (type) {
            case 'one': {
                var id = ids[0];
                this.deleteLink(id);
                break;
            }
            case 'some': {
                this.deleteSomeLinks(ids);
                break;
            }
            case 'all': {
                var id = ids[0];
                this.deleteAllLinks(id);
            }
        }
    };
    ViewChildrenContainer.prototype._defineConfirmText = function (type, text) {
        switch (type) {
            case 'one': {
                return {
                    message: text.RemoveConfirm,
                    confirm: text.RemoveLink,
                };
            }
            case 'some': {
                return {
                    message: text.RemoveSomeConfirm,
                    confirm: text.RemoveLinks,
                };
            }
            case 'all': {
                return {
                    message: text.RemoveAllConfirm,
                    confirm: text.RemoveLinks,
                };
            }
        }
    };
    return ViewChildrenContainer;
}());
export { ViewChildrenContainer };
