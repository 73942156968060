import * as tslib_1 from "tslib";
import { Box, Popover } from '@material-ui/core';
import { useFeatures } from '@react/lib/features/context';
import * as React from 'react';
import { useTranslations } from '../lib/i18n';
import { Button } from './Button';
import { CharacterCount } from './CharacterCount';
import MessageAddContentLink from './MessageAddContentLink';
import MessageAddTemplate from './MessageAddTemplate';
import { Container, StatusBar, TextArea, TextAreaContainer, Toolbar, ToolbarIconButton, WarningIcon, WarningText, } from './MessageEditor.styled';
var MessageInsert;
(function (MessageInsert) {
    MessageInsert["Template"] = "template";
    MessageInsert["ContentLink"] = "contentLink";
})(MessageInsert || (MessageInsert = {}));
export var MessageEditor = function (_a) {
    var actionText = _a.actionText, isActionActive = _a.isActionActive, maxLength = _a.maxLength, onActionClick = _a.onActionClick, onChange = _a.onChange, patientId = _a.patientId, value = _a.value, warningText = _a.warningText, inputId = _a.inputId, _b = _a.minRows, minRows = _b === void 0 ? 4 : _b, _c = _a.maxRows, maxRows = _c === void 0 ? 18 : _c, props = tslib_1.__rest(_a, ["actionText", "isActionActive", "maxLength", "onActionClick", "onChange", "patientId", "value", "warningText", "inputId", "minRows", "maxRows"]);
    var features = useFeatures();
    var enableContentLinks = !!features.messagingOptions && features.messagingOptions.contentLinks;
    var t = useTranslations().t;
    var _d = React.useState(), activeModalType = _d[0], setActiveModalType = _d[1];
    var templateButtonRef = React.useRef(null);
    var contentLinkButtonRef = React.useRef(null);
    var inputRef = React.useRef(null);
    var _e = React.useState(null), modalAnchorRef = _e[0], setModalAnchorRef = _e[1];
    var disabled = props.disabled || patientId == null;
    var insertAtCursorPos = function (txt) {
        var inputEl = inputRef.current;
        if (!inputEl) {
            return;
        }
        var strPos = inputEl.selectionStart;
        var textBeforeInsertedString = value.substring(0, strPos);
        var textAfterInsertedString = value.substring(strPos, value.length);
        var textBeforeCursor = textBeforeInsertedString.length
            ? textBeforeInsertedString + " " + txt
            : txt;
        var newText = textBeforeCursor + " " + textAfterInsertedString;
        onChange("" + newText);
    };
    var handleContentEntry = function (contentLink) {
        insertAtCursorPos(contentLink);
        setActiveModalType(undefined);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    var onInsertMessageTemplate = function (template) {
        onChange(template);
        setActiveModalType(undefined);
    };
    var handleChange = React.useCallback(function (e) {
        if (onChange != null) {
            onChange(e.target.value);
        }
    }, [onChange]);
    var viewContentLinkModal = function () {
        setModalAnchorRef(contentLinkButtonRef);
        setActiveModalType(MessageInsert.ContentLink);
    };
    var viewMessageTemplateModal = function () {
        setModalAnchorRef(templateButtonRef);
        setActiveModalType(MessageInsert.Template);
    };
    return (React.createElement(Container, null,
        React.createElement(TextAreaContainer, null,
            React.createElement(TextArea, { id: inputId, autoComplete: "off", disabled: disabled, disableUnderline: true, inputProps: {
                    maxLength: maxLength,
                }, inputRef: inputRef, minRows: minRows, maxRows: maxRows, multiline: true, placeholder: t.Messages.WriteAMessage, onChange: handleChange, value: value }),
            React.createElement(StatusBar, null,
                React.createElement(WarningText, null, warningText != null && warningText !== '' ? (React.createElement(React.Fragment, null,
                    React.createElement(WarningIcon, null),
                    warningText)) : null),
                React.createElement(CharacterCount, { length: value.length, maxLength: maxLength }))),
        React.createElement(Toolbar, null,
            React.createElement(Box, null,
                React.createElement(ToolbarIconButton, { disabled: disabled, onClick: viewMessageTemplateModal, ref: templateButtonRef, size: "small", role: "button", title: t.Messages.InsertMessageTemplate },
                    React.createElement("img", { src: "assets/imgs/messagetemplate_icon_" + (disabled ? 'inactive' : 'active') + ".svg" })),
                enableContentLinks && (React.createElement(ToolbarIconButton, { disabled: disabled, ref: contentLinkButtonRef, onClick: viewContentLinkModal, size: "small", role: "button", title: t.Admin.MessageContentInsertLink },
                    React.createElement("img", { src: "assets/imgs/contentlink_icon_" + (disabled ? 'inactive' : 'active') + ".svg" })))),
            actionText != null && onActionClick != null ? (React.createElement(Button, { color: "primary", disabled: disabled || !isActionActive, onClick: onActionClick, size: "small", variant: "contained" }, actionText)) : null),
        React.createElement(Popover, { anchorEl: modalAnchorRef && modalAnchorRef.current
                ? modalAnchorRef.current
                : undefined, anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
            }, open: !!activeModalType, onClose: function () {
                setActiveModalType(undefined);
            }, transformOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
            } },
            React.createElement(Box, { display: "flex", flexDirection: "row" },
                activeModalType === MessageInsert.ContentLink && (React.createElement(MessageAddContentLink, { onAddContentLink: handleContentEntry })),
                activeModalType === MessageInsert.Template && patientId && (React.createElement(MessageAddTemplate, { onAddTemplate: onInsertMessageTemplate, patientId: patientId }))))));
};
