import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Card, Paper, TableFooter, TableHead, TableRow, } from '@material-ui/core';
import SearchBar from '@react/components/SearchBar';
import { Link } from 'react-router-dom';
export var Container = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var ActionsContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  margin-bottom: ", "px;\n"], ["\n  align-items: center;\n  display: flex;\n  margin-bottom: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledSearchBar = styled(SearchBar)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  margin-right: ", "px;\n  position: sticky;\n  top: 0;\n"], ["\n  flex: 1;\n  margin-right: ", "px;\n  position: sticky;\n  top: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledCard = styled(Card)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  flex-grow: 1;\n"], ["\n  flex-grow: 1;\n"])));
export var FullHeightPaper = styled(Paper)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  flex-grow: 1;\n  padding: ", "px;\n"], ["\n  flex-grow: 1;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledTableHead = styled(TableHead)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  th {\n    font-size: 16px;\n    font-weight: ", ";\n    color: #757575;\n  }\n"], ["\n  th {\n    font-size: 16px;\n    font-weight: ", ";\n    color: #757575;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightMedium;
});
export var StyledTableRow = styled(TableRow)(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  &:hover {\n    background-color: #f0f0f7;\n  }\n"], ["\n  &:hover {\n    background-color: #f0f0f7;\n  }\n"])));
export var StyledTableFooter = styled(TableFooter)(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  td {\n    border-bottom: 0;\n  }\n"], ["\n  td {\n    border-bottom: 0;\n  }\n"])));
export var StyledLink = styled(Link)(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["\n  display: block;\n  padding: ", ";\n"], ["\n  display: block;\n  padding: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2) + "px";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
