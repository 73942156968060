import { SIDEStatus } from '@react/lib/api/enum';
import * as React from 'react';
import { ActiveStyledChip, InactiveStyledChip } from './SIDEStatusChip.styled';

const SIDEStatusChip: React.FunctionComponent<{
  status: keyof typeof SIDEStatus;
  label: string;
}> = (props) => {
  return props.status === SIDEStatus.Active ? (
    <ActiveStyledChip label={props.label} />
  ) : (
    <InactiveStyledChip label={props.label} />
  );
};

export default SIDEStatusChip;
