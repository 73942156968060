import * as tslib_1 from "tslib";
import { Unauthorised } from '@react/components/Unauthorised';
import { FeatureRoute } from '@react/lib/features/FeatureRoute';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Main, PageContainer, StyledRoot } from './AdminPage.styled';
import AppointmentType from './components/AppointmentType';
import AppointmentTypeList from './components/AppointmentTypeList';
import MessageTemplate from './components/MessageTemplate';
import MessageTemplatesList from './components/MessageTemplatesList';
import Nav from './components/Nav';
import Recipe from './components/side/recipe';
import RecipeList from './components/side/RecipeList';
import Users from './components/users';
export var AdminPage = function (props) {
    var t = useTranslations().t;
    var user = props.user;
    var groups = user.groups;
    var isAdminUser = groups.includes('ClinicPortalAdmin');
    var isClinicUserAdmin = groups.includes('ClinicUserAdmin');
    return (React.createElement(StyledRoot, tslib_1.__assign({}, props),
        React.createElement(PageContainer, null, !isAdminUser ? (React.createElement(Unauthorised, { url: "/", text: "Admin.NoAccessButtonText", container: false })) : (React.createElement(React.Fragment, null,
            React.createElement(Nav, { user: user }),
            React.createElement(Main, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/admin" },
                        React.createElement(Redirect, { to: "/admin/appointments" })),
                    React.createElement(Route, { exact: true, path: "/admin/appointments/:id" },
                        React.createElement(AppointmentType, null)),
                    React.createElement(Route, { exact: true, path: "/admin/appointments" },
                        React.createElement(AppointmentTypeList, null)),
                    React.createElement(Route, { exact: true, path: "/admin/message-templates/new", 
                        /* @ts-ignore */
                        redirectToOnFailure: "/admin" },
                        React.createElement(MessageTemplate, null)),
                    React.createElement(Route, { exact: true, path: "/admin/message-templates/:id", 
                        /* @ts-ignore */
                        redirectToOnFailure: "/admin" },
                        React.createElement(MessageTemplate, null)),
                    React.createElement(Route, { exact: true, path: "/admin/message-templates" },
                        React.createElement(MessageTemplatesList, null)),
                    React.createElement(Route, { exact: true, path: "/admin/side/recipes" },
                        React.createElement(RecipeList, null)),
                    React.createElement(Route, { exact: true, path: "/admin/side/recipes/:id" },
                        React.createElement(Recipe, null)),
                    isClinicUserAdmin && (React.createElement(FeatureRoute, { exact: true, path: "/admin/users", feature: "portalAdminOptions.users", redirectToOnFailure: "/admin" },
                        React.createElement(Users, null))),
                    React.createElement(Route, { exact: true, path: "*" },
                        React.createElement(Redirect, { to: "/admin" })))))))));
};
