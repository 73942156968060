import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Observer, of } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import * as fromContent from '../../content/reducers';
import * as fromRoot from '../../reducers';
import { AwsS3PresignedUrlWriteResponse } from '../models/aws-write-params';
import { CognitoWrapperService } from './congito.wrapper.service';

interface IRead {
  url: string;
  path?: string;
}

export type FolderType = 'content' | 'profilephoto';

export interface IFileUpload {
  fetchAccepts: (type: string) => string[];
  readFileFromBucket: ({ url }: { url: string }) => Promise<any>;
  readProfilePhotoFromBucket: ({ url }: { url: string }) => Promise<any>;
  writeFileToBucket: (
    folderType: FolderType,
    documentName: string,
    file: File,
    isPublic: boolean,
    patientId?: number
  ) => Observable<any>;
}

@Injectable()
export class FileUpload implements IFileUpload {
  private status: number;

  private _accepts = {
    image: '.jpg, .jpeg, .png, image/jpeg, image/png',
    file: `
      .mp4, .mov, .avi,
      .jpg, .jpeg, .png, image/jpeg, image/png,
      .pdf, .txt, .doc, .docx, application/pdf,
      text/plain, application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document
    `,
    video: '.mp4, .mov, .webm, video/mp4, video/quicktime, video/webm'
  };

  public apiEndpoint$: Observable<string> = of(
    environment.api.content.endpoint
  );

  public writeObserver: Observer<any>;

  // from s3 (content) store slice
  public bucketRoot$: Observable<string>;
  public idPoolId$: Observable<string>;
  public bucketRegion$: Observable<string>;

  // from auth store slice
  public clinicId$: Observable<string>;
  public userId$: Observable<string>;

  private _s3ProxyEndpoint$ = of(environment.api.s3Proxy.endpoint);

  constructor(
    private _http: HttpClient,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler,
    private _cognito: CognitoWrapperService
  ) {
    // s3 (content) store slice set up
    this.bucketRoot$ = this._store.pipe(select(fromContent.getRootAlbumBucket));
    this.idPoolId$ = this._store.pipe(select(fromContent.getIdentityPoolId));
    this.bucketRegion$ = this._store.pipe(select(fromContent.getBucketRegion));
    // auth store slice set up
    this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
    this.userId$ = this._store.pipe(select(fromAuth.getPublicKey));
  }

  public fetchAccepts(type: string): string[] {
    return this._accepts[type];
  }

  public readFileFromBucket({ url }: { url: string }): Promise<any> {
    return this.readFileFromBucketWithPath({
      url,
      path: 'clinic/read'
    });
  }

  public readProfilePhotoFromBucket({ url }: { url: string }): Promise<any> {
    // The signed url returned by this endpoint is cached
    return this.readFileFromBucketWithPath({
      url,
      path: 'clinic/profilephoto/read'
    });
  }

  private readFileFromBucketWithPath({ url, path }: IRead): Promise<any> {
    return new Promise((res, rej) => {
      this._createPresignedReadContentUrl({
        url,
        path
      })
        .then((response) => {
          res(response);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public writeFileToBucket(
    folderType: FolderType,
    documentName: string,
    file: File,
    isPublic: boolean,
    patientId?: number
  ): Observable<any> {
    return new Observable((observer) => {
      this.writeObserver = observer;
      this._createPresignedWriteContentUrl({
        folderType,
        documentName,
        contentType: file.type,
        isPublic,
        patientId
      })
        .then((res) => {
          this._uploadToBucket(res, file, isPublic).subscribe(
            (data) => {
              data.body = { Location: res.url.split('?')[0] };
              this.writeObserver.next(data);
            },
            (err) => {
              this.writeObserver.error(new Error(`Failed to upload file`));
            },
            () => {
              this.writeObserver.complete();
            }
          );
        })
        .catch((err) => {
          this.writeObserver.error(new Error(`Failed to upload file`));
        });
    });
  }

  private _uploadToBucket(
    s3Params: AwsS3PresignedUrlWriteResponse,
    file: File,
    isPublic: boolean
  ) {
    const headers = { 'content-type': file.type };
    if (isPublic) {
      headers['x-amz-acl'] = 'public-read';
    }
    const req = new HttpRequest('PUT', s3Params.url, file, {
      headers: new HttpHeaders(headers)
    });
    return this._http.request(req).pipe(
      map((event) => this._getEventMessage(event, file)),
      last(),
      map((response) => response)
    );
  }

  /**
   * From Angular docs: https://angular.io/guide/http (Listening to progress events)
   */
  private _getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          error: null,
          progress: 0,
          complete: false,
          response: null,
          body: null
        };
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return {
          status: null,
          error: null,
          progress: percentDone,
          complete: false,
          body: null
        };
      case HttpEventType.ResponseHeader:
        this.status = event.status;
        return {
          status: event.status,
          error: null,
          progress: 100,
          complete: false,
          body: null
        };
      case HttpEventType.DownloadProgress:
        return {
          status: this.status,
          error: null,
          progress: 100,
          complete: false,
          body: null
        };
      case HttpEventType.Response:
        return {
          status: event.status,
          error: null,
          progress: 100,
          complete: true,
          body: event.body
        };
      case HttpEventType.User:
        return {
          status: 500,
          error: new Error(`Unexpected Response`),
          progress: 100,
          complete: true,
          body: null
        };
      default:
        return event;
    }
  }

  private _createPresignedReadContentUrl({ url, path }: IRead): Promise<any> {
    if (!path) {
      path = 'clinic/read';
    }

    return new Promise((res, rej) => {
      this._callS3Proxy()
        .pipe(
          switchMap(([endpoint, options]) => {
            const body = {
              url
            };
            return this._http.post<AwsS3PresignedUrlWriteResponse>(
              `${endpoint}${path}`,
              body,
              options
            );
          })
        )
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            rej('Error authenticating to use AWS');
          }
        );
    });
  }

  private _createPresignedWriteContentUrl({
    folderType,
    documentName,
    contentType,
    isPublic,
    patientId
  }): Promise<any> {
    let folderPath: string;
    let pid: number | null;

    switch (folderType) {
      case 'content':
        folderPath = 'clinic';
        pid = null;
        break;
      case 'profilephoto':
        folderPath = 'clinic/profilephoto';
        pid = patientId;
        break;
      default:
        throw new Error('Unsupported folder type');
    }

    return new Promise((res, rej) => {
      this._callS3Proxy()
        .pipe(
          switchMap(([endpoint, options]) => {
            const body = {
              isPublic,
              filename: documentName,
              contentType,
              patientId: pid
            };
            return this._http.post<AwsS3PresignedUrlWriteResponse>(
              `${endpoint}${folderPath}/write`,
              body,
              options
            );
          })
        )
        .subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            this._error.handleError({ originalError: err });
            rej('Error authenticating to use AWS');
          }
        );
    });
  }

  private _callS3Proxy(args: Observable<any>[] = []): Observable<any> {
    return combineLatest(
      this.clinicId$,
      this._s3ProxyEndpoint$,
      this._cognito.getAuthSession(),
      ...args
    ).pipe(
      switchMap(([clinicId, endpoint, cognitoUserSession, ...rest]) => {
        const defaultHttpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: cognitoUserSession.getIdToken().jwtToken,
            'x-salve-clinic-token': clinicId
          })
        };
        return of([endpoint, defaultHttpOptions, ...rest]);
      })
    );
  }
}
