import { ConversationFolder } from '@react/lib/api/types';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import { useDispatch } from '@react/lib/store';
import { Conversation, Message } from '@react/types';
import copy from 'copy-to-clipboard';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { environment } from 'src/environments/environment';
import { Action, DEFAULT_TOAST_OPTIONS, StatusIdMap } from '../constants';
import { ActionMenuItem, ConversationActionMenuItem } from '../types';
import { useConversationUpdateMutation } from './useConversationUpdateMutation';
import { useMessageDelete } from './useMessageDelete';
import { useMessageMutation } from './useMessageMutation';
import { useMessagesToString } from './useMessagesToString';

const isConversationUnassigned = (conversation: Conversation) =>
  conversation.assignedclinicuserid === null;

export const useGetConversationActions = (type: 'conversation' | 'chat') => {
  const { t } = useTranslations();
  const features: { [key: string]: any } = useFeatures();
  const featureAssignConversation = checkFeature(
    features,
    'messagingOptions.assignConversation',
  );
  return (
    conversation: Conversation,
    folders: ConversationFolder[],
  ): ConversationActionMenuItem[] => {
    const action = {
      CONVERSATION_MARK_UNREAD: {
        actionId: Action.CONVERSATION_MARK_UNREAD,
        name: t.Messages.MarkUnread,
      },
      CONVERSATION_MARK_RESOLVED: {
        actionId: Action.CONVERSATION_MARK_RESOLVED,
        name: t.Messages.MarkResolved,
      },
      CONVERSATION_MARK_UNRESOLVED: {
        actionId: Action.CONVERSATION_MARK_UNRESOLVED,
        name: t.Messages.MarkUnresolved,
      },
      CONVERSATION_ASSIGN: {
        actionId: Action.CONVERSATION_ASSIGN,
        name: t.Messages.Assign,
        conversation,
      },
      CONVERSATION_UNASSIGN: {
        actionId: Action.CONVERSATION_UNASSIGN,
        name: t.Messages.Assign,
        conversation,
        unassign: true,
      },
      CONVERSATION_MOVE_TO: {
        actionId: Action.CONVERSATION_MOVE_TO,
        name: t.Messages.Move,
        children: folders
          .filter((folder) => folder.id !== conversation.messagetypeid)
          .map((folder) => ({
            actionId: Action.CONVERSATION_MOVE_TO,
            ...folder,
          })),
      },
      CONVERSATION_COPY_HISTORY: {
        actionId: Action.CONVERSATION_COPY_HISTORY,
        name: t.Messages.CopyMessageHistory,
      },
    };

    const actions = [];

    if (conversation.clinicread) {
      actions.push(action.CONVERSATION_MARK_UNREAD);
    }
    actions.push(
      conversation.resolved
        ? action.CONVERSATION_MARK_UNRESOLVED
        : action.CONVERSATION_MARK_RESOLVED,
    );
    if (featureAssignConversation && isConversationUnassigned(conversation)) {
      actions.push(action.CONVERSATION_ASSIGN);
    }
    if (featureAssignConversation && !isConversationUnassigned(conversation)) {
      actions.push(action.CONVERSATION_UNASSIGN);
    }
    actions.push(action.CONVERSATION_MOVE_TO);
    if (type === 'chat') {
      actions.push(action.CONVERSATION_COPY_HISTORY);
    }

    return actions;
  };
};

export const useGetConversationActionHandler = () => {
  const messagesToString = useMessagesToString();
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const { mutateAsync: updateConversationAsync } =
    useConversationUpdateMutation();
  const { mutate: deleteMessage } = useMessageDelete();
  const { mutate: updateMessage } = useMessageMutation();

  return async (
    action: ActionMenuItem,
    conversation: Conversation,
    messages?: Message[],
  ) => {
    switch (action.actionId) {
      case Action.CONVERSATION_MARK_UNREAD:
        await updateMessage({
          ...conversation,
          id: conversation.messageitemid,
          read: false,
        });
        break;
      case Action.CONVERSATION_MARK_UNRESOLVED:
        await updateConversationAsync({
          ...conversation,
          messagestatusid: null,
        })
          .then(() => {
            dispatch(
              new DisplayToastAction({
                ...DEFAULT_TOAST_OPTIONS,
                title: t.MessageToast.MessageUnresolvedTitle,
                message: t.MessageToast.MessageUnresolvedBody,
                type: 'success',
              }),
            );
          })
          .catch(() => {
            dispatch(
              new DisplayToastAction({
                ...DEFAULT_TOAST_OPTIONS,
                title: t.MessageToast.ErrorEncountered,
                message: t.MessageToast.ErrorUnresolved,
                type: 'error',
              }),
            );
          });
        break;
      case Action.CONVERSATION_MARK_RESOLVED:
        await updateConversationAsync({
          ...conversation,
          messagestatusid: StatusIdMap.RESOLVED,
        })
          .then(() => {
            dispatch(
              new DisplayToastAction({
                ...DEFAULT_TOAST_OPTIONS,
                title: t.MessageToast.MessageResolvedTitle,
                message: t.MessageToast.MessageResolvedBody,
                type: 'success',
              }),
            );
          })
          .catch(() => {
            dispatch(
              new DisplayToastAction({
                ...DEFAULT_TOAST_OPTIONS,
                title: t.MessageToast.ErrorEncountered,
                message: t.MessageToast.ErrorResolved,
                type: 'error',
              }),
            );
          });
        break;
      case Action.CONVERSATION_MOVE_TO:
        if (action.id) {
          await updateConversationAsync({
            ...conversation,
            messagetypeid: action.id,
          })
            .then(() => {
              dispatch(
                new DisplayToastAction({
                  ...DEFAULT_TOAST_OPTIONS,
                  title: t.MessageToast.MessageMovedTitle,
                  message: t.MessageToast.MessageMovedBody(
                    conversation.messagetype,
                    action.name,
                  ),
                  type: 'success',
                }),
              );
            })
            .catch(() => {
              dispatch(
                new DisplayToastAction({
                  ...DEFAULT_TOAST_OPTIONS,
                  title: t.MessageToast.ErrorEncountered,
                  message: t.MessageToast.ErrorChanging,
                  type: 'error',
                }),
              );
            });
        }
        break;
      case Action.MESSAGE_DELETE:
        if (action.id) {
          deleteMessage({
            messageId: action.id,
            conversationId: conversation.messageid,
          });
        }
        break;
      case Action.CONVERSATION_COPY_HISTORY:
        if (messages) {
          if (copy(messagesToString(conversation, messages))) {
            dispatch(
              new DisplayToastAction({
                ...DEFAULT_TOAST_OPTIONS,
                message: t.Messages.HistoryCopied(
                  conversation.patientfirstname,
                ),
                type: 'success',
              }),
            );
          } else {
            dispatch(
              new DisplayToastAction({
                ...DEFAULT_TOAST_OPTIONS,
                message: t.Messages.CopyError,
                type: 'error',
              }),
            );
          }
        }
        break;
      default:
        throw new Error('Invalid action');
    }
  };
};
