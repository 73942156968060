import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GetContentEntries } from '../actions/content.actions';
import {
  DeleteAllContentLinks,
  DeleteAllContentLinksError,
  DeleteAllContentLinksSuccess,
  DeleteContentLink,
  DeleteContentLinkError,
  DeleteContentLinkSuccess,
  DeleteSomeContentLinks,
  DeleteSomeContentLinksError,
  DeleteSomeContentLinksSuccess,
  GetAvailableToLinkContent,
  GetAvailableToLinkContentError,
  GetAvailableToLinkContentSuccess,
  LinkContent,
  LinkContentActionTypes,
  LinkContentError,
  LinkContentSuccess,
  LoadChildren,
  LoadChildrenError,
  LoadChildrenSuccess,
} from '../actions/link-content.actions';
import {
  ChildInfo,
  DeleteWithChildIdResponse,
  DeleteWithParentIdResponse,
} from '../models/content-entry';
import { ContentEntryNames, LinkedContent } from '../models/linked-content';
import { LinkContentService } from '../services/link-content.service';
import { DeleteWithChildrenIdArrayResponse } from './../models/content-entry';

@Injectable()
export class LinkContentEffects {
  @Effect()
  linkContent$ = this.actions$.pipe(
    ofType<LinkContent>(LinkContentActionTypes.LinkContent),
    switchMap((action) => {
      const { contentToLink, contentToShow } = action.payload;
      return this.linkContentService
        .linkContent(contentToShow, contentToLink)
        .pipe(
          // TODO: Fix types
          // @ts-ignore
          mergeMap((res: LinkedContent[]) => {
            return [new LinkContentSuccess(res), new GetContentEntries()];
          }),
          catchError((err) => {
            console.error(err);
            return of(new LinkContentError(err));
          }),
        );
    }),
  );

  @Effect()
  fetchAvailableToLink$ = this.actions$.pipe(
    ofType<GetAvailableToLinkContent>(
      LinkContentActionTypes.GetAvailableToLinkContent,
    ),
    switchMap((action) => {
      const { contentCategory } = action.payload;
      return this.linkContentService
        .fetchAvailableToLinkContent(contentCategory)
        .pipe(
          // TODO: Fix types
          // @ts-ignore
          map((res: ContentEntryNames[]) => {
            return new GetAvailableToLinkContentSuccess(res);
          }),
          catchError((err) => {
            console.error(err);
            return of(new GetAvailableToLinkContentError(err));
          }),
        );
    }),
  );

  @Effect()
  deleteContentLink$ = this.actions$.pipe(
    ofType<DeleteContentLink>(LinkContentActionTypes.DeleteContentLink),
    switchMap((action) => {
      return this.linkContentService.deleteContentLink(action.payload).pipe(
        mergeMap((res: DeleteWithChildIdResponse) => {
          return [new DeleteContentLinkSuccess(res), new GetContentEntries()];
        }),
        catchError((err) => {
          console.error(err);
          return of(new DeleteContentLinkError(err));
        }),
      );
    }),
  );

  @Effect()
  deleteSomeContentLinks$ = this.actions$.pipe(
    ofType<DeleteSomeContentLinks>(
      LinkContentActionTypes.DeleteSomeContentLinks,
    ),
    switchMap((action) => {
      return this.linkContentService
        .deleteSomeContentLinks(action.payload)
        .pipe(
          mergeMap((res: DeleteWithChildrenIdArrayResponse) => {
            return [
              new DeleteSomeContentLinksSuccess(res),
              new GetContentEntries(),
            ];
          }),
          catchError((err) => {
            console.error(err);
            return of(new DeleteSomeContentLinksError(err));
          }),
        );
    }),
  );

  @Effect()
  deleteAllContentLinks$ = this.actions$.pipe(
    ofType<DeleteAllContentLinks>(LinkContentActionTypes.DeleteAllContentLinks),
    switchMap((action) => {
      return this.linkContentService.deleteAllContentLinks(action.payload).pipe(
        mergeMap((res: DeleteWithParentIdResponse) => {
          return [
            new DeleteAllContentLinksSuccess(res),
            new GetContentEntries(),
          ];
        }),
        catchError((err) => {
          console.error(err);
          return of(new DeleteAllContentLinksError(err));
        }),
      );
    }),
  );

  @Effect()
  fetchChildren$ = this.actions$.pipe(
    ofType<LoadChildren>(LinkContentActionTypes.LoadChildren),
    switchMap((action) => {
      const { headerId } = action.payload;
      return this.linkContentService.loadChildren(headerId).pipe(
        map((res: ChildInfo[]) => {
          return new LoadChildrenSuccess(res);
        }),
        catchError((err) => {
          console.error(err);
          return of(new LoadChildrenError(err));
        }),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private linkContentService: LinkContentService,
    private _error: ErrorHandler,
  ) {}
}
