import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { ConfirmActionComponent } from './../../../core/components/confirm-action/confirm-action.component';
import {
  ClearFilter,
  ContentPackActionTypes,
  DeleteContentPack,
  DeleteContentPackSuccess,
  FilterContent,
  GetContentPackDetail,
  RemoveFromContentPack,
} from './../../actions/packs.actions';
import { EditFolderNameComponent } from './../../components/edit-folder-name/edit-folder-name.component';
import {
  AlterPackContentInterface,
  ContentPack,
  ContentPackDetail,
} from './../../models/content-packs';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromContent from '../../reducers';

import { Actions, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { OpenDirectorySection } from '../../actions/content.actions';
import { GetContentPacks } from '../../actions/packs.actions';
import { CreateCategoryComponent } from '../../components/create-category/create-category.component';
import { CreatePackComponent } from '../../components/create-pack/create-pack.component';
import { EditPackNameComponent } from '../../components/edit-pack-name/edit-pack-name.component';
import { Category } from '../../models/category';
import { ContentTemplate } from '../../models/content-template';

@Component({
  selector: 'portal-content-pack-detail',
  templateUrl: './content-pack-detail.container.html',
  styleUrls: ['./content-pack-detail.container.scss'],
})
export class ContentPackDetailContainer implements OnInit, OnDestroy {
  // Observables for text content
  public sectionText$: Observable<any>;
  public categoryText$: Observable<any>;
  public directoryText$: Observable<any>;

  // capture text in component for dialogs
  public sectionText: any;
  public categoryText: any;

  // Observables used in content-directory
  public categories$: Observable<Category[]> = of([]);
  public templates$: Observable<ContentTemplate[]> = of([]);
  public contentPacks$: Observable<ContentPack[]> = of([]);
  public currentPack$: Observable<ContentPack>;
  public contentPackId$: Observable<number>;
  public routeType$: Observable<string> = of('packs');

  public otherPacks$: Observable<ContentPack[]> = of([]);

  public currentPackId: number;

  // Observables for content list - i.e. the content present in pack
  public contentList$: Observable<ContentPackDetail[]> = of([]);
  public contentToDisplay$: Observable<ContentPackDetail[]> = of([]);

  // Is Loading content Pack Detail
  public loadingPackDetail$: Observable<boolean> = of(false);

  // Observables to be used in search bar
  public isFilterActive$: Observable<boolean>;
  public currentFilter$: Observable<string>;
  public activePack$: Observable<string>;
  public openDirectory$: Observable<string>;

  // Subscription to allow easy clean up
  private _subs = new Subscription();

  // Content editor flag
  public contentEditorEnabled$: Observable<boolean>;
  public contentEditorEnabled: boolean;

  // Global Portal flag
  public isGlobalPortal$: Observable<boolean>;
  public isGlobalPortal: boolean;

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private updates$: Actions,
  ) {}

  ngOnInit() {
    // Global Portal Observable
    this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));

    // Content editor group Observables
    this.contentEditorEnabled$ = this._store.pipe(
      select(fromContent.getContentEditorEnabled),
    );

    this._store.dispatch(new GetContentPacks());

    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewContentList')),
    );
    this.directoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentDirectory')),
    );
    this.categoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateCategory')),
    );

    this._subs.add(
      this.isGlobalPortal$.subscribe((value) => {
        this.isGlobalPortal = value;
      }),
    );

    // Content editor group, wrapped in a feature flag
    this._subs.add(
      this.contentEditorEnabled$.subscribe(
        (t) => (this.contentEditorEnabled = t),
      ),
    );

    // Capture text for use in Dialogs
    this._subs.add(this.sectionText$.subscribe((t) => (this.sectionText = t)));
    this._subs.add(
      this.categoryText$.subscribe((t) => (this.categoryText = t)),
    );

    // is pack detail loading
    this.loadingPackDetail$ = this._store.pipe(
      select(fromContent.isLoadingPackDetail),
    );

    // Observables for searchbar
    this.isFilterActive$ = this._store.pipe(
      select(fromContent.isPacksListFilterActive),
    );
    this.currentFilter$ = this._store.pipe(
      select(fromContent.getPacksListFilter),
    );

    // content-directoru observables
    this.categories$ = this._store.pipe(
      select(fromContent.getGeneralCategories),
    );
    this.templates$ = this._store.pipe(select(fromContent.getTemplates));
    this.contentPacks$ = this._store.pipe(select(fromContent.getPacks));
    this.contentPackId$ = this._route.params.pipe(
      map((params: Params) => +params.id),
    );
    this.currentPack$ = combineLatest(
      this.contentPacks$,
      this.contentPackId$,
    ).pipe(map(([packs, packId]) => packs.find((p) => p.id === packId)));
    this.activePack$ = this.currentPack$.pipe(
      map((pack) => {
        return (pack && pack.packName) || '';
      }),
    );

    this._subs.add(
      combineLatest(this.loadingPackDetail$, this.contentPackId$)
        .pipe(filter(([loading]) => !loading))
        .subscribe(([_, packId]) => {
          if (!this.currentPackId || this.currentPackId !== packId) {
            this.currentPackId = packId;
            this._store.dispatch(new GetContentPackDetail(packId));
          }
        }),
    );

    this.otherPacks$ = combineLatest(
      this.contentPacks$,
      this.contentPackId$,
    ).pipe(map(([packs, id]) => packs.filter((c) => c.id !== id)));

    // pack list observables
    this.contentList$ = this.contentPackId$.pipe(
      switchMap((id) =>
        this._store.pipe(select(fromContent.getActivePackDetails(id))),
      ),
    );
    this.contentToDisplay$ = combineLatest(
      this.contentList$,
      this.isFilterActive$,
      this.currentFilter$,
    ).pipe(
      map(([content, filterActive, currentFilter]) =>
        filterActive
          ? content.filter((c) =>
              c.contentName.toLowerCase().includes(currentFilter),
            )
          : content || [],
      ),
    );

    // Which section of directory is open?
    this.openDirectory$ = this._store.pipe(
      select(fromContent.activeDirectorySection),
    );

    // watch for successful deletion of a folder
    // and navigate to first pack
    this._subs.add(
      this.updates$
        .pipe(
          ofType<DeleteContentPackSuccess>(
            ContentPackActionTypes.DeleteContentPackSuccess,
          ),
          switchMap((action) =>
            this.contentPacks$.pipe(
              // TODO: Fix types
              // @ts-ignore
              filter((p: ContentPack) => p.id !== action.payload.packId),
            ),
          ),
        )
        .subscribe((packs: ContentPack[]) => {
          const id = packs.length > 0 ? packs[0].id : 0;
          this._navigationService.navigate(['content', 'pack', id]);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public goToContent(packDetail: ContentPackDetail): void {
    this._navigationService.navigate([
      'content',
      'doc',
      'edit',
      packDetail.contentCategoryId,
      packDetail.contentEntryHeaderId,
    ]);
  }

  public navigateTo(url: string): void {
    this._navigationService.navigateByUrl(url);
  }

  public updateFilter(filterString: string) {
    if (!filterString || filterString === '') {
      this._store.dispatch(new ClearFilter());
    } else {
      this._store.dispatch(new FilterContent(filterString));
    }
  }

  public openDirectorySection(section: string) {
    this._store.dispatch(new OpenDirectorySection(section));
  }

  public createNewFolder() {
    this.dialog.open(CreateCategoryComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public changeFolderName(category: Category) {
    this.dialog.open(EditFolderNameComponent, {
      data: {
        category,
        text: this.sectionText,
      },
      width: '550px',
    });
  }

  public createNewPack() {
    this.dialog.open(CreatePackComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public editPackName(pack: ContentPack) {
    this.dialog.open(EditPackNameComponent, {
      data: {
        pack,
        text: this.sectionText,
      },
      width: '550px',
    });
  }

  public deletePack(packId: number) {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.sectionText.DeletePackConfirmation,
        text: {
          Cancel: this.sectionText.Cancel,
          Confirm: this.sectionText.Delete,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this._store.dispatch(new DeleteContentPack({ packId }));
      }
    });
  }

  public removeFromPack(itemsToRemove: AlterPackContentInterface) {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.sectionText.RemovePackItemsConfirmation,
        text: {
          Cancel: this.sectionText.Cancel,
          Confirm: this.sectionText.Remove,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this._store.dispatch(new RemoveFromContentPack(itemsToRemove));
      }
    });
  }
}
