import styled from '@emotion/styled';
import {
  Chip,
  Paper,
  Table,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SearchBar from '@react/components/SearchBar';

export const StyledTableContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTable = styled(Table)`
  table-layout: fixed;
`;

export const StyledTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: #757575;
    vertical-align: top;

    &.idcol {
      width: 40px;
    }

    &.contentcol {
      width: 33%;
    }

    &.fixedcol {
      width: 100px;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f0f0f7;
    cursor: pointer;
  }

  td {
    vertical-align: top;
  }
`;

export const StyledTableFooter = styled(TableFooter)`
  td {
    border-bottom: 0;
  }
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;

export const StyledSearchBar = styled(SearchBar)`
  position: sticky;
  top: 0;
  z-index: 30;
`;

export const EmptyContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  height: 400px;
`;

export const StyledActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;
