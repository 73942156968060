import * as tslib_1 from "tslib";
import { IconButton, InputAdornment } from '@material-ui/core';
import { Tune as IconTune } from '@material-ui/icons';
import { Button, Empty, Loading, MailOpenIcon, SearchBar, } from '@react/components';
import { useRootConfig } from '@react/lib/context/rootConfig';
import { useErrorToast, useQuery } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import qs from 'qs-lite';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ActionContainer, Chat, Conversations, DiscardConversationPrompt, FilterChips, FilterMenu, Folders, LeftPanel, Main, MiddlePanel, NewMessageModal, PageContainer, RightPanel, StyledRoot, } from './components';
import { useConversationCache, useConversationPartialUpdateMutation, useFetchAllClinicUsers, useFetchConversationById, useFetchMe, useFoldersAssignedQuery, useFoldersListQuery, useRealTimeMessaging, } from './hooks';
import { filtersApplied } from './lib';
function getStateFromQuery() {
    var query = useQuery();
    return useMemo(function () { return (tslib_1.__assign({ folderIds: query.folderIds
            ? query.folderIds.split(',').map(function (id) { return Number(id); })
            : [], isStarred: query.isStarred != null ? JSON.parse(query.isStarred) : true, isResolved: query.isResolved != null ? JSON.parse(query.isResolved) : undefined, staffIds: query.staffIds
            ? query.staffIds.split(',').map(function (id) { return Number(id); })
            : undefined }, (query.conversationId != null && {
        conversationId: Number(query.conversationId),
    }), { assignedIds: query.assignedIds
            ? query.assignedIds.split(',').map(function (id) { return Number(id); })
            : undefined, isUnassigned: query.isUnassigned != null ? JSON.parse(query.isUnassigned) : undefined })); }, [query]);
}
export var ConversationPageRaw = function (_a) {
    var conversationCache = _a.conversationCache, fileUploadService = _a.fileUploadService, staffProfiles = _a.staffProfiles;
    var t = useTranslations().t;
    var history = useHistory();
    var user = useRootConfig().user;
    var filterPopoverAnchor = React.useRef(null);
    var _b = useConversationPartialUpdateMutation(), updateConversationAsync = _b.mutateAsync, isErrorAssigning = _b.isError;
    useErrorToast(isErrorAssigning, {
        title: t.MessageToast.ErrorAssigningMessageTitle,
        message: t.MessageToast.MessageStarredBodyError,
    });
    var state = getStateFromQuery();
    var _c = useState(false), filterPopoverOpen = _c[0], setFilterPopoverOpen = _c[1];
    var _d = useState(false), newMessageModalOpen = _d[0], setNewMessageModalOpen = _d[1];
    var _e = useState(false), discardConversationPromptOpen = _e[0], setDiscardConversationPromptOpen = _e[1];
    var _f = useState(), searchQuery = _f[0], setSearchQuery = _f[1];
    var conversationResult = useFetchConversationById(state.conversationId);
    var selectedConversation = conversationResult.data;
    var _g = useFetchAllClinicUsers(), clinicUsers = _g.users, isLoadingClinicUsers = _g.isLoading;
    var meResult = useFetchMe();
    var me = meResult.data;
    var foldersResult = useFoldersListQuery();
    var folders = foldersResult.data && foldersResult.data.data;
    var _h = useConversationCache(conversationCache), getInitialConversationState = _h.getInitialConversationState, setConversationState = _h.setConversationState;
    var assignedFolderResult = useFoldersAssignedQuery();
    var assignedFolderCount = assignedFolderResult.data && assignedFolderResult.data.data;
    function setStateInURL(newState) {
        var pathname = newState.conversationId
            ? "/messages/" + newState.conversationId
            : "/messages";
        if (!isEqual(newState, state)) {
            history.push({
                pathname: pathname,
                search: "" + qs.stringify(newState),
            });
        }
    }
    // Default to unresolved on first load
    function setInitialState() {
        setStateInURL(tslib_1.__assign({}, state, { isResolved: false }));
    }
    useEffect(setInitialState, []);
    var _j = useRealTimeMessaging(user), getTypingConversationMessage = _j.getTypingConversationMessage, sendTypingEvent = _j.sendTypingEvent;
    var handleChatTyping = useCallback(function (content) {
        if (selectedConversation) {
            sendTypingEvent({
                content: content,
                messageId: selectedConversation.messageid,
                user: user.FirstName + " " + user.LastName,
            });
        }
    }, [selectedConversation]);
    var isError = conversationResult.isError || foldersResult.isError;
    useErrorToast(isError, {
        title: t.MessageToast.ErrorEncountered,
        message: t.MessageToast.MessageStarredBodyError,
    });
    if (foldersResult.isLoading) {
        return React.createElement(Loading, { fullHeight: true });
    }
    function handleConversationClick(conversation) {
        setStateInURL(tslib_1.__assign({}, state, { conversationId: conversation.messageid }));
    }
    function updateSearchTerm(q) {
        setSearchQuery(q);
    }
    function handleFolderClick(folderId, isResolved, assignedId) {
        setStateInURL(tslib_1.__assign({}, omit(state, ['conversationId', 'assignedIds', 'isUnassigned']), { folderIds: folderId ? [folderId] : [], isResolved: isResolved, isStarred: true, assignedIds: assignedId ? [assignedId] : undefined }));
    }
    function handleTabChange(tabIndex) {
        setStateInURL(tslib_1.__assign({}, omit(state, ['conversationId']), { isStarred: tabIndex === 0 }));
    }
    function onFilterButtonClick() {
        setFilterPopoverOpen(!filterPopoverOpen);
    }
    function onFilterPopoverClose() {
        setFilterPopoverOpen(false);
    }
    function onFiltersUpdate(filterState) {
        var activeFolders = Array.from(filterState.folder).map(function (folderId) {
            return Number(folderId);
        });
        var activeStaff = Array.from(filterState.staff || []).map(function (clinicUserId) { return Number(clinicUserId); });
        var activeClinicUsers = Array.from(filterState.clinicUsers || [])
            .filter(function (clinicUserId) { return clinicUserId !== 'unassigned'; })
            .map(function (clinicUserId) { return Number(clinicUserId); });
        var isUnassigned = Array.from(filterState.clinicUsers || [])
            .filter(function (clinicUserId) { return clinicUserId === 'unassigned'; })
            .reduce(function (_acc, next) { return (next ? true : undefined); }, undefined);
        var isResolved;
        if (filterState.status.size === 2) {
            isResolved = undefined;
        }
        else if (filterState.status.has('resolved')) {
            isResolved = true;
        }
        else if (filterState.status.has('unresolved')) {
            isResolved = false;
        }
        else {
            isResolved = undefined;
        }
        setStateInURL(tslib_1.__assign({}, state, { folderIds: activeFolders.length > 0 ? activeFolders : [], staffIds: activeStaff.length > 0 ? activeStaff : undefined, assignedIds: activeClinicUsers.length > 0 ? activeClinicUsers : undefined, isUnassigned: isUnassigned,
            isResolved: isResolved }));
    }
    function onNewMessageClick() {
        setNewMessageModalOpen(true);
    }
    var onNewMessageModalClose = function (_a) {
        var hasValues = _a.hasValues;
        if (hasValues) {
            setDiscardConversationPromptOpen(true);
        }
        else {
            setNewMessageModalOpen(false);
        }
    };
    var discardDraftConversation = function () {
        setDiscardConversationPromptOpen(false);
        setNewMessageModalOpen(false);
    };
    var closeDiscardConversationPrompt = function () {
        setDiscardConversationPromptOpen(false);
    };
    function onFilterChipsChanged(changedFilters) {
        setStateInURL(tslib_1.__assign({}, state, changedFilters));
    }
    function onClinicUserAssignItemClick(_a) {
        var userId = _a.userId, messageId = _a.messageId;
        var conversation = {
            messageid: messageId,
            assignedclinicuserid: userId,
        };
        updateConversationAsync(conversation);
    }
    var filters = tslib_1.__assign({ q: searchQuery }, state);
    var assignClinicUsers = {
        onClinicUserAssignItemClick: onClinicUserAssignItemClick,
        clinicUsers: clinicUsers,
        isLoadingClinicUsers: isLoadingClinicUsers,
        me: me,
    };
    return (React.createElement(PageContainer, null,
        React.createElement(LeftPanel, null,
            React.createElement(ActionContainer, { className: "action-container" },
                React.createElement(Button, { color: "primary", variant: "contained", fullWidth: true, onClick: onNewMessageClick }, t.Messages.NewMessage)),
            newMessageModalOpen && (React.createElement(NewMessageModal, { open: newMessageModalOpen, onClose: onNewMessageModalClose })),
            React.createElement(DiscardConversationPrompt, { isOpen: discardConversationPromptOpen, onDiscard: discardDraftConversation, onCancel: closeDiscardConversationPrompt }),
            folders && me && (React.createElement("div", { className: "folders-container" },
                React.createElement(Folders, { "data-testid": "conversation-folders", folders: folders, filters: filters, onFolderClick: handleFolderClick, assignedFolderCount: assignedFolderCount, me: me })))),
        React.createElement(Main, null,
            React.createElement(MiddlePanel, null,
                React.createElement(SearchBar, { value: searchQuery, onChange: updateSearchTerm, placeholder: t.Messages.SearchMessages, sticky: true, elevation: 0, endAdornment: React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { "aria-label": t.Messages.FilterButtonAriaLabel, ref: filterPopoverAnchor, onClick: onFilterButtonClick, size: "small", role: "button", color: filterPopoverOpen || filtersApplied(filters)
                                ? 'primary'
                                : 'default' },
                            React.createElement(IconTune, null))) }),
                React.createElement(FilterMenu, { folders: folders, filters: filters, menuAnchor: filterPopoverAnchor.current, open: filterPopoverOpen, onClose: onFilterPopoverClose, onChange: onFiltersUpdate, clinicUsers: clinicUsers }),
                React.createElement(FilterChips, { filters: filters, folders: folders, clinicUsers: clinicUsers, onChange: onFilterChipsChanged }),
                folders && (React.createElement(Conversations, { folders: folders, getTypingConversationMessage: getTypingConversationMessage, selectedTab: state.isStarred ? 0 : 1, onTabChange: handleTabChange, filters: filters, selectedConversationId: state.conversationId, onConversationClick: handleConversationClick, assignClinicUsers: assignClinicUsers }))),
            React.createElement(RightPanel, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/messages" },
                        React.createElement(Empty, { icon: React.createElement(MailOpenIcon, null), className: "message-no-conversation-selected", message: t.Messages.NoConversationSelected })),
                    React.createElement(Route, { path: "/messages/:conversationId" },
                        conversationResult.isLoading && React.createElement(Loading, { fullHeight: true }),
                        selectedConversation && folders && (React.createElement(Chat, { folders: folders, getTypingConversationMessage: getTypingConversationMessage, conversation: selectedConversation, onChange: handleChatTyping, assignClinicUsers: assignClinicUsers, getInitialConversationState: getInitialConversationState, setConversationState: setConversationState, fileUploadService: fileUploadService, staffProfiles: staffProfiles }))))))));
};
export var ConversationPage = function (_a) {
    var conversationCache = _a.conversationCache, fileUploadService = _a.fileUploadService, staffProfiles = _a.staffProfiles, props = tslib_1.__rest(_a, ["conversationCache", "fileUploadService", "staffProfiles"]);
    return (React.createElement(StyledRoot, tslib_1.__assign({}, props),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/messages" },
                React.createElement(ConversationPageRaw, { conversationCache: conversationCache, fileUploadService: fileUploadService, staffProfiles: staffProfiles })),
            React.createElement(Route, { path: "*" },
                React.createElement(Redirect, { to: "/messages" })))));
};
