import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { VideoCallsService } from 'src/app/video-calls/services/video-calls.service';
import { LoadedPatientInformation, LoadPatientInformationError, LoadPatientInformationSuccess, PatientActionTypes } from '../actions/patient.actions';
import { PatientService } from '../services/patient.service';
var PatientEffects = /** @class */ (function () {
    function PatientEffects(actions$, patientService, _error, videoCallsService, _router) {
        var _this = this;
        this.actions$ = actions$;
        this.patientService = patientService;
        this._error = _error;
        this.videoCallsService = videoCallsService;
        this._router = _router;
        this.loadPatientInformation$ = this.actions$.pipe(ofType(PatientActionTypes.LoadPatientInformation), switchMap(function (action) {
            return combineLatest(_this.patientService.getPatientInformation(action.payload), _this.videoCallsService.getVideoCalls(action.payload));
        }));
        this.loadPatientInformationSuccess$ = this.loadPatientInformation$.pipe(switchMap(function (_a) {
            var patientInfo = _a[0], videoCalls = _a[1];
            var patient = patientInfo && patientInfo.Patient;
            if (patient == null) {
                return [new LoadedPatientInformation()];
            }
            return [
                new LoadPatientInformationSuccess({
                    response: tslib_1.__assign({}, patientInfo, { VideoCalls: videoCalls }),
                    patientId: patient.Id
                }),
                new LoadedPatientInformation()
            ];
        }), catchError(function (error) {
            return of(new LoadPatientInformationError(error));
        }));
        this.loadPatientInformationNotFound$ = this.loadPatientInformation$.pipe(tap(function (_a) {
            var patientInfo = _a[0];
            var patient = patientInfo && patientInfo.Patient;
            if (patient == null) {
                return _this._router.navigate(['/patients']);
            }
        }), catchError(function (error) {
            return of(new LoadPatientInformationError(error));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PatientEffects.prototype, "loadPatientInformationSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PatientEffects.prototype, "loadPatientInformationNotFound$", void 0);
    return PatientEffects;
}());
export { PatientEffects };
