import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { IListResponse, IRecipeAction } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { InfiniteData } from 'react-query';
import SIDEStatusChip from '../../SIDEStatusChip';
import { StyledTableHead, StyledTableRow } from './index.styled';

interface IProps {
  data: InfiniteData<IListResponse<IRecipeAction>> | undefined;
}

const MedicationRecipeActions: React.FunctionComponent<IProps> = ({ data }) => {
  const { t } = useTranslations();
  const localeDateTime = useLocaleDateTime();
  return (
    <>
      <StyledTableHead data-testid="TableHead">
        <TableRow>
          <TableCell className="idcol">
            {t.Admin.SIDE.ActionListTableHeaderId}
          </TableCell>
          <TableCell className="contentcol">
            {t.Admin.SIDE.ActionListTableHeaderContent}
          </TableCell>
          <TableCell>{t.Admin.SIDE.ActionListTableHeaderActionType}</TableCell>
          <TableCell>{t.Admin.SIDE.ActionListTableHeaderTiming}</TableCell>
          <TableCell>
            {t.Admin.SIDE.ActionListTableHeaderMedicationStatus}
          </TableCell>
          <TableCell>
            {t.Admin.SIDE.ActionListTableHeaderActionStatus}
          </TableCell>
          <TableCell>{t.Admin.SIDE.ActionListTableHeaderCreatedOn}</TableCell>
          <TableCell>
            {t.Admin.SIDE.ActionListTableHeaderLastUpdatedOn}
          </TableCell>
          <TableCell>
            {t.Admin.SIDE.ActionListTableHeaderLastUpdatedBy}
          </TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {data &&
          data.pages.map((page) =>
            page.data.map((item: IRecipeAction) => (
              <StyledTableRow
                key={item.id}
                data-testid="TableRow"
                role="button"
                tabIndex={0}
              >
                <TableCell>{item.id}</TableCell>
                <TableCell className="truncate" title={item.content}>
                  {item.content}
                </TableCell>
                <TableCell>{item.actiontype}</TableCell>
                <TableCell>
                  {`${item.period} ${
                    t.Admin.SIDE[`${item.periodname}Period`]
                  } ${
                    item.before === true
                      ? t.Admin.SIDE.Before
                      : t.Admin.SIDE.After
                  }`}
                </TableCell>
                <TableCell>
                  {item.sidemedicationstatus
                    ? t.Admin.SIDE[
                        `${item.sidemedicationstatus}MedicationStatus`
                      ]
                    : ''}
                </TableCell>
                <TableCell>
                  <SIDEStatusChip
                    status={item.status}
                    label={t.Admin.SIDE[`${item.status}Status`]}
                  />
                </TableCell>
                <TableCell>
                  {localeDateTime.format(new Date(item.createdon), 'PP, p')}
                </TableCell>
                <TableCell>
                  {localeDateTime.format(
                    new Date(item.lastmodifiedon),
                    'PP, p',
                  )}
                </TableCell>
                <TableCell>{item.lastupdatedby}</TableCell>
              </StyledTableRow>
            )),
          )}
      </TableBody>
    </>
  );
};

export default MedicationRecipeActions;
