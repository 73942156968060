<mat-card fxFlexFill class="rich-or-pdf-container">
  <div fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row">
      <button
        class="back-button"
        mat-fab
        color="primary"
        (click)="goBackToList()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div
      class="options-container"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="100px"
    >
      <div
        class="route-option"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
      >
        <img
          class="medium-icon"
          src="assets/imgs/rich-content.svg"
          alt="Rich Content Icon"
        />
        <div class="option-text">
          <p class="mat-card-option-font small">
            {{ (sectionText$ | async)?.AddRich }}
          </p>
        </div>
        <button
          mat-raised-button
          class="portal-btn btn-primary"
          (click)="goToRichContent()"
        >
          {{ (sectionText$ | async)?.CreateRich }}
        </button>
        <p class="mat-card-option-font small">
          ({{ (sectionText$ | async)?.Recommended }})
        </p>
      </div>
      <div
        class="route-option"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <img
            class="medium-icon"
            src="assets/imgs/pdf.svg"
            alt="Pdf File Icon"
          />
          <img
            class="medium-icon"
            src="assets/imgs/word-doc.svg"
            alt="Word Document File Icon"
          />
        </div>
        <div class="option-text">
          <p class="mat-card-option-font small">
            {{ (sectionText$ | async)?.UploadPdf }}
          </p>
        </div>
        <button
          mat-raised-button
          class="portal-btn btn-primary"
          (click)="goToPdf()"
        >
          {{ (sectionText$ | async)?.UsePdf }}
        </button>
      </div>
    </div>
  </div>
</mat-card>
