import { EventEmitter, } from '@angular/core';
import { TopLevelPatientData } from '../../models/TopLevelPatientData';
var PatientTopLevelComponent = /** @class */ (function () {
    function PatientTopLevelComponent() {
        this.openNewMessage = new EventEmitter();
        this.openNewVideoCall = new EventEmitter();
    }
    return PatientTopLevelComponent;
}());
export { PatientTopLevelComponent };
