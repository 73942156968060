import { sortByKeyAlphabetically } from 'src/app/shared/utils';
import {
  ContentPackActionsUnion,
  ContentPackActionTypes,
} from '../actions/packs.actions';
import { ContentPack } from '../models/content-packs';
import { ContentPackDetail } from './../models/content-packs';

export interface State {
  packs: ContentPack[];
  // loading actions
  creatingPack: boolean;
  editingPack: boolean;
  deletingPack: boolean;
  loadingPacks: boolean;
  loadingPackDetail: boolean;
  addingToPack: boolean;
  removingFromPack: boolean;
  errors: {
    [key: string]: string;
  };
  contentPackDetails: {
    [x: number]: ContentPackDetail[];
  };
  activeListFilter: boolean;
  currentListFilter: string;
}

export const initialState: State = {
  packs: [],
  creatingPack: false,
  editingPack: false,
  deletingPack: false,
  loadingPacks: false,
  loadingPackDetail: false,
  addingToPack: false,
  removingFromPack: false,
  errors: {},
  contentPackDetails: {},
  activeListFilter: false,
  currentListFilter: '',
};

export function reducer(
  state = initialState,
  action: ContentPackActionsUnion,
): State {
  switch (action.type) {
    case ContentPackActionTypes.GetContentPacks: {
      return {
        ...state,
        errors: {},
        loadingPacks: true,
      };
    }
    case ContentPackActionTypes.GetContentPacksSuccess: {
      return {
        ...state,
        loadingPacks: false,
        packs: action.payload.contentPacks,
      };
    }
    case ContentPackActionTypes.GetContentPacksError: {
      return {
        ...state,
        loadingPacks: false,
        errors: {
          loadingPacks: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.GetContentPackDetail: {
      return {
        ...state,
        errors: {},
        loadingPackDetail: true,
      };
    }
    case ContentPackActionTypes.GetContentPackDetailSuccess: {
      const contentPackDetails = {
        ...state.contentPackDetails,
        [action.payload.packId]: action.payload.contentPackDetail,
      };
      return {
        ...state,
        loadingPackDetail: false,
        contentPackDetails,
      };
    }
    case ContentPackActionTypes.GetContentPackDetailError: {
      return {
        ...state,
        loadingPackDetail: false,
        errors: {
          ...state.errors,
          loadingPackDetail: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.CreateContentPack: {
      return {
        ...state,
        creatingPack: true,
        errors: {},
      };
    }
    case ContentPackActionTypes.CreateContentPackSuccess: {
      const packs = [...state.packs]
        .concat(action.payload.newPackDetail)
        .sort(sortByKeyAlphabetically('name'));
      return {
        ...state,
        creatingPack: false,
        packs,
      };
    }
    case ContentPackActionTypes.CreateContentPackError: {
      return {
        ...state,
        creatingPack: false,
        errors: {
          ...state.errors,
          creatingPack: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.EditContentPack: {
      return {
        ...state,
        editingPack: true,
        errors: {},
      };
    }
    case ContentPackActionTypes.EditContentPackSuccess: {
      const { packId, packName, isDefault } = action.payload;
      return {
        ...state,
        editingPack: false,
        packs: state.packs.map((pack) =>
          pack.id === packId
            ? {
                id: packId,
                packName,
                isDefault,
              }
            : pack,
        ),
      };
    }
    case ContentPackActionTypes.EditContentPackError: {
      return {
        ...state,
        editingPack: false,
        errors: {
          ...state.errors,
          editingPack: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.DeleteContentPack: {
      return {
        ...state,
        deletingPack: true,
        errors: {},
      };
    }
    case ContentPackActionTypes.DeleteContentPackSuccess: {
      return {
        ...state,
        deletingPack: false,
        packs: state.packs.filter((pack) => pack.id !== action.payload.packId),
        errors: {},
      };
    }
    case ContentPackActionTypes.DeleteContentPackError: {
      return {
        ...state,
        deletingPack: false,
        errors: {
          ...state.errors,
          deletingPack: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.AddToContentPack: {
      return {
        ...state,
        addingToPack: true,
        errors: {},
      };
    }
    case ContentPackActionTypes.AddToContentPackSuccess: {
      const { packId, contentPackDetail } = action.payload;
      const previousDetails = state.contentPackDetails[packId] || [];
      return {
        ...state,
        addingToPack: false,
        errors: {},
        contentPackDetails: {
          ...state.contentPackDetails,
          [packId]: previousDetails
            .concat(contentPackDetail)
            .sort(sortByKeyAlphabetically('name')),
        },
      };
    }
    case ContentPackActionTypes.AddToContentPackError: {
      return {
        ...state,
        addingToPack: false,
        errors: {
          addingToPack: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.RemoveFromContentPack: {
      return {
        ...state,
        removingFromPack: true,
        errors: {},
      };
    }
    case ContentPackActionTypes.RemoveFromContentPackSuccess: {
      const { packId, deletedDetail } = action.payload;
      const previousDetails = state.contentPackDetails[packId];
      const flatDeletedDetails = deletedDetail.reduce(
        (a, b) => a.concat([b.contentEntryHeaderId, b.hiddenId]),
        [],
      );
      return {
        ...state,
        removingFromPack: false,
        contentPackDetails: {
          ...state.contentPackDetails,
          [packId]: previousDetails.filter(
            (d) => !flatDeletedDetails.includes(d.contentEntryHeaderId),
          ),
        },
      };
    }
    case ContentPackActionTypes.RemoveFromContentPackError: {
      return {
        ...state,
        removingFromPack: false,
        errors: {
          removingFromPack: action.payload.errorMessage,
        },
      };
    }
    case ContentPackActionTypes.ClearErrors: {
      return {
        ...state,
        errors: {},
      };
    }
    case ContentPackActionTypes.ClearFilter: {
      return {
        ...state,
        activeListFilter: false,
        currentListFilter: '',
      };
    }
    case ContentPackActionTypes.FilterContent: {
      return {
        ...state,
        activeListFilter: true,
        currentListFilter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

// Selectors
export const getPacks = (state: State) => state.packs;
export const isCreatingPack = (state: State) => state.creatingPack;
export const isEditingPack = (state: State) => state.editingPack;
export const isDeletingPack = (state: State) => state.deletingPack;
export const isLoadingPacks = (state: State) => state.loadingPacks;
export const isLoadingPackDetail = (state: State) => state.loadingPackDetail;
export const isAddingToPack = (state: State) => state.addingToPack;
export const isRemovingFromPack = (state: State) => state.removingFromPack;
export const getErrors = (state: State) => state.errors;
export const getContentPackDetails = (state: State) => state.contentPackDetails;
export const isListFilterActive = (state: State) => state.activeListFilter;
export const getCurrentListFilter = (state: State) => state.currentListFilter;
