import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as dateFns from 'date-fns';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import { SimplePatient } from 'src/app/models/SimplePatient';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import { ClinicPatientRequest } from '../models/requests/clinic-patient.request';
import { ClinicPatientResponse } from '../models/responses/clinic-patient.response';
import { SortDirection } from '../types/patient-list.component.types';

interface PortalApiPatient {
  id: number;
  firstname: string;
  lastname: string;
  patientidentifier: string;
  dateofbirth: string;
  internalsid: string;
  patientaccountcreateddateutc?: string;
}

interface PortalApiPatientsResponse {
  data: PortalApiPatient[];
}

export interface LoadPatientsPayload {
  pageNumber: number;
  query: string;
  sortType: string;
  sortDirection: SortDirection;
}

@Injectable()
export class PatientService {
  private _publicKey$: Observable<string>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler,
    private _httpService: HttpService
  ) {
    this._publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
  }

  public getClinicPatients(
    payload: LoadPatientsPayload
  ): Observable<SimplePatient[]> {
    const PAGE_SIZE = 25;
    const params: { [id: string]: string } = {
      pageNumber: payload.pageNumber.toString(),
      pageSize: PAGE_SIZE.toString()
    };

    if (payload.query) {
      params.q = payload.query;
    }

    if (payload.sortType) {
      params.sortType = payload.sortType.toLowerCase();
    }

    if (payload.sortDirection) {
      params.sortDirection = payload.sortDirection;
    }

    return this._httpService
      .get<SimplePatient[]>(EndpointType.Portal, 'patients', params)
      .pipe(
        // TODO: Fix types
        // @ts-ignore
        map((response: PortalApiPatientsResponse) => {
          const result = response.data.map(
            (p) =>
              ({
                FirstName: p.firstname,
                Id: p.id,
                LastName: p.lastname,
                PatientIdentifier: p.patientidentifier,
                InternalSid: p.internalsid,
                PatientAccountCreatedDateUtc: p.patientaccountcreateddateutc,
                DateOfBirth: dateFns.parseISO(p.dateofbirth)
              } as SimplePatient)
          );
          return result;
        }),
        catchError((err) => {
          return throwError(new Error('Error Retrieving Clinic Patients'));
        })
      );
  }

  public getPatientById(id: string | number, isInternal: boolean) {
    return this._httpService
      .get<SimplePatient[]>(
        EndpointType.Portal,
        `patients/${id}`,
        isInternal ? { type: 'internalsid' } : {}
      )
      .pipe(
        map((response: any) => {
          const p: any = response.data;
          return {
            FirstName: p.firstname,
            Id: p.id,
            LastName: p.lastname,
            InternalSid: p.internalsid
          };
        }),
        catchError((err) => {
          return throwError(new Error('Error Retrieving Clinic Patients'));
        })
      );
  }

  public getPatientInformation(
    patientId: number
  ): Observable<ClinicPatientResponse> {
    return this._publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        return this._httpService.performResolvePostRequest(
          new ClinicPatientRequest({
            PatientId: patientId,
            PublicToken: pk
          })
        );
      })
    );
  }
}
