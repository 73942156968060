import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule, MatTabsModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { select, Store, StoreModule } from '@ngrx/store';
import { ClipboardModule } from 'ngx-clipboard';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../auth/reducers';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CoreModule } from '../core/core.module';
import { GlobalPortalGuard } from '../core/services/global-guard.service';
import { LocalisationModule } from '../localisation/localisation.module';
import { MaterialModule } from '../material/material.module';
import * as fromRoot from '../reducers';
import { LoadMessageTypes } from './actions/load-message.actions';
import { ConversationResolverComponent } from './containers/conversation-resolver.component';
import { AlterMessageEffects } from './effects/alter-message.effects';
import { LoadMessageEffects } from './effects/load-message.effects';
import { MessageNotificationEffects } from './effects/message-notifications.effects';
import { NewMessageEffects } from './effects/new-message.effects';
import { ToastEffects } from './effects/toast.effects';
import { reducer } from './reducers';
import { MessageService } from './services/message.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

export const COMPONENTS = [ConversationResolverComponent];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatTabsModule,
    MatChipsModule,
    CoreModule.forFeature(),
    LocalisationModule.forRoot(),
    StoreModule.forFeature('messages', reducer),
    EffectsModule.forFeature([
      AlterMessageEffects,
      LoadMessageEffects,
      NewMessageEffects,
      ToastEffects,
      MessageNotificationEffects,
    ]),
    RouterModule.forChild([
      {
        path: 'messages',
        children: [
          {
            path: '**',
            canActivate: [AuthGuard, GlobalPortalGuard],
            component: ConversationResolverComponent,
          },
        ],
      },
    ]),
  ],
  declarations: COMPONENTS,
  providers: [
    MessageService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MessagingModule {
  constructor(private _store: Store<fromRoot.State>) {
    const loggedIn$ = this._store.pipe(select(fromAuth.getLoggedIn));
    const isGlobalPortal$ = this._store.pipe(
      select(fromRoot.getIsGlobalPortal),
    );
    const subs = new Subscription().add(
      combineLatest(loggedIn$, isGlobalPortal$).subscribe(
        ([loggedIn, isGlobalPortal]) => {
          if (loggedIn && !isGlobalPortal) {
            this._store.dispatch(new LoadMessageTypes());
          }
        },
      ),
    );
  }
}
