import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, of, Subscription, } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { ContentActionTypes, DeleteTemplate, OpenDirectorySection, SavingTemplate, } from '../../actions/content.actions';
import { CreatePackComponent } from '../../components/create-pack/create-pack.component';
import { EditFolderNameComponent } from '../../components/edit-folder-name/edit-folder-name.component';
import { EditPackNameComponent } from '../../components/edit-pack-name/edit-pack-name.component';
import * as fromContent from '../../reducers';
import { CreateCategoryComponent } from './../../components/create-category/create-category.component';
var TemplateEditComponent = /** @class */ (function () {
    function TemplateEditComponent(_navigationService, _route, _updates$, _store, dialog) {
        this._navigationService = _navigationService;
        this._route = _route;
        this._updates$ = _updates$;
        this._store = _store;
        this.dialog = dialog;
        this.packs$ = of([]);
        this.routeType$ = of('template');
        // Check if template has been saved
        this.creatingTemplate$ = new BehaviorSubject(false);
        this.creatingTemplateSuccess$ = new BehaviorSubject(false);
        this.creatingTemplateError$ = new BehaviorSubject(false);
        this._subs = new Subscription();
    }
    TemplateEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        // Content editor group Observables
        this.contentEditorEnabled$ = this._store.pipe(select(fromContent.getContentEditorEnabled));
        // Set up text Observables
        this.directoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentDirectory')));
        this.contentText$ = this._store.pipe(select(fromSettings.getSectionTranslations('NewContentList')));
        this.templateEditText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateTemplate')));
        this.categoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateCategory')));
        this._subs.add(this.isGlobalPortal$.subscribe(function (value) {
            _this.isGlobalPortal = value;
        }));
        // Content editor group, wrapped in a feature flag
        this._subs.add(this.contentEditorEnabled$.subscribe(function (t) { return (_this.contentEditorEnabled = t); }));
        // capture text for use in dialogs
        this._subs.add(this.contentText$.subscribe(function (t) { return (_this.contentText = t); }));
        this._subs.add(this.categoryText$.subscribe(function (t) { return (_this.categoryText = t); }));
        // content-directory observables from Store and active route
        this.categories$ = this._store.pipe(select(fromContent.getGeneralCategories));
        this.templates$ = this._store.pipe(select(fromContent.getTemplates));
        this.packs$ = this._store.pipe(select(fromContent.getPacks));
        this.categoryId$ = this._route.params.pipe(map(function (params) {
            return +params.id;
        }));
        // template to hand to template form
        this.selectedTemplate$ = combineLatest(this.templates$, this.categoryId$).pipe(map(function (_a) {
            var templates = _a[0], id = _a[1];
            return templates.find(function (t) { return t.id === id; });
        }));
        // watch for successful delete
        // on delete navigate to main list of items.
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.DeleteTemplateSuccess), map(function () {
            if (_this.isGlobalPortal) {
                _this.navigateTo('content/global');
            }
            else {
                _this.navigateTo('content');
            }
        }))
            .subscribe());
        // watch for template update errors
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.SavingTemplateError), map(function () {
            _this.creatingTemplate$.next(false);
            _this.creatingTemplateError$.next(true);
            setTimeout(function () {
                _this.creatingTemplateError$.next(false);
            }, 3000);
        }))
            .subscribe());
        // Watch for successful template updates
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.SavingTemplateSuccess), map(function () {
            _this.creatingTemplate$.next(false);
            _this.creatingTemplateSuccess$.next(true);
            setTimeout(function () {
                _this.creatingTemplateSuccess$.next(false);
            }, 1000);
        }))
            .subscribe());
        // Which section of directory is open?
        this.openDirectory$ = this._store.pipe(select(fromContent.activeDirectorySection));
    };
    TemplateEditComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    TemplateEditComponent.prototype.deleteTemplate = function (id) {
        this._store.dispatch(new DeleteTemplate(id));
    };
    TemplateEditComponent.prototype.saveTemplate = function (template) {
        this._store.dispatch(new SavingTemplate(template));
    };
    // Functions to manage content directory
    TemplateEditComponent.prototype.navigateTo = function (url) {
        this._navigationService.navigateByUrl(url);
    };
    TemplateEditComponent.prototype.createNewCategoryOrTemplate = function () {
        this._navigationService.navigate(['content', 'contentortemplate']);
    };
    TemplateEditComponent.prototype.openDirectorySection = function (section) {
        this._store.dispatch(new OpenDirectorySection(section));
    };
    TemplateEditComponent.prototype.createNewFolder = function () {
        this.dialog.open(CreateCategoryComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    TemplateEditComponent.prototype.changeFolderName = function (category) {
        this.dialog.open(EditFolderNameComponent, {
            data: {
                category: category,
                text: this.contentText,
            },
            width: '550px',
        });
    };
    TemplateEditComponent.prototype.createNewPack = function () {
        this.dialog.open(CreatePackComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    TemplateEditComponent.prototype.editPackName = function (pack) {
        this.dialog.open(EditPackNameComponent, {
            data: {
                pack: pack,
                text: this.contentText,
            },
            width: '550px',
        });
    };
    return TemplateEditComponent;
}());
export { TemplateEditComponent };
