import { Omit } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { useCallbackDebounced } from '@react/lib/hooks/useCallbackDebounced';
import { useTranslations } from '@react/lib/i18n';
import { VideoPlayer, VideoPlayerContainer } from '@zoom/videosdk';
import React, {
  ComponentType,
  CSSProperties,
  DetailedHTMLProps,
  DOMAttributes,
  HTMLAttributes,
  ReactNode,
  useEffect
} from 'react';
import { JoinVideoCallResponse } from '../../../../../video-calls/responses';
import { useVideoCall } from '../../hooks/useVideoCall';
import { ZoomProvider } from '../ZoomProvider';
import { VideoCallMenuButton } from './VideoCallMenuButton';
import {
  CallEndButton,
  CallEndIcon,
  MicButton,
  MicIcon,
  MicOffIcon,
  NooneHereText,
  PageContainer,
  ParticipantsContainer,
  VideoActionBarContainer,
  VideocamButton,
  VideocamIcon,
  VideocamOffIcon
} from './VideoCallModal.styled';
import ParticipantPanel from './ParticipantPanel';
import SelfPanel from './SelfPanel';

interface Props {
  displayName: string;
  joinResponse: JoinVideoCallResponse;
  partnerInviteEnabled: boolean;
  partnerInvited: boolean;
  close: () => void;
  displayConnectedNotification: () => void;
  displayErrorNotification: (message: string) => void;
  invitePartnerInCall: () => void;
  invitingPartner: boolean;
}

type CustomElement<T> = Omit<
  Partial<T & DOMAttributes<T>>,
  'children' | 'style'
>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['video-player']: DetailedHTMLProps<
        HTMLAttributes<VideoPlayer>,
        VideoPlayer
      > & { class?: string };
      ['video-player-container']: CustomElement<VideoPlayerContainer> & {
        class?: string;
        style?: CSSProperties;
        children: Element | ReactNode;
      };
    }
  }
}

const participantsVideoPlayerContainerStyle: CSSProperties = {
  flex: 1,
  position: 'relative',
  display: 'block'
};
const selfVideoPlayerContainerStyle: CSSProperties = {
  width: 256,
  aspectRatio: '16/9',
  position: 'absolute',
  top: 20,
  right: 20,
  backgroundColor: 'black'
};

function withZoomProvider<P>(Component: ComponentType<P>) {
  return function WrappedComponent(props: P) {
    return (
      <ZoomProvider>
        <Component key={undefined} {...props} />
      </ZoomProvider>
    );
  };
}

const VideoCallModalComponentBase: React.FC<Props> = ({
  displayName,
  close,
  joinResponse,
  invitePartnerInCall,
  partnerInviteEnabled,
  partnerInvited,
  invitingPartner,
  displayConnectedNotification,
  displayErrorNotification
}) => {
  const { t } = useTranslations();
  const {
    join,
    leave,
    currentUser,
    participants,
    microphoneList,
    cameraList,
    speakerList,
    toggleMic,
    toggleCamera,
    micOn,
    cameraOn,
    changeCamera,
    changeMic,
    changeSpeaker,
    joining
  } = useVideoCall();
  const onCloseClick = useCallbackDebounced(async () => {
    await leave();
    close();
  }, [close]);
  useEffect(() => {
    if (!t) {
      return;
    }
    void join({
      name: displayName,
      room: joinResponse.response.room,
      token: joinResponse.response.zoomToken,
      password: joinResponse.videoCall.id.toString()
    })
      .then((success) => {
        if (success) {
          displayConnectedNotification();
        }
      })
      .catch((error: Error) => {
        leave();
        close();
        if (error.message === 'camera-permisisons-error') {
          displayErrorNotification(t.VideoCallModal.NoCameraMic);
        } else {
          displayErrorNotification(error.message);
        }
      });
  }, [join, leave, close, t]);
  return (
    <PageContainer>
      <video-player-container style={participantsVideoPlayerContainerStyle}>
        <ParticipantsContainer>
          {joining && <CircularProgress />}
          {!joining && (!participants || !participants.length) && (
            <NooneHereText>
              {t &&
                t.VideoCallModal.NooneHere(joinResponse.videoCall.patientname)}
            </NooneHereText>
          )}
          {participants.map((participant) => {
            return (
              <ParticipantPanel
                key={participant.userId}
                participant={participant}
              />
            );
          })}
        </ParticipantsContainer>
      </video-player-container>
      {currentUser && (
        <video-player-container style={selfVideoPlayerContainerStyle}>
          <SelfPanel
            participant={currentUser}
            cameraOn={cameraOn}
            micOn={micOn}
          />
        </video-player-container>
      )}
      <VideoActionBarContainer>
        <CallEndButton size="medium" onClick={onCloseClick}>
          <CallEndIcon />
        </CallEndButton>
        <MicButton size="medium" onClick={toggleMic}>
          {micOn ? <MicIcon /> : <MicOffIcon />}
        </MicButton>
        <VideocamButton size="medium" onClick={toggleCamera}>
          {cameraOn ? <VideocamIcon /> : <VideocamOffIcon />}
        </VideocamButton>
        <VideoCallMenuButton
          cameraList={cameraList}
          microphoneList={microphoneList}
          speakerList={speakerList}
          changeCamera={changeCamera}
          changeMic={changeMic}
          changeSpeaker={changeSpeaker}
          invitePartnerInCall={invitePartnerInCall}
          invitingPartner={invitingPartner}
          partnerInvited={partnerInvited}
          partnerInviteEnabled={partnerInviteEnabled}
          disabled={joining}
        />
      </VideoActionBarContainer>
    </PageContainer>
  );
};

export const VideoCallModalComponent = withZoomProvider(
  VideoCallModalComponentBase
);
