import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Category } from '../../models/category';

@Component({
  selector: 'portal-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryListComponent {
  @Input() text: any;
  @Input() categories: Category[];
  @Output() goToCategory = new EventEmitter<number>();

  public config: PerfectScrollbarConfigInterface = {};
}
