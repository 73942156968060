/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/login-form/login-form.component.ngfactory";
import * as i2 from "../../components/login-form/login-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./login-page.container";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../core/services/navigation.service";
var styles_LoginPageContainer = [];
var RenderType_LoginPageContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginPageContainer, data: {} });
export { RenderType_LoginPageContainer as RenderType_LoginPageContainer };
export function View_LoginPageContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "portal-login-form", [], null, [[null, "forgottenPassword"], [null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("forgottenPassword" === en)) {
        var pd_0 = (_co.forgotPassword() !== false);
        ad = (pd_0 && ad);
    } if (("submitted" === en)) {
        var pd_1 = (_co.onSubmit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_LoginFormComponent_0, i1.RenderType_LoginFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.LoginFormComponent, [i3.FormBuilder], { isCore: [0, "isCore"], text: [1, "text"], loggingIn: [2, "loggingIn"], error: [3, "error"], clinicId: [4, "clinicId"], cognitoUser: [5, "cognitoUser"] }, { forgottenPassword: "forgottenPassword", submitted: "submitted" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isCoreClinic$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.toastText$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.loggingIn$)); var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 5).transform(_co.error$)); var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 6).transform(_co.clinicId$)); var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 7).transform(_co.cognitoUser$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_LoginPageContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-login-page", [], null, null, null, View_LoginPageContainer_0, RenderType_LoginPageContainer)), i0.ɵdid(1, 114688, null, 0, i5.LoginPageContainer, [i6.Store, i6.Store, i7.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageContainerNgFactory = i0.ɵccf("portal-login-page", i5.LoginPageContainer, View_LoginPageContainer_Host_0, {}, {}, []);
export { LoginPageContainerNgFactory as LoginPageContainerNgFactory };
