import { Description as DescriptionIcon } from '@material-ui/icons';
import { useBytesFormatter } from '@react/lib/hooks';
import React from 'react';
import { FileDescriptionStyled } from './FileDescription.styled';
export var FileDescription = function (_a) {
    var filename = _a.filename, fileSize = _a.fileSize, onClick = _a.onClick;
    var formatBytes = useBytesFormatter();
    return (React.createElement(FileDescriptionStyled, { onClick: onClick },
        React.createElement(DescriptionIcon, null),
        " ",
        filename,
        " (",
        formatBytes(fileSize),
        ")"));
};
