import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GetContentPackDetail, GetContentPackDetailError, GetContentPackDetailSuccess, GetContentPacksError, GetContentPacksSuccess, PackAssignmentActionTypes, } from '../actions/pack-assignment.actions';
import { PackAssignmentService } from '../services/pack-assignment.service';
import { AddExclusionError, AddExclusionSuccess, AssignPackError, AssignPackSuccess, RemoveExclusionError, RemoveExclusionSuccess, UnassignPackError, UnassignPackSuccess, } from './../actions/pack-assignment.actions';
var PackAssignmentEffects = /** @class */ (function () {
    function PackAssignmentEffects(_actions$, _packAssignService) {
        var _this = this;
        this._actions$ = _actions$;
        this._packAssignService = _packAssignService;
        this.selectActivePackId$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.SelectActivePackId), map(function (action) { return new GetContentPackDetail(action.payload); }));
        this.getContentPacks$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.GetContentPacks), switchMap(function () {
            return _this._packAssignService.getContentPacks().pipe(mergeMap(function (res) {
                var firstPack = res.contentPacks[0].id;
                return [
                    new GetContentPacksSuccess(res),
                    new GetContentPackDetail(firstPack),
                ];
            }), catchError(function (err) {
                return of(new GetContentPacksError(err));
            }));
        }));
        this.getContentPackDetail$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.GetContentPackDetail), switchMap(function (action) {
            return _this._packAssignService.getContentPackDetail(action.payload).pipe(map(function (res) {
                return new GetContentPackDetailSuccess(res);
            }), catchError(function (err) {
                return of(new GetContentPackDetailError(err));
            }));
        }));
        this.assignContentPack$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.AssignPack), switchMap(function (action) {
            return _this._packAssignService.assignPack(action.payload).pipe(map(function (res) {
                if (action.payload.pack) {
                    action.payload.pack.isTransferring = false;
                }
                if (action.payload.packItem) {
                    action.payload.packItem.isTransferring = false;
                }
                return new AssignPackSuccess(tslib_1.__assign({}, res, { exclusions: action.payload.exclusions, packName: action.payload.packName, packDetail: action.payload.packDetail }));
            }), catchError(function (err) {
                return of(new AssignPackError(err));
            }));
        }));
        this.addExclusion$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.AddExclusion), switchMap(function (action) {
            return _this._packAssignService.addExclusion(action.payload).pipe(map(function (res) {
                if (action.payload.packItem) {
                    action.payload.packItem.isTransferring = false;
                }
                return new AddExclusionSuccess(tslib_1.__assign({}, res, { packDetail: action.payload.packDetail }));
            }), catchError(function (err) {
                return of(new AddExclusionError(err));
            }));
        }));
        this.removeExclusion$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.RemoveExclusion), switchMap(function (action) {
            return _this._packAssignService.removeExclusion(action.payload).pipe(map(function (res) {
                return new RemoveExclusionSuccess(tslib_1.__assign({}, res, { packDetail: action.payload.packDetail }));
            }), catchError(function (err) {
                return of(new RemoveExclusionError(err));
            }));
        }));
        this.unassignContentPack$ = this._actions$.pipe(ofType(PackAssignmentActionTypes.UnassignPack), switchMap(function (action) {
            return _this._packAssignService.unassignPack(action.payload).pipe(map(function (res) {
                action.payload.pack.isTransferring = false;
                return new UnassignPackSuccess(res);
            }), catchError(function (err) {
                return of(new UnassignPackError(err));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "selectActivePackId$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "getContentPacks$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "getContentPackDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "assignContentPack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "addExclusion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "removeExclusion$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PackAssignmentEffects.prototype, "unassignContentPack$", void 0);
    return PackAssignmentEffects;
}());
export { PackAssignmentEffects };
