import * as tslib_1 from "tslib";
import { PackAssignmentActionTypes, } from './../actions/pack-assignment.actions';
export var initialState = {
    loadingContentPacks: false,
    loadingPackDetail: false,
    contentPacks: [],
    lockedItems: [],
    activePackId: null,
    assignedPackId: null,
    packDetail: {},
    errors: {
        loadingContentPacks: false,
        loadingPackDetail: false,
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    switch (action.type) {
        case PackAssignmentActionTypes.GetContentPacks: {
            return tslib_1.__assign({}, state, { loadingContentPacks: true });
        }
        case PackAssignmentActionTypes.GetContentPacksSuccess: {
            var contentPacks = action.payload.contentPacks;
            var firstPack = contentPacks[0];
            var activePackId = state.activePackId === null && firstPack && firstPack.id
                ? firstPack.id
                : state.activePackId;
            return tslib_1.__assign({}, state, { loadingContentPacks: false, contentPacks: contentPacks,
                activePackId: activePackId, errors: tslib_1.__assign({}, state.errors, { loadingContentPacks: false }) });
        }
        case PackAssignmentActionTypes.GetContentPacksError: {
            return tslib_1.__assign({}, state, { loadingContentPacks: false, errors: tslib_1.__assign({}, state.errors, { loadingContentPacks: true }) });
        }
        case PackAssignmentActionTypes.SelectActivePackId: {
            return tslib_1.__assign({}, state, { activePackId: action.payload, lockedItems: [] });
        }
        case PackAssignmentActionTypes.GetContentPackDetail: {
            return tslib_1.__assign({}, state, { loadingPackDetail: true, errors: tslib_1.__assign({}, state.errors, { loadingPackDetail: false }) });
        }
        case PackAssignmentActionTypes.GetContentPackDetailSuccess: {
            return tslib_1.__assign({}, state, { loadingPackDetail: false, packDetail: tslib_1.__assign({}, state.packDetail, (_a = {}, _a[action.payload.packId] = action.payload.contentPackDetail, _a)) });
        }
        case PackAssignmentActionTypes.GetContentPackDetailError: {
            return tslib_1.__assign({}, state, { loadingPackDetail: false, errors: tslib_1.__assign({}, state.errors, { loadingPackDetail: true }) });
        }
        case PackAssignmentActionTypes.LockPackItem: {
            return tslib_1.__assign({}, state, { lockedItems: state.lockedItems.concat([action.payload]) });
        }
        case PackAssignmentActionTypes.UnlockPackItem: {
            return tslib_1.__assign({}, state, { lockedItems: state.lockedItems.filter(function (id) { return id !== action.payload; }) });
        }
        case PackAssignmentActionTypes.OpenAssignedPack: {
            return tslib_1.__assign({}, state, { assignedPackId: action.payload });
        }
        case PackAssignmentActionTypes.UnassignPackSuccess: {
            return tslib_1.__assign({}, state, { assignedPackId: null });
        }
        default: {
            return state;
        }
    }
}
// Selectors
export var getContentPacks = function (state) { return state.contentPacks; };
export var getActivePackId = function (state) { return state.activePackId; };
export var getLockedItems = function (state) { return state.lockedItems; };
export var getPackDetail = function (state) { return state.packDetail; };
export var getSelectedPack = function (id, packs) {
    return packs.find(function (p) { return p.id === id; });
};
export var getSelectedPackDetail = function (id, packDetail, lockedItems) {
    var key = "" + id;
    if (packDetail.hasOwnProperty(key)) {
        return (packDetail["" + id].map(function (d) { return (tslib_1.__assign({}, d, { locked: lockedItems.includes(d.contentEntryHeaderId) })); }) || []);
    }
    return [];
};
export var assignedPackId = function (state) { return state.assignedPackId; };
export var getActiveAssignedPackId = function (state) { return state.assignedPackId; };
