import { MatPaginatorIntl } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/patients/services/patient.service';
import { LocalisationService } from '../localisation/localisation.service';
import * as fromSettings from '../settings/reducers';
import { AddFileComponent } from './components/add-file/add-file.component';
import { ButtonComponent } from './components/button/button.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { DialogWithDismissComponent } from './components/dialog-with-dismiss/dialog-with-dismiss.component';
import { DrugsTableComponent } from './components/drugs-table/drugs-table.component';
import { HealthLoadingComponent } from './components/health-loading/health-loading.component';
import { IntegrationStatusComponent } from './components/integration-status/integration-status.component';
import { InvitePartnerComponent } from './components/invite-partner/invite-partner.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { MessageEditorComponent } from './components/message-editor/message-editor.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { NavMessageComponent } from './components/nav-message/nav-message.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AppComponent } from './containers/app/app.component';
import { IntegrationStatusContainer } from './containers/integration-status/integration-status.container';
import { DiscardMessagePromptReactComponent } from './containers/new-message/discard-message-prompt.component';
import { NewMessageReactComponent } from './containers/new-message/new-message-react.component';
import { NewMessageComponent } from './containers/new-message/new-message.component';
import { NewVideoCallComponent } from './containers/new-video-call/new-video-call.component';
import { ProfilePhotoModalReactWrapperComponent } from './containers/profile-photo-modal/profile-photo-modal-react-wrapper.component';
import { ProfilePhotoReactWrapperComponent } from './containers/profile-photo/profile-photo-react-wrapper.component';
import { SwitchPortalReactWrapperComponent } from './containers/switch-portal/switch-portal-react-wrapper.component';
import { UnauthorisedReactWrapperComponent } from './containers/unauthorised/unauthorised-react-wrapper.component';
import { VideoCallModalReactWrapperComponent } from './containers/video-call-modal/video-call-modal-react-wrapper.component';
import { InputMaxLengthDirective } from './directives/input-max-length.directive';
import { InputMaxDirective, InputMinDirective } from './directives/input-max-min.directive';
import { IntersectionObserverDirective } from './directives/interesection-observer';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { StopClickPropagationDirective } from './directives/stop-click-propagation';
import { DateTransformPipe, TimeTransformPipe } from './pipes/date.time.pipes';
import { FileSizePipe } from './pipes/file-size.pipes';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { ClinicService } from './services/clinic.service';
import { CognitoWrapperService } from './services/congito.wrapper.service';
import { ContentApiCallService } from './services/content-api-call.service';
import { CustomMatPaginator } from './services/custom-mat-paginator';
import { ErrorAnnotationService } from './services/error-annotation.service';
import { HttpService } from './services/http.service';
import { Md5HashService } from './services/md5-hash.service';
import { NavigationService } from './services/navigation.service';
import { FileUpload } from './services/s3.service';
import { SelectiveStrategy } from './services/selective-strategy.service';
import { WindowRefService } from './services/window-ref.service';
var COMPONENTS = [
    AppComponent,
    LayoutComponent,
    NavItemComponent,
    ToolbarComponent,
    SearchbarComponent,
    UserProfileComponent,
    ButtonComponent,
    DrugsTableComponent,
    ConfirmActionComponent,
    NewMessageComponent,
    InvitePartnerComponent,
    NewMessageReactComponent,
    DiscardMessagePromptReactComponent,
    MessageEditorComponent,
    ProfilePhotoReactWrapperComponent,
    ProfilePhotoModalReactWrapperComponent,
    VideoCallModalReactWrapperComponent,
    HealthLoadingComponent,
    AddFileComponent,
    NavMessageComponent,
    IntegrationStatusComponent,
    IntegrationStatusContainer,
    DialogWithDismissComponent,
    LoadingDialogComponent,
    NewVideoCallComponent,
    NumberInputComponent,
    TimePickerComponent,
    SwitchPortalReactWrapperComponent,
    UnauthorisedReactWrapperComponent
];
var DIRECTIVES = [
    StopClickPropagationDirective,
    NumbersOnlyDirective,
    InputMaxLengthDirective,
    InputMinDirective,
    InputMaxDirective,
    IntersectionObserverDirective
];
var PIPES = [DateTransformPipe, FileSizePipe, TimeTransformPipe];
var CoreModule = /** @class */ (function () {
    function CoreModule(_store, _localisationService) {
        this._store = _store;
        this._localisationService = _localisationService;
        this._subs = new Subscription();
        this.selectedLanguage$ = this._store.pipe(select(fromSettings.getCurrentLanguage));
        // Subscribe to language changes
        this._subs.add(this.selectedLanguage$.subscribe(function (language) {
            _localisationService.setLocale(language);
        }));
    }
    CoreModule.forRoot = function () {
        return {
            ngModule: CoreModule,
            providers: [
                NavigationService,
                CognitoWrapperService,
                HttpService,
                ContentApiCallService,
                Md5HashService,
                PatientService,
                SelectiveStrategy,
                WindowRefService,
                CanDeactivateGuard,
                FileUpload,
                ErrorAnnotationService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginator
                },
                ClinicService
            ]
        };
    };
    CoreModule.forFeature = function () {
        return {
            ngModule: CoreModule,
            exports: COMPONENTS.slice()
        };
    };
    return CoreModule;
}());
export { CoreModule };
