import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  AddExclusionResponse,
  GetContentPackDetailResponse,
  GetContentPacksResponse,
  PackAssignmentResponse,
  RemoveExclusionResponse,
  UnassignPackResponse,
} from 'src/app/content/models/content-packs';
import {
  GetContentPackDetail,
  GetContentPackDetailError,
  GetContentPackDetailSuccess,
  GetContentPacks,
  GetContentPacksError,
  GetContentPacksSuccess,
  PackAssignmentActionTypes,
  SelectActivePackId,
} from '../actions/pack-assignment.actions';
import { PackAssignmentService } from '../services/pack-assignment.service';
import {
  AddExclusion,
  AddExclusionError,
  AddExclusionSuccess,
  AssignPack,
  AssignPackError,
  AssignPackSuccess,
  RemoveExclusion,
  RemoveExclusionError,
  RemoveExclusionSuccess,
  UnassignPack,
  UnassignPackError,
  UnassignPackSuccess,
} from './../actions/pack-assignment.actions';

@Injectable()
export class PackAssignmentEffects {
  @Effect()
  selectActivePackId$ = this._actions$.pipe(
    ofType<SelectActivePackId>(PackAssignmentActionTypes.SelectActivePackId),
    map((action) => new GetContentPackDetail(action.payload)),
  );

  @Effect()
  getContentPacks$ = this._actions$.pipe(
    ofType<GetContentPacks>(PackAssignmentActionTypes.GetContentPacks),
    switchMap(() =>
      this._packAssignService.getContentPacks().pipe(
        mergeMap((res: GetContentPacksResponse) => {
          const firstPack = res.contentPacks[0].id;
          return [
            new GetContentPacksSuccess(res),
            new GetContentPackDetail(firstPack),
          ];
        }),
        catchError((err) => {
          return of(new GetContentPacksError(err));
        }),
      ),
    ),
  );

  @Effect()
  getContentPackDetail$ = this._actions$.pipe(
    ofType<GetContentPackDetail>(
      PackAssignmentActionTypes.GetContentPackDetail,
    ),
    switchMap((action) =>
      this._packAssignService.getContentPackDetail(action.payload).pipe(
        map(
          (res: GetContentPackDetailResponse) =>
            new GetContentPackDetailSuccess(res),
        ),
        catchError((err) => {
          return of(new GetContentPackDetailError(err));
        }),
      ),
    ),
  );

  @Effect()
  assignContentPack$ = this._actions$.pipe(
    ofType<AssignPack>(PackAssignmentActionTypes.AssignPack),
    switchMap((action) =>
      this._packAssignService.assignPack(action.payload).pipe(
        map((res: PackAssignmentResponse) => {
          if (action.payload.pack) {
            action.payload.pack.isTransferring = false;
          }
          if (action.payload.packItem) {
            action.payload.packItem.isTransferring = false;
          }
          return new AssignPackSuccess({
            ...res,
            exclusions: action.payload.exclusions,
            packName: action.payload.packName,
            packDetail: action.payload.packDetail,
          });
        }),
        catchError((err) => {
          return of(new AssignPackError(err));
        }),
      ),
    ),
  );

  @Effect()
  addExclusion$ = this._actions$.pipe(
    ofType<AddExclusion>(PackAssignmentActionTypes.AddExclusion),
    switchMap((action) =>
      this._packAssignService.addExclusion(action.payload).pipe(
        map((res: AddExclusionResponse) => {
          if (action.payload.packItem) {
            action.payload.packItem.isTransferring = false;
          }
          return new AddExclusionSuccess({
            ...res,
            packDetail: action.payload.packDetail,
          });
        }),
        catchError((err) => {
          return of(new AddExclusionError(err));
        }),
      ),
    ),
  );

  @Effect()
  removeExclusion$ = this._actions$.pipe(
    ofType<RemoveExclusion>(PackAssignmentActionTypes.RemoveExclusion),
    switchMap((action) =>
      this._packAssignService.removeExclusion(action.payload).pipe(
        map(
          (res: RemoveExclusionResponse) =>
            new RemoveExclusionSuccess({
              ...res,
              packDetail: action.payload.packDetail,
            }),
        ),
        catchError((err) => {
          return of(new RemoveExclusionError(err));
        }),
      ),
    ),
  );

  @Effect()
  unassignContentPack$ = this._actions$.pipe(
    ofType<UnassignPack>(PackAssignmentActionTypes.UnassignPack),
    switchMap((action) =>
      this._packAssignService.unassignPack(action.payload).pipe(
        map((res: UnassignPackResponse) => {
          action.payload.pack.isTransferring = false;
          return new UnassignPackSuccess(res);
        }),
        catchError((err) => {
          return of(new UnassignPackError(err));
        }),
      ),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _packAssignService: PackAssignmentService,
  ) {}
}
