import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import equal from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import { NavigationService } from '../../../core/services/navigation.service';
import { FileUpload } from '../../../core/services/s3.service';
import { Language } from '../../../models/Language';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as ContentActions from '../../actions/content.actions';
import { ContentActionTypes } from '../../actions/content.actions';
import {
  DeleteAllContentLinksSuccess,
  LinkContentActionTypes,
} from '../../actions/link-content.actions';
import { Category } from '../../models/category';
import {
  ContentEntry,
  ContentEntryHeader,
  ContentEntrySection,
  NewContentEntrySection,
} from '../../models/content-entry';
import {
  ApplyTemplate,
  ContentTemplate,
  ContentTemplateEntry,
} from '../../models/content-template';
import { FileInStorage } from '../../models/file-in-storage';
import { ParentPortal } from '../../models/global-portal';
import * as fromContent from '../../reducers';
import { validateContentName } from '../../services/async-validators/validate-content-name';
import { ContentService } from '../../services/content.service';
import { ViewChildrenContainer } from '../view-children/view-children.container';

export type Status = 'DIRTY' | 'PRISTINE';

@Component({
  selector: 'portal-content-edit-form',
  templateUrl: './content-edit-form.component.html',
  styleUrls: ['./content-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentEditFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loadingError: boolean;
  @Input() isLoading: boolean;
  @Input() isMultiLingual: boolean;
  @Input() contentEntry: ContentEntry;
  @Input() categories: Category[];
  @Input() templates: ContentTemplate[];
  @Input() languageCode: string;
  @Input() languages: Language[];
  @Input() parentId?: number;
  @Input() contentEditorEnabled: boolean;
  @Input() defaultContentEntryId?: number;
  @Output() status = new EventEmitter<Status>();
  @Output() copyGlobalContent = new EventEmitter<any>();
  @Output() copyGlobalFileContent = new EventEmitter<any>();

  public subtitle: ContentEntrySection;
  public config: PerfectScrollbarConfigInterface = {};
  public contentEntryForm: FormGroup;
  public introductionError = new BehaviorSubject<boolean>(false);

  // Observables for text and translations
  public titleText$: Observable<any>;
  public bodyText$: Observable<any>;
  public controlsText$: Observable<any>;
  public videoText$: Observable<any>;
  public sectionText$: Observable<any>;
  public commentsText$: Observable<any>;
  public versionHistoryText$: Observable<any>;
  public addPdfText$: Observable<any>;
  public useATemplateText$: Observable<any>;
  public useAContentText$: Observable<any>;

  public parentInfo$: Observable<ContentEntryHeader>;

  // capture text for use in ConfirmDialog
  public controlsText: any;

  public user$: Observable<User>;

  public checkingTitle$ = new BehaviorSubject<boolean>(false);
  public titleInvalid$ = new BehaviorSubject<boolean>(false);
  public attachmentExists$ = new BehaviorSubject<boolean>(false);

  public addFile = false;

  public user: User;
  public activeEntry: ContentEntry;
  public sections: ContentEntrySection[];

  // Global Portal Flag
  public isGlobalPortal$: Observable<boolean>;
  public isGlobalPortal: boolean;

  public hasParentPortal$: Observable<boolean>;
  public hasParentPortal: boolean;
  public parentPortalId: ParentPortal['globalclinicid'] | null = null;

  private _destroyed$ = new Subject<boolean>();
  private _subs = new Subscription();
  private _currentStatus?: Status;

  get requiredSections(): FormArray {
    return this.contentEntryForm.get('requiredSections') as FormArray;
  }

  get entrySections(): FormArray {
    return this.contentEntryForm.get('sections') as FormArray;
  }

  get attachments(): FormArray {
    return this.contentEntryForm.get('attachments') as FormArray;
  }

  constructor(
    private _store: Store<fromRoot.State>,
    private _fb: FormBuilder,
    private _navigationService: NavigationService,
    private _updates$: Actions,
    private _contentService: ContentService,
    public _S3: FileUpload,
    public dialog: MatDialog,
    public change: ChangeDetectorRef,
  ) {
    // Global Portal Observable
    this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));

    this._contentService.getGlobalPortal().subscribe((res: ParentPortal) => {
      if (res && res.globalclinicid) {
        this.parentPortalId = res.globalclinicid;
      }
      return;
    });

    // Set up Text Observables
    this.titleText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorTitle')),
    );
    this.bodyText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorBody')),
    );
    this.controlsText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorControls')),
    );
    this.videoText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('AddVideoControls')),
    );
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorSections')),
    );
    this.commentsText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorComments')),
    );
    this.versionHistoryText$ = this._store.pipe(
      select(
        fromSettings.getSectionTranslations('ContentCreatorVersionHistory'),
      ),
    );
    this.addPdfText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('AddPdf')),
    );
    this.useATemplateText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('UseATemplate')),
    );
    this.useAContentText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('UseAContent')),
    );

    this._subs.add(
      this.isGlobalPortal$.subscribe((value) => {
        this.isGlobalPortal = value;
      }),
    );

    // Capture controlsText value for use in ConfirmDialogs
    this._subs.add(
      this.controlsText$.subscribe((t) => (this.controlsText = t)),
    );

    // Set up observables
    this.user$ = this._store.pipe(select(fromAuth.getUser));

    // set up dummy form until data is ready
    this.contentEntryForm = this._fb.group({
      title: [''],
      patientTitle: [''],
      subtitle: [''],
      category: [''],
      comments: [''],
      sendtoall: [false],
      requiredSections: this._fb.array([]),
      sections: this._fb.array([]),
      attachments: this._fb.array([]),
    });
  }

  ngOnInit() {
    // fetch user info and categories
    this._subs.add(this.user$.subscribe((user: User) => (this.user = user)));

    // Only allow a single attachment-
    this._subs.add(
      this.attachments.valueChanges.subscribe((v) => {
        this.attachmentExists$.next(this.attachments.length > 0);
      }),
    );

    // watch for status changes on the title field
    this._subs.add(
      this.contentEntryForm.get('title').statusChanges.subscribe((status) => {
        switch (status) {
          case 'VALID':
            this.titleInvalid$.next(false);
            break;
          case 'PENDING':
            break; // no change previously assigned validity while checking
          case 'INVALID':
            this.titleInvalid$.next(true);
            break;
          default:
            this.titleInvalid$.next(true);
        }
      }),
    );

    this._subs.add(
      this._updates$
        .pipe(
          ofType<
            ContentActions.CreateEntrySuccess | ContentActions.SaveEntrySuccess
          >(
            ContentActionTypes.CreateEntrySuccess,
            ContentActionTypes.SaveEntrySuccess,
          ),
        )
        .subscribe((action) => {
          if (action.payload.languageCode === this.languageCode) {
            this._updateStatus('PRISTINE');
          }
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const contentEntryChanges = changes.contentEntry;
    if (contentEntryChanges && contentEntryChanges.currentValue) {
      if (contentEntryChanges.previousValue == null) {
        this.setupForm();
      } else {
        this.activeEntry.id = this.contentEntry.id;
      }
    }
  }

  ngOnDestroy(): void {
    // remove all component subscriptions
    this._subs.unsubscribe();
    // stop watching for Successful updates of Save
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  public setupForm() {
    if (this.contentEntry) {
      this.parentInfo$ = this._store.pipe(
        select(fromContent.getParentById(this.contentEntry.parentid)),
      );
      this.subtitle = this.contentEntry.sections.find(
        (e) => e.type === 'subtitle',
      );
      this.addFile = this.contentEntry.isfileonly;
      this.activeEntry = cloneDeep(this.contentEntry);

      this.sections = cloneDeep(this.contentEntry.sections);
      const patientTitleValidators = this.activeEntry.fromdatasync
        ? [Validators.minLength(3), Validators.maxLength(50)]
        : [];
      this.contentEntryForm = this._fb.group({
        title: [
          {
            value: this.activeEntry.name || '',
            disabled: this.activeEntry.fromdatasync,
          },
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50),
          ]),
          [
            validateContentName(
              this._contentService,
              this.checkingTitle$,
              this.activeEntry.name,
            ),
          ],
        ],
        patientTitle: [
          {
            value: this._getTitleSectionValue(this.sections),
            disabled: !this.activeEntry.fromdatasync,
          },
          // note html in content-edit-title.component
          // is expecting to handle 'required' and 'nameExists' errors from validateContentName
          // but the below validator only checks length.
          patientTitleValidators,
        ],
        subtitle: [
          {
            value: this.subtitle.content,
            disabled: false,
          },
          [Validators.maxLength(60)],
        ],
        category: [
          {
            value: this.activeEntry.contentcategory,
            disabled: false,
          },
        ],
        comments: [
          {
            value: this.activeEntry.comments,
            disabled: false,
          },
          [Validators.maxLength(300)],
        ],
        sendtoall: [
          {
            value: this.activeEntry.sendtoall,
            disabled: false,
          },
        ],
        requiredSections: this._fb.array([]),
        sections: this._fb.array([]),
        attachments: this._fb.array(
          this.contentEntry.attachments.map((a) => this._buildAttachment(a)),
        ),
      });
      if (!this.contentEntryForm.get('title').valid) {
        this.titleInvalid$.next(true);
      }

      // process sections for content form
      this._processSections(this.sections);

      // does an attachment already exist
      this.attachmentExists$.next(this.attachments.length > 0);

      if (this.contentEntry.id == null) {
        this._updateStatus('DIRTY');
      }

      this._subs.add(
        this.contentEntryForm.valueChanges
          .pipe(debounceTime(500))
          .subscribe(() => {
            this._updateStatus(this._formHasChanged() ? 'DIRTY' : 'PRISTINE');
          }),
      );
    }
  }

  private _updateStatus(newStatus: Status) {
    if (this._currentStatus !== newStatus) {
      this._currentStatus = newStatus;
      this.status.emit(newStatus);
    }
  }

  private _getTitleSectionValue(sections: ContentEntrySection[]): string {
    return sections.find((s) => s.type === 'title').content || null;
  }

  private _goToList(id: number) {
    this._navigationService.navigate(['content', 'list', id]);
  }

  // types mixed, intro and video
  private _buildSection(section: ContentEntrySection): FormGroup {
    return this._fb.group({
      ...(section.id ? { id: section.id } : {}),
      content: section.content,
      title: [section.title, Validators.maxLength(50)],
      type: section.type,
      ordernumber: section.ordernumber,
      isrequired: section.isrequired,
    });
  }

  private _buildAttachment(attachment: FileInStorage): FormGroup {
    return this._fb.group({
      ...attachment,
    });
  }

  private _processRequiredSections(sections: ContentEntrySection[]): void {
    const reqSections = sections.map((s) => this._buildSection(s));
    this.contentEntryForm.setControl(
      'requiredSections',
      this._fb.array(reqSections),
    );
  }

  private _processNonRequiredSections(sections: ContentEntrySection[]): void {
    const nonReqSections = sections
      .filter((s) => !s.isrequired)
      .map((s) => this._buildSection(s));
    this.contentEntryForm.setControl(
      'sections',
      this._fb.array(nonReqSections),
    );
  }

  private _processSections(sections: ContentEntrySection[]) {
    // build required
    this._processRequiredSections(
      this.sections.filter((s) => s.isrequired && s.type !== 'subtitle'),
    );
    // build non-required
    this._processNonRequiredSections(
      this.sections.filter((s) => !s.isrequired),
    );
  }

  private _getOrderNumber(): number {
    return this.entrySections.length + 4 + 1;
  }

  private _formHasChanged(): boolean {
    const fV = this.contentEntryForm.value;
    // Title and sections need to be updated to reflect subtitle
    const title = this.activeEntry.fromdatasync
      ? this.activeEntry.name // original title, as can't be changed
      : fV.title; // form title as can be changed
    const sections = this._constructSections(fV).map((s) => {
      if (s.type === 'title') {
        return {
          ...s,
          content: this.activeEntry.fromdatasync
            ? fV.patientTitle // if from datasync patient title exists
            : title, // else use title given above
        };
      } else {
        return s;
      }
    });
    // only pick the relevant sections of content that might
    // be changed
    const currentValues = {
      ...pick(fV, [
        'title',
        'comments',
        'category',
        'attachments',
        'sendtoall',
      ]),
      sections,
    };

    const originalValues = {
      category: this.activeEntry.contentcategory,
      comments: this.activeEntry.comments,
      title: this.activeEntry.name,
      sections: this.activeEntry.sections,
      attachments: this.activeEntry.attachments,
      sendtoall: this.activeEntry.sendtoall || false,
    };
    // don't compare title if fromdatasync as title is locked
    // patient friendly title exists within sections so is checked in both scenarios
    const toCompare = this.activeEntry.fromdatasync
      ? ['comments', 'category', 'attachments', 'sections', 'sendtoall']
      : [
          'title',
          'comments',
          'category',
          'attachments',
          'sections',
          'sendtoall',
        ];

    return toCompare.some(
      (key) => !equal(currentValues[key], originalValues[key]),
    );
  }

  public checkIntroError(status: boolean) {
    this.introductionError.next(status);
  }

  public setOrderOfSections(contentSections: ContentEntrySection[]) {
    const mappedSections = contentSections.map((s, i) => ({
      ...s,
      ordernumber: i + 4 + 1,
    }));
    const nonReqSections = mappedSections.map((s) => this._buildSection(s));
    this.contentEntryForm.setControl(
      'sections',
      this._fb.array(nonReqSections),
    );
  }

  public exitForm() {
    this._navigationService.navigate([
      'content',
      'list',
      this.activeEntry.contentcategoryid,
    ]);
  }

  public removeForm(id: number) {
    this.entrySections.removeAt(id);
  }

  public removeAttachment(id: number) {
    this.attachments.removeAt(id);
    this.attachmentExists$.next(this.attachments.length > 0);
    this.change.detectChanges();
  }

  public addEntry() {
    this.entrySections.push(
      this._buildSection(
        new NewContentEntrySection({
          ordernumber: this._getOrderNumber(),
        }),
      ),
    );
  }

  private _getCategoryId(type: string): number {
    for (const category of this.categories) {
      if (category.name === type) {
        return category.id;
      }
    }
    // if not found return 0
    return 0;
  }

  public save() {
    const language =
      this.languageCode === 'default'
        ? this.languages.find((l) => l.isdefault)
        : this.languages.find((l) => l.code === this.languageCode);
    const messageText = this.isMultiLingual
      ? this.controlsText.PublishDialogWithLanguageMessage(language.name)
      : this.controlsText.PublishDialogMessage;

    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        information: this.isMultiLingual
          ? this.controlsText.PublishDialogInformation
          : undefined,
        message: messageText,
        text: {
          Cancel: this.controlsText.Cancel,
          Confirm: this.controlsText.Publish,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this._performSave();
      }
    });
  }

  // If either the title or introduction is empty then the entry should be saved as isEmpty
  public _isEmpty(entry: ContentEntry) {
    if (entry.isfileonly) {
      return (
        !entry.name || entry.name.length === 0 || entry.attachments.length === 0
      );
    } else {
      return entry.sections.every((s) =>
        s.type === 'title' || s.type === 'subtitle'
          ? true
          : s.content.length === 0 || s.content === null,
      );
    }
  }

  private _constructSections(newEntry) {
    const requiredSections = newEntry.requiredSections;
    const nonrequiredSections = newEntry.sections;
    const subtitle = {
      ...this.subtitle,
      content: newEntry.subtitle,
    };
    const allRequired = requiredSections
      .concat([subtitle])
      .sort((a, b) => a.ordernumber - b.ordernumber);
    const allSections = allRequired.concat(nonrequiredSections);
    return allSections;
  }

  public _performSave() {
    const newEntry = this.contentEntryForm.value;
    const categoryId = this._getCategoryId(newEntry.category);
    const updatedSections = this._constructSections(newEntry);
    const attachments = newEntry.attachments;

    // Check introduction section is less than 1000 characters
    const introduction = updatedSections.find(
      (s) => s.title === 'Introduction',
    );
    if (
      !this.activeEntry.isfileonly &&
      !this.activeEntry.parentid &&
      (introduction.content.length === 0 || !introduction.content)
    ) {
      this.introductionError.next(true);
    } else {
      const title = this.activeEntry.fromdatasync
        ? this.activeEntry.name
        : newEntry.title;
      const updatedSectionsWithTitle = updatedSections.map((s) => {
        if (s.type === 'title') {
          return {
            ...s,
            content: this.activeEntry.fromdatasync
              ? newEntry.patientTitle
              : title,
          };
        } else {
          return s;
        }
      });
      const entryToSave = {
        id: this.activeEntry.id,
        contentcategoryid: categoryId,
        contentcategory: newEntry.category,
        // if fromdatasync, name is disabled so need
        // to use the original name
        name: title,
        comments: newEntry.comments,
        // if fromdatasync is undefined it is false
        fromdatasync: this.activeEntry.fromdatasync || false,
        // automatically remove wasmigrated once amended
        wasmigrated: false,
        isfileonly: this.activeEntry.isfileonly,
        ishidden: this.activeEntry.ishidden,
        // isempty is calculated below
        isempty: false,
        isactive: this.activeEntry.isactive,
        sendtoall: newEntry.sendtoall,
        attachments,
        sections: updatedSectionsWithTitle,
      };

      this.activeEntry = {
        ...entryToSave,
        isempty: this._isEmpty(entryToSave),
      };

      for (let i = 0; i < this.activeEntry.sections.length; i++) {
        const section = this.activeEntry.sections[i];
        section.ordernumber = i + 1;
      }

      if (this.activeEntry.id == null) {
        this._store.dispatch(
          new ContentActions.CreateEntry({
            contentEntry: this.activeEntry,
            languageCode: this.languageCode,
            parentId: this.parentId,
          }),
        );
      } else {
        this._store.dispatch(
          new ContentActions.SaveEntry({
            contentEntry: this.activeEntry,
            languageCode: this.languageCode,
          }),
        );
      }
    }
  }

  public applyTemplate(applyTemplateOptions: ApplyTemplate): void {
    const method = applyTemplateOptions.options.method;
    const sections = applyTemplateOptions.template.entries;
    method === 'append'
      ? this._appendTemplate(sections)
      : this._overwriteTemplate(sections);
  }

  // This appends each section from the chosen template to the current form.
  private _appendTemplate(sections: ContentTemplateEntry[]): void {
    sections
      .filter((s) => !s.isrequired)
      .map(
        (s, i) =>
          new NewContentEntrySection({
            ordernumber: this._getOrderNumber() + i,
            title: s.title,
          }),
      )
      .forEach((e) => {
        this.entrySections.push(this._buildSection(e));
      });
  }

  // this clears the current form sections and then adds the sections from
  // the chosen template
  private _overwriteTemplate(sections: ContentTemplateEntry[]): void {
    const sectionsToAdd = sections
      .filter((s) => !s.isrequired)
      .map(
        (s, i) =>
          new NewContentEntrySection({
            ordernumber: s.ordernumber,
            title: s.title,
            type: s.type,
          }),
      );
    this._processNonRequiredSections(sectionsToAdd);
  }

  // add file to content
  public openFileDialog(isEmbedded, isFileOnly, type) {
    // had a valid title been added?
    const titleIsInvalid = this.contentEntryForm.get('title').invalid;
    if (!titleIsInvalid) {
      const title = this.contentEntryForm.get('title').value;
      if (this.attachments.length === 0) {
        const validAccepts = this._S3.fetchAccepts(type);

        const fileDialog = this.dialog.open(AddFileComponent, {
          data: {
            contentEntry: this.contentEntry,
            accept: validAccepts,
            fileName: title,
            public: false,
            patientOnly: false,
          },
        });
        fileDialog
          .afterClosed()
          .subscribe((file: { attachment: FileInStorage }) => {
            if (file && file.attachment) {
              this.attachments.push(this._buildAttachment(file.attachment));
              this.attachmentExists$.next(this.attachments.length > 0);
              this.change.markForCheck();
              this.change.detectChanges();
            }
          });
      }
    }
  }

  public openViewChildrenModal() {
    this.dialog.open(ViewChildrenContainer, {
      data: {
        parentId: this.contentEntry.id,
        contentId: null,
        categoryId: this.contentEntry.contentcategoryid,
      },
      width: '550px',
    });

    this._subs.add(
      this._updates$
        .pipe(
          ofType<DeleteAllContentLinksSuccess>(
            LinkContentActionTypes.DeleteAllContentLinksSuccess,
          ),
        )
        .subscribe(() => {
          this.contentEntry.children = [];
          this.change.markForCheck();
          this.change.detectChanges();
        }),
    );
  }

  public navigateToParent(parentId: number) {
    this._navigationService.navigate([
      'content',
      'doc',
      'edit',
      this.activeEntry.contentcategoryid,
      parentId,
    ]);
  }

  public copyContent(id: number) {
    this._contentService
      .getContentEntryById(id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((res: ContentEntry) => {
        this._copyFields(res.sections);
        this._copyRequiredSections(res.sections);
        return this._appendSections(res.sections);
      });
  }

  // This function copy the source subtitle and patient title to destination content
  private _copyFields(sections: ContentEntrySection[]) {
    const sourceSubtitle = sections.find(
      (item: ContentEntrySection) => item.type === 'subtitle',
    );
    this.contentEntryForm.patchValue({
      subtitle: sourceSubtitle.content,
    });
    this.contentEntryForm.patchValue({
      patientTitle: this._getTitleSectionValue(sections),
    });
  }

  // This function cherry picks and changes the content of the required sections
  private _copyRequiredSections(sections: ContentEntrySection[]) {
    const rawRequiredSections = this.requiredSections.value;
    const introIdx = rawRequiredSections.findIndex(
      (item: ContentEntrySection) => item.type === 'introduction',
    );
    const videoIdx = rawRequiredSections.findIndex(
      (item: ContentEntrySection) => item.type === 'video',
    );

    const sourceIntroSection = sections.find(
      (item: ContentEntrySection) => item.type === 'introduction',
    );
    const sourceVideoSection = sections.find(
      (item: ContentEntrySection) => item.type === 'video',
    );

    if (sourceIntroSection && introIdx > -1) {
      const Intro = this.requiredSections.at(introIdx);
      Intro.patchValue({
        content: sourceIntroSection.content,
      });
    }

    if (sourceVideoSection && videoIdx > -1) {
      const Video = this.requiredSections.at(videoIdx);
      Video.patchValue({
        content: sourceVideoSection.content,
      });
    }
  }

  // This appends each section from the source Content to the destination content.
  private _appendSections(sections: ContentEntrySection[]): void {
    sections
      .filter((section) => !section.isrequired)
      .map(
        (section, index) =>
          new NewContentEntrySection({
            ordernumber: this._getOrderNumber() + index,
            title: section.title,
            content: section.content,
            type: section.type,
          }),
      )
      .forEach((section) => {
        this.entrySections.push(this._buildSection(section));
      });
  }
}
