<div
  *ngIf="loadingPackDetail && packDetail.length === 0"
  class="content-table-container"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <mat-spinner [diameter]="200"> </mat-spinner>
</div>
<div *ngIf="!loadingPackDetail" fxFlexFill>
  <div
    *ngIf="someSelected()"
    class="action-controls"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
  >
    <div class="header">
      {{ text.Actions }}
    </div>
    <button
      mat-raised-button
      class="portal-btn btn-warn"
      (click)="removeItems()"
      [disabled]="!contentEditorEnabled"
      *ngIf="contentEditorEnabled"
    >
      {{ text.RemovePackItems }}
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
  <div
    class="content-table-container"
    [class.empty]="packDetail.length === 0"
    [class.selection]="someSelected()"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <perfect-scrollbar
      fxFlex="auto"
      [config]="config"
      [scrollIndicators]="true"
    >
      <table mat-table [dataSource]="dataSource" class="content-table" matSort>
        <!-- Selection Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [disabled]="!contentEditorEnabled"
              *ngIf="contentEditorEnabled"
            >
            </mat-checkbox>
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            class="selection-row"
            (click)="$event.stopPropagation()"
          >
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [disabled]="!contentEditorEnabled"
              *ngIf="contentEditorEnabled"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ text.Title }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{ element.contentName }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="contentCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ text.ContentCategory }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{ element.contentCategory }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="isEmpty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ text.Empty }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.isEmpty" class="badge is-empty">
              {{ text.Empty }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="isFileOnly">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ text.IsFileOnly }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div></div>
            <div *ngIf="element.isFileOnly" class="badge is-file-only">
              {{ text.IsFileOnly }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="salveModified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ text.DateChanged }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.salveModified | shortDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastModifiedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ text.UpdatedBy }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastModifiedBy | titlecase }}
          </td>
        </ng-container>

        <!-- Header Row -->
        <tr
          mat-header-row
          class="header-row"
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          class="content-list-table-row"
          *matRowDef="let row; columns: displayedColumns"
          (click)="goToContent(row)"
        ></tr>
      </table>
    </perfect-scrollbar>
    <mat-paginator
      *ngIf="packDetail.length > 0"
      [pageSize]="15"
      [pageSizeOptions]="[5, 10, 15, 20, 50]"
      showFirstLastButtons
    ></mat-paginator>
    <div
      *ngIf="packDetail.length === 0"
      class="empty-message"
      fxFlexFill
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
    >
      <div>
        {{ text.NoContent }}
      </div>
      <div *ngIf="currentPack.isDefault">
        {{ text.SendToAll }}
      </div>
      <button
        *ngIf="!filterActive && !currentPack.isDefault && contentEditorEnabled"
        mat-raised-button
        class="portal-btn btn-warn"
        (click)="deletePack.emit(packDetailId)"
        [disabled]="!contentEditorEnabled"
      >
        {{ text.DeletePack }}
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </div>
</div>
