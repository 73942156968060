import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromSettings from '../../../settings/reducers';
import * as fromAuth from '../../reducers';
import * as AuthActions from '../../actions/auth.actions';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { Authenticate } from '../../models/user';
var LoginPageContainer = /** @class */ (function () {
    function LoginPageContainer(_store, _storeRoot, _navigationService) {
        this._store = _store;
        this._storeRoot = _storeRoot;
        this._navigationService = _navigationService;
        this._subs = new Subscription();
        this._store.dispatch(new AuthActions.ClearLoginError());
        this.toastText$ = this._storeRoot.pipe(select(fromSettings.getSectionTranslations('Authentication')));
        this.loginText$ = this._storeRoot.pipe(select(fromSettings.getSectionTranslations('Login')));
        this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
        this.cognitoUser$ = this._store.pipe(select(fromAuth.getCognitoUser));
        this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
        this.loggingIn$ = this._store.pipe(select(fromAuth.isLoggingIn));
        this.error$ = this._store.pipe(select(fromAuth.getLoginPageError));
        this._store.dispatch(new AuthActions.ClearCodeGenerated());
    }
    LoginPageContainer.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this.loginText$.subscribe(function (t) {
            _this.loginText = t;
        }));
        this._store.dispatch(new AuthActions.EnableForm());
    };
    LoginPageContainer.prototype.onSubmit = function (_a) {
        var isCore = _a.isCore, auth = _a.auth, usedEmail = _a.usedEmail;
        this._coreLogin(auth);
    };
    LoginPageContainer.prototype.forgotPassword = function () {
        this._navigationService.navigate(['/forgot']);
    };
    LoginPageContainer.prototype._coreLogin = function (auth) {
        this._store.dispatch(new AuthActions.CoreLogin(auth));
    };
    return LoginPageContainer;
}());
export { LoginPageContainer };
