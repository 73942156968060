import { Inject, Injectable } from '@angular/core';

export type PermissionResult = 'granted' | 'denied' | 'prompt';

@Injectable()
export class PermissionsService {
  constructor(@Inject('Window') private readonly window: Window) {}

  public async hasCameraPermission(): Promise<PermissionResult> {
    return this.getPermissionResult('camera');
  }

  public async hasMicrophonePermission(): Promise<PermissionResult> {
    return this.getPermissionResult('microphone');
  }

  private async getPermissionResult(
    permission: string,
  ): Promise<PermissionResult> {
    if (!('permissions' in this.window.navigator)) {
      return 'prompt';
    }

    try {
      // @ts-ignore our version of TypeScript doesn't have these types
      const result = await this.window.navigator.permissions.query({
        // @ts-ignore our version of TypeScript doesn't have these types
        name: permission,
      });

      return result.state;
    } catch (err) {
      return 'prompt';
    }
  }
}
