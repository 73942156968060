import { Locale } from 'date-fns';
import {
  de as deLocale,
  enGB as enLocale,
  enUS as usLocale,
  es as esLocale,
  pt as ptLocale,
} from 'date-fns/locale';
import { useContext } from 'react';
import { SupportedLanguage } from 'src/app/settings/data/supported-languages';
import { LanguageContext } from '../i18n';
import { DateTime } from './service';

const languageLocaleMap: {
  [key in SupportedLanguage]: Locale;
} = {
  'en-gb': enLocale,
  'en-us': usLocale,
  de: deLocale,
  es: esLocale,
  pt: ptLocale,
};

export function useLocaleDateTime() {
  const { language } = useContext(LanguageContext);
  const locale = languageLocaleMap[language];

  return new DateTime(locale);
}
