import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { parseInteger, zeroPad } from '../utils/generic';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[inputMax]',
})
export class InputMaxDirective {
  constructor(private elRef: ElementRef) {}

  @Input() inputMax: number;
  @Input() inputMaxLength: number;
  @HostListener('input', ['$event']) onInput(e) {
    const element = this.elRef.nativeElement;
    if (!element.value) {
      return;
    }
    const value = parseInteger(element.value);
    if (value > this.inputMax) {
      element.value = zeroPad(this.inputMax, this.inputMaxLength);
    }
  }
}

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[inputMin]',
})
export class InputMinDirective {
  constructor(private elRef: ElementRef) {}

  @Input() inputMin: number;
  @Input() inputMaxLength: number;
  @HostListener('input', ['$event']) onInput(e) {
    const element = this.elRef.nativeElement;
    if (!element.value) {
      return;
    }
    const value = parseInteger(element.value);
    if (value < this.inputMin) {
      element.value = zeroPad(this.inputMin, this.inputMaxLength);
    }
  }
}
