import * as tslib_1 from "tslib";
import { MenuItem } from '@material-ui/core';
import { Action } from '@react/pages/conversations/constants';
import clsx from 'clsx';
import React, { useState } from 'react';
import { AssignMenuItem } from './AssignMenuItem';
import { StyledMenu } from './Menu.styled';
import NestedMenuItem from './NestedMenuItem';
export var DropdownMenu = function (_a) {
    var menu = _a.menu, children = _a.children, onClick = _a.onClick, className = _a.className, NestedMenuProps = _a.NestedMenuProps, assignClinicUsers = _a.assignClinicUsers, dropdownProps = tslib_1.__rest(_a, ["menu", "children", "onClick", "className", "NestedMenuProps", "assignClinicUsers"]);
    if (!children) {
        throw new TypeError('children trigger element is required');
    }
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var isOpen = Boolean(anchorEl);
    var handleMenuOpen = function (event) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function (item) {
        setAnchorEl(null);
        if (item) {
            onClick(item);
        }
    };
    function showClinicUserMenuAction(action) {
        return Boolean(action === Action.CONVERSATION_ASSIGN ||
            action === Action.CONVERSATION_UNASSIGN);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { onClick: handleMenuOpen }, children),
        React.createElement(StyledMenu, tslib_1.__assign({ className: clsx('dropdown-menu', className), anchorEl: anchorEl, open: isOpen, onClose: function () { return handleClose(); }, onClick: function (event) { return event.stopPropagation(); } }, dropdownProps), menu.map(function (item) {
            if (showClinicUserMenuAction(item.actionId) && assignClinicUsers) {
                return (React.createElement(AssignMenuItem, { key: item.name, item: item, isOpen: isOpen, nestedMenuProps: NestedMenuProps, assignClinicUsers: assignClinicUsers, handleClose: handleClose }));
            }
            if (!item.children) {
                return (React.createElement(MenuItem, { key: item.name, onClick: function () { return handleClose(item); } }, item.name));
            }
            return (
            // @ts-ignore
            React.createElement(NestedMenuItem, { key: item.name, label: item.name, parentMenuOpen: isOpen, MenuProps: NestedMenuProps }, item.children.map(function (child) { return (React.createElement(MenuItem, { key: child.name, onClick: function () { return handleClose(child); } }, child.name)); })));
        }))));
};
