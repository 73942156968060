import * as tslib_1 from "tslib";
import { Validators } from '@angular/forms';
import { PatientSearchBase } from 'src/app/patients/PatientSearchBase';
import { isPatient } from '../utils/validators';
var PatientRequiringFormBase = /** @class */ (function (_super) {
    tslib_1.__extends(PatientRequiringFormBase, _super);
    function PatientRequiringFormBase(_patientService, _localisationService) {
        return _super.call(this, _patientService, _localisationService) || this;
    }
    PatientRequiringFormBase.prototype.setupSearchBox = function (formGroup) {
        _super.prototype.observeSearchInput.call(this, formGroup.get('patientName'));
    };
    PatientRequiringFormBase.prototype.getFormPatient = function () {
        return [
            {
                value: this._patient || '',
                disabled: !!this._patient,
            },
            [Validators.required, Validators.minLength(1)],
            [isPatient(this.patients$)],
        ];
    };
    return PatientRequiringFormBase;
}(PatientSearchBase));
export { PatientRequiringFormBase };
