import { ConversationApiValue } from '@react/lib/api/types';
import { useBrowserNotifications, usePrevious } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { isBefore } from 'date-fns';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { constructMessageDeepLink } from '../lib';
import { FilterState } from '../types';
import { useLatestConversationsQuery } from './useLatestConversationsQuery';

function shouldTrigger(
  filters: FilterState,
  latestConvo: ConversationApiValue,
  previousFilters?: FilterState,
  previousLatest?: ConversationApiValue,
) {
  if (!previousLatest) {
    return false;
  }

  if (filters !== previousFilters) {
    return false;
  }

  if (
    isBefore(new Date(latestConvo.sentdate), new Date(previousLatest.sentdate))
  ) {
    return false;
  }

  const isSameMessage =
    latestConvo.messageitemid === previousLatest.messageitemid;

  return !isSameMessage && latestConvo.patientsent;
}

function focusBrowserTab() {
  window.parent.parent.focus();
}

export function useMessagingNotifications(filters: FilterState) {
  const { t } = useTranslations();
  const history = useHistory();
  const { showNotification } = useBrowserNotifications();
  const { data } = useLatestConversationsQuery(filters);
  const latestConvo = data && data.data[0];

  const previousLatest = usePrevious(latestConvo);
  const previousFilters = usePrevious(filters);

  const deepLinkFilters: FilterState = {
    ...filters,
    isResolved: false,
    isStarred: undefined,
    q: undefined,
  };

  useEffect(() => {
    if (
      latestConvo &&
      shouldTrigger(filters, latestConvo, previousFilters, previousLatest)
    ) {
      showNotification({
        title: t.MessagingNotifications.NotificationTitle,
        body: t.MessagingNotifications.NotificationBodyMessage(
          `${latestConvo.patientfirstname} ${latestConvo.patientlastname}`,
        ),
        onClick: () => {
          focusBrowserTab();
          history.push(constructMessageDeepLink(latestConvo, deepLinkFilters));
        },
      });
    }
  }, [latestConvo]);
}
