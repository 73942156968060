import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-nav-message',
  templateUrl: `./nav-message.component.html`,
  styleUrls: [`./nav-message.component.scss`],
})
export class NavMessageComponent {
  @Input() tempMessage: string;
}
