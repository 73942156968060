import { ModuleWithProviders } from '@angular/core';
import { FullDate, JustTime, LongDate, LongDateWithTime, MidDateWithTime, ShortDate, ShortDateWithTime, ShortNumericDate, } from './date.formating.pipes';
import { LocalisationService } from './localisation.service';
var PIPES = [
    ShortDate,
    ShortDateWithTime,
    ShortNumericDate,
    LongDate,
    LongDateWithTime,
    MidDateWithTime,
    JustTime,
    FullDate,
];
var LocalisationModule = /** @class */ (function () {
    function LocalisationModule() {
    }
    LocalisationModule.forRoot = function () {
        return {
            ngModule: LocalisationModule,
            providers: [
                { provide: LocalisationService, useClass: LocalisationService },
            ],
        };
    };
    return LocalisationModule;
}());
export { LocalisationModule };
