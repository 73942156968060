import { IconButton, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Dialog } from '@react/components';
import { StyledMenu } from '@react/components/Menu.styled';
import { CognitoStatus, StaffApiValue } from '@react/lib/api/types';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import {
  useDeactivateClinicUserMutation,
  useTemporaryPasswordMutation,
} from '@react/pages/admin/hooks/users';
import React, {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface Props {
  user: StaffApiValue;
  onClickInviteUser: (user: StaffApiValue) => void;
}

const Actions: FunctionComponent<Props> = ({
  user,
  onClickInviteUser,
}: Props) => {
  const { t } = useTranslations();

  const menuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showTemporaryPasswordModal, setShowTemporaryPasswordModal] =
    useState(false);

  const {
    mutateAsync: deactivateClinicUser,
    isError: isDeactivatingError,
    isSuccess: isDeactivatingSuccess,
  } = useDeactivateClinicUserMutation();

  const {
    mutateAsync: sendTemporaryPassword,
    isError: isTemporaryPasswordError,
    isSuccess: isTemporaryPasswordSuccess,
  } = useTemporaryPasswordMutation();

  useErrorToast(isDeactivatingError, {
    title: t.Admin.Users.DeactivateSentErrorTitle,
    message: t.Admin.Users.DeactivateSentErrorMessage,
  });

  useSuccessToast(isDeactivatingSuccess, {
    title: t.Admin.Users.DeactivateSentTitle,
    message: t.Admin.Users.DeactivateSentMessage,
  });

  useErrorToast(isTemporaryPasswordError, {
    title: t.Admin.Users.SendTemporaryPasswordErrorTitle,
    message: t.Admin.Users.SendTemporaryPasswordErrorDescription,
  });

  useSuccessToast(isTemporaryPasswordSuccess, {
    title: t.Admin.Users.SendTemporaryPasswordSuccessTitle,
    message: t.Admin.Users.SendTemporaryPasswordSuccessDescription,
  });

  useSuccessToast(isDeactivatingSuccess, {
    title: t.Admin.Users.DeactivateSentTitle,
    message: t.Admin.Users.DeactivateSentMessage,
  });

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(menuRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInviteUser = () => {
    handleClose();
    onClickInviteUser(user);
  };

  const handleDeactivateUser = () => {
    handleClose();
    deactivateClinicUser({ staffId: user.id });
  };

  const handleSendTemporaryPassword = () => {
    sendTemporaryPassword({ staffId: user.id });
    handleCloseTemporaryPasswordModal();
  };

  const handleSendTemporaryPasswordModal = () => {
    handleClose();
    setShowTemporaryPasswordModal(true);
  };

  const handleCloseTemporaryPasswordModal = () => {
    setShowTemporaryPasswordModal(false);
  };

  const ActionButton = React.forwardRef((_, ref) => {
    switch (user.status) {
      case CognitoStatus.Deactivated: {
        return (
          <>
            <MenuItem innerRef={ref} key="invite" onClick={handleInviteUser}>
              <div>{t.Admin.Users.InviteUserAction}</div>
            </MenuItem>
            <MenuItem
              innerRef={ref}
              key="temporaryPassword"
              onClick={handleSendTemporaryPasswordModal}
            >
              <div>{t.Admin.Users.SendTemporaryPassword}</div>
            </MenuItem>
          </>
        );
      }
      case CognitoStatus.Active: {
        return (
          <>
            <MenuItem
              innerRef={ref}
              key="deactivate"
              onClick={handleDeactivateUser}
            >
              <div>{t.Admin.Users.DeactivateUserAction}</div>
            </MenuItem>
            <MenuItem
              innerRef={ref}
              key="temporaryPassword"
              onClick={handleSendTemporaryPasswordModal}
            >
              <div>{t.Admin.Users.SendTemporaryPassword}</div>
            </MenuItem>
          </>
        );
      }
      default: {
        return null;
      }
    }
  });

  return (
    <>
      <Dialog
        acceptButtonText={t.Admin.Users.Send}
        cancelButtonText={t.Admin.Users.Cancel}
        contentText={t.Admin.Users.SendTemporaryPasswordModalText}
        onAccept={handleSendTemporaryPassword}
        onCancel={handleCloseTemporaryPasswordModal}
        isOpen={showTemporaryPasswordModal}
        titleText={t.Admin.Users.SendTemporaryPasswordModalTitle}
      />

      <span onClick={handleOpen}>
        <IconButton
          ref={menuRef}
          aria-controls="simple-menu"
          aria-haspopup="true"
          size="small"
          data-testid="admin-users-action-button"
        >
          <MoreVert />
        </IconButton>
      </span>
      <StyledMenu
        data-testid="admin-users-action-menu"
        id="admin-users-action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <ActionButton />
      </StyledMenu>
    </>
  );
};

export default Actions;
