import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, ListItem, ListItemText, Tabs } from '@material-ui/core';
import { SearchBar } from '@react/components';

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #cfcfcf;
  width: 300px;
  min-height: 32px;

  .MuiTab-root {
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
    min-height: 32px;
    min-width: auto;
  }
`;

export const StyledTabPanel = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  height: 220px;
  overflow: auto;

  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledActionsContainer = styled.div`
  border-top: 1px solid #cfcfcf;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  display: flex;
  justify-content: flex-end;

  .MuiButton-root {
    height: 28px;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;

  &.selected {
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiListItemText-root {
    flex: none;
  }
`;

type Props = React.ComponentProps<typeof ListItemText> & {
  weight?: string;
};

const boldStyle = () => css`
  font-weight: bold;
`;

export const StyledListItemText = styled(ListItemText)<Props>`
  span {
    ${({ weight }) => weight === 'bold' && boldStyle}
  }
`;

export const StyledSearchBar = styled(SearchBar)`
  position: sticky;
  top: 0;
  z-index: 30;
  height: 32px;
`;
