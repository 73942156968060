import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'portal-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPreviewComponent implements OnChanges {
  @Input() html: string;

  public videoSrc?: string;
  public videoPreview?: SafeHtml;

  constructor(public santizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.html &&
      changes.html.currentValue !== changes.html.previousValue
    ) {
      if (
        changes.html.currentValue == null ||
        changes.html.currentValue === ''
      ) {
        this.videoPreview = undefined;
        this.videoSrc = undefined;
      }

      const srcMatches = changes.html.currentValue.includes('iframe')
        ? changes.html.currentValue.match(/src\s*=\s*"([^"]+)"/)
        : null;

      if (srcMatches == null) {
        return;
      }

      const source = srcMatches[1];
      const isYouTube = source.includes('youtube');

      if (isYouTube) {
        this.videoPreview = this.santizer.bypassSecurityTrustHtml(
          changes.html.currentValue,
        );
      } else {
        this.videoSrc = source;
      }
    }
  }
}
