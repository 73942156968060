import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Tabs as MuiTabs } from '@material-ui/core';
export var Tabs = styled(MuiTabs)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  border-bottom: solid 1px ", ";\n  width: 100%;\n  height: 48px;\n\n  & .MuiTabs-indicator {\n    background-color: ", ";\n  }\n\n  & ~ [role='tabpanel'] {\n    height: calc(100% - 48px);\n  }\n"], ["\n  border-bottom: solid 1px ", ";\n  width: 100%;\n  height: 48px;\n\n  & .MuiTabs-indicator {\n    background-color: ", ";\n  }\n\n  & ~ [role='tabpanel'] {\n    height: calc(100% - 48px);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border.dark;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var templateObject_1;
