import { select, Store } from '@ngrx/store';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import * as fromAuth from '../auth/reducers';
import { LocalisationService } from '../localisation/localisation.service';
import * as fromSettings from '../settings/reducers';
import { GetContentEntries, GetContentTypes } from './actions/content-assignment.actions';
import { State } from './reducers';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true
};
var ɵ0 = adapterFactory, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var PatientsModule = /** @class */ (function () {
    function PatientsModule(_store, _localisationService) {
        this._store = _store;
        this._localisationService = _localisationService;
        this._subs = new Subscription();
        this.selectedLanguage$ = this._store.pipe(select(fromSettings.getCurrentLanguage));
        var loggedIn = false;
        this._subs.add(
        // tslint:disable-next-line: no-shadowed-variable
        this._store.pipe(select(fromAuth.getLoggedIn)).subscribe(function (loggedIn) {
            loggedIn = loggedIn;
        }));
        // Subscribe to language changes
        this._subs.add(this.selectedLanguage$.subscribe(function (language) {
            _localisationService.setLocale(language);
        }));
        // fetch latest content types and entries
        if (loggedIn) {
            this._store.dispatch(new GetContentTypes());
            this._store.dispatch(new GetContentEntries());
        }
    }
    return PatientsModule;
}());
export { PatientsModule };
export { ɵ0, ɵ1 };
