import { PipeTransform } from '@angular/core';
import { LocalisationService } from './localisation.service';
var ShortDate = /** @class */ (function () {
    function ShortDate(_localisationService) {
        this._localisationService = _localisationService;
    }
    ShortDate.prototype.transform = function (value) {
        return this._localisationService.getShortDate(value);
    };
    return ShortDate;
}());
export { ShortDate };
var ShortDateWithTime = /** @class */ (function () {
    function ShortDateWithTime(_localisationService) {
        this._localisationService = _localisationService;
    }
    ShortDateWithTime.prototype.transform = function (value) {
        return this._localisationService.getShortDateWithTime(value);
    };
    return ShortDateWithTime;
}());
export { ShortDateWithTime };
var ShortNumericDate = /** @class */ (function () {
    function ShortNumericDate(_localisationService) {
        this._localisationService = _localisationService;
    }
    ShortNumericDate.prototype.transform = function (value) {
        return this._localisationService.getShortNumericDate(value);
    };
    return ShortNumericDate;
}());
export { ShortNumericDate };
var LongDate = /** @class */ (function () {
    function LongDate(_localisationService) {
        this._localisationService = _localisationService;
    }
    LongDate.prototype.transform = function (value) {
        return this._localisationService.getLongDate(value);
    };
    return LongDate;
}());
export { LongDate };
var FullDate = /** @class */ (function () {
    function FullDate(_localisationService) {
        this._localisationService = _localisationService;
    }
    FullDate.prototype.transform = function (value) {
        return this._localisationService.getFullDate(value);
    };
    return FullDate;
}());
export { FullDate };
var LongDateWithTime = /** @class */ (function () {
    function LongDateWithTime(_localisationService) {
        this._localisationService = _localisationService;
    }
    LongDateWithTime.prototype.transform = function (value) {
        return this._localisationService.getLongDateWithTime(value);
    };
    return LongDateWithTime;
}());
export { LongDateWithTime };
var MidDateWithTime = /** @class */ (function () {
    function MidDateWithTime(_localisationService) {
        this._localisationService = _localisationService;
    }
    MidDateWithTime.prototype.transform = function (value) {
        return this._localisationService.getMidDateWithTime(value);
    };
    return MidDateWithTime;
}());
export { MidDateWithTime };
var JustTime = /** @class */ (function () {
    function JustTime(_localisationService) {
        this._localisationService = _localisationService;
    }
    JustTime.prototype.transform = function (value) {
        return this._localisationService.getOnlyTime(value);
    };
    return JustTime;
}());
export { JustTime };
