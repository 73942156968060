<div *ngIf="integrated">
  <div
    matTooltip="{{ text.Tooltip(_version) }}"
    class="status"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
  >
    <div
      class="blob"
      [class.green]="synchronised"
      [class.error]="!synchronised"
    ></div>
    <div>{{ text.SalveConnection }}</div>
  </div>
</div>
