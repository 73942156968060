import {
  PatientActionsUnion,
  PatientActionTypes,
} from '../actions/patient.actions';
import { ClinicPatientResponse } from '../models/responses/clinic-patient.response';

export interface PatientHash {
  [key: number]: ClinicPatientResponse;
}

export interface State {
  patients: PatientHash;
}

export const initialState: State = {
  patients: {},
};

export function reducer(state = initialState, action: PatientActionsUnion) {
  switch (action.type) {
    case PatientActionTypes.LoadPatientInformationSuccess: {
      return {
        ...state,
        patients: {
          ...state.patients,
          [action.payload.patientId]: action.payload.response,
        },
      };
    }
    case PatientActionTypes.ClearAllPatientInformation: {
      return initialState;
    }
    default:
      return state;
  }
}

// Selectors
export const getPatients = (state: State) => state.patients;
