import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Star as StarIcon } from '@material-ui/icons';
import { Chip } from '@react/components';
export var StyledConversationCard = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  padding: 0.75rem 1rem;\n  cursor: pointer;\n\n  * {\n    font-weight: lighter;\n  }\n\n  .patient-dob {\n    display: block;\n    margin-top: ", "px;\n    margin-bottom: ", "px;\n  }\n\n  .content {\n    margin-bottom: ", "px;\n    overflow: hidden;\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n\n    span {\n      font-weight: normal;\n    }\n  }\n\n  &.conversation-card__selected {\n    background-color: ", ";\n  }\n\n  &.conversation-card__highlight {\n    .patient-name,\n    .patient-dob,\n    .created-at {\n      font-weight: bold;\n    }\n  }\n\n  .typing-indicator {\n    font-size: 0.9rem;\n  }\n"], ["\n  padding: 0.75rem 1rem;\n  cursor: pointer;\n\n  * {\n    font-weight: lighter;\n  }\n\n  .patient-dob {\n    display: block;\n    margin-top: ", "px;\n    margin-bottom: ", "px;\n  }\n\n  .content {\n    margin-bottom: ", "px;\n    overflow: hidden;\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n\n    span {\n      font-weight: normal;\n    }\n  }\n\n  &.conversation-card__selected {\n    background-color: ", ";\n  }\n\n  &.conversation-card__highlight {\n    .patient-name,\n    .patient-dob,\n    .created-at {\n      font-weight: bold;\n    }\n  }\n\n  .typing-indicator {\n    font-size: 0.9rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryLight;
});
export var Star = styled(StarIcon, {
    shouldForwardProp: function (prop) { return prop !== 'active'; },
})(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  cursor: pointer;\n  color: ", ";\n  margin-right: ", "px;\n\n  path {\n    stroke: ", ";\n  }\n"], ["\n  cursor: pointer;\n  color: ",
    ";\n  margin-right: ", "px;\n\n  path {\n    stroke: ", ";\n  }\n"])), function (_a) {
    var active = _a.active, theme = _a.theme;
    return active ? theme.colors.yellow : 'transparent';
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var active = _a.active;
    return (active ? '' : '#9b9b9b');
});
export var Header = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  .patient-name {\n    font-weight: normal;\n    width: 50%;\n  }\n\n  .created-at {\n    margin-left: auto;\n    margin-right: ", "px;\n    width: 200px;\n    text-align: right;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  .patient-name {\n    font-weight: normal;\n    width: 50%;\n  }\n\n  .created-at {\n    margin-left: auto;\n    margin-right: ", "px;\n    width: 200px;\n    text-align: right;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var ReadIndicator = styled('div', {
    shouldForwardProp: function (prop) { return ['read', 'badge'].every(function (key) { return key !== prop; }); },
})(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  width: 18px;\n  height: 18px;\n\n  ", "\n\n  ", "\n"], ["\n  width: 18px;\n  height: 18px;\n\n  ",
    "\n\n  ",
    "\n"])), function (_a) {
    var badge = _a.badge, theme = _a.theme;
    return badge &&
        "\n  background-color: " + theme.colors.coral + ";\n  border-radius: 18px;\n  ";
}, function (_a) {
    var badge = _a.badge, read = _a.read, theme = _a.theme;
    return !badge &&
        "\n    svg {\n      fill: " + (read ? theme.colors.coral : '#999999') + "\n    }\n  ";
});
export var ChipList = styled.div(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  flex-wrap: wrap;\n  display: inline-flex;\n  gap: ", "px;\n  margin-right: ", "px;\n  margin-bottom: ", "px;\n"], ["\n  flex-wrap: wrap;\n  display: inline-flex;\n  gap: ", "px;\n  margin-right: ", "px;\n  margin-bottom: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledChip = styled(Chip)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  max-width: 145px;\n"], ["\n  max-width: 145px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
