<div
  class="content-directory-container"
  fxLayout="column"
  fxLayoutAlign="start stetch"
>
  <div class="top toolbar lg">
    <!-- This is deliberately empty -->
  </div>

  <div
    class="accordion-container"
    fxLayout="column"
    fxLayoutAlign="start stetch"
  >
    <!-- Content Folders Section -->
    <mat-list
      class="list-container list"
      [class.open]="activeDirectory === directoryOptions.folders"
      [class.globalPortal]="isGlobalPortal"
    >
      <div
        class="list-header toolbar"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
        (click)="openDirectorySection('folders')"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div
            class="expand-visual"
            *ngIf="activeDirectory === directoryOptions.folders"
          >
            <mat-icon>expand_more</mat-icon>
          </div>
          <div
            class="expand-visual"
            *ngIf="activeDirectory !== directoryOptions.folders"
          >
            <mat-icon>expand_less</mat-icon>
          </div>
          <div>
            {{ text.Categories }}
          </div>
        </div>
        <button
          portalStopClickPropagation
          matTooltip="{{ text.CreateNewFolder }}"
          mat-mini-fab
          color="primary"
          class="add-new"
          (click)="createFolder.emit()"
          [disabled]="!contentEditorEnabled"
          *ngIf="contentEditorEnabled"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div
        *ngIf="activeDirectory === directoryOptions.folders"
        class="list-scroll-container"
        [class.globalPortal]="isGlobalPortal"
      >
        <div fxFlexFill *ngIf="categories.length === 0">
          <div
            fxFlexFill
            fxLayout="column"
            fxLayoutAlign="center center"
            class="no-items"
          >
            {{ text.NoFolders }}
          </div>
        </div>
        <perfect-scrollbar
          fxFlex="auto"
          [config]="config"
          [scrollIndicators]="true"
        >
          <mat-list-item
            *ngFor="let category of categories"
            class="list-entry"
            [class.active]="
              activeSection === sectionOptions.list &&
              category.id === selectedId
            "
            (click)="goToCategoryList(category)"
          >
            <div
              fxFlex="grow"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="20px"
            >
              <div>
                {{ category.name }}
              </div>
              <button
                class="edit-button"
                mat-mini-fab
                [disabled]="!contentEditorEnabled"
                (click)="editFolderName.emit(category)"
                *ngIf="contentEditorEnabled"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </perfect-scrollbar>
      </div>
    </mat-list>

    <!-- Content Packs Section -->
    <mat-list
      class="list-container list"
      [class.open]="activeDirectory === directoryOptions.packs"
      *ngIf="!isGlobalPortal"
    >
      <div
        class="list-header toolbar"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
        (click)="openDirectorySection('packs')"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div
            class="expand-visual"
            *ngIf="activeDirectory === directoryOptions.packs"
          >
            <mat-icon>expand_more</mat-icon>
          </div>
          <div
            class="expand-visual"
            *ngIf="activeDirectory !== directoryOptions.packs"
          >
            <mat-icon>expand_less</mat-icon>
          </div>
          <div>
            {{ text.Packs }}
          </div>
        </div>
        <button
          portalStopClickPropagation
          matTooltip="{{ text.CreateNewPack }}"
          mat-mini-fab
          color="primary"
          class="add-new"
          (click)="createPack.emit()"
          [disabled]="!contentEditorEnabled"
          *ngIf="contentEditorEnabled"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div
        *ngIf="activeDirectory === directoryOptions.packs"
        class="list-scroll-container"
        [class.globalPortal]="isGlobalPortal"
      >
        <div fxFlexFill *ngIf="packs.length === 0">
          <div
            fxFlexFill
            fxLayout="column"
            fxLayoutAlign="center center"
            class="no-items"
          >
            {{ text.NoPacks }}
          </div>
        </div>
        <perfect-scrollbar
          fxFlex="auto"
          [config]="config"
          [scrollIndicators]="true"
        >
          <mat-list-item
            *ngFor="let pack of packs"
            class="list-entry"
            [class.active]="
              activeSection === sectionOptions.packs && pack.id === selectedId
            "
            (click)="goToContentPack(pack)"
          >
            <div
              fxFlex="grow"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="20px"
            >
              <div>
                {{ pack.packName }}
              </div>
              <button
                portalStopClickPropagation
                class="edit-button"
                mat-mini-fab
                (click)="editPackName.emit(pack)"
                [disabled]="!contentEditorEnabled"
                *ngIf="contentEditorEnabled"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </perfect-scrollbar>
      </div>
    </mat-list>

    <!-- Content Templates Section -->
    <mat-list
      class="list-container list"
      [class.open]="activeDirectory === directoryOptions.templates"
      [class.globalPortal]="isGlobalPortal"
    >
      <div
        class="list-header toolbar"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
        (click)="openDirectorySection('templates')"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div
            class="expand-visual"
            *ngIf="activeDirectory === directoryOptions.templates"
          >
            <mat-icon>expand_more</mat-icon>
          </div>
          <div
            class="expand-visual"
            *ngIf="activeDirectory !== directoryOptions.templates"
          >
            <mat-icon>expand_less</mat-icon>
          </div>
          <div>
            {{ text.Templates }}
          </div>
        </div>
        <button
          portalStopClickPropagation
          matTooltip="{{ text.CreateNewTemplate }}"
          mat-mini-fab
          color="primary"
          class="add-new"
          (click)="goToCreateTemplate()"
          [disabled]="!contentEditorEnabled"
          *ngIf="contentEditorEnabled"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div
        *ngIf="activeDirectory === directoryOptions.templates"
        class="list-scroll-container"
        [class.globalPortal]="isGlobalPortal"
      >
        <div fxFlexFill *ngIf="templates.length === 0">
          <div
            fxFlexFill
            fxLayout="row"
            fxLayoutAlign="center center"
            class="no-items"
          >
            {{ text.NoTemplates }}
          </div>
        </div>
        <perfect-scrollbar
          fxFlex="auto"
          [config]="config"
          [scrollIndicators]="true"
        >
          <mat-list-item
            *ngFor="let template of templates"
            class="list-entry"
            [class.active]="
              activeSection === sectionOptions.template &&
              template.id === selectedId
            "
            (click)="goToTemplateEdit(template)"
          >
            {{ template.name }}
          </mat-list-item>
        </perfect-scrollbar>
      </div>
    </mat-list>
  </div>
</div>
