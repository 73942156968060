import { Observable } from 'rxjs';
var PushNotificationsService = /** @class */ (function () {
    function PushNotificationsService() {
        this.permission = this.isSupported()
            ? Notification.permission
            : 'denied';
    }
    PushNotificationsService.prototype.isSupported = function () {
        return 'Notification' in window;
    };
    PushNotificationsService.prototype.requestPermission = function () {
        var _this = this;
        if ('Notification' in window) {
            // TODO: The callback has been deprecated and is now a promise
            // https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission#syntax
            Notification.requestPermission(function (status) { return (_this.permission = status); });
        }
    };
    PushNotificationsService.prototype.create = function (title, options) {
        var _this = this;
        return new Observable(function (obs) {
            if (!('Notification' in window)) {
                obs.error('Notifications are not available in this environment');
                obs.complete();
            }
            if (_this.permission !== 'granted') {
                obs.error("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            var n = new Notification(title, options);
            n.onshow = function (e) { return obs.next({ notification: n, event: e }); };
            n.onclick = function (e) { return obs.next({ notification: n, event: e }); };
            n.onerror = function (e) { return obs.error({ notification: n, event: e }); };
            n.onclose = function () { return obs.complete(); };
        });
    };
    return PushNotificationsService;
}());
export { PushNotificationsService };
var PushNotificationsModule = /** @class */ (function () {
    function PushNotificationsModule() {
    }
    return PushNotificationsModule;
}());
export { PushNotificationsModule };
