
    <portal-email-otp-form
      [text]="toastText$ | async"
      [loggingIn]="loggingIn$ | async"
      [CodeError]="OTPError$ | async"
      [cognitoUser]="cognitoUser$ | async"
      (verifyOTP)="verifyOTP($event)"
      (cancelOTPLogin)="cancelOTPLogin()"
    >
    </portal-email-otp-form>
  