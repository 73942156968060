import styled from '@emotion/styled';
import { Chip } from '@react/components/Chip';

export const ActiveStyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  background-color: #b8e4de;
`;

export const InvitedStyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  background-color: #ffebb2;
`;

export const InactiveStyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  background-color: #ffd0d2;
`;
