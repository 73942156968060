import * as tslib_1 from "tslib";
import { DashboardActionTypes, } from '../actions/dashboard.actions';
export var initialState = {
    status: {
        integrated: false,
        synchronised: false,
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DashboardActionTypes.LoadClinicStatusSuccess: {
            return tslib_1.__assign({}, state, { status: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getStatus = function (state) { return state.status; };
