<div class="edit-form-container">
  <h2 class="heading">{{ data.text.ChangeFolderName }}</h2>
  <div
    *ngIf="!(dataReady$ | async)"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <form *ngIf="dataReady$ | async" fxLayout="column" [formGroup]="categoryForm">
    <div class="form-field-container">
      <mat-form-field>
        <input
          matInput
          [maxlength]="maxNameLength"
          placeholder="{{ data.text.FolderName }}"
          type="text"
          [formControl]="categoryName"
        />
        <div matSuffix fxLayout="row" fxLayoutAlign="end center">
          <mat-spinner matSuffix *ngIf="checkingName$ | async" [diameter]="14">
          </mat-spinner>
          <button
            mat-button
            mat-icon-button
            aria-label="Clear"
            type="button"
            (click)="clearField(categoryName)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-error *ngIf="categoryName.hasError('required')">{{
          data.text.FolderRequired
        }}</mat-error>
        <mat-error
          *ngIf="
            (categoryName.hasError('minlength') ||
              categoryName.hasError('maxlength')) &&
            !categoryName.hasError('required')
          "
          >{{ data.text.FolderLength }}</mat-error
        >
        <mat-error *ngIf="categoryName.hasError('nameExists')">{{
          data.text.FolderExists
        }}</mat-error>
        <mat-hint align="end" class="hint">
          {{ categoryName.value.length }} / {{ maxNameLength }}
        </mat-hint>
      </mat-form-field>
      <div *ngIf="languages.length">
        <h4>{{ data.text.Translations }}</h4>
        <perfect-scrollbar
          class="translations"
          [config]="scrollConfig"
          [scrollIndicators]="true"
        >
          <div
            class="translation-form-field"
            *ngFor="let lang of languages$ | async"
          >
            <mat-form-field>
              <input
                formControlName="{{ lang.code }}"
                matInput
                placeholder="{{ lang.name }}"
                type="text"
                [maxlength]="maxNameLength"
              />
              <div matSuffix fxLayout="row" fxLayoutAlign="end center">
                <button
                  mat-button
                  mat-icon-button
                  aria-label="Clear"
                  type="button"
                  (click)="clearField(categoryForm.get(lang.code))"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <mat-error
                *ngIf="categoryForm.get(lang.code).hasError('required')"
                >{{ data.text.FolderTranslationRequired }}
              </mat-error>
              <mat-error
                *ngIf="
                  (categoryForm.get(lang.code).hasError('minlength') ||
                    categoryForm.get(lang.code).hasError('maxlength')) &&
                  !categoryForm.get(lang.code).hasError('required')
                "
                >{{ data.text.FolderLength }}
              </mat-error>
              <mat-hint align="end" class="hint">
                {{ categoryForm.get(lang.code).value.length }} /
                {{ maxNameLength }}
              </mat-hint>
            </mat-form-field>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div
      fxFlex="grow"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="15px"
      class="controls"
    >
      <button
        mat-raised-button
        type="button"
        class="portal-btn btn-primary"
        (click)="cancelNameChange()"
      >
        {{ data.text.Cancel }}
      </button>
      <div class="status">
        <div
          *ngIf="savingName$ | async"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
        >
          <mat-spinner [diameter]="36"></mat-spinner>
          <div class="status-text">
            {{ data.text.ChangingFolder }}
          </div>
        </div>
        <div
          *ngIf="savedName$ | async"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
        >
          <mat-icon class="success">check_circle</mat-icon>
          <div class="status-text">
            {{ data.text.ChangingFolderSuccess }}
          </div>
        </div>
        <div
          *ngIf="errorEncountered$ | async"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
        >
          <mat-icon class="error">highlight_off</mat-icon>
          <div class="status-text">
            {{ data.text.ChangingFolderError }}
          </div>
        </div>
      </div>
      <button
        mat-raised-button
        class="portal-btn btn-bright"
        type="submit"
        [disabled]="
          categoryForm.invalid || categoryForm.pending || !categoryForm.dirty
        "
        (click)="saveNameChange()"
      >
        {{ data.text.Save }}
      </button>
    </div>
  </form>
</div>
