import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
  @Input() languages: string[];
  @Input() activeLanguage: string;
  @Output() setLanguage = new EventEmitter<string>();

  public settingsForm: FormGroup;

  private _subs = new Subscription();

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.settingsForm = this._fb.group({
      language: [
        {
          value: this.activeLanguage,
          disabled: false,
        },
      ],
    });

    this._subs.add(
      this.settingsForm.get('language').valueChanges.subscribe((language) => {
        this.setLanguage.emit(language);
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
