/**
 * A thread (conversation) represents a series of messages
 * that belong to the same conversation - assumed to be
 * between a clinc and a single patient.
 *
 * A thread can be identified as having a specific
 * MessageId and will be either resolved or unresolved.
 *
 * Messages within the thread may be read or unread.
 *
 * If a thread contains a message that is unread,
 * the thread should be marked as unread.
 */
import { Message } from './Message';

export class Thread {
  public id: number; // this will match the messageId
  public typeId: number; // this is the label id applied to the thread
  public type: string; // this is the label applied to the thread
  public patientName: string; // the patients firstName and lastName combined.
  public patientFirstName: string; // the patients firstName and lastName combined.
  public patientId: number;
  public patientIdentifier: string; // patients identifier
  public patientDoB: Date; // the patients date of birth
  public patientSent: boolean; // was the most recent message sent from the patient
  public isResolved: boolean; // has this conversation been marked as resolved
  public isUnread: boolean | null; // if the thread has any unread messages this will be true
  public messages: Message[]; // the messages associated with this thread.
  public isReadOnly: boolean;
  public hasNewMessages: boolean;
  public subject: string;

  constructor(m: Message, messages: Message[], isReadonly: boolean) {
    this.id = m.MessageId;
    this.typeId = m.MessageTypeId;
    this.type = m.MessageType;
    this.patientName = m.PatientFirstName + ' ' + m.PatientLastName;
    this.patientFirstName = m.PatientFirstName;
    this.patientId = m.PatientId;
    this.patientIdentifier = m.PatientIdentifier;
    this.patientDoB = m.DateOfBirth;
    this.patientSent = m.PatientSent;
    this.isResolved = m.MessageStatusId === 999;
    this.isUnread = !m.Read;
    this.messages = [...messages];
    this.isReadOnly = isReadonly;
    this.hasNewMessages = false;
    this.subject = m.MessageSubject;
  }
}
