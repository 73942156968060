import { useQuery } from 'react-query';
import { useApiClient } from '../context';

export function useMessageTemplatesQuery({ enabled = true } = {}) {
  const apiClient = useApiClient().portal;

  return useQuery(
    ['messageTemplates'],
    () => apiClient.fetchMessageTemplates(),
    { enabled },
  );
}
