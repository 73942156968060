import * as tslib_1 from "tslib";
import { environment } from '../../../../environments/environment';
var ContentApiClient = /** @class */ (function () {
    function ContentApiClient(props) {
        this._clinicToken = props.clinicToken;
        this._authService = props.authService;
        this._onUnauthorized = props.onUnauthorized;
    }
    ContentApiClient.prototype.fetchPacks = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (id) {
                    return [2 /*return*/, this.fetch("content/pack/" + id)];
                }
                else {
                    return [2 /*return*/, this.fetch("content/pack")];
                }
                return [2 /*return*/];
            });
        });
    };
    ContentApiClient.prototype.fetchCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("content/categories")];
            });
        });
    };
    ContentApiClient.prototype.fetchCategoryEntries = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("content/groupedentries/assign")];
            });
        });
    };
    /**
     * Essentially a wrapper around `fetch` that sets some mandatory headers
     * and behavior.
     */
    ContentApiClient.prototype.getDefaultFetchHeaders = function (options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var authUser, headers;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authService.getAuthSession()];
                    case 1:
                        authUser = _a.sent();
                        headers = tslib_1.__assign({}, (options != null && options.headers != null ? options.headers : {}), { accept: 'application/json', authorization: authUser.getIdToken().getJwtToken(), 'x-salve-clinic-token': this._clinicToken });
                        return [2 /*return*/, headers];
                }
            });
        });
    };
    ContentApiClient.prototype.handleFetchResponse = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var content, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (response.status === 401) {
                            this._onUnauthorized();
                            throw new Error('Unauthorized');
                        }
                        if (!response.ok) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, response.text()];
                    case 2:
                        content = _a.sent();
                        return [2 /*return*/, content.length > 0 ? JSON.parse(content) : undefined];
                    case 3:
                        err_1 = _a.sent();
                        throw new Error("Error parsing body as JSON: " + err_1.message);
                    case 4: throw new Error('Network response was not ok');
                }
            });
        });
    };
    ContentApiClient.prototype.fetch = function (path, 
    /** Force `headers` to be a simple object for ease of use */
    options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headers, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDefaultFetchHeaders(options)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, fetch("" + environment.api.content.endpoint + path, tslib_1.__assign({}, options, { headers: headers }))];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, this.handleFetchResponse(response)];
                }
            });
        });
    };
    return ContentApiClient;
}());
export { ContentApiClient };
