import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, from, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { ClinicStatusResponse } from 'src/app/dashboard/models/responses/clinic-status.response';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import { CognitoWrapperService } from './congito.wrapper.service';

export enum EndpointType {
  Resolve = 1,
  Content = 2,
  MessagingWs = 3,
  Portal = 4,
  S3proxy = 5,
  Status = 6,
  Public = 7,
  PortalPublic = 8
}

@Injectable()
export class HttpService {
  constructor(
    private _cognito: CognitoWrapperService,
    private _http: HttpClient,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler
  ) {
    this.mapEndpoints();
    this._clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
  }
  private _endpointMappings = {};
  private _clinicToken$: Observable<string>;

  private baseHeaders = {
    'content-type': 'application/json',
    accept: '*/*'
  };

  get<T>(
    endpointType: EndpointType,
    path: string,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return combineLatest(
      from(this._cognito.getAuthSession()),
      this._clinicToken$
    ).pipe(
      mergeMap(([auth, clinicToken]) => {
        return this._http.get<T>(this.getEndPointWithPath(endpointType, path), {
          headers: this.getHeaders(auth, clinicToken, headers),
          params
        });
      })
    );
  }

  getRaw<T>(
    endpointType: EndpointType,
    path: string,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return this._http.get<T>(this.getEndPointWithPath(endpointType, path), {
      headers,
      params
    });
  }

  post<T>(
    endpointType: EndpointType,
    path: string,
    body: any,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return combineLatest(
      from(this._cognito.getAuthSession()),
      this._clinicToken$
    ).pipe(
      mergeMap(([auth, clinicToken]) => {
        return this._http.post<T>(
          this.getEndPointWithPath(endpointType, path),
          JSON.stringify(body),
          {
            headers: this.getHeaders(auth, clinicToken, headers),
            params
          }
        );
      })
    );
  }

  patch<T>(
    endpointType: EndpointType,
    path: string,
    body: any,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return combineLatest(
      from(this._cognito.getAuthSession()),
      this._clinicToken$
    ).pipe(
      mergeMap(([auth, clinicToken]) => {
        return this._http.patch<T>(
          this.getEndPointWithPath(endpointType, path),
          JSON.stringify(body),
          {
            headers: this.getHeaders(auth, clinicToken, headers),
            params
          }
        );
      })
    );
  }

  put<T>(
    endpointType: EndpointType,
    path: string,
    body: any,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return combineLatest(
      from(this._cognito.getAuthSession()),
      this._clinicToken$
    ).pipe(
      mergeMap(([auth, clinicToken]) => {
        return this._http.put<T>(
          this.getEndPointWithPath(endpointType, path),
          JSON.stringify(body),
          {
            headers: this.getHeaders(auth, clinicToken, headers),
            params
          }
        );
      })
    );
  }

  delete<T>(
    endpointType: EndpointType,
    path: string,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return combineLatest(
      from(this._cognito.getAuthSession()),
      this._clinicToken$
    ).pipe(
      mergeMap(([auth, clinicToken]) => {
        return this._http.delete<T>(
          this.getEndPointWithPath(endpointType, path),
          {
            headers: this.getHeaders(auth, clinicToken, headers),
            params
          }
        );
      })
    );
  }

  deleteWithBody<T>(
    endpointType: EndpointType,
    path: string,
    body: any,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<T> {
    return combineLatest(
      from(this._cognito.getAuthSession()),
      this._clinicToken$
    ).pipe(
      mergeMap(([auth, clinicToken]) => {
        return this._http.request<T>(
          'DELETE',
          this.getEndPointWithPath(endpointType, path),
          {
            headers: this.getHeaders(auth, clinicToken, headers),
            params,
            body: JSON.stringify(body)
          }
        );
      })
    );
  }

  performResolvePostRequest(body: any): Observable<any> {
    return this.post<any>(EndpointType.Resolve, '', body).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  performPublicPostRequest(
    body: any,
    path: string,
    params?: {
      [param: string]: string | string[];
    },
    headers?: HttpHeaders
  ): Observable<any> {
    return this._http.post<any>(
      this.getEndPointWithPath(EndpointType.Public, path),
      JSON.stringify(body),
      {
        headers: {
          ...this.baseHeaders,
          ...headers
        },
        params
      }
    );
  }

  performStatusRequest(clinicToken: string): Observable<ClinicStatusResponse> {
    return this._http.get<ClinicStatusResponse>(
      this.getEndPointWithPath(EndpointType.Status, ''),
      {
        params: {
          clinicToken
        }
      }
    );
  }

  private getEndPointWithPath(
    endpointType: EndpointType,
    path: string
  ): string {
    return `${this._endpointMappings[endpointType]}${path}`;
  }

  private getHeaders(
    cognitoUserSession: any,
    clinicToken: string,
    headers: HttpHeaders
  ) {
    return {
      ...this.baseHeaders,
      ...(cognitoUserSession && {
        authorization: cognitoUserSession.getIdToken().getJwtToken()
      }),
      'x-salve-clinic-token': clinicToken,
      ...headers
    };
  }

  private mapEndpoints(): void {
    this._endpointMappings[EndpointType.Resolve] =
      environment.api.resolve.endpoint;
    this._endpointMappings[EndpointType.Content] =
      environment.api.content.endpoint;
    this._endpointMappings[EndpointType.MessagingWs] =
      environment.api.messagingWs.endpoint;
    this._endpointMappings[EndpointType.Portal] =
      environment.api.portal.endpoint;
    this._endpointMappings[EndpointType.PortalPublic] =
      environment.api.portalPublic.endpoint;
    this._endpointMappings[EndpointType.S3proxy] =
      environment.api.s3Proxy.endpoint;
    this._endpointMappings[EndpointType.Status] =
      environment.api.status.endpoint;
    this._endpointMappings[EndpointType.Public] =
      environment.api.public.endpoint;
  }
}
