import styled from '@emotion/styled';

/**
 * This essentially behaves as our "global" styles
 * for React UI.
 */
export const Container = styled.div`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .MuiInputBase-input {
    box-sizing: content-box;
  }
`;
