import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import equal from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import {
  ContentTemplate,
  ContentTemplateEntry,
  NewTemplateEntry,
} from '../../models/content-template';

@Component({
  selector: 'portal-template-edit-form',
  templateUrl: './template-edit-form.component.html',
  styleUrls: ['./template-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditFormComponent implements OnChanges {
  public config: PerfectScrollbarConfigInterface = {};

  @Input() text: any;
  @Input() template: ContentTemplate;
  @Input() isNew: boolean;
  @Input() creating: boolean;
  @Input() creatingSuccess: boolean;
  @Input() creatingError: boolean;
  @Input() contentEditorEnabled: boolean;
  @Output() saveTemplate = new EventEmitter<ContentTemplate>();
  @Output() deleteTemplate = new EventEmitter<number>();

  public templateForm: FormGroup;

  public activeTemplate: ContentTemplate;
  public entries: ContentTemplateEntry[];

  get templateEntries(): FormArray {
    return this.templateForm.get('entries') as FormArray;
  }

  get requiredEntries(): FormArray {
    return this.templateForm.get('requiredEntries') as FormArray;
  }

  constructor(private _fb: FormBuilder, public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template && changes.template.currentValue) {
      this.activeTemplate = cloneDeep(this.template);
      this.entries = cloneDeep(this.template.entries);
      this.templateForm = this._fb.group({
        id: this.template.id,
        title: this.template.name,
        requiredEntries: this._fb.array([]),
        entries: this._fb.array([]),
      });
      this._processEntries(this.entries);
      if (!this.contentEditorEnabled) {
        this.templateForm.disable();
      }
    }
  }

  public dropped() {
    const entriesCopy = [];
    for (const entry of this.templateEntries.controls) {
      entriesCopy.push(entry.value);
    }
    this.templateEntries.reset(entriesCopy);
  }

  private _buildEntry(entry: ContentTemplateEntry): FormGroup {
    return this._fb.group({
      id: { value: entry.id },
      title: { value: entry.title, disabled: entry.isrequired },
      ordernumber: { value: entry.ordernumber },
      type: { value: entry.type },
    });
  }

  private _processEntries(entries: ContentTemplateEntry[]) {
    // required Entries
    const reqEntries = entries
      .filter((e) => e.isrequired && e.type !== 'title')
      .map((e) => this._buildEntry(e));
    this.templateForm.setControl('requiredEntries', this._fb.array(reqEntries));
    // non-required Entries
    const nonReqEntries = entries
      .filter((e) => !e.isrequired)
      .map((e) => this._buildEntry(e));
    this.templateForm.setControl('entries', this._fb.array(nonReqEntries));
  }

  private _getOrderNumber(): number {
    return this.templateEntries.length + 1;
  }

  private _formHasChanged(): boolean {
    const formEntries = this.templateEntries.value.map((e) => ({
      id: e.id.value,
      title: e.title,
      ordernumber: e.ordernumber.value,
    }));
    const originalTemplateEntries = this.template.entries.slice(4).map((e) => ({
      id: e.id,
      title: e.title,
      ordernumber: e.ordernumber,
    }));
    return (
      !equal(formEntries, originalTemplateEntries) &&
      this.templateForm.get('title').value === this.template.name
    );
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (this._formHasChanged()) {
      const confirmDialog = this.dialog.open(ConfirmActionComponent, {
        data: {
          message: this.text.CancelConfirmation,
          text: {
            Confirm: this.text.Confirm,
            Cancel: this.text.Cancel,
          },
        },
      });
      return confirmDialog.afterClosed();
    } else {
      return true;
    }
  }

  public removeEntry(id: number) {
    this.templateEntries.removeAt(id);
  }

  public addEntry() {
    this.templateEntries.push(
      this._buildEntry(
        new NewTemplateEntry({
          ordernumber: this._getOrderNumber(),
        }),
      ),
    );
  }

  public delete() {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.text.DeleteConfirmation,
        text: {
          Confirm: this.text.Confirm,
          Cancel: this.text.Cancel,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this.deleteTemplate.emit(this.templateForm.get('id').value);
      }
    });
  }

  public save() {
    const entries = this.entries.slice(0, 4);
    const editableEntries = this.templateEntries.value;

    const updatedEntries = entries.concat(
      editableEntries.map((e, i) => ({
        ...(e.id.value ? { id: e.id.value } : {}),
        ordernumber: i + 4 + 1,
        content: e.content,
        type: e.type.value,
        title: e.title,
        isrequired: false,
      })),
    );

    const template = {
      ...this.activeTemplate,
      name: this.templateForm.get('title').value,
      entries: updatedEntries,
    };

    this.saveTemplate.emit(template);
  }
}
