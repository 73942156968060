import { EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
var ApplyFilterComponent = /** @class */ (function () {
    function ApplyFilterComponent() {
        this.filtersApplied = new EventEmitter();
        this.isOpen = false;
        this.selectedFilters = [];
        this.appliedFilters = [];
        this.filteredItems = [];
        this.searchTerm = '';
    }
    ApplyFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.items &&
            changes.items.previousValue !== changes.items.currentValue) {
            this.filteredItems = this.items;
        }
        if (changes.searchTerm && changes.searchTerm.currentValue === '') {
            this.filteredItems = this.items;
        }
    };
    ApplyFilterComponent.prototype.onFilterToggle = function (e) {
        this.isOpen = !this.isOpen;
        this.selectedFilters = [].concat(this.appliedFilters);
    };
    ApplyFilterComponent.prototype.onFilterSearch = function (e) {
        var _this = this;
        this.searchTerm = e.target.value;
        this.filteredItems = this.items.filter(function (item) {
            return item.name.toLowerCase().includes(_this.searchTerm.toLowerCase());
        });
    };
    ApplyFilterComponent.prototype.onFilterSelected = function (e) {
        if (e.option.selected) {
            if (!this.selectedFilters.some(function (f) { return f === e.option.value; })) {
                this.selectedFilters.push(e.option.value);
            }
        }
        else {
            this.selectedFilters = this.selectedFilters.filter(function (f) { return f !== e.option.value; });
        }
    };
    ApplyFilterComponent.prototype.onFilterApply = function () {
        var _this = this;
        var removedFilters = this.appliedFilters.filter(function (f) { return !_this.selectedFilters.includes(f); });
        var addedFilters = this.selectedFilters.filter(function (f) { return !_this.appliedFilters.includes(f); });
        this.appliedFilters = this.appliedFilters
            .concat(addedFilters)
            .filter(function (f) { return !removedFilters.includes(f); });
        this.filtersApplied.emit(this.appliedFilters);
        this.isOpen = false;
        this.searchTerm = '';
    };
    ApplyFilterComponent.prototype.onFilterCancel = function () {
        this.isOpen = false;
        this.searchTerm = '';
        this.selectedFilters = [];
    };
    ApplyFilterComponent.prototype.onFilterRemove = function (filter) {
        this.selectedFilters = [].concat(this.selectedFilters.filter(function (f) { return f !== filter; }));
        this.appliedFilters = [].concat(this.selectedFilters.filter(function (f) { return f !== filter; }));
        this.filtersApplied.emit(this.appliedFilters);
    };
    ApplyFilterComponent.prototype.isSelected = function (item) {
        return this.selectedFilters.some(function (f) { return f === item; });
    };
    return ApplyFilterComponent;
}());
export { ApplyFilterComponent };
