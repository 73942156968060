import {
  ConversationApiValue,
  ConversationCreateResult,
  ConversationFilters,
  ConversationStaff,
  ConversationStaffApiValue,
  MessageApiValue,
  StaffApiValue,
} from '@react/lib/api/types';
import { ClinicUser, Conversation, Message } from '@react/types';
import qs from 'qs-lite';
import { Status } from '../constants';
import { FilterState } from '../types';

export function resolvedFilterApplied(filters: FilterState) {
  return filters.isResolved != null;
}

export function folderFiltersApplied(filters: FilterState) {
  return filters.folderIds && filters.folderIds.length > 0;
}

export function staffFiltersApplied(filters: FilterState) {
  return filters.staffIds && filters.staffIds.length > 0;
}

export function assignedFiltersApplied(filters: FilterState) {
  return filters.assignedIds && filters.assignedIds.length > 0;
}

export function unAssignedFiltersApplied(filters: FilterState) {
  return filters.isUnassigned != null;
}

export function filtersApplied(filters: FilterState) {
  return (
    resolvedFilterApplied(filters) ||
    folderFiltersApplied(filters) ||
    staffFiltersApplied(filters) ||
    assignedFiltersApplied(filters) ||
    unAssignedFiltersApplied(filters)
  );
}

const trimAndJoin = (...args: (string | null)[]) =>
  args.filter(Boolean).join(' ');

export function formatStaff(
  staff: ConversationStaffApiValue,
): ConversationStaff {
  return {
    ...staff,
    firstnameinitial: (staff.firstname || '').slice(0, 1) + '.',
  };
}

export function formatConversation(
  conversation: ConversationApiValue,
): Conversation {
  return {
    ...conversation,
    patientfullname: trimAndJoin(
      conversation.patientfirstname,
      conversation.patientlastname,
    ),
    clinicuserfullname: trimAndJoin(
      conversation.clinicuserfirstname,
      conversation.clinicuserlastname,
    ),
    staff: conversation.staff ? conversation.staff.map(formatStaff) : null,
  };
}

export function formatMessage(message: MessageApiValue): Message {
  return {
    ...message,
    patientfullname: trimAndJoin(
      message.patientfirstname,
      message.patientlastname,
    ),
    clinicuserfullname: trimAndJoin(
      message.clinicuserfirstname,
      message.clinicuserlastname,
    ),
  };
}

export function formatClinicUser(staff: StaffApiValue): ClinicUser {
  return {
    ...staff,
    userfullname: trimAndJoin(staff.firstname, staff.lastname),
    firstnameinitial: (staff.firstname || '').slice(0, 1) + '.',
  };
}

export function filtersToApi(filters: FilterState): ConversationFilters {
  return {
    ...(filters.folderIds.length > 0 && { messagetypeids: filters.folderIds }),
    isstarred: filters.isStarred ? Status.STARRED : Status.OTHERS,
    q:
      typeof filters.q === 'string' && filters.q.length > 0
        ? filters.q
        : undefined,
    isresolved: filters.isResolved,
    staffids: filters.staffIds,
    assignedids: filters.assignedIds,
    isunassigned: filters.isUnassigned,
  };
}

export function constructMessageDeepLink(
  conversation: ConversationApiValue | ConversationCreateResult,
  filters: FilterState,
) {
  const conversationId =
    'messageid' in conversation ? conversation.messageid : conversation.id;
  const query = qs.stringify({
    ...filters,
    conversationId,
    isStarred: conversation.isstarred,
  });
  return `/messages/${conversationId}?${query}`;
}
