<div class="drugs-table-wrapper">
  <table
    mat-table
    [dataSource]="medications"
    class="drugs-table"
    *ngIf="!!text"
  >
    <ng-container matColumnDef="type">
      <th mat-header-cell class="type" *matHeaderCellDef>{{ text.Type }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="drug">
      <th mat-header-cell class="drug" *matHeaderCellDef>{{ text.Drug }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.drug }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dosage">
      <th mat-header-cell class="dosage" *matHeaderCellDef>
        {{ text.Dosage }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.dosage }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ text.Status }}</th>
      <td
        mat-cell
        *matCellDef="let element"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div class="status-td">
          <mat-icon class="symbol">{{ matIcon(element.statusId) }}</mat-icon>
        </div>
        <div class="status-td">
          <span class="status">{{ element.status }}</span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr
      mat-row
      class="patient-row"
      *matRowDef="let row; columns: displayedColumns"
      (click)="onClick(row)"
    ></tr>
  </table>
</div>
