import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, from, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import { CognitoWrapperService } from './congito.wrapper.service';
export var EndpointType;
(function (EndpointType) {
    EndpointType[EndpointType["Resolve"] = 1] = "Resolve";
    EndpointType[EndpointType["Content"] = 2] = "Content";
    EndpointType[EndpointType["MessagingWs"] = 3] = "MessagingWs";
    EndpointType[EndpointType["Portal"] = 4] = "Portal";
    EndpointType[EndpointType["S3proxy"] = 5] = "S3proxy";
    EndpointType[EndpointType["Status"] = 6] = "Status";
    EndpointType[EndpointType["Public"] = 7] = "Public";
    EndpointType[EndpointType["PortalPublic"] = 8] = "PortalPublic";
})(EndpointType || (EndpointType = {}));
var HttpService = /** @class */ (function () {
    function HttpService(_cognito, _http, _store, _error) {
        this._cognito = _cognito;
        this._http = _http;
        this._store = _store;
        this._error = _error;
        this._endpointMappings = {};
        this.baseHeaders = {
            'content-type': 'application/json',
            accept: '*/*'
        };
        this.mapEndpoints();
        this._clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
    }
    HttpService.prototype.get = function (endpointType, path, params, headers) {
        var _this = this;
        return combineLatest(from(this._cognito.getAuthSession()), this._clinicToken$).pipe(mergeMap(function (_a) {
            var auth = _a[0], clinicToken = _a[1];
            return _this._http.get(_this.getEndPointWithPath(endpointType, path), {
                headers: _this.getHeaders(auth, clinicToken, headers),
                params: params
            });
        }));
    };
    HttpService.prototype.getRaw = function (endpointType, path, params, headers) {
        return this._http.get(this.getEndPointWithPath(endpointType, path), {
            headers: headers,
            params: params
        });
    };
    HttpService.prototype.post = function (endpointType, path, body, params, headers) {
        var _this = this;
        return combineLatest(from(this._cognito.getAuthSession()), this._clinicToken$).pipe(mergeMap(function (_a) {
            var auth = _a[0], clinicToken = _a[1];
            return _this._http.post(_this.getEndPointWithPath(endpointType, path), JSON.stringify(body), {
                headers: _this.getHeaders(auth, clinicToken, headers),
                params: params
            });
        }));
    };
    HttpService.prototype.patch = function (endpointType, path, body, params, headers) {
        var _this = this;
        return combineLatest(from(this._cognito.getAuthSession()), this._clinicToken$).pipe(mergeMap(function (_a) {
            var auth = _a[0], clinicToken = _a[1];
            return _this._http.patch(_this.getEndPointWithPath(endpointType, path), JSON.stringify(body), {
                headers: _this.getHeaders(auth, clinicToken, headers),
                params: params
            });
        }));
    };
    HttpService.prototype.put = function (endpointType, path, body, params, headers) {
        var _this = this;
        return combineLatest(from(this._cognito.getAuthSession()), this._clinicToken$).pipe(mergeMap(function (_a) {
            var auth = _a[0], clinicToken = _a[1];
            return _this._http.put(_this.getEndPointWithPath(endpointType, path), JSON.stringify(body), {
                headers: _this.getHeaders(auth, clinicToken, headers),
                params: params
            });
        }));
    };
    HttpService.prototype.delete = function (endpointType, path, params, headers) {
        var _this = this;
        return combineLatest(from(this._cognito.getAuthSession()), this._clinicToken$).pipe(mergeMap(function (_a) {
            var auth = _a[0], clinicToken = _a[1];
            return _this._http.delete(_this.getEndPointWithPath(endpointType, path), {
                headers: _this.getHeaders(auth, clinicToken, headers),
                params: params
            });
        }));
    };
    HttpService.prototype.deleteWithBody = function (endpointType, path, body, params, headers) {
        var _this = this;
        return combineLatest(from(this._cognito.getAuthSession()), this._clinicToken$).pipe(mergeMap(function (_a) {
            var auth = _a[0], clinicToken = _a[1];
            return _this._http.request('DELETE', _this.getEndPointWithPath(endpointType, path), {
                headers: _this.getHeaders(auth, clinicToken, headers),
                params: params,
                body: JSON.stringify(body)
            });
        }));
    };
    HttpService.prototype.performResolvePostRequest = function (body) {
        return this.post(EndpointType.Resolve, '', body).pipe(map(function (res) {
            return res;
        }), catchError(function (err) {
            return throwError(err);
        }));
    };
    HttpService.prototype.performPublicPostRequest = function (body, path, params, headers) {
        return this._http.post(this.getEndPointWithPath(EndpointType.Public, path), JSON.stringify(body), {
            headers: tslib_1.__assign({}, this.baseHeaders, headers),
            params: params
        });
    };
    HttpService.prototype.performStatusRequest = function (clinicToken) {
        return this._http.get(this.getEndPointWithPath(EndpointType.Status, ''), {
            params: {
                clinicToken: clinicToken
            }
        });
    };
    HttpService.prototype.getEndPointWithPath = function (endpointType, path) {
        return "" + this._endpointMappings[endpointType] + path;
    };
    HttpService.prototype.getHeaders = function (cognitoUserSession, clinicToken, headers) {
        return tslib_1.__assign({}, this.baseHeaders, (cognitoUserSession && {
            authorization: cognitoUserSession.getIdToken().getJwtToken()
        }), { 'x-salve-clinic-token': clinicToken }, headers);
    };
    HttpService.prototype.mapEndpoints = function () {
        this._endpointMappings[EndpointType.Resolve] =
            environment.api.resolve.endpoint;
        this._endpointMappings[EndpointType.Content] =
            environment.api.content.endpoint;
        this._endpointMappings[EndpointType.MessagingWs] =
            environment.api.messagingWs.endpoint;
        this._endpointMappings[EndpointType.Portal] =
            environment.api.portal.endpoint;
        this._endpointMappings[EndpointType.PortalPublic] =
            environment.api.portalPublic.endpoint;
        this._endpointMappings[EndpointType.S3proxy] =
            environment.api.s3Proxy.endpoint;
        this._endpointMappings[EndpointType.Status] =
            environment.api.status.endpoint;
        this._endpointMappings[EndpointType.Public] =
            environment.api.public.endpoint;
    };
    return HttpService;
}());
export { HttpService };
