import { Omit } from '@material-ui/core';
import { useApiClient } from '@react/lib/api';
import { MessageCreatePayload } from '@react/lib/api/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';
import { useMessageContentLinkExtraction } from './useMessageContentLinkExtraction';

export function useMessageCreate() {
  const { getContentLinkIds } = useMessageContentLinkExtraction();
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    function createMessage(message: Omit<MessageCreatePayload, 'metadata'>) {
      const contentLinkIds = getContentLinkIds(message.content);
      return apiClient.createMessage({
        ...message,
        metadata: { contentLinkIds },
      });
    },
    {
      onSuccess() {
        Object.keys(queryCacheKey).forEach((cacheKey) => {
          queryClient.invalidateQueries(cacheKey);
        });
      },
    },
  );
}
