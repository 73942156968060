import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TopLevelPatientData } from '../../models/TopLevelPatientData';

@Component({
  selector: 'portal-patient-top-level',
  templateUrl: './patient-top-level.component.html',
  styleUrls: ['./patient-top-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTopLevelComponent {
  @Input() text: any;
  @Input() patient: TopLevelPatientData;
  @Input() videoCallsEnabled: boolean;
  @Output() openNewMessage = new EventEmitter();
  @Output() openNewVideoCall = new EventEmitter();
}
