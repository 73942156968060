import * as tslib_1 from "tslib";
import { NewCategoryActionTypes, } from '../actions/new-category.actions';
export var initialState = {
    activeFilter: false,
    filterString: '',
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case NewCategoryActionTypes.FilterContent: {
            return tslib_1.__assign({}, state, { activeFilter: true, filterString: action.payload });
        }
        case NewCategoryActionTypes.ClearCategoriesFilter: {
            return tslib_1.__assign({}, state, { activeFilter: false, filterString: '' });
        }
        default: {
            return state;
        }
    }
}
// Accessors
export var isFilterActive = function (state) { return state.activeFilter; };
export var getFilterString = function (state) { return state.filterString; };
