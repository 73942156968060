import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../auth/reducers';
import { ToastOptions } from '../../models/ToastOptions';
import * as fromSettings from '../../settings/reducers';
import { AlterMessageActionTypes, ToastDismissed, } from '../actions/alter-message.actions';
import { ApplyFilter, OpenThread } from '../actions/message-ui.actions';
import { NewMessageActionTypes, SendNewThread, } from '../actions/new-message.actions';
var ToastEffects = /** @class */ (function () {
    function ToastEffects(actions$, toastr, _store, _error, _navigationService) {
        var _this = this;
        this.actions$ = actions$;
        this.toastr = toastr;
        this._store = _store;
        this._error = _error;
        this._navigationService = _navigationService;
        this.sendOutboundMessage$ = this.actions$.pipe(ofType(NewMessageActionTypes.SendOutboundMessage), switchMap(function (action) {
            return new Promise(function (res) {
                var _a = action.payload, newMessage = _a.newMessage, info = _a.info;
                var toast = _this.toastr.show(_this._toastText.SendingOutboundBody(newMessage.patientName), _this._toastText.SendingOutboundTitle, {
                    timeOut: 0,
                    closeButton: false,
                    toastClass: "toast custom-toast info",
                });
                var success = new ToastOptions({
                    toast: toast,
                    toastRef: toast.toastRef.componentInstance,
                    undoAction: null,
                    title: _this._toastText.OutboundSentTitle,
                    message: _this._toastText.OutboundSentBody(newMessage.patientName),
                    type: 'success',
                    options: {},
                });
                var error = new ToastOptions({
                    toast: toast,
                    toastRef: toast.toastRef.componentInstance,
                    undoAction: null,
                    title: _this._toastText.ErrorEncountered,
                    message: _this._toastText.ErrorSending(newMessage.patientName),
                    type: 'error',
                    options: {},
                });
                res(new SendNewThread({
                    messageStarringEnabled: action.payload.messageStarringEnabled,
                    data: {
                        newMessage: newMessage,
                        messageInfo: info,
                    },
                    toast: {
                        success: success,
                        error: error,
                    },
                }));
            });
        }));
        this.displayRerouteToast$ = this.actions$.pipe(ofType(NewMessageActionTypes.ShowRerouteToast), mergeMap(function (action) {
            return new Promise(function (res) {
                var t = action.payload.toast;
                var toast = t.toast;
                var toastRef = t.toastRef;
                var route = action.payload.route;
                toastRef.alterToast({
                    class: {
                        classesToRemove: ['info'],
                        classToAdd: "success route",
                    },
                    title: t.title,
                    message: t.message,
                });
                if (route) {
                    // show success
                    var reroute_1 = false;
                    // triggered if button is pressed
                    toast.onAction.subscribe(function () {
                        // trigger reroute
                        var messageInfo = action.payload.other;
                        reroute_1 = true;
                        var latestMessage = _this.createMessageFromMessageInfo(messageInfo);
                        if (latestMessage) {
                            _this._navigationService.navigate([route]);
                            toastRef.remove();
                            _this._store.dispatch(new ApplyFilter(latestMessage.MessageType.toLowerCase()));
                            _this._store.dispatch(new OpenThread(latestMessage));
                            if (action.payload.afterAction) {
                                _this._store.dispatch(action.payload.afterAction);
                            }
                            res(new ToastDismissed());
                        }
                        else {
                            _this._error.handleError(new Error('message not found'));
                            _this._navigationService.navigate([route]);
                            res(new ToastDismissed());
                        }
                    });
                    // triggered when toast disappears
                    toast.onHidden.subscribe(function () {
                        res(new ToastDismissed());
                    });
                    setTimeout(function () {
                        if (!reroute_1) {
                            toastRef.remove();
                        }
                    }, 5000);
                }
                else {
                    // show error
                    toastRef.alterToast({
                        class: {
                            classesToRemove: ['info'],
                            classToAdd: t.type + " + final",
                        },
                        title: t.title,
                        message: t.message,
                    });
                    setTimeout(function () {
                        toastRef.remove();
                        res(new ToastDismissed());
                    }, 3000);
                }
            });
        }));
        this.displayToast$ = this.actions$.pipe(ofType(AlterMessageActionTypes.ToastDisplayed), switchMap(function (action) {
            return new Promise(function (res) {
                var t = action.payload.toast;
                var toastRef = t.toastRef;
                var timeout = 3000;
                if (t.options && t.options.timeOut) {
                    timeout = t.options.timeOut;
                }
                // if toast has no undo Action and no toast reference
                // show toast with a close button and auto exit after 3
                // seconds
                if (!t.undoAction && !toastRef) {
                    // show toast
                    var toast = _this.toastr.show(t.message, t.title, {
                        toastClass: "toast custom-toast " + t.type,
                        timeOut: timeout,
                    });
                    toast.onHidden.subscribe(function () {
                        res(new ToastDismissed());
                    });
                }
                // if toast has has no undo Action and has a toast reference
                // amend existing toast instance with passed props and
                // manually remove toast from screen
                if (!t.undoAction && toastRef) {
                    // amend toast to show und
                    toastRef.alterToast({
                        class: {
                            classesToRemove: ['undo'],
                            classToAdd: t.type + " + final",
                        },
                        title: t.title,
                        message: t.message,
                    });
                    // remove manually
                    setTimeout(function () {
                        toastRef.remove();
                        res(new ToastDismissed());
                    }, timeout);
                }
                // if toast has an undo action display with undo button
                // and no close button, manage closing independently
                if (t.undoAction) {
                    // capture if undo button is pressed prior to manual timeout
                    var undoActivated_1 = false;
                    // show toast
                    var activeToast = _this.toastr.show(t.message, t.title, {
                        toastClass: "toast custom-toast " + t.type + " undoable",
                        closeButton: false,
                        disableTimeOut: true,
                        timeOut: 0,
                    });
                    // capture reference to toast instance
                    toastRef = activeToast.toastRef.componentInstance;
                    // subscribe to undo button press
                    activeToast.onAction.subscribe(function () {
                        // record click in higher scoped variable
                        undoActivated_1 = true;
                        // alter toast to show action is being undone
                        toastRef.alterToast({
                            class: {
                                classesToRemove: [t.type, 'undoable'],
                                classToAdd: 'undo',
                            },
                            title: _this._toastText.UndoingActionTitle,
                            message: _this._toastText.UndoingActionBody(t.title),
                        });
                        // assign toast reference to toastRef in undoAction
                        t.undoAction.payload.toast.toastRef = toastRef;
                        // create new changelabel action to undo prior
                        // action
                        res(t.undoAction);
                    });
                    activeToast.onHidden.subscribe(function () {
                        if (!undoActivated_1) {
                            res(new ToastDismissed());
                        }
                    });
                    // remove toast if nothing happens within 5 seconds
                    setTimeout(function () {
                        if (!undoActivated_1) {
                            toastRef.remove();
                        }
                    }, 5000);
                }
            });
        }));
        this.displayNewConvoToastReact$ = this.actions$.pipe(ofType(NewMessageActionTypes.ShowNewConvoToastReact), switchMap(function (action) {
            return new Promise(function (res) {
                var _a = action.payload, title = _a.title, message = _a.message, onActionClicked = _a.onActionClicked;
                var toast = _this.toastr.show(message, title, {
                    timeOut: 5000,
                    closeButton: false,
                    toastClass: "toast custom-toast success route",
                });
                var toastRef = toast.toastRef.componentInstance;
                // triggered if button is pressed
                toast.onAction.subscribe(function () {
                    onActionClicked();
                    toastRef.remove();
                    res(new ToastDismissed());
                });
                // triggered when toast disappears
                toast.onHidden.subscribe(function () {
                    res(new ToastDismissed());
                });
            });
        }));
        this._store
            .pipe(select(fromSettings.getSectionTranslations('MessageToast')))
            .subscribe(function (val) {
            _this._toastText = val;
        });
        this._store.pipe(select(fromAuth.getUserName)).subscribe(function (s) {
            _this._sender = s;
        });
    }
    ToastEffects.prototype.createMessageFromMessageInfo = function (messageInfo) {
        return {
            MessageId: messageInfo.id,
            MessageStatusId: 0,
            MessageTypeId: messageInfo.typeId,
            MessageType: messageInfo.type,
            Content: messageInfo.content,
            ContentType: 'text',
            SentDate: new Date(),
            Read: false,
            PatientSent: false,
            PatientFirstName: messageInfo.patientFirstName,
            PatientLastName: messageInfo.patientLastName,
            PatientIdentifier: messageInfo.patientId,
            DateOfBirth: messageInfo.patientDoB || null,
            Sender: this._sender,
            ReadDate: null,
            ClinicRead: messageInfo.ClinicRead,
            PatientRead: messageInfo.PatientRead,
            IsStarred: messageInfo.IsStarred,
            MessageSubject: messageInfo.subject,
            // TODO: refactor so that sending and receiving use different models
            MessageItemId: 0,
            PatientId: 0,
        };
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ToastEffects.prototype, "sendOutboundMessage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ToastEffects.prototype, "displayRerouteToast$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ToastEffects.prototype, "displayToast$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ToastEffects.prototype, "displayNewConvoToastReact$", void 0);
    return ToastEffects;
}());
export { ToastEffects };
