import { Component, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { sortByKeyAlphabetically } from 'src/app/shared/utils';
import {
  ConfirmActionComponent,
  ConfirmActionData,
  ConfirmActionResult,
} from '../../../core/components/confirm-action/confirm-action.component';
import {
  DialogWithDismissComponent,
  DismissComponentInterface,
} from '../../../core/components/dialog-with-dismiss/dialog-with-dismiss.component';
import {
  LoadingDialogComponent,
  LoadingDialogData,
} from '../../../core/components/loading-dialog/loading-dialog.component';
import { CanComponentDeactivate } from '../../../core/services/can-deactivate-guard.service';
import { Language } from '../../../models/Language';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import {
  ContentActionTypes,
  ContentEntryPageUnload,
  CopyGlobalContent,
  CopyGlobalContentError,
  CopyGlobalContentSuccessAdditionalTranslations,
  CopyGlobalContentSuccessMissingTranslations,
  CopyGlobalFileContent,
  CreateEntrySuccess,
  CreateNewContentEntry,
  DeleteEntry,
  DeleteEntrySuccess,
  GetContentEntry,
  SaveEntrySuccess,
} from '../../actions/content.actions';
import { Category } from '../../models/category';
import {
  ContentEntry,
  GlobalPortalContentEntry,
} from '../../models/content-entry';
import { ContentTemplate } from '../../models/content-template';
import { ParentPortal } from '../../models/global-portal';
import * as fromContent from '../../reducers';
import { ContentService } from '../../services/content.service';
import { Status } from '../content-edit-form/content-edit-form.component';

const DEFAULT_LANGUAGE_STRING = 'default';

@Component({
  selector: 'portal-content-edit',
  templateUrl: './content-edit.component.html',
  styleUrls: ['./content-edit.component.scss'],
})
export class ContentEditComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  public loadingContentEntry$: Observable<boolean>;
  public isSavingContentEntry$: Observable<boolean>;
  public loadingContentEntryError$: Observable<boolean>;
  public contentEntries$: Observable<Record<string, ContentEntry>>;
  public defaultContentEntry$: Observable<ContentEntry>;
  public contentTemplates$: Observable<ContentTemplate[]>;
  public contentCategories$: Observable<Category[]>;
  public selectedContentEntry$: Observable<ContentEntry | undefined>;
  public selectedLanguage$ = new BehaviorSubject<Language | undefined>(
    undefined,
  );
  public selectedTab$ = new BehaviorSubject<number>(0);
  public tabs$: Observable<Array<[string, 'DIRTY' | 'PRISTINE']>>;
  public availableTranslations$: Observable<
    Array<{
      languageCode: string;
      name: string;
    }>
  >;
  public controlsText$: Observable<any>;
  public clinicLanguages$: Observable<Language[]>;
  public clinicLanguages: Language[];
  public defaultClinicLanguage$: Observable<Language | undefined>;
  public defaultClinicLanguage: Language | undefined;
  public controlsText: any;

  // Content editor flag
  public contentEditorEnabled$: Observable<boolean>;
  public contentEditorEnabled: boolean;

  // Global Portal
  public hasParentPortal$: Observable<boolean>;
  public hasParentPortal: boolean;
  public parentPortalId: ParentPortal['globalclinicid'] | null = null;

  private _subs = new Subscription();
  private _defaultContentEntry?: ContentEntry;
  private _isLeaving = false;
  private _loadingDialog?: MatDialogRef<LoadingDialogComponent> | null;
  private _contentEntryStatuses$ = new BehaviorSubject<Record<string, Status>>(
    {},
  );
  private newContentListText: any;

  constructor(
    private _store: Store<fromRoot.State>,
    private _route: ActivatedRoute,
    private _updates$: Actions,
    private _navigationService: NavigationService,
    private _contentService: ContentService,
    public dialog: MatDialog,
  ) {
    // Global Portal
    this._contentService.getGlobalPortal().subscribe((res: ParentPortal) => {
      if (res && res.globalclinicid) {
        this.parentPortalId = res.globalclinicid;
      }
      return;
    });

    // Content editor group Observable
    this.contentEditorEnabled$ = this._store.pipe(
      select(fromContent.getContentEditorEnabled),
    );

    this.clinicLanguages$ = this._store.pipe(
      select(fromRoot.getLanguages),
      map((languages) => languages.sort(sortByKeyAlphabetically('name'))),
    );
    this.defaultClinicLanguage$ = this._store.pipe(
      select(fromRoot.getDefaultLanguage),
    );

    this.contentEntries$ = this._store.pipe(
      select(fromContent.getActiveContent),
    );
    this.contentTemplates$ = this._store.pipe(select(fromContent.getTemplates));
    this.controlsText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorControls')),
    );
    this._subs.add(
      this._store
        .pipe(select(fromSettings.getSectionTranslations('NewContentList')))
        .subscribe((t) => {
          this.newContentListText = t;
        }),
    );
    this._subs.add(
      this._store.pipe(select(fromRoot.getLanguages)).subscribe((t) => {
        this.clinicLanguages = t;
      }),
    );
    this._subs.add(
      this._store.pipe(select(fromRoot.getDefaultLanguage)).subscribe((t) => {
        this.defaultClinicLanguage = t;
      }),
    );
    this.contentCategories$ = this._store.pipe(
      select(fromContent.getCategories),
    );
    this.loadingContentEntry$ = this._store.pipe(
      select(fromContent.isLoadingContentEntry),
    );
    this.loadingContentEntryError$ = this._store.pipe(
      select(fromContent.hasLoadingContentEntryError),
    );

    this._subs.add(
      this.contentEditorEnabled$.subscribe(
        (t) => (this.contentEditorEnabled = t),
      ),
    );

    this.tabs$ = combineLatest(
      this.contentEntries$,
      this.clinicLanguages$,
      this._contentEntryStatuses$,
    ).pipe(
      map(([contentEntries, languages, contentEntryStatuses]) => {
        const defaultLanguage = languages.find(
          (language) => language.isdefault,
        );

        return Object.keys(contentEntries).map<[string, Status]>(
          (contentEntry) => {
            const name =
              contentEntry === 'default'
                ? defaultLanguage.name
                : languages.find((language) => language.code === contentEntry)
                    .name;

            return [name, contentEntryStatuses[contentEntry] || 'PRISTINE'];
          },
        );
      }),
    );

    this.defaultContentEntry$ = this.contentEntries$.pipe(
      map((contentEntries) => contentEntries['default']),
    );

    this.selectedContentEntry$ = combineLatest(
      this.contentEntries$,
      this.selectedTab$,
      this.clinicLanguages$,
      this.defaultClinicLanguage$,
    ).pipe(
      map(
        ([
          contentEntries,
          selectedTab,
          clinicLanguages,
          defaultClinicLanguage,
        ]) => {
          const key = Object.keys(contentEntries)[selectedTab];

          // For single language clinics the first (default) is always selected
          if (clinicLanguages.length === 0) {
            return contentEntries[key];
          }

          this.selectedLanguage$.next(
            clinicLanguages.find((l) => l.code === key) ||
              defaultClinicLanguage,
          );
          return contentEntries[key];
        },
      ),
    );

    this.availableTranslations$ = combineLatest(
      this.contentEntries$,
      this.clinicLanguages$,
      this.defaultClinicLanguage$,
    ).pipe(
      map(([contentEntries, clinicLanguages, defaultClinicLanguage]) => {
        if (defaultClinicLanguage == null) {
          return [];
        }

        const usedLanguages = Object.keys(contentEntries).map((contentEntry) =>
          contentEntry === 'default'
            ? defaultClinicLanguage.code
            : contentEntry,
        );

        return clinicLanguages
          .filter(
            (clinicLanguage) =>
              usedLanguages.findIndex(
                (usedLanguage) => usedLanguage === clinicLanguage.code,
              ) === -1,
          )
          .map((clinicLanguage) => ({
            languageCode: clinicLanguage.code,
            name: clinicLanguage.name,
          }));
      }),
    );

    this._subs.add(
      this.controlsText$.subscribe((t) => (this.controlsText = t)),
    );

    this._subs.add(
      this._route.params.subscribe((params: Params) => {
        if (params.id) {
          this._resetState();
          this._store.dispatch(new GetContentEntry(params.id));
        }
      }),
    );

    this._subs.add(
      combineLatest(this._route.params, this.contentCategories$)
        .pipe(
          // Need to wait for Content Categories to load
          filter(([, categories]) => categories.length > 0),
          take(1),
        )
        .subscribe(([routeParams, categories]) => {
          if (routeParams.id) {
            return;
          }

          // content is new, create new entry on the fly
          const categoryId = +routeParams.categoryid;
          const type = routeParams.type;

          const selectedCategory = categories.find(
            (c) => c.id === categoryId,
          ).name;

          this._store.dispatch(
            new CreateNewContentEntry({
              languageCode: 'default',
              contentEntry: ContentEntry.createNew(
                selectedCategory,
                categoryId,
                type === 'pdf',
              ),
            }),
          );
        }),
    );

    this._subs.add(
      this.defaultContentEntry$.subscribe((defaultContentEntry) => {
        this._defaultContentEntry = defaultContentEntry;
      }),
    );
  }

  ngOnInit() {
    this._subs.add(
      this._store
        .pipe(select(fromContent.getIsSavingContentEntry))
        .subscribe((isSavingContentEntry) => {
          if (isSavingContentEntry) {
            this._loadingDialog = this.dialog.open<
              LoadingDialogComponent,
              LoadingDialogData
            >(LoadingDialogComponent, {
              data: {
                loadingText: this.controlsText.PublishingContent,
              },
              width: '300px',
            });
          } else {
            if (this._loadingDialog != null) {
              this._loadingDialog.close();
              this._loadingDialog = null;
            }
          }
        }),
    );

    // When the main (default) Content Entry has been deleted then go back to the
    // list for this same category.
    this._subs.add(
      this._updates$
        .pipe(ofType<DeleteEntrySuccess>(ContentActionTypes.DeleteEntrySuccess))
        .subscribe((action) => {
          this._isLeaving = true;
          if (action.payload.languageCode === 'default') {
            this._navigationService.navigate([
              'content',
              'list',
              action.payload.contentEntry.contentcategoryid,
            ]);
          }
        }),
    );

    // When there is only one language then go back to the
    // list for this same category.
    this._subs.add(
      this._updates$
        .pipe(
          ofType<CreateEntrySuccess | SaveEntrySuccess>(
            ContentActionTypes.CreateEntrySuccess,
            ContentActionTypes.SaveEntrySuccess,
          ),
          withLatestFrom(this.clinicLanguages$),
        )
        .subscribe(([action, clinicLanguages]) => {
          if (clinicLanguages.length === 0) {
            this._isLeaving = true;
            this._navigationService.navigate([
              'content',
              'list',
              action.payload.contentEntry.contentcategoryid,
            ]);
          }
        }),
    );
  }

  ngOnDestroy() {
    this._store.dispatch(new ContentEntryPageUnload());
    this._subs.unsubscribe();
  }

  public trackContentEntryBy: TrackByFunction<{
    key: string;
    value: ContentEntry;
  }> = (index, item) => item.key;

  public trackTabsBy: TrackByFunction<{
    key: string;
    value: ContentEntry;
  }> = (index, item) => item[0];

  public tabChanged(e: number) {
    this.selectedTab$.next(e);
  }

  public addTranslation(languageCode: string) {
    this._store.dispatch(
      new CreateNewContentEntry({
        languageCode,
        contentEntry: ContentEntry.createNew(
          this._defaultContentEntry.contentcategory,
          this._defaultContentEntry.contentcategoryid,
          this._defaultContentEntry.isfileonly,
          this._defaultContentEntry.parentid,
        ),
      }),
    );
  }

  public canDeactivate() {
    // The default Content Entry has been deleted so no need to
    // warn of any changes.
    if (this._isLeaving) {
      return true;
    }

    const hasChanges = Object.values(this._contentEntryStatuses$.value).some(
      (status) => status === 'DIRTY',
    );

    if (!hasChanges) {
      return true;
    }

    const confirmDialog = this.dialog.open<
      ConfirmActionComponent,
      ConfirmActionData,
      ConfirmActionResult
    >(ConfirmActionComponent, {
      data: {
        message: this.controlsText.ExitConfirm,
        text: {
          Cancel: this.controlsText.Cancel,
          Confirm: this.controlsText.Leave,
        },
      },
    });

    return confirmDialog.afterClosed();
  }

  public delete() {
    const selectedLanguage = this.selectedLanguage$.value;
    if (selectedLanguage == null) {
      return;
    }

    const isDefaultContent = selectedLanguage.isdefault;

    // Prevent linked content being deleted
    if (isDefaultContent) {
      const isLinked =
        (this._defaultContentEntry.children || []).length > 0 ||
        this._defaultContentEntry.parentid != null;

      if (isLinked) {
        const deleteItemsDialog = this.dialog.open<
          DialogWithDismissComponent,
          DismissComponentInterface
        >(DialogWithDismissComponent, {
          data: {
            message: this.newContentListText.DeleteItemBlock(
              this._defaultContentEntry.name,
            ),
            text: {
              Dismiss: this.newContentListText.OK,
            },
          },
        });

        return;
      }
    }

    const confirmDialog = this.dialog.open<
      ConfirmActionComponent,
      ConfirmActionData,
      ConfirmActionResult
    >(ConfirmActionComponent, {
      data: {
        information: isDefaultContent
          ? this.controlsText.DeleteDefaultConfirmInformation
          : this.controlsText.DeleteConfirmInformation,
        message: isDefaultContent
          ? this.controlsText.DeleteDefaultConfirmMessage
          : this.controlsText.DeleteConfirmMessage,
        text: {
          Cancel: this.controlsText.Cancel,
          Confirm: this.controlsText.Delete,
        },
      },
    });

    this._subs.add(
      confirmDialog
        .afterClosed()
        .pipe(withLatestFrom(this.selectedContentEntry$))
        .subscribe(([confirmResult, selectedContentEntry]) => {
          if (confirmResult) {
            this._store.dispatch(
              new DeleteEntry({
                contentEntry: selectedContentEntry,
                languageCode: selectedLanguage.isdefault
                  ? 'default'
                  : selectedLanguage.code,
              }),
            );
          }
        }),
    );
  }

  public statusChange(languageCode: string, status: Status) {
    // A slight timeout is needed as Angular complains about tracked
    // bindings changing in development.
    setTimeout(() => {
      this._contentEntryStatuses$.next({
        ...this._contentEntryStatuses$.value,
        [languageCode]: status,
      });
    }, 0);
  }

  private _resetState(): void {
    this._contentEntryStatuses$.next({});
  }

  public async copyGlobalContent(id: number) {
    try {
      const res = await this._contentService
        .getGlobalContentEntryById(this.parentPortalId, id)
        .toPromise();

      const defaultLanguageCode = this.defaultClinicLanguage.code;
      const languageCodes = Object.values(this.clinicLanguages).map(
        (language) => language.code,
      );
      const availableCopyLanguages = Object.keys(res);
      const availableLanguagesWithoutDefault = [
        DEFAULT_LANGUAGE_STRING,
        ...languageCodes,
      ].filter((value) => value !== defaultLanguageCode);
      const missingLanguages = availableLanguagesWithoutDefault.filter(
        (value) => !availableCopyLanguages.includes(value),
      );
      const additionalLanguages = availableCopyLanguages.filter(
        (value) => !availableLanguagesWithoutDefault.includes(value),
      );

      // Create new translation content entries in Redux, and overwrite any already created.
      Object.entries(res).forEach(([key, value]) => {
        if (
          key !== DEFAULT_LANGUAGE_STRING &&
          availableLanguagesWithoutDefault.includes(key)
        ) {
          this._store.dispatch(
            new CreateNewContentEntry({
              languageCode: key,
              contentEntry: ContentEntry.createCopy(
                this._defaultContentEntry.contentcategory,
                this._defaultContentEntry.contentcategoryid,
                this._defaultContentEntry.isfileonly,
                this._defaultContentEntry.parentid,
                value.name,
              ),
            }),
          );
        }
      });

      // Setup sections for content
      const sections = Object.entries(res).reduce((agg, [key, value]) => {
        if (availableLanguagesWithoutDefault.includes(key)) {
          return {
            ...agg,
            [key]: {
              ...(agg[key] && agg[key]),
              name: value.name,
              sections: value.sections,
            },
          };
        }
        return agg;
      }, {});

      // Dispatch section (content data) for each translation (including the default) to populate the new content fields.
      this._store.dispatch(new CopyGlobalContent(sections));
      this.statusChange(DEFAULT_LANGUAGE_STRING, 'DIRTY');

      // Dispatch any user warning for missing or additional translations.
      if (missingLanguages.length) {
        this._store.dispatch(
          new CopyGlobalContentSuccessMissingTranslations(
            missingLanguages.join(', ').toUpperCase(),
          ),
        );
      }

      if (additionalLanguages.length) {
        this._store.dispatch(
          new CopyGlobalContentSuccessAdditionalTranslations(
            additionalLanguages.join(', ').toUpperCase(),
          ),
        );
      }
    } catch (e) {
      console.error(e);
      this._store.dispatch(new CopyGlobalContentError(e));
    }
  }

  public async copyGlobalFileContent(id: number) {
    try {
      const res = await this._contentService
        .getGlobalContentEntryById(this.parentPortalId, id)
        .toPromise();

      const defaultLanguageCode = this.defaultClinicLanguage.code;
      const languageCodes = Object.values(this.clinicLanguages).map(
        (language) => language.code,
      );
      const availableCopyLanguages = Object.keys(res);
      const availableLanguagesWithoutDefault = [
        DEFAULT_LANGUAGE_STRING,
        ...languageCodes,
      ].filter((value) => value !== defaultLanguageCode);
      const missingLanguages = availableLanguagesWithoutDefault.filter(
        (value) => !availableCopyLanguages.includes(value),
      );
      const additionalLanguages = availableCopyLanguages.filter(
        (value) => !availableLanguagesWithoutDefault.includes(value),
      );

      // Create new translation content entries in Redux, and overwrite any already created.
      Object.entries(res).forEach(([key, value]) => {
        if (
          key !== DEFAULT_LANGUAGE_STRING &&
          availableLanguagesWithoutDefault.includes(key)
        ) {
          this._store.dispatch(
            new CreateNewContentEntry({
              languageCode: key,
              contentEntry: ContentEntry.createCopy(
                this._defaultContentEntry.contentcategory,
                this._defaultContentEntry.contentcategoryid,
                this._defaultContentEntry.isfileonly,
                this._defaultContentEntry.parentid,
                value.name,
              ),
            }),
          );
        }
      });

      let content = {};
      // Setup sections for content
      content = Object.entries(res).reduce((agg, [key, value]) => {
        if (availableLanguagesWithoutDefault.includes(key)) {
          return {
            ...agg,
            [key]: {
              ...(agg[key] && agg[key]),
              name: value.name,
              sections: value.sections,
            },
          };
        }
        return agg;
      }, content);

      // Setup attachments
      content = Object.entries(res).reduce((agg, [key, value]) => {
        if (availableLanguagesWithoutDefault.includes(key)) {
          return {
            ...agg,
            [key]: {
              ...(agg[key] && agg[key]),
              attachments: value.attachments.map((attachment) => ({
                ...attachment,
                isGlobalFile: true,
              })),
            },
          };
        }
        return agg;
      }, content);

      // Dispatch section (content data) for each translation (including the default) to populate the new content fields.
      this._store.dispatch(new CopyGlobalFileContent(content));
      this.statusChange(DEFAULT_LANGUAGE_STRING, 'DIRTY');

      // Dispatch any user warning for missing or additional translations.
      if (missingLanguages.length) {
        this._store.dispatch(
          new CopyGlobalContentSuccessMissingTranslations(
            missingLanguages.join(', ').toUpperCase(),
          ),
        );
      }

      if (additionalLanguages.length) {
        this._store.dispatch(
          new CopyGlobalContentSuccessAdditionalTranslations(
            additionalLanguages.join(', ').toUpperCase(),
          ),
        );
      }
    } catch (e) {
      console.error(e);
      this._store.dispatch(new CopyGlobalContentError(e));
    }
  }
}
