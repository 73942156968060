import { DateTime } from '@react/lib/date/service';
import { TimeFrames } from './types';

export const getDateFieldValue = (localeDateTime: DateTime, value?: Date) => {
  return localeDateTime.format(value || new Date(), 'yyyy-MM-dd');
};

export const getTimeFrameDates = (
  localeDateTime: DateTime,
  selectedTimeFrame: TimeFrames,
): { start: Date; end: Date } => {
  let start = new Date();
  let end = new Date();

  switch (selectedTimeFrame) {
    case TimeFrames.Yesterday:
      start = localeDateTime.addDays(start, -1);
      end = localeDateTime.addDays(end, -1);
      break;

    case TimeFrames.Last7Days:
      start = localeDateTime.addDays(end, -7);
      break;

    case TimeFrames.LastWeek:
      start = localeDateTime.startOfWeek(localeDateTime.addWeeks(start, -1));
      end = localeDateTime.addDays(localeDateTime.addWeeks(start, 1), -1);
      break;

    case TimeFrames.ThisMonth:
      start = localeDateTime.startOfMonth(end);
      break;

    case TimeFrames.LastMonth:
      start = localeDateTime.startOfMonth(localeDateTime.addMonths(start, -1));
      end = localeDateTime.addDays(localeDateTime.addMonths(start, 1), -1);
      break;

    case TimeFrames.Last12Months:
      start = localeDateTime.addMonths(start, -12);
      break;
  }

  return { start, end };
};
