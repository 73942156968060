import { PerfectScrollbarConfigInterface, } from 'ngx-perfect-scrollbar';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { SettingsComponent } from './containers/settings/settings.component';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true,
};
var COMPONENTS = [SettingsComponent, GeneralSettingsComponent];
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SettingsModule = /** @class */ (function () {
    function SettingsModule() {
    }
    return SettingsModule;
}());
export { SettingsModule };
export { ɵ0 };
