<div
  class="choose-template-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div fxLayout="row" fxLayoutAlign="space-between start-" fxLayoutGap="10px">
    <h3 class="header">{{ text.UseATemplate }}</h3>
    <button mat-icon-button class="icon-size" (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="mat-select-form">
    <mat-form-field fxFlexFill appearance="outline">
      <mat-select
        placeholder="{{ text.ChooseATemplate }}"
        [formControl]="templateForm"
      >
        <mat-option>--</mat-option>
        <mat-option *ngFor="let template of data.templates" [value]="template">
          {{ template.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="templateForm.hasError('required')">
        {{ text.SelectATemplate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-raised-button
        class="portal-btn btn-primary"
        [disabled]="templateForm.hasError('required')"
        (click)="appendToContent()"
      >
        {{ text.Append }}
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">- {{ text.Or }} -</div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-raised-button
        class="portal-btn btn-primary"
        [disabled]="templateForm.hasError('required')"
        (click)="overwriteContent()"
      >
        {{ text.Replace }}
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div
        *ngIf="!templateForm.hasError('required')"
        class="warning title"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        {{ text.Warning }}
      </div>
      <div
        *ngIf="!templateForm.hasError('required')"
        class="warning"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        {{ text.WillDelete }}
      </div>
    </div>
  </div>
</div>
