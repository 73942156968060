import styled from '@emotion/styled';

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

export const StyledContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  flex-wrap: wrap;
`;

export const StyledList = styled.ul`
  list-style: disc;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;
