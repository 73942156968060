import { Button, Dialog } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { useExportAdminList } from '@react/pages/admin/hooks';
import { DownloadLists } from '@react/pages/admin/types';
import React, { FunctionComponent, useState } from 'react';

const ExportRecords: FunctionComponent = () => {
  const { t } = useTranslations();
  const localeDateTime = useLocaleDateTime();

  const [isExportDialogOpen, setExportDialogOpen] = useState(false);
  const [isExportError, setExportError] = useState(false);
  const [isExportSuccess, setExportSuccess] = useState(false);

  const onDownloadSuccess = () => {
    setExportDialogOpen(false);
    setExportSuccess(true);
  };

  const onDownloadFailure = () => {
    setExportError(true);
    setExportDialogOpen(false);
  };

  const { download, fileUrl, ref, fileName } = useExportAdminList({
    type: DownloadLists.Users,
    onDownloadSuccess,
    onDownloadFailure,
  });

  async function saveToCSV() {
    const fileNameSuffix = `${
      t.Admin.Users.UsersExport
    } ${localeDateTime.format(new Date(), 'yyyy/MM/dd')}`;
    await download(fileNameSuffix, true);
  }

  useErrorToast(
    isExportError,
    {
      title: t.Admin.Users.ExportErrorTitle,
      message: t.Admin.Users.ExportErrorDescription,
    },
    0,
  );

  useSuccessToast(isExportSuccess, {
    title: t.Admin.Users.ExportSuccessTitle,
    message: t.Admin.Users.ExportSuccessDescription,
  });

  return (
    <>
      <Button
        data-testid="btn_export"
        color="primary"
        onClick={() => setExportDialogOpen(true)}
        variant="contained"
      >
        {t.Admin.Users.ExportCSV}
      </Button>
      <a href={fileUrl} download={fileName} hidden ref={ref} />

      <Dialog
        acceptButtonText={t.Admin.Users.ExportDialogDownload}
        cancelButtonText={t.Admin.Users.ExportDialogCancel}
        contentText={t.Admin.Users.ExportDialogDescription}
        onAccept={saveToCSV}
        onCancel={() => setExportDialogOpen(false)}
        isOpen={isExportDialogOpen}
        titleText={t.Admin.Users.ExportDialogTitle}
      />
    </>
  );
};

export default ExportRecords;
