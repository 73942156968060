import * as tslib_1 from "tslib";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
var styledDivProps = function (props) {
    var sizePixels;
    switch (props.size) {
        case 'small':
            sizePixels = 16;
            break;
        case 'regular':
            sizePixels = 30;
            break;
        default:
            sizePixels = 40;
            break;
    }
    return css(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    width: ", "px;\n    height: ", "px;\n  "], ["\n    width: ", "px;\n    height: ", "px;\n  "])), sizePixels, sizePixels);
};
var ɵ0 = styledDivProps;
export var StyledDiv = styled('div')(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), styledDivProps);
export var StyledWrapper = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  width: 100%;\n  margin-right: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  width: 100%;\n  margin-right: ",
    ";\n"])), function (_a) {
    var size = _a.size, theme = _a.theme;
    return size === 'regular' ? theme.spacing(1) + "px" : '0';
});
export var StyledImg = styled('img')(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  border-radius: 50%;\n  max-height: 100%;\n  max-width: 100%;\n"], ["\n  border-radius: 50%;\n  max-height: 100%;\n  max-width: 100%;\n"])));
export var StyledInitials = styled.div(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  background: ", ";\n  width: 100%;\n  height: 100%;\n  border-radius: 100%;\n  font-weight: 700;\n  font-size: 12px;\n  text-align: center;\n  text-transform: uppercase;\n  line-height: 30px;\n  color: #fff;\n"], ["\n  background: ", ";\n  width: 100%;\n  height: 100%;\n  border-radius: 100%;\n  font-weight: 700;\n  font-size: 12px;\n  text-align: center;\n  text-transform: uppercase;\n  line-height: 30px;\n  color: #fff;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.purpleDark;
});
export { ɵ0 };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
