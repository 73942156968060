import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import { Unauthorised } from '../../../react/components/Unauthorised';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
import { GLOBAL_CMS_ROUTE } from '../../services/navigation.service';

const containerElementName = 'unauthorisedReactComponent';

@Component({
  selector: 'portal-unauthorised',
  template: `<div
    style="height: 100%; width: 100%;"
    #${containerElementName}
  ></div>`,
})
export class UnauthorisedReactWrapperComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(containerElementName) containerRef!: ElementRef;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
      ]).subscribe(
        ([clinicToken, clinicOptions, user, language, translations]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
          };
          this.render();
        },
      ),
    );
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  ngAfterViewInit() {
    this.render();
  }

  private async render() {
    ReactDOM.render(
      React.createElement(Root, {
        ...this._props,
        className: 'portal-unauthorised',
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        children: React.createElement(Unauthorised, {
          container: true,
          url: `/${GLOBAL_CMS_ROUTE}`,
          text: 'GlobalCMS.Back',
        }),
      }),
      this.containerRef.nativeElement,
    );
  }
}
