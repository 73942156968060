import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { select, Store, StoreModule } from '@ngrx/store';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { Observable, Subscription } from 'rxjs';
import * as fromAuth from '../auth/reducers';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CoreModule } from '../core/core.module';
import { GlobalPortalGuard } from '../core/services/global-guard.service';
import { LocalisationModule } from '../localisation/localisation.module';
import { LocalisationService } from '../localisation/localisation.service';
import { MaterialModule } from '../material/material.module';
import * as fromSettings from '../settings/reducers';
import { VideoCallsModule } from '../video-calls/video-calls.module';
import {
  GetContentEntries,
  GetContentTypes
} from './actions/content-assignment.actions';
import { AssignedPackListComponent } from './components/assigned-pack-list/assigned-pack-list.component';
import { ContentAssignedComponent } from './components/content-assigned/content-assigned.component';
import { ContentSelectComponent } from './components/content-select/content-select.component';
import { ContentTypeFilterComponent } from './components/content-type-filter/content-type-filter.component';
import { PackAssignmentComponent } from './components/pack-assignment/pack-assignment.component';
import { PatientCalendarComponent } from './components/patient-calendar/patient-calendar.component';
import { PatientInfoComponent } from './components/patient-info/patient-info.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { PatientSectionsListComponent } from './components/patient-sections-list/patient-sections-list.component';
import { PatientTopLevelComponent } from './components/patient-top-level/patient-top-level.component';
import { ToolbarTitleComponent } from './components/toolbar-title/toolbar-title.component';
import { AssignedContentContainer } from './containers/assigned-content/assigned-content.container';
import { ContentAssignmentComponent } from './containers/content-assignment/content-assignment.component';
import { PatientDetailsInternalComponent } from './containers/patient-details-internal/patient-details-internal.component';
import { PatientDocumentsComponent } from './containers/patient-documents/patient-documents.component';
import { PatientsDetailComponent } from './containers/patients-detail/patients-detail.component';
import { PatientsListComponent } from './containers/patients-list/patients-list.component';
import { ContentAssignmentEffects } from './effects/content-assignment.effects';
import { PackAssignmentEffects } from './effects/pack-assignment.effects';
import { PatientEffects } from './effects/patient.effects';
import { reducers, State } from './reducers';
import { ContentAssignmentService } from './services/content-assignment.service';
import { PackAssignmentService } from './services/pack-assignment.service';
import { PatientService } from './services/patient.service';
import { ScriptInjectorService } from '../core/services/script-injector.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    LocalisationModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CoreModule.forFeature(),
    StoreModule.forFeature('patientInfo', reducers),
    EffectsModule.forFeature([
      PatientEffects,
      ContentAssignmentEffects,
      PackAssignmentEffects
    ]),
    VideoCallsModule.forRoot(),
    RouterModule.forChild([
      {
        path: 'patients/internal/:internalsid',
        canActivate: [AuthGuard, GlobalPortalGuard],
        component: PatientDetailsInternalComponent
      },
      {
        path: 'patients/:patientId',
        canActivate: [AuthGuard, GlobalPortalGuard],
        component: PatientsDetailComponent
      },
      {
        path: 'patients',
        canActivate: [AuthGuard, GlobalPortalGuard],
        component: PatientsListComponent
      }
    ])
  ],
  declarations: [
    PatientsDetailComponent,
    PatientsListComponent,
    PatientInfoComponent,
    PatientCalendarComponent,
    PatientListComponent,
    PatientTopLevelComponent,
    PatientSectionsListComponent,
    ContentAssignmentComponent,
    ContentTypeFilterComponent,
    ContentSelectComponent,
    ContentAssignedComponent,
    PatientDocumentsComponent,
    PackAssignmentComponent,
    ToolbarTitleComponent,
    AssignedContentContainer,
    AssignedPackListComponent,
    PatientDetailsInternalComponent
  ],
  providers: [
    PatientService,
    ContentAssignmentService,
    ScriptInjectorService,
    PackAssignmentService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PatientsModule {
  private _subs = new Subscription();
  public selectedLanguage$: Observable<string>;

  constructor(
    private _store: Store<State>,
    private _localisationService: LocalisationService
  ) {
    this.selectedLanguage$ = this._store.pipe(
      select(fromSettings.getCurrentLanguage)
    );

    const loggedIn = false;

    this._subs.add(
      // tslint:disable-next-line: no-shadowed-variable
      this._store.pipe(select(fromAuth.getLoggedIn)).subscribe((loggedIn) => {
        loggedIn = loggedIn;
      })
    );

    // Subscribe to language changes
    this._subs.add(
      this.selectedLanguage$.subscribe((language) => {
        _localisationService.setLocale(language);
      })
    );

    // fetch latest content types and entries
    if (loggedIn) {
      this._store.dispatch(new GetContentTypes());
      this._store.dispatch(new GetContentEntries());
    }
  }
}
