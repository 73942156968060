import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { parseInteger, zeroPad } from '../../utils/generic';

@Component({
  selector: 'portal-number-input',
  templateUrl: 'number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit, AfterViewInit {
  // tslint:disable-next-line: no-input-rename
  @Input('max-length') maxLength: number;
  @Input() min: number;
  @Input() max: number;
  @Input() rotate: boolean;
  @Input() step: number;
  @Input() initialValue: number;
  // tslint:disable-next-line: no-input-rename
  @Input('zero-pad') zeroPad: boolean;

  @ViewChild('input') private inputEl: ElementRef;
  @Output() valueChanged = new EventEmitter<number>();
  @Output() touchedChanged = new EventEmitter<void>();

  widthCss: string;

  constructor() {}

  ngOnInit() {
    this.widthCss = `${(this.maxLength || 2) * 1.1}ch`;
  }

  ngAfterViewInit(): void {
    if (this.inputEl) {
      this.inputEl.nativeElement.addEventListener('focus', (e) => {
        this.initializeValue();
        this.touchedChanged.emit();
      });
      if (this.initialValue != null) {
        this.setValue(this.initialValue);
      }
    }
  }

  onIncrease() {
    if (!this.initializeValue()) {
      let nextValue =
        parseInteger(this.inputEl.nativeElement.value) +
        parseInteger(this.step);
      if (this.rotate && nextValue > parseInteger(this.max)) {
        nextValue = parseInteger(this.min);
      }
      this.setValue(nextValue);
      this.touchedChanged.emit();
    }
  }

  onDecrease() {
    if (!this.initializeValue()) {
      let nextValue =
        parseInteger(this.inputEl.nativeElement.value) -
        parseInteger(this.step);
      if (this.rotate && nextValue < parseInteger(this.min)) {
        nextValue = parseInteger(this.max);
      }
      this.setValue(nextValue);
      this.touchedChanged.emit();
    }
  }

  private initializeValue() {
    if (
      this.inputEl.nativeElement.value == null ||
      this.inputEl.nativeElement.value.trim() === ''
    ) {
      this.setValue(parseInteger(this.min));
      return true;
    }
    return false;
  }

  private setValue(value: number) {
    if (this.zeroPad) {
      this.inputEl.nativeElement.value = zeroPad(
        value,
        parseInteger(this.maxLength),
      );
    } else {
      this.inputEl.nativeElement.value = value;
    }

    this.valueChanged.emit(value);
  }
}
