import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { Empty } from '@react/components/Empty';
import { SIDERecipeEventType } from '@react/lib/api/enum';
import { IRecipe } from '@react/lib/api/types';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Waypoint } from 'react-waypoint';
import { useSIDERecipeActionListQuery } from '../../../../../admin/hooks';
import { EmptyActionListIcon } from '../../icons/EmptyActionListIcon';
import AppointmentRecipeActions from './AppointmentRecipeActions';
import MedicationRecipeActions from './MedicationRecipeActions';
import RegistrationRecipeActions from './RegistrationRecipeActions';

import {
  EmptyContainer,
  LoaderWrapper,
  StyledTable,
  StyledTableFooter,
} from './index.styled';

interface IProps {
  recipe: IRecipe;
}

const ActionList: FunctionComponent<IProps> = ({ recipe }) => {
  const { t } = useTranslations();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
  } = useSIDERecipeActionListQuery({ id: recipe.id });

  useErrorToast(isError, {
    title: t.Admin.SIDE.ActionListLoadingErrorToastHeader,
    message: t.Admin.SIDE.ActionListErrorToastMessage,
  });

  function onScrollToEnd() {
    const shouldFetchNextPage =
      hasNextPage && !isLoading && !isFetchingNextPage;
    if (shouldFetchNextPage) {
      fetchNextPage();
    }
  }

  const loading = isLoading || isFetchingNextPage;
  const showResults = !isLoading && !isError;

  return (
    <>
      {showResults && data && data.pages[0].data.length === 0 && (
        <EmptyContainer>
          <Empty
            icon={<EmptyActionListIcon />}
            message={t.Admin.SIDE.ActionListEmptyMessage}
          />
        </EmptyContainer>
      )}
      {showResults && data && data.pages[0].data.length > 0 && (
        <StyledTable>
          {recipe.eventname === SIDERecipeEventType.Appointment && (
            <AppointmentRecipeActions data={data} />
          )}
          {recipe.eventname === SIDERecipeEventType.Medication && (
            <MedicationRecipeActions data={data} />
          )}
          {recipe.eventname === SIDERecipeEventType.Registration && (
            <RegistrationRecipeActions data={data} />
          )}
          <StyledTableFooter>
            <TableRow>
              {loading && (
                <TableCell colSpan={3}>
                  <LoaderWrapper data-testid="Loader">
                    <CircularProgress />
                  </LoaderWrapper>
                </TableCell>
              )}
            </TableRow>
          </StyledTableFooter>
        </StyledTable>
      )}
      <Waypoint onEnter={onScrollToEnd} />
    </>
  );
};

export default ActionList;
