import {
  ContentActionsUnion,
  ContentActionTypes,
} from '../actions/content.actions';
import { OpenDirectorySection } from './../actions/content.actions';

export interface State {
  loadingDirectory: boolean;
  loadingTemplate: boolean;
  loadingContent: boolean;
  savingCategory: boolean;
  savingTemplate: boolean;
  savingContent: boolean;
  activeDirectorySection: string;
  activeListFilter: boolean;
  currentListFilter: string;
  contentEditorEnabled: boolean;
}

export const initialState: State = {
  loadingDirectory: false,
  loadingTemplate: false,
  loadingContent: false,
  savingCategory: false,
  savingTemplate: false,
  savingContent: false,
  activeDirectorySection: 'folders',
  activeListFilter: false,
  currentListFilter: '',
  contentEditorEnabled: false,
};

export function reducer(
  state = initialState,
  action: ContentActionsUnion,
): State {
  switch (action.type) {
    case ContentActionTypes.LoadingDirectory: {
      return {
        ...state,
        loadingDirectory: true,
      };
    }
    case ContentActionTypes.LoadingTemplate: {
      return {
        ...state,
        loadingTemplate: true,
      };
    }
    case ContentActionTypes.LoadedContent: {
      return {
        ...state,
        loadingContent: true,
      };
    }
    case ContentActionTypes.LoadedDirectory: {
      return {
        ...state,
        loadingDirectory: false,
      };
    }
    case ContentActionTypes.LoadedTemplate: {
      return {
        ...state,
        loadingTemplate: false,
      };
    }
    case ContentActionTypes.LoadedContent: {
      return {
        ...state,
        loadingContent: false,
      };
    }
    case ContentActionTypes.OpenDirectorySection: {
      return {
        ...state,
        activeDirectorySection: action.payload,
      };
    }
    case ContentActionTypes.SavingTemplate: {
      return {
        ...state,
        savingTemplate: true,
      };
    }
    case ContentActionTypes.SavingTemplateSuccess ||
      ContentActionTypes.SavingTemplateError: {
      return {
        ...state,
        savingTemplate: false,
      };
    }
    case ContentActionTypes.SavingContent: {
      return {
        ...state,
        savingContent: true,
      };
    }
    case ContentActionTypes.SavingContentSuccess ||
      ContentActionTypes.SavingContentError: {
      return {
        ...state,
        savingContent: false,
      };
    }
    case ContentActionTypes.ClearFilter: {
      return {
        ...state,
        activeListFilter: false,
        currentListFilter: '',
      };
    }
    case ContentActionTypes.FilterContent: {
      return {
        ...state,
        activeListFilter: true,
        currentListFilter: action.payload,
      };
    }
    case ContentActionTypes.GetContentEditorEnabledSuccess ||
      ContentActionTypes.GetContentEditorEnabledError: {
      return {
        ...state,
        contentEditorEnabled: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const isLoadingDirectory = (state: State) => state.loadingDirectory;
export const isLoadingTemplate = (state: State) => state.loadingTemplate;
export const isLoadingContent = (state: State) => state.loadingContent;
export const isSavingTemplate = (state: State) => state.savingTemplate;
export const isFilterActive = (state: State) => state.activeListFilter;
export const getCurrentListFilter = (state: State) => state.currentListFilter;
export const getActiveDirectorySection = (state: State) =>
  state.activeDirectorySection;
export const getContentEditorEnabled = (state: State) =>
  state.contentEditorEnabled;
