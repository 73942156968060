import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { MicOff, VideocamOff } from '@material-ui/icons';
export var SelfVideoFrame = styled('div')(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  width: 262px;\n  height: 150px;\n  position: absolute;\n  top: -3px;\n  right: -3px;\n  border-radius: 8px;\n  border: 3px solid #1f2124;\n"], ["\n  width: 262px;\n  height: 150px;\n  position: absolute;\n  top: -3px;\n  right: -3px;\n  border-radius: 8px;\n  border: 3px solid #1f2124;\n"])));
export var SelfVideoIconsContainer = styled('div')(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  position: absolute;\n  top: 12px;\n  left: 12px;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n"], ["\n  position: absolute;\n  top: 12px;\n  left: 12px;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n"])));
export var SelfMicIconContainer = styled('div')(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  background-color: ", ";\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: ", ";\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return (props.cameraOn ? '#1f2124' : '#000000'); });
export var SelfVideoIconContainer = styled('div')(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  background-color: #000000;\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: #000000;\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var SelfMicOffIcon = styled(MicOff)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  color: #ffffff;\n"], ["\n  color: #ffffff;\n"])));
export var SelfVideocamOffIcon = styled(VideocamOff)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  color: #ffffff;\n"], ["\n  color: #ffffff;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
