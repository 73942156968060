import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import { cognitoContentEditorState } from '../../auth/utils/convert-cognito-user';
import { DisplayToastAction } from '../../core/actions/toast.actions';
import * as fromSettings from '../../settings/reducers';
import {
  ContentActionTypes,
  CreateCategory,
  CreateCategoryError,
  CreateCategorySuccess,
  CreateEntry,
  CreateEntryError,
  CreateEntrySuccess,
  CreateTemplate,
  CreateTemplateError,
  CreateTemplateSuccess,
  DeleteCategory,
  DeleteCategoryError,
  DeleteCategorySuccess,
  DeleteEntries,
  DeleteEntriesError,
  DeleteEntriesSuccess,
  DeleteEntry,
  DeleteEntryError,
  DeleteEntrySuccess,
  DeleteTemplate,
  DeleteTemplateError,
  DeleteTemplateSuccess,
  GetCategories,
  GetCategoriesSuccess,
  GetContentEditorEnabled,
  GetContentEditorEnabledError,
  GetContentEditorEnabledSuccess,
  GetContentEntries,
  GetContentEntriesError,
  GetContentEntriesSuccess,
  GetContentEntry,
  GetContentEntryError,
  GetContentEntrySuccess,
  GetTemplates,
  GetTemplatesError,
  GetTemplatesSuccess,
  MoveEntries,
  MoveEntriesError,
  MoveEntriesSuccess,
  SaveEntry,
  SaveEntryError,
  SaveEntrySuccess,
  SavingCategory,
  SavingCategoryError,
  SavingCategorySuccess,
  SavingTemplate,
  SavingTemplateError,
  SavingTemplateSuccess
} from '../actions/content.actions';
import {
  formErrorAction,
  formSuccessAction
} from '../directives/connect-form.directive';
import { Category } from '../models/category';
import { ContentEntry, GroupedContentEntries } from '../models/content-entry';
import { ContentTemplate } from '../models/content-template';
import { CreateEntryRequest } from '../models/requests/create-entry.request';
import { MoveEntriesResponse } from '../models/requests/move-entries.response';
import { DeleteEntriesResponse } from '../models/responses/delete-entries.response';
import { ContentService } from '../services/content.service';

@Injectable()
export class ContentEffects {
  // Content Entries CRUD Effects
  // ==============================================================
  @Effect()
  createEntry$ = this.actions$.pipe(
    ofType<CreateEntry>(ContentActionTypes.CreateEntry),
    withLatestFrom(
      this.store.pipe(
        select(fromSettings.getSectionTranslations('ContentCreatorControls'))
      )
    ),
    switchMap(([action, text]) => {
      const request: CreateEntryRequest = {
        ...action.payload.contentEntry
      };

      if ('parentId' in action.payload && action.payload.parentId != null) {
        request.translation = {
          languagecode: action.payload.languageCode,
          maincontentheaderid: action.payload.parentId
        };
      }

      return this.contentService.createEntry(request).pipe(
        switchMap((res) => [
          new CreateEntrySuccess({
            contentEntry: res,
            languageCode:
              'languageCode' in action.payload
                ? action.payload.languageCode
                : undefined
          }),
          new DisplayToastAction({
            message: text.SuccessToastDescription as string,
            timeout: 3000,
            title: text.SuccessToastTitle as string,
            type: 'success'
          }),
          new GetContentEntries()
        ]),
        catchError((err) => {
          return of(
            new CreateEntryError(err),
            new DisplayToastAction({
              message: text.ErrorToastDescription as string,
              timeout: 0,
              title: text.ErrorToastTitle as string,
              type: 'error'
            })
          );
        })
      );
    })
  );

  @Effect()
  getEntries$ = this.actions$.pipe(
    ofType<GetContentEntries>(ContentActionTypes.GetContentEntries),
    switchMap(() =>
      this.contentService.getContentEntryHeaders().pipe(
        // TODO: Fix types
        // @ts-ignore
        map((res: GroupedContentEntries) => new GetContentEntriesSuccess(res)),
        catchError((err) => {
          return of(new GetContentEntriesError(err));
        })
      )
    )
  );

  @Effect()
  getContentEntryById$ = this.actions$.pipe(
    ofType<GetContentEntry>(ContentActionTypes.GetContentEntry),
    switchMap((action) =>
      this.contentService.getContentEntryById(action.payload)
    ),
    switchMap((contentEntry) =>
      forkJoin([
        of(contentEntry),
        ...contentEntry.translations.reduce<Observable<ContentEntry>[]>(
          (prev, curr) => [
            ...prev,
            this.contentService
              .getContentEntryById(curr.contententryheaderid)
              .pipe(take(1))
          ],
          []
        )
      ])
    ),
    map((res) => {
      const [defaultContentEntry, ...rest] = res;
      let payload: ConstructorParameters<typeof GetContentEntrySuccess>[0] = {
        default: defaultContentEntry
      };

      payload = rest.reduce((prev, curr) => {
        const contentEntryTranslation = defaultContentEntry.translations.find(
          (df) => df.contententryheaderid === curr.id
        );

        return {
          ...prev,
          [contentEntryTranslation.languagecode]: curr
        };
      }, payload);

      return new GetContentEntrySuccess(payload);
    }),
    catchError((err) => {
      return of(new GetContentEntryError(err));
    })
  );

  @Effect()
  amendEntry$ = this.actions$.pipe(
    ofType<SaveEntry>(ContentActionTypes.SaveEntry),
    withLatestFrom(
      this.store.pipe(
        select(fromSettings.getSectionTranslations('ContentCreatorControls'))
      )
    ),
    switchMap(([action, text]) =>
      this.contentService.amendEntry(action.payload.contentEntry).pipe(
        switchMap((res: ContentEntry) => [
          new SaveEntrySuccess({
            contentEntry: res,
            languageCode: action.payload.languageCode
          }),
          new DisplayToastAction({
            message: text.SuccessToastDescription as string,
            timeout: 3000,
            title: text.SuccessToastTitle as string,
            type: 'success'
          }),
          new GetContentEntries()
        ]),
        catchError((err) => {
          return of(
            new SaveEntryError(err),
            new DisplayToastAction({
              message: text.ErrorToastDescription as string,
              timeout: 0,
              title: text.ErrorToastTitle as string,
              type: 'error'
            })
          );
        })
      )
    )
  );

  @Effect()
  deleteEntry$ = this.actions$.pipe(
    ofType<DeleteEntry>(ContentActionTypes.DeleteEntry),
    withLatestFrom(
      this.store.pipe(
        select(fromSettings.getSectionTranslations('ContentCreatorControls'))
      )
    ),
    switchMap(([action, text]) => {
      if (action.payload.contentEntry.id == null) {
        return of(new DeleteEntrySuccess(action.payload));
      }

      return this.contentService
        .deleteEntry(action.payload.contentEntry.id)
        .pipe(
          switchMap((res) => [
            new DeleteEntrySuccess(action.payload),
            new GetContentEntries()
          ]),
          catchError((err) => {
            return of(
              new DeleteEntryError(err),
              new DisplayToastAction({
                message: text.DeleteErrorToastDescription as string,
                timeout: 0,
                title: text.DeleteErrorToastTitle as string,
                type: 'error'
              })
            );
          })
        );
    })
  );

  @Effect()
  moveEntries$ = this.actions$.pipe(
    ofType<MoveEntries>(ContentActionTypes.MoveEntries),
    switchMap((action) =>
      this.contentService.moveEntries(action.payload).pipe(
        map((res: MoveEntriesResponse) => new MoveEntriesSuccess(res)),
        catchError((err) => {
          return of(new MoveEntriesError(err));
        })
      )
    )
  );

  @Effect()
  deleteEntries$ = this.actions$.pipe(
    ofType<DeleteEntries>(ContentActionTypes.DeleteEntries),
    switchMap((action) =>
      this.contentService.deleteEntries(action.payload).pipe(
        map((res: DeleteEntriesResponse) => new DeleteEntriesSuccess(res)),
        catchError((err) => {
          return of(new DeleteEntriesError(err));
        })
      )
    )
  );

  @Effect()
  getContentEditorEnabled$ = this.actions$.pipe(
    ofType<GetContentEditorEnabled>(ContentActionTypes.GetContentEditorEnabled),
    withLatestFrom(
      this.store.pipe(select(fromAuth.getCognitoUser)),
      this.store.pipe(select(fromAuth.getContentEditorFeatureEnabled))
    ),
    map(([_action, cognitoUser, contentEditorFeatureEnabled]) => {
      if (!contentEditorFeatureEnabled) {
        return new GetContentEditorEnabledSuccess(true);
      }
      return new GetContentEditorEnabledSuccess(
        cognitoContentEditorState(cognitoUser)
      );
    }),
    catchError((err) => {
      return of(new GetContentEditorEnabledError(true));
    })
  );

  // Content Templates CRUD Effects
  // ==============================================================
  @Effect()
  createTemplate$ = this.actions$.pipe(
    ofType<CreateTemplate>(ContentActionTypes.CreateTemplate),
    switchMap((action) =>
      this.contentService.createTemplate(action.payload).pipe(
        map((res: ContentTemplate) => new CreateTemplateSuccess(res)),
        catchError((err) => {
          return of(new CreateTemplateError(err));
        })
      )
    )
  );

  @Effect()
  getTemplates$ = this.actions$.pipe(
    ofType<GetTemplates>(ContentActionTypes.GetTemplates),
    switchMap(() =>
      this.contentService.getTemplates().pipe(
        map((res: ContentTemplate[]) => new GetTemplatesSuccess(res)),
        catchError((err) => {
          return of(new GetTemplatesError(err));
        })
      )
    )
  );

  @Effect()
  amendTemplate$ = this.actions$.pipe(
    ofType<SavingTemplate>(ContentActionTypes.SavingTemplate),
    switchMap((action) =>
      this.contentService.amendTemplate(action.payload).pipe(
        map((template: ContentTemplate) => {
          return new SavingTemplateSuccess(template);
        }),
        catchError((err) => {
          return of(new SavingTemplateError(false));
        })
      )
    )
  );

  @Effect()
  deleteTemplate$ = this.actions$.pipe(
    ofType<DeleteTemplate>(ContentActionTypes.DeleteTemplate),
    switchMap((action) =>
      this.contentService.deleteTemplate(action.payload).pipe(
        map(() => {
          return new DeleteTemplateSuccess(action.payload);
        }),
        catchError((err) => {
          return of(new DeleteTemplateError(false));
        })
      )
    )
  );

  // Content Categories CRUD Effects
  // ==============================================================
  @Effect()
  getCategories$ = this.actions$.pipe(
    ofType<GetCategories>(ContentActionTypes.GetCategories),
    switchMap(() =>
      this.contentService.getContentCategories().pipe(
        map((res: Category[]) => new GetCategoriesSuccess(res)),
        catchError((err) => {
          return of(new GetCategoriesSuccess(err));
        })
      )
    )
  );

  @Effect()
  deleteCategory$ = this.actions$.pipe(
    ofType<DeleteCategory>(ContentActionTypes.DeleteCategory),
    switchMap((action) =>
      this.contentService.deleteCategory(action.payload).pipe(
        map((_) => new DeleteCategorySuccess(action.payload)),
        catchError((err) => {
          return of(new DeleteCategoryError(false));
        })
      )
    )
  );

  @Effect()
  amendCategory$ = this.actions$.pipe(
    ofType<SavingCategory>(ContentActionTypes.SavingCategory),
    switchMap((action) =>
      this.contentService.amendCategory(action.payload).pipe(
        map((category) => new SavingCategorySuccess(category)),
        catchError((err) => {
          return of(new SavingCategoryError(false));
        })
      )
    )
  );

  @Effect()
  createCategory$ = this.actions$.pipe(
    ofType<CreateCategory>(ContentActionTypes.CreateCategory),
    switchMap((action) => {
      return this.contentService.createCategory(action.payload).pipe(
        mergeMap((category: Category) => {
          return [
            formSuccessAction(`'newCategory'`, category.id),
            new CreateCategorySuccess(category)
          ];
        }),
        catchError((err) => {
          return [
            of(formErrorAction(`'newCategory'`, err)),
            of(new CreateCategoryError(false))
          ];
        })
      );
    })
  );

  @Effect()
  copyGlobalContentError$ = this.actions$.pipe(
    ofType<GetCategories>(ContentActionTypes.CopyGlobalContentError),
    withLatestFrom(
      this.store.pipe(
        select(fromSettings.getSectionTranslations('UseAContent'))
      )
    ),
    switchMap(([action, text]) => [
      new DisplayToastAction({
        message: text.CopyGlobalContentErrorDescription as string,
        timeout: 3000,
        title: text.CopyGlobalContentErrorTitle as string,
        type: 'error'
      })
    ])
  );

  @Effect()
  copyGlobalContentSuccessMissingTranslations$ = this.actions$.pipe(
    ofType<GetCategories>(
      ContentActionTypes.CopyGlobalContentSuccessMissingTranslations
    ),
    withLatestFrom(
      this.store.pipe(
        select(fromSettings.getSectionTranslations('UseAContent'))
      )
    ),
    switchMap(([action, text]) => {
      const message = text.CopyGlobalContentTranslationMissingDescription as (
        text: string
      ) => string;

      return [
        new DisplayToastAction({
          message: message(action.payload),
          timeout: 0,
          title: text.CopyGlobalContentTranslationMissingTitle as string,
          type: 'info'
        })
      ];
    })
  );

  @Effect()
  copyGlobalContentSuccessAdditionalTranslations$ = this.actions$.pipe(
    ofType<GetCategories>(
      ContentActionTypes.CopyGlobalContentSuccessAdditionalTranslations
    ),
    withLatestFrom(
      this.store.pipe(
        select(fromSettings.getSectionTranslations('UseAContent'))
      )
    ),
    switchMap(([action, text]) => {
      const message =
        text.CopyGlobalContentTranslationAdditionalDescription as (
          text: string
        ) => string;

      return [
        new DisplayToastAction({
          message: message(action.payload),
          timeout: 0,
          title: text.CopyGlobalContentTranslationAdditionalTitle as string,
          type: 'info'
        })
      ];
    })
  );

  constructor(
    private actions$: Actions,
    private contentService: ContentService,
    private store: Store<fromSettings.State>,
    private _error: ErrorHandler
  ) {}
}
