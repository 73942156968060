import { TableContentEntry } from './content-entry';

export interface LinkedContent {
  linkid: number;
  contententryheaderid: number;
  contententrytoshowheaderid: number;
}

export interface LinkItemsEvent {
  selectedItems: TableContentEntry[];
  clearSelection: () => void;
  contentCategoryId: number;
}

export interface LinkContentDialogData {
  text: any;
  selectedItems: TableContentEntry[];
  contentCategoryId: number;
}

export interface ContentEntryNames {
  id: number;
  clinicname: string;
  patientname: string;
}

export interface LinkedItemSuccessResponse {
  linkedItems: LinkedContent[];
}

export enum LinkDeletionParamTypes {
  one = 'one',
  some = 'some',
  all = 'all',
}

export interface LinkDeletionParams {
  type: LinkDeletionParamTypes;
  ids: number[];
}
