import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { Empty } from '@react/components/Empty';
import { SIDERecipeEventType } from '@react/lib/api/enum';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { useSIDERecipeActionListQuery } from '../../../../../admin/hooks';
import { EmptyActionListIcon } from '../../icons/EmptyActionListIcon';
import AppointmentRecipeActions from './AppointmentRecipeActions';
import MedicationRecipeActions from './MedicationRecipeActions';
import RegistrationRecipeActions from './RegistrationRecipeActions';
import { EmptyContainer, LoaderWrapper, StyledTable, StyledTableFooter, } from './index.styled';
var ActionList = function (_a) {
    var recipe = _a.recipe;
    var t = useTranslations().t;
    var _b = useSIDERecipeActionListQuery({ id: recipe.id }), data = _b.data, isLoading = _b.isLoading, fetchNextPage = _b.fetchNextPage, hasNextPage = _b.hasNextPage, isFetchingNextPage = _b.isFetchingNextPage, isError = _b.isError;
    useErrorToast(isError, {
        title: t.Admin.SIDE.ActionListLoadingErrorToastHeader,
        message: t.Admin.SIDE.ActionListErrorToastMessage,
    });
    function onScrollToEnd() {
        var shouldFetchNextPage = hasNextPage && !isLoading && !isFetchingNextPage;
        if (shouldFetchNextPage) {
            fetchNextPage();
        }
    }
    var loading = isLoading || isFetchingNextPage;
    var showResults = !isLoading && !isError;
    return (React.createElement(React.Fragment, null,
        showResults && data && data.pages[0].data.length === 0 && (React.createElement(EmptyContainer, null,
            React.createElement(Empty, { icon: React.createElement(EmptyActionListIcon, null), message: t.Admin.SIDE.ActionListEmptyMessage }))),
        showResults && data && data.pages[0].data.length > 0 && (React.createElement(StyledTable, null,
            recipe.eventname === SIDERecipeEventType.Appointment && (React.createElement(AppointmentRecipeActions, { data: data })),
            recipe.eventname === SIDERecipeEventType.Medication && (React.createElement(MedicationRecipeActions, { data: data })),
            recipe.eventname === SIDERecipeEventType.Registration && (React.createElement(RegistrationRecipeActions, { data: data })),
            React.createElement(StyledTableFooter, null,
                React.createElement(TableRow, null, loading && (React.createElement(TableCell, { colSpan: 3 },
                    React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                        React.createElement(CircularProgress, null)))))))),
        React.createElement(Waypoint, { onEnter: onScrollToEnd })));
};
var ɵ0 = ActionList;
export default ActionList;
export { ɵ0 };
