import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../auth/reducers';
import { CognitoWrapperService } from '../core/services/congito.wrapper.service';
import { AdminPage } from '../react/pages/admin/AdminPage';
import * as fromSettings from '../settings/reducers';
var containerElementName = 'portalAdminReactContainer';
var AdminComponent = /** @class */ (function () {
    function AdminComponent(_store, _cognito) {
        this._store = _store;
        this._cognito = _cognito;
        this._subscriptions = new Subscription();
    }
    AdminComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4];
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user,
            };
            _this.render();
        }));
    };
    AdminComponent.prototype.ngAfterViewInit = function () {
        this.render();
    };
    AdminComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
        this._subscriptions.unsubscribe();
    };
    AdminComponent.prototype.render = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                ReactDOM.render(React.createElement(AdminPage, tslib_1.__assign({}, this._props, { authService: this._cognito, dispatch: this._store.dispatch.bind(this._store) })), this.containerRef.nativeElement);
                return [2 /*return*/];
            });
        });
    };
    return AdminComponent;
}());
export { AdminComponent };
