import { Action } from '@ngrx/store';
import {
  ChildInfo,
  DeleteWithChildIdResponse,
  DeleteWithChildrenIdArrayResponse,
  DeleteWithParentIdResponse,
} from '../models/content-entry';
import { ContentEntryNames } from '../models/linked-content';
import { LinkedContent } from '../models/linked-content';

export enum LinkContentActionTypes {
  // Server requests
  GetAvailableToLinkContent = '[Link-Content] Get Content Available To Link',
  GetAvailableToLinkContentSuccess = '[Link-Content] Get Content Available To Link Success',
  GetAvailableToLinkContentError = '[Link-Content] Get Content Available To Link Error',

  LinkContent = '[Link-Content] Link Content',
  LinkContentSuccess = '[Link-Content] Link Content Success',
  LinkContentError = '[Link-Content] Link Content Error',

  DeleteContentLink = '[Link-Content] Delete Content Link',
  DeleteContentLinkSuccess = '[Link-Content] Delete Content Link Success',
  DeleteContentLinkError = '[Link-Content] Delete Content Link Error',

  DeleteSomeContentLinks = '[Link-Content] Delete Some Content Links',
  DeleteSomeContentLinksSuccess = '[Link-Content] Delete Some Content Links Success',
  DeleteSomeContentLinksError = '[Link-Content] Delete Some Content Links Error',

  DeleteAllContentLinks = '[Link-Content] Delete All Content Links',
  DeleteAllContentLinksSuccess = '[Link-Content] Delete All Content Links Success',
  DeleteAllContentLinksError = '[Link-Content] Delete All Content Links Error',

  LoadChildren = '[Link-Content] Load Children of Parent',
  LoadChildrenSuccess = '[Link-Content] Load Children of Parent Success',
  LoadChildrenError = '[Link-Content] Load Children of Parent Error',

  // UI Only
  ClearChildren = '[Link-Content] Clear Children',
  ClearErrors = '[Link-Content] Clear Errors',
  FilterTargetLink = '[Link-Content] Filter Content To Target',
  SelectedContentForLinking = '[Link-Content] Selected Content For Linking',
}

export class FilterTargetLink implements Action {
  readonly type = LinkContentActionTypes.FilterTargetLink;

  constructor(public payload: string) {}
}

export class ClearErrors implements Action {
  readonly type = LinkContentActionTypes.ClearErrors;
}

export class GetAvailableToLinkContent implements Action {
  readonly type = LinkContentActionTypes.GetAvailableToLinkContent;

  constructor(
    public payload: {
      contentCategory: number;
    },
  ) {}
}
export class GetAvailableToLinkContentSuccess implements Action {
  readonly type = LinkContentActionTypes.GetAvailableToLinkContentSuccess;

  constructor(public payload: ContentEntryNames[]) {}
}
export class GetAvailableToLinkContentError implements Action {
  readonly type = LinkContentActionTypes.GetAvailableToLinkContentError;

  constructor(public payload: any) {}
}

export class LinkContent implements Action {
  readonly type = LinkContentActionTypes.LinkContent;

  constructor(
    public payload: {
      contentToShow: number;
      contentToLink: number[];
    },
  ) {}
}
export class LinkContentSuccess implements Action {
  readonly type = LinkContentActionTypes.LinkContentSuccess;

  constructor(public payload: LinkedContent[]) {}
}
export class LinkContentError implements Action {
  readonly type = LinkContentActionTypes.LinkContentError;

  constructor(public payload: any) {}
}

export class LoadChildren implements Action {
  readonly type = LinkContentActionTypes.LoadChildren;

  constructor(
    public payload: {
      headerId: number;
    },
  ) {}
}
export class LoadChildrenSuccess implements Action {
  readonly type = LinkContentActionTypes.LoadChildrenSuccess;

  constructor(public payload: ChildInfo[]) {}
}
export class LoadChildrenError implements Action {
  readonly type = LinkContentActionTypes.LoadChildrenError;

  constructor(public payload: any) {}
}
export class ClearChildren implements Action {
  readonly type = LinkContentActionTypes.ClearChildren;
}

export class DeleteContentLink implements Action {
  readonly type = LinkContentActionTypes.DeleteContentLink;

  constructor(public payload: number) {}
}
export class DeleteContentLinkSuccess implements Action {
  readonly type = LinkContentActionTypes.DeleteContentLinkSuccess;

  constructor(public payload: DeleteWithChildIdResponse) {}
}
export class DeleteContentLinkError implements Action {
  readonly type = LinkContentActionTypes.DeleteContentLinkError;

  constructor(public payload: any) {}
}

export class DeleteSomeContentLinks implements Action {
  readonly type = LinkContentActionTypes.DeleteSomeContentLinks;

  constructor(public payload: number[]) {}
}
export class DeleteSomeContentLinksSuccess implements Action {
  readonly type = LinkContentActionTypes.DeleteSomeContentLinksSuccess;

  constructor(public payload: DeleteWithChildrenIdArrayResponse) {}
}
export class DeleteSomeContentLinksError implements Action {
  readonly type = LinkContentActionTypes.DeleteSomeContentLinksError;

  constructor(public payload: any) {}
}

export class DeleteAllContentLinks implements Action {
  readonly type = LinkContentActionTypes.DeleteAllContentLinks;

  constructor(public payload: number) {}
}
export class DeleteAllContentLinksSuccess implements Action {
  readonly type = LinkContentActionTypes.DeleteAllContentLinksSuccess;

  constructor(public payload: DeleteWithParentIdResponse) {}
}
export class DeleteAllContentLinksError implements Action {
  readonly type = LinkContentActionTypes.DeleteAllContentLinksError;

  constructor(public payload: any) {}
}

export class SelectedContentForLinking implements Action {
  readonly type = LinkContentActionTypes.SelectedContentForLinking;

  constructor(public payload: number[]) {}
}

export type LinkContentActionsUnion =
  | GetAvailableToLinkContent
  | GetAvailableToLinkContentSuccess
  | GetAvailableToLinkContentError
  | LinkContent
  | LinkContentSuccess
  | LinkContentError
  | ClearErrors
  | FilterTargetLink
  | ClearChildren
  | LoadChildren
  | LoadChildrenSuccess
  | LoadChildrenError
  | DeleteContentLink
  | DeleteContentLinkSuccess
  | DeleteContentLinkError
  | DeleteAllContentLinks
  | DeleteAllContentLinksSuccess
  | DeleteAllContentLinksError
  | DeleteSomeContentLinks
  | DeleteSomeContentLinksSuccess
  | DeleteSomeContentLinksError
  | SelectedContentForLinking;
