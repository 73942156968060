import styled from '@emotion/styled';
import { Chip } from '@material-ui/core';

export const FilterChip = styled(Chip)<{ bgColor?: string }>`
  background-color: ${(props) => props.bgColor || '#fff'};
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin: 0px 4px 0px 0px;

  .MuiChip-deleteIcon {
    color: rgba(0, 0, 0, 0.6);
  }
`;
