import styled from '@emotion/styled';
import { Editable } from 'slate-react';
import { Button } from './Button';
import { CharacterCount } from './CharacterCount';

export const EditorContainer = styled.div<{ error?: boolean }>`
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.colors.border.default}`};
  border-radius: 4px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover,
  &:focus-within {
    border: ${({ error, theme }) =>
      error
        ? `1px solid ${theme.colors.error}`
        : `1px solid ${theme.colors.primary}`};
  }
`;

export const EditorStyled = styled(Editable)`
  flex: 1;
  overflow-y: auto;
  min-height: ${({ theme }) => theme.spacing(40)}px;
  max-height: ${({ theme }) => theme.spacing(80)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const Toolbar = styled.div`
  align-items: center;
  background-color: #f7f7fb;
  border-radius: 0 0 4px 4px;
  display: flex;
  height: ${({ theme }) => theme.spacing(5)}px;
`;

export const ParagraphSpan = styled.p`
  margin: ${({ theme }) => theme.spacing(0.25)}px;
`;

export const VariablesButton = styled(Button)`
  font-weight: normal;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  margin-right: auto;
`;

export const StyledCharacterCount = styled(CharacterCount)`
  margin: ${({ theme }) => theme.spacing(0.5, 0.5, 0.5, 'auto')};
`;
