<div class="folder-info-container">
  <mat-card>
    <mat-card-title>{{ text['UnresolvedMessages'] }}</mat-card-title>
    <mat-card-content>
      <portal-folder-info
        *ngFor="let label of messagingSummary.results"
        [messageTypeId]="label.MessageTypeId"
        [name]="label.MessageType"
        [text]="text['Unresolved'](label.MessageType)"
        [count]="label.Unresolved"
        (goto)="goToLabel.emit($event)"
        (goToMessages)="goToMessages.emit($event)"
      >
      </portal-folder-info>
    </mat-card-content>
  </mat-card>
</div>
