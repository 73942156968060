import * as tslib_1 from "tslib";
import { AuthActionTypes } from '../actions/auth.actions';
import convertCognitoUser from '../utils/convert-cognito-user';
export var initialState = {
    loggedIn: false,
    loggingIn: false,
    updatingPassword: false,
    updatePasswordError: null,
    user: null,
    isCognito: false,
    cognitoUser: null,
    clinicId: '',
    isCore: null,
    clinicName: '',
    languageCode: '',
    availableToSelect: null,
    inviteCode: '',
    generatingCode: false,
    codeGenerated: false,
    resettingPassword: false,
    otpError: false,
    clinicGroupId: null,
    internalClinicId: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.CoreLogin: {
            return tslib_1.__assign({}, state, { isCognito: true, loggingIn: true, otpError: false });
        }
        case AuthActionTypes.GetClinicIdSuccess: {
            return tslib_1.__assign({}, state, { clinicId: action.payload.Token, isCore: action.payload.Core, clinicName: action.payload.Name, languageCode: action.payload.LanguageCode, availableToSelect: action.payload.AvailableToSelect, inviteCode: action.payload.InviteCode, clinicGroupId: action.payload.clinicgroupid, internalClinicId: action.payload.internalclinicid });
        }
        case AuthActionTypes.CoreLoginSuccess: {
            var _a = convertCognitoUser(action.payload.user), user = _a.user, signInUserSession = _a.signInUserSession;
            return tslib_1.__assign({}, state, { loggingIn: false, loggedIn: true, isCognito: true, otpError: false, user: user, cognitoUser: signInUserSession });
        }
        case AuthActionTypes.CoreLoginFailure: {
            return tslib_1.__assign({}, state, { loggingIn: false, loggedIn: false, otpError: false, isCognito: true, user: null, cognitoUser: null });
        }
        case AuthActionTypes.Logout: {
            return tslib_1.__assign({}, state, { loggingIn: false, loggedIn: false, otpError: false, user: null, cognitoUser: null });
        }
        case AuthActionTypes.CompletePassword: {
            return tslib_1.__assign({}, state, { updatingPassword: true });
        }
        case AuthActionTypes.CompletePasswordSuccess: {
            var _b = convertCognitoUser(action.payload.user), user = _b.user, signInUserSession = _b.signInUserSession;
            return tslib_1.__assign({}, state, { updatingPassword: false, loggingIn: false, loggedIn: true, isCognito: true, user: user, cognitoUser: signInUserSession });
        }
        case AuthActionTypes.CompletePasswordFailure: {
            return tslib_1.__assign({}, state, { updatingPassword: false, updatePasswordError: action.payload, cognitoUser: action.payload.user });
        }
        case AuthActionTypes.TempSuccess: {
            return tslib_1.__assign({}, state, { loggingIn: false, loggedIn: false, isCognito: true, cognitoUser: action.payload.user });
        }
        case AuthActionTypes.ResetPassword: {
            return tslib_1.__assign({}, state, { resettingPassword: true });
        }
        case AuthActionTypes.ResetPasswordSuccess:
        case AuthActionTypes.ResetPasswordFailure: {
            return tslib_1.__assign({}, state, { resettingPassword: false });
        }
        case AuthActionTypes.GenerateResetCode: {
            return tslib_1.__assign({}, state, { generatingCode: true });
        }
        case AuthActionTypes.GenerateResetCodeSuccess: {
            return tslib_1.__assign({}, state, { codeGenerated: true, generatingCode: false });
        }
        case AuthActionTypes.GenerateResetCodeFailure: {
            return tslib_1.__assign({}, state, { generatingCode: false });
        }
        case AuthActionTypes.ClearCodeGenerated: {
            return tslib_1.__assign({}, state, { updatingPassword: false, codeGenerated: false });
        }
        case AuthActionTypes.OTPRequired: {
            return tslib_1.__assign({}, state, { loggingIn: false, cognitoUser: action.payload.user });
        }
        case AuthActionTypes.VerifyOTP: {
            return tslib_1.__assign({}, state, { loggingIn: true, cognitoUser: action.payload.user });
        }
        case AuthActionTypes.OTPRejected: {
            return tslib_1.__assign({}, state, { loggingIn: false, cognitoUser: action.payload.user, otpError: true });
        }
        case AuthActionTypes.OTPLoginFailure: {
            return tslib_1.__assign({}, state, { loggingIn: false, loggedIn: false, otpError: false, isCognito: true, user: null, cognitoUser: null });
        }
        default: {
            return state;
        }
    }
}
export var isLoggingIn = function (state) { return state.loggingIn; };
export var OTPError = function (state) { return state.otpError; };
export var isCognito = function (state) { return state.isCognito; };
export var getCognitoUser = function (state) { return state.cognitoUser; };
export var getLoggedIn = function (state) { return state.loggedIn; };
export var getUser = function (state) { return state.user; };
export var getClinicId = function (state) { return state.clinicId; };
export var getPublicKey = function (user) { return (user ? user.PublicToken : null); };
export var getClinicName = function (state) { return state.clinicName; };
export var getLanguageCode = function (state) { return state.languageCode; };
export var getAvailableToSelect = function (state) { return state.availableToSelect; };
export var getInviteCode = function (state) { return state.inviteCode; };
export var getCore = function (state) { return state.isCore; };
export var isUpdatingPassword = function (state) { return state.updatingPassword; };
export var isResettingPassword = function (state) { return state.resettingPassword; };
export var isGeneratingCode = function (state) { return state.generatingCode; };
export var hasGeneratedCode = function (state) { return state.codeGenerated; };
export var getInternalClinicId = function (state) { return state.internalClinicId; };
export var getClinicGroupId = function (state) { return state.clinicGroupId; };
export var hasUpdatePasswordError = function (state) {
    return state.updatePasswordError;
};
