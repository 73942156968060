import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromSettings from '../../../settings/reducers';
var RichOrPdfComponent = /** @class */ (function () {
    function RichOrPdfComponent(_navigationService, _route, _store) {
        var _this = this;
        this._navigationService = _navigationService;
        this._route = _route;
        this._store = _store;
        this._subs = new Subscription();
        this._subs.add(this._route.params
            .pipe(map(function (_a) {
            var id = _a.id;
            return +id;
        }))
            .subscribe(function (v) { return (_this.categoryId = v); }));
    }
    RichOrPdfComponent.prototype.ngOnInit = function () {
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateContentItem')));
    };
    RichOrPdfComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    RichOrPdfComponent.prototype.goBackToList = function () {
        this._navigationService.navigate(['content', 'list', this.categoryId]);
    };
    RichOrPdfComponent.prototype.goToPdf = function () {
        this._navigationService.navigate([
            'content',
            'doc',
            'create',
            'pdf',
            this.categoryId,
        ]);
    };
    RichOrPdfComponent.prototype.goToRichContent = function () {
        this._navigationService.navigate([
            'content',
            'doc',
            'create',
            'rich',
            this.categoryId,
        ]);
    };
    return RichOrPdfComponent;
}());
export { RichOrPdfComponent };
