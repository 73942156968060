import {
  Empty,
  InfiniteScroll,
  SearchIcon,
  Tab,
  TabPanel,
  Tabs,
} from '@react/components';
import {
  AssignClinicUsers,
  ConversationFolder,
  ConversationsResponse,
} from '@react/lib/api/types';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { Conversation } from '@react/types';
import React, { FunctionComponent, useEffect } from 'react';
import { STARRED_DELAY_MS, Status, StatusName } from '../constants';
import {
  useConversationListQuery,
  useConversationsCountByStatusQuery,
  useConversationUpdateMutation,
  useGetConversationActionHandler,
  useGetConversationActions,
  useMessageMutation,
  useMessagingNotifications,
} from '../hooks';
import {
  ConversationActionMenuItem,
  FilterState,
  StarredStatus,
} from '../types';
import { ConversationCard, ConversationCardProps } from './ConversationCard';
import { ConversationsContainer } from './ui';

interface Props {
  filters: FilterState;
  folders: ConversationFolder[];
  getTypingConversationMessage: (conversationId: number) => string | undefined;
  onTabChange: (index: number) => void;
  onConversationClick: (conversation: Conversation) => void;
  selectedConversationId?: number;
  selectedTab: number;
  assignClinicUsers: AssignClinicUsers;
}

export const Conversations: FunctionComponent<Props> = ({
  filters,
  folders,
  getTypingConversationMessage,
  onTabChange,
  onConversationClick,
  selectedConversationId,
  selectedTab,
  assignClinicUsers,
}: Props) => {
  const { t } = useTranslations();
  const handleAction = useGetConversationActionHandler();
  const getActions = useGetConversationActions('conversation');
  const debouncedQuery = useDebouncedState(filters.q, 500);

  useMessagingNotifications(filters);

  const counts = useConversationsCountByStatusQuery(filters, debouncedQuery);
  const conversationsQueryResult = useConversationListQuery(
    filters,
    debouncedQuery,
  );

  const { mutateAsync: updateMessageAsync } = useMessageMutation();
  const { mutateAsync: updateConversationAsync } =
    useConversationUpdateMutation();

  const showConversationType =
    filters.folderIds == null || filters.folderIds.length !== 1;

  const handleChange = (
    _: React.ChangeEvent<{}>,
    value: StarredStatus,
  ): void => {
    onTabChange(value);
  };

  const handleConversationClick = (conversation: Conversation): void => {
    onConversationClick(conversation);

    // Mark as read by the clinic
    if (conversation.patientsent && !conversation.clinicread) {
      updateMessageAsync({
        id: conversation.messageitemid,
        messageid: conversation.messageid,
        read: true,
      });
    }
  };

  const handleActionClick = (
    conversation: Conversation,
    action: ConversationActionMenuItem,
  ): void => {
    handleAction(action, conversation);
  };

  let timeoutId: NodeJS.Timer;

  const toggleStarConversation = (conversation: Conversation) => {
    timeoutId = setTimeout(() => {
      updateConversationAsync({
        ...conversation,
        isstarred: conversation.isstarred,
      });
    }, STARRED_DELAY_MS);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, []);

  const getConversationCardProps = (
    conversation: Conversation,
  ): ConversationCardProps => ({
    ...conversation,
    key: conversation.messageid,
    onClick: handleConversationClick,
    onStarClick: toggleStarConversation,
    onActionClick: handleActionClick,
    showType: showConversationType,
    actions: getActions(conversation, folders),
    selected: conversation.messageid === selectedConversationId,
    userTypingMessage: getTypingConversationMessage(conversation.messageid),
    assignClinicUsers,
  });

  const conversationsContainerProps = {
    infiniteQueryResult: conversationsQueryResult,
    endOfResults: (
      <div className="conversation-end-of-list-message">
        <Empty message={t.Messages.EndOfConversation} />
      </div>
    ),
    noResults: (
      <Empty icon={<SearchIcon />} message={t.Messages.NoSearchResults} />
    ),
  };

  return (
    <ConversationsContainer>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab
          count={counts[StatusName.STARRED] || null}
          label={t.Messages.StarredTab}
          tabIndex={Status.STARRED}
        />
        <Tab
          count={counts[StatusName.OTHERS] || null}
          label={t.Messages.EverythingElseTab}
          tabIndex={Status.OTHERS}
        />
      </Tabs>
      <TabPanel value={selectedTab} index={Status.STARRED}>
        <InfiniteScroll<ConversationsResponse, Conversation>
          {...conversationsContainerProps}
          renderItem={(conversation) => (
            <ConversationCard {...getConversationCardProps(conversation)} />
          )}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={Status.OTHERS}>
        <InfiniteScroll<ConversationsResponse, Conversation>
          {...conversationsContainerProps}
          renderItem={(conversation) => (
            <ConversationCard {...getConversationCardProps(conversation)} />
          )}
        />
      </TabPanel>
    </ConversationsContainer>
  );
};
