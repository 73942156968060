import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import { cognitoContentEditorState } from '../../auth/utils/convert-cognito-user';
import { DisplayToastAction } from '../../core/actions/toast.actions';
import * as fromSettings from '../../settings/reducers';
import { ContentActionTypes, CreateCategoryError, CreateCategorySuccess, CreateEntryError, CreateEntrySuccess, CreateTemplateError, CreateTemplateSuccess, DeleteCategoryError, DeleteCategorySuccess, DeleteEntriesError, DeleteEntriesSuccess, DeleteEntryError, DeleteEntrySuccess, DeleteTemplateError, DeleteTemplateSuccess, GetCategoriesSuccess, GetContentEditorEnabledError, GetContentEditorEnabledSuccess, GetContentEntries, GetContentEntriesError, GetContentEntriesSuccess, GetContentEntryError, GetContentEntrySuccess, GetTemplatesError, GetTemplatesSuccess, MoveEntriesError, MoveEntriesSuccess, SaveEntryError, SaveEntrySuccess, SavingCategoryError, SavingCategorySuccess, SavingTemplateError, SavingTemplateSuccess } from '../actions/content.actions';
import { formErrorAction, formSuccessAction } from '../directives/connect-form.directive';
import { ContentService } from '../services/content.service';
var ContentEffects = /** @class */ (function () {
    function ContentEffects(actions$, contentService, store, _error) {
        var _this = this;
        this.actions$ = actions$;
        this.contentService = contentService;
        this.store = store;
        this._error = _error;
        // Content Entries CRUD Effects
        // ==============================================================
        this.createEntry$ = this.actions$.pipe(ofType(ContentActionTypes.CreateEntry), withLatestFrom(this.store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorControls')))), switchMap(function (_a) {
            var action = _a[0], text = _a[1];
            var request = tslib_1.__assign({}, action.payload.contentEntry);
            if ('parentId' in action.payload && action.payload.parentId != null) {
                request.translation = {
                    languagecode: action.payload.languageCode,
                    maincontentheaderid: action.payload.parentId
                };
            }
            return _this.contentService.createEntry(request).pipe(switchMap(function (res) { return [
                new CreateEntrySuccess({
                    contentEntry: res,
                    languageCode: 'languageCode' in action.payload
                        ? action.payload.languageCode
                        : undefined
                }),
                new DisplayToastAction({
                    message: text.SuccessToastDescription,
                    timeout: 3000,
                    title: text.SuccessToastTitle,
                    type: 'success'
                }),
                new GetContentEntries()
            ]; }), catchError(function (err) {
                return of(new CreateEntryError(err), new DisplayToastAction({
                    message: text.ErrorToastDescription,
                    timeout: 0,
                    title: text.ErrorToastTitle,
                    type: 'error'
                }));
            }));
        }));
        this.getEntries$ = this.actions$.pipe(ofType(ContentActionTypes.GetContentEntries), switchMap(function () {
            return _this.contentService.getContentEntryHeaders().pipe(
            // TODO: Fix types
            // @ts-ignore
            map(function (res) { return new GetContentEntriesSuccess(res); }), catchError(function (err) {
                return of(new GetContentEntriesError(err));
            }));
        }));
        this.getContentEntryById$ = this.actions$.pipe(ofType(ContentActionTypes.GetContentEntry), switchMap(function (action) {
            return _this.contentService.getContentEntryById(action.payload);
        }), switchMap(function (contentEntry) {
            return forkJoin([
                of(contentEntry)
            ].concat(contentEntry.translations.reduce(function (prev, curr) { return prev.concat([
                _this.contentService
                    .getContentEntryById(curr.contententryheaderid)
                    .pipe(take(1))
            ]); }, [])));
        }), map(function (res) {
            var defaultContentEntry = res[0], rest = res.slice(1);
            var payload = {
                default: defaultContentEntry
            };
            payload = rest.reduce(function (prev, curr) {
                var _a;
                var contentEntryTranslation = defaultContentEntry.translations.find(function (df) { return df.contententryheaderid === curr.id; });
                return tslib_1.__assign({}, prev, (_a = {}, _a[contentEntryTranslation.languagecode] = curr, _a));
            }, payload);
            return new GetContentEntrySuccess(payload);
        }), catchError(function (err) {
            return of(new GetContentEntryError(err));
        }));
        this.amendEntry$ = this.actions$.pipe(ofType(ContentActionTypes.SaveEntry), withLatestFrom(this.store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorControls')))), switchMap(function (_a) {
            var action = _a[0], text = _a[1];
            return _this.contentService.amendEntry(action.payload.contentEntry).pipe(switchMap(function (res) { return [
                new SaveEntrySuccess({
                    contentEntry: res,
                    languageCode: action.payload.languageCode
                }),
                new DisplayToastAction({
                    message: text.SuccessToastDescription,
                    timeout: 3000,
                    title: text.SuccessToastTitle,
                    type: 'success'
                }),
                new GetContentEntries()
            ]; }), catchError(function (err) {
                return of(new SaveEntryError(err), new DisplayToastAction({
                    message: text.ErrorToastDescription,
                    timeout: 0,
                    title: text.ErrorToastTitle,
                    type: 'error'
                }));
            }));
        }));
        this.deleteEntry$ = this.actions$.pipe(ofType(ContentActionTypes.DeleteEntry), withLatestFrom(this.store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorControls')))), switchMap(function (_a) {
            var action = _a[0], text = _a[1];
            if (action.payload.contentEntry.id == null) {
                return of(new DeleteEntrySuccess(action.payload));
            }
            return _this.contentService
                .deleteEntry(action.payload.contentEntry.id)
                .pipe(switchMap(function (res) { return [
                new DeleteEntrySuccess(action.payload),
                new GetContentEntries()
            ]; }), catchError(function (err) {
                return of(new DeleteEntryError(err), new DisplayToastAction({
                    message: text.DeleteErrorToastDescription,
                    timeout: 0,
                    title: text.DeleteErrorToastTitle,
                    type: 'error'
                }));
            }));
        }));
        this.moveEntries$ = this.actions$.pipe(ofType(ContentActionTypes.MoveEntries), switchMap(function (action) {
            return _this.contentService.moveEntries(action.payload).pipe(map(function (res) { return new MoveEntriesSuccess(res); }), catchError(function (err) {
                return of(new MoveEntriesError(err));
            }));
        }));
        this.deleteEntries$ = this.actions$.pipe(ofType(ContentActionTypes.DeleteEntries), switchMap(function (action) {
            return _this.contentService.deleteEntries(action.payload).pipe(map(function (res) { return new DeleteEntriesSuccess(res); }), catchError(function (err) {
                return of(new DeleteEntriesError(err));
            }));
        }));
        this.getContentEditorEnabled$ = this.actions$.pipe(ofType(ContentActionTypes.GetContentEditorEnabled), withLatestFrom(this.store.pipe(select(fromAuth.getCognitoUser)), this.store.pipe(select(fromAuth.getContentEditorFeatureEnabled))), map(function (_a) {
            var _action = _a[0], cognitoUser = _a[1], contentEditorFeatureEnabled = _a[2];
            if (!contentEditorFeatureEnabled) {
                return new GetContentEditorEnabledSuccess(true);
            }
            return new GetContentEditorEnabledSuccess(cognitoContentEditorState(cognitoUser));
        }), catchError(function (err) {
            return of(new GetContentEditorEnabledError(true));
        }));
        // Content Templates CRUD Effects
        // ==============================================================
        this.createTemplate$ = this.actions$.pipe(ofType(ContentActionTypes.CreateTemplate), switchMap(function (action) {
            return _this.contentService.createTemplate(action.payload).pipe(map(function (res) { return new CreateTemplateSuccess(res); }), catchError(function (err) {
                return of(new CreateTemplateError(err));
            }));
        }));
        this.getTemplates$ = this.actions$.pipe(ofType(ContentActionTypes.GetTemplates), switchMap(function () {
            return _this.contentService.getTemplates().pipe(map(function (res) { return new GetTemplatesSuccess(res); }), catchError(function (err) {
                return of(new GetTemplatesError(err));
            }));
        }));
        this.amendTemplate$ = this.actions$.pipe(ofType(ContentActionTypes.SavingTemplate), switchMap(function (action) {
            return _this.contentService.amendTemplate(action.payload).pipe(map(function (template) {
                return new SavingTemplateSuccess(template);
            }), catchError(function (err) {
                return of(new SavingTemplateError(false));
            }));
        }));
        this.deleteTemplate$ = this.actions$.pipe(ofType(ContentActionTypes.DeleteTemplate), switchMap(function (action) {
            return _this.contentService.deleteTemplate(action.payload).pipe(map(function () {
                return new DeleteTemplateSuccess(action.payload);
            }), catchError(function (err) {
                return of(new DeleteTemplateError(false));
            }));
        }));
        // Content Categories CRUD Effects
        // ==============================================================
        this.getCategories$ = this.actions$.pipe(ofType(ContentActionTypes.GetCategories), switchMap(function () {
            return _this.contentService.getContentCategories().pipe(map(function (res) { return new GetCategoriesSuccess(res); }), catchError(function (err) {
                return of(new GetCategoriesSuccess(err));
            }));
        }));
        this.deleteCategory$ = this.actions$.pipe(ofType(ContentActionTypes.DeleteCategory), switchMap(function (action) {
            return _this.contentService.deleteCategory(action.payload).pipe(map(function (_) { return new DeleteCategorySuccess(action.payload); }), catchError(function (err) {
                return of(new DeleteCategoryError(false));
            }));
        }));
        this.amendCategory$ = this.actions$.pipe(ofType(ContentActionTypes.SavingCategory), switchMap(function (action) {
            return _this.contentService.amendCategory(action.payload).pipe(map(function (category) { return new SavingCategorySuccess(category); }), catchError(function (err) {
                return of(new SavingCategoryError(false));
            }));
        }));
        this.createCategory$ = this.actions$.pipe(ofType(ContentActionTypes.CreateCategory), switchMap(function (action) {
            return _this.contentService.createCategory(action.payload).pipe(mergeMap(function (category) {
                return [
                    formSuccessAction("'newCategory'", category.id),
                    new CreateCategorySuccess(category)
                ];
            }), catchError(function (err) {
                return [
                    of(formErrorAction("'newCategory'", err)),
                    of(new CreateCategoryError(false))
                ];
            }));
        }));
        this.copyGlobalContentError$ = this.actions$.pipe(ofType(ContentActionTypes.CopyGlobalContentError), withLatestFrom(this.store.pipe(select(fromSettings.getSectionTranslations('UseAContent')))), switchMap(function (_a) {
            var action = _a[0], text = _a[1];
            return [
                new DisplayToastAction({
                    message: text.CopyGlobalContentErrorDescription,
                    timeout: 3000,
                    title: text.CopyGlobalContentErrorTitle,
                    type: 'error'
                })
            ];
        }));
        this.copyGlobalContentSuccessMissingTranslations$ = this.actions$.pipe(ofType(ContentActionTypes.CopyGlobalContentSuccessMissingTranslations), withLatestFrom(this.store.pipe(select(fromSettings.getSectionTranslations('UseAContent')))), switchMap(function (_a) {
            var action = _a[0], text = _a[1];
            var message = text.CopyGlobalContentTranslationMissingDescription;
            return [
                new DisplayToastAction({
                    message: message(action.payload),
                    timeout: 0,
                    title: text.CopyGlobalContentTranslationMissingTitle,
                    type: 'info'
                })
            ];
        }));
        this.copyGlobalContentSuccessAdditionalTranslations$ = this.actions$.pipe(ofType(ContentActionTypes.CopyGlobalContentSuccessAdditionalTranslations), withLatestFrom(this.store.pipe(select(fromSettings.getSectionTranslations('UseAContent')))), switchMap(function (_a) {
            var action = _a[0], text = _a[1];
            var message = text.CopyGlobalContentTranslationAdditionalDescription;
            return [
                new DisplayToastAction({
                    message: message(action.payload),
                    timeout: 0,
                    title: text.CopyGlobalContentTranslationAdditionalTitle,
                    type: 'info'
                })
            ];
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "createEntry$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "getEntries$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "getContentEntryById$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "amendEntry$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "deleteEntry$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "moveEntries$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "deleteEntries$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "getContentEditorEnabled$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "createTemplate$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "getTemplates$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "amendTemplate$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "deleteTemplate$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "getCategories$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "deleteCategory$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "amendCategory$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "createCategory$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "copyGlobalContentError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "copyGlobalContentSuccessMissingTranslations$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentEffects.prototype, "copyGlobalContentSuccessAdditionalTranslations$", void 0);
    return ContentEffects;
}());
export { ContentEffects };
