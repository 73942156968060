import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';
import { useMessageContentLinkExtraction } from './useMessageContentLinkExtraction';
export function useCreateConversationMutation() {
    var queryClient = useQueryClient();
    var getContentLinkIds = useMessageContentLinkExtraction().getContentLinkIds;
    var apiClient = useApiClient().portal;
    return useMutation(function (conversation) {
        var contentLinkIds = getContentLinkIds(conversation.content);
        return apiClient.createConversation(tslib_1.__assign({}, conversation, { metadata: { contentLinkIds: contentLinkIds } }));
    }, {
        onSuccess: function () {
            Object.keys(queryCacheKey).forEach(function (cacheKey) {
                queryClient.invalidateQueries(cacheKey);
            });
        },
    });
}
