import { Tooltip } from '@material-ui/core';
import { CognitoStatus } from '@react/lib/api/types';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { ActiveStyledChip, InactiveStyledChip, } from './index.styles';
var CognitoStatusChip = function (props) {
    var t = useTranslations().t;
    switch (props.status) {
        case CognitoStatus.Active:
        default: {
            return (React.createElement(Tooltip, { title: React.createElement("span", { style: { fontWeight: 'normal' } }, t.Admin.Users.TooltipActive), placement: "right" },
                React.createElement(ActiveStyledChip, { label: props.label })));
        }
        case CognitoStatus.Deactivated: {
            return (React.createElement(Tooltip, { title: React.createElement("span", { style: { fontWeight: 'normal' } }, t.Admin.Users.TooltipInactive), placement: "right" },
                React.createElement(InactiveStyledChip, { label: props.label })));
        }
    }
};
var ɵ0 = CognitoStatusChip;
export default CognitoStatusChip;
export { ɵ0 };
