import { Popover, Typography } from '@material-ui/core';
import { Close, ExpandMoreOutlined } from '@material-ui/icons';
import * as React from 'react';
import { IKeyNumVal } from 'src/app/react/lib/api/types';
import { useTranslations } from 'src/app/react/lib/i18n';
import { FilterChip } from './FilterChip';
import { Loading } from './Loading';
import { StyledContainer, StyledLink, StyledList } from './PopoverChip.styled';

interface IProps {
  data: IKeyNumVal[];
  label: string;
  onDelete: (item: number) => void;
  clearAll: () => void;
  loading?: boolean;
}

export function PopoverChip({
  data,
  onDelete,
  label,
  clearAll,
  loading,
}: IProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const popoverAnchor = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslations();
  return (
    <>
      <FilterChip
        id={`${label.toLowerCase()}-chip`}
        label={label}
        ref={popoverAnchor}
        onDelete={() => setOpen(true)}
        deleteIcon={<ExpandMoreOutlined />}
        onClick={() => setOpen(true)}
      />
      <Popover
        id={`${label.toLowerCase()}-filter-Popover`}
        open={open}
        anchorEl={popoverAnchor.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledContainer>
          <StyledLink onClick={clearAll}>
            {t.Admin.SIDE.ClearAllFilter}
          </StyledLink>
          <StyledList>
            {data.map((item: IKeyNumVal) => (
              <li key={`popover-${item.value}`}>
                <Typography>{item.key}</Typography>
                <Close className="close" onClick={() => onDelete(item.value)} />
              </li>
            ))}
          </StyledList>
          {loading && <Loading />}
        </StyledContainer>
      </Popover>
    </>
  );
}
