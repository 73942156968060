<div
  class="content-edit-body-container"
  [formGroup]="sectionsForm"
  fxLayout="column"
  fxLayoutGap="15px"
  fxLayoutAlign="start stretch"
>
  <div
    formArrayName="requiredSections"
    class="required-sections"
    fxLayout="column"
    fxLayoutGap="15px"
  >
    <div *ngFor="let reqSection of requiredSections.controls; let i = index">
      <div [formGroupName]="i">
        <div
          *ngIf="reqSection.get('type').value === 'introduction'"
          class="req-section-entry"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="15px"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div class="entry-section-title">
              {{ text.Introduction }}
            </div>
            <div class="entry-section-sub">
              {{ text.Required }}
            </div>
          </div>
          <quill-editor
            class="intro-textarea"
            [class.error]="introError$ | async"
            [style]="{ height: '200px' }"
            id="{{ 'reqSection' + i }}"
            [modules]="editorConfig"
            [placeholder]="text.AddIntroduction"
            formControlName="content"
            (onEditorCreated)="editorCreated($event)"
            (onContentChanged)="getIntroductionText($event)"
            (click)="setFocus($event)"
            [readOnly]="!contentEditorEnabled"
          ></quill-editor>
          <div class="text-area-message" [class.error]="introError$ | async">
            {{ text.TextOnly }}
            <span class="count">{{ introLength - introText.length }}</span>
            {{ text.CharsRemaining }}
          </div>
          <div *ngIf="introError$ | async" class="text-area-message error">
            {{ text.MinLength }}
          </div>
        </div>
        <div
          *ngIf="reqSection.get('type').value === 'video'"
          class="req-section-entry"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="10px"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <div class="entry-section-title">
                {{ text.Video }}
              </div>
              <button
                mat-raised-button
                class="portal-btn btn-upload"
                (click)="addVideo()"
                [disabled]="!contentEditorEnabled"
              >
                <span class="btn-text">{{ text.AddVideo }}</span>
              </button>
              <button
                mat-raised-button
                class="portal-btn btn-warn"
                *ngIf="showRemoveVideoButton$ | async"
                (click)="removeVideo()"
                [disabled]="!contentEditorEnabled"
              >
                {{ text.RemoveVideo }}
              </button>
            </div>
            <portal-video-preview
              [html]="videoPreview$ | async"
            ></portal-video-preview>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
