import { CompletePassword, EnableForm } from './../../actions/auth.actions';
import * as fromSettings from '../../../settings/reducers';
import * as fromAuth from '../../reducers';
import { select, Store } from '@ngrx/store';
var CreateAccountContainer = /** @class */ (function () {
    function CreateAccountContainer(_store) {
        this._store = _store;
        this.isCompletingPassword$ = this._store.pipe(select(fromAuth.isUpdatingPassword));
        this.cognitoUser$ = this._store.pipe(select(fromAuth.getCognitoUser));
        this.createAccountError$ = this._store.pipe(select(fromAuth.hasUpdatePasswordError));
        this.authText$ = this._store.pipe(select(fromSettings.getSectionTranslations('Authentication')));
        this._store.dispatch(new EnableForm());
    }
    CreateAccountContainer.prototype.handleAccountSubmission = function (args) {
        this._store.dispatch(new CompletePassword(args));
    };
    return CreateAccountContainer;
}());
export { CreateAccountContainer };
