import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { FormControl, Radio, Typography } from '@material-ui/core';
export var LoaderWrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n"], ["\n  flex: 1;\n  text-align: center;\n"])));
export var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", "px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var AppointmentTypeTitle = styled(Typography)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  color: #505050;\n  margin-left: ", "px;\n"], ["\n  color: #505050;\n  margin-left: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledFormControl = styled(FormControl)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  top: -10px;\n"], ["\n  top: -10px;\n"])));
export var StyledFormControlLabel = styled(Typography)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  color: #505050;\n  font-weight: ", ";\n"], ["\n  color: #505050;\n  font-weight: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightBold;
});
export var StyledRadio = styled(Radio)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  color: #505050;\n\n  &.Mui-checked {\n    color: #505050;\n  }\n"], ["\n  color: #505050;\n\n  &.Mui-checked {\n    color: #505050;\n  }\n"])));
export var ProvisionalContent = styled.div(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var ProvisionalHoursContainer = styled.div(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  gap: ", "px;\n  padding-left: ", "px;\n"], ["\n  display: flex;\n  gap: ", "px;\n  padding-left: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(3.5);
});
export var ProvisionalInput = styled.input(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["\n  max-width: 60px;\n"], ["\n  max-width: 60px;\n"])));
export var FormActions = styled.div(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  padding: ", "px 0;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  padding: ", "px 0;\n  justify-content: flex-end;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
