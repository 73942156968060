import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Paper, Table, TableFooter, TableHead, TableRow, } from '@material-ui/core';
export var StyledTable = styled(Table)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  table-layout: fixed;\n"], ["\n  table-layout: fixed;\n"])));
export var StyledTableContainer = styled(Paper)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledTableHead = styled(TableHead)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  th {\n    font-size: 16px;\n    font-weight: ", ";\n    color: #757575;\n    vertical-align: top;\n\n    &.idcol {\n      width: 40px;\n    }\n\n    &.contentcol {\n      width: 30%;\n    }\n  }\n"], ["\n  th {\n    font-size: 16px;\n    font-weight: ", ";\n    color: #757575;\n    vertical-align: top;\n\n    &.idcol {\n      width: 40px;\n    }\n\n    &.contentcol {\n      width: 30%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightMedium;
});
export var StyledTableRow = styled(TableRow)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  td {\n    vertical-align: top;\n\n    &.truncate {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n"], ["\n  td {\n    vertical-align: top;\n\n    &.truncate {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n"])));
export var StyledTableFooter = styled(TableFooter)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  td {\n    border-bottom: 0;\n  }\n"], ["\n  td {\n    border-bottom: 0;\n  }\n"])));
export var LoaderWrapper = styled.div(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"], ["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"])));
export var EmptyContainer = styled.div(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n  height: 400px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n  height: 400px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
