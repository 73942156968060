import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ToastActions, ToastDisplayedAction, ToastRemovedAction, } from '../actions/toast.actions';
var ToastEffects = /** @class */ (function () {
    function ToastEffects(_actions$, _toastrService) {
        var _this = this;
        this._actions$ = _actions$;
        this._toastrService = _toastrService;
        this.displayToast = this._actions$.pipe(ofType(ToastActions.DisplayToast), switchMap(function (action) {
            return new Promise(function (resolve) {
                var requestedToast = action.payload.toastRef;
                var requestedToastActive = requestedToast &&
                    requestedToast.toastRef.componentInstance.state.value === 'active';
                var newToast;
                if (!requestedToast || !requestedToastActive) {
                    newToast = _this._toastrService.show(action.payload.message, action.payload.title, {
                        toastClass: "toast custom-toast " + action.payload.type,
                        timeOut: action.payload.timeout,
                    });
                }
                else {
                    requestedToast.toastRef.componentInstance.alterToast({
                        class: {
                            classesToRemove: ['info', 'success', 'error'],
                            classToAdd: action.payload.type,
                        },
                        title: action.payload.title,
                        message: action.payload.message,
                    });
                    if (action.payload.timeout > 0) {
                        setTimeout(function () {
                            requestedToast.toastRef.componentInstance.remove();
                        }, action.payload.timeout);
                    }
                }
                // In some cases toastrService.show can return null
                var toast = newToast || requestedToast;
                if (toast) {
                    toast.onHidden.subscribe(function () {
                        resolve(new ToastRemovedAction());
                    });
                    resolve(new ToastDisplayedAction({ toastRef: toast }));
                }
            });
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ToastEffects.prototype, "displayToast", void 0);
    return ToastEffects;
}());
export { ToastEffects };
