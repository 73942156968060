<div class="table-container">
  <table mat-table [dataSource]="attachments">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="id-header"></th>
      <!-- <td
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        > -->
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          (click)="contentEditorEnabled && handleRemoveAttachment(i)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
      <!-- <div>
          {{element.id}}
        </div> -->
      <!-- </td> -->
    </ng-container>

    <ng-container matColumnDef="documentname">
      <th mat-header-cell *matHeaderCellDef>{{ text.Name }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.documentname }}
      </td>
    </ng-container>

    <ng-container matColumnDef="filetype">
      <th mat-header-cell *matHeaderCellDef>{{ text.FileType }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.filetype }}
      </td>
    </ng-container>

    <ng-container matColumnDef="filesize">
      <th mat-header-cell *matHeaderCellDef>{{ text.FileSize }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.filesize }}
      </td>
    </ng-container>

    <ng-container matColumnDef="preview">
      <th mat-header-cell *matHeaderCellDef>{{ text.Preview }}</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          class="portal-btn btn-primary"
          (click)="readFile(element)"
        >
          {{ text.CheckFile }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="attachment-row"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</div>
