import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromSettings from '../../../settings/reducers';
var PortalToastComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PortalToastComponent, _super);
    // constructor is only necessary when not using AoT
    function PortalToastComponent(toastrService, toastPackage, _store) {
        var _this = _super.call(this, toastrService, toastPackage) || this;
        _this.toastrService = toastrService;
        _this.toastPackage = toastPackage;
        _this._store = _store;
        _this._subs = new Subscription();
        _this.toastText$ = _this._store.pipe(select(fromSettings.getSectionTranslations('MessageToast')));
        _this._subs.add(_this.toastText$.subscribe(function (t) { return (_this.toastText = t); }));
        return _this;
    }
    PortalToastComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    // wrapper function to perform specific functions in one go
    PortalToastComponent.prototype.alterToast = function (_a) {
        var _b = _a.class, classesToRemove = _b.classesToRemove, classToAdd = _b.classToAdd, title = _a.title, message = _a.message;
        if (title) {
            this.setTitle(title);
        }
        if (message) {
            this.setMessage(message);
        }
        if (classesToRemove || classToAdd) {
            this.changeClass(classesToRemove, classToAdd);
        }
    };
    PortalToastComponent.prototype.changeClass = function (classesToRemove, classToAdd) {
        var temp = this.toastClasses;
        // remove all strings provided in classToRemove
        for (var _i = 0, classesToRemove_1 = classesToRemove; _i < classesToRemove_1.length; _i++) {
            var _class = classesToRemove_1[_i];
            temp = temp.replace(_class, '');
        }
        // add the class to add.
        temp = temp + " " + classToAdd;
        this.toastClasses = temp;
    };
    PortalToastComponent.prototype.setTitle = function (title) {
        this.title = title;
    };
    PortalToastComponent.prototype.setMessage = function (message) {
        this.message = message;
    };
    PortalToastComponent.prototype.action = function (event) {
        event.stopPropagation();
        // trigger action back where service was created
        this.toastPackage.triggerAction();
        return false;
    };
    return PortalToastComponent;
}(Toast));
export { PortalToastComponent };
