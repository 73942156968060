import * as tslib_1 from "tslib";
import { SettingsActionTypes, } from '../actions/settings.actions';
import { translations } from '../data/settings';
export var initialState = {
    language: 'en-gb',
    languageSet: false,
    text: translations,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SettingsActionTypes.ChangeLanguage: {
            return tslib_1.__assign({}, state, { language: action.payload, languageSet: true });
        }
        default:
            return state;
    }
}
// Selectors
export var getCurrentLanguage = function (state) { return state.language; };
export var getLanguageSet = function (state) { return state.languageSet; };
