import { S3ActionsUnion, S3ActionTypes } from '../actions/s3.actions';
import { S3OptionsResponse } from '../models/responses/s3-options.response';

export interface S3State {
  config: S3OptionsResponse;
}

export const initialState: S3State = {
  config: null,
};

export function reducer(state = initialState, action: S3ActionsUnion): S3State {
  switch (action.type) {
    case S3ActionTypes.GetS3OptionsSuccess: {
      return {
        ...state,
        config: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
