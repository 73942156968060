/**
 * Enumerated, namespaced layout action types
 */
export var RealTimeMessageActionTypes;
(function (RealTimeMessageActionTypes) {
    RealTimeMessageActionTypes["Connect"] = "[MessagingAPI] Connect";
    RealTimeMessageActionTypes["Typing"] = "[MessagingAPI] Typing";
    RealTimeMessageActionTypes["Disconnect"] = "[MessagingAPI] Disconnect";
    RealTimeMessageActionTypes["ForceRefreshTyping"] = "[MessagingAPI] Force Refresh Typing";
})(RealTimeMessageActionTypes || (RealTimeMessageActionTypes = {}));
/**
 * Action Creators
 */
var Connect = /** @class */ (function () {
    function Connect() {
        this.type = RealTimeMessageActionTypes.Connect;
    }
    return Connect;
}());
export { Connect };
var Typing = /** @class */ (function () {
    function Typing(payload) {
        this.payload = payload;
        this.type = RealTimeMessageActionTypes.Typing;
    }
    return Typing;
}());
export { Typing };
var Disconnect = /** @class */ (function () {
    function Disconnect() {
        this.type = RealTimeMessageActionTypes.Disconnect;
    }
    return Disconnect;
}());
export { Disconnect };
var ForceRefreshTyping = /** @class */ (function () {
    function ForceRefreshTyping() {
        this.type = RealTimeMessageActionTypes.ForceRefreshTyping;
    }
    return ForceRefreshTyping;
}());
export { ForceRefreshTyping };
export var RealTimeActions = {
    Connect: Connect,
    Typing: Typing,
    Disconnect: Disconnect,
    ForceRefreshTyping: ForceRefreshTyping,
};
