import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
import { FormBuilder, Validators, } from '@angular/forms';
import { passwordComplexity } from 'src/app/core/utils/validators';
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent(_fb) {
        this._fb = _fb;
        this.forgottenPassword = new EventEmitter();
        this.submitted = new EventEmitter();
        this._setupForm();
    }
    Object.defineProperty(LoginFormComponent.prototype, "loggingIn", {
        set: function (isLoggingIn) {
            if (isLoggingIn) {
                this.loginForm.disable();
            }
            else {
                this.loginForm.enable();
            }
            this.isLoggingIn = isLoggingIn;
        },
        enumerable: true,
        configurable: true
    });
    LoginFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.isCore) {
            if (changes.isCore.currentValue !== changes.isCore.previousValue) {
                this._setupForm();
            }
        }
    };
    LoginFormComponent.prototype._setupForm = function () {
        this.loginForm = this._fb.group({
            username: [
                {
                    value: '',
                    disabled: false,
                },
                [Validators.email],
            ],
            password: [
                {
                    value: '',
                    disabled: false,
                },
                [Validators.required],
            ],
        }, {
            validator: [this._passwordOrCoreValidator()],
        });
    };
    LoginFormComponent.prototype._passwordOrCoreValidator = function () {
        var _this = this;
        return function (group) {
            // extract username
            var username = group.controls['username'];
            // password
            var password = group.controls['password'];
            if (username.value.includes('@') || _this.isCore) {
                return passwordComplexity(password);
            }
            return null;
        };
    };
    LoginFormComponent.prototype.submit = function () {
        this.formSubmitted = true;
        var auth = tslib_1.__assign({}, this.loginForm.value, { validationData: {
                clinictoken: this.clinicId,
            } });
        this.submitted.emit({
            isCore: this.isCore,
            usedEmail: true,
            auth: auth,
        });
    };
    return LoginFormComponent;
}());
export { LoginFormComponent };
