import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import { LoadActionMedicationsError, LoadNoActionMedicationsError, LoadPotentialDuplicatesError, } from '../actions/medications.actions';
var MedicationsService = /** @class */ (function () {
    function MedicationsService(_httpService, _store, _error) {
        this._httpService = _httpService;
        this._store = _store;
        this._error = _error;
        this.publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
    }
    MedicationsService.prototype.getActionRequiredMedications = function (searchTerm) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var params = _this._getParams(searchTerm);
            _this._httpService
                .get(EndpointType.Portal, 'medications/action-required', params)
                .subscribe(function (response) {
                resolve(response.data);
            }, function (error) {
                reject(error);
                _this._store.dispatch(new LoadActionMedicationsError(error));
            });
        });
    };
    MedicationsService.prototype.getNoActionRequiredMedications = function (searchTerm) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var params = _this._getParams(searchTerm);
            _this._httpService
                .get(EndpointType.Portal, 'medications/no-action-required', params)
                .subscribe(function (response) {
                resolve(response.data);
            }, function (error) {
                reject(error);
                _this._store.dispatch(new LoadNoActionMedicationsError(error));
            });
        });
    };
    MedicationsService.prototype.getPotentialDuplicateMedications = function (searchTerm) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var params = _this._getParams(searchTerm);
            _this._httpService
                .get(EndpointType.Portal, 'medications/potential-duplicates', params)
                .subscribe(function (response) {
                resolve(response.data);
            }, function (error) {
                reject(error);
                _this._store.dispatch(new LoadPotentialDuplicatesError(error));
            });
        });
    };
    MedicationsService.prototype._getParams = function (searchTerm) {
        if (searchTerm === undefined || searchTerm === '') {
            return {};
        }
        return {
            q: searchTerm,
        };
    };
    return MedicationsService;
}());
export { MedicationsService };
