import { Menu, MenuItem, Radio } from '@material-ui/core';
import { useCallbackDebounced } from '@react/lib/hooks/useCallbackDebounced';
import { useTranslations } from '@react/lib/i18n';
import React, { useCallback } from 'react';
import { ClickThroughMenu, MenuButton, MenuDeviceRowContainer, MoreVertIcon } from './VideoCallMenuButton.styled';
var MenuDeviceRow = function (_a) {
    var device = _a.device, changeDevice = _a.changeDevice, closeMenu = _a.closeMenu;
    var onDeviceClicked = useCallbackDebounced(function () {
        changeDevice(device.deviceId);
        closeMenu();
    }, [device, changeDevice, closeMenu]);
    return (React.createElement(MenuItem, { key: device.deviceId, onClick: onDeviceClicked },
        React.createElement(Radio, { checked: device.active, color: "primary" }),
        device.label));
};
var ɵ0 = MenuDeviceRow;
var anchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
};
export var VideoCallMenuButton = function (_a) {
    var microphoneList = _a.microphoneList, cameraList = _a.cameraList, speakerList = _a.speakerList, changeCamera = _a.changeCamera, changeMic = _a.changeMic, changeSpeaker = _a.changeSpeaker, invitePartnerInCall = _a.invitePartnerInCall, partnerInvited = _a.partnerInvited, partnerInviteEnabled = _a.partnerInviteEnabled, invitingPartner = _a.invitingPartner, disabled = _a.disabled;
    var t = useTranslations().t;
    var _b = React.useState(null), menuAnchorElement = _b[0], setMenuAnchorElement = _b[1];
    var _c = React.useState(null), menuAudioInputAnchorElement = _c[0], setAudioInputMenuAnchorElement = _c[1];
    var _d = React.useState(null), menuAudioOutputAnchorElement = _d[0], setAudioOutputMenuAnchorElement = _d[1];
    var _e = React.useState(null), menuVideoAnchorElement = _e[0], setVideoMenuAnchorElement = _e[1];
    var onAudioInputMenuHover = useCallback(function (event) {
        setAudioInputMenuAnchorElement(event.currentTarget);
        setAudioOutputMenuAnchorElement(null);
        setVideoMenuAnchorElement(null);
    }, []);
    var onAudioOutputMenuHover = useCallback(function (event) {
        setAudioOutputMenuAnchorElement(event.currentTarget);
        setAudioInputMenuAnchorElement(null);
        setVideoMenuAnchorElement(null);
    }, []);
    var onVideoMenuHover = useCallback(function (event) {
        setVideoMenuAnchorElement(event.currentTarget);
        setAudioOutputMenuAnchorElement(null);
        setAudioInputMenuAnchorElement(null);
    }, []);
    var handleCloseAudioInputMenu = useCallback(function () {
        setAudioInputMenuAnchorElement(null);
    }, []);
    var handleCloseAudioOutputMenu = useCallback(function () {
        setAudioOutputMenuAnchorElement(null);
    }, []);
    var handleCloseVideoMenu = useCallback(function () {
        setVideoMenuAnchorElement(null);
    }, []);
    var onMenuClick = useCallbackDebounced(function (event) {
        setMenuAnchorElement(event.currentTarget);
    }, []);
    var invitePartner = useCallbackDebounced(function () {
        invitePartnerInCall();
        handleCloseMenu();
    }, [invitePartnerInCall]);
    var handleCloseMenu = useCallback(function () {
        setMenuAnchorElement(null);
        setAudioInputMenuAnchorElement(null);
        setAudioOutputMenuAnchorElement(null);
        setVideoMenuAnchorElement(null);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuButton, { size: "medium", disabled: disabled, onClick: onMenuClick },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Menu, { id: "simple-menu", anchorEl: menuAnchorElement, keepMounted: true, open: Boolean(menuAnchorElement), onClose: handleCloseMenu, anchorOrigin: anchorOrigin },
            React.createElement(MenuItem, { onMouseEnter: onAudioInputMenuHover, onMouseLeave: handleCloseAudioInputMenu },
                t && t.VideoCallModal.AudioInputMenu,
                React.createElement(ClickThroughMenu, { anchorEl: menuAudioInputAnchorElement, open: Boolean(menuAudioInputAnchorElement), onClose: handleCloseMenu, anchorOrigin: anchorOrigin },
                    React.createElement(MenuDeviceRowContainer, null, microphoneList.map(function (microphone) {
                        return (React.createElement(MenuDeviceRow, { key: microphone.deviceId, device: microphone, closeMenu: handleCloseMenu, changeDevice: changeMic }));
                    })))),
            React.createElement(MenuItem, { onMouseEnter: onAudioOutputMenuHover, onMouseLeave: handleCloseAudioOutputMenu },
                t && t.VideoCallModal.AudioOutputMenu,
                React.createElement(ClickThroughMenu, { anchorEl: menuAudioOutputAnchorElement, open: Boolean(menuAudioOutputAnchorElement), onClose: handleCloseMenu, anchorOrigin: anchorOrigin },
                    React.createElement(MenuDeviceRowContainer, null, speakerList.map(function (speaker) {
                        return (React.createElement(MenuDeviceRow, { key: speaker.deviceId, device: speaker, closeMenu: handleCloseMenu, changeDevice: changeSpeaker }));
                    })))),
            React.createElement(MenuItem, { onMouseEnter: onVideoMenuHover, onMouseLeave: handleCloseVideoMenu },
                t && t.VideoCallModal.VideoMenu,
                React.createElement(ClickThroughMenu, { anchorEl: menuVideoAnchorElement, open: Boolean(menuVideoAnchorElement), onClose: handleCloseMenu, anchorOrigin: anchorOrigin },
                    React.createElement(MenuDeviceRowContainer, null, cameraList.map(function (camera) {
                        return (React.createElement(MenuDeviceRow, { key: camera.deviceId, device: camera, closeMenu: handleCloseMenu, changeDevice: changeCamera }));
                    })))),
            partnerInviteEnabled && !partnerInvited && (React.createElement(MenuItem, { onClick: invitePartner, disabled: invitingPartner }, t && t.VideoCallsInvitePartner.InviteButton)))));
};
export { ɵ0 };
