export const debounceAsync = <
  T,
  Callback extends (...args: any[]) => Promise<T> = (
    ...args: any[]
  ) => Promise<T>,
>(
  callback: Callback,
  wait: number,
): ((...args: Parameters<Callback>) => Promise<T>) => {
  // The `@types/node` conflicts with the browser type for this function
  // that we actually want.
  const browserSetTimeout = setTimeout as typeof window.setTimeout;
  let timeoutId: number | null = null;

  return (...args: Parameters<Callback>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<T>((resolveOuter) => {
      const timeoutPromise = new Promise<void>((resolveInner) => {
        timeoutId = browserSetTimeout(resolveInner, wait);
      });
      timeoutPromise.then(async () => {
        resolveOuter(await callback(...args));
      });
    });
  };
};
