import { Component, OnInit } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromAuth from '../../reducers';

import * as AuthActions from '../../actions/auth.actions';

import { NavigationService } from 'src/app/core/services/navigation.service';
import { LoginSubmission } from '../../models/LoginSubmission';
import { Authenticate, VerifyOTPParams } from '../../models/user';
import { CognitoError } from './../../models/LoginSubmission';

@Component({
  selector: 'portal-login-page',
  template: `
    <portal-login-form
      [isCore]="isCoreClinic$ | async"
      [text]="toastText$ | async"
      [loggingIn]="loggingIn$ | async"
      [error]="error$ | async"
      [clinicId]="clinicId$ | async"
      [cognitoUser]="cognitoUser$ | async"
      (forgottenPassword)="forgotPassword()"
      (submitted)="onSubmit($event)"
    >
    </portal-login-form>
  `,
  styles: [],
})
export class LoginPageContainer implements OnInit {
  public isCoreClinic$: Observable<boolean>;
  public cognitoUser$: Observable<CognitoUser | any>;
  public clinicId$: Observable<string>;
  public loggingIn$: Observable<boolean>;
  public error$: Observable<CognitoError>;
  public toastText$: Observable<any>;
  public loginText$: Observable<any>;

  private loginText: any;
  private _subs = new Subscription();

  constructor(
    private _store: Store<fromAuth.State>,
    private _storeRoot: Store<fromRoot.State>,
    private _navigationService: NavigationService,
  ) {
    this._store.dispatch(new AuthActions.ClearLoginError());

    this.toastText$ = this._storeRoot.pipe(
      select(fromSettings.getSectionTranslations('Authentication')),
    );
    this.loginText$ = this._storeRoot.pipe(
      select(fromSettings.getSectionTranslations('Login')),
    );
    this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
    this.cognitoUser$ = this._store.pipe(select(fromAuth.getCognitoUser));
    this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
    this.loggingIn$ = this._store.pipe(select(fromAuth.isLoggingIn));
    this.error$ = this._store.pipe(select(fromAuth.getLoginPageError));

    this._store.dispatch(new AuthActions.ClearCodeGenerated());
  }

  public ngOnInit() {
    this._subs.add(
      this.loginText$.subscribe((t) => {
        this.loginText = t;
      }),
    );
    this._store.dispatch(new AuthActions.EnableForm());
  }

  public onSubmit({ isCore, auth, usedEmail }: LoginSubmission) {
    this._coreLogin(auth);
  }

  public forgotPassword() {
    this._navigationService.navigate(['/forgot']);
  }

  private _coreLogin(auth: Authenticate) {
    this._store.dispatch(new AuthActions.CoreLogin(auth));
  }
}
