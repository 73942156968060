var MetaMessagePrefix = '_meta:';
var MetaMessageService = /** @class */ (function () {
    function MetaMessageService() {
    }
    MetaMessageService.prototype.createMessage = function (metaMessage) {
        return "" + MetaMessagePrefix + JSON.stringify(metaMessage);
    };
    MetaMessageService.prototype.parseMessage = function (message) {
        if (!this.isMetaMessage(message)) {
            return;
        }
        var rawMetaMessage = message.replace(MetaMessagePrefix, '');
        var metaMessage;
        try {
            var parsedMessage = JSON.parse(rawMetaMessage);
            if ('type' in parsedMessage) {
                metaMessage = parsedMessage;
            }
        }
        catch (e) {
            console.error("Error parsing meta message: " + message);
            return;
        }
        return metaMessage;
    };
    MetaMessageService.prototype.isMetaMessage = function (message) {
        return message.startsWith(MetaMessagePrefix);
    };
    return MetaMessageService;
}());
export { MetaMessageService };
