export const translationPt = {
  Languages: {
    English: 'English',
    German: 'Deutsch',
    Spanish: 'Español',
    Portuguese: 'Português'
  },
  // New Content Sections
  // =====================================
  ClinicStatus: {
    SalveConnection: 'Guardar ligação',
    Tooltip:
      // tslint:disable-next-line:max-line-length
      (version) =>
        `Sempre que for visível um ponto vermelho no ecrã a ligação entre o Salve e o sistema da clínica está temporariamente offline. Os pacientes podem não receber as atualizações demedicamentos / consultas na sua aplicação em tempo real. Esta situação será resolvida em breve. Se a situação persistir, envie um e-mail para hello@salveapp.co.uk. Versão do Salve: ${version}`
  },
  NewContentList: {
    ThisFolder: (folder) => `${folder} Pasta`,
    SearchContent: (folder) => `Pesquisar conteúdo de ${folder}...`,
    ThisPack: (pack) => `${pack} Pacote`,
    FilterPack: (pack) => `Filtrar o ${pack} Pacote de Conteúdo...`,
    AddItem: 'Adicionar item',
    Title: 'Título do conteúdo',
    Comments: 'Comentários',
    DateChanged: 'Data alterada',
    UpdatedBy: 'Última atualização por',
    NoContent: 'Nenhum conteúdo encontrado',
    Empty: 'Vazio',
    Migrated: 'Migrado',
    DeleteItems: 'Eliminar itens',
    Actions: 'Ações',
    MoveItems: 'Mover itens',
    LinkedToEMR: 'Conteúdo gerado a partir dos dados EMR',
    Translated: 'O conteúdo foi traduzido',
    TranslatedRow: (languages: string) =>
      `Traduções atuais: ${languages}. Clique neste conteúdo para adicionar traduções`,
    ViewLinks: 'Visualizar links',
    ItemsPerPage: 'Visualizar',
    FirstPage: 'Primeira página',
    PreviousPage: 'Página anterior',
    NextPage: 'Página seguinte',
    LastPage: 'Última página',
    ContentHasLink: 'O conteúdo tem um link para outro conteúdo',
    NoChildren: 'Sem resultados para o conteúdo "Filho"',
    LinkedAndSynced:
      'Sem ações disponíveis para itens com link e sincronização através do EMR',
    Link: 'Ligar itens',
    LinkContent: 'Ligar conteúdo',
    ContentReplaced:
      'O conteúdo da tabela "Filho" será substítuido com o conteúdo da tabela "Pai"',
    LinkingContent: 'Ligação de conteúdos em curso...',
    LinkedSuccess: 'Conteúdos ligados com sucesso!',
    LinkingError: 'Erro na ligação de conteúdos...',
    ContentLink: 'Conteúdo ligado',
    CtoPDesc: (parentName) =>
      `É exibido o conteúdo de ${parentName} em vez dos seguintes elementos:`,
    RemoveSelectedLinks: 'Remover os links selecionados',
    RemoveLink: 'Remover link',
    RemoveLinks: 'Remover links',
    RemoveConfirm: 'Tem a certeza de que pretende remover este link?',
    RemoveSomeLinks: 'Remover alguns links',
    RemoveAllLinks: 'Remover todos os links',
    RemoveSomeConfirm:
      'Tem a certeza de que pretende remover estes links deste conteúdo?',
    RemoveAllConfirm:
      'Tem a certeza de que pretende remover todos os links deste conteúdo?',
    RemovingLink: 'A remover link...',
    RemovingLinks: 'A remover links...',
    RemoveLinkSuccess: 'Link removido com sucesso!',
    RemoveLinksSuccess: 'Links removidos com sucesso!',
    RemoveLinkError: 'Erro ao remover link',
    RemoveLinksError: 'Erro ao remover links',
    To: 'Para',
    Parent: 'Pai',
    Child: 'Filho',
    FilterContent: 'Filtrar conteúdo...',
    MovedItemsSuccess: 'Entradas movidas com sucesso',
    MovingItems: 'A mover as entradas...',
    ErrorMovingItems: 'Erro encontrado ao mover itens',
    DeleteFolder: 'Eliminar pasta',
    MoveConfirmation: 'Tem a certeza de que pretende mover estes itens?',
    WhichFolder: 'Selecione a pasta para a qual pretende mover os itens:',
    ChooseAFolder: 'Selecione uma pasta',
    PleaseChoose: 'Por favor, escolha uma pasta',
    DeleteFolderConfirmation:
      'Tem a certeza de que pretende eliminar esta pasta?',
    DeleteItemsConfirmation:
      'Tem a certeza de que pretende eliminar estes itens?',
    DeleteItemsAdditionalInfo:
      'Se existirem traduções associadas a este conteúdo, estas também serão eliminadas.',
    DeleteItemBlock: (itemName) =>
      `Não pode eliminar "${itemName}" porque está ligado a outro conteúdo. Para o eliminar, tem de remover primeiro o link.`,
    Delete: 'Eliminar',
    Move: 'Mover',
    Cancel: 'Cancelar',
    OK: 'Ok',
    Save: 'Guardar',
    FolderName: 'Nome da pasta',
    ChangeFolderName: 'Alterar o nome da pasta',
    FolderRequired: 'Campo de preenchimento obrigatório',
    FolderTranslationRequired:
      'As traduções existentes não podem ser deixadas em branco',
    FolderExists: 'Já existe uma pasta com o mesmo nome',
    FolderLength: 'O nome deve ter entre 3 a 50 caracteres',
    ChangingFolder: 'A alterar o nome...',
    ChangingFolderSuccess: 'Alterado com sucesso!',
    ChangingFolderError: 'Erro ao alterar o nome!',
    // Multilingual
    Translations: 'Traduções',
    PressMe: 'Prima este',
    IconFolder: 'botão para criar a sua primeira pasta de conteúdos',
    // New Translations
    PackName: 'Nome do Pacote',
    WhatPackName: 'Introduza um nome exclusivo:',
    PackNameRequired: 'Campo de preenchimento obrigatório',
    PackExists: 'Já existe um Pacote com o mesmo nome',
    PackNameLength: 'O nome do Pacote deve ter entre 3 a 50 caracteres',
    ContentCategory: 'Tipo de conteúdo',
    IsFileOnly: 'Apenas ficheiros',
    DeletePack: 'Eliminar Pacote de conteúdo',
    DeletePackConfirmation:
      'Tem a certeza de que pretende eliminar este Pacote?',
    RemovePackItems: 'Remover do Pacote',
    RemovePackItemsConfirmation:
      'Tem a certeza de que pretende remover estes itens deste Pacote?',
    Remove: 'Remover',
    AddToPack: 'Adicionar ao Pacote',
    AddToPackDescription:
      'Os elementos seguintes serão adicionados aos Pacotes selecionados:',
    AddingToPack: 'A adicionar conteúdo ao Pacote...',
    AddedSuccessfully: 'Conteúdo adicionado com sucesso',
    AddingToPackError: 'Erro ao adicionar conteúdo!',
    SelectAPack: 'Selecionar Pacotes de conteúdo',
    PleaseChoosePack: 'Escolha um Pacote...',
    SendToAll: 'Este Pacote é enviado automaticamente a todos os pacientes',
    SendTo: 'Enviar para',
    AllPatients: 'Todos'
  },
  NoContent: {
    PressAdd: 'Prima o botão Adicionar conteúdo para iniciar',
    AddContent: 'Adicionar conteúdo',
    Appear: 'Sem conteúdo para mostrar'
  },
  ContentDirectory: {
    Add: 'Adicionar',
    CreateNewFolder: 'Criar nova pasta',
    CreateNewTemplate: 'Criar novo modelo',
    CreateNew: 'Criar',
    Categories: 'Pastas',
    Templates: 'Modelos',
    NoFolders: 'Criar a sua primeira pasta',
    NoTemplates: 'Criar o seu primeiro modelo',
    // New
    CreateNewPack: 'Criar novo Pacote',
    Packs: 'Pacotes',
    NoPacks: 'Crie o seu primeiro Pacote'
  },
  CreateCategoryOrTemplate: {
    What: 'O que quer criar?',
    CreateCategory: 'Criar pasta',
    CreateTemplate: 'Criar modelo',
    Organise: 'Organize o seu conteúdo em pastas.',
    MakeItEasy:
      'Torne mais fácil a pesquisa, criação e distribuição dos conteúdos da sua clínica.',
    Uniformity: 'Os modelos permitem-lhe criar consistência no seu conteúdo.',
    UsefulFor: 'Especialmente útil para medicamentos e consultas!',
    CategoryButton: 'Criar pasta de conteúdo',
    TemplateButton: 'Criar modelo de conteúdo'
  },
  CreateCategory: {
    CreateCategory: 'Introduza um nome exclusivo:',
    AddTitle: 'Adicione o nome da sua pasta aqui...',
    CurrentCategories: 'Pastas atuais',
    FolderRequired: 'Campo de preenchimento obrigatório',
    FolderExists: 'Já existe uma pasta com o mesmo nome',
    FolderLength: 'O nome deve ter entre 3 a 50 caracteres',
    CreatingFolder: 'A criar pasta...',
    CreatedFolder: 'Pasta criada!',
    ErrorCreatingFolder: 'Erro ao criar pasta',
    Cancel: 'Cancelar',
    Create: 'Criar',
    // New Words
    CreatePack: 'Introduza um novo nome exclusivo:',
    AddPackTitle: 'Adicione o nome do seu Pacote aqui...',
    CurrentPacks: 'Pacotes atuais',
    PackRequired: 'Campo de preenchimento obrigatório',
    PackExists: 'Já existe um Pacote com o mesmo nome',
    PackLength: 'O nome do Pacote deve ter entre 3 a 50 caracteres',
    CreatingPack: 'A criar Pacote...',
    CreatedPack: 'Pacote criado!',
    ErrorCreatingPack: 'Erro ao criar Pacote'
  },
  CreateTemplate: {
    NewTitle: 'Novo modelo de conteúdo',
    Title: 'Modelo de conteúdo',
    TemplateTitle: 'Título do modelo de conteúdo...',
    Required: 'Obrigatório',
    AddNew: 'Adicionar novo',
    Save: 'Guardar',
    AddSectionTitle: 'Adicione o título da secção',
    CancelConfirmation: 'Fechar o modelo sem guardar as alterações?',
    DeleteConfirmation: 'Tem a certeza de que pretende eliminar este modelo?',
    Delete: 'Eliminar',
    Confirm: 'Confirmar',
    Cancel: 'Cancelar',
    Saving: 'A guardar o modelo...',
    SavedSuccessfully: 'Guardado com sucesso',
    SavedError: 'Encontrado erro'
  },
  CreateContentItem: {
    AddRich:
      'Adicione conteúdo rico, como imagens, vídeos e texto, com a ajuda do nosso criador de conteúdos fácil de utilizar.',
    UploadPdf:
      'Carregue um documento em formato PDF ou Word para que o doente o possa ler facilmente no seu dispositivo.',
    CreateRich: 'Criar conteúdo rico',
    UsePdf: 'Usar um documento',
    Recommended: 'recomendado'
  },
  ContentCreatorTitle: {
    ManageLinks: 'Gerir conteúdo com ligado',
    HasChildren: 'Está ligado a outro conteúdo',
    ParentExists: 'Consulte o conteúdo para',
    Title: 'Título',
    AddTitle: 'Adicione o seu título aqui...',
    PatientTitle: 'Título do paciente',
    AddPatientTitle: 'Adicionar o título que o doente deve ver...',
    TransferTitle: 'Utilize um nome do EMR',
    Subtitle: 'Subtítulo',
    AddSubtitle: 'Adicione o seu subtítulo aqui...',
    UseTemplate: 'Usar modelo',
    ChooseATemplate: 'Escolha um modelo',
    TitleLengthError:
      'O título deve ter entre <strong>3</strong> e <strong>50</strong> caracteres',
    TitleRequired: 'Título <strong>obrigatório</strong>',
    TitleUnique:
      'O título deve ser <strong>exclusivo</strong> este título já existe.',
    SubtitleLessThan: 'O subtítulo deve ter menos de',
    TitleMust: 'O título deve ter',
    TitleIs: 'O título é',
    Between: 'entre',
    LessThan: 'inferior a',
    TitleExists: 'este título já existe',
    Unique: 'exclusivo',
    Required: 'obrigatório',
    And: 'e',
    Characters: 'caracteres',
    TitleChange:
      'Os nomes dos itens sincronizados devem ser alterados no Meditex',
    TitlePattern:
      'O título deve conter apenas letras, números, espaços e traços'
  },
  ContentCreatorBody: {
    Introduction: 'Introdução',
    AddIntroduction: 'Adicione a introdução do seu conteúdo...',
    Required: 'Obrigatório',
    TextOnly: 'Apenas formato de texto.',
    CharsRemaining: 'Caracteres restantes.',
    Video: 'Vídeo',
    AddVideo: 'Adicionar vídeo',
    RemoveVideo: 'Remover vídeo',
    MinLength: 'A introdução deve conter conteúdo.'
  },
  ContentCreatorControls: {
    Back: 'Voltar',
    Cancel: 'Cancelar',
    Publish: 'Publicar',
    Delete: 'Eliminar',
    SendToAll: 'Enviar a todos',
    AddTranslation: 'Adicionar tradução',
    Confirm: 'Confirmar',
    Leave: 'Sair',
    PublishDialogInformation:
      'Quaisquer alterações noutras línguas devem ser publicadas separadamente.',
    PublishDialogMessage: 'Publicar as suas alterações?',
    PublishDialogWithLanguageMessage: (languageName: string) =>
      `Publicar as suas alterações em ${languageName}?`,
    SaveConfirm: 'Tem a certeza de que pretende guardar as alterações?',
    ResetConfirm: 'Repor o conteúdo sem guardar as alterações?',
    DeleteConfirmInformation:
      'Esta ação é irreversível. Nenhuma outra tradução será afetada.',
    DeleteConfirmMessage: 'Tem a certeza que pretende eliminar esta tradução?',
    DeleteDefaultConfirmInformation:
      'Todo o conteúdo e quaisquer traduções associadas serão eliminados. A ação não pode ser anulada.',
    DeleteDefaultConfirmMessage:
      'Tem a certeza que pretende eliminar este conteúdo?',
    DeleteErrorToastTitle: 'Erro ao eliminar conteúdo',
    DeleteErrorToastDescription:
      'O conteúdo não foi eliminado. Por favor, tente novamente.',
    ExitConfirm:
      'Tem a certeza de que pretende sair sem guardar as alterações?',
    OneAttachment: 'Apenas um anexo por conteúdo.',
    TitleRequired: 'Introduza um título válido para adicionar um ficheiro',
    UnpublishedChanges: 'Alterações não publicadas',
    ErrorToastDescription:
      'O conteúdo não foi publicado. Por favor, tente novamente.',
    ErrorToastTitle: 'Erro ao publicar conteúdo',
    SuccessToastDescription: 'O conteúdo foi publicado com sucesso',
    SuccessToastTitle: 'Conteúdo publicado',
    PublishingContent: 'A publicar conteúdo...'
  },
  AddVideoControls: {
    AddVideo: 'Adicionar vídeo',
    YoutubeLink: 'Link do Youtube',
    WebsiteLink: 'Link para o website',
    Upload: 'Carregar vídeo',
    AddLink: 'Adicione o seu link aqui...',
    SubmitLink: 'Enviar link'
  },
  ContentCreatorSections: {
    ConfirmDelete: 'Tem a certeza de que pretende eliminar esta secção?',
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    AddSectionTitle: 'Adicione o título da secção...',
    InsertText: 'Inserir texto aqui...',
    AddSection: 'Adicionar secção',
    MaxTitleLength: 'O título deve ter no máximo 50 caracteres',
    SectionTitle: 'Título da secção',
    CharactersRemaining: 'caracteres restantes'
  },
  ContentCreatorComments: {
    Comments: 'Comentários',
    OnlyVisible: 'Apenas visível internamente'
  },
  ContentCreatorVersionHistory: {
    VersionHistory: 'Histórico de versões',
    ComingSoon: '...Em breve...'
  },
  AddPdf: {
    Title: 'Título',
    AddTitle: 'Adicione o seu título aqui...',
    AddFile: 'Adicionar ficheiro',
    Current: 'Anexo atual',
    Id: 'Id',
    Name: 'Nome do anexo',
    FileType: 'Tipo de ficheiro',
    FileSize: 'Tamanho do ficheiro (Bytes)',
    Preview: 'Pré-visualizar',
    CheckFile: 'Verificar ficheiro',
    Remove: 'Remover',
    Cancel: 'Cancelar',
    RemoveConfirm: 'Tem a certeza que pretende remover este anexo?'
  },
  UseATemplate: {
    UseATemplate: 'Utilizar um modelo',
    ChooseATemplate: 'Escolher um modelo',
    SelectATemplate: 'Selecione um modelo',
    Append: 'Adicionar secções de modelo à parte inferior',
    Replace: 'Substituir secções pelo novo modelo',
    WillDelete: 'A substituição eliminará todas as secções atuais!',
    Warning: 'Aviso',
    Or: 'OU'
  },
  UseAContent: {
    Actions: 'Ações',
    DialogTitle: 'Copiar de conteúdos existentes',
    DialogGlobalTitle: 'Copiar conteúdo do portal geral',
    DialogGlobalDescription:
      'Ao copiar conteúdo para um artigo de conteúdo do tipo documento, apenas podem ser utilizados outros artigos de conteúdo do tipo documento. Não é possível pesquisar conteúdos avançados na lista disponível.',
    DialogDescription:
      'Apenas os conteúdos avançados podem ser copiados para outros artigos de conteúdo. Não é possível pesquisar conteúdos de tipo documento na lista disponível.',
    ChooseAContent: 'Escolher um artigo de conteúdo',
    ChooseAGlobalContent: 'Selecionar um artigo de conteúdo geral',
    SelectAContent: 'Selecione um artigo de conteúdo',
    SelectAGlobalContent: 'Selecionar um artigo de conteúdo geral',
    Copy: 'Copiar',
    Cancel: 'Cancelar',
    Warning: 'Aviso',
    WarningText:
      'A cópia do conteúdo selecionado substituirá quaisquer títulos, introduções e vídeos de pacientes existentes. Serão adicionadas secções adicionais sob as secções existentes.',
    WarningGlobalText:
      'Tudo o atualmente introduzido neste artigo de conteúdo será substituído pelo que está a ser copiado, incluindo qualquer título existente/título do paciente, subtítulo, introdução, vídeo e secções. Esta ação não pode ser anulada.',
    WarningGlobalFileText:
      'Tudo o atualmente introduzido neste artigo de conteúdo será substituído pelo que está a ser copiado, incluindo qualquer título existente/título do paciente, subtítulo, introdução, vídeo e secções. Esta ação não pode ser anulada.',
    CopyGlobalContentErrorTitle:
      'Não é possível copiar conteúdos do portal geral',
    CopyGlobalContentErrorDescription:
      'Verifique a sua ligação e tente novamente',
    CopyGlobalContentTranslationAdditionalTitle:
      'Não foi possível copiar uma ou mais traduções',
    CopyGlobalContentTranslationAdditionalDescription: (
      listOfLanguages: string
    ) =>
      `Não foi possível copiar uma ou mais traduções do portal geral devido a estarem numa língua não suportada por este portal. Traduções excluídas: ${listOfLanguages}`,
    CopyGlobalContentTranslationMissingTitle:
      'Artigo de conteúdo geral copiado sem uma ou mais traduções',
    CopyGlobalContentTranslationMissingDescription: (listOfLanguages: string) =>
      `O artigo do conteúdo geral que copiou não contém a(s) seguinte(s) tradução(ões) existente(s): ${listOfLanguages}. Verifique novamente as suas traduções antes de as publicar.`
  },
  ContentSwing: {
    NoContentAvailable: 'Não tem conteúdos',
    ToCreate: 'Para criar conteúdos, prima o botão abaixo:',
    CreateContent: 'Criar conteúdos',
    UploadFile: 'Carregar ficheiro do paciente',
    SearchContent: 'Pesquisar conteúdos...',
    SearchFiles: 'Pesquisar ficheiros...',
    ContentType: 'Tipo de conteúdo',
    Folders: 'Pastas',
    Packs: 'Pacotes',
    ViewAll: 'Todos',
    AvailableContent: (folderName) =>
      `Conteúdo de ${folderName ? folderName + '.' : ''}`,
    SentDate: (time: string, date: string) => `Enviado às ${time} em ${date}`,
    ReadDate: (time: string, date: string) => `Lido às ${time} em ${date}`,
    PatientContent: 'Conteúdo para ',
    ReadyDocuments: 'Pronto para enviar',
    PatientDocuments: (patientName: string) =>
      `Documentos enviados a ${patientName}`,
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    DeleteConfirm:
      'Tem a certeza de que pretende eliminar este ficheiro dos pacientes?',
    SearchAssigned: 'Pesquisar conteúdo atribuído...',
    Items: 'Itens individuais',
    AssignPackContent: 'Atribuir Pacote de conteúdo',
    LockItem: 'Impedir que este conteúdo seja adicionado ao paciente',
    AddSelectedItems: 'Adicionar itens do Pacote ao paciente',
    AssignIndividualItem: 'Adicionar este item ao paciente',
    SeePatientView: 'Visualizar a lista de conteúdos da perspetiva do paciente',
    SeeClinicView: 'Visualizar a lista de conteúdos da perspetiva da clínica',
    ViewInfo: 'Clique para saber mais sobre a atribuição de conteúdos',
    PackEmpty: 'Pacote sem conteúdo',
    RemovePack: 'Remover pacote do paciente',
    RemovePackItem: 'Remover este item do Pacote do paciente',
    ViewPackContents: 'Visualizar atribuições do Pacote',
    AllItemsExcluded: 'Todos os itens estão excluídos',
    NoAssignedPacks: 'O paciente não tem Pacotes atribuídos',
    ViewAssignedPacks: 'Visualizar os Pacotes atribuídos ao paciente',
    ViewIndividualItems: 'Visualizar itens atribuídos individualmente',
    AllItemsAssigned: 'Todos os itens foram atribuídos',
    SendToAllIsAutomatic:
      'Os itens Enviar a todos são automaticamente atribuídos a todos os pacientes',
    NoContentToAssign: 'Todos os conteúdos disponíveis foram atribuídos'
  },
  AddFile: {
    DocumentTitle: 'Título do documento',
    NameRequired: 'Campo de preenchimento obrigatório',
    NameLength: 'O nome do documento deve ter entre 3 e 50 caracteres',
    NameUnique: 'O nome do documento deve ser exclusivo',
    FixUnique: 'Necessário nome exclusivo antes de carregar o ficheiro',
    NamePattern:
      'O nome do documento deve conter apenas letras, números, espaços e sobtraços',
    FirstInstruction:
      'Adicione um título compreensível para o paciente que será exibido na app',
    AddDocTitle: 'Adicione aqui o título do documento...',
    SecondInstruction: 'Anexe o seu documento:',
    AttachVideo: 'Anexe o seu ficheiro de vídeo:',
    FileRequired: 'Campo de preenchimento obrigatório',
    ChooseFile: 'Escolher ficheiro',
    UploadFile: 'Carregar ficheiro',
    Uploading: 'A carregar...',
    Uploaded: 'Carregado!',
    Error: 'Erro',
    UploadSuccessful: 'Carregamento bem-sucedido!',
    ErrorUploading: 'Erro ao carregar ficheiro!',
    ErrorImage: 'Erro ao carregar imagem',
    ChooseImage: 'Selecione uma imagem',
    Cancel: 'Cancelar',
    Submit: 'Enviar'
  },
  Login: {
    ErrorLoggingIn: 'Erro ao iniciar sessão',
    Username: 'Nome de utilizador',
    Email: 'E-mail',
    ForgottenPassword: 'Esqueceu-se da palavra-passe? Clique aqui',
    HelloUser: (user) => `Olá ${user}`,
    Login: 'Iniciar sessão',
    Password: 'Palavra-passe',
    PasswordMissing: 'Por favor, introduza uma palavra-passe',
    UsernameMissing: 'Por favor, introduza um nome de utilizador',
    LoginSuccess: 'Início de sessão bem-sucedido'
  },
  Toolbar: {
    // Also used for sidebar navigation
    PageTitle: (heading) =>
      ({
        Home: 'Painel de controlo',
        Dashboard: 'Painel de controlo',
        Messages: 'Mensagens',
        Content: 'Conteúdo',
        Medications: 'Medicação',
        Patients: 'Doentes',
        Payments: 'Pagamentos',
        Settings: 'Definições',
        VideoCalls: 'Videochamadas',
        Admin: 'Admin',
        SuperAdmin: 'Super Admin'
      }[heading]),
    PleaseLogIn: 'Inicie sessão para continuar',
    Dashboard: 'Painel de controlo',
    SearchPatients: 'Pesquisar pacientes...',
    SignOut: 'Sair',
    ProfilePhoto: 'Fotografia do perfil'
  },
  Dashboard: {
    UnresolvedMessages: 'Mensagens não resolvidas',
    Unresolved: (folder: string) => ({
      Unresolved: `${folder}`,
      Tooltip: `Ver mensagens de ${folder}`
    })
  },
  UpcomingDrugs: {
    UpcomingDrugs: 'Medicamentos futuros',
    NoDrugs: 'Sem medicamentos futuros',
    Name: 'Nome',
    Type: 'Tipo de medicamento',
    Drug: 'Fármaco',
    Dosage: 'Posologia',
    Status: 'Estado',
    DueAt: 'Tomar às'
  },
  NonTimedTriggerDrugs: {
    UpcomingDrugs: 'Medicamentos sem horário específico',
    NoDrugs: 'Sem medicamentos futuros',
    Name: 'Nome',
    Type: 'Tipo de medicamento',
    Drug: 'Fármaco',
    Dosage: 'Posologia',
    Status: 'Estado',
    DueAt: 'Tomar às',
    DueDate: 'Data de vencimento'
  },
  Messages: {
    NewMessage: 'Mensagem nova',
    NewMessages: 'Mostrar mensagens novas',
    AllUnresolved: 'Todas as não resolvidas',
    AllResolved: 'Todas as resolvidas',
    AllMessages: 'Todas as mensagens',
    SearchMessages: 'Pesquisar mensagens',
    SearchingFor: 'Pesquisar por',
    Search: 'Pesquisar',
    Clear: 'Apagar',
    Actions: 'Ações',
    Unread: 'Não lida',
    SelectAMessage: 'Selecione uma mensagem',
    MarkUnread: 'Marcar como não lida',
    MarkUnresolved: 'Marcar como não resolvida',
    MarkResolved: 'Marcar como resolvida',
    Move: 'Mover',
    ChangeLabelTo: 'Mover para...',
    WriteAMessage: 'Escrever uma mensagem',
    Enlarge: 'Aumentar',
    Minimise: 'Minimizar',
    Send: 'Enviar',
    SeenBy: 'Vista por',
    On: 'em',
    At: 'em',
    New: 'Nova',
    Open: 'Abrir',
    OpenMessageAttachmentFileErrorMessage:
      'Verifique a sua ligação e tente novamente',
    OpenMessageAttachmentFileErrorTitle: 'Não foi possível abrir o ficheiro',
    SingularTyping: 'está a escrever...',
    PluralTyping: 'estão a escrever...',
    SingularTyped: 'introduziu texto...',
    PluralTyped: 'introduziram texto...',
    Dismiss: 'FECHAR',
    CopyMessageHistory: 'Copiar histórico de mensagens',
    HistoryCopied: (patientName: string) =>
      `O histórico das mensagens com ${patientName} foi copiado com sucesso para a área de transferência`,
    MarkedResolved: 'Esta conversa foi marcada como resolvida',
    ReadReceipt: (patientName: string, readDate: string) =>
      ` [Recibo de leitura: Vista por ${patientName} em ${readDate}]`,
    ReadBy: (name: string, time: string, date: string) =>
      `Vista por ${name} às ${time} em ${date}`,
    SentFile: (fileName: string) => `Enviou um ficheiro - ${fileName}`,
    Seen: 'Visto',
    CopyError:
      // tslint:disable-next-line:max-line-length
      'Não é possível copiar o histórico de mensagens para a área de transferência. Verifique a sua ligação, atualize o seu browser e tente novamente. Se o problema persistir, contacte-nos através do e-mail hello@salveapp.co.uk',
    EndOfConversation: 'Fim das conversas',
    NoConversations: 'Esta pasta não tem conversas marcadas sem estrela',
    NoStarredConversations: 'Esta pasta não tem conversas marcadas com estrela',
    PleaseRefineSearch:
      'Número máximo de mensagens devolvidas. Por favor, refine os seus critérios de pesquisa.',
    PleaseUseSearch:
      'Número máximo de mensagens devolvidas. Por favor, utilize a caixa de pesquisa..',
    Subject: 'Assunto:',
    ContentMaxLengthError: 'A mensagem não pode exceder 2000 caracteres',
    DeleteMessageItem: 'Eliminar mensagem',
    DeleteMessageConfirmationTitle: 'Eliminar mensagem?',
    DeleteMessageConfirmationBody:
      'Tem a certeza de que pretende eliminar esta mensagem?',
    DeleteMessageConfirmationBody2:
      'O paciente ainda poderá ver o conteúdo das mensagens excluídas nas notificações do dispositivo.',
    DeleteMessageButtonDelete: 'Eliminar',
    DeleteMessageButtonCancel: 'Cancelar',
    DeletedMessageText: (name: string, date: string, time: string) =>
      `Esta mensagem foi eliminada da aplicação dos pacientes por ${name} em ${date} a ${time}.`,
    NoMessageTemplates:
      'Sem modelos para apresentar, adicione-os no painel admin',
    NoMatchingMessageTemplates:
      'Não foram encontrados modelos de mensagem correspondentes',
    InsertMessageTemplate: 'Inserir modelo de mensagem',
    StarredTab: 'Com estrela',
    EverythingElseTab: 'Todas as restantes',
    NoSearchResults: 'Sem resultados para a pesquisa ou filtro',
    FoldersChipLabel: 'Pastas',
    TeamMembersFilterLabel: 'Equipa do paciente',
    FolderFilterLabel: 'Pasta',
    ResolvedFilterLabel: 'Resolvida',
    UnresolvedFilterLabel: 'Pendente',
    StatusFilterLabel: 'Estado',
    FolderFilterSearchPlaceholder: 'Pesquisar pastas',
    TeamMembersFilterSearchPlaceholder: 'Procurar membros da equipa',
    FilterButtonAriaLabel: 'Filtros',
    FilterMenuAriaLabel: 'Menu filtro',
    FilterMenuApplyButton: 'Aplicar',
    FilterLoadingErrorTitle: 'Ocorreu um erro',
    FilterLoadingErrorMessage: 'Erro ao pesquisar os filtros disponíveis',
    NoConversationSelected: 'Nenhuma conversa selecionada',
    PatientsTeamMember: 'Membro da equipa do paciente',
    SearchUsers: 'Pesquisar utilizadores',
    Me: 'Eu',
    Assign: 'Atribuir',
    Unassign: 'Remover atribuição',
    SearchAssignees: 'Pesquisar responsável',
    Assignees: 'Responsável',
    Unassigned: 'Não atribuído',
    Mine: 'Meu'
  },
  NewVideoCall: {
    AddDescription: 'Introduza uma descrição, por exemplo, Primeira consulta',
    AddName: 'Adicione o nome do funcionário que está a receber a chamada',
    Cancel: 'Cancelar',
    CloseCheck: 'Fechar sem efetuar uma chamada?',
    Confirm: 'Confirmar',
    ConfirmDelete: 'Tem a certeza de que pretende eliminar esta videochamada?',
    Create: 'Criar',
    Date: 'Data',
    DateRequired: 'Deve introduzir uma data válida',
    Delete: 'Eliminar',
    DeleteCall: 'Eliminar chamada',
    Description: 'Descrição',
    DescriptionLength: 'A descrição deve ter entre 3 e 100 caracteres',
    DescriptionRequired: 'Campo de preenchimento obrigatório',
    Duration: 'Duração (mins)',
    DurationRequired: 'Campo de preenchimento obrigatório',
    Host: 'Colaborador',
    HostLength: 'O nome do colaborador deve ter entre 3 e 50 caracteres',
    HostRequired: 'Campo de preenchimento obrigatório',
    LocalTime: 'Introduza a hora local da sua clínica',
    PastDate: 'A data não pode ser anterior à data atual',
    PastTime: 'A hora não pode ser anterior à hora atual',
    Patient: 'Paciente',
    PatientRequired: 'Campo de preenchimento obrigatório',
    PleaseSelect: 'Introduza o ID ou nome do paciente a pesquisar',
    Save: 'Guardar',
    ServerError: 'Ocorreu um erro no servidor - Tente novamente mais tarde',
    SetDate: 'Marque uma data',
    StartTime: 'Hora de início',
    StartTimeRequired: 'Campo de preenchimento obrigatório',
    TitleEdit: 'Editar videochamada',
    TitleNew: 'Nova videochamada'
  },
  NewMessage: {
    To: 'Para',
    Patient: 'Paciente',
    PleaseSelect: 'Selecione um paciente válido',
    Type: 'Tipo',
    ChooseMessageType: 'Escolha o tipo de mensagem...',
    PatientRequired: 'Campo de preenchimento obrigatório',
    ServerError: 'Ocorreu um erro no servidor - Tente novamente mais tarde',
    MessageLabelRequired: 'Campo de preenchimento obrigatório',
    WriteYourMessage: 'Escreva a sua mensagem',
    PleaseEnterMessage: 'Por favor, introduza uma mensagem',
    Send: 'Enviar',
    DiscardTitle: 'Fechar sem enviar?',
    DiscardDescription: 'Todos os dados introduzidos serão perdidos.',
    Cancel: 'Continuar a escrever',
    Confirm: 'Fechar',
    Subject: 'Assunto',
    SubjectPlaceholder: 'Escreva o assunto (opcional)',
    SubjectMaxLengthError: 'O assunto não pode exceder 256 caracteres',
    ContentMaxLengthError: 'A mensagem não pode exceder 2000 caracteres',
    Title: 'Nova conversa',
    FolderFieldLabel: 'Pasta de mensagens',
    FolderFieldPlaceholder: 'Escolha a pasta de mensagens',
    SubjectFieldLabel: 'Assunto (opcional)',
    SubjectFieldPlaceholder: 'Escreva o assunto',
    ContentFieldLabel: 'Mensagem'
  },
  Medication: {
    PotentialDupe: 'Medicação possivelmente duplicada',
    ActionRequired: 'Ação necessária',
    Name: 'Nome',
    Type: 'Tipo',
    Drug: 'Medicamento',
    Dosage: 'Posologia',
    Status: 'Estado',
    Overdue: (time) => `Em atraso - programada para ${time}`,
    NoActionRequired: 'Não é necessária nenhuma ação',
    DueAt: (time) => `Tomar às ${time}`,
    DueToday: 'Tomar hoje',
    WindowClosing: (time) => `${time} minutos para fechar a janela`,
    TakenAt: (takenTime, dueTime) =>
      `Tomada às ${takenTime} (prevista ${dueTime})`,
    PotentialDuplicate: (dueDate, scheduledTime) =>
      `Possível duplicação: ${dueDate} ${scheduledTime}`,
    NoAction: 'Nenhum medicamento requer ação',
    NoMedications: 'Não há medicamentos para visualizar',
    NoPotentialDuplicates: 'Sem medicamentos possivelmente duplicados',
    NoSearchResults: 'Nenhum medicamento corresponde à pesquisa',
    Search: 'Pesquisar',
    Id: 'ID',
    DOB: 'Data de nascimento',
    ErrorTitle: 'Não é possível carregar os dados',
    ErrorMessage: 'Verifique a sua ligação e tente novamente'
  },
  ContentList: {
    All: 'Todos',
    Medications: 'Medicamentos',
    Medication: 'Medicação',
    Appointments: 'Consultas',
    Appointment: 'Consulta',
    SearchContent: 'Pesquisar conteúdo...',
    Type: 'Tipo',
    Name: 'Nome',
    LastModifiedByHeader: 'Última modificação por - (Utilizador) - Data',
    LastModifiedByRow: (user, date) => `(${user}) ${date}`
  },
  PatientList: {
    SearchPatients: 'Pesquisar nome do paciente ID DN (DDMMAAAA)',
    PatientId: 'ID do paciente',
    FirstName: 'Nome',
    LastName: 'Apelido',
    SyncDate: 'Data de registo',
    ClearFilter: 'Limpar filtro',
    InviteCode: 'Código de convite: ',
    DOB: 'Data de nascimento'
  },
  PatientInfo: {
    NewMessage: 'Nova mensagem',
    PatientInfo: 'Informação do paciente',
    Partner: 'Parceiro',
    Contact: 'Contacto',
    LabResults: 'Resultados do laboratório',
    ResultRange: 'Intervalo:',
    ResultReceived: 'Resultado recebido:',
    ResultInterpretation: 'Interpretação do resultado:',
    NoLabResults: 'Não há resultados de laboratório disponíveis',
    ManageContent: 'Gerir conteúdos',
    PatientDocuments: 'Documentos do paciente',
    PatientId: 'ID do paciente',
    SyncDate: 'Data de registo',
    LastLogin: 'Último início de sessão',
    FirstName: 'Nome',
    LastName: 'Apelido',
    DOB: 'Data de nascimento',
    Age: 'Idade',
    NumberOfCycles: 'Número de ciclos',
    NoPartner: 'Nenhum parceiro selecionado',
    Email: 'E-mail',
    WorkPhoneLandline: 'Telefone do trabalho (fixo)',
    WorkPhoneMobile: 'Telefone do trabalho (móvel)',
    PersonalPhoneLandline: 'Telefone pessoal (fixo)',
    PersonalPhoneMobile: 'Telefone pessoal (móvel)',
    ComingSoon: '...Em breve...',
    NewVideoCall: 'Nova videochamada',
    Minutes: 'minutos',
    ClinicPhysician: 'Médico',
    Counselor: 'Terapeuta',
    Nurse: 'Enfermeiro(a)',
    Accountant: 'Contabilista',
    TreatingDr: 'Médico assistente'
  },
  PatientCalendar: {
    Calendar: 'Calendário',
    Key: 'Chave',
    Hidden: 'Oculto',
    Medication: 'Medicação',
    Appointment: 'Consulta',
    VideoCall: 'Videochamada',
    Provisional: 'Provisório',
    Back: 'Voltar',
    Today: 'Hoje',
    Next: 'Seguinte',
    Days: () => [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado'
    ],
    Months: () => [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    EventLabelAppointment: (dateTime: string, appointmentTitle: string) =>
      `${dateTime} - ${appointmentTitle}`,
    EventLabelAppointmentProvisional: (
      dateTime: string,
      appointmentTitle: string
    ) => `${dateTime} - ${appointmentTitle} (provisional)`
  },
  Authentication: {
    WelcomeToSalve: 'Bem-vindo ao Salve',
    Core: 'Core',
    ErrorLoggingIn: 'Erro ao iniciar sessão',
    Username: 'Nome de utilizador',
    Email: 'E-mail',
    ForgottenPassword: 'Esqueceu-se da palavra-passe? Clique aqui',
    HelloUser: (user) => `Olá ${user}`,
    Login: 'Iniciar sessão',
    Password: 'Palavra-passe',
    PasswordMissing: 'Introduza uma palavra-passe',
    UsernameMissing: 'Introduza um nome de utilizador',
    LoginSuccess: 'Início de sessão bem sucedido',
    ResetTitle: 'Repor a sua palavra-passe',
    ResetButton: 'Repor palavra-passe',
    EmailRequired: 'Introduza um e-mail válido',
    YourCode: 'O seu código de reposição',
    CodeRequired: 'Campo de preenchimento obrigatório',
    NewPassword: 'Nova palavra-passe',
    PasswordRequired: 'Campo de preenchimento obrigatório',
    PasswordsMustMatch: 'As suas palavras-passe devem corresponder',
    ConfirmPassword: 'Confirmar palavra-passe',
    SubmitNewPassword: 'Enviar nova palavra-passe',
    ConfirmNewPassword: 'Confirmar a nova palavra-passe',
    CreateYourAccount: 'Está na altura de criar a sua conta',
    FillInBelow: 'Por favor, preencha o formulário abaixo e clique em enviar',
    FirstName: 'Nome',
    FirstNameRequired: 'Campo de preenchimento obrigatório',
    LastName: 'Apelido',
    LastNameRequired: 'Campo de preenchimento obrigatório',
    CountryCode: 'Código do país',
    CountryCodeRequired: 'Campo de preenchimento obrigatório',
    PhoneNumber: 'Número de telefone',
    PhoneNumberRequired: 'Campo de preenchimento obrigatório',
    InvalidPhoneNumber: 'Introduza um número de telemóvel válido',
    CreateAccount: 'Criar conta',
    UserNotFoundException: 'E-mail não encontrado.',
    NotAuthorizedException: 'E-mail ou palavra-passe errados',
    InvalidPasswordException:
      'A palavra-passe deve ter no mínimo 8 caracteres e conter maiúsculas, minúsculas e números.',
    InvalidParameterException:
      'A palavra-passe deve ter no mínimo 8 caracteres e conter maiúsculas, minúsculas e números.',
    CodeMismatchException:
      'Código de verificação fornecido inválido. Por favor, tente novamente.',
    LimitExceededException:
      'Limite de tentativas excedido. Repita a tentativa após alguns minutos.',
    ResetEmail:
      'Enviámos-lhe um e-mail com um código de reposição. Introduza-o na caixa abaixo.',
    CodeCancel: 'Cancelar',
    CodeMissing:
      'Introduza o código válido. Deve ter um comprimento de 6 caracteres.',
    Code: 'Introduzir código',
    OTPTitle: 'Verifique o seu e-mail',
    OTPSubtitle:
      'Enviámos um código para o seu endereço de e-mail registado. Introduza-o abaixo para iniciar sessão.',
    SupportEmail: 'hello@salveapp.co.uk',
    OTPInstructions:
      'O código é válido durante 15 minutos. Por favor, verifique a sua caixa de spam se não o conseguir visualizar na sua caixa de entrada. Se tiver algum problema, envie-nos um e-mail para',
    InvalidCode: 'Código é inválido. Por favor, tente novamente.',
    OTPToastTitle: 'Tente iniciar sessão novamente',
    OTPToastMessage:
      'Excedeu o número de tentativas de inserção do código de login. Inicie sessão para tentar novamente. Se tiver dificuldades, envie-nos um e-mail para hello@salveapp.co.uk.'
  },
  MessageToast: {
    View: 'Ver',
    Undo: 'DESFAZER',
    Loading: 'A carregar...',
    MessageResolvedTitle: 'Mensagem resolvida',
    MessageResolvedBody: 'Mensagem marcada como resolvida',
    MessageResolvedUndoneTitle: 'Desfazer',
    MessageResolvedUndoneBody: 'Mensagem assinalada como não resolvida',
    MessageUnresolvedTitle: 'Mensagem não resolvida',
    MessageUnresolvedBody: 'Mensagem marcada como não resolvida',
    MessageUnresolvedUndoneTitle: 'Desfazer',
    MessageUnresolvedUndoneBody: 'Mensagem assinalada como resolvida',
    MessageUnreadTitle: 'Mensagem não lida',
    MessageUnreadBody: 'A mensagem foi marcada como não lida',
    MessageStarredTitle: 'Conversa marcada com estrela',
    MessageStarredBody:
      'A conversa foi marcada com estrela e movida para o separador com estrela.',
    MessageStarredTitleError: 'Não é possível marcar conversa com estrela',
    MessageStarredBodyError: 'Verifique a sua ligação e tente novamente.',
    MessageUnstarredTitle: 'Conversa desmarcada',
    MessageUnstarredBody:
      'A conversa foi desmarcada e movida para o separador Todas.',
    MessageUnstarredTitleError:
      'Não é possível desmarcar a conversa com estrela',
    MessageUnstarredBodyError: 'Verifique a sua ligação e tente novamente.',
    LabelChangedTitle: 'Rótulo alterado',
    LabelChangedBody: (from, to) =>
      `Rótulo da mensagem alterado de ${from} para ${to}`,
    MessageMovedTitle: 'Mensagem movida',
    MessageMovedBody: (from, to) => `Mensagem movida de ${from} para ${to}`,
    OutboundSentTitle: 'Mensagem de saída enviada',
    OutboundSentBody: (patientString) =>
      `Mensagem enviada com sucesso para ${patientString}`,
    LabelChangedUndoneTitle: 'Desfazer',
    LabelChangedUndoneBody: (from, to) =>
      `Rótulo da mensagem alterado para ${to} de ${from}`,
    MessageMovedUndoneTitle: 'Desfazer',
    MessageMovedUndoneBody: (from, to) =>
      `Mensagem movida de volta para ${to} de ${from}`,
    UndoingActionTitle: 'A desfazer ação',
    UndoingActionBody: (action) => `A desfazer a ação ${action}...`,
    ErrorEncountered: 'Erro encontrado',
    ErrorUnread: 'Erro ao marcar a mensagem como não lida',
    ErrorUnresolved: 'Erro ao marcar a mensagem como não resolvida',
    ErrorResolved: 'Erro ao marcar a mensagem como resolvida',
    ErrorChanging: 'Erro ao alterar rótulo',
    ErrorSending: (patient) => `Erro ao enviar mensagem para ${patient}`,
    SendingOutboundTitle: 'A enviar mensagem de saída',
    SendingOutboundBody: (patient) => `A enviar mensagem para ${patient}`,
    ErrorDeletingMessage:
      'O paciente já leu a mensagem, não é possivel eliminá-la.',
    ErrorDeletingMessageTitle: 'Erro ao apagar a mensagem',
    ErrorAssigningMessageTitle: 'Não foi possível atribuir conversa',
    ErrorUnAssigningMessageTitle:
      'Não foi possível anular a atribuição de conversa'
  },
  VideoCallsList: {
    AddFilter: 'Adicionar filtro',
    ClearFilter: 'Limpar',
    CreateVideoCall: 'Criar videochamada',
    Description: 'Descrição',
    DOB: 'Data de nascimento',
    Duration: 'Duração',
    Edit: 'Editar',
    HostResource: 'Colaborador/Recurso',
    Join: 'Participar',
    Locked:
      'Esta chamada foi agendada através do EMR. Se pretender remarcar ou cancelar esta chamada, faça-o no EMR.',
    More: 'Mais',
    NoPreviousCalls: 'Sem chamadas anteriores para mostrar',
    NoUpcomingCalls: 'Nenhuma chamada agendada para mostrar',
    Patient: 'Paciente',
    PreviousCalls: 'CHAMADAS ANTERIORES',
    Search: 'Pesquisar nome do paciente ID DN (DDMMAAAA)',
    Status: 'Estado',
    StatusFinished: 'Concluído',
    StatusOngoing: 'Em curso',
    StatusUpcoming: 'Próximas',
    UpcomingCalls: 'PRÓXIMAS CHAMADAS',
    When: 'Quando',
    FilterNoResults: 'Sem resultados',
    FilterSearch: 'Pesquisar',
    FilterApply: 'Aplicar',
    FilterCancel: 'Cancelar',
    FilterHostResource: 'Colaborador/Recurso',
    RefreshTitle: 'Atualizar a lista de chamadas'
  },
  ProfilePhotoModal: {
    Title: 'Fotografia de perfil',
    SubTitle:
      'Tenha em conta que esta foto será visível para os pacientes na aplicação',
    SelectPhoto: 'Selecionar uma fotografia',
    DeleteConfirmation: {
      Cancel: 'Cancelar',
      Delete: 'Eliminar',
      Content:
        'A sua foto de perfil será eliminada do portal da clínica e da aplicação do paciente.',
      Title: 'Tem a certeza?'
    },
    CloseConfirmation: {
      Cancel: 'Cancelar',
      Close: 'Fechar',
      Content: 'As alterações não serão guardadas.',
      Title: 'Tem a certeza?'
    },
    ChangePhoto: 'Alterar fotografia',
    Delete: 'Eliminar',
    Save: 'Guardar',
    Loading: 'A carregar',
    SuccessToast: {
      UpdatedTitle: 'Fotografia atualizada com sucesso',
      UpdatedDescription:
        'A sua fotografia de perfil foi guardada e está agora visível no portal da clínica e na aplicação do paciente.',
      DeletedTitle: 'Foto eliminada com sucesso',
      DeletedDescription:
        'A sua fotografia de perfil foi removida do portal da clínica e da aplicação do paciente.'
    },
    ErrorToast: {
      UploadTitle: 'Não foi possível carregar a fotografia de perfil',
      UpdateTitle: 'Não foi possível atualizar a fotografia de perfil',
      DeleteTitle: 'Não foi possível apagar a foto do perfil',
      GenericDescription:
        'Por favor, verifique a sua ligação e tente novamente.'
    }
  },
  VideoCallsToast: {
    Deleted: 'Videochamada eliminada',
    DeletedSuccessfully: 'A videochamada foi eliminada com sucesso',
    Deleting: 'A apagar a videochamada',
    ErrorDeleting: 'Erro ao apagar a videochamada',
    ErrorEncountered: 'Ocorreu um erro. Tente novamente.',
    ErrorSaving: 'Erro ao guardar a videochamada',
    JoiningVideoCall: 'A juntar-se à videochamada',
    PleaseWait: 'Aguarde...',
    PleaseWaitAndAllowAccess:
      'Por favor, aguarde e autorize o acesso ao microfone e/ou câmara se solicitado',
    Saved: 'Videochamada guardada',
    SavedSuccessfully: 'A videochamada foi guardada com sucesso',
    Saving: 'A guardar a videochamada',
    Error: 'Erro'
  },
  VideoCallModal: {
    Allow: 'Permitir',
    AnErrorOccured: 'Ocorreu um erro',
    AttemptingReconnect: 'A tentar restabelecer a ligação',
    AudioInputMenu: 'Entrada de áudio',
    AudioOutputMenu: 'Saída de áudio',
    CallEnded: 'Chamada terminada',
    CallNotReconnected: 'Não foi possível restabelecer a ligação',
    Cancel: 'Cancelar',
    Connected: 'Já está ligado',
    EndCall: 'Terminar a chamada',
    Error: 'Erro',
    LeaveCall: 'Sair da chamada',
    Mute: 'Desativar o microfone',
    NeedPermissions:
      'Para usar a videochamada, necessitamos primeiro de autorização para aceder ao microfone e à câmara. Permita o acesso se o seu browser o solicitar.',
    NoCameraMic:
      'É necessário um microfone e/ou uma câmara para participar numa chamada',
    NooneHere: (name) =>
      `De momento não existe ninguém disponível. Por favor, aguarde até que ${name} se junte a nós.`,
    Ok: 'OK',
    ParticipantDisconnected: 'Participante desconectado',
    ParticipantDisconnectedMessage: 'Um participante desconectou-se da chamada',
    Reconnecting: 'A restabelecer a ligação',
    StartCamera: 'Ligar câmara',
    StopCamera: 'Desligar câmara',
    Success: 'Bem sucedido',
    SureLeave: 'Tem a certeza de que quer terminar esta chamada?',
    Unmute: 'Ativar o microfone',
    VideoMenu: 'Vídeo',
    Yes: 'Sim'
  },
  VideoCallsInvitePartner: {
    InviteButton: 'Convidar parceiro',
    InvitePartner: 'Convidar parceiro?',
    InviteText1:
      'Esta videochamada foi adicionada à agenda do parceiro. Poderá demorar alguns minutos a aparecer na aplicação',
    InviteText2:
      'Tenha em atenção que não poderá cancelar o convite se a chamada já estiver a decorrer.',
    Cancel: 'Cancelar',
    Invite: 'Convidar',
    PartnerInvited: 'Parceiro convidado',
    PartnerInvitedText:
      'Esta videochamada foi adicionada ao calendário de consultas do parceiro. Poderá demorar alguns minutos a aparecer na sua aplicação',
    UnInviteButton: 'Anular o convite ao parceiro',
    UnInvitePartner: 'Anular o convite ao parceiro?',
    UnInviteText1:
      'Esta ação irá remover a videochamada do calendário de consultas do parceiro e este deixará de poder participar.',
    UnInviteText2:
      'Tenha em atenção que não poderá anular o convite do parceiro se a chamada já estiver a decorrer.',
    UnInvite: 'Anular convite',
    PartnerUninvited: 'Convite anulado',
    PartnerUnInvitedText:
      'A videochamada foi removida do calendário de consultas do parceiro e este já não poderá participar. Tenha em atenção que pode demorar até 5 minutos a ser removida.',
    InvitePartnerInCall:
      'Tenha em atenção que esta videochamada demorará até 5 minutos a aparecer no calendário de consultas.',
    InvitePartnerInCallText:
      'Tenha em atenção que a videochamada demorará até 5 minutos a ser apresentada no calendário de consultas na aplicação.',
    OK: 'OK',
    UnInvitePartnerInCall:
      'Tenha em atenção que esta videochamada demorará até 5 minutos a ser removida do calendário de consultas.',
    UnInvitePartnerInCallText1:
      'A videochamada foi removida do calendário de consultas do parceiro e este já não poderá participar.',
    UnInvitePartnerInCallText2:
      'Tenha em atenção que pode demorar até 5 minutos a ser removida.',
    PartnerNotFound: 'Parceiro não encontrado',
    PartnerNotFoundText1:
      'Não existe nenhum parceiro associado a este paciente no Salve.',
    PartnerNotFoundText2:
      'Se este paciente tiver um parceiro, o parceiro deve descarregar primeiro a aplicação e criar a sua própria conta.',
    PartnerNotFoundText3:
      'Após a realização deste procedimento, tente novamente.',
    PartnerNotFoundText4:
      'Se considerar que esta mensagem contém um erro, contacte o serviço de apoio do Salve em hello@salveapp.co.uk.',
    CouldNotInvite: 'Não foi possível convidar o parceiro',
    CouldNotInviteErrorMessage: 'Verifique a sua ligação e tente novamente.',
    CouldNotUnInvite: 'Não foi possível anular o convite do parceiro',
    CouldNotUnInviteErrorMessage: 'Verifique a sua ligação e tente novamente.',
    CallInProgressUnInviteMessage: 'Esta chamada já está a decorrer.'
  },
  SessionExpired: {
    Title: 'A sua sessão foi terminada',
    Message: 'A sua sessão expirou. Por favor, inicie sessão novamente'
  },
  MessagingNotifications: {
    BrowserTabTitle: (messagesLength: string) =>
      `(${messagesLength}) Portal da Clínica`,
    NotificationBody: (messagesLength: string) =>
      `Recebeu ${messagesLength} novas mensagens`,
    NotificationBodyMessage: (from: string) =>
      `Tem uma nova mensagem de ${from}`,
    NotificationTitle: 'Novas mensagens recebidas'
  },
  Admin: {
    AppointmentTypeNavItem: 'Consultas',
    AppointmentTypeListSearchPlaceholder: 'Pesquisar consultas...',
    AppointmentTypeListTableHeaderId: 'ID #',
    AppointmentTypeListTableHeaderName: 'Nome',
    AppointmentTypeListTableHeaderStatus: 'Estado',
    AppointmentTypeListTableChipActive: 'Ativo',
    AppointmentTypeListTableChipHidden: 'Oculto',
    AppointmentTypeListTableChipProvisional: 'Provisório',
    AppointmentTypeListTableChipStatusFilter: 'Filtro estado EMR',
    AppointmentTypeHeaderId: 'ID #',
    AppointmentTypeCaptionId: 'Número ID do tipo de consulta do EMR',
    AppointmentTypeHeaderCreatedAt: 'Criado em',
    AppointmentTypeCaptionCreatedAt:
      'A data em que o tipo de consulta foi criado no EMR',
    AppointmentTypeHeaderActive: 'Mostrar no Salve',
    AppointmentTypeCaptionActive:
      'Exibida no planeamento dos pacientes e disponível para edição de conteúdos',
    AppointmentTypeHeaderHidden: 'Sempre oculta',
    AppointmentTypeCaptionHidden: 'Sempre oculta dos pacientes na aplicação',
    AppointmentTypeHeaderProvisional: 'Provisória até',
    AppointmentTypeCaptionProvisional:
      'Será marcada como provisória na app do paciente até à hora indicada',
    AppointmentTypeValueProvisional: (duration: string) => `${duration} antes`,
    AppointmentTypeHeaderStatusFilters: 'Filtro estado EMR',
    AppointmentTypeCaptionStatusFilters:
      'Oculta até que um estado específico seja aplicado à marcação efectuada no EMR',
    AppointmentTypeHeaderVideoCall: 'Converter para videochamada',
    AppointmentTypeCaptionVideoCall:
      'Converter todas as consultas marcadas deste tipo em videochamadas. Esta opção deve ser utilizada apenas em tipos de consultas que serão agendadas exclusivamente para videochamadas. <b>Importante:</b> Esta opção apenas converterá as consultas deste tipo que forem agendadas <u>após</u> ter ativado a configuração. Quaisquer consultas deste tipo marcadas antes de a ativar não serão convertidas.',
    AppointmentTypeValueYes: 'Sim',
    AppointmentTypeValueNo: 'Não',
    AppointmentTypeListErrorToastMessage:
      'Erro ao carregar os tipos de consulta',
    AppointmentTypeLoadingErrorToastHeader: 'Erro de rede',
    AppointmentTypeLoadingErrorToastMessage:
      'Erro ao carregar o tipo de consulta',
    AppointmentTypeProvisionalHoursInputErrorRequired:
      'Introduza pelo menos um dígito no campo acima',
    AppointmentTypeProvisionalHoursInputLabel1: 'Provisória até:',
    AppointmentTypeProvisionalHoursInputLabel2:
      'horas antes da hora marcada para a consulta',
    AppointmentTypeSavingErrorHeader: 'Não é possível guardar as alterações',
    AppointmentTypeSavingErrorMessage:
      'Verifique a sua ligação e tente novamente',
    AppointmentTypeSavingSuccessHeader: 'Alterações guardadas com sucesso',
    AppointmentTypeSavingSuccessMessage:
      'As suas alterações foram guardadas com sucesso. Aguarde até 10 minutos para que estas sejam visíveis.',
    MessageTemplatesContentErrorLength: (max: number) =>
      `O conteúdo do modelo deve ter um máximo de ${max} caracteres`,
    MessageTemplatesContentErrorRequired: 'Campo de preenchimento obrigatório',
    MessageTemplatesDeleteButtonText: 'Eliminar',
    MessageTemplatesDeleteDialogAcceptButtonText: 'Eliminar',
    MessageTemplatesDeleteDialogCancelButtonText: 'Cancelar',
    MessageTemplatesDeleteDialogContent:
      'Se excluir este modelo, ele deixará de estar disponível para utilização nas conversas. Esta ação não pode ser desfeita',
    MessageTemplatesDeleteDialogTitle: 'Tem a certeza?',
    MessageTemplatesEmptyMessage: 'Não tem modelos de mensagens guardados',
    MessageTemplatesEmptyNonMatchingMessage:
      'Não foram encontrados modelos de mensagem correspondentes',
    MessageTemplatesInsertVariableButtonText:
      '{{{\u00A0\u00A0}}} Inserir variável',
    MessageTemplatesLastUpdatedByLabel: 'Última atualização por',
    MessageTemplatesLoadingErrorMessage:
      'Ocorreu um erro ao carregar os modelos de mensagens',
    MessageTemplatesLoadingErrorRetryButtonText: 'Tente novamente',
    MessageTemplatesNameErrorLength: (min: number, max: number) =>
      `Os nomes dos modelos devem ter ${min}-${max} caracteres`,
    MessageTemplatesNameErrorRequired: 'Campo de preenchimento obrigatório',
    MessageTemplatesNameErrorUnique:
      'O nome já está a ser utilizado. Por favor, introduza outro diferente.',
    MessageTemplatesNameLabel: 'Nome',
    MessageTemplatesNamePlaceholder: 'Por exemplo, confirmação de consulta',
    MessageTemplatesNavItem: 'Modelos de Mensagens',
    MessageTemplatesNavigationWarning:
      'Lembre-se de guardar todas as alterações antes de sair desta página',
    MessageTemplatesNewTemplateButtonText: 'Novo modelo',
    MessageTemplatesSaveTemplateButtonText: 'Guardar modelo',
    MessageTemplatesSearchPlaceholder: 'Pesquisar modelos de mensagens',
    MessageContentSearchPlaceholder: 'Pesquisar conteúdo',
    MessageContentSearchNoResults:
      'Sem conteúdo disponível. Adicione-o na secção Conteúdos',
    MessageContentSearchSelect: 'Selecione uma pasta ou pack à esquerda',
    MessageContentNoFolders:
      'Não tem pastas de conteúdo. Adicione-as na secção Conteúdos.',
    MessageContentNoPacks:
      'Não tem packs de conteúdo. Adicione-os na secção Conteúdos.',
    MessageContentEmptySearch: 'Sem resultados que correspondam à sua pesquisa',
    MessageContentLoadError: 'Erro ao carregar dados de conteúdo',
    MessageContentInsertLink: 'Inserir link de conteúdo',
    MessageTemplatesTemplateContentLabel: 'Modelo de conteúdo',
    NoAccessButtonText: 'Voltar ao painel de controlo',
    NoAccessMessage:
      'Não tem autorização para aceder a esta área do portal. Se crê que se trata de um erro, contacte o seu superior hierárquico.',
    SIDE: {
      NavItem: 'SIDE',
      SearchBarPlaceholder: 'Pesquisar receitas do SIDE...',
      RecipeListTableHeaderId: 'ID',
      RecipeListTableHeaderName: 'Nome',
      RecipeListTableHeaderStatus: 'Estado',
      RecipeListTableHeaderCreatedOn: 'Criada em',
      RecipeListTableHeaderLastUpdatedOn: 'Última atualização em',
      RecipeListTableHeaderLastUpdatedBy: 'Última atualização por',
      RecipeListLoadingErrorToastHeader: 'Erro de rede',
      RecipeListErrorToastMessage: 'Erro ao carregar receitas do SIDE',
      RecipeListTableHeaderAction: 'Ação',
      RecipeListTableHeaderEventType: 'Tipo de evento',
      AppointmentEvent: 'Consulta',
      MedicationEvent: 'Medicação',
      RegistrationEvent: 'Registo',
      ActiveStatus: 'Ativo',
      InactiveStatus: 'Inativo',
      RecipeListEmptyMessage: 'Não tem receitas definidas no SIDE',
      RecipeLoadingErrorToastHeader: 'Erro de rede',
      RecipeLoadingErrorToastMessage: 'Erro ao carregar a receita',
      SettingsHeader: 'Configurações',
      RecipeNameLabel: 'Nome',
      RecipeStatusLabel: 'Estado',
      ActionListTableHeaderId: 'ID',
      ActionListTableHeaderContent: 'Conteúdo',
      ActionListTableHeaderActionType: 'Tipo de ação',
      ActionListTableHeaderTiming: 'Programação',
      ActionListTableHeaderMedicationStatus: 'Estado da medicação',
      ActionListTableHeaderActionStatus: 'Estado da ação',
      ActionListTableHeaderCreatedOn: 'Criada em',
      ActionListTableHeaderLastUpdatedOn: 'Última atualização em',
      ActionListTableHeaderLastUpdatedBy: 'Última atualização por',
      ActionListTableHeaderAppointmentStatus: 'Estado consulta',
      ActionListTableHeaderSite: 'Local',
      ActionHeader: 'Ações',
      ActionListEmptyMessage: 'A receita não tem ações definidas',
      'Not setAppointmentStatus': 'Não definida',
      AttendedAppointmentStatus: 'Compareceu',
      'No-ShowAppointmentStatus': 'Não Compareceu',
      CancelledAppointmentStatus: 'Cancelada',
      'Before AppointmentAppointmentStatus': 'Antes da consulta',
      'After AppointmentAppointmentStatus': 'Após a consulta',
      ConfirmedAppointmentStatus: 'Confirmada',
      'Not CancelledAppointmentStatus': 'Não Cancelado',
      BookedAppointmentStatus: 'Marcada',
      BookedMedicationStatus: 'Marcada',
      HourPeriod: 'hora',
      MinutePeriod: 'minuto',
      DayPeriod: 'dia',
      Before: 'antes',
      After: 'depois',
      ActionListLoadingErrorToastHeader: 'Erro de rede',
      ActionListErrorToastMessage: 'Erro ao carregar ações',
      AppointmentTriggerHeader: 'Tipos de consultas afetadas',
      MedicationTriggerHeader: 'Tipos de medicamentos afetados',
      TriggerListLoadingErrorToastHeader: 'Erro de rede',
      TriggerListErrorToastMessage: 'Erro ao carregar triggers',
      NoTriggersAvailable: 'Sem triggers disponíveis',
      ExportCSV: 'Exportar ficheiro CSV',
      ExportDialogTitle: 'Exportar todos os dados do SIDE?',
      ExportDialogDescription:
        'Tenha em atenção que nenhum filtro aplicado estará incluído no ficheiro CSV exportado',
      ExportDialogCancel: 'Cancelar',
      ExportDialogDownload: 'Download',
      SIDEExport: 'Exportação do SIDE',
      ExportErrorTitle: 'Erro ao descarregar a exportação',
      ExportErrorDescription: 'Verifique a sua ligação e tente novamente',
      AddFilter: 'Adicionar filtro',
      FilterApply: 'Aplicar',
      FilterTabEventType: 'Tipo de evento',
      FilterTabStatus: 'Estado',
      FilterTabAppointmentType: 'Tipos de consulta',
      FilterTabDrugType: 'Tipos de medicamentos',
      FilterApptSearchBarPlaceholder: 'Pesquisar tipos de consultas',
      FilterDrugSearchBarPlaceholder: 'Pesquisar tipos de medicamentos',
      ClearAllFilter: 'Eliminar tudo',
      RecipeNameRequiredErrorMessage: 'Campo de preenchimento obrigatório.',
      RecipesSavingErrorHeader: 'Erro ao atualizar a receita',
      RecipesSavingErrorMessage: 'Verifique a sua ligação e tente novamente.',
      RecipesSavingSuccessHeader: 'Receita do SIDE atualizada com sucesso',
      RecipesSavingSuccessMessage: 'A receita foi atualizada com sucesso',
      RecipeNameErrorLength: (min, max) =>
        `Os nomes das receitas devem ter ${min}-${max} caracteres`,
      RecipeSaveButtonText: 'Guardar',
      RecipeTriggerInfo:
        'Os tipos de consulta em causa não podem ser editados para efeitos de auditoria e contabilidade. Se necessitar de fazer alterações, marque esta receita como inativa e crie uma nova.',
      RecipeUniqueNameError: 'O nome da receita já está a ser utilizado.'
    },
    Users: {
      NavItem: 'Utilizadores',
      SearchBarPlaceholder: 'Pesquisar utilizadores',
      UsersListEmptyMessage: 'Nenhum utilizador encontrado',
      UsersListEmptyActiveUsers: 'Não tem utilizadores ativos',
      UsersListEmptyInvitedUsers: 'Não tem utilizadores convidados',
      UsersListEmptyInactiveUsers: 'Não existem utilizadores inativos',
      UsersListEmptyUsers: 'Não foram encontrados resultados',
      ActiveStatus: 'Ativo',
      DeactivatedStatus: 'Inativo',
      InvitedStatus: 'Convidado',
      UsersListTableHeaderFirstName: 'Nome',
      UsersListTableHeaderLastName: 'Apelido',
      UsersListTableHeaderEmail: 'E-mail',
      UsersListTableHeaderStatus: 'Estado',
      UsersListTableHeaderLastLogIn: 'Último acesso',
      UsersListTableHeaderRegistered: 'Registado',
      UsersLoadingErrorToastHeader: 'Erro de rede',
      UsersLoadingErrorToastMessage: 'Erro ao carregar utilizadores',
      AddFilter: 'Adicionar filtro',
      FilterApply: 'Aplicar',
      FilterTabStatus: 'Estado',
      UsersExport: 'Exportação dos utilizadores Salve',
      ExportCSV: 'Exportar ficheiro CSV',
      ExportDialogTitle: 'Exportar todos os utilizadores?',
      ExportDialogDescription:
        'Tenha em atenção que esta exportação em formato CSV conterá a última sincronização de todos os funcionários registados no seu EMR, mesmo aqueles que não criaram uma conta no Salve.',
      ExportDialogCancel: 'Cancelar',
      ExportDialogDownload: 'Exportar',
      ExportErrorTitle: 'Não foi possível exportar para CSV',
      ExportErrorDescription: 'Verifique a sua ligação e tente novamente.',
      ExportSuccessTitle: 'Exportado com sucesso',
      ExportSuccessDescription: 'Veja o ficheiro CSV nas suas transferências',
      InviteUserAction: 'Convidar utilizador',
      DeactivateUserAction: 'Remover o acesso ao Salve',
      InviteUserErrorTitle: 'Não foi possível executar a ação',
      InviteUserErrorDescription: 'Verifique a sua ligação e tente novamente.',
      InviteUserSuccessTitle: 'Título do sucesso',
      InviteUserSuccessDescription: 'Descrição do sucesso',
      DeactivateUserErrorTitle: 'Não foi possível executar a ação',
      DeactivateUserErrorDescription:
        'Verifique a sua ligação e tente novamente.',
      InviteUserModalCancel: 'Cancelar',
      InviteUserModalAccept: 'Convidar',
      DeactivateUserModalTitle:
        'Tem a certeza de que pretende remover o acesso ao Salve para este utilizador?',
      DeactivateUserModalText:
        'Tenha em atenção que isto impedirá os utilizadores de iniciarem sessão no portal. Se pretender dar-lhes novamente acesso ao portal, terá de os convidar novamente.',
      DeactivateUserModalCancel: 'Cancelar',
      DeactivateUserModalAccept: 'Remover acesso',
      TooltipActive: 'O funcionário tem acesso ao portal do Salve.',
      TooltipInactive: 'O funcionário não tem acesso ao portal do Salve.',
      TooltipInvited: 'O funcionário foi convidado para o portal do Salve.',
      InviteUser: 'Convidar utilizador',
      User: 'Utilizador',
      WelcomeMessage: 'Mensagem de boas-vindas',
      Send: 'Enviar',
      Cancel: 'Cancelar',
      InviteSentTitle: 'Convite enviado',
      InviteSentMessage: 'Este convite foi enviado ao utilizador.',
      InviteSentErrorTitle: 'Não foi possível convidar o utilizador',
      InviteSentErrorMessage:
        'Verifique a sua ligação e tente novamente. Certifique-se também de que o e-mail do utilizador está correto.',
      DeactivateSentTitle: 'Desativar utilizador',
      DeactivateSentMessage: 'O utilizador foi desativado.',
      DeactivateSentErrorTitle: 'Não foi possível desativar o utilizador',
      DeactivateSentErrorMessage: 'Verifique a sua ligação e tente novamente.',
      InviteMessage: (clinicURL) => `Olá,
      Bem-vindo ao Salve! Se já possui uma conta Salve para outra clínica, utilize os seus dados de login existentes ao aceder a este portal.
      Se é um novo utilizador do Salve, enviámos-lhe um e-mail separado com o seu nome de utilizador e a sua palavra-passe temporária. Utilize-os para iniciar sessão e criar a sua conta permanente no portal: ${clinicURL}
      A sua palavra-passe temporária expirará dentro de três dias. Se não criar a sua conta nesse período, será necessário solicitar uma nova palavra-passe temporária.
      Tenha em atenção que deve aceder ao portal Salve através do Google Chrome ou do Microsoft Edge. O Salve não é compatível com o Internet Explorer.`,
      SendTemporaryPassword: 'Reenviar nova palavra-passe temporária',
      SendTemporaryPasswordModalTitle: 'Enviar nova palavra-passe temporária?',
      SendTemporaryPasswordModalText: `Se clicar em 'Enviar', o funcionário receberá no seu e-mail uma nova palavra-passe temporária.`,
      SendTemporaryPasswordErrorTitle:
        'Não foi possível enviar a nova palavra-passe temporária',
      SendTemporaryPasswordErrorDescription:
        'Verifique a sua ligação e tente novamente.',
      SendTemporaryPasswordSuccessTitle:
        'Nova palavra-passe temporária enviada',
      SendTemporaryPasswordSuccessDescription: `O funcionário recebeu uma nova palavra-passe temporária.`
    }
  },
  SuperAdmin: {
    DataExport: {
      Title: 'Exportação de dados',
      StartDateLabel: 'Data de início',
      EndDateLabel: 'Data de fim',
      TimeFrameLabel: 'Duração',
      RecipeLabel: 'Receita',
      Today: 'Hoje',
      Yesterday: 'Ontem',
      Last7Days: 'Últimos 7 dias',
      LastWeek: 'Última semana',
      ThisMonth: 'Este mês',
      LastMonth: 'Último mês',
      Last12Months: 'Últimos 12 meses',
      CustomDates: 'Datas personalizadas',
      MessagesTitle: 'Mensagens',
      MessagesSubtitle:
        'A exportação conterá pormenores de conversas entre os funcionários da clínica e os pacientes no período de tempo selecionado',
      MessagesFilenameSuffix: 'Dados de mensagens do Salve',
      SIDERecipeTitle: 'Dados do SIDE',
      SIDERecipeSubtitle:
        'A exportação conterá dados baseados na receita selecionada do SIDE e nas ações que contém.',
      SIDERecipeFilenameSuffix: 'Guardar dados da receita',
      SIDERecipeInactive: 'Inativo',
      ToastSuccessTitle: 'Exportação bem-sucedida',
      ToastSuccessDescription:
        'Os dados solicitados foram exportados com sucesso. Verifique a sua pasta de transferências.',
      ToastFailureTitle: 'Falha na exportação',
      ToastFailureDescription: 'Verifique a sua ligação e tente novamente'
    },
    NoAccessButtonText: 'Voltar ao painel de controlo',
    NoAccessMessage:
      'Não tem autorização para aceder a esta área do portal. Se crê que se trata de um erro, contacte o seu superior hierárquico.'
  },
  General: {
    OpenAttachmentFileErrorTitle: 'Não foi possível abrir o ficheiro',
    OpenAttachmentFileErrorMessage: 'Verifique a sua ligação e tente novamente',
    ConnectionError: 'Verifique a sua ligação e tente novamente'
  },
  GlobalCMS: {
    Back: 'Voltar'
  }
};
