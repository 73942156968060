import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import SwitchPortal from '../../../react/components/switchPortal';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';

const containerElementName = 'switchPortalReactWrapperComponent';

@Component({
  selector: 'portal-switch',
  template: `<div #${containerElementName}></div>`,
})
export class SwitchPortalReactWrapperComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(containerElementName) containerRef!: ElementRef;
  @Input() clinic: string;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
      ]).subscribe(
        ([clinicToken, clinicOptions, user, language, translations]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
          };
          this.render();
        },
      ),
    );
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.render();
  }

  private async render() {
    ReactDOM.render(
      React.createElement(Root, {
        ...this._props,
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        children: React.createElement(SwitchPortal, {
          clinicName: this.clinic,
          clinicToken: this._props.clinicToken,
        }),
      }),
      this.containerRef.nativeElement,
    );
  }
}
