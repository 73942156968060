import * as tslib_1 from "tslib";
import { S3ActionTypes } from '../actions/s3.actions';
export var initialState = {
    config: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case S3ActionTypes.GetS3OptionsSuccess: {
            return tslib_1.__assign({}, state, { config: action.payload });
        }
        default: {
            return state;
        }
    }
}
