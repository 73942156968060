import styled from '@emotion/styled';
import { ChevronLeft } from '@material-ui/icons';

export const StyledChevron = styled(ChevronLeft)<{ open: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(270deg)')};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StyledTitle = styled.span`
  cursor: pointer;
`;

export const StyledLink = styled.a`
  display: block;
  width: 100%;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
