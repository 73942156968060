import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
var ContentListControlsComponent = /** @class */ (function () {
    function ContentListControlsComponent(_fb) {
        this._fb = _fb;
        this.listType = 'content-list';
        this.setFilter = new EventEmitter();
        this.addNew = new EventEmitter();
        this.options = {
            contentList: 'content-list',
            packList: 'pack-list',
        };
    }
    ContentListControlsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchForm = this._fb.group({
            searchValue: [{ value: this.filter, disabled: false }],
        });
        this._searchFormSub = this.searchForm
            .get('searchValue')
            .valueChanges.pipe(debounceTime(300))
            .subscribe(function (val) {
            _this.setFilter.emit(val.toLowerCase());
        });
    };
    ContentListControlsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.contentType) {
            if (changes.contentType.currentValue !== changes.contentType.previousValue) {
                if (this.searchForm) {
                    setTimeout(function () {
                        _this.clearFilter();
                    });
                }
            }
        }
    };
    ContentListControlsComponent.prototype.ngOnDestroy = function () {
        if (this._searchFormSub) {
            this._searchFormSub.unsubscribe();
        }
    };
    ContentListControlsComponent.prototype.clearFilter = function () {
        this.searchForm.patchValue({
            searchValue: '',
        });
    };
    return ContentListControlsComponent;
}());
export { ContentListControlsComponent };
