import '@emotion/react';
import { Theme } from '@emotion/react';
import { createTheme } from '@material-ui/core';

const primaryColor = '#8665e3';
const errorColor = '#c21616';

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: errorColor,
    },
  },
  typography: {
    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
  },
});

export const portalTheme = {
  colors: {
    primary: primaryColor,
    primaryLight: '#eeecf9',
    primaryDark: '#6338da',
    border: {
      default: '#979797',
      dark: '#d3d3d3',
    },
    error: errorColor,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    gray: '#e0e0e0',
    grayLight: '#f7f7fb',
    paleGray: '#eeecf9',
    greyishBrown: '#4a4a4a',
    brownGrey: '#9b9b9b',
    purple: '#d5c9f5',
    green: '#b8e4de',
    red: '#ffd0d2',
    coral: '#ff6d74',
    yellow: '#ffc107',
    yellowLight: '#ffebb2',
    purpleDark: '#5E3FA0',
  },
  spacing: materialTheme.spacing,
  typography: materialTheme.typography,
};

declare module '@emotion/react' {
  // https://emotion.sh/docs/typescript#define-a-theme

  type PortalTheme = typeof portalTheme;
  // tslint:disable-next-line: no-empty-interface
  export interface Theme extends PortalTheme {}
}
