import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConversationPage } from '@react/pages/conversations';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import { FileUpload } from 'src/app/core/services/s3.service';
import * as fromAuth from '../../auth/reducers';
import { CognitoWrapperService } from '../../core/services/congito.wrapper.service';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
var containerElementName = 'portalConversationResolver';
var ConversationResolverComponent = /** @class */ (function () {
    function ConversationResolverComponent(_fileUpload, _store, _cognito) {
        this._fileUpload = _fileUpload;
        this._store = _store;
        this._cognito = _cognito;
        this._subscriptions = new Subscription();
    }
    ConversationResolverComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
            this._store.pipe(select(fromRoot.getConversationCache)),
            this._store.pipe(select(fromRoot.getStaffProfiles)),
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4], conversationCache = _a[5], staffProfiles = _a[6];
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user,
                conversationCache: conversationCache,
                staffProfiles: staffProfiles,
            };
            _this.render();
        }));
    };
    ConversationResolverComponent.prototype.ngAfterViewInit = function () {
        this.render();
    };
    ConversationResolverComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
        this._subscriptions.unsubscribe();
    };
    ConversationResolverComponent.prototype.render = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                ReactDOM.render(React.createElement(ConversationPage, tslib_1.__assign({}, this._props, { authService: this._cognito, dispatch: this._store.dispatch.bind(this._store), fileUploadService: this._fileUpload })), this.containerRef.nativeElement);
                return [2 /*return*/];
            });
        });
    };
    return ConversationResolverComponent;
}());
export { ConversationResolverComponent };
