import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import * as React from 'react';
export var Container = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var FullHeightPaper = styled(Paper)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  flex-grow: 1;\n  padding: ", "px;\n"], ["\n  flex-grow: 1;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledForm = styled.form(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  max-width: 32rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  max-width: 32rem;\n"])));
export var WarningText = styled(Typography)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var WarningIcon = styled(Warning)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  color: orange;\n  margin-right: 4px;\n  position: relative;\n  top: -1px;\n"], ["\n  color: orange;\n  margin-right: 4px;\n  position: relative;\n  top: -1px;\n"])));
export var FieldWrapper = styled.div(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  margin: ", "px 0;\n"], ["\n  margin: ", "px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(0.75);
});
export var Actions = styled.div(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  justify-content: ", ";\n  margin-top: ", "px;\n"], ["\n  display: flex;\n  justify-content: ",
    ";\n  margin-top: ", "px;\n"])), function (_a) {
    var children = _a.children;
    return React.Children.toArray(children).filter(function (x) { return !!x; }).length > 1
        ? 'space-between'
        : 'flex-end';
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var ButtonProgress = styled(CircularProgress)(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  left: 50%;\n  margin-left: ", ";\n  margin-top: ", ";\n  position: absolute;\n  top: 50%;\n"], ["\n  left: 50%;\n  margin-left: ",
    ";\n  margin-top: ",
    ";\n  position: absolute;\n  top: 50%;\n"])), function (_a) {
    var size = _a.size;
    return size != null ? "-" + parseInt(size, 10) / 2 + "px" : 0;
}, function (_a) {
    var size = _a.size;
    return size != null ? "-" + parseInt(size, 10) / 2 + "px" : 0;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
