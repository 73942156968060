import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
export var StyledList = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  list-style: none;\n  margin-top: ", "px;\n  overflow: auto;\n  max-height: 300px;\n\n  div.listItem {\n    padding-left: ", "px;\n    padding-top: ", "px;\n    padding-bottom: ", "px;\n    cursor: pointer;\n    font-size: ", ";\n\n    &.active {\n      color: ", ";\n    }\n  }\n"], ["\n  list-style: none;\n  margin-top: ", "px;\n  overflow: auto;\n  max-height: 300px;\n\n  div.listItem {\n    padding-left: ", "px;\n    padding-top: ", "px;\n    padding-bottom: ", "px;\n    cursor: pointer;\n    font-size: ", ";\n\n    &.active {\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.typography.body2.fontSize;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledKey = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  gap: ", "px;\n"], ["\n  display: flex;\n  gap: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var LoaderWrapper = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"], ["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
