import { ActiveToast, IndividualConfig } from 'ngx-toastr';
import { PortalToastComponent } from '../core/components/portal-toast/portal-toast.component';

export class ToastOptions {
  public toast: ActiveToast<PortalToastComponent>;
  public toastRef: PortalToastComponent | null;
  public undoAction: any;
  public title: string;
  public message: string;
  public type: string;
  public options?: IndividualConfig;

  constructor({ toast, toastRef, undoAction, title, message, type, options }) {
    (this.toast = toast || null), (this.toastRef = toastRef);
    this.undoAction = undoAction;
    this.title = title;
    this.message = message;
    this.type = type;
    this.options = options;
  }
}
