import {
  MessageUIActionsUnion,
  MessageUIActionTypes,
} from '../actions/message-ui.actions';
import { TabState } from '../models/TabState';

import { MessagesState } from '../reducers';

export function reducer(
  state: MessagesState,
  action: MessageUIActionsUnion,
): MessagesState {
  switch (action.type) {
    case MessageUIActionTypes.OpenThread: {
      const m = action.payload;
      return {
        ...state,
        loadingThread: true,
        activeThreadFirstLoad: true,
        activeThreadId: m.MessageId,
        activeThread: {
          id: m.MessageId,
          typeId: m.MessageTypeId,
          type: m.MessageType,
          patientName: `${m.PatientFirstName} ${m.PatientLastName}`,
          patientFirstName: m.PatientFirstName,
          patientId: m.PatientId,
          patientIdentifier: m.PatientIdentifier,
          patientDoB: m.DateOfBirth,
          patientSent: m.PatientSent,
          isResolved: m.MessageStatusId === 999,
          isUnread: m.Read && !m.PatientSent,
          isReadOnly: true,
          messages: [
            {
              ...m,
              ReadDate: null,
            },
          ],
          hasNewMessages: false,
          subject: m.MessageSubject,
        },
        activeThreadOpen: true,
      };
    }

    case MessageUIActionTypes.CloseThread: {
      return {
        ...state,
        activeThreadOpen: false,
        activeThreadId: null,
        activeThread: null,
      };
    }

    case MessageUIActionTypes.ApplyFilter: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    // change label and reset count of previous list to default of 25
    case MessageUIActionTypes.ApplyMessageTypeFilter: {
      return {
        ...state,
        messageList: [],
        reachedEndOfMessages: false,
        messageTypeFilter: action.payload,
        nextPageToLoad: {
          ...state.nextPageToLoad,
          SearchText: null,
          FetchAfterMessageItemId: null,
          FetchBeforeMessageItemId: null,
          MessageTypeId: action.payload.messageTypeId,
        },
        messageCountSummary: {
          starred: null,
          unstarred: null,
        },
      };
    }

    case MessageUIActionTypes.UpdateSearchString: {
      return {
        ...state,
        messageList: [],
        searchActive: true,
        searchString: action.payload,
        nextPageToLoad: {
          ...state.nextPageToLoad,
          FetchAfterMessageItemId: null,
          FetchBeforeMessageItemId: null,
          MessageTypeId: null,
          SearchText: action.payload,
          Resolved: null,
        },
        reachedEndOfMessages: false,
      };
    }

    case MessageUIActionTypes.ActivateSearch: {
      return {
        ...state,
        searchActive: true,
      };
    }

    case MessageUIActionTypes.ClearSearch: {
      return {
        ...state,
        searchActive: false,
        searchString: null,
        messageList: [],
        nextPageToLoad: {
          ...state.nextPageToLoad,
          MessageTypeId: state.messageTypeFilter.messageTypeId,
          FetchAfterMessageItemId: null,
          FetchBeforeMessageItemId: null,
          Resolved: false,
          SearchText: null,
        },
        reachedEndOfMessages: false,
      };
    }

    case MessageUIActionTypes.ChangeMessageResponseSize: {
      return {
        ...state,
        messageResponseSize: action.payload,
      };
    }

    case MessageUIActionTypes.HideThreadNewMessagesButton: {
      return {
        ...state,
        activeThread: {
          ...state.activeThread,
          hasNewMessages: false,
        },
      };
    }

    case MessageUIActionTypes.SetSelectedTab: {
      return {
        ...state,
        messageList: [],
        reachedEndOfMessages: false,
        nextPageToLoad: {
          ...state.nextPageToLoad,
          FetchAfterMessageItemId: null,
          FetchBeforeMessageItemId: null,
          ...action.payload.params,
        },
        tab: action.payload.tab,
      };
    }

    case MessageUIActionTypes.UpdateConversationCache: {
      return {
        ...state,
        conversationCache: {
          ...state.conversationCache,
          [action.payload.messageId]: action.payload.message,
        },
      };
    }

    default:
      return state;
  }
}
