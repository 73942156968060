import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EditContentPack, } from '../../actions/packs.actions';
import { validateContentPackName } from '../../services/async-validators/validate-pack-name';
import { PacksService } from '../../services/packs.service';
import { ContentPackActionTypes, } from './../../actions/packs.actions';
var EditPackNameComponent = /** @class */ (function () {
    function EditPackNameComponent(_store, _updates, dialogRef, dialog, _packService, data) {
        this._store = _store;
        this._updates = _updates;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this._packService = _packService;
        this.data = data;
        this.loading$ = new BehaviorSubject(false);
        this.savingName$ = new BehaviorSubject(false);
        this.savedName$ = new BehaviorSubject(false);
        this.errorEncountered$ = new BehaviorSubject(false);
        this.formStatus = {
            INVALID: 'INVALID',
            PENDING: 'PENDING',
        };
        this._subs = new Subscription();
    }
    EditPackNameComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.packName = new FormControl(this.data.pack.packName, Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50),
        ]), [
            validateContentPackName(this._packService, this.loading$, this.data.pack.packName),
        ]);
        // watch for errors on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentPackActionTypes.EditContentPackError))
            .subscribe(function () {
            _this.savingName$.next(false);
            _this.errorEncountered$.next(true);
            _this.exitDialog(false, 1000);
        }));
        // watch for success on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentPackActionTypes.EditContentPackSuccess))
            .subscribe(function () {
            _this.savingName$.next(false);
            _this.savedName$.next(true);
            _this.exitDialog(true, 1000);
        }));
    };
    EditPackNameComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    EditPackNameComponent.prototype.clearForm = function () {
        this.packName.setValue('');
    };
    EditPackNameComponent.prototype.savePackNameChange = function () {
        this.savingName$.next(true);
        var name = this.packName.value;
        this._store.dispatch(new EditContentPack({
            packId: this.data.pack.id,
            name: name,
        }));
    };
    EditPackNameComponent.prototype.cancelNameChange = function () {
        this.exitDialog(false);
    };
    EditPackNameComponent.prototype.exitDialog = function (status, time) {
        var _this = this;
        if (status === void 0) { status = false; }
        if (time === void 0) { time = 0; }
        setTimeout(function () {
            _this.dialogRef.close(status);
        }, time);
    };
    return EditPackNameComponent;
}());
export { EditPackNameComponent };
