import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableMedicationAction } from '../../models/MedicationAction';
import { MedicationStatus } from '../patient-list/patient-list.component.enums';
import { IRowSelection } from '../patient-list/patient-list.component.interfaces';

@Component({
  selector: 'portal-patient-medications',
  templateUrl: './patient-medications.component.html',
  styleUrls: ['./patient-medications.component.scss'],
})
export class PatientMedicationsComponent implements OnInit {
  @Input()
  public medications: Array<TableMedicationAction>;

  public displayedColumns: Array<string> = ['type', 'drug', 'dosage', 'status'];

  @Input()
  public text: any;

  @Output()
  public rowSelected: EventEmitter<IRowSelection> = new EventEmitter<IRowSelection>();

  constructor() {}

  ngOnInit() {}

  public onClick(row: TableMedicationAction): void {
    this.rowSelected.emit({
      id: row.id,
      date: new Date(row.dueDate),
    });
  }

  public matIcon(status: MedicationStatus): string {
    const symbols: { [id: number]: string } = {
      [MedicationStatus.Overdue]: 'query_builder',
      [MedicationStatus.WindowClosing]: 'query_builder',
      [MedicationStatus.PotentialDuplicate]: 'error',
      [MedicationStatus.Taken]: 'check_circle',
      [MedicationStatus.DueAt]: 'error',
    };

    return symbols[status];
  }
}
