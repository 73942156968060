<form
  class="title-form-container"
  [formGroup]="titleForm"
  fxFlex="grow"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="10px"
>
  <div
    class="content-entry-title-container"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="50px"
  >
    <mat-form-field
      fxFlex="1 0 calc(425px)"
      class="title input-container"
      appearance="outline"
    >
      <mat-label>{{ text.Title }}</mat-label>
      <input
        matInput
        class="content-entry-title"
        id="title"
        formControlName="title"
        placeholder="{{ text.AddTitle }}"
      />
      <mat-icon
        *ngIf="fromDataSync"
        matSuffix
        matTooltip="{{ text.TitleChange }}"
        >lock</mat-icon
      >
      <div
        *ngIf="!fromDataSync"
        matSuffix
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="15px"
      >
        <mat-spinner *ngIf="checkingTitle" diameter="20"> </mat-spinner>
        <button
          *ngIf="
            titleForm.get('title').value &&
            titleForm.get('title').value.length > 0
          "
          mat-button
          mat-icon-button
          (click)="clearTitle()"
          [disabled]="!contentEditorEnabled"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <mat-error *ngIf="titleForm.get('title').hasError('required')">
        {{ text.TitleIs }} <strong>{{ text.Required }}</strong>
      </mat-error>
      <mat-error *ngIf="titleForm.get('title').hasError('pattern')">
        {{ text.TitlePattern }}
      </mat-error>
      <mat-error
        *ngIf="
          (titleForm.get('title').hasError('minlength') ||
            titleForm.get('title').hasError('maxlength')) &&
          !titleForm.get('title').hasError('required')
        "
      >
        {{ text.TitleMust }} {{ text.Between }} <strong>3</strong>
        {{ text.And }} <strong>50</strong> {{ text.Characters }}.
      </mat-error>
      <mat-error *ngIf="titleForm.get('title').hasError('nameExists')">
        {{ text.TitleMust }} <strong>{{ text.Unique }}</strong
        >{{ text.TitleExists }}.
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="fromDataSync"
      fxFlex="1 0 calc(425px)"
      class="title input-container"
      appearance="outline"
    >
      <div matPrefix fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-icon-button
          matTooltip="{{ text.TransferTitle }}"
          (click)="copyTitleFromEMR()"
          [disabled]="!contentEditorEnabled"
        >
          <mat-icon>trending_flat</mat-icon>
        </button>
      </div>
      <mat-label>{{ text.PatientTitle }}</mat-label>
      <input
        matInput
        class="content-entry-title"
        id="patientTitle"
        formControlName="patientTitle"
        placeholder="{{ text.AddPatientTitle }}"
      />
      <div
        matSuffix
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="15px"
      >
        <!-- <mat-spinner
          *ngIf="checkingTitle"
          diameter="20"
        >
        </mat-spinner> -->
        <button
          *ngIf="
            titleForm.get('patientTitle').value &&
            titleForm.get('patientTitle').value.length > 0
          "
          mat-button
          mat-icon-button
          (click)="clearPatientTitle()"
          [disabled]="!contentEditorEnabled"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <mat-error
        *ngIf="
          (titleForm.get('patientTitle').hasError('minlength') ||
            titleForm.get('patientTitle').hasError('maxlength')) &&
          !titleForm.get('patientTitle').hasError('required')
        "
      >
        {{ text.TitleMust }} {{ text.Between }} <strong>3</strong>
        {{ text.And }} <strong>50</strong> {{ text.Characters }}.
      </mat-error>
      <mat-error *ngIf="titleForm.get('patientTitle').hasError('required')">
        {{ text.TitleIs }} <strong>{{ text.Required }}.</strong>
      </mat-error>
      <mat-error *ngIf="titleForm.get('patientTitle').hasError('nameExists')">
        {{ text.TitleMust }} <strong>{{ text.Unique }}</strong
        >{{ text.TitleExists }}.
      </mat-error>
    </mat-form-field>
  </div>
  <div
    *ngIf="!parentInfo"
    class="content-entry-title-container"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <mat-form-field class="input-container large" appearance="outline">
      <mat-label>{{ text.Subtitle }}</mat-label>
      <input
        matInput
        class="content-entry-title"
        id="subtitle"
        formControlName="subtitle"
        placeholder="{{ text.AddSubtitle }}"
      />
      <div
        matSuffix
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="15px"
      >
        <button
          *ngIf="titleForm.get('subtitle').value.length > 0"
          mat-button
          mat-icon-button
          (click)="clearSubtitle()"
          [disabled]="!contentEditorEnabled"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <mat-error *ngIf="titleForm.get('subtitle').hasError('maxlength')">
        {{ text.SubtitleLessThan }} <strong>60</strong> {{ text.Characters }}.
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <button
      *ngIf="!fileOnly && !parentInfo"
      [matMenuTriggerFor]="menu"
      mat-raised-button
      class="portal-btn btn-primary"
      [disabled]="!contentEditorEnabled"
    >
      {{ contentText.Actions }}
    </button>
    <mat-menu #menu="matMenu" yPosition="below" class="content-edit-menu-panel">
      <button
        *ngIf="parentPortalId"
        mat-menu-item
        class="menu-item"
        (click)="selectGlobalContent()"
      >
        {{ contentText.DialogGlobalTitle }}
      </button>
      <button mat-menu-item class="menu-item" (click)="selectContent()">
        {{ contentText.DialogTitle }}
      </button>
      <button
        mat-menu-item
        *ngIf="templates.length > 0 && !fileOnly && !parentInfo"
        class="use-template menu-item"
        (click)="selectTemplate()"
        [disabled]="!contentEditorEnabled"
      >
        {{ text.UseTemplate }}
      </button>
    </mat-menu>

    <div
      *ngIf="parentInfo"
      class="explanation"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div>
        {{ text.ParentExists }}
      </div>
      <a class="hover-span" (click)="goToParent.emit(parentInfo.id)">
        {{ parentInfo.name }}
      </a>
    </div>
    <div
      *ngIf="hasChildren"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="5px"
    >
      <div>
        {{ text.HasChildren }}
      </div>
      <button
        mat-raised-button
        class="portal-btn btn-primary use-template"
        (click)="manageChildLink.emit()"
        [disabled]="!contentEditorEnabled"
      >
        {{ text.ManageLinks }}
      </button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <button
      *ngIf="fileOnly && !parentInfo && parentPortalId"
      [matMenuTriggerFor]="menuFile"
      mat-raised-button
      class="portal-btn btn-primary"
      [disabled]="!contentEditorEnabled"
    >
      {{ contentText.Actions }}
    </button>
    <mat-menu
      #menuFile="matMenu"
      yPosition="below"
      class="content-edit-menu-panel"
    >
      <button
        *ngIf="parentPortalId"
        mat-menu-item
        class="menu-item"
        (click)="selectGlobalFileContent()"
      >
        {{ contentText.DialogGlobalTitle }}
      </button>
    </mat-menu>
  </div>
</form>
