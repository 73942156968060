<portal-layout #app>
  <portal-toolbar
    class="portal-toolbar"
    [pageTitle]="activePageKey"
    [open]="showSidenav$ | async"
    [clinic]="clinic$ | async"
    [loggedIn]="loggedIn$ | async"
    [text]="sectionText$ | async"
    (openMenu)="openSidenav()"
    (closeMenu)="closeSidenav()"
  >
    <portal-searchbar
      *ngIf="!isGlobalPortal && loggedIn$ | async"
      [text]="sectionText$ | async"
      (gotoPatient)="gotoPatient($event)"
      class="top-searchbar"
    ></portal-searchbar>
    <portal-user-profile
      *ngIf="loggedIn$ | async"
      [user]="user$ | async"
      [isCognito]="isCognito$ | async"
      [text]="sectionText$ | async"
      [isGlobalPortal]="isGlobalPortal"
      (logout)="logout($event)"
    ></portal-user-profile>
  </portal-toolbar>

  <mat-sidenav-container>
    <!-- Main navigation menu -->
    <mat-sidenav
      class="sidebar"
      [opened]="showSidenav$ | async"
      [mode]="'side'"
    >
      <div class="sidebar-inner">
        <!-- Need to add routerLinks etc -->
        <div>
          <portal-nav-item
            *ngFor="let menuItem of menuItems"
            [tempMessage]="tempMessage$"
            [menuItem]="menuItem"
            [text]="sectionText$ | async"
            [activated]="activePageKey === menuItem.key"
            (gotoNavItem)="goToMenuItem($event)"
          >
          </portal-nav-item>
        </div>
        <div
          class="temp-message-container"
          fxFlex="grow"
          fxLayoutAlign="center center"
        >
          <portal-nav-message
            [tempMessage]="tempMessage$ | async"
          ></portal-nav-message>
        </div>
        <portal-integration-container
          *ngIf="!isGlobalPortal"
        ></portal-integration-container>
      </div>
    </mat-sidenav>

    <!-- Main content of page -->
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</portal-layout>
