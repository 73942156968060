import { useApiClient } from '@react/lib/api';
import { ConversationFilters } from '@react/lib/api/types';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { filtersToApi } from '../lib';
import { FilterState } from '../types';

export function useLatestConversationsQuery(filters: FilterState) {
  const apiClient = useApiClient().portal;

  const latestConvoFilters: ConversationFilters = {
    ...filtersToApi(filters),
    isresolved: false,
    isstarred: undefined,
    q: undefined,
  };

  return useQuery(
    [queryCacheKey.LATEST_CONVERSATIONS, latestConvoFilters],
    () => apiClient.fetchConversations({ pageSize: 1, ...latestConvoFilters }),
    {
      refetchInterval: POLLING_INTERVAL_MS,
      refetchIntervalInBackground: true,
    },
  );
}
