import styled from '@emotion/styled';
import { Box, Tabs } from '@material-ui/core';

export const StyledPanel = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  height: 200px;
  width: 250px;
  overflow: hidden;
`;

export const StyledContainer = styled(Tabs)`
  border-bottom: 2px;
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 0.875rem;
  min-height: 48px;
`;

export const StyledIndicator = styled.span`
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0 12px;
`;

export const StyledActionsContainer = styled.div`
  border-top-color: ${({ theme }) => theme.colors.border.default};
  border-top-width: 1px;
  border-top-style: solid;
  padding: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: end;
`;
