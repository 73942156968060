<mat-card>
  <table
    mat-table
    [dataSource]="patients"
    class="patient-list-table"
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="columnTitles.PatientIdentifier">
      <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ text.PatientId }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.PatientIdentifier }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="columnTitles.DateOfBirth">
      <th class="w-25" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ text.DOB }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a>
          {{ element.DateOfBirth | shortDate }}
        </a>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="columnTitles.FirstName">
      <th class="w-25" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ text.FirstName }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a>
          {{ element.FirstName }}
        </a>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="columnTitles.LastName">
      <th class="w-25" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ text.LastName }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.LastName }}</td>
    </ng-container>

    <ng-container [matColumnDef]="columnTitles.PatientAccountCreatedDateUtc">
      <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ text.SyncDate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.PatientAccountCreatedDateUtc | shortDateWithTime }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      class="patient-list-table-row"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="goToPatient(row)"
    ></tr>
  </table>
  <div
    *ngIf="loading"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
  >
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</mat-card>
<div
  intersection-observer
  [observeFirstChildElement]="false"
  observeMargin="200px"
  (observed)="onObserved()"
></div>
