import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import * as fromContent from '../../content/reducers';
import { CognitoWrapperService } from './congito.wrapper.service';
var FileUpload = /** @class */ (function () {
    function FileUpload(_http, _store, _error, _cognito) {
        this._http = _http;
        this._store = _store;
        this._error = _error;
        this._cognito = _cognito;
        this._accepts = {
            image: '.jpg, .jpeg, .png, image/jpeg, image/png',
            file: "\n      .mp4, .mov, .avi,\n      .jpg, .jpeg, .png, image/jpeg, image/png,\n      .pdf, .txt, .doc, .docx, application/pdf,\n      text/plain, application/msword,\n      application/vnd.openxmlformats-officedocument.wordprocessingml.document\n    ",
            video: '.mp4, .mov, .webm, video/mp4, video/quicktime, video/webm'
        };
        this.apiEndpoint$ = of(environment.api.content.endpoint);
        this._s3ProxyEndpoint$ = of(environment.api.s3Proxy.endpoint);
        // s3 (content) store slice set up
        this.bucketRoot$ = this._store.pipe(select(fromContent.getRootAlbumBucket));
        this.idPoolId$ = this._store.pipe(select(fromContent.getIdentityPoolId));
        this.bucketRegion$ = this._store.pipe(select(fromContent.getBucketRegion));
        // auth store slice set up
        this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
        this.userId$ = this._store.pipe(select(fromAuth.getPublicKey));
    }
    FileUpload.prototype.fetchAccepts = function (type) {
        return this._accepts[type];
    };
    FileUpload.prototype.readFileFromBucket = function (_a) {
        var url = _a.url;
        return this.readFileFromBucketWithPath({
            url: url,
            path: 'clinic/read'
        });
    };
    FileUpload.prototype.readProfilePhotoFromBucket = function (_a) {
        var url = _a.url;
        // The signed url returned by this endpoint is cached
        return this.readFileFromBucketWithPath({
            url: url,
            path: 'clinic/profilephoto/read'
        });
    };
    FileUpload.prototype.readFileFromBucketWithPath = function (_a) {
        var _this = this;
        var url = _a.url, path = _a.path;
        return new Promise(function (res, rej) {
            _this._createPresignedReadContentUrl({
                url: url,
                path: path
            })
                .then(function (response) {
                res(response);
            })
                .catch(function (err) {
                rej(err);
            });
        });
    };
    FileUpload.prototype.writeFileToBucket = function (folderType, documentName, file, isPublic, patientId) {
        var _this = this;
        return new Observable(function (observer) {
            _this.writeObserver = observer;
            _this._createPresignedWriteContentUrl({
                folderType: folderType,
                documentName: documentName,
                contentType: file.type,
                isPublic: isPublic,
                patientId: patientId
            })
                .then(function (res) {
                _this._uploadToBucket(res, file, isPublic).subscribe(function (data) {
                    data.body = { Location: res.url.split('?')[0] };
                    _this.writeObserver.next(data);
                }, function (err) {
                    _this.writeObserver.error(new Error("Failed to upload file"));
                }, function () {
                    _this.writeObserver.complete();
                });
            })
                .catch(function (err) {
                _this.writeObserver.error(new Error("Failed to upload file"));
            });
        });
    };
    FileUpload.prototype._uploadToBucket = function (s3Params, file, isPublic) {
        var _this = this;
        var headers = { 'content-type': file.type };
        if (isPublic) {
            headers['x-amz-acl'] = 'public-read';
        }
        var req = new HttpRequest('PUT', s3Params.url, file, {
            headers: new HttpHeaders(headers)
        });
        return this._http.request(req).pipe(map(function (event) { return _this._getEventMessage(event, file); }), last(), map(function (response) { return response; }));
    };
    /**
     * From Angular docs: https://angular.io/guide/http (Listening to progress events)
     */
    FileUpload.prototype._getEventMessage = function (event, file) {
        switch (event.type) {
            case HttpEventType.Sent:
                return {
                    error: null,
                    progress: 0,
                    complete: false,
                    response: null,
                    body: null
                };
            case HttpEventType.UploadProgress:
                // Compute and show the % done:
                var percentDone = Math.round((100 * event.loaded) / event.total);
                return {
                    status: null,
                    error: null,
                    progress: percentDone,
                    complete: false,
                    body: null
                };
            case HttpEventType.ResponseHeader:
                this.status = event.status;
                return {
                    status: event.status,
                    error: null,
                    progress: 100,
                    complete: false,
                    body: null
                };
            case HttpEventType.DownloadProgress:
                return {
                    status: this.status,
                    error: null,
                    progress: 100,
                    complete: false,
                    body: null
                };
            case HttpEventType.Response:
                return {
                    status: event.status,
                    error: null,
                    progress: 100,
                    complete: true,
                    body: event.body
                };
            case HttpEventType.User:
                return {
                    status: 500,
                    error: new Error("Unexpected Response"),
                    progress: 100,
                    complete: true,
                    body: null
                };
            default:
                return event;
        }
    };
    FileUpload.prototype._createPresignedReadContentUrl = function (_a) {
        var _this = this;
        var url = _a.url, path = _a.path;
        if (!path) {
            path = 'clinic/read';
        }
        return new Promise(function (res, rej) {
            _this._callS3Proxy()
                .pipe(switchMap(function (_a) {
                var endpoint = _a[0], options = _a[1];
                var body = {
                    url: url
                };
                return _this._http.post("" + endpoint + path, body, options);
            }))
                .subscribe(function (response) {
                res(response);
            }, function (err) {
                rej('Error authenticating to use AWS');
            });
        });
    };
    FileUpload.prototype._createPresignedWriteContentUrl = function (_a) {
        var _this = this;
        var folderType = _a.folderType, documentName = _a.documentName, contentType = _a.contentType, isPublic = _a.isPublic, patientId = _a.patientId;
        var folderPath;
        var pid;
        switch (folderType) {
            case 'content':
                folderPath = 'clinic';
                pid = null;
                break;
            case 'profilephoto':
                folderPath = 'clinic/profilephoto';
                pid = patientId;
                break;
            default:
                throw new Error('Unsupported folder type');
        }
        return new Promise(function (res, rej) {
            _this._callS3Proxy()
                .pipe(switchMap(function (_a) {
                var endpoint = _a[0], options = _a[1];
                var body = {
                    isPublic: isPublic,
                    filename: documentName,
                    contentType: contentType,
                    patientId: pid
                };
                return _this._http.post("" + endpoint + folderPath + "/write", body, options);
            }))
                .subscribe(function (response) {
                res(response);
            }, function (err) {
                _this._error.handleError({ originalError: err });
                rej('Error authenticating to use AWS');
            });
        });
    };
    FileUpload.prototype._callS3Proxy = function (args) {
        if (args === void 0) { args = []; }
        return combineLatest.apply(void 0, [this.clinicId$,
            this._s3ProxyEndpoint$,
            this._cognito.getAuthSession()].concat(args)).pipe(switchMap(function (_a) {
            var clinicId = _a[0], endpoint = _a[1], cognitoUserSession = _a[2], rest = _a.slice(3);
            var defaultHttpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: cognitoUserSession.getIdToken().jwtToken,
                    'x-salve-clinic-token': clinicId
                })
            };
            return of([endpoint, defaultHttpOptions].concat(rest));
        }));
    };
    return FileUpload;
}());
export { FileUpload };
