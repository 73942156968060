import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';
import {
  CallEnd,
  Mic,
  MicOff,
  Videocam,
  VideocamOff
} from '@material-ui/icons';

export const PageContainer = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #1f2124;
`;

export const ParticipantsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

export const NooneHereText = styled(Typography)`
  color: #686868;
`;

export const CallEndIcon = styled(CallEnd)`
  font-size: 38px;
  color: #ffffff;
`;

export const MicIcon = styled(Mic)`
  font-size: 28px;
  color: #3a3a3a;
`;
export const MicOffIcon = styled(MicOff)`
  font-size: 28px;
  color: #3a3a3a;
`;
export const VideocamIcon = styled(Videocam)`
  font-size: 28px;
  color: #3a3a3a;
`;
export const VideocamOffIcon = styled(VideocamOff)`
  font-size: 28px;
  color: #3a3a3a;
`;

export const VideocamButton = styled(IconButton)`
  background-color: #ffffff;
  margin: 16px;
  &:hover {
    background-color: #ffffff;
  }
`;

export const MicButton = styled(IconButton)`
  background-color: #ffffff;
  margin: 16px;
  &:hover {
    background-color: #ffffff;
  }
`;
export const CallEndButton = styled(IconButton)`
  background-color: #d0021b;
  margin: 16px;
  &:hover {
    background-color: #d0021b;
  }
`;

export const VideoActionBarContainer = styled('div')`
  height: 100px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
