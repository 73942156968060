var ScriptInjectorService = /** @class */ (function () {
    function ScriptInjectorService() {
        this.scripts = [];
    }
    ScriptInjectorService.prototype.loadScript = function (name, src) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Check if the script is already loaded
            var existingScript = _this.scripts.find(function (s) { return s.name === name; });
            if (existingScript) {
                if (existingScript.loaded) {
                    resolve();
                }
                else {
                    // If the script is in the process of loading, wait for it
                    existingScript['promise']
                        .then(function () { return resolve(); })
                        .catch(function (error) { return reject(error); });
                }
                return;
            }
            var scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.src = src;
            scriptElement.async = true;
            var script = {
                name: name,
                src: src,
                loaded: false
            };
            scriptElement.onload = function () {
                script.loaded = true;
                resolve();
            };
            scriptElement.onerror = function (event, source, lineno, colno, error) {
                console.error(error);
                reject(new Error("Failed to load script: " + name));
            };
            document.body.appendChild(scriptElement);
            _this.scripts.push(script);
        });
    };
    return ScriptInjectorService;
}());
export { ScriptInjectorService };
