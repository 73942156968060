import { select, Store } from '@ngrx/store';
import get from 'lodash/get';
import { map } from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import { NavigationService } from './navigation.service';
var FeatureGuard = /** @class */ (function () {
    function FeatureGuard(_navigationService, _store) {
        this._navigationService = _navigationService;
        this._store = _store;
    }
    FeatureGuard.prototype.canActivate = function (route) {
        var _this = this;
        var optionsPath = route.data.optionsPath;
        return this._store.pipe(select(fromAuth.getClinicOptions), map(function (clinicOptions) {
            var enabled = !!get(clinicOptions, optionsPath);
            if (!enabled) {
                _this._navigationService.navigate(['/dashboard']);
            }
            return enabled;
        }));
    };
    return FeatureGuard;
}());
export { FeatureGuard };
