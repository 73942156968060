import { MatDialog, MatDialogRef } from '@angular/material';
var DialogWithDismissComponent = /** @class */ (function () {
    function DialogWithDismissComponent(dialogRef, dialog, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        if (data.message) {
            this.message = data.message;
        }
    }
    DialogWithDismissComponent.prototype.dismiss = function () {
        this.dialogRef.close(false);
    };
    return DialogWithDismissComponent;
}());
export { DialogWithDismissComponent };
