
    <portal-view-children
      [text]="text$ | async"
      [children]="children$ | async"
      [childSelected]="data.contentId"
      [parentInfo]="parentInfo$ | async"
      [loadingChildren]="loadingChildren$ | async"
      [deletingLink]="deletingLink$ | async"
      [deleteLinkError]="deleteLinkError$ | async"
      [deleteLinkSuccess]="deleteLinkSuccess$ | async"
      [deletingLinks]="deletingLinks$ | async"
      [deleteLinksError]="deleteLinksError$ | async"
      [deleteLinksSuccess]="deleteLinksSuccess$ | async"
      (deleteLinks)="handleLinkDeletion($event)"
      (cancel)="closeModal()"
    ></portal-view-children>
  