import * as tslib_1 from "tslib";
import { Box, CircularProgress, Tab } from '@material-ui/core';
import { Button } from '@react/components/Button';
import { InfiniteList, List } from '@react/components/filter/list';
import TabPanel from '@react/components/filter/tabPanel';
import { SIDERecipeEventType, SIDEStatus } from '@react/lib/api/enum';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useState } from 'react';
import { useAppointmentTypesQuery, useDrugTypesQuery, } from '../../../../admin/hooks';
import { LoaderWrapper, StyledActionsContainer, StyledSearchBar, StyledTabs, } from './index.styled';
var RecipeListFilters = function (_a) {
    var filters = _a.filters, apply = _a.apply;
    var _b = useState(filters), localFilters = _b[0], setLocalFilters = _b[1];
    var _c = useState(0), selectedTab = _c[0], setSelectedTab = _c[1];
    var _d = useState(''), apptSearchInputValue = _d[0], setApptSearchInputValue = _d[1];
    var apptSearchTerm = useDebouncedState(apptSearchInputValue, 500);
    var _e = useState(''), drugSearchInputValue = _e[0], setDrugSearchInputValue = _e[1];
    var drugSearchTerm = useDebouncedState(drugSearchInputValue, 500);
    var _f = useAppointmentTypesQuery({ searchTerm: apptSearchTerm }), appointmentTypes = _f.data, isApptTypesLoading = _f.isLoading, fetchNextApptTypes = _f.fetchNextPage, hasNextPageForApptTypes = _f.hasNextPage, isFetchingNextApptTypes = _f.isFetchingNextPage;
    var _g = useDrugTypesQuery({ searchTerm: drugSearchTerm }), drugTypes = _g.data, isDrugTypesLoading = _g.isLoading, fetchNextDrugTypes = _g.fetchNextPage, hasNextPageForDrugTypes = _g.hasNextPage, isFetchingNextDrugTypes = _g.isFetchingNextPage;
    var t = useTranslations().t;
    function handleChange(event, value) {
        setSelectedTab(value);
    }
    function loadMoreAppointmentTypes() {
        var shouldFetchNextPage = hasNextPageForApptTypes &&
            !isApptTypesLoading &&
            !isFetchingNextApptTypes;
        if (shouldFetchNextPage) {
            fetchNextApptTypes();
        }
    }
    function loadMoreDrugTypes() {
        var shouldFetchNextPage = hasNextPageForDrugTypes &&
            !isDrugTypesLoading &&
            !isFetchingNextDrugTypes;
        if (shouldFetchNextPage) {
            fetchNextDrugTypes();
        }
    }
    function setEventType(key) {
        var val = localFilters.eventType === key ? undefined : key;
        setLocalFilters(tslib_1.__assign({}, localFilters, { eventType: val, drugTypes: undefined, appointmentTypes: undefined }));
    }
    function setSideStatus(key) {
        var status = Array.isArray(localFilters.status)
            ? Array.from(localFilters.status)
            : [];
        if (status.includes(key)) {
            status = Array.from(status).filter(function (item) { return item !== key; });
        }
        else {
            status.push(key);
        }
        setLocalFilters(tslib_1.__assign({}, localFilters, { status: status }));
    }
    function setAppointmentTypes(type) {
        var typeList = Array.isArray(localFilters.appointmentTypes)
            ? Array.from(localFilters.appointmentTypes)
            : [];
        var data = typeList.find(function (item) { return item.value === type.id; });
        if (data) {
            typeList = Array.from(typeList).filter(function (item) { return item.value !== type.id; });
        }
        else {
            typeList.push({
                key: type.name || '',
                value: type.id,
            });
        }
        setLocalFilters(tslib_1.__assign({}, localFilters, { drugTypes: undefined, eventType: SIDERecipeEventType.Appointment, appointmentTypes: typeList }));
    }
    function setDrugTypes(type) {
        var typeList = Array.isArray(localFilters.drugTypes)
            ? Array.from(localFilters.drugTypes)
            : [];
        var data = typeList.find(function (item) { return item.value === type.id; });
        if (data) {
            typeList = Array.from(typeList).filter(function (item) { return item.value !== type.id; });
        }
        else {
            typeList.push({
                key: type.name || '',
                value: type.id,
            });
        }
        setLocalFilters(tslib_1.__assign({}, localFilters, { drugTypes: typeList, eventType: SIDERecipeEventType.Medication, appointmentTypes: undefined }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(StyledTabs, { value: selectedTab, onChange: handleChange, scrollButtons: "auto", variant: "scrollable" },
                React.createElement(Tab, { role: "tab", label: t.Admin.SIDE.FilterTabEventType }),
                React.createElement(Tab, { role: "tab", label: t.Admin.SIDE.FilterTabStatus }),
                React.createElement(Tab, { role: "tab", label: t.Admin.SIDE.FilterTabAppointmentType }),
                React.createElement(Tab, { role: "tab", label: t.Admin.SIDE.FilterTabDrugType }))),
        React.createElement(TabPanel, { value: selectedTab, index: 0 },
            React.createElement(List, { data: Object.keys(SIDERecipeEventType), isActive: function (item) { return item === localFilters.eventType; }, id: "EventTypeFilters", onClick: function (item) {
                    return setEventType(SIDERecipeEventType[item]);
                }, getLabel: function (key) {
                    return t.Admin.SIDE[key + "Event"];
                } })),
        React.createElement(TabPanel, { value: selectedTab, index: 1 },
            React.createElement(List, { data: Object.keys(SIDEStatus), isActive: function (item) {
                    return localFilters.status
                        ? localFilters.status.includes(SIDEStatus[item])
                        : false;
                }, id: "SideStatusFilters", onClick: function (item) {
                    return setSideStatus(SIDEStatus[item]);
                }, getLabel: function (key) {
                    return t.Admin.SIDE[key + "Status"];
                } })),
        React.createElement(TabPanel, { value: selectedTab, index: 2 },
            React.createElement(StyledSearchBar, { value: apptSearchInputValue, onChange: setApptSearchInputValue, placeholder: t.Admin.SIDE.FilterApptSearchBarPlaceholder }),
            isApptTypesLoading && (React.createElement(LoaderWrapper, { "data-testid": "AppointmentType-Loader" },
                React.createElement(CircularProgress, null))),
            appointmentTypes && appointmentTypes.pages && !isApptTypesLoading && (React.createElement(InfiniteList, { data: appointmentTypes.pages, isActive: function (item) {
                    var apptType = Array.isArray(localFilters.appointmentTypes)
                        ? localFilters.appointmentTypes.find(function (type) { return type.value === item.id; })
                        : null;
                    return apptType ? true : false;
                }, id: "SideAppointmentTypeFilters", loading: isFetchingNextApptTypes, onClick: function (item) { return setAppointmentTypes(item); }, getLabel: function (item) { return item.name || ''; }, loadMore: loadMoreAppointmentTypes }))),
        React.createElement(TabPanel, { value: selectedTab, index: 3 },
            React.createElement(StyledSearchBar, { value: drugSearchInputValue, onChange: setDrugSearchInputValue, placeholder: t.Admin.SIDE.FilterDrugSearchBarPlaceholder }),
            isDrugTypesLoading && (React.createElement(LoaderWrapper, { "data-testid": "DrugTypes-Loader" },
                React.createElement(CircularProgress, null))),
            drugTypes && drugTypes.pages && !isDrugTypesLoading && (React.createElement(InfiniteList, { data: drugTypes.pages, isActive: function (item) {
                    var drugType = Array.isArray(localFilters.drugTypes)
                        ? localFilters.drugTypes.find(function (type) { return type.value === item.id; })
                        : null;
                    return drugType ? true : false;
                }, id: "SideDrugTypeFilters", loading: isFetchingNextDrugTypes, onClick: function (item) { return setDrugTypes(item); }, getLabel: function (item) { return item.name || ''; }, loadMore: loadMoreDrugTypes }))),
        React.createElement(StyledActionsContainer, null,
            React.createElement(Button, { "data-testid": "btn_apply", color: "primary", variant: "contained", onClick: function () { return apply(localFilters); } }, t.Admin.SIDE.FilterApply))));
};
var ɵ0 = RecipeListFilters;
export default RecipeListFilters;
export { ɵ0 };
