import * as tslib_1 from "tslib";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, ListItem, ListItemText, Tabs } from '@material-ui/core';
import { SearchBar } from '@react/components';
export var StyledTabs = styled(Tabs)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  border-bottom: 1px solid #cfcfcf;\n  width: 300px;\n  min-height: 32px;\n\n  .MuiTab-root {\n    padding: 0;\n    padding-left: 8px;\n    padding-right: 8px;\n    min-height: 32px;\n    min-width: auto;\n  }\n"], ["\n  border-bottom: 1px solid #cfcfcf;\n  width: 300px;\n  min-height: 32px;\n\n  .MuiTab-root {\n    padding: 0;\n    padding-left: 8px;\n    padding-right: 8px;\n    min-height: 32px;\n    min-width: auto;\n  }\n"])));
export var StyledTabPanel = styled(Box)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  padding: ", "px;\n  height: 220px;\n  overflow: auto;\n\n  .MuiList-root {\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n"], ["\n  padding: ", "px;\n  height: 220px;\n  overflow: auto;\n\n  .MuiList-root {\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledActionsContainer = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  border-top: 1px solid #cfcfcf;\n  padding: ", "px;\n  display: flex;\n  justify-content: flex-end;\n\n  .MuiButton-root {\n    height: 28px;\n  }\n"], ["\n  border-top: 1px solid #cfcfcf;\n  padding: ", "px;\n  display: flex;\n  justify-content: flex-end;\n\n  .MuiButton-root {\n    height: 28px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1.5);
});
export var StyledListItem = styled(ListItem)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  padding-top: 8px;\n  padding-bottom: 8px;\n  cursor: pointer;\n  display: flex;\n  gap: ", "px;\n\n  &.selected {\n    color: ", ";\n  }\n\n  .MuiListItemText-root {\n    flex: none;\n  }\n"], ["\n  padding-top: 8px;\n  padding-bottom: 8px;\n  cursor: pointer;\n  display: flex;\n  gap: ", "px;\n\n  &.selected {\n    color: ", ";\n  }\n\n  .MuiListItemText-root {\n    flex: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var boldStyle = function () { return css(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"]))); };
var ɵ0 = boldStyle;
export var StyledListItemText = styled(ListItemText)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  span {\n    ", "\n  }\n"], ["\n  span {\n    ", "\n  }\n"])), function (_a) {
    var weight = _a.weight;
    return weight === 'bold' && boldStyle;
});
export var StyledSearchBar = styled(SearchBar)(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n  height: 32px;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n  height: 32px;\n"])));
export { ɵ0 };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
