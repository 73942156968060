import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

export interface InvitePartnerData {
  title: string;
  message1?: string;
  message2?: string;
  message3?: string;
  message4?: string;
  text: {
    Cancel: string;
    Confirm: string;
  };
}

@Component({
  selector: 'portal-invite-partner',
  templateUrl: './invite-partner.component.html',
  styleUrls: ['./invite-partner.component.scss'],
})
export class InvitePartnerComponent {
  constructor(
    public dialogRef: MatDialogRef<InvitePartnerComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: InvitePartnerData,
  ) {}

  onYesClick() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
