import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { List, ListItem, ListItemText } from '@material-ui/core';
import SearchBar from './SearchBar';

export const StyledWrapper = styled.div`
  width: 240px;
`;

export const StyledList = styled(List)`
  max-height: 200px;
  width: 240px;
  overflow-y: auto;
`;

export const StyledListItem = styled(ListItem)`
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;

  &.selected {
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiListItemText-root {
    flex: none;
  }
`;

export const StyledSearchBar = styled(SearchBar)`
  position: sticky;
  top: 0;
  z-index: 30;
  height: 32px;
`;

type Props = React.ComponentProps<typeof ListItemText> & {
  weight: string;
};

const boldStyle = () => css`
  font-weight: bold;
`;

export const StyledListItemText = styled(ListItemText)<Props>`
  span {
    ${({ weight }) => weight === 'bold' && boldStyle}
  }
`;

export const LoadingWrapper = styled.div`
  padding: 20px 0;
`;
