import { Action } from '@ngrx/store';
import { UpdateVideoCall } from 'src/app/video-calls/models/UpdateVideoCall';
import { TabsState } from '../models/TabsState';
import { ApiVideoCall, VideoCallEffectsCompletedResponse } from '../responses';

export enum VideoCallActionTypes {
  UpdateVideoCall = '[VideoCalls] Update',
  LoadVideoCalls = '[VideoCalls] Get All',
  ActivateListFilter = '[VideoCalls] Activate List Filter',
  SetListFilter = '[VideoCalls] Set List Filter',
  DeactivateListFilter = '[VideoCalls] Deactivate List Filter',
  SetCurrentTab = '[VideoCalls] Set Current Tab',
  DeleteVideoCall = '[VideoCalls] Delete',
  ResetVideoCallEffects = '[VideoCalls] Reset',
  EffectsCompleted = '[VideoCalls] Effects Completed',
  UpdateList = '[VideoCalls] Update List',
  Join = '[VideoCalls] Join',
  InvitePartnerToVideoCall = '[VideoCalls] Invite Partner',
  UnInvitePartnerToVideoCall = '[VideoCalls] Uninvite Partner',
  InvitePartnerInCallToVideoCall = '[VideoCalls] Invite Partner In Call',
  UnInvitePartnerInCallToVideoCall = '[VideoCalls] Uninvite Partner In Call',
  InviteInCallSuccess = '[VideoCalls] Invite In Call Success',
  UnInviteInCallSuccess = '[VideoCalls] UnInvite In Call Success',
}

export class UpdateVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.UpdateVideoCall;
  constructor(public payload: UpdateVideoCall) {}
}

export class SetListFilterAction implements Action {
  readonly type = VideoCallActionTypes.SetListFilter;
  constructor(public payload: string) {}
}

export class SetCurrentTabAction implements Action {
  readonly type = VideoCallActionTypes.SetCurrentTab;
  constructor(public payload: TabsState) {}
}
export class ResetEffectsStateAction implements Action {
  readonly type = VideoCallActionTypes.ResetVideoCallEffects;
}

export class DeleteVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.DeleteVideoCall;
  constructor(public payload: { id: number }) {}
}

export class InvitePartnerToVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.InvitePartnerToVideoCall;
  constructor(public payload: UpdateVideoCall) {}
}

export class UnInvitePartnerToVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.UnInvitePartnerToVideoCall;
  constructor(public payload: UpdateVideoCall) {}
}

export class InvitePartnerInCallToVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.InvitePartnerInCallToVideoCall;
  constructor(public payload: UpdateVideoCall) {}
}

export class UnInvitePartnerInCallToVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.UnInvitePartnerInCallToVideoCall;
  constructor(public payload: UpdateVideoCall) {}
}

export class EffectsCompletedAction implements Action {
  readonly type = VideoCallActionTypes.EffectsCompleted;
  constructor(public payload: VideoCallEffectsCompletedResponse) {}
}

export class UpdateListAction implements Action {
  readonly type = VideoCallActionTypes.UpdateList;
  constructor(
    public payload: {
      videoCall: ApiVideoCall;
      isNew: boolean;
      joinedVideoCallId: number;
      inCallInviteFlag: boolean;
    },
  ) {}
}

export class InviteInCallSuccessAction implements Action {
  readonly type = VideoCallActionTypes.InviteInCallSuccess;
  constructor(
    public payload: {
      inCallInviteFlag: boolean;
    },
  ) {}
}

export class UnInviteInCallSuccessAction implements Action {
  readonly type = VideoCallActionTypes.UnInviteInCallSuccess;
  constructor(
    public payload: {
      inCallInviteFlag: boolean;
    },
  ) {}
}

export class JoinVideoCallAction implements Action {
  readonly type = VideoCallActionTypes.Join;
  constructor(
    public payload: {
      videoCall: ApiVideoCall;
      partnerInviteEnabled: boolean;
      videoCallsInvitePartner: Record<string, string>;
    },
  ) {}
}

export type VideoCallActionsUnion =
  | UpdateVideoCallAction
  | UpdateListAction
  | SetListFilterAction
  | SetCurrentTabAction
  | DeleteVideoCallAction
  | ResetEffectsStateAction
  | EffectsCompletedAction
  | JoinVideoCallAction
  | InvitePartnerToVideoCallAction
  | UnInvitePartnerToVideoCallAction
  | InvitePartnerInCallToVideoCallAction
  | UnInvitePartnerInCallToVideoCallAction
  | InviteInCallSuccessAction
  | UnInviteInCallSuccessAction;
