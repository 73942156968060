import {
  PatientActionsUnion,
  PatientActionTypes,
} from '../actions/patient.actions';

export interface State {
  patientLoading: boolean;
  filterActive: boolean;
  filter: string;
  activeSection: string;
}

export const initialState: State = {
  patientLoading: true,
  filterActive: false,
  filter: '',
  activeSection: 'patientInformation',
};

export function reducer(
  state = initialState,
  action: PatientActionsUnion,
): State {
  switch (action.type) {
    case PatientActionTypes.LoadingPatientInformation: {
      return {
        ...state,
        patientLoading: true,
      };
    }
    case PatientActionTypes.LoadedPatientInformation: {
      return {
        ...state,
        patientLoading: false,
      };
    }
    case PatientActionTypes.ActivatePatientListFilter: {
      return {
        ...state,
        filterActive: true,
      };
    }
    case PatientActionTypes.DeactivatePatientListFilter: {
      return {
        ...state,
        filterActive: false,
      };
    }
    case PatientActionTypes.SetPatientListFilter: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case PatientActionTypes.ChangeActiveSection: {
      return {
        ...state,
        activeSection: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const patientIsLoading = (state: State) => state.patientLoading;
export const patientListFiltered = (state: State) => state.filterActive;
export const getPatientListFilter = (state: State) => state.filter;
export const getActiveSection = (state: State) => state.activeSection;
