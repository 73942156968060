import { useApiClient } from '@react/lib/api';
import {
  CreateMessageTemplateRequest,
  UpdateAppointmentTypeRequest,
  UpdateMessageTemplateRequest,
} from '@react/lib/api/types';
import * as Sentry from '@sentry/browser';
import { useRef, useState } from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { DownloadLists, IDownloadHookParams, IDownloadHookRes } from '../types';

export function useAppointmentTypesQuery({
  searchTerm,
}: {
  searchTerm: string;
}) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    ['appointmentTypes', { searchTerm }],
    ({ pageParam }: { pageParam?: number }) =>
      apiClient.fetchAppointmentTypes({ pageParam, query: searchTerm }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    },
  );
}

export function useAppointmentTypeQuery({ id }: { id: number }) {
  const apiClient = useApiClient().portal;

  return useQuery(['appointmentType', id], () =>
    apiClient.fetchAppointmentTypeDetails({ id }),
  );
}

export function useAppointmentTypeMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    (args: UpdateAppointmentTypeRequest) =>
      apiClient.updateAppointmentTypeDetails(args),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('appointmentTypes');
        queryClient.setQueryData(['appointmentType', data.data.id], data);
      },
    },
  );
}

export function useMessageTemplateQuery({ id }: { id: number }) {
  const apiClient = useApiClient().portal;

  return useQuery(['messageTemplate', id], () =>
    apiClient.fetchMessageTemplate({ id }),
  );
}

export function useMessageTemplatesMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    (args: CreateMessageTemplateRequest | UpdateMessageTemplateRequest) => {
      return 'id' in args && args.id != null
        ? apiClient.updateMessageTemplate(args)
        : apiClient.createMessageTemplate(args);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('messageTemplates');
        queryClient.setQueryData(['messageTemplate', data.data.id], data);
      },
    },
  );
}

export function useDeleteMessageTemplateMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation((id: number) => apiClient.deleteMessageTemplate({ id }), {
    onSuccess: (data, id) => {
      queryClient.invalidateQueries('messageTemplates');
      queryClient.removeQueries(['messageTemplate', id]);
    },
  });
}

export function useMessageTemplateVariablesQuery({ enabled = true } = {}) {
  const apiClient = useApiClient().portal;

  return useQuery(
    ['messageTemplateVariables'],
    () => apiClient.fetchMessageTemplateVariables(),
    { enabled },
  );
}

export function useExportAdminList({
  type,
  onDownloadSuccess,
  onDownloadFailure,
}: IDownloadHookParams): IDownloadHookRes {
  const apiClient = useApiClient().portal;

  const ref = useRef<HTMLAnchorElement | null>(null);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const request = async () => {
    switch (type) {
      case DownloadLists.SIDERecipes: {
        return apiClient.exportSIDERecipes();
      }
      case DownloadLists.Users: {
        return apiClient.exportAdminUsers();
      }
      default: {
        throw new Error(`No request has been found for type: ${type}`);
      }
    }
  };

  async function download(fileNameSuffix: string, hideDataName?: boolean) {
    try {
      const { data } = await request();
      const blob = new Blob([data.csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      setFileUrl(url);
      setFileName(
        hideDataName ? fileNameSuffix : `${data.filename} ${fileNameSuffix}`,
      );
      if (ref.current) {
        ref.current.click();
      } else {
        throw new Error('ref is required');
      }
      onDownloadSuccess();
      URL.revokeObjectURL(url);
    } catch (error) {
      Sentry.captureException(error);
      onDownloadFailure();
    }
  }

  return { download, fileUrl, ref, fileName };
}

export * from './side';
export * from './medications';
