import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import get from 'lodash/get';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromAuth from '../../auth/reducers';
import { NavigationService } from './navigation.service';

@Injectable()
export class FeatureGuard implements CanActivate {
  constructor(
    private _navigationService: NavigationService,
    private _store: Store<fromAuth.State>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const optionsPath = route.data.optionsPath as string;
    return this._store.pipe(
      select(fromAuth.getClinicOptions),
      map((clinicOptions) => {
        const enabled = !!get(clinicOptions, optionsPath);
        if (!enabled) {
          this._navigationService.navigate(['/dashboard']);
        }
        return enabled;
      }),
    );
  }
}
