import { EventEmitter, } from '@angular/core';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent() {
        this.open = true;
        this.openMenu = new EventEmitter();
        this.closeMenu = new EventEmitter();
    }
    return ToolbarComponent;
}());
export { ToolbarComponent };
