import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { ChevronLeft } from '@material-ui/icons';
export var StyledChevron = styled(ChevronLeft)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  transform: ", ";\n  color: ", ";\n"], ["\n  transform: ", ";\n  color: ", ";\n"])), function (_a) {
    var open = _a.open;
    return (open ? 'rotate(90deg)' : 'rotate(270deg)');
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text.primary;
});
export var StyledTitle = styled.span(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var StyledLink = styled.a(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  display: block;\n  width: 100%;\n  max-width: 300px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n"], ["\n  display: block;\n  width: 100%;\n  max-width: 300px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
