import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Editable } from 'slate-react';
import { Button } from './Button';
import { CharacterCount } from './CharacterCount';
export var EditorContainer = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  border: ", ";\n  border-radius: 4px;\n  box-sizing: content-box;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  &:hover,\n  &:focus-within {\n    border: ", ";\n  }\n"], ["\n  border: ",
    ";\n  border-radius: 4px;\n  box-sizing: content-box;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  &:hover,\n  &:focus-within {\n    border: ",
    ";\n  }\n"])), function (_a) {
    var error = _a.error, theme = _a.theme;
    return error
        ? "1px solid " + theme.colors.error
        : "1px solid " + theme.colors.border.default;
}, function (_a) {
    var error = _a.error, theme = _a.theme;
    return error
        ? "1px solid " + theme.colors.error
        : "1px solid " + theme.colors.primary;
});
export var EditorStyled = styled(Editable)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  overflow-y: auto;\n  min-height: ", "px;\n  max-height: ", "px;\n  padding: ", "px;\n"], ["\n  flex: 1;\n  overflow-y: auto;\n  min-height: ", "px;\n  max-height: ", "px;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(40);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(80);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var Toolbar = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  background-color: #f7f7fb;\n  border-radius: 0 0 4px 4px;\n  display: flex;\n  height: ", "px;\n"], ["\n  align-items: center;\n  background-color: #f7f7fb;\n  border-radius: 0 0 4px 4px;\n  display: flex;\n  height: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(5);
});
export var ParagraphSpan = styled.p(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  margin: ", "px;\n"], ["\n  margin: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(0.25);
});
export var VariablesButton = styled(Button)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  font-weight: normal;\n  margin-left: ", "px;\n  margin-right: auto;\n"], ["\n  font-weight: normal;\n  margin-left: ", "px;\n  margin-right: auto;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledCharacterCount = styled(CharacterCount)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  margin: ", ";\n"], ["\n  margin: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(0.5, 0.5, 0.5, 'auto');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
