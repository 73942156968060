// Action Types
// =============================================
export var AuthActionTypes;
(function (AuthActionTypes) {
    AuthActionTypes["GetClinicId"] = "[Auth] Get Clinic Id";
    AuthActionTypes["GetClinicIdSuccess"] = "[Auth] Get Clinic Id Success";
    AuthActionTypes["GetClinicIdError"] = "[Auth] Get Clinic Id Failure";
    AuthActionTypes["EnableForm"] = "[Auth] Enable Form";
    AuthActionTypes["CoreLogin"] = "[Auth] Core Login";
    AuthActionTypes["CoreLoginSuccess"] = "[Auth] Core Login Success";
    AuthActionTypes["CoreLoginFailure"] = "[Auth] Core Login Failure";
    AuthActionTypes["CompletePassword"] = "[Auth] Core Complete Password";
    AuthActionTypes["CompletePasswordSuccess"] = "[Auth] Core Complete Password Success";
    AuthActionTypes["CompletePasswordFailure"] = "[Auth] Core Complete Password Failure";
    AuthActionTypes["Logout"] = "[Auth] Logout";
    AuthActionTypes["LogoutSuccess"] = "[Auth] Logout Success";
    AuthActionTypes["LogoutFailure"] = "[Auth] Logout Failure";
    AuthActionTypes["LoginRedirect"] = "[Auth] Login Redirect";
    AuthActionTypes["ClearCodeGenerated"] = "[Auth] Set Code Generated";
    AuthActionTypes["GenerateResetCode"] = "[Auth] Generate Reset Code";
    AuthActionTypes["GenerateResetCodeSuccess"] = "[Auth] Generate Reset Code Success";
    AuthActionTypes["GenerateResetCodeFailure"] = "[Auth] Generate Reset Code Failure";
    AuthActionTypes["ResetPassword"] = "[Auth] Reset Password Code";
    AuthActionTypes["ResetPasswordSuccess"] = "[Auth] Reset Password Code Success";
    AuthActionTypes["ResetPasswordFailure"] = "[Auth] Reset Password Code Failure";
    AuthActionTypes["TempSuccess"] = "[Auth] Temp Password Success";
    AuthActionTypes["ClearLoginError"] = "[Auth] Clear Login Error";
    AuthActionTypes["OTPRequired"] = "[Auth] OTP Required";
    AuthActionTypes["OTPRejected"] = "[Auth] OTP Rejected";
    AuthActionTypes["VerifyOTP"] = "[Auth] Verify OTP";
    AuthActionTypes["OTPLoginFailure"] = "[Auth] OTP Login Failed";
})(AuthActionTypes || (AuthActionTypes = {}));
// Action Creators
// =============================================
var TempSuccess = /** @class */ (function () {
    function TempSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.TempSuccess;
    }
    return TempSuccess;
}());
export { TempSuccess };
var GetClinicId = /** @class */ (function () {
    function GetClinicId() {
        this.type = AuthActionTypes.GetClinicId;
    }
    return GetClinicId;
}());
export { GetClinicId };
var EnableForm = /** @class */ (function () {
    function EnableForm() {
        this.type = AuthActionTypes.EnableForm;
    }
    return EnableForm;
}());
export { EnableForm };
var GetClinicIdSuccess = /** @class */ (function () {
    function GetClinicIdSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.GetClinicIdSuccess;
    }
    return GetClinicIdSuccess;
}());
export { GetClinicIdSuccess };
var GetClinicIdError = /** @class */ (function () {
    function GetClinicIdError(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.GetClinicIdError;
    }
    return GetClinicIdError;
}());
export { GetClinicIdError };
var CoreLogin = /** @class */ (function () {
    function CoreLogin(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.CoreLogin;
    }
    return CoreLogin;
}());
export { CoreLogin };
var CoreLoginSuccess = /** @class */ (function () {
    function CoreLoginSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.CoreLoginSuccess;
    }
    return CoreLoginSuccess;
}());
export { CoreLoginSuccess };
var CoreLoginFailure = /** @class */ (function () {
    function CoreLoginFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.CoreLoginFailure;
    }
    return CoreLoginFailure;
}());
export { CoreLoginFailure };
var GenerateResetCode = /** @class */ (function () {
    function GenerateResetCode(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.GenerateResetCode;
    }
    return GenerateResetCode;
}());
export { GenerateResetCode };
var GenerateResetCodeSuccess = /** @class */ (function () {
    function GenerateResetCodeSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.GenerateResetCodeSuccess;
    }
    return GenerateResetCodeSuccess;
}());
export { GenerateResetCodeSuccess };
var GenerateResetCodeFailure = /** @class */ (function () {
    function GenerateResetCodeFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.GenerateResetCodeFailure;
    }
    return GenerateResetCodeFailure;
}());
export { GenerateResetCodeFailure };
var ClearCodeGenerated = /** @class */ (function () {
    function ClearCodeGenerated() {
        this.type = AuthActionTypes.ClearCodeGenerated;
    }
    return ClearCodeGenerated;
}());
export { ClearCodeGenerated };
var ResetPassword = /** @class */ (function () {
    function ResetPassword(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.ResetPassword;
    }
    return ResetPassword;
}());
export { ResetPassword };
var ResetPasswordSuccess = /** @class */ (function () {
    function ResetPasswordSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.ResetPasswordSuccess;
    }
    return ResetPasswordSuccess;
}());
export { ResetPasswordSuccess };
var ResetPasswordFailure = /** @class */ (function () {
    function ResetPasswordFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.ResetPasswordFailure;
    }
    return ResetPasswordFailure;
}());
export { ResetPasswordFailure };
var CompletePassword = /** @class */ (function () {
    function CompletePassword(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.CompletePassword;
    }
    return CompletePassword;
}());
export { CompletePassword };
var CompletePasswordSuccess = /** @class */ (function () {
    function CompletePasswordSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.CompletePasswordSuccess;
    }
    return CompletePasswordSuccess;
}());
export { CompletePasswordSuccess };
var CompletePasswordFailure = /** @class */ (function () {
    function CompletePasswordFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.CompletePasswordFailure;
    }
    return CompletePasswordFailure;
}());
export { CompletePasswordFailure };
var LoginRedirect = /** @class */ (function () {
    function LoginRedirect() {
        this.type = AuthActionTypes.LoginRedirect;
    }
    return LoginRedirect;
}());
export { LoginRedirect };
var Logout = /** @class */ (function () {
    function Logout(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.Logout;
    }
    return Logout;
}());
export { Logout };
var LogoutSuccess = /** @class */ (function () {
    function LogoutSuccess(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.LogoutSuccess;
    }
    return LogoutSuccess;
}());
export { LogoutSuccess };
var LogoutFailure = /** @class */ (function () {
    function LogoutFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.LogoutFailure;
    }
    return LogoutFailure;
}());
export { LogoutFailure };
var ClearLoginError = /** @class */ (function () {
    function ClearLoginError() {
        this.type = AuthActionTypes.ClearLoginError;
    }
    return ClearLoginError;
}());
export { ClearLoginError };
var OTPRequired = /** @class */ (function () {
    function OTPRequired(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.OTPRequired;
    }
    return OTPRequired;
}());
export { OTPRequired };
var VerifyOTP = /** @class */ (function () {
    function VerifyOTP(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.VerifyOTP;
    }
    return VerifyOTP;
}());
export { VerifyOTP };
var OTPRejected = /** @class */ (function () {
    function OTPRejected(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.OTPRejected;
    }
    return OTPRejected;
}());
export { OTPRejected };
var OTPLoginFailure = /** @class */ (function () {
    function OTPLoginFailure(payload) {
        this.payload = payload;
        this.type = AuthActionTypes.OTPLoginFailure;
    }
    return OTPLoginFailure;
}());
export { OTPLoginFailure };
