import styled from '@emotion/styled';

export const FileDescriptionStyled = styled.p`
  display: flex;
  font-weight: normal;
  ${({ onClick }) => (typeof onClick === 'function' ? 'cursor: pointer;' : '')}

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`;
