<mat-toolbar
  *ngIf="categoryId !== 0"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="30px"
>
  <div class="section-heading">
    {{
      listType === options.contentList
        ? text.ThisFolder(contentType)
        : text.ThisPack(contentType)
    }}
  </div>
  <div fxFlex="grow">
    <form
      class="content-filter-form"
      novalidate
      [formGroup]="searchForm"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="10px"
    >
      <mat-icon>search</mat-icon>
      <input
        fxFlex="grow"
        class="content-search-form"
        id="searchValue"
        formControlName="searchValue"
        placeholder="{{
          listType === options.contentList
            ? text.SearchContent(contentType)
            : text.FilterPack(contentType)
        }}"
      />
      <mat-icon
        class="clear-filter"
        *ngIf="filterActive"
        (click)="clearFilter()"
        >clear</mat-icon
      >
    </form>
  </div>
  <div *ngIf="listType === options.contentList && contentEditorEnabled">
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="addNew.emit(categoryId)"
      [disabled]="!contentEditorEnabled"
    >
      {{ text.AddItem }}
    </button>
  </div>
</mat-toolbar>
