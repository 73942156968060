import { ErrorHandler, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing/dist/browser';
import { Offline } from '@sentry/integrations';
import { environment } from './../../environments/environment';
import { versions } from './../../environments/versions';
import { ErrorAnnotationService } from './../core/services/error-annotation.service';
// Set up Error Reporting
Sentry.init({
    dsn: 'https://3217f81fa9aa4d198d1b48d3549e5ffd@sentry.io/1366588',
    // this will be either 'production' or 'test'. locally generated errors
    // won't be submitted to sentry.io
    environment: environment.environment,
    release: "salve-portal:" + versions.branch + "@" + versions.version + "." + versions.revision,
    integrations: [new BrowserTracing(), new Offline()],
    // ignore these errors because they seem to fire on the main.js bundle every time a user loads the clinic portal. that bundle size isnt going to reduce in the current state of the app
    ignoreErrors: ['Uncompressed Asset', 'Large Render Blocking Asset']
});
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler(_injector) {
        this._injector = _injector;
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        var errorAnnotation = this._injector.get(ErrorAnnotationService);
        var annotatedError = errorAnnotation.annotateError(error);
        // don't log errors in the console when in production
        var isProduction = environment.production && environment.environment === 'production';
        if (!isProduction) {
            console.error(annotatedError.originalError || error);
        }
        // add tags
        Sentry.configureScope(function (scope) {
            scope.setTag('framework', 'angular');
            scope.setTag('version', versions.version);
            scope.setTag('branch', versions.branch);
            scope.setTag('hostname', window.location.hostname);
            scope.setTag('clinic', window.location.hostname.split('.')[0]);
            // TODO:
            // capture state of the store and perhaps the last x number
            // of actions
            if (annotatedError.user) {
                var user = annotatedError.user;
                scope.setUser({ id: user.CognitoUsername });
            }
        });
        // We don't want to capture errors encountered locally
        if (environment.environment !== 'local') {
            Sentry.captureException(annotatedError.originalError || error);
        }
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
