import { ExpandMore } from '@material-ui/icons';
import {
  Button,
  DropdownMenu,
  DropdownMenuProps,
  Loading,
  Text,
} from '@react/components';
import {
  AssignClinicUsers,
  ConversationFolder,
  StaffProfiles,
} from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useErrorToast, useFileViewer } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { Conversation, Message } from '@react/types';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { IFileUpload } from 'src/app/core/services/s3.service';
import { scrollToBottom } from 'src/app/shared/utils';
import {
  ConversationCache,
  useGetConversationActionHandler,
  useGetConversationActions,
  useMessagesQuery,
} from '../hooks';
import { useMessageCreate } from '../hooks/useMessageCreate';
import { ActionMenuItem } from '../types';
import {
  ChatStyled,
  Header,
  PatientDetails,
  PatientDoB,
  Subject,
} from './Chat.styled';
import { ChatInput, ChatInputProps } from './ChatInput';
import { ChatMessage } from './ChatMessage';
import { TypingIndicator } from './ui';

const nestedMenuProps: DropdownMenuProps['NestedMenuProps'] = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
};

export interface ChatProps {
  conversation: Conversation;
  folders: ConversationFolder[];
  getTypingConversationMessage: (conversationId: number) => string | undefined;
  getInitialConversationState: ConversationCache['getInitialConversationState'];
  setConversationState: (messageId: number, message: string) => void;
  onChange: ChatInputProps['onChange'];
  assignClinicUsers: AssignClinicUsers;
  fileUploadService: IFileUpload;
  staffProfiles: StaffProfiles;
}

const assignLastMessageFlag = (messages: Message[] = []) => {
  let lastClinicUserId: number | null = null;
  const clone = [...messages];
  const assignedLastMessage = clone
    .reverse()
    .map((message) => {
      const lastMessage = Boolean(message.clinicuserid !== lastClinicUserId);
      lastClinicUserId = message.clinicuserid;
      return {
        ...message,
        lastmessagegrouped: lastMessage,
      };
    })
    .reverse();
  return assignedLastMessage;
};

export const Chat: FunctionComponent<ChatProps> = ({
  conversation,
  folders,
  getTypingConversationMessage,
  getInitialConversationState,
  setConversationState,
  onChange,
  assignClinicUsers,
  fileUploadService,
  staffProfiles,
}: ChatProps) => {
  const { t } = useTranslations();
  const dateTime = useLocaleDateTime();
  const handleAction = useGetConversationActionHandler();
  const getActions = useGetConversationActions('chat');
  const fileViewer = useFileViewer();
  const messagesInnerRef = useRef<HTMLDivElement>(null);

  const features: { [key: string]: any } = useFeatures();
  const featureProfilePhoto = checkFeature(
    features,
    'profilePhotoOptions.enabled',
  );

  const {
    data: messages,
    isError,
    isLoading,
  } = useMessagesQuery({
    messageid: conversation.messageid,
    pageSize: 1000,
  });

  const {
    mutateAsync: createMessage,
    isError: isSendingError,
    isLoading: isSendingLoading,
  } = useMessageCreate();

  useErrorToast(isError, {
    title: t.MessageToast.ErrorEncountered,
    message: t.MessageToast.MessageStarredBodyError,
  });

  useErrorToast(isSendingError, {
    title: t.MessageToast.ErrorEncountered,
    message: t.MessageToast.ErrorSending(
      `${conversation.patientfirstname} ${conversation.patientlastname}`,
    ),
  });

  useEffect(() => {
    if (messagesInnerRef && messagesInnerRef.current) {
      scrollToBottom(messagesInnerRef.current, false);
    }
  }, [messages]);

  const formatPatientName = ({
    patientfirstname,
    patientlastname,
    patientidentifier,
  }: Conversation) => {
    return [
      patientlastname + ',',
      patientfirstname,
      `(${patientidentifier})`,
    ].join(' ');
  };

  const formatPatientDoB = ({ patientdateofbirth }: Conversation) => {
    return dateTime.format(new Date(patientdateofbirth), 'P', {
      utc: true,
    });
  };

  const handleActionClick = (action: ActionMenuItem): void => {
    handleAction(action, conversation, messages);
  };

  const handleFileClick = (message: Message): void => {
    if (message.patientattachmenturl) {
      fileViewer.previewFile(message.patientattachmenturl);
    }
  };

  const handleSendMessage: ChatInputProps['onSubmit'] = async (content) => {
    await createMessage({
      messageid: conversation.messageid,
      content,
    });
  };

  if (isLoading) {
    return <Loading fullHeight />;
  }

  const actions = getActions(conversation, folders);
  const userTypingMessage = getTypingConversationMessage(
    conversation.messageid,
  );

  const updatedMessages = assignLastMessageFlag(messages);

  return (
    <ChatStyled>
      <Header>
        <div>
          <PatientDetails>
            <a href={`/patients/${conversation.patientid}`}>
              {formatPatientName(conversation)}
            </a>
          </PatientDetails>
          <PatientDoB> - {formatPatientDoB(conversation)}</PatientDoB>
          <Subject>
            <Text.Small bold>{t.Messages.Subject}</Text.Small>{' '}
            <Text.Small>{conversation.messagesubject}</Text.Small>
          </Subject>
        </div>
        <div>
          <DropdownMenu
            menu={actions}
            onClick={handleActionClick}
            NestedMenuProps={nestedMenuProps}
            assignClinicUsers={assignClinicUsers}
          >
            <Button
              color="primary"
              variant="contained"
              endIcon={<ExpandMore />}
            >
              {t.Messages.Actions}
            </Button>
          </DropdownMenu>
        </div>
      </Header>

      {messages && (
        <div className="messages-container">
          <div ref={messagesInnerRef} className="messages-inner">
            {updatedMessages.map((message) => (
              <ChatMessage
                key={message.id}
                message={message}
                alignDirection={message.patientsent ? 'left' : 'right'}
                onActionClick={handleActionClick}
                onFileClick={handleFileClick}
                fileUploadService={fileUploadService}
                staffProfiles={staffProfiles}
                featureProfilePhoto={featureProfilePhoto}
              />
            ))}
          </div>
        </div>
      )}

      {userTypingMessage && (
        <TypingIndicator>{userTypingMessage}</TypingIndicator>
      )}

      {conversation.canclinicreply && (
        <ChatInput
          conversation={conversation}
          onSubmit={handleSendMessage}
          onChange={onChange}
          disabled={isSendingLoading}
          getInitialConversationState={getInitialConversationState}
          setConversationState={setConversationState}
        />
      )}
    </ChatStyled>
  );
};
