import { SettingsSection } from '../models/section';
import { translationDe } from './lang.de';
import { translationEnGB } from './lang.en.gb';
import { translationEnUS } from './lang.en.us';
import { translationEs } from './lang.es';
import { translationPt } from './lang.pt';
import { SupportedLanguage } from './supported-languages';

export type TranslationData = typeof translationEnGB;

export const sections: SettingsSection[] = [
  {
    name: 'general',
    displayName: 'General',
  },
];

export const translations: Record<SupportedLanguage, TranslationData> = {
  'en-gb': translationEnGB,
  'en-us': translationEnUS,
  de: translationDe,
  es: translationEs,
  pt: translationPt,
};
