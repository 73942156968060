import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { Label } from 'src/app/models/Label';
import { environment } from 'src/environments/environment';
import * as LoadMessageActions from '../../../messaging/actions/load-message.actions';
import * as MessageActions from '../../../messaging/actions/message-ui.actions';
import * as fromMessages from '../../../messaging/reducers';
import * as fromSettings from '../../../settings/reducers';
import * as DashboardActions from '../../actions/dashboard.actions';
import * as fromDashboard from '../../reducers';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(_store, _navigationService, angulartics2) {
        var _this = this;
        this._store = _store;
        this._navigationService = _navigationService;
        this.angulartics2 = angulartics2;
        this._subs = new Subscription();
        this.displayedColumnList = ['name', 'type', 'drug', 'dueDate'];
        // Setup Observables
        this.labels$ = this._store.pipe(select(fromMessages.getLabelNames));
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('Dashboard')));
        this.tableText$ = this._store.pipe(select(fromSettings.getSectionTranslations('UpcomingDrugs')));
        this.nonTimedTriggerTableText$ = this._store.pipe(select(fromSettings.getSectionTranslations('NonTimedTriggerDrugs')));
        this.summary$ = this._store.pipe(select(fromDashboard.getDashboardSummary));
        this.messagingSummary$ = this._store.pipe(select(fromMessages.getMessagingSummary));
        this.medication$ = this._store.pipe(select(fromDashboard.getDashboardMedications), map(function (meds) {
            return meds.Medication.filter(function (med) { return med.StatusId === 2; }).map(function (med) { return ({
                id: med.PatientId,
                name: (med.FirstName + " " + med.LastName + " (" + med.PatientIdentifier + ")").toUpperCase(),
                type: med.Type,
                drug: med.DrugName,
                dosage: med.DrugDosage,
                status: med.Status,
                statusId: med.StatusId,
                dueDate: med.DueDate,
            }); });
        }));
        this.nonTimedTriggerMedication$ = this._store.pipe(select(fromDashboard.getDashboardNonTimedTriggerMedications), map(function (meds) {
            return meds.Medication.filter(function (med) { return true; }).map(function (med) { return ({
                id: med.PatientId,
                name: (med.FirstName + " " + med.LastName + " (" + med.PatientIdentifier + ")").toUpperCase(),
                type: med.Type,
                drug: med.DrugName,
                dosage: med.DrugDosage,
                status: med.Status,
                statusId: med.StatusId,
                dueDate: med.DueDate,
            }); });
        }));
        // capture text entries to feed into components
        this._subs.add(this.sectionText$.subscribe(function (val) { return (_this.sectionText = val); }));
        this._subs.add(this.tableText$.subscribe(function (val) { return (_this.tableText = val); }));
        this._subs.add(this.nonTimedTriggerTableText$.subscribe(function (val) { return (_this.nonTimedTriggerTableText = val); }));
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        // reload dashboard straight away and then
        // every 30 seconds
        this.load();
        this.interval = setInterval(function () {
            _this.load();
        }, 1000 * environment.dashboardRefreshIntervalSeconds);
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.interval);
        this._subs.unsubscribe();
    };
    DashboardComponent.prototype.gotoMessages = function (label) {
        // Track event
        var action = 'Navigate to ' + label + ' messages';
        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: 'Messaging' },
        });
        // set label of messages to view
        this._store.dispatch(new MessageActions.ApplyFilter(label.toLowerCase()));
        // then open messages
        this._navigationService.navigate(['/messages']);
    };
    DashboardComponent.prototype.goToMessages = function (messageTypeId) {
        this._store.dispatch(new MessageActions.ApplyMessageTypeFilter(new Label({
            messageTypeId: messageTypeId,
            displayName: '',
            filter: '',
            isGroup: false,
        })));
        // then open messages
        this._navigationService.navigate(['/messages']);
    };
    DashboardComponent.prototype.gotoPatient = function (number) {
        this._navigationService.navigate(["/patients/" + number]);
    };
    DashboardComponent.prototype.load = function () {
        this._store.dispatch(new DashboardActions.RefreshClinicDashboard());
        this._store.dispatch(new LoadMessageActions.LoadMessagingSummary());
    };
    return DashboardComponent;
}());
export { DashboardComponent };
