import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  FullDate,
  JustTime,
  LongDate,
  LongDateWithTime,
  MidDateWithTime,
  ShortDate,
  ShortDateWithTime,
  ShortNumericDate,
} from './date.formating.pipes';
import { LocalisationService } from './localisation.service';
const PIPES = [
  ShortDate,
  ShortDateWithTime,
  ShortNumericDate,
  LongDate,
  LongDateWithTime,
  MidDateWithTime,
  JustTime,
  FullDate,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...PIPES],
  exports: [...PIPES],
})
export class LocalisationModule {
  static forRoot(): ModuleWithProviders<LocalisationModule> {
    return {
      ngModule: LocalisationModule,
      providers: [
        { provide: LocalisationService, useClass: LocalisationService },
      ],
    };
  }
}
