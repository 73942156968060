import * as tslib_1 from "tslib";
import { useRealTimeMessagingContext, } from '@react/lib/context/websocket';
import { useTranslations } from '@react/lib/i18n';
import { differenceInMilliseconds } from 'date-fns';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MARK_MESSAGE_AS_NOT_TYPING_AFTER_MS, MARK_MESSAGE_AS_TYPED_AFTER_MS, MESSAGE_MAX_VALID_ELAPSED_MS, TYPING_DELAY_MS, } from '../constants';
function isMyOwnMessage(event, user) {
    return event.user === user.FirstName + " " + user.LastName;
}
function isEventTimeInRange(event) {
    var now = new Date();
    return (differenceInMilliseconds(now, new Date(event.lastEdited)) <
        MESSAGE_MAX_VALID_ELAPSED_MS);
}
function isMessageRemoved(event) {
    return event.lastEdited.includes('1970-01-01');
}
function shouldMarkAsTyped(event) {
    var now = new Date();
    return (differenceInMilliseconds(now, new Date(event.lastEdited)) >
        MARK_MESSAGE_AS_TYPED_AFTER_MS);
}
export function useRealTimeMessaging(currentUser) {
    var t = useTranslations().t;
    var _a = useRealTimeMessagingContext(), connected = _a.connected, websocketSendTypingEvent = _a.sendTypingEvent, setOnMessage = _a.setOnMessage, websocketDisconnect = _a.websocketDisconnect, websocketConnect = _a.websocketConnect;
    var timeoutIds = useRef({});
    var _b = useState({}), conversationTypingIdMap = _b[0], setConversationTypingIdMap = _b[1];
    useEffect(function () {
        websocketConnect();
        return function () {
            websocketDisconnect();
        };
    }, []);
    var getTypingConversationMessage = useCallback(function (conversationId) {
        var typingDetails = conversationTypingIdMap[conversationId];
        if (typingDetails) {
            var users = typingDetails.users, status_1 = typingDetails.status;
            var message = users.join(', ');
            if (status_1 === 'typed') {
                message +=
                    users.length === 1
                        ? t.Messages.SingularTyped
                        : t.Messages.PluralTyped;
            }
            else {
                message +=
                    users.length === 1
                        ? t.Messages.SingularTyping
                        : t.Messages.PluralTyping;
            }
            return message;
        }
    }, [conversationTypingIdMap]);
    var sendTypingEvent = function (_a) {
        var messageId = _a.messageId, user = _a.user, content = _a.content;
        websocketSendTypingEvent({
            content: content,
            messageId: messageId,
            user: user,
        });
    };
    var throttledSendTypingEvent = useCallback(throttle(sendTypingEvent, TYPING_DELAY_MS), [conversationTypingIdMap, websocketSendTypingEvent, connected]);
    useEffect(function () {
        if (connected) {
            var onMessage = function (event) {
                clearTimeout(timeoutIds.current[event.messageId]);
                var typingDetails = conversationTypingIdMap[event.messageId] || {
                    users: [],
                    status: 'typing',
                };
                if (isMyOwnMessage(event, currentUser) || isMessageRemoved(event)) {
                    typingDetails.users = typingDetails.users.filter(function (userName) { return userName !== event.user; });
                }
                else if (isEventTimeInRange(event) &&
                    !typingDetails.users.includes(event.user)) {
                    typingDetails.users.push(event.user);
                    if (shouldMarkAsTyped(event)) {
                        typingDetails.status = 'typed';
                    }
                }
                // Filter details without users
                conversationTypingIdMap[event.messageId] =
                    typingDetails.users.length === 0 ? undefined : typingDetails;
                setConversationTypingIdMap(tslib_1.__assign({}, conversationTypingIdMap));
                var autoSetTypingStatus = function (delay) {
                    var details = conversationTypingIdMap[event.messageId];
                    if (!details) {
                        return;
                    }
                    // @ts-ignore-nextline
                    timeoutIds.current[event.messageId] = setTimeout(function () {
                        var _a;
                        if (details.status === 'typing') {
                            details.status = 'typed';
                            setConversationTypingIdMap(tslib_1.__assign((_a = {}, _a[event.messageId] = details, _a), conversationTypingIdMap));
                            autoSetTypingStatus(MARK_MESSAGE_AS_NOT_TYPING_AFTER_MS);
                        }
                        else {
                            delete conversationTypingIdMap[event.messageId];
                            setConversationTypingIdMap(tslib_1.__assign({}, conversationTypingIdMap));
                        }
                    }, delay);
                };
                autoSetTypingStatus(MARK_MESSAGE_AS_TYPED_AFTER_MS);
            };
            var socketId_1 = setOnMessage(onMessage);
            return function () {
                Object.keys(timeoutIds.current).forEach(function (key) {
                    clearTimeout(timeoutIds.current[key]);
                });
                setOnMessage(null, socketId_1);
            };
        }
    }, [connected]);
    return {
        getTypingConversationMessage: getTypingConversationMessage,
        sendTypingEvent: throttledSendTypingEvent,
    };
}
