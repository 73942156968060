import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
var Small = styled('span', {
    shouldForwardProp: function (prop) { return ['bold', 'block'].every(function (item) { return prop !== item; }); },
})(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  font-size: 14px;\n  font-weight: ", ";\n  ", ";\n"], ["\n  font-size: 14px;\n  font-weight: ", ";\n  ", ";\n"])), function (_a) {
    var bold = _a.bold;
    return (bold ? 'bold' : 'normal');
}, function (_a) {
    var block = _a.block;
    return (block ? 'display: block;' : '');
});
export var Text = {
    Small: Small,
};
var templateObject_1;
