import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

export interface DismissComponentInterface {
  message: string;
  text: { [key: string]: string };
}

@Component({
  selector: 'portal-dialog-with-dismiss',
  templateUrl: './dialog-with-dismiss.component.html',
  styleUrls: ['./dialog-with-dismiss.component.scss'],
})
export class DialogWithDismissComponent {
  public message: string;

  constructor(
    public dialogRef: MatDialogRef<DialogWithDismissComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DismissComponentInterface,
  ) {
    if (data.message) {
      this.message = data.message;
    }
  }

  dismiss(): void {
    this.dialogRef.close(false);
  }
}
