import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, delay, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ServerError } from '../../models/Error';
import { ToastOptions } from '../../models/ToastOptions';
import * as fromSettings from '../../settings/reducers';
import { AlterMessageActionTypes, ChangeLabelError, ChangeLabelSuccess, DeleteMessageItemError, DeleteMessageItemSuccess, MarkResolvedError, MarkResolvedSuccess, MarkStarredError, MarkStarredSuccess, MarkUnreadError, MarkUnreadSuccess, MarkUnresolvedError, MarkUnresolvedSuccess, MarkUnstarredError, MarkUnstarredSuccess, ToastDisplayed } from '../actions/alter-message.actions';
import { LoadMessagingSummary, LoadNewerThreadMessages } from '../actions/load-message.actions';
import { SetSelectedTab } from '../actions/message-ui.actions';
import { ShowRerouteToast } from '../actions/new-message.actions';
import { TabState } from '../models/TabState';
import * as fromMessages from '../reducers';
import { MessageService } from '../services/message.service';
var AlterMessageEffects = /** @class */ (function () {
    function AlterMessageEffects(actions$, messageService, angulartics2, toastr, _store, _error) {
        var _this = this;
        this.actions$ = actions$;
        this.messageService = messageService;
        this.angulartics2 = angulartics2;
        this.toastr = toastr;
        this._store = _store;
        this._error = _error;
        this._store
            .pipe(select(fromSettings.getSectionTranslations('MessageToast')))
            .subscribe(function (val) {
            _this._toastText = val;
        });
    }
    // Mark Unread
    AlterMessageEffects.prototype.markUnread$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.MarkUnread), mergeMap(function (action) {
            var data = action.payload.data;
            var toast = action.payload.toast;
            return _this.messageService.markMessageUnread(data.id).pipe(mergeMap(function (response) {
                _this.angulartics2.eventTrack.next({
                    action: 'Mark message unread',
                    properties: { category: 'Messaging' }
                });
                return [
                    new MarkUnreadSuccess(tslib_1.__assign({ result: response }, data)),
                    new ToastDisplayed({
                        toast: toast
                    })
                ];
            }), catchError(function (err) {
                return [
                    new MarkUnreadError(new ServerError('[AlterMessage]', 'Error marking message as unread', '')),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: 'Error',
                            message: _this._toastText.ErrorUnread,
                            type: 'error',
                            options: {
                                timeOut: 3000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    // Mark Read not applicable yet
    // Mark Unresolved
    AlterMessageEffects.prototype.markUnresolved$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.MarkUnresolved), mergeMap(function (action) {
            // call message service to mark message as read
            return _this.messageService
                .markMessageUnresolved(action.payload.data.id)
                .pipe(mergeMap(function (response) {
                _this.angulartics2.eventTrack.next({
                    action: 'Mark message unresolved',
                    properties: { category: 'Messaging' }
                });
                return [
                    new MarkUnresolvedSuccess(tslib_1.__assign({ result: response }, action.payload.data)),
                    new LoadMessagingSummary(),
                    new ToastDisplayed({
                        toast: action.payload.toast
                    })
                ];
            }), catchError(function (err) {
                return [
                    new MarkUnresolvedError(new ServerError('[AlterMessage]', 'Error marking message as unresolved', '')),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: _this._toastText.ErrorEncountered,
                            message: _this._toastText.ErrorUnresolved,
                            type: 'error',
                            options: {
                                timeOut: 3000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    // Mark Resolved
    AlterMessageEffects.prototype.markResolved$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.MarkResolved), mergeMap(function (action) {
            // call message service to mark message as read
            return _this.messageService
                .markMessageResolved(action.payload.data.id)
                .pipe(mergeMap(function (response) {
                _this.angulartics2.eventTrack.next({
                    action: 'Mark message resolved',
                    properties: { category: 'Messaging' }
                });
                return [
                    new MarkResolvedSuccess(tslib_1.__assign({ result: response }, action.payload.data)),
                    new LoadMessagingSummary(),
                    new ToastDisplayed({
                        toast: action.payload.toast
                    })
                ];
            }), catchError(function (err) {
                return [
                    new MarkResolvedError(new ServerError('[AlterMessage]', 'Error marking message as resolved', '')),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: _this._toastText.ErrorEncountered,
                            message: _this._toastText.ErrorResolved,
                            type: 'error',
                            options: {
                                timeOut: 3000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    // ChangeLabel
    AlterMessageEffects.prototype.changeLabel$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.ChangeLabel), mergeMap(function (action) {
            // call message service to mark message as read
            return _this.messageService.updateMessageType(action.payload.data).pipe(mergeMap(function (response) {
                _this.angulartics2.eventTrack.next({
                    action: 'Reassign message label',
                    properties: { category: 'Messaging' }
                });
                return [
                    new ChangeLabelSuccess({
                        data: action.payload.data,
                        result: response
                    }),
                    new ToastDisplayed({
                        toast: action.payload.toast
                    })
                ];
            }), catchError(function (err) {
                return [
                    new ChangeLabelError(new ServerError('[AlterMessage]', 'Error changing label', '')),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: _this._toastText.ErrorEncountered,
                            message: _this._toastText.ErrorChanging,
                            type: 'error',
                            options: {
                                timeOut: 3000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    AlterMessageEffects.prototype.deleteMessageItem$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.DeleteMessageItem), withLatestFrom(this._store.pipe(select(fromMessages.getActiveThread))), mergeMap(function (_a) {
            var action = _a[0], activeThread = _a[1];
            return _this.messageService.deleteMessageItem(action.payload).pipe(mergeMap(function (response) {
                var actions = [];
                if (activeThread.id === action.payload.messageId) {
                    var patientMessages = activeThread.messages.filter(function (m) { return m.PatientSent; });
                    var latestId = 0;
                    if (patientMessages.length > 0) {
                        latestId =
                            patientMessages[patientMessages.length - 1].MessageItemId;
                    }
                    actions.push(new LoadNewerThreadMessages({
                        messageId: activeThread.id,
                        latestMessageItemId: latestId
                    }));
                }
                actions.push(new DeleteMessageItemSuccess(action.payload));
                return actions;
            }), catchError(function (err) {
                return [
                    new DeleteMessageItemError(),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: _this._toastText.ErrorDeletingMessageTitle,
                            message: _this._toastText.ErrorDeletingMessage,
                            type: 'error',
                            options: {
                                timeOut: 7000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    // Mark Starred
    AlterMessageEffects.prototype.markStarred$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.MarkStarred), delay(500), mergeMap(function (action) {
            var message = action.payload.message;
            return _this.messageService
                .markMessageStarred(action.payload.message.MessageId)
                .pipe(mergeMap(function (response) {
                _this.angulartics2.eventTrack.next({
                    action: 'Mark message starred',
                    properties: { category: 'Messaging' }
                });
                var toast = _this.toastr.show(action.payload.toast.message, action.payload.toast.title, {
                    timeOut: 0,
                    closeButton: false,
                    toastClass: "toast custom-toast info"
                });
                return [
                    new MarkStarredSuccess({
                        result: response,
                        message: message
                    }),
                    new ShowRerouteToast({
                        route: '/messages',
                        afterAction: new SetSelectedTab({
                            tab: TabState.Starred,
                            params: {
                                MessageTypeId: action.payload.message.MessageTypeId,
                                FetchAfterMessageItemId: null,
                                FetchBeforeMessageItemId: null
                            }
                        }),
                        toast: tslib_1.__assign({}, action.payload.toast, { toast: toast, toastRef: toast.toastRef.componentInstance }),
                        other: {
                            id: message.MessageId,
                            type: message.MessageType,
                            typeId: message.MessageTypeId,
                            patientFirstName: message.PatientFirstName,
                            patientLastName: message.PatientLastName,
                            patientId: message.PatientId,
                            patientIdentifier: message.PatientIdentifier,
                            patientDoB: message.DateOfBirth,
                            isClincRead: message.ClinicRead,
                            isPatientRead: message.PatientRead,
                            isStarred: message.IsStarred,
                            subject: message.MessageSubject,
                            content: message.Content
                        }
                    })
                ];
            }), catchError(function (err) {
                return [
                    new MarkStarredError({
                        messageId: message.MessageId,
                        error: new ServerError('[AlterMessage]', 'Error marking message as starred', '')
                    }),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: _this._toastText.MessageStarredTitleError,
                            message: _this._toastText.MessageStarredBodyError,
                            type: 'error',
                            options: {
                                timeOut: 3000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    // Mark Unstarred
    AlterMessageEffects.prototype.markUnstarred$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(AlterMessageActionTypes.MarkUnstarred), delay(500), mergeMap(function (action) {
            var message = action.payload.message;
            return _this.messageService
                .markMessageUnstarred(action.payload.message.MessageId)
                .pipe(mergeMap(function (response) {
                var toast = _this.toastr.show(action.payload.toast.message, action.payload.toast.title, {
                    timeOut: 0,
                    closeButton: false,
                    toastClass: "toast custom-toast info"
                });
                _this.angulartics2.eventTrack.next({
                    action: 'Mark message unstarred',
                    properties: { category: 'Messaging' }
                });
                return [
                    new MarkUnstarredSuccess({
                        result: response,
                        message: action.payload.message
                    }),
                    new ShowRerouteToast({
                        route: '/messages',
                        afterAction: new SetSelectedTab({
                            tab: TabState.EverythingElse,
                            params: {
                                MessageTypeId: action.payload.message.MessageTypeId,
                                FetchAfterMessageItemId: null,
                                FetchBeforeMessageItemId: null
                            }
                        }),
                        toast: tslib_1.__assign({}, action.payload.toast, { toast: toast, toastRef: toast.toastRef.componentInstance }),
                        other: {
                            id: message.MessageId,
                            type: message.MessageType,
                            typeId: message.MessageTypeId,
                            patientFirstName: message.PatientFirstName,
                            patientLastName: message.PatientLastName,
                            patientId: message.PatientId,
                            patientIdentifier: message.PatientIdentifier,
                            patientDoB: message.DateOfBirth,
                            isClincRead: message.ClinicRead,
                            isPatientRead: message.PatientRead,
                            isStarred: message.IsStarred,
                            subject: message.MessageSubject,
                            content: message.Content
                        }
                    })
                ];
            }), catchError(function (err) {
                return [
                    new MarkUnstarredError({
                        messageId: message.MessageId,
                        error: new ServerError('[AlterMessage]', 'Error marking message as unstarred', '')
                    }),
                    new ToastDisplayed({
                        toast: new ToastOptions({
                            toast: null,
                            toastRef: null,
                            undoAction: null,
                            title: _this._toastText.MessageUnstarredTitleError,
                            message: _this._toastText.MessageUnstarredBodyError,
                            type: 'error',
                            options: {
                                timeOut: 3000
                            }
                        })
                    })
                ];
            }));
        }));
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "markUnread$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "markUnresolved$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "markResolved$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "changeLabel$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "deleteMessageItem$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "markStarred$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], AlterMessageEffects.prototype, "markUnstarred$", null);
    return AlterMessageEffects;
}());
export { AlterMessageEffects };
