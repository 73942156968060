/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/router";
import * as i5 from "../../components/general-settings/general-settings.component.ngfactory";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "../../components/general-settings/general-settings.component";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i12 from "@angular/material/card";
import * as i13 from "./settings.component";
import * as i14 from "@ngrx/store";
var styles_SettingsComponent = [i0.styles];
var RenderType_SettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettingsComponent, data: {} });
export { RenderType_SettingsComponent as RenderType_SettingsComponent };
function View_SettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "mat-list-item", [["class", "mat-list-item"], ["routerLinkActive", "router-link-active"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), i1.ɵdid(5, 16384, [[4, 4]], 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 2), i1.ɵdid(7, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 4, { links: 1 }), i1.ɵqud(603979776, 5, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(10, 2, ["", ""])), i1.ɵppd(11, 1)], function (_ck, _v) { var currVal_2 = _ck(_v, 6, 0, "/settings", _v.context.$implicit); _ck(_v, 5, 0, currVal_2); var currVal_3 = "router-link-active"; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)); _ck(_v, 10, 0, currVal_4); }); }
function View_SettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "portal-general-settings", [["fxFlex", "grow"]], null, [[null, "setLanguage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setLanguage" === en)) {
        var pd_0 = (_co.changePortalLanguage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_GeneralSettingsComponent_0, i5.RenderType_GeneralSettingsComponent)), i1.ɵdid(1, 671744, null, 0, i6.DefaultFlexDirective, [i1.ElementRef, i7.StyleUtils, i7.LAYOUT_CONFIG, i6.FlexStyleBuilder, i7.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(2, 245760, null, 0, i8.GeneralSettingsComponent, [i9.FormBuilder], { languages: [0, "languages"], activeLanguage: [1, "activeLanguage"] }, { setLanguage: "setLanguage" }), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "grow"; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.languages$)); var currVal_2 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.activeLanguage$)); _ck(_v, 2, 0, currVal_1, currVal_2); }, null); }
export function View_SettingsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 11, "mat-card", [["class", "mat-card"]], null, null, null, i11.View_MatCard_0, i11.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i12.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 9, "div", [["fxLayout", "row"], ["fxLayoutAlign", "start start"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i6.DefaultLayoutAlignDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutAlignStyleBuilder], i7.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "mat-list", [["class", "sidenav mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(7, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_SettingsComponent_1)), i1.ɵdid(9, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettingsComponent_2)), i1.ɵdid(12, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "start start"; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.settingSections$)); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.cardTitle === "general"); _ck(_v, 12, 0, currVal_3); }, null); }
export function View_SettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i1.ɵdid(1, 245760, null, 0, i13.SettingsComponent, [i4.ActivatedRoute, i14.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SettingsComponentNgFactory = i1.ɵccf("portal-settings", i13.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
export { SettingsComponentNgFactory as SettingsComponentNgFactory };
