import { Component, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { MedicationType } from '../../components/patient-list/patient-list.component.enums';
import { IRowSelection } from '../../components/patient-list/patient-list.component.interfaces';
import { MedicationsService } from '../../services/medications.service';
import {
  IActionMedication,
  IMedication,
} from '../../services/medications.service.interfaces';

@Component({
  selector: 'portal-medications',
  templateUrl: './medications.component.html',
  styleUrls: ['./medications.component.scss'],
})
export class MedicationsComponent implements OnInit {
  public displayPotentialDupes$: Observable<boolean>;

  public actionRequired: Array<IActionMedication>;

  public noActionRequired: Array<IActionMedication>;

  public potentialDuplicate: Array<IMedication>;

  public medicationTableText$: Observable<any>;

  public actionRequiredLoading = false;

  public noActionRequiredLoading = false;

  public potentialDuplicatesLoading = false;

  public medicationType: typeof MedicationType = MedicationType;

  constructor(
    private _store: Store<fromRoot.State>,
    private _navigationService: NavigationService,
    private _medicationsService: MedicationsService,
  ) {
    this._getPotentialDuplicates();
    this._getActionMedications();
    this._getNoActionMedications();

    this.medicationTableText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('Medication')),
    );
  }

  ngOnInit(): void {}

  public gotoPatient(selection: IRowSelection): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        date: selection.date.toISOString().split('T')[0],
      },
    };

    this._navigationService.navigate(
      ['/patients', selection.id],
      navigationExtras,
    );
  }

  public onActionMedicationSearchTermChange(searchTerm: string): void {
    this._getActionMedications(searchTerm);
  }

  public onNoActionMedicationSearchTermChange(searchTerm: string): void {
    this._getNoActionMedications(searchTerm);
  }

  public onPotentialDuplicatesSearchTermChange(searchTerm: string): void {
    this._getPotentialDuplicates(searchTerm);
  }

  private _getActionMedications(searchTerm?: string): void {
    this.actionRequiredLoading = true;
    this._medicationsService
      .getActionRequiredMedications(searchTerm)
      .then((response: Array<IActionMedication>): void => {
        this.actionRequired = response;
        this.actionRequiredLoading = false;
      });
  }

  private _getNoActionMedications(searchTerm?: string): void {
    this.noActionRequiredLoading = true;
    this._medicationsService
      .getNoActionRequiredMedications(searchTerm)
      .then((response: Array<IActionMedication>): void => {
        this.noActionRequired = response;
        this.noActionRequiredLoading = false;
      });
  }

  private _getPotentialDuplicates(searchTerm?: string): void {
    this.potentialDuplicatesLoading = true;
    this._medicationsService
      .getPotentialDuplicateMedications(searchTerm)
      .then((response: Array<IMedication>): void => {
        this.potentialDuplicate = response;
        this.potentialDuplicatesLoading = false;
      });
  }
}
