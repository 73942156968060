import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

export const StyledList = styled.ul`
  list-style: none;
  min-width: 200px;
  max-height: 330px;
  overflow-y: auto;
  padding: 0px;
  margin-bottom: 0px;

  li {
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(2)}px;
    padding-top: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: ${({ theme }) => theme.spacing(1)}px;
    padding-right: ${({ theme }) => theme.spacing(1)}px;

    .close {
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const StyledContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-right: 0px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  max-height: 350px;
  overflow: hidden;
`;
