<div class="list-container">
  <div fxLayout="row" fxLayoutAlign="start">
    <div class="template-form">
      <form novalidate (ngSubmit)="save()" [formGroup]="templateForm">
        <perfect-scrollbar
          fxFlex="auto"
          [config]="config"
          [scrollIndicators]="true"
        >
          <h3 *ngIf="isNew" class="title">
            {{ text.NewTitle }}
          </h3>
          <h3 *ngIf="!isNew" class="title">
            {{ text.Title }}
          </h3>
          <div class="content-title">
            <input
              formControlName="title"
              type="text"
              placeholder="{{ text.TemplateTitle }}"
              [disabled]="!contentEditorEnabled"
            />
          </div>
          <div *ngFor="let reqEntry of requiredEntries.controls">
            <div
              class="req-form-entry"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="15px"
            >
              {{ reqEntry.get('title').value }}
              <div
                class="entry-controls"
                fxLayout="row"
                fxLayoutAlign="end center"
                fxLayoutGap="15px"
              >
                <div>
                  {{ text.Required }}
                </div>
              </div>
            </div>
          </div>
          <div
            (drop)="dropped()"
            ngxDroppable
            [model]="templateEntries.controls"
          >
            <div
              class="entry-list"
              ngxDraggable
              formArrayName="entries"
              *ngFor="let entry of templateEntries.controls; let i = index"
              [model]="entry"
              [moves]="contentEditorEnabled"
            >
              <div [formGroupName]="i">
                <div
                  class="form-entry"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="15px"
                >
                  <mat-icon ngxDragHandle class="handle">
                    drag_indicator
                  </mat-icon>
                  <input
                    fxFlex="grow"
                    class="form-input"
                    type="text"
                    placeholder="{{ text.AddSectionTitle }}"
                    id="{{ 'entryId' + i }}"
                    formControlName="title"
                  />
                  <mat-icon
                    class="close"
                    (click)="contentEditorEnabled && removeEntry(i)"
                  >
                    close
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-controls"
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="15px"
          >
            <div fxLayout="row" fxLayout="center">
              <button
                mat-raised-button
                class="portal-btn btn-upload add-new"
                type="button"
                fxLayout="row"
                fxLayoutAlign="center center"
                (click)="addEntry()"
                [disabled]="!contentEditorEnabled"
              >
                {{ text.AddNew }}
              </button>
            </div>
            <div
              class="buttons"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="15px"
            >
              <div
                *ngIf="activeTemplate.id"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <button
                  mat-raised-button
                  class="portal-btn btn-warn"
                  type="button"
                  (click)="delete()"
                  [disabled]="!contentEditorEnabled"
                >
                  {{ text.Delete }} <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="status" fxFlex="grow">
                <div
                  *ngIf="creating"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="15px"
                >
                  <mat-spinner [diameter]="36"></mat-spinner>
                  <div class="status-text">
                    {{ text.Saving }}
                  </div>
                </div>
                <div
                  *ngIf="creatingSuccess"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="15px"
                >
                  <mat-icon class="success">check_circle</mat-icon>
                  <div class="status-text">
                    {{ text.SavedSuccessfully }}
                  </div>
                </div>
                <div
                  *ngIf="creatingError"
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="15px"
                >
                  <mat-icon class="error">highlight_off</mat-icon>
                  <div class="status-text">
                    {{ text.SavedError }}
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <button
                  mat-raised-button
                  class="portal-btn btn-bright"
                  type="submit"
                  [disabled]="!contentEditorEnabled"
                >
                  {{ text.Save }}
                </button>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </form>
    </div>
  </div>
</div>
