import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoError } from '../../models/LoginSubmission';
import { VerifyOTPParams } from '../../models/user';

@Component({
  selector: 'portal-email-otp-form',
  templateUrl: './email-otp-form.component.html',
  styleUrls: ['./email-otp-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailOTPFormComponent implements OnChanges {
  @Input() text: any;
  @Input()
  set loggingIn(isLoggingIn: boolean) {
    if (isLoggingIn) {
      this.OTPForm.disable();
    } else {
      this.OTPForm.enable();
    }
    this.isLoggingIn = isLoggingIn;
  }
  @Input() CodeError: boolean;
  @Input() cognitoUser: CognitoUser | any;
  @Output() verifyOTP = new EventEmitter<VerifyOTPParams>();
  @Output() cancelOTPLogin = new EventEmitter();

  public showScrollbar: boolean;
  public isLoggingIn: boolean;
  public loginForm: FormGroup;
  public OTPForm: FormGroup;
  public formSubmitted: boolean;

  constructor(private _fb: FormBuilder) {
    this._setupForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isCore) {
      if (changes.isCore.currentValue !== changes.isCore.previousValue) {
        this._setupForm();
      }
    }
  }

  private _setupForm() {
    this.OTPForm = this._fb.group({
      code: [
        {
          value: '',
          disabled: false,
        },
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  public submitOTP() {
    const { code } = this.OTPForm.value;
    this.verifyOTP.emit({
      user: this.cognitoUser,
      code,
      toast: {
        title: this.text.OTPToastTitle,
        message: this.text.OTPToastMessage,
        timeout: 0,
        type: 'error',
      },
    });
  }

  public cancelOTP() {
    this.cancelOTPLogin.emit();
  }
}
