import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {
  VideoCallActionsUnion,
  VideoCallActionTypes
} from '../actions/video-call.actions';
import { TabsState } from '../models/TabsState';
import {
  ApiVideoCall,
  VideoCallEffectsCompletedResponse,
  VideoCallEffectsResposeType
} from '../responses';

export const featureStateName = 'videoCalls';

export interface AppState extends fromRoot.State {
  [featureStateName]: VideoCallsState;
}

export interface VideoCallsState {
  videoCallsList: ApiVideoCall[];
  loading: boolean;
  filterActive: boolean;
  filter: string;
  tabsState: TabsState;
  effectsCompletedResponse: VideoCallEffectsCompletedResponse;
  inCallInviteFlag: boolean;
  invitingInCall: boolean;
}

const initialState: VideoCallsState = {
  loading: false,
  videoCallsList: [],
  filterActive: false,
  filter: '',
  tabsState: TabsState.Upcoming,
  effectsCompletedResponse: null,
  inCallInviteFlag: false,
  invitingInCall: false
};

export function reducer(
  state: VideoCallsState = initialState,
  action: VideoCallActionsUnion
): VideoCallsState {
  switch (action.type) {
    case VideoCallActionTypes.SetListFilter:
      return {
        ...state,
        filter: action.payload
      };
    case VideoCallActionTypes.SetCurrentTab:
      return {
        ...state,
        tabsState: action.payload
      };
    case VideoCallActionTypes.ResetVideoCallEffects:
      return {
        ...state,
        effectsCompletedResponse: null
      };
    case VideoCallActionTypes.EffectsCompleted:
      const result: VideoCallsState = {
        ...state,
        effectsCompletedResponse: action.payload,
        loading: false
      };
      switch (action.payload.type) {
        case VideoCallEffectsResposeType.NoPartnerFoundError:
          result.invitingInCall = false;
          break;
        case VideoCallEffectsResposeType.InviteInCallError:
          result.invitingInCall = false;
          break;
        case VideoCallEffectsResposeType.LoadSuccess:
          result.videoCallsList = action.payload.data;
          break;
        case VideoCallEffectsResposeType.LoadError:
          break;
        case VideoCallEffectsResposeType.UpdateSuccess:
          break;
        case VideoCallEffectsResposeType.UpdateError:
          break;
        case VideoCallEffectsResposeType.InviteSuccess:
          break;
        case VideoCallEffectsResposeType.InviteError:
          break;
        case VideoCallEffectsResposeType.UnInviteSuccess:
          break;
        case VideoCallEffectsResposeType.UnInviteError:
          break;
        case VideoCallEffectsResposeType.DeleteSuccess:
          result.videoCallsList = state.videoCallsList.filter(
            (a) => a.id !== action.payload.data.id
          );
          break;
        case VideoCallEffectsResposeType.DeleteError:
          break;
      }
      return result;
    case VideoCallActionTypes.UpdateList: {
      const updatingItem = action.payload;
      const updatingList = [...state.videoCallsList];
      if (updatingItem.isNew) {
        updatingList.push(updatingItem.videoCall);
      } else {
        if (updatingItem.joinedVideoCallId) {
          const existingItemIndex = updatingList.findIndex(
            (i) => i.id === updatingItem.joinedVideoCallId
          );
          const itemToUpdate = {
            ...updatingList[existingItemIndex],
            status: 'in progress'
          };
          updatingList.splice(existingItemIndex, 1, itemToUpdate);
        } else {
          const existingItemIndex = updatingList.findIndex(
            (i) => i.id === updatingItem.videoCall.id
          );
          updatingList.splice(existingItemIndex, 1, updatingItem.videoCall);
        }
      }
      return {
        ...state,
        videoCallsList: updatingList,
        inCallInviteFlag: action.payload.inCallInviteFlag
      };
    }
    case VideoCallActionTypes.InvitePartnerInCallToVideoCall: {
      return {
        ...state,
        invitingInCall: true
      };
    }
    case VideoCallActionTypes.InviteInCallSuccess: {
      return {
        ...state,
        inCallInviteFlag: action.payload.inCallInviteFlag,
        invitingInCall: false
      };
    }
    case VideoCallActionTypes.UnInviteInCallSuccess: {
      return {
        ...state,
        inCallInviteFlag: action.payload.inCallInviteFlag
      };
    }
    default:
      return state;
  }
}

export const videoCallsSelector = createFeatureSelector<
  AppState,
  VideoCallsState
>(featureStateName);

export const getLoadingSelector = createSelector(
  videoCallsSelector,
  (state) => state.loading
);

export const getCurrentTabSelector = createSelector(
  videoCallsSelector,
  (state) => state.tabsState
);

export const getEffectsCompletedResponse = createSelector(
  videoCallsSelector,
  (state) => state.effectsCompletedResponse
);

export const getInCallInviteFlagSelector = createSelector(
  videoCallsSelector,
  (state) => state.inCallInviteFlag
);
export const getInvitingPartnerInCall = createSelector(
  videoCallsSelector,
  (state) => state.invitingInCall
);
