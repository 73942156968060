import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery, useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatStaff } from '../lib';
export function usePatientStaffListQuery(staffIds) {
    var _this = this;
    var apiClient = useApiClient().portal;
    return useQuery([queryCacheKey.PATIENT_STAFF, staffIds], function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var response;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.fetchPatientStaff({ staffIds: staffIds })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, tslib_1.__assign({}, response, { data: response.data.map(formatStaff) })];
            }
        });
    }); });
}
export function usePatientStaffListInfiniteQuery(filters) {
    var _this = this;
    var apiClient = useApiClient().portal;
    return useInfiniteQuery([queryCacheKey.PATIENT_STAFF_INFINITE, filters], function (_a) {
        var pageNumber = _a.pageParam;
        return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, apiClient.fetchPatientStaff(tslib_1.__assign({ pageNumber: pageNumber }, filters))];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, tslib_1.__assign({}, response, { data: response.data.map(formatStaff) })];
                }
            });
        });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
        keepPreviousData: true,
    });
}
