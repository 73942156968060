var _this = this;
import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { debounceAsync } from '@react/lib/debounceAsync';
import * as React from 'react';
// Needed so that we call the same `debounceAsync` instance across
// different renders.
export var useMessageTemplateUniqueNameValidator = function (_a) {
    var delay = _a.delay, errorMessage = _a.errorMessage, existingMessageTemplateId = _a.existingMessageTemplateId;
    var portalApiClient = useApiClient().portal;
    return React.useMemo(function () {
        return debounceAsync(function (value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var messageTemplatesResponse, uniqueName, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, portalApiClient.fetchMessageTemplates()];
                    case 1:
                        messageTemplatesResponse = _a.sent();
                        uniqueName = isNameUnique(removeById(messageTemplatesResponse.data, existingMessageTemplateId), value);
                        return [2 /*return*/, !uniqueName ? errorMessage : undefined];
                    case 2:
                        err_1 = _a.sent();
                        // We can stay silent here as if the user manages to submit
                        // the API will throw an error for a matching name anyway.
                        return [2 /*return*/, undefined];
                    case 3: return [2 /*return*/];
                }
            });
        }); }, delay);
    }, [delay, errorMessage, portalApiClient]);
};
var removeById = function (messageTemplates, id) { return messageTemplates.filter(function (mt) { return mt.id !== id; }); };
var ɵ0 = removeById;
var isNameUnique = function (existingMessageTemplates, value) { return existingMessageTemplates.every(function (mt) { return mt.name !== value; }); };
var ɵ1 = isNameUnique;
export { ɵ0, ɵ1 };
