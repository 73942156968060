import { PipeTransform } from '@angular/core';
import { addMinutes, isAfter, isBefore, isWithinInterval, parseISO, } from 'date-fns';
var StatusToTextPipe = /** @class */ (function () {
    function StatusToTextPipe() {
    }
    StatusToTextPipe.prototype.transform = function (input, text) {
        switch (input) {
            case 'upcoming':
                return text.StatusUpcoming;
            case 'in progress':
                return text.StatusOngoing;
            case 'finished':
                return text.StatusFinished;
        }
    };
    return StatusToTextPipe;
}());
export { StatusToTextPipe };
var JoinDisabledPipe = /** @class */ (function () {
    function JoinDisabledPipe() {
    }
    JoinDisabledPipe.prototype.transform = function (input) {
        var windowStart = addMinutes(parseISO(input.scheduleddate), -10);
        var windowEnd = addMinutes(parseISO(input.scheduleddate), input.allowedduration);
        return !isWithinInterval(Date.now(), {
            start: windowStart,
            end: windowEnd,
        });
    };
    return JoinDisabledPipe;
}());
export { JoinDisabledPipe };
var UnInviteEnabledPipe = /** @class */ (function () {
    function UnInviteEnabledPipe() {
    }
    UnInviteEnabledPipe.prototype.transform = function (input) {
        var windowStart = addMinutes(parseISO(input.scheduleddate), -10);
        return !isAfter(Date.now(), windowStart);
    };
    return UnInviteEnabledPipe;
}());
export { UnInviteEnabledPipe };
var EditDisabledPipe = /** @class */ (function () {
    function EditDisabledPipe() {
    }
    EditDisabledPipe.prototype.transform = function (input) {
        if (!input.iseditable) {
            return true;
        }
        var windowStart = addMinutes(parseISO(input.scheduleddate), -10);
        return !isBefore(Date.now(), windowStart);
    };
    return EditDisabledPipe;
}());
export { EditDisabledPipe };
