import { FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { SimplePatient } from 'src/app/models/SimplePatient';
import { PatientSearchBase } from 'src/app/patients/PatientSearchBase';
import { PatientService } from 'src/app/patients/services/patient.service';
import { isPatient } from '../utils/validators';

export class PatientRequiringFormBase extends PatientSearchBase {
  protected _patient: SimplePatient;
  public patients$: Observable<SimplePatient[]>;

  constructor(
    _patientService: PatientService,
    _localisationService: LocalisationService,
  ) {
    super(_patientService, _localisationService);
  }

  protected setupSearchBox(formGroup: FormGroup) {
    super.observeSearchInput(formGroup.get('patientName'));
  }

  protected getFormPatient() {
    return [
      {
        value: this._patient || '',
        disabled: !!this._patient,
      },
      [Validators.required, Validators.minLength(1)],
      [isPatient(this.patients$)],
    ];
  }
}
