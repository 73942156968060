<div
  class="add-video-container"
  fxLayout="column"
  fxLayoutAlign="center stretch"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3 class="add-video-title">
      {{ text.AddVideo }}
    </h3>
    <button mat-icon-button type="button" (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div
    *ngIf="showOptions | async"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="30px"
  >
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="showForm('youtube')"
    >
      {{ text.YoutubeLink }}
    </button>
    <!-- <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="showForm('website')"
    >
      {{text.WebsiteLink}}
    </button> -->
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="uploadVideo()"
    >
      {{ text.Upload }}
    </button>
  </div>
  <div *ngIf="addForm | async">
    <form [formGroup]="linkForm">
      <div class="form" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex="1 1 auto" appearance="outline">
          <mat-label>{{ text.AddLink }}</mat-label>
          <input matInput type="text" formControlName="link" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button
          mat-raised-button
          type="button"
          class="portal-btn btn-primary"
          (click)="submitLink()"
        >
          {{ text.SubmitLink }}
        </button>
      </div>
    </form>
  </div>
</div>
