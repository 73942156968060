import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import { ContentTemplate } from '../../models/content-template';
import * as fromContent from '../../reducers';

@Component({
  selector: 'portal-use-template',
  templateUrl: './use-template.component.html',
  styleUrls: ['./use-template.component.scss'],
})
export class UseTemplateComponent implements OnDestroy {
  public templates$: Observable<ContentTemplate[]>;

  public type: string;
  public categoryId: number;

  public chooseTemplateVisible = false;

  private _sub: Subscription;

  constructor(
    private _store: Store<fromRoot.State>,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
  ) {
    this.templates$ = this._store.pipe(select(fromContent.getTemplates));
    this._sub = this._route.params
      .pipe(map(({ id, type }) => ({ id: +id, type })))
      .subscribe(({ id, type }) => {
        this.categoryId = id;
        this.type = type;
      });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  useTemplate(id: number): void {
    this._navigationService.navigate([
      'content',
      'doc',
      'create',
      this.type,
      this.categoryId,
      id,
    ]);
  }

  hideTemplateSelector() {
    this.chooseTemplateVisible = false;
  }

  showTemplateSelector() {
    this.chooseTemplateVisible = true;
  }

  useBlank() {
    this._navigationService.navigate([
      'content',
      'doc',
      'create',
      this.type,
      this.categoryId,
      0,
    ]);
  }
}
