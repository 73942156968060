import * as tslib_1 from "tslib";
import { ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientService } from 'src/app/patients/services/patient.service';
import { getInviteCode } from '../../../auth/reducers';
import * as fromSettings from '../../../settings/reducers';
import { PatientSearchBase } from '../../PatientSearchBase';
var PatientsListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PatientsListComponent, _super);
    function PatientsListComponent(_store, fb, _navigationService, _errorHandler, _patientService, _localisationService) {
        var _this = _super.call(this, _patientService, _localisationService) || this;
        _this._store = _store;
        _this.fb = fb;
        _this._navigationService = _navigationService;
        _this._errorHandler = _errorHandler;
        _this._subs = new Subscription();
        return _this;
    }
    PatientsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inviteCode$ = this._store.pipe(select(getInviteCode));
        this.patientListText$ = this._store.pipe(select(fromSettings.getSectionTranslations('PatientList')));
        this.setupSearchObservables(this._errorHandler);
        this._subs.add(this.patientListText$.subscribe(function (t) { return (_this.patientListText = t); }));
        this.setupSearchInput();
    };
    PatientsListComponent.prototype.setupSearchInput = function () {
        this.filterForm = this.fb.group({
            filter: [
                {
                    value: null,
                    disabled: false,
                },
            ],
        });
        this.observeSearchInput(this.filterForm.get('filter'));
    };
    PatientsListComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    PatientsListComponent.prototype.clearSearch = function () {
        this.filterForm.patchValue({
            filter: '',
        });
    };
    PatientsListComponent.prototype.gotoPatient = function (id) {
        this._navigationService.navigate(['patients', id]);
    };
    return PatientsListComponent;
}(PatientSearchBase));
export { PatientsListComponent };
