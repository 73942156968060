import * as React from 'react';
import {
  Container,
  IconContainer,
  InnerContainer,
  StyledText,
} from './LoadingError.styled';

interface Props {
  /**
   * Optional `data-testid` to apply to root element for testing.
   * Defaults to "LoadingError".
   */
  'data-testid'?: string;
  icon: React.ReactNode;
  message: string;
}

/**
 * Can be used to provide a message to the user that there has been
 * an error loading data.
 */
export const LoadingError: React.FC<Props> = ({
  children,
  'data-testid': dataTestId = 'LoadingError',
  icon,
  message,
}) => (
  <Container data-testid={dataTestId}>
    <InnerContainer>
      <IconContainer>{icon}</IconContainer>
      {/* Need newer version of TypeScript for `component`
        // @ts-ignore  */}
      <StyledText component="span" variant="h6">
        {message}
      </StyledText>
      {children}
    </InnerContainer>
  </Container>
);
