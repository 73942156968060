import { PipeTransform } from '@angular/core';
import { format, isToday, isTomorrow, parseISO, startOfDay } from 'date-fns';
var DateTransformPipe = /** @class */ (function () {
    function DateTransformPipe() {
    }
    DateTransformPipe.prototype.transform = function (date, today, tomorrow) {
        var parsedDate = date instanceof Date ? date : parseISO(date);
        var d = startOfDay(parsedDate);
        if (isToday(d)) {
            return today;
        }
        else if (isTomorrow(d)) {
            return tomorrow;
        }
        else {
            return format(d, 'd LLL yyyy');
        }
    };
    return DateTransformPipe;
}());
export { DateTransformPipe };
var TimeTransformPipe = /** @class */ (function () {
    function TimeTransformPipe() {
    }
    TimeTransformPipe.prototype.transform = function (date, today, tomorrow) {
        var parsedDate = date instanceof Date ? date : parseISO(date);
        return format(parsedDate, 'HH:mm');
    };
    return TimeTransformPipe;
}());
export { TimeTransformPipe };
