export var LoadMessageActionTypes;
(function (LoadMessageActionTypes) {
    /* ========= Server Side ========== */
    LoadMessageActionTypes["LoadMessagingSummary"] = "[LoadMessage] Load Messaging Summary";
    LoadMessageActionTypes["LoadMessagingSummarySuccess"] = "[LoadMessage] Load Messaging Summary Success";
    LoadMessageActionTypes["LoadMessagingSummaryError"] = "[LoadMessage] Load Messaging Summary Error";
    LoadMessageActionTypes["LoadMessages"] = "[LoadMessage] Load Messages";
    LoadMessageActionTypes["LoadMessagesSuccess"] = "[LoadMessage] Load Messages Success";
    LoadMessageActionTypes["LoadMessagesError"] = "[LoadMessage] Load Messages Error";
    LoadMessageActionTypes["LoadThread"] = "[LoadMessage] Load Thread";
    LoadMessageActionTypes["LoadThreadSuccess"] = "[LoadMessage] Load Thread Success";
    LoadMessageActionTypes["LoadThreadError"] = "[LoadMessage] Load Thread Error";
    LoadMessageActionTypes["LoadNewerThreadMessages"] = "[LoadMessage] Load Newer Thread Messages";
    LoadMessageActionTypes["LoadNewerThreadMessagesSuccess"] = "[LoadMessage] Load Newer Thread Messages Success";
    LoadMessageActionTypes["RefreshMessaging"] = "[LoadMessage] Refresh Core Message Data";
    LoadMessageActionTypes["RefreshAllMessages"] = "[LoadMessage] Refresh Data In Messages Tab";
    LoadMessageActionTypes["LoadNewestMessages"] = "[LoadMessage] Load newest messages";
    LoadMessageActionTypes["LoadNewestMessagesSuccess"] = "[LoadMessage] Load newest messages success";
    LoadMessageActionTypes["LoadNewestMessagesError"] = "[LoadMessage] Load newest messages error";
    /* ========= Client Side ========== */
    LoadMessageActionTypes["LoadMessageTypes"] = "[LoadMessage] Load Message Types";
    LoadMessageActionTypes["LoadMessageTypesSuccess"] = "[LoadMessage] Load Message Types Success";
    LoadMessageActionTypes["LoadMessageTypesFailure"] = "[LoadMessage] Load Message Types Failure";
    LoadMessageActionTypes["LoadingList"] = "[LoadMessage] Loading Message List";
    LoadMessageActionTypes["LoadedList"] = "[LoadMessage] Loaded Message List";
    LoadMessageActionTypes["LoadingThread"] = "[LoadMessage] Loading Message Thread";
    LoadMessageActionTypes["LoadedThread"] = "[LoadMessage] Loaded Message Thread";
    LoadMessageActionTypes["AddMessageToThread"] = "[LoadMessage] Add Message To Thread";
    LoadMessageActionTypes["ShowPushNotification"] = "[LoadMessage] Show Push Notification";
    LoadMessageActionTypes["ClosedPushNotification"] = "[LoadMessage] Closed Push Notification";
    LoadMessageActionTypes["ClickedPushNotification"] = "[LoadMessage] Click Push Notification";
    LoadMessageActionTypes["UpdateLatestMessage"] = "[LoadMessage] Update Latest Message";
    LoadMessageActionTypes["UpdateLastSeen"] = "[LoadMessage] Update Last Seen Message";
    LoadMessageActionTypes["SetIsLoadingConversations"] = "[LoadMessage] Set Loading";
})(LoadMessageActionTypes || (LoadMessageActionTypes = {}));
/**
 * Action Creators
 */
// ============== Server Side Actions ==================== //
var LoadMessagingSummary = /** @class */ (function () {
    function LoadMessagingSummary() {
        this.type = LoadMessageActionTypes.LoadMessagingSummary;
    }
    return LoadMessagingSummary;
}());
export { LoadMessagingSummary };
var LoadMessagingSummarySuccess = /** @class */ (function () {
    function LoadMessagingSummarySuccess(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessagingSummarySuccess;
    }
    return LoadMessagingSummarySuccess;
}());
export { LoadMessagingSummarySuccess };
var LoadMessagingSummaryError = /** @class */ (function () {
    function LoadMessagingSummaryError(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessagesError;
    }
    return LoadMessagingSummaryError;
}());
export { LoadMessagingSummaryError };
var LoadMessages = /** @class */ (function () {
    function LoadMessages(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessages;
    }
    return LoadMessages;
}());
export { LoadMessages };
var LoadMessagesSuccess = /** @class */ (function () {
    function LoadMessagesSuccess(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessagesSuccess;
    }
    return LoadMessagesSuccess;
}());
export { LoadMessagesSuccess };
var LoadMessagesError = /** @class */ (function () {
    function LoadMessagesError(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessagesError;
    }
    return LoadMessagesError;
}());
export { LoadMessagesError };
var LoadNewestMessages = /** @class */ (function () {
    function LoadNewestMessages(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadNewestMessages;
    }
    return LoadNewestMessages;
}());
export { LoadNewestMessages };
var LoadNewestMessagesSuccess = /** @class */ (function () {
    function LoadNewestMessagesSuccess(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadNewestMessagesSuccess;
    }
    return LoadNewestMessagesSuccess;
}());
export { LoadNewestMessagesSuccess };
var LoadNewestMessagesError = /** @class */ (function () {
    function LoadNewestMessagesError(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadNewestMessagesError;
    }
    return LoadNewestMessagesError;
}());
export { LoadNewestMessagesError };
var LoadThread = /** @class */ (function () {
    // Load Messages require id of message thread to load
    function LoadThread(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadThread;
    }
    return LoadThread;
}());
export { LoadThread };
var LoadThreadSuccess = /** @class */ (function () {
    // returns array of messages without patient info
    function LoadThreadSuccess(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadThreadSuccess;
    }
    return LoadThreadSuccess;
}());
export { LoadThreadSuccess };
var LoadThreadError = /** @class */ (function () {
    function LoadThreadError(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadThreadError;
    }
    return LoadThreadError;
}());
export { LoadThreadError };
var LoadNewerThreadMessages = /** @class */ (function () {
    function LoadNewerThreadMessages(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadNewerThreadMessages;
    }
    return LoadNewerThreadMessages;
}());
export { LoadNewerThreadMessages };
var LoadNewerThreadMessagesSuccess = /** @class */ (function () {
    function LoadNewerThreadMessagesSuccess(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadNewerThreadMessagesSuccess;
    }
    return LoadNewerThreadMessagesSuccess;
}());
export { LoadNewerThreadMessagesSuccess };
var RefreshMessaging = /** @class */ (function () {
    function RefreshMessaging() {
        this.type = LoadMessageActionTypes.RefreshMessaging;
    }
    return RefreshMessaging;
}());
export { RefreshMessaging };
// ============== Client Side Actions ==================== //
var LoadMessageTypes = /** @class */ (function () {
    function LoadMessageTypes() {
        this.type = LoadMessageActionTypes.LoadMessageTypes;
    }
    return LoadMessageTypes;
}());
export { LoadMessageTypes };
var LoadMessageTypesSuccess = /** @class */ (function () {
    function LoadMessageTypesSuccess(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessageTypesSuccess;
    }
    return LoadMessageTypesSuccess;
}());
export { LoadMessageTypesSuccess };
var LoadMessageTypesFailure = /** @class */ (function () {
    function LoadMessageTypesFailure(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.LoadMessageTypesFailure;
    }
    return LoadMessageTypesFailure;
}());
export { LoadMessageTypesFailure };
var LoadingList = /** @class */ (function () {
    function LoadingList() {
        this.type = LoadMessageActionTypes.LoadingList;
    }
    return LoadingList;
}());
export { LoadingList };
var LoadedList = /** @class */ (function () {
    function LoadedList() {
        this.type = LoadMessageActionTypes.LoadedList;
    }
    return LoadedList;
}());
export { LoadedList };
var LoadingThread = /** @class */ (function () {
    function LoadingThread() {
        this.type = LoadMessageActionTypes.LoadingThread;
    }
    return LoadingThread;
}());
export { LoadingThread };
var LoadedThread = /** @class */ (function () {
    function LoadedThread() {
        this.type = LoadMessageActionTypes.LoadedThread;
    }
    return LoadedThread;
}());
export { LoadedThread };
var AddMessageToThread = /** @class */ (function () {
    function AddMessageToThread(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.AddMessageToThread;
    }
    return AddMessageToThread;
}());
export { AddMessageToThread };
var ShowPushNotification = /** @class */ (function () {
    function ShowPushNotification(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.ShowPushNotification;
    }
    return ShowPushNotification;
}());
export { ShowPushNotification };
var ClosedPushNotification = /** @class */ (function () {
    function ClosedPushNotification() {
        this.type = LoadMessageActionTypes.ClosedPushNotification;
    }
    return ClosedPushNotification;
}());
export { ClosedPushNotification };
var ClickedPushNotification = /** @class */ (function () {
    function ClickedPushNotification() {
        this.type = LoadMessageActionTypes.ClickedPushNotification;
    }
    return ClickedPushNotification;
}());
export { ClickedPushNotification };
var UpdateLatestMessage = /** @class */ (function () {
    function UpdateLatestMessage() {
        this.type = LoadMessageActionTypes.UpdateLatestMessage;
    }
    return UpdateLatestMessage;
}());
export { UpdateLatestMessage };
var UpdateLastSeen = /** @class */ (function () {
    function UpdateLastSeen() {
        this.type = LoadMessageActionTypes.UpdateLastSeen;
    }
    return UpdateLastSeen;
}());
export { UpdateLastSeen };
var SetIsLoadingConversations = /** @class */ (function () {
    function SetIsLoadingConversations(payload) {
        this.payload = payload;
        this.type = LoadMessageActionTypes.SetIsLoadingConversations;
    }
    return SetIsLoadingConversations;
}());
export { SetIsLoadingConversations };
