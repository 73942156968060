import { MenuItem } from '@material-ui/core';
import { AssignClinicUsers } from '@react/lib/api/types';
import { Action } from '@react/pages/conversations/constants';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { AssignMenuItem } from './AssignMenuItem';
import { StyledMenu } from './Menu.styled';
import NestedMenuItem, { NestedMenuItemProps } from './NestedMenuItem';

export interface DropdownMenuItem {
  [key: string]: any;
  name: string;
  children?: DropdownMenuItem[];
}

export interface DropdownMenuProps {
  menu: DropdownMenuItem[];
  children: ReactElement;
  className?: string;
  onClick: (item: DropdownMenuItem) => void;
  NestedMenuProps?: NestedMenuItemProps['MenuProps'];
  assignClinicUsers?: AssignClinicUsers;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  menu,
  children,
  onClick,
  className,
  NestedMenuProps,
  assignClinicUsers,
  ...dropdownProps
}) => {
  if (!children) {
    throw new TypeError('children trigger element is required');
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item?: DropdownMenuItem) => {
    setAnchorEl(null);
    if (item) {
      onClick(item);
    }
  };

  function showClinicUserMenuAction(action: string): boolean {
    return Boolean(
      action === Action.CONVERSATION_ASSIGN ||
        action === Action.CONVERSATION_UNASSIGN,
    );
  }

  return (
    <>
      <span onClick={handleMenuOpen}>{children}</span>
      <StyledMenu
        className={clsx('dropdown-menu', className)}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => handleClose()}
        onClick={(event) => event.stopPropagation()}
        {...dropdownProps}
      >
        {menu.map((item) => {
          if (showClinicUserMenuAction(item.actionId) && assignClinicUsers) {
            return (
              <AssignMenuItem
                key={item.name}
                item={item}
                isOpen={isOpen}
                nestedMenuProps={NestedMenuProps}
                assignClinicUsers={assignClinicUsers}
                handleClose={handleClose}
              />
            );
          }

          if (!item.children) {
            return (
              <MenuItem key={item.name} onClick={() => handleClose(item)}>
                {item.name}
              </MenuItem>
            );
          }

          return (
            // @ts-ignore
            <NestedMenuItem
              key={item.name}
              label={item.name}
              parentMenuOpen={isOpen}
              MenuProps={NestedMenuProps}
            >
              {item.children.map((child) => (
                <MenuItem key={child.name} onClick={() => handleClose(child)}>
                  {child.name}
                </MenuItem>
              ))}
            </NestedMenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};
