import * as tslib_1 from "tslib";
import { MatPaginatorIntl } from '@angular/material';
import { select, Store } from '@ngrx/store';
import * as fromSettings from '../../settings/reducers';
var CustomMatPaginator = /** @class */ (function (_super) {
    tslib_1.__extends(CustomMatPaginator, _super);
    function CustomMatPaginator(_store) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        // Set default english values
        _this.itemsPerPageLabel = 'Items per page';
        _this.firstPageLabel = 'First page';
        _this.previousPageLabel = 'Previous page';
        _this.nextPageLabel = 'Next page';
        _this.lastPageLabel = 'Last page';
        // Watch translations object and replace text for paginator as
        // required, depending on language
        _this._store
            .pipe(select(fromSettings.getSectionTranslations('NewContentList')))
            .subscribe(function (t) {
            _this.itemsPerPageLabel = t.ItemsPerPage;
            _this.firstPageLabel = t.FirstPage;
            _this.previousPageLabel = t.PreviousPage;
            _this.nextPageLabel = t.NextPage;
            _this.lastPageLabel = t.LastPage;
        });
        return _this;
    }
    return CustomMatPaginator;
}(MatPaginatorIntl));
export { CustomMatPaginator };
