import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { MicOff, VideocamOff } from '@material-ui/icons';

export const ParticipantDisplayNameText = styled(Typography)`
  color: #ffffff;
  margin-right: 6px;
`;
export const ParticipantVideoContainer = styled('div')`
  min-width: 256px;
  aspect-ratio: 16/9;
  position: relative;
  flex: 1;
  max-height: 100vh;
`;

export const ParticipantVideoIconsContainer = styled('div')`
  position: absolute;
  height: 30px;
  bottom: 12px;
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ParticipantMicIconContainer = styled('div')`
  background-color: #1f2124;
  margin-right: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ParticipantVideoIconContainer = styled('div')`
  background-color: #1f2124;
  margin-right: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ParticipantMicOffIcon = styled(MicOff)`
  color: #ffffff;
`;
export const ParticipantVideocamOffIcon = styled(VideocamOff)`
  color: #ffffff;
`;
