import styled from '@emotion/styled';
import { Paper, Table, TableHead, TableRow } from '@material-ui/core';
import * as React from 'react';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 32rem;
`;

export const FieldWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(0.75)}px 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: ${({ children }) =>
    React.Children.toArray(children).filter((x) => !!x).length > 1
      ? 'space-between'
      : 'flex-end'};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const EmptyContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  height: 400px;
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;

export const StyledTableContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTable = styled(Table)`
  table-layout: fixed;
`;

export const StyledTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: #757575;
    vertical-align: top;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f0f0f7;
    cursor: pointer;
  }

  td {
    vertical-align: top;
  }
`;
