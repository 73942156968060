import * as tslib_1 from "tslib";
import libphonenumber from 'google-libphonenumber';
import { first, map } from 'rxjs/operators';
var phoneUtil;
if (libphonenumber) {
    phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
}
// Validator function to figure out if patient entry is valid
export function isPatient(patients$) {
    return function (c) {
        return patients$.pipe(map(function (patients) {
            if (!c.value || !patients.find(function (p) { return p.Id === c.value.Id; })) {
                return { notPatient: true };
            }
            return null;
        }), first());
    };
}
export function checkMobileNumber(mobileFieldName, countryCodeFieldName) {
    return function (group) {
        var mobileNumber = "" + group.controls[mobileFieldName].value;
        var countryCode = group.controls[countryCodeFieldName].value;
        if (mobileNumber &&
            mobileNumber.length > 0 &&
            countryCode &&
            countryCode.length > 0) {
            // Attempt to convert mobileNumber and countryCode into number
            var number = void 0;
            try {
                number = phoneUtil.parseAndKeepRawInput(mobileNumber, countryCode);
            }
            catch (e) {
                return { 'invalid-phone': true };
            }
            // check is valid and possible
            var isPossible = void 0;
            var isValid = void 0;
            try {
                isPossible = phoneUtil.isPossibleNumber(number);
                isValid = phoneUtil.isValidNumber(number);
            }
            catch (e) {
                return { 'invalid-phone': true };
            }
            if (!isPossible || !isValid) {
                return { 'invalid-phone': true };
            }
            // check phone number is a mobile number
            var phoneType = void 0;
            try {
                phoneType = phoneUtil.getNumberType(number);
            }
            catch (e) {
                return { 'not-mobile': true };
            }
            // 1 = MOBILE, 2 = FIXED_LINE_OR_MOBILE
            if (phoneType !== 1 && phoneType !== 2) {
                return { 'not-mobile': true };
            }
        }
        else {
            return { 'no-number': true };
        }
        // no errors!
        return null;
    };
}
export function valuesMatch(targetKey, toMatchKey) {
    return function (group) {
        var target = group.controls[targetKey];
        var toMatch = group.controls[toMatchKey];
        var isMatch = target.value === toMatch.value;
        if (!isMatch) {
            return { mismatch: true };
        }
        return null;
    };
}
export function passwordComplexity(control) {
    var currentValue = control.value;
    var errors = [];
    // contains one number
    if (!/[0-9]/g.test(currentValue)) {
        errors.push('no-number');
    }
    // contains one uppercase letter
    if (!/[A-Z]/g.test(currentValue)) {
        errors.push('no-uppercase');
    }
    // contains one lowercase character
    if (!/[a-z]/g.test(currentValue)) {
        errors.push('no-lowercase');
    }
    // is at least 8 characters
    if (currentValue.length < 8) {
        errors.push('minlength');
    }
    if (errors.length === 0) {
        return null;
    }
    return { complexity: true };
}
export function setErrors(error, control) {
    control.setErrors(tslib_1.__assign({}, control.errors, error));
}
export function removeErrors(keys, control) {
    var remainingErrors = keys.reduce(function (errors, key) {
        delete errors[key];
        return errors;
    }, tslib_1.__assign({}, control.errors));
    control.setErrors(Object.keys(remainingErrors).length > 0 ? remainingErrors : null);
}
