import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { ActiveToast } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ConfirmActionComponent } from 'src/app/core/components/confirm-action/confirm-action.component';
import { VideoCallContainerComponent } from '../../../twilio/containers/video-call.container/video-call.container.component';
import { ApiVideoCall, JoinVideoCallResponse } from '../../responses';

import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromVideoCalls from '../../reducers';
import { PermissionsService } from '../../services/permissions-service';

@Component({
  selector: 'portal-video-call-modal',
  templateUrl: 'video-call-modal.container.html',
  styleUrls: ['./video-call-modal.container.scss'],
})
export class VideoCallModalContainer implements OnInit, AfterViewInit {
  public invitePartnerInCall: (call: JoinVideoCallResponse) => void;
  public unInvitePartnerInCall: (call: JoinVideoCallResponse) => void;
  public isPartnerInvited: boolean;
  public videoCall: ApiVideoCall;

  @ViewChild(VideoCallContainerComponent)
  private _videoCallComponent: VideoCallContainerComponent;

  private _subs = new Subscription();
  private _videoCallModalText$: Observable<any>;
  private _toastRef: ActiveToast<any>;
  public _isPartnerInvitedSub$: Observable<boolean>;

  private _videoCallModalText: any;
  private _joinResponse: JoinVideoCallResponse;
  private _toastRef$: Observable<ActiveToast<any>>;

  constructor(
    private readonly _permissionsService: PermissionsService,
    private _store: Store<fromRoot.State>,
    private _store1: Store<fromVideoCalls.AppState>,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<VideoCallModalContainer>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this._joinResponse = data.joinResponse;
    this.invitePartnerInCall = data.invitePartnerInCall;
    this.unInvitePartnerInCall = data.unInvitePartnerInCall;
    this.videoCall = data.joinResponse.videoCall;
    this._toastRef$ = this._store.pipe(select(fromRoot.getToastRef));
    this._videoCallModalText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallModal')),
    );
    this._isPartnerInvitedSub$ = this._store1.pipe(
      select(fromVideoCalls.getInCallInviteFlagSelector),
    );
  }

  ngOnInit() {
    this._subs.add(
      this._videoCallModalText$.subscribe((t) => {
        this._videoCallModalText = t;
      }),
    );

    this._subs.add(
      this._isPartnerInvitedSub$.subscribe((t) => {
        this.isPartnerInvited = t;
      }),
    );
  }

  ngAfterViewInit(): void {
    this._videoCallComponent.localIdentity =
      this._joinResponse.response.participant.identity;
    this._videoCallComponent.roomName = this._joinResponse.response.room;
    this._videoCallComponent.token = this._joinResponse.response.token;
    this._videoCallComponent.leaveConfirmationCallback = async () => {
      return await this.displaySureLeaveDialog();
    };

    this._subs.add(
      this._videoCallComponent.errorConnecting.subscribe((e) => {
        this.displayOtherErrorNotification();
        this.closeModal();
      }),
    );

    this._subs.add(
      this._videoCallComponent.errorDevices.subscribe((e) => {
        this.displayNoCameraMicErrorNotification();
        this.closeModal();
      }),
    );

    this._subs.add(
      this._videoCallComponent.leftCall.subscribe((e) => {
        if (this._videoCallComponent.isReconnecting.getValue()) {
          this.displayConnectionErrorNotification();
        }
        this.closeModal();
      }),
    );

    this._subs.add(
      this._videoCallComponent.connected.subscribe((c) => {
        this.displayConnectedNotification();
      }),
    );

    this._subs.add(
      this._videoCallComponent.participantDisconnected.subscribe((p) => {
        this.displayParticipantDisconnectedNotification();
      }),
    );

    this._subs.add(
      this._videoCallComponent.isReconnecting.subscribe((isReconnecting) => {
        if (isReconnecting) {
          this.displayReconnectingNotification();
        }
      }),
    );

    this._subs.add(
      this._toastRef$.subscribe((tr) => {
        this._toastRef = tr;
      }),
    );

    setTimeout(() => {
      this.displayPermissionsDialog();
      this._videoCallComponent.nooneHereText =
        this._videoCallModalText.NooneHere(
          this._joinResponse.videoCall.patientname,
        );
    }, 0);
  }

  private async displayPermissionsDialog() {
    const [cameraPermission, microphonePermission] = await Promise.all([
      this._permissionsService.hasCameraPermission(),
      this._permissionsService.hasMicrophonePermission(),
    ]);

    if (cameraPermission === 'granted' && microphonePermission === 'granted') {
      this.initializeCall();
      return;
    }

    const confirmDialog = this._dialog.open(ConfirmActionComponent, {
      panelClass: 'reduced-padding-dialog',
      width: '40vw',
      data: {
        message: this._videoCallModalText.NeedPermissions,
        text: {
          Cancel: this._videoCallModalText.Cancel,
          Confirm: this._videoCallModalText.Allow,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (!result) {
        this.closeModal();
        this.displayNoCameraMicErrorNotification();
      } else {
        this.initializeCall();
      }
    });
  }

  private displaySureLeaveDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmDialog = this._dialog.open(ConfirmActionComponent, {
        data: {
          message: this._videoCallModalText.SureLeave,
          text: {
            Cancel: this._videoCallModalText.Cancel,
            Confirm: this._videoCallModalText.Yes,
          },
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        resolve(!!result);
      });
    });
  }

  private initializeCall() {
    this._videoCallComponent.initialize();
  }

  private closeModal() {
    this._dialogRef.close({});
  }

  private displayOtherErrorNotification() {
    this.displayErrorNotification(this._videoCallModalText.Error);
  }

  private displayNoCameraMicErrorNotification() {
    this.displayErrorNotification(this._videoCallModalText.NoCameraMic);
  }

  private displayConnectionErrorNotification() {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: null,
        message: this._videoCallModalText.CallNotReconnected,
        title: this._videoCallModalText.CallEnded,
        timeout: 5000,
        type: 'warning',
      }),
    );
  }

  private displayReconnectingNotification() {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: null,
        message: this._videoCallModalText.AttemptingReconnect,
        title: this._videoCallModalText.Reconnecting,
        timeout: 5000,
        type: 'info',
      }),
    );
  }

  private displayParticipantDisconnectedNotification() {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: null,
        message: this._videoCallModalText.ParticipantDisconnectedMessage,
        title: this._videoCallModalText.ParticipantDisconnected,
        timeout: 5000,
        type: 'info',
      }),
    );
  }

  private displayErrorNotification(errorMessage: string) {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: errorMessage,
        title: this._videoCallModalText.Error,
        timeout: 0,
        type: 'error',
      }),
    );
  }

  private displayConnectedNotification() {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: this._videoCallModalText.Connected,
        title: this._videoCallModalText.Success,
        timeout: 3000,
        type: 'success',
      }),
    );
  }
}
