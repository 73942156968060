import * as React from 'react';

export const MessageIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    className={className}
    version="1.1"
    viewBox="0 0 60 58"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-717 -232)" fill="#CFC3F2" fillRule="nonzero">
        <g transform="translate(204 69)">
          <g transform="translate(513 163)">
            <path
              id="Path"
              d="m29.998 39.982c-0.99242 0-1.9848-0.23498-2.8923-0.70744l-26.433-13.794c-0.61245-0.31997-0.84743-1.0749-0.52996-1.6874 0.31997-0.61245 1.0724-0.84743 1.6874-0.52996l26.435 13.791c1.0874 0.56745 2.3823 0.56745 3.4697 0l26.435-13.791c0.61495-0.31747 1.3674-0.082493 1.6874 0.52996s0.082493 1.3674-0.52996 1.6874l-26.438 13.791c-0.90743 0.47246-1.8998 0.70994-2.8923 0.70994z"
            />
            <path
              id="Path"
              d="m54.996 57.495h-49.996c-2.7573 0-4.9996-2.2423-4.9996-4.9996v-31.472c0-1.8698 1.0349-3.5697 2.6998-4.4346l5.4721-2.8498c0.61245-0.31747 1.3674-0.079994 1.6849 0.52996 0.31747 0.61245 0.079993 1.3674-0.52996 1.6849l-5.4746 2.8498c-0.83493 0.43746-1.3524 1.2874-1.3524 2.2198v31.472c0 1.3774 1.1224 2.4998 2.4998 2.4998h49.996c1.3774 0 2.4998-1.1224 2.4998-2.4998v-31.472c0-0.93492-0.51746-1.7824-1.3524-2.2148l-5.4746-2.8523c-0.61245-0.31747-0.84993-1.0724-0.52996-1.6849 0.31497-0.61245 1.0699-0.85243 1.6849-0.52996l5.4746 2.8498c1.6624 0.86243 2.6973 2.5623 2.6973 4.4321v31.472c0 2.7573-2.2423 4.9996-4.9996 4.9996z"
            />
            <path
              id="Path"
              d="m41.247 27.498h-22.498c-0.68994 0-1.2499-0.55995-1.2499-1.2499 0-0.68994 0.55995-1.2499 1.2499-1.2499h22.498c0.68994 0 1.2499 0.55995 1.2499 1.2499 0 0.68994-0.55995 1.2499-1.2499 1.2499z"
            />
            <path
              id="Path"
              d="m41.247 19.998h-22.498c-0.68994 0-1.2499-0.55995-1.2499-1.2499 0-0.68994 0.55995-1.2499 1.2499-1.2499h22.498c0.68994 0 1.2499 0.55995 1.2499 1.2499 0 0.68994-0.55995 1.2499-1.2499 1.2499z"
            />
            <path
              id="a"
              d="m31.247 12.499h-12.499c-0.68994 0-1.2499-0.55995-1.2499-1.2499 0-0.68994 0.55995-1.2499 1.2499-1.2499h12.499c0.68994 0 1.2499 0.55995 1.2499 1.2499 0 0.68994-0.55995 1.2499-1.2499 1.2499z"
            />
            <path d="m51.246 29.548c-0.68994 0-1.2499-0.55995-1.2499-1.2499v-22.048c0-2.0673-1.6824-3.7497-3.7497-3.7497h-32.497c-2.0673 0-3.7497 1.6824-3.7497 3.7497v22.048c0 0.68994-0.55995 1.2499-1.2499 1.2499-0.68994 0-1.2499-0.55995-1.2499-1.2499v-22.048c0-3.4447 2.8048-6.2495 6.2495-6.2495h32.497c3.4447 0 6.2495 2.8048 6.2495 6.2495v22.048c0 0.68994-0.55995 1.2499-1.2499 1.2499z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
