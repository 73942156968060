import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import { simplePatientToPatient } from '@react/lib/models/patient';
import { NewMessageModal } from '@react/pages/conversations/components';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/reducers';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
var containerElementName = 'newMessageComponentReact';
var NewMessageReactComponent = /** @class */ (function () {
    function NewMessageReactComponent(_store, _cognito, data) {
        this._store = _store;
        this._cognito = _cognito;
        this.data = data;
        this._subscriptions = new Subscription();
    }
    NewMessageReactComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4];
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user,
            };
            _this.render();
        }));
    };
    NewMessageReactComponent.prototype.ngAfterViewInit = function () {
        this.render();
    };
    NewMessageReactComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
        this._subscriptions.unsubscribe();
    };
    NewMessageReactComponent.prototype.render = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                ReactDOM.render(React.createElement(Root, tslib_1.__assign({}, this._props, { authService: this._cognito, dispatch: this._store.dispatch.bind(this._store), children: React.createElement(NewMessageModal, {
                        open: false,
                        dialogWrapper: false,
                        refreshPageOnLinkClicked: true,
                        onClose: this.data.close,
                        patientInputProps: {
                            defaultPatient: simplePatientToPatient(this.data.patient),
                            disabled: true,
                        },
                    }) })), this.containerRef.nativeElement);
                return [2 /*return*/];
            });
        });
    };
    return NewMessageReactComponent;
}());
export { NewMessageReactComponent };
