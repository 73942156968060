<mat-card class="card-container">
  <perfect-scrollbar
    class="email-otp-form-container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div #formContainer class="form-container">
      <div class="form-element">
        <form
          class="form"
          [formGroup]="OTPForm"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxFlex="0 1 calc(510px)"
        >
          <div class="OTPTitle">
            <div>{{ text.OTPTitle }}</div>
          </div>
          <div class="OTPSubtitle">
            <div>{{ text.OTPSubtitle }}</div>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>{{ text.Code }}</mat-label>
            <input
              matInput
              class="main-input"
              type="text"
              formControlName="code"
              required
            />
            <mat-error
              *ngIf="
                (OTPForm.get('code').touched || OTPForm.get('code').dirty) &&
                !OTPForm.get('code').valid
              "
              class="error"
            >
              {{ text.CodeMissing }}
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="CodeError === true" class="error">
            {{ text.InvalidCode }}
          </mat-error>

          <div *ngIf="isLoggingIn" fxLayoutAlign="center center">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="20px"
          >
            <button
              mat-raised-button
              type="button"
              (click)="cancelOTP()"
              class="portal-btn btn-primary btn-submit"
            >
              {{ text.CodeCancel }}
            </button>
            <button
              mat-raised-button
              type="submit"
              (click)="submitOTP()"
              (keyup.enter)="submitOTP()"
              [disabled]="OTPForm.status === 'INVALID'"
              class="portal-btn btn-primary btn-submit btn-bright"
            >
              {{ text.Login }}
            </button>
          </div>

          <div class="OTPInstructions">
            <div>
              {{ text.OTPInstructions }}
              <a class="supportEmail" href="mailto:{{ text.SupportEmail }}">{{
                text.SupportEmail
              }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </perfect-scrollbar>
</mat-card>
