import { Component, OnInit } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromAuth from '../../reducers';

import * as AuthActions from '../../actions/auth.actions';

import { NavigationService } from 'src/app/core/services/navigation.service';
import { VerifyOTPParams } from '../../models/user';

@Component({
  selector: 'portal-email-otp-page',
  template: `
    <portal-email-otp-form
      [text]="toastText$ | async"
      [loggingIn]="loggingIn$ | async"
      [CodeError]="OTPError$ | async"
      [cognitoUser]="cognitoUser$ | async"
      (verifyOTP)="verifyOTP($event)"
      (cancelOTPLogin)="cancelOTPLogin()"
    >
    </portal-email-otp-form>
  `,
  styles: [],
})
export class EmailOTPPageContainer implements OnInit {
  public cognitoUser$: Observable<CognitoUser | any>;
  public loggingIn$: Observable<boolean>;
  public toastText$: Observable<any>;
  public loginText$: Observable<any>;
  public OTPError$: Observable<boolean>;

  constructor(
    private _store: Store<fromAuth.State>,
    private _storeRoot: Store<fromRoot.State>,
    private _navigationService: NavigationService,
  ) {
    this._store.dispatch(new AuthActions.ClearLoginError());

    this.toastText$ = this._storeRoot.pipe(
      select(fromSettings.getSectionTranslations('Authentication')),
    );
    this.loginText$ = this._storeRoot.pipe(
      select(fromSettings.getSectionTranslations('Login')),
    );
    this.cognitoUser$ = this._store.pipe(select(fromAuth.getCognitoUser));
    this.loggingIn$ = this._store.pipe(select(fromAuth.isLoggingIn));
    this.OTPError$ = this._store.pipe(select(fromAuth.OTPError));

    this._store.dispatch(new AuthActions.ClearCodeGenerated());
  }

  public ngOnInit() {
    this._store.dispatch(new AuthActions.EnableForm());
  }

  public verifyOTP({ user, code, toast }: VerifyOTPParams) {
    this._store.dispatch(new AuthActions.VerifyOTP({ user, code, toast }));
  }

  public cancelOTPLogin() {
    this._navigationService.navigate(['/login']);
  }
}
