import {
  Component,
  ErrorHandler,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import * as dateFns from 'date-fns';
import range from 'lodash/range';
import { Observable, Subscription } from 'rxjs';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientService } from 'src/app/patients/services/patient.service';
import {
  VideoCallEffectsCompletedResponse,
  VideoCallEffectsResposeType,
} from 'src/app/video-calls/responses';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as VideoCallActions from '../../../video-calls/actions/video-call.actions';
import {
  DeleteVideoCallAction,
  ResetEffectsStateAction,
} from '../../../video-calls/actions/video-call.actions';
import { UpdateVideoCall } from '../../../video-calls/models/UpdateVideoCall';
import * as fromVideoCalls from '../../../video-calls/reducers';
import { DisplayToastAction } from '../../actions/toast.actions';
import { ConfirmActionComponent } from '../../components/confirm-action/confirm-action.component';
import { PatientRequiringFormBase } from '../patient-requiring-form-base';

@Component({
  selector: 'portal-new-video-call',
  templateUrl: 'new-video-call.component.html',
  styleUrls: ['./new-video-call.component.scss'],
})
export class NewVideoCallComponent
  extends PatientRequiringFormBase
  implements OnInit, OnDestroy
{
  public newVideoCallText$: Observable<any>;
  public effectsCompleted$: Observable<VideoCallEffectsCompletedResponse>;

  public newVideoCallForm: FormGroup;
  public newVideoCallText: any;
  public durations = range(15, 125, 5);
  public isEdit = false;

  public selectedDate: Date;
  public minDate = dateFns.startOfDay(Date.now());

  private _subs = new Subscription();
  private _toastText: any;
  private _toastRef: any;

  private _languageCode$: Observable<string>;
  private _toastText$: Observable<any>;
  private _toastRef$: Observable<any>;

  constructor(
    private _store: Store<fromVideoCalls.AppState>,
    private angulartics2: Angulartics2,
    public dialogRef: MatDialogRef<NewVideoCallComponent>,
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _dateAdapter: DateAdapter<any>,
    private _errorHandler: ErrorHandler,
    @Inject(MAT_DIALOG_DATA) public data: any,
    _patientService: PatientService,
    _localisationService: LocalisationService,
  ) {
    super(_patientService, _localisationService);
    this.selectedDate = new Date(Date.now());
    this.isEdit = !!this.data.id;

    this._toastText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallsToast')),
    );

    this.angulartics2.eventTrack.next({
      action: 'Initialise New Video Call',
      properties: { category: 'Video Call', label: 'New Video Call' },
    });

    this.newVideoCallText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewVideoCall')),
    );

    this._languageCode$ = this._store.pipe(select(fromAuth.getLanguageCode));

    this.effectsCompleted$ = this._store.pipe(
      select(fromVideoCalls.getEffectsCompletedResponse),
    );

    this._toastRef$ = _store.pipe(select(fromRoot.getToastRef));

    // if patient data passed to modal then use this for the form
    if (this.data.patient) {
      this._patient = data.patient;
    }

    this.isFutureDate = this.isFutureDate.bind(this);
  }

  isFutureDate(date: Date) {
    return (
      dateFns.isSameDay(date, this._localisationService.getZonedToday()) ||
      dateFns.isAfter(date, this._localisationService.getZonedToday())
    );
  }

  dateInPastValidator(): ValidatorFn {
    return (control) => {
      const date = control.value as Date | null;

      if (date == null) {
        return null;
      }

      // Since the value of this input affects the startTime input
      // we should request that is revalidated.
      if (control.parent != null) {
        control.parent.get('startTime').updateValueAndValidity({
          onlySelf: true,
        });
      }

      return !this.isFutureDate(date) ? { inPast: true } : null;
    };
  }

  startTimeInPastValidator(): ValidatorFn {
    return (control) => {
      if (control.parent == null) {
        return null;
      }

      const appointmentTime = this.createDateFromFields(
        control.parent as FormGroup,
      );

      if (!appointmentTime) {
        return null;
      }

      return appointmentTime < this._localisationService.getZonedToday()
        ? { inPast: true }
        : null;
    };
  }

  ngOnInit() {
    this._subs.add(
      this.newVideoCallText$.subscribe((val) => (this.newVideoCallText = val)),
    );
    this._subs.add(
      this._languageCode$.subscribe((val) => {
        this._dateAdapter.setLocale(val || 'en-GB');
      }),
    );
    this._subs.add(
      this.effectsCompleted$.subscribe((payload) => {
        this.onEffectsCompleted(payload);
      }),
    );

    this._subs.add(
      this._toastText$.subscribe((val) => {
        this._toastText = val;
      }),
    );

    this.setupSearchObservables(this._errorHandler);
    this.setupForm();
    this.setupSearchBox(this.newVideoCallForm);

    this._subs.add(
      this._toastRef$.subscribe((tr) => {
        this._toastRef = tr;
      }),
    );
  }

  private setupForm() {
    this.newVideoCallForm = this._fb.group({
      patientName: this.getFormPatient(),
      host: [
        { value: this.data.host || '', disabled: false },
        [Validators.required, Validators.minLength(3)],
      ],
      description: [
        { value: this.data.description || '', disabled: false },
        [Validators.required, Validators.minLength(3)],
      ],
      date: [
        { value: this.data.date || null, disabled: false },
        [Validators.required, this.dateInPastValidator()],
      ],
      startTime: [
        { value: this.data.startTime || '', disabled: false },
        [Validators.required, this.startTimeInPastValidator()],
      ],
      duration: [
        { value: this.data.duration || null, disabled: false },
        [Validators.required],
      ],
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  closeDialog() {
    if (this.newVideoCallForm.dirty) {
      const confirmDialog = this.dialog.open(ConfirmActionComponent, {
        data: {
          message: this.newVideoCallText.CloseCheck,
          text: {
            Cancel: this.newVideoCallText.Cancel,
            Confirm: this.newVideoCallText.Confirm,
          },
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.doCloseDialog();
        }
      });
    } else {
      this.doCloseDialog();
    }
  }

  confirmDeleteDialog() {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.newVideoCallText.ConfirmDelete,
        text: {
          Cancel: this.newVideoCallText.Cancel,
          Confirm: this.newVideoCallText.Delete,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteVideoCall();
      }
    });
  }

  saveVideoCall() {
    this._store.dispatch(
      new DisplayToastAction({
        message: this._toastText.PleaseWait,
        title: this._toastText.Saving,
        timeout: 0,
        type: 'info',
      }),
    );

    const patient =
      this._patient || this.newVideoCallForm.get('patientName').value;

    const newVideoCall: UpdateVideoCall = {
      id: this.data.id,
      patient,
      description: this.newVideoCallForm.get('description').value,
      duration: this.newVideoCallForm.get('duration').value,
      host: this.newVideoCallForm.get('host').value,
      scheduleddate: this._localisationService
        .toUTCDate(this.createDateFromFields(this.newVideoCallForm))
        .toISOString(),
    };

    this._store.dispatch(
      new VideoCallActions.UpdateVideoCallAction(newVideoCall),
    );
  }

  private onEffectsCompleted(payload: VideoCallEffectsCompletedResponse) {
    if (payload) {
      switch (payload.type) {
        case VideoCallEffectsResposeType.UpdateSuccess:
          this.onSavedSuccessfully();
          break;
        case VideoCallEffectsResposeType.DeleteSuccess:
          this.onDeletedSuccessfully();
          break;
        case VideoCallEffectsResposeType.DeleteError:
        case VideoCallEffectsResposeType.UpdateError:
          this.onUDerror(payload.type);
          break;
      }

      this._store.dispatch(new ResetEffectsStateAction());
    }
  }

  private createDateFromFields(group: FormGroup): Date {
    const startTime = group.get('startTime').value as string | null | undefined;

    if (startTime == null || startTime === '') {
      return null;
    }

    const [hours, minutes] = startTime.split(':');
    let dateValue = group.get('date').value;

    if (!dateValue || hours == null || minutes == null) {
      return null;
    }

    dateValue = dateFns.setHours(dateValue, Number(hours));
    dateValue = dateFns.setMinutes(dateValue, Number(minutes));

    return dateValue;
  }

  private deleteVideoCall() {
    this._store.dispatch(
      new DisplayToastAction({
        message: this._toastText.PleaseWait,
        title: this._toastText.Deleting,
        timeout: 0,
        type: 'info',
      }),
    );
    this._store.dispatch(new DeleteVideoCallAction({ id: this.data.id }));
  }

  private onSavedSuccessfully() {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: this._toastText.SavedSuccessfully,
        title: this._toastText.Saved,
        timeout: 3000,
        type: 'success',
      }),
    );
    this.doCloseDialog();
  }

  private onUDerror(type: VideoCallEffectsResposeType) {
    if (!this._toastRef) {
      return;
    }
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: this._toastText.ErrorEncountered,
        title:
          type === VideoCallEffectsResposeType.UpdateError
            ? this._toastText.ErrorSaving
            : this._toastText.ErrorDeleting,
        timeout: 0,
        type: 'error',
      }),
    );
    this._store.dispatch(new ResetEffectsStateAction());
  }

  private onDeletedSuccessfully() {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: this._toastText.DeletedSuccessfully,
        title: this._toastText.Deleted,
        timeout: 3000,
        type: 'success',
      }),
    );
    this.doCloseDialog();
    this._store.dispatch(new ResetEffectsStateAction());
  }

  private doCloseDialog() {
    this.dialogRef.close({
      content: null,
      patientId: null,
    });
  }
}
