import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { VideoCallsService } from 'src/app/video-calls/services/video-calls.service';
import {
  LoadedPatientInformation,
  LoadPatientInformation,
  LoadPatientInformationError,
  LoadPatientInformationSuccess,
  PatientActionTypes
} from '../actions/patient.actions';
import { PatientService } from '../services/patient.service';

@Injectable()
export class PatientEffects {
  loadPatientInformation$ = this.actions$.pipe(
    ofType<LoadPatientInformation>(PatientActionTypes.LoadPatientInformation),
    switchMap((action) => {
      return combineLatest(
        this.patientService.getPatientInformation(action.payload),
        this.videoCallsService.getVideoCalls(action.payload)
      );
    })
  );

  @Effect()
  loadPatientInformationSuccess$ = this.loadPatientInformation$.pipe(
    switchMap(([patientInfo, videoCalls]) => {
      const patient = patientInfo && patientInfo.Patient;

      if (patient == null) {
        return [new LoadedPatientInformation()];
      }

      return [
        new LoadPatientInformationSuccess({
          response: { ...patientInfo, VideoCalls: videoCalls },
          patientId: patient.Id
        }),
        new LoadedPatientInformation()
      ];
    }),
    catchError((error) => {
      return of(new LoadPatientInformationError(error));
    })
  );

  @Effect({ dispatch: false })
  loadPatientInformationNotFound$ = this.loadPatientInformation$.pipe(
    tap(([patientInfo]) => {
      const patient = patientInfo && patientInfo.Patient;

      if (patient == null) {
        return this._router.navigate(['/patients']);
      }
    }),
    catchError((error) => {
      return of(new LoadPatientInformationError(error));
    })
  );

  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private _error: ErrorHandler,
    private videoCallsService: VideoCallsService,
    private _router: Router
  ) {}
}
