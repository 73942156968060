<mat-card class="card-container">
  <perfect-scrollbar
    class="login-form-container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div #formContainer class="form-container">
      <div class="form-element">
        <form
          class="form"
          [formGroup]="loginForm"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxFlex="0 1 calc(510px)"
        >
          <div class="welcome">
            <div>{{ text.WelcomeToSalve }}{{ isCore ? text.Core : '' }}</div>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>{{ text.Email }}</mat-label>
            <input
              matInput
              class="main-input"
              type="text"
              autocomplete="username"
              formControlName="username"
              required
            />
            <mat-error
              *ngIf="
                (loginForm.get('username').touched ||
                  loginForm.get('username').dirty) &&
                !loginForm.get('username').valid
              "
              class="error"
            >
              {{ text.EmailRequired }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ text.Password }}</mat-label>
            <input
              matInput
              type="password"
              autocomplete="password"
              formControlName="password"
              required
            />
            <mat-error
              *ngIf="
                (loginForm.get('password').touched ||
                  loginForm.get('password').dirty) &&
                loginForm.get('password').hasError('required') &&
                !formSubmitted
              "
              class="error"
            >
              {{ text.PasswordMissing }}
            </mat-error>
            <mat-error
              *ngIf="
                (loginForm.get('password').touched ||
                  loginForm.get('password').dirty) &&
                !loginForm.get('password').hasError('required') &&
                loginForm.get('password').hasError('complexity') &&
                !formSubmitted
              "
              class="error"
            >
              {{ text.InvalidPasswordException }}
            </mat-error>
          </mat-form-field>

          <div *ngIf="isLoggingIn" fxLayoutAlign="center center">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>

          <div class="main-error" fxLayout="row" fxLayoutAlign="start center">
            <mat-error *ngIf="error">{{
              text[error.code] || error.message
            }}</mat-error>
            <mat-error
              *ngIf="
                (loginForm.get('password').touched ||
                  loginForm.get('password').dirty) &&
                !loginForm.get('password').hasError('required') &&
                !loginForm.get('password').hasError('complexity') &&
                loginForm.hasError('complexity') &&
                !formSubmitted
              "
              >{{ text.InvalidPasswordException }}</mat-error
            >
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button
              mat-raised-button
              type="submit"
              (click)="submit()"
              (keyup.enter)="submit()"
              [disabled]="loginForm.status === 'INVALID'"
              class="portal-btn btn-primary btn-submit btn-bright"
            >
              {{ text.Login }}
            </button>
          </div>

          <div
            class="forgotten-password"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div (click)="forgottenPassword.emit()">
              {{ text.ForgottenPassword }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </perfect-scrollbar>
</mat-card>
