import { AuthActionsUnion, AuthActionTypes } from '../actions/auth.actions';
import { CognitoError } from './../models/LoginSubmission';

export interface State {
  error: CognitoError;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {
    case AuthActionTypes.CoreLogin: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case AuthActionTypes.EnableForm: {
      return {
        ...state,
        pending: false,
      };
    }

    case AuthActionTypes.CoreLoginSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }

    case AuthActionTypes.CoreLoginFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    }

    case AuthActionTypes.ClearLoginError: {
      return {
        ...state,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
