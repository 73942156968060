<div
  class="user-profile"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
>
  <!-- button to display notification breakdown -->
  <div>
    <img
      class="user-avatar"
      src="assets/imgs/nurse.jpeg"
      mat-button
      [matMenuTriggerFor]="menu"
      *ngIf="!isProfilePhotoEnabled()"
    />
    <portal-profile-photo
      class="user-avatar"
      size="medium"
      mat-button
      [matMenuTriggerFor]="menu"
      *ngIf="isProfilePhotoEnabled()"
    >
    </portal-profile-photo>
    <mat-menu #menu="matMenu">
      <button
        *ngIf="!isGlobalPortal && isProfilePhotoEnabled()"
        mat-menu-item
        (click)="handleOpenProfilePhotoDialog()"
      >
        {{ text.ProfilePhoto }}
      </button>
      <!-- button press to log user out -->
      <button mat-menu-item (click)="handleLogout()">
        {{ text.SignOut }}
      </button>
    </mat-menu>
  </div>
</div>
