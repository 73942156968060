import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../../auth/reducers';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { ClearFilter, ContentActionTypes, DeleteCategory, DeleteEntries, FilterContent, OpenDirectorySection, } from '../../actions/content.actions';
import { EditFolderNameComponent } from '../../components/edit-folder-name/edit-folder-name.component';
import { EditPackNameComponent } from '../../components/edit-pack-name/edit-pack-name.component';
import { MoveEntriesComponent } from '../../components/move-entries/move-entries.component';
import * as fromContent from '../../reducers';
import { CreateCategoryComponent } from './../../components/create-category/create-category.component';
import { CreatePackComponent } from './../../components/create-pack/create-pack.component';
import { ViewChildrenContainer } from './../view-children/view-children.container';
var ContentListComponent = /** @class */ (function () {
    function ContentListComponent(_navigationService, _route, _store, dialog, updates$) {
        this._navigationService = _navigationService;
        this._route = _route;
        this._store = _store;
        this.dialog = dialog;
        this.updates$ = updates$;
        // Observables used in content-directory
        this.categories$ = of([]);
        this.otherCategories$ = of([]);
        this.templates$ = of([]);
        this.packs$ = of([]);
        this.routeType$ = of('list');
        this.otherCategories = [];
        // Observables for content list
        this.contentList$ = of([]);
        this.contentToDisplay$ = of([]);
        // Subscription to allow easy clean up
        this._subs = new Subscription();
    }
    ContentListComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Global Portal Observable
        this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        // Content editor group Observables
        this.contentEditorEnabled$ = this._store.pipe(select(fromContent.getContentEditorEnabled));
        // Text Observables
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('NewContentList')));
        this.directoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentDirectory')));
        this.categoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateCategory')));
        this._subs.add(this.isGlobalPortal$.subscribe(function (value) {
            _this.isGlobalPortal = value;
        }));
        // Content editor group, wrapped in a feature flag
        this._subs.add(this.contentEditorEnabled$.subscribe(function (t) { return (_this.contentEditorEnabled = t); }));
        // Capture text for use in Dialogs
        this._subs.add(this.sectionText$.subscribe(function (t) { return (_this.sectionText = t); }));
        this._subs.add(this.categoryText$.subscribe(function (t) { return (_this.categoryText = t); }));
        // content-directory observables from Store and active route
        this.categories$ = this._store.pipe(select(fromContent.getGeneralCategories));
        this.templates$ = this._store.pipe(select(fromContent.getTemplates));
        this.packs$ = this._store.pipe(select(fromContent.getPacks));
        this.categoryId$ = this._route.params.pipe(map(function (params) { return +params.id; }));
        this.otherCategories$ = combineLatest(this.categories$, this.categoryId$).pipe(map(function (_a) {
            var categories = _a[0], id = _a[1];
            return categories.filter(function (c) { return c.id !== id; });
        }));
        // Observables for searchbar
        this.isFilterActive$ = this._store.pipe(select(fromContent.isFilterActive));
        this.currentFilter$ = this._store.pipe(select(fromContent.getCurrentListFilter));
        // Observable for S3 setup
        this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
        this.activeCategory$ = combineLatest(this.categories$, this._route.params).pipe(map(function (_a) {
            var categories = _a[0], params = _a[1];
            var category = categories.find(function (c) { return c.id === +params.id; });
            return category ? category.name : '';
        }));
        this.activeCategoryInfo$ = combineLatest(this.categories$, this._route.params).pipe(map(function (_a) {
            var categories = _a[0], params = _a[1];
            var category = categories.find(function (c) { return c.id === +params.id; });
            return category;
        }));
        // content list observables
        this.contentList$ = this._store.pipe(select(fromContent.getContentHeaders));
        this.contentToDisplay$ = combineLatest(this.contentList$, this.isFilterActive$, this.currentFilter$).pipe(map(function (_a) {
            var content = _a[0], filterActive = _a[1], currentFilter = _a[2];
            var rtnContent = content.map(function (c) { return ({
                id: c.id,
                name: c.name,
                isEmpty: c.isempty,
                isMigrated: c.wasmigrated,
                fromDataSync: c.fromdatasync,
                link: c.haschildren ? 2 : c.parentid ? 1 : 0,
                comments: c.comments,
                categoryId: c.contentcategoryid,
                category: c.contentcategory,
                hasChildren: c.haschildren,
                sendToAll: c.sendtoall,
                parentId: c.parentid,
                salveModified: c.salvemodified,
                lastModifiedBy: c.lastmodifiedby,
                translations: c.translations,
            }); });
            if (filterActive) {
                return rtnContent.filter(function (c) {
                    return ("" + c.id).includes(currentFilter) ||
                        c.name.toLowerCase().includes(currentFilter);
                });
            }
            else {
                return rtnContent;
            }
        }));
        // Which section of directory is open?
        this.openDirectory$ = this._store.pipe(select(fromContent.activeDirectorySection));
        this.isMultiLingual$ = this._store.pipe(select(fromRoot.getLanguages), map(function (languages) { return languages.length > 0; }));
        // watch for successful deletion of a folder
        this._subs.add(this.updates$
            .pipe(ofType(ContentActionTypes.DeleteCategorySuccess), switchMap(function (action) {
            return _this.categories$.pipe(
            // TODO: Fix types
            // @ts-ignore
            filter(function (c) { return c.id !== action.payload; }));
        }))
            .subscribe(function (categories) {
            var id = categories.length > 0 ? categories[0].id : 0;
            _this._navigationService.navigate(['content', 'list', id]);
        }));
        // watch for successful creation of a folder
        this._subs.add(this.updates$
            .pipe(ofType(ContentActionTypes.CreateCategorySuccess))
            .subscribe(function (action) {
            var id = action.payload.id;
            _this._navigationService.navigate(['content', 'list', id]);
        }));
        // capture other categories to use in order to provide options
        // of which folders an item can be moved to.
        this._subs.add(this.otherCategories$.subscribe(function (c) { return (_this.otherCategories = c); }));
    };
    ContentListComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    ContentListComponent.prototype.navigateTo = function (url) {
        this._navigationService.navigateByUrl(url);
    };
    ContentListComponent.prototype.updateFilter = function (filterString) {
        if (!filterString || filterString === '') {
            this._store.dispatch(new ClearFilter());
        }
        else {
            this._store.dispatch(new FilterContent(filterString));
        }
    };
    ContentListComponent.prototype.goToContent = function (te) {
        this._navigationService.navigate([
            'content',
            'doc',
            'edit',
            te.categoryId,
            te.id,
        ]);
    };
    ContentListComponent.prototype.createEntry = function (categoryId) {
        this._navigationService.navigate([
            'content',
            'doc',
            'richorpdf',
            categoryId,
        ]);
    };
    ContentListComponent.prototype.openDirectorySection = function (section) {
        this._store.dispatch(new OpenDirectorySection(section));
    };
    ContentListComponent.prototype.deleteEntries = function (deleteEntries) {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.sectionText.DeleteItemsConfirmation,
                information: this.sectionText.DeleteItemsAdditionalInfo,
                text: {
                    Cancel: this.sectionText.Cancel,
                    Confirm: this.sectionText.Delete,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this._store.dispatch(new DeleteEntries({
                    categoryId: deleteEntries.categoryId,
                    entriesToDelete: deleteEntries.entries,
                }));
            }
        });
    };
    ContentListComponent.prototype.moveEntries = function (moveEntries) {
        this.dialog.open(MoveEntriesComponent, {
            data: {
                entries: moveEntries.entries,
                currentCategoryId: moveEntries.categoryId,
                otherCategories: this.otherCategories,
                text: tslib_1.__assign({}, this.sectionText),
            },
        });
    };
    ContentListComponent.prototype.deleteCategory = function (id) {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.sectionText.DeleteFolderConfirmation,
                text: {
                    Cancel: this.sectionText.Cancel,
                    Confirm: this.sectionText.Delete,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this._store.dispatch(new DeleteCategory(id));
            }
        });
    };
    ContentListComponent.prototype.createNewFolder = function () {
        this.dialog.open(CreateCategoryComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    ContentListComponent.prototype.changeFolderName = function (category) {
        this.dialog.open(EditFolderNameComponent, {
            data: {
                category: category,
                text: this.sectionText,
            },
            width: '550px',
        });
    };
    ContentListComponent.prototype.openViewChildrenModal = function (viewChildParams) {
        var childrenModal = this.dialog.open(ViewChildrenContainer, {
            data: tslib_1.__assign({}, viewChildParams),
            width: '550px',
        });
        childrenModal
            .afterClosed()
            .pipe(take(1))
            .subscribe(function (result) { });
    };
    ContentListComponent.prototype.createNewPack = function () {
        this.dialog.open(CreatePackComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    ContentListComponent.prototype.editPackName = function (pack) {
        this.dialog.open(EditPackNameComponent, {
            data: {
                pack: pack,
                text: this.sectionText,
            },
            width: '550px',
        });
    };
    return ContentListComponent;
}());
export { ContentListComponent };
