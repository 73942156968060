import * as tslib_1 from "tslib";
import { Popover } from '@material-ui/core';
import { Button, FilterChip } from '@react/components';
import { useTranslations } from '@react/lib/i18n';
import React, { useRef, useState } from 'react';
import FilterPanel from '../filterPanel';
var Filters = function (_a) {
    var filters = _a.filters, setFilters = _a.setFilters;
    var t = useTranslations().t;
    var filterPopoverAnchor = useRef(null);
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var apply = function (localFilters) {
        setFilters(localFilters);
        setOpen(false);
    };
    var deleteStatusFilter = function (item) {
        var status = Array.isArray(filters.status)
            ? Array.from(filters.status)
            : [];
        status = status.filter(function (key) { return item !== key; });
        setFilters(tslib_1.__assign({}, filters, { status: status }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "data-testid": "btn_filter", color: "primary", variant: "contained", ref: filterPopoverAnchor, onClick: function () { return setOpen(true); } },
            "+ ",
            t.Admin.Users.AddFilter),
        React.createElement(Popover, { id: "filter-Popover", open: open, anchorEl: filterPopoverAnchor.current, onClose: function () { return setOpen(false); }, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } },
            React.createElement(FilterPanel, { filters: filters, apply: apply })),
        Array.isArray(filters.status) &&
            filters.status.map(function (item) { return (React.createElement(FilterChip, { key: item, "data-testid": "filterChip" + t.Admin.Users[item + "Status"], label: t.Admin.Users[item + "Status"], onDelete: function () { return deleteStatusFilter(item); } })); })));
};
var ɵ0 = Filters;
export default Filters;
export { ɵ0 };
