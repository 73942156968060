import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { Container } from './Loading.styled';
/**
 * Can be used to show the user that an operation is being performed.
 */
export var Loading = function (_a) {
    var _b = _a["data-testid"], dataTestId = _b === void 0 ? 'Loading' : _b, fullHeight = _a.fullHeight;
    return (React.createElement(Container, { "data-testid": dataTestId, fullHeight: fullHeight },
        React.createElement(CircularProgress, null)));
};
