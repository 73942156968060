import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  connect,
  createLocalTracks,
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteParticipant,
  Room,
} from 'twilio-video';

export enum LocalConnectionStatus {
  Connected,
  Disconnected,
}

@Injectable()
export class VideoChatService {
  tracks: LocalTrack[];
  room: Room = null;

  audioOutputEnabled = 'setSinkId' in HTMLAudioElement.prototype;
  localConnectionStatusChanged$ = new Subject<LocalConnectionStatus>();
  audioInputDeviceChanged$ = new BehaviorSubject<MediaDeviceInfo | undefined>(
    undefined,
  );
  audioInputDevicesChanged$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
  audioOutputDeviceChanged$ = new BehaviorSubject<MediaDeviceInfo | undefined>(
    undefined,
  );
  audioOutputDevicesChanged$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
  videoDeviceChanged$ = new BehaviorSubject<MediaDeviceInfo | undefined>(
    undefined,
  );
  videoDevicesChanged$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
  participantConnected$ = new Subject<RemoteParticipant>();
  participantDisconnected$ = new Subject<RemoteParticipant>();
  dominantParticipantChanged$ = new Subject<RemoteParticipant>();

  errorConnecting = new Subject<string>();
  roomReconnecting = new Subject<boolean>();
  errorDevices = new Subject<string>();

  constructor() {
    this.onMediaDevicesChanged = this.onMediaDevicesChanged.bind(this);
  }

  async initializeMedia() {
    try {
      this.tracks = [
        ...(await createLocalTracks({ audio: true, video: true })),
        new LocalDataTrack(),
      ];
    } catch (error) {
      this.errorDevices.next(error);
    }
  }

  stopMedia() {
    try {
      this.tracks.forEach((t) => {
        if ('stop' in t) {
          t.stop();
        }
      });
    } catch (error) {
      console.error('Failed to stop tracks');
      console.error(error);
    }
  }

  async joinOrCreateRoom(name: string, token: string) {
    this.leaveRoom();

    navigator.mediaDevices.addEventListener(
      'devicechange',
      this.onMediaDevicesChanged,
    );

    await this.updateMediaDevices();

    try {
      this.room = await connect(token, {
        name,
        tracks: this.tracks,
        dominantSpeaker: true,
        preferredVideoCodecs: ['H264', 'VP8'],
      });
      if (this.room) {
        this.room.on('participantConnected', (p) => {
          this.onParticipantConnected(p);
        });
        this.room.on('participantDisconnected', (p) => {
          this.onParticipantDisconnected(p);
        });
        this.room.on('disconnected', (r) => {
          this.onDisconnected(r);
        });
        this.room.on('reconnecting', () => {
          this.onRoomReconnecting();
        });
        this.room.on('reconnected', () => {
          this.onRoomReconnected();
        });
        this.room.on('dominantSpeakerChanged', (p) => {
          this.onDominantSpeakerChanged(p);
        });

        this.room.participants.forEach((p) => {
          this.onParticipantConnected(p);
        });
      }
    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
      this.errorConnecting.next(error.message);
    } finally {
      this.localConnectionStatusChanged$.next(
        this.room
          ? LocalConnectionStatus.Connected
          : LocalConnectionStatus.Disconnected,
      );
    }
  }

  async leaveRoom() {
    this.roomReconnecting.next(false);
    if (this.room) {
      this.room.disconnect();
      this.room = null;
    }

    navigator.mediaDevices.removeEventListener(
      'devicechange',
      this.onMediaDevicesChanged,
    );
  }

  sendMessage(message: string): void {
    this.room.localParticipant.dataTracks.forEach(({ track }) => {
      track.send(message);
    });
  }

  toggleCamera(): boolean {
    this.room.localParticipant.videoTracks.forEach(({ track }) => {
      if (track.isEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    });

    return Array.from(this.room.localParticipant.videoTracks.values()).every(
      ({ track }) => track.isEnabled,
    );
  }

  toggleMute(): boolean {
    this.room.localParticipant.audioTracks.forEach(({ track }) => {
      if (track.isEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    });

    return Array.from(this.room.localParticipant.audioTracks.values()).every(
      ({ track }) => track.isEnabled,
    );
  }

  async changeAudioInputDevice(audioInputDeviceId: string): Promise<void> {
    const audioInputTrack = this.tracks.find(
      (t) => t.kind === 'audio',
    ) as LocalAudioTrack;

    const audioInputDevice = this.audioInputDevicesChanged$.value.find(
      (d) => d.deviceId === audioInputDeviceId,
    );

    if (audioInputTrack && audioInputDevice) {
      const constraints: MediaTrackConstraints = {
        deviceId: { exact: audioInputDevice.deviceId },
      };

      await audioInputTrack.restart(constraints);

      this.audioInputDeviceChanged$.next(audioInputDevice);
    }
  }

  changeAudioOutputDevice(audioOutputDeviceId: string) {
    const audioOutputDevice = this.audioOutputDevicesChanged$.value.find(
      (a) => a.deviceId === audioOutputDeviceId,
    );

    if (audioOutputDevice) {
      this.audioOutputDeviceChanged$.next(audioOutputDevice);
    }
  }

  async changeVideoDevice(videoDeviceId: string): Promise<void> {
    const videoTrack = this.tracks.find(
      (t) => t.kind === 'video',
    ) as LocalVideoTrack;

    const videoDevice = this.videoDevicesChanged$.value.find(
      (d) => d.deviceId === videoDeviceId,
    );
    if (videoTrack) {
      const constraints: MediaTrackConstraints = {
        deviceId: { exact: videoDevice.deviceId },
      };

      await videoTrack.restart(constraints);

      this.videoDeviceChanged$.next(videoDevice);
    }
  }

  private onParticipantConnected(participant: RemoteParticipant) {
    this.participantConnected$.next(participant);
  }

  private onParticipantDisconnected(participant: RemoteParticipant) {
    this.participantDisconnected$.next(participant);
  }

  private onDisconnected(room: Room) {
    room.localParticipant.tracks.forEach((track) => {
      if ((track as any).detach) {
        (track as any).detach();
      }
    });
    this.localConnectionStatusChanged$.next(LocalConnectionStatus.Disconnected);
  }

  private onRoomReconnecting() {
    this.roomReconnecting.next(true);
  }

  private onRoomReconnected() {
    this.roomReconnecting.next(false);
  }

  private onDominantSpeakerChanged(participant: RemoteParticipant) {
    this.dominantParticipantChanged$.next(participant);
  }

  private async onMediaDevicesChanged() {
    this.updateMediaDevices();
  }

  /**
   * Called during initialization or whenever the user's devices change.
   *
   * This method is responsible for obtaining an up to date list of devices
   * and then reconciling this
   */
  private async updateMediaDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const audioInputDevices = devices.filter((d) => d.kind === 'audioinput');
    const audioOutputDevices = devices.filter((d) => d.kind === 'audiooutput');
    const videoDevices = devices.filter((d) => d.kind === 'videoinput');

    // Audio input
    const audioTrack = this.tracks.find(
      (t) => t.kind === 'audio',
    ) as LocalAudioTrack;
    const audioTrackDeviceId =
      audioTrack.mediaStreamTrack.getSettings().deviceId;
    const currentAudioInputDevice = audioInputDevices.find(
      (d) => d.deviceId === audioTrackDeviceId,
    );

    this.audioInputDevicesChanged$.next(audioInputDevices);

    // Despite the fact this might not be needed there are cases
    // where a user may remove a device but the sound doesn't
    // automatically transfer back to the default device properly
    // If we just restart the local audio track regardless we can
    // fix this and it causes minimum disruption to the user.
    if (currentAudioInputDevice != null) {
      this.changeAudioInputDevice(currentAudioInputDevice.deviceId);
    }

    // Audio output
    this.audioOutputDevicesChanged$.next(audioOutputDevices);

    // Twilio Video is not concerned with audio output as it's
    // our job to make sure any remote participant's <audio/>
    // elements send audio to the correct device. So when we get
    // updated devices we need to check for two different things:
    //
    // 1. Is this our first time?
    // 2. Has our previously chosen device been removed?
    //
    // If any of those things are true then we need to revert back
    // to the default device (first in array of devices).
    if (
      this.audioOutputEnabled &&
      audioOutputDevices.length > 0 &&
      (this.audioOutputDeviceChanged$.value == null ||
        !audioOutputDevices.find(
          (d) => d.deviceId === this.audioOutputDeviceChanged$.value.deviceId,
        ))
    ) {
      this.audioOutputDeviceChanged$.next(audioOutputDevices[0]);
    }

    // Video
    const videoTrack = this.tracks.find(
      (t) => t.kind === 'video',
    ) as LocalVideoTrack;
    const videoTrackDeviceId =
      videoTrack.mediaStreamTrack.getSettings().deviceId;
    const currentVideoDevice = videoDevices.find(
      (d) => d.deviceId === videoTrackDeviceId,
    );

    this.videoDevicesChanged$.next(videoDevices);

    if (currentVideoDevice != null) {
      // If it's the first time then default device will have been
      // chosen so we can just capture that.
      if (this.videoDeviceChanged$.value == null) {
        this.videoDeviceChanged$.next(currentVideoDevice);
      } else if (
        this.videoDeviceChanged$.value.deviceId !== currentVideoDevice.deviceId
      ) {
        this.changeVideoDevice(currentVideoDevice.deviceId);
      }
    }
  }
}
