import { MatDialog, MatDialogRef } from '@angular/material';
var ConfirmActionComponent = /** @class */ (function () {
    function ConfirmActionComponent(dialogRef, dialog, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        if (data.isContent) {
            this.isContent = data.isContent;
        }
        if (data.information) {
            this.information = data.information;
        }
    }
    ConfirmActionComponent.prototype.onYesClick = function () {
        this.dialogRef.close(true);
    };
    ConfirmActionComponent.prototype.onNoClick = function () {
        this.dialogRef.close(false);
    };
    return ConfirmActionComponent;
}());
export { ConfirmActionComponent };
