import * as tslib_1 from "tslib";
import { RealTimeMessageActionTypes, } from '../actions/real-time.actions';
export function reducer(state, action) {
    var _a;
    switch (action.type) {
        case RealTimeMessageActionTypes.Typing: {
            var typingInfo_1 = {
                user: action.payload.user,
                lastUpdated: action.payload.lastEdited,
            };
            var updatedMessagingInfo = state.typingInfo[action.payload.messageId] || [];
            var currentUserMessageInfo = updatedMessagingInfo.findIndex(function (ti) { return ti.user === typingInfo_1.user; });
            if (currentUserMessageInfo > -1) {
                updatedMessagingInfo[currentUserMessageInfo] = typingInfo_1;
            }
            else {
                updatedMessagingInfo.push(typingInfo_1);
            }
            return tslib_1.__assign({}, state, { typingInfo: tslib_1.__assign({}, state.typingInfo, (_a = {}, _a[action.payload.messageId] = updatedMessagingInfo, _a)) });
        }
        case RealTimeMessageActionTypes.ForceRefreshTyping: {
            return tslib_1.__assign({}, state, { forceRefreshTyping: new Date() });
        }
        default: {
            return state;
        }
    }
}
