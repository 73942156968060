import * as React from 'react';
import { createContext, useContext } from 'react';
import { translationEnGB } from '../../../settings/data/lang.en.gb';
export var LanguageContext = createContext({
    language: 'en-gb',
    translations: translationEnGB,
});
export var LanguageProvider = function (_a) {
    var children = _a.children, language = _a.language, translations = _a.translations;
    return (React.createElement(LanguageContext.Provider, { value: { language: language, translations: translations }, children: children }));
};
export function useTranslations() {
    var _a = useContext(LanguageContext), translations = _a.translations, language = _a.language;
    return { t: translations, language: language };
}
