import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { MenuItem, Select as MuiSelect } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { InputLabel } from './InputLabel';
export var Select = function (_a) {
    var id = _a.id, label = _a.label, placeholder = _a.placeholder, value = _a.value, options = _a.options, otherProps = tslib_1.__rest(_a, ["id", "label", "placeholder", "value", "options"]);
    var anchorRef = useRef(null);
    var _b = useState(), anchorEl = _b[0], setAnchorEl = _b[1];
    var hasValue = value != null && value !== '';
    var renderValue = function (selectedValue) {
        var selectedOption = options.find(function (option) { return option.value === selectedValue; });
        return selectedOption ? selectedOption.label : placeholder;
    };
    useEffect(function () {
        if (anchorRef.current) {
            setAnchorEl(anchorRef.current);
        }
    }, [anchorRef.current]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputLabel, { id: id }, label),
        React.createElement("div", { ref: anchorRef },
            React.createElement(StyledSelect, tslib_1.__assign({ className: clsx({ 'select-empty': !hasValue }), labelId: id, renderValue: renderValue, value: value, MenuProps: {
                    anchorEl: anchorEl,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    style: {
                        maxHeight: 220,
                    },
                } }, otherProps), options.map(function (option) { return (React.createElement(StyledMenuItem, { className: "newMessageModal-menuItem", key: option.value, value: option.value }, option.label)); })))));
};
Select.defaultProps = {
    displayEmpty: true,
    variant: 'outlined',
};
var StyledSelect = styled(MuiSelect)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  &.select-empty {\n    color: ", ";\n  }\n"], ["\n  &.select-empty {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.brownGrey;
});
var StyledMenuItem = styled(MenuItem)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.paleGray;
});
var templateObject_1, templateObject_2;
