import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeletePatientAssignedContent, OpenItemList, OpenPackList, OpenPatientViewFolder, SetAssignedContentFilter, } from './../../actions/content-assignment.actions';
import { AddExclusion } from './../../actions/pack-assignment.actions';
import { ContentTabs, } from './../../reducers/content-assignment.reducer';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import * as fromSettings from '../../../settings/reducers';
import * as fromContentAssignment from '../../reducers';
import { ChangeAssignedContentView, GetAllPatientAssignedContent, GetPatientAssignedContent, GetPatientOnlyAssignments, GetPatientViewOfContent, } from '../../actions/content-assignment.actions';
import { OpenAssignedPack, UnassignPack, } from '../../actions/pack-assignment.actions';
var AssignedContentContainer = /** @class */ (function () {
    function AssignedContentContainer(_store, _route) {
        var _this = this;
        this._store = _store;
        this._route = _route;
        this._subs = new Subscription();
        this.patientId$ = this._route.params.pipe(map(function (params) { return +params.patientId; }));
        this.contentSwingText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentSwing')));
        // check router for changes to target patient
        this._subs.add(this.patientId$.subscribe(function (id) {
            _this._store.dispatch(new GetPatientAssignedContent(id));
            _this._store.dispatch(new GetPatientViewOfContent(id));
            _this._store.dispatch(new GetAllPatientAssignedContent(id));
            _this._store.dispatch(new GetPatientOnlyAssignments({
                patientId: id,
            }));
        }));
        // fetch current patient
        this.currentPatient$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromContentAssignment.getPatientById(id)));
        }), filter(function (clinicPatientResponse) {
            return !!clinicPatientResponse && !!clinicPatientResponse.Patient;
        }), map(function (clinicPatientResponse) {
            return clinicPatientResponse.Patient;
        }));
        this.patientName$ = this.currentPatient$.pipe(map(function (patient) {
            return patient.FirstName + " " + patient.LastName;
        }));
        this.patientViewOpenFolder$ = this._store.pipe(select(fromContentAssignment.getViewAsPatientOpenFolder));
        this.loadingAllPatientContent$ = this._store.pipe(select(fromContentAssignment.loadingPatientAssigned));
        this.loadingPatientContentView$ = this._store.pipe(select(fromContentAssignment.loadingPatientViewOfContent));
        this.assignedPacks$ = this._store.pipe(select(fromContentAssignment.getPatientAssignedPacks));
        this.assignedContent$ = this._store.pipe(select(fromContentAssignment.getPatientAssignedContent));
        this.patientViewContent$ = this._store
            .pipe(select(fromContentAssignment.patientViewOfContent))
            .pipe(map(function (p) {
            return p;
        }));
        this.filterActive$ = this._store.pipe(select(fromContentAssignment.isAssignedContentFilterActive));
        this.viewAsPatient$ = this._store.pipe(select(fromContentAssignment.viewAsPatient));
        this.clinicViewingAssignedPacks$ = this._store.pipe(select(fromContentAssignment.isClinicViewingAssignedPacks));
        this.clinicViewingAssignedItems$ = this._store.pipe(select(fromContentAssignment.isClinicViewingAssignedItems));
        this.activeAssignedPackId$ = this._store.pipe(select(fromContentAssignment.getActiveAssignedPackId));
        this.activeTab$ = this._store.pipe(select(fromContentAssignment.getActiveContentTab));
    }
    AssignedContentContainer.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    AssignedContentContainer.prototype.handleSwitchView = function () {
        this._store.dispatch(new ChangeAssignedContentView());
    };
    AssignedContentContainer.prototype.handleViewInfo = function () { };
    AssignedContentContainer.prototype.handleClinicViewSwitch = function (packViewOpen) {
        this._store.dispatch(packViewOpen ? new OpenItemList() : new OpenPackList());
    };
    AssignedContentContainer.prototype.openAssignedPack = function (packId) {
        this._store.dispatch(new OpenAssignedPack(packId));
    };
    AssignedContentContainer.prototype.removePack = function (unassignPack) {
        unassignPack.pack.isTransferring = true;
        this._store.dispatch(new UnassignPack(unassignPack));
    };
    AssignedContentContainer.prototype.excludeItem = function (addExclusion) {
        addExclusion.packItem.isTransferring = true;
        this._store.dispatch(new AddExclusion(addExclusion));
    };
    AssignedContentContainer.prototype.removeIndividualItem = function (content) {
        content.isTransferring = true;
        this._store.dispatch(new DeletePatientAssignedContent({
            assignmentid: content.assignmentId,
            contententryheaderid: content.contentEntryHeaderId,
        }));
    };
    AssignedContentContainer.prototype.openPatientFolder = function (folderId) {
        this._store.dispatch(new OpenPatientViewFolder(folderId));
    };
    AssignedContentContainer.prototype.setAssignedContentFilter = function (filterValue) {
        this._store.dispatch(new SetAssignedContentFilter(filterValue));
    };
    return AssignedContentContainer;
}());
export { AssignedContentContainer };
