import { PipeTransform } from '@angular/core';
import prettyBytes from 'pretty-bytes';
import { LocalisationService } from 'src/app/localisation/localisation.service';
var FileSizePipe = /** @class */ (function () {
    /**
     *
     */
    function FileSizePipe(_localisationService) {
        this._localisationService = _localisationService;
    }
    FileSizePipe.prototype.transform = function (fileSize) {
        return prettyBytes(fileSize, {
            locale: this._localisationService.getLanguageCode(),
            maximumFractionDigits: 2,
        });
    };
    return FileSizePipe;
}());
export { FileSizePipe };
