export class AddMessageToThreadRequest {
  private Action = 'InsertClinicMessage';
  public PublicToken: string;
  public MessageId: number;
  public MessageContent: number;

  constructor({ PublicToken, MessageId, MessageContent }) {
    this.PublicToken = PublicToken;
    this.MessageId = MessageId;
    this.MessageContent = MessageContent;
  }
}
