import styled from '@emotion/styled';
import { FilterChip, PopoverChip } from '@react/components';
import {
  ConversationFolder,
  IKeyNumVal,
  OptionalIKeyNumVal,
} from '@react/lib/api/types';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { ClinicUser } from '@react/types';
import React from 'react';
import { usePatientStaffListQuery } from '../hooks/usePatientStaffListQuery';
import {
  assignedFiltersApplied,
  folderFiltersApplied,
  resolvedFilterApplied,
  staffFiltersApplied,
  unAssignedFiltersApplied,
} from '../lib';
import { FilterState } from '../types';
import { unassigned_option } from '../utils';

interface Props {
  filters: FilterState;
  folders?: ConversationFolder[];
  clinicUsers?: ClinicUser[];
  onChange: (filters: FilterState) => void;
}

export const FilterChips: React.FC<Props> = ({
  filters,
  folders,
  clinicUsers,
  onChange,
}) => {
  const { t } = useTranslations();
  const staffQueryResult = usePatientStaffListQuery(filters.staffIds);
  const staff = staffQueryResult.data && staffQueryResult.data.data;

  const hasResolvedFilter = resolvedFilterApplied(filters);
  const hasFolderFilter = folderFiltersApplied(filters);
  const hasStaffFilter = staffFiltersApplied(filters);
  const hasAssignedFilter = assignedFiltersApplied(filters);
  const hasUnAssignedFilter = unAssignedFiltersApplied(filters);
  const filtersApplied =
    hasResolvedFilter ||
    hasFolderFilter ||
    hasStaffFilter ||
    hasAssignedFilter ||
    hasUnAssignedFilter;

  const unAssignedOption = unassigned_option(t.Messages.Unassigned);

  const selectedFolders = hasFolderFilter
    ? filters.folderIds.map((id) => {
        const folder = folders && folders.find((f) => f.id === id);
        const key = folder ? folder.name : '';
        return { key, value: id };
      })
    : {};

  const selectedStaff = hasStaffFilter
    ? // tslint:disable-next-line:no-non-null-assertion
      filters.staffIds!.map((id) => {
        const person = staff && staff.find((s) => s.clinicuserid === id);
        const key = person ? `${person.firstname} ${person.lastname}` : '';
        return { key, value: id };
      })
    : {};

  const selectedAssignedValues = () => {
    let assigned: OptionalIKeyNumVal[] = [];
    if (hasUnAssignedFilter) {
      assigned = [...assigned, { key: unAssignedOption.label }];
    }
    if (hasAssignedFilter && clinicUsers) {
      // tslint:disable-next-line:no-non-null-assertion
      const selected = filters.assignedIds!.map((id) => {
        const clinicUser = clinicUsers.find((u) => u.id === id);
        const key = clinicUser ? clinicUser.userfullname : '';
        return { key, value: id };
      });
      assigned = [...assigned, ...selected];
    }
    return assigned;
  };

  const selectedAssigned =
    (hasAssignedFilter && Array.isArray(filters.assignedIds)) ||
    hasUnAssignedFilter
      ? selectedAssignedValues()
      : {};

  function onResolvedFilterDelete() {
    onChange({ ...filters, isResolved: undefined });
  }

  function onFolderFilterDelete(value: number) {
    const folderIds = filters.folderIds
      ? filters.folderIds.filter((id) => id !== value)
      : [];
    onChange({
      ...filters,
      folderIds: folderIds.length > 0 ? folderIds : [],
    });
  }

  function onFolderFiltersClear() {
    onChange({ ...filters, folderIds: [] });
  }

  function onStaffFilterDelete(value: number) {
    const staffIds = filters.staffIds
      ? filters.staffIds.filter((id) => id !== value)
      : [];
    onChange({
      ...filters,
      staffIds: staffIds.length > 0 ? staffIds : undefined,
    });
  }

  function onStaffFiltersClear() {
    onChange({ ...filters, staffIds: undefined });
  }

  function onAssigneeFilterDelete(value?: number) {
    const clinicUserIds = filters.assignedIds
      ? filters.assignedIds.filter((id) => id !== value)
      : [];
    const isUnassigned =
      filters.isUnassigned && value !== undefined ? true : undefined;

    onChange({
      ...filters,
      assignedIds: clinicUserIds.length > 0 ? clinicUserIds : undefined,
      isUnassigned,
    });
  }

  function onAssigneeFiltersClear() {
    onChange({ ...filters, assignedIds: undefined, isUnassigned: undefined });
  }

  useErrorToast(staffQueryResult.isError, {
    title: t.Messages.FilterLoadingErrorTitle,
    message: t.Messages.FilterLoadingErrorMessage,
  });

  if (!filtersApplied) {
    return null;
  }

  return (
    <StyledContainer>
      {hasFolderFilter && (
        <PopoverChip
          data={selectedFolders as IKeyNumVal[]}
          label={t.Messages.FoldersChipLabel}
          onDelete={onFolderFilterDelete}
          clearAll={onFolderFiltersClear}
        />
      )}
      {hasStaffFilter && (
        <PopoverChip
          data={selectedStaff as IKeyNumVal[]}
          label={t.Messages.TeamMembersFilterLabel}
          onDelete={onStaffFilterDelete}
          clearAll={onStaffFiltersClear}
          loading={staffQueryResult.isLoading}
        />
      )}
      {hasResolvedFilter && (
        <FilterChip
          label={
            filters.isResolved
              ? t.Messages.ResolvedFilterLabel
              : t.Messages.UnresolvedFilterLabel
          }
          onDelete={onResolvedFilterDelete}
        />
      )}
      {(hasAssignedFilter || hasUnAssignedFilter) && (
        <PopoverChip
          data={selectedAssigned as IKeyNumVal[]}
          label={t.Messages.Assignees}
          onDelete={onAssigneeFilterDelete}
          clearAll={onAssigneeFiltersClear}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  margin-top: -${({ theme }) => theme.spacing(1)}px;
  background-color: #f0f0f7;
  border-radius: 5px;
  display: flex;
  gap: 4px;
`;
