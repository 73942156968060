import get from 'lodash/get';
import React, { ReactElement, ReactNode } from 'react';
import { InfiniteQueryObserverResult } from 'react-query';
import { Waypoint } from 'react-waypoint';
import { Container, ContainerInner } from './InfiniteScroll.styled';
import { Loading } from './Loading';

interface Props<Response, Item> {
  infiniteQueryResult: InfiniteQueryObserverResult<Response>;
  error?: ReactNode;
  endOfResults?: ReactNode;
  noResults: ReactNode;
  renderItem: (item: Item, index: number) => ReactElement;
}

export const InfiniteScroll = <Response extends { data: any[] }, Item>({
  infiniteQueryResult,
  error,
  endOfResults = null,
  noResults,
  renderItem,
}: Props<Response, Item>) => {
  const {
    isError,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    data,
    isFetching,
  } = infiniteQueryResult;

  function onScrollToEnd() {
    const shouldFetchNextPage =
      hasNextPage && !isLoading && !isFetchingNextPage;
    if (shouldFetchNextPage) {
      fetchNextPage();
    }
  }

  if (isLoading) {
    return <Loading fullHeight />;
  }

  if (isError || !data) {
    return <>{error}</>;
  }

  const hasResults = get(data, 'pages[0].data.length') > 0;

  if (!hasResults) {
    return <>{noResults}</>;
  }

  return (
    <Container>
      <ContainerInner>
        {data.pages.map((page) => page.data.map(renderItem))}
        {isFetching && <Loading fullHeight />}
        {!hasNextPage && endOfResults}
        <Waypoint onEnter={onScrollToEnd} />
      </ContainerInner>
    </Container>
  );
};
