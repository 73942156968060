export var ContentActionTypes;
(function (ContentActionTypes) {
    ContentActionTypes["GetContentTypes"] = "[Content] Get Content Types";
    ContentActionTypes["GetContentTypesSuccess"] = "[Content] Get Content Types Success";
    ContentActionTypes["GetContentTypesError"] = "[Content] Get Content Types Error";
    ContentActionTypes["GetTemplates"] = "[Content] Get Content Template";
    ContentActionTypes["GetTemplatesSuccess"] = "[Content] Get Content Template Success";
    ContentActionTypes["GetTemplatesError"] = "[Content] Get Content Template Error";
    ContentActionTypes["GetContentEntries"] = "[Content] Get Content Entries";
    ContentActionTypes["GetContentEntriesSuccess"] = "[Content] Get Content Entries Success";
    ContentActionTypes["GetContentEntriesError"] = "[Content] Get Content Entries Error";
    ContentActionTypes["GetContentEntry"] = "[Content] Get Content Entry";
    ContentActionTypes["GetContentEntrySuccess"] = "[Content] Get Content Entry Success";
    ContentActionTypes["GetContentEntryError"] = "[Content] Get Content Entry Error";
    ContentActionTypes["CreateEntry"] = "[Content] Create Content Entry";
    ContentActionTypes["CreateEntrySuccess"] = "[Content] Create Content Entry Success";
    ContentActionTypes["CreateEntryError"] = "[Content] Create Content Entry Error";
    ContentActionTypes["CreateNewContentEntry"] = "[Content] Create New Content Entry";
    ContentActionTypes["SaveEntry"] = "[Content] Save Content Entry";
    ContentActionTypes["SaveEntrySuccess"] = "[Content] Save Content Entry Success";
    ContentActionTypes["SaveEntryError"] = "[Content] Save Content Entry Error";
    ContentActionTypes["DeleteEntry"] = "[Content] Delete Content Entry";
    ContentActionTypes["DeleteEntrySuccess"] = "[Content] Delete Content Entry Success";
    ContentActionTypes["DeleteEntryError"] = "[Content] Delete Content Entry Error";
    ContentActionTypes["DeleteEntries"] = "[Content] Delete Content Entries";
    ContentActionTypes["DeleteEntriesSuccess"] = "[Content] Delete Content Entries Success";
    ContentActionTypes["DeleteEntriesError"] = "[Content] Delete Content Entries Entries";
    ContentActionTypes["MoveEntries"] = "[Content] Move Content Entries";
    ContentActionTypes["MoveEntriesSuccess"] = "[Content] Move Content Entries Success";
    ContentActionTypes["MoveEntriesError"] = "[Content] Move Content Entries Entries";
    // Templates
    // ===================================================
    ContentActionTypes["SavingTemplate"] = "[Content] Saving Template";
    ContentActionTypes["SavingTemplateSuccess"] = "[Content] Saving Template Success";
    ContentActionTypes["SavingTemplateError"] = "[Content] Saving Template Error";
    ContentActionTypes["CreateTemplate"] = "[Content] Create Template";
    ContentActionTypes["CreateTemplateSuccess"] = "[Content] Create Template Success";
    ContentActionTypes["CreateTemplateError"] = "[Content] Create Template Error";
    ContentActionTypes["CreatedTypeAndTemplateSuccess"] = "[Content] Create Type and Template Success";
    ContentActionTypes["DeleteTemplate"] = "[Content] Deleting Template";
    ContentActionTypes["DeleteTemplateSuccess"] = "[Content] Deleting Template Success";
    ContentActionTypes["DeleteTemplateError"] = "[Content] Deleting Template Error";
    ContentActionTypes["SavingContent"] = "[Content] Saving Content";
    ContentActionTypes["SavingContentSuccess"] = "[Content] Saving Content Success";
    ContentActionTypes["SavingContentError"] = "[Content] Saving Content Error";
    // Category
    // ===================================================
    ContentActionTypes["GetCategories"] = "[Content] Get Content Categories";
    ContentActionTypes["GetCategoriesSuccess"] = "[Content] Get Content Categories Success";
    ContentActionTypes["GetCategoriesError"] = "[Content] Get Content Categories Error";
    ContentActionTypes["SavingCategory"] = "[Content] Saving Category";
    ContentActionTypes["SavingCategorySuccess"] = "[Content] Saving Category Success";
    ContentActionTypes["SavingCategoryError"] = "[Content] Saving Category Error";
    ContentActionTypes["CreateCategory"] = "[Content] Create Category";
    ContentActionTypes["CreateCategorySuccess"] = "[Content] Create Category Success";
    ContentActionTypes["CreateCategoryError"] = "[Content] Create Category Error";
    ContentActionTypes["DeleteCategory"] = "[Content] Deleting Category";
    ContentActionTypes["DeleteCategorySuccess"] = "[Content] Deleting Category Success";
    ContentActionTypes["DeleteCategoryError"] = "[Content] Deleting Category Error";
    // Client Side
    ContentActionTypes["LoadingDirectory"] = "[Content] Loading Items for Content Directory";
    ContentActionTypes["LoadedDirectory"] = "[Content] Loaded Items for Content Directory";
    ContentActionTypes["LoadingTemplate"] = "[Content] Loading Template";
    ContentActionTypes["LoadedTemplate"] = "[Content] Loaded Template";
    ContentActionTypes["LoadingContent"] = "[Content] Loading Content";
    ContentActionTypes["LoadedContent"] = "[Content] Loaded Content";
    ContentActionTypes["OpenDirectorySection"] = "[Content] Open Directory Section";
    ContentActionTypes["FilterContent"] = "[Content] Filter Content List";
    ContentActionTypes["ClearFilter"] = "[Content] Clear Content List Filter";
    ContentActionTypes["ChangeTab"] = "[Content] Change Current Content Tab";
    ContentActionTypes["ContentEntryPageUnload"] = "[Content] Content Entry Page Unload";
    ContentActionTypes["RefreshContent"] = "[Content-All] Refresh core content data";
    ContentActionTypes["GetContentEditorEnabled"] = "[Content] Get Content Editor Enabled";
    ContentActionTypes["GetContentEditorEnabledSuccess"] = "[Content] Get Content Editor Enabled Success";
    ContentActionTypes["GetContentEditorEnabledError"] = "[Content] Get Content Editor Enabled Error";
    ContentActionTypes["CopyGlobalContent"] = "[Content] Copy Global Content";
    ContentActionTypes["CopyGlobalContentSuccessMissingTranslations"] = "[Content] Copy Global Content Success Missing Translations";
    ContentActionTypes["CopyGlobalContentSuccessAdditionalTranslations"] = "[Content] Copy Global Content Success Additional Translations";
    ContentActionTypes["CopyGlobalContentError"] = "[Content] Copy Global Content Error";
    ContentActionTypes["CopyGlobalFileContent"] = "[Content] Copy Global File Content";
    ContentActionTypes["CopyGlobalFileContentSuccessMissingTranslations"] = "[Content] Copy Global File Content Success Missing Translations";
    ContentActionTypes["CopyGlobalFileContentSuccessAdditionalTranslations"] = "[Content] Copy Global File Content Success Additional Translations";
    ContentActionTypes["CopyGlobalFileContentError"] = "[Content] Copy Global File Content Error";
})(ContentActionTypes || (ContentActionTypes = {}));
// Action Creators
// ==================================================
// Server Side
var GetCategories = /** @class */ (function () {
    function GetCategories(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetCategories;
    }
    return GetCategories;
}());
export { GetCategories };
var GetCategoriesSuccess = /** @class */ (function () {
    function GetCategoriesSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetCategoriesSuccess;
    }
    return GetCategoriesSuccess;
}());
export { GetCategoriesSuccess };
var GetCategoriesError = /** @class */ (function () {
    function GetCategoriesError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetCategoriesError;
    }
    return GetCategoriesError;
}());
export { GetCategoriesError };
var GetContentTypes = /** @class */ (function () {
    function GetContentTypes(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentTypes;
    }
    return GetContentTypes;
}());
export { GetContentTypes };
var GetContentTypesSuccess = /** @class */ (function () {
    function GetContentTypesSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentTypesSuccess;
    }
    return GetContentTypesSuccess;
}());
export { GetContentTypesSuccess };
var GetContentTypesError = /** @class */ (function () {
    function GetContentTypesError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentTypesError;
    }
    return GetContentTypesError;
}());
export { GetContentTypesError };
var GetTemplates = /** @class */ (function () {
    function GetTemplates(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetTemplates;
    }
    return GetTemplates;
}());
export { GetTemplates };
var GetTemplatesSuccess = /** @class */ (function () {
    function GetTemplatesSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetTemplatesSuccess;
    }
    return GetTemplatesSuccess;
}());
export { GetTemplatesSuccess };
var GetTemplatesError = /** @class */ (function () {
    function GetTemplatesError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetTemplatesError;
    }
    return GetTemplatesError;
}());
export { GetTemplatesError };
var GetContentEntries = /** @class */ (function () {
    function GetContentEntries(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEntries;
    }
    return GetContentEntries;
}());
export { GetContentEntries };
var GetContentEntriesSuccess = /** @class */ (function () {
    function GetContentEntriesSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEntriesSuccess;
    }
    return GetContentEntriesSuccess;
}());
export { GetContentEntriesSuccess };
var GetContentEntriesError = /** @class */ (function () {
    function GetContentEntriesError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEntriesError;
    }
    return GetContentEntriesError;
}());
export { GetContentEntriesError };
var GetContentEntry = /** @class */ (function () {
    function GetContentEntry(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEntry;
    }
    return GetContentEntry;
}());
export { GetContentEntry };
var GetContentEntrySuccess = /** @class */ (function () {
    function GetContentEntrySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEntrySuccess;
    }
    return GetContentEntrySuccess;
}());
export { GetContentEntrySuccess };
var GetContentEntryError = /** @class */ (function () {
    function GetContentEntryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEntryError;
    }
    return GetContentEntryError;
}());
export { GetContentEntryError };
var CreateEntry = /** @class */ (function () {
    function CreateEntry(
    /**
     * `languageCode` and `parentId` are only required if the
     * ContentEntry is translated content.
     */
    payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateEntry;
    }
    return CreateEntry;
}());
export { CreateEntry };
var CreateEntrySuccess = /** @class */ (function () {
    function CreateEntrySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateEntrySuccess;
    }
    return CreateEntrySuccess;
}());
export { CreateEntrySuccess };
var CreateEntryError = /** @class */ (function () {
    function CreateEntryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateEntryError;
    }
    return CreateEntryError;
}());
export { CreateEntryError };
var CreateNewContentEntry = /** @class */ (function () {
    function CreateNewContentEntry(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateNewContentEntry;
    }
    return CreateNewContentEntry;
}());
export { CreateNewContentEntry };
var SaveEntry = /** @class */ (function () {
    function SaveEntry(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SaveEntry;
    }
    return SaveEntry;
}());
export { SaveEntry };
var SaveEntrySuccess = /** @class */ (function () {
    function SaveEntrySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SaveEntrySuccess;
    }
    return SaveEntrySuccess;
}());
export { SaveEntrySuccess };
var SaveEntryError = /** @class */ (function () {
    function SaveEntryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SaveEntryError;
    }
    return SaveEntryError;
}());
export { SaveEntryError };
var DeleteEntry = /** @class */ (function () {
    function DeleteEntry(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteEntry;
    }
    return DeleteEntry;
}());
export { DeleteEntry };
var DeleteEntrySuccess = /** @class */ (function () {
    function DeleteEntrySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteEntrySuccess;
    }
    return DeleteEntrySuccess;
}());
export { DeleteEntrySuccess };
var DeleteEntryError = /** @class */ (function () {
    function DeleteEntryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteEntryError;
    }
    return DeleteEntryError;
}());
export { DeleteEntryError };
var MoveEntries = /** @class */ (function () {
    function MoveEntries(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.MoveEntries;
    }
    return MoveEntries;
}());
export { MoveEntries };
var MoveEntriesSuccess = /** @class */ (function () {
    function MoveEntriesSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.MoveEntriesSuccess;
    }
    return MoveEntriesSuccess;
}());
export { MoveEntriesSuccess };
var MoveEntriesError = /** @class */ (function () {
    function MoveEntriesError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.MoveEntriesError;
    }
    return MoveEntriesError;
}());
export { MoveEntriesError };
var DeleteEntries = /** @class */ (function () {
    function DeleteEntries(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteEntries;
    }
    return DeleteEntries;
}());
export { DeleteEntries };
var DeleteEntriesSuccess = /** @class */ (function () {
    function DeleteEntriesSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteEntriesSuccess;
    }
    return DeleteEntriesSuccess;
}());
export { DeleteEntriesSuccess };
var DeleteEntriesError = /** @class */ (function () {
    function DeleteEntriesError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteEntriesError;
    }
    return DeleteEntriesError;
}());
export { DeleteEntriesError };
// Category Actions
// ============================================
var SavingCategory = /** @class */ (function () {
    function SavingCategory(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingCategory;
    }
    return SavingCategory;
}());
export { SavingCategory };
var SavingCategorySuccess = /** @class */ (function () {
    function SavingCategorySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingCategorySuccess;
    }
    return SavingCategorySuccess;
}());
export { SavingCategorySuccess };
var SavingCategoryError = /** @class */ (function () {
    function SavingCategoryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingCategoryError;
    }
    return SavingCategoryError;
}());
export { SavingCategoryError };
var CreateCategory = /** @class */ (function () {
    function CreateCategory(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateCategory;
    }
    return CreateCategory;
}());
export { CreateCategory };
var CreateCategorySuccess = /** @class */ (function () {
    function CreateCategorySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateCategorySuccess;
    }
    return CreateCategorySuccess;
}());
export { CreateCategorySuccess };
var CreateCategoryError = /** @class */ (function () {
    function CreateCategoryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateCategoryError;
    }
    return CreateCategoryError;
}());
export { CreateCategoryError };
var DeleteCategory = /** @class */ (function () {
    function DeleteCategory(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteCategory;
    }
    return DeleteCategory;
}());
export { DeleteCategory };
var DeleteCategorySuccess = /** @class */ (function () {
    function DeleteCategorySuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteCategorySuccess;
    }
    return DeleteCategorySuccess;
}());
export { DeleteCategorySuccess };
var DeleteCategoryError = /** @class */ (function () {
    function DeleteCategoryError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteCategoryError;
    }
    return DeleteCategoryError;
}());
export { DeleteCategoryError };
// Templates
// ===================================================
var CreateTemplate = /** @class */ (function () {
    function CreateTemplate(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateTemplate;
    }
    return CreateTemplate;
}());
export { CreateTemplate };
var CreateTemplateSuccess = /** @class */ (function () {
    function CreateTemplateSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateTemplateSuccess;
    }
    return CreateTemplateSuccess;
}());
export { CreateTemplateSuccess };
var CreateTemplateError = /** @class */ (function () {
    function CreateTemplateError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreateTemplateError;
    }
    return CreateTemplateError;
}());
export { CreateTemplateError };
var CreatedTypeAndTemplateSuccess = /** @class */ (function () {
    function CreatedTypeAndTemplateSuccess(
    // type id
    payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CreatedTypeAndTemplateSuccess;
    }
    return CreatedTypeAndTemplateSuccess;
}());
export { CreatedTypeAndTemplateSuccess };
var DeleteTemplate = /** @class */ (function () {
    function DeleteTemplate(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteTemplate;
    }
    return DeleteTemplate;
}());
export { DeleteTemplate };
var DeleteTemplateSuccess = /** @class */ (function () {
    function DeleteTemplateSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteTemplateSuccess;
    }
    return DeleteTemplateSuccess;
}());
export { DeleteTemplateSuccess };
var DeleteTemplateError = /** @class */ (function () {
    function DeleteTemplateError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.DeleteTemplateError;
    }
    return DeleteTemplateError;
}());
export { DeleteTemplateError };
var SavingTemplate = /** @class */ (function () {
    function SavingTemplate(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingTemplate;
    }
    return SavingTemplate;
}());
export { SavingTemplate };
var SavingTemplateSuccess = /** @class */ (function () {
    function SavingTemplateSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingTemplateSuccess;
    }
    return SavingTemplateSuccess;
}());
export { SavingTemplateSuccess };
var SavingTemplateError = /** @class */ (function () {
    function SavingTemplateError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingTemplateError;
    }
    return SavingTemplateError;
}());
export { SavingTemplateError };
// Client Side
var SavingContent = /** @class */ (function () {
    function SavingContent(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingContent;
    }
    return SavingContent;
}());
export { SavingContent };
var SavingContentSuccess = /** @class */ (function () {
    function SavingContentSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingContentSuccess;
    }
    return SavingContentSuccess;
}());
export { SavingContentSuccess };
var SavingContentError = /** @class */ (function () {
    function SavingContentError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.SavingContentError;
    }
    return SavingContentError;
}());
export { SavingContentError };
var OpenDirectorySection = /** @class */ (function () {
    function OpenDirectorySection(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.OpenDirectorySection;
    }
    return OpenDirectorySection;
}());
export { OpenDirectorySection };
var LoadingDirectory = /** @class */ (function () {
    function LoadingDirectory(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.LoadingDirectory;
    }
    return LoadingDirectory;
}());
export { LoadingDirectory };
var LoadedDirectory = /** @class */ (function () {
    function LoadedDirectory(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.LoadedDirectory;
    }
    return LoadedDirectory;
}());
export { LoadedDirectory };
var LoadingTemplate = /** @class */ (function () {
    function LoadingTemplate(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.LoadingTemplate;
    }
    return LoadingTemplate;
}());
export { LoadingTemplate };
var LoadedTemplate = /** @class */ (function () {
    function LoadedTemplate(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.LoadedTemplate;
    }
    return LoadedTemplate;
}());
export { LoadedTemplate };
var LoadingContent = /** @class */ (function () {
    function LoadingContent(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.LoadingContent;
    }
    return LoadingContent;
}());
export { LoadingContent };
var LoadedContent = /** @class */ (function () {
    function LoadedContent(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.LoadedContent;
    }
    return LoadedContent;
}());
export { LoadedContent };
var FilterContent = /** @class */ (function () {
    function FilterContent(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.FilterContent;
    }
    return FilterContent;
}());
export { FilterContent };
var ClearFilter = /** @class */ (function () {
    function ClearFilter(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.ClearFilter;
    }
    return ClearFilter;
}());
export { ClearFilter };
var ChangeTab = /** @class */ (function () {
    function ChangeTab(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.ChangeTab;
    }
    return ChangeTab;
}());
export { ChangeTab };
var ContentEntryPageUnload = /** @class */ (function () {
    function ContentEntryPageUnload() {
        this.type = ContentActionTypes.ContentEntryPageUnload;
    }
    return ContentEntryPageUnload;
}());
export { ContentEntryPageUnload };
var GetContentEditorEnabled = /** @class */ (function () {
    function GetContentEditorEnabled(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEditorEnabled;
    }
    return GetContentEditorEnabled;
}());
export { GetContentEditorEnabled };
var GetContentEditorEnabledSuccess = /** @class */ (function () {
    function GetContentEditorEnabledSuccess(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEditorEnabledSuccess;
    }
    return GetContentEditorEnabledSuccess;
}());
export { GetContentEditorEnabledSuccess };
var GetContentEditorEnabledError = /** @class */ (function () {
    function GetContentEditorEnabledError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.GetContentEditorEnabledError;
    }
    return GetContentEditorEnabledError;
}());
export { GetContentEditorEnabledError };
var CopyGlobalContent = /** @class */ (function () {
    function CopyGlobalContent(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalContent;
    }
    return CopyGlobalContent;
}());
export { CopyGlobalContent };
var CopyGlobalContentSuccessMissingTranslations = /** @class */ (function () {
    function CopyGlobalContentSuccessMissingTranslations(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalContentSuccessMissingTranslations;
    }
    return CopyGlobalContentSuccessMissingTranslations;
}());
export { CopyGlobalContentSuccessMissingTranslations };
var CopyGlobalContentSuccessAdditionalTranslations = /** @class */ (function () {
    function CopyGlobalContentSuccessAdditionalTranslations(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalContentSuccessAdditionalTranslations;
    }
    return CopyGlobalContentSuccessAdditionalTranslations;
}());
export { CopyGlobalContentSuccessAdditionalTranslations };
var CopyGlobalContentError = /** @class */ (function () {
    function CopyGlobalContentError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalContentError;
    }
    return CopyGlobalContentError;
}());
export { CopyGlobalContentError };
var CopyGlobalFileContent = /** @class */ (function () {
    function CopyGlobalFileContent(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalFileContent;
    }
    return CopyGlobalFileContent;
}());
export { CopyGlobalFileContent };
var CopyGlobalFileContentError = /** @class */ (function () {
    function CopyGlobalFileContentError(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalFileContentError;
    }
    return CopyGlobalFileContentError;
}());
export { CopyGlobalFileContentError };
var CopyGlobalFileContentSuccessMissingTranslations = /** @class */ (function () {
    function CopyGlobalFileContentSuccessMissingTranslations(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalFileContentSuccessMissingTranslations;
    }
    return CopyGlobalFileContentSuccessMissingTranslations;
}());
export { CopyGlobalFileContentSuccessMissingTranslations };
var CopyGlobalFileContentSuccessAdditionalTranslations = /** @class */ (function () {
    function CopyGlobalFileContentSuccessAdditionalTranslations(payload) {
        this.payload = payload;
        this.type = ContentActionTypes.CopyGlobalFileContentSuccessAdditionalTranslations;
    }
    return CopyGlobalFileContentSuccessAdditionalTranslations;
}());
export { CopyGlobalFileContentSuccessAdditionalTranslations };
