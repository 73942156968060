<div class="list-container" fxLayout="column">
  <portal-toolbar-title
    [text]="contentSwingText$ | async"
    [patientName]="patientName$ | async"
    [patientView]="viewAsPatient$ | async"
    (switchView)="handleSwitchView()"
    (viewInfo)="handleViewInfo()"
  ></portal-toolbar-title>
  <!-- <mat-toolbar
    class="search"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    Search Form
  </mat-toolbar> -->
  <portal-assigned-pack-list
    [text]="contentSwingText$ | async"
    [patientId]="patientId$ | async"
    [viewAsPatient]="viewAsPatient$ | async"
    [loadingAllPatientContent]="loadingAllPatientContent$ | async"
    [loadingPatientContentView]="loadingPatientContentView$ | async"
    [openPacks]="clinicViewingAssignedPacks$ | async"
    [openItems]="clinicViewingAssignedItems$ | async"
    [openFolderId]="patientViewOpenFolder$ | async"
    [openPackId]="activeAssignedPackId$ | async"
    [filterActive]="filterActive$ | async"
    [patientViewContent]="patientViewContent$ | async"
    [assignedPacks]="assignedPacks$ | async"
    [assignedContent]="assignedContent$ | async"
    [activeTab]="activeTab$ | async"
    (removeItem)="removeIndividualItem($event)"
    (switchClinicView)="handleClinicViewSwitch($event)"
    (openAssignedPack)="openAssignedPack($event)"
    (removePack)="removePack($event)"
    (excludeItem)="excludeItem($event)"
    (openPatientFolder)="openPatientFolder($event)"
    (filterList)="setAssignedContentFilter($event)"
  ></portal-assigned-pack-list>
</div>
