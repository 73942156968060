import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
var ClinicStatusService = /** @class */ (function () {
    function ClinicStatusService(_httpService, _store, _error) {
        this._httpService = _httpService;
        this._store = _store;
        this._error = _error;
        this._clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
    }
    ClinicStatusService.prototype.getClinicStatus = function () {
        var _this = this;
        return this._clinicToken$.pipe(switchMap(function (clinicToken) {
            return _this._httpService.performStatusRequest(clinicToken);
        }), catchError(function (err) {
            return throwError(err);
        }));
    };
    return ClinicStatusService;
}());
export { ClinicStatusService };
