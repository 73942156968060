import * as tslib_1 from "tslib";
import { List, ListItem, ListItemText, Paper, Portal, } from '@material-ui/core';
import React, { useMemo } from 'react';
import { createEditor, Editor, Range, Transforms, } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, Slate, withReact, } from 'slate-react';
import { EditorContainer, EditorStyled, ParagraphSpan, StyledCharacterCount, Toolbar, VariablesButton, } from './TemplateEditor.styled';
import { deserialize, getLastThreeCharacters, getLastThreeCharactersRange, insertVariable, serialize, } from './TemplateEditor.utils';
// The reason for the many @ts-ignore is that even though we've altered the declarations
// for Slate it seems our TypeScript version is too old and it fails to merge them
// properly.
export var TemplateEditor = function (_a) {
    var dataTestId = _a["data-testid"], error = _a.error, insertVariablesButtonText = _a.insertVariablesButtonText, maxLength = _a.maxLength, onBlur = _a.onBlur, onChange = _a.onChange, value = _a.value, variables = _a.variables, rest = tslib_1.__rest(_a, ['data-testid', "error", "insertVariablesButtonText", "maxLength", "onBlur", "onChange", "value", "variables"]);
    // Using the "callback ref" pattern here to ensure we can act on a ref being
    // set (https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node).
    var _b = React.useState(null), portalRef = _b[0], setPortalRef = _b[1];
    // The current "target" Slate range where the user is typing
    var _c = React.useState(), target = _c[0], setTarget = _c[1];
    // The currently selected variable index in the popup list
    var _d = React.useState(0), variableIndex = _d[0], setVariableIndex = _d[1];
    // The current set of characters the user has entered in variable mode
    var _e = React.useState(''), search = _e[0], setSearch = _e[1];
    var renderElement = React.useCallback(function (props) { return React.createElement(Element, tslib_1.__assign({}, props)); }, []);
    var editor = useMemo(function () { return withReact(withHistory(createEditor())); }, []);
    var matchingVariables = React.useMemo(function () {
        var searchLowerCase = search.toLowerCase();
        var others = variables
            .filter(function (c) { return c.toLowerCase().includes(searchLowerCase); })
            .slice(0, 10);
        // If the user has started entering a custom variable we'll
        // have no matches and can allow them to use what they're
        // entering.
        return others.length > 0 ? others : [search];
    }, [search, variables]);
    var handleKeyDown = React.useCallback(function (event) {
        if (target) {
            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    var prevIndex = variableIndex >= matchingVariables.length - 1
                        ? 0
                        : variableIndex + 1;
                    setVariableIndex(prevIndex);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    var nextIndex = variableIndex <= 0
                        ? matchingVariables.length - 1
                        : variableIndex - 1;
                    setVariableIndex(nextIndex);
                    break;
                case 'Tab':
                case 'Enter':
                    event.preventDefault();
                    Transforms.select(editor, target);
                    insertVariable(editor, matchingVariables[variableIndex]);
                    setTarget();
                    break;
                case 'Escape':
                    event.preventDefault();
                    setTarget();
                    break;
            }
        }
    }, [editor, variableIndex, matchingVariables, target]);
    var handleChange = React.useCallback(function (newValue) {
        var isSelectionChangeOnly = editor.operations.every(function (op) { return 'set_selection' === op.type; });
        // Moved the cursor so cancel any in progress variable mode
        if (isSelectionChangeOnly) {
            setVariableIndex(0);
            setSearch('');
            setTarget();
            return;
        }
        // Propagate any change in value
        onChange(serialize(newValue));
        var operations = editor.operations, selection = editor.selection;
        if (selection == null || !Range.isCollapsed(selection)) {
            return;
        }
        var start = Range.edges(selection)[0];
        var insertTextOp = operations.find(function (op) { return op.type === 'insert_text'; });
        // No current variable in progress
        if (target == null) {
            // We start caring when the user enters a "{" as after the third
            // one we enter variable mode.
            if (insertTextOp == null || !insertTextOp.text.includes('{')) {
                return;
            }
            var beforeRange = getLastThreeCharactersRange(editor, start);
            var beforeText = beforeRange && Editor.string(editor, beforeRange);
            var beforeMatch = beforeText && beforeText.match(/^\{\{\{$/);
            if (beforeMatch != null) {
                setVariableIndex(0);
                setSearch('');
                setTarget(beforeRange);
            }
            return;
        }
        if (insertTextOp != null) {
            // If user has entered a "}" we need to check if it's the
            // third one and therefore exit variable mode.
            if (insertTextOp.text === '}') {
                var beforeText = getLastThreeCharacters(editor, start);
                var beforeMatch = beforeText && beforeText.match(/^\}\}\}$/);
                if (beforeMatch != null) {
                    setVariableIndex(0);
                    setSearch('');
                    setTarget();
                    return;
                }
            }
        }
        var afterRange = Editor.range(editor, target, start);
        var afterText = afterRange && Editor.string(editor, afterRange);
        // Has user removed any opening braces?
        if (!afterText.startsWith('{{{')) {
            setVariableIndex(0);
            setSearch('');
            setTarget();
            return;
        }
        var afterMatch = afterText && afterText.match(/^\{\{\{(.*)$/);
        if (afterMatch != null) {
            // Will stop any braces showing in the popup list
            setSearch(afterMatch[1].replace(/\{|\}/g, ''));
            setTarget(afterRange);
        }
    }, [editor, onChange, target]);
    var handleInsertVariableButtonClick = React.useCallback(function () {
        if (target != null) {
            setSearch('');
            setTarget();
            return;
        }
        ReactEditor.focus(editor);
        setTimeout(function () { return editor.insertText('{{{'); }, 10);
    }, [editor, target]);
    var handleVariableListItemClick = React.useCallback(function (e) {
        var variable = e.currentTarget.dataset.variable;
        if (variable != null) {
            ReactEditor.focus(editor);
            Transforms.select(editor, target);
            insertVariable(editor, variable);
            setTarget();
        }
    }, [editor, target]);
    // Move the portal containing the menu to just below where
    // the user is typing.
    React.useLayoutEffect(function () {
        // We need the "callback ref" pattern so we get a re-render
        // when the "ref" has been set and can then move the menu
        // to the correct place.
        if (target && matchingVariables.length > 0 && portalRef != null) {
            var domRange = ReactEditor.toDOMRange(editor, target);
            var rect = domRange.getBoundingClientRect();
            portalRef.style.top = rect.top + window.pageYOffset + 24 + "px";
            portalRef.style.left = rect.left + window.pageXOffset + "px";
        }
    }, [matchingVariables.length, editor, target, portalRef]);
    // Reset the user's selection if the list of variables
    // has shrunk enough to remove their previously highlighted variable
    React.useEffect(function () {
        if (target != null && variableIndex >= matchingVariables.length) {
            setVariableIndex(0);
        }
    }, [variableIndex, matchingVariables, target]);
    return (React.createElement(Slate, { editor: editor, onChange: handleChange, value: deserialize(value) },
        React.createElement(EditorContainer, { "data-testid": dataTestId, error: error },
            React.createElement(EditorStyled, tslib_1.__assign({}, rest, { autoComplete: "off", onBlur: onBlur, onKeyDown: handleKeyDown, renderElement: renderElement })),
            React.createElement(StyledCharacterCount, { length: value.length, maxLength: maxLength }),
            React.createElement(Toolbar, null,
                React.createElement(VariablesButton, { onClick: handleInsertVariableButtonClick, size: "small", type: "button", variant: "text" }, insertVariablesButtonText))),
        target != null && matchingVariables.length > 0 && (React.createElement(Portal, null,
            React.createElement(Paper, { elevation: 5, ref: setPortalRef, style: {
                    top: '-9999px',
                    left: '-9999px',
                    position: 'absolute',
                    zIndex: 1,
                } },
                React.createElement(List, null, matchingVariables.map(function (variable, i) { return (React.createElement(ListItem, { button: true, "data-variable": variable, key: variable, onClick: handleVariableListItemClick, selected: i === variableIndex },
                    React.createElement(ListItemText, null, variable))); })))))));
};
var Element = function (props) {
    var attributes = props.attributes, children = props.children, element = props.element;
    // @ts-ignore
    switch (element.type) {
        default:
            return React.createElement(ParagraphSpan, tslib_1.__assign({}, attributes), children);
    }
};
var ɵ0 = Element;
export { ɵ0 };
