var AddMessageToThreadRequest = /** @class */ (function () {
    function AddMessageToThreadRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId, MessageContent = _a.MessageContent;
        this.Action = 'InsertClinicMessage';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
        this.MessageContent = MessageContent;
    }
    return AddMessageToThreadRequest;
}());
export { AddMessageToThreadRequest };
