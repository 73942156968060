import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';

import { User } from 'src/app/auth/models/user';

@Injectable()
export class ErrorAnnotationService implements OnDestroy {
  private _extra: any;
  private _user$: Observable<User>;
  private user: User;

  private _subs = new Subscription();

  constructor(private _store: Store<fromRoot.State>) {
    this._user$ = this._store.pipe(select(fromAuth.getUser));
    this._subs.add(this._user$.subscribe((u) => (this.user = u)));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public setExtra(data: any) {
    this._extra = data;
  }

  public annotateError(error: any): any {
    return {
      ...error,
      user: this.user,
    };
  }

  private _clearExtra() {
    this._extra = undefined;
  }
}
