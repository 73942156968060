<div
  class="create-form-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <p>{{ data.text.CreateCategory }}</p>
  </div>
  <div class="form-field-container" fxLayout="column">
    <mat-form-field class="text-input">
      <input
        matInput
        type="text"
        placeholder="{{ data.text.AddTitle }}"
        [formControl]="folderName"
      />
      <div matSuffix fxLayout="row" fxLayoutAlign="end center">
        <mat-spinner
          matSuffix
          *ngIf="checkingFolderName$ | async"
          [diameter]="14"
        >
        </mat-spinner>
        <button
          mat-button
          mat-icon-button
          aria-label="Clear"
          (click)="clearForm()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-form-field>
    <div class="errors">
      <mat-error *ngIf="folderName.hasError('required') && folderName.dirty">{{
        data.text.FolderRequired
      }}</mat-error>
      <mat-error
        *ngIf="
          (folderName.hasError('minlength') ||
            folderName.hasError('maxlength')) &&
          !folderName.hasError('required') &&
          folderName.dirty
        "
        >{{ data.text.FolderLength }}</mat-error
      >
      <mat-error
        *ngIf="folderName.hasError('nameExists') && folderName.dirty"
        >{{ data.text.FolderExists }}</mat-error
      >
    </div>
  </div>
  <div
    fxFlex="grow"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="15px"
  >
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="cancelNameChange()"
    >
      {{ data.text.Cancel }}
    </button>
    <div class="status">
      <div
        *ngIf="savingFolder$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-spinner [diameter]="36"></mat-spinner>
        <div class="status-text">
          {{ data.text.CreatingFolder }}
        </div>
      </div>
      <div
        *ngIf="savedFolder$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-icon class="success">check_circle</mat-icon>
        <div class="status-text">
          {{ data.text.CreatedFolder }}
        </div>
      </div>
      <div
        *ngIf="errorEncountered$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-icon class="error">highlight_off</mat-icon>
        <div class="status-text">
          {{ data.text.ErrorCreatingFolder }}
        </div>
      </div>
    </div>
    <button
      mat-raised-button
      class="portal-btn btn-bright"
      [disabled]="
        folderName.status === 'INVALID' || folderName.status === 'PENDING'
      "
      (click)="createNewFolder()"
    >
      {{ data.text.Create }}
    </button>
  </div>
</div>
