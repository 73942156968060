<!-- Patient Details -->
<mat-card #patientCard class="patient-card">
  <div
    fxFlexFill
    *ngIf="patientLoading$ | async"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-spinner> </mat-spinner>
  </div>

  <div
    *ngIf="!(patientLoading$ | async)"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <!-- Top Level Patient Information -->
    <portal-patient-top-level
      [text]="patientInfoText$ | async"
      [patient]="topLevelPatientData$ | async"
      [videoCallsEnabled]="videoCallsEnabled$ | async"
      (openNewMessage)="openNewMessage()"
      (openNewVideoCall)="openVideoCall()"
    >
    </portal-patient-top-level>
    <div fxLayout="row" fxLayoutAlign="start start">
      <!-- Sections List -->
      <portal-patient-sections-list
        [text]="patientInfoText$ | async"
        [sections]="sectionsToShow$ | async"
        [activeSection]="activeSection$ | async"
        (changeSection)="changeActiveSection($event)"
      >
      </portal-patient-sections-list>

      <!-- Section Detail -->
      <div class="section-detail" fxFlex="grow">
        <portal-patient-info
          [text]="patientInfoText$ | async"
          [isCore]="isCoreClinic$ | async"
          [activeSection]="activeSection$ | async"
          [patientInfo]="patientInfo$ | async"
          [partnerInfo]="partnerInfo$ | async"
          [contactInfo]="contactInfo$ | async"
          [labResults]="labResults$ | async"
          [manageContent]="'Coming Soon'"
        >
        </portal-patient-info>
      </div>
    </div>
  </div>
</mat-card>

<!-- Calendar -->
<mat-card *ngIf="!(isCoreClinic$ | async)" class="calendar-card">
  <mat-toolbar fxLayoutAlign="start center" class="calendar-header">
    <h3 class="card-title">{{ calendarText.Calendar }}</h3>
  </mat-toolbar>
  <portal-patient-calendar
    [text]="calendarText"
    [events]="events$ | async"
    [videoCallsEnabled]="videoCallsEnabled$ | async"
    [viewDate]="viewDate"
  >
  </portal-patient-calendar>
</mat-card>
<mat-card
  *ngIf="
    (medicationManagerEnabled$ | async) && (medicationManagerLoaded$ | async)
  "
  class="calendar-card"
>
  <mat-toolbar fxLayoutAlign="start center" class="calendar-header">
    <h3 class="card-title">Medication manager</h3>
  </mat-toolbar>
  <salve-medication-template-picker
    [client_token]="clinicGroupId$ | async"
    [clinic_id]="internalClinicId$ | async"
    [auth_token]="loggedInToken$ | async"
  ></salve-medication-template-picker>
</mat-card>
