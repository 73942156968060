import {
  Component,
  ErrorHandler,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { SimplePatient } from 'src/app/models/SimplePatient';
import { PatientSearchBase } from 'src/app/patients/PatientSearchBase';
import { PatientService } from 'src/app/patients/services/patient.service';

@Component({
  selector: 'portal-searchbar',
  templateUrl: `./searchbar.component.html`,
  styleUrls: [`./searchbar.component.scss`],
})
export class SearchbarComponent extends PatientSearchBase implements OnInit {
  @Input() text: any;
  @Output() gotoPatient = new EventEmitter<number>();

  public patientControl = new FormControl();
  public searchActive: boolean;
  public patients$: Observable<SimplePatient[]>;

  constructor(
    _patientService: PatientService,
    _localisationService: LocalisationService,
    private readonly _errorHandler: ErrorHandler,
  ) {
    super(_patientService, _localisationService);
  }

  ngOnInit(): void {
    this.setupSearchObservables(this._errorHandler);
    this.observeSearchInput(this.patientControl);
  }

  public patientSelected(patient: SimplePatient) {
    if (patient && patient.Id) {
      this.gotoPatient.emit(patient.Id);
      this.clearSearch();
    }
  }

  public clearSearch(): void {
    this.patientControl.patchValue('');
  }
}
