import { IconButton } from '@material-ui/core';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';
import { Button } from '@react/components/Button';
import { Dialog } from '@react/components/Dialog';
import { Empty } from '@react/components/Empty';
import { HelperText } from '@react/components/HelperText';
import { MessageIcon } from '@react/components/icons/MessageIcon';
import { Input } from '@react/components/Input';
import { Label } from '@react/components/Label';
import { Loading } from '@react/components/Loading';
import { TemplateEditor } from '@react/components/TemplateEditor';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import {
  useDeleteMessageTemplateMutation,
  useMessageTemplateQuery,
  useMessageTemplatesMutation,
  useMessageTemplateVariablesQuery,
} from '../hooks';
import { useMessageTemplateUniqueNameValidator } from '../hooks/useMessageTemplateUniqueNameValidator';
import { AppointmentTypeTitle, TitleWrapper } from './AppointmentType.styled';
import {
  Actions,
  ButtonProgress,
  Container,
  FieldWrapper,
  FullHeightPaper,
  StyledForm,
  WarningIcon,
  WarningText,
} from './MessageTemplate.styled';

interface Inputs {
  name: string;
  content: string;
}

const CONTENT_MAX_LENGTH = 2500;
const NAME_MIN_LENGTH = 5;
const NAME_MAX_LENGTH = 30;
const NAME_UNIQUE_NAME_DEBOUNCE_TIME = 350;

interface EditProps {
  id: number;
}

const MessageTemplateEdit: React.VFC<EditProps> = ({ id }) => {
  const { push, replace } = useHistory();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const { t } = useTranslations();
  const { data, isLoading, isError } = useMessageTemplateQuery({
    id,
  });
  const { data: variablesData, isLoading: isLoadingVariables } =
    useMessageTemplateVariablesQuery();
  const { mutate: save, isLoading: isSaving } = useMessageTemplatesMutation();
  const { mutate: _delete, isLoading: isDeleting } =
    useDeleteMessageTemplateMutation();
  const handleDeleteRequest = React.useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);
  const handleDeleteAcceptClick = React.useCallback(() => {
    setIsDeleteDialogOpen(false);
    _delete(id, {
      onSuccess: () => {
        replace('/admin/message-templates');
      },
    });
  }, []);
  const handleDeleteCancelClick = React.useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);
  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    save(
      {
        id: data != null ? data.data.id : undefined,
        content: formData.content,
        name: formData.name,
      },
      {
        onSuccess: () => {
          push('/admin/message-templates');
        },
      },
    );
  };

  React.useEffect(() => {
    if (isError) {
      replace('/admin/message-templates');
    }
  }, [data, isError]);

  return (
    <Container>
      <TitleWrapper>
        <IconButton component={Link} to="/admin/message-templates" size="small">
          <IconArrowBack />
        </IconButton>
        {data != null && (
          <AppointmentTypeTitle variant="h5" role="heading">
            {data.data.name}
          </AppointmentTypeTitle>
        )}
      </TitleWrapper>
      <FullHeightPaper>
        {isLoading || isLoadingVariables ? (
          <Loading />
        ) : data == null ? (
          <Empty
            icon={MessageIcon}
            message={t.Admin.MessageTemplatesEmptyMessage}
          />
        ) : (
          <MessageTemplateForm
            currentId={data.data.id}
            defaultContentValue={data.data.content}
            defaultNameValue={data.data.name}
            hasDeleteButton
            isDeleting={isDeleting}
            isSaving={isSaving}
            onDeleteRequest={handleDeleteRequest}
            onSubmit={onSubmit}
            variables={variablesData != null ? variablesData.data : []}
          />
        )}
      </FullHeightPaper>
      <Dialog
        acceptButtonText={t.Admin.MessageTemplatesDeleteDialogAcceptButtonText}
        cancelButtonText={t.Admin.MessageTemplatesDeleteDialogCancelButtonText}
        contentText={t.Admin.MessageTemplatesDeleteDialogContent}
        onAccept={handleDeleteAcceptClick}
        onCancel={handleDeleteCancelClick}
        isOpen={isDeleteDialogOpen}
        titleText={t.Admin.MessageTemplatesDeleteDialogTitle}
      />
    </Container>
  );
};

const MessageTemplateNew: React.VFC = () => {
  const { replace } = useHistory();
  const { t } = useTranslations();
  const { data: variablesData, isLoading: isLoadingVariables } =
    useMessageTemplateVariablesQuery();
  const { mutate: save, isLoading: isSaving } = useMessageTemplatesMutation();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    save(
      {
        content: formData.content,
        name: formData.name,
      },
      {
        onSuccess: () => {
          replace('/admin/message-templates');
        },
      },
    );
  };

  return (
    <Container>
      <TitleWrapper>
        <IconButton component={Link} to="/admin/message-templates" size="small">
          <IconArrowBack />
        </IconButton>
        <AppointmentTypeTitle variant="h5" role="heading">
          {t.Admin.MessageTemplatesNewTemplateButtonText}
        </AppointmentTypeTitle>
      </TitleWrapper>
      <FullHeightPaper>
        {isLoadingVariables ? (
          <Loading />
        ) : (
          <MessageTemplateForm
            isSaving={isSaving}
            onSubmit={onSubmit}
            variables={variablesData != null ? variablesData.data : []}
          />
        )}
      </FullHeightPaper>
    </Container>
  );
};

interface FormProps {
  currentId?: number;
  defaultContentValue?: string;
  defaultNameValue?: string;
  hasDeleteButton?: boolean;
  onDeleteRequest?: () => void;
  onSubmit: SubmitHandler<Inputs>;
  isDeleting?: boolean;
  isSaving?: boolean;
  variables: string[];
}

const MessageTemplateForm: React.VFC<FormProps> = ({
  currentId,
  isDeleting,
  isSaving,
  defaultContentValue = '',
  defaultNameValue = '',
  hasDeleteButton,
  onDeleteRequest,
  onSubmit,
  variables,
}) => {
  const { t } = useTranslations();
  const {
    control,
    errors,
    formState: { isDirty, isValid },
    register,
    handleSubmit,
  } = useForm<Inputs>({ mode: 'onChange' });

  // Needed so that we call the same `debounceAsync` instance across
  // different renders.
  const debouncedUniqueNameValidator = useMessageTemplateUniqueNameValidator({
    delay: NAME_UNIQUE_NAME_DEBOUNCE_TIME,
    errorMessage: t.Admin.MessageTemplatesNameErrorUnique,
    existingMessageTemplateId: currentId,
  });

  return (
    <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
      <WarningText color="textSecondary" variant="body2">
        <WarningIcon />
        {t.Admin.MessageTemplatesNavigationWarning}
      </WarningText>
      <FieldWrapper>
        <Label error={!!errors.name} htmlFor="name">
          {t.Admin.MessageTemplatesNameLabel}
        </Label>
        <Input
          aria-describedby="nameHelper"
          data-testid="NameInput"
          defaultValue={defaultNameValue}
          error={!!errors.name}
          fullWidth
          id="name"
          name="name"
          placeholder={t.Admin.MessageTemplatesNamePlaceholder}
          ref={register({
            minLength: {
              message: t.Admin.MessageTemplatesNameErrorLength(
                NAME_MIN_LENGTH,
                NAME_MAX_LENGTH,
              ),
              value: NAME_MIN_LENGTH,
            },
            maxLength: {
              message: t.Admin.MessageTemplatesNameErrorLength(
                NAME_MIN_LENGTH,
                NAME_MAX_LENGTH,
              ),
              value: NAME_MAX_LENGTH,
            },
            required: {
              message: t.Admin.MessageTemplatesNameErrorRequired,
              value: true,
            },
            validate: {
              uniqueName: debouncedUniqueNameValidator,
            },
          })}
        />
        <HelperText aria-live="polite" error={!!errors.name} id="nameHelper">
          {errors.name != null ? errors.name.message : null}
        </HelperText>
      </FieldWrapper>
      <FieldWrapper>
        <Label error={!!errors.content} htmlFor="content">
          {t.Admin.MessageTemplatesTemplateContentLabel}
        </Label>
        <Controller
          control={control}
          defaultValue={defaultContentValue}
          name="content"
          render={
            // @ts-ignore Need to update TypeScript
            ({ onChange, onBlur, value }) => (
              <TemplateEditor
                aria-describedby="contentHelperText"
                data-testid="ContentInput"
                error={!!errors.content}
                id="content"
                insertVariablesButtonText={
                  t.Admin.MessageTemplatesInsertVariableButtonText
                }
                maxLength={CONTENT_MAX_LENGTH}
                onBlur={() => onBlur()}
                onChange={(newValue) => onChange(newValue)}
                value={value}
                variables={variables}
              />
            )
          }
          rules={{
            maxLength: {
              message:
                t.Admin.MessageTemplatesContentErrorLength(CONTENT_MAX_LENGTH),
              value: CONTENT_MAX_LENGTH,
            },
            required: {
              message: t.Admin.MessageTemplatesContentErrorRequired,
              value: true,
            },
          }}
        />

        <HelperText
          aria-live="polite"
          error={!!errors.content}
          id="contentHelperText"
        >
          {errors.content != null ? errors.content.message : null}
        </HelperText>
      </FieldWrapper>
      <Actions>
        {hasDeleteButton && (
          <Button
            color="secondary"
            disabled={isDeleting || isSaving}
            onClick={onDeleteRequest}
            type="button"
            variant="contained"
          >
            <>
              {t.Admin.MessageTemplatesDeleteButtonText}
              {isDeleting && <ButtonProgress size={24} />}
            </>
          </Button>
        )}
        <Button
          color="primary"
          disabled={!isDirty || isSaving || !isValid}
          type="submit"
          variant="contained"
        >
          <>
            {t.Admin.MessageTemplatesSaveTemplateButtonText}
            {isSaving && <ButtonProgress size={24} />}
          </>
        </Button>
      </Actions>
    </StyledForm>
  );
};

const MessageTemplate: React.VFC = () => {
  const { id } = useParams<{ id?: string }>();

  if (id == null) {
    return <MessageTemplateNew />;
  }

  const parsedId = parseInt(id, 10);
  const hasValidId = !isNaN(parsedId);

  if (!hasValidId) {
    return <Redirect to="/admin/message-templates" />;
  }

  return <MessageTemplateEdit id={parsedId} />;
};

export default MessageTemplate;
