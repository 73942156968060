import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  LinkDeletionParams,
  LinkDeletionParamTypes,
} from '../../models/linked-content';
import { ChildInfo, ContentEntryHeader } from './../../models/content-entry';

@Component({
  selector: 'portal-view-children',
  templateUrl: './view-children.component.html',
  styleUrls: ['./view-children.component.scss'],
})
export class ViewChildrenComponent implements OnInit, OnChanges {
  @Input() text: any;
  @Input() children: ChildInfo[] = [];
  @Input() childSelected: number;
  @Input() loadingChildren: boolean;
  @Input() parentInfo: ContentEntryHeader;
  @Input() deletingLink: boolean;
  @Input() deleteLinkError: boolean;
  @Input() deleteLinkSuccess: boolean;
  @Input() deletingLinks: boolean;
  @Input() deleteLinksError: boolean;
  @Input() deleteLinksSuccess: boolean;
  @Output() deleteLinks = new EventEmitter<LinkDeletionParams>();
  @Output() cancel = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  public dataSource: MatTableDataSource<ChildInfo>;
  public config: PerfectScrollbarConfigInterface = {};
  public selection: SelectionModel<ChildInfo>;

  public displayedColumns: string[] = ['select', 'name'];

  constructor(public dialog: MatDialog, public change: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._updateDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.children && changes.children.currentValue) {
      this._updateDataSource();
    }
  }

  public handleDeleteLinks() {
    const howMany = this.howManySelected();
    let type = LinkDeletionParamTypes.one;
    if (howMany === 2) {
      type = LinkDeletionParamTypes.some;
    } else if (howMany === 3) {
      type = LinkDeletionParamTypes.all;
    }
    const ids = howMany === 3 ? [this.parentInfo.id] : this._selectedItems();
    this.deleteLinks.emit({
      type,
      ids,
    });
  }

  // Table related Function
  // ================================

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public howManySelected(): number {
    if (this.selection.selected.length === 0) {
      return 0; // none
    }
    if (this.selection.selected.length === this.dataSource.data.length) {
      return 3; // all (could be one - so this goes first)
    }
    if (this.selection.selected.length === 1) {
      return 1; // one
    }
    return 2; // some
  }

  public removeLinkText(): string {
    const howMany = this.howManySelected();
    switch (howMany) {
      case 0:
      case 1:
        return this.text.RemoveLink;
      case 2:
        return this.text.RemoveSomeLinks;
      case 3:
        return this.text.RemoveAllLinks;
    }
  }

  public clearSelection(): void {
    this.selection.clear();
    this.change.markForCheck();
    this.change.detectChanges();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.clearSelection()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  private _selectedItems(): number[] {
    return this.selection.selected.map((r) => r.id);
  }

  private _updateDataSource() {
    this.dataSource = new MatTableDataSource(this.children);
    this.dataSource.sort = this.sort;
    this.selection = new SelectionModel(true, []);
    if (this.childSelected && this.dataSource.data.length > 0) {
      const first = this.dataSource.data[0];
      if (first.id === this.childSelected) {
        this.dataSource.data.some((row) => {
          if (row.id === this.childSelected) {
            this.selection.select(row);
            return true;
          } else {
            return false;
          }
        });
      }
    }
  }
}
