import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 250px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.hint};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  text-align: center;
  font-weight: normal;
`;
