import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import { Category } from '../../models/category';
import * as fromContent from '../../reducers';

@Component({
  selector: 'portal-global-dashboard',
  templateUrl: './global-dashboard.component.html',
  styleUrls: ['./global-dashboard.component.scss'],
})
export class GlobalDashboardComponent implements OnInit, OnDestroy {
  public categories$: Observable<Category[]> = of([]);

  // Subscription to allow easy clean up
  private _subs = new Subscription();

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
  ) {}

  ngOnInit() {
    this.categories$ = this._store.pipe(
      select(fromContent.getGeneralCategories),
    );

    this._subs.add(
      combineLatest(this._route.url, this.categories$).subscribe(
        ([route, categories]) => {
          if (route[0].path === 'global' && categories.length) {
            this._navigationService.navigate([
              'content',
              'list',
              categories[0].id,
            ]);
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
