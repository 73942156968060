var _this = this;
import * as tslib_1 from "tslib";
import { Typography } from '@material-ui/core';
import { Button, CharacterCount, InputLabel, TextInput, } from '@react/components';
import { Container, StatusBar, TextArea, TextAreaContainer, } from '@react/components/MessageEditor.styled';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { useInviteClinicUserMutation } from '@react/pages/admin/hooks/users';
import { MESSAGE_MAX_LENGTH } from '@react/pages/conversations/constants';
import React, { useCallback, useState } from 'react';
import { ButtonRow, StyledContainer } from './index.styled';
var clinicURL = location.origin;
var InviteUser = function (_a) {
    var onClose = _a.onClose, user = _a.user;
    var t = useTranslations().t;
    var _b = useState(t.Admin.Users.InviteMessage(clinicURL)), message = _b[0], setMessage = _b[1];
    var _c = useInviteClinicUserMutation(), inviteClinicUser = _c.mutateAsync, isInvitingError = _c.isError, isInvitingSuccess = _c.isSuccess;
    useErrorToast(isInvitingError, {
        title: t.Admin.Users.InviteSentErrorTitle,
        message: t.Admin.Users.InviteSentErrorMessage,
    });
    useSuccessToast(isInvitingSuccess, {
        title: t.Admin.Users.InviteSentTitle,
        message: t.Admin.Users.InviteSentMessage,
    });
    var handleMessageChange = useCallback(function (e) {
        setMessage(e.target.value);
    }, [setMessage]);
    var handleClose = function () {
        onClose();
    };
    var onSendClicked = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, inviteClinicUser({ staffId: user.id, message: message })];
                case 1:
                    _a.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var isFormValid = function () {
        return validMessage;
    };
    var validMessage = message.length > 0 && message.length <= MESSAGE_MAX_LENGTH;
    return (React.createElement(StyledContainer, null,
        React.createElement(Typography, { className: "newMessageModal-title", variant: "subtitle1" }, t.Admin.Users.InviteUser),
        React.createElement("div", { className: "newMessageModal-input" },
            React.createElement(TextInput, { id: "email", label: t.Admin.Users.User, fullWidth: true, value: user.firstname + " " + user.lastname + " (" + user.email + ")", disabled: true })),
        React.createElement("div", { className: "newMessageModal-input" },
            React.createElement(InputLabel, { htmlFor: "newMessageModal-content" }, t.Admin.Users.WelcomeMessage),
            React.createElement(Container, null,
                React.createElement(TextAreaContainer, null,
                    React.createElement(TextArea, { id: "message", autoComplete: "off", disableUnderline: true, inputProps: {
                            maxLength: MESSAGE_MAX_LENGTH,
                        }, minRows: 4, maxRows: 6, multiline: true, onChange: handleMessageChange, value: message }),
                    React.createElement(StatusBar, null,
                        React.createElement("span", null),
                        React.createElement(CharacterCount, { length: message.length, maxLength: MESSAGE_MAX_LENGTH }))))),
        React.createElement(ButtonRow, null,
            React.createElement(Button, { onClick: handleClose, color: "secondary", size: "small", variant: "contained" }, t.Admin.Users.Cancel),
            React.createElement(Button, { onClick: onSendClicked, color: "primary", size: "small", variant: "contained", disabled: !isFormValid() }, t.Admin.Users.Send))));
};
var ɵ0 = InviteUser;
export default InviteUser;
export { ɵ0 };
