import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/reducers';
import { VideoCallModalComponent } from '../../../react/pages/videoCall/components/VideoCallModal';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromVideoCalls from '../../../video-calls/reducers';
import { DisplayToastAction } from '../../actions/toast.actions';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
var containerElementName = 'videoCallModalReactWrapperComponent';
var VideoCallModalReactWrapperComponent = /** @class */ (function () {
    function VideoCallModalReactWrapperComponent(_store, _cognito, data) {
        var _this = this;
        this._store = _store;
        this._cognito = _cognito;
        this.data = data;
        this._subscriptions = new Subscription();
        this.displayConnectedNotification = function () {
            _this._store.dispatch(new DisplayToastAction({
                toastRef: _this._toastRef,
                message: _this._videoCallModalText.Connected,
                title: _this._videoCallModalText.Success,
                timeout: 3000,
                type: 'success'
            }));
        };
        this.displayErrorNotification = function (errorMessage) {
            _this._store.dispatch(new DisplayToastAction({
                toastRef: _this._toastRef,
                message: errorMessage,
                title: _this._videoCallModalText.Error,
                timeout: 0,
                type: 'error'
            }));
        };
        this._toastRef$ = _store.pipe(select(fromRoot.getToastRef));
        this._videoCallModalText$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallModal')));
    }
    VideoCallModalReactWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.render();
        this._subscriptions.add(this._toastRef$.subscribe(function (tr) {
            _this._toastRef = tr;
        }));
        this._subscriptions.add(this._videoCallModalText$.subscribe(function (t) {
            _this._videoCallModalText = t;
        }));
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
            this._store.pipe(select(fromVideoCalls.getInCallInviteFlagSelector)),
            this._store.pipe(select(fromVideoCalls.getInvitingPartnerInCall))
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4], inCallInviteFlag = _a[5], invitingInCall = _a[6];
            _this._inCallInviteFlag = inCallInviteFlag;
            _this._invitingInCall = invitingInCall;
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user
            };
            _this.render();
        }));
    };
    VideoCallModalReactWrapperComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
        this._subscriptions.unsubscribe();
    };
    VideoCallModalReactWrapperComponent.prototype.ngAfterViewInit = function () {
        this.render();
    };
    VideoCallModalReactWrapperComponent.prototype.render = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var displayName;
            return tslib_1.__generator(this, function (_a) {
                displayName = (_.upperFirst(_.get(this._props, 'user.FirstName', '')) + " " + _.upperFirst(_.get(this._props, 'user.LastName', ''))).trim();
                ReactDOM.render(React.createElement(Root, tslib_1.__assign({}, this._props, { authService: this._cognito, dispatch: this._store.dispatch.bind(this._store), children: React.createElement(VideoCallModalComponent, {
                        displayName: displayName,
                        close: this.data.close,
                        joinResponse: this.data.joinResponse,
                        partnerInvited: this._inCallInviteFlag,
                        invitePartnerInCall: this.data.invitePartnerInCall,
                        invitingPartner: this._invitingInCall,
                        partnerInviteEnabled: this.data.partnerInviteEnabled,
                        displayConnectedNotification: this.displayConnectedNotification,
                        displayErrorNotification: this.displayErrorNotification
                    }) })), this.containerRef.nativeElement);
                return [2 /*return*/];
            });
        });
    };
    return VideoCallModalReactWrapperComponent;
}());
export { VideoCallModalReactWrapperComponent };
