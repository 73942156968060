import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { DisplayToastAction } from '../../core/actions/toast.actions';
import * as fromSettings from '../../settings/reducers';
import { MedicationsActionTypes } from '../actions/medications.actions';
import { MedicationsService } from '../services/medications.service';
var MedicationEffects = /** @class */ (function () {
    function MedicationEffects(actions$, medicationsService, _error, _store) {
        var _this = this;
        this.actions$ = actions$;
        this.medicationsService = medicationsService;
        this._error = _error;
        this._store = _store;
        this.loadPotentialDuplicatesError$ = this.actions$.pipe(ofType(MedicationsActionTypes.LoadPotentialDuplicatesError), map(function (action) { return _this._handleError(action); }));
        this.loadActionMedicationsError$ = this.actions$.pipe(ofType(MedicationsActionTypes.LoadActionMedicationsError), map(function (action) { return _this._handleError(action); }));
        this.loadNoActionMedicationsError$ = this.actions$.pipe(ofType(MedicationsActionTypes.LoadNoActionMedicationsError), map(function (action) { return _this._handleError(action); }));
        this._store
            .pipe(select(fromSettings.getSectionTranslations('Medication')))
            .subscribe(function (text) { return (_this._medicationTableText = text); });
    }
    MedicationEffects.prototype._handleError = function (action) {
        var toastOptions = {
            message: this._medicationTableText.ErrorMessage,
            timeout: 5000,
            title: this._medicationTableText.ErrorTitle,
            type: 'error'
        };
        return new DisplayToastAction(toastOptions);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MedicationEffects.prototype, "loadPotentialDuplicatesError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MedicationEffects.prototype, "loadActionMedicationsError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MedicationEffects.prototype, "loadNoActionMedicationsError$", void 0);
    return MedicationEffects;
}());
export { MedicationEffects };
