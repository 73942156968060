import {
  RealTimeActionsUnion,
  RealTimeMessageActionTypes,
} from '../actions/real-time.actions';
import { TypingInfo } from '../models/messaging';
import { MessagesState } from './index';

export function reducer(
  state: MessagesState,
  action: RealTimeActionsUnion,
): MessagesState {
  switch (action.type) {
    case RealTimeMessageActionTypes.Typing: {
      const typingInfo: TypingInfo = {
        user: action.payload.user,
        lastUpdated: action.payload.lastEdited,
      };

      const updatedMessagingInfo: TypingInfo[] =
        state.typingInfo[action.payload.messageId] || [];

      const currentUserMessageInfo = updatedMessagingInfo.findIndex(
        (ti) => ti.user === typingInfo.user,
      );

      if (currentUserMessageInfo > -1) {
        updatedMessagingInfo[currentUserMessageInfo] = typingInfo;
      } else {
        updatedMessagingInfo.push(typingInfo);
      }

      return {
        ...state,
        typingInfo: {
          ...state.typingInfo,
          [action.payload.messageId]: updatedMessagingInfo,
        },
      };
    }

    case RealTimeMessageActionTypes.ForceRefreshTyping: {
      return {
        ...state,
        forceRefreshTyping: new Date(),
      };
    }

    default: {
      return state;
    }
  }
}
