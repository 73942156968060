import * as tslib_1 from "tslib";
import { DashboardActionTypes, } from '../actions/dashboard.actions';
export var initialState = {
    summaryLoading: false,
    medicationsLoading: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DashboardActionTypes.LoadingClinicSummary: {
            return tslib_1.__assign({}, state, { summaryLoading: true });
        }
        case DashboardActionTypes.LoadingClinicMedication: {
            return tslib_1.__assign({}, state, { medicationsLoading: true });
        }
        case DashboardActionTypes.LoadedClinicSummary: {
            return tslib_1.__assign({}, state, { summaryLoading: false });
        }
        case DashboardActionTypes.LoadedClinicMedication: {
            return tslib_1.__assign({}, state, { medicationsLoading: false });
        }
        default: {
            return state;
        }
    }
}
export var summaryIsLoading = function (state) { return state.summaryLoading; };
export var medicationsAreLoading = function (state) { return state.medicationsLoading; };
