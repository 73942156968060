import * as tslib_1 from "tslib";
import { useCallbackDebounced } from '@react/lib/hooks/useCallbackDebounced';
import useStateWithRef from '@react/lib/hooks/useStateWithRef';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useZoom } from './useZoom';
function useIsMountedRef() {
    var isMountedRef = useRef(false);
    useEffect(function () {
        isMountedRef.current = true;
        return function () {
            isMountedRef.current = false;
        };
    }, []);
    return isMountedRef;
}
export function useVideoCall(_a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a, onUserAddedCallback = _b.onUserAdded, onUserUpdatedCallback = _b.onUserUpdated, onUserRemovedCallback = _b.onUserRemoved;
    var isMountedRef = useIsMountedRef();
    var zoomClient = useZoom().zoomClient;
    var _c = useState([]), cameraList = _c[0], setCameraList = _c[1];
    var _d = useState([]), microphoneList = _d[0], setMicrophoneList = _d[1];
    var _e = useState([]), speakerList = _e[0], setSpeakerList = _e[1];
    var _f = useStateWithRef([]), participants = _f[0], setParticipants = _f[1], participantsRef = _f[2];
    var _g = useStateWithRef(true), micOn = _g[0], setMicOn = _g[1], micOnRef = _g[2];
    var _h = useStateWithRef(true), cameraOn = _h[0], setCameraOn = _h[1], cameraOnRef = _h[2];
    var _j = useStateWithRef(), currentUser = _j[0], setCurrentUser = _j[1], currentUserRef = _j[2];
    var _k = useState(false), joining = _k[0], setJoining = _k[1];
    var _l = useState(), error = _l[0], setError = _l[1];
    var initRef = useRef(false);
    var joinRef = useRef(false);
    var changeCamera = useCallbackDebounced(function (deviceId) {
        if (!zoomClient || !initRef.current || !joinRef.current) {
            return false;
        }
        var mediaStream = zoomClient.getMediaStream();
        var activeCamera = mediaStream.getActiveCamera();
        if (deviceId !== activeCamera) {
            mediaStream.switchCamera(deviceId);
            setCameraList(function (prevCameraList) {
                return prevCameraList.map(function (camera) {
                    if (camera.deviceId === deviceId) {
                        return tslib_1.__assign({}, camera, { active: true });
                    }
                    else if (camera.active) {
                        return tslib_1.__assign({}, camera, { active: false });
                    }
                    else {
                        return camera;
                    }
                });
            });
        }
    }, [zoomClient, initRef, joinRef]);
    var changeMic = useCallbackDebounced(function (deviceId) {
        if (!zoomClient || !initRef.current || !joinRef.current) {
            return false;
        }
        var mediaStream = zoomClient.getMediaStream();
        var activeMic = mediaStream.getActiveMicrophone();
        if (deviceId !== activeMic) {
            mediaStream.switchMicrophone(deviceId);
            setMicrophoneList(function (prevMicrophoneList) {
                return prevMicrophoneList.map(function (microphone) {
                    if (microphone.deviceId === deviceId) {
                        return tslib_1.__assign({}, microphone, { active: true });
                    }
                    else if (microphone.active) {
                        return tslib_1.__assign({}, microphone, { active: false });
                    }
                    else {
                        return microphone;
                    }
                });
            });
        }
    }, [zoomClient, initRef, joinRef]);
    var changeSpeaker = useCallbackDebounced(function (deviceId) {
        if (!zoomClient || !initRef.current || !joinRef.current) {
            return false;
        }
        var mediaStream = zoomClient.getMediaStream();
        var activeSpeaker = mediaStream.getActiveSpeaker();
        if (deviceId !== activeSpeaker) {
            mediaStream.switchSpeaker(deviceId);
            setSpeakerList(function (prevSpeakerList) {
                return prevSpeakerList.map(function (speaker) {
                    if (speaker.deviceId === deviceId) {
                        return tslib_1.__assign({}, speaker, { active: true });
                    }
                    else if (speaker.active) {
                        return tslib_1.__assign({}, speaker, { active: false });
                    }
                    else {
                        return speaker;
                    }
                });
            });
        }
    }, [zoomClient, initRef, joinRef]);
    var toggleMic = useCallbackDebounced(function () {
        if (!zoomClient) {
            return false;
        }
        var mediaStream = zoomClient.getMediaStream();
        if (!micOnRef.current) {
            if (initRef.current && joinRef.current && mediaStream.isAudioMuted()) {
                mediaStream.unmuteAudio();
            }
            setMicOn(true);
        }
        else {
            if (initRef.current && joinRef.current && !mediaStream.isAudioMuted()) {
                mediaStream.muteAudio();
            }
            setMicOn(false);
        }
    }, [zoomClient, micOnRef, setMicOn]);
    var toggleCamera = useCallbackDebounced(function () {
        if (!zoomClient) {
            return false;
        }
        var mediaStream = zoomClient.getMediaStream();
        if (!cameraOnRef.current) {
            if (initRef.current &&
                joinRef.current &&
                !mediaStream.isCapturingVideo()) {
                mediaStream.startVideo({
                    mirrored: true
                });
            }
            setCameraOn(true);
        }
        else {
            if (initRef.current &&
                joinRef.current &&
                mediaStream.isCapturingVideo()) {
                mediaStream.stopVideo();
            }
            setCameraOn(false);
        }
    }, [zoomClient, cameraOnRef, setCameraOn]);
    var onUserAdded = useCallback(function (addedUsers) {
        if (!joinRef.current || !currentUserRef.current) {
            return;
        }
        var currentUserId = currentUserRef.current.userId;
        var _a = _.partition(addedUsers, function (participant) { return participant.userId === currentUserId; }), addedCurrentUser = _a[0][0], addedParticipants = _a[1];
        if (addedCurrentUser) {
            var currentUserNext = tslib_1.__assign({}, currentUserRef.current, addedCurrentUser);
            setCurrentUser(currentUserNext);
            if (onUserAddedCallback) {
                onUserAddedCallback(currentUserNext, true);
            }
        }
        if (addedParticipants.length) {
            var addedParticipantMap_1 = addedParticipants.reduce(function (memo, next) {
                memo[next.userId] = next;
                return memo;
            }, {});
            var participantsNext = _.chain(participantsRef.current)
                .filter(function (participant) { return !addedParticipantMap_1[participant.userId]; })
                .unionBy(addedParticipants, 'userId')
                .value();
            setParticipants(participantsNext);
            if (onUserAddedCallback) {
                for (var _i = 0, addedParticipants_1 = addedParticipants; _i < addedParticipants_1.length; _i++) {
                    var participant = addedParticipants_1[_i];
                    onUserAddedCallback(participant, false);
                }
            }
        }
    }, [
        setParticipants,
        setCurrentUser,
        currentUserRef,
        participantsRef,
        joinRef,
        onUserAddedCallback
    ]);
    var onUserUpdated = useCallback(function (updatedUsers) {
        if (!joinRef.current || !currentUserRef.current) {
            return;
        }
        var currentUserId = currentUserRef.current.userId;
        var _a = _.partition(updatedUsers, function (participant) { return participant.userId === currentUserId; }), currentUserUpdate = _a[0][0], participantUpdates = _a[1];
        if (currentUserUpdate) {
            var currentUserNext = tslib_1.__assign({}, currentUserRef.current, currentUserUpdate);
            setCurrentUser(currentUserNext);
            if (onUserUpdatedCallback) {
                onUserUpdatedCallback(currentUserNext, true);
            }
        }
        if (participantUpdates.length) {
            var participantUpdateMap_1 = participantUpdates.reduce(function (memo, next) {
                memo[next.userId] = next;
                return memo;
            }, {});
            var participantsNext = participantsRef.current.map(function (participant) {
                if (participantUpdateMap_1[participant.userId]) {
                    return tslib_1.__assign({}, participant, participantUpdateMap_1[participant.userId]);
                }
                return participant;
            });
            setParticipants(participantsNext);
            var updatedParticipants = participantsNext.filter(function (participant) { return participantUpdateMap_1[participant.userId]; });
            if (onUserUpdatedCallback) {
                for (var _i = 0, updatedParticipants_1 = updatedParticipants; _i < updatedParticipants_1.length; _i++) {
                    var participant = updatedParticipants_1[_i];
                    onUserUpdatedCallback(participant, false);
                }
            }
        }
    }, [
        setParticipants,
        setCurrentUser,
        currentUserRef,
        participantsRef,
        joinRef,
        onUserUpdatedCallback
    ]);
    var onUserRemoved = useCallback(function (removedUsers) {
        if (!joinRef.current || !currentUserRef.current) {
            return;
        }
        var currentUserId = currentUserRef.current.userId;
        var _a = _.partition(removedUsers, function (participant) { return participant.userId === currentUserId; }), currentUserRemoved = _a[0][0], participantsRemoved = _a[1];
        if (currentUserRemoved) {
            setCurrentUser(undefined);
            if (onUserRemovedCallback) {
                onUserRemovedCallback(currentUserRef.current, true);
            }
        }
        if (participantsRemoved.length) {
            var participantsRemovedMap_1 = participantsRemoved.reduce(function (memo, next) {
                memo[next.userId] = next;
                return memo;
            }, {});
            var _b = _.partition(participantsRef.current, function (participant) { return !participantsRemovedMap_1[participant.userId]; }), participantsNext = _b[0], participantsRemovedNext = _b[1];
            setParticipants(participantsNext);
            if (onUserRemovedCallback) {
                for (var _i = 0, participantsRemovedNext_1 = participantsRemovedNext; _i < participantsRemovedNext_1.length; _i++) {
                    var participant = participantsRemovedNext_1[_i];
                    onUserRemovedCallback(participant, false);
                }
            }
        }
    }, [
        setParticipants,
        setCurrentUser,
        currentUserRef,
        participantsRef,
        joinRef,
        onUserRemovedCallback
    ]);
    var onDeviceChange = useCallback(function () {
        if (!zoomClient) {
            return false;
        }
        var mediaStream = zoomClient.getMediaStream();
        if (mediaStream) {
            var activeCamera_1 = mediaStream.getActiveCamera();
            var cameras = mediaStream.getCameraList();
            var mappedCameras = cameras.map(function (camera) { return (tslib_1.__assign({}, camera, { active: camera.deviceId === activeCamera_1 })); });
            var activeMicrophone_1 = mediaStream.getActiveMicrophone();
            var microphones = mediaStream.getMicList();
            var mappedMicrophones = microphones.map(function (microphone) { return (tslib_1.__assign({}, microphone, { active: microphone.deviceId === activeMicrophone_1 })); });
            var activeSpeaker_1 = mediaStream.getActiveSpeaker();
            var speakers = mediaStream.getSpeakerList();
            var mappedSpeakers = speakers.map(function (speaker) { return (tslib_1.__assign({}, speaker, { active: speaker.deviceId === activeSpeaker_1 })); });
            setCameraList(mappedCameras);
            setMicrophoneList(mappedMicrophones);
            setSpeakerList(mappedSpeakers);
        }
    }, [zoomClient]);
    var init = useCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var _a, cameraPermission, microphonePermission;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        navigator.permissions && navigator.permissions.query
                            ? navigator.permissions.query({
                                name: 'camera'
                            })
                            : 'prompt',
                        navigator.permissions && navigator.permissions.query
                            ? navigator.permissions.query({
                                name: 'microphone'
                            })
                            : 'prompt'
                    ])];
                case 1:
                    _a = _b.sent(), cameraPermission = _a[0], microphonePermission = _a[1];
                    if (!(cameraPermission !== 'granted' || microphonePermission !== 'granted')) return [3 /*break*/, 3];
                    return [4 /*yield*/, navigator.mediaDevices
                            .getUserMedia({ audio: true, video: true })
                            .catch(function () {
                            throw new Error('camera-permisisons-error');
                        })];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    if (!zoomClient || initRef.current) {
                        return [2 /*return*/, false];
                    }
                    return [4 /*yield*/, zoomClient.init('en-US', window.location.origin + "/assets/lib", {
                            webEndpoint: 'zoom.us',
                            enforceMultipleVideos: { disableRenderLimits: true },
                            enforceVirtualBackground: true,
                            stayAwake: true,
                            patchJsMedia: true,
                            leaveOnPageUnload: true
                        })];
                case 4:
                    _b.sent();
                    initRef.current = true;
                    return [2 /*return*/, true];
            }
        });
    }); }, [zoomClient, initRef]);
    var join = useCallback(function (_a) {
        var room = _a.room, token = _a.token, password = _a.password, name = _a.name;
        return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var initialised, cleanup, assertIsMounted, initialiseAfterJoining;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (joinRef.current) {
                            return [2 /*return*/, true];
                        }
                        setError(undefined);
                        setJoining(true);
                        return [4 /*yield*/, init().catch(function (initError) {
                                setError(initError.message);
                                setJoining(false);
                                throw initError;
                            })];
                    case 1:
                        initialised = _b.sent();
                        if (!zoomClient || !initialised) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, zoomClient.leave().catch(_.noop)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, zoomClient
                                .join(room, token, name, password)
                                .catch(function (joinError) {
                                setError(joinError.message);
                                setJoining(false);
                                throw joinError;
                            })];
                    case 3:
                        _b.sent();
                        cleanup = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var mediaStream, _a, cameraPermission, microphonePermission;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!zoomClient) return [3 /*break*/, 6];
                                        mediaStream = zoomClient.getMediaStream();
                                        return [4 /*yield*/, mediaStream.stopVideo().catch(_.noop)];
                                    case 1:
                                        _b.sent();
                                        return [4 /*yield*/, mediaStream.stopAudio().catch(_.noop)];
                                    case 2:
                                        _b.sent();
                                        return [4 /*yield*/, zoomClient.leave().catch(_.noop)];
                                    case 3:
                                        _b.sent();
                                        return [4 /*yield*/, Promise.all([
                                                navigator.permissions && navigator.permissions.query
                                                    ? navigator.permissions.query({
                                                        name: 'camera'
                                                    })
                                                    : 'prompt',
                                                navigator.permissions && navigator.permissions.query
                                                    ? navigator.permissions.query({
                                                        name: 'microphone'
                                                    })
                                                    : 'prompt'
                                            ])];
                                    case 4:
                                        _a = _b.sent(), cameraPermission = _a[0], microphonePermission = _a[1];
                                        if (!(cameraPermission === 'granted' &&
                                            microphonePermission === 'granted')) return [3 /*break*/, 6];
                                        return [4 /*yield*/, navigator.mediaDevices
                                                .getUserMedia({ video: true, audio: true })
                                                .then(function (stream) {
                                                stream.getTracks().forEach(function (track) { return track.stop(); });
                                            })
                                                .catch(_.noop)];
                                    case 5:
                                        _b.sent();
                                        _b.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        }); };
                        assertIsMounted = function () {
                            if (!isMountedRef.current) {
                                throw new Error('early-exit');
                            }
                        };
                        initialiseAfterJoining = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var mediaStream, microphoneOn, currentUserInfo, allParticipants, _i, allParticipants_1, participant, activeCamera, cameras, mappedCameras, activeMicrophone, microphones, mappedMicrophones, activeSpeaker, speakers, mappedSpeakers;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        mediaStream = zoomClient.getMediaStream();
                                        if (!(cameraOnRef.current && !mediaStream.isCapturingVideo())) return [3 /*break*/, 2];
                                        return [4 /*yield*/, mediaStream
                                                .startVideo({
                                                mirrored: true
                                            })
                                                .catch(_.noop)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        assertIsMounted();
                                        return [4 /*yield*/, mediaStream.startAudio().catch(_.noop)];
                                    case 3:
                                        _a.sent();
                                        assertIsMounted();
                                        microphoneOn = !mediaStream.isAudioMuted();
                                        if (!(!micOnRef.current && microphoneOn)) return [3 /*break*/, 5];
                                        return [4 /*yield*/, mediaStream.muteAudio().catch(_.noop)];
                                    case 4:
                                        _a.sent();
                                        _a.label = 5;
                                    case 5:
                                        assertIsMounted();
                                        currentUserInfo = zoomClient.getCurrentUserInfo();
                                        setCurrentUser(currentUserInfo);
                                        allParticipants = (zoomClient.getAllUser() || []).filter(function (participant) { return participant.userId !== currentUserInfo.userId; });
                                        setParticipants(allParticipants);
                                        if (onUserAddedCallback) {
                                            onUserAddedCallback(currentUserInfo, true);
                                            for (_i = 0, allParticipants_1 = allParticipants; _i < allParticipants_1.length; _i++) {
                                                participant = allParticipants_1[_i];
                                                onUserAddedCallback(participant, false);
                                            }
                                        }
                                        zoomClient.on('user-added', onUserAdded);
                                        zoomClient.on('user-removed', onUserRemoved);
                                        zoomClient.on('user-updated', onUserUpdated);
                                        zoomClient.on('device-change', onDeviceChange);
                                        activeCamera = mediaStream.getActiveCamera();
                                        cameras = mediaStream.getCameraList();
                                        mappedCameras = cameras
                                            .filter(function (camera) { return camera.deviceId; })
                                            .map(function (camera) { return (tslib_1.__assign({}, camera, { active: camera.deviceId === activeCamera })); });
                                        activeMicrophone = mediaStream.getActiveMicrophone();
                                        microphones = mediaStream.getMicList();
                                        mappedMicrophones = microphones
                                            .filter(function (microphone) { return microphone.deviceId; })
                                            .map(function (microphone) { return (tslib_1.__assign({}, microphone, { active: microphone.deviceId === activeMicrophone })); });
                                        activeSpeaker = mediaStream.getActiveSpeaker();
                                        speakers = mediaStream.getSpeakerList();
                                        mappedSpeakers = speakers
                                            .filter(function (speaker) { return speaker.deviceId; })
                                            .map(function (speaker) { return (tslib_1.__assign({}, speaker, { active: speaker.deviceId === activeSpeaker })); });
                                        setCameraList(mappedCameras);
                                        setMicrophoneList(mappedMicrophones);
                                        setSpeakerList(mappedSpeakers);
                                        joinRef.current = true;
                                        setJoining(false);
                                        return [2 /*return*/, true];
                                }
                            });
                        }); };
                        return [2 /*return*/, initialiseAfterJoining().catch(function (initAfterJoiningError) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, cleanup()];
                                        case 1:
                                            _a.sent();
                                            if (initAfterJoiningError.message === 'early-exit') {
                                                return [2 /*return*/, false];
                                            }
                                            throw initAfterJoiningError;
                                    }
                                });
                            }); })];
                }
            });
        });
    }, [
        cameraOnRef,
        init,
        micOnRef,
        onDeviceChange,
        onUserAdded,
        onUserAddedCallback,
        onUserRemoved,
        onUserUpdated,
        setCurrentUser,
        setParticipants,
        zoomClient,
        isMountedRef
    ]);
    var leave = useCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var _a, cameraPermission, microphonePermission;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!zoomClient || !joinRef.current) {
                        return [2 /*return*/, false];
                    }
                    return [4 /*yield*/, zoomClient.leave()];
                case 1:
                    _b.sent();
                    return [4 /*yield*/, Promise.all([
                            navigator.permissions && navigator.permissions.query
                                ? navigator.permissions.query({
                                    name: 'camera'
                                })
                                : 'prompt',
                            navigator.permissions && navigator.permissions.query
                                ? navigator.permissions.query({
                                    name: 'microphone'
                                })
                                : 'prompt'
                        ])];
                case 2:
                    _a = _b.sent(), cameraPermission = _a[0], microphonePermission = _a[1];
                    if (!(cameraPermission === 'granted' && microphonePermission === 'granted')) return [3 /*break*/, 4];
                    return [4 /*yield*/, navigator.mediaDevices
                            .getUserMedia({ video: true, audio: true })
                            .then(function (stream) {
                            stream.getTracks().forEach(function (track) { return track.stop(); });
                        })
                            .catch(_.noop)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [2 /*return*/, true];
            }
        });
    }); }, [zoomClient]);
    useEffect(function () {
        if (!zoomClient) {
            return;
        }
        return function () {
            if (!zoomClient) {
                return;
            }
            zoomClient.off('user-added', onUserAdded);
            zoomClient.off('user-removed', onUserRemoved);
            zoomClient.off('user-updated', onUserUpdated);
            zoomClient.off('device-change', onDeviceChange);
        };
    }, [zoomClient, onUserAdded, onUserRemoved, onUserUpdated, onDeviceChange]);
    return {
        cameraList: cameraList,
        cameraOn: cameraOn,
        changeCamera: changeCamera,
        changeMic: changeMic,
        changeSpeaker: changeSpeaker,
        currentUser: currentUser,
        join: join,
        joining: joining,
        leave: leave,
        micOn: micOn,
        microphoneList: microphoneList,
        participants: participants,
        speakerList: speakerList,
        toggleCamera: toggleCamera,
        toggleMic: toggleMic,
        error: error
    };
}
