import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, CardContent, Paper, Select, Typography, } from '@material-ui/core';
import { Input } from '@react/components/Input';
export var LoaderWrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n"], ["\n  flex: 1;\n  text-align: center;\n"])));
export var TitleWrapper = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", "px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var RecipeTitle = styled(Typography)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  color: #505050;\n  margin-left: ", "px;\n"], ["\n  color: #505050;\n  margin-left: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledAccordian = styled(Accordion)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledAccordionDetails = styled(AccordionDetails)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var SectionTitle = styled(Typography)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  color: #505050;\n  font-weight: 500;\n"], ["\n  color: #505050;\n  font-weight: 500;\n"])));
export var InfoRow = styled.div(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n"])));
export var ActionRow = styled.div(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n"])));
export var StyledContainer = styled(Paper)(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledContent = styled(CardContent)(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["\n  width: ", "px;\n  font-style: ", ";\n"], ["\n  width: ", "px;\n  font-style: ", ";\n"])), function (props) { return (props.width ? props.width : 150); }, function (props) { return (props.fontStyle ? props.fontStyle : 'normal'); });
export var StyledInput = styled(Input)(templateObject_11 || (templateObject_11 = tslib_1.__makeTemplateObject(["\n  border-radius: 4px;\n  outline: none;\n"], ["\n  border-radius: 4px;\n  outline: none;\n"])));
export var StyledSelect = styled(Select)(templateObject_12 || (templateObject_12 = tslib_1.__makeTemplateObject(["\n  border: 1px solid;\n  border-radius: 4px;\n  font-size: 1rem;\n  outline: none;\n  padding: 0.1rem;\n  padding-left: 10px;\n  width: 100%;\n  &:hover,\n  &:focus {\n    border: ", ";\n  }\n"], ["\n  border: 1px solid;\n  border-radius: 4px;\n  font-size: 1rem;\n  outline: none;\n  padding: 0.1rem;\n  padding-left: 10px;\n  width: 100%;\n  &:hover,\n  &:focus {\n    border: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "1px solid " + theme.colors.primary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
