import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Action } from 'redux';
import { map } from 'rxjs/operators';
import { DisplayToastAction } from '../../core/actions/toast.actions';
import { DisplayToastPayload } from '../../core/models/DisplayToastPayload';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
import {
  LoadActionMedicationsError,
  LoadNoActionMedicationsError,
  LoadPotentialDuplicatesError,
  MedicationErrorAction,
  MedicationsActionTypes
} from '../actions/medications.actions';
import { MedicationsService } from '../services/medications.service';

@Injectable()
export class MedicationEffects {
  private _medicationTableText: any;

  @Effect()
  loadPotentialDuplicatesError$ = this.actions$.pipe(
    ofType<LoadPotentialDuplicatesError>(
      MedicationsActionTypes.LoadPotentialDuplicatesError
    ),
    map((action) => this._handleError(action))
  );

  @Effect()
  loadActionMedicationsError$ = this.actions$.pipe(
    ofType<LoadActionMedicationsError>(
      MedicationsActionTypes.LoadActionMedicationsError
    ),
    map((action) => this._handleError(action))
  );

  @Effect()
  loadNoActionMedicationsError$ = this.actions$.pipe(
    ofType<LoadNoActionMedicationsError>(
      MedicationsActionTypes.LoadNoActionMedicationsError
    ),
    map((action) => this._handleError(action))
  );

  constructor(
    private actions$: Actions,
    private medicationsService: MedicationsService,
    private _error: ErrorHandler,
    private _store: Store<fromRoot.State>
  ) {
    this._store
      .pipe(select(fromSettings.getSectionTranslations('Medication')))
      .subscribe((text) => (this._medicationTableText = text));
  }

  private _handleError(action: MedicationErrorAction): Action {
    const toastOptions: DisplayToastPayload = {
      message: this._medicationTableText.ErrorMessage,
      timeout: 5000,
      title: this._medicationTableText.ErrorTitle,
      type: 'error'
    };
    return new DisplayToastAction(toastOptions);
  }
}
