import { Action } from '@ngrx/store';
import { ApiVideoCall } from 'src/app/video-calls/responses';
import { ClinicPatientResponse } from '../models/responses/clinic-patient.response';

export enum PatientActionTypes {
  // Server Side
  LoadPatientInformation = '[Patient] Load Patient Information',
  LoadPatientInformationSuccess = '[Patient] Load Patient Information Success',
  LoadPatientInformationError = '[Patient] Load Patient Information Error',

  // Client Side
  LoadingPatientInformation = '[Patient] Loading Patient Information',
  LoadedPatientInformation = '[Patient] Loaded Patient Information',

  ClearAllPatientInformation = '[Patient] Clear All Patient Information',

  ActivatePatientListFilter = '[Patient] Activate Patient Filter',
  DeactivatePatientListFilter = '[Patient] Deactivate Patient Filter',
  SetPatientListFilter = '[Patient] Set Patient Filter',

  ChangeActiveSection = '[Patient] Change Active Section',
}

// Action Creators
// ===============================================

// Server Side
export class LoadPatientInformation implements Action {
  readonly type = PatientActionTypes.LoadPatientInformation;

  constructor(public payload: number) {}
}
export class LoadPatientInformationSuccess implements Action {
  readonly type = PatientActionTypes.LoadPatientInformationSuccess;

  constructor(
    public payload: {
      response: ClinicPatientResponse;
      patientId: number;
    },
  ) {}
}
export class LoadPatientInformationError implements Action {
  readonly type = PatientActionTypes.LoadPatientInformationError;

  constructor(public payload: any) {}
}

// Client Side
export class LoadingPatientInformation implements Action {
  readonly type = PatientActionTypes.LoadingPatientInformation;
}
export class LoadedPatientInformation implements Action {
  readonly type = PatientActionTypes.LoadedPatientInformation;
}
export class ClearAllPatientInformation implements Action {
  readonly type = PatientActionTypes.ClearAllPatientInformation;
}

export class ActivatePatientListFilter implements Action {
  readonly type = PatientActionTypes.ActivatePatientListFilter;
}
export class DeactivatePatientListFilter implements Action {
  readonly type = PatientActionTypes.DeactivatePatientListFilter;
}

export class SetPatientListFilter implements Action {
  readonly type = PatientActionTypes.SetPatientListFilter;

  constructor(public payload: string) {}
}

export class ChangeActiveSection implements Action {
  readonly type = PatientActionTypes.ChangeActiveSection;

  constructor(public payload: string) {}
}

// Patient Actions Union
// ===============================================
export type PatientActionsUnion =
  | LoadPatientInformation
  | LoadPatientInformationSuccess
  | LoadPatientInformationError
  | LoadingPatientInformation
  | LoadedPatientInformation
  | ClearAllPatientInformation
  | ActivatePatientListFilter
  | DeactivatePatientListFilter
  | SetPatientListFilter
  | ChangeActiveSection;
