import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
var PacksService = /** @class */ (function () {
    function PacksService(_httpService, _error) {
        this._httpService = _httpService;
        this._error = _error;
    }
    PacksService.prototype.getContentPacks = function () {
        return this._httpService
            .get(EndpointType.Content, "content/pack")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.getContentPackDetail = function (packId) {
        return this._httpService
            .get(EndpointType.Content, "content/pack/" + packId)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.checkContentPackName = function (name) {
        return this._httpService
            .get(EndpointType.Content, "content/pack/name", { name: name })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.createContentPack = function (createPack) {
        return this._httpService
            .post(EndpointType.Content, "content/pack", createPack)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.editContentPack = function (editPack) {
        return this._httpService
            .put(EndpointType.Content, "content/pack/" + editPack.packId, { name: editPack.name })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.deleteContentPack = function (deletePack) {
        return this._httpService
            .delete(EndpointType.Content, "content/pack/" + deletePack.packId)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.addToContentPack = function (_a) {
        var packId = _a.packId, contentEntries = _a.contentEntries;
        return this._httpService
            .post(EndpointType.Content, "content/pack/" + packId + "/add", { contentEntries: contentEntries })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.addToMultipleContentPacks = function (_a) {
        var packIds = _a.packIds, contentEntries = _a.contentEntries;
        return this._httpService
            .post(EndpointType.Content, "content/pack/add", { contentEntries: contentEntries, packIds: packIds })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PacksService.prototype.removeFromContentPack = function (_a) {
        var packId = _a.packId, contentEntries = _a.contentEntries;
        return this._httpService
            .deleteWithBody(EndpointType.Content, "content/pack/" + packId + "/remove", { contentEntries: contentEntries })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    return PacksService;
}());
export { PacksService };
