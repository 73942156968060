import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
export var LoaderWrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n"], ["\n  flex: 1;\n  text-align: center;\n"])));
export var StyledContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding-top: ", "px;\n  display: flex;\n  gap: ", "px;\n  flex-wrap: wrap;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding-top: ", "px;\n  display: flex;\n  gap: ", "px;\n  flex-wrap: wrap;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledList = styled.ul(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  list-style: disc;\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  margin-right: ", "px;\n"], ["\n  list-style: disc;\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  margin-right: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1, templateObject_2, templateObject_3;
