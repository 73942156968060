import * as tslib_1 from "tslib";
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon, MoreVert as MoreVertIcon, } from '@material-ui/icons';
import { Chip, DropdownMenu } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ChipList, Header, ReadIndicator, Star, StyledChip, StyledConversationCard, } from './ConversationCard.styled';
import { FileDescription } from './FileDescription';
import { TypingIndicator } from './ui';
var getClinicUserById = function (id, users) { return users && users.find(function (user) { return user.id === id; }); };
var ɵ0 = getClinicUserById;
var getClinicUserLabel = function (user) {
    return user ? user.firstnameinitial + " " + user.lastname : '';
};
var ɵ1 = getClinicUserLabel;
export var ConversationCard = function (_a) {
    var actions = _a.actions, onClick = _a.onClick, onStarClick = _a.onStarClick, onActionClick = _a.onActionClick, selected = _a.selected, showType = _a.showType, userTypingMessage = _a.userTypingMessage, assignClinicUsers = _a.assignClinicUsers, conversation = tslib_1.__rest(_a, ["actions", "onClick", "onStarClick", "onActionClick", "selected", "showType", "userTypingMessage", "assignClinicUsers"]);
    var t = useTranslations().t;
    var dateTime = useLocaleDateTime();
    var features = useFeatures();
    var featureAssignConversation = checkFeature(features, 'messagingOptions.assignConversation');
    // Create a copy to reflect local changes ASAP, i.e.: toggling `isstarred`
    var _b = useState(conversation), localConversation = _b[0], setLocalConversation = _b[1];
    var formatPatientName = function (_a) {
        var patientfirstname = _a.patientfirstname, patientlastname = _a.patientlastname, patientidentifier = _a.patientidentifier;
        return [
            patientlastname + ', ' + patientfirstname,
            patientidentifier,
        ].join(' - ');
    };
    var formatPatientDoB = function (_a) {
        var patientdateofbirth = _a.patientdateofbirth;
        return dateTime.format(new Date(patientdateofbirth), 'PP', {
            utc: true,
        });
    };
    var formatCreatedAt = function (_a) {
        var sentdate = _a.sentdate;
        return dateTime.format(new Date(sentdate), 'P @ p');
    };
    var handleStarClick = function () {
        var updatedConversation = tslib_1.__assign({}, conversation, { isstarred: !localConversation.isstarred });
        setLocalConversation(updatedConversation);
        onStarClick(updatedConversation);
    };
    var handleActionClick = function (action) {
        onActionClick(conversation, action);
    };
    var isstarred = localConversation.isstarred;
    var content = conversation.content, messagesubject = conversation.messagesubject, messagetype = conversation.messagetype, patientfilename = conversation.patientfilename, patientfilesize = conversation.patientfilesize, assignedclinicuserid = conversation.assignedclinicuserid;
    var hasType = Boolean(showType && messagetype);
    var hasStatusBadge = conversation.patientsent && !conversation.clinicread;
    var hasChips = hasType ||
        (Array.isArray(conversation.staff) && conversation.staff.length > 0);
    var clinicUsers = assignClinicUsers.clinicUsers;
    var isAssigned = Boolean(assignedclinicuserid);
    var assignedClinicUser = assignedclinicuserid
        ? getClinicUserById(assignedclinicuserid, clinicUsers)
        : undefined;
    var assignedClinicUseLabel = getClinicUserLabel(assignedClinicUser);
    return (React.createElement(StyledConversationCard, { "aria-selected": selected, className: clsx('conversation-card', {
            'conversation-card__selected': selected,
            'conversation-card__highlight': hasStatusBadge,
        }), onClick: function () { return onClick(conversation); } },
        React.createElement(Header, null,
            React.createElement(Star, { "aria-hidden": false, role: "checkbox", "aria-checked": isstarred, active: isstarred, onClick: handleStarClick }),
            React.createElement("span", { className: "patient-name" }, formatPatientName(conversation)),
            React.createElement("span", { className: "created-at" }, formatCreatedAt(conversation)),
            React.createElement(DropdownMenu, { "data-testid": "conversation-card--actions", menu: actions, onClick: handleActionClick, assignClinicUsers: assignClinicUsers },
                React.createElement(IconButton, { "aria-controls": "simple-menu", "aria-haspopup": "true", size: "small" },
                    React.createElement(MoreVertIcon, null))),
            React.createElement(ReadIndicator, { role: "status", "aria-label": "read status", read: conversation.read, badge: hasStatusBadge }, !hasStatusBadge && React.createElement(CheckIcon, { "aria-checked": conversation.read }))),
        React.createElement("span", { className: "patient-dob" }, formatPatientDoB(conversation)),
        patientfilename ? (React.createElement(FileDescription, { filename: patientfilename, fileSize: patientfilesize })) : (React.createElement("div", { className: "content" },
            messagesubject && React.createElement("span", null,
                messagesubject,
                ":"),
            " ",
            content)),
        featureAssignConversation && isAssigned && (React.createElement(ChipList, null,
            React.createElement(StyledChip, { key: assignedClinicUseLabel, yellow: true, label: assignedClinicUseLabel, title: assignedClinicUseLabel }))),
        hasChips && (React.createElement(ChipList, null,
            hasType && React.createElement(Chip, { purple: true, label: messagetype }),
            conversation.staff &&
                conversation.staff.map(function (person) { return (React.createElement(StyledChip, { key: person.clinicuserid, green: true, label: person.firstnameinitial + " " + person.lastname, title: t.Messages.PatientsTeamMember })); }))),
        userTypingMessage && (React.createElement(TypingIndicator, null, userTypingMessage))));
};
export { ɵ0, ɵ1 };
