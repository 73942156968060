/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./unauthorised-react-wrapper.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/congito.wrapper.service";
var styles_UnauthorisedReactWrapperComponent = [];
var RenderType_UnauthorisedReactWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnauthorisedReactWrapperComponent, data: {} });
export { RenderType_UnauthorisedReactWrapperComponent as RenderType_UnauthorisedReactWrapperComponent };
export function View_UnauthorisedReactWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["unauthorisedReactComponent", 1]], null, 0, "div", [["style", "height: 100%; width: 100%;"]], null, null, null, null, null))], null, null); }
export function View_UnauthorisedReactWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-unauthorised", [], null, null, null, View_UnauthorisedReactWrapperComponent_0, RenderType_UnauthorisedReactWrapperComponent)), i0.ɵdid(1, 4440064, null, 0, i1.UnauthorisedReactWrapperComponent, [i2.Store, i3.CognitoWrapperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorisedReactWrapperComponentNgFactory = i0.ɵccf("portal-unauthorised", i1.UnauthorisedReactWrapperComponent, View_UnauthorisedReactWrapperComponent_Host_0, {}, {}, []);
export { UnauthorisedReactWrapperComponentNgFactory as UnauthorisedReactWrapperComponentNgFactory };
