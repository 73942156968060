import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
var DataRowWrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n\n  .label-title,\n  .value {\n    color: #505050;\n    font-weight: ", ";\n  }\n\n  .label {\n    width: 450px;\n    padding-right: 30px;\n  }\n\n  .label-caption {\n    display: inline-block;\n    margin-top: ", "px;\n    height: 2rem;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n\n  .label-title,\n  .value {\n    color: #505050;\n    font-weight: ", ";\n  }\n\n  .label {\n    width: 450px;\n    padding-right: 30px;\n  }\n\n  .label-caption {\n    display: inline-block;\n    margin-top: ", "px;\n    height: 2rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightBold;
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var DataRow = function (_a) {
    var children = _a.children, label = _a.label, caption = _a.caption, rest = tslib_1.__rest(_a, ["children", "label", "caption"]);
    return (React.createElement(DataRowWrapper, tslib_1.__assign({}, rest),
        React.createElement("div", { className: "label" },
            React.createElement(Typography, { variant: "body1", className: "label-title" }, label),
            caption && (React.createElement(Typography, { variant: "caption", className: "label-caption" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: caption } })))),
        children));
};
export default DataRow;
var templateObject_1;
