import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  ContentPack,
  ContentPackDetail,
} from 'src/app/content/models/content-packs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { Category } from '../../../content/models/category';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import {
  ChangeContentTab,
  CreatePatientAssignedContent,
  DeletePatientAssignedContent,
  GetAllPatientAssignedContent,
  GetContentEntries,
  GetContentTypes,
  GetPatientAssignedContent,
  GetPatientOnlyAssignments,
  SetActiveContentType,
  SetAssignedContentFilter,
  SetContentSelectFilter,
} from '../../actions/content-assignment.actions';
import { ToggleLockedItem } from '../../components/pack-assignment/pack-assignment.component';
import { ContentEntryHeader } from '../../models/Content';
import { TabOptions } from '../../models/ContentPacks';
import { DetailedPatient } from '../../models/DetailedPatient';
import {
  IndividuallyAssignedPatientContent,
  PatientAssignedCreation,
} from '../../models/UserContent';
import * as fromContentAssignment from '../../reducers';
import { PackAssignmentInterface } from './../../../content/models/content-packs';
import {
  AssignPack,
  GetContentPacks,
  LockPackItem,
  RemoveExclusion,
  SelectActivePackId,
  UnlockPackItem,
} from './../../actions/pack-assignment.actions';
import { ClinicPatientResponse } from './../../models/responses/clinic-patient.response';
import { ContentTabs } from './../../reducers/content-assignment.reducer';

@Component({
  selector: 'portal-content-assignment',
  templateUrl: './content-assignment.component.html',
  styleUrls: ['./content-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentAssignmentComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};

  // Observables
  // ================================================
  // Text
  public contentSwingText$: Observable<any>;
  // active tab
  public contentPacks$: Observable<ContentPack[]>;
  public activeTab$: Observable<ContentTabs>;
  public selectedPackId$: Observable<number>;
  public selectedPack$: Observable<ContentPack>;
  public selectedPackDetail$: Observable<ContentPackDetail[]>;
  public selectedPackFilteredDetail$: Observable<ContentPackDetail[]>;
  public lockedItems$: Observable<number[]>;

  public selectedFolderName$: Observable<string>;

  // content entries
  public activeContentTypeId$: Observable<number>;
  public userAssignedContent$: Observable<IndividuallyAssignedPatientContent[]>;
  public contentTypes$: Observable<Category[]>;
  // content-select
  public csFilterActive$: Observable<boolean>;
  public csFilterString$: Observable<string>;
  public csContent$: Observable<ContentEntryHeader[]>;
  // assigned-content
  public acFilterActive$: Observable<boolean>;
  public acFilterString$: Observable<string>;
  public patientId$: Observable<number>;
  // patient only content
  public isCategoryPatientOnly$: Observable<boolean>;
  // current patient
  public currentPatient$: Observable<DetailedPatient>;
  public patientName$: Observable<string>;

  // Subscriptions container
  private _subs = new Subscription();

  private _patientId: number;
  public tabOptions = TabOptions;

  constructor(
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    private _store: Store<fromRoot.State>,
    public change: ChangeDetectorRef,
  ) {
    // fetch user assigned content and update types and entries
    this._store.dispatch(new GetContentPacks());
    this._store.dispatch(new GetContentEntries());
    this._store.dispatch(new GetContentTypes());

    this.patientId$ = this._route.params.pipe(
      map((params: Params) => +params.patientId),
    );

    // check router for changes to target patient
    this._subs.add(
      this.patientId$.subscribe((id) => {
        this._patientId = id;
        this._store.dispatch(new GetPatientAssignedContent(this._patientId));
        this._store.dispatch(new GetAllPatientAssignedContent(this._patientId));
        this._store.dispatch(
          new GetPatientOnlyAssignments({
            patientId: this._patientId,
          }),
        );
      }),
    );
    // fetch text from store
    this.contentSwingText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentSwing')),
    );

    this.contentPacks$ = this._store.pipe(
      select(fromContentAssignment.getContentPacks),
    );
    this.activeTab$ = this._store.pipe(
      select(fromContentAssignment.getActiveContentTab),
    );
    this.selectedPackId$ = this._store.pipe(
      select(fromContentAssignment.getActivePackId),
    );

    this.selectedPack$ = this._store.pipe(
      select(fromContentAssignment.selectedPackWithAssignment),
    );
    this.selectedPackDetail$ = this._store.pipe(
      select(fromContentAssignment.getSelectedPackDetail),
    );
    this.selectedPackFilteredDetail$ = this._store.pipe(
      select(fromContentAssignment.getSelectedPackFilteredDetail),
    );

    this.selectedFolderName$ = this._store.pipe(
      select(fromContentAssignment.getSelectdFolderName),
    );

    this.lockedItems$ = this._store.pipe(
      select(fromContentAssignment.getLockedItems),
    );

    // define observables to fetch data from store
    this.activeContentTypeId$ = this._store.pipe(
      select(fromContentAssignment.getActiveContentType),
    );
    this.contentTypes$ = this._store.pipe(
      select(fromContentAssignment.getContentTypes),
    );
    this.csFilterActive$ = this._store.pipe(
      select(fromContentAssignment.isContentSelectFilterActive),
    );
    this.csFilterString$ = this._store.pipe(
      select(fromContentAssignment.getContentSelectFilterString),
    );
    this.acFilterActive$ = this._store.pipe(
      select(fromContentAssignment.isAssignedContentFilterActive),
    );
    this.acFilterString$ = this._store.pipe(
      select(fromContentAssignment.getAssignedContentFilterString),
    );
    // Patient specific files
    this.isCategoryPatientOnly$ = this._store.pipe(
      select(fromContentAssignment.isCategoryPatientOnly),
    );

    // define observables to pass filtered data to components
    this.csContent$ = this._store.pipe(
      select(fromContentAssignment.applySearchToFilteredCsContent),
    );

    // fetch current patient
    this.currentPatient$ = this.patientId$.pipe(
      switchMap((id) =>
        this._store.pipe(select(fromContentAssignment.getPatientById(id))),
      ),
      filter(
        (clinicPatientResponse: ClinicPatientResponse) =>
          !!clinicPatientResponse && !!clinicPatientResponse.Patient,
      ),
      map(
        (clinicPatientResponse: ClinicPatientResponse) =>
          clinicPatientResponse.Patient,
      ),
    );
    this.patientName$ = this.currentPatient$.pipe(
      map(
        (patient: DetailedPatient) =>
          `${patient.FirstName} ${patient.LastName}`,
      ),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public createContent(): void {
    this._navigationService.navigate(['content', 'list', 0]);
  }

  public setActiveContentTypeId(contentTypeId: number): void {
    this._store.dispatch(new SetActiveContentType(contentTypeId));
  }

  // content-select functions
  // ========================================
  public addToPatientApp(item: IndividuallyAssignedPatientContent): void {
    item.isTransferring = true;
    const userContent: PatientAssignedCreation = {
      contententryheaderid: item.contentEntryHeaderId,
    };
    this._store.dispatch(
      new CreatePatientAssignedContent({
        assignment: userContent,
        patientId: this._patientId,
        patientContent: item,
      }),
    );
  }

  public setContentSelectFilter(str: string): void {
    this._store.dispatch(new SetContentSelectFilter(str.toLowerCase()));
  }

  // assigned-content content-select functions
  // ========================================
  public removeFromApp({ assignmentid, contententryheaderid }): void {
    this._store.dispatch(
      new DeletePatientAssignedContent({
        assignmentid,
        contententryheaderid,
      }),
    );
  }
  public setAssignedContentFilter(str: string): void {
    this._store.dispatch(new SetAssignedContentFilter(str.toLowerCase()));
  }

  public selectTab(tab: ContentTabs): void {
    this._store.dispatch(new ChangeContentTab(tab));
  }

  public selectActivePackId(id: number): void {
    this._store.dispatch(new SelectActivePackId(id));
  }

  public assignPack(packAssign: PackAssignmentInterface) {
    // If the full pack was selected
    if (packAssign.pack) {
      packAssign.pack.isTransferring = true;
    }
    // If an item in the pack was selected
    if (packAssign.packItem) {
      packAssign.packItem.isTransferring = true;
    }
    this._store.dispatch(new AssignPack(packAssign));
  }

  public toggleLockedItem(toggleLocked: ToggleLockedItem) {
    if (toggleLocked.isLocked) {
      this._store.dispatch(new UnlockPackItem(toggleLocked.id));
    } else {
      this._store.dispatch(new LockPackItem(toggleLocked.id));
    }
  }

  public addPackItemToPatient(args: PackAssignmentInterface) {
    this._store.dispatch(new RemoveExclusion(args));
  }

  public removeExclusions(args: PackAssignmentInterface) {
    this._store.dispatch(new RemoveExclusion(args));
  }
}
