import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
var PackAssignmentService = /** @class */ (function () {
    function PackAssignmentService(_httpService, _error) {
        this._httpService = _httpService;
        this._error = _error;
    }
    PackAssignmentService.prototype.getContentPacks = function () {
        return this._httpService
            .get(EndpointType.Content, "content/pack")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PackAssignmentService.prototype.getContentPackDetail = function (packId) {
        return this._httpService
            .get(EndpointType.Content, "content/pack/" + packId)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PackAssignmentService.prototype.assignPack = function (assignment) {
        return this._httpService
            .post(EndpointType.Content, "content/pack/" + assignment.packId + "/assign", {
            patientId: assignment.patientId,
            exclusions: assignment.exclusions
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PackAssignmentService.prototype.addExclusion = function (assignment) {
        return this._httpService
            .post(EndpointType.Content, "content/pack/" + assignment.packId + "/exclusion", {
            patientId: assignment.patientId,
            exclusions: assignment.exclusions
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PackAssignmentService.prototype.removeExclusion = function (assignment) {
        return this._httpService
            .deleteWithBody(EndpointType.Content, "content/pack/" + assignment.packId + "/exclusion", {
            patientId: assignment.patientId,
            exclusions: assignment.exclusions
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    PackAssignmentService.prototype.unassignPack = function (assignment) {
        var patientId = assignment.patientId;
        return this._httpService
            .deleteWithBody(EndpointType.Content, "content/pack/" + assignment.packId + "/unassign", {
            patientId: patientId
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    return PackAssignmentService;
}());
export { PackAssignmentService };
