import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { ContentActionTypes, CreateTemplate, OpenDirectorySection, } from '../../actions/content.actions';
import { CreateCategoryComponent } from '../../components/create-category/create-category.component';
import { CreatePackComponent } from '../../components/create-pack/create-pack.component';
import { EditFolderNameComponent } from '../../components/edit-folder-name/edit-folder-name.component';
import { NewContentTemplate, } from '../../models/content-template';
import * as fromContent from '../../reducers';
import { EditPackNameComponent } from './../../components/edit-pack-name/edit-pack-name.component';
var TemplateNewComponent = /** @class */ (function () {
    function TemplateNewComponent(_navigationService, _route, _updates$, _store, dialog) {
        this._navigationService = _navigationService;
        this._route = _route;
        this._updates$ = _updates$;
        this._store = _store;
        this.dialog = dialog;
        this.creatingTemplate$ = new BehaviorSubject(false);
        this.creatingTemplateError$ = new BehaviorSubject(false);
        this.packs$ = of([]);
        this.categoryId$ = of(0);
        this.routeType$ = of('template');
        this._subs = new Subscription();
    }
    TemplateNewComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Global Portal Observable
        this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        // Content editor group Observables
        this.contentEditorEnabled$ = this._store.pipe(select(fromContent.getContentEditorEnabled));
        // set up text observables
        this.directoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentDirectory')));
        this.contentText$ = this._store.pipe(select(fromSettings.getSectionTranslations('NewContentList')));
        this.templateEditText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateTemplate')));
        this.categoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('CreateCategory')));
        this._subs.add(this.isGlobalPortal$.subscribe(function (value) {
            _this.isGlobalPortal = value;
        }));
        // Content editor group, wrapped in a feature flag
        this._subs.add(this.contentEditorEnabled$.subscribe(function (t) { return (_this.contentEditorEnabled = t); }));
        // capture text for use in dialogs
        this._subs.add(this.contentText$.subscribe(function (t) { return (_this.contentText = t); }));
        this._subs.add(this.categoryText$.subscribe(function (t) { return (_this.categoryText = t); }));
        // content-directory observables from Store and active route
        this.categories$ = this._store.pipe(select(fromContent.getGeneralCategories));
        this.templates$ = this._store.pipe(select(fromContent.getTemplates));
        this.packs$ = this._store.pipe(select(fromContent.getPacks));
        this.categoryId$ = this._route.params.pipe(map(function (params) {
            if (params.id) {
                return +params.id;
            }
            else {
                return 0;
            }
        }));
        // template to hand to template form
        this.currentUser$ = this._store.pipe(select(fromAuth.getUser));
        this.selectedTemplate$ = this.currentUser$.pipe(map(function () {
            return new NewContentTemplate('', true);
        }));
        // watch for successful creation of template to reroute to edit screen
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.CreateTemplateSuccess), take(1), map(function (action) {
            _this.creatingTemplate$.next(false);
            var id = action.payload.id;
            _this.navigateTo("content/template/edit/" + id);
        }))
            .subscribe());
        // watch for error creating template
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.CreateTemplateError), take(1), map(function () {
            _this.creatingTemplate$.next(false);
            _this.creatingTemplateError$.next(true);
        }))
            .subscribe());
        // Which section of directory is open?
        this.openDirectory$ = this._store.pipe(select(fromContent.activeDirectorySection));
    };
    TemplateNewComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    TemplateNewComponent.prototype.createTemplate = function (template) {
        this.creatingTemplate$.next(true);
        this._store.dispatch(new CreateTemplate(template));
    };
    // Functions to manage content directory
    TemplateNewComponent.prototype.navigateTo = function (url) {
        this._navigationService.navigateByUrl(url);
    };
    TemplateNewComponent.prototype.openDirectorySection = function (section) {
        this._store.dispatch(new OpenDirectorySection(section));
    };
    TemplateNewComponent.prototype.createNewFolder = function () {
        this.dialog.open(CreateCategoryComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    TemplateNewComponent.prototype.changeFolderName = function (category) {
        this.dialog.open(EditFolderNameComponent, {
            data: {
                category: category,
                text: this.contentText,
            },
            width: '550px',
        });
    };
    TemplateNewComponent.prototype.createNewPack = function () {
        this.dialog.open(CreatePackComponent, {
            data: {
                text: this.categoryText,
            },
            width: '550px',
        });
    };
    TemplateNewComponent.prototype.editPackName = function (pack) {
        this.dialog.open(EditPackNameComponent, {
            data: {
                pack: pack,
                text: this.contentText,
            },
            width: '550px',
        });
    };
    return TemplateNewComponent;
}());
export { TemplateNewComponent };
