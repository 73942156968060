import { Action } from '@ngrx/store';

// Constants
export enum NewCategoryActionTypes {
  FilterContent = '[Content][NewCategory] Filter Categories',
  ClearCategoriesFilter = '[Content][NewCategory] Filter Categories',
}
export class FilterContent implements Action {
  readonly type = NewCategoryActionTypes.FilterContent;

  constructor(public payload: string) {}
}
export class ClearCategoriesFilter implements Action {
  readonly type = NewCategoryActionTypes.ClearCategoriesFilter;

  constructor(public payload?: null) {}
}

export type NewCategoryActionsUnion = FilterContent | ClearCategoriesFilter;
