<div
  class="new-video-call-modal"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <!-- Close button -->
  <!-- <div fxLayout="row" fxLayoutAlign="end start"> -->
  <div class="header">
    <h2 class="heading">
      {{ isEdit ? newVideoCallText.TitleEdit : newVideoCallText.TitleNew }}
    </h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="closeDialog()"
      color="primary"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- form -->
  <form
    fxFlex="grow"
    class="message-form"
    novalidate
    [formGroup]="newVideoCallForm"
  >
    <div fxLayout="column" fxLayoutAlign="start stretch" class="form-layout">
      <!-- To -->
      <div
        class="nvc-input form-group patient"
        [class.has-error]="
          (newVideoCallForm.get('patientName').touched ||
            newVideoCallForm.get('patientName').dirty) &&
          !newVideoCallForm.get('patientName').valid
        "
      >
        <div class="nvc-patient form-group" fxLayout="row">
          <label for="patientName" class="field-label">{{
            newVideoCallText.Patient
          }}</label>
          <input
            class="form-control nvc-entry"
            #patientName
            id="patientName"
            formControlName="patientName"
            type="text"
            placeholder="{{ newVideoCallText.PleaseSelect }}"
            fxFlex="grow"
            [matAutocomplete]="patientAuto"
            cdkFocusInitial
          />
          <mat-autocomplete
            #patientAuto="matAutocomplete"
            [displayWith]="transformPatientToString"
          >
            <mat-option
              *ngFor="let patient of patients$ | async"
              [value]="patient"
            >
              {{ transformPatientToString(patient) }}
            </mat-option>
            <div
              *ngIf="isLoading$ | async"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
              class="spinner-container"
            >
              <mat-spinner diameter="40"></mat-spinner>
            </div>
            <div
              style="height: 1px; margin: 0; padding: 0"
              intersection-observer
              [observeFirstChildElement]="false"
              observeMargin="200px"
              (observed)="triggerNextPage()"
            ></div>
          </mat-autocomplete>
        </div>
        <div
          class="error"
          *ngIf="
            (newVideoCallForm.get('patientName').touched ||
              newVideoCallForm.get('patientName').dirty) &&
            !newVideoCallForm.get('patientName').valid
          "
        >
          <mat-error
            *ngIf="newVideoCallForm.get('patientName').hasError('notPatient')"
          >
            {{ newVideoCallText.PatientRequired }}
          </mat-error>
          <mat-error
            *ngIf="newVideoCallForm.get('patientName').hasError('required')"
          >
            {{ newVideoCallText.PatientRequired }}
          </mat-error>
          <mat-error
            *ngIf="newVideoCallForm.get('patientName').hasError('serverError')"
          >
            {{ newVideoCallText.ServerError }}
          </mat-error>
        </div>
      </div>
      <div
        class="nvc-input host"
        [class.has-error]="
          newVideoCallForm.get('host').touched &&
          !newVideoCallForm.get('host').valid
        "
      >
        <div class="form-group" fxLayout="row">
          <label for="host" class="field-label">{{
            newVideoCallText.Host
          }}</label>
          <input
            class="form-control nvc-entry"
            #host
            id="host"
            formControlName="host"
            type="text"
            placeholder="{{ newVideoCallText.AddName }}"
            fxFlex="grow"
            [inputMaxLength]="50"
          />
        </div>
        <div class="input-info">
          <div
            class="error"
            *ngIf="
              newVideoCallForm.get('host').touched &&
              !newVideoCallForm.get('host').valid
            "
          >
            <mat-error
              *ngIf="newVideoCallForm.get('host').hasError('required')"
            >
              {{ newVideoCallText.HostRequired }}
            </mat-error>
            <mat-error
              *ngIf="newVideoCallForm.get('host').hasError('minlength')"
            >
              {{ newVideoCallText.HostLength }}
            </mat-error>
          </div>
          <mat-hint class="hint"> {{ host.value.length }}/{{ 50 }} </mat-hint>
        </div>
      </div>
      <div
        class="nvc-input description"
        [class.has-error]="
          newVideoCallForm.get('description').touched &&
          !newVideoCallForm.get('description').valid
        "
      >
        <div class="form-group" fxLayout="row">
          <label for="description" class="field-label">{{
            newVideoCallText.Description
          }}</label>
          <input
            class="form-control nvc-entry"
            #description
            id="description"
            formControlName="description"
            type="text"
            placeholder="{{ newVideoCallText.AddDescription }}"
            fxFlex="grow"
            [inputMaxLength]="100"
          />
        </div>

        <div class="input-info">
          <div
            class="error"
            *ngIf="
              newVideoCallForm.get('description').touched &&
              !newVideoCallForm.get('description').valid
            "
          >
            <mat-error
              *ngIf="newVideoCallForm.get('description').hasError('required')"
            >
              {{ newVideoCallText.DescriptionRequired }}
            </mat-error>
            <mat-error
              *ngIf="newVideoCallForm.get('description').hasError('minlength')"
            >
              {{ newVideoCallText.DescriptionLength }}
            </mat-error>
          </div>
          <mat-hint class="hint">
            {{ description.value.length }}/{{ 100 }}
          </mat-hint>
        </div>
      </div>

      <div
        class="nvc-input date"
        [class.has-error]="
          newVideoCallForm.get('date').touched &&
          !newVideoCallForm.get('date').valid
        "
      >
        <div
          class="form-group"
          fxLayout="row"
          fxLayoutAlign="flex-start center"
        >
          <label for="date" fxFlex="0 1 100px" class="field-label">{{
            newVideoCallText.Date
          }}</label>
          <mat-form-field class="datePicker">
            <input
              matInput
              [matDatepickerFilter]="isFutureDate"
              [matDatepicker]="picker"
              formControlName="date"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          class="error"
          *ngIf="
            newVideoCallForm.get('date').touched &&
            !newVideoCallForm.get('date').valid
          "
        >
          <mat-error *ngIf="newVideoCallForm.get('date').hasError('required')">
            {{ newVideoCallText.DateRequired }}
          </mat-error>
          <mat-error
            *ngIf="newVideoCallForm.get('date').hasError('matDatepickerFilter')"
          >
            {{ newVideoCallText.DateRequired }}
          </mat-error>
          <mat-error *ngIf="newVideoCallForm.get('date').hasError('inPast')">
            {{ newVideoCallText.PastDate }}
          </mat-error>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <div
          class="nvc-input clear-h-margin start-time-input"
          fxFlex="0 1 50%"
          [class.has-error]="
            newVideoCallForm.get('startTime').touched &&
            !newVideoCallForm.get('startTime').valid
          "
        >
          <div class="form-group" fxLayout="row" fxLayoutAlign="stretch center">
            <label class="field-label" fxFlex="0 1 100px">{{
              newVideoCallText.StartTime
            }}</label>
            <portal-time-picker
              class="time-picker"
              formControlName="startTime"
            ></portal-time-picker>
          </div>
          <div>
            <mat-hint class="hint mat-hint">
              {{ newVideoCallText.LocalTime }}
            </mat-hint>
          </div>
          <div
            class="error"
            *ngIf="
              newVideoCallForm.get('startTime').touched &&
              !newVideoCallForm.get('startTime').valid
            "
          >
            <mat-error
              *ngIf="newVideoCallForm.get('startTime').hasError('required')"
            >
              {{ newVideoCallText.StartTimeRequired }}
            </mat-error>
            <mat-error
              *ngIf="newVideoCallForm.get('startTime').hasError('inPast')"
            >
              {{ newVideoCallText.PastTime }}
            </mat-error>
          </div>
        </div>
        <div
          class="nvc-input clear-h-margin duration-input"
          fxFlex="0 1 50%"
          [class.has-error]="
            newVideoCallForm.get('duration').touched &&
            !newVideoCallForm.get('duration').valid
          "
        >
          <div class="form-group" fxLayoutAlign="stretch center">
            <label class="field-label" fxFlex="0 1 100px">{{
              newVideoCallText.Duration
            }}</label>
            <mat-form-field class="duration-field">
              <mat-select formControlName="duration">
                <mat-option
                  *ngFor="let duration of durations"
                  [value]="duration"
                  >{{ duration }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="error"
            *ngIf="
              newVideoCallForm.get('duration').touched &&
              !newVideoCallForm.get('duration').valid
            "
          >
            <mat-error
              *ngIf="newVideoCallForm.get('duration').hasError('required')"
            >
              {{ newVideoCallText.DurationRequired }}
            </mat-error>
          </div>
        </div>
      </div>
      <div class="controls" *ngIf="!isEdit">
        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            mat-raised-button
            type="submit"
            class="portal-btn btn-bright"
            [disabled]="newVideoCallForm.status === 'INVALID'"
            (click)="saveVideoCall()"
          >
            {{ newVideoCallText.Create }}
          </button>
        </div>
      </div>

      <div class="controls" *ngIf="isEdit">
        <div
          mat-dialog-actions
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <button
            mat-raised-button
            type="button"
            class="portal-btn btn-warn"
            (click)="confirmDeleteDialog()"
          >
            {{ newVideoCallText.DeleteCall }}
          </button>
          <button
            mat-raised-button
            type="button"
            class="portal-btn btn-bright"
            [disabled]="newVideoCallForm.status === 'INVALID'"
            (click)="saveVideoCall()"
          >
            {{ newVideoCallText.Save }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
