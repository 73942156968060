export enum MedicationType {
  ActionRequired,
  NoActionRequired,
  Duplicate,
}

export enum MedicationStatus {
  Taken = 50,
  DueToday = 1,
  DueAt = 2,
  WindowClosing = 103,
  Overdue = 104,
  PotentialDuplicate = 75,
}

export enum DataState {
  Results,
  NoResults,
  NoSearchResults,
}
