import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'portal-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  @Input() text: any;
  @Input() maxLength: number;
  public commentForm: FormGroup;

  get commentLength(): number {
    return this.commentForm.get('comments').value.length;
  }

  constructor(private _controlContainer: ControlContainer) {}

  ngOnInit() {
    this.commentForm = this._controlContainer.control as FormGroup;
  }
}
