import { ElementRef, ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { format, parseISO } from 'date-fns';
import { BehaviorSubject, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, mergeMap, scan, shareReplay, tap } from 'rxjs/operators';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { InvitePartnerComponent } from 'src/app/core/components/invite-partner/invite-partner.component';
import { NewVideoCallComponent } from 'src/app/core/containers/new-video-call/new-video-call.component';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import * as fromAuth from '../../../auth/reducers';
import { VideoCallModalReactWrapperComponent } from '../../../core/containers/video-call-modal/video-call-modal-react-wrapper.component';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as VideoCallActions from '../../../video-calls/actions/video-call.actions';
import { InviteInCallSuccessAction, JoinVideoCallAction, ResetEffectsStateAction, SetCurrentTabAction, UnInviteInCallSuccessAction, UpdateListAction } from '../../actions/video-call.actions';
import { TabsState } from '../../models/TabsState';
import * as fromVideoCalls from '../../reducers';
import { VideoCallEffectsResposeType } from '../../responses';
import { VideoCallsService } from '../../services/video-calls.service';
var VideoCallsPageComponent = /** @class */ (function () {
    function VideoCallsPageComponent(_store, _fb, _error, _elementRef, _videoCallsService, _localisationService, dialog) {
        this._store = _store;
        this._fb = _fb;
        this._error = _error;
        this._elementRef = _elementRef;
        this._videoCallsService = _videoCallsService;
        this._localisationService = _localisationService;
        this.dialog = dialog;
        this.loading$ = new Subject();
        this.listIsFiltered$ = new BehaviorSubject(false);
        this._subs = new Subscription();
        this._hasMoreItems = true;
        this._pageNumber = 0;
        this._searchTrigger$ = new Subject();
        this._effectsCompleted$ = this._store.pipe(select(fromVideoCalls.getEffectsCompletedResponse));
        this._toastText$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallsToast')));
        this._toastRef$ = this._store.pipe(select(fromRoot.getToastRef));
        this.partnerInviteEnabled$ = this._store.pipe(select(fromAuth.getVideoCallsPartnerInvite));
    }
    VideoCallsPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.videoCallsListText$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallsList')));
        this.videoCallsInvitePartner$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallsInvitePartner')));
        this.videoCallsList$ = this._searchTrigger$.pipe(mergeMap(function (searchTrigger) {
            _this.loading$.next(true);
            return _this._getVideoCalls$(searchTrigger.pageNumber, searchTrigger.q, searchTrigger.resources);
        }), tap(function (next) {
            _this.loading$.next(false);
        }), scan(function (accumulator, value) {
            _this._hasMoreItems = value.length > 0;
            return _this._pageNumber > 1 ? accumulator.concat(value) : value;
        }), catchError(function (err) {
            _this._store.dispatch(new DisplayToastAction({
                toastRef: _this._toastRef,
                type: 'error',
                message: _this._toastText.ErrorEncountered,
                title: _this._toastText.Error,
                timeout: 0
            }));
            return throwError(err);
        }), shareReplay(1));
        this.videoCallResources$ = this._videoCallsService.getVideoCallResources();
        this.selectedTab$ = this._store.pipe(select(fromVideoCalls.getCurrentTabSelector));
        this._subs.add(this.videoCallsListText$.subscribe(function (t) {
            _this.videoCallsListText = t;
        }));
        this._subs.add(this.videoCallsInvitePartner$.subscribe(function (t) {
            _this.videoCallsInvitePartner = t;
        }));
        this._subs.add(this._toastText$.subscribe(function (val) {
            _this._toastText = val;
        }));
        this._subs.add(this._effectsCompleted$.subscribe(function (p) {
            _this.onEffectsCompleted(p);
        }));
        this._subs.add(this._toastRef$.subscribe(function (tr) {
            _this._toastRef = tr;
        }));
        this._subs.add(this.selectedTab$.subscribe(function (val) {
            _this._past = val === TabsState.Previous;
            _this.currentStateIsPast = val === TabsState.Previous;
            _this.noCallsText =
                val === TabsState.Previous
                    ? _this.videoCallsListText.NoPreviousCalls
                    : _this.videoCallsListText.NoUpcomingCalls;
        }));
        this._subs.add(this.partnerInviteEnabled$.subscribe(function (pi) {
            _this.partnerInviteEnabled = pi;
        }));
        this.filterForm = this._fb.group({
            filter: [{ value: '', disabled: false }]
        });
        this.filterForm
            .get('filter')
            .valueChanges.pipe(debounceTime(500))
            .subscribe(function () {
            _this._resetPage();
            _this.triggerNextPage();
        });
    };
    VideoCallsPageComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    VideoCallsPageComponent.prototype.filtersApplied = function (filters) {
        this._resources = filters.map(function (item) { return item.id.toString(); }).join(',');
        this._resetPage();
        this.triggerNextPage();
    };
    VideoCallsPageComponent.prototype.openVideoCall = function (videoCall) {
        var data = {};
        if (videoCall) {
            // TODO: The SuperHack!!
            var nameSplit = videoCall.patientname.split(' ');
            var lastName = '';
            var firstName = videoCall.patientname;
            if (nameSplit.length > 1) {
                lastName = nameSplit.pop();
                firstName = nameSplit.join(' ');
            }
            // ^^ Might want to fix this
            var patient = {
                FirstName: firstName,
                LastName: lastName,
                PatientIdentifier: videoCall.patientidentifier,
                Id: videoCall.patientid,
                DateOfBirth: new Date(videoCall.patientdateofbirth)
            };
            data.id = videoCall.id;
            data.host = videoCall.host;
            data.description = videoCall.description;
            data.duration = videoCall.duration;
            data.date = this._localisationService.toZonedDate(parseISO(videoCall.scheduleddate));
            data.startTime = format(this._localisationService.toZonedDate(parseISO(videoCall.scheduleddate)), 'HH:mm');
            data.patient = patient;
        }
        var dialogSize = this._getDialogSize();
        var dialogRef = this.dialog.open(NewVideoCallComponent, {
            panelClass: 'reduced-padding-dialog',
            width: dialogSize.width + "vw",
            height: '500px',
            autoFocus: false,
            closeOnNavigation: true,
            data: data,
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(function (val) { });
    };
    VideoCallsPageComponent.prototype.triggerNextPage = function () {
        this._pageNumber = this._pageNumber + 1;
        var formValue = this.filterForm.value;
        this._searchTrigger$.next({
            pageNumber: this._pageNumber,
            q: formValue.filter,
            resources: this._resources
        });
        this.listIsFiltered$.next(formValue.filter ? true : false);
    };
    VideoCallsPageComponent.prototype.joinVideoCall = function (videoCall) {
        this._store.dispatch(new DisplayToastAction({
            type: 'info',
            message: this._toastText.PleaseWaitAndAllowAccess,
            title: this._toastText.JoiningVideoCall,
            timeout: 5000
        }));
        this._store.dispatch(new JoinVideoCallAction({
            videoCall: videoCall,
            partnerInviteEnabled: this.partnerInviteEnabled,
            videoCallsInvitePartner: this.videoCallsInvitePartner
        }));
    };
    VideoCallsPageComponent.prototype.connectVideoCall = function (call) {
        var _this = this;
        this._videoCallDialogRef = this.dialog.open(VideoCallModalReactWrapperComponent, {
            panelClass: 'full-screen-dialog',
            maxWidth: '100vw',
            width: "100vw",
            height: '100vh',
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                close: function () {
                    _this._videoCallDialogRef.close();
                },
                joinResponse: call,
                partnerInviteEnabled: this.partnerInviteEnabled,
                invitePartnerInCall: this.invitePartnerInCall.bind(this, call)
            },
            disableClose: true
        });
    };
    VideoCallsPageComponent.prototype.editVideoCall = function (videoCall) {
        this.openVideoCall(videoCall);
    };
    VideoCallsPageComponent.prototype.getUpdatableVideoCallWithInvite = function (videoCall, invited) {
        if (invited === void 0) { invited = true; }
        var patient = {
            FirstName: videoCall.firstname,
            LastName: videoCall.lastname,
            PatientIdentifier: videoCall.patientidentifier,
            Id: videoCall.patientid
        };
        var invitedVideoCall = {
            id: videoCall.id,
            host: videoCall.host,
            description: videoCall.description,
            duration: videoCall.duration,
            patient: patient,
            scheduleddate: this._localisationService
                .toUTCDate(new Date(videoCall.scheduleddate))
                .toISOString(),
            ispartnerinvited: invited
        };
        return invitedVideoCall;
    };
    VideoCallsPageComponent.prototype.invitePartner = function (videoCall) {
        var _this = this;
        var dialogRef = this.dialog.open(InvitePartnerComponent, {
            maxWidth: '500px',
            data: {
                title: this.videoCallsInvitePartner.InvitePartner,
                message1: this.videoCallsInvitePartner.InviteText1,
                message2: this.videoCallsInvitePartner.InviteText2,
                text: {
                    Cancel: this.videoCallsInvitePartner.Cancel,
                    Confirm: this.videoCallsInvitePartner.Invite
                }
            }
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                var invitedVideoCall = _this.getUpdatableVideoCallWithInvite(videoCall);
                _this._store.dispatch(new VideoCallActions.InvitePartnerToVideoCallAction(invitedVideoCall));
            }
        });
    };
    VideoCallsPageComponent.prototype.invitePartnerInCall = function (call) {
        var _this = this;
        var dialogRef = this.dialog.open(InvitePartnerComponent, {
            maxWidth: '500px',
            data: {
                title: this.videoCallsInvitePartner.InvitePartner,
                message1: this.videoCallsInvitePartner.InvitePartnerInCall,
                text: {
                    Cancel: this.videoCallsInvitePartner.Cancel,
                    Confirm: this.videoCallsInvitePartner.Invite
                }
            }
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                var invitedVideoCall = _this.getUpdatableVideoCallWithInvite(call.videoCall);
                _this._store.dispatch(new VideoCallActions.InvitePartnerInCallToVideoCallAction(invitedVideoCall));
            }
        });
    };
    VideoCallsPageComponent.prototype.unInvitePartner = function (videoCall) {
        var _this = this;
        var dialogRef = this.dialog.open(InvitePartnerComponent, {
            maxWidth: '500px',
            data: {
                title: this.videoCallsInvitePartner.UnInvitePartner,
                message1: this.videoCallsInvitePartner.UnInviteText1,
                message2: this.videoCallsInvitePartner.UnInviteText2,
                text: {
                    Cancel: this.videoCallsInvitePartner.Cancel,
                    Confirm: this.videoCallsInvitePartner.UnInvite
                }
            }
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                var unInvitedVideoCall = _this.getUpdatableVideoCallWithInvite(videoCall, false);
                _this._store.dispatch(new VideoCallActions.UnInvitePartnerToVideoCallAction(unInvitedVideoCall));
            }
        });
    };
    VideoCallsPageComponent.prototype.unInvitePartnerInCall = function (call) {
        var _this = this;
        var dialogRef = this.dialog.open(InvitePartnerComponent, {
            maxWidth: '500px',
            data: {
                title: this.videoCallsInvitePartner.UnInvitePartner,
                message1: this.videoCallsInvitePartner.UnInvitePartnerInCall,
                text: {
                    Cancel: this.videoCallsInvitePartner.Cancel,
                    Confirm: this.videoCallsInvitePartner.UnInvite
                }
            }
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                var unInvitedVideoCall = _this.getUpdatableVideoCallWithInvite(call.videoCall, false);
                _this._store.dispatch(new VideoCallActions.UnInvitePartnerInCallToVideoCallAction(unInvitedVideoCall));
            }
        });
    };
    VideoCallsPageComponent.prototype.clearFilter = function () {
        this.filterForm.patchValue({ filter: '' });
    };
    VideoCallsPageComponent.prototype.tabChanged = function (index) {
        this._store.dispatch(new SetCurrentTabAction(index === 0 ? TabsState.Upcoming : TabsState.Previous));
        this._past = index === 1;
        this._resetPage();
        this.triggerNextPage();
    };
    VideoCallsPageComponent.prototype.reload = function () {
        this._resetPage();
        this.triggerNextPage();
    };
    VideoCallsPageComponent.prototype.onEffectsCompleted = function (payload) {
        if (!payload) {
            return;
        }
        switch (payload.type) {
            case VideoCallEffectsResposeType.UpdateSuccess:
            case VideoCallEffectsResposeType.DeleteSuccess:
                this._resetPage();
                this.triggerNextPage();
                break;
            case VideoCallEffectsResposeType.JoinSuccess:
                this._store.dispatch(new UpdateListAction({
                    videoCall: null,
                    isNew: false,
                    joinedVideoCallId: payload.data.videoCall.id,
                    inCallInviteFlag: payload.data.videoCall.ispartnerinvited
                }));
                this.connectVideoCall(payload.data);
                break;
            case VideoCallEffectsResposeType.JoinError:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'error',
                    message: this._toastText.ErrorEncountered,
                    title: this._toastText.JoiningVideoCall,
                    timeout: 5000
                }));
                break;
            case VideoCallEffectsResposeType.InviteSuccess:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'success',
                    message: this.videoCallsInvitePartner.PartnerInvitedText,
                    title: this.videoCallsInvitePartner.PartnerInvited,
                    timeout: 5000
                }));
                this._resetPage();
                this.triggerNextPage();
                break;
            case VideoCallEffectsResposeType.NoPartnerFoundError:
                var noPartnerDialogRef = this.dialog.open(InvitePartnerComponent, {
                    maxWidth: '500px',
                    data: {
                        title: this.videoCallsInvitePartner.PartnerNotFound,
                        message1: this.videoCallsInvitePartner.PartnerNotFoundText1,
                        message2: this.videoCallsInvitePartner.PartnerNotFoundText2,
                        message3: this.videoCallsInvitePartner.PartnerNotFoundText3,
                        message4: this.videoCallsInvitePartner.PartnerNotFoundText4,
                        text: {
                            Confirm: this.videoCallsInvitePartner.OK
                        }
                    }
                });
                noPartnerDialogRef.afterClosed().subscribe(function (val) { });
                break;
            case VideoCallEffectsResposeType.InviteError:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'error',
                    message: this.videoCallsInvitePartner.CouldNotInviteErrorMessage,
                    title: this.videoCallsInvitePartner.CouldNotInvite,
                    timeout: 5000
                }));
                break;
            case VideoCallEffectsResposeType.UnInviteSuccess:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'success',
                    message: this.videoCallsInvitePartner.PartnerUnInvitedText,
                    title: this.videoCallsInvitePartner.PartnerUninvited,
                    timeout: 5000
                }));
                this._resetPage();
                this.triggerNextPage();
                break;
            case VideoCallEffectsResposeType.UnInviteError:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'error',
                    message: this.videoCallsInvitePartner.CouldNotUnInviteErrorMessage,
                    title: this.videoCallsInvitePartner.CouldNotUnInvite,
                    timeout: 5000
                }));
                break;
            case VideoCallEffectsResposeType.UnInviteActiveCallError:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'error',
                    message: this.videoCallsInvitePartner.CallInProgressUnInviteMessage,
                    title: this.videoCallsInvitePartner.CouldNotUnInvite,
                    timeout: 5000
                }));
                break;
            case VideoCallEffectsResposeType.InviteInCallSuccess:
                this._store.dispatch(new InviteInCallSuccessAction({
                    inCallInviteFlag: true
                }));
                var inviteInCallDialogRef = this.dialog.open(InvitePartnerComponent, {
                    maxWidth: '500px',
                    data: {
                        title: this.videoCallsInvitePartner.PartnerInvited,
                        message1: this.videoCallsInvitePartner.InvitePartnerInCallText,
                        text: {
                            Confirm: this.videoCallsInvitePartner.OK
                        }
                    }
                });
                inviteInCallDialogRef.afterClosed().subscribe(function (val) { });
                break;
            case VideoCallEffectsResposeType.InviteInCallError:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'error',
                    message: this.videoCallsInvitePartner.CouldNotInviteErrorMessage,
                    title: this.videoCallsInvitePartner.CouldNotInvite,
                    timeout: 5000
                }));
                break;
            case VideoCallEffectsResposeType.UnInviteInCallSuccess:
                this._store.dispatch(new UnInviteInCallSuccessAction({
                    inCallInviteFlag: false
                }));
                var unInviteInCallDialogRef = this.dialog.open(InvitePartnerComponent, {
                    maxWidth: '500px',
                    data: {
                        title: this.videoCallsInvitePartner.PartnerUninvited,
                        message1: this.videoCallsInvitePartner.UnInvitePartnerInCallText1,
                        message2: this.videoCallsInvitePartner.UnInvitePartnerInCallText2,
                        text: {
                            Confirm: this.videoCallsInvitePartner.OK
                        }
                    }
                });
                unInviteInCallDialogRef.afterClosed().subscribe(function (val) { });
                break;
            case VideoCallEffectsResposeType.UnInviteInCallError:
                this._store.dispatch(new DisplayToastAction({
                    toastRef: this._toastRef,
                    type: 'error',
                    message: this.videoCallsInvitePartner.CouldNotUnInviteErrorMessage,
                    title: this.videoCallsInvitePartner.CouldNotUnInvite,
                    timeout: 5000
                }));
                break;
        }
        this._store.dispatch(new ResetEffectsStateAction());
    };
    VideoCallsPageComponent.prototype._getDialogSize = function () {
        var target = this._elementRef.nativeElement.parentElement;
        var screenWidth = target.scrollWidth;
        var width = 65;
        if (screenWidth > 1100) {
            width = 50;
        }
        else if (screenWidth > 900) {
            width = 65;
        }
        return {
            width: width
        };
    };
    VideoCallsPageComponent.prototype._getVideoDialogSize = function () {
        var target = this._elementRef.nativeElement.parentElement;
        var screenWidth = target.scrollWidth;
        var width = 100;
        if (screenWidth > 900) {
            width = 80;
        }
        return {
            width: width
        };
    };
    VideoCallsPageComponent.prototype._resetPage = function () {
        this._pageNumber = 0;
        this._hasMoreItems = true;
    };
    VideoCallsPageComponent.prototype._getVideoCalls$ = function (pageNumber, searchText, resources) {
        var result = this._hasMoreItems
            ? this._videoCallsService.getVideoCalls(null, this._past, pageNumber.toString(), '50', searchText, resources)
            : of(new Array());
        return result;
    };
    return VideoCallsPageComponent;
}());
export { VideoCallsPageComponent };
