import { Observable } from 'rxjs';
import * as SparkMD5 from 'spark-md5';
var HashProgress = /** @class */ (function () {
    function HashProgress() {
    }
    return HashProgress;
}());
export { HashProgress };
var Md5HashService = /** @class */ (function () {
    function Md5HashService() {
        this._chunkSize = 2097152; // Read in chunks of 2MB
    }
    Md5HashService.prototype.setChunkSize = function (bytes) {
        this._chunkSize = bytes;
    };
    Md5HashService.prototype.generateFileName = function () {
        var stringToHash = this._randomString() + new Date().valueOf();
        var hex = SparkMD5.hash(stringToHash);
        return hex;
    };
    Md5HashService.prototype._randomString = function () {
        return Math.random().toString(36);
    };
    Md5HashService.prototype.hashFile = function (file) {
        var blobSlice = File.prototype.slice;
        var chunkSize = this._chunkSize;
        var chunks = Math.ceil(file.size / chunkSize);
        var spark = new SparkMD5.ArrayBuffer();
        var fileReader = new FileReader();
        var currentChunk = 0;
        return Observable.create(function (observer) {
            fileReader.onload = function fileReaderOnLoad(e) {
                var progress = Math.floor((currentChunk / chunks) * 100);
                spark.append(e.target.result);
                currentChunk++;
                if (currentChunk < chunks) {
                    observer.next({
                        hash: null,
                        progress: progress,
                        error: null,
                    });
                    loadNext();
                }
                else {
                    observer.next({
                        hash: spark.end(),
                        progress: 100,
                        error: null,
                    });
                }
            };
            fileReader.onerror = function () {
                observer.error(new Error('Error parsing file: ' + file.name));
                observer.complete();
            };
            function loadNext() {
                var start = currentChunk * chunkSize;
                var end = start + chunkSize >= file.size ? file.size : start + chunkSize;
                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }
            loadNext();
        });
    };
    return Md5HashService;
}());
export { Md5HashService };
