import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  AlterPackContentInterface,
  ContentPack,
  ContentPackDetail,
} from '../../models/content-packs';

@Component({
  selector: 'portal-pack-detail-table',
  templateUrl: './pack-detail-table.component.html',
  styleUrls: ['./pack-detail-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackDetailTableComponent implements OnInit, OnChanges {
  @Input() text: any;
  @Input() packDetailId: number;
  @Input() packDetail: ContentPackDetail[] = [];
  @Input() filterActive: boolean;
  @Input() loadingPackDetail: boolean;
  @Input() currentPack: ContentPack;
  @Input() contentEditorEnabled: boolean;
  @Output() goto = new EventEmitter<ContentPackDetail>();
  @Output() deletePack = new EventEmitter<number>();
  @Output() removePackItems = new EventEmitter<AlterPackContentInterface>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: MatTableDataSource<ContentPackDetail>;
  public config: PerfectScrollbarConfigInterface = {};
  public selection: SelectionModel<ContentPackDetail>;

  public displayedColumns: string[] = [
    'select',
    'name',
    'contentCategory',
    'isFileOnly',
    'isEmpty',
    'lastModifiedBy',
    'salveModified',
  ];

  constructor(public dialog: MatDialog, public change: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._updateDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { packDetail, packDetailId } = changes;
    if (packDetail && changes.packDetail.currentValue) {
      this._updateDataSource();
    }
    if (
      packDetailId &&
      this.paginator &&
      packDetailId.currentValue &&
      packDetailId.previousValue
    ) {
      if (packDetailId.currentValue !== packDetailId.previousValue) {
        this.paginator.firstPage();
      }
    }
  }

  public someSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public clearSelection(): void {
    this.selection.clear();
    this.change.markForCheck();
    this.change.detectChanges();
  }

  private _updateDataSource() {
    this.dataSource = new MatTableDataSource(this.packDetail);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.selection = new SelectionModel(true, []);
  }

  private _selectedItems(): number[] {
    return this.selection.selected.map((r) => r.contentEntryHeaderId);
  }

  public masterToggle(): void {
    this.isAllSelected()
      ? this.clearSelection()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public goToContent(row) {
    this.goto.emit(row);
  }

  public removeItems() {
    this.removePackItems.emit({
      packId: this.packDetailId,
      contentEntries: this._selectedItems(),
    });
  }
}
