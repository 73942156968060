import * as tslib_1 from "tslib";
import { ElementRef, ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { validatePatientDocumentName } from 'src/app/patients/services/async-validators/validate-patient-document-name';
import * as fromAuth from '../../../auth/reducers';
import { validateDocumentName } from '../../../content/services/async-validators/validate-document-name';
import { ContentService } from '../../../content/services/content.service';
import { Md5HashService } from '../../../core/services/md5-hash.service';
import { FileUpload } from '../../../core/services/s3.service';
import * as fromSettings from '../../../settings/reducers';
var AddFileComponent = /** @class */ (function () {
    function AddFileComponent(_store, _S3, _contentService, _error, _hashService, dialogRef, dialog, data) {
        var _this = this;
        this._store = _store;
        this._S3 = _S3;
        this._contentService = _contentService;
        this._error = _error;
        this._hashService = _hashService;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        // capture selected file information
        this.filename$ = new BehaviorSubject(null);
        this.fileInStorage = {
            clinicid: '',
            patientonly: false,
            documentname: '',
            issalvecontent: false,
            isembedded: false,
            filetype: '',
            filesize: 0,
            md5checksum: '',
            uri: '' // the location in S3 of the resource
        };
        // uploading is the act of uploading to S3... if file exists
        //   return file stats from db and use that information
        this.uploading$ = new BehaviorSubject(false);
        this.uploadSuccess$ = new BehaviorSubject(false);
        this.uploadError$ = new BehaviorSubject(false);
        // Control the upload button text
        this.buttonText$ = new BehaviorSubject('');
        this.labelText$ = new BehaviorSubject('');
        // Am I validating the document name?
        this.loading$ = new BehaviorSubject(false);
        this._subs = new Subscription();
        this.fileInStorage.patientonly = !!this.data.patientOnly;
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('AddFile')));
        this._subs.add(this.sectionText$.subscribe(function (t) { return (_this.sectionText = t); }));
    }
    AddFileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buttonText$.next(this.sectionText.UploadFile);
        this.labelText$.next(this.sectionText.ChooseFile);
        if (this.data.type && this.data.type === 'image') {
            this.labelText$.next(this.sectionText.ChooseImage);
        }
        this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
        this.userPk$ = this._store.pipe(select(fromAuth.getPublicKey));
        var documentName = this.data.patientOnly ? '' : this.data.fileName;
        var asyncValidators = this.data.patientOnly
            ? [
                validatePatientDocumentName(this._contentService, this.loading$, documentName, this.data.patientId)
            ]
            : [
                validateDocumentName(this._contentService, this.loading$, documentName)
            ];
        this.documentForm = new FormGroup({
            name: new FormControl(documentName, Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(50)
            ]), asyncValidators),
            file: new FormControl({ value: null, disabled: false }, [
                Validators.required
            ])
        });
        // watch for changes to file input in order to append
        // filename name to filename span
        this._subs.add(this.documentForm.get('file').valueChanges.subscribe(function (file) {
            if (file) {
                _this.selectedFile = _this.docFileInput.nativeElement.files[0];
                var fileNameArr = file.split('\\');
                _this.fileName = fileNameArr[fileNameArr.length - 1];
                _this.fileSize = _this.selectedFile.size;
                _this.filename$.next(_this.fileName);
                if (_this.data.type === 'image') {
                    _this.uploadFile();
                }
            }
        }));
    };
    AddFileComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    AddFileComponent.prototype.clearName = function () {
        this.documentForm.patchValue({
            name: ''
        });
    };
    AddFileComponent.prototype.clearFile = function () {
        this.documentForm.patchValue({
            file: null
        });
        this.docFileInput.nativeElement.value = '';
        this.selectedFile = null;
        this.filename$.next('');
    };
    AddFileComponent.prototype.uploadFile = function () {
        var _this = this;
        // check file by hashing and searching for duplicates
        this.buttonText$.next(this.sectionText.Checking);
        this.uploading$.next(true);
        // Hash file
        this._hashFile(this.selectedFile)
            .then(function (md5Hash) {
            // Check if hash already exists in db
            if (!_this.data.patientOnly) {
                return _this._doesHashExist(md5Hash);
            }
            else {
                return Promise.resolve([]);
            }
        })
            .then(function (files) {
            _this.buttonText$.next(_this.sectionText.Uploading);
            if (files.length > 0) {
                // If exists, add info to content entry
                return Promise.resolve(files[0]);
            }
            // if not exists, create and upload and retrieve fileInfo
            return _this._createFileInStorage(_this.selectedFile);
        })
            .then(function (file) {
            if (file.uri.length > 0) {
                return Promise.resolve(file);
            }
            return _this._writeFileToBucket(_this.selectedFile, file);
            // return this._uploadFileToS3(this.selectedFile, file);
        })
            .then(function (file) {
            return _this._saveDocument(file);
        })
            .then(function (file) {
            _this.fileInStorage = file;
            _this.uploading$.next(false);
            _this.uploadSuccess$.next(true);
            _this.buttonText$.next(_this.sectionText.Uploaded);
            setTimeout(function () {
                _this.submit();
            }, 500);
        })
            .catch(function (err) {
            _this.uploading$.next(false);
            _this.uploadError$.next(true);
            _this.buttonText$.next(_this.sectionText.Error);
        });
    };
    AddFileComponent.prototype._writeFileToBucket = function (file, fileInfo) {
        var _this = this;
        return new Promise(function (res, rej) {
            var fileComponents = file.name.split('.');
            var extension = fileComponents[fileComponents.length - 1];
            var fileName = _this._cleanFileName(fileInfo.documentname) + "." + extension;
            // const type = this.data.public
            //   ? 'public'
            //   : 'private';
            _this._S3
                .writeFileToBucket('content', fileName, file, _this.data.public, _this.data.patientId)
                .subscribe(function (r) {
                if (r.complete && (r.body || r.error)) {
                    if (r.body && !(r.error instanceof Error)) {
                        fileInfo.uri = r.body.Location;
                        res(fileInfo);
                    }
                    else if (r.error instanceof Error) {
                        rej(r.error);
                    }
                    else {
                        rej(new Error("Unexpected response from s3 service"));
                    }
                }
            }, function (e) {
                _this.uploadError$.next(true);
                rej(e);
            });
        });
    };
    AddFileComponent.prototype._saveDocument = function (file) {
        var _this = this;
        return new Promise(function (res, rej) {
            _this._contentService.updateFileInStorage(file).subscribe(function (updatedFile) { return res(updatedFile); }, function (err) { return rej(err); });
        });
    };
    AddFileComponent.prototype._cleanFileName = function (name) {
        return name.replace(/\'/g, '').replace(/\s/g, '_').toLowerCase();
    };
    AddFileComponent.prototype._createFileInStorage = function (file) {
        var _this = this;
        var newFile = tslib_1.__assign({}, this.fileInStorage);
        return new Promise(function (res, rej) {
            _this.clinicId$
                .pipe(take(1), switchMap(function (clinicId) {
                // update file with relevant fields
                newFile.clinicid = clinicId;
                newFile.md5checksum = _this.docMD5;
                newFile.documentname = _this.documentForm.get('name').value;
                newFile.filetype = file.type;
                newFile.filesize = +file.size;
                return _this._contentService.createFileInStorage(newFile);
            }))
                .subscribe(function (createdFile) {
                res(createdFile);
            }, function (err) { return rej(err); });
        });
    };
    AddFileComponent.prototype._doesHashExist = function (hash) {
        var _this = this;
        return new Promise(function (res, rej) {
            _this._contentService
                .checkFileByMD5(hash)
                .pipe(take(1), map(function (files) {
                res(files);
            }))
                .subscribe(function () { }, function (err) {
                rej(err);
            });
        });
    };
    AddFileComponent.prototype._hashFile = function (file) {
        var _this = this;
        return new Promise(function (res, rej) {
            _this._hashService.hashFile(file).subscribe(function (progress) {
                if (progress.progress === 100) {
                    _this.docMD5 = progress.hash;
                    res(progress.hash);
                }
            }, function (err) {
                rej(err);
            });
        });
    };
    // send form and uploaded document back to main form
    // or cancel
    AddFileComponent.prototype.submit = function () {
        this.dialogRef.close({
            attachment: this.fileInStorage
        });
    };
    AddFileComponent.prototype.cancel = function () {
        this.dialogRef.close({
            attachment: null
        });
    };
    return AddFileComponent;
}());
export { AddFileComponent };
