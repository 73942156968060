import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { Container } from './Loading.styled';

interface Props {
  /**
   * Optional `data-testid` to apply to root element for testing.
   * Defaults to "Empty".
   */
  'data-testid'?: string;

  fullHeight?: boolean;
}

/**
 * Can be used to show the user that an operation is being performed.
 */
export const Loading: React.VFC<Props> = ({
  'data-testid': dataTestId = 'Loading',
  fullHeight,
}) => (
  <Container data-testid={dataTestId} fullHeight={fullHeight}>
    <CircularProgress />
  </Container>
);
