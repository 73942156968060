import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { DashboardActionTypes, LoadClinicMedication, LoadClinicNonTimedTriggerMedication, LoadClinicNonTimedTriggerMedicationError, LoadClinicNonTimedTriggerMedicationSuccess, LoadClinicStatus, LoadClinicStatusError, LoadClinicStatusSuccess, LoadClinicSummary, LoadClinicSummaryError, LoadClinicSummarySuccess, RefreshClinicDashboard, RefreshClinicStatus } from '../actions/dashboard.actions';
import { ClinicStatusService } from '../services/clinic-status.service';
import { DashboardService } from '../services/dashboard.service';
var DashboardEffects = /** @class */ (function () {
    function DashboardEffects(actions$, dashboardService, clinicStatusService, _error) {
        var _this = this;
        this.actions$ = actions$;
        this.dashboardService = dashboardService;
        this.clinicStatusService = clinicStatusService;
        this._error = _error;
        this.refreshClinicStatus$ = this.actions$.pipe(ofType(DashboardActionTypes.RefreshClinicStatus), mergeMap(function () { return [new LoadClinicStatus()]; }));
        this.loadClinicStatus$ = this.actions$.pipe(ofType(DashboardActionTypes.LoadClinicStatus), switchMap(function () {
            return _this.clinicStatusService.getClinicStatus().pipe(map(function (summary) {
                return new LoadClinicStatusSuccess(summary);
            }), catchError(function (error) {
                return of(new LoadClinicStatusError(error));
            }));
        }));
        this.refreshDashboard$ = this.actions$.pipe(ofType(DashboardActionTypes.RefreshClinicDashboard), mergeMap(function () { return [
            // new LoadClinicSummary(),
            new LoadClinicMedication(),
            new LoadClinicNonTimedTriggerMedication()
        ]; }));
        this.loadClinicSummary$ = this.actions$.pipe(ofType(DashboardActionTypes.LoadClinicSummary), switchMap(function () {
            return _this.dashboardService.getClinicSummary().pipe(map(function (summary) {
                return new LoadClinicSummarySuccess(summary);
            }), catchError(function (error) {
                return of(new LoadClinicSummaryError(error));
            }));
        }));
        // @Effect()
        // loadClinicMedication$ = this.actions$.pipe(
        //   ofType<LoadClinicMedication>(DashboardActionTypes.LoadClinicMedication),
        //   switchMap((action) => {
        //     return this.dashboardService.getClinicMedications().pipe(
        //       map((meds) => {
        //         return new LoadClinicMedicationSuccess(meds);
        //       }),
        //       catchError((error) => {
        //         return of(new LoadClinicMedicationError(error));
        //       }),
        //     );
        //   }),
        // );
        this.loadClinicNonTimedTriggerMedication$ = this.actions$.pipe(ofType(DashboardActionTypes.LoadClinicNonTimedTriggerMedication), switchMap(function (action) {
            return _this.dashboardService.getClinicNonTimedTriggerMedications().pipe(map(function (meds) {
                return new LoadClinicNonTimedTriggerMedicationSuccess(meds);
            }), catchError(function (error) {
                return of(new LoadClinicNonTimedTriggerMedicationError(error));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DashboardEffects.prototype, "refreshClinicStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DashboardEffects.prototype, "loadClinicStatus$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DashboardEffects.prototype, "refreshDashboard$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DashboardEffects.prototype, "loadClinicSummary$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], DashboardEffects.prototype, "loadClinicNonTimedTriggerMedication$", void 0);
    return DashboardEffects;
}());
export { DashboardEffects };
