import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IntersectionObserverDirective } from 'src/app/core/directives/interesection-observer';
import { ApiVideoCall } from '../../responses';

@Component({
  selector: 'portal-video-calls-list',
  templateUrl: 'video-calls-list.component.html',
  styleUrls: ['./video-calls-list.component.scss'],
})
export class VideoCallsListComponent implements OnInit, OnDestroy {
  @ViewChildren(IntersectionObserverDirective)
  isos: QueryList<IntersectionObserverDirective>;
  private _items: ApiVideoCall[];
  private _partnerInviteEnabled: boolean;

  @Input() set items(value: ApiVideoCall[]) {
    this._items = value;
  }
  get items(): ApiVideoCall[] {
    return this._items;
  }

  @Input() set partnerInviteEnabled(value: boolean) {
    this._partnerInviteEnabled = value;
  }
  get partnerInviteEnabled() {
    return this._partnerInviteEnabled;
  }

  @Input() text: any;
  @Input() invitePartnerText: Record<string, string>;
  @Input() currentStateIsPast: boolean;

  @Output() joinVideoCall = new EventEmitter<ApiVideoCall>();
  @Output() editVideoCall = new EventEmitter<ApiVideoCall>();
  @Output() invitePartner = new EventEmitter<ApiVideoCall>();
  @Output() unInvitePartner = new EventEmitter<ApiVideoCall>();

  @Output() reachedScrollEnd = new EventEmitter<void>();

  displayedColumns: string[] = [
    'patientId',
    'patientName',
    'patientDOB',
    'host',
    'description',
    'when',
    'duration',
    // 'status',
    'join-button',
    'more-button',
    'invite',
  ];

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.detachObservers();
  }

  detachObservers() {
    this.isos.forEach((iso) => iso.DetachObserver());
  }

  onObserved() {
    this.reachedScrollEnd.emit();
  }
}
