export enum CognitoStatuses {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  SELECT_MFA_TYPE = 'SELECT_MFA_TYPE',
  MFA_SETUP = 'MFA_SETUP',
  PASSWORD_VERIFIER = 'PASSWORD_VERIFIER',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
  DEVICE_SRP_AUTH = 'DEVICE_SRP_AUTH',
  DEVICE_PASSWORD_VERIFIER = 'DEVICE_PASSWORD_VERIFIER',
  ADMIN_NO_SRP_AUTH = 'ADMIN_NO_SRP_AUTH',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}
