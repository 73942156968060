import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { passwordComplexity } from 'src/app/core/utils/validators';
import { LoginSubmission } from '../../models/LoginSubmission';
import { CognitoError } from './../../models/LoginSubmission';

@Component({
  selector: 'portal-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnChanges {
  @Input() isCore: boolean;
  @Input() text: any;
  @Input()
  set loggingIn(isLoggingIn: boolean) {
    if (isLoggingIn) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
    this.isLoggingIn = isLoggingIn;
  }
  @Input() error: CognitoError;
  @Input() clinicId: number;
  @Input() cognitoUser: CognitoUser | any;
  @Output() forgottenPassword = new EventEmitter();
  @Output() submitted = new EventEmitter<LoginSubmission>();

  public showScrollbar: boolean;
  public isLoggingIn: boolean;
  public loginForm: FormGroup;
  public OTPForm: FormGroup;
  public formSubmitted: boolean;

  constructor(private _fb: FormBuilder) {
    this._setupForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isCore) {
      if (changes.isCore.currentValue !== changes.isCore.previousValue) {
        this._setupForm();
      }
    }
  }

  private _setupForm() {
    this.loginForm = this._fb.group(
      {
        username: [
          {
            value: '',
            disabled: false,
          },
          [Validators.email],
        ],
        password: [
          {
            value: '',
            disabled: false,
          },
          [Validators.required],
        ],
      },
      {
        validator: [this._passwordOrCoreValidator()],
      },
    );
  }

  private _passwordOrCoreValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      // extract username
      const username = group.controls['username'];
      // password
      const password = group.controls['password'];

      if (username.value.includes('@') || this.isCore) {
        return passwordComplexity(password);
      }
      return null;
    };
  }

  public submit() {
    this.formSubmitted = true;
    const auth = {
      ...this.loginForm.value,
      validationData: {
        clinictoken: this.clinicId,
      },
    };
    this.submitted.emit({
      isCore: this.isCore,
      usedEmail: true,
      auth,
    });
  }
}
