import styled from '@emotion/styled';
import { Star as StarIcon } from '@material-ui/icons';
import { Chip } from '@react/components';

export const StyledConversationCard = styled.div`
  padding: 0.75rem 1rem;
  cursor: pointer;

  * {
    font-weight: lighter;
  }

  .patient-dob {
    display: block;
    margin-top: ${({ theme }) => theme.spacing(1)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  .content {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    span {
      font-weight: normal;
    }
  }

  &.conversation-card__selected {
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }

  &.conversation-card__highlight {
    .patient-name,
    .patient-dob,
    .created-at {
      font-weight: bold;
    }
  }

  .typing-indicator {
    font-size: 0.9rem;
  }
`;

interface StarProps {
  active?: boolean;
}

export const Star = styled(StarIcon, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StarProps>`
  cursor: pointer;
  color: ${({ active, theme }) =>
    active ? theme.colors.yellow : 'transparent'};
  margin-right: ${({ theme }) => theme.spacing(1)}px;

  path {
    stroke: ${({ active }) => (active ? '' : '#9b9b9b')};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  .patient-name {
    font-weight: normal;
    width: 50%;
  }

  .created-at {
    margin-left: auto;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    width: 200px;
    text-align: right;
  }
`;

interface ReadIndicatorProps {
  read?: boolean;
  badge?: boolean;
}

export const ReadIndicator = styled('div', {
  shouldForwardProp: (prop) => ['read', 'badge'].every((key) => key !== prop),
})<ReadIndicatorProps>`
  width: 18px;
  height: 18px;

  ${({ badge, theme }) =>
    badge &&
    `
  background-color: ${theme.colors.coral};
  border-radius: 18px;
  `}

  ${({ badge, read, theme }) =>
    !badge &&
    `
    svg {
      fill: ${read ? theme.colors.coral : '#999999'}
    }
  `}
`;

export const ChipList = styled.div`
  flex-wrap: wrap;
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledChip = styled(Chip)`
  max-width: 145px;
`;
