import { Label } from 'src/app/models/Label';
import { Message } from '../../models/Message';
import {
  LoadMessageActionsUnion,
  LoadMessageActionTypes,
} from '../actions/load-message.actions';
import { MessagesState } from '../reducers';
import { mergeMessageArrays, replaceMessageInState } from './utils';

export function reducer(
  state: MessagesState,
  action: LoadMessageActionsUnion,
): MessagesState {
  switch (action.type) {
    case LoadMessageActionTypes.LoadMessagingSummarySuccess: {
      return {
        ...state,
        messagingSummary: action.payload,
      };
    }

    case LoadMessageActionTypes.LoadingThread: {
      return {
        ...state,
        loadingThread: true,
      };
    }

    case LoadMessageActionTypes.LoadedThread: {
      return {
        ...state,
        loadingThread: false,
      };
    }

    case LoadMessageActionTypes.UpdateLatestMessage: {
      return {
        ...state,
        latestMessage: { ...state.messageList[0] },
      };
    }

    case LoadMessageActionTypes.LoadMessages: {
      return {
        ...state,
        isLoadingConversations: true,
      };
    }

    case LoadMessageActionTypes.LoadThreadSuccess: {
      const id = action.payload.id;
      const messages = state.messageList.map((m) =>
        m.MessageId !== id ? m : { ...m, Read: true },
      );
      return {
        ...state,
        loadingThread: false,
        activeThreadFirstLoad: false,
        messageList: messages,
        activeThread: action.payload,
        isReadOnly: action.payload.isReadOnly,
      };
    }

    case LoadMessageActionTypes.LoadNewerThreadMessagesSuccess: {
      if (
        action.payload.threadId === state.activeThreadId &&
        action.payload.messages.length > 0
      ) {
        // Update message in messages list
        let messages = state.messageList;
        const existingMessage = state.messageList.find(
          (m) => m.MessageId === action.payload.threadId,
        );
        if (existingMessage) {
          messages = replaceMessageInState(
            state,
            existingMessage,
            action.payload.messages[action.payload.messages.length - 1],
          );
        }

        // Remove existing message items from state
        const threadMessages = [...state.activeThread.messages];
        action.payload.messages.forEach((newMessage) => {
          const existingStateMessage = threadMessages.find(
            (m) => m.MessageItemId === newMessage.MessageItemId,
          );
          if (existingStateMessage) {
            const existingMessageIndex =
              threadMessages.indexOf(existingStateMessage);
            threadMessages.splice(existingMessageIndex, 1, newMessage);
          } else {
            threadMessages.push(newMessage);
          }
        });

        // Add message to thread
        return {
          ...state,
          activeThread: {
            ...state.activeThread,
            messages: threadMessages,
            hasNewMessages: !!action.payload.messages.find(
              (m) => m.PatientSent,
            ),
          },
        };
      }
      return { ...state };
    }

    case LoadMessageActionTypes.LoadMessageTypesSuccess: {
      const labels: Label[] = [];

      labels.push(
        new Label({
          messageTypeId: -1,
          isGroup: true,
          filter: 'unresolved',
          displayName: 'AllUnresolved',
          unresolvedCount: 0,
        }),
      );

      action.payload.results.forEach((messageType) => {
        labels.push(
          new Label({
            displayName: messageType.MessageType,
            messageTypeId: messageType.MessageTypeId,
            filter: messageType.MessageType.toLowerCase(),
            isGroup: false,
            isUncategorised: messageType.Uncategorised,
            canClinicReply: messageType.CanClinicReply,
          }),
        );
      });

      labels.push(
        new Label({
          messageTypeId: -2,
          includeResolved: true,
          includeUnresolved: false,
          isGroup: true,
          filter: 'resolved',
          displayName: 'AllResolved',
          unresolvedCount: 0,
        }),
        new Label({
          messageTypeId: -3,
          includeResolved: true,
          isGroup: true,
          filter: 'all',
          displayName: 'AllMessages',
          unresolvedCount: 0,
        }),
      );

      return {
        ...state,
        labels,
      };
    }

    case LoadMessageActionTypes.AddMessageToThread: {
      const { sentDate, newMessage, message } = action.payload;
      // Only update active thread if messageId still matches the
      // active thread
      if (state.activeThread.id === newMessage.MessageId) {
        const messageToAdd: Message = {
          ...message,
          PatientSent: false,
          PatientRead: false,
          Read: false,
          Content: newMessage.MessageContent,
          SentDate: sentDate,
          DeletedDate: null,
        };
        return {
          ...state,
          sendingMessage: null,
          activeThread: {
            ...state.activeThread,
            messages: state.activeThread.messages.concat([messageToAdd]),
          },
        };
      } else {
        return {
          ...state,
          sendingMessage: null,
        };
      }
    }

    // ---------------------- Refactoring --------------------

    case LoadMessageActionTypes.LoadMessagesSuccess: {
      const messages = action.payload.messages;
      const counts = action.payload.counts;

      const mergedMessages = mergeMessageArrays(state.messageList, messages);
      const minimumNumberOfMessagesOnPage = 25;

      let reachedEndOfMessages;

      // Remove once feature is enabled in production.
      if (state.tab === 'None') {
        reachedEndOfMessages = messages.length === 0;
      } else {
        reachedEndOfMessages =
          state.tab === 'Starred'
            ? messages.filter((m) => m.IsStarred === true).length === 0
            : messages.filter((m) => m.IsStarred === false).length === 0;
      }

      return {
        ...state,
        isLoadingConversations: false,
        messageCountSummary: {
          ...counts,
        },
        messageList: mergedMessages,
        reachedEndOfMessages,
        autoLoadNextPage:
          (messages.length > 0 &&
            mergedMessages.length === state.messageList.length) ||
          mergedMessages.length < minimumNumberOfMessagesOnPage
            ? Math.random()
            : 0,
        nextPageToLoad: {
          ...state.nextPageToLoad,
          FetchBeforeMessageItemId:
            messages.length > 0
              ? messages[messages.length - 1].MessageItemId
              : null,
        },
        latestMessage: { ...messages[0] },
      };
    }

    case LoadMessageActionTypes.LoadNewestMessagesSuccess: {
      if (action.payload.messages.length === 0) {
        return { ...state };
      }

      const indicesToRemove = [];
      const existingMessages = [...state.messageList];
      action.payload.messages
        .map((m) => m.MessageId)
        .forEach((id) => {
          const message = existingMessages.find((m) => m.MessageId === id);
          if (message) {
            indicesToRemove.push(existingMessages.indexOf(message));
          }
        });
      indicesToRemove.forEach((i) => {
        existingMessages.splice(i, 1);
      });

      const mergedMessages = [...action.payload.messages, ...existingMessages];

      return {
        ...state,
        messageList: mergedMessages,
      };
    }

    case LoadMessageActionTypes.SetIsLoadingConversations: {
      return {
        ...state,
        isLoadingConversations: action.payload.isLoading,
      };
    }

    default:
      return state;
  }
}
