import { OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
var CommentsComponent = /** @class */ (function () {
    function CommentsComponent(_controlContainer) {
        this._controlContainer = _controlContainer;
    }
    Object.defineProperty(CommentsComponent.prototype, "commentLength", {
        get: function () {
            return this.commentForm.get('comments').value.length;
        },
        enumerable: true,
        configurable: true
    });
    CommentsComponent.prototype.ngOnInit = function () {
        this.commentForm = this._controlContainer.control;
    };
    return CommentsComponent;
}());
export { CommentsComponent };
