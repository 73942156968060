import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { User } from 'src/app/auth/models/user';
import {
  StyledCard,
  StyledNav,
  StyledNavLink,
  StyledNavList,
} from './Nav.styled';

interface Props {
  user: User;
}

const Nav: FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslations();

  const features: { [key: string]: any } = useFeatures();
  const enabledUserAdmin = Boolean(
    features &&
      features.portalAdminOptions &&
      features.portalAdminOptions.users,
  );
  const isClinicUserAdmin = user.groups.includes('ClinicUserAdmin');

  return (
    <StyledCard>
      <StyledNav>
        <StyledNavList>
          <li>
            {/* Property 'to' does not exist on type
            // @ts-ignore */}
            <StyledNavLink activeClassName="active" to="/admin/appointments">
              {t.Admin.AppointmentTypeNavItem}
            </StyledNavLink>
            {/* Property 'to' does not exist on type
               // @ts-ignore */}
            <StyledNavLink
              activeClassName="active"
              to="/admin/message-templates"
            >
              {t.Admin.MessageTemplatesNavItem}
            </StyledNavLink>
            {/* Property 'to' does not exist on type
               // @ts-ignore */}
            <StyledNavLink activeClassName="active" to="/admin/side/recipes">
              {t.Admin.SIDE.NavItem}
            </StyledNavLink>

            {enabledUserAdmin && isClinicUserAdmin && (
              // @ts-ignore */
              <StyledNavLink activeClassName="active" to="/admin/users">
                {t.Admin.Users.NavItem}
              </StyledNavLink>
            )}
          </li>
        </StyledNavList>
      </StyledNav>
    </StyledCard>
  );
};

export default Nav;
