import * as tslib_1 from "tslib";
var HttpClientBase = /** @class */ (function () {
    function HttpClientBase(options) {
        this._clinicToken = options.clinicToken;
        this._authService = options.authService;
        this._onUnauthorized = options.onUnauthorized;
        this._baseUrl = options.baseUrl;
    }
    /**
     * Essentially a wrapper around `fetch` that sets some mandatory headers
     * and behavior.
     */
    HttpClientBase.prototype.fetch = function (path, 
    /** Force `headers` to be a simple object for ease of use */
    options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var authUser, headers, response, content, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authService.getAuthSession()];
                    case 1:
                        authUser = _a.sent();
                        headers = tslib_1.__assign({}, (options != null && options.headers != null ? options.headers : {}), { accept: 'application/json', authorization: authUser.getIdToken().getJwtToken(), 'x-salve-clinic-token': this._clinicToken });
                        return [4 /*yield*/, fetch("" + this._baseUrl + path, tslib_1.__assign({}, options, { headers: headers }))];
                    case 2:
                        response = _a.sent();
                        if (response.status === 401) {
                            this._onUnauthorized();
                            throw new Error('Unauthorized');
                        }
                        if (!response.ok) return [3 /*break*/, 6];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, response.text()];
                    case 4:
                        content = _a.sent();
                        return [2 /*return*/, content.length > 0 ? JSON.parse(content) : undefined];
                    case 5:
                        err_1 = _a.sent();
                        throw new Error("Error parsing body as JSON: " + err_1.message);
                    case 6: throw new Error('Network response was not ok');
                }
            });
        });
    };
    return HttpClientBase;
}());
export { HttpClientBase };
