import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material';
import { FileUpload } from 'src/app/core/services/s3.service';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import {
  IndividuallyAssignedPatientContent,
  PatientOnlyAssignContentItem
} from '../../models/UserContent';

@Component({
  selector: 'portal-content-select',
  templateUrl: './content-select.component.html',
  styleUrls: ['./content-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentSelectComponent implements OnDestroy {
  @ViewChild('contentList') contentList: ElementRef;

  @Input() text: any;
  @Input() filterActive = false;
  @Input() filterString = '';
  @Input() content: IndividuallyAssignedPatientContent[] = [];
  @Input() patientOnly: boolean;
  @Input() patientOnlyContent: PatientOnlyAssignContentItem[];
  @Input() folderName: string;
  @Output() setSearchString = new EventEmitter<string>();
  @Output() addAppContent =
    new EventEmitter<IndividuallyAssignedPatientContent>();
  @Output() openFileDialog = new EventEmitter();
  @Output() activatePatientOnlyFile =
    new EventEmitter<PatientOnlyAssignContentItem>();
  @Output() deletePatientOnlyFile =
    new EventEmitter<PatientOnlyAssignContentItem>();

  public config: PerfectScrollbarConfigInterface = {};

  public searchForm: FormGroup;

  private _subs = new Subscription();

  isSlidingItem = false;

  constructor(
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private _s3: FileUpload,
    private _error: ErrorHandler
  ) {
    this.searchForm = this._fb.group({
      searchValue: this.filterString || ''
    });

    this._subs.add(
      this.searchForm
        .get('searchValue')
        .valueChanges.subscribe((val) => this.setSearchString.emit(val))
    );
  }

  ngOnDestroy(): void {
    this.setSearchString.emit('');
    this._subs.unsubscribe();
  }

  viewFile(entry: PatientOnlyAssignContentItem) {
    this._s3
      .readFileFromBucket({
        url: entry.uri
      })
      .then((res) => {
        window.open(res.url, '_blank');
      });
  }

  public removeFile(entry: PatientOnlyAssignContentItem) {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.text.DeleteConfirm,
        text: {
          Confirm: this.text.Confirm,
          Cancel: this.text.Cancel
        }
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {
      if (!result) {
        this.contentList.nativeElement.focus();
      } else {
        this.deletePatientOnlyFile.emit(entry);
      }
    });
  }

  public clearFilter() {
    this.searchForm.patchValue({
      searchValue: ''
    });
  }

  onClickedActivePatientOnlyFile(item: PatientOnlyAssignContentItem) {
    if (!item.isTransferring) {
      this.activatePatientOnlyFile.emit(item);
    }
  }

  onClickedAddAppContent(item: IndividuallyAssignedPatientContent) {
    if (!item.isTransferring) {
      // addAppContent.emit(entry.contentEntryHeaderId)
      this.addAppContent.emit(item);
    }
  }
}
