import * as tslib_1 from "tslib";
import { Box } from '@material-ui/core';
import { Button } from '@react/components/Button';
import { List } from '@react/components/filter/list';
import { CognitoStatus } from '@react/lib/api/types';
import { useTranslations } from '@react/lib/i18n';
import React, { useState } from 'react';
import { StyledActionsContainer, StyledContainer, StyledIndicator, StyledPanel, StyledTitle, } from './index.styled';
var FilterPanel = function (_a) {
    var filters = _a.filters, apply = _a.apply;
    var _b = useState(filters), localFilters = _b[0], setLocalFilters = _b[1];
    var t = useTranslations().t;
    var setStatus = function (key) {
        var status = Array.isArray(localFilters.status)
            ? Array.from(localFilters.status)
            : [];
        if (status.includes(key)) {
            status = Array.from(status).filter(function (item) { return item !== key; });
        }
        else {
            status.push(key);
        }
        setLocalFilters(tslib_1.__assign({}, localFilters, { status: status }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(StyledContainer, { value: 0 },
                React.createElement(StyledTitle, null, t.Admin.Users.FilterTabStatus),
                React.createElement(StyledIndicator, null))),
        React.createElement(StyledPanel, null,
            React.createElement(List, { data: Object.keys(CognitoStatus), isActive: function (item) {
                    return localFilters.status
                        ? localFilters.status.includes(CognitoStatus[item])
                        : false;
                }, id: "StatusFilters", onClick: function (item) {
                    return setStatus(CognitoStatus[item]);
                }, getLabel: function (key) {
                    return t.Admin.Users[key + "Status"];
                } })),
        React.createElement(StyledActionsContainer, null,
            React.createElement(Button, { "data-testid": "btn_apply", color: "primary", variant: "contained", onClick: function () { return apply(localFilters); } }, t.Admin.Users.FilterApply))));
};
var ɵ0 = FilterPanel;
export default FilterPanel;
export { ɵ0 };
