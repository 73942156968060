import { ErrorHandler, Provider, } from '@angular/core';
import { RouterStateSerializer, } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { PortalToastComponent } from './core/components/portal-toast/portal-toast.component';
import { SentryErrorHandler } from './shared/sentry-error-handler';
import { CustomRouterStateSerializer } from './shared/utils';
// initialState for router reducer
var initialRouterState = {
    state: {
        section: 'Dashboard',
        url: '/',
        params: {},
        queryParams: {},
    },
    navigationId: 0,
};
var initialState = {
    router: initialRouterState,
};
// Toast options
var toastOptions = {
    closeButton: true,
    timeout: 5000,
    positionClass: 'toast-bottom-left',
    maxOpened: 3,
    autoDismiss: true,
    toastClass: 'toast custom-toast',
    preventDuplicates: true,
    toastComponent: PortalToastComponent,
};
var ɵ0 = window;
var providers = [
    {
        provide: 'Window',
        useValue: ɵ0,
    }
].concat((environment.environment !== 'local'
    ? [
        {
            provide: RouterStateSerializer,
            useClass: CustomRouterStateSerializer,
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
    ]
    : [
        {
            provide: RouterStateSerializer,
            useClass: CustomRouterStateSerializer,
        },
    ]));
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
