/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./profile-photo-react-wrapper.component";
import * as i2 from "../../services/s3.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../../services/congito.wrapper.service";
var styles_ProfilePhotoReactWrapperComponent = [];
var RenderType_ProfilePhotoReactWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfilePhotoReactWrapperComponent, data: {} });
export { RenderType_ProfilePhotoReactWrapperComponent as RenderType_ProfilePhotoReactWrapperComponent };
export function View_ProfilePhotoReactWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["profilePhotoReactComponent", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_ProfilePhotoReactWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-profile-photo", [], null, null, null, View_ProfilePhotoReactWrapperComponent_0, RenderType_ProfilePhotoReactWrapperComponent)), i0.ɵdid(1, 4440064, null, 0, i1.ProfilePhotoReactWrapperComponent, [i2.FileUpload, i3.Store, i4.CognitoWrapperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfilePhotoReactWrapperComponentNgFactory = i0.ɵccf("portal-profile-photo", i1.ProfilePhotoReactWrapperComponent, View_ProfilePhotoReactWrapperComponent_Host_0, { clinicUserId: "clinicUserId", size: "size" }, {}, []);
export { ProfilePhotoReactWrapperComponentNgFactory as ProfilePhotoReactWrapperComponentNgFactory };
