import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import { Category } from '../../content/models/category';
import { ContentEntry } from '../../content/models/content-entry';
import {
  CreatePatientOnlyAssignment,
  DeletePatientOnlyAssignment,
  GetAllPatientAssignedContentResponse,
  GetPatientViewResponse,
  IndividuallyAssignedPatientContent,
  PatientAssignedContentItem,
  PatientAssignedCreation,
  PatientOnlyAssignContentItem,
  UpdatePatientOnlyAssignment
} from '../models/UserContent';

@Injectable()
export class ContentAssignmentService {
  constructor(
    private _error: ErrorHandler,
    private _httpService: HttpService
  ) {}

  // READ for Content Entries
  // ===================================================
  // Read
  getContentEntryHeaders(): Observable<ContentEntry[] | Error> {
    return this._httpService
      .get<ContentEntry[]>(
        EndpointType.Content,
        `content/groupedentries/assign`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // GET all

  // READ for Categories
  // ===================================================
  // Read
  getContentCategories(): Observable<Category[] | Error> {
    return this._httpService
      .get<Category[]>(EndpointType.Content, `content/categories`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // CRUD for ContentAssignmentEntries
  // ===================================================
  // Read
  getUserAssignedContentById(
    id: number
  ): Observable<IndividuallyAssignedPatientContent[]> {
    return this._httpService
      .get<IndividuallyAssignedPatientContent[]>(
        EndpointType.Content,
        `content/assign/${id}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAllUserAssignedContent(
    id: number
  ): Observable<GetAllPatientAssignedContentResponse[] | Error> {
    return this._httpService
      .get<GetAllPatientAssignedContentResponse[]>(
        EndpointType.Content,
        `content/assign/${id}/all`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getPatientViewOfContent(id: number): Observable<GetPatientViewResponse> {
    return this._httpService
      .get<GetPatientViewResponse>(
        EndpointType.Content,
        `content/entries/patient/${id}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Delete
  deleteUserAssignedContent(id: number): Observable<boolean | Error> {
    return this._httpService
      .delete<{ success: boolean; body: string }>(
        EndpointType.Content,
        `content/assign/${id}`
      )
      .pipe(
        map((res: { success: boolean; body: string }) => res.success),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Create
  createUserAssignedContent(
    newAssigned: PatientAssignedCreation,
    id: number
  ): Observable<PatientAssignedContentItem | Error> {
    return this._httpService
      .post<PatientAssignedContentItem>(
        EndpointType.Content,
        `content/assign/${id}`,
        newAssigned
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // CRUD for PatientContentAssignmentEntries
  // ===================================================
  // Read
  getPatientOnlyAssignedContent(
    patientId: number
  ): Observable<PatientOnlyAssignContentItem[] | Error> {
    return this._httpService
      .get<PatientOnlyAssignContentItem[]>(
        EndpointType.Content,
        `content/patient/${patientId}/1`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Create
  createPatientOnlyAssignedContent(
    body: CreatePatientOnlyAssignment,
    patientId: number
  ): Observable<PatientOnlyAssignContentItem | Error> {
    // TODO: Not sure how this is invoked
    return this._httpService
      .post<PatientOnlyAssignContentItem>(
        EndpointType.Content,
        `content/patient/${patientId}`,
        body
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
    // return this._contentApiProvider.callApi().pipe(
    //   switchMap(([endpoint, defaultHttpOptions]) => {
    //     const uri = `${endpoint}content/patient/${patientId}`;
    //     return this.http
    //       .post<PatientOnlyAssignContentItem>(uri, body, defaultHttpOptions)
    //       .pipe(
    //         map((res: PatientOnlyAssignContentItem) => res),
    //         catchError((err) => {
    //           return throwError(err);
    //         })
    //       );
    //   })
    // );
  }

  // Update
  updatePatientOnlyAssignedContent(
    updateInfo: UpdatePatientOnlyAssignment,
    assignmentId: number
  ): Observable<PatientOnlyAssignContentItem | Error> {
    return this._httpService
      .put<PatientOnlyAssignContentItem>(
        EndpointType.Content,
        `content/patient/${assignmentId}`,
        updateInfo
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Delete
  deletePatientOnlyAssignedContent(
    deleteInfo: DeletePatientOnlyAssignment
  ): Observable<boolean | Error> {
    return this._httpService
      .delete<{ success: boolean; body: string }>(
        EndpointType.Content,
        `content/patient/${deleteInfo.assignmentid}`
      )
      .pipe(
        map((res) => res.success),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
