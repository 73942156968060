import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EndpointType, HttpService } from 'src/app/core/services/http.service';

import {
  AddExclusionResponse,
  GetContentPackDetailResponse,
  GetContentPacksResponse,
  PackAssignmentInterface,
  PackAssignmentResponse,
  RemoveExclusionResponse,
  UnassignPackResponse
} from 'src/app/content/models/content-packs';

@Injectable()
export class PackAssignmentService {
  constructor(
    private _httpService: HttpService,
    private _error: ErrorHandler
  ) {}

  getContentPacks(): Observable<GetContentPacksResponse> {
    return this._httpService
      .get<GetContentPacksResponse>(EndpointType.Content, `content/pack`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getContentPackDetail(
    packId: number
  ): Observable<GetContentPackDetailResponse> {
    return this._httpService
      .get<GetContentPackDetailResponse>(
        EndpointType.Content,
        `content/pack/${packId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  assignPack(
    assignment: PackAssignmentInterface
  ): Observable<PackAssignmentResponse> {
    return this._httpService
      .post<PackAssignmentResponse>(
        EndpointType.Content,
        `content/pack/${assignment.packId}/assign`,
        {
          patientId: assignment.patientId,
          exclusions: assignment.exclusions
        }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addExclusion(
    assignment: PackAssignmentInterface
  ): Observable<AddExclusionResponse> {
    return this._httpService
      .post<AddExclusionResponse>(
        EndpointType.Content,
        `content/pack/${assignment.packId}/exclusion`,
        {
          patientId: assignment.patientId,
          exclusions: assignment.exclusions
        }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  removeExclusion(
    assignment: PackAssignmentInterface
  ): Observable<RemoveExclusionResponse> {
    return this._httpService
      .deleteWithBody<RemoveExclusionResponse>(
        EndpointType.Content,
        `content/pack/${assignment.packId}/exclusion`,
        {
          patientId: assignment.patientId,
          exclusions: assignment.exclusions
        }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  unassignPack(
    assignment: PackAssignmentInterface
  ): Observable<UnassignPackResponse> {
    const { patientId } = assignment;
    return this._httpService
      .deleteWithBody<UnassignPackResponse>(
        EndpointType.Content,
        `content/pack/${assignment.packId}/unassign`,
        {
          patientId
        }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
