import styled from '@emotion/styled';

export const ChatMessageWrapperStyled = styled.div`
  width: 100%;

  &.grid {
    display: grid;
    grid-template-columns: 10% 90%;
  }

  &.grid.chat-message-wrapper__left {
    grid-template-columns: 90% 10%;

    .profile-message {
      order: 3;
    }
  }
`;

export const ProfilePictureStyled = styled.div`
  order: 1;
  display: flex;
  align-items: flex-end;
`;

export const ChatMessageStyled = styled.div`
  order: 2;
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);

  &.chat-message-wrapper__left {
    background-color: ${({ theme }) => theme.colors.grayLight};
    border-radius: 10px 10px 10px 0;
  }

  &.chat-message-wrapper__right {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    border-radius: 10px 10px 0 10px;
  }

  .deleted-by {
    margin-top: 1rem;
    border-top: solid 1px #c7c7c7;
    padding-top: 12px;
  }

  .read-by {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    svg {
      font-size: 1rem;
      margin-right: 0.25rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: #4d525b;

  .timestamp {
    margin-left: auto;
  }
`;

export const Content = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
  margin-top: 1rem;
`;
