import { ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromSettings from '../../../settings/reducers';
import { ChangeContentTab, CreatePatientAssignedContent, DeletePatientAssignedContent, GetAllPatientAssignedContent, GetContentEntries, GetContentTypes, GetPatientAssignedContent, GetPatientOnlyAssignments, SetActiveContentType, SetAssignedContentFilter, SetContentSelectFilter, } from '../../actions/content-assignment.actions';
import { TabOptions } from '../../models/ContentPacks';
import * as fromContentAssignment from '../../reducers';
import { AssignPack, GetContentPacks, LockPackItem, RemoveExclusion, SelectActivePackId, UnlockPackItem, } from './../../actions/pack-assignment.actions';
var ContentAssignmentComponent = /** @class */ (function () {
    function ContentAssignmentComponent(_route, _navigationService, _store, change) {
        var _this = this;
        this._route = _route;
        this._navigationService = _navigationService;
        this._store = _store;
        this.change = change;
        this.config = {};
        // Subscriptions container
        this._subs = new Subscription();
        this.tabOptions = TabOptions;
        // fetch user assigned content and update types and entries
        this._store.dispatch(new GetContentPacks());
        this._store.dispatch(new GetContentEntries());
        this._store.dispatch(new GetContentTypes());
        this.patientId$ = this._route.params.pipe(map(function (params) { return +params.patientId; }));
        // check router for changes to target patient
        this._subs.add(this.patientId$.subscribe(function (id) {
            _this._patientId = id;
            _this._store.dispatch(new GetPatientAssignedContent(_this._patientId));
            _this._store.dispatch(new GetAllPatientAssignedContent(_this._patientId));
            _this._store.dispatch(new GetPatientOnlyAssignments({
                patientId: _this._patientId,
            }));
        }));
        // fetch text from store
        this.contentSwingText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentSwing')));
        this.contentPacks$ = this._store.pipe(select(fromContentAssignment.getContentPacks));
        this.activeTab$ = this._store.pipe(select(fromContentAssignment.getActiveContentTab));
        this.selectedPackId$ = this._store.pipe(select(fromContentAssignment.getActivePackId));
        this.selectedPack$ = this._store.pipe(select(fromContentAssignment.selectedPackWithAssignment));
        this.selectedPackDetail$ = this._store.pipe(select(fromContentAssignment.getSelectedPackDetail));
        this.selectedPackFilteredDetail$ = this._store.pipe(select(fromContentAssignment.getSelectedPackFilteredDetail));
        this.selectedFolderName$ = this._store.pipe(select(fromContentAssignment.getSelectdFolderName));
        this.lockedItems$ = this._store.pipe(select(fromContentAssignment.getLockedItems));
        // define observables to fetch data from store
        this.activeContentTypeId$ = this._store.pipe(select(fromContentAssignment.getActiveContentType));
        this.contentTypes$ = this._store.pipe(select(fromContentAssignment.getContentTypes));
        this.csFilterActive$ = this._store.pipe(select(fromContentAssignment.isContentSelectFilterActive));
        this.csFilterString$ = this._store.pipe(select(fromContentAssignment.getContentSelectFilterString));
        this.acFilterActive$ = this._store.pipe(select(fromContentAssignment.isAssignedContentFilterActive));
        this.acFilterString$ = this._store.pipe(select(fromContentAssignment.getAssignedContentFilterString));
        // Patient specific files
        this.isCategoryPatientOnly$ = this._store.pipe(select(fromContentAssignment.isCategoryPatientOnly));
        // define observables to pass filtered data to components
        this.csContent$ = this._store.pipe(select(fromContentAssignment.applySearchToFilteredCsContent));
        // fetch current patient
        this.currentPatient$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromContentAssignment.getPatientById(id)));
        }), filter(function (clinicPatientResponse) {
            return !!clinicPatientResponse && !!clinicPatientResponse.Patient;
        }), map(function (clinicPatientResponse) {
            return clinicPatientResponse.Patient;
        }));
        this.patientName$ = this.currentPatient$.pipe(map(function (patient) {
            return patient.FirstName + " " + patient.LastName;
        }));
    }
    ContentAssignmentComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    ContentAssignmentComponent.prototype.createContent = function () {
        this._navigationService.navigate(['content', 'list', 0]);
    };
    ContentAssignmentComponent.prototype.setActiveContentTypeId = function (contentTypeId) {
        this._store.dispatch(new SetActiveContentType(contentTypeId));
    };
    // content-select functions
    // ========================================
    ContentAssignmentComponent.prototype.addToPatientApp = function (item) {
        item.isTransferring = true;
        var userContent = {
            contententryheaderid: item.contentEntryHeaderId,
        };
        this._store.dispatch(new CreatePatientAssignedContent({
            assignment: userContent,
            patientId: this._patientId,
            patientContent: item,
        }));
    };
    ContentAssignmentComponent.prototype.setContentSelectFilter = function (str) {
        this._store.dispatch(new SetContentSelectFilter(str.toLowerCase()));
    };
    // assigned-content content-select functions
    // ========================================
    ContentAssignmentComponent.prototype.removeFromApp = function (_a) {
        var assignmentid = _a.assignmentid, contententryheaderid = _a.contententryheaderid;
        this._store.dispatch(new DeletePatientAssignedContent({
            assignmentid: assignmentid,
            contententryheaderid: contententryheaderid,
        }));
    };
    ContentAssignmentComponent.prototype.setAssignedContentFilter = function (str) {
        this._store.dispatch(new SetAssignedContentFilter(str.toLowerCase()));
    };
    ContentAssignmentComponent.prototype.selectTab = function (tab) {
        this._store.dispatch(new ChangeContentTab(tab));
    };
    ContentAssignmentComponent.prototype.selectActivePackId = function (id) {
        this._store.dispatch(new SelectActivePackId(id));
    };
    ContentAssignmentComponent.prototype.assignPack = function (packAssign) {
        // If the full pack was selected
        if (packAssign.pack) {
            packAssign.pack.isTransferring = true;
        }
        // If an item in the pack was selected
        if (packAssign.packItem) {
            packAssign.packItem.isTransferring = true;
        }
        this._store.dispatch(new AssignPack(packAssign));
    };
    ContentAssignmentComponent.prototype.toggleLockedItem = function (toggleLocked) {
        if (toggleLocked.isLocked) {
            this._store.dispatch(new UnlockPackItem(toggleLocked.id));
        }
        else {
            this._store.dispatch(new LockPackItem(toggleLocked.id));
        }
    };
    ContentAssignmentComponent.prototype.addPackItemToPatient = function (args) {
        this._store.dispatch(new RemoveExclusion(args));
    };
    ContentAssignmentComponent.prototype.removeExclusions = function (args) {
        this._store.dispatch(new RemoveExclusion(args));
    };
    return ContentAssignmentComponent;
}());
export { ContentAssignmentComponent };
