import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { List, ListItem, Paper } from '@material-ui/core';
import { usePatientsInfiniteQuery } from '@react/lib/api/hooks';
import { useLocaleDateTime } from '@react/lib/date';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Loading } from './Loading';
import { TextInput } from './TextInput';
export var PatientInput = function (_a) {
    var id = _a.id, label = _a.label, onChange = _a.onChange, _b = _a.defaultPatient, defaultPatient = _b === void 0 ? null : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c;
    var t = useTranslations().t;
    var inputRef = useRef(null);
    var _d = useState(''), inputValue = _d[0], setInputValue = _d[1];
    var debouncedInputValue = useDebouncedState(inputValue, 500);
    var _e = useState(false), autocompleteOpen = _e[0], setAutocompleteOpen = _e[1];
    var _f = useState(defaultPatient), selectedPatient = _f[0], setSelectedPatient = _f[1];
    var patientsResult = usePatientsInfiniteQuery({
        q: debouncedInputValue.length > 0 ? debouncedInputValue : undefined,
    });
    var localeDateTime = useLocaleDateTime();
    var data = patientsResult.data;
    function buildPatientLabel(patient) {
        if (!patient) {
            return null;
        }
        var patientLabel = patient.firstname + " " + patient.lastname;
        if (patient.patientidentifier) {
            patientLabel = patientLabel + " (" + patient.patientidentifier + ")";
        }
        if (patient.dateofbirth) {
            patientLabel = patientLabel + " - " + localeDateTime.format(new Date(patient.dateofbirth), 'PPP', {
                utc: true,
            });
        }
        return patientLabel;
    }
    function onInputChange(e) {
        setInputValue(e.target.value);
        if (e.target.value === '') {
            setSelectedPatient(null);
        }
    }
    function onInputFocus() {
        setAutocompleteOpen(true);
    }
    function onPatientClick(patient) {
        setSelectedPatient(patient);
        setInputValue(buildPatientLabel(patient) || '');
        if (inputRef.current) {
            inputRef.current.blur();
        }
        setAutocompleteOpen(false);
    }
    function onAutocompleteClose() {
        if (inputRef.current) {
            inputRef.current.blur();
        }
        setAutocompleteOpen(false);
    }
    function onAutocompleteScrollEnd() {
        if (patientsResult.hasNextPage &&
            !patientsResult.isFetchingNextPage &&
            !patientsResult.isLoading) {
            patientsResult.fetchNextPage();
        }
    }
    useEffect(function () {
        if (selectedPatient) {
            onPatientClick(selectedPatient);
        }
        onChange(selectedPatient);
    }, [selectedPatient]);
    return (React.createElement(StyledContainer, null,
        autocompleteOpen && (React.createElement("div", { className: "patientInput-clickAway", onClick: onAutocompleteClose })),
        React.createElement("div", { className: "patientInput-inputWrapper" },
            React.createElement(TextInput, { ref: inputRef, id: id, label: label, placeholder: t.PatientList.SearchPatients, fullWidth: true, value: inputValue, onChange: onInputChange, onFocus: onInputFocus, disabled: disabled }),
            autocompleteOpen && (React.createElement(Paper, { className: "patientInput-autocomplete", style: {
                    width: inputRef.current
                        ? inputRef.current.offsetWidth
                        : undefined,
                }, elevation: 8 },
                React.createElement(List, null, data &&
                    data.pages.map(function (page) {
                        return page.data.map(function (patient) { return (React.createElement(ListItem, { key: patient.id, className: 'patientInput-listItem', button: true, onClick: function () { return onPatientClick(patient); } }, buildPatientLabel(patient))); });
                    })),
                React.createElement(Waypoint, { onEnter: onAutocompleteScrollEnd }),
                patientsResult.isLoading ||
                    (patientsResult.isFetchingNextPage && React.createElement(Loading, null)))))));
};
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  .patientInput-clickAway {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n  }\n\n  .patientInput-inputWrapper {\n    position: relative;\n  }\n\n  .patientInput-autocomplete {\n    box-sizing: border-box;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    max-height: 200px;\n    overflow: auto;\n    padding-right: 2px;\n    z-index: 2;\n  }\n\n  .patientInput-listItem {\n    color: ", ";\n\n    &:hover {\n      background-color: ", ";\n    }\n  }\n\n  input.MuiInputBase-input:disabled {\n    background-color: #f7f7fb;\n  }\n"], ["\n  .patientInput-clickAway {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n  }\n\n  .patientInput-inputWrapper {\n    position: relative;\n  }\n\n  .patientInput-autocomplete {\n    box-sizing: border-box;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    max-height: 200px;\n    overflow: auto;\n    padding-right: 2px;\n    z-index: 2;\n  }\n\n  .patientInput-listItem {\n    color: ", ";\n\n    &:hover {\n      background-color: ", ";\n    }\n  }\n\n  input.MuiInputBase-input:disabled {\n    background-color: #f7f7fb;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.greyishBrown;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.paleGray;
});
var templateObject_1;
