import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IntersectionObserverDirective } from '../../../../app/core/directives/interesection-observer';
import { SimplePatient } from '../../../models/SimplePatient';
import { SortParams } from '../../interfaces/patient-list.component.interfaces';
import { PatientListColumnsTitles } from './patient-list.component.constants';

@Component({
  selector: 'portal-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnDestroy {
  @ViewChildren(IntersectionObserverDirective)
  isos: QueryList<IntersectionObserverDirective>;

  @Input() text: any;

  @Input() patients: SimplePatient[];

  @Input() loading: any;

  @Output() goto = new EventEmitter();

  @Output() reachedScrollEnd = new EventEmitter<void>();

  @Output()
  public sortChange = new EventEmitter<SortParams>();

  displayedColumns: string[] = [
    PatientListColumnsTitles.PatientIdentifier,
    PatientListColumnsTitles.FirstName,
    PatientListColumnsTitles.LastName,
    PatientListColumnsTitles.DateOfBirth,
    PatientListColumnsTitles.PatientAccountCreatedDateUtc,
  ];

  goToPatient(row) {
    this.goto.emit(row.Id);
  }

  onObserved() {
    this.reachedScrollEnd.emit();
  }

  ngOnDestroy(): void {
    this.detachObservers();
  }

  detachObservers() {
    this.isos.forEach((iso) => iso.DetachObserver());
  }

  attachObservers() {
    this.isos.forEach((iso) => iso.SetupObserver());
  }

  public get columnTitles(): typeof PatientListColumnsTitles {
    return PatientListColumnsTitles;
  }

  public onSortChange(sortParams: SortParams): void {
    if (sortParams.direction === '') {
      sortParams.active = '';
    }

    this.sortChange.next(sortParams);
  }
}
