import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Button as MaterialButton } from '@material-ui/core';
import * as React from 'react';
var StyledButton = styled(MaterialButton)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  text-transform: none;\n"], ["\n  text-transform: none;\n"])));
export var Button = React.forwardRef(function (_a, ref) {
    var children = _a.children, rest = tslib_1.__rest(_a, ["children"]);
    return (React.createElement(StyledButton, tslib_1.__assign({ ref: ref }, rest), children));
});
var templateObject_1;
