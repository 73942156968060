import { CircularProgress, Typography } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { LoaderWrapper, StyledKey, StyledList } from './index.styled';
export function List(_a) {
    var data = _a.data, isActive = _a.isActive, onClick = _a.onClick, id = _a.id, getLabel = _a.getLabel;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledList, null, data.map(function (item, index) {
            return (React.createElement("div", { className: "listItem " + (isActive(item) === true ? 'active' : ''), onClick: function () { return onClick(item); }, key: id + "-" + index },
                React.createElement(StyledKey, null,
                    React.createElement(Typography, null, getLabel(item)),
                    isActive(item) === true && React.createElement(Done, null))));
        }))));
}
export function InfiniteList(_a) {
    var data = _a.data, isActive = _a.isActive, onClick = _a.onClick, id = _a.id, getLabel = _a.getLabel, loadMore = _a.loadMore, loading = _a.loading;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledList, null,
            data.map(function (page) {
                return page.data.map(function (item, index) {
                    return (React.createElement("div", { className: "listItem " + (isActive(item) === true ? 'active' : ''), onClick: function () { return onClick(item); }, key: id + "-" + index },
                        React.createElement(StyledKey, null,
                            React.createElement(Typography, null, getLabel(item)),
                            isActive(item) === true && React.createElement(Done, null))));
                });
            }),
            loading && (React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                React.createElement(CircularProgress, null))),
            React.createElement(Waypoint, { onEnter: loadMore }))));
}
