import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import {
  ContentActionTypes,
  DeleteTemplate,
  DeleteTemplateSuccess,
  OpenDirectorySection,
  SavingTemplate,
  SavingTemplateError,
  SavingTemplateSuccess,
} from '../../actions/content.actions';
import { CreatePackComponent } from '../../components/create-pack/create-pack.component';
import { EditFolderNameComponent } from '../../components/edit-folder-name/edit-folder-name.component';
import { EditPackNameComponent } from '../../components/edit-pack-name/edit-pack-name.component';
import { Category } from '../../models/category';
import { ContentPack } from '../../models/content-packs';
import { ContentTemplate } from '../../models/content-template';
import * as fromContent from '../../reducers';
import { CreateCategoryComponent } from './../../components/create-category/create-category.component';

@Component({
  selector: 'portal-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss'],
})
export class TemplateEditComponent implements OnInit, OnDestroy {
  // Observables used for text / translations
  public directoryText$: Observable<any>;
  public templateEditText$: Observable<any>;
  public contentText$: Observable<any>;
  public categoryText$: Observable<any>;

  public contentText: any;
  public categoryText: any;

  // Observables used in content-directory
  public categories$: Observable<Category[]>;
  public templates$: Observable<ContentTemplate[]>;
  public packs$: Observable<ContentPack[]> = of([]);
  public categoryId$: Observable<number>;
  public routeType$: Observable<string> = of('template');

  // Observable to hand to template form
  public selectedTemplate$: Observable<ContentTemplate>;

  // Check if template has been saved
  public creatingTemplate$ = new BehaviorSubject<boolean>(false);
  public creatingTemplateSuccess$ = new BehaviorSubject<boolean>(false);
  public creatingTemplateError$ = new BehaviorSubject<boolean>(false);

  public openDirectory$: Observable<string>;

  private _subs = new Subscription();

  // Content editor flag
  public contentEditorEnabled$: Observable<boolean>;
  public contentEditorEnabled: boolean;

  // Global portal flag
  public isGlobalPortal$: Observable<boolean>;
  public isGlobalPortal: boolean;

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _updates$: Actions,
    private _store: Store<fromRoot.State>,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));

    // Content editor group Observables
    this.contentEditorEnabled$ = this._store.pipe(
      select(fromContent.getContentEditorEnabled),
    );

    // Set up text Observables
    this.directoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentDirectory')),
    );
    this.contentText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewContentList')),
    );
    this.templateEditText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateTemplate')),
    );
    this.categoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateCategory')),
    );

    this._subs.add(
      this.isGlobalPortal$.subscribe((value) => {
        this.isGlobalPortal = value;
      }),
    );

    // Content editor group, wrapped in a feature flag
    this._subs.add(
      this.contentEditorEnabled$.subscribe(
        (t) => (this.contentEditorEnabled = t),
      ),
    );

    // capture text for use in dialogs
    this._subs.add(this.contentText$.subscribe((t) => (this.contentText = t)));
    this._subs.add(
      this.categoryText$.subscribe((t) => (this.categoryText = t)),
    );

    // content-directory observables from Store and active route
    this.categories$ = this._store.pipe(
      select(fromContent.getGeneralCategories),
    );
    this.templates$ = this._store.pipe(select(fromContent.getTemplates));
    this.packs$ = this._store.pipe(select(fromContent.getPacks));
    this.categoryId$ = this._route.params.pipe(
      map((params: Params) => {
        return +params.id;
      }),
    );

    // template to hand to template form
    this.selectedTemplate$ = combineLatest(
      this.templates$,
      this.categoryId$,
    ).pipe(
      map(([templates, id]) => {
        return templates.find((t) => t.id === id);
      }),
    );

    // watch for successful delete
    // on delete navigate to main list of items.
    this._subs.add(
      this._updates$
        .pipe(
          ofType<DeleteTemplateSuccess>(
            ContentActionTypes.DeleteTemplateSuccess,
          ),
          map(() => {
            if (this.isGlobalPortal) {
              this.navigateTo('content/global');
            } else {
              this.navigateTo('content');
            }
          }),
        )
        .subscribe(),
    );

    // watch for template update errors
    this._subs.add(
      this._updates$
        .pipe(
          ofType<SavingTemplateError>(ContentActionTypes.SavingTemplateError),
          map(() => {
            this.creatingTemplate$.next(false);
            this.creatingTemplateError$.next(true);
            setTimeout(() => {
              this.creatingTemplateError$.next(false);
            }, 3000);
          }),
        )
        .subscribe(),
    );
    // Watch for successful template updates
    this._subs.add(
      this._updates$
        .pipe(
          ofType<SavingTemplateSuccess>(
            ContentActionTypes.SavingTemplateSuccess,
          ),
          map(() => {
            this.creatingTemplate$.next(false);
            this.creatingTemplateSuccess$.next(true);
            setTimeout(() => {
              this.creatingTemplateSuccess$.next(false);
            }, 1000);
          }),
        )
        .subscribe(),
    );

    // Which section of directory is open?
    this.openDirectory$ = this._store.pipe(
      select(fromContent.activeDirectorySection),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  deleteTemplate(id: number): void {
    this._store.dispatch(new DeleteTemplate(id));
  }

  saveTemplate(template: ContentTemplate): void {
    this._store.dispatch(new SavingTemplate(template));
  }

  // Functions to manage content directory
  navigateTo(url: string): void {
    this._navigationService.navigateByUrl(url);
  }

  createNewCategoryOrTemplate(): void {
    this._navigationService.navigate(['content', 'contentortemplate']);
  }

  openDirectorySection(section: string) {
    this._store.dispatch(new OpenDirectorySection(section));
  }

  public createNewFolder() {
    this.dialog.open(CreateCategoryComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public changeFolderName(category: Category) {
    this.dialog.open(EditFolderNameComponent, {
      data: {
        category,
        text: this.contentText,
      },
      width: '550px',
    });
  }

  public createNewPack() {
    this.dialog.open(CreatePackComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public editPackName(pack: ContentPack) {
    this.dialog.open(EditPackNameComponent, {
      data: {
        pack,
        text: this.contentText,
      },
      width: '550px',
    });
  }
}
