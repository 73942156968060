import { Omit } from '@material-ui/core';
import { environment } from 'src/environments/environment';
import { HttpClientBase, HttpClientBaseOptions } from './httpClientBase';

export interface SignedUrlResponse {
  url: string;
}

export type S3ClientOptions = Omit<HttpClientBaseOptions, 'baseUrl'>;

export class S3Client extends HttpClientBase {
  constructor(options: S3ClientOptions) {
    super({
      ...options,
      baseUrl: environment.api.s3Proxy.endpoint,
    });
  }

  public async getSignedUrl(url: string): Promise<SignedUrlResponse> {
    return this.fetch(`clinic/read`, {
      body: JSON.stringify({ url }),
      headers: { 'content-type': 'application/json' },
      method: 'POST',
    });
  }
}
