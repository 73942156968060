import { useCallbackDebounced } from '@react/lib/hooks/useCallbackDebounced';
import useStateWithRef from '@react/lib/hooks/useStateWithRef';
import { MediaDevice, Participant } from '@zoom/videosdk';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useZoom } from './useZoom';

// fixes error TS2339: Property 'permissions' does not exist on type 'Navigator'.
declare const navigator: Navigator & {
  permissions: {
    query({ name }: { name: string }): Promise<string>;
  };
};

function useIsMountedRef(): React.MutableRefObject<boolean> {
  const isMountedRef = useRef<boolean>(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
}

export function useVideoCall({
  onUserAdded: onUserAddedCallback,
  onUserUpdated: onUserUpdatedCallback,
  onUserRemoved: onUserRemovedCallback
}: {
  onUserAdded?: (participant: Participant, isCurrentUser: boolean) => void;
  onUserUpdated?: (participant: Participant, isCurrentUser: boolean) => void;
  onUserRemoved?: (participant: Participant, isCurrentUser: boolean) => void;
} = {}) {
  const isMountedRef = useIsMountedRef();
  const { zoomClient } = useZoom();
  const [cameraList, setCameraList] = useState<
    (MediaDevice & { active: boolean })[]
  >([]);
  const [microphoneList, setMicrophoneList] = useState<
    (MediaDevice & { active: boolean })[]
  >([]);
  const [speakerList, setSpeakerList] = useState<
    (MediaDevice & { active: boolean })[]
  >([]);
  const [participants, setParticipants, participantsRef] = useStateWithRef<
    Participant[]
  >([]);
  const [micOn, setMicOn, micOnRef] = useStateWithRef<boolean>(true);
  const [cameraOn, setCameraOn, cameraOnRef] = useStateWithRef<boolean>(true);
  const [currentUser, setCurrentUser, currentUserRef] =
    useStateWithRef<Participant>();
  const [joining, setJoining] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const initRef = useRef(false);
  const joinRef = useRef(false);
  const changeCamera = useCallbackDebounced(
    (deviceId: string) => {
      if (!zoomClient || !initRef.current || !joinRef.current) {
        return false;
      }
      const mediaStream = zoomClient.getMediaStream();
      const activeCamera = mediaStream.getActiveCamera();
      if (deviceId !== activeCamera) {
        mediaStream.switchCamera(deviceId);
        setCameraList((prevCameraList) =>
          prevCameraList.map((camera) => {
            if (camera.deviceId === deviceId) {
              return {
                ...camera,
                active: true
              };
            } else if (camera.active) {
              return {
                ...camera,
                active: false
              };
            } else {
              return camera;
            }
          })
        );
      }
    },
    [zoomClient, initRef, joinRef]
  );
  const changeMic = useCallbackDebounced(
    (deviceId: string) => {
      if (!zoomClient || !initRef.current || !joinRef.current) {
        return false;
      }
      const mediaStream = zoomClient.getMediaStream();
      const activeMic = mediaStream.getActiveMicrophone();
      if (deviceId !== activeMic) {
        mediaStream.switchMicrophone(deviceId);
        setMicrophoneList((prevMicrophoneList) =>
          prevMicrophoneList.map((microphone) => {
            if (microphone.deviceId === deviceId) {
              return {
                ...microphone,
                active: true
              };
            } else if (microphone.active) {
              return {
                ...microphone,
                active: false
              };
            } else {
              return microphone;
            }
          })
        );
      }
    },
    [zoomClient, initRef, joinRef]
  );
  const changeSpeaker = useCallbackDebounced(
    (deviceId: string) => {
      if (!zoomClient || !initRef.current || !joinRef.current) {
        return false;
      }
      const mediaStream = zoomClient.getMediaStream();
      const activeSpeaker = mediaStream.getActiveSpeaker();
      if (deviceId !== activeSpeaker) {
        mediaStream.switchSpeaker(deviceId);
        setSpeakerList((prevSpeakerList) =>
          prevSpeakerList.map((speaker) => {
            if (speaker.deviceId === deviceId) {
              return {
                ...speaker,
                active: true
              };
            } else if (speaker.active) {
              return {
                ...speaker,
                active: false
              };
            } else {
              return speaker;
            }
          })
        );
      }
    },
    [zoomClient, initRef, joinRef]
  );
  const toggleMic = useCallbackDebounced(() => {
    if (!zoomClient) {
      return false;
    }
    const mediaStream = zoomClient.getMediaStream();
    if (!micOnRef.current) {
      if (initRef.current && joinRef.current && mediaStream.isAudioMuted()) {
        mediaStream.unmuteAudio();
      }
      setMicOn(true);
    } else {
      if (initRef.current && joinRef.current && !mediaStream.isAudioMuted()) {
        mediaStream.muteAudio();
      }
      setMicOn(false);
    }
  }, [zoomClient, micOnRef, setMicOn]);
  const toggleCamera = useCallbackDebounced(() => {
    if (!zoomClient) {
      return false;
    }
    const mediaStream = zoomClient.getMediaStream();
    if (!cameraOnRef.current) {
      if (
        initRef.current &&
        joinRef.current &&
        !mediaStream.isCapturingVideo()
      ) {
        mediaStream.startVideo({
          mirrored: true
        });
      }
      setCameraOn(true);
    } else {
      if (
        initRef.current &&
        joinRef.current &&
        mediaStream.isCapturingVideo()
      ) {
        mediaStream.stopVideo();
      }
      setCameraOn(false);
    }
  }, [zoomClient, cameraOnRef, setCameraOn]);
  const onUserAdded = useCallback(
    (addedUsers: Participant[]) => {
      if (!joinRef.current || !currentUserRef.current) {
        return;
      }
      const currentUserId = currentUserRef.current.userId;
      const [[addedCurrentUser], addedParticipants] = _.partition(
        addedUsers,
        (participant) => participant.userId === currentUserId
      );
      if (addedCurrentUser) {
        const currentUserNext = {
          ...currentUserRef.current,
          ...addedCurrentUser
        };
        setCurrentUser(currentUserNext);
        if (onUserAddedCallback) {
          onUserAddedCallback(currentUserNext, true);
        }
      }
      if (addedParticipants.length) {
        const addedParticipantMap = addedParticipants.reduce<
          Record<string, Participant>
        >((memo, next) => {
          memo[next.userId] = next;
          return memo;
        }, {});
        const participantsNext = _.chain(participantsRef.current)
          .filter((participant) => !addedParticipantMap[participant.userId])
          .unionBy(addedParticipants, 'userId')
          .value();
        setParticipants(participantsNext);
        if (onUserAddedCallback) {
          for (const participant of addedParticipants) {
            onUserAddedCallback(participant, false);
          }
        }
      }
    },
    [
      setParticipants,
      setCurrentUser,
      currentUserRef,
      participantsRef,
      joinRef,
      onUserAddedCallback
    ]
  );
  const onUserUpdated = useCallback(
    (updatedUsers: Participant[]) => {
      if (!joinRef.current || !currentUserRef.current) {
        return;
      }
      const currentUserId = currentUserRef.current.userId;
      const [[currentUserUpdate], participantUpdates] = _.partition(
        updatedUsers,
        (participant) => participant.userId === currentUserId
      );
      if (currentUserUpdate) {
        const currentUserNext = {
          ...currentUserRef.current,
          ...currentUserUpdate
        };
        setCurrentUser(currentUserNext);
        if (onUserUpdatedCallback) {
          onUserUpdatedCallback(currentUserNext, true);
        }
      }
      if (participantUpdates.length) {
        const participantUpdateMap = participantUpdates.reduce<
          Record<string, Participant>
        >((memo, next) => {
          memo[next.userId] = next;
          return memo;
        }, {});
        const participantsNext = participantsRef.current.map((participant) => {
          if (participantUpdateMap[participant.userId]) {
            return {
              ...participant,
              ...participantUpdateMap[participant.userId]
            };
          }
          return participant;
        });
        setParticipants(participantsNext);
        const updatedParticipants = participantsNext.filter(
          (participant) => participantUpdateMap[participant.userId]
        );
        if (onUserUpdatedCallback) {
          for (const participant of updatedParticipants) {
            onUserUpdatedCallback(participant, false);
          }
        }
      }
    },
    [
      setParticipants,
      setCurrentUser,
      currentUserRef,
      participantsRef,
      joinRef,
      onUserUpdatedCallback
    ]
  );
  const onUserRemoved = useCallback(
    (removedUsers: Participant[]) => {
      if (!joinRef.current || !currentUserRef.current) {
        return;
      }
      const currentUserId = currentUserRef.current.userId;
      const [[currentUserRemoved], participantsRemoved] = _.partition(
        removedUsers,
        (participant) => participant.userId === currentUserId
      );
      if (currentUserRemoved) {
        setCurrentUser(undefined);
        if (onUserRemovedCallback) {
          onUserRemovedCallback(currentUserRef.current, true);
        }
      }
      if (participantsRemoved.length) {
        const participantsRemovedMap = participantsRemoved.reduce<
          Record<string, Participant>
        >((memo, next) => {
          memo[next.userId] = next;
          return memo;
        }, {});
        const [participantsNext, participantsRemovedNext] = _.partition(
          participantsRef.current,
          (participant) => !participantsRemovedMap[participant.userId]
        );
        setParticipants(participantsNext);
        if (onUserRemovedCallback) {
          for (const participant of participantsRemovedNext) {
            onUserRemovedCallback(participant, false);
          }
        }
      }
    },
    [
      setParticipants,
      setCurrentUser,
      currentUserRef,
      participantsRef,
      joinRef,
      onUserRemovedCallback
    ]
  );
  const onDeviceChange = useCallback(() => {
    if (!zoomClient) {
      return false;
    }
    const mediaStream = zoomClient.getMediaStream();
    if (mediaStream) {
      const activeCamera = mediaStream.getActiveCamera();
      const cameras = mediaStream.getCameraList();
      const mappedCameras = cameras.map((camera) => ({
        ...camera,
        active: camera.deviceId === activeCamera
      }));
      const activeMicrophone = mediaStream.getActiveMicrophone();
      const microphones = mediaStream.getMicList();
      const mappedMicrophones = microphones.map((microphone) => ({
        ...microphone,
        active: microphone.deviceId === activeMicrophone
      }));
      const activeSpeaker = mediaStream.getActiveSpeaker();
      const speakers = mediaStream.getSpeakerList();
      const mappedSpeakers = speakers.map((speaker) => ({
        ...speaker,
        active: speaker.deviceId === activeSpeaker
      }));
      setCameraList(mappedCameras);
      setMicrophoneList(mappedMicrophones);
      setSpeakerList(mappedSpeakers);
    }
  }, [zoomClient]);
  const init = useCallback(async () => {
    const [cameraPermission, microphonePermission] = await Promise.all([
      navigator.permissions && navigator.permissions.query
        ? navigator.permissions.query({
            name: 'camera'
          })
        : 'prompt',
      navigator.permissions && navigator.permissions.query
        ? navigator.permissions.query({
            name: 'microphone'
          })
        : 'prompt'
    ]);
    if (cameraPermission !== 'granted' || microphonePermission !== 'granted') {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .catch(() => {
          throw new Error('camera-permisisons-error');
        });
    }
    if (!zoomClient || initRef.current) {
      return false;
    }
    await zoomClient.init('en-US', `${window.location.origin}/assets/lib`, {
      webEndpoint: 'zoom.us',
      enforceMultipleVideos: { disableRenderLimits: true },
      enforceVirtualBackground: true,
      stayAwake: true,
      patchJsMedia: true,
      leaveOnPageUnload: true
    });
    initRef.current = true;
    return true;
  }, [zoomClient, initRef]);
  const join = useCallback(
    async ({
      room,
      token,
      password,
      name
    }: {
      room: string;
      token: string;
      password: string;
      name: string;
    }) => {
      if (joinRef.current) {
        return true;
      }
      setError(undefined);
      setJoining(true);
      const initialised = await init().catch((initError: Error) => {
        setError(initError.message);
        setJoining(false);
        throw initError;
      });
      if (!zoomClient || !initialised) {
        return false;
      }
      await zoomClient.leave().catch(_.noop);
      await zoomClient
        .join(room, token, name, password)
        .catch((joinError: Error) => {
          setError(joinError.message);
          setJoining(false);
          throw joinError;
        });
      const cleanup = async () => {
        if (zoomClient) {
          const mediaStream = zoomClient.getMediaStream();
          await mediaStream.stopVideo().catch(_.noop);
          await mediaStream.stopAudio().catch(_.noop);
          await zoomClient.leave().catch(_.noop);
          const [cameraPermission, microphonePermission] = await Promise.all([
            navigator.permissions && navigator.permissions.query
              ? navigator.permissions.query({
                  name: 'camera'
                })
              : 'prompt',

            navigator.permissions && navigator.permissions.query
              ? navigator.permissions.query({
                  name: 'microphone'
                })
              : 'prompt'
          ]);
          if (
            cameraPermission === 'granted' &&
            microphonePermission === 'granted'
          ) {
            await navigator.mediaDevices
              .getUserMedia({ video: true, audio: true })
              .then((stream) => {
                stream.getTracks().forEach((track) => track.stop());
              })
              .catch(_.noop);
          }
        }
      };
      const assertIsMounted = () => {
        if (!isMountedRef.current) {
          throw new Error('early-exit');
        }
      };
      const initialiseAfterJoining = async () => {
        const mediaStream = zoomClient.getMediaStream();
        if (cameraOnRef.current && !mediaStream.isCapturingVideo()) {
          await mediaStream
            .startVideo({
              mirrored: true
            })
            .catch(_.noop);
        }
        assertIsMounted();
        await mediaStream.startAudio().catch(_.noop);
        assertIsMounted();
        const microphoneOn = !mediaStream.isAudioMuted();
        if (!micOnRef.current && microphoneOn) {
          await mediaStream.muteAudio().catch(_.noop);
        }
        assertIsMounted();
        const currentUserInfo = zoomClient.getCurrentUserInfo();
        setCurrentUser(currentUserInfo);
        const allParticipants = (zoomClient.getAllUser() || []).filter(
          (participant) => participant.userId !== currentUserInfo.userId
        );
        setParticipants(allParticipants);
        if (onUserAddedCallback) {
          onUserAddedCallback(currentUserInfo, true);
          for (const participant of allParticipants) {
            onUserAddedCallback(participant, false);
          }
        }
        zoomClient.on('user-added', onUserAdded);
        zoomClient.on('user-removed', onUserRemoved);
        zoomClient.on('user-updated', onUserUpdated);
        zoomClient.on('device-change', onDeviceChange);
        const activeCamera = mediaStream.getActiveCamera();
        const cameras = mediaStream.getCameraList();
        const mappedCameras = cameras
          .filter((camera) => camera.deviceId)
          .map((camera) => ({
            ...camera,
            active: camera.deviceId === activeCamera
          }));
        const activeMicrophone = mediaStream.getActiveMicrophone();
        const microphones = mediaStream.getMicList();
        const mappedMicrophones = microphones
          .filter((microphone) => microphone.deviceId)
          .map((microphone) => ({
            ...microphone,
            active: microphone.deviceId === activeMicrophone
          }));
        const activeSpeaker = mediaStream.getActiveSpeaker();
        const speakers = mediaStream.getSpeakerList();
        const mappedSpeakers = speakers
          .filter((speaker) => speaker.deviceId)
          .map((speaker) => ({
            ...speaker,
            active: speaker.deviceId === activeSpeaker
          }));
        setCameraList(mappedCameras);
        setMicrophoneList(mappedMicrophones);
        setSpeakerList(mappedSpeakers);
        joinRef.current = true;
        setJoining(false);
        return true;
      };
      return initialiseAfterJoining().catch(async (initAfterJoiningError) => {
        await cleanup();
        if (initAfterJoiningError.message === 'early-exit') {
          return false;
        }
        throw initAfterJoiningError;
      });
    },
    [
      cameraOnRef,
      init,
      micOnRef,
      onDeviceChange,
      onUserAdded,
      onUserAddedCallback,
      onUserRemoved,
      onUserUpdated,
      setCurrentUser,
      setParticipants,
      zoomClient,
      isMountedRef
    ]
  );
  const leave = useCallback(async () => {
    if (!zoomClient || !joinRef.current) {
      return false;
    }
    await zoomClient.leave();
    const [cameraPermission, microphonePermission] = await Promise.all([
      navigator.permissions && navigator.permissions.query
        ? navigator.permissions.query({
            name: 'camera'
          })
        : 'prompt',

      navigator.permissions && navigator.permissions.query
        ? navigator.permissions.query({
            name: 'microphone'
          })
        : 'prompt'
    ]);
    if (cameraPermission === 'granted' && microphonePermission === 'granted') {
      await navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch(_.noop);
    }
    return true;
  }, [zoomClient]);
  useEffect(() => {
    if (!zoomClient) {
      return;
    }
    return () => {
      if (!zoomClient) {
        return;
      }
      zoomClient.off('user-added', onUserAdded);
      zoomClient.off('user-removed', onUserRemoved);
      zoomClient.off('user-updated', onUserUpdated);
      zoomClient.off('device-change', onDeviceChange);
    };
  }, [zoomClient, onUserAdded, onUserRemoved, onUserUpdated, onDeviceChange]);
  return {
    cameraList,
    cameraOn,
    changeCamera,
    changeMic,
    changeSpeaker,
    currentUser,
    join,
    joining,
    leave,
    micOn,
    microphoneList,
    participants,
    speakerList,
    toggleCamera,
    toggleMic,
    error
  };
}
