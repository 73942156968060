import { EventEmitter, } from '@angular/core';
var DrugsTableComponent = /** @class */ (function () {
    function DrugsTableComponent() {
        this.display = true;
        this.title = 'Medications Table';
        this.emptyMessage = '';
        this.medications = [];
        this.displayedColumns = [
            'name',
            'type',
            'drug',
            'dosage',
            'status',
        ];
        this.gotoPatient = new EventEmitter();
    }
    DrugsTableComponent.prototype.matIcon = function (status) {
        // query_builder = coming up = >100
        // error = overdue = 2
        var n = status;
        if (status >= 100) {
            n = 100;
        }
        var symbols = {
            100: 'query_builder',
            50: 'check_circle',
            2: 'error',
        };
        return symbols[n];
    };
    DrugsTableComponent.prototype.goto = function (row) {
        this.gotoPatient.emit(row.id);
    };
    return DrugsTableComponent;
}());
export { DrugsTableComponent };
