import { AfterViewInit, ElementRef, EventEmitter, OnInit, } from '@angular/core';
import { parseInteger, zeroPad } from '../../utils/generic';
var NumberInputComponent = /** @class */ (function () {
    function NumberInputComponent() {
        this.valueChanged = new EventEmitter();
        this.touchedChanged = new EventEmitter();
    }
    NumberInputComponent.prototype.ngOnInit = function () {
        this.widthCss = (this.maxLength || 2) * 1.1 + "ch";
    };
    NumberInputComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.inputEl) {
            this.inputEl.nativeElement.addEventListener('focus', function (e) {
                _this.initializeValue();
                _this.touchedChanged.emit();
            });
            if (this.initialValue != null) {
                this.setValue(this.initialValue);
            }
        }
    };
    NumberInputComponent.prototype.onIncrease = function () {
        if (!this.initializeValue()) {
            var nextValue = parseInteger(this.inputEl.nativeElement.value) +
                parseInteger(this.step);
            if (this.rotate && nextValue > parseInteger(this.max)) {
                nextValue = parseInteger(this.min);
            }
            this.setValue(nextValue);
            this.touchedChanged.emit();
        }
    };
    NumberInputComponent.prototype.onDecrease = function () {
        if (!this.initializeValue()) {
            var nextValue = parseInteger(this.inputEl.nativeElement.value) -
                parseInteger(this.step);
            if (this.rotate && nextValue < parseInteger(this.min)) {
                nextValue = parseInteger(this.max);
            }
            this.setValue(nextValue);
            this.touchedChanged.emit();
        }
    };
    NumberInputComponent.prototype.initializeValue = function () {
        if (this.inputEl.nativeElement.value == null ||
            this.inputEl.nativeElement.value.trim() === '') {
            this.setValue(parseInteger(this.min));
            return true;
        }
        return false;
    };
    NumberInputComponent.prototype.setValue = function (value) {
        if (this.zeroPad) {
            this.inputEl.nativeElement.value = zeroPad(value, parseInteger(this.maxLength));
        }
        else {
            this.inputEl.nativeElement.value = value;
        }
        this.valueChanged.emit(value);
    };
    return NumberInputComponent;
}());
export { NumberInputComponent };
