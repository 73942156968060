import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';
import { Button } from '@react/components/Button';
import DataRow from '@react/components/DataRow';
import { stringToBool } from '@react/lib';
import { UpdateAppointmentTypeRequest } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { StringBoolean } from '@react/types/global';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useAppointmentTypeMutation, useAppointmentTypeQuery } from '../hooks';
import {
  AppointmentTypeTitle,
  FormActions,
  LoaderWrapper,
  ProvisionalContent,
  ProvisionalHoursContainer,
  ProvisionalInput,
  StyledFormControl,
  StyledFormControlLabel,
  StyledRadio,
  TitleWrapper
} from './AppointmentType.styled';

const AppointmentTypeRow: React.FC<
  React.ComponentProps<typeof DataRow> & { first?: boolean }
> = ({ caption, children, first, label }) => {
  return (
    <>
      {!first && <Divider />}
      <CardContent>
        <DataRow label={label} caption={caption}>
          {children}
        </DataRow>
      </CardContent>
    </>
  );
};

type Inputs = {
  isActive: StringBoolean;
  isHidden: StringBoolean;
  convertToVideo: StringBoolean;
} & (
  | {
      isProvisional: 'false';
    }
  | {
      isProvisional: 'true';
      provisionalHours: number;
    }
);

const AppointmentType: React.VFC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const localeDateTime = useLocaleDateTime();
  const {
    control,
    errors,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    watch
  } = useForm<Inputs>();

  const { data, isLoading, isError } = useAppointmentTypeQuery({
    id: Number(id)
  });

  const {
    mutate: save,
    isError: isSavingError,
    isSuccess
  } = useAppointmentTypeMutation();

  useErrorToast(isError, {
    title: t.Admin.AppointmentTypeLoadingErrorToastHeader,
    message: t.Admin.AppointmentTypeLoadingErrorToastMessage
  });

  useErrorToast(isSavingError, {
    title: t.Admin.AppointmentTypeSavingErrorHeader,
    message: t.Admin.AppointmentTypeSavingErrorMessage
  });

  useSuccessToast(isSuccess, {
    title: t.Admin.AppointmentTypeSavingSuccessHeader,
    message: t.Admin.AppointmentTypeSavingSuccessMessage
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (!data) {
      throw new Error('No data available on submit');
    }

    const baseRequestData = {
      id: data.data.id,
      active: stringToBool(formData.isActive),
      displaytopatient: !stringToBool(formData.isHidden),
      video: stringToBool(formData.convertToVideo)
    };

    const requestData: UpdateAppointmentTypeRequest =
      formData.isProvisional === 'true'
        ? {
            ...baseRequestData,
            embargo: true,
            embargoperiod: formData.provisionalHours,
            embargoperiodname: 'Hour'
          }
        : {
            ...baseRequestData,
            embargo: false
          };

    save(requestData, {
      onSuccess: ({ data: updatedData }) => {
        // @ts-ignore
        reset({
          isActive: updatedData.active.toString(),
          isHidden: (!updatedData.displaytopatient).toString(),
          isProvisional: updatedData.embargo.toString(),
          convertToVideo: (
            updatedData.appointmenttype === 'video-call'
          ).toString(),
          provisionalHours:
            updatedData.embargoperiod != null
              ? updatedData.embargoperiod
              : undefined
        });
      }
    });
  };

  if (isLoading) {
    return (
      <Card>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </Card>
    );
  }

  if (isError || !data) {
    return <Card data-testid="error" />;
  }

  const { data: appointmentType } = data as NonNullable<typeof data>;
  const { isProvisional = appointmentType.embargo.toString() } = watch();

  return (
    <>
      <TitleWrapper>
        <IconButton component={Link} to="/admin/appointments" size="small">
          <IconArrowBack />
        </IconButton>
        <AppointmentTypeTitle variant="h5" role="heading">
          {appointmentType.name} - {appointmentType.internalsid}
        </AppointmentTypeTitle>
      </TitleWrapper>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <AppointmentTypeRow
            first
            label={t.Admin.AppointmentTypeHeaderId}
            caption={t.Admin.AppointmentTypeCaptionId}
          >
            <Typography variant="body1" className="value">
              {appointmentType.internalsid}
            </Typography>
          </AppointmentTypeRow>
          <AppointmentTypeRow
            label={t.Admin.AppointmentTypeHeaderCreatedAt}
            caption={t.Admin.AppointmentTypeCaptionCreatedAt}
          >
            <Typography variant="body1" className="value">
              {localeDateTime.format(
                new Date(appointmentType.salvecreatedutc),
                'PPP - p'
              )}
            </Typography>
          </AppointmentTypeRow>
          <AppointmentTypeRow
            label={t.Admin.AppointmentTypeHeaderActive}
            caption={t.Admin.AppointmentTypeCaptionActive}
          >
            {/* 
            // @ts-ignore TypeScript not new enough */}
            <StyledFormControl component="fieldset">
              <FormLabel component="legend" hidden>
                {t.Admin.AppointmentTypeHeaderActive}
              </FormLabel>
              <Controller
                as={
                  <RadioGroup data-testid="active">
                    <FormControlLabel
                      control={<StyledRadio size="small" />}
                      label={
                        <StyledFormControlLabel variant="body1">
                          {t.Admin.AppointmentTypeValueNo}
                        </StyledFormControlLabel>
                      }
                      value={'false'}
                    />
                    <FormControlLabel
                      control={<StyledRadio size="small" />}
                      label={
                        <StyledFormControlLabel variant="body1">
                          {t.Admin.AppointmentTypeValueYes}
                        </StyledFormControlLabel>
                      }
                      value={'true'}
                    />
                  </RadioGroup>
                }
                control={control}
                defaultValue={
                  appointmentType.active != null
                    ? appointmentType.active.toString()
                    : 'false'
                }
                name="isActive"
              />
            </StyledFormControl>
          </AppointmentTypeRow>
          <AppointmentTypeRow
            label={t.Admin.AppointmentTypeHeaderHidden}
            caption={t.Admin.AppointmentTypeCaptionHidden}
          >
            {/* 
            // @ts-ignore TypeScript not new enough */}
            <StyledFormControl component="fieldset">
              <FormLabel component="legend" hidden>
                {t.Admin.AppointmentTypeHeaderHidden}
              </FormLabel>
              <Controller
                as={
                  <RadioGroup data-testid="hidden">
                    <FormControlLabel
                      control={<StyledRadio size="small" />}
                      label={
                        <StyledFormControlLabel variant="body1">
                          {t.Admin.AppointmentTypeValueNo}
                        </StyledFormControlLabel>
                      }
                      value={'false'}
                    />
                    <FormControlLabel
                      control={<StyledRadio size="small" />}
                      label={
                        <StyledFormControlLabel variant="body1">
                          {t.Admin.AppointmentTypeValueYes}
                        </StyledFormControlLabel>
                      }
                      value={'true'}
                    />
                  </RadioGroup>
                }
                control={control}
                defaultValue={
                  appointmentType.displaytopatient != null
                    ? (!appointmentType.displaytopatient).toString()
                    : 'false'
                }
                name="isHidden"
              />
            </StyledFormControl>
          </AppointmentTypeRow>
          <AppointmentTypeRow
            label={t.Admin.AppointmentTypeHeaderProvisional}
            caption={t.Admin.AppointmentTypeCaptionProvisional}
          >
            <ProvisionalContent>
              {/* 
            // @ts-ignore TypeScript not new enough */}
              <StyledFormControl component="fieldset">
                <FormLabel component="legend" hidden>
                  {t.Admin.AppointmentTypeHeaderProvisional}
                </FormLabel>
                <Controller
                  as={
                    <RadioGroup data-testid="provisional">
                      <FormControlLabel
                        control={<StyledRadio size="small" />}
                        label={
                          <StyledFormControlLabel variant="body1">
                            {t.Admin.AppointmentTypeValueNo}
                          </StyledFormControlLabel>
                        }
                        value={'false'}
                      />
                      <FormControlLabel
                        control={<StyledRadio size="small" />}
                        label={
                          <StyledFormControlLabel variant="body1">
                            {t.Admin.AppointmentTypeValueYes}
                          </StyledFormControlLabel>
                        }
                        value={'true'}
                      />
                    </RadioGroup>
                  }
                  control={control}
                  defaultValue={appointmentType.embargo.toString()}
                  name="isProvisional"
                />
              </StyledFormControl>
              {isProvisional === 'true' ? (
                <>
                  <ProvisionalHoursContainer>
                    <Typography variant="body1">
                      {t.Admin.AppointmentTypeProvisionalHoursInputLabel1}
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={appointmentType.embargoperiod || ''}
                      name="provisionalHours"
                      render={({ onChange, ...rest }) => (
                        <ProvisionalInput
                          {...rest}
                          maxLength={3}
                          onChange={(e) => {
                            const numbers = e.target.value.replace(
                              /[^0-9]/g,
                              ''
                            );
                            onChange(numbers);
                          }}
                        />
                      )}
                      rules={{
                        min: {
                          message:
                            t.Admin
                              .AppointmentTypeProvisionalHoursInputErrorRequired,
                          value: 1
                        },
                        required: {
                          message:
                            t.Admin
                              .AppointmentTypeProvisionalHoursInputErrorRequired,
                          value: true
                        }
                      }}
                    ></Controller>
                    <Typography variant="body1">
                      {t.Admin.AppointmentTypeProvisionalHoursInputLabel2}
                    </Typography>
                  </ProvisionalHoursContainer>
                  {'provisionalHours' in errors &&
                  errors.provisionalHours != null ? (
                    <Typography color="secondary" variant="body2">
                      {errors.provisionalHours.message}
                    </Typography>
                  ) : null}
                </>
              ) : null}
            </ProvisionalContent>
          </AppointmentTypeRow>
          <AppointmentTypeRow
            label={t.Admin.AppointmentTypeHeaderVideoCall}
            caption={t.Admin.AppointmentTypeCaptionVideoCall}
          >
            {/* 
            // @ts-ignore TypeScript not new enough */}
            <StyledFormControl component="fieldset">
              <FormLabel component="legend" hidden>
                {t.Admin.AppointmentTypeHeaderVideoCall}
              </FormLabel>
              <Controller
                as={
                  <RadioGroup data-testid="video-call">
                    <FormControlLabel
                      control={<StyledRadio size="small" />}
                      label={
                        <StyledFormControlLabel variant="body1">
                          {t.Admin.AppointmentTypeValueNo}
                        </StyledFormControlLabel>
                      }
                      value={'false'}
                    />
                    <FormControlLabel
                      control={<StyledRadio size="small" />}
                      label={
                        <StyledFormControlLabel variant="body1">
                          {t.Admin.AppointmentTypeValueYes}
                        </StyledFormControlLabel>
                      }
                      value={'true'}
                    />
                  </RadioGroup>
                }
                control={control}
                defaultValue={(
                  appointmentType.appointmenttype === 'video-call'
                ).toString()}
                name="convertToVideo"
              />
            </StyledFormControl>
          </AppointmentTypeRow>
          {appointmentType.statusfilters.length > 0 && (
            <AppointmentTypeRow
              label={t.Admin.AppointmentTypeHeaderStatusFilters}
              caption={t.Admin.AppointmentTypeCaptionStatusFilters}
            >
              <Typography variant="body1" className="value">
                {appointmentType.statusfilters.join(', ')}
              </Typography>
            </AppointmentTypeRow>
          )}
        </Card>
        <FormActions>
          <Button
            color="primary"
            disabled={!isDirty || isSubmitting}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </FormActions>
      </form>
    </>
  );
};

export default AppointmentType;
