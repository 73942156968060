import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { LoadingError } from '@react/components/LoadingError';
import { useTranslations } from '@react/lib/i18n';
import { NoPermissionIcon } from '@react/pages/admin/components/NoPermissionIcon';
import get from 'lodash/get';
import React from 'react';
export var Unauthorised = function (_a) {
    var url = _a.url, text = _a.text, container = _a.container;
    var t = useTranslations().t;
    var buttonText = get(t, text);
    return (React.createElement(Wrapper, { container: container },
        React.createElement(PageContainer, null,
            React.createElement(LoadingErrorContainer, null,
                React.createElement(LoadingError, { icon: React.createElement(NoPermissionIcon, null), message: t.Admin.NoAccessMessage },
                    React.createElement(Button, { color: "primary", href: url, variant: "contained" }, buttonText))))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: ", ";\n"])), function (_a) {
    var container = _a.container;
    return (container ? '100%' : 'auto');
});
var PageContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  min-height: 100%;\n  width: 100%;\n  padding: ", "px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  min-height: 100%;\n  width: 100%;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var LoadingErrorContainer = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
