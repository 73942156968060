import styled from '@emotion/styled';
import { Box, ListItem, ListItemText } from '@material-ui/core';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { QueryObserverResult } from 'react-query';
import { ContentEntry } from 'src/app/content/models/content-entry';
import {
  ContentPackDetail,
  GetContentPackDetailResponse,
} from 'src/app/content/models/content-packs';
import { Empty } from './Empty';
import { ListingTooltip } from './ListingTooltip';
import { Loading } from './Loading';
import MessageEditorSearchField from './MessageEditorSearchField';

export interface ContentItem {
  type: 'pack' | 'folder';
  id: string | number;
  name: string;
}

interface Props {
  loading: boolean;
  mode: 'folder' | 'pack';
  folders: ContentEntry[] | undefined;
  packDetail: QueryObserverResult<GetContentPackDetailResponse>;
  onSelectContent: (contentItem: ContentItem) => void;
}

const ItemText = styled('span')`
  display: block;
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemRender = ({
  id,
  name,
  onClick,
}: {
  id: number;
  name: string;
  onClick: () => void;
}) => {
  return (
    <ListingTooltip title={name}>
      <ListItem button onClick={onClick} data-id={id}>
        <ListItemText>
          <ItemText>{name}</ItemText>
        </ListItemText>
      </ListItem>
    </ListingTooltip>
  );
};

const Message = ({ message }: { message: string }) => {
  return (
    <Box display="flex" flex={1} alignItems="center">
      <Empty message={message} />
    </Box>
  );
};

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const MessageAddContentLinkListing = ({
  loading,
  mode,
  folders,
  packDetail,
  onSelectContent,
}: Props) => {
  const {
    t: { Admin: adminI18n },
  } = useTranslations();
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const debouncedSearchTerm = useDebouncedState(searchInputValue, 500);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const Listing = () => {
    const emptyMessage = (
      <Message message={adminI18n.MessageContentSearchNoResults} />
    );
    const emptySearchListing = (
      <Message message={adminI18n.MessageContentEmptySearch} />
    );
    const searchTermLower = debouncedSearchTerm.trim().toLowerCase();

    const packDetailListing = (items: ContentPackDetail[]) => {
      const dataset =
        searchTermLower === ''
          ? items
          : items.filter(({ contentName }) => {
              return contentName.toLowerCase().includes(searchTermLower);
            });

      const renderDataSet = ({
        contentEntryHeaderId,
        contentName,
      }: ContentPackDetail) => {
        const onClick = () => {
          onSelectContent({
            type: 'pack',
            id: contentEntryHeaderId,
            name: contentName,
          });
        };

        return (
          <ItemRender
            id={contentEntryHeaderId}
            key={contentEntryHeaderId}
            name={contentName}
            onClick={onClick}
          />
        );
      };

      return (
        <>{dataset.length ? dataset.map(renderDataSet) : emptySearchListing}</>
      );
    };

    const subFolderListing = (
      items: (ContentEntry & { isEmpty: boolean })[],
    ) => {
      const dataset =
        searchTermLower === ''
          ? items.filter(
              ({ contentName, isEmpty }) => !!contentName && !isEmpty,
            )
          : items.filter(({ contentName, isEmpty }) => {
              return (
                !!contentName &&
                !isEmpty &&
                contentName.toLowerCase().includes(searchTermLower)
              );
            });

      const renderDataSet = (folder: ContentEntry) => {
        const id = (folder as any).contentEntryHeaderId;
        const name = folder.contentName || '';
        const onClick = () => {
          onSelectContent({ type: 'folder', id, name });
        };

        return <ItemRender id={id} key={id} name={name} onClick={onClick} />;
      };

      return (
        <>{dataset.length ? dataset.map(renderDataSet) : emptySearchListing}</>
      );
    };

    if (mode === 'folder' && folders) {
      return folders.length
        ? subFolderListing(folders as (ContentEntry & { isEmpty: boolean })[])
        : emptyMessage;
    }

    if (mode === 'pack' && packDetail.data) {
      return packDetail.data.contentPackDetail.length
        ? packDetailListing(packDetail.data.contentPackDetail)
        : emptyMessage;
    }

    return emptyMessage;
  };

  return (
    <Box minHeight="100%" display="flex" flexDirection="column">
      {(folders && folders.length) ||
      (packDetail.data && packDetail.data.contentPackDetail.length) ? (
        <MessageEditorSearchField
          value={searchInputValue}
          onChangeText={setSearchInputValue}
          placeholder={adminI18n.MessageContentSearchPlaceholder}
        />
      ) : null}

      {folders || packDetail.data ? (
        <Listing />
      ) : (
        <Message message={adminI18n.MessageContentSearchSelect} />
      )}
    </Box>
  );
};

export default MessageAddContentLinkListing;
