import { IconButton, MenuItem } from '@material-ui/core';
import { useEnvVariables } from '@react/lib/hooks';
import { useFetchMeWithClinics } from '@react/pages/conversations/hooks';
import React, { useRef, useState } from 'react';
import { StyledMenu } from '../Menu.styled';
import { StyledChevron, StyledLink, StyledTitle } from './index.styled';
var SwitchPortal = function (_a) {
    var clinicName = _a.clinicName, clinicToken = _a.clinicToken;
    var env = useEnvVariables();
    var result = useFetchMeWithClinics();
    var clinicUser = result.data;
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var menuRef = useRef(null);
    var _c = React.useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var open = Boolean(anchorEl);
    var handleOpen = function (event) {
        event.stopPropagation();
        setAnchorEl(menuRef.current);
        setMenuOpen(true);
    };
    var handleClose = function () {
        setAnchorEl(null);
        setMenuOpen(false);
    };
    var portalUrl = function (subdomain) {
        return "https://" + subdomain + "." + env.portalDomain;
    };
    var clinics = clinicUser && clinicUser.clinics;
    var clinicsWithoutCurrent = clinics && clinics.filter(function (clinic) { return clinic.token !== clinicToken; });
    if (!clinicUser ||
        !clinicsWithoutCurrent ||
        clinicsWithoutCurrent.length === 0) {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "navbar-brand" }, clinicName)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTitle, { className: "navbar-brand", onClick: handleOpen }, clinicName),
        React.createElement(IconButton, { "data-testid": "switch-portal-button", id: "switch-portal-button", "aria-controls": "switch-portal", "aria-expanded": open ? 'true' : undefined, "aria-haspopup": "true", size: "small", onClick: handleOpen },
            React.createElement(StyledChevron, { open: menuOpen })),
        React.createElement(StyledMenu, { "data-testid": "switch-portal-menu", id: "switch-portal-menu", "aria-labelledby": "switch-portal-button", anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }, getContentAnchorEl: null, PaperProps: {
                style: {
                    maxHeight: 300,
                    maxWidth: 300,
                },
            } }, clinicsWithoutCurrent.map(function (clinic) { return (React.createElement(MenuItem, { key: clinic.name },
            React.createElement(StyledLink, { role: "link", title: clinic.name, href: portalUrl(clinic.subdomain) }, clinic.name))); })),
        React.createElement("div", { ref: menuRef })));
};
var ɵ0 = SwitchPortal;
export default SwitchPortal;
export { ɵ0 };
