import { useApiClient } from '@react/lib/api';
import {
  StaffApiValue,
  StaffProfile,
  StaffProfiles,
} from '@react/lib/api/types';
import { useDispatch } from '@react/lib/store';
import React, { useEffect, useState } from 'react';
import { LoadStaffProfile } from 'src/app/core/actions/clinic.actions';
import { IFileUpload } from '../../core/services/s3.service';
import {
  PhotoSize,
  StyledDiv,
  StyledImg,
  StyledInitials,
  StyledWrapper,
} from './ProfilePhoto.styled';

export interface Props {
  clinicUserId?: number;
  fileUploadService: IFileUpload;
  size: PhotoSize;
  showInitials?: boolean;
  staffProfiles: StaffProfiles;
}

const defaultUrl = 'assets/imgs/nurse.jpeg';

const getInitials = (staff: StaffApiValue) => {
  const trimmedFirstName = (staff.firstname && staff.firstname.trim()) || '';
  const trimmedLastName = (staff.lastname && staff.lastname.trim()) || '';
  return `${trimmedFirstName.slice(0, 1)} ${trimmedLastName.slice(0, 1)}`;
};

const getCurrentStaffProfile = (staffProfiles: StaffProfiles, id?: number) =>
  id && staffProfiles && staffProfiles[id] ? staffProfiles[id] : undefined;

const hasAWSProfilePicture = (staff?: StaffProfile) =>
  Boolean(staff && staff.profilephotouri && staff.hasAWSPhoto);

const hasDisplayProfilePhoto = (
  showInitials: boolean,
  hasClinicUserId: boolean,
  staff?: StaffProfile,
) =>
  Boolean(
    (showInitials && !hasClinicUserId) ||
      !(showInitials && staff && !staff.hasAWSPhoto),
  );

export const ProfilePhoto: React.FC<Props> = ({
  clinicUserId,
  fileUploadService,
  size,
  showInitials = false,
  staffProfiles,
}) => {
  const client = useApiClient().portal;
  const dispatch = useDispatch();
  const [url, setUrl] = useState<string>(defaultUrl);

  const updateStaffProfiles = (staff: StaffProfile) =>
    dispatch(new LoadStaffProfile(staff));
  const setAWSProfileUrl = (staff?: StaffProfile) => {
    if (staff && staff.profilephotouri) {
      setUrl(staff.profilephotouri);
    }
  };

  const hasClinicUserId = Boolean(clinicUserId);
  const currentStaffProfile = getCurrentStaffProfile(
    staffProfiles,
    clinicUserId,
  );
  const canDisplayProfilePhoto = hasDisplayProfilePhoto(
    Boolean(showInitials),
    hasClinicUserId,
    currentStaffProfile,
  );

  const fetchProfilePhotoUrl = async () => {
    const fetch = clinicUserId
      ? client.fetchStaff(clinicUserId)
      : client.fetchStaffMe();

    fetch.then((staffResult) => {
      let profilePhotoUri: string | null = null;

      const { data } = staffResult;

      if (data) {
        updateStaffProfiles({
          ...data,
          hasAWSPhoto: false,
        });
        profilePhotoUri = data.profilephotouri;
      }

      if (!profilePhotoUri) {
        setUrl(defaultUrl);
        return;
      }

      const staffProfile = getCurrentStaffProfile(staffProfiles, data.id);
      if (staffProfile && staffProfile.profilephotouri) {
        setUrl(staffProfile.profilephotouri);
        return;
      }

      fileUploadService
        .readProfilePhotoFromBucket({
          url: profilePhotoUri,
        })
        .then((readResult) => {
          updateStaffProfiles({
            ...data,
            profilephotouri: readResult.url,
            hasAWSPhoto: true,
          });
          setUrl(readResult.url);
        });
    });
  };

  useEffect(() => {
    if (currentStaffProfile) {
      if (hasAWSProfilePicture(currentStaffProfile)) {
        setAWSProfileUrl(currentStaffProfile);
      }
    } else {
      fetchProfilePhotoUrl();
    }
  }, [currentStaffProfile]);

  const displayInitials = (staff: StaffProfile) => (
    <StyledInitials>{getInitials(staff)}</StyledInitials>
  );

  const displayPicture = () => <StyledImg alt="user-avatar" src={url} />;

  const displayProfile = () => {
    if (currentStaffProfile && !canDisplayProfilePhoto) {
      return displayInitials(currentStaffProfile);
    }
    return displayPicture();
  };

  return (
    <StyledWrapper size={size}>
      <StyledDiv size={size}>{displayProfile()}</StyledDiv>
    </StyledWrapper>
  );
};
