import { PerfectScrollbarConfigInterface, } from 'ngx-perfect-scrollbar';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { PatientMedicationsComponent } from './components/patient-medication/patient-medications.component';
import { MedicationsComponent } from './containers/medications/medications.component';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true,
};
// No Components being used
export var COMPONENTS = [
    MedicationsComponent,
    PatientListComponent,
    PatientMedicationsComponent,
];
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var MedicationsModule = /** @class */ (function () {
    function MedicationsModule() {
    }
    return MedicationsModule;
}());
export { MedicationsModule };
export { ɵ0 };
