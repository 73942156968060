import { useDispatch } from '@react/lib/store';
import { Conversation } from '@react/types';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { UpdateConversationCache } from 'src/app/messaging/actions/message-ui.actions';
import { EMPTY_CONVERSATION_STATE, TYPING_DELAY_MS } from '../constants';

export type IConversationCache = Record<number, string>;
export interface ConversationCache {
  getInitialConversationState: (messageId: Conversation['messageid']) => string;
  setConversationState: (messageId: number, message: string) => void;
}

export function useConversationCache(
  conversationCache: IConversationCache,
): ConversationCache {
  const dispatch = useDispatch();
  const [conversationsState, setConversationsState] =
    useState(conversationCache);

  useEffect(() => {
    setConversationsState(conversationCache);
  }, [conversationCache]);

  const setConversationState = (
    messageId: Conversation['messageid'],
    message: string,
  ) => {
    dispatch(
      new UpdateConversationCache({
        messageId,
        message,
      }),
    );
  };

  const debouncedSetConversationState = useCallback(
    debounce(setConversationState, TYPING_DELAY_MS),
    [dispatch, setConversationState],
  );

  const getInitialConversationState = useCallback(
    (messageId: Conversation['messageid']) =>
      conversationsState[messageId] || EMPTY_CONVERSATION_STATE,
    [conversationsState],
  );

  return {
    getInitialConversationState,
    setConversationState: debouncedSetConversationState,
  };
}
