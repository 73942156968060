import { DependencyList, useCallback, useRef } from 'react';

// leading debounce - callback is called immediately and then a delay is applied afterwards to prevent spam
export const useCallbackDebounced = <T extends (...args: any[]) => any>(
  fn: T,
  deps: DependencyList,
  delay = 300
): T => {
  const timerRef = useRef<NodeJS.Timer>();
  const resRef = useRef<ReturnType<T>>();
  const callback = (...args: Parameters<T>): ReturnType<T> => {
    if (!timerRef.current) {
      // @ts-expect-error This syntax requires an imported helper named '__spreadArray' which does not exist in 'tslib'. Consider upgrading your version of 'tslib'.ts(2343)
      resRef.current = fn(...args);
    } else {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      timerRef.current = undefined;
    }, delay);
    return resRef.current as ReturnType<T>;
  };

  return useCallback(callback, deps) as T;
};
