<div class="patient-documents-container" fxFlexFill fxLayout="row">
  <portal-content-select
    fxFlex="1 1 calc(130px)"
    [text]="contentSwingText$ | async"
    [filterActive]="csFilterActive$ | async"
    [filterString]="csFilterString$ | async"
    [patientOnly]="isCategoryPatientOnly$ | async"
    [patientOnlyContent]="inactivePatientOnlyItems$ | async"
    (setSearchString)="setContentSelectFilter($event)"
    (openFileDialog)="createNewFile()"
    (activatePatientOnlyFile)="setPatientFileToActive($event)"
    (deletePatientOnlyFile)="deletePatientFile($event)"
  >
  </portal-content-select>
  <portal-content-assigned
    fxFlex="1 1 calc(130px)"
    [text]="contentSwingText$ | async"
    [filterActive]="acFilterActive$ | async"
    [filterString]="acFilterString$ | async"
    [patientName]="patientName$ | async"
    [patientOnly]="isCategoryPatientOnly$ | async"
    [patientOnlyContent]="activePatientOnlyItems$ | async"
    (removePatientOnlyFile)="setPatientFileToInactive($event)"
  >
  </portal-content-assigned>
</div>
