import {
  CircularProgress,
  Popover,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  Button,
  Dialog,
  Empty,
  FilterChip,
  PopoverChip,
} from '@react/components';
import { SIDEStatus } from '@react/lib/api/enum';
import { IFilters, IKeyNumVal, IRecipeListItem } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useExportAdminList, useSIDERecipeListQuery } from '../../hooks';
import { DownloadLists } from '../../types';
import RecipeListFilters from './filters';
import { EmptyRecipeListIcon } from './icons/EmptyRecipeListIcon';
import {
  EmptyContainer,
  LoaderWrapper,
  StyledActionsContainer,
  StyledSearchBar,
  StyledTable,
  StyledTableContainer,
  StyledTableFooter,
  StyledTableHead,
  StyledTableRow,
} from './RecipeList.styled';
import SIDEStatusChip from './SIDEStatusChip';

const RecipeList: React.FunctionComponent = () => {
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const searchTerm = useDebouncedState(searchInputValue, 500);
  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isExportError, setExportError] = React.useState(false);
  const [filters, setFilters] = React.useState<IFilters>({});
  const [open, setOpen] = React.useState<boolean>(false);
  const filterPopoverAnchor = React.useRef<HTMLButtonElement>(null);

  const history = useHistory();
  const { t } = useTranslations();
  const localeDateTime = useLocaleDateTime();
  const onDownloadSuccess = () => setExportDialogOpen(false);
  const onDownloadFailure = () => {
    setExportError(true);
    setExportDialogOpen(false);
  };
  const { download, fileUrl, ref, fileName } = useExportAdminList({
    type: DownloadLists.SIDERecipes,
    onDownloadSuccess,
    onDownloadFailure,
  });

  async function saveToCSV() {
    const fileNameSuffix = `${t.Admin.SIDE.SIDEExport} ${localeDateTime.format(
      new Date(),
      'P',
    )}`;
    await download(fileNameSuffix);
  }

  function apply(localFilters: IFilters) {
    setFilters(localFilters);
    setOpen(false);
  }

  function deleteEventTypeFilter() {
    setFilters({
      ...filters,
      eventType: undefined,
    });
  }

  function deleteStatusFilter(item: keyof typeof SIDEStatus) {
    let status: Array<keyof typeof SIDEStatus> = Array.isArray(filters.status)
      ? Array.from(filters.status)
      : [];
    status = status.filter((key) => item !== key);

    setFilters({
      ...filters,
      status,
    });
  }

  function deleteAppointmentTypes(val: number) {
    let types: IKeyNumVal[] = Array.isArray(filters.appointmentTypes)
      ? Array.from(filters.appointmentTypes)
      : [];
    types = types.filter((type: IKeyNumVal) => type.value !== val);

    setFilters({
      ...filters,
      appointmentTypes: types,
    });
  }

  function deleteDrugTypes(val: number) {
    let types: IKeyNumVal[] = Array.isArray(filters.drugTypes)
      ? Array.from(filters.drugTypes)
      : [];
    types = types.filter((type: IKeyNumVal) => type.value !== val);

    setFilters({
      ...filters,
      drugTypes: types,
    });
  }

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
  } = useSIDERecipeListQuery({ searchTerm, filters });

  useErrorToast(isError, {
    title: t.Admin.SIDE.RecipeListLoadingErrorToastHeader,
    message: t.Admin.SIDE.RecipeListErrorToastMessage,
  });

  useErrorToast(
    isExportError,
    {
      title: t.Admin.SIDE.ExportErrorTitle,
      message: t.Admin.SIDE.ExportErrorDescription,
    },
    0,
  );

  function onScrollToEnd() {
    const shouldFetchNextPage =
      hasNextPage && !isLoading && !isFetchingNextPage;
    if (shouldFetchNextPage) {
      fetchNextPage();
    }
  }

  function onRowClick(id: number) {
    history.push(`/admin/side/recipes/${id}`);
  }

  const loading = isLoading || isFetchingNextPage;
  const showResults = !isLoading && !isError;
  const renderRecords = showResults && data && data.pages[0].data.length > 0;
  const isFiltered = Object.keys(filters).length > 0;

  return (
    <>
      <StyledSearchBar
        value={searchInputValue}
        onChange={setSearchInputValue}
        placeholder={t.Admin.SIDE.SearchBarPlaceholder}
      />
      <StyledActionsContainer>
        {renderRecords && (
          <>
            <Button
              data-testid="btn_export"
              color="primary"
              onClick={() => setExportDialogOpen(true)}
              variant="contained"
            >
              {t.Admin.SIDE.ExportCSV}
            </Button>
            <a href={fileUrl} download={fileName} hidden ref={ref} />

            <Dialog
              acceptButtonText={t.Admin.SIDE.ExportDialogDownload}
              cancelButtonText={t.Admin.SIDE.ExportDialogCancel}
              contentText={t.Admin.SIDE.ExportDialogDescription}
              onAccept={saveToCSV}
              onCancel={() => setExportDialogOpen(false)}
              isOpen={isExportDialogOpen}
              titleText={t.Admin.SIDE.ExportDialogTitle}
            />
          </>
        )}
        <Button
          data-testid="btn_filter"
          color="primary"
          variant="contained"
          disabled={!renderRecords && !isFiltered}
          ref={filterPopoverAnchor}
          onClick={() => setOpen(true)}
        >
          + {t.Admin.SIDE.AddFilter}
        </Button>
        <Popover
          id="filter-Popover"
          open={open}
          anchorEl={filterPopoverAnchor.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <RecipeListFilters filters={filters} apply={apply} />
        </Popover>

        {/* Chips */}
        {/* Event Type */}
        {filters.eventType && (
          <FilterChip
            label={t.Admin.SIDE[`${filters.eventType}Event`]}
            onDelete={deleteEventTypeFilter}
          />
        )}

        {/* Status */}
        {Array.isArray(filters.status) &&
          filters.status.map((item) => (
            <FilterChip
              label={t.Admin.SIDE[`${item}Status`]}
              onDelete={() => deleteStatusFilter(item)}
            />
          ))}

        {/* Appointment Types */}
        {Array.isArray(filters.appointmentTypes) &&
          filters.appointmentTypes.length > 0 && (
            <PopoverChip
              label={t.Admin.SIDE.FilterTabAppointmentType}
              data={filters.appointmentTypes}
              onDelete={deleteAppointmentTypes}
              clearAll={() =>
                setFilters({
                  ...filters,
                  appointmentTypes: undefined,
                })
              }
            />
          )}

        {/* Drug Types */}
        {Array.isArray(filters.drugTypes) && filters.drugTypes.length > 0 && (
          <PopoverChip
            label={t.Admin.SIDE.FilterTabDrugType}
            data={filters.drugTypes}
            onDelete={deleteDrugTypes}
            clearAll={() =>
              setFilters({
                ...filters,
                drugTypes: undefined,
              })
            }
          />
        )}
      </StyledActionsContainer>

      {loading && (
        <EmptyContainer>
          <LoaderWrapper data-testid="Filtered-Loader">
            <CircularProgress />
          </LoaderWrapper>
        </EmptyContainer>
      )}

      {!renderRecords && !loading && (
        <EmptyContainer>
          <Empty
            data-testid="empty_state"
            icon={<EmptyRecipeListIcon />}
            message={t.Admin.SIDE.RecipeListEmptyMessage}
          />
        </EmptyContainer>
      )}
      {renderRecords && (
        <StyledTableContainer>
          <StyledTable>
            <StyledTableHead>
              <TableRow>
                <TableCell className="idcol">
                  {t.Admin.SIDE.RecipeListTableHeaderId}
                </TableCell>
                <TableCell className="contentcol">
                  {t.Admin.SIDE.RecipeListTableHeaderName}
                </TableCell>
                <TableCell className="fixedcol">
                  {t.Admin.SIDE.RecipeListTableHeaderAction}
                </TableCell>
                <TableCell>
                  {t.Admin.SIDE.RecipeListTableHeaderEventType}
                </TableCell>
                <TableCell>
                  {t.Admin.SIDE.RecipeListTableHeaderStatus}
                </TableCell>
                <TableCell>
                  {t.Admin.SIDE.RecipeListTableHeaderCreatedOn}
                </TableCell>
                <TableCell>
                  {t.Admin.SIDE.RecipeListTableHeaderLastUpdatedOn}
                </TableCell>
                <TableCell>
                  {t.Admin.SIDE.RecipeListTableHeaderLastUpdatedBy}
                </TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {data.pages.map((page) =>
                page.data.map((item: IRecipeListItem) => (
                  <StyledTableRow
                    key={item.id}
                    onClick={() => onRowClick(item.id)}
                    data-testid="TableRow"
                    role="button"
                    tabIndex={0}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {item.contentcount +
                        item.messagecount +
                        item.contentpackcount}
                    </TableCell>
                    <TableCell>
                      {t.Admin.SIDE[`${item.eventname}Event`]}
                    </TableCell>
                    <TableCell>
                      <SIDEStatusChip
                        status={item.status}
                        label={t.Admin.SIDE[`${item.status}Status`]}
                      />
                    </TableCell>
                    <TableCell>
                      {localeDateTime.format(new Date(item.createdon), 'PP, p')}
                    </TableCell>
                    <TableCell>
                      {localeDateTime.format(
                        new Date(item.lastmodifiedon),
                        'PP, p',
                      )}
                    </TableCell>
                    <TableCell>{item.lastupdatedby}</TableCell>
                  </StyledTableRow>
                )),
              )}
            </TableBody>
            <StyledTableFooter>
              <TableRow>
                {loading && (
                  <TableCell colSpan={7}>
                    <LoaderWrapper data-testid="Loader">
                      <CircularProgress />
                    </LoaderWrapper>
                  </TableCell>
                )}
              </TableRow>
            </StyledTableFooter>
          </StyledTable>
        </StyledTableContainer>
      )}
      <Waypoint onEnter={onScrollToEnd} />
    </>
  );
};

export default RecipeList;
