import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EffectsCompletedAction, VideoCallActionTypes } from '../actions/video-call.actions';
import { VideoCallEffectsResposeType } from '../responses';
import { VideoCallsService } from '../services/video-calls.service';
var checkForNoPartnerError = function (error) {
    return error && error.error && error.error.code === 'NOLINKEDPARTNER';
};
var ɵ0 = checkForNoPartnerError;
var VideoCallEffects = /** @class */ (function () {
    function VideoCallEffects(actions$, store, _videoCallsService, _error) {
        this.actions$ = actions$;
        this.store = store;
        this._videoCallsService = _videoCallsService;
        this._error = _error;
    }
    VideoCallEffects.prototype.updateVideoCall$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.UpdateVideoCall), switchMap(function (action) {
            return _this._videoCallsService.updateVideoCall(action.payload).pipe(map(function (response) {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.UpdateSuccess,
                    data: {
                        requestData: action.payload,
                        responseData: response.data
                    },
                    error: null
                });
            }), catchError(function (error) {
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.UpdateError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    VideoCallEffects.prototype.deleteVideoCall = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.DeleteVideoCall), switchMap(function (action) {
            return _this._videoCallsService.deleteVideoCall(action.payload.id).pipe(map(function (response) {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.DeleteSuccess,
                    data: response.data,
                    error: null
                });
            }), catchError(function (error) {
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.DeleteError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    VideoCallEffects.prototype.joinVideoCall = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.Join), switchMap(function (action) {
            return _this._videoCallsService
                .joinVideoCall(action.payload.videoCall.id)
                .pipe(map(function (response) {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.JoinSuccess,
                    data: {
                        videoCall: action.payload.videoCall,
                        partnerInviteEnabled: action.payload.partnerInviteEnabled,
                        videoCallsInvitePartner: action.payload.videoCallsInvitePartner,
                        response: response.data
                    },
                    error: null
                });
            }), catchError(function (error) {
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.JoinError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    VideoCallEffects.prototype.invitePartnerToVideoCall$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.InvitePartnerToVideoCall), switchMap(function (action) {
            return _this._videoCallsService.updateVideoCall(action.payload).pipe(map(function () {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.InviteSuccess,
                    data: {
                        requestData: action.payload
                    },
                    error: null
                });
            }), catchError(function (error) {
                if (checkForNoPartnerError(error)) {
                    return of(new EffectsCompletedAction({
                        type: VideoCallEffectsResposeType.NoPartnerFoundError,
                        data: null,
                        error: error
                    }));
                }
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.InviteError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    VideoCallEffects.prototype.unInvitePartnerToVideoCall$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.UnInvitePartnerToVideoCall), switchMap(function (action) {
            return _this._videoCallsService.updateVideoCall(action.payload).pipe(map(function () {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.UnInviteSuccess,
                    data: {
                        requestData: action.payload
                    },
                    error: null
                });
            }), catchError(function (error) {
                if (checkForNoPartnerError(error)) {
                    return of(new EffectsCompletedAction({
                        type: VideoCallEffectsResposeType.NoPartnerFoundError,
                        data: null,
                        error: error
                    }));
                }
                if (error && error.error && error.error.code === 'CALLACTIVE') {
                    return of(new EffectsCompletedAction({
                        type: VideoCallEffectsResposeType.UnInviteActiveCallError,
                        data: null,
                        error: error
                    }));
                }
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.UnInviteError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    VideoCallEffects.prototype.invitePartnerInCallToVideoCall$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.InvitePartnerInCallToVideoCall), switchMap(function (action) {
            return _this._videoCallsService.updateVideoCall(action.payload).pipe(map(function () {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.InviteInCallSuccess,
                    data: {
                        requestData: action.payload
                    },
                    error: null
                });
            }), catchError(function (error) {
                if (checkForNoPartnerError(error)) {
                    return of(new EffectsCompletedAction({
                        type: VideoCallEffectsResposeType.NoPartnerFoundError,
                        data: null,
                        error: error
                    }));
                }
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.InviteInCallError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    VideoCallEffects.prototype.unInvitePartnerInCallToVideoCall$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(VideoCallActionTypes.UnInvitePartnerInCallToVideoCall), switchMap(function (action) {
            return _this._videoCallsService.updateVideoCall(action.payload).pipe(map(function () {
                return new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.UnInviteInCallSuccess,
                    data: {
                        requestData: action.payload
                    },
                    error: null
                });
            }), catchError(function (error) {
                if (checkForNoPartnerError(error)) {
                    return of(new EffectsCompletedAction({
                        type: VideoCallEffectsResposeType.NoPartnerFoundError,
                        data: null,
                        error: error
                    }));
                }
                return of(new EffectsCompletedAction({
                    type: VideoCallEffectsResposeType.UnInviteInCallError,
                    data: null,
                    error: error
                }));
            }));
        }));
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], VideoCallEffects.prototype, "updateVideoCall$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], VideoCallEffects.prototype, "deleteVideoCall", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], VideoCallEffects.prototype, "joinVideoCall", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], VideoCallEffects.prototype, "invitePartnerToVideoCall$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], VideoCallEffects.prototype, "unInvitePartnerToVideoCall$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], VideoCallEffects.prototype, "invitePartnerInCallToVideoCall$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], VideoCallEffects.prototype, "unInvitePartnerInCallToVideoCall$", null);
    return VideoCallEffects;
}());
export { VideoCallEffects };
export { ɵ0 };
