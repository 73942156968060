import * as React from 'react';
import { createContext, useContext } from 'react';
export var DispatchContext = createContext(function (action) { return action; });
export var DispatchProvider = function (_a) {
    var children = _a.children, dispatch = _a.dispatch;
    return React.createElement(DispatchContext.Provider, { value: dispatch, children: children });
};
export function useDispatch() {
    return useContext(DispatchContext);
}
