<mat-card class="content-list-container">
  <div fxLayout="row" fxLayoutAlign="start stretch" fxFill>
    <portal-content-directory
      [text]="directoryText$ | async"
      [categories]="categories$ | async"
      [templates]="templates$ | async"
      [packs]="packs$ | async"
      [activeSection]="routeType$ | async"
      [selectedId]="categoryId$ | async"
      [activeDirectory]="openDirectory$ | async"
      [contentEditorEnabled]="contentEditorEnabled"
      (goToPage)="navigateTo($event)"
      (createFolder)="createNewFolder()"
      (editFolderName)="changeFolderName($event)"
      (openSection)="openDirectorySection($event)"
      (createPack)="createNewPack()"
      (editPackName)="editPackName($event)"
      [isGlobalPortal]="isGlobalPortal$ | async"
    >
    </portal-content-directory>
    <div fxFlex="grow" class="list-container">
      <portal-content-list-controls
        [text]="sectionText$ | async"
        [filterActive]="isFilterActive$ | async"
        [filter]="currentFilter$ | async"
        [contentType]="activeCategory$ | async"
        [categoryId]="categoryId$ | async"
        [listType]="'content-list'"
        [contentEditorEnabled]="contentEditorEnabled"
        (setFilter)="updateFilter($event)"
        (addNew)="createEntry($event)"
      >
      </portal-content-list-controls>
      <portal-content-list-table
        [categoryId]="categoryId$ | async"
        [text]="sectionText$ | async"
        [content]="contentToDisplay$ | async"
        [filterActive]="isFilterActive$ | async"
        [isMultiLingual]="isMultiLingual$"
        [category]="activeCategoryInfo$ | async"
        [contentEditorEnabled]="contentEditorEnabled"
        (createFolder)="createNewFolder()"
        (goto)="goToContent($event)"
        (deleteAllSelected)="deleteEntries($event)"
        (moveAllSelected)="moveEntries($event)"
        (deleteCategory)="deleteCategory($event)"
        (viewChildren)="openViewChildrenModal($event)"
        [isGlobalPortal$]="isGlobalPortal$"
      >
      </portal-content-list-table>
    </div>
  </div>
</mat-card>
