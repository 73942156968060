import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import * as fromRoot from '../../../reducers';
import {
  ContentActionTypes,
  MoveEntriesError,
} from '../../actions/content.actions';
import { MoveEntries, MoveEntriesSuccess } from '../../actions/content.actions';
import { Category } from '../../models/category';

@Component({
  selector: 'portal-move-entries',
  templateUrl: './move-entries.component.html',
  styleUrls: ['./move-entries.component.scss'],
})
export class MoveEntriesComponent implements OnInit, OnDestroy {
  public movingEntries$ = new BehaviorSubject(false);
  public movedEntries$ = new BehaviorSubject(false);
  public errorEncountered$ = new BehaviorSubject(false);

  public selectedCategory: Category;
  public categories = new FormControl('', [Validators.required]);

  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates: Actions,
    public dialogRef: MatDialogRef<MoveEntriesComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this._subs.add(
      this.categories.valueChanges.subscribe(
        (c) => (this.selectedCategory = c),
      ),
    );
    this._subs.add(
      this._updates
        .pipe(ofType<MoveEntriesSuccess>(ContentActionTypes.MoveEntriesSuccess))
        .subscribe(() => {
          this.movingEntries$.next(false);
          this.movedEntries$.next(true);
          this.exitSuccessfully();
        }),
    );
    this._subs.add(
      this._updates
        .pipe(ofType<MoveEntriesError>(ContentActionTypes.MoveEntriesError))
        .subscribe(() => {
          this.movingEntries$.next(false);
          this.errorEncountered$.next(true);
          this.exitOnError();
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  onMoveClick() {
    this.movingEntries$.next(true);
    const newCategoryId = this.selectedCategory.id;
    this._store.dispatch(
      new MoveEntries({
        oldCategoryId: this.data.currentCategoryId,
        entries: this.data.entries,
        newCategoryId,
      }),
    );
  }

  exitSuccessfully() {
    setTimeout(() => this.dialogRef.close(true), 1000);
  }

  exitOnError() {
    setTimeout(() => this.dialogRef.close(false), 1000);
  }
}
