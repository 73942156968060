import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
var ContentService = /** @class */ (function () {
    function ContentService(_error, _httpService) {
        this._error = _error;
        this._httpService = _httpService;
    }
    ContentService.prototype.checkFileByMD5 = function (md5) {
        return this._httpService
            .get(EndpointType.Content, "content/files/md5", {
            checksum: md5
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.getFilesByName = function (fileName) {
        return this._httpService
            .get(EndpointType.Content, "content/files/name", {
            documentname: fileName
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.updateFileInStorage = function (file) {
        return this._httpService
            .put(EndpointType.Content, "content/files/" + file.id, tslib_1.__assign({}, file, { filesize: +file.filesize }))
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.createFileInStorage = function (file) {
        return this._httpService
            .post(EndpointType.Content, "content/files", file)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // CRUD for Templates
    // ===================================================
    // Read
    ContentService.prototype.getTemplates = function () {
        return this._httpService
            .get(EndpointType.Content, "content/templates")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.getTemplateById = function (id) {
        return this._httpService
            .get(EndpointType.Content, "content/templates/" + id)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.deleteTemplate = function (id) {
        return this._httpService
            .delete(EndpointType.Content, "content/templates/" + id)
            .pipe(map(function (res) { return true; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.amendTemplate = function (template) {
        return this._httpService
            .put(EndpointType.Content, "content/templates/" + template.id, template)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // TODO: This will throw a back end error when using the same name
    ContentService.prototype.createTemplate = function (template) {
        return this._httpService
            .post(EndpointType.Content, "content/templates", template)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // TODO: This seems not be used
    ContentService.prototype.checkTemplateName = function (name) {
        return this._httpService
            .get(EndpointType.Content, "content/templates/name", {
            name: name
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // CRUD for Categories
    // ===================================================
    // Read
    ContentService.prototype.getContentCategories = function () {
        return this._httpService
            .get(EndpointType.Content, "content/categories")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Get category
    ContentService.prototype.getContentCategory = function (id) {
        return this._httpService
            .get(EndpointType.Content, "content/categories/" + id)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Delete
    ContentService.prototype.deleteCategory = function (id) {
        return this._httpService
            .delete(EndpointType.Content, "content/categories/" + id)
            .pipe(map(function (res) { return true; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Update
    // https://bitbucket.org/salvedevelopment/salve.content-api-v1/src/master/routes/content-categories.js
    ContentService.prototype.amendCategory = function (category) {
        var id = category.id, rest = tslib_1.__rest(category, ["id"]);
        return this._httpService
            .put(EndpointType.Content, "content/categories/" + id, rest)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Create
    ContentService.prototype.createCategory = function (category) {
        return this._httpService
            .post(EndpointType.Content, "content/categories", category)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.checkCategoryName = function (name) {
        return this._httpService
            .get(EndpointType.Content, "content/categories/name", { name: name })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // CRUD for ContentEntries
    // ======================================
    // Create
    ContentService.prototype.createEntry = function (entry) {
        return this._httpService
            .post(EndpointType.Content, "content/entries", entry)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Read
    ContentService.prototype.getContentEntryHeaders = function () {
        return this._httpService
            .get(EndpointType.Content, "content/groupedentries")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Read By Id
    ContentService.prototype.getContentEntryById = function (id) {
        return this._httpService
            .get(EndpointType.Content, "content/entries/" + id)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Update
    ContentService.prototype.amendEntry = function (contentEntry) {
        return this._httpService
            .put(EndpointType.Content, "content/entries/" + contentEntry.id, contentEntry)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Delete
    ContentService.prototype.deleteEntry = function (id) {
        return this._httpService
            .delete(EndpointType.Content, "content/entries/" + id)
            .pipe(map(function (res) { return true; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.moveEntries = function (body) {
        return this._httpService
            .put(EndpointType.Content, "content/entries/move", body)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.deleteEntries = function (body) {
        return this._httpService
            .deleteWithBody(EndpointType.Content, "content/entries/delete", body)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.checkContentName = function (name) {
        return this._httpService
            .get(EndpointType.Content, "content/entries/name", {
            name: name
        })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Check patient file names
    ContentService.prototype.getPatientDocsByName = function (fileName, patientId) {
        return this._httpService
            .get(EndpointType.Content, "content/patient/" + patientId + "/name", { documentname: fileName })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Main content grouped by categories
    ContentService.prototype.getGroupedEntryList = function (language) {
        return this._httpService
            .get(EndpointType.Content, "content/groupedentrylist", language != null ? { language: language } : undefined)
            .pipe(map(function (res) { return res.data; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Check for parent global portal
    ContentService.prototype.getGlobalPortal = function () {
        return this._httpService
            .get(EndpointType.Content, "content/global/get-parent-clinic")
            .pipe(map(function (res) { return res.data; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Main global content grouped by categories
    ContentService.prototype.getGlobalPortalGroupedEntryList = function (globalPortalId, language) {
        return this._httpService
            .get(EndpointType.Content, "content/global/" + globalPortalId + "/grouped-entry-list", language != null ? { language: language } : undefined)
            .pipe(map(function (res) { return res.data; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    ContentService.prototype.getGlobalPortalGroupedFileEntryList = function (globalPortalId, language) {
        return this._httpService
            .get(EndpointType.Content, "content/global/" + globalPortalId + "/grouped-file-entry-list", language != null ? { language: language } : undefined)
            .pipe(map(function (res) { return res.data; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    // Read By Id
    ContentService.prototype.getGlobalContentEntryById = function (globalPortalId, id) {
        return this._httpService
            .get(EndpointType.Content, "content/global/" + globalPortalId + "/entries/" + id)
            .pipe(take(1));
    };
    return ContentService;
}());
export { ContentService };
