import { Injectable } from '@angular/core';

const MetaMessagePrefix = '_meta:';

export interface NameMetaMessage {
  identity: string;
  name: string;
  type: 'ParticipantIdentity';
}

export type MetaMessage = NameMetaMessage;

@Injectable()
export class MetaMessageService {
  public createMessage(metaMessage: MetaMessage): string {
    return `${MetaMessagePrefix}${JSON.stringify(metaMessage)}`;
  }

  public parseMessage(message: string): MetaMessage | undefined {
    if (!this.isMetaMessage(message)) {
      return;
    }

    const rawMetaMessage = message.replace(MetaMessagePrefix, '');
    let metaMessage: MetaMessage | undefined;

    try {
      const parsedMessage = JSON.parse(rawMetaMessage);

      if ('type' in parsedMessage) {
        metaMessage = parsedMessage;
      }
    } catch (e) {
      console.error(`Error parsing meta message: ${message}`);
      return;
    }

    return metaMessage;
  }

  private isMetaMessage(message: string): boolean {
    return message.startsWith(MetaMessagePrefix);
  }
}
