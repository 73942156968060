import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import { NavLink } from 'react-router-dom';
export var StyledCard = styled(Card)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  align-self: flex-start;\n  position: sticky;\n  top: 0;\n"], ["\n  align-self: flex-start;\n  position: sticky;\n  top: 0;\n"])));
export var StyledNav = styled.nav(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  min-width: 180px;\n  height: 400px;\n"], ["\n  min-width: 180px;\n  height: 400px;\n"])));
export var StyledNavList = styled.ul(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"], ["\n  list-style: none;\n  padding: 0;\n  margin: 0;\n"])));
export var StyledNavLink = styled(NavLink)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  color: #3f699e;\n  font-size: 16px;\n  padding: ", "px;\n  display: block;\n\n  &.active {\n    font-weight: ", ";\n    background-color: #b6e8ff;\n  }\n"], ["\n  color: #3f699e;\n  font-size: 16px;\n  padding: ", "px;\n  display: block;\n\n  &.active {\n    font-weight: ", ";\n    background-color: #b6e8ff;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightBold;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
