import { Action } from '@ngrx/store';
import { Message } from 'src/app/models/Message';
import { FetchMessagesSuccessEvent, TypingEvent } from '../models/messaging';

/**
 * Enumerated, namespaced layout action types
 */
export enum RealTimeMessageActionTypes {
  Connect = '[MessagingAPI] Connect',
  Typing = '[MessagingAPI] Typing',
  Disconnect = '[MessagingAPI] Disconnect',
  ForceRefreshTyping = '[MessagingAPI] Force Refresh Typing',
}

/**
 * Action Creators
 */
export class Connect implements Action {
  readonly type = RealTimeMessageActionTypes.Connect;
}

export class Typing implements Action {
  readonly type = RealTimeMessageActionTypes.Typing;

  constructor(public payload: TypingEvent) {}
}

export class Disconnect implements Action {
  readonly type = RealTimeMessageActionTypes.Disconnect;
}

export class ForceRefreshTyping implements Action {
  readonly type = RealTimeMessageActionTypes.ForceRefreshTyping;
}

export const RealTimeActions = {
  Connect,
  Typing,
  Disconnect,
  ForceRefreshTyping,
};

/**
 * Real Time Actions Type Union
 */
export type RealTimeActionsUnion =
  | Connect
  | Typing
  | Disconnect
  | ForceRefreshTyping;
