import { EventEmitter, OnDestroy, QueryList, } from '@angular/core';
import { IntersectionObserverDirective } from '../../../../app/core/directives/interesection-observer';
import { PatientListColumnsTitles } from './patient-list.component.constants';
var PatientListComponent = /** @class */ (function () {
    function PatientListComponent() {
        this.goto = new EventEmitter();
        this.reachedScrollEnd = new EventEmitter();
        this.sortChange = new EventEmitter();
        this.displayedColumns = [
            PatientListColumnsTitles.PatientIdentifier,
            PatientListColumnsTitles.FirstName,
            PatientListColumnsTitles.LastName,
            PatientListColumnsTitles.DateOfBirth,
            PatientListColumnsTitles.PatientAccountCreatedDateUtc,
        ];
    }
    PatientListComponent.prototype.goToPatient = function (row) {
        this.goto.emit(row.Id);
    };
    PatientListComponent.prototype.onObserved = function () {
        this.reachedScrollEnd.emit();
    };
    PatientListComponent.prototype.ngOnDestroy = function () {
        this.detachObservers();
    };
    PatientListComponent.prototype.detachObservers = function () {
        this.isos.forEach(function (iso) { return iso.DetachObserver(); });
    };
    PatientListComponent.prototype.attachObservers = function () {
        this.isos.forEach(function (iso) { return iso.SetupObserver(); });
    };
    Object.defineProperty(PatientListComponent.prototype, "columnTitles", {
        get: function () {
            return PatientListColumnsTitles;
        },
        enumerable: true,
        configurable: true
    });
    PatientListComponent.prototype.onSortChange = function (sortParams) {
        if (sortParams.direction === '') {
            sortParams.active = '';
        }
        this.sortChange.next(sortParams);
    };
    return PatientListComponent;
}());
export { PatientListComponent };
