export var MessageUIActionTypes;
(function (MessageUIActionTypes) {
    /**
     * These actions don't require server side actions
     */
    MessageUIActionTypes["OpenThread"] = "[MessageUI] Open Thread";
    MessageUIActionTypes["CloseThread"] = "[MessageUI] Close Thread";
    MessageUIActionTypes["ChangeActiveList"] = "[MessageUI] Change Active List";
    // eventually search will involve server calling
    // currently it sorts through all threads.
    MessageUIActionTypes["ActivateSearch"] = "[MessageUI] Activate Search";
    MessageUIActionTypes["ClearSearch"] = "[MessageUI] Clear Search";
    MessageUIActionTypes["UpdateSearchString"] = "[MessageUI] Update Search String";
    // change active label or search if search is
    // active
    MessageUIActionTypes["ApplyFilter"] = "[MessageUI] Apply Message Filter";
    MessageUIActionTypes["ApplyMessageTypeFilter"] = "[MessageUI] Apply Message Type Filter";
    MessageUIActionTypes["SortMessageList"] = "[MessageUI] Sort Message List";
    MessageUIActionTypes["SetSelectedTab"] = "[MessageUI] Set Selected Tab";
    MessageUIActionTypes["StarMessage"] = "[MessageUI] Star Message";
    // change size and layout of message response box
    MessageUIActionTypes["ChangeMessageResponseSize"] = "[MessageUI] Change Message Response Size";
    MessageUIActionTypes["HideThreadNewMessagesButton"] = "[MessageUI] Hide thread new messages button";
    MessageUIActionTypes["UpdateConversationCache"] = "[MessageUI] Updating conversation cache";
})(MessageUIActionTypes || (MessageUIActionTypes = {}));
// ============== Client Side Actions ==================== //
// This action triggers LoadThread Action
var OpenThread = /** @class */ (function () {
    function OpenThread(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.OpenThread;
    }
    return OpenThread;
}());
export { OpenThread };
var CloseThread = /** @class */ (function () {
    function CloseThread() {
        this.type = MessageUIActionTypes.CloseThread;
    }
    return CloseThread;
}());
export { CloseThread };
var ApplyFilter = /** @class */ (function () {
    function ApplyFilter(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.ApplyFilter;
    }
    return ApplyFilter;
}());
export { ApplyFilter };
var ApplyMessageTypeFilter = /** @class */ (function () {
    function ApplyMessageTypeFilter(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.ApplyMessageTypeFilter;
    }
    return ApplyMessageTypeFilter;
}());
export { ApplyMessageTypeFilter };
var ActivateSearch = /** @class */ (function () {
    function ActivateSearch() {
        this.type = MessageUIActionTypes.ActivateSearch;
    }
    return ActivateSearch;
}());
export { ActivateSearch };
var ClearSearch = /** @class */ (function () {
    function ClearSearch() {
        this.type = MessageUIActionTypes.ClearSearch;
    }
    return ClearSearch;
}());
export { ClearSearch };
var UpdateSearchString = /** @class */ (function () {
    function UpdateSearchString(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.UpdateSearchString;
    }
    return UpdateSearchString;
}());
export { UpdateSearchString };
var SortMessageList = /** @class */ (function () {
    function SortMessageList(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.SortMessageList;
    }
    return SortMessageList;
}());
export { SortMessageList };
var ChangeMessageResponseSize = /** @class */ (function () {
    function ChangeMessageResponseSize(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.ChangeMessageResponseSize;
    }
    return ChangeMessageResponseSize;
}());
export { ChangeMessageResponseSize };
var HideThreadNewMessagesButton = /** @class */ (function () {
    function HideThreadNewMessagesButton() {
        this.type = MessageUIActionTypes.HideThreadNewMessagesButton;
    }
    return HideThreadNewMessagesButton;
}());
export { HideThreadNewMessagesButton };
var SetSelectedTab = /** @class */ (function () {
    function SetSelectedTab(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.SetSelectedTab;
    }
    return SetSelectedTab;
}());
export { SetSelectedTab };
var UpdateConversationCache = /** @class */ (function () {
    function UpdateConversationCache(payload) {
        this.payload = payload;
        this.type = MessageUIActionTypes.UpdateConversationCache;
    }
    return UpdateConversationCache;
}());
export { UpdateConversationCache };
