import React from 'react';
import { SvgWrapper } from './_SvgWrapper';

export const MailOpenIcon: React.FC<{ className?: string }> = (props) => (
  <SvgWrapper {...props} width={60} height={58}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-898.000000, -343.000000)"
        fill="#CFC3F2"
        fillRule="nonzero"
      >
        <g transform="translate(898.000000, 343.000000)">
          <path d="M54.780107,20.4963389 C54.3901062,19.9287987 53.6151438,19.7838375 53.0425645,20.1713773 L32.5401026,34.1988656 C30.9950996,35.2539067 29.0000957,35.2539067 27.4575536,34.1988656 L6.95497452,20.1713773 C6.38251247,19.7813765 5.60497188,19.9263377 5.21743207,20.4963389 C4.82989225,21.06634 4.97497065,21.8438806 5.54497177,22.2338813 L26.0475509,36.2613697 C27.2475532,37.0839103 28.625095,37.493833 30.0000586,37.493833 C31.3750222,37.493833 32.752564,37.0837931 33.9525663,36.2613697 L54.4551454,22.2338813 C55.0251465,21.8438806 55.1701078,21.06634 54.780107,20.4963389 Z"></path>
          <path d="M57.1476507,16.1763304 L34.0926056,1.23876219 C31.6701009,-0.418741052 28.3025943,-0.401280081 25.9325897,1.22130121 L2.85246651,16.1738695 C1.06500208,17.3313327 0,19.2912974 0,21.4213016 L0,51.2013988 C0,54.6463665 2.80500548,57.451372 6.24997314,57.451372 L53.7500269,57.451372 C57.1949945,57.451372 60,54.6488274 60,51.2013988 L60,21.4213016 C60,19.2912974 58.9351151,17.3313327 57.1476507,16.1763304 Z M57.5001514,51.2013988 C57.5001514,53.2689419 55.8176871,54.9514062 53.750144,54.9514062 L6.24997314,54.9514062 C4.18243004,54.9514062 2.49996582,53.2689419 2.49996582,51.2013988 L2.49996582,21.4213016 C2.49996582,20.1438382 3.13992801,18.9663359 4.2124301,18.2737564 L27.3175143,3.30126621 C28.1125158,2.75880422 29.0400567,2.47122553 30.0000586,2.47122553 C30.9600605,2.47122553 31.8876013,2.75868703 32.7075639,3.31872719 L55.7875699,18.2737564 C56.860072,18.9687968 57.5001514,20.143721 57.5001514,21.4213016 L57.5001514,51.2013988 L57.5001514,51.2013988 Z"></path>
          <path d="M22.2100043,37.8988338 C21.7675035,37.3687937 20.9800019,37.2963717 20.4475009,37.7388725 L5.44747158,50.238936 C4.91743148,50.6814369 4.84500946,51.4713994 5.28751033,52.0014395 C5.53501081,52.2989791 5.88997244,52.4514403 6.24997314,52.4514403 C6.53251276,52.4514403 6.81751332,52.3564011 7.05001377,52.1614007 L22.0500431,39.6613372 C22.5800832,39.2188364 22.6525052,38.4288739 22.2100043,37.8988338 Z"></path>
          <path d="M54.5526456,50.238936 L39.5526163,37.7388725 C39.0176543,37.2963717 38.2301528,37.3689109 37.7901129,37.8988338 C37.347612,38.4313348 37.4201512,39.2188364 37.9500741,39.6613372 L52.9501034,52.1614007 C53.1850648,52.3564011 53.4676044,52.4514403 53.750144,52.4514403 C54.1076838,52.4514403 54.4626454,52.2989791 54.7126069,52.0014395 C55.1551077,51.4689384 55.0825685,50.6814369 54.5526456,50.238936 Z"></path>
        </g>
      </g>
    </g>
  </SvgWrapper>
);
