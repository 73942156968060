import * as tslib_1 from "tslib";
import { Box, List, Popover, Tab } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { Button, Loading } from '@react/components';
import { useTranslations } from '@react/lib/i18n';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { StyledActionsContainer, StyledListItem, StyledListItemText, StyledSearchBar, StyledTabPanel, StyledTabs, } from './FilterMenuPopover.styled';
export function getOptionsStateFromTabs(tabs) {
    return tabs.reduce(function (tabsState, tab) {
        var _a;
        return tslib_1.__assign({}, tabsState, (_a = {}, _a[tab.id] = new Set(tab.options
            .filter(function (option) { return option.active; })
            .map(function (option) { return option.id; })), _a));
    }, {});
}
export var FilterMenuPopover = function (_a) {
    var id = _a.id, anchorEl = _a.anchorEl, open = _a.open, onClose = _a.onClose, tabs = _a.tabs, anchorOrigin = _a.anchorOrigin, transformOrigin = _a.transformOrigin, onChange = _a.onChange, onTabEndReached = _a.onTabEndReached, onSearchUpdate = _a.onSearchUpdate, onOptionClicked = _a.onOptionClicked, optionsState = _a.optionsState;
    var t = useTranslations().t;
    var _b = useState(0), selectedTab = _b[0], setSelectedTab = _b[1];
    var _c = useState(false), transitionEnded = _c[0], setTransitionEnded = _c[1];
    function onTabChange(event, value) {
        setSelectedTab(value);
    }
    function onApplyClicked(e) {
        onChange(optionsState);
        if (onClose) {
            onClose(e, 'escapeKeyDown');
        }
    }
    function onCloseInner(e) {
        if (onClose) {
            onClose(e, 'escapeKeyDown');
        }
    }
    function onSearchChange(tabId, value) {
        if (onSearchUpdate) {
            onSearchUpdate(tabId, value);
        }
    }
    function onEndReached(tabId) {
        if (onTabEndReached) {
            onTabEndReached(tabId);
        }
    }
    return (React.createElement(Popover, { id: id, "aria-modal": "true", "aria-label": t.Messages.FilterMenuAriaLabel, open: open, anchorEl: anchorEl, TransitionProps: {
            onEntered: function () { return setTransitionEnded(true); },
        }, onClose: onCloseInner, anchorOrigin: anchorOrigin, transformOrigin: transformOrigin },
        React.createElement(Box, null,
            React.createElement(StyledTabs
            // Hack to fix issue where tab scroll does not init
            // properly during transition
            , { 
                // Hack to fix issue where tab scroll does not init
                // properly during transition
                key: "open-" + transitionEnded, value: selectedTab, onChange: onTabChange, variant: "scrollable", scrollButtons: "auto", indicatorColor: "primary" }, tabs.map(function (tab) { return (React.createElement(Tab, { key: tab.id, role: "tab", label: tab.label })); }))),
        tabs.map(function (tab, index) {
            return (React.createElement("div", { key: tab.id, role: "tabpanel", hidden: selectedTab !== index, id: "tabpanel-" + index }, selectedTab === index && (React.createElement(StyledTabPanel, null,
                tab.searchEnabled && (React.createElement(StyledSearchBar, { value: tab.searchValue, onChange: function (value) { return onSearchChange(tab.id, value); }, placeholder: tab.searchPlaceholder })),
                React.createElement(List, { dense: true },
                    tab.options
                        .filter(function (option) { return !option.hidden; })
                        .map(function (option) {
                        var selected = optionsState[tab.id].has(option.id);
                        return (React.createElement(StyledListItem, { className: clsx({ selected: selected }), button: true, key: option.id, dense: true, onClick: function () { return onOptionClicked(tab.id, option.id); }, style: { paddingTop: 8, paddingBottom: 8 } },
                            React.createElement(StyledListItemText, { id: option.id, primary: option.label, weight: option.weight }),
                            selected && React.createElement(Done, null)));
                    }),
                    React.createElement(Waypoint, { onEnter: function () { return onEndReached(tab.id); } }),
                    tab.loading && React.createElement(Loading, null))))));
        }),
        React.createElement(StyledActionsContainer, null,
            React.createElement(Button, { "data-testid": "btn_apply", color: "primary", variant: "contained", onClick: onApplyClicked }, t.Messages.FilterMenuApplyButton))));
};
FilterMenuPopover.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
};
