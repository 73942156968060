import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { take } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'portal-patients-detail',
  template: '<div></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientDetailsInternalComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _navigationService: NavigationService,
    private _patientService: PatientService
  ) {}

  ngOnInit(): void {
    const routerParams$ = this._store.pipe(select(fromRoot.getRouterParams));
    this._subs.add(
      routerParams$.pipe(take(1)).subscribe((params) => {
        this._patientService
          .getPatientById(params.internalsid, true)
          .pipe(take(1))
          .subscribe((patient) => {
            if (patient.Id) {
              this._navigationService.navigate(['patients', patient.Id]);
            }
          });
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
