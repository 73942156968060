import styled from '@emotion/styled';
import { CameraAlt as CameraIcon } from '@material-ui/icons';
import React, { MouseEventHandler } from 'react';

interface Props {
  onSelectClicked: MouseEventHandler<HTMLDivElement>;
}

export const Placeholder: React.FC<Props> = ({ onSelectClicked }) => {
  return (
    <Circle aria-label="cameraIcon" role="button" onClick={onSelectClicked}>
      <CameraIcon fontSize="large" />
    </Circle>
  );
};

const Circle = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray};
  border: 2px dashed ${({ theme }) => theme.colors.text.primary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 200px;
`;
