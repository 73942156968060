import { BaseEditor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

export interface CustomText {
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  text: string;
}

export interface ParagraphElement {
  type: 'paragraph';
  children: CustomText[];
}

export interface VariableElement {
  type: 'variable';
  content: string;
  children: CustomText[];
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: ParagraphElement | VariableElement;
  }
}

export const isParagraphElement = (element: any): element is ParagraphElement =>
  element.type === 'paragraph';

export const isVariableElement = (element: any): element is VariableElement =>
  element.type === 'variable';
