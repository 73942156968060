
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { MaterialModule } from '../material/material.module';
import { CreateAccountFormComponent } from './components/create-account-form/create-account.component';
import { EmailOTPFormComponent } from './components/email-otp-form/email-otp-form.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { CreateAccountContainer } from './containers/create-account/create-account.container';
import { EmailOTPPageContainer } from './containers/email-otp-page/email-otp.container';
import { ForgotPasswordContainer } from './containers/forgot-password/forgot-password.container';
import { LoginPageContainer } from './containers/login-page/login-page.container';
import { AuthEffects } from './effects/auth.effects';
import { reducers } from './reducers';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

export const COMPONENTS = [
  LoginPageContainer,
  CreateAccountContainer,
  ForgotPasswordContainer,
  EmailOTPPageContainer,
  LoginFormComponent,
  CreateAccountFormComponent,
  ForgotPasswordComponent,
  EmailOTPFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    PerfectScrollbarModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootAuthModule,
      providers: [
        AuthService,
        AuthGuard,
        {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    AuthModule,
    RouterModule.forChild([
      {
        path: 'login',
        data: {
          preload: true,
        },
        component: LoginPageContainer,
      },
      {
        path: 'create',
        data: {
          preload: true,
        },
        component: CreateAccountContainer,
      },
      {
        path: 'forgot',
        data: {
          preload: true,
        },
        component: ForgotPasswordContainer,
      },
      {
        path: 'email-otp',
        data: {
          preload: true,
        },
        component: EmailOTPPageContainer,
      },
    ]),
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class RootAuthModule {}
