import { Action } from '@ngrx/store';
import { StaffProfile } from '@react/lib/api/types/clinicUsers';
import { Language } from 'src/app/models/Language';
import {
  BusinessUnitClinicInfoResponse,
  ClinicInfoResponse
} from '../../auth/models/server-response/clinic-info-response';

export enum ClinicActionTypes {
  LoadClinicLanguages = '[Dashboard] Load Clinic Languages',
  LoadClinicLanguagesSuccess = '[Dashboard] Load Clinic Languages Success',
  LoadClinicLanguagesError = '[Dashboard] Load Clinic Languages Error',
  LoadClinicInfoSuccess = '[Dashboard] Load Clinic Info Success',
  LoadStaffProfile = '[Dashboard] Load Staff Profile',
  ClearStaffProfiles = '[Dashboard] Clear Staff Profiles'
}

export class LoadClinicLanguages implements Action {
  readonly type = ClinicActionTypes.LoadClinicLanguages;
}

export class LoadClinicLanguagesSuccess implements Action {
  readonly type = ClinicActionTypes.LoadClinicLanguagesSuccess;

  constructor(public payload: Language[]) {}
}

export class LoadClinicLanguagesError implements Action {
  readonly type = ClinicActionTypes.LoadClinicLanguagesError;

  constructor(public payload: Error) {}
}

export class LoadClinicInfoSuccess implements Action {
  readonly type = ClinicActionTypes.LoadClinicInfoSuccess;

  constructor(
    public payload: ClinicInfoResponse & BusinessUnitClinicInfoResponse
  ) {}
}

export class LoadStaffProfile implements Action {
  readonly type = ClinicActionTypes.LoadStaffProfile;

  constructor(public payload: StaffProfile) {}
}

export class ClearStaffProfiles implements Action {
  readonly type = ClinicActionTypes.ClearStaffProfiles;
}

export type ClinicActions =
  | LoadClinicLanguages
  | LoadClinicLanguagesError
  | LoadClinicLanguagesSuccess
  | LoadClinicInfoSuccess
  | LoadStaffProfile
  | ClearStaffProfiles;
