import styled from '@emotion/styled';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { Button } from '@react/components';
import { Dialog as SalveDialog } from '@react/components/Dialog';
import { Loading } from '@react/components/Loading';
import { useApiClient } from '@react/lib/api';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect, useState } from 'react';
import { IFileUpload } from '../../../../core/services/s3.service';
import PhotoPicker from './PhotoPicker';

interface Props {
  open: boolean;
  onCancelled: () => void;
  onConfirmed: () => void;
  dialogWrapper?: boolean;
  fileUploadService: IFileUpload;
}

interface IToast {
  title: string;
  message: string;
}

export const ProfilePhotoModalComponent: React.FC<Props> = ({
  dialogWrapper = true,
  fileUploadService,
  onCancelled,
  onConfirmed,
  open,
}) => {
  const [loading, setLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(true);
  const [image, setImage] = useState<string | File | null>(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isClosingConfirmationOpen, setIsClosingConfirmationOpen] =
    useState(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<IToast | null>(null);
  const [successToast, setSuccessToast] = useState<IToast | null>(null);
  const { t } = useTranslations();
  const client = useApiClient().portal;

  const [editor, setEditor] = useState<any | null>(null);

  useErrorToast(!!errorToast, {
    title: (errorToast as IToast) ? (errorToast as IToast).title : '',
    message: (errorToast as IToast) ? (errorToast as IToast).message : '',
  });
  useSuccessToast(!!successToast, {
    title: (successToast as IToast) ? (successToast as IToast).title : '',
    message: (successToast as IToast) ? (successToast as IToast).message : '',
  });

  const wrapperProps = {
    open,
    dialogWrapper,
    fileUploadService,
    onCancelled,
    onConfirmed,
  };

  const handleSelectClicked = async () => {
    // This looks a bit weird because it's creating a file dialog without placing
    // a file input component on the page. There might be a better way of doing this,
    // but I found it pretty straight forward to just do it like this.

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.jpg, .jpeg, .png';

    input.onchange = (e) => {
      if (e == null) {
        return;
      }

      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length) {
        const file = target.files[0] as unknown as string;
        setHasChanged(true);
        setIsEditable(true);
        setImage(file);
      }
    };

    input.click();
  };

  const writeFileToBucket = (file: File) => {
    const filename = (image as File).name;

    return new Promise((res, rej) => {
      fileUploadService
        .writeFileToBucket('profilephoto', filename, file, false)
        .subscribe(
          (r) => {
            if (r.complete && (r.body || r.error)) {
              if (r.body && !(r.error instanceof Error)) {
                res(r.body.Location);
              } else if (r.error instanceof Error) {
                rej(r.error);
              } else {
                rej(new Error(`Unexpected response from file service`));
              }
            }
          },
          (e) => {
            rej(e);
          },
        );
    });
  };

  const getEditedImageBlob = (): Promise<File> => {
    const editorObj = editor as any;
    const canvas = editorObj.getImageScaledToCanvas();

    return new Promise((resolve) => {
      canvas.toBlob((blob: File) => {
        resolve(blob);
      });
    });
  };

  const handleSaveClicked = async () => {
    if (!editor) {
      setErrorToast({
        title: t.ProfilePhotoModal.ErrorToast.UpdateTitle,
        message: t.ProfilePhotoModal.ErrorToast.GenericDescription,
      });
      return;
    }

    try {
      const blob = await getEditedImageBlob();
      const result = await writeFileToBucket(blob);

      if (!result) {
        throw new Error('File url was not returned');
      }

      await client.updateStaff(result as string);
      setLoading(true);
      setSuccessToast({
        title: t.ProfilePhotoModal.SuccessToast.UpdatedTitle,
        message: t.ProfilePhotoModal.SuccessToast.UpdatedDescription,
      });
      onConfirmed();
    } catch {
      setErrorToast({
        title: t.ProfilePhotoModal.ErrorToast.UpdateTitle,
        message: t.ProfilePhotoModal.ErrorToast.GenericDescription,
      });
    }
  };

  const handleDeleteClicked = async () => {
    try {
      await client.updateStaff(null);
      setLoading(true);
      setIsDeleteConfirmationOpen(false);
      setSuccessToast({
        title: t.ProfilePhotoModal.SuccessToast.DeletedTitle,
        message: t.ProfilePhotoModal.SuccessToast.DeletedDescription,
      });
      onConfirmed();
    } catch {
      setErrorToast({
        title: t.ProfilePhotoModal.ErrorToast.UpdateTitle,
        message: t.ProfilePhotoModal.ErrorToast.GenericDescription,
      });
    }
  };

  const handleCloseClicked = () => {
    onCancelled();
  };

  useEffect(() => {
    client
      .fetchStaffMe()
      .then((staffResult) => {
        let profilePhotoUri: string | null = null;

        if (staffResult.data) {
          profilePhotoUri = staffResult.data.profilephotouri;
        }

        if (!profilePhotoUri) {
          setImage(null);
          setLoading(false);
          return;
        }

        fileUploadService
          .readProfilePhotoFromBucket({
            url: profilePhotoUri,
          })
          .then((fileResult) => {
            setImage(fileResult.url);
            setIsEditable(false);
            setLoading(false);
          });
      })
      .catch((err) => {
        setErrorToast(err);
      });
  }, []);

  useEffect(() => {
    // This lets the error toast re-show when there are repeated errors,
    // e.g. if the user retries an action such as delete after a network failure.
    if (errorToast) {
      setErrorToast(null);
    }
  }, [setErrorToast]);

  return (
    <Wrapper {...wrapperProps}>
      <StyledContainer>
        <Typography className="profilePhotoModal-title" variant="h6">
          {t.ProfilePhotoModal.Title}
        </Typography>
        {!loading && (
          <Typography className="profilePhotoModal-title" variant="subtitle1">
            {t.ProfilePhotoModal.SubTitle}
          </Typography>
        )}
        <IconButton
          className="profilePhotoModal-close"
          onClick={() => setIsClosingConfirmationOpen(true)}
          size="small"
          role="button"
        >
          <IconClose color="primary" />
        </IconButton>
        {loading && (
          <Grid container justifyContent="center">
            <Loading />
            <Typography style={{ marginTop: 20 }}>
              {t.ProfilePhotoModal.Loading}
            </Typography>
          </Grid>
        )}
        {!loading && (
          <Grid container>
            <Grid container alignItems="center" direction="column">
              <Grid item style={{ margin: '30px 0' }}>
                <PhotoPicker
                  image={image as string}
                  isEditable={isEditable}
                  onHasChanged={(val) => setHasChanged}
                  onSelectClicked={handleSelectClicked}
                  setEditor={setEditor}
                />
              </Grid>
              <Button
                aria-label="change"
                onClick={handleSelectClicked}
                color="default"
                size="small"
                variant="contained"
              >
                {isEditable
                  ? t.ProfilePhotoModal.SelectPhoto
                  : t.ProfilePhotoModal.ChangePhoto}
              </Button>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              direction="row-reverse"
            >
              <Button
                aria-label="save"
                onClick={handleSaveClicked}
                color="primary"
                size="medium"
                variant="contained"
                disabled={!hasChanged}
              >
                {t.ProfilePhotoModal.Save}
              </Button>
              {!isEditable && (
                <Button
                  aria-label="delete"
                  onClick={() => setIsDeleteConfirmationOpen(true)}
                  color="secondary"
                  size="medium"
                  variant="contained"
                >
                  {t.ProfilePhotoModal.Delete}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <SalveDialog
          acceptButtonText={t.ProfilePhotoModal.DeleteConfirmation.Delete}
          cancelButtonText={t.ProfilePhotoModal.DeleteConfirmation.Cancel}
          cancelButtonColour="default"
          contentText={t.ProfilePhotoModal.DeleteConfirmation.Content}
          isOpen={isDeleteConfirmationOpen}
          onAccept={handleDeleteClicked}
          onCancel={() => setIsDeleteConfirmationOpen(false)}
          titleText={t.ProfilePhotoModal.DeleteConfirmation.Title}
        />
        <SalveDialog
          acceptButtonText={t.ProfilePhotoModal.CloseConfirmation.Close}
          cancelButtonText={t.ProfilePhotoModal.CloseConfirmation.Cancel}
          cancelButtonColour="default"
          contentText={t.ProfilePhotoModal.CloseConfirmation.Content}
          isOpen={isClosingConfirmationOpen}
          onAccept={handleCloseClicked}
          onCancel={() => setIsClosingConfirmationOpen(false)}
          titleText={t.ProfilePhotoModal.CloseConfirmation.Title}
        />
      </StyledContainer>
    </Wrapper>
  );
};

const Wrapper: React.FunctionComponent<Props> = ({
  children,
  dialogWrapper,
  fileUploadService,
  onCancelled,
  onConfirmed,
  open,
}) => {
  if (!dialogWrapper) {
    return <>{children}</>;
  }

  return (
    <Dialog open={open} onClose={onCancelled}>
      {children}
    </Dialog>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 20px;
  position: relative;

  .profilePhotoModal-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .profilePhotoModal-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .newMessageModal-sendButton {
    display: flex;
    justify-content: flex-end;
  }
`;
