import { ElementRef } from '@angular/core';
import { parseInteger, zeroPad } from '../utils/generic';
var InputMaxDirective = /** @class */ (function () {
    function InputMaxDirective(elRef) {
        this.elRef = elRef;
    }
    InputMaxDirective.prototype.onInput = function (e) {
        var element = this.elRef.nativeElement;
        if (!element.value) {
            return;
        }
        var value = parseInteger(element.value);
        if (value > this.inputMax) {
            element.value = zeroPad(this.inputMax, this.inputMaxLength);
        }
    };
    return InputMaxDirective;
}());
export { InputMaxDirective };
var InputMinDirective = /** @class */ (function () {
    function InputMinDirective(elRef) {
        this.elRef = elRef;
    }
    InputMinDirective.prototype.onInput = function (e) {
        var element = this.elRef.nativeElement;
        if (!element.value) {
            return;
        }
        var value = parseInteger(element.value);
        if (value < this.inputMin) {
            element.value = zeroPad(this.inputMin, this.inputMaxLength);
        }
    };
    return InputMinDirective;
}());
export { InputMinDirective };
