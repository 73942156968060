
    <portal-login-form
      [isCore]="isCoreClinic$ | async"
      [text]="toastText$ | async"
      [loggingIn]="loggingIn$ | async"
      [error]="error$ | async"
      [clinicId]="clinicId$ | async"
      [cognitoUser]="cognitoUser$ | async"
      (forgottenPassword)="forgotPassword()"
      (submitted)="onSubmit($event)"
    >
    </portal-login-form>
  