import * as tslib_1 from "tslib";
import { ClinicUserMenu } from '@react/pages/conversations/components/ClinicUserMenu';
import { uniqBy } from 'lodash';
import React from 'react';
import NestedMenuItem from './NestedMenuItem';
export var AssignMenuItem = function (_a) {
    var item = _a.item, isOpen = _a.isOpen, nestedMenuProps = _a.nestedMenuProps, assignClinicUsers = _a.assignClinicUsers, handleClose = _a.handleClose;
    var onClinicUserAssignItemClick = assignClinicUsers.onClinicUserAssignItemClick, clinicUsers = assignClinicUsers.clinicUsers, me = assignClinicUsers.me, isLoadingClinicUsers = assignClinicUsers.isLoadingClinicUsers;
    if (!clinicUsers || !me) {
        return null;
    }
    function onClickUserMenu(value) {
        if (onClinicUserAssignItemClick) {
            onClinicUserAssignItemClick(value);
        }
        handleClose();
    }
    var uniqueClinicUsers = uniqBy(clinicUsers, 'id');
    var assignClinicUserNestedProps = tslib_1.__assign({}, nestedMenuProps, { anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'right' } });
    return (React.createElement(NestedMenuItem, { key: item.name, label: item.name, parentMenuOpen: isOpen, MenuProps: assignClinicUserNestedProps },
        React.createElement(ClinicUserMenu, { key: item.name + "-ClinicUserMenu", onClick: onClickUserMenu, clinicUsers: uniqueClinicUsers, me: me, conversation: item.conversation, unassign: item.unassign, loading: isLoadingClinicUsers })));
};
