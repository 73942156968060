import { useEffect } from 'react';

interface NotificationOptions {
  title: string;
  body: string;
  onClick?: (e: Event) => void;
}

function requestPermission() {
  if (Notification.permission === 'default') {
    Notification.requestPermission();
  }
}

function showNotification({ title, body, onClick }: NotificationOptions) {
  if (Notification.permission === 'granted') {
    const notification = new Notification(title, {
      body,
      icon: 'assets/imgs/logo.png',
    });

    if (onClick) {
      notification.onclick = onClick;
    }
  }
}

export function useBrowserNotifications() {
  useEffect(() => {
    requestPermission();
  }, []);

  return { showNotification };
}
