import { OnChanges, SimpleChanges, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var VideoPreviewComponent = /** @class */ (function () {
    function VideoPreviewComponent(santizer) {
        this.santizer = santizer;
    }
    VideoPreviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.html &&
            changes.html.currentValue !== changes.html.previousValue) {
            if (changes.html.currentValue == null ||
                changes.html.currentValue === '') {
                this.videoPreview = undefined;
                this.videoSrc = undefined;
            }
            var srcMatches = changes.html.currentValue.includes('iframe')
                ? changes.html.currentValue.match(/src\s*=\s*"([^"]+)"/)
                : null;
            if (srcMatches == null) {
                return;
            }
            var source = srcMatches[1];
            var isYouTube = source.includes('youtube');
            if (isYouTube) {
                this.videoPreview = this.santizer.bypassSecurityTrustHtml(changes.html.currentValue);
            }
            else {
                this.videoSrc = source;
            }
        }
    };
    return VideoPreviewComponent;
}());
export { VideoPreviewComponent };
