import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { version } from '../../../../../package.json';

@Component({
  selector: 'portal-integration-status',
  templateUrl: './integration-status.component.html',
  styleUrls: ['./integration-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationStatusComponent {
  @Input() text: any;
  @Input() integrated: boolean;
  @Input() synchronised: boolean;

  _version: string;

  constructor() {
    this._version = version;
  }
}
