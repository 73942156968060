import styled from '@emotion/styled';
import { Menu } from '@material-ui/core';

export const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }

  li {
    padding-right: 2.5rem;

    > svg {
      position: absolute;
      right: 0.5rem;
    }
  }

  .MuiListItem-focusVisible,
  .MuiListItem-button:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }
`;
