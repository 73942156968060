import React, { createContext, FunctionComponent, useContext } from 'react';
import { User } from 'src/app/auth/models/user';

interface RootConfigContext {
  clinicToken: string;
  user: User;
}

// tslint:disable-next-line: no-non-null-assertion
export const RootConfigContext = createContext<RootConfigContext>(null!);

interface ProviderProps {
  children: React.ReactNode;
  value: RootConfigContext;
}

export const RootConfigProvider: FunctionComponent<ProviderProps> = ({
  children,
  value,
}) => {
  return <RootConfigContext.Provider value={value} children={children} />;
};

export function useRootConfig() {
  return useContext(RootConfigContext);
}
