import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import { NavLink } from 'react-router-dom';

export const StyledCard = styled(Card)`
  align-self: flex-start;
  position: sticky;
  top: 0;
`;

export const StyledNav = styled.nav`
  min-width: 180px;
  height: 400px;
`;

export const StyledNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledNavLink = styled(NavLink)`
  color: #3f699e;
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  display: block;

  &.active {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    background-color: #b6e8ff;
  }
`;
