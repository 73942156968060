<div
  class="folder-info"
  (click)="goToMessages.emit(messageTypeId)"
  [matTooltip]="text.Tooltip"
>
  <div>
    <h4>{{ text.Unresolved }}</h4>
  </div>

  <div>
    {{ count }}
  </div>
</div>
