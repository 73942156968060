import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'portal-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.scss'],
})
export class VersionHistoryComponent {
  @Input() text: any;
}
