import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CloseThread } from 'src/app/messaging/actions/message-ui.actions';
export var GLOBAL_CMS_ROUTE = 'content/global';
var NavigationService = /** @class */ (function () {
    function NavigationService(_router, _store) {
        this._router = _router;
        this._store = _store;
    }
    NavigationService.prototype.navigate = function (commands, extras) {
        if (this._router.url.toLowerCase().startsWith('/messages')) {
            this._store.dispatch(new CloseThread());
        }
        this._router.navigate(commands, extras);
    };
    NavigationService.prototype.navigateAfterLogin = function (isGlobalPortal) {
        if (isGlobalPortal) {
            this.navigate(["/" + GLOBAL_CMS_ROUTE]);
        }
        else {
            this.navigate(['/']);
        }
    };
    NavigationService.prototype.navigateByUrl = function (url) {
        this._router.navigateByUrl(url);
    };
    return NavigationService;
}());
export { NavigationService };
