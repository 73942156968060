var ConversationsRequest = /** @class */ (function () {
    // tslint:disable-next-line: no-shadowed-variable
    function ConversationsRequest(ConversationListParams) {
        this.ConversationListParams = ConversationListParams;
        this.Action = 'conversations';
    }
    return ConversationsRequest;
}());
export { ConversationsRequest };
var ConversationsRequestV2 = /** @class */ (function () {
    // tslint:disable-next-line: no-shadowed-variable
    function ConversationsRequestV2(ConversationListParams) {
        this.ConversationListParams = ConversationListParams;
        this.Action = 'conversations.v2';
    }
    return ConversationsRequestV2;
}());
export { ConversationsRequestV2 };
