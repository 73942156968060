import { ConversationFolder } from '@react/lib/api/types';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { ClinicUser } from '@react/types';
import flatten from 'lodash/flatten';
import React, { useEffect, useMemo, useState } from 'react';
import { usePatientStaffListInfiniteQuery } from '../hooks/usePatientStaffListQuery';
import { FilterState } from '../types';
import { unassigned_option } from '../utils';
import {
  FilterMenuPopover,
  FilterMenuPopoverState,
  getOptionsStateFromTabs,
} from './FilterMenuPopover';

interface Props {
  menuAnchor?: Element | null;
  open: boolean;
  onClose: () => void;
  onChange: (updateOptions: FilterMenuPopoverState) => void;
  folders?: ConversationFolder[];
  filters: FilterState;
  clinicUsers?: ClinicUser[];
}

const setToArray = (set?: Set<string>) =>
  set && set.size ? Array.from(set) : [];

export const FilterMenu: React.FC<Props> = ({
  menuAnchor,
  open,
  onClose,
  onChange,
  folders,
  filters,
  clinicUsers,
}) => {
  const { t } = useTranslations();
  const features: { [key: string]: any } = useFeatures();
  const featureAssignConversation = checkFeature(
    features,
    'messagingOptions.assignConversation',
  );
  const [searchState, setSearchState] = useState({
    folder: '',
    staff: '',
    clinicUsers: '',
  });
  const [optionsState, setOptionsState] = useState<FilterMenuPopoverState>({
    folder: new Set(),
    status: new Set(),
    clinicUsers: new Set(),
  });
  const debouncedSearch = useDebouncedState(searchState, 500);
  const staffResult = usePatientStaffListInfiniteQuery({
    q: debouncedSearch['staff'],
  });
  const hasStaff =
    staffResult.data && staffResult.data.pages[0].data.length > 0;

  const [showStaff, setShowStaff] = useState(hasStaff);

  useEffect(() => {
    setOptionsState((state) => {
      const tabState = getOptionsStateFromTabs(tabs);
      return Object.keys(tabState).reduce((agg, key) => {
        const tabStateSet = tabState[key];
        const stateSet = state[key];
        return {
          ...agg,
          [key]: new Set([...setToArray(tabStateSet), ...setToArray(stateSet)]),
        };
      }, state);
    });
  }, [filters]);

  function onTabEndReached(tabId: string) {
    if (tabId === 'staff') {
      const shouldFetchNextPage =
        staffResult.hasNextPage &&
        !staffResult.isLoading &&
        !staffResult.isFetchingNextPage;

      if (shouldFetchNextPage) {
        staffResult.fetchNextPage();
      }
    }
  }

  function onSearchUpdate(tabId: string, value: string) {
    setSearchState((current) => ({
      ...current,
      [tabId]: value,
    }));
  }

  // Show staff tab even if filters reduce results to 0
  useEffect(() => {
    if (hasStaff) {
      setShowStaff(true);
    }
  }, [hasStaff]);

  useErrorToast(staffResult.isError, {
    title: t.Messages.FilterLoadingErrorTitle,
    message: t.Messages.FilterLoadingErrorMessage,
  });

  function getFoldersTab() {
    const mapOption = (folder: ConversationFolder) => ({
      label: folder.name,
      id: String(folder.id),
      active: filters.folderIds ? filters.folderIds.includes(folder.id) : false,
    });
    const options = Array.from(folders || []);
    const filteredOptions = options
      .filter((folder) =>
        folder.name.toLocaleLowerCase().includes(searchState['folder']),
      )
      .map(mapOption);

    return {
      id: 'folder',
      label: t.Messages.FolderFilterLabel,
      options: filteredOptions,
      searchEnabled: true,
      searchPlaceholder: t.Messages.FolderFilterSearchPlaceholder,
      searchValue: searchState['folder'],
    };
  }

  function getStatusTab() {
    return {
      id: 'status',
      label: t.Messages.StatusFilterLabel,
      options: [
        {
          id: 'resolved',
          label: t.Messages.ResolvedFilterLabel,
          active: filters.isResolved === true,
        },
        {
          id: 'unresolved',
          label: t.Messages.UnresolvedFilterLabel,
          active: filters.isResolved === false,
        },
      ],
    };
  }

  function getClinicUsersTab() {
    const unassignedOption = {
      ...unassigned_option(t.Messages.Unassigned),
      active: filters.isUnassigned ? filters.isUnassigned : false,
    };
    const mapOption = (user: ClinicUser) => ({
      label: user.userfullname,
      id: String(user.id),
      userfullname: user.userfullname,
      active: filters.assignedIds
        ? filters.assignedIds.includes(user.id)
        : false,
    });

    const clinicUserOptions = Array.from(clinicUsers || []).filter(
      (user) => user.id !== null,
    );

    const options = clinicUserOptions.map(mapOption);
    const clinicUserOptionsWithUnassigned = [unassignedOption, ...options];
    const filteredClinicOptions = clinicUserOptionsWithUnassigned.filter(
      (user) =>
        user.userfullname
          .toLocaleLowerCase()
          .includes(searchState['clinicUsers'].toLocaleLowerCase()),
    );

    return {
      id: 'clinicUsers',
      label: t.Messages.Assignees,
      options: filteredClinicOptions,
      searchEnabled: true,
      searchPlaceholder: t.Messages.SearchAssignees,
      searchValue: searchState['clinicUsers'],
    };
  }

  const tabs = useMemo(() => {
    const foldersTab = getFoldersTab();
    const statusTab = getStatusTab();
    const clinicUsersTab = getClinicUsersTab();

    const computedTabs = [foldersTab, statusTab];

    if (featureAssignConversation) {
      computedTabs.push(clinicUsersTab);
    }

    if (showStaff) {
      const options = flatten(
        // tslint:disable-next-line:no-non-null-assertion
        staffResult.data!.pages.map((page) =>
          page.data.map((person) => ({
            label: `${person.firstname} ${person.lastname}`,
            id: String(person.clinicuserid),
            active: filters.staffIds
              ? filters.staffIds.includes(person.clinicuserid)
              : false,
            hidden: false,
          })),
        ),
      );

      // Add dummy results for currently selected filters
      if (filters.staffIds) {
        filters.staffIds.forEach((staffId) => {
          const hasSelectedStaff = options.some(
            (option) => option.id === String(staffId),
          );
          if (!hasSelectedStaff) {
            options.push({
              id: String(staffId),
              label: String(staffId),
              active: true,
              hidden: true,
            });
          }
        });
      }

      const staffTab = {
        id: 'staff',
        label: t.Messages.TeamMembersFilterLabel,
        options,
        searchEnabled: true,
        searchPlaceholder: t.Messages.TeamMembersFilterSearchPlaceholder,
        searchValue: searchState['staff'],
        // @ts-ignore
        loading: staffResult.isLoading || staffResult.isFetchingNextPage,
      };
      computedTabs.push(staffTab);
    }

    return computedTabs;
  }, [t, folders, searchState, filters, showStaff, staffResult]);

  const onOptionClicked = (tabId: string, optionId: string) => {
    const newTabOptions = new Set(optionsState[tabId]);
    if (newTabOptions.has(optionId)) {
      newTabOptions.delete(optionId);
    } else {
      newTabOptions.add(optionId);
    }
    setOptionsState({
      ...optionsState,
      [tabId]: newTabOptions,
    });
  };

  const onCloseMenu = () => {
    setOptionsState(getOptionsStateFromTabs(tabs));
    onClose();
  };

  return (
    <FilterMenuPopover
      id={`FilterMenuPopover-${staffResult.isLoading ? 'Loading' : 'Loaded'}`}
      anchorEl={menuAnchor}
      open={open}
      tabs={tabs}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={onCloseMenu}
      onChange={onChange}
      onTabEndReached={onTabEndReached}
      onSearchUpdate={onSearchUpdate}
      onOptionClicked={onOptionClicked}
      optionsState={optionsState}
    />
  );
};
