import styled from '@emotion/styled';
import {
  Box as MuiBox,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
} from '@material-ui/core';
import {
  MailOutline as MailOutlineIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import { Container } from '@react/components/Container';
import Root from '@react/components/Root';
import React, { FunctionComponent } from 'react';

export const StyledRoot = styled(Root)`
  width: 100%;
  height: calc(100vh - 100px);
  box-shadow: 0 0 4px 0 #999;
  background-color: #f7f7fb;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const ActionContainer = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #f7f7fb;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.dark};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  place-content: stretch flex-start;
  align-items: stretch;
  flex: 1 1 100%;
  max-width: 100%;
`;

export const LeftPanel = styled(MuiBox)`
  min-width: 180px;
  max-width: 240px;
  overflow: hidden;
  border-right: solid 1px #e5e5e7;

  > .action-container {
    height: 70px;
  }

  > .folders-container {
    height: calc(100% - 70px);
    overflow-y: scroll;
  }
`;

export const MiddlePanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 550px;
  height: 100%;
  z-index: 10;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const ConversationsContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  border-radius: 5px;

  .conversation-card {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.dark};
  }

  .conversation-end-of-list-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
`;

export const RightPanel = styled.div`
  flex: 1;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2, 2, 2, 0)};

  --approx-conversations-header-height: 148px;

  .message-no-conversation-selected {
    padding-top: var(--approx-conversations-header-height);
  }
`;

export const MenuList = styled(MuiMenuList)`
  width: 100%;
  padding: 0;
`;

export const MenuItem = styled(MuiMenuItem)<{ fontWeight?: 'lighter' }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)}px;
  justify-content: space-between;
  align-items: baseline;
  white-space: initial;
  font-size: 0.9rem;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};

  &:hover,
  &[class*='-selected'],
  &:hover[class*='-selected'] {
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }

  &[class*='-selected'] > * {
    font-weight: bold;
  }

  .count {
    padding-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const MailIcon = styled(MailOutlineIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 86px;
`;

const StyledTypingIndicator = styled.div`
  display: flex;
  align-items: center;
  font-style: italic;
  color: ${({ theme }) => theme.colors.greyishBrown};

  svg {
    color: ${({ theme }) => theme.colors.yellow};
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }
`;

export const TypingIndicator: FunctionComponent = ({ children }) => {
  return (
    <StyledTypingIndicator className="typing-indicator">
      <WarningIcon /> {children}
    </StyledTypingIndicator>
  );
};
