export var PackAssignmentActionTypes;
(function (PackAssignmentActionTypes) {
    PackAssignmentActionTypes["GetContentPacks"] = "[Pack-Assignment] Get Content Packs";
    PackAssignmentActionTypes["GetContentPacksSuccess"] = "[Pack-Assignment] Get Content Packs Success";
    PackAssignmentActionTypes["GetContentPacksError"] = "[Pack-Assignment] Get Content Packs Error";
    PackAssignmentActionTypes["GetContentPackDetail"] = "[Pack-Assignment] Get Content Pack Detail";
    PackAssignmentActionTypes["GetContentPackDetailSuccess"] = "[Pack-Assignment] Get Content Pack Detail Success";
    PackAssignmentActionTypes["GetContentPackDetailError"] = "[Pack-Assignment] Get Content Pack Detail Error";
    PackAssignmentActionTypes["SelectActivePackId"] = "[Pack-Assignment] Select Active Pack Id";
    PackAssignmentActionTypes["LockPackItem"] = "[Pack-Assignment] Lock Pack Item";
    PackAssignmentActionTypes["UnlockPackItem"] = "[Pack-Assignment] Unlock Pack Item";
    PackAssignmentActionTypes["AssignPack"] = "[Pack-Assignment] Assign pack to patient";
    PackAssignmentActionTypes["AssignPackSuccess"] = "[Pack-Assignment] Assign pack to patient Success";
    PackAssignmentActionTypes["AssignPackError"] = "[Pack-Assignment] Assign pack to patient Error";
    PackAssignmentActionTypes["UnassignPack"] = "[Pack-Assignment] Unassign pack to patient";
    PackAssignmentActionTypes["UnassignPackSuccess"] = "[Pack-Assignment] Unassign pack to patient Success";
    PackAssignmentActionTypes["UnassignPackError"] = "[Pack-Assignment] Unassign pack to patient Error";
    PackAssignmentActionTypes["AddExclusion"] = "[Pack-Assignment] Add pack exclusion to patient";
    PackAssignmentActionTypes["AddExclusionSuccess"] = "[Pack-Assignment] Add pack exclusion to patient Success";
    PackAssignmentActionTypes["AddExclusionError"] = "[Pack-Assignment] Add pack exclusion to patient Error";
    PackAssignmentActionTypes["RemoveExclusion"] = "[Pack-Assignment] Remove pack exclusion to patient";
    PackAssignmentActionTypes["RemoveExclusionSuccess"] = "[Pack-Assignment] Remove pack exclusion to patient Success";
    PackAssignmentActionTypes["RemoveExclusionError"] = "[Pack-Assignment] Remove pack exclusion to patient Error";
    PackAssignmentActionTypes["OpenAssignedPack"] = "[Pack-Assignment] Open Assigned Pack";
})(PackAssignmentActionTypes || (PackAssignmentActionTypes = {}));
var GetContentPacks = /** @class */ (function () {
    function GetContentPacks() {
        this.type = PackAssignmentActionTypes.GetContentPacks;
    }
    return GetContentPacks;
}());
export { GetContentPacks };
var GetContentPacksSuccess = /** @class */ (function () {
    function GetContentPacksSuccess(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.GetContentPacksSuccess;
    }
    return GetContentPacksSuccess;
}());
export { GetContentPacksSuccess };
var GetContentPacksError = /** @class */ (function () {
    function GetContentPacksError(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.GetContentPacksError;
    }
    return GetContentPacksError;
}());
export { GetContentPacksError };
var GetContentPackDetail = /** @class */ (function () {
    function GetContentPackDetail(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.GetContentPackDetail;
    }
    return GetContentPackDetail;
}());
export { GetContentPackDetail };
var GetContentPackDetailSuccess = /** @class */ (function () {
    function GetContentPackDetailSuccess(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.GetContentPackDetailSuccess;
    }
    return GetContentPackDetailSuccess;
}());
export { GetContentPackDetailSuccess };
var GetContentPackDetailError = /** @class */ (function () {
    function GetContentPackDetailError(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.GetContentPackDetailError;
    }
    return GetContentPackDetailError;
}());
export { GetContentPackDetailError };
var SelectActivePackId = /** @class */ (function () {
    function SelectActivePackId(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.SelectActivePackId;
    }
    return SelectActivePackId;
}());
export { SelectActivePackId };
var LockPackItem = /** @class */ (function () {
    function LockPackItem(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.LockPackItem;
    }
    return LockPackItem;
}());
export { LockPackItem };
var UnlockPackItem = /** @class */ (function () {
    function UnlockPackItem(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.UnlockPackItem;
    }
    return UnlockPackItem;
}());
export { UnlockPackItem };
var AssignPack = /** @class */ (function () {
    function AssignPack(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.AssignPack;
    }
    return AssignPack;
}());
export { AssignPack };
var AssignPackSuccess = /** @class */ (function () {
    function AssignPackSuccess(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.AssignPackSuccess;
    }
    return AssignPackSuccess;
}());
export { AssignPackSuccess };
var AssignPackError = /** @class */ (function () {
    function AssignPackError(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.AssignPackError;
    }
    return AssignPackError;
}());
export { AssignPackError };
var AddExclusion = /** @class */ (function () {
    function AddExclusion(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.AddExclusion;
    }
    return AddExclusion;
}());
export { AddExclusion };
var AddExclusionSuccess = /** @class */ (function () {
    function AddExclusionSuccess(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.AddExclusionSuccess;
    }
    return AddExclusionSuccess;
}());
export { AddExclusionSuccess };
var AddExclusionError = /** @class */ (function () {
    function AddExclusionError(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.AddExclusionError;
    }
    return AddExclusionError;
}());
export { AddExclusionError };
var RemoveExclusion = /** @class */ (function () {
    function RemoveExclusion(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.RemoveExclusion;
    }
    return RemoveExclusion;
}());
export { RemoveExclusion };
var RemoveExclusionSuccess = /** @class */ (function () {
    function RemoveExclusionSuccess(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.RemoveExclusionSuccess;
    }
    return RemoveExclusionSuccess;
}());
export { RemoveExclusionSuccess };
var RemoveExclusionError = /** @class */ (function () {
    function RemoveExclusionError(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.RemoveExclusionError;
    }
    return RemoveExclusionError;
}());
export { RemoveExclusionError };
var UnassignPack = /** @class */ (function () {
    function UnassignPack(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.UnassignPack;
    }
    return UnassignPack;
}());
export { UnassignPack };
var UnassignPackSuccess = /** @class */ (function () {
    function UnassignPackSuccess(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.UnassignPackSuccess;
    }
    return UnassignPackSuccess;
}());
export { UnassignPackSuccess };
var UnassignPackError = /** @class */ (function () {
    function UnassignPackError(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.UnassignPackError;
    }
    return UnassignPackError;
}());
export { UnassignPackError };
var OpenAssignedPack = /** @class */ (function () {
    function OpenAssignedPack(payload) {
        this.payload = payload;
        this.type = PackAssignmentActionTypes.OpenAssignedPack;
    }
    return OpenAssignedPack;
}());
export { OpenAssignedPack };
