import {
  AccordionSummary,
  Card,
  CircularProgress,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import {
  ArrowBack as IconArrowBack,
  ExpandMore as IconExpandMore,
} from '@material-ui/icons';
import { Button } from '@react/components/Button';
import { HelperText } from '@react/components/HelperText';
import { Label } from '@react/components/Label';
import { SIDERecipeEventType, SIDEStatus } from '@react/lib/api/enum';
import { UpdateSideRecipeRequest } from '@react/lib/api/types';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import {
  useSideRecipeMutation,
  useSIDERecipeQuery,
  useUniqueNameValidator,
} from '../../../hooks';
import ActionList from './actions';
import {
  ActionRow,
  InfoRow,
  LoaderWrapper,
  RecipeTitle,
  SectionTitle,
  StyledAccordian,
  StyledAccordionDetails,
  StyledContainer,
  StyledContent,
  StyledInput,
  StyledSelect,
  TitleWrapper,
} from './index.styled';
import RecipeTriggers from './triggers';

const NAME_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 50;

interface RecipeInput {
  name: string;
  status: keyof typeof SIDEStatus;
}

const Recipe: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const uniqueNameValidator = useUniqueNameValidator({
    delay: 300,
    errorMessage: t.Admin.SIDE.RecipeUniqueNameError,
    sideRecipeId: Number(id),
  });
  const {
    control,
    register,
    errors,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<RecipeInput>({
    mode: 'onBlur',
  });

  const { data, isLoading, isError } = useSIDERecipeQuery({
    id: Number(id),
  });
  const {
    mutate: saveRecipe,
    isError: isSavingError,
    isSuccess,
    isLoading: isSaving,
  } = useSideRecipeMutation();

  useErrorToast(isError, {
    title: t.Admin.SIDE.RecipeLoadingErrorToastHeader,
    message: t.Admin.SIDE.RecipeLoadingErrorToastMessage,
  });

  useErrorToast(isSavingError, {
    title: t.Admin.SIDE.RecipesSavingErrorHeader,
    message: t.Admin.SIDE.RecipesSavingErrorMessage,
  });

  useSuccessToast(isSuccess, {
    title: t.Admin.SIDE.RecipesSavingSuccessHeader,
    message: t.Admin.SIDE.RecipesSavingSuccessMessage,
  });

  const onSubmit: SubmitHandler<RecipeInput> = async (formData) => {
    const recipeBody: UpdateSideRecipeRequest = {
      ...formData,
      id: Number(id),
    };

    saveRecipe(recipeBody, {
      onSuccess: () => {
        // @ts-ignore
        reset({
          name: formData.name,
          status: formData.status,
        });
      },
    });
  };

  if (isLoading) {
    return (
      <Card>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </Card>
    );
  }

  if (isError || !data) {
    return <Card data-testid="error" />;
  }

  const { data: recipe } = data as NonNullable<typeof data>;

  return (
    <>
      <TitleWrapper>
        <IconButton component={Link} to="/admin/side/recipes" size="small">
          <IconArrowBack />
        </IconButton>
        <RecipeTitle variant="h5" role="heading">
          {recipe.name} ({recipe.id})
        </RecipeTitle>
      </TitleWrapper>
      <StyledAccordian>
        <AccordionSummary expandIcon={<IconExpandMore />} id="recipe-settings">
          <SectionTitle>{t.Admin.SIDE.SettingsHeader}</SectionTitle>
        </AccordionSummary>
        <StyledAccordionDetails>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <InfoRow>
              <StyledContent width={400}>
                <Label>{t.Admin.SIDE.RecipeNameLabel}</Label>
                <StyledInput
                  data-testid="nameInput"
                  fullWidth={true}
                  defaultValue={recipe.name}
                  id="name"
                  name="name"
                  error={!!errors.name}
                  ref={register({
                    required: {
                      message: t.Admin.SIDE.RecipeNameRequiredErrorMessage,
                      value: true,
                    },
                    minLength: {
                      message: t.Admin.SIDE.RecipeNameErrorLength(
                        NAME_MIN_LENGTH,
                        NAME_MAX_LENGTH,
                      ),
                      value: NAME_MIN_LENGTH,
                    },
                    maxLength: {
                      message: t.Admin.SIDE.RecipeNameErrorLength(
                        NAME_MIN_LENGTH,
                        NAME_MAX_LENGTH,
                      ),
                      value: NAME_MAX_LENGTH,
                    },
                    validate: uniqueNameValidator,
                  })}
                />
                <HelperText
                  aria-live="polite"
                  error={!!errors.name}
                  id="nameHelper"
                >
                  {errors.name != null ? errors.name.message : null}
                </HelperText>
              </StyledContent>
              <StyledContent>
                <Label>{t.Admin.SIDE.RecipeStatusLabel}</Label>
                <Controller
                  as={
                    <StyledSelect
                      disableUnderline
                      value={recipe.status}
                      label="status"
                      labelId="status"
                      ref={register()}
                    >
                      <MenuItem value={SIDEStatus.Active}>
                        {t.Admin.SIDE['ActiveStatus']}
                      </MenuItem>
                      <MenuItem value={SIDEStatus.Inactive}>
                        {t.Admin.SIDE['InactiveStatus']}
                      </MenuItem>
                    </StyledSelect>
                  }
                  name="status"
                  control={control}
                  defaultValue={recipe.status}
                />
              </StyledContent>
            </InfoRow>
            {recipe.eventname !== SIDERecipeEventType.Registration && (
              <RecipeTriggers recipe={recipe} />
            )}
            <ActionRow>
              <StyledContent fontStyle="Italic" width={600}>
                {t.Admin.SIDE.RecipeTriggerInfo}
              </StyledContent>
              <StyledContent>
                <Button
                  data-testid="btn_save"
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={!isDirty || isSaving}
                >
                  <>{t.Admin.SIDE.RecipeSaveButtonText}</>
                </Button>
              </StyledContent>
            </ActionRow>
          </form>
        </StyledAccordionDetails>
      </StyledAccordian>
      <StyledContainer>
        <SectionTitle>{t.Admin.SIDE.ActionHeader}</SectionTitle>
        <ActionList recipe={recipe} />
      </StyledContainer>
    </>
  );
};

export default Recipe;
