import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import * as fromAuth from '../reducers';
import * as AuthActions from '../actions/auth.actions';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(_store) {
        this._store = _store;
    }
    AuthGuard.prototype.canActivate = function () {
        var _this = this;
        return this._store.pipe(select(fromAuth.getUser), map(function (user) {
            if (!user) {
                _this._store.dispatch(new AuthActions.LoginRedirect());
                return false;
            }
            if (!user.jwtToken) {
                _this._store.dispatch(new AuthActions.Logout({ isCognito: false }));
                return false;
            }
            return true;
        }), take(1));
    };
    return AuthGuard;
}());
export { AuthGuard };
