<div
  *ngIf="someSelected()"
  class="action-controls"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="20px"
>
  <div class="header">
    {{ text.Actions }}
  </div>
  <button
    *ngIf="noSyncedItemsSelected()"
    mat-raised-button
    class="portal-btn btn-primary"
    (click)="moveItems()"
    [disabled]="!contentEditorEnabled"
  >
    {{ text.MoveItems }}
    <mat-icon>archive</mat-icon>
  </button>
  <button
    *ngIf="noSyncedItemsSelected()"
    mat-raised-button
    class="portal-btn btn-warn"
    (click)="deleteItems()"
    [disabled]="!contentEditorEnabled"
  >
    {{ text.DeleteItems }}
    <mat-icon>delete_forever</mat-icon>
  </button>
  <button
    *ngIf="!isGlobalPortal && noLinkedItemsSelected()"
    mat-raised-button
    class="portal-btn btn-primary"
    (click)="linkItems()"
    [disabled]="!contentEditorEnabled"
  >
    {{ text.Link }}
    <mat-icon>compare_arrows</mat-icon>
  </button>
  <button
    mat-raised-button
    class="portal-btn btn-primary"
    (click)="addToContentPack()"
    *ngIf="!isGlobalPortal"
    [disabled]="!contentEditorEnabled"
  >
    {{ text.AddToPack }}
    <mat-icon>move_to_inbox</mat-icon>
  </button>
</div>
<div
  fxFlexFill
  *ngIf="categoryId === 0"
  class="no-folders"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
>
  <div class="end">
    {{ text.PressMe }}
  </div>
  <button
    matTooltip="{{ text.CreateNewFolder }}"
    mat-fab
    color="primary"
    (click)="createFolder.emit()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <div>
    {{ text.IconFolder }}
  </div>
</div>
<div
  *ngIf="categoryId !== 0"
  class="content-table-container"
  [class.empty]="content.length === 0"
  [class.selection]="someSelected()"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <perfect-scrollbar fxFlex="auto" [config]="config" [scrollIndicators]="true">
    <table mat-table [dataSource]="dataSource" class="content-table" matSort>
      <!-- Selection Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [disabled]="!contentEditorEnabled"
            *ngIf="contentEditorEnabled"
          >
          </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          class="selection-row"
          (click)="$event.stopPropagation()"
          [matTooltip]="selectTooltip(row)"
          [matTooltipPosition]="'above'"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="!contentEditorEnabled"
            *ngIf="contentEditorEnabled"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Has item come from EMR? -->
      <ng-container matColumnDef="fromDataSync">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-icon [matTooltip]="text.LinkedToEMR" matTooltipPosition="above"
            >link</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            [matTooltip]="text.LinkedToEMR"
            matTooltipPosition="above"
            *ngIf="element.fromDataSync"
            >link
          </mat-icon>
        </td>
      </ng-container>

      // Translations
      <ng-container matColumnDef="translations">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-icon [matTooltip]="text.Translated" matTooltipPosition="above"
            >translate</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            [matTooltip]="text.TranslatedRow(element.translations?.join(', '))"
            matTooltipPosition="above"
            *ngIf="element.translations != null"
            >translate
          </mat-icon>
        </td>
      </ng-container>

      <!-- Link Column -->
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-icon
            [matTooltip]="text.ContentHasLink"
            matTooltipPosition="above"
            >compare_arrows</mat-icon
          >
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="selection-row"
          (click)="$event.stopPropagation()"
        >
          <div
            [matTooltip]="element.link !== 0 ? text.ViewLinks : ''"
            matTooltipPosition="above"
            (click)="contentEditorEnabled && handleLinkClick(element)"
          >
            <mat-icon *ngIf="element.link === 2">call_received</mat-icon>
            <mat-icon *ngIf="element.link === 1">call_made</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ text.Title }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
        >
          <div>
            {{ element.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isEmpty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ text.Empty }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.isEmpty" class="badge is-empty">
            {{ text.Empty }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="sendToAll">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ text.SendTo }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.sendToAll" class="badge send-to-all">
            {{ text.AllPatients }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="salveModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ text.DateChanged }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.salveModified | shortDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastModifiedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ text.UpdatedBy }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastModifiedBy | titlecase }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        class="header-row"
        *matHeaderRowDef="displayedColumns$ | async; sticky: true"
      ></tr>
      <tr
        mat-row
        class="content-list-table-row"
        *matRowDef="let row; columns: displayedColumns$ | async"
        (click)="goToContent(row)"
      ></tr>
    </table>
  </perfect-scrollbar>
  <mat-paginator
    *ngIf="content.length > 0"
    [pageSize]="15"
    [pageSizeOptions]="[5, 10, 15, 20, 50]"
    showFirstLastButtons
  ></mat-paginator>
  <div
    *ngIf="content.length === 0"
    class="empty-message"
    fxFlexFill
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
  >
    <div>
      {{ text.NoContent }}
    </div>
    <button
      *ngIf="!filterActive"
      mat-raised-button
      class="portal-btn btn-warn"
      (click)="deleteFolder()"
      [disabled]="!contentEditorEnabled"
    >
      {{ text.DeleteFolder }}
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</div>
