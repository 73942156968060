import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromSettings from '../../../settings/reducers';
import * as fromAuth from '../../reducers';
import * as AuthActions from '../../actions/auth.actions';
import { NavigationService } from 'src/app/core/services/navigation.service';
var EmailOTPPageContainer = /** @class */ (function () {
    function EmailOTPPageContainer(_store, _storeRoot, _navigationService) {
        this._store = _store;
        this._storeRoot = _storeRoot;
        this._navigationService = _navigationService;
        this._store.dispatch(new AuthActions.ClearLoginError());
        this.toastText$ = this._storeRoot.pipe(select(fromSettings.getSectionTranslations('Authentication')));
        this.loginText$ = this._storeRoot.pipe(select(fromSettings.getSectionTranslations('Login')));
        this.cognitoUser$ = this._store.pipe(select(fromAuth.getCognitoUser));
        this.loggingIn$ = this._store.pipe(select(fromAuth.isLoggingIn));
        this.OTPError$ = this._store.pipe(select(fromAuth.OTPError));
        this._store.dispatch(new AuthActions.ClearCodeGenerated());
    }
    EmailOTPPageContainer.prototype.ngOnInit = function () {
        this._store.dispatch(new AuthActions.EnableForm());
    };
    EmailOTPPageContainer.prototype.verifyOTP = function (_a) {
        var user = _a.user, code = _a.code, toast = _a.toast;
        this._store.dispatch(new AuthActions.VerifyOTP({ user: user, code: code, toast: toast }));
    };
    EmailOTPPageContainer.prototype.cancelOTPLogin = function () {
        this._navigationService.navigate(['/login']);
    };
    return EmailOTPPageContainer;
}());
export { EmailOTPPageContainer };
