import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { format, parseISO } from 'date-fns';
import { combineLatest, of, Subscription, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NewVideoCallComponent } from 'src/app/core/containers/new-video-call/new-video-call.component';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { ScriptInjectorService } from 'src/app/core/services/script-injector.service';
import * as fromAuth from '../../../auth/reducers';
import { DiscardMessagePromptReactComponent } from '../../../core/containers/new-message/discard-message-prompt.component';
import { NewMessageReactComponent } from '../../../core/containers/new-message/new-message-react.component';
import * as fromMessages from '../../../messaging/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as ContentActions from '../../actions/content-assignment.actions';
import * as PatientsActions from '../../actions/patient.actions';
import { PatientSection } from '../../components/patient-info/patient-info.component';
import { Auth } from 'aws-amplify';
import * as fromPatients from '../../reducers';
import { environment } from 'src/environments/environment';
var PatientsDetailComponent = /** @class */ (function () {
    function PatientsDetailComponent(_store, dialog, _localisationService, _scriptInjectorService, _route) {
        var _this = this;
        this._store = _store;
        this.dialog = dialog;
        this._localisationService = _localisationService;
        this._scriptInjectorService = _scriptInjectorService;
        this._route = _route;
        this.newMessageDialogRef = null;
        this.viewDate = new Date();
        this.sections$ = of([
            {
                type: PatientSection.patientInformation,
                Name: 'PatientInfo',
                isCore: true
            },
            {
                type: PatientSection.partner,
                Name: 'Partner',
                isCore: false
            },
            {
                type: PatientSection.contact,
                Name: 'Contact',
                isCore: true
            },
            {
                type: PatientSection.labResults,
                Name: 'LabResults',
                isCore: false
            },
            {
                type: PatientSection.manageContent,
                Name: 'ManageContent',
                isCore: true
            },
            {
                type: PatientSection.patientDocuments,
                Name: 'PatientDocuments',
                isCore: true
            }
        ]);
        // Component Subscriptions
        this._subs = new Subscription();
        this.closeNewMessage = function (_a) {
            var hasChanged = _a.hasChanged;
            if (hasChanged) {
                _this.openDiscardMessageConfirmationPrompt();
                return;
            }
            _this.newMessageDialogRef.close();
        };
        this._route.queryParams.subscribe(function (params) {
            if (params.date) {
                _this.viewDate = new Date(params.date);
            }
        });
        this.medicationManagerLoaded$ = from(_scriptInjectorService
            .loadScript('salve-medication-template-picker', environment.medicationManagerUrl)
            .then(function () { return true; })
            .catch(function () { return false; }));
        this.loggedInToken$ = from(Auth.currentSession().then(function (session) {
            var token = session.getIdToken().getJwtToken();
            return token;
        }));
        // General
        // ================================================
        this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
        this.labResultsEnabled$ = this._store.pipe(select(fromAuth.getLabResultsEnabled));
        this.patientInfoText$ = this._store.pipe(select(fromSettings.getSectionTranslations('PatientInfo')));
        this.patientCalendarText$ = this._store.pipe(select(fromSettings.getSectionTranslations('PatientCalendar')));
        this._subs.add(this.patientCalendarText$.subscribe(function (t) { return (_this.calendarText = t); }));
        this.patientLoading$ = this._store.pipe(select(fromPatients.isPatientLoading));
        this.sectionsToShow$ = combineLatest(this.sections$, this.isCoreClinic$, this.labResultsEnabled$).pipe(map(function (_a) {
            var sections = _a[0], isCore = _a[1], labResultsEnabled = _a[2];
            // initially filter based on whether clinic is Core
            var initial = isCore ? sections.filter(function (s) { return s.isCore; }) : sections;
            // filter - skip section check if it's not lab result, else check show value
            var filtered = initial.filter(function (s) {
                var skipSection = s.Name !== 'LabResults';
                var result = skipSection || (!skipSection && labResultsEnabled);
                return result;
            });
            return filtered;
        }));
        this.patientId$ = this._store.pipe(select(fromRoot.getRouterPatientId));
        this.activeSection$ = this._store.pipe(select(fromPatients.getActiveSection));
        this.activePatient$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }));
        this.topLevelPatientData$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }), map(function (patient) {
            if (patient) {
                // assign patient data to selectedPatient for use in outbound
                _this.selectedPatient = {
                    Id: patient.Patient.Id,
                    FirstName: patient.Patient.FirstName,
                    LastName: patient.Patient.LastName,
                    PatientIdentifier: patient.Patient.PatientIdentifier,
                    DateOfBirth: patient.Patient.DateOfBirth
                };
                return {
                    id: patient.Patient.Id,
                    name: patient.Patient.FirstName + " " + patient.Patient.LastName,
                    patientIdentifier: patient.Patient.PatientIdentifier
                };
            }
        }));
        this.messages$ = this._store.pipe(select(fromMessages.getMessageList));
        this.messages$.subscribe(function (messages) { return (_this.messages = messages.slice()); });
        // For Patient Detail Sections
        // ================================================
        this.patientInfo$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }), map(function (patient) {
            if (patient) {
                var p = patient.Patient;
                return {
                    id: p.Id,
                    firstName: p.FirstName,
                    lastName: p.LastName,
                    dateOfBirth: p.DateOfBirth,
                    age: p.Age,
                    numberOfCycles: p.NumberOfCycles,
                    patientIdentifier: p.PatientIdentifier,
                    patientAccountCreated: p.PatientAccountCreatedDateUtc,
                    latestLogin: p.LatestLoginUtc,
                    clinicPhysician: p.ClinicPhysician,
                    assignedProfessionals: p.AssignedProfessionals
                };
            }
        }));
        this.partnerInfo$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }), map(function (patient) {
            if (patient) {
                var p = patient.Patient;
                if (p.PartnerFirstName ||
                    p.PartnerLastName ||
                    p.PartnerDateOfBirth ||
                    p.PartnerAge) {
                    return {
                        firstName: p.PartnerFirstName,
                        lastName: p.PartnerLastName,
                        dateOfBirth: p.PartnerDateOfBirth,
                        age: p.PartnerAge
                    };
                }
                else {
                    return null;
                }
            }
        }));
        this.contactInfo$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }), map(function (patient) {
            if (patient) {
                var p = patient.Patient;
                var patientInfo = {
                    email: p.Email || ' '
                };
                if (p.PhoneWork || p.MobileWork) {
                    patientInfo.workPhones = {
                        mobile: p.MobileWork || ' ',
                        landline: p.PhoneWork || ' '
                    };
                }
                if (p.PhonePrivate || p.MobilePrivate) {
                    patientInfo.personalPhones = {
                        mobile: p.MobilePrivate || ' ',
                        landline: p.PhonePrivate || ' '
                    };
                }
                return patientInfo;
            }
        }));
        // For Patient Lab Results
        // ================================================
        this.labResults$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }), map(function (patient) {
            if (patient) {
                return patient.LabTests.map(function (r) {
                    return {
                        Date: "" + _this._localisationService.getShortDateWithTime(r.Date),
                        ResultDate: "" + _this._localisationService.getShortDate(r.ResultDate),
                        Investigation: r.Investigation,
                        Remarks: r.Remarks,
                        Result: r.Result === null ? null : r.Result.toString(),
                        Unit: r.Unit,
                        ResultText: r.ResultText,
                        ReferenceRange: r.ReferenceRange
                    };
                }).slice();
            }
        }));
        // For Patient Calendar
        // ================================================
        this.events$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromPatients.getPatientById(id)));
        }), map(function (patient) {
            if (patient) {
                return patient.Medication.map(function (m) { return ({
                    start: new Date(m.DueDate),
                    end: new Date(m.DueDate),
                    title: _this._localisationService.getShortDateWithTime(m.DueDate) + " -" +
                        m.DrugName +
                        ' (' +
                        m.Dosage +
                        m.DosageUnit +
                        ')',
                    incrementsBadgeTotal: !m.Taken,
                    cssClass: "medicine " + (m.Taken ? 'taken' : 'not-taken')
                }); }).concat(patient.Appointments.map(function (a) {
                    var localisedStartDate = _this._localisationService.getShortDateWithTime(a.StartDate);
                    return {
                        start: new Date(a.StartDate),
                        title: a.IsProvisional
                            ? _this.calendarText.EventLabelAppointmentProvisional(localisedStartDate, a.AppointmentType)
                            : _this.calendarText.EventLabelAppointment(localisedStartDate, a.AppointmentType),
                        cssClass: "appointment " + (a.IsProvisional ? 'provisional' : 'not-provisional') + "\n                " + (a.Active && a.DisplayToPatient ? '' : 'hidden'),
                        isProvisional: a.IsProvisional
                    };
                }), patient.VideoCalls.map(function (c) { return ({
                    start: new Date(c.scheduleddate),
                    title: format(parseISO(c.scheduleddate), 'HH:mm - ') + " " + c.description + " (" + c.host + ") (" + c.duration + " " + _this.patientInfoText.Minutes + ")",
                    cssClass: 'video-call'
                }); }));
            }
        }));
        // For options
        this.videoCallsEnabled$ = this._store.pipe(select(fromAuth.getVideoCallsEnabled));
        this.internalClinicId$ = this._store.pipe(select(fromAuth.getInternalClinicId));
        this.clinicGroupId$ = this._store.pipe(select(fromAuth.getClinicGroupId));
        this.medicationManagerEnabled$ = this._store.pipe(select(fromAuth.medicationManagerEnabled));
    }
    PatientsDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._store.dispatch(new PatientsActions.LoadingPatientInformation());
        this._subs.add(this.patientId$.subscribe(function (id) {
            if (id) {
                _this._store.dispatch(new ContentActions.SetPatientId(id));
                _this._store.dispatch(new PatientsActions.LoadPatientInformation(id));
            }
        }));
        this._subs.add(this.patientInfoText$.subscribe(function (t) { return (_this.patientInfoText = t); }));
    };
    PatientsDetailComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    PatientsDetailComponent.prototype.changeActiveSection = function (section) {
        this._store.dispatch(new PatientsActions.ChangeActiveSection(section));
        if (section === 'patientDocuments') {
            this._store.dispatch(new ContentActions.SetContentTypeToPatientDocs());
        }
        if (section === 'manageContent') {
            this._store.dispatch(new ContentActions.SetContentTypeToNonPatientDocs());
        }
    };
    PatientsDetailComponent.prototype.openDiscardMessageConfirmationPrompt = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DiscardMessagePromptReactComponent, {
            panelClass: 'reduced-padding-dialog',
            maxWidth: '100%',
            autoFocus: false,
            closeOnNavigation: true,
            disableClose: true,
            data: {
                onDiscard: function () { return _this.dialog.closeAll(); },
                onCancel: function () { return dialogRef.close(); }
            }
        });
        dialogRef.afterClosed().subscribe(function () { });
    };
    PatientsDetailComponent.prototype.openNewMessage = function () {
        this.newMessageDialogRef = this.dialog.open(NewMessageReactComponent, {
            panelClass: 'reduced-padding-dialog',
            maxWidth: '100%',
            autoFocus: false,
            closeOnNavigation: true,
            disableClose: true,
            data: {
                patient: this.selectedPatient,
                close: this.closeNewMessage
            }
        });
        this.newMessageDialogRef.afterClosed().subscribe(function () { });
    };
    PatientsDetailComponent.prototype.openVideoCall = function () {
        var dialogSize = this._getDialogSize();
        var dialogRef = this.dialog.open(NewVideoCallComponent, {
            panelClass: 'reduced-padding-dialog',
            width: dialogSize.width + "vw",
            height: '500px',
            autoFocus: false,
            closeOnNavigation: true,
            data: { patient: this.selectedPatient },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(function (val) { });
    };
    PatientsDetailComponent.prototype._getDialogSize = function () {
        var target = this.messageContainer.nativeElement;
        var screenWidth = target.scrollWidth;
        var width = 65;
        if (screenWidth > 1100) {
            width = 50;
        }
        else if (screenWidth > 900) {
            width = 65;
        }
        return {
            width: width
        };
    };
    return PatientsDetailComponent;
}());
export { PatientsDetailComponent };
