import {
  List,
  ListItem,
  ListItemText,
  Omit,
  Paper,
  Portal,
} from '@material-ui/core';
import React, { ComponentProps, FC, useMemo, VFC } from 'react';
import {
  createEditor,
  Editor,
  InsertTextOperation,
  Range,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from 'slate-react';
import {
  EditorContainer,
  EditorStyled,
  ParagraphSpan,
  StyledCharacterCount,
  Toolbar,
  VariablesButton,
} from './TemplateEditor.styled';
import {
  deserialize,
  getLastThreeCharacters,
  getLastThreeCharactersRange,
  insertVariable,
  serialize,
} from './TemplateEditor.utils';

interface TemplateEditorProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof EditorStyled>,
    'onChange'
  > {
  'data-testid'?: string;
  error?: boolean;
  insertVariablesButtonText: string;
  maxLength: number;
  onBlur?: React.FocusEventHandler;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  variables: string[];
}

// The reason for the many @ts-ignore is that even though we've altered the declarations
// for Slate it seems our TypeScript version is too old and it fails to merge them
// properly.

export const TemplateEditor: VFC<TemplateEditorProps> = ({
  'data-testid': dataTestId,
  error,
  insertVariablesButtonText,
  maxLength,
  onBlur,
  onChange,
  value,
  variables,
  ...rest
}) => {
  // Using the "callback ref" pattern here to ensure we can act on a ref being
  // set (https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node).
  const [portalRef, setPortalRef] = React.useState<HTMLDivElement | null>(null);
  // The current "target" Slate range where the user is typing
  const [target, setTarget] = React.useState<Range | void>();
  // The currently selected variable index in the popup list
  const [variableIndex, setVariableIndex] = React.useState(0);
  // The current set of characters the user has entered in variable mode
  const [search, setSearch] = React.useState('');

  const renderElement = React.useCallback<
    NonNullable<ComponentProps<typeof Editable>['renderElement']>
  >((props) => <Element {...props} />, []);

  const editor = useMemo(
    () => withReact(withHistory(createEditor() as ReactEditor)),
    [],
  );

  const matchingVariables = React.useMemo(() => {
    const searchLowerCase = search.toLowerCase();

    const others = variables
      .filter((c) => c.toLowerCase().includes(searchLowerCase))
      .slice(0, 10);

    // If the user has started entering a custom variable we'll
    // have no matches and can allow them to use what they're
    // entering.
    return others.length > 0 ? others : [search];
  }, [search, variables]);

  const handleKeyDown = React.useCallback<
    NonNullable<React.ComponentProps<typeof EditorStyled>['onKeyDown']>
  >(
    (event) => {
      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex =
              variableIndex >= matchingVariables.length - 1
                ? 0
                : variableIndex + 1;
            setVariableIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex =
              variableIndex <= 0
                ? matchingVariables.length - 1
                : variableIndex - 1;
            setVariableIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertVariable(editor, matchingVariables[variableIndex]);
            setTarget();
            break;
          case 'Escape':
            event.preventDefault();
            setTarget();
            break;
        }
      }
    },
    [editor, variableIndex, matchingVariables, target],
  );

  const handleChange = React.useCallback<
    NonNullable<React.ComponentProps<typeof Slate>['onChange']>
  >(
    (newValue) => {
      const isSelectionChangeOnly = editor.operations.every(
        (op) => 'set_selection' === op.type,
      );

      // Moved the cursor so cancel any in progress variable mode
      if (isSelectionChangeOnly) {
        setVariableIndex(0);
        setSearch('');
        setTarget();
        return;
      }

      // Propagate any change in value
      onChange(serialize(newValue));

      const { operations, selection } = editor;
      if (selection == null || !Range.isCollapsed(selection)) {
        return;
      }
      const [start] = Range.edges(selection);
      const insertTextOp = operations.find(
        (op) => op.type === 'insert_text',
      ) as InsertTextOperation | undefined;

      // No current variable in progress
      if (target == null) {
        // We start caring when the user enters a "{" as after the third
        // one we enter variable mode.
        if (insertTextOp == null || !insertTextOp.text.includes('{')) {
          return;
        }

        const beforeRange = getLastThreeCharactersRange(editor, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/^\{\{\{$/);

        if (beforeMatch != null) {
          setVariableIndex(0);
          setSearch('');
          setTarget(beforeRange);
        }

        return;
      }

      if (insertTextOp != null) {
        // If user has entered a "}" we need to check if it's the
        // third one and therefore exit variable mode.
        if (insertTextOp.text === '}') {
          const beforeText = getLastThreeCharacters(editor, start);
          const beforeMatch = beforeText && beforeText.match(/^\}\}\}$/);

          if (beforeMatch != null) {
            setVariableIndex(0);
            setSearch('');
            setTarget();
            return;
          }
        }
      }

      const afterRange = Editor.range(editor, target as Range, start);
      const afterText = afterRange && Editor.string(editor, afterRange);

      // Has user removed any opening braces?
      if (!afterText.startsWith('{{{')) {
        setVariableIndex(0);
        setSearch('');
        setTarget();
        return;
      }
      const afterMatch = afterText && afterText.match(/^\{\{\{(.*)$/);

      if (afterMatch != null) {
        // Will stop any braces showing in the popup list
        setSearch(afterMatch[1].replace(/\{|\}/g, ''));
        setTarget(afterRange);
      }
    },
    [editor, onChange, target],
  );

  const handleInsertVariableButtonClick = React.useCallback<
    NonNullable<React.ComponentProps<'button'>['onClick']>
  >(() => {
    if (target != null) {
      setSearch('');
      setTarget();
      return;
    }

    ReactEditor.focus(editor);
    setTimeout(() => editor.insertText('{{{'), 10);
  }, [editor, target]);

  const handleVariableListItemClick = React.useCallback<
    NonNullable<React.ComponentProps<typeof ListItem>['onClick']>
  >(
    (e) => {
      const variable = e.currentTarget.dataset.variable;
      if (variable != null) {
        ReactEditor.focus(editor);
        Transforms.select(editor, target as Range);
        insertVariable(editor, variable);
        setTarget();
      }
    },
    [editor, target],
  );

  // Move the portal containing the menu to just below where
  // the user is typing.
  React.useLayoutEffect(() => {
    // We need the "callback ref" pattern so we get a re-render
    // when the "ref" has been set and can then move the menu
    // to the correct place.
    if (target && matchingVariables.length > 0 && portalRef != null) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      portalRef.style.top = `${rect.top + window.pageYOffset + 24}px`;
      portalRef.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [matchingVariables.length, editor, target, portalRef]);

  // Reset the user's selection if the list of variables
  // has shrunk enough to remove their previously highlighted variable
  React.useEffect(() => {
    if (target != null && variableIndex >= matchingVariables.length) {
      setVariableIndex(0);
    }
  }, [variableIndex, matchingVariables, target]);

  return (
    <Slate editor={editor} onChange={handleChange} value={deserialize(value)}>
      <EditorContainer data-testid={dataTestId} error={error}>
        <EditorStyled
          {...rest}
          autoComplete="off"
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          renderElement={renderElement}
        />
        <StyledCharacterCount length={value.length} maxLength={maxLength} />
        <Toolbar>
          <VariablesButton
            onClick={handleInsertVariableButtonClick}
            size="small"
            type="button"
            variant="text"
          >
            {insertVariablesButtonText}
          </VariablesButton>
        </Toolbar>
      </EditorContainer>
      {target != null && matchingVariables.length > 0 && (
        <Portal>
          <Paper
            elevation={5}
            ref={setPortalRef}
            style={{
              top: '-9999px',
              left: '-9999px',
              position: 'absolute',
              zIndex: 1,
            }}
          >
            <List>
              {matchingVariables.map((variable, i) => (
                <ListItem
                  button
                  data-variable={variable}
                  key={variable}
                  onClick={handleVariableListItemClick}
                  selected={i === variableIndex}
                >
                  <ListItemText>{variable}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Portal>
      )}
    </Slate>
  );
};

const Element: FC<RenderElementProps> = (props) => {
  const { attributes, children, element } = props;
  // @ts-ignore
  switch (element.type) {
    default:
      return <ParagraphSpan {...attributes}>{children}</ParagraphSpan>;
  }
};
