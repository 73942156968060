import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { User } from '../models/user';
import * as fromAuth from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';
import * as fromOptions from './options.reducer';

// Interfaces
// =========================================================
export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
  clinicOptions: fromOptions.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

// Reducer Definition, and MetaReducers
// =========================================================
export const reducers: ActionReducerMap<AuthState> = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
  clinicOptions: fromOptions.reducer
};

// Selectors
// =========================================================
export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state.status
);

/** Returns the clinic token. */
export const getClinicId = createSelector(
  selectAuthStatusState,
  fromAuth.getClinicId
);

export const getLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getLoggedIn
);

export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const getCognitoUser = createSelector(
  selectAuthStatusState,
  fromAuth.getCognitoUser
);

export const isUserCognito = createSelector(
  selectAuthStatusState,
  fromAuth.isCognito
);

export const isLoggingIn = createSelector(
  selectAuthStatusState,
  fromAuth.isLoggingIn
);

export const OTPError = createSelector(
  selectAuthStatusState,
  fromAuth.OTPError
);

export const isResettingPassword = createSelector(
  selectAuthStatusState,
  fromAuth.isResettingPassword
);
export const isGeneratingCode = createSelector(
  selectAuthStatusState,
  fromAuth.isGeneratingCode
);
export const hasGeneratedCode = createSelector(
  selectAuthStatusState,
  fromAuth.hasGeneratedCode
);
export const hasUpdatePasswordError = createSelector(
  selectAuthStatusState,
  fromAuth.hasUpdatePasswordError
);

export const getClinicName = createSelector(
  selectAuthStatusState,
  fromAuth.getClinicName
);
export const getLanguageCode = createSelector(
  selectAuthStatusState,
  fromAuth.getLanguageCode
);
export const getAvailableToSelect = createSelector(
  selectAuthStatusState,
  fromAuth.getAvailableToSelect
);
export const getInviteCode = createSelector(
  selectAuthStatusState,
  fromAuth.getInviteCode
);
export const getCore = createSelector(selectAuthStatusState, fromAuth.getCore);

export const getUserName = createSelector(getUser, (user: User) =>
  user ? `${user.FirstName} ${user.LastName}` : null
);

export const getFirstName = createSelector(
  getUser,
  (user: User) => user.FirstName
);

export const getPublicKey = createSelector(getUser, fromAuth.getPublicKey);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginPage
);

export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.getError
);

export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending
);

export const isUpdatingPassword = createSelector(
  selectAuthStatusState,
  fromAuth.isUpdatingPassword
);

/* Clinic Options Selectors */

export const selectOptionsState = createSelector(
  selectAuthState,
  (state: AuthState) => state.clinicOptions
);

export const getClinicOptionsLoaded = createSelector(
  selectOptionsState,
  fromOptions.getClinicOptionsLoaded
);

export const getClinicOptions = createSelector(
  selectOptionsState,
  fromOptions.getClinicOptions
);

export const getVideoCallsEnabled = createSelector(
  selectOptionsState,
  fromOptions.getVideoCallsEnabled
);
export const getInternalClinicId = createSelector(
  selectAuthStatusState,
  fromAuth.getInternalClinicId
);
export const getClinicGroupId = createSelector(
  selectAuthStatusState,
  fromAuth.getClinicGroupId
);
export const medicationManagerEnabled = createSelector(
  selectOptionsState,
  fromOptions.getMedicationManagerEnabled
);

export const getVideoCallsPartnerInvite = createSelector(
  selectOptionsState,
  fromOptions.getVideoCallsPartnerInvite
);

export const getLabResultsEnabled = createSelector(
  selectOptionsState,
  fromOptions.getLabResultsEnabled
);

export const getMessageStarringEnabled = createSelector(
  selectOptionsState,
  fromOptions.getMessageStarringEnabled
);

export const getContentEditorFeatureEnabled = createSelector(
  selectOptionsState,
  fromOptions.getContentEditorEnabled
);
