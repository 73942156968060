import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ThemedGrid, ThemedImg, ThemedSlider } from './PhotoPicker.styled';
import { Placeholder } from './Placeholder';

export interface Props {
  setEditor: ((editor: any) => void) | null;
  image: string | File | null;
  isEditable: boolean;
  onHasChanged: (hasChanged: boolean) => void;
  onSelectClicked: (event: any) => void;
}

const size = 200;

export const PhotoPicker: React.FC<Props> = ({
  setEditor,
  image,
  isEditable,
  onHasChanged,
  onSelectClicked,
}) => {
  const [scale, setScale] = useState<number>(1);

  const debouncedSetHasChanged = useMemo(
    () => debounce(onHasChanged, 500),
    [onHasChanged],
  );

  const handleScaleChanged = (newValue: number) => {
    setScale(newValue);
    debouncedSetHasChanged(true);
  };

  return (
    <>
      {!image && <Placeholder onSelectClicked={onSelectClicked} />}
      {image && isEditable && (
        <div aria-label="avatarEditor" role="dialog">
          <AvatarEditor
            borderRadius={size / 2}
            image={image}
            height={size}
            width={size}
            onPositionChange={() => debouncedSetHasChanged(true)}
            ref={setEditor}
            scale={scale}
          />
          <ThemedSlider
            aria-labelledby="slider"
            max={6}
            min={1}
            onChange={(e, newValue) => handleScaleChanged(Number(newValue))}
            step={0.1}
            value={scale}
          />
        </div>
      )}
      {(image as string) && !isEditable && (
        <ThemedGrid container justifyContent="center" onClick={onSelectClicked}>
          <ThemedImg src={image as string} />
        </ThemedGrid>
      )}
    </>
  );
};
