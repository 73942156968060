import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[inputMaxLength]',
})
export class InputMaxLengthDirective {
  constructor(private elRef: ElementRef) {}

  @Input() inputMaxLength: number;
  @HostListener('input', ['$event']) onInput(e) {
    const element = this.elRef.nativeElement;
    if (element.value.length > this.inputMaxLength) {
      element.value = element.value.substring(0, this.inputMaxLength);
    }
  }
}
