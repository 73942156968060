import { HttpHeaders } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import { CognitoWrapperService } from './congito.wrapper.service';
var HttpDefaultOptions = /** @class */ (function () {
    function HttpDefaultOptions() {
    }
    return HttpDefaultOptions;
}());
export { HttpDefaultOptions };
var ContentApiCallService = /** @class */ (function () {
    function ContentApiCallService(_store, _cognito) {
        this._store = _store;
        this._cognito = _cognito;
        this._userToken$ = this._store.pipe(select(fromAuth.getPublicKey));
        this._clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
        this._apiEndpoint$ = of(environment.api.content.endpoint);
    }
    /**
     * Takes an array of observables and returns Observable array that
     * defines the content api endpoint, the default options to accompany the
     * http request (which consists of query string parameters and default
     * request headers), and any other observables necessary for the api call
     * can be added to the array of observables passed as the only parameter,
     * and will be appended to the end of the observable array that is returned.
     * containing [apiEndpoint, defaultParams, ...args]
     */
    ContentApiCallService.prototype.callApi = function (args) {
        if (args === void 0) { args = []; }
        return combineLatest.apply(void 0, [this._apiEndpoint$,
            this._userToken$,
            this._clinicToken$,
            this._cognito.getAuthSession()].concat(args)).pipe(filter(function (_a) {
            var endpoint = _a[0], userToken = _a[1], clinicToken = _a[2];
            return !!userToken && !!endpoint && !!clinicToken;
        }), mergeMap(function (_a) {
            var endpoint = _a[0], userToken = _a[1], clinicToken = _a[2], cognitoUserSession = _a[3], rest = _a.slice(4);
            var defaultHttpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: cognitoUserSession.getIdToken().jwtToken,
                }),
                params: {
                    userToken: userToken,
                    clinicToken: clinicToken,
                },
            };
            return of([endpoint, defaultHttpOptions].concat(rest));
        }));
    };
    return ContentApiCallService;
}());
export { ContentApiCallService };
