import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
export var Container = styled('div')(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  flex-grow: 1;\n  height: 100%;\n  padding: ", "px;\n  background-color: ", ";\n"], ["\n  flex-grow: 1;\n  height: 100%;\n  padding: ", "px;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var bgColor = _a.bgColor;
    return bgColor || "#fff";
});
var templateObject_1;
