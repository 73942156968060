import * as React from 'react';
import { createContext, FunctionComponent, useContext } from 'react';
import { PortalApiClient } from './client';
import { ContentApiClient } from './contentClient';
import { S3Client } from './s3Client';

interface ApiClientContextPayload {
  portal: PortalApiClient;
  s3Proxy: S3Client;
  content: ContentApiClient;
}

const ApiClientContext = createContext<ApiClientContextPayload>({
  portal: null as unknown as PortalApiClient,
  s3Proxy: null as unknown as S3Client,
  content: null as unknown as ContentApiClient,
});

export const ApiClientProvider: FunctionComponent<ApiClientContextPayload> = ({
  children,
  ...rest
}) => {
  return <ApiClientContext.Provider value={rest} children={children} />;
};

export function useApiClient() {
  return useContext(ApiClientContext);
}
