import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { FileUpload } from 'src/app/core/services/s3.service';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import { FileInStorage } from '../../models/file-in-storage';

@Component({
  selector: 'portal-attachments-table',
  templateUrl: './attachments-table.component.html',
  styleUrls: ['./attachments-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsTableComponent implements OnInit {
  @Input() text: any;
  @Input() attachments: FileInStorage[];
  @Input() contentEditorEnabled: boolean;
  @Output() removeAttachment = new EventEmitter<number>();

  public displayedColumns = [
    'id',
    'documentname',
    'filetype',
    'filesize',
    'preview',
  ];

  constructor(private _dialog: MatDialog, private _s3: FileUpload) {}

  ngOnInit(): void {
    const attachment = this.attachments[0];
    if (attachment && attachment.isGlobalFile) {
      this.displayedColumns = ['id', 'documentname', 'filetype', 'filesize'];
    }
  }

  readFile(file: FileInStorage) {
    this._s3
      .readFileFromBucket({
        url: file.uri,
      })
      .then((res) => {
        window.open(res.url, '_blank');
      });
  }

  handleRemoveAttachment(id: number) {
    const dialog = this._dialog.open(ConfirmActionComponent, {
      data: {
        message: this.text.RemoveConfirm,
        text: {
          Cancel: this.text.Cancel,
          Confirm: this.text.Remove,
        },
      },
    });
    dialog.afterClosed().subscribe((val) => {
      if (val) {
        this.removeAttachment.emit(id);
      }
    });
  }
}
