import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  Provider,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { Angulartics2Module } from 'angulartics2';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { ContentModule } from './content/content.module';
import { PortalToastComponent } from './core/components/portal-toast/portal-toast.component';
import { AppComponent } from './core/containers/app/app.component';
import { CoreModule } from './core/core.module';
import { ToastEffects } from './core/effects/toast.effects';
import { DashboardModule } from './dashboard/dashboard.module';
import { LocalisationModule } from './localisation/localisation.module';
import { MaterialModule } from './material/material.module';
import { MedicationsModule } from './medications/medications.module';
import { MessagingModule } from './messaging/messaging.module';
import { PatientsModule } from './patients/patients.module';
import { metaReducers, reducers } from './reducers';
import { SettingsModule } from './settings/settings.module';
import { SentryErrorHandler } from './shared/sentry-error-handler';
import { CustomRouterStateSerializer } from './shared/utils';
import { SuperAdminModule } from './superadmin/superadmin.module';
import { VideoCallsModule } from './video-calls/video-calls.module';

// initialState for router reducer
const initialRouterState = {
  state: {
    section: 'Dashboard',
    url: '/',
    params: {},
    queryParams: {},
  },
  navigationId: 0,
};

const initialState = {
  router: initialRouterState,
};

// Toast options
const toastOptions = {
  closeButton: true,
  timeout: 5000,
  positionClass: 'toast-bottom-left',
  maxOpened: 3,
  autoDismiss: true,
  toastClass: 'toast custom-toast',
  preventDuplicates: true,
  toastComponent: PortalToastComponent,
};

const providers: Provider[] = [
  {
    provide: 'Window',
    useValue: window,
  },
  ...(environment.environment !== 'local'
    ? [
        {
          provide: RouterStateSerializer,
          useClass: CustomRouterStateSerializer,
        },
        {
          provide: ErrorHandler,
          useClass: SentryErrorHandler,
        },
      ]
    : [
        {
          provide: RouterStateSerializer,
          useClass: CustomRouterStateSerializer,
        },
      ]),
];

@NgModule({
  declarations: [PortalToastComponent],
  entryComponents: [PortalToastComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    SettingsModule,
    DashboardModule,
    MessagingModule,
    ContentModule,
    VideoCallsModule.forRoot(),
    MedicationsModule,
    PatientsModule,
    AdminModule,
    SuperAdminModule,
    NgxDnDModule,
    ClipboardModule,
    LocalisationModule.forRoot(),
    CoreModule.forRoot(),
    ToastrModule.forRoot(toastOptions),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, { metaReducers, initialState }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
      stateKey: 'router',
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'NgRx Clinic-Portal DevTools',
      logOnly:
        environment.production && environment.environment === 'production',
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([ToastEffects]),
    AuthModule.forRoot(),
    AppRoutingModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
        clearHash: true,
      },
    }),
  ],
  providers,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
