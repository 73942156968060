/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./camera.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../audio-video-indicators/audio-video-indicators.component.ngfactory";
import * as i3 from "../audio-video-indicators/audio-video-indicators.component";
import * as i4 from "@angular/common";
import * as i5 from "./camera.component";
import * as i6 from "../../services/video.chat.service";
var styles_CameraComponent = [i0.styles];
var RenderType_CameraComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CameraComponent, data: {} });
export { RenderType_CameraComponent as RenderType_CameraComponent };
function View_CameraComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["spinner", 1]], null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_CameraComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-audio-video-indicators", [["class", "audio-video-indicators"]], null, null, null, i2.View_AudioVideoIndicatorsComponent_0, i2.RenderType_AudioVideoIndicatorsComponent)), i1.ɵdid(1, 49152, null, 0, i3.AudioVideoIndicatorsComponent, [], { isAudioEnabled: [0, "isAudioEnabled"], isVideoEnabled: [1, "isVideoEnabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAudioEnabled; var currVal_1 = _co.isVideoEnabled; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CameraComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { previewElement: 0 }), i1.ɵqud(671088640, 2, { spinner: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { camera: 0, disabled: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["cameraContainer", 1]], null, 0, "div", [["class", "camera-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CameraComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, true, !_co.isVideoEnabled); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isWaiting; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isWaiting; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_CameraComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-camera", [], null, null, null, View_CameraComponent_0, RenderType_CameraComponent)), i1.ɵdid(1, 49152, null, 0, i5.CameraComponent, [i1.Renderer2, i6.VideoChatService], null, null)], null, null); }
var CameraComponentNgFactory = i1.ɵccf("portal-camera", i5.CameraComponent, View_CameraComponent_Host_0, { isAudioEnabled: "isAudioEnabled", isVideoEnabled: "isVideoEnabled" }, {}, []);
export { CameraComponentNgFactory as CameraComponentNgFactory };
