<div
  [ngClass]="{
    camera: true,
    disabled: !isVideoEnabled
  }"
>
  <div *ngIf="isWaiting" class="loader">
    <div class="spinner" #spinner></div>
  </div>
  <div class="camera-container" #cameraContainer></div>
  <portal-audio-video-indicators
    *ngIf="!isWaiting"
    class="audio-video-indicators"
    [isAudioEnabled]="isAudioEnabled"
    [isVideoEnabled]="isVideoEnabled"
  ></portal-audio-video-indicators>
</div>
