import { MatDialog, MatDialogRef } from '@angular/material';
var InvitePartnerComponent = /** @class */ (function () {
    function InvitePartnerComponent(dialogRef, dialog, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
    }
    InvitePartnerComponent.prototype.onYesClick = function () {
        this.dialogRef.close(true);
    };
    InvitePartnerComponent.prototype.onNoClick = function () {
        this.dialogRef.close(false);
    };
    return InvitePartnerComponent;
}());
export { InvitePartnerComponent };
