import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { sortByKeyAlphabetically } from 'src/app/shared/utils';
import { Language } from '../../../models/Language';
import * as fromRoot from '../../../reducers';
import {
  SavingCategory,
  SavingCategoryError,
} from '../../actions/content.actions';
import { Category, UpdateCategoryRequest } from '../../models/category';
import { validateFolderName } from '../../services/async-validators/validate-folder-name';
import {
  ContentActionTypes,
  SavingCategorySuccess,
} from './../../actions/content.actions';
import { ContentService } from './../../services/content.service';

@Component({
  selector: 'portal-edit-folder-name',
  templateUrl: './edit-folder-name.component.html',
  styleUrls: ['./edit-folder-name.component.scss'],
})
export class EditFolderNameComponent implements OnInit, OnDestroy {
  public scrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };

  public checkingName$ = new BehaviorSubject(false);
  public loadingCategory$ = new BehaviorSubject(false);

  public dataReady$ = new BehaviorSubject<boolean>(false);

  public savingName$ = new BehaviorSubject(false);
  public savedName$ = new BehaviorSubject(false);
  public errorEncountered$ = new BehaviorSubject(false);

  public languages$: Observable<Language[]>;
  public category$: Observable<Category>;

  public languages: Language[];
  public category: Category;

  public maxNameLength = 50;

  public initialFolderName: string;

  public categoryName: FormControl;

  public categoryForm: FormGroup;

  public _subs = new Subscription();

  private _sharedValidators = [
    Validators.minLength(3),
    Validators.maxLength(this.maxNameLength),
  ];

  constructor(
    private _fb: FormBuilder,
    private _store: Store<fromRoot.State>,
    private _updates: Actions,
    public dialogRef: MatDialogRef<EditFolderNameComponent>,
    private _contentService: ContentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.categoryForm = this._fb.group({});
  }

  ngOnInit() {
    this._loadFormData();

    // watch for errors on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<SavingCategoryError>(ContentActionTypes.SavingCategoryError),
        )
        .subscribe(() => {
          this.savingName$.next(false);
          this.errorEncountered$.next(true);
        }),
    );

    // watch for success on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<SavingCategorySuccess>(
            ContentActionTypes.SavingCategorySuccess,
          ),
        )
        .subscribe(() => {
          this.savingName$.next(false);
          this.savedName$.next(true);
          this.exitDialog(true, 1000);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  clearField(field: FormControl) {
    field.setValue('');
    field.markAsDirty();
    field.markAsTouched();
  }

  saveNameChange() {
    this.errorEncountered$.next(false);
    this.savingName$.next(true);
    const newFolderName = this.categoryName.value;

    const requestData: UpdateCategoryRequest = {
      id: this.category.id,
      name: newFolderName,
      translations: (this.languages || [])
        .filter((l) => {
          const field = this.categoryForm.get(l.code);
          return field.value !== '' && field.dirty;
        })
        .map((l) => ({
          languagecode: l.code,
          name: this.categoryForm.get(l.code).value,
        })),
    };

    this._store.dispatch(new SavingCategory(requestData));
  }

  cancelNameChange() {
    this.exitDialog(false);
  }

  exitDialog(status: boolean = false, time: number = 0) {
    setTimeout(() => {
      this.dialogRef.close(status);
    }, time);
  }

  private _loadFormData() {
    this.loadingCategory$.next(true);
    this.languages$ = this._store.pipe(
      select(fromRoot.getLanguages),
      map((languages) =>
        languages
          .filter((language) => !language.isdefault)
          .sort(sortByKeyAlphabetically('name')),
      ),
    );
    this.category$ = this._contentService.getContentCategory(
      this.data.category.id,
    );
    // Retrieve clinic languages and category data ta set up form
    this._subs.add(
      combineLatest(this.languages$, this.category$).subscribe(([l, c]) => {
        this.languages = l;
        this.category = c;
        // Prepare form based on data
        this._prepareForm(l, c);
        this.loadingCategory$.next(false);
      }),
    );
  }

  private _prepareForm(languages: Language[], category: Category) {
    this.initialFolderName = category.name;
    // Prepare control for category name
    this.categoryName = new FormControl(
      this.initialFolderName,
      [Validators.required, ...this._sharedValidators],
      validateFolderName(
        this._contentService,
        this.checkingName$,
        this.initialFolderName,
      ),
    );

    // This is necessary because the Material form field only shows errors
    // when a field is touched. If we don't do this then the user will see no
    // errors until they leave the field the first time.
    this.categoryName.markAsTouched();

    // Add control to form
    this.categoryForm.addControl(category.name, this.categoryName);

    const categoryTranslations: Record<string, string> = {};

    // Prepare translation only if there are languages to translate into
    if (languages) {
      const { translations = [] } = category;
      for (const language of languages) {
        categoryTranslations[language.code] = '';
        // Populate translation if translations exist
        for (const translation of translations) {
          if (translation.languagecode === language.code) {
            categoryTranslations[language.code] = translation.name;
          }
        }
      }
    }

    for (const key of Object.keys(categoryTranslations)) {
      const value = categoryTranslations[key];
      const hasExistingValue = value !== '';
      const newControl = new FormControl(
        categoryTranslations[key],
        hasExistingValue
          ? [Validators.required, ...this._sharedValidators]
          : this._sharedValidators,
      );
      this.categoryForm.addControl(key, newControl);
      // This is necessary because the Material form field only shows errors
      // when a field is touched. If we don't do this then the user will see no
      // errors until they leave the field the first time.
      newControl.markAsTouched();
    }

    this.dataReady$.next(true);
  }
}
