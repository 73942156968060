var _this = this;
import * as tslib_1 from "tslib";
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import { useDispatch } from '@react/lib/store';
import copy from 'copy-to-clipboard';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { Action, DEFAULT_TOAST_OPTIONS, StatusIdMap } from '../constants';
import { useConversationUpdateMutation } from './useConversationUpdateMutation';
import { useMessageDelete } from './useMessageDelete';
import { useMessageMutation } from './useMessageMutation';
import { useMessagesToString } from './useMessagesToString';
var isConversationUnassigned = function (conversation) {
    return conversation.assignedclinicuserid === null;
};
var ɵ0 = isConversationUnassigned;
export var useGetConversationActions = function (type) {
    var t = useTranslations().t;
    var features = useFeatures();
    var featureAssignConversation = checkFeature(features, 'messagingOptions.assignConversation');
    return function (conversation, folders) {
        var action = {
            CONVERSATION_MARK_UNREAD: {
                actionId: Action.CONVERSATION_MARK_UNREAD,
                name: t.Messages.MarkUnread,
            },
            CONVERSATION_MARK_RESOLVED: {
                actionId: Action.CONVERSATION_MARK_RESOLVED,
                name: t.Messages.MarkResolved,
            },
            CONVERSATION_MARK_UNRESOLVED: {
                actionId: Action.CONVERSATION_MARK_UNRESOLVED,
                name: t.Messages.MarkUnresolved,
            },
            CONVERSATION_ASSIGN: {
                actionId: Action.CONVERSATION_ASSIGN,
                name: t.Messages.Assign,
                conversation: conversation,
            },
            CONVERSATION_UNASSIGN: {
                actionId: Action.CONVERSATION_UNASSIGN,
                name: t.Messages.Assign,
                conversation: conversation,
                unassign: true,
            },
            CONVERSATION_MOVE_TO: {
                actionId: Action.CONVERSATION_MOVE_TO,
                name: t.Messages.Move,
                children: folders
                    .filter(function (folder) { return folder.id !== conversation.messagetypeid; })
                    .map(function (folder) { return (tslib_1.__assign({ actionId: Action.CONVERSATION_MOVE_TO }, folder)); }),
            },
            CONVERSATION_COPY_HISTORY: {
                actionId: Action.CONVERSATION_COPY_HISTORY,
                name: t.Messages.CopyMessageHistory,
            },
        };
        var actions = [];
        if (conversation.clinicread) {
            actions.push(action.CONVERSATION_MARK_UNREAD);
        }
        actions.push(conversation.resolved
            ? action.CONVERSATION_MARK_UNRESOLVED
            : action.CONVERSATION_MARK_RESOLVED);
        if (featureAssignConversation && isConversationUnassigned(conversation)) {
            actions.push(action.CONVERSATION_ASSIGN);
        }
        if (featureAssignConversation && !isConversationUnassigned(conversation)) {
            actions.push(action.CONVERSATION_UNASSIGN);
        }
        actions.push(action.CONVERSATION_MOVE_TO);
        if (type === 'chat') {
            actions.push(action.CONVERSATION_COPY_HISTORY);
        }
        return actions;
    };
};
export var useGetConversationActionHandler = function () {
    var messagesToString = useMessagesToString();
    var dispatch = useDispatch();
    var t = useTranslations().t;
    var updateConversationAsync = useConversationUpdateMutation().mutateAsync;
    var deleteMessage = useMessageDelete().mutate;
    var updateMessage = useMessageMutation().mutate;
    return function (action, conversation, messages) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var _a;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = action.actionId;
                    switch (_a) {
                        case Action.CONVERSATION_MARK_UNREAD: return [3 /*break*/, 1];
                        case Action.CONVERSATION_MARK_UNRESOLVED: return [3 /*break*/, 3];
                        case Action.CONVERSATION_MARK_RESOLVED: return [3 /*break*/, 5];
                        case Action.CONVERSATION_MOVE_TO: return [3 /*break*/, 7];
                        case Action.MESSAGE_DELETE: return [3 /*break*/, 10];
                        case Action.CONVERSATION_COPY_HISTORY: return [3 /*break*/, 11];
                    }
                    return [3 /*break*/, 12];
                case 1: return [4 /*yield*/, updateMessage(tslib_1.__assign({}, conversation, { id: conversation.messageitemid, read: false }))];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 13];
                case 3: return [4 /*yield*/, updateConversationAsync(tslib_1.__assign({}, conversation, { messagestatusid: null }))
                        .then(function () {
                        dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { title: t.MessageToast.MessageUnresolvedTitle, message: t.MessageToast.MessageUnresolvedBody, type: 'success' })));
                    })
                        .catch(function () {
                        dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { title: t.MessageToast.ErrorEncountered, message: t.MessageToast.ErrorUnresolved, type: 'error' })));
                    })];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 13];
                case 5: return [4 /*yield*/, updateConversationAsync(tslib_1.__assign({}, conversation, { messagestatusid: StatusIdMap.RESOLVED }))
                        .then(function () {
                        dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { title: t.MessageToast.MessageResolvedTitle, message: t.MessageToast.MessageResolvedBody, type: 'success' })));
                    })
                        .catch(function () {
                        dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { title: t.MessageToast.ErrorEncountered, message: t.MessageToast.ErrorResolved, type: 'error' })));
                    })];
                case 6:
                    _b.sent();
                    return [3 /*break*/, 13];
                case 7:
                    if (!action.id) return [3 /*break*/, 9];
                    return [4 /*yield*/, updateConversationAsync(tslib_1.__assign({}, conversation, { messagetypeid: action.id }))
                            .then(function () {
                            dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { title: t.MessageToast.MessageMovedTitle, message: t.MessageToast.MessageMovedBody(conversation.messagetype, action.name), type: 'success' })));
                        })
                            .catch(function () {
                            dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { title: t.MessageToast.ErrorEncountered, message: t.MessageToast.ErrorChanging, type: 'error' })));
                        })];
                case 8:
                    _b.sent();
                    _b.label = 9;
                case 9: return [3 /*break*/, 13];
                case 10:
                    if (action.id) {
                        deleteMessage({
                            messageId: action.id,
                            conversationId: conversation.messageid,
                        });
                    }
                    return [3 /*break*/, 13];
                case 11:
                    if (messages) {
                        if (copy(messagesToString(conversation, messages))) {
                            dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { message: t.Messages.HistoryCopied(conversation.patientfirstname), type: 'success' })));
                        }
                        else {
                            dispatch(new DisplayToastAction(tslib_1.__assign({}, DEFAULT_TOAST_OPTIONS, { message: t.Messages.CopyError, type: 'error' })));
                        }
                    }
                    return [3 /*break*/, 13];
                case 12: throw new Error('Invalid action');
                case 13: return [2 /*return*/];
            }
        });
    }); };
};
export { ɵ0 };
