import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromSettings from '../../../settings/reducers';
import { MedicationType } from '../../components/patient-list/patient-list.component.enums';
import { MedicationsService } from '../../services/medications.service';
var MedicationsComponent = /** @class */ (function () {
    function MedicationsComponent(_store, _navigationService, _medicationsService) {
        this._store = _store;
        this._navigationService = _navigationService;
        this._medicationsService = _medicationsService;
        this.actionRequiredLoading = false;
        this.noActionRequiredLoading = false;
        this.potentialDuplicatesLoading = false;
        this.medicationType = MedicationType;
        this._getPotentialDuplicates();
        this._getActionMedications();
        this._getNoActionMedications();
        this.medicationTableText$ = this._store.pipe(select(fromSettings.getSectionTranslations('Medication')));
    }
    MedicationsComponent.prototype.ngOnInit = function () { };
    MedicationsComponent.prototype.gotoPatient = function (selection) {
        var navigationExtras = {
            queryParams: {
                date: selection.date.toISOString().split('T')[0],
            },
        };
        this._navigationService.navigate(['/patients', selection.id], navigationExtras);
    };
    MedicationsComponent.prototype.onActionMedicationSearchTermChange = function (searchTerm) {
        this._getActionMedications(searchTerm);
    };
    MedicationsComponent.prototype.onNoActionMedicationSearchTermChange = function (searchTerm) {
        this._getNoActionMedications(searchTerm);
    };
    MedicationsComponent.prototype.onPotentialDuplicatesSearchTermChange = function (searchTerm) {
        this._getPotentialDuplicates(searchTerm);
    };
    MedicationsComponent.prototype._getActionMedications = function (searchTerm) {
        var _this = this;
        this.actionRequiredLoading = true;
        this._medicationsService
            .getActionRequiredMedications(searchTerm)
            .then(function (response) {
            _this.actionRequired = response;
            _this.actionRequiredLoading = false;
        });
    };
    MedicationsComponent.prototype._getNoActionMedications = function (searchTerm) {
        var _this = this;
        this.noActionRequiredLoading = true;
        this._medicationsService
            .getNoActionRequiredMedications(searchTerm)
            .then(function (response) {
            _this.noActionRequired = response;
            _this.noActionRequiredLoading = false;
        });
    };
    MedicationsComponent.prototype._getPotentialDuplicates = function (searchTerm) {
        var _this = this;
        this.potentialDuplicatesLoading = true;
        this._medicationsService
            .getPotentialDuplicateMedications(searchTerm)
            .then(function (response) {
            _this.potentialDuplicate = response;
            _this.potentialDuplicatesLoading = false;
        });
    };
    return MedicationsComponent;
}());
export { MedicationsComponent };
