<mat-toolbar fxLayoutAlign="start center" class="calendar-header">
  <h3 class="card-title">
    {{ patientListText.InviteCode }}{{ inviteCode$ | async }}
  </h3>
</mat-toolbar>
<mat-card class="search-card" fxLayout="row" fxLayoutAlign="start center">
  <form
    class="patient-filter-form"
    novalidate
    [formGroup]="filterForm"
    fxFlex="grow"
    fxLayout="row"
    fxLayoutAlign="start center"
    autocomplete="off"
  >
    <mat-icon>search</mat-icon>
    <input
      class="filter-input"
      type="text"
      autocomplete="none"
      id="filter"
      formControlName="filter"
      placeholder="{{ patientListText.SearchPatients }}"
      fxFlexFill
    />
  </form>
  <div *ngIf="isSearchMode$ | async">
    <button mat-button class="portal-btn btn-primary" (click)="clearSearch()">
      {{ patientListText.ClearFilter }}
    </button>
  </div>
</mat-card>
<portal-patient-list
  [text]="patientListText$ | async"
  [patients]="patients$ | async"
  [loading]="isLoading$ | async"
  (goto)="gotoPatient($event)"
  (reachedScrollEnd)="triggerNextPage()"
  (sortChange)="onSortChanged($event)"
>
</portal-patient-list>
