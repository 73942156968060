import { ErrorHandler } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import { throwError } from 'rxjs';
var LinkContentService = /** @class */ (function () {
    function LinkContentService(_httpService, _error) {
        this._httpService = _httpService;
        this._error = _error;
    }
    LinkContentService.prototype.linkContent = function (contentToLinkTo, itemsToLink) {
        return this._httpService
            .post(EndpointType.Content, "content/link/" + contentToLinkTo, { itemsToLink: itemsToLink })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    LinkContentService.prototype.fetchAvailableToLinkContent = function (categoryId) {
        return this._httpService
            .get(EndpointType.Content, "content/link/" + categoryId)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    LinkContentService.prototype.loadChildren = function (headerId) {
        return this._httpService
            .get(EndpointType.Content, "content/entries/" + headerId + "/children")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    LinkContentService.prototype.deleteContentLink = function (childId) {
        return this._httpService
            .delete(EndpointType.Content, "content/link/" + childId)
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    LinkContentService.prototype.deleteSomeContentLinks = function (children) {
        return this._httpService
            .deleteWithBody(EndpointType.Content, "content/link/children", { children: children })
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    LinkContentService.prototype.deleteAllContentLinks = function (parentId) {
        return this._httpService
            .delete(EndpointType.Content, "content/link/" + parentId + "/children")
            .pipe(map(function (res) { return res; }), catchError(function (err) {
            return throwError(err);
        }));
    };
    return LinkContentService;
}());
export { LinkContentService };
