import { useApiClient } from '@react/lib/api';
import { Conversation, Message } from '@react/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';

interface Payload {
  conversationId: Conversation['messageid'];
  messageId: Message['id'];
}

export function useMessageDelete() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    ({ conversationId, messageId }: Payload) =>
      apiClient.removeMessage(conversationId, messageId),
    {
      onSuccess() {
        Object.keys(queryCacheKey).forEach((cacheKey) => {
          queryClient.invalidateQueries(cacheKey);
        });
      },
    },
  );
}
