import * as tslib_1 from "tslib";
import { sortByKeyAlphabetically } from 'src/app/shared/utils';
import { ContentPackActionTypes, } from '../actions/packs.actions';
export var initialState = {
    packs: [],
    creatingPack: false,
    editingPack: false,
    deletingPack: false,
    loadingPacks: false,
    loadingPackDetail: false,
    addingToPack: false,
    removingFromPack: false,
    errors: {},
    contentPackDetails: {},
    activeListFilter: false,
    currentListFilter: '',
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b, _c;
    switch (action.type) {
        case ContentPackActionTypes.GetContentPacks: {
            return tslib_1.__assign({}, state, { errors: {}, loadingPacks: true });
        }
        case ContentPackActionTypes.GetContentPacksSuccess: {
            return tslib_1.__assign({}, state, { loadingPacks: false, packs: action.payload.contentPacks });
        }
        case ContentPackActionTypes.GetContentPacksError: {
            return tslib_1.__assign({}, state, { loadingPacks: false, errors: {
                    loadingPacks: action.payload.errorMessage,
                } });
        }
        case ContentPackActionTypes.GetContentPackDetail: {
            return tslib_1.__assign({}, state, { errors: {}, loadingPackDetail: true });
        }
        case ContentPackActionTypes.GetContentPackDetailSuccess: {
            var contentPackDetails = tslib_1.__assign({}, state.contentPackDetails, (_a = {}, _a[action.payload.packId] = action.payload.contentPackDetail, _a));
            return tslib_1.__assign({}, state, { loadingPackDetail: false, contentPackDetails: contentPackDetails });
        }
        case ContentPackActionTypes.GetContentPackDetailError: {
            return tslib_1.__assign({}, state, { loadingPackDetail: false, errors: tslib_1.__assign({}, state.errors, { loadingPackDetail: action.payload.errorMessage }) });
        }
        case ContentPackActionTypes.CreateContentPack: {
            return tslib_1.__assign({}, state, { creatingPack: true, errors: {} });
        }
        case ContentPackActionTypes.CreateContentPackSuccess: {
            var packs = state.packs.slice().concat(action.payload.newPackDetail)
                .sort(sortByKeyAlphabetically('name'));
            return tslib_1.__assign({}, state, { creatingPack: false, packs: packs });
        }
        case ContentPackActionTypes.CreateContentPackError: {
            return tslib_1.__assign({}, state, { creatingPack: false, errors: tslib_1.__assign({}, state.errors, { creatingPack: action.payload.errorMessage }) });
        }
        case ContentPackActionTypes.EditContentPack: {
            return tslib_1.__assign({}, state, { editingPack: true, errors: {} });
        }
        case ContentPackActionTypes.EditContentPackSuccess: {
            var _d = action.payload, packId_1 = _d.packId, packName_1 = _d.packName, isDefault_1 = _d.isDefault;
            return tslib_1.__assign({}, state, { editingPack: false, packs: state.packs.map(function (pack) {
                    return pack.id === packId_1
                        ? {
                            id: packId_1,
                            packName: packName_1,
                            isDefault: isDefault_1,
                        }
                        : pack;
                }) });
        }
        case ContentPackActionTypes.EditContentPackError: {
            return tslib_1.__assign({}, state, { editingPack: false, errors: tslib_1.__assign({}, state.errors, { editingPack: action.payload.errorMessage }) });
        }
        case ContentPackActionTypes.DeleteContentPack: {
            return tslib_1.__assign({}, state, { deletingPack: true, errors: {} });
        }
        case ContentPackActionTypes.DeleteContentPackSuccess: {
            return tslib_1.__assign({}, state, { deletingPack: false, packs: state.packs.filter(function (pack) { return pack.id !== action.payload.packId; }), errors: {} });
        }
        case ContentPackActionTypes.DeleteContentPackError: {
            return tslib_1.__assign({}, state, { deletingPack: false, errors: tslib_1.__assign({}, state.errors, { deletingPack: action.payload.errorMessage }) });
        }
        case ContentPackActionTypes.AddToContentPack: {
            return tslib_1.__assign({}, state, { addingToPack: true, errors: {} });
        }
        case ContentPackActionTypes.AddToContentPackSuccess: {
            var _e = action.payload, packId = _e.packId, contentPackDetail = _e.contentPackDetail;
            var previousDetails = state.contentPackDetails[packId] || [];
            return tslib_1.__assign({}, state, { addingToPack: false, errors: {}, contentPackDetails: tslib_1.__assign({}, state.contentPackDetails, (_b = {}, _b[packId] = previousDetails
                    .concat(contentPackDetail)
                    .sort(sortByKeyAlphabetically('name')), _b)) });
        }
        case ContentPackActionTypes.AddToContentPackError: {
            return tslib_1.__assign({}, state, { addingToPack: false, errors: {
                    addingToPack: action.payload.errorMessage,
                } });
        }
        case ContentPackActionTypes.RemoveFromContentPack: {
            return tslib_1.__assign({}, state, { removingFromPack: true, errors: {} });
        }
        case ContentPackActionTypes.RemoveFromContentPackSuccess: {
            var _f = action.payload, packId = _f.packId, deletedDetail = _f.deletedDetail;
            var previousDetails = state.contentPackDetails[packId];
            var flatDeletedDetails_1 = deletedDetail.reduce(function (a, b) { return a.concat([b.contentEntryHeaderId, b.hiddenId]); }, []);
            return tslib_1.__assign({}, state, { removingFromPack: false, contentPackDetails: tslib_1.__assign({}, state.contentPackDetails, (_c = {}, _c[packId] = previousDetails.filter(function (d) { return !flatDeletedDetails_1.includes(d.contentEntryHeaderId); }), _c)) });
        }
        case ContentPackActionTypes.RemoveFromContentPackError: {
            return tslib_1.__assign({}, state, { removingFromPack: false, errors: {
                    removingFromPack: action.payload.errorMessage,
                } });
        }
        case ContentPackActionTypes.ClearErrors: {
            return tslib_1.__assign({}, state, { errors: {} });
        }
        case ContentPackActionTypes.ClearFilter: {
            return tslib_1.__assign({}, state, { activeListFilter: false, currentListFilter: '' });
        }
        case ContentPackActionTypes.FilterContent: {
            return tslib_1.__assign({}, state, { activeListFilter: true, currentListFilter: action.payload });
        }
        default: {
            return state;
        }
    }
}
// Selectors
export var getPacks = function (state) { return state.packs; };
export var isCreatingPack = function (state) { return state.creatingPack; };
export var isEditingPack = function (state) { return state.editingPack; };
export var isDeletingPack = function (state) { return state.deletingPack; };
export var isLoadingPacks = function (state) { return state.loadingPacks; };
export var isLoadingPackDetail = function (state) { return state.loadingPackDetail; };
export var isAddingToPack = function (state) { return state.addingToPack; };
export var isRemovingFromPack = function (state) { return state.removingFromPack; };
export var getErrors = function (state) { return state.errors; };
export var getContentPackDetails = function (state) { return state.contentPackDetails; };
export var isListFilterActive = function (state) { return state.activeListFilter; };
export var getCurrentListFilter = function (state) { return state.currentListFilter; };
