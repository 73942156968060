import { OnChanges, SimpleChanges, } from '@angular/core';
import { Subject } from 'rxjs';
var PatientCalendarComponent = /** @class */ (function () {
    function PatientCalendarComponent() {
        this.events = [];
        this.view = 'month';
        this.viewDate = new Date();
        this.yesterday = new Date();
        this.exclude = [];
        this.activeDayIsOpen = true;
        this.refresh = new Subject();
        this.hasProvisionalAppointments = false;
        this.yesterday.setDate(this.yesterday.getDate() - 1);
    }
    PatientCalendarComponent.prototype.ngOnChanges = function (changes) {
        if (changes.text && changes.text.currentValue) {
            this.displayDate();
        }
        if (changes.events && changes.events.currentValue) {
            this.hasProvisionalAppointments = changes.events.currentValue.some(function (event) { return event.isProvisional; });
        }
    };
    // creates day translation for column header
    PatientCalendarComponent.prototype.getDate = function (date) {
        return this.text.Days()[date.getDay()];
    };
    PatientCalendarComponent.prototype.decrement = function () {
        this.viewDate.setMonth(this.viewDate.getMonth() - 1);
        this.displayDate();
    };
    PatientCalendarComponent.prototype.today = function () {
        this.viewDate = new Date();
        this.displayDate();
    };
    PatientCalendarComponent.prototype.increment = function () {
        this.viewDate.setMonth(this.viewDate.getMonth() + 1);
        this.displayDate();
    };
    PatientCalendarComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (events && events.length === 0) {
            this.activeDayIsOpen = false;
        }
        else if (this.viewDate.valueOf() === date.valueOf()) {
            this.activeDayIsOpen = !this.activeDayIsOpen;
        }
        else {
            this.activeDayIsOpen = true;
        }
        this.viewDate = date;
        this.displayDate();
    };
    PatientCalendarComponent.prototype.displayDate = function () {
        var monthNames = this.text ? this.text.Months().slice() : [];
        this.dateDisplayed =
            monthNames[this.viewDate.getMonth()] + ' ' + this.viewDate.getFullYear();
        this.refresh.next();
    };
    PatientCalendarComponent.prototype.addBadgeTotal = function (day) {
        day.badgeTotal = day.events.filter(function (event) { return event['incrementsBadgeTotal']; }).length;
    };
    return PatientCalendarComponent;
}());
export { PatientCalendarComponent };
