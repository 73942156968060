var _this = this;
import * as tslib_1 from "tslib";
import { Card, CardContent, CircularProgress, Divider, FormControlLabel, FormLabel, IconButton, RadioGroup, Typography } from '@material-ui/core';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';
import { Button } from '@react/components/Button';
import DataRow from '@react/components/DataRow';
import { stringToBool } from '@react/lib';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useAppointmentTypeMutation, useAppointmentTypeQuery } from '../hooks';
import { AppointmentTypeTitle, FormActions, LoaderWrapper, ProvisionalContent, ProvisionalHoursContainer, ProvisionalInput, StyledFormControl, StyledFormControlLabel, StyledRadio, TitleWrapper } from './AppointmentType.styled';
var AppointmentTypeRow = function (_a) {
    var caption = _a.caption, children = _a.children, first = _a.first, label = _a.label;
    return (React.createElement(React.Fragment, null,
        !first && React.createElement(Divider, null),
        React.createElement(CardContent, null,
            React.createElement(DataRow, { label: label, caption: caption }, children))));
};
var ɵ0 = AppointmentTypeRow;
var AppointmentType = function () {
    var id = useParams().id;
    var t = useTranslations().t;
    var localeDateTime = useLocaleDateTime();
    var _a = useForm(), control = _a.control, errors = _a.errors, _b = _a.formState, isDirty = _b.isDirty, isSubmitting = _b.isSubmitting, handleSubmit = _a.handleSubmit, reset = _a.reset, watch = _a.watch;
    var _c = useAppointmentTypeQuery({
        id: Number(id)
    }), data = _c.data, isLoading = _c.isLoading, isError = _c.isError;
    var _d = useAppointmentTypeMutation(), save = _d.mutate, isSavingError = _d.isError, isSuccess = _d.isSuccess;
    useErrorToast(isError, {
        title: t.Admin.AppointmentTypeLoadingErrorToastHeader,
        message: t.Admin.AppointmentTypeLoadingErrorToastMessage
    });
    useErrorToast(isSavingError, {
        title: t.Admin.AppointmentTypeSavingErrorHeader,
        message: t.Admin.AppointmentTypeSavingErrorMessage
    });
    useSuccessToast(isSuccess, {
        title: t.Admin.AppointmentTypeSavingSuccessHeader,
        message: t.Admin.AppointmentTypeSavingSuccessMessage
    });
    var onSubmit = function (formData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var baseRequestData, requestData;
        return tslib_1.__generator(this, function (_a) {
            if (!data) {
                throw new Error('No data available on submit');
            }
            baseRequestData = {
                id: data.data.id,
                active: stringToBool(formData.isActive),
                displaytopatient: !stringToBool(formData.isHidden),
                video: stringToBool(formData.convertToVideo)
            };
            requestData = formData.isProvisional === 'true'
                ? tslib_1.__assign({}, baseRequestData, { embargo: true, embargoperiod: formData.provisionalHours, embargoperiodname: 'Hour' }) : tslib_1.__assign({}, baseRequestData, { embargo: false });
            save(requestData, {
                onSuccess: function (_a) {
                    var updatedData = _a.data;
                    // @ts-ignore
                    reset({
                        isActive: updatedData.active.toString(),
                        isHidden: (!updatedData.displaytopatient).toString(),
                        isProvisional: updatedData.embargo.toString(),
                        convertToVideo: (updatedData.appointmenttype === 'video-call').toString(),
                        provisionalHours: updatedData.embargoperiod != null
                            ? updatedData.embargoperiod
                            : undefined
                    });
                }
            });
            return [2 /*return*/];
        });
    }); };
    if (isLoading) {
        return (React.createElement(Card, null,
            React.createElement(LoaderWrapper, null,
                React.createElement(CircularProgress, null))));
    }
    if (isError || !data) {
        return React.createElement(Card, { "data-testid": "error" });
    }
    var appointmentType = data.data;
    var _e = watch().isProvisional, isProvisional = _e === void 0 ? appointmentType.embargo.toString() : _e;
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWrapper, null,
            React.createElement(IconButton, { component: Link, to: "/admin/appointments", size: "small" },
                React.createElement(IconArrowBack, null)),
            React.createElement(AppointmentTypeTitle, { variant: "h5", role: "heading" },
                appointmentType.name,
                " - ",
                appointmentType.internalsid)),
        React.createElement("form", { noValidate: true, onSubmit: handleSubmit(onSubmit) },
            React.createElement(Card, null,
                React.createElement(AppointmentTypeRow, { first: true, label: t.Admin.AppointmentTypeHeaderId, caption: t.Admin.AppointmentTypeCaptionId },
                    React.createElement(Typography, { variant: "body1", className: "value" }, appointmentType.internalsid)),
                React.createElement(AppointmentTypeRow, { label: t.Admin.AppointmentTypeHeaderCreatedAt, caption: t.Admin.AppointmentTypeCaptionCreatedAt },
                    React.createElement(Typography, { variant: "body1", className: "value" }, localeDateTime.format(new Date(appointmentType.salvecreatedutc), 'PPP - p'))),
                React.createElement(AppointmentTypeRow, { label: t.Admin.AppointmentTypeHeaderActive, caption: t.Admin.AppointmentTypeCaptionActive },
                    React.createElement(StyledFormControl, { component: "fieldset" },
                        React.createElement(FormLabel, { component: "legend", hidden: true }, t.Admin.AppointmentTypeHeaderActive),
                        React.createElement(Controller, { as: React.createElement(RadioGroup, { "data-testid": "active" },
                                React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueNo), value: 'false' }),
                                React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueYes), value: 'true' })), control: control, defaultValue: appointmentType.active != null
                                ? appointmentType.active.toString()
                                : 'false', name: "isActive" }))),
                React.createElement(AppointmentTypeRow, { label: t.Admin.AppointmentTypeHeaderHidden, caption: t.Admin.AppointmentTypeCaptionHidden },
                    React.createElement(StyledFormControl, { component: "fieldset" },
                        React.createElement(FormLabel, { component: "legend", hidden: true }, t.Admin.AppointmentTypeHeaderHidden),
                        React.createElement(Controller, { as: React.createElement(RadioGroup, { "data-testid": "hidden" },
                                React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueNo), value: 'false' }),
                                React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueYes), value: 'true' })), control: control, defaultValue: appointmentType.displaytopatient != null
                                ? (!appointmentType.displaytopatient).toString()
                                : 'false', name: "isHidden" }))),
                React.createElement(AppointmentTypeRow, { label: t.Admin.AppointmentTypeHeaderProvisional, caption: t.Admin.AppointmentTypeCaptionProvisional },
                    React.createElement(ProvisionalContent, null,
                        React.createElement(StyledFormControl, { component: "fieldset" },
                            React.createElement(FormLabel, { component: "legend", hidden: true }, t.Admin.AppointmentTypeHeaderProvisional),
                            React.createElement(Controller, { as: React.createElement(RadioGroup, { "data-testid": "provisional" },
                                    React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueNo), value: 'false' }),
                                    React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueYes), value: 'true' })), control: control, defaultValue: appointmentType.embargo.toString(), name: "isProvisional" })),
                        isProvisional === 'true' ? (React.createElement(React.Fragment, null,
                            React.createElement(ProvisionalHoursContainer, null,
                                React.createElement(Typography, { variant: "body1" }, t.Admin.AppointmentTypeProvisionalHoursInputLabel1),
                                React.createElement(Controller, { control: control, defaultValue: appointmentType.embargoperiod || '', name: "provisionalHours", render: function (_a) {
                                        var onChange = _a.onChange, rest = tslib_1.__rest(_a, ["onChange"]);
                                        return (React.createElement(ProvisionalInput, tslib_1.__assign({}, rest, { maxLength: 3, onChange: function (e) {
                                                var numbers = e.target.value.replace(/[^0-9]/g, '');
                                                onChange(numbers);
                                            } })));
                                    }, rules: {
                                        min: {
                                            message: t.Admin
                                                .AppointmentTypeProvisionalHoursInputErrorRequired,
                                            value: 1
                                        },
                                        required: {
                                            message: t.Admin
                                                .AppointmentTypeProvisionalHoursInputErrorRequired,
                                            value: true
                                        }
                                    } }),
                                React.createElement(Typography, { variant: "body1" }, t.Admin.AppointmentTypeProvisionalHoursInputLabel2)),
                            'provisionalHours' in errors &&
                                errors.provisionalHours != null ? (React.createElement(Typography, { color: "secondary", variant: "body2" }, errors.provisionalHours.message)) : null)) : null)),
                React.createElement(AppointmentTypeRow, { label: t.Admin.AppointmentTypeHeaderVideoCall, caption: t.Admin.AppointmentTypeCaptionVideoCall },
                    React.createElement(StyledFormControl, { component: "fieldset" },
                        React.createElement(FormLabel, { component: "legend", hidden: true }, t.Admin.AppointmentTypeHeaderVideoCall),
                        React.createElement(Controller, { as: React.createElement(RadioGroup, { "data-testid": "video-call" },
                                React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueNo), value: 'false' }),
                                React.createElement(FormControlLabel, { control: React.createElement(StyledRadio, { size: "small" }), label: React.createElement(StyledFormControlLabel, { variant: "body1" }, t.Admin.AppointmentTypeValueYes), value: 'true' })), control: control, defaultValue: (appointmentType.appointmenttype === 'video-call').toString(), name: "convertToVideo" }))),
                appointmentType.statusfilters.length > 0 && (React.createElement(AppointmentTypeRow, { label: t.Admin.AppointmentTypeHeaderStatusFilters, caption: t.Admin.AppointmentTypeCaptionStatusFilters },
                    React.createElement(Typography, { variant: "body1", className: "value" }, appointmentType.statusfilters.join(', '))))),
            React.createElement(FormActions, null,
                React.createElement(Button, { color: "primary", disabled: !isDirty || isSubmitting, type: "submit", variant: "contained" }, "Save")))));
};
var ɵ1 = AppointmentType;
export default AppointmentType;
export { ɵ0, ɵ1 };
