import styled from '@emotion/styled';
import {
  Card,
  Paper,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SearchBar from '@react/components/SearchBar';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledSearchBar = styled(SearchBar)`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  position: sticky;
  top: 0;
`;

export const StyledCard = styled(Card)`
  flex-grow: 1;
`;

export const FullHeightPaper = styled(Paper)`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: #757575;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f0f0f7;
  }
`;

export const StyledTableFooter = styled(TableFooter)`
  td {
    border-bottom: 0;
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;
