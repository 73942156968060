export var PatientActionTypes;
(function (PatientActionTypes) {
    // Server Side
    PatientActionTypes["LoadPatientInformation"] = "[Patient] Load Patient Information";
    PatientActionTypes["LoadPatientInformationSuccess"] = "[Patient] Load Patient Information Success";
    PatientActionTypes["LoadPatientInformationError"] = "[Patient] Load Patient Information Error";
    // Client Side
    PatientActionTypes["LoadingPatientInformation"] = "[Patient] Loading Patient Information";
    PatientActionTypes["LoadedPatientInformation"] = "[Patient] Loaded Patient Information";
    PatientActionTypes["ClearAllPatientInformation"] = "[Patient] Clear All Patient Information";
    PatientActionTypes["ActivatePatientListFilter"] = "[Patient] Activate Patient Filter";
    PatientActionTypes["DeactivatePatientListFilter"] = "[Patient] Deactivate Patient Filter";
    PatientActionTypes["SetPatientListFilter"] = "[Patient] Set Patient Filter";
    PatientActionTypes["ChangeActiveSection"] = "[Patient] Change Active Section";
})(PatientActionTypes || (PatientActionTypes = {}));
// Action Creators
// ===============================================
// Server Side
var LoadPatientInformation = /** @class */ (function () {
    function LoadPatientInformation(payload) {
        this.payload = payload;
        this.type = PatientActionTypes.LoadPatientInformation;
    }
    return LoadPatientInformation;
}());
export { LoadPatientInformation };
var LoadPatientInformationSuccess = /** @class */ (function () {
    function LoadPatientInformationSuccess(payload) {
        this.payload = payload;
        this.type = PatientActionTypes.LoadPatientInformationSuccess;
    }
    return LoadPatientInformationSuccess;
}());
export { LoadPatientInformationSuccess };
var LoadPatientInformationError = /** @class */ (function () {
    function LoadPatientInformationError(payload) {
        this.payload = payload;
        this.type = PatientActionTypes.LoadPatientInformationError;
    }
    return LoadPatientInformationError;
}());
export { LoadPatientInformationError };
// Client Side
var LoadingPatientInformation = /** @class */ (function () {
    function LoadingPatientInformation() {
        this.type = PatientActionTypes.LoadingPatientInformation;
    }
    return LoadingPatientInformation;
}());
export { LoadingPatientInformation };
var LoadedPatientInformation = /** @class */ (function () {
    function LoadedPatientInformation() {
        this.type = PatientActionTypes.LoadedPatientInformation;
    }
    return LoadedPatientInformation;
}());
export { LoadedPatientInformation };
var ClearAllPatientInformation = /** @class */ (function () {
    function ClearAllPatientInformation() {
        this.type = PatientActionTypes.ClearAllPatientInformation;
    }
    return ClearAllPatientInformation;
}());
export { ClearAllPatientInformation };
var ActivatePatientListFilter = /** @class */ (function () {
    function ActivatePatientListFilter() {
        this.type = PatientActionTypes.ActivatePatientListFilter;
    }
    return ActivatePatientListFilter;
}());
export { ActivatePatientListFilter };
var DeactivatePatientListFilter = /** @class */ (function () {
    function DeactivatePatientListFilter() {
        this.type = PatientActionTypes.DeactivatePatientListFilter;
    }
    return DeactivatePatientListFilter;
}());
export { DeactivatePatientListFilter };
var SetPatientListFilter = /** @class */ (function () {
    function SetPatientListFilter(payload) {
        this.payload = payload;
        this.type = PatientActionTypes.SetPatientListFilter;
    }
    return SetPatientListFilter;
}());
export { SetPatientListFilter };
var ChangeActiveSection = /** @class */ (function () {
    function ChangeActiveSection(payload) {
        this.payload = payload;
        this.type = PatientActionTypes.ChangeActiveSection;
    }
    return ChangeActiveSection;
}());
export { ChangeActiveSection };
