<div class="dismiss-dialog" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="end start" (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <p class="message">{{ message }}</p>
  </div>
  <div mat-dialog-actions fxLayoutAlign="center end">
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="dismiss()"
      cdkFocusInitial
    >
      {{ data.text.Dismiss }}
    </button>
  </div>
</div>
