import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import get from 'lodash/get';
import { Observable, Subscription } from 'rxjs';
import { ClinicOptions } from '../../../auth/models/ClinicOptions';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import { ProfilePhotoModalReactWrapperComponent } from '../../containers/profile-photo-modal/profile-photo-modal-react-wrapper.component';

@Component({
  selector: 'portal-user-profile',
  styleUrls: [`./user-profile.component.scss`],
  templateUrl: `./user-profile.component.html`,
})
export class UserProfileComponent implements OnInit, AfterViewInit {
  @Input() user: User;
  @Input() isCognito: boolean;
  @Input() text: any;
  @Input() isGlobalPortal: boolean;
  @Output() logout = new EventEmitter<boolean>();

  private _subs = new Subscription();
  private _clinicOptions$: Observable<ClinicOptions>;
  private _profilePhotoEnabled: boolean;

  constructor(
    private _dialog: MatDialog,
    private _store: Store<fromRoot.State>,
  ) {
    this._clinicOptions$ = this._store.pipe(select(fromAuth.getClinicOptions));
  }

  public handleLogout() {
    this.logout.emit(this.isCognito);
  }

  public handleOpenProfilePhotoDialog(): void {
    if (!this._profilePhotoEnabled) {
      return;
    }

    let dialogRef: MatDialogRef<
      ProfilePhotoModalReactWrapperComponent,
      MatDialogConfig
    >;

    const dialogCommonOptions = {
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
    };

    dialogRef = this._dialog.open(ProfilePhotoModalReactWrapperComponent, {
      panelClass: 'reduced-padding-dialog',
      data: {
        close: this._dialog.closeAll.bind(this._dialog),
      },
      ...dialogCommonOptions,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  public isProfilePhotoEnabled() {
    return this._profilePhotoEnabled;
  }

  ngOnInit(): void {
    this._subs.add(
      this._clinicOptions$.subscribe((opts) => {
        this._profilePhotoEnabled = get(opts, 'profilePhotoOptions.enabled');
      }),
    );
  }

  ngAfterViewInit() {}
}
