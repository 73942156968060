import { Component, Inject, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ApplyTemplateMethod } from '../../models/content-template';

@Component({
  selector: 'portal-choose-template',
  templateUrl: './choose-template.component.html',
  styleUrls: ['./choose-template.component.scss'],
})
export class ChooseTemplateComponent {
  public text: any;

  public templateForm: FormControl;

  constructor(
    public dialogRef: MatDialogRef<ChooseTemplateComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.text = data.text;
    this.templateForm = new FormControl('', Validators.required);
  }

  appendToContent() {
    const method: ApplyTemplateMethod = 'append';
    this.dialogRef.close({
      method,
      template: this.templateForm.value,
    });
  }

  overwriteContent(): void {
    const method: ApplyTemplateMethod = 'overwrite';
    this.dialogRef.close({
      method,
      template: this.templateForm.value,
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
