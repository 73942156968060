import { of } from 'rxjs';
var SelectiveStrategy = /** @class */ (function () {
    function SelectiveStrategy() {
    }
    SelectiveStrategy.prototype.preload = function (route, fn) {
        if (route.data && route.data['preload']) {
            return fn();
        }
        return of(null);
    };
    return SelectiveStrategy;
}());
export { SelectiveStrategy };
