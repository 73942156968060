import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Box, ListItem, ListItemText } from '@material-ui/core';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { Empty } from './Empty';
import { ListingTooltip } from './ListingTooltip';
import { Loading } from './Loading';
import MessageEditorSearchField from './MessageEditorSearchField';
var ItemText = styled('span')(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: block;\n  width: 270px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  display: block;\n  width: 270px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
var ItemRender = function (_a) {
    var id = _a.id, name = _a.name, onClick = _a.onClick;
    return (React.createElement(ListingTooltip, { title: name },
        React.createElement(ListItem, { button: true, onClick: onClick, "data-id": id },
            React.createElement(ListItemText, null,
                React.createElement(ItemText, null, name)))));
};
var ɵ0 = ItemRender;
var Message = function (_a) {
    var message = _a.message;
    return (React.createElement(Box, { display: "flex", flex: 1, alignItems: "center" },
        React.createElement(Empty, { message: message })));
};
var ɵ1 = Message;
var LoadingContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  align-items: center;\n"], ["\n  height: 100%;\n  display: flex;\n  align-items: center;\n"])));
var MessageAddContentLinkListing = function (_a) {
    var loading = _a.loading, mode = _a.mode, folders = _a.folders, packDetail = _a.packDetail, onSelectContent = _a.onSelectContent;
    var adminI18n = useTranslations().t.Admin;
    var _b = React.useState(''), searchInputValue = _b[0], setSearchInputValue = _b[1];
    var debouncedSearchTerm = useDebouncedState(searchInputValue, 500);
    if (loading) {
        return (React.createElement(LoadingContainer, null,
            React.createElement(Loading, null)));
    }
    var Listing = function () {
        var emptyMessage = (React.createElement(Message, { message: adminI18n.MessageContentSearchNoResults }));
        var emptySearchListing = (React.createElement(Message, { message: adminI18n.MessageContentEmptySearch }));
        var searchTermLower = debouncedSearchTerm.trim().toLowerCase();
        var packDetailListing = function (items) {
            var dataset = searchTermLower === ''
                ? items
                : items.filter(function (_a) {
                    var contentName = _a.contentName;
                    return contentName.toLowerCase().includes(searchTermLower);
                });
            var renderDataSet = function (_a) {
                var contentEntryHeaderId = _a.contentEntryHeaderId, contentName = _a.contentName;
                var onClick = function () {
                    onSelectContent({
                        type: 'pack',
                        id: contentEntryHeaderId,
                        name: contentName,
                    });
                };
                return (React.createElement(ItemRender, { id: contentEntryHeaderId, key: contentEntryHeaderId, name: contentName, onClick: onClick }));
            };
            return (React.createElement(React.Fragment, null, dataset.length ? dataset.map(renderDataSet) : emptySearchListing));
        };
        var subFolderListing = function (items) {
            var dataset = searchTermLower === ''
                ? items.filter(function (_a) {
                    var contentName = _a.contentName, isEmpty = _a.isEmpty;
                    return !!contentName && !isEmpty;
                })
                : items.filter(function (_a) {
                    var contentName = _a.contentName, isEmpty = _a.isEmpty;
                    return (!!contentName &&
                        !isEmpty &&
                        contentName.toLowerCase().includes(searchTermLower));
                });
            var renderDataSet = function (folder) {
                var id = folder.contentEntryHeaderId;
                var name = folder.contentName || '';
                var onClick = function () {
                    onSelectContent({ type: 'folder', id: id, name: name });
                };
                return React.createElement(ItemRender, { id: id, key: id, name: name, onClick: onClick });
            };
            return (React.createElement(React.Fragment, null, dataset.length ? dataset.map(renderDataSet) : emptySearchListing));
        };
        if (mode === 'folder' && folders) {
            return folders.length
                ? subFolderListing(folders)
                : emptyMessage;
        }
        if (mode === 'pack' && packDetail.data) {
            return packDetail.data.contentPackDetail.length
                ? packDetailListing(packDetail.data.contentPackDetail)
                : emptyMessage;
        }
        return emptyMessage;
    };
    return (React.createElement(Box, { minHeight: "100%", display: "flex", flexDirection: "column" },
        (folders && folders.length) ||
            (packDetail.data && packDetail.data.contentPackDetail.length) ? (React.createElement(MessageEditorSearchField, { value: searchInputValue, onChangeText: setSearchInputValue, placeholder: adminI18n.MessageContentSearchPlaceholder })) : null,
        folders || packDetail.data ? (React.createElement(Listing, null)) : (React.createElement(Message, { message: adminI18n.MessageContentSearchSelect }))));
};
var ɵ2 = MessageAddContentLinkListing;
export default MessageAddContentLinkListing;
export { ɵ0, ɵ1, ɵ2 };
var templateObject_1, templateObject_2;
