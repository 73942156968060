import * as tslib_1 from "tslib";
import { AlterMessageActionTypes } from '../actions/alter-message.actions';
import { getMessageFromState, removeMessageFromState, replaceMessageInState, } from './utils';
export function reducer(state, action) {
    switch (action.type) {
        case AlterMessageActionTypes.MarkUnreadSuccess: {
            var id = action.payload.id;
            var message = getMessageFromState(state, id);
            return tslib_1.__assign({}, state, { messageList: replaceMessageInState(state, message, tslib_1.__assign({}, message, { Read: false })) });
        }
        case AlterMessageActionTypes.MarkResolvedSuccess:
        case AlterMessageActionTypes.MarkUnresolvedSuccess: {
            return tslib_1.__assign({}, state, { messageList: removeMessageFromState(state, action.payload.id) });
        }
        case AlterMessageActionTypes.ChangeLabelSuccess: {
            var _a = action.payload.data, messageId = _a.messageId, messageTypeId = _a.messageTypeId, messageType = _a.messageType;
            var message = getMessageFromState(state, messageId);
            return tslib_1.__assign({}, state, { messageList: state.messageTypeFilter.messageTypeId < 0
                    ? replaceMessageInState(state, message, tslib_1.__assign({}, message, { MessageType: messageType, MessageTypeId: messageTypeId }))
                    : removeMessageFromState(state, messageId) });
        }
        case AlterMessageActionTypes.MarkStarred:
            var um = getMessageFromState(state, action.payload.message.MessageId);
            return tslib_1.__assign({}, state, { messageList: replaceMessageInState(state, um, tslib_1.__assign({}, um, { MessageAction: 'STARRING' })) });
        case AlterMessageActionTypes.MarkStarredError:
            var markStarredErrorMessage = getMessageFromState(state, action.payload.messageId);
            return tslib_1.__assign({}, state, { messageList: replaceMessageInState(state, markStarredErrorMessage, tslib_1.__assign({}, markStarredErrorMessage, { IsStarred: false })) });
        case AlterMessageActionTypes.MarkUnstarred:
            var markUnstarredMessage = getMessageFromState(state, action.payload.message.MessageId);
            return tslib_1.__assign({}, state, { messageList: replaceMessageInState(state, markUnstarredMessage, tslib_1.__assign({}, markUnstarredMessage, { MessageAction: 'UNSTARRING' })) });
        case AlterMessageActionTypes.MarkUnstarredError:
            var markUnstarredErrorMessage = getMessageFromState(state, action.payload.messageId);
            return tslib_1.__assign({}, state, { messageList: replaceMessageInState(state, markUnstarredErrorMessage, tslib_1.__assign({}, markUnstarredErrorMessage, { IsStarred: true })) });
        case AlterMessageActionTypes.MarkUnstarredSuccess:
            var muNewStarredCount = state.messageCountSummary.starred - 1;
            var muNewUnstarredCount = state.messageCountSummary.unstarred + 1;
            return tslib_1.__assign({}, state, { messageCountSummary: tslib_1.__assign({}, state.messageCountSummary, { starred: muNewStarredCount <= 0 ? 0 : muNewStarredCount, unstarred: muNewUnstarredCount <= 0 ? 0 : muNewUnstarredCount }), messageList: removeMessageFromState(state, action.payload.message.MessageId) });
        case AlterMessageActionTypes.MarkStarredSuccess:
            var msNewStarredCount = state.messageCountSummary.starred + 1;
            var msNewUnstarredCount = state.messageCountSummary.unstarred - 1;
            return tslib_1.__assign({}, state, { messageCountSummary: tslib_1.__assign({}, state.messageCountSummary, { starred: msNewStarredCount <= 0 ? 0 : msNewStarredCount, unstarred: msNewUnstarredCount <= 0 ? 0 : msNewUnstarredCount }), messageList: removeMessageFromState(state, action.payload.message.MessageId) });
        default:
            return state;
    }
}
