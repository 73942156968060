import { useApiClient } from '@react/lib/api';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';

export function useFoldersListQuery() {
  const apiClient = useApiClient().portal;

  return useQuery(
    queryCacheKey.CONVERSATION_FOLDERS,
    () => apiClient.fetchConversationFolders(),
    {
      refetchInterval: POLLING_INTERVAL_MS,
    },
  );
}

export function useFoldersAssignedQuery() {
  const apiClient = useApiClient().portal;

  return useQuery(
    queryCacheKey.CONVERSATION_ASSIGNED_FOLDER,
    () => apiClient.fetchConversationAssignedFolder(),
    {
      refetchInterval: POLLING_INTERVAL_MS,
    },
  );
}
