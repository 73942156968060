
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { MaterialModule } from '../material/material.module';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { SettingsComponent } from './containers/settings/settings.component';
import { reducers } from './reducers';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};
const COMPONENTS = [SettingsComponent, GeneralSettingsComponent];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    StoreModule.forFeature('settings', reducers),
    RouterModule.forChild([
      {
        path: 'settings/:section',
        pathMatch: 'full',
        component: SettingsComponent,
      },
      {
        path: 'settings',
        pathMatch: 'full',
        redirectTo: 'settings/general',
      },
    ]),
  ],
  declarations: COMPONENTS,
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModule {}
