import get from 'lodash/get';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Container, ContainerInner } from './InfiniteScroll.styled';
import { Loading } from './Loading';
export var InfiniteScroll = function (_a) {
    var infiniteQueryResult = _a.infiniteQueryResult, error = _a.error, _b = _a.endOfResults, endOfResults = _b === void 0 ? null : _b, noResults = _a.noResults, renderItem = _a.renderItem;
    var isError = infiniteQueryResult.isError, hasNextPage = infiniteQueryResult.hasNextPage, isLoading = infiniteQueryResult.isLoading, isFetchingNextPage = infiniteQueryResult.isFetchingNextPage, fetchNextPage = infiniteQueryResult.fetchNextPage, data = infiniteQueryResult.data, isFetching = infiniteQueryResult.isFetching;
    function onScrollToEnd() {
        var shouldFetchNextPage = hasNextPage && !isLoading && !isFetchingNextPage;
        if (shouldFetchNextPage) {
            fetchNextPage();
        }
    }
    if (isLoading) {
        return React.createElement(Loading, { fullHeight: true });
    }
    if (isError || !data) {
        return React.createElement(React.Fragment, null, error);
    }
    var hasResults = get(data, 'pages[0].data.length') > 0;
    if (!hasResults) {
        return React.createElement(React.Fragment, null, noResults);
    }
    return (React.createElement(Container, null,
        React.createElement(ContainerInner, null,
            data.pages.map(function (page) { return page.data.map(renderItem); }),
            isFetching && React.createElement(Loading, { fullHeight: true }),
            !hasNextPage && endOfResults,
            React.createElement(Waypoint, { onEnter: onScrollToEnd }))));
};
