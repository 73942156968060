import {
  LinkContentActionsUnion,
  LinkContentActionTypes,
} from '../actions/link-content.actions';
import { ChildInfo } from './../models/content-entry';
import { ContentEntryNames } from './../models/linked-content';

export interface State {
  deletingLink: boolean;
  deletingLinks: boolean;
  loadingChildren: boolean;
  filterTargetContent: string;
  children: ChildInfo[];
  loadingAvailableContentToLinkTo: boolean;
  availableContentToLinkTo: ContentEntryNames[];
  creatingLink: boolean;
  errors: {
    [key: string]: boolean;
  };
  selectedContent: number[];
}

export const initialState: State = {
  deletingLink: false,
  deletingLinks: false,
  loadingChildren: false,
  filterTargetContent: '',
  children: [],
  loadingAvailableContentToLinkTo: false,
  availableContentToLinkTo: [],
  creatingLink: false,
  errors: {},
  selectedContent: [],
};

export function reducer(
  state = initialState,
  action: LinkContentActionsUnion,
): State {
  switch (action.type) {
    case LinkContentActionTypes.FilterTargetLink: {
      return {
        ...state,
        filterTargetContent: action.payload.toLowerCase(),
      };
    }
    case LinkContentActionTypes.GetAvailableToLinkContent: {
      return {
        ...state,
        loadingAvailableContentToLinkTo: true,
        errors: {},
      };
    }
    case LinkContentActionTypes.GetAvailableToLinkContentSuccess: {
      return {
        ...state,
        availableContentToLinkTo: action.payload,
        loadingAvailableContentToLinkTo: false,
        errors: {
          ...state.errors,
          availableContentToLinkTo: false,
        },
      };
    }
    case LinkContentActionTypes.GetAvailableToLinkContentError: {
      return {
        ...state,
        loadingAvailableContentToLinkTo: false,
        errors: {
          availableContentToLinkTo: true,
        },
      };
    }
    case LinkContentActionTypes.LinkContent: {
      return {
        ...state,
        creatingLink: true,
        errors: {},
      };
    }
    case LinkContentActionTypes.LinkContentSuccess: {
      return {
        ...state,
        filterTargetContent: '',
        creatingLink: false,
        errors: {
          creatingLink: false,
        },
      };
    }
    case LinkContentActionTypes.LinkContentError: {
      return {
        ...state,
        creatingLink: false,
        errors: {
          creatingLink: true,
        },
      };
    }
    case LinkContentActionTypes.ClearErrors: {
      return {
        ...state,
        errors: {},
      };
    }
    case LinkContentActionTypes.LoadChildren: {
      return {
        ...state,
        loadingChildren: true,
        errors: {},
      };
    }
    case LinkContentActionTypes.LoadChildrenSuccess: {
      return {
        ...state,
        loadingChildren: false,
        children: action.payload,
        errors: {},
      };
    }
    case LinkContentActionTypes.LoadChildrenError: {
      return {
        ...state,
        loadingChildren: false,
        errors: {
          loadingChildren: true,
        },
      };
    }
    case LinkContentActionTypes.ClearChildren: {
      return {
        ...state,
        children: [],
        errors: {},
      };
    }
    case LinkContentActionTypes.DeleteContentLink: {
      return {
        ...state,
        deletingLink: true,
        errors: {},
      };
    }
    case LinkContentActionTypes.DeleteContentLinkSuccess: {
      return {
        ...state,
        deletingLink: false,
        errors: {},
      };
    }
    case LinkContentActionTypes.DeleteContentLinkError: {
      return {
        ...state,
        deletingLink: false,
        errors: {
          deletingLink: true,
        },
      };
    }
    case LinkContentActionTypes.DeleteAllContentLinks:
    case LinkContentActionTypes.DeleteSomeContentLinks: {
      return {
        ...state,
        deletingLinks: true,
        errors: {},
      };
    }
    case LinkContentActionTypes.DeleteAllContentLinksSuccess:
    case LinkContentActionTypes.DeleteSomeContentLinksSuccess: {
      return {
        ...state,
        deletingLinks: false,
        errors: {},
      };
    }
    case LinkContentActionTypes.DeleteAllContentLinksError:
    case LinkContentActionTypes.DeleteSomeContentLinksError: {
      return {
        ...state,
        deletingLinks: false,
        errors: {
          deletingLinks: true,
        },
      };
    }
    case LinkContentActionTypes.SelectedContentForLinking: {
      return {
        ...state,
        selectedContent: action.payload,
      };
    }
    default:
      return state;
  }
}

export const getTargetFilter = (state: State) => state.filterTargetContent;
export const getAvailableToLinkContent = (state: State) =>
  state.availableContentToLinkTo;
export const isLoadingAvailableToLinkContent = (state: State) =>
  state.loadingAvailableContentToLinkTo;
export const isCreatingLink = (state: State) => state.creatingLink;
export const getErrors = (state: State) => state.errors;
export const getError = (key: string) => (state: State) => {
  const errors = getErrors(state);
  if (errors && errors.hasOwnProperty(key)) {
    return errors[key];
  }
};
export const getChildren = (state: State) => state.children;
export const isDeletingLink = (state: State) => state.deletingLink;
export const isDeletingLinks = (state: State) => state.deletingLinks;
export const isLoadingChildren = (state: State) => state.loadingChildren;
export const getSelectedContent = (state: State) => state.selectedContent;
