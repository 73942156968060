import { useEnvVariables } from '@react/lib/hooks';
import { match } from 'path-to-regexp';

export function useMessageContentLinkExtraction() {
  const { portalAppBaseUrl } = useEnvVariables();

  return {
    getContentLinkIds: (messageContent: string) => {
      const portalLinks = getPatientPortalLinks(
        messageContent,
        portalAppBaseUrl,
      );
      return portalLinks ? getContentlinkInfo(portalLinks) : undefined;
    },
  };
  function getContentlinkInfo(portalLinks: string[]) {
    const contentLinkPath = `/information/:contentRootId/:contentId`;
    return portalLinks.reduce((accum, portlUrl) => {
      const matchedObject = match<{ contentRootId: string; contentId: string }>(
        contentLinkPath,
      )(portlUrl.substring(portalAppBaseUrl.length));
      if (matchedObject) {
        accum.push(parseInt(matchedObject.params.contentId, 10));
      }
      return accum;
    }, [] as number[]);
  }
}
export function getPatientPortalLinks(str: string, baseUrl: string) {
  const regEx = new RegExp(`${baseUrl}\/[a-zA-Z0-9/?=]*`, 'g');
  return str.match(regEx);
}
