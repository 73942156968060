import * as tslib_1 from "tslib";
import { CircularProgress, Popover, TableBody, TableCell, TableRow, } from '@material-ui/core';
import { Button, Dialog, Empty, FilterChip, PopoverChip, } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useExportAdminList, useSIDERecipeListQuery } from '../../hooks';
import { DownloadLists } from '../../types';
import RecipeListFilters from './filters';
import { EmptyRecipeListIcon } from './icons/EmptyRecipeListIcon';
import { EmptyContainer, LoaderWrapper, StyledActionsContainer, StyledSearchBar, StyledTable, StyledTableContainer, StyledTableFooter, StyledTableHead, StyledTableRow, } from './RecipeList.styled';
import SIDEStatusChip from './SIDEStatusChip';
var RecipeList = function () {
    var _a = React.useState(''), searchInputValue = _a[0], setSearchInputValue = _a[1];
    var searchTerm = useDebouncedState(searchInputValue, 500);
    var _b = React.useState(false), isExportDialogOpen = _b[0], setExportDialogOpen = _b[1];
    var _c = React.useState(false), isExportError = _c[0], setExportError = _c[1];
    var _d = React.useState({}), filters = _d[0], setFilters = _d[1];
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var filterPopoverAnchor = React.useRef(null);
    var history = useHistory();
    var t = useTranslations().t;
    var localeDateTime = useLocaleDateTime();
    var onDownloadSuccess = function () { return setExportDialogOpen(false); };
    var onDownloadFailure = function () {
        setExportError(true);
        setExportDialogOpen(false);
    };
    var _f = useExportAdminList({
        type: DownloadLists.SIDERecipes,
        onDownloadSuccess: onDownloadSuccess,
        onDownloadFailure: onDownloadFailure,
    }), download = _f.download, fileUrl = _f.fileUrl, ref = _f.ref, fileName = _f.fileName;
    function saveToCSV() {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileNameSuffix;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileNameSuffix = t.Admin.SIDE.SIDEExport + " " + localeDateTime.format(new Date(), 'P');
                        return [4 /*yield*/, download(fileNameSuffix)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function apply(localFilters) {
        setFilters(localFilters);
        setOpen(false);
    }
    function deleteEventTypeFilter() {
        setFilters(tslib_1.__assign({}, filters, { eventType: undefined }));
    }
    function deleteStatusFilter(item) {
        var status = Array.isArray(filters.status)
            ? Array.from(filters.status)
            : [];
        status = status.filter(function (key) { return item !== key; });
        setFilters(tslib_1.__assign({}, filters, { status: status }));
    }
    function deleteAppointmentTypes(val) {
        var types = Array.isArray(filters.appointmentTypes)
            ? Array.from(filters.appointmentTypes)
            : [];
        types = types.filter(function (type) { return type.value !== val; });
        setFilters(tslib_1.__assign({}, filters, { appointmentTypes: types }));
    }
    function deleteDrugTypes(val) {
        var types = Array.isArray(filters.drugTypes)
            ? Array.from(filters.drugTypes)
            : [];
        types = types.filter(function (type) { return type.value !== val; });
        setFilters(tslib_1.__assign({}, filters, { drugTypes: types }));
    }
    var _g = useSIDERecipeListQuery({ searchTerm: searchTerm, filters: filters }), data = _g.data, isLoading = _g.isLoading, fetchNextPage = _g.fetchNextPage, hasNextPage = _g.hasNextPage, isFetchingNextPage = _g.isFetchingNextPage, isError = _g.isError;
    useErrorToast(isError, {
        title: t.Admin.SIDE.RecipeListLoadingErrorToastHeader,
        message: t.Admin.SIDE.RecipeListErrorToastMessage,
    });
    useErrorToast(isExportError, {
        title: t.Admin.SIDE.ExportErrorTitle,
        message: t.Admin.SIDE.ExportErrorDescription,
    }, 0);
    function onScrollToEnd() {
        var shouldFetchNextPage = hasNextPage && !isLoading && !isFetchingNextPage;
        if (shouldFetchNextPage) {
            fetchNextPage();
        }
    }
    function onRowClick(id) {
        history.push("/admin/side/recipes/" + id);
    }
    var loading = isLoading || isFetchingNextPage;
    var showResults = !isLoading && !isError;
    var renderRecords = showResults && data && data.pages[0].data.length > 0;
    var isFiltered = Object.keys(filters).length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledSearchBar, { value: searchInputValue, onChange: setSearchInputValue, placeholder: t.Admin.SIDE.SearchBarPlaceholder }),
        React.createElement(StyledActionsContainer, null,
            renderRecords && (React.createElement(React.Fragment, null,
                React.createElement(Button, { "data-testid": "btn_export", color: "primary", onClick: function () { return setExportDialogOpen(true); }, variant: "contained" }, t.Admin.SIDE.ExportCSV),
                React.createElement("a", { href: fileUrl, download: fileName, hidden: true, ref: ref }),
                React.createElement(Dialog, { acceptButtonText: t.Admin.SIDE.ExportDialogDownload, cancelButtonText: t.Admin.SIDE.ExportDialogCancel, contentText: t.Admin.SIDE.ExportDialogDescription, onAccept: saveToCSV, onCancel: function () { return setExportDialogOpen(false); }, isOpen: isExportDialogOpen, titleText: t.Admin.SIDE.ExportDialogTitle }))),
            React.createElement(Button, { "data-testid": "btn_filter", color: "primary", variant: "contained", disabled: !renderRecords && !isFiltered, ref: filterPopoverAnchor, onClick: function () { return setOpen(true); } },
                "+ ",
                t.Admin.SIDE.AddFilter),
            React.createElement(Popover, { id: "filter-Popover", open: open, anchorEl: filterPopoverAnchor.current, onClose: function () { return setOpen(false); }, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                } },
                React.createElement(RecipeListFilters, { filters: filters, apply: apply })),
            filters.eventType && (React.createElement(FilterChip, { label: t.Admin.SIDE[filters.eventType + "Event"], onDelete: deleteEventTypeFilter })),
            Array.isArray(filters.status) &&
                filters.status.map(function (item) { return (React.createElement(FilterChip, { label: t.Admin.SIDE[item + "Status"], onDelete: function () { return deleteStatusFilter(item); } })); }),
            Array.isArray(filters.appointmentTypes) &&
                filters.appointmentTypes.length > 0 && (React.createElement(PopoverChip, { label: t.Admin.SIDE.FilterTabAppointmentType, data: filters.appointmentTypes, onDelete: deleteAppointmentTypes, clearAll: function () {
                    return setFilters(tslib_1.__assign({}, filters, { appointmentTypes: undefined }));
                } })),
            Array.isArray(filters.drugTypes) && filters.drugTypes.length > 0 && (React.createElement(PopoverChip, { label: t.Admin.SIDE.FilterTabDrugType, data: filters.drugTypes, onDelete: deleteDrugTypes, clearAll: function () {
                    return setFilters(tslib_1.__assign({}, filters, { drugTypes: undefined }));
                } }))),
        loading && (React.createElement(EmptyContainer, null,
            React.createElement(LoaderWrapper, { "data-testid": "Filtered-Loader" },
                React.createElement(CircularProgress, null)))),
        !renderRecords && !loading && (React.createElement(EmptyContainer, null,
            React.createElement(Empty, { "data-testid": "empty_state", icon: React.createElement(EmptyRecipeListIcon, null), message: t.Admin.SIDE.RecipeListEmptyMessage }))),
        renderRecords && (React.createElement(StyledTableContainer, null,
            React.createElement(StyledTable, null,
                React.createElement(StyledTableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { className: "idcol" }, t.Admin.SIDE.RecipeListTableHeaderId),
                        React.createElement(TableCell, { className: "contentcol" }, t.Admin.SIDE.RecipeListTableHeaderName),
                        React.createElement(TableCell, { className: "fixedcol" }, t.Admin.SIDE.RecipeListTableHeaderAction),
                        React.createElement(TableCell, null, t.Admin.SIDE.RecipeListTableHeaderEventType),
                        React.createElement(TableCell, null, t.Admin.SIDE.RecipeListTableHeaderStatus),
                        React.createElement(TableCell, null, t.Admin.SIDE.RecipeListTableHeaderCreatedOn),
                        React.createElement(TableCell, null, t.Admin.SIDE.RecipeListTableHeaderLastUpdatedOn),
                        React.createElement(TableCell, null, t.Admin.SIDE.RecipeListTableHeaderLastUpdatedBy))),
                React.createElement(TableBody, null, data.pages.map(function (page) {
                    return page.data.map(function (item) { return (React.createElement(StyledTableRow, { key: item.id, onClick: function () { return onRowClick(item.id); }, "data-testid": "TableRow", role: "button", tabIndex: 0 },
                        React.createElement(TableCell, null, item.id),
                        React.createElement(TableCell, null, item.name),
                        React.createElement(TableCell, null, item.contentcount +
                            item.messagecount +
                            item.contentpackcount),
                        React.createElement(TableCell, null, t.Admin.SIDE[item.eventname + "Event"]),
                        React.createElement(TableCell, null,
                            React.createElement(SIDEStatusChip, { status: item.status, label: t.Admin.SIDE[item.status + "Status"] })),
                        React.createElement(TableCell, null, localeDateTime.format(new Date(item.createdon), 'PP, p')),
                        React.createElement(TableCell, null, localeDateTime.format(new Date(item.lastmodifiedon), 'PP, p')),
                        React.createElement(TableCell, null, item.lastupdatedby))); });
                })),
                React.createElement(StyledTableFooter, null,
                    React.createElement(TableRow, null, loading && (React.createElement(TableCell, { colSpan: 7 },
                        React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                            React.createElement(CircularProgress, null))))))))),
        React.createElement(Waypoint, { onEnter: onScrollToEnd })));
};
var ɵ0 = RecipeList;
export default RecipeList;
export { ɵ0 };
