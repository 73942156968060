import styled from '@emotion/styled';
import { Table, TableFooter, TableHead, TableRow } from '@material-ui/core';

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;

export const StyledTable = styled(Table)`
  table-layout: fixed;
`;

export const StyledTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: #757575;
    vertical-align: top;

    &.emailCol {
      width: 30%;
    }

    &.fixedcol {
      width: 100px;
    }
  }
`;

export const StyledTableFooter = styled(TableFooter)`
  td {
    border-bottom: 0;
  }
`;
