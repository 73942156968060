export enum DownloadLists {
  Users = 'exportAdminClinicUsers',
  SIDERecipes = 'exportSIDERecipes',
}

export interface IDownloadHookParams {
  readonly type: DownloadLists;
  readonly onDownloadSuccess: () => void;
  readonly onDownloadFailure: () => void;
}

export interface IDownloadHookRes {
  readonly download: (
    fileNameSuffix: string,
    hideDataName?: boolean,
  ) => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly fileUrl: string | undefined;
  readonly fileName: string | undefined;
}
