import { useApiClient } from '@react/lib/api';
import { MessageUpdatePayload } from '@react/lib/api/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';

export function useMessageMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    (message: MessageUpdatePayload) => apiClient.updateMessage(message),
    {
      onSuccess() {
        Object.keys(queryCacheKey).forEach((cacheKey) => {
          queryClient.invalidateQueries(cacheKey);
        });
      },
    },
  );
}
