/**
 * Enumerated, namespaced Message action types
 */
export var AlterMessageActionTypes;
(function (AlterMessageActionTypes) {
    /**
     * These actions require a server side response
     * i.e. they have side-effects and aren't 'pure'
     */
    AlterMessageActionTypes["MarkUnread"] = "[AlterMessage] Mark Unread";
    AlterMessageActionTypes["MarkUnreadSuccess"] = "[AlterMessage] Mark Unread Success";
    AlterMessageActionTypes["MarkUnreadError"] = "[AlterMessage] Mark Unread Error";
    AlterMessageActionTypes["MarkUnresolved"] = "[AlterMessage] Mark Unresolved";
    AlterMessageActionTypes["MarkUnresolvedSuccess"] = "[AlterMessage] Mark Unresolved Success";
    AlterMessageActionTypes["MarkUnresolvedError"] = "[AlterMessage] Mark Unresolved Error";
    AlterMessageActionTypes["MarkResolved"] = "[AlterMessage] Mark Resolved";
    AlterMessageActionTypes["MarkResolvedSuccess"] = "[AlterMessage] Mark Resolved Success";
    AlterMessageActionTypes["MarkResolvedError"] = "[AlterMessage] Mark Resolved Error";
    AlterMessageActionTypes["MarkStarred"] = "[AlterMessage] Mark Starred";
    AlterMessageActionTypes["MarkStarredSuccess"] = "[AlterMessage] Mark Starred Success";
    AlterMessageActionTypes["MarkStarredError"] = "[AlterMessage] Mark Starred Error";
    AlterMessageActionTypes["MarkUnstarred"] = "[AlterMessage] Mark Unstarred";
    AlterMessageActionTypes["MarkUnstarredSuccess"] = "[AlterMessage] Mark Unstarred Success";
    AlterMessageActionTypes["MarkUnstarredError"] = "[AlterMessage] Mark Unstarred Error";
    AlterMessageActionTypes["ChangeLabel"] = "[AlterMessage] Change Label";
    AlterMessageActionTypes["ChangeLabelSuccess"] = "[AlterMessage] Change Label Success";
    AlterMessageActionTypes["ChangeLabelError"] = "[AlterMessage] Change Label Error";
    // Toast Actions - should put all toasts in own files in core
    AlterMessageActionTypes["ToastDisplayed"] = "[Toast] Display Toast";
    AlterMessageActionTypes["ToastDismissed"] = "[Toast] Toast Dismissed";
    AlterMessageActionTypes["DeleteMessageItem"] = "[AlterMessage] Delete Message Item";
    AlterMessageActionTypes["DeleteMessageItemSuccess"] = "[AlterMessage] Delete Message Item Success";
    AlterMessageActionTypes["DeleteMessageItemError"] = "[AlterMessage] Delete Message Item Error";
})(AlterMessageActionTypes || (AlterMessageActionTypes = {}));
/**
 * Action Creators
 */
// ============== Server Side Actions ==================== //
var MarkUnread = /** @class */ (function () {
    function MarkUnread(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnread;
    }
    return MarkUnread;
}());
export { MarkUnread };
var MarkUnreadSuccess = /** @class */ (function () {
    function MarkUnreadSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnreadSuccess;
    }
    return MarkUnreadSuccess;
}());
export { MarkUnreadSuccess };
var MarkUnreadError = /** @class */ (function () {
    function MarkUnreadError(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnreadError;
    }
    return MarkUnreadError;
}());
export { MarkUnreadError };
var MarkUnresolved = /** @class */ (function () {
    // Need to pass the thread of the id to mark as unresolved
    function MarkUnresolved(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnresolved;
    }
    return MarkUnresolved;
}());
export { MarkUnresolved };
var MarkUnresolvedSuccess = /** @class */ (function () {
    function MarkUnresolvedSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnresolvedSuccess;
    }
    return MarkUnresolvedSuccess;
}());
export { MarkUnresolvedSuccess };
var MarkUnresolvedError = /** @class */ (function () {
    function MarkUnresolvedError(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnresolvedError;
    }
    return MarkUnresolvedError;
}());
export { MarkUnresolvedError };
var MarkResolved = /** @class */ (function () {
    // Need to pass the thread of the id to mark as unresolved
    function MarkResolved(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkResolved;
    }
    return MarkResolved;
}());
export { MarkResolved };
var MarkResolvedSuccess = /** @class */ (function () {
    function MarkResolvedSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkResolvedSuccess;
    }
    return MarkResolvedSuccess;
}());
export { MarkResolvedSuccess };
var MarkResolvedError = /** @class */ (function () {
    function MarkResolvedError(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkResolvedError;
    }
    return MarkResolvedError;
}());
export { MarkResolvedError };
var MarkStarred = /** @class */ (function () {
    function MarkStarred(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkStarred;
    }
    return MarkStarred;
}());
export { MarkStarred };
var MarkUnstarred = /** @class */ (function () {
    function MarkUnstarred(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnstarred;
    }
    return MarkUnstarred;
}());
export { MarkUnstarred };
var MarkUnstarredSuccess = /** @class */ (function () {
    function MarkUnstarredSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnstarredSuccess;
    }
    return MarkUnstarredSuccess;
}());
export { MarkUnstarredSuccess };
var MarkUnstarredError = /** @class */ (function () {
    function MarkUnstarredError(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkUnstarredError;
    }
    return MarkUnstarredError;
}());
export { MarkUnstarredError };
var MarkStarredSuccess = /** @class */ (function () {
    function MarkStarredSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkStarredSuccess;
    }
    return MarkStarredSuccess;
}());
export { MarkStarredSuccess };
var MarkStarredError = /** @class */ (function () {
    function MarkStarredError(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.MarkStarredError;
    }
    return MarkStarredError;
}());
export { MarkStarredError };
var ChangeLabel = /** @class */ (function () {
    // requires MessageTypeId and Message Thread Id
    function ChangeLabel(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.ChangeLabel;
    }
    return ChangeLabel;
}());
export { ChangeLabel };
var ChangeLabelSuccess = /** @class */ (function () {
    function ChangeLabelSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.ChangeLabelSuccess;
    }
    return ChangeLabelSuccess;
}());
export { ChangeLabelSuccess };
var ChangeLabelError = /** @class */ (function () {
    function ChangeLabelError(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.ChangeLabelError;
    }
    return ChangeLabelError;
}());
export { ChangeLabelError };
var ToastDisplayed = /** @class */ (function () {
    function ToastDisplayed(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.ToastDisplayed;
    }
    return ToastDisplayed;
}());
export { ToastDisplayed };
var ToastDismissed = /** @class */ (function () {
    function ToastDismissed() {
        this.type = AlterMessageActionTypes.ToastDismissed;
    }
    return ToastDismissed;
}());
export { ToastDismissed };
var DeleteMessageItem = /** @class */ (function () {
    function DeleteMessageItem(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.DeleteMessageItem;
    }
    return DeleteMessageItem;
}());
export { DeleteMessageItem };
var DeleteMessageItemSuccess = /** @class */ (function () {
    function DeleteMessageItemSuccess(payload) {
        this.payload = payload;
        this.type = AlterMessageActionTypes.DeleteMessageItemSuccess;
    }
    return DeleteMessageItemSuccess;
}());
export { DeleteMessageItemSuccess };
var DeleteMessageItemError = /** @class */ (function () {
    function DeleteMessageItemError() {
        this.type = AlterMessageActionTypes.DeleteMessageItemSuccess;
    }
    return DeleteMessageItemError;
}());
export { DeleteMessageItemError };
export function isError(action) {
    return (action instanceof MarkUnreadError ||
        action instanceof MarkResolvedError ||
        action instanceof MarkUnresolvedError ||
        action instanceof MarkStarredError ||
        action instanceof MarkUnstarredError ||
        action instanceof ChangeLabelError);
}
