import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  ContentPackDetail,
  PackAssignmentInterface,
} from 'src/app/content/models/content-packs';
import {
  AssignedContentPack,
  ContentEntriesByCategory,
  IndividuallyAssignedPatientContent,
} from '../../models/UserContent';
import { ContentTabs } from '../../reducers/content-assignment.reducer';
import { ContentTabsOption } from './../../reducers/content-assignment.reducer';

@Component({
  selector: 'portal-assigned-pack-list',
  templateUrl: './assigned-pack-list.component.html',
  styleUrls: ['./assigned-pack-list.component.scss'],
})
export class AssignedPackListComponent implements OnInit, OnDestroy {
  @Input() text: any;
  @Input() patientId: number;
  @Input() viewAsPatient: boolean;
  @Input() loadingAllPatientContent: boolean;
  @Input() loadingPatientContentView: boolean;
  @Input() openPacks: boolean;
  @Input() openItems: boolean;
  @Input() patientViewContent: ContentEntriesByCategory[];
  @Input() openFolderId: number;
  @Input() allOpen: boolean;
  @Input() filterActive: boolean;
  @Input() openPackId: number;
  @Input() assignedPacks: AssignedContentPack[];
  @Input() assignedContent: IndividuallyAssignedPatientContent[];
  @Input() activeTab: ContentTabs;
  @Output() removeItem = new EventEmitter<IndividuallyAssignedPatientContent>();
  @Output() openAssignedPack = new EventEmitter<number>();
  @Output() removePack = new EventEmitter<PackAssignmentInterface>();
  @Output() excludeItem = new EventEmitter<PackAssignmentInterface>();
  @Output() openPatientFolder = new EventEmitter<number>();
  @Output() filterList = new EventEmitter<string>();

  public filterValue: FormControl = new FormControl('');
  public contentTabOptions = ContentTabsOption;

  private _subs = new Subscription();

  constructor() {}

  ngOnInit() {
    this._subs.add(
      this.filterValue.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((filter: string) => {
          this.filterList.emit(filter);
        }),
    );
  }

  ngOnDestroy() {
    this.filterList.emit('');
    this._subs.unsubscribe();
  }

  public handleRemovePack(pack) {
    this.removePack.emit({
      pack,
      patientId: this.patientId,
      packId: pack.packId,
      exclusions: [],
    });
  }

  public handleOpenPack(packId) {
    if (this.openPackId) {
      this.openAssignedPack.emit(null);
    } else {
      this.openAssignedPack.emit(packId);
    }
  }

  public addExclusion(packId, packItem: ContentPackDetail) {
    const exclusionId = packItem.hiddenId || packItem.contentEntryHeaderId;
    this.excludeItem.emit({
      packId,
      packDetail: [packItem],
      packItem,
      exclusions: [exclusionId],
      patientId: this.patientId,
    });
  }

  public removeIndividualItem(content: IndividuallyAssignedPatientContent) {
    if (!content.fromMeditex && !content.isTransferring) {
      this.removeItem.emit(content);
    }
  }

  public clearFilter() {
    this.filterValue.setValue('');
  }
}
