import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Box, Tabs } from '@material-ui/core';
export var StyledPanel = styled(Box)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  padding: ", "px;\n  margin-bottom: ", "px;\n  height: 200px;\n  width: 250px;\n  overflow: hidden;\n"], ["\n  padding: ", "px;\n  margin-bottom: ", "px;\n  height: 200px;\n  width: 250px;\n  overflow: hidden;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StyledContainer = styled(Tabs)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  border-bottom: 2px;\n  & .MuiTabs-indicator {\n    background-color: ", ";\n  }\n"], ["\n  border-bottom: 2px;\n  & .MuiTabs-indicator {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledTitle = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  width: 100%;\n  display: inline-flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  font-weight: 500;\n  line-height: 1.75;\n  white-space: normal;\n  text-transform: uppercase;\n  padding: 6px 12px;\n  font-size: 0.875rem;\n  min-height: 48px;\n"], ["\n  width: 100%;\n  display: inline-flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  font-weight: 500;\n  line-height: 1.75;\n  white-space: normal;\n  text-transform: uppercase;\n  padding: 6px 12px;\n  font-size: 0.875rem;\n  min-height: 48px;\n"])));
export var StyledIndicator = styled.span(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  left: 0;\n  width: 100%;\n  background-color: ", ";\n  bottom: 0;\n  height: 2px;\n  position: absolute;\n  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  padding: 0 12px;\n"], ["\n  left: 0;\n  width: 100%;\n  background-color: ", ";\n  bottom: 0;\n  height: 2px;\n  position: absolute;\n  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  padding: 0 12px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledActionsContainer = styled.div(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  border-top-color: ", ";\n  border-top-width: 1px;\n  border-top-style: solid;\n  padding: ", "px;\n  display: flex;\n  justify-content: end;\n"], ["\n  border-top-color: ", ";\n  border-top-width: 1px;\n  border-top-style: solid;\n  padding: ", "px;\n  display: flex;\n  justify-content: end;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border.default;
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
