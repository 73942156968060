<div class="dashboard-container">
  <portal-dashboard-folders
    [messagingSummary]="messagingSummary$ | async"
    [labels]="labels$ | async"
    [text]="sectionText$ | async"
    [summary]="summary$ | async"
    (goToLabel)="gotoMessages($event)"
    (goToMessages)="goToMessages($event)"
  >
  </portal-dashboard-folders>
  <!-- 
    angulartics2On="click"
    angularticsAction="Navigate to message sub section"
  -->
  <portal-drugs-table
    [medications]="nonTimedTriggerMedication$ | async"
    [text]="nonTimedTriggerTableText"
    [title]="nonTimedTriggerTableText.UpcomingDrugs"
    [emptyMessage]="nonTimedTriggerTableText.NoDrugs"
    [displayedColumns]="displayedColumnList"
    (gotoPatient)="gotoPatient($event)"
  ></portal-drugs-table>
</div>
