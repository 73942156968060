import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { Directive } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { MatDialog, MatRadioChange } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { LocalVideoTrack, RemoteParticipant } from 'twilio-video';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import {
  ApiVideoCall,
  JoinVideoCallResponse,
} from '../../../video-calls/responses';
import { CameraComponent } from '../../components/camera/camera.component';
import { ParticipantComponent } from '../../components/participant/participant.component';
import {
  LocalConnectionStatus,
  VideoChatService,
} from '../../services/video.chat.service';

interface CallParticipant {
  participant: RemoteParticipant;
  isDominant: boolean;
}

@Directive({
  selector: '[portalParticipantsHost]',
})
export class ParticipantsHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'portal-video-call',
  templateUrl: './video-call.container.component.html',
  styleUrls: ['./video-call.container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoCallContainerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public invitePartnerText$: Observable<any>;
  public invitePartnerText: Record<string, string>;
  public partnerInviteEnabled$: Observable<boolean>;
  public partnerInviteEnabled: boolean;

  @ViewChild('camera') private _camera: CameraComponent;
  @ViewChildren(ParticipantComponent)
  private _participantComponents: QueryList<ParticipantComponent>;
  private _subs = new Subscription();

  callParticipants = new Array<CallParticipant>();

  @Input() localIdentity: string;
  @Input() roomName: string;
  @Input() token: string;
  @Input() nooneHereText;
  @Input() leaveConfirmationCallback: () => Promise<boolean>;
  @Input() isPartnerInvited: boolean;
  @Input() videoCall: ApiVideoCall;

  @Output() leftCall = new Subject<void>();
  @Output() errorConnecting = new Subject<string>();
  @Output() errorDevices = new Subject<string>();
  @Output() connected = new Subject<void>();
  @Output() isReconnecting = new BehaviorSubject<boolean>(false);
  @Output() participantDisconnected = new Subject<RemoteParticipant>();
  @Output() invitePartnerInCall = new EventEmitter<JoinVideoCallResponse>();
  @Output() unInvitePartnerInCall = new EventEmitter<JoinVideoCallResponse>();

  isConnected = false;
  isConnecting = true;
  hasParticipants = false;
  isAudioEnabled = true;
  isAudioOutputEnabled = false;
  isVideoEnabled = true;
  audioInputDevices: MediaDeviceInfo[] = [];
  audioOutputDevices: MediaDeviceInfo[] = [];
  videoDevices: MediaDeviceInfo[] = [];
  currentAudioInputDeviceId: string | undefined;
  currentAudioOutputDeviceId: string | undefined;
  currentVideoDeviceId: string | undefined;
  videoCallModalText: any;
  userFirstName = '';

  private _videoCallModalText$: Observable<any>;
  private _userFirstName$: Observable<string>;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.leaveRoom();
  }

  constructor(
    private _store: Store<fromRoot.State>,
    private readonly _videoChatService: VideoChatService,
    public dialog: MatDialog,
  ) {
    this.partnerInviteEnabled$ = this._store.pipe(
      select(fromAuth.getVideoCallsPartnerInvite),
    );
    this._subs.add(
      _videoChatService.localConnectionStatusChanged$.subscribe((s) => {
        this.isConnected = s === LocalConnectionStatus.Connected;
        this.isConnecting =
          !this.isConnected && s !== LocalConnectionStatus.Disconnected;

        if (this.isConnected) {
          this.connected.next();
        } else if (!this.isConnected && !this.isConnecting) {
          this.leftCall.next();
        }
      }),
    );

    this._subs.add(
      _videoChatService.errorConnecting.subscribe((e) => {
        this.errorConnecting.next(e);
      }),
    );

    this._subs.add(
      _videoChatService.errorDevices.subscribe((e) => {
        this.errorDevices.next(e);
      }),
    );

    this._subs.add(
      _videoChatService.roomReconnecting.subscribe((e) => {
        this.isReconnecting.next(e);
      }),
    );

    this._videoCallModalText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallModal')),
    );
    this._userFirstName$ = this._store.pipe(select(fromAuth.getFirstName));

    this.invitePartnerText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallsInvitePartner')),
    );
  }

  async ngOnInit() {
    this._subs.add(
      this.invitePartnerText$.subscribe((t) => {
        this.invitePartnerText = t;
      }),
    );

    this._subs.add(
      this.partnerInviteEnabled$.subscribe((pi) => {
        this.partnerInviteEnabled = pi;
      }),
    );

    this._subs.add(
      this._videoCallModalText$.subscribe((t) => {
        this.videoCallModalText = t;
      }),
    );
    this._subs.add(
      this._userFirstName$.subscribe((t) => {
        this.userFirstName = t;
      }),
    );
  }

  async ngAfterViewInit() {}

  ngOnDestroy(): void {
    this._videoChatService.leaveRoom();
    this._subs.unsubscribe();
  }

  async initialize() {
    await this._videoChatService.initializeMedia();
    this.isAudioOutputEnabled = this._videoChatService.audioOutputEnabled;

    if (this._videoChatService.tracks) {
      this._camera.videoTrack = this._videoChatService.tracks.find(
        (t) => t.kind === 'video',
      ) as LocalVideoTrack;

      this._videoChatService.participantConnected$.subscribe((p) => {
        const participant = {
          participant: p,
          isDominant: true,
        };
        this.callParticipants.push(participant);
        this.setDominantParticipant(participant);
      });

      this._subs.add(
        this._videoChatService.participantDisconnected$.subscribe((p) => {
          this.callParticipants = this.callParticipants.filter(
            (item) => item.participant.sid !== p.sid,
          );
          if (this.callParticipants.length > 0) {
            this.setDominantParticipant(this.callParticipants[0]);
          }
          this.participantDisconnected.next(p);
        }),
      );

      this._subs.add(
        this._videoChatService.localConnectionStatusChanged$.subscribe((s) => {
          if (s === LocalConnectionStatus.Disconnected) {
            this._participantComponents.forEach((c) => {
              c.isWaiting = false;
            });
            this._camera.finalizePreview();
            this._videoChatService.stopMedia();
          }
        }),
      );

      this._subs.add(
        this._videoChatService.dominantParticipantChanged$.subscribe((p) => {
          if (p) {
            const participant = this.callParticipants.find(
              (c) => c.participant.sid === p.sid,
            );
            this.setDominantParticipant(participant);
          }
        }),
      );

      this._subs.add(
        this._videoChatService.audioInputDevicesChanged$.subscribe(
          (devices) => {
            this.audioInputDevices = devices;
          },
        ),
      );

      this._subs.add(
        this._videoChatService.audioInputDeviceChanged$.subscribe((device) => {
          this.currentAudioInputDeviceId =
            device != null ? device.deviceId : undefined;
        }),
      );

      this._subs.add(
        this._videoChatService.audioOutputDevicesChanged$.subscribe(
          (devices) => {
            this.audioOutputDevices = devices;
          },
        ),
      );

      this._subs.add(
        this._videoChatService.audioOutputDeviceChanged$.subscribe((device) => {
          this.currentAudioOutputDeviceId =
            device != null ? device.deviceId : undefined;
        }),
      );

      this._subs.add(
        this._videoChatService.videoDevicesChanged$.subscribe((devices) => {
          this.videoDevices = devices;
        }),
      );

      this._subs.add(
        this._videoChatService.videoDeviceChanged$.subscribe((device) => {
          this.currentVideoDeviceId =
            device != null ? device.deviceId : undefined;
        }),
      );

      await this.joinRoom(this.roomName);
    }
  }

  async joinRoom(roomName: string) {
    if (roomName) {
      await this._videoChatService.joinOrCreateRoom(this.roomName, this.token);
    }
  }

  async leaveRoom() {
    if (this.leaveConfirmationCallback) {
      if (await this.leaveConfirmationCallback()) {
        this._videoChatService.leaveRoom();
      }
    } else {
      this._videoChatService.leaveRoom();
    }
  }

  toggleCamera() {
    this.isVideoEnabled = this._videoChatService.toggleCamera();
  }

  toggleMute() {
    this.isAudioEnabled = this._videoChatService.toggleMute();
  }

  async changeAudioInputDevice(e: MatRadioChange) {
    const audioInputDeviceId = e.value as string;

    await this._videoChatService.changeAudioInputDevice(audioInputDeviceId);
  }

  changeAudioOutputDevice(e: MatRadioChange) {
    const audioOutputDeviceId = e.value as string;

    this._videoChatService.changeAudioOutputDevice(audioOutputDeviceId);
  }

  async changeVideoDevice(e: MatRadioChange) {
    const videoDeviceId = e.value as string;

    await this._videoChatService.changeVideoDevice(videoDeviceId);
  }

  private setDominantParticipant(participant: CallParticipant) {
    for (const p of this.callParticipants) {
      p.isDominant = p === participant;
    }
  }
}
