var CustomRouterStateSerializer = /** @class */ (function () {
    function CustomRouterStateSerializer() {
    }
    CustomRouterStateSerializer.prototype.serialize = function (routerState) {
        var route = routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        var url = routerState.url, queryParams = routerState.root.queryParams;
        // I only want the first section of the url
        // let section = url.replace(/^\/(\w+)(\/.+)?$/, '$1');
        // // let section = url.split('\/')[1];
        // switch (section) {
        //   case 'cms':
        //     section = 'CMS';
        //     break;
        //   //section will be looked out for in language, and string has to match lang fill key
        //   case 'video-calls':
        //     section = 'VideoCalls';
        //     break;
        //   //default:
        //   // if (section) {
        //   //   section = section[0].toUpperCase() + section.substring(1);
        //   // }
        // }
        var params = route.params;
        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return {
            section: url.split('/')[1],
            url: url,
            params: params,
            queryParams: queryParams,
        };
    };
    return CustomRouterStateSerializer;
}());
export { CustomRouterStateSerializer };
export function sortByKeyAlphabetically(key) {
    return function (a, b) {
        if (a[key] < b[key]) {
            return -1;
        }
        if (a[key] > b[key]) {
            return 1;
        }
        return 0;
    };
}
export function sortByKeyAlphabeticallyLowercase(key) {
    return function (a, b) {
        var aVal = a[key].toLowerCase();
        var bVal = b[key].toLowerCase();
        if (aVal < bVal) {
            return -1;
        }
        if (aVal > bVal) {
            return 1;
        }
        return 0;
    };
}
export function sortAlphabetically(a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}
export function sortAlpabeticallyLowercase(a, b) {
    var aLower = a.name.toLowerCase();
    var bLower = b.name.toLowerCase();
    if (aLower < bLower) {
        return -1;
    }
    if (aLower > bLower) {
        return 1;
    }
    return 0;
}
export function filterAndSortCategories(categories) {
    var drugsAndMeds = categories
        .filter(function (a) {
        return a.isappointment || a.isdrug || a.issite || a.isstaff || a.istreatment;
    })
        .sort(sortAlpabeticallyLowercase);
    var others = categories
        .filter(function (a) {
        return !(a.isappointment ||
            a.isdrug ||
            a.issite ||
            a.isstaff ||
            a.istreatment);
    })
        .sort(sortAlpabeticallyLowercase);
    return drugsAndMeds.concat(others);
}
export function scrollToTop(element) {
    window.requestAnimationFrame(function () {
        element.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    });
}
export function scrollToBottom(element, smooth) {
    window.requestAnimationFrame(function () {
        element.scrollTo({
            top: element.scrollHeight,
            left: 0,
            behavior: smooth ? 'smooth' : 'auto',
        });
    });
}
