import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  ErrorHandler,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Auth, Hub, Logger } from 'aws-amplify';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription
} from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/content/models/category';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { environment } from 'src/environments/environment';
import * as AuthActions from '../../../auth/actions/auth.actions';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import * as fromContent from '../../../content/reducers';
import * as DashboardActions from '../../../dashboard/actions/dashboard.actions';
import * as LoadMessageActions from '../../../messaging/actions/load-message.actions';
import * as fromMessages from '../../../messaging/reducers';
import { SideBarMenuItem } from '../../../models/SideBarMenuItem';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as ClinicActions from '../../actions/clinic.actions';
import * as LayoutActions from '../../actions/layout.actions';
import { ActivateMenuItemAction } from '../../actions/layout.actions';
import { NavigationService } from '../../services/navigation.service';
import { PushNotificationsService } from '../../services/push-notifications.service';
import { getMenuItems } from './menu-items';

Auth.configure({
  Auth: environment.auth
});

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public categories$: Observable<Category[]>;
  public firstCategoryId$: Observable<number>;
  public firstCategoryId: number;
  public isCoreClinic$: Observable<boolean>;
  public isGlobalPortal$: Observable<boolean>;
  public hasSetGlobalPortal$: Observable<boolean>;
  public permissionRequested = false;
  private _logger: any = new Logger('auth_logger');

  // contains breakpoints to define width of app
  public mobileQuery: MediaQueryList[];

  public clinicToken$: Observable<string>;
  public showSidenav$: Observable<boolean>;
  public loggedIn$: Observable<boolean>;
  public user$: Observable<User>;
  public clinic$: Observable<string>;
  public loggedInSub: Subscription;
  public sectionText$: Observable<any>;
  public selectedLanguage$: Observable<string>;
  public isCognito$: Observable<boolean>;
  public tempMessage$: BehaviorSubject<string> = new BehaviorSubject('');

  public loggedIn: boolean;
  public isCoreClinic: boolean;
  public isGlobalPortal: boolean;
  public hasSetGlobalPortal: boolean;
  public sectionText: any;

  public realTimeMessagingSub: Subscription;

  // interval
  public interval;

  // Manage component Subscriptions
  private _subs = new Subscription();

  // render if logged in, could be retrieved from server,
  // upon verification of available modules to client.
  public menuItems$: Observable<SideBarMenuItem[]> = of([]);
  public activeMenuItem$: Observable<SideBarMenuItem>;
  public activePageKey: string;

  public menuItems: SideBarMenuItem[];
  private _activated$: Observable<string>;

  @HostListener('click')
  hostClick() {
    if (!this.permissionRequested) {
      this._push.requestPermission();
      this.permissionRequested = true;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  checkForDraftMessages($event) {
    const draftMessages = this._store.pipe(
      select(fromMessages.getDraftMessages)
    );
    let hasDraftMessages = false;
    const sub = draftMessages.subscribe((dm) => {
      for (const key in dm) {
        if (Object.hasOwnProperty.apply(dm, key)) {
          const value = dm[key];
          if (value.length > 0) {
            hasDraftMessages = true;
          }
        }
      }
    });
    sub.unsubscribe();

    if (hasDraftMessages) {
      $event.returnValue = 'You have unsent messages';
    }
  }

  constructor(
    private _store: Store<fromRoot.State>,
    private _push: PushNotificationsService,
    private _error: ErrorHandler,
    private _localisationService: LocalisationService,
    private _navigationService: NavigationService,
    public mediaMatcher: MediaMatcher,
    public breakpointObserver: BreakpointObserver,
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    public changeDetection: ChangeDetectorRef,
    private _title: Title
  ) {
    this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
    this.hasSetGlobalPortal$ = this._store.pipe(
      select(fromRoot.getHasSetGlobalPortal)
    );
    this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
    this.clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
    this.categories$ = this._store.pipe(select(fromContent.getCategories));
    this.firstCategoryId$ = this._store.pipe(
      select(fromContent.getFirstCategoryId)
    );
    this.angulartics2GoogleAnalytics.setUserProperties({ dimension1: 'test' });
    this.angulartics2GoogleAnalytics.startTracking();
    this.menuItems$ = this._store.pipe(select(fromRoot.getMenuItems));
    this.showSidenav$ = this._store.pipe(select(fromRoot.getShowSidenav));
    this._store.dispatch(new AuthActions.GetClinicId());

    // subscribe to store
    this.activeMenuItem$ = this._store.pipe(select(fromRoot.getActiveMenuItem));
    this._activated$ = this._store.pipe(select(fromRoot.getRouteHead));
    this.loggedIn$ = this._store.pipe(select(fromAuth.getLoggedIn));
    this.user$ = this._store.pipe(select(fromAuth.getUser));
    this.clinic$ = this._store.pipe(select(fromAuth.getClinicName));
    this.selectedLanguage$ = this._store.pipe(
      select(fromSettings.getCurrentLanguage)
    );
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('Toolbar'))
    );

    this.clinic$.subscribe((nextValue) =>
      this._title.setTitle(`${nextValue} | ${this._title.getTitle()}`)
    );

    this._subs.add(
      this.sectionText$.subscribe((t) => {
        this.sectionText = t;
      })
    );

    this.isCognito$ = this._store.pipe(select(fromAuth.isUserCognito));

    this._logger.onHubCapsule = (capsule) => {
      switch (capsule.payload.event) {
        case 'signIn':
          this._logger.log('user signed in'); // [ERROR] Alexander_the_auth_watcher - user signed in
          break;
        case 'signUp':
          this._logger.log('user signed up');
          break;
        case 'signOut':
          this._logger.log('user signed out');
          break;
        case 'signIn_failure':
          this._logger.error('user sign in failed');
          break;
        case 'configured':
          this._logger.log('the Auth module is configured');
      }
    };
    Hub.listen('auth', this._logger);

    this._subs.add(
      this.loggedIn$.subscribe((l) => {
        this.loggedIn = l;
      })
    );

    this._subs.add(
      this.isGlobalPortal$.subscribe((value) => {
        this.isGlobalPortal = value;
      })
    );

    this._subs.add(
      this.hasSetGlobalPortal$.subscribe((value) => {
        this.hasSetGlobalPortal = value;
      })
    );

    this._subs.add(
      combineLatest(
        this.isCoreClinic$,
        this.isCognito$,
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this.user$,
        this.isGlobalPortal$,
        this.hasSetGlobalPortal$
      ).subscribe(
        ([
          isCoreClinic,
          isUserCognito,
          clinicOptions,
          user,
          isGlobalPortal,
          hasSetGlobalPortal
        ]) => {
          if (hasSetGlobalPortal) {
            this.menuItems = getMenuItems(
              isCoreClinic,
              isUserCognito,
              isGlobalPortal,
              clinicOptions,
              user != null ? user.groups : []
            );
          }
        }
      )
    );
  }

  ngOnInit() {
    // screen width functions start ===========================
    const small$ = this.breakpointObserver.observe(['(max-width: 768px)']);

    const medium$ = this.breakpointObserver.observe(['(max-width: 1365px)']);

    // If status of either breakpoint observer has changed the
    // screensize width has crossed a breakpoint
    combineLatest([small$, medium$])
      .pipe(map((o) => o.map((r) => r.matches)))
      .subscribe((result) => {
        this.changeScreenWidth(result);
      });
    // screen width functions end =============================

    this._subs.add(
      combineLatest(this.clinicToken$, this.isGlobalPortal$).subscribe(
        ([_clinicToken, isGlobalPortal]) => {
          if (!isGlobalPortal) {
            setTimeout(() => {
              this.refreshStatus();
              this.interval = setInterval(() => {
                this.refreshStatus();
              }, 60 * 1000);
            });
          }
        }
      )
    );

    this._subs.add(
      combineLatest(
        this.clinicToken$,
        this.user$,
        this.isGlobalPortal$
      ).subscribe(([clinicToken, user, isGlobalPortal]) => {
        if (user && user.PublicToken && clinicToken) {
          this.clearTempMessage();
          this._store.dispatch(new ClinicActions.LoadClinicLanguages());
        }
      })
    );

    // Subscribe to language changes
    this._subs.add(
      this.selectedLanguage$.subscribe((language) => {
        this._localisationService.setLocale(language);
      })
    );

    this._subs.add(
      this.firstCategoryId$.subscribe((id) => (this.firstCategoryId = id))
    );

    this._subs.add(
      this.activeMenuItem$.subscribe((item) => {
        if (item) {
          this.activePageKey = item.key;
        }
      })
    );

    this._subs.add(
      this._activated$.subscribe((path) => {
        if (this.menuItems) {
          this._store.dispatch(
            new ActivateMenuItemAction(
              this.menuItems.find((i) => i.path === path)
            )
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    // Remove all subscriptions
    this._subs.unsubscribe();
  }

  private refreshStatus(): void {
    if (!this.isGlobalPortal) {
      this._store.dispatch(new DashboardActions.RefreshClinicStatus());
    }
  }

  // This sets the screen width size in the app store
  changeScreenWidth([small, medium]: boolean[]): void {
    const size = small ? 'sm' : medium ? 'md' : 'lg';

    if (size !== 'lg') {
      this.closeSidenav();
    } else {
      this.openSidenav();
    }
  }

  // These functions manage state of side menu
  public closeSidenav(): void {
    this._store.dispatch(new LayoutActions.CloseSidenav());
  }
  public openSidenav(): void {
    this._store.dispatch(new LayoutActions.OpenSidenav());
  }

  public logout(isCognito) {
    this._store.dispatch(
      new AuthActions.Logout({
        isCognito
      })
    );
  }

  public gotoPatient(id: number): void {
    this._navigationService.navigate(['patients', id]);
  }

  public clearTempMessage() {
    this.tempMessage$.next('');
  }

  public showTempMessage(message: string) {
    this.tempMessage$.next(message);

    setTimeout(() => {
      this.clearTempMessage();
    }, 3000);
  }

  public goToMenuItem(menuItem: SideBarMenuItem) {
    if (this.loggedIn) {
      if (menuItem.key === 'Content') {
        this._navigationService.navigate([
          menuItem.path,
          'list',
          this.firstCategoryId
        ]);
      } else {
        this._navigationService.navigate([menuItem.path]);
      }
    } else {
      this.showTempMessage(this.sectionText.PleaseLogIn);
    }
  }
}
