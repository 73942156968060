import { Popover, Typography } from '@material-ui/core';
import { Close, ExpandMoreOutlined } from '@material-ui/icons';
import * as React from 'react';
import { useTranslations } from 'src/app/react/lib/i18n';
import { FilterChip } from './FilterChip';
import { Loading } from './Loading';
import { StyledContainer, StyledLink, StyledList } from './PopoverChip.styled';
export function PopoverChip(_a) {
    var data = _a.data, onDelete = _a.onDelete, label = _a.label, clearAll = _a.clearAll, loading = _a.loading;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var popoverAnchor = React.useRef(null);
    var t = useTranslations().t;
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterChip, { id: label.toLowerCase() + "-chip", label: label, ref: popoverAnchor, onDelete: function () { return setOpen(true); }, deleteIcon: React.createElement(ExpandMoreOutlined, null), onClick: function () { return setOpen(true); } }),
        React.createElement(Popover, { id: label.toLowerCase() + "-filter-Popover", open: open, anchorEl: popoverAnchor.current, onClose: function () { return setOpen(false); }, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            } },
            React.createElement(StyledContainer, null,
                React.createElement(StyledLink, { onClick: clearAll }, t.Admin.SIDE.ClearAllFilter),
                React.createElement(StyledList, null, data.map(function (item) { return (React.createElement("li", { key: "popover-" + item.value },
                    React.createElement(Typography, null, item.key),
                    React.createElement(Close, { className: "close", onClick: function () { return onDelete(item.value); } }))); })),
                loading && React.createElement(Loading, null)))));
}
