import styled from '@emotion/styled';

interface Prop {
  bgColor?: string;
}

export const Container = styled('div')<Prop>`
  flex-grow: 1;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ bgColor }) => bgColor || `#fff`};
`;
