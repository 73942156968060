var _this = this;
import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useDispatch } from '@react/lib/store';
import React, { useEffect, useState } from 'react';
import { LoadStaffProfile } from 'src/app/core/actions/clinic.actions';
import { StyledDiv, StyledImg, StyledInitials, StyledWrapper, } from './ProfilePhoto.styled';
var defaultUrl = 'assets/imgs/nurse.jpeg';
var getInitials = function (staff) {
    var trimmedFirstName = (staff.firstname && staff.firstname.trim()) || '';
    var trimmedLastName = (staff.lastname && staff.lastname.trim()) || '';
    return trimmedFirstName.slice(0, 1) + " " + trimmedLastName.slice(0, 1);
};
var ɵ0 = getInitials;
var getCurrentStaffProfile = function (staffProfiles, id) {
    return id && staffProfiles && staffProfiles[id] ? staffProfiles[id] : undefined;
};
var ɵ1 = getCurrentStaffProfile;
var hasAWSProfilePicture = function (staff) {
    return Boolean(staff && staff.profilephotouri && staff.hasAWSPhoto);
};
var ɵ2 = hasAWSProfilePicture;
var hasDisplayProfilePhoto = function (showInitials, hasClinicUserId, staff) {
    return Boolean((showInitials && !hasClinicUserId) ||
        !(showInitials && staff && !staff.hasAWSPhoto));
};
var ɵ3 = hasDisplayProfilePhoto;
export var ProfilePhoto = function (_a) {
    var clinicUserId = _a.clinicUserId, fileUploadService = _a.fileUploadService, size = _a.size, _b = _a.showInitials, showInitials = _b === void 0 ? false : _b, staffProfiles = _a.staffProfiles;
    var client = useApiClient().portal;
    var dispatch = useDispatch();
    var _c = useState(defaultUrl), url = _c[0], setUrl = _c[1];
    var updateStaffProfiles = function (staff) {
        return dispatch(new LoadStaffProfile(staff));
    };
    var setAWSProfileUrl = function (staff) {
        if (staff && staff.profilephotouri) {
            setUrl(staff.profilephotouri);
        }
    };
    var hasClinicUserId = Boolean(clinicUserId);
    var currentStaffProfile = getCurrentStaffProfile(staffProfiles, clinicUserId);
    var canDisplayProfilePhoto = hasDisplayProfilePhoto(Boolean(showInitials), hasClinicUserId, currentStaffProfile);
    var fetchProfilePhotoUrl = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var fetch;
        return tslib_1.__generator(this, function (_a) {
            fetch = clinicUserId
                ? client.fetchStaff(clinicUserId)
                : client.fetchStaffMe();
            fetch.then(function (staffResult) {
                var profilePhotoUri = null;
                var data = staffResult.data;
                if (data) {
                    updateStaffProfiles(tslib_1.__assign({}, data, { hasAWSPhoto: false }));
                    profilePhotoUri = data.profilephotouri;
                }
                if (!profilePhotoUri) {
                    setUrl(defaultUrl);
                    return;
                }
                var staffProfile = getCurrentStaffProfile(staffProfiles, data.id);
                if (staffProfile && staffProfile.profilephotouri) {
                    setUrl(staffProfile.profilephotouri);
                    return;
                }
                fileUploadService
                    .readProfilePhotoFromBucket({
                    url: profilePhotoUri,
                })
                    .then(function (readResult) {
                    updateStaffProfiles(tslib_1.__assign({}, data, { profilephotouri: readResult.url, hasAWSPhoto: true }));
                    setUrl(readResult.url);
                });
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (currentStaffProfile) {
            if (hasAWSProfilePicture(currentStaffProfile)) {
                setAWSProfileUrl(currentStaffProfile);
            }
        }
        else {
            fetchProfilePhotoUrl();
        }
    }, [currentStaffProfile]);
    var displayInitials = function (staff) { return (React.createElement(StyledInitials, null, getInitials(staff))); };
    var displayPicture = function () { return React.createElement(StyledImg, { alt: "user-avatar", src: url }); };
    var displayProfile = function () {
        if (currentStaffProfile && !canDisplayProfilePhoto) {
            return displayInitials(currentStaffProfile);
        }
        return displayPicture();
    };
    return (React.createElement(StyledWrapper, { size: size },
        React.createElement(StyledDiv, { size: size }, displayProfile())));
};
export { ɵ0, ɵ1, ɵ2, ɵ3 };
