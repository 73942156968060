/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./patient-sections-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/common";
import * as i5 from "./patient-sections-list.component";
var styles_PatientSectionsListComponent = [i0.styles];
var RenderType_PatientSectionsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PatientSectionsListComponent, data: {} });
export { RenderType_PatientSectionsListComponent as RenderType_PatientSectionsListComponent };
function View_PatientSectionsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "active", null], [2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeSection.emit(_v.context.$implicit.type) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵted(5, 2, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.type === _co.activeSection); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_2 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.text[_v.context.$implicit.Name]; _ck(_v, 5, 0, currVal_3); }); }
export function View_PatientSectionsListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-list", [["class", "sections-list mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(1, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PatientSectionsListComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sections; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PatientSectionsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-patient-sections-list", [], null, null, null, View_PatientSectionsListComponent_0, RenderType_PatientSectionsListComponent)), i1.ɵdid(1, 49152, null, 0, i5.PatientSectionsListComponent, [], null, null)], null, null); }
var PatientSectionsListComponentNgFactory = i1.ɵccf("portal-patient-sections-list", i5.PatientSectionsListComponent, View_PatientSectionsListComponent_Host_0, { text: "text", sections: "sections", activeSection: "activeSection" }, { changeSection: "changeSection" }, []);
export { PatientSectionsListComponentNgFactory as PatientSectionsListComponentNgFactory };
