import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnChanges, OnDestroy, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MessageEditor } from '@react/components';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import Root from 'src/app/react/components/Root';
import * as fromAuth from '../../../auth/reducers';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
var containerElementName = 'portalMessageEditorReactContainer';
var MessageEditorComponent = /** @class */ (function () {
    function MessageEditorComponent(_store, _cognito) {
        this._store = _store;
        this._cognito = _cognito;
        this.maxLength = 2000;
        this.value = '';
        this.actionClick = new EventEmitter();
        this.valueChange = new EventEmitter();
        this._subscriptions = new Subscription();
        this.handleActionClick = this.handleActionClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    MessageEditorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4];
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user,
            };
            _this.render();
        }));
    };
    MessageEditorComponent.prototype.ngOnChanges = function () {
        this.render();
    };
    MessageEditorComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
        this._subscriptions.unsubscribe();
    };
    MessageEditorComponent.prototype.handleActionClick = function () {
        this.actionClick.emit();
    };
    MessageEditorComponent.prototype.handleOnChange = function (value) {
        this.valueChange.emit(value);
    };
    MessageEditorComponent.prototype.render = function () {
        if (this._props == null) {
            return;
        }
        ReactDOM.render(React.createElement(Root, tslib_1.__assign({}, this._props, { authService: this._cognito, dispatch: this._store.dispatch.bind(this._store) }), React.createElement(MessageEditor, {
            actionText: this.actionText,
            isActionActive: this.isActionActive,
            maxLength: this.maxLength,
            onActionClick: this.handleActionClick,
            onChange: this.handleOnChange,
            patientId: this.patientId,
            value: this.value,
            warningText: this.warningText,
        })), this.containerRef.nativeElement);
    };
    return MessageEditorComponent;
}());
export { MessageEditorComponent };
