import { Action } from '@ngrx/store';
import {
  AddToMultiplePacksBody,
  AddToMultiplePacksResponse,
  AddToPackResponse,
  AlterPackContentInterface,
  CreateContentPackInterface,
  CreateContentPackResponse,
  DeletePackInterface,
  DeletePackResponse,
  EditContentPackInterface,
  EditContentPackResponse,
  GetContentPackDetailResponse,
  GetContentPacksResponse,
  RemoveFromPackResponse,
  ServerError,
} from './../models/content-packs';

export enum ContentPackActionTypes {
  GetContentPacks = '[Content-Packs] Get Content Packs',
  GetContentPacksSuccess = '[Content-Packs] Get Content Packs Success',
  GetContentPacksError = '[Content-Packs] Get Content Packs Error',

  GetContentPackDetail = '[Content-Packs] Get Content Pack Detail',
  GetContentPackDetailSuccess = '[Content-Packs] Get Content Pack Detail Success',
  GetContentPackDetailError = '[Content-Packs] Get Content Pack Detail Error',

  CreateContentPack = '[Content-Packs] Create Content Pack',
  CreateContentPackSuccess = '[Content-Packs] Create Content Pack Success',
  CreateContentPackError = '[Content-Packs] Create Content Pack Error',

  EditContentPack = '[Content-Packs] Edit Content Pack',
  EditContentPackSuccess = '[Content-Packs] Edit Content Pack Success',
  EditContentPackError = '[Content-Packs] Edit Content Pack Error',

  DeleteContentPack = '[Content-Packs] Delete Content Pack',
  DeleteContentPackSuccess = '[Content-Packs] Delete Content Pack Success',
  DeleteContentPackError = '[Content-Packs] Delete Content Pack Error',

  AddToContentPack = '[Content-Packs] Add To Content Pack',
  AddToContentPackSuccess = '[Content-Packs] Add To Content Pack Success',
  AddToContentPackError = '[Content-Packs] Add To Content Pack Error',

  AddToMultipleContentPacks = '[Content-Packs] Add To Multiple Content Packs',
  AddToMultipleContentPacksSuccess = '[Content-Packs] Add To Multiple Content Packs Success',
  AddToMultipleContentPacksError = '[Content-Packs] Add To Multiple Content Packs Error',

  RemoveFromContentPack = '[Content-Packs] Remove From Content Pack',
  RemoveFromContentPackSuccess = '[Content-Packs] Remove From Content Pack Success',
  RemoveFromContentPackError = '[Content-Packs] Remove From Content Pack Error',

  ClearErrors = '[Content-Packs] Clear Errors',
  FilterContent = '[Content-Packs] Filter Content List',
  ClearFilter = '[Content-Packs] Clear Content List Filter',
}

export class GetContentPacks implements Action {
  readonly type = ContentPackActionTypes.GetContentPacks;
}
export class GetContentPacksSuccess implements Action {
  readonly type = ContentPackActionTypes.GetContentPacksSuccess;

  constructor(public payload: GetContentPacksResponse) {}
}
export class GetContentPacksError implements Action {
  readonly type = ContentPackActionTypes.GetContentPacksError;

  constructor(public payload: ServerError) {}
}
export class GetContentPackDetail implements Action {
  readonly type = ContentPackActionTypes.GetContentPackDetail;

  constructor(public payload: number) {}
}
export class GetContentPackDetailSuccess implements Action {
  readonly type = ContentPackActionTypes.GetContentPackDetailSuccess;

  constructor(public payload: GetContentPackDetailResponse) {}
}
export class GetContentPackDetailError implements Action {
  readonly type = ContentPackActionTypes.GetContentPackDetailError;

  constructor(public payload: ServerError) {}
}
export class CreateContentPack implements Action {
  readonly type = ContentPackActionTypes.CreateContentPack;

  constructor(public payload: CreateContentPackInterface) {}
}
export class CreateContentPackSuccess implements Action {
  readonly type = ContentPackActionTypes.CreateContentPackSuccess;

  constructor(public payload: CreateContentPackResponse) {}
}
export class CreateContentPackError implements Action {
  readonly type = ContentPackActionTypes.CreateContentPackError;

  constructor(public payload: ServerError) {}
}
export class EditContentPack implements Action {
  readonly type = ContentPackActionTypes.EditContentPack;

  constructor(public payload: EditContentPackInterface) {}
}
export class EditContentPackSuccess implements Action {
  readonly type = ContentPackActionTypes.EditContentPackSuccess;

  constructor(public payload: EditContentPackResponse) {}
}
export class EditContentPackError implements Action {
  readonly type = ContentPackActionTypes.EditContentPackError;

  constructor(public payload: ServerError) {}
}
export class DeleteContentPack implements Action {
  readonly type = ContentPackActionTypes.DeleteContentPack;

  constructor(public payload: DeletePackInterface) {}
}
export class DeleteContentPackSuccess implements Action {
  readonly type = ContentPackActionTypes.DeleteContentPackSuccess;

  constructor(public payload: DeletePackResponse) {}
}
export class DeleteContentPackError implements Action {
  readonly type = ContentPackActionTypes.DeleteContentPackError;

  constructor(public payload: ServerError) {}
}
export class AddToContentPack implements Action {
  readonly type = ContentPackActionTypes.AddToContentPack;

  constructor(public payload: AlterPackContentInterface) {}
}
export class AddToContentPackSuccess implements Action {
  readonly type = ContentPackActionTypes.AddToContentPackSuccess;

  constructor(public payload: AddToPackResponse) {}
}
export class AddToContentPackError implements Action {
  readonly type = ContentPackActionTypes.AddToContentPackError;

  constructor(public payload: ServerError) {}
}

export class AddToMultipleContentPacks implements Action {
  readonly type = ContentPackActionTypes.AddToMultipleContentPacks;

  constructor(public payload: AddToMultiplePacksBody) {}
}
export class AddToMultipleContentPacksSuccess implements Action {
  readonly type = ContentPackActionTypes.AddToMultipleContentPacksSuccess;

  constructor(public payload: AddToMultiplePacksResponse) {}
}
export class AddToMultipleContentPacksError implements Action {
  readonly type = ContentPackActionTypes.AddToMultipleContentPacksError;

  constructor(public payload: ServerError) {}
}

export class RemoveFromContentPack implements Action {
  readonly type = ContentPackActionTypes.RemoveFromContentPack;

  constructor(public payload: AlterPackContentInterface) {}
}
export class RemoveFromContentPackSuccess implements Action {
  readonly type = ContentPackActionTypes.RemoveFromContentPackSuccess;

  constructor(public payload: RemoveFromPackResponse) {}
}
export class RemoveFromContentPackError implements Action {
  readonly type = ContentPackActionTypes.RemoveFromContentPackError;

  constructor(public payload: ServerError) {}
}

export class FilterContent implements Action {
  readonly type = ContentPackActionTypes.FilterContent;

  constructor(public payload: string) {}
}
export class ClearFilter implements Action {
  readonly type = ContentPackActionTypes.ClearFilter;

  constructor(public payload?: any) {}
}

export class ClearErrors implements Action {
  readonly type = ContentPackActionTypes.ClearErrors;
}

export type ContentPackActionsUnion =
  | GetContentPacks
  | GetContentPacksSuccess
  | GetContentPacksError
  | GetContentPackDetail
  | GetContentPackDetailSuccess
  | GetContentPackDetailError
  | CreateContentPack
  | CreateContentPackSuccess
  | CreateContentPackError
  | EditContentPack
  | EditContentPackSuccess
  | EditContentPackError
  | DeleteContentPack
  | DeleteContentPackSuccess
  | DeleteContentPackError
  | AddToContentPack
  | AddToContentPackSuccess
  | AddToContentPackError
  | RemoveFromContentPack
  | RemoveFromContentPackSuccess
  | RemoveFromContentPackError
  | AddToMultipleContentPacks
  | AddToMultipleContentPacksSuccess
  | AddToMultipleContentPacksError
  | FilterContent
  | ClearFilter
  | ClearErrors;
