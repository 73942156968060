export const translationEs = {
  Languages: {
    English: 'English',
    German: 'Deutsch',
    Spanish: 'Español',
    Portuguese: 'Português',
  },
  // New Content Sections
  // =====================================
  ClinicStatus: {
    SalveConnection: 'Conexión Salve',
    Tooltip:
      // tslint:disable-next-line:max-line-length
      (version) =>
        `Un punto rojo indica que la conexión entre Salve y la clínica está momentáneamente fuera de línea. Puede que las pacientes no reciban actualizaciones en tiempo real de sus citas/medicación. Esto se resolverá en breve. Si el problema persiste, envía un correo a hello@salveapp.co.uk. Salve version: ${version}`,
  },
  NewContentList: {
    ThisFolder: (folder) => `${folder}`,
    SearchContent: (folder) => `Buscar en ${folder}...`,
    ThisPack: (pack) => `Pack ${pack}`,
    FilterPack: (pack) => `Filtrar el contenido del Pack ${pack}...`,
    AddItem: 'Añadir ítem',
    Title: 'Título del contenido',
    Comments: 'Comentarios',
    DateChanged: 'Fecha modificada',
    UpdatedBy: 'Última actualización por',
    NoContent: 'Contenido no encontrado',
    Empty: 'Vacío',
    Migrated: 'Migrado',
    DeleteItems: 'Eliminar ítems',
    Actions: 'Acciones',
    MoveItems: 'Mover ítems',
    LinkedToEMR: 'Contenido generado desde datos de la HCE',
    Translated: 'Este contenido tiene traducciones',
    TranslatedRow: (languages: string) =>
      `Traducciones actuales: ${languages}. Haz clic en el ícono para editarlas.`,
    ViewLinks: 'Ver enlaces',
    ItemsPerPage: 'Ver',
    FirstPage: 'Página inicial',
    PreviousPage: 'Página anterior',
    NextPage: 'Próxima página',
    LastPage: 'Última página',
    ContentHasLink: 'Contenido vinculado a otro contenido',
    NoChildren: 'No se ha encontrado datos del Hijo',
    LinkedAndSynced:
      'No existen acciones para ítems que están vinculados y sincronizados con la HCE',
    Link: 'Vincular ítems',
    LinkContent: 'Vincular contenido',
    ContentReplaced:
      // tslint:disable-next-line:quotemark
      "Datos del 'Hijo' se cambiarán con datos del 'Padre'",
    LinkingContent: 'Vinculando datos...',
    LinkedSuccess: 'Datos vinculados con éxito!',
    LinkingError: 'Error vinculando datos...',
    ContentLink: 'Datos vinculados',
    CtoPDesc: (parentName) =>
      `Se mostrará el contenido de ${parentName} para los siguientes ítems: `,
    RemoveSelectedLinks: 'Quitar enlaces seleccionados',
    RemoveLink: 'Quitar enlace',
    RemoveLinks: 'Quitar enlaces',
    RemoveConfirm: '¿Seguro que desea quitar este enlace de datos?',
    RemoveSomeLinks: 'Quitar algunos enlaces',
    RemoveAllLinks: 'Quitar todos los enlaces',
    RemoveSomeConfirm:
      '¿Seguro que desea quitar estos enlaces de este contenido? ',
    RemoveAllConfirm:
      '¿Seguro que desea quitar todos los enlaces de este contenido?',
    RemovingLink: 'Quitando enlace...',
    RemovingLinks: 'Quitando enlaces...',
    RemoveLinkSuccess: 'Enlace quitado con éxito!',
    RemoveLinksSuccess: 'Enlaces quitados con éxito!',
    RemoveLinkError: 'Error al quitar enlace',
    RemoveLinksError: 'Error al quitar enlaces',
    To: 'Para',
    Parent: 'Padre',
    Child: 'Hijo',
    FilterContent: 'Filtrar contenido...',
    MovedItemsSuccess: 'Entradas trasladadas con éxito',
    MovingItems: 'Trasladando entradas...',
    ErrorMovingItems: 'Error al trasladar ítems',
    DeleteFolder: 'Eliminar carpeta',
    MoveConfirmation: '¿Seguro que desea trasladar estos ítems?',
    WhichFolder: 'Selecciona una carpeta donde trasladar estos archivos: ',
    ChooseAFolder: 'Elige una carpeta',
    PleaseChoose: 'Elige una carpeta',
    DeleteFolderConfirmation: ' ¿Seguro que desea eliminar esta carpeta? ',
    DeleteItemsConfirmation: '¿Seguro que desea eliminar estos ítems?',
    DeleteItemsAdditionalInfo:
      'Si existen traducciones asociadas a este contenido, estas también serán eliminadas.',
    DeleteItemBlock: (itemName) =>
      `No se puede eliminar "${itemName}" porque está vinculado a otro contenido. Intenta desvincularlo antes de eliminar.`,
    Delete: 'Eliminar',
    Move: 'Mover',
    Cancel: 'Cancelar',
    OK: 'OK',
    Save: 'Guardar',
    FolderName: 'Nombre de carpeta',
    ChangeFolderName: 'Cambiar el nombre de la carpeta',
    FolderRequired: 'Se necesita un nombre de carpeta',
    FolderTranslationRequired:
      'Traducciones ya existentes no pueden quedar en blanco',
    FolderExists: 'Este nombre de carpeta ya existe',
    FolderLength: 'El nombre debe tener entre 3 y 50 caracteres',
    ChangingFolder: 'Cambiando nombre...',
    ChangingFolderSuccess: 'Cambiado con éxito!',
    ChangingFolderError: 'Error al cambiar nombre!',
    // Multilingual
    Translations: 'Traducciones',
    PressMe: 'Haz clic aquí ',
    IconFolder: ' Botón para crear tu primera carpeta con contentido',
    // New Translations
    PackName: 'Nombre de Pack',
    WhatPackName: 'Crea un nuevo nombre de Pack: ',
    PackNameRequired: 'Se necesita un nombre de Pack ',
    PackExists: 'Este nombre de Pack ya existe',
    PackNameLength: 'El nombre del Pack debe tener entre 3 y 50 caracteres',
    ContentCategory: 'Categoría de contenido',
    IsFileOnly: 'Solo archivo',
    DeletePack: 'Eliminar contenido del Pack',
    DeletePackConfirmation: '¿Seguro que desea eliminar este Pack?',
    RemovePackItems: 'Quitar del Pack',
    RemovePackItemsConfirmation:
      '¿Seguro que desea quitar estos ítems de este pack?',
    Remove: 'Quitar',
    AddToPack: 'Añadir al Pack',
    AddToPackDescription:
      'Los siguientes ítems se van a añadir a los Packs indicados: ',
    AddingToPack: 'Añadiendo contenido al Pack...',
    AddedSuccessfully: 'Contenido añadido al Pack con éxito',
    AddingToPackError: 'Error al añadir contendio al Pack!',
    SelectAPack: 'Seleccionar packs de contenido',
    PleaseChoosePack: 'Selecciona un Pack...',
    SendToAll: 'Este pack se enviará automáticamente a todos los pacientes',
    SendTo: 'Enviar a',
    AllPatients: 'Todos',
  },
  NoContent: {
    PressAdd: 'Haz clic en Añadir contenido para empezar',
    AddContent: 'Añadir contenido',
    // tslint:disable-next-line:quotemark
    Appear: 'No aparece ningún contenido',
  },
  ContentDirectory: {
    Add: 'Añadir',
    CreateNewFolder: 'Crea una nueva carpeta',
    CreateNewTemplate: 'Crea una nueva plantilla',
    CreateNew: 'Crear nuevo',
    Categories: 'Carpetas',
    Templates: 'Plantillas',
    NoFolders: 'Crea tu primera carpeta',
    NoTemplates: 'Crea tu primera plantilla ',
    // New
    CreateNewPack: 'Crea un nuevo Pack',
    Packs: 'Packs',
    NoPacks: 'Crea tu primer Pack',
  },
  CreateCategoryOrTemplate: {
    What: '¿Qué quieres hacer?',
    CreateCategory: 'Crea carpeta',
    CreateTemplate: 'Crea plantilla',
    Organise: 'Organiza tu contenido en carpetas.',
    MakeItEasy:
      // tslint:disable-next-line:quotemark
      'Crea una forma fácil para buscar, crear y distribuir el contenido de tu clínica.',
    Uniformity:
      'Las plantillas permiten crear tu contenido de forma homogénea.',
    UsefulFor: ' Particularmente útil para Medicación y Citas!',
    CategoryButton: 'Crea carpeta de contenidos',
    TemplateButton: 'Crea plantilla de contenido',
  },
  CreateCategory: {
    CreateCategory: 'Crea un nuevo nombre de carpeta: ',
    AddTitle: 'Añadir el nombre de carpeta aquí...',
    CurrentCategories: 'Carpetas actuales',
    FolderRequired: 'Se necesita un nombre de carpeta',
    FolderExists: 'Este nombre de carpeta ya existe',
    FolderLength: 'El nombre debe tener entre 3 y 50 caracteres',
    CreatingFolder: 'Creando carpeta...',
    CreatedFolder: 'Carpeta creada!',
    ErrorCreatingFolder: 'Error al crear carpeta',
    Cancel: 'Cancelar',
    Create: 'Crear',
    // New Words
    CreatePack: 'Crea un nuevo nombre de Pack : ',
    AddPackTitle: 'Añadir el nombre de Pack aquí...',
    CurrentPacks: ' Packs actuales',
    PackRequired: 'Se necesita un nombre de Pack ',
    PackExists: 'Este nombre de Pack ya existe',
    PackLength: 'El nombre de Pack debe tener entre 3 y 50 caracteres',
    CreatingPack: 'Creando Pack...',
    CreatedPack: 'Pack creado!',
    ErrorCreatingPack: 'Error al crear Pack',
  },
  CreateTemplate: {
    NewTitle: 'Nueva plantilla de contenido',
    Title: 'Plantilla de contenido',
    TemplateTitle: 'Título de la plantilla de contenido...',
    Required: 'Obligatorio',
    AddNew: 'Añadir nuevo',
    Save: 'Guardar',
    AddSectionTitle: 'Añadir título de sección',
    CancelConfirmation: '¿Cerrar plantilla sin guardar cambios?',
    DeleteConfirmation: '¿Seguro que desea eliminar esta plantilla?',
    Delete: 'Eliminar',
    Confirm: 'Confirmar',
    Cancel: 'Cancelar',
    Saving: 'Guardando plantilla...',
    SavedSuccessfully: 'Guardado con éxito',
    SavedError: 'Error encontrado',
  },
  CreateContentItem: {
    AddRich:
      'Añadir contenido multimedia como imágenes, vídeos y texto usando el editor de contenido',
    UploadPdf:
      'Subir un PDF o documento Word para que el paciente lo lea fácilmente en su móvil',
    CreateRich: 'Crear contenido',
    UsePdf: 'Usar un documento',
    Recommended: 'recomendado',
  },
  ContentCreatorTitle: {
    ManageLinks: 'Gestionar vínculo', //
    HasChildren: 'Vinculado a otro contenido ', //
    ParentExists: 'Contenido vinculado: ', //
    Title: 'Título',
    AddTitle: 'Añadir tu título aquí ...',
    PatientTitle: 'Título para el paciente ',
    AddPatientTitle: 'Añadir un título que verá el paciente...',
    TransferTitle: 'Usar nombre de la HCE',
    Subtitle: 'Subtítulo',
    AddSubtitle: 'Añadir un subtítulo aquí...',
    UseTemplate: 'Usar plantilla',
    ChooseATemplate: 'Elegir plantilla',
    TitleLengthError:
      'El título debe tener entre  <strong>3</strong> y <strong>50</strong> caracteres',
    TitleRequired: 'El título es <strong>obligatorio</strong>',
    TitleUnique:
      'El título debe ser <strong>único</strong>, este título ya existe.',
    SubtitleLessThan: 'El subtítulo debe ser de menos de',
    TitleMust: 'El título debe ser',
    TitleIs: 'El título es',
    Between: 'entre',
    LessThan: 'menos que',
    TitleExists: ', este título ya existe',
    Unique: 'único',
    Required: 'obligatorio',
    And: 'y',
    Characters: 'caracteres',
    TitleChange: 'Nombres de ítems vinculados deben ser cambiados en la HCE',
    TitlePattern:
      'El título solo puede tener letras, números, espacios y guiones bajos.',
  },
  ContentCreatorBody: {
    Introduction: 'Introducción',
    // tslint:disable-next-line:quotemark
    AddIntroduction: 'Añadir una introducción a tu contenido...',
    Required: 'Obligatorio',
    TextOnly: 'Solo puede ser texto.',
    CharsRemaining: 'caracteres restantes.',
    Video: 'Vídeo',
    AddVideo: 'Añadir vídeo',
    RemoveVideo: 'Quitar vídeo',
    MinLength: 'Se debe introducir contenido en la introducción.',
  },
  ContentCreatorControls: {
    Back: 'Atrás',
    Cancel: 'Cancelar',
    Publish: 'Publicar',
    Delete: 'Eliminar',
    SendToAll: 'Enviar a todos',
    AddTranslation: 'Añadir traducción',
    Confirm: 'Confirmar',
    Leave: 'Salir',
    PublishDialogInformation:
      'Cambios realizados a otros idiomas deben ser publicados separadamente.',
    PublishDialogMessage: '¿Publicar tus cambios?',
    PublishDialogWithLanguageMessage: (languageName: string) =>
      `¿Publicar tus cambios en ${languageName}?`,
    SaveConfirm: '¿Seguro que desea guardar los cambios?',
    ResetConfirm: '¿Reestablecer contenido sin guardar cambios?',
    DeleteConfirmInformation:
      'Esta acción es irreversible. Otras traducciones no serán afectadas.',
    DeleteConfirmMessage: '¿Seguro que desea eliminar esta traducción?',
    DeleteDefaultConfirmInformation:
      'Todo el contenido y cualquier traducción asociada al mismo será eliminado. Esta acción es irreversible.',
    DeleteDefaultConfirmMessage: '¿Seguro que desea eliminar este contenido?',
    DeleteErrorToastTitle: 'Error eliminando el contenido',
    DeleteErrorToastDescription:
      'El contenido no se ha eliminado. Por favor vuelve a intentarlo.',
    ExitConfirm: '¿Seguro que desea salir sin publicar los cambios?',
    OneAttachment: 'Solo un adjunto por cada contenido.',
    TitleRequired: 'Un título válido se requiere para adjuntar un archivo',
    UnpublishedChanges: 'Cambios sin publicar',
    ErrorToastDescription:
      'El contenido no se ha publicado. Por favor vuelve a intentarlo.',
    ErrorToastTitle: 'Error publicando el contenido',
    SuccessToastDescription: 'El contenido se ha publicado exitosamente',
    SuccessToastTitle: 'Contenido publicado',
    PublishingContent: 'Publicando el contenido…',
  },
  AddVideoControls: {
    AddVideo: 'Añadir vídeo',
    YoutubeLink: 'Enlace a YouTube',
    WebsiteLink: 'Enlace a sitio web',
    Upload: 'Subir vídeo',
    AddLink: 'Añadir tu enlace aquí...',
    SubmitLink: 'Guardar enlace',
  },
  ContentCreatorSections: {
    ConfirmDelete: '¿Seguro que desea eliminar esta sección?',
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    AddSectionTitle: 'Añadir un título a tu sección...',
    InsertText: 'Escribir texto aquí ...',
    AddSection: 'Añadir sección',
    MaxTitleLength: 'El título puede ser de máximo 50 caracteres',
    SectionTitle: 'Título de sección',
    CharactersRemaining: 'caracteres restantes',
  },
  ContentCreatorComments: {
    Comments: 'Comentarios',
    OnlyVisible: 'Solo visible internamente',
  },
  ContentCreatorVersionHistory: {
    VersionHistory: 'Historial de versiones',
    ComingSoon: '...Próximamente...',
  },
  AddPdf: {
    Title: 'Título',
    AddTitle: 'Añadir título aquí...',
    AddFile: 'Añadir archivo',
    Current: 'Adjunto actual',
    Id: 'ID',
    Name: 'Nombre de adjunto',
    FileType: 'Tipo de archivo',
    FileSize: 'Tamaño de archivo (Bytes)',
    Preview: 'Previsualizar',
    CheckFile: 'Comprobar archivo',
    Remove: 'Quitar',
    Cancel: 'Cancelar',
    RemoveConfirm: '¿Seguro que desea eliminar este adjunto?',
  },
  UseATemplate: {
    UseATemplate: 'Usar plantilla',
    ChooseATemplate: 'Seleccionar plantilla',
    SelectATemplate: 'Selecciona una plantilla',
    Append: 'Añadir secciones a plantillas al final',
    Replace: 'Reemplazar secciones con nueva plantilla',
    WillDelete: 'El reemplazar eliminará todas las secciones actuales!',
    Warning: 'Aviso',
    Or: 'o',
  },
  UseAContent: {
    Actions: 'Acciones',
    DialogTitle: 'Copiar contenido existente',
    DialogGlobalTitle: 'Copiar contenido del portal global',
    DialogGlobalDescription:
      'When copying content into a document-type content article, only other document-type content articles can be used. Rich content will not be searchable in the available list.',
    DialogDescription:
      'Solo contenido multimedia se puede copiar a otro contenido. Todo contenido basado en archivos no aparecerá en la lista.',
    ChooseAContent: 'Seleccionar contenido',
    ChooseAGlobalContent: 'Elija un artículo de contenido global',
    SelectAContent: 'Por favor seleccione el contenido',
    SelectAGlobalContent: 'Seleccione un artículo de contenido global',
    Copy: 'Copiar',
    Cancel: 'Cancelar',
    Warning: 'Atención',
    WarningText:
      'Copiando aquí el contenido seleccionado, se reemplazarán el Título para el paciente, la Introducción y vídeo existentes. Otras secciones se agregarán debajo de las secciones ya existentes.',
    WarningGlobalText:
      'Anything currently entered into this content article will be overwritten by what is being copied including any existing title/patient title, subtitle, introduction, video, sections. This action cannot be undone.',
    WarningGlobalFileText:
      'Anything currently entered into this content article will be overwritten by what is being copied including any existing title/patient title, subtitle, and uploaded file. This action cannot be undone.',
    CopyGlobalContentErrorTitle: '',
    CopyGlobalContentErrorDescription: '',
    CopyGlobalContentTranslationAdditionalTitle: '',
    CopyGlobalContentTranslationAdditionalDescription: (
      listOfLanguages: string,
    ) => '',
    CopyGlobalContentTranslationMissingTitle: '',
    CopyGlobalContentTranslationMissingDescription: (listOfLanguages: string) =>
      '',
  },
  ContentSwing: {
    NoContentAvailable: 'No hay ningún contenido',
    ToCreate: 'Para crear contenido haz clic en el botón debajo: ',
    CreateContent: 'Crear contenido',
    UploadFile: 'Subir archivo de paciente',
    SearchContent: 'Buscar contenido...',
    SearchFiles: 'Buscar archivos...',
    ContentType: 'Tipo de contenido',
    Folders: 'Carpetas',
    Packs: 'Packs',
    ViewAll: 'Todo',
    AvailableContent: (folderName) =>
      `Contenido ${folderName ? ' ' + folderName : ''}`,
    SentDate: (time: string, date: string) =>
      `Enviado a las ${time} el ${date}`,
    ReadDate: (time: string, date: string) => `Leído a las ${time} el ${date}`,
    PatientContent: 'Contenido para ',
    ReadyDocuments: 'Listos para enviar',
    PatientDocuments: (patientName: string) =>
      `Documentos enviados a ${patientName}`,
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    DeleteConfirm: '¿Seguro que desea eliminar el archivo de este paciente? ',
    SearchAssigned: 'Buscar contenido asignado...',
    Items: 'Ítems individuales ',
    AssignPackContent: 'Añadir contenido al Pack',
    LockItem: 'Evitar que este contenido se añada al paciente',
    AddSelectedItems: 'Añadir ítems del Pack al paciente',
    AssignIndividualItem: 'Añadir este ítem al paciente',
    SeePatientView: 'Ver listado de contenido como lo ve el paciente',
    SeeClinicView: 'Ver listado de contenido como lo ve la clínica',
    ViewInfo: 'Haz clic para ver más sobre la asignación del contenido',
    PackEmpty: 'Este Pack esta vacío',
    RemovePack: 'Quitar Pack del paciente',
    RemovePackItem: ' Quitar este ítem del Pack del paciente',
    ViewPackContents: 'Ver asignaciones del Pack',
    AllItemsExcluded: 'Se excluyen todos los ítems',
    NoAssignedPacks: 'El paciente no tiene ningún Pack asignado',
    ViewAssignedPacks: 'Ver Packs asignados al paciente',
    ViewIndividualItems: 'Ver ítems asignados por separado',
    AllItemsAssigned: 'Todos los ítems han sido asignados',
    SendToAllIsAutomatic:
      'Ítems marcados con Enviar a todos se asignan automáticamente a todos los pacientes',
    NoContentToAssign: 'Todo el contenido disponible ha sido asignado',
  },
  AddFile: {
    DocumentTitle: 'Nombre de documento',
    NameRequired: 'Se necesita un nombre de documento',
    NameLength: 'El nombre del documento debe tener entre 3 y 50 caracteres',
    NameUnique: 'El nombre del documento debe ser único',
    FixUnique: 'Se necesita un nombre único antes de subir un archivo',
    NamePattern:
      'El nombre del documento debe solo incluir letras, números, espacios y guiones bajos.',
    FirstInstruction:
      'Introduce un nombre de fácil lectura que se mostrará en la app del paciente. ',
    AddDocTitle: 'Añadir el nombre del documento aquí...',
    SecondInstruction: 'Adjunta el documento: ',
    AttachVideo: 'Adjunta el archivo de vídeo: ',
    FileRequired: 'Se necesita un archivo',
    ChooseFile: 'Selecciona un archivo',
    UploadFile: 'Subir archivo',
    Uploading: 'Subiendo...',
    Uploaded: 'Subido!',
    Error: 'Error',
    UploadSuccessful: 'Subido con éxito!',
    ErrorUploading: 'Error al subir archivo!',
    ErrorImage: 'Error al subir imagen',
    ChooseImage: 'Selecciona una imagen',
    Cancel: 'Cancelar',
    Submit: 'Enviar',
  },
  Login: {
    ErrorLoggingIn: 'Error al iniciar sesión',
    Username: 'Nombre de usuario',
    Email: 'Email',
    ForgottenPassword: '¿Has olvidado tu clave? Haz clic aquí',
    HelloUser: (user) => `Hola ${user}`,
    Login: 'Iniciar sesión',
    Password: 'Clave',
    PasswordMissing: 'Introduce tu clave',
    UsernameMissing: 'Introduce tu usuario',
    LoginSuccess: 'Sesión iniciada correctamente',
  },
  Toolbar: {
    // Also used for sidebar navigation
    PageTitle: (heading) =>
      ({
        Home: 'Inicio',
        Dashboard: 'Inicio',
        Messages: 'Mensajes',
        Content: 'Contenido',
        Medications: 'Medicación',
        Patients: 'Pacientes',
        Payments: 'Pagos',
        Settings: 'Ajustes',
        VideoCalls: 'Videollamadas',
        Admin: 'Administración',
        SuperAdmin: 'Super Administración',
      }[heading]),
    PleaseLogIn: 'Accede con tu cuenta para continuar',
    Dashboard: 'Inicio',
    SearchPatients: 'Buscar pacientes...',
    SignOut: 'Cerrar sesión',
    ProfilePhoto: 'Profile photo',
  },
  Dashboard: {
    UnresolvedMessages: 'Mensajes sin resolver',
    Unresolved: (folder: string) => ({
      Unresolved: `${folder}`,
      Tooltip: `Ver mensajes en ${folder}`,
    }),
  },
  UpcomingDrugs: {
    UpcomingDrugs: 'Próxima medicación',
    NoDrugs: 'No hay medicación pendiente',
    Name: 'Nombre',
    Type: 'Tipo',
    Drug: 'Medicamento',
    Dosage: 'Dosis',
    Status: 'Estado',
    DueAt: 'Hora',
  },
  NonTimedTriggerDrugs: {
    UpcomingDrugs: 'Medicación sin hora programada',
    NoDrugs: 'No hay medicación pendiente',
    Name: 'Nombre',
    Type: 'Tipo',
    Drug: 'Medicamento',
    Dosage: 'Dosis',
    Status: 'Estado',
    DueAt: 'Hora',
    DueDate: 'Fecha',
  },
  Messages: {
    NewMessage: 'Nuevo mensaje',
    NewMessages: 'Mostrar mensajes nuevos',
    AllUnresolved: 'Todos los Sin resolver',
    AllResolved: 'Todos los Resueltos',
    AllMessages: 'Todos los mensajes',
    SearchMessages: 'Buscar mensajes',
    SearchingFor: 'Buscando',
    Search: 'Buscar',
    Clear: 'Quitar',
    Actions: 'Acciones',
    Unread: 'No leídos',
    SelectAMessage: 'Seleccionar un mensaje',
    MarkUnread: 'Marcar como No leído',
    MarkUnresolved: 'Marcar como No resuelto',
    MarkResolved: 'Marcar como Resuelto',
    Move: 'Mover',
    ChangeLabelTo: 'Mover a...',
    WriteAMessage: 'Redactar mensaje',
    Enlarge: 'Ampliar',
    Minimise: 'Minimizar',
    Send: 'Enviar',
    SeenBy: 'Visto por',
    On: 'el',
    At: 'a las',
    New: 'Nuevo',
    Open: 'Abrir',
    OpenMessageAttachmentFileErrorMessage:
      'Por favor verifica tu conexión y vuelve a intentarlo',
    OpenMessageAttachmentFileErrorTitle: 'No se ha podido abrir el archivo',
    SingularTyping: ' está escribiendo...',
    PluralTyping: ' están escribiendo...',
    SingularTyped: ' ha introducido texto...',
    PluralTyped: ' han introducido texto...',
    Dismiss: 'DESCARTAR',
    CopyMessageHistory: 'Copiar historial de mensajes',
    HistoryCopied: (patientName: string) =>
      `Historial de mensajes con ${patientName} copiado con éxito al portapapeles`,
    MarkedResolved: 'Esta conversación se ha marcado como resuelta',
    ReadReceipt: (patientName: string, readDate: string) =>
      ` [Recibo: Visto por ${patientName} el ${readDate}]`,
    ReadBy: (name: string, time: string, date: string) =>
      `Visto por ${name} a las ${time} el ${date}`,
    SentFile: (fileName: string) => `Ha enviado un archivo – ${fileName}`,
    Seen: 'Visto',
    CopyError:
      // tslint:disable-next-line:max-line-length
      'No se ha podido copiar el historial de mensajes al portapapeles. Revisa tu conexión, refresca tu navegador e inténtalo de nuevo. Si el problema persiste, envía un correo a hello@salveapp.co.uk',
    EndOfConversation: 'Fin de conversaciones',
    NoConversations: 'Esta carpeta no tiene conversaciones sin prioridad',
    NoStarredConversations: 'Esta carpeta no tiene conversaciones priorizadas',
    PleaseRefineSearch:
      'Se está visualizando el número máximo de mensajes. Refina los criterios de búsqueda. ',
    PleaseUseSearch:
      'Sé está visualizando el número máximo de mensajes. Utiliza la barra de búsqueda.',
    Subject: 'Asunto:',
    ContentMaxLengthError: 'El mensaje puede tener 2000 caracteres como máximo',
    DeleteMessageItem: 'Eliminar mensaje',
    DeleteMessageConfirmationTitle: '¿Desea eliminar el mensaje?',
    DeleteMessageConfirmationBody: '¿Seguro que desea eliminar este mensaje?',
    DeleteMessageConfirmationBody2:
      'Es posible que el paciente vea el contenido de un mensaje ya eliminado entre las notificaciones de su dispositivo móvil.',
    DeleteMessageButtonDelete: 'Eliminar',
    DeleteMessageButtonCancel: 'Cancelar',
    DeletedMessageText: (name: string, date: string, time: string) =>
      `${name} ha eliminado este mensaje de la aplicación del paciente el día ${date} a las ${time}.`,
    NoMessageTemplates:
      'No hay plantillas de mensaje, puedes crearlas en la sección Administración.',
    NoMatchingMessageTemplates:
      'Las plantillas de mensaje existentes no coinciden con tu búsqueda',
    InsertMessageTemplate: 'Insertar plantilla de mensaje',
    StarredTab: 'Prioridad',
    EverythingElseTab: 'Sin prioridad',
    NoSearchResults: 'Ningún resultado coincide con la búsqueda o filtros',
    FoldersChipLabel: 'Categoría',
    TeamMembersFilterLabel: 'Equipo del paciente',
    FolderFilterLabel: 'Categoría',
    ResolvedFilterLabel: 'Resueltos',
    UnresolvedFilterLabel: 'Sin resolver',
    StatusFilterLabel: 'Estado',
    FolderFilterSearchPlaceholder: 'Buscar categoría',
    TeamMembersFilterSearchPlaceholder: 'Buscar miembro de equipo',
    FilterButtonAriaLabel: 'Filtros',
    FilterMenuAriaLabel: 'Menú de filtros',
    FilterMenuApplyButton: 'Aplicar',
    FilterLoadingErrorTitle: 'Ha ocurrido un error',
    FilterLoadingErrorMessage: 'Error aplicando los filtros disponibles',
    NoConversationSelected: 'No hay conversación seleccionada',
    PatientsTeamMember: 'Miembro de equipo del paciente',
    SearchUsers: 'Buscar usuarios',
    Me: 'Yo',
    Assign: 'Asignar',
    Unassign: 'Desasignar',
    SearchAssignees: 'Buscar designados',
    Assignees: 'Designados',
    Unassigned: 'Sin asignar',
    Mine: 'Mis mensajes',
  },
  NewVideoCall: {
    AddDescription: 'Añada una descripción para la llamada',
    AddName: 'Añada nombre del médico/enfermera que realizará la llamada',
    Cancel: 'Cancelar',
    CloseCheck: '¿Desea cerrar sin agendar una llamada?',
    Confirm: 'Confirmar',
    ConfirmDelete: '¿Seguro que desea eliminar esta llamada?',
    Create: 'Crear',
    Date: 'Fecha',
    DateRequired: 'Se necesita una fecha válida',
    Delete: 'Eliminar',
    DeleteCall: 'Eliminar llamada',
    Description: 'Descripción',
    DescriptionLength: 'La descripción debe tener entre 3 y 50 caracteres',
    DescriptionRequired: 'Se necesita una descripción',
    Duration: 'Duración (minutos)',
    DurationRequired: 'Se necesita una duración',
    Host: 'Anfitrión',
    HostLength: 'El campo Anfitrión debe tener entre 3 y 50 caracteres',
    HostRequired: 'Se necesita un anfitrión',
    LocalTime: 'Por favor ingrese la hora local de su clínica',
    PastDate: 'No se puede ingresar una fecha pasada',
    PastTime: 'No se puede ingresar un tiempo pasado',
    Patient: 'Paciente',
    PatientRequired: 'Se necesita un paciente',
    PleaseSelect: 'Ingrese el nombre o ID del paciente para buscar',
    Save: 'Guardar',
    ServerError:
      'Se ha encontrado un error de servidor. Vuelve a intentarlo más tarde.',
    SetDate: 'Seleccionar fecha',
    StartTime: 'Establecer hora de inicio',
    StartTimeRequired: 'Se necesita una hora de inicio',
    TitleEdit: 'Editar llamada',
    TitleNew: 'Nueva llamada',
  },
  NewMessage: {
    To: 'Para',
    Patient: 'Paciente',
    PleaseSelect: 'Seleccione un paciente válido',
    Type: 'Tipo',
    ChooseMessageType: 'Elige un tipo de mensaje...',
    PatientRequired: 'Se necesita un paciente',
    ServerError:
      'Se ha encontrado un error de servidor. Vuelve a intentarlo más tarde.',
    MessageLabelRequired: 'Se necesita una etiqueta de mensaje. ',
    WriteYourMessage: 'Escribe tu mensaje',
    PleaseEnterMessage: 'Introduce un mensaje',
    Send: 'Enviar',
    DiscardTitle: '¿Cerrar sin enviar mensaje?',
    DiscardDescription: 'Se perderá el mensaje y los datos ingresados.',
    Cancel: 'Volver al mensaje',
    Confirm: 'Cerrar',
    Subject: 'Asunto',
    SubjectPlaceholder: 'Escribe el asunto de tu correo (opcional)',
    SubjectMaxLengthError: 'El asunto puede tener 256 caracteres como máximo',
    ContentMaxLengthError: 'El mensaje puede tener 2000 caracteres como máximo',
    Title: 'Nueva conversación',
    FolderFieldLabel: 'Categoría de mensaje',
    FolderFieldPlaceholder: 'Selecciona una categoría',
    SubjectFieldLabel: 'Asunto (opcional)',
    SubjectFieldPlaceholder: 'Escribe el tema de la conversación',
    ContentFieldLabel: 'Mensaje',
  },
  Medication: {
    PotentialDupe: 'Posible medicamento duplicado',
    ActionRequired: 'Se requiere acción',
    Name: 'Nombre',
    Type: 'Tipo',
    Drug: 'Medicamento',
    Dosage: 'Dosis',
    Status: 'Estado',
    Overdue: (time) => `Retraso - se debe administrar a las ${time}`,
    NoActionRequired: 'No se requiere acción',
    DueAt: (time) => `Se debe administrar a las ${time}`,
    DueToday: 'A ser administrado hoy',
    WindowClosing: (time) => `${time} minutos para el cierre de la ventana`,
    TakenAt: (takenTime, dueTime) =>
      `Administrado a las ${takenTime} (indicada para las ${dueTime})`,
    PotentialDuplicate: (dueDate, scheduledTime) =>
      `Posible duplicado: ${dueDate} ${scheduledTime}`,
    NoAction: 'Ningún medicamento requiere acción',
    NoMedications: 'No hay medicación que visualizar',
    NoPotentialDuplicates: 'No hay posible medicación duplicada',
    NoSearchResults: 'Ningún medicamento coincide con la búsqueda',
    Search: 'Buscar',
    Id: 'ID',
    DOB: 'Fecha de nacimiento',
    ErrorTitle: 'No se ha podido cargar los datos',
    ErrorMessage: 'Por favor verifica tu conexión y vuelve a intentarlo',
  },
  ContentList: {
    All: 'Todo',
    Medications: 'Medicación',
    Medication: 'Medicación',
    Appointments: 'Citas',
    Appointment: 'Citas',
    SearchContent: 'Buscar contenido...',
    Type: 'Tipo',
    Name: 'Nombre',
    LastModifiedByHeader: 'Última modificación por - (Usuario) - Fecha',
    LastModifiedByRow: (user, date) => `(${user}) ${date}`,
  },
  PatientList: {
    SearchPatients:
      'Buscar paciente por nombre, ID, fecha de nacimiento (DDMMYYYY)',
    PatientId: 'ID Paciente',
    FirstName: 'Nombre',
    LastName: 'Apellido',
    SyncDate: 'Registrado en',
    ClearFilter: 'Quitar filtros',
    InviteCode: 'Código de invitación: ',
    DOB: 'Fecha de nacimiento',
  },
  PatientInfo: {
    NewMessage: 'Nuevo mensaje',
    PatientInfo: 'Datos del paciente',
    Partner: 'Pareja',
    Contact: 'Contacto',
    LabResults: 'Laboratorio',
    ResultRange: 'Rango de referencia:',
    ResultReceived: 'Recibido en fecha:',
    ResultInterpretation: 'Interpretación del resultado:',
    NoLabResults: 'No se han reportado resultados',
    ManageContent: 'Gestionar contenidos',
    PatientDocuments: 'Documentos del paciente',
    PatientId: 'ID Paciente',
    SyncDate: 'Registrado en',
    LastLogin: 'Último inicio de sesión',
    FirstName: 'Nombre',
    LastName: 'Apellido',
    DOB: 'Fecha de nacimiento',
    Age: 'Edad',
    NumberOfCycles: 'Número de ciclos',
    NoPartner: 'No hay pareja seleccionada',
    Email: 'Email',
    WorkPhoneLandline: 'Teléfono de trabajo (fijo):',
    WorkPhoneMobile: 'Teléfono de trabajo (móvil):',
    PersonalPhoneLandline: 'Teléfono personal (fijo):',
    PersonalPhoneMobile: 'Teléfono personal (móvil):',
    ComingSoon: '...Proximamente...',
    NewVideoCall: 'Nueva llamada',
    Minutes: 'minutos',
    ClinicPhysician: 'Doctor',
    Counselor: 'Consejero',
    Nurse: 'Enfermero',
    Accountant: 'Contable',
    TreatingDr: 'Doctor Tratante',
  },
  PatientCalendar: {
    Calendar: 'Calendario',
    Key: 'Leyenda',
    Hidden: 'Ocultado',
    Medication: 'Medicación',
    Appointment: 'Cita',
    VideoCall: 'Videollamada',
    Provisional: 'Provisional',
    Back: 'Atrás',
    Today: 'Hoy',
    Next: 'Próximo',
    Days: () => [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    Months: () => [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    EventLabelAppointment: (dateTime: string, appointmentTitle: string) =>
      `${dateTime} - ${appointmentTitle}`,
    EventLabelAppointmentProvisional: (
      dateTime: string,
      appointmentTitle: string,
    ) => `${dateTime} - ${appointmentTitle} (provisional)`,
  },
  Authentication: {
    WelcomeToSalve: 'Bienvenido a Salve',
    Core: ' Core',
    ErrorLoggingIn: 'Error al iniciar sesión',
    Username: 'Nombre de usuario',
    Email: 'Email',
    ForgottenPassword: '¿Has olvidado tu clave? Haz clic aquí',
    HelloUser: (user) => `Hola ${user}`,
    Login: 'Iniciar sesión',
    Password: 'Clave',
    PasswordMissing: 'Introduce tu clave',
    UsernameMissing: 'Introduce tu usuario',
    LoginSuccess: 'Sesión iniciada con éxito',
    ResetTitle: 'Reestablecer tu clave',
    ResetButton: 'Reestablecer tu clave',
    EmailRequired: 'Es necesario un email válido',
    YourCode: 'Tu código de reactivación',
    CodeRequired: 'Se requiere un código de reactivación',
    NewPassword: 'Nueva clave',
    PasswordRequired: 'Se requiere una nueva clave',
    PasswordsMustMatch: 'Las claves deben ser iguales',
    ConfirmPassword: 'Confirma clave',
    SubmitNewPassword: 'Enviar nueva clave',
    ConfirmNewPassword: 'Confirmar nueva clave',
    // tslint:disable-next-line:quotemark
    CreateYourAccount: 'Es el momento de crear tu cuenta',
    FillInBelow: 'Rellena los siguiente campos y pulsa Enviar',
    FirstName: 'Nombre',
    FirstNameRequired: 'Se requiere un nombre',
    LastName: 'Apellido',
    LastNameRequired: 'Se requiere un apellido',
    CountryCode: 'Prefijo del país',
    CountryCodeRequired: 'Se requiere un prefijo de país',
    PhoneNumber: 'Número de teléfono',
    PhoneNumberRequired: 'Se requiere un número de teléfono',
    InvalidPhoneNumber: 'Introduce un número de móvil válido.',
    CreateAccount: 'Crea tu cuenta',
    UserNotFoundException: 'Email no encontrado',
    NotAuthorizedException: 'Email o clave incorrecto',
    InvalidPasswordException:
      'La clave debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas y números.',
    InvalidParameterException:
      'La clave debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas y números.',
    CodeMismatchException:
      'Código de verificación no válido. Inténtalo de nuevo.',
    LimitExceededException:
      'Has superado el número de intentos. Inténtalo de nuevo pasado unos minutos.',
    ResetEmail:
      'Hemos mandado un código de reactivación por email. Introdúcelo en este campo.',
    CodeCancel: 'Cancelar',
    CodeMissing:
      'Por favor ingresa un código válido (debe tener 6 caracteres).',
    Code: 'Insertar código',
    OTPTitle: 'Revisa tu correo',
    OTPSubtitle:
      'Hemos enviado un código a tu correo. Insértalo debajo para iniciar sesión:',
    SupportEmail: 'hello@salveapp.co.uk',
    OTPInstructions:
      'El código es válido por 15 minutos. Si no lo encuentras en tu casilla de correo, por favor revisa tu casilla de spam. Ante cualquier problema, envíanos un correo a',
    InvalidCode: 'Código inválido. Por favor inténtalo de nuevo.',
    OTPToastTitle: 'Intenta iniciar sesión nuevamente',
    OTPToastMessage:
      'Has excedido el número de intentos permitidos para ingresar el código. Por favor inicia sesión para intentarlo de nuevo. Ante cualquier problema, envíanos un correo a hello@salveapp.co.uk',
  },
  MessageToast: {
    View: 'Ver',
    Undo: 'DESHACER',
    Loading: 'Cargando...',
    MessageResolvedTitle: 'Mensaje resuelto',
    MessageResolvedBody: 'Mensaje marcado como resuelto ',
    MessageResolvedUndoneTitle: 'Mensaje resuelto deshecho',
    MessageResolvedUndoneBody: 'Mensaje marcado de nuevo como no resuelto',
    MessageUnresolvedTitle: 'Mensaje no resuelto',
    MessageUnresolvedBody: 'Mensaje marcado como no resuelto',
    MessageUnresolvedUndoneTitle: 'Mensaje no resuelto deshecho',
    MessageUnresolvedUndoneBody: 'Mensaje marcado de nuevo como resuelto',
    MessageUnreadTitle: 'Mensaje sin leer',
    MessageUnreadBody: 'Mensaje marcado sin leer',
    MessageStarredTitle: 'Conversación priorizada',
    MessageStarredBody:
      'La conversación ha sido priorizada y se ha movido a la pestaña Prioridad',
    MessageStarredTitleError: 'No se ha podido priorizar esta conversación',
    MessageStarredBodyError:
      'Por favor verifica tu conexión y vuelve a intentarlo.',
    MessageUnstarredTitle: 'Prioridad quitada a la conversación',
    MessageUnstarredBody:
      'Se ha quitado la prioridad a la conversación, y movido a la pestaña Sin prioridad',
    MessageUnstarredTitleError:
      'No se ha podido quitar prioridad a esta conversación',
    MessageUnstarredBodyError:
      'Por favor verificar tu conexión y vuelve a intentarlo.',
    LabelChangedTitle: 'Etiqueta cambiada',
    LabelChangedBody: (from, to) =>
      `Etiqueta de mensaje cambiada de ${from} a ${to}`,
    MessageMovedTitle: 'Mensaje trasladado',
    MessageMovedBody: (from: string, to: string) =>
      `Mensaje trasladado de ${from} a ${to}`,
    OutboundSentTitle: 'Mensaje de salida enviado',
    OutboundSentBody: (patientString) =>
      `Mensaje enviado con éxito a ${patientString}`,
    LabelChangedUndoneTitle: 'Cambio de etiqueta deshecho',
    LabelChangedUndoneBody: (from, to) =>
      `Etiqueta de mensaje cambiada de nuevo a ${to} de ${from}`,
    MessageMovedUndoneTitle: 'Traslado de mensaje deshecho',
    MessageMovedUndoneBody: (from, to) =>
      `Mensaje trasladado de nuevo a ${to} de ${from}`,
    UndoingActionTitle: 'Deshaciendo acción',
    UndoingActionBody: (action) => `Deshaciendo la acción de '${action}'...`,
    ErrorEncountered: 'Error encontrado',
    ErrorUnread: 'Error marcando mensaje como no leído',
    ErrorUnresolved: 'Error marcando mensaje como no resuelto ',
    ErrorResolved: 'Error marcando mensajes como leídos',
    ErrorChanging: 'Error al cambiar etiqueta',
    ErrorSending: (patient) => `Error al enviar mensaje a ${patient}`,
    SendingOutboundTitle: 'Enviando mensaje de salida',
    SendingOutboundBody: (patient) => `Enviando mensaje a ${patient}`,
    ErrorDeletingMessage:
      'El paciente ha leído este mensaje y ya no puede ser eliminado.',
    ErrorDeletingMessageTitle: 'Error eliminando el mensaje',
    ErrorAssigningMessageTitle: 'No se ha podido asignar la conversación',
    ErrorUnAssigningMessageTitle: 'No se ha podido desasignar la conversación',
  },
  VideoCallsList: {
    AddFilter: 'Añadir filtro',
    ClearFilter: 'Quitar',
    CreateVideoCall: 'Crear llamada',
    Description: 'Descripción',
    DOB: 'Fecha de nacimiento',
    Duration: 'Duración',
    Edit: 'Editar',
    HostResource: 'Anfitrión/Recurso',
    Join: 'Conectar',
    Locked:
      'Llamada agendada desde la HCE. Para reagendar o cancelar, por favor hacerlo desde el software HCE de la clínica.',
    More: 'Más',
    NoPreviousCalls: 'No hay llamadas que mostrar',
    NoUpcomingCalls: 'No hay llamadas que mostrar',
    Patient: 'Paciente',
    PreviousCalls: 'LLAMADAS ANTERIORES',
    Search: 'Buscar paciente por nombre, ID, fecha de nacimiento (DDMMYYYY)',
    Status: 'Estado',
    StatusFinished: 'Finalizada',
    StatusOngoing: 'En proceso',
    StatusUpcoming: 'Próximamente',
    UpcomingCalls: 'PRÓXIMAS LLAMADAS',
    When: 'Fecha y hora',
    FilterNoResults: 'No hay resultados',
    FilterSearch: 'Buscar',
    FilterApply: 'Aplicar',
    FilterCancel: 'Cancelar',
    FilterHostResource: 'Anfitrión/Recurso',
    RefreshTitle: 'Actualizar lista de llamadas',
  },
  ProfilePhotoModal: {
    Title: 'Foto de perfil',
    SubTitle: 'Esta foto será visible en el portal y la app para pacientes.',
    SelectPhoto: 'Seleccionar foto',
    DeleteConfirmation: {
      Cancel: 'Cancelar',
      Delete: 'Eliminar',
      Content:
        'Su foto de perfil será eliminada del portal y la app para pacientes.',
      Title: '¿Seguro que desea eliminar la foto?',
    },
    CloseConfirmation: {
      Cancel: 'Cancelar',
      Close: 'Cerrar',
      Content: 'No se guardarán los cambios',
      Title: 'Estas seguro',
    },
    ChangePhoto: 'Cambiar foto',
    Delete: 'Eliminar',
    Save: 'Guardar',
    Loading: 'Cargando',
    SuccessToast: {
      UpdatedTitle: 'Foto subida exitosamente',
      UpdatedDescription:
        'Su foto de perfil ha sido guardada y ahora será visible en el portal y la app para pacientes.',
      DeletedTitle: 'Foto eliminada exitosamente',
      DeletedDescription:
        'Su foto de perfil ha sido eliminada del portal y la app para pacientes.',
    },
    ErrorToast: {
      UploadTitle: 'No se ha podido subir la foto de perfil',
      UpdateTitle: 'No se ha podido actualizar la foto de perfil',
      DeleteTitle: 'No se ha podido eliminar la foto de perfil',
      GenericDescription:
        'Por favor verifica tu conexión y vuelve a intentarlo.',
    },
  },
  VideoCallsToast: {
    Deleted: 'Llamada eliminada',
    DeletedSuccessfully: 'Llamada eliminada exitosamente',
    Deleting: 'Eliminando la llamada',
    ErrorDeleting: 'Error eliminando la llamada',
    ErrorEncountered: 'Ha habido un error. Vuelve a intentarlo más tarde.',
    ErrorSaving: 'Error guardando la llamada',
    JoiningVideoCall: 'Conectando la llamada',
    PleaseWait: 'Por favor espere...',
    PleaseWaitAndAllowAccess:
      'Por favor espere. Si requerido, permite el uso de cámara y micrófono',
    Saved: 'Llamada guardada',
    SavedSuccessfully: 'Llamada guardada exitosamente',
    Saving: 'Guardando la llamada',
    Error: 'Error',
  },
  VideoCallModal: {
    Allow: 'Permitir',
    AnErrorOccured: 'Ha habido un error',
    AttemptingReconnect: 'Intentando reconectarse a la llamada',
    AudioInputMenu: 'Entrada de micrófono',
    AudioOutputMenu: 'Salida de altavoces',
    CallEnded: 'Llamada finalizada',
    CallNotReconnected: 'No se ha podido reconectar a la llamada',
    Cancel: 'Cancelar',
    Connected: 'Te has conectado a la llamada',
    EndCall: 'Finalizar llamada',
    Error: 'Error',
    LeaveCall: 'Abandonar la llamada',
    Mute: 'Desactivar micrófono',
    NeedPermissions:
      'Para utiliizar llamadas, necesitamos tu permiso para el uso de tu cámara y micrófono. Si requerido por tu navegador, por favor permite el uso.',
    NoCameraMic:
      'Se necesito un micrófono y/o cámara para conectarse a la llamada',
    NooneHere: (name) => `No hay nadie aquí aún. Por favor espere a ${name}.`,
    Ok: 'OK',
    ParticipantDisconnected: 'Participante desconectado',
    ParticipantDisconnectedMessage: 'Un participante se ha desconectado',
    Reconnecting: 'Reconectando',
    StartCamera: 'Activar cámara',
    StopCamera: 'Desactivar cámara',
    Success: 'Conectado',
    SureLeave: '¿Seguro que desea abandonar la llamada?',
    Unmute: 'Activar micrófono',
    VideoMenu: 'Vídeo',
    Yes: 'Sí',
  },
  VideoCallsInvitePartner: {
    InviteButton: 'Invitar a pareja',
    InvitePartner: '¿Desea invitar a la pareja?',
    InviteText1:
      'Esto agregará la llamada a las citas de la pareja. Para la pareja, la llamada puede demorarse algunos minutos en aparecer en la app.',
    InviteText2:
      'Tenga en cuenta que para la pareja, la llamada puede demorarse algunos minutos en aparecer en la app.',
    Cancel: 'Cancelar',
    Invite: 'Invitar',
    PartnerInvited: 'Pareja invitada',
    PartnerInvitedText:
      'Se ha agregado esta llamada a las citas de la pareja. Puede demorarse algunos minutos en aparecer en la app.',
    UnInviteButton: 'Retirar invitación',
    UnInvitePartner: '¿Desea retirar la invitación a la pareja?',
    UnInviteText1:
      'Se eliminará la llamada de entre las citas de la pareja y ya no será posible unirse a la llamada.',
    UnInviteText2:
      'Tenga en cuenta que no podrá retirar la invitación a los participantes si la llamada está en curso.',
    UnInvite: 'Retirar',
    PartnerUninvited: 'Invitación retirada',
    PartnerUnInvitedText:
      'La llamada se ha eliminado de entre las citas de la pareja y ya no será posible unirse a la llamada. Tenga en cuenta que la llamada puede demorarse hasta 5 minutos en desaparecer de la app.',
    InvitePartnerInCall:
      'Tenga en cuenta que la llamada puede demorarse hasta 5 minutos en aparecer entre las citas de la app.',
    InvitePartnerInCallText:
      'Tenga en cuenta que la llamada puede demorarse hasta 5 minutos en aparecer entre las citas de la app.',
    OK: 'OK',
    UnInvitePartnerInCall:
      'Tenga en cuenta que la llamada puede demorarse hasta 5 minutos en desaparecer de la app.',
    UnInvitePartnerInCallText1:
      'La llamada se ha eliminado de entre las citas de la pareja y ya no será posible unirse a la llamada.',
    UnInvitePartnerInCallText2:
      'Tenga en cuenta que la llamada puede demorarse hasta 5 minutos en desaparecer de la app.',
    PartnerNotFound: 'Pareja no encontrada',
    PartnerNotFoundText1: 'No hay pareja asociada al paciente en Salve.',
    PartnerNotFoundText2:
      'Si este paciente tiene una pareja, la pareja necesita descargar la app Salve y crear su propia cuenta.',
    PartnerNotFoundText3:
      'Cuando la pareja lo haya hecho, por favor vuelva a intentarlo.',
    PartnerNotFoundText4:
      'Si crees que esto se trata de un error, por favor envía un correo a hello@salveapp.co.uk.',
    CouldNotInvite: 'No se ha podido invitar a la pareja',
    CouldNotInviteErrorMessage:
      'Por favor verifica tu conexión y vuelve a intentarlo.',
    CouldNotUnInvite: 'No se ha podido retirar la invitación a la pareja',
    CouldNotUnInviteErrorMessage:
      'Por favor verifica tu conexión y vuelve a intentarlo.',
    CallInProgressUnInviteMessage:
      'No se puede retirar la invitación si la llamada está en curso.',
  },
  SessionExpired: {
    Title: 'Sesión cerrada automáticamente',
    Message: 'Tu sesión ha expirado. Por favor vuelve a iniciar sesión.',
  },
  MessagingNotifications: {
    BrowserTabTitle: (messagesLength: string) =>
      `(${messagesLength}) Portal Salve`,
    NotificationBody: (messagesLength: string) =>
      `Has recibido ${messagesLength} mensaje(s) nuevo(s)`,
    NotificationBodyMessage: (from: string) => `Nuevo mensaje de ${from}`,
    NotificationTitle: 'Nuevo(s) mensaje(s) recibido(s)',
  },
  Admin: {
    AppointmentTypeNavItem: 'Citas',
    AppointmentTypeListSearchPlaceholder: 'Buscar citas...',
    AppointmentTypeListTableHeaderId: '# ID',
    AppointmentTypeListTableHeaderName: 'Nombre',
    AppointmentTypeListTableHeaderStatus: 'Estado',
    AppointmentTypeListTableChipActive: 'Activa',
    AppointmentTypeListTableChipHidden: 'Oculta',
    AppointmentTypeListTableChipProvisional: 'Provisional',
    AppointmentTypeListTableChipStatusFilter: 'Oculta según estado',
    AppointmentTypeHeaderId: '# ID',
    AppointmentTypeCaptionId: 'ID del tipo de cita en la HCE',
    AppointmentTypeHeaderCreatedAt: 'Creada el',
    AppointmentTypeCaptionCreatedAt:
      'Indica que el tipo de cita ha sido creado en la HCE',
    AppointmentTypeHeaderActive: 'Visible en Salve',
    AppointmentTypeCaptionActive:
      'Será posible crear contenido para estas citas, y también serán visibles en el plan de tratamiento de los pacientes',
    AppointmentTypeHeaderHidden: 'Siempre oculta',
    AppointmentTypeCaptionHidden: 'Esta cita nunca es visible en la app',
    AppointmentTypeHeaderProvisional: 'Provisional hasta',
    AppointmentTypeCaptionProvisional:
      'Se mostrará como provisional en la app del paciente hasta la hora indicada',
    AppointmentTypeValueProvisional: (duration: string) => `${duration} antes`,
    AppointmentTypeHeaderStatusFilters: 'Oculta según estado en la HCE',
    AppointmentTypeCaptionStatusFilters:
      'Oculta hasta que el estado especificado se aplique a la cita en la HCE',
    AppointmentTypeHeaderVideoCall: 'Convertir a video llamada',
    AppointmentTypeCaptionVideoCall:
      'Convertir todas las citas de este tipo a video llamadas. Esta opción solo debe usarse para tipos de cita que se llevarán a cabo exclusivamente a través de video llamada. <b>Importante:</b> Esto solo convertirá las citas de este tipo que se reserven <u>después</u> de activar la configuración. Cualquier cita de este tipo reservada antes de activarla no será convertida.',
    AppointmentTypeValueYes: 'Sí',
    AppointmentTypeValueNo: 'No',
    AppointmentTypeListErrorToastMessage: 'Error cargando los tipos de cita',
    AppointmentTypeLoadingErrorToastHeader: 'Error de red',
    AppointmentTypeLoadingErrorToastMessage: 'Error cargando el tipo de cita',
    AppointmentTypeProvisionalHoursInputErrorRequired:
      'Ingresa al menos un dígito en el campo de arriba',
    AppointmentTypeProvisionalHoursInputLabel1: 'Provisional hasta:',
    AppointmentTypeProvisionalHoursInputLabel2:
      'horas antes de la hora de la cita',
    AppointmentTypeSavingErrorHeader: 'No se ha podido guardar los cambios',
    AppointmentTypeSavingErrorMessage:
      'Por favor verifica tu conexión y vuelve a intentarlo',
    AppointmentTypeSavingSuccessHeader: 'Cambios guardados',
    AppointmentTypeSavingSuccessMessage:
      'Los cambios han sido guardados exitosamente y serán visibles en los próximos 10 minutos.',
    MessageTemplatesContentErrorLength: (max: number) =>
      `El Contenido debe tener como máximo ${max} caracteres`,
    MessageTemplatesContentErrorRequired: 'El Contenido es obligatorio',
    MessageTemplatesDeleteButtonText: 'Eliminar',
    MessageTemplatesDeleteDialogAcceptButtonText: 'Eliminar',
    MessageTemplatesDeleteDialogCancelButtonText: 'Cancelar',
    MessageTemplatesDeleteDialogContent:
      'Si eliminas esta plantilla, ya no será posible utilizarla en tus conversaciones. Esta acción es irreversible.',
    MessageTemplatesDeleteDialogTitle: '¿Estás seguro?',
    MessageTemplatesEmptyMessage: 'No hay plantillas de mensaje guardadas',
    MessageTemplatesEmptyNonMatchingMessage:
      'Las plantillas de mensaje existentes no coinciden con tu búsqueda',
    MessageTemplatesInsertVariableButtonText:
      '{{{\u00A0\u00A0}}} Insertar variable',
    MessageTemplatesLastUpdatedByLabel: 'Última actualización por',
    MessageTemplatesLoadingErrorMessage: 'Error cargando plantillas de mensaje',
    MessageTemplatesLoadingErrorRetryButtonText: 'Reintentar',
    MessageTemplatesNameErrorLength: (min: number, max: number) =>
      `El nombre de la plantilla debe tener entre ${min}–${max} caracteres`,
    MessageTemplatesNameErrorRequired: 'El Nombre es obligatorio',
    MessageTemplatesNameErrorUnique:
      'Este nombre ya está en uso. Vuelve a intentarlo con un nombre distinto.',
    MessageTemplatesNameLabel: 'Nombre',
    MessageTemplatesNamePlaceholder: 'e.g. Confirmación de cita',
    MessageTemplatesNavItem: 'Plantillas de mensaje',
    MessageTemplatesNavigationWarning:
      'Por favor recuerda guardar tus cambios antes de abandonar la página.',
    MessageTemplatesNewTemplateButtonText: 'Nueva plantilla',
    MessageTemplatesSaveTemplateButtonText: 'Guardar plantilla',
    MessageTemplatesSearchPlaceholder: 'Buscar plantillas de mensaje…',
    MessageContentSearchPlaceholder: 'Buscar contenido',
    MessageContentSearchNoResults:
      'No hay contenido aquí. Puedes crearlo en la sección Contenido',
    MessageContentSearchSelect:
      'Por favor selecciona una carpeta o pack a la izquierda',
    MessageContentNoFolders:
      'No hay carpetas de contenido. Puedes crearlas en la sección Contenido',
    MessageContentNoPacks:
      'No hay packs de contenido. Puedes crearlos en la sección Contenido',
    MessageContentEmptySearch: 'La búsqueda no ha dado resultados',
    MessageContentLoadError: 'Error cargando el contenido',
    MessageContentInsertLink: 'Insertar enlace a contenido',
    MessageTemplatesTemplateContentLabel: 'Contenido de plantilla',
    NoAccessButtonText: 'Volver al inicio',
    NoAccessMessage:
      'No tienes permiso para acceder a esta página. Si crees que esto se trata de un error, por favor contact a tu superior.',
    SIDE: {
      NavItem: 'SIDE',
      SearchBarPlaceholder: 'Buscar recetas SIDE...',
      RecipeListTableHeaderId: 'ID',
      RecipeListTableHeaderName: 'Nombre',
      RecipeListTableHeaderStatus: 'Estado',
      RecipeListTableHeaderCreatedOn: 'Creada el',
      RecipeListTableHeaderLastUpdatedOn: 'Última actualización el',
      RecipeListTableHeaderLastUpdatedBy: 'Última actualización por',
      RecipeListLoadingErrorToastHeader: 'Error de red',
      RecipeListErrorToastMessage: 'Error cargando recetas SIDE',
      RecipeListTableHeaderAction: 'Acción',
      RecipeListTableHeaderEventType: 'Tipo de evento',
      AppointmentEvent: 'Cita',
      MedicationEvent: 'Medicación',
      RegistrationEvent: 'Registración',
      ActiveStatus: 'Activa',
      InactiveStatus: 'Inactiva',
      RecipeListEmptyMessage: 'No hay recetas SIDE',
      RecipeLoadingErrorToastHeader: 'Error de red',
      RecipeLoadingErrorToastMessage: 'Error cargando receta',
      SettingsHeader: 'Ajustes',
      RecipeNameLabel: 'Nombre',
      RecipeStatusLabel: 'Estado',
      ActionListTableHeaderId: 'ID',
      ActionListTableHeaderContent: 'Contenido',
      ActionListTableHeaderActionType: 'Tipo de acción',
      ActionListTableHeaderTiming: 'Tiempo',
      ActionListTableHeaderMedicationStatus: 'Estado de medicación',
      ActionListTableHeaderActionStatus: 'Estado de regla',
      ActionListTableHeaderCreatedOn: 'Creada el',
      ActionListTableHeaderLastUpdatedOn: 'Última actualización el',
      ActionListTableHeaderLastUpdatedBy: 'Última actualización por',
      ActionListTableHeaderAppointmentStatus: 'Estado de cita',
      ActionListTableHeaderSite: 'Sitio',
      ActionHeader: 'Acciones',
      ActionListEmptyMessage: 'Esta receta no tiene acciones asociadas',
      'Not setAppointmentStatus': 'Indefinida',
      AttendedAppointmentStatus: 'Asistida',
      'No-ShowAppointmentStatus': 'Ausente',
      CancelledAppointmentStatus: 'Cancelada',
      'Before AppointmentAppointmentStatus': 'Antes de la cita',
      'After AppointmentAppointmentStatus': 'Después de la cita',
      ConfirmedAppointmentStatus: 'Confirmada',
      'Not CancelledAppointmentStatus': 'No cancelada',
      BookedAppointmentStatus: 'Agendada',
      BookedMedicationStatus: 'Agendada',
      HourPeriod: 'hora',
      MinutePeriod: 'minuto',
      DayPeriod: 'día',
      Before: 'antes',
      After: 'después',
      ActionListLoadingErrorToastHeader: 'Error de red',
      ActionListErrorToastMessage: 'Error cargando acciones',
      AppointmentTriggerHeader: 'Tipos de cita asociados',
      MedicationTriggerHeader: 'Tipos de medicación asociados',
      TriggerListLoadingErrorToastHeader: 'Error de red',
      TriggerListErrorToastMessage: 'Error cargando eventos',
      NoTriggersAvailable: 'No hay eventos desencadenantes disponibles',
      ExportCSV: 'Exportar a CSV',
      ExportDialogTitle: '¿Desea exportar todos los datos de SIDE?',
      ExportDialogDescription:
        'Tenga en cuenta que los filtros aplicados a los resultados no afectarán a los datos exportados',
      ExportDialogCancel: 'Cancelar',
      ExportDialogDownload: 'Descargar',
      SIDEExport: 'datos SIDE',
      ExportErrorTitle: 'Error descargando datos',
      ExportErrorDescription:
        'Por favor verifica tu conexión y vuelve a intentarlo',
      AddFilter: 'Añadir filtro',
      FilterApply: 'Aplicar',
      FilterTabEventType: 'Tipo de evento',
      FilterTabStatus: 'Estado',
      FilterTabAppointmentType: 'Tipos de cita',
      FilterTabDrugType: 'Tipos de medicación',
      FilterApptSearchBarPlaceholder: 'Buscar tipos de cita...',
      FilterDrugSearchBarPlaceholder: 'Buscar tipos de medicación...',
      ClearAllFilter: 'Quitar filtros',
      RecipeNameRequiredErrorMessage: 'Se necesita un nombre de receta',
      RecipesSavingErrorHeader: 'Error actualizando la receta',
      RecipesSavingErrorMessage:
        'Por favor verifica tu conexión y vuelve a intentarlo',
      RecipesSavingSuccessHeader: 'Receta SIDE actualizada',
      RecipesSavingSuccessMessage: 'La receta ha sido actualizada exitosamente',
      RecipeNameErrorLength: (min: number, max: number) =>
        `El nombre debe tener entre ${min}–${max} caracteres`,
      RecipeSaveButtonText: 'Guardar',
      RecipeTriggerInfo:
        'A fin de mantener la integridad de registros de auditoría y responsabilidad, los tipos de cita afectados no se pueden modificar. Si desea realizar cambios, por favor marque esta receta como inactiva y vuelva a crear una nueva.',
      RecipeUniqueNameError: 'Este nombre ya está en uso.',
    },
    Users: {
      NavItem: 'Usuarios',
      SearchBarPlaceholder: 'Buscar usuarios',
      UsersListEmptyMessage: 'No se encontraron usuarios',
      UsersListEmptyActiveUsers: 'No hay usuarios activos',
      UsersListEmptyInvitedUsers: 'No hay usuarios invitados',
      UsersListEmptyInactiveUsers: 'No hay usuarios inactivos',
      UsersListEmptyUsers: 'No se han encontrado resultados',
      ActiveStatus: 'Activo',
      DeactivatedStatus: 'Inactivo',
      InvitedStatus: 'Invitado',
      UsersListTableHeaderFirstName: 'Nombre',
      UsersListTableHeaderLastName: 'Apellido',
      UsersListTableHeaderEmail: 'Correo electrónico',
      UsersListTableHeaderStatus: 'Estado',
      UsersListTableHeaderLastLogIn: 'Último acceso',
      UsersListTableHeaderRegistered: 'Registrado',
      UsersLoadingErrorToastHeader: 'Error de red',
      UsersLoadingErrorToastMessage: 'Error al cargar usuarios',
      AddFilter: 'Añadir filtro',
      FilterApply: 'Aplicar',
      FilterTabStatus: 'Estado',
      UsersExport: 'Exportación de usuarios de Salve',
      ExportCSV: 'Exportar CSV',
      ExportDialogTitle: '¿Exportar a todos los usuarios?',
      ExportDialogDescription:
        'Tenga en cuenta que esta exportación de CSV contendrá la última sincronización de todos los miembros del personal que se hayan registrado en su HCE, incluso aquellos que no hayan creado una cuenta Salve.',
      ExportDialogCancel: 'Cancelar',
      ExportDialogDownload: 'Exportar',
      ExportErrorTitle: 'No se pudo exportar el CSV',
      ExportErrorDescription:
        'Por favor, compruebe la conexión y vuelva a intentarlo',
      ExportSuccessTitle: 'Exportado exitosamente',
      ExportSuccessDescription: 'Por favor vea el CSV en sus descargas',
      InviteUserAction: 'Invitar usuario',
      DeactivateUserAction: 'Quitar acceso a Salve',
      InviteUserErrorTitle: 'No se pudo realizar la acción',
      InviteUserErrorDescription:
        'Por favor verifique su conexión e intente de nuevo',
      InviteUserSuccessTitle: 'Invitación enviada',
      InviteUserSuccessDescription:
        'Esta invitación ha sido enviada al usuario.',
      DeactivateUserErrorTitle: 'No se pudo realizar la acción',
      DeactivateUserErrorDescription:
        'Por favor verifique su conexión e intente de nuevo',
      InviteUserModalCancel: 'Cancelar',
      InviteUserModalAccept: 'Invitar',
      DeactivateUserModalTitle:
        '¿Está seguro de que desea quitar el acceso a Salve para este usuario?',
      DeactivateUserModalText:
        'Tenga en cuenta que esto impedirá que los usuarios puedan iniciar sesión en el portal. Si desea volver a dar acceso a los usuarios al portal, será necesario volver a invitarlos.',
      DeactivateUserModalCancel: 'Cancelar',
      DeactivateUserModalAccept: 'Quitar acceso a Salve',
      TooltipActive: 'El miembro del personal tiene acceso al portal Salve.',
      TooltipInactive:
        'El miembro del personal no tiene acceso al portal Salve.',
      TooltipInvited:
        'El miembro del personal ha sido invitado al portal Salve.',
      InviteUser: 'Invitar usuario',
      User: 'Usuario',
      WelcomeMessage: 'Mensaje de bienvenida',
      Send: 'Enviar',
      Cancel: 'Cancelar',
      InviteSentTitle: 'Invitación enviada',
      InviteSentMessage: 'Esta invitación ha sido enviada al usuario.',
      InviteSentErrorTitle: 'Invitación enviada',
      InviteSentErrorMessage:
        'Por favor verifique su conexión e intente de nuevo. Asegúrese también de que el correo electrónico del usuario sea correcto.',
      DeactivateSentTitle: 'Usuario inactivo',
      DeactivateSentMessage: 'El usuario ha sido desactivado.',
      DeactivateSentErrorTitle: 'No se pudo desactivar el usuario',
      DeactivateSentErrorMessage:
        'Por favor verifique su conexión e intente de nuevo',
      InviteMessage: (clinicURL) => `Hola,
¡Bienvenido/s a Salve! Si ya tiene una cuenta de Salve para un portal clínico diferente, utilice sus datos de inicio de sesión existentes cuando acceda a este portal.
Si es nuevo en Salve, le enviamos un correo electrónico por separado con su nombre de usuario y contraseña temporal. Úselos para iniciar sesión y crear su cuenta permanente en su portal: ${clinicURL}\n
Su contraseña temporal caducará en tres días. Si no configura su cuenta dentro de esos tres días, deberá solicitarnos una nueva contraseña temporal.
Tenga en cuenta que debe acceder al portal Salve a través de Google Chrome o Microsoft Edge. Salve no es compatible con Internet Explorer.`,
      SendTemporaryPassword: 'Reenviar nueva contraseña temporal',
      SendTemporaryPasswordModalTitle: '¿Enviar nueva contraseña temporal?',
      SendTemporaryPasswordModalText: `Al hacer clic en 'Enviar' se enviará al miembro del personal una nueva contraseña temporal a su correo electrónico.`,
      SendTemporaryPasswordErrorTitle:
        'No se pudo enviar una nueva contraseña temporal',
      SendTemporaryPasswordErrorDescription:
        'Por favor verifique su conexión e intente de nuevo',
      SendTemporaryPasswordSuccessTitle: 'Nueva contraseña temporal enviada',
      SendTemporaryPasswordSuccessDescription: `Se ha enviado al miembro del personal una nueva contraseña temporal.`,
    },
  },
  SuperAdmin: {
    DataExport: {
      Title: 'Exportación de datos',
      StartDateLabel: 'Fecha de inicio',
      EndDateLabel: 'Fecha de fin',
      TimeFrameLabel: 'Intervalo de tiempo',
      RecipeLabel: 'Receta',
      Today: 'Hoy',
      Yesterday: 'Ayer',
      Last7Days: 'Últimos 7 días',
      LastWeek: 'Semana pasada',
      ThisMonth: 'Este mes',
      LastMonth: 'Mes pasado',
      Last12Months: 'Últimos 12 meses',
      CustomDates: 'Fechas personalizadas',
      MessagesTitle: 'Mensajes',
      MessagesSubtitle:
        'La exportación contendrá detalles de las conversaciones entre el personal de la clínica y los pacientes en un intervalo de tiempo seleccionado',
      MessagesFilenameSuffix: 'Datos de Mensajería Salve',
      SIDERecipeTitle: 'Datos de SIDE',
      SIDERecipeSubtitle:
        'La exportación contendrá detalles de las estadísticas de SIDE basadas en una receta',
      SIDERecipeFilenameSuffix: 'Datos de Receta Salve',
      SIDERecipeInactive: 'Inactivo',
      ToastSuccessTitle: 'Exportación exitosa',
      ToastSuccessDescription:
        'Los datos solicitados se han exportado correctamente. Por favor, verifica la carpeta de descargas',
      ToastFailureTitle: 'Error en la exportación',
      ToastFailureDescription:
        'Por favor, verifica tu conexión e intenta nuevamente',
    },
    NoAccessButtonText: 'Volver al inicio',
    NoAccessMessage:
      'No tienes permiso para acceder a esta página. Si crees que esto se trata de un error, por favor contact a tu superior.',
  },
  General: {
    OpenAttachmentFileErrorTitle: 'No se ha podido abrir el archivo',
    OpenAttachmentFileErrorMessage:
      'Por favor verifica tu conexión y vuelve a intentarlo',
    ConnectionError: 'Por favor verifica tu conexión y vuelve a intentarlo',
  },
  GlobalCMS: {
    Back: 'Atrás',
  },
};
