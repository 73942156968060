import { Box, Popover } from '@material-ui/core';
import { useFeatures } from '@react/lib/features/context';
import * as React from 'react';
import { useTranslations } from '../lib/i18n';
import { Button } from './Button';
import { CharacterCount } from './CharacterCount';
import MessageAddContentLink from './MessageAddContentLink';
import MessageAddTemplate from './MessageAddTemplate';
import {
  Container,
  StatusBar,
  TextArea,
  TextAreaContainer,
  Toolbar,
  ToolbarIconButton,
  WarningIcon,
  WarningText,
} from './MessageEditor.styled';

interface Props {
  actionText?: string;
  isActionActive?: boolean;
  maxLength: number;
  onActionClick?: () => void;
  onChange: (value: string) => void;
  patientId?: number | null;
  value: string;
  warningText?: string;
  inputId?: string;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
}

enum MessageInsert {
  Template = 'template',
  ContentLink = 'contentLink',
}

export const MessageEditor: React.VFC<Props> = ({
  actionText,
  isActionActive,
  maxLength,
  onActionClick,
  onChange,
  patientId,
  value,
  warningText,
  inputId,
  minRows = 4,
  maxRows = 18,
  ...props
}) => {
  const features: { [key: string]: any } = useFeatures();
  const enableContentLinks =
    !!features.messagingOptions && features.messagingOptions.contentLinks;
  const { t } = useTranslations();
  const [activeModalType, setActiveModalType] = React.useState<MessageInsert>();
  const templateButtonRef = React.useRef<HTMLButtonElement>(null);
  const contentLinkButtonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [modalAnchorRef, setModalAnchorRef] =
    React.useState<React.MutableRefObject<HTMLButtonElement>>(null);
  const disabled = props.disabled || patientId == null;

  const insertAtCursorPos = (txt: string) => {
    const inputEl = inputRef.current;
    if (!inputEl) {
      return;
    }

    const strPos = inputEl.selectionStart;
    const textBeforeInsertedString = value.substring(0, strPos);
    const textAfterInsertedString = value.substring(strPos, value.length);
    const textBeforeCursor = textBeforeInsertedString.length
      ? `${textBeforeInsertedString} ${txt}`
      : txt;
    const newText = `${textBeforeCursor} ${textAfterInsertedString}`;
    onChange(`${newText}`);
  };

  const handleContentEntry = (contentLink: string) => {
    insertAtCursorPos(contentLink);
    setActiveModalType(undefined);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const onInsertMessageTemplate = (template: string) => {
    onChange(template);
    setActiveModalType(undefined);
  };

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >(
    (e) => {
      if (onChange != null) {
        onChange(e.target.value);
      }
    },
    [onChange],
  );

  const viewContentLinkModal = () => {
    setModalAnchorRef(contentLinkButtonRef);
    setActiveModalType(MessageInsert.ContentLink);
  };

  const viewMessageTemplateModal = () => {
    setModalAnchorRef(templateButtonRef);
    setActiveModalType(MessageInsert.Template);
  };

  return (
    <Container>
      <TextAreaContainer>
        <TextArea
          id={inputId}
          autoComplete="off"
          disabled={disabled}
          disableUnderline
          inputProps={{
            maxLength,
          }}
          inputRef={inputRef}
          minRows={minRows}
          maxRows={maxRows}
          multiline
          placeholder={t.Messages.WriteAMessage}
          onChange={handleChange}
          value={value}
        />
        <StatusBar>
          <WarningText>
            {warningText != null && warningText !== '' ? (
              <>
                <WarningIcon />
                {warningText}
              </>
            ) : null}
          </WarningText>
          <CharacterCount length={value.length} maxLength={maxLength} />
        </StatusBar>
      </TextAreaContainer>
      <Toolbar>
        <Box>
          <ToolbarIconButton
            disabled={disabled}
            onClick={viewMessageTemplateModal}
            ref={templateButtonRef}
            size="small"
            role="button"
            title={t.Messages.InsertMessageTemplate}
          >
            <img
              src={`assets/imgs/messagetemplate_icon_${
                disabled ? 'inactive' : 'active'
              }.svg`}
            />
          </ToolbarIconButton>
          {enableContentLinks && (
            <ToolbarIconButton
              disabled={disabled}
              ref={contentLinkButtonRef}
              onClick={viewContentLinkModal}
              size="small"
              role="button"
              title={t.Admin.MessageContentInsertLink}
            >
              <img
                src={`assets/imgs/contentlink_icon_${
                  disabled ? 'inactive' : 'active'
                }.svg`}
              />
            </ToolbarIconButton>
          )}
        </Box>
        {actionText != null && onActionClick != null ? (
          <Button
            color="primary"
            disabled={disabled || !isActionActive}
            onClick={onActionClick}
            size="small"
            variant="contained"
          >
            {actionText}
          </Button>
        ) : null}
      </Toolbar>
      <Popover
        anchorEl={
          modalAnchorRef && modalAnchorRef.current
            ? modalAnchorRef.current
            : undefined
        }
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={!!activeModalType}
        onClose={() => {
          setActiveModalType(undefined);
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <Box display="flex" flexDirection="row">
          {activeModalType === MessageInsert.ContentLink && (
            <MessageAddContentLink onAddContentLink={handleContentEntry} />
          )}
          {activeModalType === MessageInsert.Template && patientId && (
            <MessageAddTemplate
              onAddTemplate={onInsertMessageTemplate}
              patientId={patientId}
            />
          )}
        </Box>
      </Popover>
    </Container>
  );
};
