import { IconButton, MenuItem } from '@material-ui/core';
import { useEnvVariables } from '@react/lib/hooks';
import { useFetchMeWithClinics } from '@react/pages/conversations/hooks';
import React, { FunctionComponent, useRef, useState } from 'react';
import { StyledMenu } from '../Menu.styled';
import { StyledChevron, StyledLink, StyledTitle } from './index.styled';

export interface Props {
  clinicName: string;
  clinicToken: string;
}

const SwitchPortal: FunctionComponent<Props> = ({
  clinicName,
  clinicToken,
}: Props) => {
  const env = useEnvVariables();
  const result = useFetchMeWithClinics();
  const clinicUser = result.data;

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(menuRef.current);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const portalUrl = (subdomain: string) =>
    `https://${subdomain}.${env.portalDomain}`;

  const clinics = clinicUser && clinicUser.clinics;
  const clinicsWithoutCurrent =
    clinics && clinics.filter((clinic) => clinic.token !== clinicToken);

  if (
    !clinicUser ||
    !clinicsWithoutCurrent ||
    clinicsWithoutCurrent.length === 0
  ) {
    return (
      <>
        <span className="navbar-brand">{clinicName}</span>
      </>
    );
  }

  return (
    <>
      <StyledTitle className="navbar-brand" onClick={handleOpen}>
        {clinicName}
      </StyledTitle>
      <IconButton
        data-testid="switch-portal-button"
        id="switch-portal-button"
        aria-controls="switch-portal"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        size="small"
        onClick={handleOpen}
      >
        <StyledChevron open={menuOpen} />
      </IconButton>
      <StyledMenu
        data-testid="switch-portal-menu"
        id="switch-portal-menu"
        aria-labelledby="switch-portal-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: 300,
            maxWidth: 300,
          },
        }}
      >
        {clinicsWithoutCurrent.map((clinic) => (
          <MenuItem key={clinic.name}>
            <StyledLink
              role="link"
              title={clinic.name}
              href={portalUrl(clinic.subdomain)}
            >
              {clinic.name}
            </StyledLink>
          </MenuItem>
        ))}
      </StyledMenu>
      <div ref={menuRef} />
    </>
  );
};

export default SwitchPortal;
