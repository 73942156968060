import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { LinkDeletionParamTypes, } from '../../models/linked-content';
import { ContentEntryHeader } from './../../models/content-entry';
var ViewChildrenComponent = /** @class */ (function () {
    function ViewChildrenComponent(dialog, change) {
        this.dialog = dialog;
        this.change = change;
        this.children = [];
        this.deleteLinks = new EventEmitter();
        this.cancel = new EventEmitter();
        this.config = {};
        this.displayedColumns = ['select', 'name'];
    }
    ViewChildrenComponent.prototype.ngOnInit = function () {
        this._updateDataSource();
    };
    ViewChildrenComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.children && changes.children.currentValue) {
            this._updateDataSource();
        }
    };
    ViewChildrenComponent.prototype.handleDeleteLinks = function () {
        var howMany = this.howManySelected();
        var type = LinkDeletionParamTypes.one;
        if (howMany === 2) {
            type = LinkDeletionParamTypes.some;
        }
        else if (howMany === 3) {
            type = LinkDeletionParamTypes.all;
        }
        var ids = howMany === 3 ? [this.parentInfo.id] : this._selectedItems();
        this.deleteLinks.emit({
            type: type,
            ids: ids,
        });
    };
    // Table related Function
    // ================================
    /** Whether the number of selected elements matches the total number of rows. */
    ViewChildrenComponent.prototype.isAllSelected = function () {
        return this.selection.selected.length === this.dataSource.data.length;
    };
    ViewChildrenComponent.prototype.howManySelected = function () {
        if (this.selection.selected.length === 0) {
            return 0; // none
        }
        if (this.selection.selected.length === this.dataSource.data.length) {
            return 3; // all (could be one - so this goes first)
        }
        if (this.selection.selected.length === 1) {
            return 1; // one
        }
        return 2; // some
    };
    ViewChildrenComponent.prototype.removeLinkText = function () {
        var howMany = this.howManySelected();
        switch (howMany) {
            case 0:
            case 1:
                return this.text.RemoveLink;
            case 2:
                return this.text.RemoveSomeLinks;
            case 3:
                return this.text.RemoveAllLinks;
        }
    };
    ViewChildrenComponent.prototype.clearSelection = function () {
        this.selection.clear();
        this.change.markForCheck();
        this.change.detectChanges();
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    ViewChildrenComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected()
            ? this.clearSelection()
            : this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    ViewChildrenComponent.prototype._selectedItems = function () {
        return this.selection.selected.map(function (r) { return r.id; });
    };
    ViewChildrenComponent.prototype._updateDataSource = function () {
        var _this = this;
        this.dataSource = new MatTableDataSource(this.children);
        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel(true, []);
        if (this.childSelected && this.dataSource.data.length > 0) {
            var first = this.dataSource.data[0];
            if (first.id === this.childSelected) {
                this.dataSource.data.some(function (row) {
                    if (row.id === _this.childSelected) {
                        _this.selection.select(row);
                        return true;
                    }
                    else {
                        return false;
                    }
                });
            }
        }
    };
    return ViewChildrenComponent;
}());
export { ViewChildrenComponent };
