import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { CameraAlt as CameraIcon } from '@material-ui/icons';
import React from 'react';
export var Placeholder = function (_a) {
    var onSelectClicked = _a.onSelectClicked;
    return (React.createElement(Circle, { "aria-label": "cameraIcon", role: "button", onClick: onSelectClicked },
        React.createElement(CameraIcon, { fontSize: "large" })));
};
var Circle = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border: 2px dashed ", ";\n  border-radius: 50%;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  height: 200px;\n  width: 200px;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border: 2px dashed ", ";\n  border-radius: 50%;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  height: 200px;\n  width: 200px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.gray;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text.primary;
});
var templateObject_1;
