import { useApiClient } from '@react/lib/api';
import { ConversationsCountFilters } from '@react/lib/api/types';
import pick from 'lodash/pick';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { filtersToApi } from '../lib';
import { FilterState } from '../types';

export function useConversationsCountByStatusQuery(
  filters: FilterState,
  q?: string,
): Record<string, string> {
  const apiClient = useApiClient().portal;

  const apiFilters: ConversationsCountFilters = filtersToApi({
    ...filters,
    q,
  });

  // Guard against extra filters being passed in.
  const trimmedFilters = pick(apiFilters, [
    'q',
    'messagetypeids',
    'isresolved',
    'staffids',
    'assignedids',
    'isunassigned',
  ]);

  const response = useQuery(
    [queryCacheKey.CONVERSATIONS_COUNT_BY_STATUS, trimmedFilters],
    () => apiClient.fetchConversationsCountByStatus(trimmedFilters),
    {
      refetchInterval: POLLING_INTERVAL_MS,
    },
  );

  if (response.data && response.data.data) {
    const items = response.data.data;
    return items.reduce(
      (acc, item) => ({ ...acc, [item.name]: item.count }),
      {},
    );
  }

  return {};
}
