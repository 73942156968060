import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TableMedicationAction } from '../../../dashboard/models/MedicationAction';

@Component({
  selector: 'portal-drugs-table',
  templateUrl: './drugs-table.component.html',
  styleUrls: ['./drugs-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrugsTableComponent {
  @Input() display = true;
  @Input() title = 'Medications Table';
  @Input() emptyMessage = '';
  @Input() medications: TableMedicationAction[] = [];
  @Input() text: any;
  @Input() displayedColumns: string[] = [
    'name',
    'type',
    'drug',
    'dosage',
    'status',
  ];
  @Output() gotoPatient = new EventEmitter<number>();

  public matIcon(status: number): string {
    // query_builder = coming up = >100
    // error = overdue = 2
    let n = status;

    if (status >= 100) {
      n = 100;
    }

    const symbols = {
      100: 'query_builder',
      50: 'check_circle',
      2: 'error',
    };

    return symbols[n];
  }

  public goto(row: TableMedicationAction) {
    this.gotoPatient.emit(row.id);
  }
}
