import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ServerError } from '../../models/Error';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
import {
  AddMessageToThread,
  LoadNewestMessages,
} from '../actions/load-message.actions';
import { SetSelectedTab } from '../actions/message-ui.actions';
import {
  NewMessageActionTypes,
  SendNewMessage,
  SendNewMessageError,
  SendNewMessageSuccess,
  SendNewThread,
  ShowRerouteToast,
} from '../actions/new-message.actions';
import * as fromMessages from '../reducers';
import { MessageService } from '../services/message.service';

@Injectable()
export class NewMessageEffects {
  private _toastText: any;

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private messageService: MessageService,
    private _store: Store<fromRoot.State>,
  ) {
    this._store
      .pipe(select(fromSettings.getSectionTranslations('MessageToast')))
      .subscribe((val) => {
        this._toastText = val;
      });
  }

  // Send New Message
  @Effect()
  sendNewMessage$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<SendNewMessage>(NewMessageActionTypes.SendNewMessage),
      switchMap((action) => {
        return this.messageService
          .addMessageToThread(action.payload.newMessage)
          .pipe(
            mergeMap((response) => {
              const result = {
                ...action.payload,
                message: {
                  ...action.payload.message,
                  MessageItemId: response,
                },
              };
              return [
                new AddMessageToThread(result),
                new SendNewMessageSuccess({
                  mesageStarringEnabled: action.payload.messageStarringEnabled,
                }),
              ];
            }),
            catchError(() => {
              this.toastr.show(
                this._toastText.ErrorSending(
                  action.payload.message.PatientFirstName +
                    ' ' +
                    action.payload.message.PatientLastName,
                ),
                this._toastText.ErrorEncountered,
                {
                  timeOut: 0,
                  closeButton: true,
                  toastClass: `toast custom-toast error`,
                },
              );

              return [
                new SendNewMessageError(
                  new ServerError(
                    '[NewMessage]',
                    'Error sending new message',
                    action.type,
                  ),
                ),
              ];
            }),
          );
      }),
    );
  }

  // Create new Thread (outbound messaging)
  @Effect()
  sendNewThread$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<SendNewThread>(NewMessageActionTypes.SendNewThread),
      withLatestFrom(this._store.pipe(select(fromMessages.getCurrentTab))),
      mergeMap(([action, tab]) => {
        const { newMessage, messageInfo } = action.payload.data;
        const { patientName, ...rest } = newMessage;
        const toast = action.payload.toast;
        return this.messageService.createNewThread(rest).pipe(
          mergeMap((newThread: { Result: string; NewMessageId: number }) => {
            return [
              new ShowRerouteToast({
                toast: toast.success,
                route: '/messages',
                other: {
                  ...rest,
                  ...newMessage,
                  ...messageInfo,
                  id: newThread.NewMessageId,
                },
                afterAction: new SetSelectedTab({
                  tab,
                  params: {
                    MessageTypeId: newMessage.MessageTypeId,
                    FetchAfterMessageItemId: null,
                    FetchBeforeMessageItemId: null,
                  },
                }),
              }),
              new SendNewMessageSuccess({
                mesageStarringEnabled: action.payload.messageStarringEnabled,
              }),
            ];
          }),
          catchError((err) => {
            return [
              new SendNewMessageError(
                new ServerError(
                  '[NewMessage]',
                  'Error sending new message to new conversation',
                  action.type,
                ),
              ),
              new ShowRerouteToast({
                toast: toast.error,
              }),
            ];
          }),
        );
      }),
    );
  }

  @Effect()
  sendNewMessageSuccess(): Observable<Action> {
    return this.actions$.pipe(
      ofType<SendNewMessageSuccess>(
        NewMessageActionTypes.SendNewMessageSuccess,
      ),
      withLatestFrom(
        this._store.pipe(select(fromMessages.getMessageList)),
        this._store.pipe(select(fromMessages.getNextPageToLoad)),
      ),
      switchMap(([action, messages, nextPageToLoad]) => {
        const latestMessageItemId =
          messages.length > 0 ? messages[0].MessageItemId : 0;
        return [
          new LoadNewestMessages({
            fetchParams: {
              ...nextPageToLoad,
              FetchBeforeMessageItemId: null,
              FetchAfterMessageItemId: latestMessageItemId,
            },
            showPushNotification: false,
          }),
        ];
      }),
    );
  }
}
