<mat-card class="card-container">
  <perfect-scrollbar
    class="create-core-account"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div
      class="form-container"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="30px"
    >
      <form
        [formGroup]="createAccountForm"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="20px"
      >
        <div
          class="create-your-account"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="30px"
        >
          <h1 class="head">{{ text.CreateYourAccount }}</h1>
          <h4 class="sub-head">{{ text.FillInBelow }}</h4>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="20px"
          class="full-width"
        >
          <mat-form-field fxFlex="1 1 calc(50% - 10px)" appearance="fill">
            <mat-label>{{ text.FirstName }}</mat-label>
            <input
              matInput
              class="half-input"
              type="text"
              autocomplete="firstName"
              formControlName="firstName"
              required
            />
            <mat-error
              *ngIf="
                createAccountForm.get('firstName').hasError('required') &&
                (createAccountForm.get('firstName').touched ||
                  createAccountForm.get('firstName').dirty)
              "
            >
              {{ text.FirstNameRequired }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="1 1 calc(50% - 10px)" appearance="fill">
            <mat-label>{{ text.LastName }}</mat-label>
            <input
              matInput
              class="half-input"
              type="text"
              autocomplete="lastName"
              formControlName="lastName"
              required
            />
            <mat-error
              *ngIf="
                createAccountForm.get('lastName').hasError('required') &&
                (createAccountForm.get('lastName').touched ||
                  createAccountForm.get('lastName').dirty)
              "
            >
              {{ text.LastNameRequired }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="full-width"
        >
          <mat-form-field fxFlex="1 1 calc(50% - 10px)" appearance="fill">
            <mat-label>{{ text.CountryCode }}</mat-label>
            <mat-select formControlName="countryCode" required>
              <mat-option>--</mat-option>
              <mat-option
                *ngFor="let countryCode of allCountryCodes"
                [value]="countryCode.value"
              >
                {{ countryCode.sectionText ? countryCode.sectionText : '' }}
                {{ countryCode.text }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                createAccountForm.get('countryCode').hasError('required') &&
                (createAccountForm.get('countryCode').touched ||
                  createAccountForm.get('countryCode').dirty)
              "
            >
              {{ text.CountryCodeRequired }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="1 1 calc(50% - 10px)" appearance="fill">
            <mat-label>{{ text.PhoneNumber }}</mat-label>
            <input
              matInput
              type="text"
              autocomplete="mobileNumber"
              formControlName="mobileNumber"
              required
            />
            <mat-error
              *ngIf="
                createAccountForm.get('mobileNumber').hasError('required') &&
                (createAccountForm.get('mobileNumber').touched ||
                  createAccountForm.get('mobileNumber').dirty)
              "
            >
              {{ text.PhoneNumberRequired }}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-error
          *ngIf="
            !createAccountForm.get('countryCode').hasError('required') &&
            !createAccountForm.get('mobileNumber').hasError('required') &&
            (createAccountForm.hasError('not-mobile') ||
              createAccountForm.hasError('invalid-phone') ||
              createAccountForm.hasError('no-number')) &&
            (createAccountForm.get('mobileNumber').touched ||
              createAccountForm.get('mobileNumber').dirty) &&
            (createAccountForm.get('countryCode').touched ||
              createAccountForm.get('countryCode').dirty)
          "
          class="phone-error"
        >
          {{ text.InvalidPhoneNumber }}
        </mat-error>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ text.Password }}</mat-label>
          <input
            matInput
            type="password"
            autocomplete="password"
            formControlName="password"
            required
          />
          <mat-error
            *ngIf="
              createAccountForm.get('password').hasError('required') &&
              (createAccountForm.get('password').touched ||
                createAccountForm.get('password').dirty)
            "
          >
            {{ text.PasswordRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              !createAccountForm.get('password').hasError('required') &&
              createAccountForm.get('password').hasError('complexity') &&
              (createAccountForm.get('password').touched ||
                createAccountForm.get('password').dirty)
            "
          >
            {{ text.InvalidPasswordException }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ text.ConfirmPassword }}</mat-label>
          <input
            matInput
            type="password"
            autocomplete="password"
            formControlName="confirmPassword"
            required
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            createAccountForm.hasError('mismatch') &&
            (createAccountForm.get('confirmPassword').touched ||
              createAccountForm.get('confirmPassword').dirty ||
              createAccountForm.get('password').touched ||
              createAccountForm.get('password').dirty)
          "
        >
          {{ text.PasswordsMustMatch }}
        </mat-error>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
          <button
            mat-raised-button
            [disabled]="this.createAccountForm.invalid"
            class="portal-btn btn-primary btn-submit"
            (click)="submitForm()"
          >
            {{ text.CreateAccount }}
          </button>
          <div *ngIf="isUpdatingPassword">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
</mat-card>
