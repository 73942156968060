import * as React from 'react';
import { Container, InnerContainer, StyledText } from './Empty.styled';

interface Props {
  /**
   * Optional `data-testid` to apply to root element for testing.
   * Defaults to "Empty".
   */
  'data-testid'?: string;
  icon?: React.ReactNode;
  message: string;
  className?: string;
}

/**
 * Can be used to provide a message to the user that there is no loaded data
 * available.
 */
export const Empty: React.FC<Props> = ({
  'data-testid': dataTestId = 'Empty',
  icon,
  message,
  className,
}) => (
  <Container className={className}>
    <InnerContainer data-testid={dataTestId}>
      {icon && <div>{icon}</div>}
      {/* Need newer version of TypeScript for `component`
        // @ts-ignore  */}
      <StyledText component="span" variant="h6">
        {message}
      </StyledText>
    </InnerContainer>
  </Container>
);
