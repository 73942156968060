import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';

export const ItemText = styled('span')`
  display: block;
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function ListingTooltip({
  title,
  children,
}: {
  title: string;
  children: ReactElement;
}) {
  const enterDelay = 1500;
  return (
    <Tooltip
      title={title}
      placement="top-start"
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      leaveDelay={250}
    >
      {children}
    </Tooltip>
  );
}
