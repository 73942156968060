export var DashboardActionTypes;
(function (DashboardActionTypes) {
    // Server Side
    DashboardActionTypes["LoadClinicStatus"] = "[Dashboard] Load Clinic Status";
    DashboardActionTypes["LoadClinicStatusSuccess"] = "[Dashboard] Load Clinic Status Success";
    DashboardActionTypes["LoadClinicStatusError"] = "[Dashboard] Load Clinic Status Error";
    DashboardActionTypes["LoadClinicSummary"] = "[Dashboard] Load Clinic Summary";
    DashboardActionTypes["LoadClinicSummarySuccess"] = "[Dashboard] Load Clinic Summary Success";
    DashboardActionTypes["LoadClinicSummaryError"] = "[Dashboard] Load Clinic Summary Error";
    DashboardActionTypes["LoadClinicMedication"] = "[Dashboard] Load Clinic Medication";
    DashboardActionTypes["LoadClinicMedicationSuccess"] = "[Dashboard] Load Clinic Medication Success";
    DashboardActionTypes["LoadClinicMedicationError"] = "[Dashboard] Load Clinic Medication Error";
    DashboardActionTypes["LoadClinicNonTimedTriggerMedication"] = "[Dashboard] Load Clinic Non Timed Trigger Medication";
    DashboardActionTypes["LoadClinicNonTimedTriggerMedicationSuccess"] = "[Dashboard] Load Clinic Non Timed Trigger Medication Success";
    DashboardActionTypes["LoadClinicNonTimedTriggerMedicationError"] = "[Dashboard] Load Clinic Non Timed Trigger Medication Error";
    // Client Side
    DashboardActionTypes["RefreshClinicDashboard"] = "[Dashboard] Refresh Clinic Dashboard";
    DashboardActionTypes["RefreshClinicStatus"] = "[Dashboard] Refresh Clinic Status";
    DashboardActionTypes["LoadingClinicMessagingSummary"] = "[Dashboard] Loading Clinic Messaging Summary";
    DashboardActionTypes["LoadedClinicMessagingSummary"] = "[Dashboard] Loaded Clinic Messaging Summary";
    DashboardActionTypes["LoadingClinicSummary"] = "[Dashboard] Loading Clinic Summary";
    DashboardActionTypes["LoadedClinicSummary"] = "[Dashboard] Loaded Clinic Summary";
    DashboardActionTypes["LoadingClinicMedication"] = "[Dashboard] Loading Clinic Medication";
    DashboardActionTypes["LoadedClinicMedication"] = "[Dashboard] Loaded Clinic Medication";
    DashboardActionTypes["LoadingClinicNonTimedTriggerMedication"] = "[Dashboard] Loading Clinic Non Timed Trigger Medication";
    DashboardActionTypes["LoadedClinicNonTimedTriggerMedication"] = "[Dashboard] Loaded Clinic Non Timed Trigger Medication";
})(DashboardActionTypes || (DashboardActionTypes = {}));
// Action Creators
// ==================================================
// Server Side
var LoadClinicStatus = /** @class */ (function () {
    function LoadClinicStatus() {
        this.type = DashboardActionTypes.LoadClinicStatus;
    }
    return LoadClinicStatus;
}());
export { LoadClinicStatus };
var LoadClinicStatusSuccess = /** @class */ (function () {
    function LoadClinicStatusSuccess(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicStatusSuccess;
    }
    return LoadClinicStatusSuccess;
}());
export { LoadClinicStatusSuccess };
var LoadClinicStatusError = /** @class */ (function () {
    function LoadClinicStatusError(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicStatusError;
    }
    return LoadClinicStatusError;
}());
export { LoadClinicStatusError };
var LoadClinicSummary = /** @class */ (function () {
    function LoadClinicSummary() {
        this.type = DashboardActionTypes.LoadClinicSummary;
    }
    return LoadClinicSummary;
}());
export { LoadClinicSummary };
var LoadClinicSummarySuccess = /** @class */ (function () {
    function LoadClinicSummarySuccess(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicSummarySuccess;
    }
    return LoadClinicSummarySuccess;
}());
export { LoadClinicSummarySuccess };
var LoadClinicSummaryError = /** @class */ (function () {
    function LoadClinicSummaryError(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicSummaryError;
    }
    return LoadClinicSummaryError;
}());
export { LoadClinicSummaryError };
var LoadClinicMedication = /** @class */ (function () {
    function LoadClinicMedication() {
        this.type = DashboardActionTypes.LoadClinicMedication;
    }
    return LoadClinicMedication;
}());
export { LoadClinicMedication };
var LoadClinicMedicationSuccess = /** @class */ (function () {
    function LoadClinicMedicationSuccess(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicMedicationSuccess;
    }
    return LoadClinicMedicationSuccess;
}());
export { LoadClinicMedicationSuccess };
var LoadClinicMedicationError = /** @class */ (function () {
    function LoadClinicMedicationError(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicMedicationError;
    }
    return LoadClinicMedicationError;
}());
export { LoadClinicMedicationError };
var LoadClinicNonTimedTriggerMedication = /** @class */ (function () {
    function LoadClinicNonTimedTriggerMedication() {
        this.type = DashboardActionTypes.LoadClinicNonTimedTriggerMedication;
    }
    return LoadClinicNonTimedTriggerMedication;
}());
export { LoadClinicNonTimedTriggerMedication };
var LoadClinicNonTimedTriggerMedicationSuccess = /** @class */ (function () {
    function LoadClinicNonTimedTriggerMedicationSuccess(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicNonTimedTriggerMedicationSuccess;
    }
    return LoadClinicNonTimedTriggerMedicationSuccess;
}());
export { LoadClinicNonTimedTriggerMedicationSuccess };
var LoadClinicNonTimedTriggerMedicationError = /** @class */ (function () {
    function LoadClinicNonTimedTriggerMedicationError(payload) {
        this.payload = payload;
        this.type = DashboardActionTypes.LoadClinicNonTimedTriggerMedicationError;
    }
    return LoadClinicNonTimedTriggerMedicationError;
}());
export { LoadClinicNonTimedTriggerMedicationError };
var RefreshClinicStatus = /** @class */ (function () {
    function RefreshClinicStatus() {
        this.type = DashboardActionTypes.RefreshClinicStatus;
    }
    return RefreshClinicStatus;
}());
export { RefreshClinicStatus };
// Client Side
var RefreshClinicDashboard = /** @class */ (function () {
    function RefreshClinicDashboard() {
        this.type = DashboardActionTypes.RefreshClinicDashboard;
    }
    return RefreshClinicDashboard;
}());
export { RefreshClinicDashboard };
var LoadingClinicSummary = /** @class */ (function () {
    function LoadingClinicSummary() {
        this.type = DashboardActionTypes.LoadingClinicSummary;
    }
    return LoadingClinicSummary;
}());
export { LoadingClinicSummary };
var LoadedClinicSummary = /** @class */ (function () {
    function LoadedClinicSummary() {
        this.type = DashboardActionTypes.LoadedClinicSummary;
    }
    return LoadedClinicSummary;
}());
export { LoadedClinicSummary };
var LoadingClinicMessagingSummary = /** @class */ (function () {
    function LoadingClinicMessagingSummary() {
        this.type = DashboardActionTypes.LoadingClinicMessagingSummary;
    }
    return LoadingClinicMessagingSummary;
}());
export { LoadingClinicMessagingSummary };
var LoadedClinicMessagingSummary = /** @class */ (function () {
    function LoadedClinicMessagingSummary() {
        this.type = DashboardActionTypes.LoadedClinicMessagingSummary;
    }
    return LoadedClinicMessagingSummary;
}());
export { LoadedClinicMessagingSummary };
var LoadingClinicMedication = /** @class */ (function () {
    function LoadingClinicMedication() {
        this.type = DashboardActionTypes.LoadingClinicMedication;
    }
    return LoadingClinicMedication;
}());
export { LoadingClinicMedication };
var LoadedClinicMedication = /** @class */ (function () {
    function LoadedClinicMedication() {
        this.type = DashboardActionTypes.LoadedClinicMedication;
    }
    return LoadedClinicMedication;
}());
export { LoadedClinicMedication };
var LoadingClinicNonTimedTriggerMedication = /** @class */ (function () {
    function LoadingClinicNonTimedTriggerMedication() {
        this.type = DashboardActionTypes.LoadingClinicNonTimedTriggerMedication;
    }
    return LoadingClinicNonTimedTriggerMedication;
}());
export { LoadingClinicNonTimedTriggerMedication };
var LoadedClinicNonTimedTriggerMedication = /** @class */ (function () {
    function LoadedClinicNonTimedTriggerMedication() {
        this.type = DashboardActionTypes.LoadedClinicNonTimedTriggerMedication;
    }
    return LoadedClinicNonTimedTriggerMedication;
}());
export { LoadedClinicNonTimedTriggerMedication };
