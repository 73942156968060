import styled from '@emotion/styled';
import {
  IconButton,
  Menu,
  MenuItem,
  PopoverOrigin,
  Radio
} from '@material-ui/core';
import { useCallbackDebounced } from '@react/lib/hooks/useCallbackDebounced';
import { useTranslations } from '@react/lib/i18n';
import { MediaDevice } from '@zoom/videosdk';
import React, { useCallback } from 'react';
import {
  ClickThroughMenu,
  MenuButton,
  MenuDeviceRowContainer,
  MoreVertIcon
} from './VideoCallMenuButton.styled';

const MenuDeviceRow: React.FC<{
  device: MediaDevice & { active: boolean };
  changeDevice: (deviceId: string) => void;
  closeMenu: () => void;
}> = ({ device, changeDevice, closeMenu }) => {
  const onDeviceClicked = useCallbackDebounced(() => {
    changeDevice(device.deviceId);
    closeMenu();
  }, [device, changeDevice, closeMenu]);
  return (
    <MenuItem key={device.deviceId} onClick={onDeviceClicked}>
      <Radio checked={device.active} color="primary" />
      {device.label}
    </MenuItem>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

export const VideoCallMenuButton: React.FC<{
  microphoneList: (MediaDevice & { active: boolean })[];
  cameraList: (MediaDevice & { active: boolean })[];
  speakerList: (MediaDevice & { active: boolean })[];
  changeCamera: (deviceId: string) => void;
  changeMic: (deviceId: string) => void;
  changeSpeaker: (deviceId: string) => void;
  invitePartnerInCall: () => void;
  partnerInvited: boolean;
  partnerInviteEnabled: boolean;
  invitingPartner: boolean;
  disabled: boolean;
}> = ({
  microphoneList,
  cameraList,
  speakerList,
  changeCamera,
  changeMic,
  changeSpeaker,
  invitePartnerInCall,
  partnerInvited,
  partnerInviteEnabled,
  invitingPartner,
  disabled
}) => {
  const { t } = useTranslations();
  const [menuAnchorElement, setMenuAnchorElement] =
    React.useState<null | HTMLElement>(null);
  const [menuAudioInputAnchorElement, setAudioInputMenuAnchorElement] =
    React.useState<null | HTMLElement>(null);
  const [menuAudioOutputAnchorElement, setAudioOutputMenuAnchorElement] =
    React.useState<null | HTMLElement>(null);
  const [menuVideoAnchorElement, setVideoMenuAnchorElement] =
    React.useState<null | HTMLElement>(null);

  const onAudioInputMenuHover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAudioInputMenuAnchorElement(event.currentTarget);
      setAudioOutputMenuAnchorElement(null);
      setVideoMenuAnchorElement(null);
    },
    []
  );

  const onAudioOutputMenuHover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAudioOutputMenuAnchorElement(event.currentTarget);
      setAudioInputMenuAnchorElement(null);
      setVideoMenuAnchorElement(null);
    },
    []
  );
  const onVideoMenuHover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setVideoMenuAnchorElement(event.currentTarget);
      setAudioOutputMenuAnchorElement(null);
      setAudioInputMenuAnchorElement(null);
    },
    []
  );

  const handleCloseAudioInputMenu = useCallback(() => {
    setAudioInputMenuAnchorElement(null);
  }, []);
  const handleCloseAudioOutputMenu = useCallback(() => {
    setAudioOutputMenuAnchorElement(null);
  }, []);
  const handleCloseVideoMenu = useCallback(() => {
    setVideoMenuAnchorElement(null);
  }, []);

  const onMenuClick = useCallbackDebounced(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    []
  );

  const invitePartner = useCallbackDebounced(() => {
    invitePartnerInCall();
    handleCloseMenu();
  }, [invitePartnerInCall]);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorElement(null);
    setAudioInputMenuAnchorElement(null);
    setAudioOutputMenuAnchorElement(null);
    setVideoMenuAnchorElement(null);
  }, []);

  return (
    <>
      <MenuButton size="medium" disabled={disabled} onClick={onMenuClick}>
        <MoreVertIcon />
      </MenuButton>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorElement}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={handleCloseMenu}
        anchorOrigin={anchorOrigin}
      >
        <MenuItem
          onMouseEnter={onAudioInputMenuHover}
          onMouseLeave={handleCloseAudioInputMenu}
        >
          {t && t.VideoCallModal.AudioInputMenu}
          <ClickThroughMenu
            anchorEl={menuAudioInputAnchorElement}
            open={Boolean(menuAudioInputAnchorElement)}
            onClose={handleCloseMenu}
            anchorOrigin={anchorOrigin}
          >
            <MenuDeviceRowContainer>
              {microphoneList.map((microphone) => {
                return (
                  <MenuDeviceRow
                    key={microphone.deviceId}
                    device={microphone}
                    closeMenu={handleCloseMenu}
                    changeDevice={changeMic}
                  />
                );
              })}
            </MenuDeviceRowContainer>
          </ClickThroughMenu>
        </MenuItem>
        <MenuItem
          onMouseEnter={onAudioOutputMenuHover}
          onMouseLeave={handleCloseAudioOutputMenu}
        >
          {t && t.VideoCallModal.AudioOutputMenu}
          <ClickThroughMenu
            anchorEl={menuAudioOutputAnchorElement}
            open={Boolean(menuAudioOutputAnchorElement)}
            onClose={handleCloseMenu}
            anchorOrigin={anchorOrigin}
          >
            <MenuDeviceRowContainer>
              {speakerList.map((speaker) => {
                return (
                  <MenuDeviceRow
                    key={speaker.deviceId}
                    device={speaker}
                    closeMenu={handleCloseMenu}
                    changeDevice={changeSpeaker}
                  />
                );
              })}
            </MenuDeviceRowContainer>
          </ClickThroughMenu>
        </MenuItem>
        <MenuItem
          onMouseEnter={onVideoMenuHover}
          onMouseLeave={handleCloseVideoMenu}
        >
          {t && t.VideoCallModal.VideoMenu}
          <ClickThroughMenu
            anchorEl={menuVideoAnchorElement}
            open={Boolean(menuVideoAnchorElement)}
            onClose={handleCloseMenu}
            anchorOrigin={anchorOrigin}
          >
            <MenuDeviceRowContainer>
              {cameraList.map((camera) => {
                return (
                  <MenuDeviceRow
                    key={camera.deviceId}
                    device={camera}
                    closeMenu={handleCloseMenu}
                    changeDevice={changeCamera}
                  />
                );
              })}
            </MenuDeviceRowContainer>
          </ClickThroughMenu>
        </MenuItem>
        {partnerInviteEnabled && !partnerInvited && (
          <MenuItem onClick={invitePartner} disabled={invitingPartner}>
            {t && t.VideoCallsInvitePartner.InviteButton}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
