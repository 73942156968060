import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'portal-toolbar',
  templateUrl: `./toolbar.component.html`,
  styleUrls: [`./toolbar.component.scss`],
})
export class ToolbarComponent {
  @Input() clinic: string;
  @Input() loggedIn: boolean;
  @Input() pageTitle: string;
  @Input() open = true;
  @Input() text: any;
  @Output() openMenu = new EventEmitter();
  @Output() closeMenu = new EventEmitter();
}
