<div class="list-container" fxLayout="column">
  <mat-toolbar fxLayout="row" fxLayoutAlign="center center">
    {{ text.PatientDocuments(patientName) }}
  </mat-toolbar>
  <mat-toolbar
    class="search"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <form
      novalidate
      [formGroup]="searchForm"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <mat-icon>search</mat-icon>
      <input
        type="text"
        placeholder="{{ !patientOnly ? text.SearchContent : text.SearchFiles }}"
        id="searchValue"
        formControlName="searchValue"
      />
      <mat-icon *ngIf="filterActive" (click)="clearFilter()">clear</mat-icon>
    </form>
  </mat-toolbar>
  <perfect-scrollbar fxFlex="auto" [config]="config" [scrollIndicators]="true">
    <div class="list" *ngIf="!patientOnly">
      <div
        *ngFor="let entry of content"
        class="list-item"
        [class.remove]="!entry.frommeditex"
        [class.sync]="entry.frommeditex"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="removeContent(entry)"
      >
        {{ entry.contentname }}
        <mat-icon *ngIf="!entry.frommeditex">remove</mat-icon>
        <mat-icon *ngIf="entry.frommeditex">lock</mat-icon>
      </div>
    </div>
    <div class="list" *ngIf="patientOnly">
      <div
        *ngFor="let entry of patientOnlyContent"
        class="list-item remove"
        [attr.disabled]="entry.isTransferring ? '' : null"
        (click)="onClicked(entry)"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div
          *ngIf="entry.isTransferring"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="2px"
        >
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
          <div>
            {{ entry.documentname }}
          </div>
          <div class="sent-time">
            {{
              text.SentDate(
                entry.salvemodified | onlyTime,
                entry.salvemodified | longDate
              )
            }}
          </div>
          <div
            *ngIf="entry.readdate && isReadDateMocked(entry.readdate)"
            class="sent-time"
          >
            {{
              text.ReadDate(
                entry.readdate | onlyTime,
                entry.readdate | longDate
              )
            }}
          </div>
        </div>
        <div
          class="list-item remove"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="15px"
        >
          <a
            (click)="viewFile(entry)"
            portalStopClickPropagation
            target="_blank"
          >
            <mat-icon>cloud_download</mat-icon>
          </a>
          <mat-icon>remove</mat-icon>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
