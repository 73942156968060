import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import {
  Category,
  UpdateCategoryRequest,
  UpdateCategoryResponse
} from '../models/category';
import {
  ContentEntry,
  GlobalPortalContentEntry,
  GroupedContentEntries
} from '../models/content-entry';
import { ContentTemplate } from '../models/content-template';
import { FileInStorage } from '../models/file-in-storage';
import { ParentPortal } from '../models/global-portal';
import { GroupedContent } from '../models/groupedContentEntries';
import { CreateEntryRequest } from '../models/requests/create-entry.request';
import { DeleteEntriesRequest } from '../models/requests/delete-entries.request';
import { MoveEntriesResponse } from '../models/requests/move-entries.response';
import { CreateEntryResponse } from '../models/responses/create-entry.response';
import { DeleteEntriesResponse } from '../models/responses/delete-entries.response';
import { MoveEntriesRequest } from '../models/responses/move-entries.response';

export interface ErrorForArray extends Error {
  length?: number;
}

@Injectable()
export class ContentService {
  constructor(
    private _error: ErrorHandler,
    private _httpService: HttpService
  ) {}

  checkFileByMD5(md5: string): Observable<FileInStorage[] | ErrorForArray> {
    return this._httpService
      .get<FileInStorage[]>(EndpointType.Content, `content/files/md5`, {
        checksum: md5
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getFilesByName(
    fileName: string
  ): Observable<FileInStorage[] | ErrorForArray> {
    return this._httpService
      .get<FileInStorage[]>(EndpointType.Content, `content/files/name`, {
        documentname: fileName
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  updateFileInStorage(file: FileInStorage): Observable<FileInStorage | Error> {
    return this._httpService
      .put<FileInStorage>(EndpointType.Content, `content/files/${file.id}`, {
        ...file,
        filesize: +file.filesize
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createFileInStorage(file: FileInStorage): Observable<FileInStorage | Error> {
    return this._httpService
      .post<FileInStorage>(EndpointType.Content, `content/files`, file)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // CRUD for Templates
  // ===================================================
  // Read
  getTemplates(): Observable<ContentTemplate[] | Error> {
    return this._httpService
      .get<ContentTemplate[]>(EndpointType.Content, `content/templates`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getTemplateById(id: number): Observable<ContentTemplate | Error> {
    return this._httpService
      .get<ContentTemplate>(EndpointType.Content, `content/templates/${id}`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteTemplate(id: number): Observable<boolean | Error> {
    return this._httpService
      .delete<ContentTemplate>(EndpointType.Content, `content/templates/${id}`)
      .pipe(
        map((res) => true),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  amendTemplate(
    template: ContentTemplate
  ): Observable<ContentTemplate | Error> {
    return this._httpService
      .put<ContentTemplate>(
        EndpointType.Content,
        `content/templates/${template.id}`,
        template
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // TODO: This will throw a back end error when using the same name
  createTemplate(
    template: ContentTemplate
  ): Observable<ContentTemplate | Error> {
    return this._httpService
      .post<ContentTemplate>(
        EndpointType.Content,
        `content/templates`,
        template
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // TODO: This seems not be used
  checkTemplateName(name: string): Observable<{ name: string }[]> {
    return this._httpService
      .get<{ name: string }[]>(EndpointType.Content, `content/templates/name`, {
        name
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // CRUD for Categories
  // ===================================================
  // Read
  getContentCategories(): Observable<Category[] | Error> {
    return this._httpService
      .get<Category[]>(EndpointType.Content, `content/categories`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Get category
  getContentCategory(id: number): Observable<Category> {
    return this._httpService
      .get<Category>(EndpointType.Content, `content/categories/${id}`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Delete
  deleteCategory(id: number): Observable<boolean | Error> {
    return this._httpService
      .delete<Category[]>(EndpointType.Content, `content/categories/${id}`)
      .pipe(
        map((res) => true),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Update
  // https://bitbucket.org/salvedevelopment/salve.content-api-v1/src/master/routes/content-categories.js
  amendCategory(
    category: UpdateCategoryRequest
  ): Observable<UpdateCategoryResponse> {
    const { id, ...rest } = category;

    return this._httpService
      .put<UpdateCategoryResponse>(
        EndpointType.Content,
        `content/categories/${id}`,
        rest
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  // Create
  createCategory(category: Category): Observable<Category | Error> {
    return this._httpService
      .post<Category>(EndpointType.Content, `content/categories`, category)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  checkCategoryName(name: string): Observable<{ name: string }[]> {
    return this._httpService
      .get<{ name: string }[]>(
        EndpointType.Content,
        `content/categories/name`,
        { name }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // CRUD for ContentEntries
  // ======================================
  // Create
  createEntry(entry: CreateEntryRequest): Observable<ContentEntry> {
    return this._httpService
      .post<CreateEntryResponse>(EndpointType.Content, `content/entries`, entry)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Read
  getContentEntryHeaders(): Observable<GroupedContentEntries[] | Error> {
    return this._httpService
      .get<GroupedContentEntries[]>(
        EndpointType.Content,
        `content/groupedentries`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Read By Id
  getContentEntryById(id: number): Observable<ContentEntry> {
    return this._httpService
      .get<ContentEntry>(EndpointType.Content, `content/entries/${id}`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Update
  amendEntry(contentEntry: ContentEntry): Observable<ContentEntry | Error> {
    return this._httpService
      .put<ContentEntry>(
        EndpointType.Content,
        `content/entries/${contentEntry.id}`,
        contentEntry
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Delete
  deleteEntry(id: number): Observable<boolean | Error> {
    return this._httpService
      .delete<any>(EndpointType.Content, `content/entries/${id}`)
      .pipe(
        map((res) => true),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  moveEntries(
    body: MoveEntriesRequest
  ): Observable<MoveEntriesResponse | Error> {
    return this._httpService
      .put<MoveEntriesResponse>(
        EndpointType.Content,
        `content/entries/move`,
        body
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteEntries(
    body: DeleteEntriesRequest
  ): Observable<DeleteEntriesResponse | Error> {
    return this._httpService
      .deleteWithBody<DeleteEntriesResponse>(
        EndpointType.Content,
        `content/entries/delete`,
        body
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  checkContentName(name: string): Observable<{ name: string }[]> {
    return this._httpService
      .get<{ name: string }[]>(EndpointType.Content, `content/entries/name`, {
        name
      })
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Check patient file names
  getPatientDocsByName(
    fileName: string,
    patientId: number
  ): Observable<string[]> {
    return this._httpService
      .get<string[]>(
        EndpointType.Content,
        `content/patient/${patientId}/name`,
        { documentname: fileName }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Main content grouped by categories
  getGroupedEntryList(language?: string): Observable<GroupedContent[]> {
    return this._httpService
      .get<{ data: GroupedContent[] }>(
        EndpointType.Content,
        `content/groupedentrylist`,
        language != null ? { language } : undefined
      )
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Check for parent global portal
  getGlobalPortal(): Observable<ParentPortal | null> {
    return this._httpService
      .get<{ data: ParentPortal | null }>(
        EndpointType.Content,
        `content/global/get-parent-clinic`
      )
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Main global content grouped by categories
  getGlobalPortalGroupedEntryList(
    globalPortalId: number,
    language?: string
  ): Observable<GroupedContent[]> {
    return this._httpService
      .get<{ data: GroupedContent[] }>(
        EndpointType.Content,
        `content/global/${globalPortalId}/grouped-entry-list`,
        language != null ? { language } : undefined
      )
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getGlobalPortalGroupedFileEntryList(
    globalPortalId: number,
    language?: string
  ): Observable<GroupedContent[]> {
    return this._httpService
      .get<{ data: GroupedContent[] }>(
        EndpointType.Content,
        `content/global/${globalPortalId}/grouped-file-entry-list`,
        language != null ? { language } : undefined
      )
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // Read By Id
  getGlobalContentEntryById(
    globalPortalId: number,
    id: number
  ): Observable<GlobalPortalContentEntry> {
    return this._httpService
      .get<GlobalPortalContentEntry>(
        EndpointType.Content,
        `content/global/${globalPortalId}/entries/${id}`
      )
      .pipe(take(1));
  }
}
