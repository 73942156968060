import {
  ContentPack,
  ContentPackDetail,
} from './../../content/models/content-packs';
import {
  PackAssignmentActionsUnion,
  PackAssignmentActionTypes,
} from './../actions/pack-assignment.actions';

export interface State {
  loadingContentPacks: boolean;
  loadingPackDetail: boolean;
  contentPacks: ContentPack[];
  lockedItems: number[];
  activePackId: number;
  assignedPackId: number;
  packDetail: {
    [key: string]: ContentPackDetail[];
  };
  errors: {
    loadingContentPacks: boolean;
    loadingPackDetail: boolean;
  };
}

export const initialState: State = {
  loadingContentPacks: false,
  loadingPackDetail: false,
  contentPacks: [],
  lockedItems: [],
  activePackId: null,
  assignedPackId: null,
  packDetail: {},
  errors: {
    loadingContentPacks: false,
    loadingPackDetail: false,
  },
};

export function reducer(
  state = initialState,
  action: PackAssignmentActionsUnion,
): State {
  switch (action.type) {
    case PackAssignmentActionTypes.GetContentPacks: {
      return {
        ...state,
        loadingContentPacks: true,
      };
    }
    case PackAssignmentActionTypes.GetContentPacksSuccess: {
      const contentPacks = action.payload.contentPacks;
      const firstPack = contentPacks[0];
      const activePackId =
        state.activePackId === null && firstPack && firstPack.id
          ? firstPack.id
          : state.activePackId;
      return {
        ...state,
        loadingContentPacks: false,
        contentPacks,
        activePackId,
        errors: {
          ...state.errors,
          loadingContentPacks: false,
        },
      };
    }
    case PackAssignmentActionTypes.GetContentPacksError: {
      return {
        ...state,
        loadingContentPacks: false,
        errors: {
          ...state.errors,
          loadingContentPacks: true,
        },
      };
    }

    case PackAssignmentActionTypes.SelectActivePackId: {
      return {
        ...state,
        activePackId: action.payload,
        lockedItems: [],
      };
    }

    case PackAssignmentActionTypes.GetContentPackDetail: {
      return {
        ...state,
        loadingPackDetail: true,
        errors: {
          ...state.errors,
          loadingPackDetail: false,
        },
      };
    }
    case PackAssignmentActionTypes.GetContentPackDetailSuccess: {
      return {
        ...state,
        loadingPackDetail: false,
        packDetail: {
          ...state.packDetail,
          [action.payload.packId]: action.payload.contentPackDetail,
        },
      };
    }
    case PackAssignmentActionTypes.GetContentPackDetailError: {
      return {
        ...state,
        loadingPackDetail: false,
        errors: {
          ...state.errors,
          loadingPackDetail: true,
        },
      };
    }
    case PackAssignmentActionTypes.LockPackItem: {
      return {
        ...state,
        lockedItems: [...state.lockedItems, action.payload],
      };
    }
    case PackAssignmentActionTypes.UnlockPackItem: {
      return {
        ...state,
        lockedItems: state.lockedItems.filter((id) => id !== action.payload),
      };
    }
    case PackAssignmentActionTypes.OpenAssignedPack: {
      return {
        ...state,
        assignedPackId: action.payload,
      };
    }
    case PackAssignmentActionTypes.UnassignPackSuccess: {
      return {
        ...state,
        assignedPackId: null,
      };
    }

    default: {
      return state;
    }
  }
}

// Selectors
export const getContentPacks = (state: State) => state.contentPacks;
export const getActivePackId = (state: State) => state.activePackId;
export const getLockedItems = (state: State) => state.lockedItems;
export const getPackDetail = (state: State) => state.packDetail;
export const getSelectedPack = (id: number, packs: ContentPack[]) =>
  packs.find((p) => p.id === id);
export const getSelectedPackDetail = (
  id: number,
  packDetail: { [key: string]: ContentPackDetail[] },
  lockedItems: number[],
): ContentPackDetail[] => {
  const key = `${id}`;
  if (packDetail.hasOwnProperty(key)) {
    return (
      packDetail[`${id}`].map((d) => ({
        ...d,
        locked: lockedItems.includes(d.contentEntryHeaderId),
      })) || []
    );
  }
  return [];
};
export const assignedPackId = (state: State) => state.assignedPackId;
export const getActiveAssignedPackId = (state: State) => state.assignedPackId;
