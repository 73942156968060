import * as React from 'react';
import { StyledTabPanel } from './index.styled';

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FunctionComponent<IProps> = ({
  children,
  value,
  index,
}: IProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <StyledTabPanel>{children}</StyledTabPanel>}
    </div>
  );
};

export default TabPanel;
