import { useApiClient } from '@react/lib/api';
import {
  MessageTemplate,
  MessageTemplatesListResponse,
} from '@react/lib/api/types';
import { debounceAsync } from '@react/lib/debounceAsync';
import * as React from 'react';
import { ValidateResult } from 'react-hook-form';

export interface UseMessageTemplateUniqueNameValidatorArgs {
  delay: number;
  errorMessage: string;
  existingMessageTemplateId?: number;
}
// Needed so that we call the same `debounceAsync` instance across
// different renders.
export const useMessageTemplateUniqueNameValidator = ({
  delay,
  errorMessage,
  existingMessageTemplateId,
}: UseMessageTemplateUniqueNameValidatorArgs) => {
  const { portal: portalApiClient } = useApiClient();

  return React.useMemo(() => {
    return debounceAsync<
      ValidateResult,
      (value: string) => Promise<ValidateResult>
    >(async (value: string) => {
      try {
        const messageTemplatesResponse: MessageTemplatesListResponse =
          await portalApiClient.fetchMessageTemplates();

        const uniqueName = isNameUnique(
          removeById(messageTemplatesResponse.data, existingMessageTemplateId),
          value,
        );

        return !uniqueName ? errorMessage : undefined;
      } catch (err) {
        // We can stay silent here as if the user manages to submit
        // the API will throw an error for a matching name anyway.
        return undefined;
      }
    }, delay);
  }, [delay, errorMessage, portalApiClient]);
};

const removeById = (
  messageTemplates: MessageTemplate[],
  id?: number,
): MessageTemplate[] => messageTemplates.filter((mt) => mt.id !== id);

const isNameUnique = (
  existingMessageTemplates: MessageTemplate[],
  value: string,
): boolean => existingMessageTemplates.every((mt) => mt.name !== value);
