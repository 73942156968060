import { Action } from '@ngrx/store';
import { AlterMessage } from './../models/messaging';

import { IBasicServerResponse } from '../../models/BasicServerResponse';
import { IChangeLabel } from '../../models/ChangeLabel';
import { Message } from '../../models/Message';

import { ServerError } from '../../models/Error';
import { ToastOptions } from '../../models/ToastOptions';

/**
 * Enumerated, namespaced Message action types
 */
export enum AlterMessageActionTypes {
  /**
   * These actions require a server side response
   * i.e. they have side-effects and aren't 'pure'
   */
  MarkUnread = '[AlterMessage] Mark Unread',
  MarkUnreadSuccess = '[AlterMessage] Mark Unread Success',
  MarkUnreadError = '[AlterMessage] Mark Unread Error',

  MarkUnresolved = '[AlterMessage] Mark Unresolved',
  MarkUnresolvedSuccess = '[AlterMessage] Mark Unresolved Success',
  MarkUnresolvedError = '[AlterMessage] Mark Unresolved Error',

  MarkResolved = '[AlterMessage] Mark Resolved',
  MarkResolvedSuccess = '[AlterMessage] Mark Resolved Success',
  MarkResolvedError = '[AlterMessage] Mark Resolved Error',

  MarkStarred = '[AlterMessage] Mark Starred',
  MarkStarredSuccess = '[AlterMessage] Mark Starred Success',
  MarkStarredError = '[AlterMessage] Mark Starred Error',

  MarkUnstarred = '[AlterMessage] Mark Unstarred',
  MarkUnstarredSuccess = '[AlterMessage] Mark Unstarred Success',
  MarkUnstarredError = '[AlterMessage] Mark Unstarred Error',

  ChangeLabel = '[AlterMessage] Change Label',
  ChangeLabelSuccess = '[AlterMessage] Change Label Success',
  ChangeLabelError = '[AlterMessage] Change Label Error',

  // Toast Actions - should put all toasts in own files in core
  ToastDisplayed = '[Toast] Display Toast',
  ToastDismissed = '[Toast] Toast Dismissed',

  DeleteMessageItem = '[AlterMessage] Delete Message Item',
  DeleteMessageItemSuccess = '[AlterMessage] Delete Message Item Success',
  DeleteMessageItemError = '[AlterMessage] Delete Message Item Error',
}

/**
 * Action Creators
 */
// ============== Server Side Actions ==================== //
export class MarkUnread implements Action {
  readonly type = AlterMessageActionTypes.MarkUnread;

  constructor(
    public payload: {
      data: AlterMessage;
      toast: ToastOptions;
    },
  ) {}
}
export class MarkUnreadSuccess implements Action {
  readonly type = AlterMessageActionTypes.MarkUnreadSuccess;

  constructor(public payload: AlterMessage) {}
}
export class MarkUnreadError implements Action {
  readonly type = AlterMessageActionTypes.MarkUnreadError;

  constructor(public payload: ServerError) {}
}

export class MarkUnresolved implements Action {
  readonly type = AlterMessageActionTypes.MarkUnresolved;

  // Need to pass the thread of the id to mark as unresolved
  constructor(
    public payload: {
      data: AlterMessage;
      toast?: ToastOptions;
    },
  ) {}
}
export class MarkUnresolvedSuccess implements Action {
  readonly type = AlterMessageActionTypes.MarkUnresolvedSuccess;

  constructor(public payload: AlterMessage) {}
}
export class MarkUnresolvedError implements Action {
  readonly type = AlterMessageActionTypes.MarkUnresolvedError;

  constructor(public payload: ServerError) {}
}

export class MarkResolved implements Action {
  readonly type = AlterMessageActionTypes.MarkResolved;

  // Need to pass the thread of the id to mark as unresolved
  constructor(
    public payload: {
      data: AlterMessage;
      toast?: ToastOptions;
    },
  ) {}
}
export class MarkResolvedSuccess implements Action {
  readonly type = AlterMessageActionTypes.MarkResolvedSuccess;

  constructor(public payload: AlterMessage) {}
}
export class MarkResolvedError implements Action {
  readonly type = AlterMessageActionTypes.MarkResolvedError;

  constructor(public payload: ServerError) {}
}

export class MarkStarred implements Action {
  readonly type = AlterMessageActionTypes.MarkStarred;

  constructor(
    public payload: {
      message: Message;
      toast?: ToastOptions;
    },
  ) {}
}
export class MarkUnstarred implements Action {
  readonly type = AlterMessageActionTypes.MarkUnstarred;

  constructor(
    public payload: {
      message: Message;
      toast?: ToastOptions;
    },
  ) {}
}
export class MarkUnstarredSuccess implements Action {
  readonly type = AlterMessageActionTypes.MarkUnstarredSuccess;

  constructor(public payload: { message: Message; result: boolean }) {}
}
export class MarkUnstarredError implements Action {
  readonly type = AlterMessageActionTypes.MarkUnstarredError;

  constructor(
    public payload: {
      messageId: number;
      error: ServerError;
    },
  ) {}
}

export class MarkStarredSuccess implements Action {
  readonly type = AlterMessageActionTypes.MarkStarredSuccess;

  constructor(public payload: { message: Message; result: boolean }) {}
}
export class MarkStarredError implements Action {
  readonly type = AlterMessageActionTypes.MarkStarredError;

  constructor(
    public payload: {
      messageId: number;
      error: ServerError;
    },
  ) {}
}

export class ChangeLabel implements Action {
  readonly type = AlterMessageActionTypes.ChangeLabel;

  // requires MessageTypeId and Message Thread Id
  constructor(
    public payload: {
      data: IChangeLabel;
      toast?: ToastOptions;
    },
  ) {}
}
export class ChangeLabelSuccess implements Action {
  readonly type = AlterMessageActionTypes.ChangeLabelSuccess;

  constructor(
    public payload: {
      data: IChangeLabel;
      result: IBasicServerResponse;
    },
  ) {}
}
export class ChangeLabelError implements Action {
  readonly type = AlterMessageActionTypes.ChangeLabelError;

  constructor(public payload: ServerError) {}
}

export class ToastDisplayed implements Action {
  readonly type = AlterMessageActionTypes.ToastDisplayed;

  constructor(public payload: { toast: ToastOptions }) {}
}
export class ToastDismissed implements Action {
  readonly type = AlterMessageActionTypes.ToastDismissed;
}

export class DeleteMessageItem implements Action {
  readonly type = AlterMessageActionTypes.DeleteMessageItem;
  constructor(public payload: { messageId: number; messageItemId: number }) {}
}

export class DeleteMessageItemSuccess implements Action {
  readonly type = AlterMessageActionTypes.DeleteMessageItemSuccess;
  constructor(public payload: { messageId: number; messageItemId: number }) {}
}

export class DeleteMessageItemError implements Action {
  readonly type = AlterMessageActionTypes.DeleteMessageItemSuccess;
}

/**
 * AlterMessageActionTypes
 */
export type AlterMessageActionsUnion =
  /* Causes Effects */
  // alter existing messages
  | MarkUnread
  | MarkUnreadSuccess
  | MarkUnreadError
  | MarkUnresolved
  | MarkUnresolvedSuccess
  | MarkUnresolvedError
  | MarkResolved
  | MarkResolvedSuccess
  | MarkResolvedError
  | MarkStarred
  | MarkStarredSuccess
  | MarkStarredError
  | MarkUnstarred
  | MarkUnstarredSuccess
  | MarkUnstarredError
  | ChangeLabel
  | ChangeLabelSuccess
  | ChangeLabelError
  | DeleteMessageItem
  | DeleteMessageItemSuccess
  | DeleteMessageItemError
  | ToastDisplayed
  | ToastDismissed;

export function isError(action: Action): boolean {
  return (
    action instanceof MarkUnreadError ||
    action instanceof MarkResolvedError ||
    action instanceof MarkUnresolvedError ||
    action instanceof MarkStarredError ||
    action instanceof MarkUnstarredError ||
    action instanceof ChangeLabelError
  );
}
