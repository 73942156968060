import React, { memo, useEffect, useRef } from 'react';
import { VideoQuality } from '@zoom/videosdk';
import { useZoom } from '../../hooks/useZoom';
import { ParticipantDisplayNameText, ParticipantMicIconContainer, ParticipantMicOffIcon, ParticipantVideocamOffIcon, ParticipantVideoContainer, ParticipantVideoIconContainer, ParticipantVideoIconsContainer } from './ParticipantPanel.styled';
var participantVideoPlayerStyle = {
    position: 'absolute',
    display: 'block',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
};
var ParticipantPanel = function (_a) {
    var participant = _a.participant;
    var zoomClient = useZoom().zoomClient;
    var videoAttachedRef = useRef(false);
    var videoPlayerRef = useRef(null);
    useEffect(function () {
        if (!zoomClient) {
            return;
        }
        var mediaStream = zoomClient.getMediaStream();
        if (!mediaStream || !videoPlayerRef.current) {
            return;
        }
        if (!videoAttachedRef.current && participant.bVideoOn) {
            videoAttachedRef.current = true;
            mediaStream.attachVideo(participant.userId, VideoQuality.Video_360P, videoPlayerRef.current);
            return;
        }
        if (videoAttachedRef.current && !participant.bVideoOn) {
            videoAttachedRef.current = false;
            mediaStream.detachVideo(participant.userId, videoPlayerRef.current);
            return;
        }
    }, [zoomClient, participant, videoAttachedRef]);
    return (React.createElement(ParticipantVideoContainer, { key: participant.userId },
        React.createElement("video-player", { style: participantVideoPlayerStyle, ref: videoPlayerRef }),
        React.createElement(ParticipantVideoIconsContainer, null,
            participant.muted && (React.createElement(ParticipantMicIconContainer, null,
                React.createElement(ParticipantMicOffIcon, null))),
            !participant.bVideoOn && (React.createElement(ParticipantVideoIconContainer, null,
                React.createElement(ParticipantVideocamOffIcon, null))),
            React.createElement(ParticipantDisplayNameText, null, participant.displayName))));
};
var ɵ0 = ParticipantPanel;
export default memo(ParticipantPanel);
export { ɵ0 };
