import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordComplexity, valuesMatch } from 'src/app/core/utils/validators';
import {
  ForgottenPasswordForm,
  SubmitNewPasswordForm,
} from '../../models/LoginSubmission';

@Component({
  selector: 'portal-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  @Input() isCore: boolean;
  @Input() text: any;
  @Input()
  set generatingCode(isGeneratingCode: boolean) {
    if (isGeneratingCode) {
      this.forgottenPasswordForm.disable();
    } else {
      this.forgottenPasswordForm.enable();
    }
    this.isGeneratingCode = isGeneratingCode;
  }
  @Input()
  set resettingPassword(isResettingPassword: boolean) {
    if (isResettingPassword) {
      this.newPasswordForm.disable();
      this.forgottenPasswordForm.disable();
    } else {
      this.newPasswordForm.enable();
      this.forgottenPasswordForm.enable();
    }
    this.isResettingPassword = isResettingPassword;
  }
  @Input() errorMessage: string | null;
  @Input() clinicId: string;
  @Input() codeGenerated: boolean;
  @Output() requestPasswordCode = new EventEmitter<ForgottenPasswordForm>();
  @Output() submitNewPassword = new EventEmitter<SubmitNewPasswordForm>();

  public isGeneratingCode = false;
  public forgottenPasswordForm: FormGroup;
  public forgottenSubmitted = false;
  public isResettingPassword = false;
  public newPasswordForm: FormGroup;
  public newPasswordSubmitted = false;

  constructor(private _fb: FormBuilder) {
    this.forgottenPasswordForm = this._fb.group({
      email: this._fb.control('', [Validators.required, Validators.email]),
    });

    this.newPasswordForm = this._fb.group(
      {
        code: this._fb.control('', [Validators.required]),
        password: this._fb.control('', [
          Validators.required,
          passwordComplexity,
        ]),
        confirmPassword: this._fb.control(''),
      },
      {
        validator: [valuesMatch('password', 'confirmPassword')],
      },
    );
  }

  getResetCode() {
    this.forgottenSubmitted = true;
    const email: string = this.forgottenPasswordForm.get('email').value;
    const forgottenPassword = {
      email,
      validationData: {
        clinictoken: this.clinicId,
      },
    };
    this.requestPasswordCode.emit(forgottenPassword);
  }

  createNewPassword() {
    this.newPasswordSubmitted = true;
    const email: string = this.forgottenPasswordForm.get('email').value;
    const code: string = this.newPasswordForm.get('code').value;
    const new_password: string = this.newPasswordForm.get('password').value;
    const newPasswordData = {
      email,
      code,
      new_password,
      validationData: {
        clinictoken: this.clinicId,
      },
    };
    this.submitNewPassword.emit(newPasswordData);
  }
}
