import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Category } from '../../content/models/category';
import {
  ContentAssignmentActionTypes,
  CreatePatientAssignedContent,
  CreatePatientAssignedContentError,
  CreatePatientAssignedContentSuccess,
  CreatePatientOnlyAssignments,
  CreatePatientOnlyAssignmentsError,
  CreatePatientOnlyAssignmentsSuccess,
  DeletePatientAssignedContent,
  DeletePatientAssignedContentError,
  DeletePatientAssignedContentSuccess,
  DeletePatientOnlyAssignments,
  DeletePatientOnlyAssignmentsError,
  DeletePatientOnlyAssignmentsSuccess,
  GetAllPatientAssignedContent,
  GetAllPatientAssignedContentError,
  GetAllPatientAssignedContentSuccess,
  GetContentEntries,
  GetContentEntriesError,
  GetContentEntriesSuccess,
  GetContentTypes,
  GetContentTypesError,
  GetContentTypesSuccess,
  GetPatientAssignedContent,
  GetPatientAssignedContentError,
  GetPatientAssignedContentSuccess,
  GetPatientOnlyAssignments,
  GetPatientOnlyAssignmentsError,
  GetPatientOnlyAssignmentsSuccess,
  GetPatientViewOfContent,
  GetPatientViewOfContentError,
  GetPatientViewOfContentSuccess,
  UpdatePatientOnlyAssignments,
  UpdatePatientOnlyAssignmentsError,
  UpdatePatientOnlyAssignmentsSuccess
} from '../actions/content-assignment.actions';
import {
  GetAllPatientAssignedContentResponse,
  GetPatientViewResponse,
  IndividuallyAssignedPatientContent,
  PatientOnlyAssignContentItem
} from '../models/UserContent';
import { ContentAssignmentService } from '../services/content-assignment.service';
import { ContentEntryHeader } from './../models/Content';

@Injectable()
export class ContentAssignmentEffects {
  // Get Content Entries
  @Effect()
  getContentEntries$ = this._actions$.pipe(
    ofType<GetContentEntries>(ContentAssignmentActionTypes.GetContentEntries),
    mergeMap(() =>
      this._contentAssignmentService.getContentEntryHeaders().pipe(
        // TODO: Fix types
        // @ts-ignore
        map(
          (res: { [key: string]: ContentEntryHeader[] }) =>
            new GetContentEntriesSuccess(res)
        ),
        catchError((err) => {
          return of(new GetContentEntriesError(err));
        })
      )
    )
  );

  // Get Content Types
  @Effect()
  getTypes$ = this._actions$.pipe(
    ofType<GetContentTypes>(ContentAssignmentActionTypes.GetContentTypes),
    mergeMap(() =>
      this._contentAssignmentService.getContentCategories().pipe(
        map((res: Category[]) => new GetContentTypesSuccess(res)),
        catchError((err) => {
          return of(new GetContentTypesError(err));
        })
      )
    )
  );

  // CRUD for 'userassignedcontent'
  // ================================================
  // Create User Assigned Content
  @Effect()
  createUserAssignedContent$ = this._actions$.pipe(
    ofType<CreatePatientAssignedContent>(
      ContentAssignmentActionTypes.CreatePatientAssignedContent
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .createUserAssignedContent(
          action.payload.assignment,
          action.payload.patientId
        )
        .pipe(
          // TODO: Fix types
          // @ts-ignore
          map((res: IndividuallyAssignedPatientContent) => {
            if (action.payload.patientContent) {
              action.payload.patientContent.isTransferring = false;
            }
            return new CreatePatientAssignedContentSuccess(res);
          }),
          catchError((err) => {
            if (action.payload.patientContent) {
              action.payload.patientContent.isTransferring = false;
            }
            return of(new CreatePatientAssignedContentError(err));
          })
        )
    )
  );

  // Get User Assigned Content
  @Effect()
  getUserAssignedContent$ = this._actions$.pipe(
    ofType<GetPatientAssignedContent>(
      ContentAssignmentActionTypes.GetPatientAssignedContent
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .getUserAssignedContentById(action.payload)
        .pipe(
          map(
            (res: IndividuallyAssignedPatientContent[]) =>
              new GetPatientAssignedContentSuccess(res)
          ),
          catchError((err) => {
            return of(new GetPatientAssignedContentError(err));
          })
        )
    )
  );

  // Get User Assigned Content
  @Effect()
  getAllUserAssignedContent$ = this._actions$.pipe(
    ofType<GetAllPatientAssignedContent>(
      ContentAssignmentActionTypes.GetAllPatientAssignedContent
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .getAllUserAssignedContent(action.payload)
        .pipe(
          // TODO: Fix types
          // @ts-ignore
          map(
            (res: GetAllPatientAssignedContentResponse) =>
              new GetAllPatientAssignedContentSuccess(res)
          ),
          catchError((err) => {
            return of(new GetAllPatientAssignedContentError(err));
          })
        )
    )
  );

  // Get Patient View Of Assigned Content
  @Effect()
  getPatientViewOfAssignedContent$ = this._actions$.pipe(
    ofType<GetPatientViewOfContent>(
      ContentAssignmentActionTypes.GetAllPatientAssignedContent
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .getPatientViewOfContent(action.payload)
        .pipe(
          map(
            (res: GetPatientViewResponse) =>
              new GetPatientViewOfContentSuccess(res)
          ),
          catchError((err) => {
            return of(new GetPatientViewOfContentError(err));
          })
        )
    )
  );

  // Delete User Assigned Content
  @Effect()
  deleteUserAssignedContent$ = this._actions$.pipe(
    ofType<DeletePatientAssignedContent>(
      ContentAssignmentActionTypes.DeletePatientAssignedContent
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .deleteUserAssignedContent(action.payload.assignmentid)
        .pipe(
          map((res: boolean) => {
            return new DeletePatientAssignedContentSuccess(action.payload);
          }),
          catchError((err) => {
            return of(new DeletePatientAssignedContentError(err));
          })
        )
    )
  );

  // CRUD for 'patient only content'
  // ================================================
  // Read
  @Effect()
  getPatientOnlyAssignedContent$ = this._actions$.pipe(
    ofType<GetPatientOnlyAssignments>(
      ContentAssignmentActionTypes.GetPatientOnlyAssignments
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .getPatientOnlyAssignedContent(action.payload.patientId)
        .pipe(
          map(
            (res: PatientOnlyAssignContentItem[]) =>
              new GetPatientOnlyAssignmentsSuccess(res)
          ),
          catchError((err) => {
            return of(new GetPatientOnlyAssignmentsError(err));
          })
        )
    )
  );

  // Create
  @Effect()
  createPatientOnlyAssignedContent$ = this._actions$.pipe(
    ofType<CreatePatientOnlyAssignments>(
      ContentAssignmentActionTypes.CreatePatientOnlyAssignments
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .createPatientOnlyAssignedContent(
          action.payload.body,
          action.payload.patientId
        )
        .pipe(
          map(
            (res: PatientOnlyAssignContentItem) =>
              new CreatePatientOnlyAssignmentsSuccess(res)
          ),
          catchError((err) => {
            return of(new CreatePatientOnlyAssignmentsError(err));
          })
        )
    )
  );

  // Update
  @Effect()
  updatePatientOnlyAssignedContent$ = this._actions$.pipe(
    ofType<UpdatePatientOnlyAssignments>(
      ContentAssignmentActionTypes.UpdatePatientOnlyAssignments
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .updatePatientOnlyAssignedContent(
          action.payload.updateInfo,
          action.payload.assignmentId
        )
        .pipe(
          map(
            (res: PatientOnlyAssignContentItem) =>
              new UpdatePatientOnlyAssignmentsSuccess(res)
          ),
          catchError((err) => {
            return of(new UpdatePatientOnlyAssignmentsError(err));
          })
        )
    )
  );

  // Delete
  @Effect()
  deletePatientOnlyAssignedContent$ = this._actions$.pipe(
    ofType<DeletePatientOnlyAssignments>(
      ContentAssignmentActionTypes.DeletePatientOnlyAssignments
    ),
    mergeMap((action) =>
      this._contentAssignmentService
        .deletePatientOnlyAssignedContent(action.payload.deleteInfo)
        .pipe(
          map(
            (res: boolean) =>
              new DeletePatientOnlyAssignmentsSuccess(action.payload.deleteInfo)
          ),
          catchError((err) => {
            return of(new DeletePatientOnlyAssignmentsError(err));
          })
        )
    )
  );

  constructor(
    private _actions$: Actions,
    private _contentAssignmentService: ContentAssignmentService,
    private _error: ErrorHandler
  ) {}
}
