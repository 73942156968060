var MarkMessageUnresolvedRequest = /** @class */ (function () {
    function MarkMessageUnresolvedRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId;
        this.Action = 'MarkMessageUnresolved';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
    }
    return MarkMessageUnresolvedRequest;
}());
export { MarkMessageUnresolvedRequest };
