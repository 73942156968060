<div class="general-settings-container">
  <form [formGroup]="settingsForm">
    <div
      class="set-language input"
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="start center"
    >
      <label for="language">Language:</label>
      <mat-select id="language" formControlName="language">
        <mat-option *ngFor="let language of languages" [value]="language">{{
          language
        }}</mat-option>
      </mat-select>
    </div>
  </form>
</div>
