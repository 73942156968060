import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import { simplePatientToPatient } from '@react/lib/models/patient';
import { NewMessageModal } from '@react/pages/conversations/components';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { SimplePatient } from 'src/app/models/SimplePatient';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';

const containerElementName = 'newMessageComponentReact';

interface DialogData {
  close: () => void;
  patient: SimplePatient;
}

@Component({
  selector: 'portal-new-message-component-react',
  template: `<div #${containerElementName}></div>`,
})
export class NewMessageReactComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(containerElementName) containerRef!: ElementRef;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
      ]).subscribe(
        ([clinicToken, clinicOptions, user, language, translations]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
          };
          this.render();
        },
      ),
    );
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    this._subscriptions.unsubscribe();
  }

  private async render() {
    ReactDOM.render(
      React.createElement(Root, {
        ...this._props,
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        children: React.createElement(NewMessageModal, {
          open: false,
          dialogWrapper: false,
          refreshPageOnLinkClicked: true,
          onClose: this.data.close,
          patientInputProps: {
            defaultPatient: simplePatientToPatient(this.data.patient),
            disabled: true,
          },
        }),
      }),
      this.containerRef.nativeElement,
    );
  }
}
