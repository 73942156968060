import styled from '@emotion/styled';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import * as React from 'react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FullHeightPaper = styled(Paper)`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 32rem;
`;

export const WarningText = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const WarningIcon = styled(Warning)`
  color: orange;
  margin-right: 4px;
  position: relative;
  top: -1px;
`;

export const FieldWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(0.75)}px 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: ${({ children }) =>
    React.Children.toArray(children).filter((x) => !!x).length > 1
      ? 'space-between'
      : 'flex-end'};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const ButtonProgress = styled(CircularProgress)`
  left: 50%;
  margin-left: ${({ size }) =>
    size != null ? `-${parseInt(size as string, 10) / 2}px` : 0};
  margin-top: ${({ size }) =>
    size != null ? `-${parseInt(size as string, 10) / 2}px` : 0};
  position: absolute;
  top: 50%;
`;
