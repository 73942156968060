
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as SparkMD5 from 'spark-md5';

export class HashProgress {
  public hash: string;
  public progress: number;
  public error: Error;
}

@Injectable()
export class Md5HashService {
  private _chunkSize = 2097152; // Read in chunks of 2MB

  constructor() {}

  public setChunkSize(bytes: number) {
    this._chunkSize = bytes;
  }

  public generateFileName(): string {
    const stringToHash = this._randomString() + new Date().valueOf();
    const hex = SparkMD5.hash(stringToHash);
    return hex;
  }

  private _randomString(): string {
    return Math.random().toString(36);
  }

  public hashFile(file: File): Observable<HashProgress> {
    const blobSlice = File.prototype.slice;
    const chunkSize = this._chunkSize;
    const chunks = Math.ceil(file.size / chunkSize);
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let currentChunk = 0;

    return Observable.create((observer) => {
      fileReader.onload = function fileReaderOnLoad(e: any) {
        const progress = Math.floor((currentChunk / chunks) * 100);
        spark.append(e.target.result);
        currentChunk++;

        if (currentChunk < chunks) {
          observer.next({
            hash: null,
            progress,
            error: null,
          });
          loadNext();
        } else {
          observer.next({
            hash: spark.end(),
            progress: 100,
            error: null,
          });
        }
      };

      fileReader.onerror = () => {
        observer.error(new Error('Error parsing file: ' + file.name));
        observer.complete();
      };

      function loadNext() {
        const start = currentChunk * chunkSize;
        const end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }

      loadNext();
    });
  }
}
