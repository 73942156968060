import * as tslib_1 from "tslib";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VideoCallActionTypes } from '../actions/video-call.actions';
import { TabsState } from '../models/TabsState';
import { VideoCallEffectsResposeType } from '../responses';
export var featureStateName = 'videoCalls';
var initialState = {
    loading: false,
    videoCallsList: [],
    filterActive: false,
    filter: '',
    tabsState: TabsState.Upcoming,
    effectsCompletedResponse: null,
    inCallInviteFlag: false,
    invitingInCall: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case VideoCallActionTypes.SetListFilter:
            return tslib_1.__assign({}, state, { filter: action.payload });
        case VideoCallActionTypes.SetCurrentTab:
            return tslib_1.__assign({}, state, { tabsState: action.payload });
        case VideoCallActionTypes.ResetVideoCallEffects:
            return tslib_1.__assign({}, state, { effectsCompletedResponse: null });
        case VideoCallActionTypes.EffectsCompleted:
            var result = tslib_1.__assign({}, state, { effectsCompletedResponse: action.payload, loading: false });
            switch (action.payload.type) {
                case VideoCallEffectsResposeType.NoPartnerFoundError:
                    result.invitingInCall = false;
                    break;
                case VideoCallEffectsResposeType.InviteInCallError:
                    result.invitingInCall = false;
                    break;
                case VideoCallEffectsResposeType.LoadSuccess:
                    result.videoCallsList = action.payload.data;
                    break;
                case VideoCallEffectsResposeType.LoadError:
                    break;
                case VideoCallEffectsResposeType.UpdateSuccess:
                    break;
                case VideoCallEffectsResposeType.UpdateError:
                    break;
                case VideoCallEffectsResposeType.InviteSuccess:
                    break;
                case VideoCallEffectsResposeType.InviteError:
                    break;
                case VideoCallEffectsResposeType.UnInviteSuccess:
                    break;
                case VideoCallEffectsResposeType.UnInviteError:
                    break;
                case VideoCallEffectsResposeType.DeleteSuccess:
                    result.videoCallsList = state.videoCallsList.filter(function (a) { return a.id !== action.payload.data.id; });
                    break;
                case VideoCallEffectsResposeType.DeleteError:
                    break;
            }
            return result;
        case VideoCallActionTypes.UpdateList: {
            var updatingItem_1 = action.payload;
            var updatingList = state.videoCallsList.slice();
            if (updatingItem_1.isNew) {
                updatingList.push(updatingItem_1.videoCall);
            }
            else {
                if (updatingItem_1.joinedVideoCallId) {
                    var existingItemIndex = updatingList.findIndex(function (i) { return i.id === updatingItem_1.joinedVideoCallId; });
                    var itemToUpdate = tslib_1.__assign({}, updatingList[existingItemIndex], { status: 'in progress' });
                    updatingList.splice(existingItemIndex, 1, itemToUpdate);
                }
                else {
                    var existingItemIndex = updatingList.findIndex(function (i) { return i.id === updatingItem_1.videoCall.id; });
                    updatingList.splice(existingItemIndex, 1, updatingItem_1.videoCall);
                }
            }
            return tslib_1.__assign({}, state, { videoCallsList: updatingList, inCallInviteFlag: action.payload.inCallInviteFlag });
        }
        case VideoCallActionTypes.InvitePartnerInCallToVideoCall: {
            return tslib_1.__assign({}, state, { invitingInCall: true });
        }
        case VideoCallActionTypes.InviteInCallSuccess: {
            return tslib_1.__assign({}, state, { inCallInviteFlag: action.payload.inCallInviteFlag, invitingInCall: false });
        }
        case VideoCallActionTypes.UnInviteInCallSuccess: {
            return tslib_1.__assign({}, state, { inCallInviteFlag: action.payload.inCallInviteFlag });
        }
        default:
            return state;
    }
}
export var videoCallsSelector = createFeatureSelector(featureStateName);
export var getLoadingSelector = createSelector(videoCallsSelector, function (state) { return state.loading; });
export var getCurrentTabSelector = createSelector(videoCallsSelector, function (state) { return state.tabsState; });
export var getEffectsCompletedResponse = createSelector(videoCallsSelector, function (state) { return state.effectsCompletedResponse; });
export var getInCallInviteFlagSelector = createSelector(videoCallsSelector, function (state) { return state.inCallInviteFlag; });
export var getInvitingPartnerInCall = createSelector(videoCallsSelector, function (state) { return state.invitingInCall; });
