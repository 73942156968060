import { Tooltip } from '@material-ui/core';
import { CognitoStatus } from '@react/lib/api/types';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import {
  ActiveStyledChip,
  InactiveStyledChip,
  InvitedStyledChip,
} from './index.styles';

const CognitoStatusChip: React.FunctionComponent<{
  status: keyof typeof CognitoStatus;
  label: string;
}> = (props) => {
  const { t } = useTranslations();

  switch (props.status) {
    case CognitoStatus.Active:
    default: {
      return (
        <Tooltip
          title={
            <span style={{ fontWeight: 'normal' }}>
              {t.Admin.Users.TooltipActive}
            </span>
          }
          placement="right"
        >
          <ActiveStyledChip label={props.label} />
        </Tooltip>
      );
    }
    case CognitoStatus.Deactivated: {
      return (
        <Tooltip
          title={
            <span style={{ fontWeight: 'normal' }}>
              {t.Admin.Users.TooltipInactive}
            </span>
          }
          placement="right"
        >
          <InactiveStyledChip label={props.label} />
        </Tooltip>
      );
    }
  }
};

export default CognitoStatusChip;
