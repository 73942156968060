import { useApiClient } from '@react/lib/api';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslations } from '../i18n';
import { useErrorToast } from './hooks';

const CACHE_KEY = 'FILE_VIEWER';
const CACHE_TIME_MS = 5 * 60 * 1000; // 5 mins

export function useFileViewer() {
  const { t } = useTranslations();
  const s3Client = useApiClient().s3Proxy;

  const [fileUrl, setFileUrl] = useState<string>();
  const [openFileWhenLoaded, setOpenFileWhenLoaded] = useState<boolean>(false);

  const result = useQuery(
    [CACHE_KEY, fileUrl],
    // tslint:disable-next-line: no-non-null-assertion
    () => s3Client.getSignedUrl(fileUrl!),
    {
      cacheTime: CACHE_TIME_MS,
      enabled: Boolean(fileUrl),
      retry: false,
    },
  );

  useErrorToast(result.isError, {
    title: t.General.OpenAttachmentFileErrorTitle,
    message: t.General.OpenAttachmentFileErrorMessage,
  });

  function previewFile(url: string): void {
    setFileUrl(url);
    setOpenFileWhenLoaded(true);
  }

  useEffect(() => {
    if (
      !result.isLoading &&
      openFileWhenLoaded &&
      result.data &&
      result.data.url
    ) {
      setOpenFileWhenLoaded(false);
      window.open(result.data.url, '_blank');
    }
  }, [result.isLoading, openFileWhenLoaded]);

  return {
    previewFile,
    ...result,
  };
}
