<div>
  <a
    class="back-link"
    [routerLink]="[
      '/content/list',
      (defaultContentEntry$ | async)?.contentcategoryid || 0
    ]"
    replaceUrl="true"
  >
    <mat-icon class="back-link-arrow" inline>arrow_back</mat-icon>
    {{ controlsText.Back }}
  </a>
  <mat-card class="content-edit-new-container">
    <mat-toolbar
      *ngIf="
        !(loadingContentEntry$ | async) && (clinicLanguages$ | async).length > 1
      "
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <!-- Language tabs -->
      <mat-tab-group
        [selectedIndex]="selectedTab$"
        (selectedIndexChange)="tabChanged($event)"
      >
        <mat-tab *ngFor="let tab of tabs$ | async; trackBy: trackTabsBy">
          <ng-template mat-tab-label>
            {{ tab[0].toUpperCase() }}
            <mat-icon
              *ngIf="tab[1] === 'DIRTY'"
              class="tab-icon"
              inline
              matTooltip="{{ controlsText.UnpublishedChanges }}"
              >error_outline</mat-icon
            >
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <!-- Buttons -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <!-- Delete -->
        <button
          mat-raised-button
          class="portal-btn btn-warn"
          [disabled]="
            (selectedContentEntry$ | async)?.fromdatasync ||
            !contentEditorEnabled
          "
          (click)="delete($event)"
        >
          {{ controlsText.Delete }}
          <mat-icon>delete_forever</mat-icon>
        </button>
        <!-- Add translation | Languages d -->
        <button
          class="portal-btn btn-primary"
          mat-raised-button
          type="button"
          [matMenuTriggerFor]="menu"
          [disabled]="
            (defaultContentEntry$ | async)?.id == null ||
            (availableTranslations$ | async)?.length === 0 ||
            !contentEditorEnabled
          "
        >
          {{ controlsText.AddTranslation }}
        </button>
        <mat-menu
          #menu="matMenu"
          xPosition="before"
          yPosition="below"
          overlapTrigger="false"
          classList="options-panel"
        >
          <button
            *ngFor="let m of availableTranslations$ | async"
            mat-menu-item
            (click)="addTranslation(m.languageCode)"
            [disabled]="!contentEditorEnabled"
          >
            <span>{{ m.name }}</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <portal-content-edit-form
      *ngFor="let contentEntry of contentEntries$ | async | keyvalue"
      [style.display]="
        contentEntry.value === (selectedContentEntry$ | async)
          ? 'initial'
          : 'none'
      "
      [loadingError]="loadingContentEntryError$ | async"
      [isLoading]="loadingContentEntry$ | async"
      [languageCode]="contentEntry.key"
      [languages]="clinicLanguages$ | async"
      [contentEntry]="contentEntry.value"
      [isMultiLingual]="(clinicLanguages$ | async)?.length > 1"
      [isNew]="isNew$ | async"
      [templates]="contentTemplates$ | async"
      [categories]="contentCategories$ | async"
      [parentId]="
        contentEntry.key !== 'default'
          ? (defaultContentEntry$ | async)?.id
          : undefined
      "
      [contentEditorEnabled]="contentEditorEnabled"
      (status)="statusChange(contentEntry.key, $event)"
      (copyGlobalContent)="copyGlobalContent($event)"
      (copyGlobalFileContent)="copyGlobalFileContent($event)"
      [defaultContentEntryId]="(defaultContentEntry$ | async)?.id"
    >
    </portal-content-edit-form>
  </mat-card>
</div>
