<div fxLayout="column" fxLayoutAlign="space-between stretch">
  <h3 mat-dialog-title class="title">
    {{ data.text.Link }}
  </h3>
  <div class="description">
    {{ data.text.ContentReplaced }}
  </div>
  <div
    class="dialog-content"
    mat-dialog-content
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
  >
    <div fxFlex="3 1 230px" class="border">
      <div class="header-container" fxLayoutAlign="start center">
        <div>{{ data.text.Child }}</div>
      </div>
      <perfect-scrollbar
        class="list-container"
        [config]="scrollConfig"
        [scrollIndicators]="true"
      >
        <div class="option" *ngFor="let item of data.selectedItems">
          {{ item.name }}
        </div>
      </perfect-scrollbar>
    </div>
    <div fxFlex="1 1 50px" class="content-header middle">
      {{ data.text.To }}
    </div>
    <div class="dialog-content border" fxFlex="3 1 250px">
      <div
        *ngIf="loadingAvailableItems$ | async"
        class="dialog-content"
        fxLayoutAlign="center center"
      >
        <mat-spinner diameter="100"></mat-spinner>
      </div>
      <div
        *ngIf="!(loadingAvailableItems$ | async) && !itemSelected"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <div class="header-container" fxLayoutAlign="start center">
          {{ data.text.Parent }}
        </div>
        <form
          class="target-form-container"
          [formGroup]="filterForm"
          fxFlex="row"
          fxLayoutAlign="space-between center"
        >
          <div>
            <mat-icon>search</mat-icon>
          </div>
          <div fxFlex="grow" class="input-container">
            <input
              class="filter-input"
              type="text"
              placeholder="{{ data.text.FilterContent }}"
              formControlName="target"
            />
          </div>
          <div>
            <button
              class="close-icon"
              mat-button
              mat-icon-button
              (click)="clearTarget()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </form>
        <form [formGroup]="contentToLinkForm">
          <perfect-scrollbar
            class="options-list-container"
            [config]="scrollConfig"
            [scrollIndicators]="true"
          >
            <div
              *ngFor="let item of availableItemsToLinkTo$ | async"
              class="option"
              (click)="selectItem(item)"
            >
              {{ item.patientname || item.clinicname }}
            </div>
          </perfect-scrollbar>
        </form>
      </div>
      <div
        *ngIf="itemSelected"
        class="selected-container"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>
          {{ itemSelected }}
        </div>
        <button
          class="close-icon"
          mat-button
          mat-icon-button
          (click)="clearSelectedItem()"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <div
      fxFlex="1 1 calc(100% - 100px)"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <button
        mat-raised-button
        class="portal-btn btn-primary"
        (click)="cancel()"
      >
        {{ data.text.Cancel }}
      </button>
      <div
        *ngIf="creatingLink$ | async"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <mat-spinner diameter="30"></mat-spinner>
        <div>
          {{ data.text.LinkingContent }}
        </div>
      </div>
      <div *ngIf="success" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon class="success">check_circle</mat-icon>
        <div>
          {{ data.text.LinkedSuccess }}
        </div>
      </div>
      <div
        *ngIf="creatingLinkError$ | async"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <mat-icon class="error">highlight_off</mat-icon>
        <div>
          {{ data.text.LinkingError }}
        </div>
      </div>
    </div>

    <button
      mat-raised-button
      class="portal-btn btn-bright"
      (click)="linkContent()"
    >
      {{ data.text.Save }}
    </button>
  </div>
</div>
