<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="20px"
  fxFlexFill
  [ngClass]="toastClasses"
>
  <!-- Message and Title -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message"
    ></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>
  </div>
  <!-- Loading component -->
  <div
    *ngIf="toastClasses.includes('undo') && !toastClasses.includes('undoable')"
  >
    {{ toastText.loading }}
  </div>
  <!-- Controls -->
  <div>
    <button
      mat-raised-button
      *ngIf="!options.closeButton && toastClasses.includes('undoable')"
      class="portal-btn btn-toast"
      (click)="action($event)"
    >
      {{ toastText.Undo }}
    </button>
    <button
      mat-raised-button
      *ngIf="!options.closeButton && toastClasses.includes('route')"
      class="portal-btn btn-toast"
      (click)="action($event)"
    >
      {{ toastText.View }}
    </button>
    <a
      *ngIf="options.closeButton || toastClasses.includes('final')"
      (click)="remove()"
      class="btn btn-sm"
    >
      <mat-icon>close</mat-icon>
    </a>
  </div>
</div>
