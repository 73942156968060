import Root from '@react/components/Root';
import { Unauthorised } from '@react/components/Unauthorised';
import { FeatureRoute } from '@react/lib/features/FeatureRoute';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Main, PageContainer, StyledRoot } from './AdminPage.styled';
import AppointmentType from './components/AppointmentType';
import AppointmentTypeList from './components/AppointmentTypeList';
import MessageTemplate from './components/MessageTemplate';
import MessageTemplatesList from './components/MessageTemplatesList';
import Nav from './components/Nav';
import Recipe from './components/side/recipe';
import RecipeList from './components/side/RecipeList';
import Users from './components/users';

interface Props extends React.ComponentProps<typeof Root> {}

export const AdminPage: FunctionComponent<Props> = (props) => {
  const { t } = useTranslations();
  const { user } = props;
  const { groups } = user;
  const isAdminUser = groups.includes('ClinicPortalAdmin');
  const isClinicUserAdmin = groups.includes('ClinicUserAdmin');

  return (
    <StyledRoot {...props}>
      <PageContainer>
        {!isAdminUser ? (
          <Unauthorised
            url="/"
            text="Admin.NoAccessButtonText"
            container={false}
          />
        ) : (
          <>
            <Nav user={user} />
            <Main>
              <Switch>
                <Route exact path="/admin">
                  <Redirect to="/admin/appointments" />
                </Route>
                <Route exact path="/admin/appointments/:id">
                  <AppointmentType />
                </Route>
                <Route exact path="/admin/appointments">
                  <AppointmentTypeList />
                </Route>
                <Route
                  exact
                  path="/admin/message-templates/new"
                  /* @ts-ignore */
                  redirectToOnFailure="/admin"
                >
                  <MessageTemplate />
                </Route>
                <Route
                  exact
                  path="/admin/message-templates/:id"
                  /* @ts-ignore */
                  redirectToOnFailure="/admin"
                >
                  <MessageTemplate />
                </Route>
                <Route exact path="/admin/message-templates">
                  <MessageTemplatesList />
                </Route>
                <Route exact path="/admin/side/recipes">
                  <RecipeList />
                </Route>
                <Route exact path="/admin/side/recipes/:id">
                  <Recipe />
                </Route>
                {isClinicUserAdmin && (
                  <FeatureRoute
                    exact
                    path="/admin/users"
                    feature="portalAdminOptions.users"
                    redirectToOnFailure="/admin"
                  >
                    <Users />
                  </FeatureRoute>
                )}
                {/* Redirect any other routes */}
                <Route exact path="*">
                  <Redirect to="/admin" />
                </Route>
              </Switch>
            </Main>
          </>
        )}
      </PageContainer>
    </StyledRoot>
  );
};
