import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CoreModule } from '../core/core.module';
import { GlobalPortalGuard } from '../core/services/global-guard.service';
import { MaterialModule } from '../material/material.module';
import { DashboardFoldersComponent } from './components/dashboard-folders/dashboard-folders.component';
import { FolderInfoComponent } from './components/folder-info/folder-info.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { DashboardEffects } from './effects/dashboard.effects';
import { UnreadCount } from './pipes/unread-count.pipe';
import { reducers } from './reducers';
import { ClinicStatusService } from './services/clinic-status.service';
import { DashboardService } from './services/dashboard.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

export const COMPONENTS = [
  DashboardComponent,
  FolderInfoComponent,
  DashboardFoldersComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PerfectScrollbarModule,
    CoreModule.forFeature(),
    StoreModule.forFeature('dashboard', reducers),
    EffectsModule.forFeature([DashboardEffects]),
    RouterModule.forChild([
      {
        path: 'dashboard',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [AuthGuard, GlobalPortalGuard],
      },
    ]),
  ],
  declarations: [...COMPONENTS, UnreadCount],
  providers: [
    DashboardService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ClinicStatusService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
