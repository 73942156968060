import { ErrorHandler, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromRoot from '../../reducers';
import * as AuthActions from '../actions/auth.actions';
import {
  ForgottenPasswordForm,
  SubmitNewPasswordForm
} from '../models/LoginSubmission';
import { ClinicInfoResponse } from '../models/server-response/clinic-info-response';
import { environment } from './../../../environments/environment';
import { PrimaryLogin } from './../models/PrimaryLogin';
import { CompletePasswordForm, VerifyOTPParams } from './../models/user';

@Injectable()
export class AuthService {
  constructor(
    private _store: Store<fromRoot.State>,
    private _errorHandler: ErrorHandler,
    private _httpService: HttpService
  ) {
    this._store.dispatch(new AuthActions.GetClinicId());
  }

  public getClinicId(
    hostname = window.location.hostname
  ): Observable<ClinicInfoResponse> {
    if (
      environment.environment === 'local' ||
      (environment.environment === 'test' && hostname === 'localhost')
    ) {
      hostname = `testsalveclinic.${hostname}`;
      // hostname = `ccrmtest.${hostname}`;
      // hostname = `testsalvecore.${hostname}`;
      // hostname = `testsalvegermany.${hostname}`;
      // hostname = 'manchesterfertility.salvehealth.co';
      // hostname = 'springtest.salvehealthtest.net'
      // hostname = 'crghsyncv2test.${hostname}';
      // hostname = 'crgh.${hostname}';
      // hostname = `mathe-mellowood-consent.${hostname}`;
      // hostname = `vreprotest.${hostname}`;
    }

    const postParams = {
      Action: 'getclinicbysubdomain',
      Input: hostname
    };

    return this._httpService
      .performPublicPostRequest(postParams, '/clinicbysubdomain')
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public async cognitoLogout() {
    return Auth.signOut();
  }

  public async cognitoLogin(primaryLoginInfo: PrimaryLogin): Promise<any> {
    let cognitoUser: any;

    try {
      cognitoUser = await Auth.signIn(primaryLoginInfo);
    } catch (e) {
      return {
        message: `Error Signing in cognito user`,
        originalError: e
      };
    }

    return cognitoUser;
  }

  public async verifyOTP(params: VerifyOTPParams): Promise<any> {
    let cognitoUser: any;

    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(
        params.user,
        params.code
      );
    } catch (e) {
      return {
        message: `Error Verify OTP`,
        originalError: e
      };
    }

    return cognitoUser;
  }

  public async cognitoCompletePassword(
    submissionData: CompletePasswordForm
  ): Promise<any> {
    const { user, password, requiredAttributes } = submissionData;
    let cognitoUser: any;

    try {
      cognitoUser = await Auth.completeNewPassword(
        user,
        password,
        requiredAttributes
      );
    } catch (e) {
      return {
        message: `Error Completing New Password`,
        originalError: e
      };
    }

    return cognitoUser;
  }

  public async cognitoResetPassword(
    submissionData: SubmitNewPasswordForm
  ): Promise<any> {
    const { email, code, new_password } = submissionData;
    let res;

    try {
      res = await Auth.forgotPasswordSubmit(email, code, new_password);
    } catch (e) {
      console.error(e);
      throw new Error(`Error Completing New Password`);
    }

    return res;
  }

  public async cognitoGenerateResetCode(
    submissionData: ForgottenPasswordForm
  ): Promise<any> {
    const { email } = submissionData;
    let res;

    try {
      res = await Auth.forgotPassword(email);
    } catch (e) {
      console.error(e);
      throw new Error(`Error Generating Code`);
    }

    return res;
  }
}
