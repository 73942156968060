import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, of, Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromContent from '../../reducers';
var GlobalDashboardComponent = /** @class */ (function () {
    function GlobalDashboardComponent(_navigationService, _route, _store) {
        this._navigationService = _navigationService;
        this._route = _route;
        this._store = _store;
        this.categories$ = of([]);
        // Subscription to allow easy clean up
        this._subs = new Subscription();
    }
    GlobalDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.categories$ = this._store.pipe(select(fromContent.getGeneralCategories));
        this._subs.add(combineLatest(this._route.url, this.categories$).subscribe(function (_a) {
            var route = _a[0], categories = _a[1];
            if (route[0].path === 'global' && categories.length) {
                _this._navigationService.navigate([
                    'content',
                    'list',
                    categories[0].id,
                ]);
            }
        }));
    };
    GlobalDashboardComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    return GlobalDashboardComponent;
}());
export { GlobalDashboardComponent };
