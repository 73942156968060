import { Action } from '@ngrx/store';

export const UPDATE_FORM = '[Content][Forms] Update Form';

export class UpdateForm implements Action {
  readonly type = UPDATE_FORM;

  constructor(
    public payload: {
      path: string;
      value: any;
    },
  ) {}
}

export interface FormState {
  newCategory: { category: string };
}

export const initialState: FormState = {
  newCategory: {
    category: '',
  },
};

export function reducer(state = initialState, action: UpdateForm) {
  switch (action.type) {
    case UPDATE_FORM: {
      return {
        ...state,
        [action.payload.path]: action.payload.value,
      };
    }
    default: {
      return state;
    }
  }
}

export const getForm = (state: FormState, path: string) => state[path];
