import { useApiClient } from '@react/lib/api';
import { useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatClinicUser } from '../lib';

export function useFetchMe() {
  const apiClient = useApiClient().portal;

  return useQuery([queryCacheKey.MYSELF], async () => {
    const { data } = await apiClient.fetchMe();
    return formatClinicUser(data);
  });
}

export function useFetchMeWithClinics() {
  const apiClient = useApiClient().portal;

  return useQuery([queryCacheKey.MYSELF_WITH_CLINICS], async () => {
    const { data } = await apiClient.fetchMe(true);
    return formatClinicUser(data);
  });
}
