import { ElementRef, Renderer2, } from '@angular/core';
import { VideoChatService } from '../../services/video.chat.service';
var CameraComponent = /** @class */ (function () {
    function CameraComponent(renderer, videoChatService) {
        this.renderer = renderer;
        this.videoChatService = videoChatService;
        this.isWaiting = false;
    }
    Object.defineProperty(CameraComponent.prototype, "videoTrack", {
        get: function () {
            return this._videoTrack;
        },
        set: function (value) {
            if (value) {
                this._videoTrack = value;
                this.finalizePreview();
                this.showPreview();
            }
        },
        enumerable: true,
        configurable: true
    });
    CameraComponent.prototype.finalizePreview = function () {
        try {
            if (this.videoTrack) {
                this.videoTrack.detach().forEach(function (element) { return element.remove(); });
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    CameraComponent.prototype.showPreview = function () {
        var _this = this;
        try {
            if (this.videoTrack) {
                this.isWaiting = true;
                this.videoTrack.on('started', function () {
                    _this.isWaiting = false;
                });
                var videoElement = this.videoTrack.attach();
                this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
            }
        }
        catch (error) { }
    };
    return CameraComponent;
}());
export { CameraComponent };
