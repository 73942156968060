import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { APIVideoCallResource } from '../../responses';

@Component({
  selector: 'portal-apply-filter',
  templateUrl: './apply-filter.component.html',
  styleUrls: ['./apply-filter.component.scss'],
})
export class ApplyFilterComponent implements OnChanges {
  @Input() filterButtonText: string;
  @Input() applyButtonText: string;
  @Input() cancelButtonText: string;
  @Input() searchPlaceholderText: string;
  @Input() noResultsText: string;
  @Input() headerText: string;
  @Input() items: APIVideoCallResource[];

  @Output() filtersApplied = new EventEmitter<APIVideoCallResource[]>();

  isOpen = false;
  selectedFilters: APIVideoCallResource[] = [];
  appliedFilters: APIVideoCallResource[] = [];
  filteredItems: APIVideoCallResource[] = [];
  searchTerm = '';

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.items &&
      changes.items.previousValue !== changes.items.currentValue
    ) {
      this.filteredItems = this.items;
    }

    if (changes.searchTerm && changes.searchTerm.currentValue === '') {
      this.filteredItems = this.items;
    }
  }

  onFilterToggle(e) {
    this.isOpen = !this.isOpen;
    this.selectedFilters = [].concat(this.appliedFilters);
  }

  onFilterSearch(e) {
    this.searchTerm = e.target.value;
    this.filteredItems = this.items.filter((item) =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
    );
  }

  onFilterSelected(e) {
    if (e.option.selected) {
      if (!this.selectedFilters.some((f) => f === e.option.value)) {
        this.selectedFilters.push(e.option.value);
      }
    } else {
      this.selectedFilters = this.selectedFilters.filter(
        (f) => f !== e.option.value,
      );
    }
  }

  onFilterApply() {
    const removedFilters = this.appliedFilters.filter(
      (f) => !this.selectedFilters.includes(f),
    );

    const addedFilters = this.selectedFilters.filter(
      (f) => !this.appliedFilters.includes(f),
    );

    this.appliedFilters = this.appliedFilters
      .concat(addedFilters)
      .filter((f) => !removedFilters.includes(f));

    this.filtersApplied.emit(this.appliedFilters);

    this.isOpen = false;
    this.searchTerm = '';
  }

  onFilterCancel() {
    this.isOpen = false;
    this.searchTerm = '';
    this.selectedFilters = [];
  }

  onFilterRemove(filter) {
    this.selectedFilters = [].concat(
      this.selectedFilters.filter((f) => f !== filter),
    );
    this.appliedFilters = [].concat(
      this.selectedFilters.filter((f) => f !== filter),
    );
    this.filtersApplied.emit(this.appliedFilters);
  }

  isSelected(item) {
    return this.selectedFilters.some((f) => f === item);
  }
}
