import * as tslib_1 from "tslib";
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkFeature } from './check';
import { useFeatures } from './context';
export var FeatureRoute = function (_a) {
    var feature = _a.feature, redirectToOnFailure = _a.redirectToOnFailure, rest = tslib_1.__rest(_a, ["feature", "redirectToOnFailure"]);
    var features = useFeatures();
    var shouldRender = checkFeature(features, feature);
    if (!shouldRender) {
        return React.createElement(Redirect, { to: redirectToOnFailure });
    }
    return React.createElement(Route, tslib_1.__assign({}, rest));
};
