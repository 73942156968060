import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Button } from '@react/components/Button';
import { Empty } from '@react/components/Empty';
import { MessageIcon } from '@react/components/icons/MessageIcon';
import { Loading } from '@react/components/Loading';
import { LoadingError } from '@react/components/LoadingError';
import { useMessageTemplatesQuery } from '@react/lib/api/hooks';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ActionsContainer, Container } from './MessageTemplatesList.styled';
import {
  FullHeightPaper,
  StyledLink,
  StyledSearchBar,
  StyledTableHead,
  StyledTableRow,
} from './MessageTemplatesList.styled';

const MessageTemplatesList: React.VFC = () => {
  const { t } = useTranslations();
  const { data, isError, isLoading, refetch } = useMessageTemplatesQuery();
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const debouncedSearchTerm = useDebouncedState(searchInputValue, 500);

  // If we don't do this we're back to every keystroke causing the
  // the list to be filtered.
  const results = React.useMemo(() => {
    if (data == null || data.data.length === 0) {
      return [];
    }

    const searchTermLower = debouncedSearchTerm.trim().toLowerCase();
    if (searchTermLower === '') {
      return data.data;
    }

    return data.data.filter(
      ({ content, name }) =>
        name.toLowerCase().includes(searchTermLower) ||
        content.toLowerCase().includes(searchTermLower),
    );
  }, [debouncedSearchTerm, data]);

  const noResults = data == null || data.data.length === 0;
  const noMatchingResults = !noResults && results.length === 0;

  return (
    <Container>
      <ActionsContainer>
        <StyledSearchBar
          onChange={setSearchInputValue}
          placeholder={t.Admin.MessageTemplatesSearchPlaceholder}
          value={searchInputValue}
        />
        {/* Property 'component' requires newer TypeScript
            // @ts-ignore */}
        <Button
          color="primary"
          /* @ts-ignore */
          component={Link}
          disabled={isLoading}
          to="/admin/message-templates/new"
          variant="contained"
        >
          {t.Admin.MessageTemplatesNewTemplateButtonText}
        </Button>
      </ActionsContainer>
      <FullHeightPaper>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <LoadingError
            icon={<MessageIcon />}
            message={t.Admin.MessageTemplatesLoadingErrorMessage}
          >
            <Button
              color="primary"
              onClick={() => refetch()}
              variant="contained"
            >
              {t.Admin.MessageTemplatesLoadingErrorRetryButtonText}
            </Button>
          </LoadingError>
        ) : noResults ? (
          <Empty
            icon={<MessageIcon />}
            message={t.Admin.MessageTemplatesEmptyMessage}
          />
        ) : noMatchingResults ? (
          <Empty
            icon={<MessageIcon />}
            message={t.Admin.MessageTemplatesEmptyNonMatchingMessage}
          />
        ) : (
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>{t.Admin.MessageTemplatesNameLabel}</TableCell>
                  <TableCell>
                    {t.Admin.MessageTemplatesLastUpdatedByLabel}
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {results.map((mt) => (
                  <StyledTableRow
                    key={mt.id}
                    data-testid="MessageTemplateTableRow"
                    tabIndex={0}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <StyledLink to={`/admin/message-templates/${mt.id}`}>
                        {mt.name}
                      </StyledLink>
                    </TableCell>
                    <TableCell padding="none">
                      <StyledLink to={`/admin/message-templates/${mt.id}`}>
                        {mt.modifiedBy.name}
                      </StyledLink>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </FullHeightPaper>
    </Container>
  );
};

export default MessageTemplatesList;
