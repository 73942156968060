import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { RemoteParticipant, } from 'twilio-video';
import { MetaMessageService } from '../../services/meta-message.service';
import { VideoChatService } from '../../services/video.chat.service';
var ParticipantComponent = /** @class */ (function () {
    function ParticipantComponent(metaMessageService, videoChatService) {
        var _this = this;
        this.name = '';
        this.isWaiting = false;
        this.onDataTrackMessage = function (data, track) {
            if (!(typeof data === 'string')) {
                return;
            }
            var metaMessage = _this._metaMessageService.parseMessage(data);
            if (metaMessage == null) {
                return;
            }
            switch (metaMessage.type) {
                case 'ParticipantIdentity': {
                    _this.name = metaMessage.name;
                    break;
                }
            }
        };
        this._metaMessageService = metaMessageService;
        this._videoChatService = videoChatService;
    }
    Object.defineProperty(ParticipantComponent.prototype, "audioOutputDeviceId", {
        set: function (value) {
            this._audioOutputDeviceId = value;
            var audioElement = this._audioPlaceholder.nativeElement.querySelector('audio');
            if (audioElement) {
                this.setAudioSink(audioElement);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParticipantComponent.prototype, "isAudioEnabled", {
        get: function () {
            return this._remoteAudioTrack != null
                ? this._remoteAudioTrack.isEnabled
                : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ParticipantComponent.prototype, "isVideoEnabled", {
        get: function () {
            return this._remoteVideoTrack != null
                ? this._remoteVideoTrack.isEnabled
                : true;
        },
        enumerable: true,
        configurable: true
    });
    ParticipantComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.participant.tracks.forEach(function (publication) {
            if (publication.isSubscribed) {
                var track = publication.track;
                _this.attachTrack(track);
            }
        });
        this.participant.on('trackSubscribed', function (track) {
            _this.attachTrack(track);
        });
    };
    ParticipantComponent.prototype.ngOnDestroy = function () {
        try {
            if (this.participant) {
                this.participant.removeAllListeners();
            }
            try {
                if (this._remoteAudioTrack) {
                    this._remoteAudioTrack.detach();
                    this._remoteAudioTrack = undefined;
                }
                if (this._remoteDataTrack) {
                    this._remoteDataTrack.removeListener('message', this.onDataTrackMessage);
                    this._remoteDataTrack = undefined;
                }
                if (this._remoteVideoTrack) {
                    this._remoteVideoTrack.detach();
                    this._remoteVideoTrack = undefined;
                }
            }
            catch (_a) { }
        }
        catch (error) {
            console.error('Error detaching');
            console.error(error);
        }
    };
    ParticipantComponent.prototype.attachTrack = function (track) {
        var _this = this;
        switch (track.kind) {
            case 'audio': {
                if (this._remoteAudioTrack &&
                    this._remoteAudioTrack.sid === track.sid) {
                    return;
                }
                var audioElement = track.attach();
                this._audioPlaceholder.nativeElement.appendChild(audioElement);
                this.setAudioSink(audioElement);
                this._remoteAudioTrack = track;
                break;
            }
            case 'video': {
                this.isWaiting = true;
                track.on('started', function () {
                    _this.isWaiting = false;
                    track.removeAllListeners();
                });
                if (this._remoteVideoTrack &&
                    this._remoteVideoTrack.sid === track.sid) {
                    return;
                }
                // Remove all previous videos attached to avoid multiple
                // videos appearing from the same participant
                this._videoPlaceholder.nativeElement.childNodes.forEach(function (node) {
                    _this._videoPlaceholder.nativeElement.removeChild(node);
                });
                this._videoPlaceholder.nativeElement.appendChild(track.attach());
                this._remoteVideoTrack = track;
                break;
            }
            case 'data': {
                this._remoteDataTrack = track;
                track.on('message', this.onDataTrackMessage);
                var nameMessage = this._metaMessageService.createMessage({
                    identity: this.localIdentity,
                    name: this.localName,
                    type: 'ParticipantIdentity',
                });
                this._videoChatService.sendMessage(nameMessage);
            }
        }
    };
    ParticipantComponent.prototype.setAudioSink = function (audioElement) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._audioOutputDeviceId != null && 'setSinkId' in audioElement)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        // @ts-ignore
                        return [4 /*yield*/, audioElement.setSinkId(this._audioOutputDeviceId)];
                    case 2:
                        // @ts-ignore
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ParticipantComponent;
}());
export { ParticipantComponent };
