<mat-card>
  <div fxLayout="row" fxLayoutAlign="start start">
    <mat-list class="sidenav">
      <mat-list-item
        *ngFor="let section of settingSections$ | async"
        [routerLink]="['/settings', section]"
        routerLinkActive="router-link-active"
        >{{ section | titlecase }}</mat-list-item
      >
    </mat-list>
    <portal-general-settings
      *ngIf="cardTitle === 'general'"
      [languages]="languages$ | async"
      [activeLanguage]="activeLanguage$ | async"
      (setLanguage)="changePortalLanguage($event)"
      fxFlex="grow"
    >
    </portal-general-settings>
  </div>
</mat-card>
