export class DeleteMessageItemRequest {
  private Action = 'DeleteClinicMessageItem';
  public PublicToken: string;
  public MessageId: number;
  public DeleteMessageItemId: number;

  constructor({ PublicToken, MessageId, DeleteMessageItemId }) {
    this.PublicToken = PublicToken;
    this.MessageId = MessageId;
    this.DeleteMessageItemId = DeleteMessageItemId;
  }
}
