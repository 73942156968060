import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';

@Component({
  selector: 'portal-rich-or-pdf',
  templateUrl: './rich-or-pdf.component.html',
  styleUrls: ['./rich-or-pdf.component.scss'],
})
export class RichOrPdfComponent implements OnDestroy, OnInit {
  public sectionText$: Observable<any>;

  public categoryId: number;

  private _subs = new Subscription();

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
  ) {
    this._subs.add(
      this._route.params
        .pipe(map(({ id }) => +id))
        .subscribe((v) => (this.categoryId = v)),
    );
  }

  ngOnInit(): void {
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateContentItem')),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  goBackToList(): void {
    this._navigationService.navigate(['content', 'list', this.categoryId]);
  }

  goToPdf() {
    this._navigationService.navigate([
      'content',
      'doc',
      'create',
      'pdf',
      this.categoryId,
    ]);
  }

  goToRichContent() {
    this._navigationService.navigate([
      'content',
      'doc',
      'create',
      'rich',
      this.categoryId,
    ]);
  }
}
