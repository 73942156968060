import { useApiClient } from '@react/lib/api';
import { useMutation, useQuery } from 'react-query';

export function usePatientFormQuery({
  key,
  value,
}: {
  key: string;
  value: string;
}) {
  const apiClient = useApiClient().portal;

  return useQuery(
    ['ResubmittablePatientLookupForm', key, value],
    () => apiClient.fetchResubmittablePatientLookupForms(key, value),
    {
      enabled: false,
    },
  );
}

export function useResubmitPatientFormMutation() {
  const apiClient = useApiClient().portal;

  return useMutation((formId: number) => {
    return apiClient.resubmitPatientForm(formId);
  });
}
