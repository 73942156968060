/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./switch-portal-react-wrapper.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/congito.wrapper.service";
var styles_SwitchPortalReactWrapperComponent = [];
var RenderType_SwitchPortalReactWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SwitchPortalReactWrapperComponent, data: {} });
export { RenderType_SwitchPortalReactWrapperComponent as RenderType_SwitchPortalReactWrapperComponent };
export function View_SwitchPortalReactWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["switchPortalReactWrapperComponent", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_SwitchPortalReactWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-switch", [], null, null, null, View_SwitchPortalReactWrapperComponent_0, RenderType_SwitchPortalReactWrapperComponent)), i0.ɵdid(1, 4440064, null, 0, i1.SwitchPortalReactWrapperComponent, [i2.Store, i3.CognitoWrapperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SwitchPortalReactWrapperComponentNgFactory = i0.ɵccf("portal-switch", i1.SwitchPortalReactWrapperComponent, View_SwitchPortalReactWrapperComponent_Host_0, { clinic: "clinic" }, {}, []);
export { SwitchPortalReactWrapperComponentNgFactory as SwitchPortalReactWrapperComponentNgFactory };
