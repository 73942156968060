import * as tslib_1 from "tslib";
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon, MoreVert as MoreVertIcon, } from '@material-ui/icons';
import { DropdownMenu, Text } from '@react/components';
import { ProfilePhoto } from '@react/components/ProfilePhoto';
import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { Action } from '../constants';
import { ChatMessageStyled, ChatMessageWrapperStyled, Content, Header, ProfilePictureStyled, } from './ChatMessage.styled';
import { FileDescription } from './FileDescription';
export var ChatMessage = function (_a) {
    var alignDirection = _a.alignDirection, message = _a.message, onActionClick = _a.onActionClick, onFileClick = _a.onFileClick, fileUploadService = _a.fileUploadService, staffProfiles = _a.staffProfiles, featureProfilePhoto = _a.featureProfilePhoto;
    var t = useTranslations().t;
    var dateTime = useLocaleDateTime();
    var formatSentAt = function (date) {
        return dateTime.format(new Date(date), 'PPP - p');
    };
    var formatDate = function (date) { return dateTime.format(new Date(date), 'PP'); };
    var formatTime = function (date) { return dateTime.format(new Date(date), 'p'); };
    var actions = [
        tslib_1.__assign({ actionId: Action.MESSAGE_DELETE, name: t.Messages.DeleteMessageItem }, message),
    ];
    var handleActionClick = function (action) {
        onActionClick(action, message);
    };
    var clinicuserfirstname = message.clinicuserfirstname, clinicuserfullname = message.clinicuserfullname, clinicuserid = message.clinicuserid, deleteddate = message.deleteddate, deletedbyclinicuser = message.deletedbyclinicuser, lastmessagegrouped = message.lastmessagegrouped, patientfirstname = message.patientfirstname, patientsent = message.patientsent, patientattachmentfilename = message.patientattachmentfilename, patientattachmentfilesize = message.patientattachmentfilesize, read = message.read, readdate = message.readdate;
    var sender = patientsent ? patientfirstname : clinicuserfullname;
    var isRemoveVisible = !deleteddate && !patientsent && !read;
    var isDeletedMessageVisible = Boolean(!patientsent && deletedbyclinicuser);
    var isSeenMessageVisible = !isDeletedMessageVisible && !patientsent && read;
    var clinicUserId = !patientsent && clinicuserid ? clinicuserid : undefined;
    var hasClinicUserId = Boolean(clinicUserId);
    var showProfilePhoto = Boolean(lastmessagegrouped && hasClinicUserId) && featureProfilePhoto;
    return (React.createElement(ChatMessageWrapperStyled, { className: "chat-message-wrapper__" + alignDirection + " " + (showProfilePhoto ? 'grid' : 'grouped') },
        showProfilePhoto && (React.createElement(ProfilePictureStyled, { className: 'profile-message' },
            React.createElement(ProfilePhoto, { fileUploadService: fileUploadService, size: "regular", clinicUserId: clinicUserId, showInitials: true, staffProfiles: staffProfiles }))),
        React.createElement(ChatMessageStyled, { className: "chat-message-wrapper__" + alignDirection },
            React.createElement(Header, null,
                React.createElement(Text.Small, { bold: true }, sender),
                React.createElement(Text.Small, { className: "timestamp" }, formatSentAt(message.sentdate)),
                isRemoveVisible && (React.createElement(DropdownMenu, { "data-testid": "chat-message--actions", menu: actions, onClick: handleActionClick },
                    React.createElement(IconButton, { "aria-controls": "simple-menu", "aria-haspopup": "true", size: "small" },
                        React.createElement(MoreVertIcon, null))))),
            patientattachmentfilename ? (React.createElement(FileDescription, { filename: patientattachmentfilename, fileSize: patientattachmentfilesize, onClick: function () { return onFileClick(message); } })) : (React.createElement(Content, null, message.content)),
            isDeletedMessageVisible && deleteddate && (React.createElement(Text.Small, { className: "deleted-by", block: true }, t.Messages.DeletedMessageText(clinicuserfirstname, formatDate(deleteddate), formatTime(deleteddate)))),
            isSeenMessageVisible && (React.createElement(Text.Small, { className: "read-by", block: true },
                React.createElement(CheckIcon, null),
                ' ',
                t.Messages.ReadBy(patientfirstname, formatTime(readdate), formatDate(readdate)))))));
};
