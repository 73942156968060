import { TableCell } from '@material-ui/core';
import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import Actions from '../actions';
import CognitoStatusChip from '../status';
import { StyledTableRow } from './index.styled';
var UsersRow = function (_a) {
    var user = _a.user, onClickInviteUser = _a.onClickInviteUser;
    var localeDateTime = useLocaleDateTime();
    var t = useTranslations().t;
    return (React.createElement(StyledTableRow, { key: user.id, "data-testid": "UserTableRow", role: "button", tabIndex: 0 },
        React.createElement(TableCell, null, user.firstname),
        React.createElement(TableCell, null, user.lastname),
        React.createElement(TableCell, null, user.email),
        React.createElement(TableCell, null,
            React.createElement(CognitoStatusChip, { status: user.status, label: t.Admin.Users[user.status + "Status"] })),
        React.createElement(TableCell, null, user.salvecreated &&
            localeDateTime.format(new Date(user.salvecreated), 'PP, p')),
        React.createElement(TableCell, null,
            React.createElement(Actions, { user: user, onClickInviteUser: onClickInviteUser }))));
};
var ɵ0 = UsersRow;
export default UsersRow;
export { ɵ0 };
