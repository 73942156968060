import {
  Component,
  ElementRef,
  ErrorHandler,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { validatePatientDocumentName } from 'src/app/patients/services/async-validators/validate-patient-document-name';
import * as fromAuth from '../../../auth/reducers';
import { ContentEntry } from '../../../content/models/content-entry';
import { FileInStorage } from '../../../content/models/file-in-storage';
import { validateDocumentName } from '../../../content/services/async-validators/validate-document-name';
import { ContentService } from '../../../content/services/content.service';
import { Md5HashService } from '../../../core/services/md5-hash.service';
import { FileUpload } from '../../../core/services/s3.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';

@Component({
  selector: 'portal-file-pdf',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.scss']
})
export class AddFileComponent implements OnInit, OnDestroy {
  @ViewChild('docFileInput') docFileInput: ElementRef;
  @Input() categoryType: string;

  // Observable for text
  public sectionText$: Observable<any>;
  public sectionText: any;

  // capture selected file information
  public filename$ = new BehaviorSubject<string>(null);
  public fileName: string;
  public fileSize: number;

  public selectedFile: File;
  public fileInStorage: FileInStorage = {
    clinicid: '', // which clinic owns this content
    patientonly: false, // used if this is patient specific
    documentname: '', // from file
    issalvecontent: false, // is this content generated by salve?
    isembedded: false, // is this document in rich content?
    filetype: '', // from file
    filesize: 0,
    md5checksum: '', // generated by MD5
    uri: '' // the location in S3 of the resource
  };
  public docMD5: string;

  // uploading is the act of uploading to S3... if file exists
  //   return file stats from db and use that information
  public uploading$ = new BehaviorSubject<boolean>(false);
  public uploadSuccess$ = new BehaviorSubject<boolean>(false);
  public uploadError$ = new BehaviorSubject<boolean>(false);

  // Control the upload button text
  public buttonText$ = new BehaviorSubject<string>('');
  public labelText$ = new BehaviorSubject<string>('');

  // Am I validating the document name?
  public loading$ = new BehaviorSubject<boolean>(false);

  public clinicId$: Observable<string>;
  public userPk$: Observable<string>;

  public documentForm: FormGroup;

  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _S3: FileUpload,
    private _contentService: ContentService,
    private _error: ErrorHandler,
    private _hashService: Md5HashService,
    public dialogRef: MatDialogRef<AddFileComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contentEntry: ContentEntry;
      accept: string[];
      fileName: string;
      type: string;
      public: boolean;
      patientOnly: boolean;
      patientId: number;
    }
  ) {
    this.fileInStorage.patientonly = !!this.data.patientOnly;
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('AddFile'))
    );
    this._subs.add(this.sectionText$.subscribe((t) => (this.sectionText = t)));
  }

  ngOnInit() {
    this.buttonText$.next(this.sectionText.UploadFile);
    this.labelText$.next(this.sectionText.ChooseFile);
    if (this.data.type && this.data.type === 'image') {
      this.labelText$.next(this.sectionText.ChooseImage);
    }
    this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
    this.userPk$ = this._store.pipe(select(fromAuth.getPublicKey));
    const documentName = this.data.patientOnly ? '' : this.data.fileName;

    const asyncValidators = this.data.patientOnly
      ? [
          validatePatientDocumentName(
            this._contentService,
            this.loading$,
            documentName,
            this.data.patientId
          )
        ]
      : [
          validateDocumentName(
            this._contentService,
            this.loading$,
            documentName
          )
        ];

    this.documentForm = new FormGroup({
      name: new FormControl(
        documentName,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ]),
        asyncValidators
      ),
      file: new FormControl({ value: null, disabled: false }, [
        Validators.required
      ])
    });

    // watch for changes to file input in order to append
    // filename name to filename span
    this._subs.add(
      this.documentForm.get('file').valueChanges.subscribe((file: string) => {
        if (file) {
          this.selectedFile = this.docFileInput.nativeElement.files[0];
          const fileNameArr = file.split('\\');
          this.fileName = fileNameArr[fileNameArr.length - 1];
          this.fileSize = this.selectedFile.size;
          this.filename$.next(this.fileName);

          if (this.data.type === 'image') {
            this.uploadFile();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public clearName(): void {
    this.documentForm.patchValue({
      name: ''
    });
  }

  public clearFile(): void {
    this.documentForm.patchValue({
      file: null
    });
    this.docFileInput.nativeElement.value = '';
    this.selectedFile = null;
    this.filename$.next('');
  }

  public uploadFile(): void {
    // check file by hashing and searching for duplicates
    this.buttonText$.next(this.sectionText.Checking);

    this.uploading$.next(true);
    // Hash file
    this._hashFile(this.selectedFile)
      .then((md5Hash) => {
        // Check if hash already exists in db
        if (!this.data.patientOnly) {
          return this._doesHashExist(md5Hash);
        } else {
          return Promise.resolve([]);
        }
      })
      .then((files) => {
        this.buttonText$.next(this.sectionText.Uploading);
        if (files.length > 0) {
          // If exists, add info to content entry
          return Promise.resolve(files[0]);
        }
        // if not exists, create and upload and retrieve fileInfo
        return this._createFileInStorage(this.selectedFile);
      })
      .then((file: FileInStorage) => {
        if (file.uri.length > 0) {
          return Promise.resolve(file);
        }
        return this._writeFileToBucket(this.selectedFile, file);
        // return this._uploadFileToS3(this.selectedFile, file);
      })
      .then((file: FileInStorage) => {
        return this._saveDocument(file);
      })
      .then((file: FileInStorage) => {
        this.fileInStorage = file;
        this.uploading$.next(false);
        this.uploadSuccess$.next(true);
        this.buttonText$.next(this.sectionText.Uploaded);

        setTimeout(() => {
          this.submit();
        }, 500);
      })
      .catch((err) => {
        this.uploading$.next(false);
        this.uploadError$.next(true);
        this.buttonText$.next(this.sectionText.Error);
      });
  }

  private _writeFileToBucket(file: File, fileInfo: FileInStorage) {
    return new Promise((res, rej) => {
      const fileComponents = file.name.split('.');
      const extension = fileComponents[fileComponents.length - 1];
      const fileName = `${this._cleanFileName(
        fileInfo.documentname
      )}.${extension}`;
      // const type = this.data.public
      //   ? 'public'
      //   : 'private';
      this._S3
        .writeFileToBucket(
          'content',
          fileName,
          file,
          this.data.public,
          this.data.patientId
        )
        .subscribe(
          (r) => {
            if (r.complete && (r.body || r.error)) {
              if (r.body && !(r.error instanceof Error)) {
                fileInfo.uri = r.body.Location;
                res(fileInfo);
              } else if (r.error instanceof Error) {
                rej(r.error);
              } else {
                rej(new Error(`Unexpected response from s3 service`));
              }
            }
          },
          (e) => {
            this.uploadError$.next(true);
            rej(e);
          }
        );
    });
  }

  private _saveDocument(file: FileInStorage): Promise<FileInStorage | Error> {
    return new Promise((res, rej) => {
      this._contentService.updateFileInStorage(file).subscribe(
        (updatedFile) => res(updatedFile),
        (err) => rej(err)
      );
    });
  }

  private _cleanFileName(name: string): string {
    return name.replace(/\'/g, '').replace(/\s/g, '_').toLowerCase();
  }

  private _createFileInStorage(file: File): Promise<FileInStorage | Error> {
    const newFile = {
      ...this.fileInStorage
    };
    return new Promise((res, rej) => {
      this.clinicId$
        .pipe(
          take(1),
          switchMap((clinicId) => {
            // update file with relevant fields
            newFile.clinicid = clinicId;
            newFile.md5checksum = this.docMD5;
            newFile.documentname = this.documentForm.get('name').value;
            newFile.filetype = file.type;
            newFile.filesize = +file.size;

            return this._contentService.createFileInStorage(newFile);
          })
        )
        .subscribe(
          (createdFile: FileInStorage) => {
            res(createdFile);
          },
          (err) => rej(err)
        );
    });
  }

  private _doesHashExist(hash: string): Promise<FileInStorage[]> {
    return new Promise((res, rej) => {
      this._contentService
        .checkFileByMD5(hash)
        .pipe(
          take(1),
          map((files: FileInStorage[]) => {
            res(files);
          })
        )
        .subscribe(
          () => {},
          (err) => {
            rej(err);
          }
        );
    });
  }

  private _hashFile(file: File): Promise<string> {
    return new Promise((res, rej) => {
      this._hashService.hashFile(file).subscribe(
        (progress) => {
          if (progress.progress === 100) {
            this.docMD5 = progress.hash;
            res(progress.hash);
          }
        },
        (err) => {
          rej(err);
        }
      );
    });
  }

  // send form and uploaded document back to main form
  // or cancel
  public submit() {
    this.dialogRef.close({
      attachment: this.fileInStorage
    });
  }

  public cancel() {
    this.dialogRef.close({
      attachment: null
    });
  }
}
