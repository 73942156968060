import get from 'lodash/get';
import { ClinicOptions } from 'src/app/auth/models/ClinicOptions';
import { SideBarMenuItem } from 'src/app/models/SideBarMenuItem';

export const menuItems: SideBarMenuItem[] = [
  {
    key: 'Dashboard',
    path: 'dashboard',
    displayName: 'Home',
    icon: 'home',
    isCore: true,
    isGlobal: false,
  },
  {
    key: 'Messages',
    path: 'messages',
    displayName: 'Messages',
    icon: 'forum',
    isCore: true,
    isGlobal: false,
  },
  {
    key: 'Content',
    path: 'content',
    displayName: 'Content',
    icon: 'file_copy',
    isCore: true,
    isGlobal: true,
  },
  {
    key: 'Medications',
    path: 'medications',
    displayName: 'Medications',
    icon: 'local_hospital',
    isCore: false,
    isGlobal: false,
  },
  {
    key: 'Patients',
    path: 'patients',
    displayName: 'Patients',
    icon: 'account_box',
    isCore: true,
    isGlobal: false,
  },
  {
    key: 'VideoCalls',
    path: 'video-calls',
    optionsPath: 'videoCallOptions.enabled',
    displayName: 'Video Calls',
    icon: 'video_call',
    requiresCognito: true,
    isCore: true,
    isGlobal: false,
  },
  {
    key: 'Admin',
    path: 'admin',
    displayName: 'Admin',
    icon: 'settings',
    requiresCognito: true,
    requiresUserGroup: 'ClinicPortalAdmin',
    isCore: true,
    isGlobal: false,
  },
  {
    key: 'SuperAdmin',
    path: 'superadmin',
    displayName: 'Super Admin',
    icon: 'plumbing',
    requiresCognito: true,
    requiresUserGroup: 'ClinicPortalSuperAdmin',
    isCore: true,
    isGlobal: false,
  },
];

export function getMenuItems(
  isCoreClinic: boolean,
  isUserCognito: boolean,
  isGlobalClinic: boolean,
  clinicOptions: ClinicOptions,
  userGroups: string[],
  menuItemsObj: SideBarMenuItem[] = menuItems,
) {
  const featureFlagFilter = (item: SideBarMenuItem) =>
    item.optionsPath == null ||
    (item.optionsPath && get(clinicOptions, item.optionsPath));
  const cognitoItemFilter = (item: SideBarMenuItem) =>
    item.requiresCognito == null || (item.requiresCognito && isUserCognito);
  const coreItemFilter = (item: SideBarMenuItem) =>
    !isCoreClinic || item.isCore;
  const groupsItemFilter = (item: SideBarMenuItem) =>
    item.requiresUserGroup == null ||
    userGroups.includes(item.requiresUserGroup);
  const globalItemFilter = (item: SideBarMenuItem) =>
    !isGlobalClinic ? true : item.isGlobal;

  return menuItemsObj.filter(
    (item) =>
      coreItemFilter(item) &&
      cognitoItemFilter(item) &&
      featureFlagFilter(item) &&
      groupsItemFilter(item) &&
      globalItemFilter(item),
  );
}
