import * as tslib_1 from "tslib";
import { AuthActionTypes } from '../actions/auth.actions';
export var initialState = {
    loaded: false,
    options: {}
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.GetClinicIdSuccess: {
            return tslib_1.__assign({}, state, { options: action.payload.JsonOptions
                    ? JSON.parse(action.payload.JsonOptions)
                    : {} });
        }
        default: {
            return state;
        }
    }
}
export var getClinicOptionsLoaded = function (state) { return state.loaded; };
export var getClinicOptions = function (state) { return state.options; };
export var getVideoCallsEnabled = function (state) {
    return !!state.options.videoCallOptions && !!state.options.videoCallOptions.enabled;
};
export var getMedicationManagerEnabled = function (state) {
    return !!state.options.medicationManagerOptions &&
        !!state.options.medicationManagerOptions.enabled;
};
export var getVideoCallsPartnerInvite = function (state) {
    return !!state.options.videoCallOptions &&
        !!state.options.videoCallOptions.partnerInvite;
};
export var getLabResultsEnabled = function (state) {
    return !!state.options.labResultOptions && !!state.options.labResultOptions.enabled;
};
export var getMessageStarringEnabled = function (state) {
    return !!state.options.messagingOptions &&
        !!state.options.messagingOptions &&
        !!state.options.messagingOptions.messageStarring;
};
export var getContentEditorEnabled = function (state) {
    return !!state.options.contentEditorOptions &&
        !!state.options.contentEditorOptions.enabled;
};
