/* tslint:disable */
import * as tslib_1 from "tslib";
import { StyledMenu } from './Menu.styled';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';
import React, { useImperativeHandle, useRef, useState } from 'react';
var TRANSPARENT = 'rgba(0,0,0,0)';
var ɵ0 = function (theme) { return ({
    root: function (props) { return ({
        backgroundColor: props.open ? theme.palette.action.hover : TRANSPARENT,
    }); },
}); };
var useMenuItemStyles = makeStyles(ɵ0);
var ɵ1 = function () { return ({
    paper: function () { return ({
        pointerEvents: 'auto',
    }); },
}); };
var usePopOverStyles = makeStyles(ɵ1);
var ɵ2 = function NestedMenuItem(props, ref) {
    var parentMenuOpen = props.parentMenuOpen, _a = props.component, component = _a === void 0 ? 'div' : _a, label = props.label, _b = props.rightIcon, rightIcon = _b === void 0 ? React.createElement(ArrowRight, null) : _b, children = props.children, className = props.className, tabIndexProp = props.tabIndex, _c = props.MenuProps, MenuProps = _c === void 0 ? {} : _c, _d = props.ContainerProps, ContainerPropsProp = _d === void 0 ? {} : _d, MenuItemProps = tslib_1.__rest(props, ["parentMenuOpen", "component", "label", "rightIcon", "children", "className", "tabIndex", "MenuProps", "ContainerProps"]);
    var containerRefProp = ContainerPropsProp.ref, ContainerProps = tslib_1.__rest(ContainerPropsProp, ["ref"]);
    var menuItemRef = useRef(null);
    useImperativeHandle(ref, function () { return menuItemRef.current; });
    var containerRef = useRef(null);
    useImperativeHandle(containerRefProp, function () { return containerRef.current; });
    var menuContainerRef = useRef(null);
    var _e = useState(false), isSubMenuOpen = _e[0], setIsSubMenuOpen = _e[1];
    var handleMouseEnter = function (event) {
        setIsSubMenuOpen(true);
        if (ContainerProps && ContainerProps.onMouseEnter) {
            ContainerProps.onMouseEnter(event);
        }
    };
    var handleMouseLeave = function (event) {
        setIsSubMenuOpen(false);
        if (ContainerProps && ContainerProps.onMouseLeave) {
            ContainerProps.onMouseLeave(event);
        }
    };
    // Check if any immediate children are active
    var isSubmenuFocused = function () {
        var active = containerRef.current &&
            containerRef.current.ownerDocument &&
            containerRef.current.ownerDocument.activeElement;
        var children = Array.from((menuContainerRef.current && menuContainerRef.current.children) || []);
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var child = children_1[_i];
            if (child === active) {
                return true;
            }
        }
        return false;
    };
    var handleFocus = function (event) {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }
        if (ContainerProps && ContainerProps.onFocus) {
            ContainerProps.onFocus(event);
        }
    };
    var handleKeyDown = function (event) {
        if (event.key === 'Escape') {
            return;
        }
        if (isSubmenuFocused()) {
            event.stopPropagation();
        }
        var active = containerRef.current &&
            containerRef.current.ownerDocument &&
            containerRef.current.ownerDocument.activeElement;
        if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
            containerRef.current && containerRef.current.focus();
        }
        if (event.key === 'ArrowRight' &&
            event.target === containerRef.current &&
            event.target === active) {
            var firstChild = menuContainerRef.current &&
                menuContainerRef.current.children[0];
            firstChild && firstChild.focus();
        }
    };
    var open = isSubMenuOpen && parentMenuOpen;
    var menuItemClasses = useMenuItemStyles({ open: open });
    var usePopOverClasses = usePopOverStyles();
    // Root element must have a `tabIndex` attribute for keyboard navigation
    var tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }
    return (React.createElement("div", tslib_1.__assign({}, ContainerProps, { ref: containerRef, onFocus: handleFocus, tabIndex: tabIndex, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onKeyDown: handleKeyDown }),
        React.createElement(MenuItem, tslib_1.__assign({}, MenuItemProps, { className: clsx(menuItemClasses.root, className, {
                'MuiListItem-focusVisible': isSubMenuOpen,
            }), ref: menuItemRef }),
            label,
            rightIcon),
        React.createElement(StyledMenu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        , tslib_1.__assign({ 
            // Set pointer events to 'none' to prevent the invisible Popover div
            // from capturing events for clicks and hovers
            style: { pointerEvents: 'none' }, PopoverClasses: usePopOverClasses, getContentAnchorEl: null, anchorEl: menuItemRef.current, anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, open: open, autoFocus: false, disableAutoFocus: true, disableEnforceFocus: true, onClose: function () {
                setIsSubMenuOpen(false);
            } }, MenuProps),
            React.createElement("div", { ref: menuContainerRef, style: { pointerEvents: 'auto' } }, children))));
};
/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
var NestedMenuItem = React.forwardRef(ɵ2);
export default NestedMenuItem;
export { ɵ0, ɵ1, ɵ2 };
