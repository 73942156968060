import { IconButton, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Dialog } from '@react/components';
import { StyledMenu } from '@react/components/Menu.styled';
import { CognitoStatus } from '@react/lib/api/types';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { useDeactivateClinicUserMutation, useTemporaryPasswordMutation, } from '@react/pages/admin/hooks/users';
import React, { useRef, useState, } from 'react';
var Actions = function (_a) {
    var user = _a.user, onClickInviteUser = _a.onClickInviteUser;
    var t = useTranslations().t;
    var menuRef = useRef(null);
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var _c = useState(false), showTemporaryPasswordModal = _c[0], setShowTemporaryPasswordModal = _c[1];
    var _d = useDeactivateClinicUserMutation(), deactivateClinicUser = _d.mutateAsync, isDeactivatingError = _d.isError, isDeactivatingSuccess = _d.isSuccess;
    var _e = useTemporaryPasswordMutation(), sendTemporaryPassword = _e.mutateAsync, isTemporaryPasswordError = _e.isError, isTemporaryPasswordSuccess = _e.isSuccess;
    useErrorToast(isDeactivatingError, {
        title: t.Admin.Users.DeactivateSentErrorTitle,
        message: t.Admin.Users.DeactivateSentErrorMessage,
    });
    useSuccessToast(isDeactivatingSuccess, {
        title: t.Admin.Users.DeactivateSentTitle,
        message: t.Admin.Users.DeactivateSentMessage,
    });
    useErrorToast(isTemporaryPasswordError, {
        title: t.Admin.Users.SendTemporaryPasswordErrorTitle,
        message: t.Admin.Users.SendTemporaryPasswordErrorDescription,
    });
    useSuccessToast(isTemporaryPasswordSuccess, {
        title: t.Admin.Users.SendTemporaryPasswordSuccessTitle,
        message: t.Admin.Users.SendTemporaryPasswordSuccessDescription,
    });
    useSuccessToast(isDeactivatingSuccess, {
        title: t.Admin.Users.DeactivateSentTitle,
        message: t.Admin.Users.DeactivateSentMessage,
    });
    var handleOpen = function (event) {
        event.stopPropagation();
        setAnchorEl(menuRef.current);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleInviteUser = function () {
        handleClose();
        onClickInviteUser(user);
    };
    var handleDeactivateUser = function () {
        handleClose();
        deactivateClinicUser({ staffId: user.id });
    };
    var handleSendTemporaryPassword = function () {
        sendTemporaryPassword({ staffId: user.id });
        handleCloseTemporaryPasswordModal();
    };
    var handleSendTemporaryPasswordModal = function () {
        handleClose();
        setShowTemporaryPasswordModal(true);
    };
    var handleCloseTemporaryPasswordModal = function () {
        setShowTemporaryPasswordModal(false);
    };
    var ActionButton = React.forwardRef(function (_, ref) {
        switch (user.status) {
            case CognitoStatus.Deactivated: {
                return (React.createElement(React.Fragment, null,
                    React.createElement(MenuItem, { innerRef: ref, key: "invite", onClick: handleInviteUser },
                        React.createElement("div", null, t.Admin.Users.InviteUserAction)),
                    React.createElement(MenuItem, { innerRef: ref, key: "temporaryPassword", onClick: handleSendTemporaryPasswordModal },
                        React.createElement("div", null, t.Admin.Users.SendTemporaryPassword))));
            }
            case CognitoStatus.Active: {
                return (React.createElement(React.Fragment, null,
                    React.createElement(MenuItem, { innerRef: ref, key: "deactivate", onClick: handleDeactivateUser },
                        React.createElement("div", null, t.Admin.Users.DeactivateUserAction)),
                    React.createElement(MenuItem, { innerRef: ref, key: "temporaryPassword", onClick: handleSendTemporaryPasswordModal },
                        React.createElement("div", null, t.Admin.Users.SendTemporaryPassword))));
            }
            default: {
                return null;
            }
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { acceptButtonText: t.Admin.Users.Send, cancelButtonText: t.Admin.Users.Cancel, contentText: t.Admin.Users.SendTemporaryPasswordModalText, onAccept: handleSendTemporaryPassword, onCancel: handleCloseTemporaryPasswordModal, isOpen: showTemporaryPasswordModal, titleText: t.Admin.Users.SendTemporaryPasswordModalTitle }),
        React.createElement("span", { onClick: handleOpen },
            React.createElement(IconButton, { ref: menuRef, "aria-controls": "simple-menu", "aria-haspopup": "true", size: "small", "data-testid": "admin-users-action-button" },
                React.createElement(MoreVert, null))),
        React.createElement(StyledMenu, { "data-testid": "admin-users-action-menu", id: "admin-users-action-menu", anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }, getContentAnchorEl: null },
            React.createElement(ActionButton, null))));
};
var ɵ0 = Actions;
export default Actions;
export { ɵ0 };
