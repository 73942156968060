import styled from '@emotion/styled';
import {
  Paper,
  Table,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';

export const StyledTable = styled(Table)`
  table-layout: fixed;
`;

export const StyledTableContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: #757575;
    vertical-align: top;

    &.idcol {
      width: 40px;
    }

    &.contentcol {
      width: 30%;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  td {
    vertical-align: top;

    &.truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const StyledTableFooter = styled(TableFooter)`
  td {
    border-bottom: 0;
  }
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  height: 400px;
`;
