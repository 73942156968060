import * as tslib_1 from "tslib";
import * as Sentry from '@sentry/browser';
import { useRef, useState } from 'react';
export function createExportDownloadHandler(request, onDownloadSuccess, onDownloadFailure) {
    var ref = useRef(null);
    var _a = useState(), fileUrl = _a[0], setFileUrl = _a[1];
    var _b = useState(), fileName = _b[0], setFileName = _b[1];
    function download(fileNameSuffix) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, blob, url, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, request()];
                    case 1:
                        data = (_a.sent()).data;
                        blob = new Blob([data.csv], { type: 'text/csv' });
                        url = URL.createObjectURL(blob);
                        setFileUrl(url);
                        setFileName(data.filename + " " + fileNameSuffix);
                        if (ref.current) {
                            ref.current.click();
                        }
                        else {
                            throw new Error('ref is required');
                        }
                        onDownloadSuccess();
                        URL.revokeObjectURL(url);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        Sentry.captureException(error_1);
                        onDownloadFailure();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return { download: download, fileUrl: fileUrl, ref: ref, fileName: fileName };
}
