import * as tslib_1 from "tslib";
import { LinkContentActionTypes, } from '../actions/link-content.actions';
export var initialState = {
    deletingLink: false,
    deletingLinks: false,
    loadingChildren: false,
    filterTargetContent: '',
    children: [],
    loadingAvailableContentToLinkTo: false,
    availableContentToLinkTo: [],
    creatingLink: false,
    errors: {},
    selectedContent: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case LinkContentActionTypes.FilterTargetLink: {
            return tslib_1.__assign({}, state, { filterTargetContent: action.payload.toLowerCase() });
        }
        case LinkContentActionTypes.GetAvailableToLinkContent: {
            return tslib_1.__assign({}, state, { loadingAvailableContentToLinkTo: true, errors: {} });
        }
        case LinkContentActionTypes.GetAvailableToLinkContentSuccess: {
            return tslib_1.__assign({}, state, { availableContentToLinkTo: action.payload, loadingAvailableContentToLinkTo: false, errors: tslib_1.__assign({}, state.errors, { availableContentToLinkTo: false }) });
        }
        case LinkContentActionTypes.GetAvailableToLinkContentError: {
            return tslib_1.__assign({}, state, { loadingAvailableContentToLinkTo: false, errors: {
                    availableContentToLinkTo: true,
                } });
        }
        case LinkContentActionTypes.LinkContent: {
            return tslib_1.__assign({}, state, { creatingLink: true, errors: {} });
        }
        case LinkContentActionTypes.LinkContentSuccess: {
            return tslib_1.__assign({}, state, { filterTargetContent: '', creatingLink: false, errors: {
                    creatingLink: false,
                } });
        }
        case LinkContentActionTypes.LinkContentError: {
            return tslib_1.__assign({}, state, { creatingLink: false, errors: {
                    creatingLink: true,
                } });
        }
        case LinkContentActionTypes.ClearErrors: {
            return tslib_1.__assign({}, state, { errors: {} });
        }
        case LinkContentActionTypes.LoadChildren: {
            return tslib_1.__assign({}, state, { loadingChildren: true, errors: {} });
        }
        case LinkContentActionTypes.LoadChildrenSuccess: {
            return tslib_1.__assign({}, state, { loadingChildren: false, children: action.payload, errors: {} });
        }
        case LinkContentActionTypes.LoadChildrenError: {
            return tslib_1.__assign({}, state, { loadingChildren: false, errors: {
                    loadingChildren: true,
                } });
        }
        case LinkContentActionTypes.ClearChildren: {
            return tslib_1.__assign({}, state, { children: [], errors: {} });
        }
        case LinkContentActionTypes.DeleteContentLink: {
            return tslib_1.__assign({}, state, { deletingLink: true, errors: {} });
        }
        case LinkContentActionTypes.DeleteContentLinkSuccess: {
            return tslib_1.__assign({}, state, { deletingLink: false, errors: {} });
        }
        case LinkContentActionTypes.DeleteContentLinkError: {
            return tslib_1.__assign({}, state, { deletingLink: false, errors: {
                    deletingLink: true,
                } });
        }
        case LinkContentActionTypes.DeleteAllContentLinks:
        case LinkContentActionTypes.DeleteSomeContentLinks: {
            return tslib_1.__assign({}, state, { deletingLinks: true, errors: {} });
        }
        case LinkContentActionTypes.DeleteAllContentLinksSuccess:
        case LinkContentActionTypes.DeleteSomeContentLinksSuccess: {
            return tslib_1.__assign({}, state, { deletingLinks: false, errors: {} });
        }
        case LinkContentActionTypes.DeleteAllContentLinksError:
        case LinkContentActionTypes.DeleteSomeContentLinksError: {
            return tslib_1.__assign({}, state, { deletingLinks: false, errors: {
                    deletingLinks: true,
                } });
        }
        case LinkContentActionTypes.SelectedContentForLinking: {
            return tslib_1.__assign({}, state, { selectedContent: action.payload });
        }
        default:
            return state;
    }
}
export var getTargetFilter = function (state) { return state.filterTargetContent; };
export var getAvailableToLinkContent = function (state) {
    return state.availableContentToLinkTo;
};
export var isLoadingAvailableToLinkContent = function (state) {
    return state.loadingAvailableContentToLinkTo;
};
export var isCreatingLink = function (state) { return state.creatingLink; };
export var getErrors = function (state) { return state.errors; };
export var getError = function (key) { return function (state) {
    var errors = getErrors(state);
    if (errors && errors.hasOwnProperty(key)) {
        return errors[key];
    }
}; };
export var getChildren = function (state) { return state.children; };
export var isDeletingLink = function (state) { return state.deletingLink; };
export var isDeletingLinks = function (state) { return state.deletingLinks; };
export var isLoadingChildren = function (state) { return state.loadingChildren; };
export var getSelectedContent = function (state) { return state.selectedContent; };
