<mat-card class="content-list-container">
  <div fxLayout="row" fxLayoutAlign="start stretch" fxFill>
    <portal-content-directory
      [text]="directoryText$ | async"
      [categories]="categories$ | async"
      [templates]="templates$ | async"
      [packs]="contentPacks$ | async"
      [activeSection]="routeType$ | async"
      [selectedId]="contentPackId$ | async"
      [activeDirectory]="openDirectory$ | async"
      [contentEditorEnabled]="contentEditorEnabled"
      (goToPage)="navigateTo($event)"
      (createFolder)="createNewFolder()"
      (editFolderName)="changeFolderName($event)"
      (openSection)="openDirectorySection($event)"
      (createPack)="createNewPack()"
      (editPackName)="editPackName($event)"
      [isGlobalPortal]="isGlobalPortal$ | async"
    >
    </portal-content-directory>
    <div fxFlex="grow" class="list-container">
      <portal-content-list-controls
        [text]="sectionText$ | async"
        [filterActive]="isFilterActive$ | async"
        [filter]="currentFilter$ | async"
        [contentType]="activePack$ | async"
        [categoryId]="contentPackId$ | async"
        [listType]="'pack-list'"
        [contentEditorEnabled]="contentEditorEnabled"
        (setFilter)="updateFilter($event)"
      >
      </portal-content-list-controls>
      <portal-pack-detail-table
        [text]="sectionText$ | async"
        [packDetailId]="contentPackId$ | async"
        [packDetail]="contentToDisplay$ | async"
        [currentPack]="currentPack$ | async"
        [filterActive]="isFilterActive$ | async"
        [loadingPackDetail]="loadingPackDetail$ | async"
        [contentEditorEnabled]="contentEditorEnabled"
        (goto)="goToContent($event)"
        (deletePack)="deletePack($event)"
        (removePackItems)="removeFromPack($event)"
      >
      </portal-pack-detail-table>
    </div>
  </div>
</mat-card>
