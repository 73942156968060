/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/forgot-password/forgot-password.component.ngfactory";
import * as i2 from "../../components/forgot-password/forgot-password.component";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./forgot-password.container";
import * as i6 from "@ngrx/store";
var styles_ForgotPasswordContainer = [];
var RenderType_ForgotPasswordContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotPasswordContainer, data: {} });
export { RenderType_ForgotPasswordContainer as RenderType_ForgotPasswordContainer };
export function View_ForgotPasswordContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "portal-forgot-password", [], null, [[null, "requestPasswordCode"], [null, "submitNewPassword"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("requestPasswordCode" === en)) {
        var pd_0 = (_co.requestNewPasswordCode($event) !== false);
        ad = (pd_0 && ad);
    } if (("submitNewPassword" === en)) {
        var pd_1 = (_co.submitNewPassword($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ForgotPasswordComponent_0, i1.RenderType_ForgotPasswordComponent)), i0.ɵdid(1, 49152, null, 0, i2.ForgotPasswordComponent, [i3.FormBuilder], { isCore: [0, "isCore"], text: [1, "text"], generatingCode: [2, "generatingCode"], resettingPassword: [3, "resettingPassword"], errorMessage: [4, "errorMessage"], clinicId: [5, "clinicId"], codeGenerated: [6, "codeGenerated"] }, { requestPasswordCode: "requestPasswordCode", submitNewPassword: "submitNewPassword" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isCoreClinic$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.authText$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.generatingCode$)); var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 5).transform(_co.resettingPassword$)); var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 6).transform(_co.error$)); var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 7).transform(_co.clinicId$)); var currVal_6 = i0.ɵunv(_v, 1, 6, i0.ɵnov(_v, 8).transform(_co.codeGenerated$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ForgotPasswordContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-forgot-password-container", [], null, null, null, View_ForgotPasswordContainer_0, RenderType_ForgotPasswordContainer)), i0.ɵdid(1, 49152, null, 0, i5.ForgotPasswordContainer, [i6.Store, i6.Store], null, null)], null, null); }
var ForgotPasswordContainerNgFactory = i0.ɵccf("portal-forgot-password-container", i5.ForgotPasswordContainer, View_ForgotPasswordContainer_Host_0, {}, {}, []);
export { ForgotPasswordContainerNgFactory as ForgotPasswordContainerNgFactory };
