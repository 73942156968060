import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Box as MuiBox, MenuItem as MuiMenuItem, MenuList as MuiMenuList, } from '@material-ui/core';
import { MailOutline as MailOutlineIcon, Warning as WarningIcon, } from '@material-ui/icons';
import { Container } from '@react/components/Container';
import Root from '@react/components/Root';
import React from 'react';
export var StyledRoot = styled(Root)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  width: 100%;\n  height: calc(100vh - 100px);\n  box-shadow: 0 0 4px 0 #999;\n  background-color: #f7f7fb;\n"], ["\n  width: 100%;\n  height: calc(100vh - 100px);\n  box-shadow: 0 0 4px 0 #999;\n  background-color: #f7f7fb;\n"])));
export var PageContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n"])));
export var ActionContainer = styled(MuiBox)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: sticky;\n  top: 0;\n  background-color: #f7f7fb;\n  z-index: 1;\n  padding: ", "px;\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: sticky;\n  top: 0;\n  background-color: #f7f7fb;\n  z-index: 1;\n  padding: ", "px;\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border.dark;
});
export var Main = styled.div(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  place-content: stretch flex-start;\n  align-items: stretch;\n  flex: 1 1 100%;\n  max-width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  place-content: stretch flex-start;\n  align-items: stretch;\n  flex: 1 1 100%;\n  max-width: 100%;\n"])));
export var LeftPanel = styled(MuiBox)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  min-width: 180px;\n  max-width: 240px;\n  overflow: hidden;\n  border-right: solid 1px #e5e5e7;\n\n  > .action-container {\n    height: 70px;\n  }\n\n  > .folders-container {\n    height: calc(100% - 70px);\n    overflow-y: scroll;\n  }\n"], ["\n  min-width: 180px;\n  max-width: 240px;\n  overflow: hidden;\n  border-right: solid 1px #e5e5e7;\n\n  > .action-container {\n    height: 70px;\n  }\n\n  > .folders-container {\n    height: calc(100% - 70px);\n    overflow-y: scroll;\n  }\n"])));
export var MiddlePanel = styled.div(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 550px;\n  height: 100%;\n  z-index: 10;\n  padding: ", "px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 550px;\n  height: 100%;\n  z-index: 10;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var ConversationsContainer = styled(Container)(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  padding: 0;\n  margin-top: ", "px;\n  border-radius: 5px;\n\n  .conversation-card {\n    border-bottom: 1px solid ", ";\n  }\n\n  .conversation-end-of-list-message {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 1rem;\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  padding: 0;\n  margin-top: ", "px;\n  border-radius: 5px;\n\n  .conversation-card {\n    border-bottom: 1px solid ", ";\n  }\n\n  .conversation-end-of-list-message {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 1rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1.5);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border.dark;
});
export var RightPanel = styled.div(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  height: 100%;\n  padding: ", ";\n\n  --approx-conversations-header-height: 148px;\n\n  .message-no-conversation-selected {\n    padding-top: var(--approx-conversations-header-height);\n  }\n"], ["\n  flex: 1;\n  height: 100%;\n  padding: ", ";\n\n  --approx-conversations-header-height: 148px;\n\n  .message-no-conversation-selected {\n    padding-top: var(--approx-conversations-header-height);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2, 2, 2, 0);
});
export var MenuList = styled(MuiMenuList)(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["\n  width: 100%;\n  padding: 0;\n"], ["\n  width: 100%;\n  padding: 0;\n"])));
export var MenuItem = styled(MuiMenuItem)(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  padding: ", "px;\n  justify-content: space-between;\n  align-items: baseline;\n  white-space: initial;\n  font-size: 0.9rem;\n  font-weight: ", ";\n\n  &:hover,\n  &[class*='-selected'],\n  &:hover[class*='-selected'] {\n    background-color: ", ";\n  }\n\n  &[class*='-selected'] > * {\n    font-weight: bold;\n  }\n\n  .count {\n    padding-left: ", "px;\n  }\n"], ["\n  display: flex;\n  padding: ", "px;\n  justify-content: space-between;\n  align-items: baseline;\n  white-space: initial;\n  font-size: 0.9rem;\n  font-weight: ", ";\n\n  &:hover,\n  &[class*='-selected'],\n  &:hover[class*='-selected'] {\n    background-color: ", ";\n  }\n\n  &[class*='-selected'] > * {\n    font-weight: bold;\n  }\n\n  .count {\n    padding-left: ", "px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var fontWeight = _a.fontWeight;
    return fontWeight || 'normal';
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryLight;
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var MailIcon = styled(MailOutlineIcon)(templateObject_11 || (templateObject_11 = tslib_1.__makeTemplateObject(["\n  color: ", ";\n  font-size: 86px;\n"], ["\n  color: ", ";\n  font-size: 86px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var StyledTypingIndicator = styled.div(templateObject_12 || (templateObject_12 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-style: italic;\n  color: ", ";\n\n  svg {\n    color: ", ";\n    margin-right: 0.5rem;\n    font-size: 1.25rem;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  font-style: italic;\n  color: ", ";\n\n  svg {\n    color: ", ";\n    margin-right: 0.5rem;\n    font-size: 1.25rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.greyishBrown;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.yellow;
});
export var TypingIndicator = function (_a) {
    var children = _a.children;
    return (React.createElement(StyledTypingIndicator, { className: "typing-indicator" },
        React.createElement(WarningIcon, null),
        " ",
        children));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
