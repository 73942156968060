import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portal-health-loading',
  templateUrl: './health-loading.component.html',
  styleUrls: ['./health-loading.component.scss'],
})
export class HealthLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
