import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  take,
} from 'rxjs/operators';
import { ContentService } from '../content.service';

export function validateContentName(
  contentService: ContentService,
  loading$: BehaviorSubject<boolean>,
  currentName: string,
): AsyncValidatorFn {
  return (
    control: AbstractControl,
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    // if name is the original value
    if (
      (control.value === currentName && currentName !== '') ||
      control.value.length > 50 ||
      control.value.length < 3
    ) {
      return of(null).pipe(take(1));
    } else {
      loading$.next(true);
      return contentService.checkContentName(control.value).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        take(1),
        map((entries) => {
          loading$.next(false);
          if (entries && entries.length > 0) {
            return { nameExists: true };
          } else if (entries.length === -1) {
            // Only occurs when service encounters an error
            return { serverNotConnected: true };
          } else {
            return null;
          }
        }),
        first(),
      );
    }
  };
}
