import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  DeleteVideoCallAction,
  EffectsCompletedAction,
  InvitePartnerInCallToVideoCallAction,
  InvitePartnerToVideoCallAction,
  JoinVideoCallAction,
  UnInvitePartnerInCallToVideoCallAction,
  UnInvitePartnerToVideoCallAction,
  UpdateVideoCallAction,
  VideoCallActionTypes
} from '../actions/video-call.actions';
import { TabsState } from '../models/TabsState';
import * as fromVideoCalls from '../reducers';
import { VideoCallEffectsResposeType } from '../responses';
import { VideoCallsService } from '../services/video-calls.service';

const checkForNoPartnerError = (error) =>
  error && error.error && error.error.code === 'NOLINKEDPARTNER';
@Injectable()
export class VideoCallEffects {
  @Effect()
  updateVideoCall$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<UpdateVideoCallAction>(VideoCallActionTypes.UpdateVideoCall),
      switchMap((action) => {
        return this._videoCallsService.updateVideoCall(action.payload).pipe(
          map((response) => {
            return new EffectsCompletedAction({
              type: VideoCallEffectsResposeType.UpdateSuccess,
              data: {
                requestData: action.payload,
                responseData: response.data
              },
              error: null
            });
          }),
          catchError((error) => {
            return of(
              new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.UpdateError,
                data: null,
                error
              })
            );
          })
        );
      })
    );
  }

  @Effect()
  deleteVideoCall() {
    return this.actions$.pipe(
      ofType<DeleteVideoCallAction>(VideoCallActionTypes.DeleteVideoCall),
      switchMap((action) => {
        return this._videoCallsService.deleteVideoCall(action.payload.id).pipe(
          map((response) => {
            return new EffectsCompletedAction({
              type: VideoCallEffectsResposeType.DeleteSuccess,
              data: response.data,
              error: null
            });
          }),
          catchError((error) => {
            return of(
              new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.DeleteError,
                data: null,
                error
              })
            );
          })
        );
      })
    );
  }

  @Effect()
  joinVideoCall() {
    return this.actions$.pipe(
      ofType<JoinVideoCallAction>(VideoCallActionTypes.Join),
      switchMap((action) => {
        return this._videoCallsService
          .joinVideoCall(action.payload.videoCall.id)
          .pipe(
            map((response) => {
              return new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.JoinSuccess,
                data: {
                  videoCall: action.payload.videoCall,
                  partnerInviteEnabled: action.payload.partnerInviteEnabled,
                  videoCallsInvitePartner:
                    action.payload.videoCallsInvitePartner,
                  response: response.data
                },
                error: null
              });
            }),
            catchError((error) => {
              return of(
                new EffectsCompletedAction({
                  type: VideoCallEffectsResposeType.JoinError,
                  data: null,
                  error
                })
              );
            })
          );
      })
    );
  }

  @Effect()
  invitePartnerToVideoCall$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<InvitePartnerToVideoCallAction>(
        VideoCallActionTypes.InvitePartnerToVideoCall
      ),
      switchMap((action) => {
        return this._videoCallsService.updateVideoCall(action.payload).pipe(
          map(() => {
            return new EffectsCompletedAction({
              type: VideoCallEffectsResposeType.InviteSuccess,
              data: {
                requestData: action.payload
              },
              error: null
            });
          }),
          catchError((error) => {
            if (checkForNoPartnerError(error)) {
              return of(
                new EffectsCompletedAction({
                  type: VideoCallEffectsResposeType.NoPartnerFoundError,
                  data: null,
                  error
                })
              );
            }
            return of(
              new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.InviteError,
                data: null,
                error
              })
            );
          })
        );
      })
    );
  }

  @Effect()
  unInvitePartnerToVideoCall$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<UnInvitePartnerToVideoCallAction>(
        VideoCallActionTypes.UnInvitePartnerToVideoCall
      ),
      switchMap((action) => {
        return this._videoCallsService.updateVideoCall(action.payload).pipe(
          map(() => {
            return new EffectsCompletedAction({
              type: VideoCallEffectsResposeType.UnInviteSuccess,
              data: {
                requestData: action.payload
              },
              error: null
            });
          }),
          catchError((error) => {
            if (checkForNoPartnerError(error)) {
              return of(
                new EffectsCompletedAction({
                  type: VideoCallEffectsResposeType.NoPartnerFoundError,
                  data: null,
                  error
                })
              );
            }
            if (error && error.error && error.error.code === 'CALLACTIVE') {
              return of(
                new EffectsCompletedAction({
                  type: VideoCallEffectsResposeType.UnInviteActiveCallError,
                  data: null,
                  error
                })
              );
            }
            return of(
              new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.UnInviteError,
                data: null,
                error
              })
            );
          })
        );
      })
    );
  }

  @Effect()
  invitePartnerInCallToVideoCall$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<InvitePartnerInCallToVideoCallAction>(
        VideoCallActionTypes.InvitePartnerInCallToVideoCall
      ),
      switchMap((action) => {
        return this._videoCallsService.updateVideoCall(action.payload).pipe(
          map(() => {
            return new EffectsCompletedAction({
              type: VideoCallEffectsResposeType.InviteInCallSuccess,
              data: {
                requestData: action.payload
              },
              error: null
            });
          }),
          catchError((error) => {
            if (checkForNoPartnerError(error)) {
              return of(
                new EffectsCompletedAction({
                  type: VideoCallEffectsResposeType.NoPartnerFoundError,
                  data: null,
                  error
                })
              );
            }
            return of(
              new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.InviteInCallError,
                data: null,
                error
              })
            );
          })
        );
      })
    );
  }

  @Effect()
  unInvitePartnerInCallToVideoCall$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<UnInvitePartnerInCallToVideoCallAction>(
        VideoCallActionTypes.UnInvitePartnerInCallToVideoCall
      ),
      switchMap((action) => {
        return this._videoCallsService.updateVideoCall(action.payload).pipe(
          map(() => {
            return new EffectsCompletedAction({
              type: VideoCallEffectsResposeType.UnInviteInCallSuccess,
              data: {
                requestData: action.payload
              },
              error: null
            });
          }),
          catchError((error) => {
            if (checkForNoPartnerError(error)) {
              return of(
                new EffectsCompletedAction({
                  type: VideoCallEffectsResposeType.NoPartnerFoundError,
                  data: null,
                  error
                })
              );
            }
            return of(
              new EffectsCompletedAction({
                type: VideoCallEffectsResposeType.UnInviteInCallError,
                data: null,
                error
              })
            );
          })
        );
      })
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store<fromVideoCalls.AppState>,
    private _videoCallsService: VideoCallsService,
    private _error: ErrorHandler
  ) {}
}
