import {
  DashboardActionsUnion,
  DashboardActionTypes,
} from '../actions/dashboard.actions';
import { ClinicStatusResponse } from '../models/responses/clinic-status.response';

export interface State {
  status: ClinicStatusResponse;
}

export const initialState: State = {
  status: {
    integrated: false,
    synchronised: false,
  },
};

export function reducer(
  state = initialState,
  action: DashboardActionsUnion,
): State {
  switch (action.type) {
    case DashboardActionTypes.LoadClinicStatusSuccess: {
      return {
        ...state,
        status: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getStatus = (state: State) => state.status;
