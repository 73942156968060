var MarkMessageResolvedRequest = /** @class */ (function () {
    function MarkMessageResolvedRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId;
        this.Action = 'MarkMessageResolved';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
    }
    return MarkMessageResolvedRequest;
}());
export { MarkMessageResolvedRequest };
