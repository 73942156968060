import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent } from 'react';
import {
  StyledCard,
  StyledNav,
  StyledNavLink,
  StyledNavList,
} from './Nav.styled';

const Nav: FunctionComponent = () => {
  const { t } = useTranslations();

  return (
    <StyledCard>
      <StyledNav>
        <StyledNavList>
          <li>
            {/* Property 'to' does not exist on type
            // @ts-ignore */}
            <StyledNavLink
              activeClassName="active"
              to="/superadmin/data-exports"
            >
              {t.SuperAdmin.DataExport.Title}
            </StyledNavLink>
          </li>
          <li>
            {/* Property 'to' does not exist on type
            // @ts-ignore */}
            <StyledNavLink
              activeClassName="active"
              to="/superadmin/form-lookup"
            >
              Patient Forms Lookup
            </StyledNavLink>
          </li>
        </StyledNavList>
      </StyledNav>
    </StyledCard>
  );
};

export default Nav;
