import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { LoadingError } from '@react/components/LoadingError';
import { useTranslations } from '@react/lib/i18n';
import { NoPermissionIcon } from '@react/pages/admin/components/NoPermissionIcon';
import get from 'lodash/get';
import React from 'react';

export interface Props {
  url?: string;
  text: string;
  container: boolean;
}
export const Unauthorised: React.FC<Props> = ({ url, text, container }) => {
  const { t } = useTranslations();
  const buttonText = get(t, text);
  return (
    <Wrapper container={container}>
      <PageContainer>
        <LoadingErrorContainer>
          <LoadingError
            icon={<NoPermissionIcon />}
            message={t.Admin.NoAccessMessage}
          >
            <Button color="primary" href={url} variant="contained">
              {buttonText}
            </Button>
          </LoadingError>
        </LoadingErrorContainer>
      </PageContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ container: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ container }) => (container ? '100%' : 'auto')};
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const LoadingErrorContainer = styled.div`
  flex: 1;
`;
