<div
  class="content-edit-sections-container"
  [formGroup]="quillSectionsForm"
  fxLayout="column"
  fxLayoutGap="15px"
  fxLayoutAlign="start stretch"
>
  <div
    (drop)="dropped()"
    ngxDroppable
    [model]="sections.controls"
    class="sections"
    fxLayout="column"
    fxLayoutGap="15px"
  >
    <div
      ngxDraggable
      formArrayName="sections"
      *ngFor="let section of sections.controls; let i = index"
      [model]="section"
      [moves]="contentEditorEnabled"
    >
      <div [formGroupName]="i">
        <div class="section-entry" fxLayout="row" fxLayoutGap="15px">
          <div fxLayout="column" fxLayoutAlign="start center">
            <img
              ngxDragHandle
              class="handle"
              src="assets/imgs/drag-and-drop.png"
            />
          </div>
          <div
            fxFlex="grow"
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="10px"
          >
            <mat-form-field appearance="outline">
              <mat-label>{{ text.SectionTitle }}</mat-label>
              <input
                matInput
                [errorStateMatcher]="matcher"
                class="entry-section-title"
                type="text"
                placeholder="{{ text.AddSectionTitle }}"
                id="{{ 'titleId' + i }}"
                formControlName="title"
              />
              <mat-hint align="start">
                <strong>{{ 50 - section.get('title').value.length }}</strong>
                {{ text.CharactersRemaining }}
              </mat-hint>
              <mat-error *ngIf="section.get('title').hasError('maxlength')">
                {{ text.MaxTitleLength }}
              </mat-error>
            </mat-form-field>
            <quill-editor
              id="{{ 'section' + i }}"
              [style]="{ height: '300px' }"
              [placeholder]="text.InsertText"
              formControlName="content"
              [modules]="editorConfig"
              (onEditorCreated)="editorCreated($event)"
              (click)="setFocus($event)"
              [readOnly]="!contentEditorEnabled"
            ></quill-editor>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center">
            <mat-icon
              class="remove-section"
              (click)="contentEditorEnabled && handleRemoveSection(section, i)"
              >close</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="add-section-button">
    <button
      mat-raised-button
      type="button"
      class="portal-btn btn-upload"
      (click)="addSection.emit()"
      [disabled]="!contentEditorEnabled"
    >
      {{ text.AddSection }}
    </button>
  </div>
</div>
