export var MedicationsActionTypes;
(function (MedicationsActionTypes) {
    MedicationsActionTypes["LoadPotentialDuplicatesError"] = "[Medications] Load Potential Duplicates Medication Error";
    MedicationsActionTypes["LoadActionMedicationsError"] = "[Medications] Load Action Required Medications Error";
    MedicationsActionTypes["LoadNoActionMedicationsError"] = "[Medications] Load No Action Required Medications Error";
})(MedicationsActionTypes || (MedicationsActionTypes = {}));
var LoadPotentialDuplicatesError = /** @class */ (function () {
    function LoadPotentialDuplicatesError(payload) {
        this.payload = payload;
        this.type = MedicationsActionTypes.LoadPotentialDuplicatesError;
    }
    return LoadPotentialDuplicatesError;
}());
export { LoadPotentialDuplicatesError };
var LoadActionMedicationsError = /** @class */ (function () {
    function LoadActionMedicationsError(payload) {
        this.payload = payload;
        this.type = MedicationsActionTypes.LoadActionMedicationsError;
    }
    return LoadActionMedicationsError;
}());
export { LoadActionMedicationsError };
var LoadNoActionMedicationsError = /** @class */ (function () {
    function LoadNoActionMedicationsError(payload) {
        this.payload = payload;
        this.type = MedicationsActionTypes.LoadNoActionMedicationsError;
    }
    return LoadNoActionMedicationsError;
}());
export { LoadNoActionMedicationsError };
