import debounce from 'lodash/debounce';
import prettyBytes from 'pretty-bytes';
import { useContext, useRef } from 'react';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useLocation } from 'react-router';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { LanguageContext } from 'src/app/react/lib/i18n';
import { environment } from 'src/environments/environment';
import { useDispatch } from '../store';

export function useDebouncedState<T>(outerState: T, delay: number) {
  const [value, setValue] = useState(outerState);

  const debouncedSetValue = useMemo(() => debounce(setValue, delay), [delay]);

  useEffect(() => {
    debouncedSetValue(outerState);
  }, [outerState]);

  // Make sure any pending debounce operation is cancelled
  // Note: this is separate as the above `useEffect` would
  // call its cleanup function when `outerState` changes at all
  useEffect(() => {
    return debouncedSetValue.cancel;
  }, []);

  return value;
}

export function useErrorToast(
  isError: boolean,
  { title, message }: { title: string; message: string },
  timeout = 5000,
) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      dispatch(
        new DisplayToastAction({
          title,
          message,
          timeout,
          type: 'error',
        }),
      );
    }
  }, [isError]);
}

export function useSuccessToast(
  isSuccess: boolean,
  { title, message }: { title: string; message: string },
) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        new DisplayToastAction({
          title,
          message,
          timeout: 5000,
          type: 'success',
        }),
      );
    }
  }, [isSuccess]);
}

export function useQuery<T extends Record<string, string>>() {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const query: Partial<T> = {};

    searchParams.forEach((value, key) => (query[key] = value));

    return query;
  }, [search]);
}

export function useBytesFormatter() {
  const { language } = useContext(LanguageContext);

  return (value: number) =>
    prettyBytes(value, {
      locale: language,
    });
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useEnvVariables() {
  return environment;
}
