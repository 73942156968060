import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { de as deLocale, enGB as enLocale, enUS as usLocale, es as esLocale, } from 'date-fns/locale';
var dateLocales = {
    'en-gb': enLocale,
    'en-us': usLocale,
    de: deLocale,
    es: esLocale,
};
var LocalisationService = /** @class */ (function () {
    function LocalisationService() {
    }
    LocalisationService.prototype.setLocale = function (languageCode) {
        this._languageCode = languageCode;
        this._locale = dateLocales[languageCode];
    };
    LocalisationService.prototype.getTimeZone = function () {
        return this._timeZone;
    };
    LocalisationService.prototype.setTimeZone = function (timeZone) {
        this._timeZone = timeZone;
    };
    LocalisationService.prototype.getLanguageCode = function () {
        return this._languageCode;
    };
    LocalisationService.prototype.parseDate = function (date) {
        return date instanceof Date ? date : dateFns.parseISO(date);
    };
    LocalisationService.prototype.getShortDate = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'LLL dd yyyy', {
                    locale: this._locale,
                });
            }
        }
        return dateFns.format(this.parseDate(from), 'dd LLL yyyy', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getShortDateWithTime = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'LLL dd yyyy - h:mm a', {
                    locale: this._locale,
                });
            }
        }
        return dateFns.format(this.parseDate(from), 'dd LLL yyyy - HH:mm', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getLongDate = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'iiii LLL dd yyyy', {
                    locale: this._locale,
                });
            }
        }
        return dateFns.format(this.parseDate(from), 'iiii dd LLL yyyy', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getLongDateWithTime = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'iiii LLL dd yyyy - HH:mm', { locale: this._locale });
            }
        }
        return dateFns.format(this.parseDate(from), 'iiii dd LLL yyyy - HH:mm', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getFullDate = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'iiii LLLL dd yyyy', {
                    locale: this._locale,
                });
            }
        }
        return dateFns.format(this.parseDate(from), 'iiii dd LLLL yyyy', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getMidDateWithTime = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'iii LLL dd yyyy - HH:mm', {
                    locale: this._locale,
                });
            }
        }
        return dateFns.format(this.parseDate(from), 'iii dd LLL yyyy - HH:mm', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getShortNumericDate = function (from) {
        if (!from) {
            return '';
        }
        switch (this._languageCode) {
            case 'en-us': {
                return dateFns.format(this.parseDate(from), 'LL/dd/yyyy', {
                    locale: this._locale,
                });
            }
        }
        return dateFns.format(this.parseDate(from), 'dd/LL/yyyy', {
            locale: this._locale,
        });
    };
    LocalisationService.prototype.getOnlyTime = function (from) {
        if (!from) {
            return '';
        }
        return dateFns.format(this.parseDate(from), 'HH:mm', {
            locale: this._locale,
        });
    };
    /**
     * Takes an ISO8601 string or `Date` and formats to the
     * current locales date format in the current time zone.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
     */
    LocalisationService.prototype.formatDate = function (from, style) {
        if (style === void 0) { style = 'short'; }
        return new Date(from).toLocaleDateString(this._languageCode, {
            // @ts-ignore
            dateStyle: style,
            timeZone: this._timeZone,
        });
    };
    /**
     * Takes an ISO8601 string or `Date` and formats to the
     * current locales date format in the UTC time zone.
     *
     * This is useful when wanting to show a DOB, for example,
     * where it should be the same date whatever the timezone.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
     */
    LocalisationService.prototype.formatUTCDate = function (from, style) {
        if (style === void 0) { style = 'short'; }
        return new Date(from).toLocaleDateString(this._languageCode, {
            // @ts-ignore
            dateStyle: style,
            timeZone: 'UTC',
        });
    };
    /**
     * Takes an ISO8601 string or `Date` and formats to the
     * current locales time format in the current time zone.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
     */
    LocalisationService.prototype.formatTime = function (from, style) {
        if (style === void 0) { style = 'short'; }
        return new Date(from).toLocaleTimeString(this._languageCode, {
            // @ts-ignore
            timeStyle: style,
            timeZone: this._timeZone,
        });
    };
    /**
     * Takes an ISO8601 string or `Date` and formats to the
     * current locales date and time format in the current time zone.
     *
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
     */
    LocalisationService.prototype.formatDateTime = function (from, dateStyle, timeStyle) {
        if (dateStyle === void 0) { dateStyle = 'short'; }
        if (timeStyle === void 0) { timeStyle = 'short'; }
        return new Date(from).toLocaleString(this._languageCode, {
            // @ts-ignore
            dateStyle: dateStyle,
            timeStyle: timeStyle,
            timeZone: this._timeZone,
        });
    };
    /**
     * Returns a format suitable for matching against user input when using DOBs.
     *
     * For example, 30112000, 11302000, etc.
     */
    LocalisationService.prototype.getShortDateDigitsOnly = function (from) {
        return new Date(from)
            .toLocaleDateString(this._languageCode, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        })
            .replace(/[^0-9]+/g, '');
    };
    LocalisationService.prototype.getZonedToday = function () {
        return dateFnsTz.toDate(dateFnsTz.formatInTimeZone(new Date(), this.getTimeZone(), 'yyyy-MM-dd HH:mm:ss'));
    };
    LocalisationService.prototype.toZonedDate = function (date) {
        return dateFnsTz.utcToZonedTime(date, this.getTimeZone());
    };
    LocalisationService.prototype.toUTCDate = function (date) {
        return dateFnsTz.zonedTimeToUtc(date, this.getTimeZone());
    };
    return LocalisationService;
}());
export { LocalisationService };
