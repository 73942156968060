import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StaffProfiles } from '@react/lib/api/types/clinicUsers';
import { ConversationPage } from '@react/pages/conversations';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { User } from 'src/app/auth/models/user';
import { FileUpload } from 'src/app/core/services/s3.service';
import { TranslationData } from 'src/app/settings/data/settings';
import * as fromAuth from '../../auth/reducers';
import { CognitoWrapperService } from '../../core/services/congito.wrapper.service';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';

const containerElementName = 'portalConversationResolver';

@Component({
  selector: 'portal-conversation-component',
  template: `<div
    #${containerElementName}
    class="conversation react-wrapper"
  ></div>`,
  styleUrls: ['./conversation-resolver.component.scss'],
})
export class ConversationResolverComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(containerElementName) containerRef!: ElementRef;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
    conversationCache: Record<number, string>;
    staffProfiles: StaffProfiles;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _fileUpload: FileUpload,
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
        this._store.pipe(select(fromRoot.getConversationCache)),
        this._store.pipe(select(fromRoot.getStaffProfiles)),
      ]).subscribe(
        ([
          clinicToken,
          clinicOptions,
          user,
          language,
          translations,
          conversationCache,
          staffProfiles,
        ]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
            conversationCache,
            staffProfiles,
          };

          this.render();
        },
      ),
    );
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    this._subscriptions.unsubscribe();
  }

  private async render() {
    ReactDOM.render(
      React.createElement(ConversationPage, {
        ...this._props,
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        fileUploadService: this._fileUpload,
      }),
      this.containerRef.nativeElement,
    );
  }
}
