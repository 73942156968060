type AppointmentTypeAppointmentTypes = 'video-call';
export interface AppointmentType {
  id: number;
  internalsid: string;
  name?: string;
  active?: boolean;
  displaytopatient?: boolean;
  salvecreatedutc?: string;
  embargo?: boolean;
  hasstatusfilters: boolean;
  appointmenttype?: AppointmentTypeAppointmentTypes;
}

export interface AppointmentTypeDetails {
  id: number;
  internalsid: string;
  name?: string;
  active: boolean;
  displaytopatient: boolean;
  embargo: boolean;
  embargoperiod?: number;
  embargoperiodname?: string;
  salvecreatedutc: string;
  statusfilters: string[];
  appointmenttype?: string;
}

export interface AppointmentTypeListResponse {
  data: AppointmentType[];
}

export interface AppointmentTypeDetailsResponse {
  data: AppointmentTypeDetails;
}

interface BaseUpdateAppointmentTypeRequest {
  id: number;
  active?: boolean;
  displaytopatient?: boolean;
  video?: boolean;
}

export type UpdateAppointmentTypeRequest =
  | (BaseUpdateAppointmentTypeRequest & { embargo: false })
  | (BaseUpdateAppointmentTypeRequest & {
      embargo: true;
      embargoperiod: number;
      embargoperiodname: 'Hour';
    });

export interface UpdateAppointmentTypeResponse {
  data: AppointmentTypeDetails;
}

export interface MessageTemplateResponse {
  data: MessageTemplate;
}

export interface MessageTemplatesListResponse {
  data: MessageTemplate[];
}

export interface CreateMessageTemplateRequest {
  content: string;
  name: string;
}

export interface CreateMessageTemplateResponse {
  data: MessageTemplate;
}

export type DeleteMessageTemplateResponse = void;

export interface UpdateMessageTemplateRequest {
  id: number;
  content: string;
  name: string;
}

export interface UpdateMessageTemplateResponse {
  data: MessageTemplate;
}

export interface GetMessageTemplateMessageRequest {
  id: number;
  patientId: number;
}

export interface GetMessageTemplateMessageResponse {
  data: { message: string };
}

export interface MessageTemplate {
  id: number;
  clinicId: number;
  content: string;
  createdAt: string;
  createdBy: {
    id: number;
    name: string;
  };
  modifiedAt: string;
  modifiedBy: {
    id: number;
    name: string;
  };
  name: string;
}

export interface GetMessageTemplateVariablesResponse {
  data: string[];
}

export interface IListResponse<T> {
  data: T[];
}

export interface IResponse<T> {
  data: T;
}

export interface CUResponse {
  id: number;
  status: string;
}

export interface Pagination {
  pageNumber?: number;
  pageSize?: number;
}

export interface ResubmittablePatientForms {
  id: number;
  formversion: number;
  version: number;
  salvecreated: Date;
}

export interface ResubmittablePatientLookupForm {
  id: number;
  name: string;
  assignedon: Date;
  lastmodified: Date;
  issubmitted: boolean;
  status: string;
  progress: string;
}

export * from './conversations';
export * from './side';
export * from './medications';
export * from './patients';
export * from './clinicUsers';
