import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import React from 'react';

type Props = React.ComponentProps<typeof Paper> & {
  sticky?: boolean;
  shadow?: string;
};

const stickyStyles = () => css`
  position: sticky;
  top: 0;
  z-index: 30;
`;

const shadowStyles = () => css`
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
`;

export const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'sticky',
})<Props>`
  align-items: center;
  display: flex;
  height: 42px;
  border-radius: 5px;
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
  ${({ sticky }) => sticky && stickyStyles}
  ${({ shadow }) => shadow && shadowStyles}

  svg {
    font-size: 1.2rem;
  }
`;
