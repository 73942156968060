import { EventEmitter, } from '@angular/core';
var ContentDirectoryComponent = /** @class */ (function () {
    function ContentDirectoryComponent() {
        this.packs = [];
        this.activeDirectory = '';
        this.goToPage = new EventEmitter(); // trigger navigation
        this.addNew = new EventEmitter(); // create new template or category type
        this.createFolder = new EventEmitter();
        this.editFolderName = new EventEmitter();
        this.createPack = new EventEmitter();
        this.editPackName = new EventEmitter();
        this.openSection = new EventEmitter();
        this.directoryOptions = {
            folders: 'folders',
            templates: 'templates',
            packs: 'packs',
        };
        this.sectionOptions = {
            list: 'list',
            packs: 'packs',
            template: 'template',
        };
        this.config = {};
    }
    ContentDirectoryComponent.prototype.goToContentPack = function (contentPack) {
        this.goToPage.emit("/content/pack/" + contentPack.id);
    };
    ContentDirectoryComponent.prototype.goToCategoryList = function (category) {
        this.goToPage.emit("/content/list/" + category.id);
    };
    ContentDirectoryComponent.prototype.goToTemplateEdit = function (template) {
        this.goToPage.emit("/content/template/edit/" + template.id);
    };
    ContentDirectoryComponent.prototype.goToCreateTemplate = function () {
        this.goToPage.emit('/content/template/create');
    };
    ContentDirectoryComponent.prototype.openDirectorySection = function (sectionToOpen) {
        this.openSection.emit(sectionToOpen);
    };
    return ContentDirectoryComponent;
}());
export { ContentDirectoryComponent };
