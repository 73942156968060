import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import {
  ContentActionTypes,
  CreateTemplate,
  CreateTemplateError,
  CreateTemplateSuccess,
  OpenDirectorySection,
} from '../../actions/content.actions';
import { CreateCategoryComponent } from '../../components/create-category/create-category.component';
import { CreatePackComponent } from '../../components/create-pack/create-pack.component';
import { EditFolderNameComponent } from '../../components/edit-folder-name/edit-folder-name.component';
import { Category } from '../../models/category';
import { ContentPack } from '../../models/content-packs';
import {
  ContentTemplate,
  NewContentTemplate,
} from '../../models/content-template';
import * as fromContent from '../../reducers';
import { EditPackNameComponent } from './../../components/edit-pack-name/edit-pack-name.component';

@Component({
  selector: 'portal-template-new',
  templateUrl: './template-new.component.html',
  styleUrls: ['./template-new.component.scss'],
})
export class TemplateNewComponent implements OnInit, OnDestroy {
  // Obervables for Text / Translations
  public directoryText$: Observable<any>;
  public templateEditText$: Observable<any>;
  public contentText$: Observable<any>;
  public categoryText$: Observable<any>;

  public creatingTemplate$ = new BehaviorSubject<boolean>(false);
  public creatingTemplateError$ = new BehaviorSubject<boolean>(false);

  public contentText: any;
  public categoryText: any;

  // Observables used in content-directory
  public categories$: Observable<Category[]>;
  public templates$: Observable<ContentTemplate[]>;
  public packs$: Observable<ContentPack[]> = of([]);
  public categoryId$: Observable<number> = of(0);
  public routeType$: Observable<string> = of('template');

  // Observable to hand to template form
  public selectedTemplate$: Observable<NewContentTemplate>;
  public currentUser$: Observable<User>;

  public openDirectory$: Observable<string>;

  // Content editor flag
  public contentEditorEnabled$: Observable<boolean>;
  public contentEditorEnabled: boolean;

  // Global Portal flag
  public isGlobalPortal$: Observable<boolean>;
  public isGlobalPortal: boolean;

  private _subs = new Subscription();

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _updates$: Actions,
    private _store: Store<fromRoot.State>,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    // Global Portal Observable
    this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));

    // Content editor group Observables
    this.contentEditorEnabled$ = this._store.pipe(
      select(fromContent.getContentEditorEnabled),
    );
    // set up text observables
    this.directoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentDirectory')),
    );
    this.contentText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewContentList')),
    );
    this.templateEditText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateTemplate')),
    );
    this.categoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateCategory')),
    );

    this._subs.add(
      this.isGlobalPortal$.subscribe((value) => {
        this.isGlobalPortal = value;
      }),
    );

    // Content editor group, wrapped in a feature flag
    this._subs.add(
      this.contentEditorEnabled$.subscribe(
        (t) => (this.contentEditorEnabled = t),
      ),
    );

    // capture text for use in dialogs
    this._subs.add(this.contentText$.subscribe((t) => (this.contentText = t)));
    this._subs.add(
      this.categoryText$.subscribe((t) => (this.categoryText = t)),
    );

    // content-directory observables from Store and active route
    this.categories$ = this._store.pipe(
      select(fromContent.getGeneralCategories),
    );
    this.templates$ = this._store.pipe(select(fromContent.getTemplates));
    this.packs$ = this._store.pipe(select(fromContent.getPacks));
    this.categoryId$ = this._route.params.pipe(
      map((params: Params) => {
        if (params.id) {
          return +params.id;
        } else {
          return 0;
        }
      }),
    );

    // template to hand to template form
    this.currentUser$ = this._store.pipe(select(fromAuth.getUser));
    this.selectedTemplate$ = this.currentUser$.pipe(
      map(() => {
        return new NewContentTemplate('', true);
      }),
    );

    // watch for successful creation of template to reroute to edit screen
    this._subs.add(
      this._updates$
        .pipe(
          ofType<CreateTemplateSuccess>(
            ContentActionTypes.CreateTemplateSuccess,
          ),
          take(1),
          map((action) => {
            this.creatingTemplate$.next(false);
            const id = action.payload.id;
            this.navigateTo(`content/template/edit/${id}`);
          }),
        )
        .subscribe(),
    );

    // watch for error creating template
    this._subs.add(
      this._updates$
        .pipe(
          ofType<CreateTemplateError>(ContentActionTypes.CreateTemplateError),
          take(1),
          map(() => {
            this.creatingTemplate$.next(false);
            this.creatingTemplateError$.next(true);
          }),
        )
        .subscribe(),
    );

    // Which section of directory is open?
    this.openDirectory$ = this._store.pipe(
      select(fromContent.activeDirectorySection),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  createTemplate(template: ContentTemplate): void {
    this.creatingTemplate$.next(true);
    this._store.dispatch(new CreateTemplate(template));
  }

  // Functions to manage content directory
  navigateTo(url: string): void {
    this._navigationService.navigateByUrl(url);
  }

  openDirectorySection(section: string) {
    this._store.dispatch(new OpenDirectorySection(section));
  }

  public createNewFolder() {
    this.dialog.open(CreateCategoryComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public changeFolderName(category: Category) {
    this.dialog.open(EditFolderNameComponent, {
      data: {
        category,
        text: this.contentText,
      },
      width: '550px',
    });
  }

  public createNewPack() {
    this.dialog.open(CreatePackComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public editPackName(pack: ContentPack) {
    this.dialog.open(EditPackNameComponent, {
      data: {
        pack,
        text: this.contentText,
      },
      width: '550px',
    });
  }
}
