export var ContentPackActionTypes;
(function (ContentPackActionTypes) {
    ContentPackActionTypes["GetContentPacks"] = "[Content-Packs] Get Content Packs";
    ContentPackActionTypes["GetContentPacksSuccess"] = "[Content-Packs] Get Content Packs Success";
    ContentPackActionTypes["GetContentPacksError"] = "[Content-Packs] Get Content Packs Error";
    ContentPackActionTypes["GetContentPackDetail"] = "[Content-Packs] Get Content Pack Detail";
    ContentPackActionTypes["GetContentPackDetailSuccess"] = "[Content-Packs] Get Content Pack Detail Success";
    ContentPackActionTypes["GetContentPackDetailError"] = "[Content-Packs] Get Content Pack Detail Error";
    ContentPackActionTypes["CreateContentPack"] = "[Content-Packs] Create Content Pack";
    ContentPackActionTypes["CreateContentPackSuccess"] = "[Content-Packs] Create Content Pack Success";
    ContentPackActionTypes["CreateContentPackError"] = "[Content-Packs] Create Content Pack Error";
    ContentPackActionTypes["EditContentPack"] = "[Content-Packs] Edit Content Pack";
    ContentPackActionTypes["EditContentPackSuccess"] = "[Content-Packs] Edit Content Pack Success";
    ContentPackActionTypes["EditContentPackError"] = "[Content-Packs] Edit Content Pack Error";
    ContentPackActionTypes["DeleteContentPack"] = "[Content-Packs] Delete Content Pack";
    ContentPackActionTypes["DeleteContentPackSuccess"] = "[Content-Packs] Delete Content Pack Success";
    ContentPackActionTypes["DeleteContentPackError"] = "[Content-Packs] Delete Content Pack Error";
    ContentPackActionTypes["AddToContentPack"] = "[Content-Packs] Add To Content Pack";
    ContentPackActionTypes["AddToContentPackSuccess"] = "[Content-Packs] Add To Content Pack Success";
    ContentPackActionTypes["AddToContentPackError"] = "[Content-Packs] Add To Content Pack Error";
    ContentPackActionTypes["AddToMultipleContentPacks"] = "[Content-Packs] Add To Multiple Content Packs";
    ContentPackActionTypes["AddToMultipleContentPacksSuccess"] = "[Content-Packs] Add To Multiple Content Packs Success";
    ContentPackActionTypes["AddToMultipleContentPacksError"] = "[Content-Packs] Add To Multiple Content Packs Error";
    ContentPackActionTypes["RemoveFromContentPack"] = "[Content-Packs] Remove From Content Pack";
    ContentPackActionTypes["RemoveFromContentPackSuccess"] = "[Content-Packs] Remove From Content Pack Success";
    ContentPackActionTypes["RemoveFromContentPackError"] = "[Content-Packs] Remove From Content Pack Error";
    ContentPackActionTypes["ClearErrors"] = "[Content-Packs] Clear Errors";
    ContentPackActionTypes["FilterContent"] = "[Content-Packs] Filter Content List";
    ContentPackActionTypes["ClearFilter"] = "[Content-Packs] Clear Content List Filter";
})(ContentPackActionTypes || (ContentPackActionTypes = {}));
var GetContentPacks = /** @class */ (function () {
    function GetContentPacks() {
        this.type = ContentPackActionTypes.GetContentPacks;
    }
    return GetContentPacks;
}());
export { GetContentPacks };
var GetContentPacksSuccess = /** @class */ (function () {
    function GetContentPacksSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.GetContentPacksSuccess;
    }
    return GetContentPacksSuccess;
}());
export { GetContentPacksSuccess };
var GetContentPacksError = /** @class */ (function () {
    function GetContentPacksError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.GetContentPacksError;
    }
    return GetContentPacksError;
}());
export { GetContentPacksError };
var GetContentPackDetail = /** @class */ (function () {
    function GetContentPackDetail(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.GetContentPackDetail;
    }
    return GetContentPackDetail;
}());
export { GetContentPackDetail };
var GetContentPackDetailSuccess = /** @class */ (function () {
    function GetContentPackDetailSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.GetContentPackDetailSuccess;
    }
    return GetContentPackDetailSuccess;
}());
export { GetContentPackDetailSuccess };
var GetContentPackDetailError = /** @class */ (function () {
    function GetContentPackDetailError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.GetContentPackDetailError;
    }
    return GetContentPackDetailError;
}());
export { GetContentPackDetailError };
var CreateContentPack = /** @class */ (function () {
    function CreateContentPack(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.CreateContentPack;
    }
    return CreateContentPack;
}());
export { CreateContentPack };
var CreateContentPackSuccess = /** @class */ (function () {
    function CreateContentPackSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.CreateContentPackSuccess;
    }
    return CreateContentPackSuccess;
}());
export { CreateContentPackSuccess };
var CreateContentPackError = /** @class */ (function () {
    function CreateContentPackError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.CreateContentPackError;
    }
    return CreateContentPackError;
}());
export { CreateContentPackError };
var EditContentPack = /** @class */ (function () {
    function EditContentPack(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.EditContentPack;
    }
    return EditContentPack;
}());
export { EditContentPack };
var EditContentPackSuccess = /** @class */ (function () {
    function EditContentPackSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.EditContentPackSuccess;
    }
    return EditContentPackSuccess;
}());
export { EditContentPackSuccess };
var EditContentPackError = /** @class */ (function () {
    function EditContentPackError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.EditContentPackError;
    }
    return EditContentPackError;
}());
export { EditContentPackError };
var DeleteContentPack = /** @class */ (function () {
    function DeleteContentPack(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.DeleteContentPack;
    }
    return DeleteContentPack;
}());
export { DeleteContentPack };
var DeleteContentPackSuccess = /** @class */ (function () {
    function DeleteContentPackSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.DeleteContentPackSuccess;
    }
    return DeleteContentPackSuccess;
}());
export { DeleteContentPackSuccess };
var DeleteContentPackError = /** @class */ (function () {
    function DeleteContentPackError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.DeleteContentPackError;
    }
    return DeleteContentPackError;
}());
export { DeleteContentPackError };
var AddToContentPack = /** @class */ (function () {
    function AddToContentPack(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.AddToContentPack;
    }
    return AddToContentPack;
}());
export { AddToContentPack };
var AddToContentPackSuccess = /** @class */ (function () {
    function AddToContentPackSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.AddToContentPackSuccess;
    }
    return AddToContentPackSuccess;
}());
export { AddToContentPackSuccess };
var AddToContentPackError = /** @class */ (function () {
    function AddToContentPackError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.AddToContentPackError;
    }
    return AddToContentPackError;
}());
export { AddToContentPackError };
var AddToMultipleContentPacks = /** @class */ (function () {
    function AddToMultipleContentPacks(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.AddToMultipleContentPacks;
    }
    return AddToMultipleContentPacks;
}());
export { AddToMultipleContentPacks };
var AddToMultipleContentPacksSuccess = /** @class */ (function () {
    function AddToMultipleContentPacksSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.AddToMultipleContentPacksSuccess;
    }
    return AddToMultipleContentPacksSuccess;
}());
export { AddToMultipleContentPacksSuccess };
var AddToMultipleContentPacksError = /** @class */ (function () {
    function AddToMultipleContentPacksError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.AddToMultipleContentPacksError;
    }
    return AddToMultipleContentPacksError;
}());
export { AddToMultipleContentPacksError };
var RemoveFromContentPack = /** @class */ (function () {
    function RemoveFromContentPack(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.RemoveFromContentPack;
    }
    return RemoveFromContentPack;
}());
export { RemoveFromContentPack };
var RemoveFromContentPackSuccess = /** @class */ (function () {
    function RemoveFromContentPackSuccess(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.RemoveFromContentPackSuccess;
    }
    return RemoveFromContentPackSuccess;
}());
export { RemoveFromContentPackSuccess };
var RemoveFromContentPackError = /** @class */ (function () {
    function RemoveFromContentPackError(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.RemoveFromContentPackError;
    }
    return RemoveFromContentPackError;
}());
export { RemoveFromContentPackError };
var FilterContent = /** @class */ (function () {
    function FilterContent(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.FilterContent;
    }
    return FilterContent;
}());
export { FilterContent };
var ClearFilter = /** @class */ (function () {
    function ClearFilter(payload) {
        this.payload = payload;
        this.type = ContentPackActionTypes.ClearFilter;
    }
    return ClearFilter;
}());
export { ClearFilter };
var ClearErrors = /** @class */ (function () {
    function ClearErrors() {
        this.type = ContentPackActionTypes.ClearErrors;
    }
    return ClearErrors;
}());
export { ClearErrors };
