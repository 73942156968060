import { EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { passwordComplexity, valuesMatch } from 'src/app/core/utils/validators';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(_fb) {
        this._fb = _fb;
        this.requestPasswordCode = new EventEmitter();
        this.submitNewPassword = new EventEmitter();
        this.isGeneratingCode = false;
        this.forgottenSubmitted = false;
        this.isResettingPassword = false;
        this.newPasswordSubmitted = false;
        this.forgottenPasswordForm = this._fb.group({
            email: this._fb.control('', [Validators.required, Validators.email]),
        });
        this.newPasswordForm = this._fb.group({
            code: this._fb.control('', [Validators.required]),
            password: this._fb.control('', [
                Validators.required,
                passwordComplexity,
            ]),
            confirmPassword: this._fb.control(''),
        }, {
            validator: [valuesMatch('password', 'confirmPassword')],
        });
    }
    Object.defineProperty(ForgotPasswordComponent.prototype, "generatingCode", {
        set: function (isGeneratingCode) {
            if (isGeneratingCode) {
                this.forgottenPasswordForm.disable();
            }
            else {
                this.forgottenPasswordForm.enable();
            }
            this.isGeneratingCode = isGeneratingCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForgotPasswordComponent.prototype, "resettingPassword", {
        set: function (isResettingPassword) {
            if (isResettingPassword) {
                this.newPasswordForm.disable();
                this.forgottenPasswordForm.disable();
            }
            else {
                this.newPasswordForm.enable();
                this.forgottenPasswordForm.enable();
            }
            this.isResettingPassword = isResettingPassword;
        },
        enumerable: true,
        configurable: true
    });
    ForgotPasswordComponent.prototype.getResetCode = function () {
        this.forgottenSubmitted = true;
        var email = this.forgottenPasswordForm.get('email').value;
        var forgottenPassword = {
            email: email,
            validationData: {
                clinictoken: this.clinicId,
            },
        };
        this.requestPasswordCode.emit(forgottenPassword);
    };
    ForgotPasswordComponent.prototype.createNewPassword = function () {
        this.newPasswordSubmitted = true;
        var email = this.forgottenPasswordForm.get('email').value;
        var code = this.newPasswordForm.get('code').value;
        var new_password = this.newPasswordForm.get('password').value;
        var newPasswordData = {
            email: email,
            code: code,
            new_password: new_password,
            validationData: {
                clinictoken: this.clinicId,
            },
        };
        this.submitNewPassword.emit(newPasswordData);
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
