import { Component, Input } from '@angular/core';
import { ContactInfo } from '../../models/ContactInfo';
import { LabResult } from '../../models/LabResult';
import { PartnerInfo } from '../../models/PartnerInfo';
import { PatientInfo } from '../../models/PatientInfo';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

export enum PatientSection {
  patientInformation = 'patientInformation',
  partner = 'partner',
  contact = 'contact',
  labResults = 'labResults',
  patientDocuments = 'patientDocuments',
  manageContent = 'manageContent',
}

@Component({
  selector: 'portal-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent {
  @Input() text: any;
  @Input() isCore: boolean;
  @Input() activeSection: string;
  @Input() patientInfo: PatientInfo;
  @Input() partnerInfo: PartnerInfo;
  @Input() contactInfo: ContactInfo;
  @Input() labResults: LabResult[];

  public patientSection = PatientSection;

  public config: PerfectScrollbarConfigInterface = {};
}
