var MarkMessageStarredRequest = /** @class */ (function () {
    function MarkMessageStarredRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId;
        this.Action = 'MarkMessageStarred';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
    }
    return MarkMessageStarredRequest;
}());
export { MarkMessageStarredRequest };
