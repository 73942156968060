export var ToastActions;
(function (ToastActions) {
    ToastActions["DisplayToast"] = "[Toast] Display";
    ToastActions["ToastDisplayed"] = "[Toast] Displayed";
    ToastActions["ToastRemoved"] = "[Toast] Removed";
})(ToastActions || (ToastActions = {}));
var DisplayToastAction = /** @class */ (function () {
    function DisplayToastAction(payload) {
        this.payload = payload;
        this.type = ToastActions.DisplayToast;
    }
    return DisplayToastAction;
}());
export { DisplayToastAction };
var ToastDisplayedAction = /** @class */ (function () {
    function ToastDisplayedAction(payload) {
        this.payload = payload;
        this.type = ToastActions.ToastDisplayed;
    }
    return ToastDisplayedAction;
}());
export { ToastDisplayedAction };
var ToastRemovedAction = /** @class */ (function () {
    function ToastRemovedAction() {
        this.type = ToastActions.ToastRemoved;
    }
    return ToastRemovedAction;
}());
export { ToastRemovedAction };
