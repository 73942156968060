import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { StyledCard, StyledNav, StyledNavLink, StyledNavList, } from './Nav.styled';
var Nav = function () {
    var t = useTranslations().t;
    return (React.createElement(StyledCard, null,
        React.createElement(StyledNav, null,
            React.createElement(StyledNavList, null,
                React.createElement("li", null,
                    React.createElement(StyledNavLink, { activeClassName: "active", to: "/superadmin/data-exports" }, t.SuperAdmin.DataExport.Title)),
                React.createElement("li", null,
                    React.createElement(StyledNavLink, { activeClassName: "active", to: "/superadmin/form-lookup" }, "Patient Forms Lookup"))))));
};
var ɵ0 = Nav;
export default Nav;
export { ɵ0 };
