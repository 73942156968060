import { AuthActionsUnion, AuthActionTypes } from '../actions/auth.actions';
import { ClinicOptionsState } from '../models/ClinicOptions';

export type State = ClinicOptionsState;

export const initialState: State = {
  loaded: false,
  options: {}
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {
    case AuthActionTypes.GetClinicIdSuccess: {
      return {
        ...state,
        options: action.payload.JsonOptions
          ? JSON.parse(action.payload.JsonOptions)
          : {}
      };
    }
    default: {
      return state;
    }
  }
}

export const getClinicOptionsLoaded = (state: State) => state.loaded;
export const getClinicOptions = (state: State) => state.options;
export const getVideoCallsEnabled = (state: State) =>
  !!state.options.videoCallOptions && !!state.options.videoCallOptions.enabled;
export const getMedicationManagerEnabled = (state: State) =>
  !!state.options.medicationManagerOptions &&
  !!state.options.medicationManagerOptions.enabled;
export const getVideoCallsPartnerInvite = (state: State) =>
  !!state.options.videoCallOptions &&
  !!state.options.videoCallOptions.partnerInvite;
export const getLabResultsEnabled = (state: State) =>
  !!state.options.labResultOptions && !!state.options.labResultOptions.enabled;
export const getMessageStarringEnabled = (state: State) =>
  !!state.options.messagingOptions &&
  !!state.options.messagingOptions &&
  !!state.options.messagingOptions.messageStarring;
export const getContentEditorEnabled = (state: State) =>
  !!state.options.contentEditorOptions &&
  !!state.options.contentEditorOptions.enabled;
