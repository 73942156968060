import {
  NewMessageActionsUnion,
  NewMessageActionTypes,
} from '../actions/new-message.actions';

import { MessagesState } from '../reducers';

export function reducer(
  state: MessagesState,
  action: NewMessageActionsUnion,
): MessagesState {
  switch (action.type) {
    case NewMessageActionTypes.SendNewMessage: {
      return {
        ...state,
        sendingMessage: action.payload.message.MessageId,
      };
    }

    case NewMessageActionTypes.SendNewMessageError: {
      return {
        ...state,
        sendingMessage: null,
      };
    }

    case NewMessageActionTypes.StoreDraft: {
      const messageId = action.payload.id;
      const content = action.payload.content;

      return {
        ...state,
        draftMessages: {
          ...state.draftMessages,
          [messageId]: content,
        },
      };
    }

    default:
      return state;
  }
}
