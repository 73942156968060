import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { formatMessage } from '../lib';
export function useMessagesQuery(filters) {
    var _this = this;
    var apiClient = useApiClient().portal;
    return useQuery([queryCacheKey.MESSAGES, filters], function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var data;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.fetchMessages(filters)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.map(formatMessage)];
            }
        });
    }); }, {
        refetchInterval: POLLING_INTERVAL_MS,
    });
}
