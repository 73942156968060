import { useApiClient } from '@react/lib/api';
import { IFilters, UpdateSideRecipeRequest } from '@react/lib/api/types';
import { debounceAsync } from '@react/lib/debounceAsync';
import { useMemo, useRef, useState } from 'react';
import { ValidateResult } from 'react-hook-form';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

export function useSIDERecipeListQuery({
  searchTerm,
  filters,
}: {
  searchTerm: string;
  filters: IFilters;
}) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    ['sideRecipes', { searchTerm, filters }],
    ({ pageParam }: { pageParam?: number }) =>
      apiClient.fetchSIDERecipes({ pageParam, query: searchTerm, filters }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    },
  );
}

export function useSIDERecipeQuery({ id }: { id: number }) {
  const apiClient = useApiClient().portal;

  return useQuery(['sideRecipe', id], () => apiClient.fetchSIDERecipe(id));
}

export function useSIDERecipeTriggerQuery({ id }: { id: number }) {
  const apiClient = useApiClient().portal;

  return useQuery(['sideRecipeTrigger', id], () =>
    apiClient.fetchSIDERecipeTriggers(id),
  );
}

export function useSideRecipeMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    (data: UpdateSideRecipeRequest) => {
      return apiClient.updateSideRecipe(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['sideRecipe', Number(data.data.id)]);
      },
    },
  );
}

export function useSIDERecipeActionListQuery({ id }: { id: number }) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    ['sideRecipeActions', id],
    ({ pageParam }: { pageParam?: number }) =>
      apiClient.fetchSIDERecipeActions({ pageParam, id }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    },
  );
}

export const useUniqueNameValidator = ({
  delay,
  errorMessage,
  sideRecipeId,
}: any) => {
  const { portal: portalApiClient } = useApiClient();

  return useMemo(() => {
    return debounceAsync<
      ValidateResult,
      (value: string) => Promise<ValidateResult>
    >(async (value: string) => {
      try {
        const { data } = await portalApiClient.fetchSIDERecipes({
          query: value,
        });

        if (data.length === 0) {
          return undefined;
        }

        const recipe = data.find(
          (item) =>
            item.id !== sideRecipeId &&
            item.name.toLowerCase() === value.toLowerCase(),
        );

        return recipe ? errorMessage : undefined;
      } catch (err) {
        return errorMessage;
      }
    }, delay);
  }, [delay, errorMessage, portalApiClient, sideRecipeId]);
};
