import { AfterViewInit, ElementRef, EventEmitter, } from '@angular/core';
var IntersectionObserverDirective = /** @class */ (function () {
    function IntersectionObserverDirective(elementRef) {
        this.elementRef = elementRef;
        this.startedSettingUp = new EventEmitter();
        this.observed = new EventEmitter();
    }
    Object.defineProperty(IntersectionObserverDirective.prototype, "elementToObserve", {
        get: function () {
            if (this.elementRef) {
                return this.observeFirstChildElement
                    ? this.elementRef.nativeElement.children[0]
                    : this.elementRef.nativeElement;
            }
        },
        enumerable: true,
        configurable: true
    });
    IntersectionObserverDirective.prototype.ngAfterViewInit = function () {
        this.SetupObserver();
    };
    IntersectionObserverDirective.prototype.SetupObserver = function () {
        if (this.observer) {
            this.DetachObserver();
        }
        if ('IntersectionObserver' in window) {
            if (this.startedSettingUp) {
                this.startedSettingUp.emit();
            }
            var config = {
                root: null,
                rootMargin: this.observeMargin || '0px',
                threshold: 0.5,
            };
            this.observer = new IntersectionObserver(this.OnObserverChanges.bind(this), config);
            this.observer.observe(this.elementToObserve);
        }
    };
    IntersectionObserverDirective.prototype.DetachObserver = function () {
        if (this.observer) {
            try {
                this.observer.unobserve(this.elementToObserve);
                this.observer = null;
            }
            catch (_a) { }
        }
    };
    IntersectionObserverDirective.prototype.OnObserverChanges = function (changes, observer) {
        for (var _i = 0, changes_1 = changes; _i < changes_1.length; _i++) {
            var change = changes_1[_i];
            if (change.intersectionRatio > 0) {
                if (this.observer) {
                    try {
                        if (this.observed) {
                            this.observed.emit();
                        }
                    }
                    catch (_a) { }
                }
            }
        }
    };
    return IntersectionObserverDirective;
}());
export { IntersectionObserverDirective };
