import React, { useContext, useEffect, useRef, useState } from 'react';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

export interface TypingEventResponse {
  event: 'Typing';
  messageId: number;
  user: string;
  lastEdited: string;
}

export interface TypingEventPayload {
  content: string;
  messageId: number;
  user: string;
}

type SendTypingEvent = (data: TypingEventPayload) => void;
type SetOnMessage = (
  onMessage: ((data: TypingEventResponse) => void) | null,
  socketId?: string,
) => string | undefined;
type WebsocketConnect = () => Promise<WebSocket>;
type WebsocketDisconnect = () => void;
type Resolve = (value: WebSocket) => void;
type OnMessage = (event: TypingEventResponse) => void;

interface RealTimeMessagingConnectionContextPayload {
  connected: boolean;
  sendTypingEvent: SendTypingEvent;
  setOnMessage: SetOnMessage;
  websocketConnect: WebsocketConnect;
  websocketDisconnect: WebsocketDisconnect;
}

export const RealTimeMessagingConnectionContext =
  React.createContext<RealTimeMessagingConnectionContextPayload>({
    connected: false,
    sendTypingEvent: null as unknown as SendTypingEvent,
    setOnMessage: null as unknown as SetOnMessage,
    websocketConnect: null as unknown as WebsocketConnect,
    websocketDisconnect: null as unknown as WebsocketDisconnect,
  });

export const RealTimeMessagingConnectionProvider: React.FC<{
  clinicToken: string;
}> = ({ children, clinicToken }) => {
  const [connected, setConnected] = useState(false);
  const connection = useRef<WebSocket | null>(null);
  const onMessages = useRef<
    Record<string, (event: TypingEventResponse) => void>
  >({});
  const timeoutRef = useRef<NodeJS.Timer>();

  const websocketConnect = async () => {
    return new Promise((resolve: Resolve) => {
      if (connection.current && connection.current.readyState === 1) {
        resolve(connection.current);
        return;
      }

      // Clearing the connection object probably overzealously, but just to be sure any old websocket connections are not still attached.
      connection.current = null;

      const endpoint = `${environment.api.messagingWs.endpoint}?clinicToken=${clinicToken}`;
      connection.current = new WebSocket(endpoint);

      resolve(connection.current);

      connection.current.onopen = () => {
        if (connection.current) {
          setConnected(true);
          heartbeat();
        }
      };
    });
  };

  const websocketDisconnect = () => {
    clearTimeout(timeoutRef.current as NodeJS.Timer);
    if (connection.current) {
      connection.current.close();
    }
  };

  const receiveMessage = () => {
    if (!connection.current) {
      return;
    }

    try {
      connection.current.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data) as unknown;

        if (!isTypingEvent(data)) {
          return;
        }

        Object.values(onMessages.current).map((onMessage: OnMessage) => {
          if (onMessage) {
            onMessage(data);
          }
        });
      };
    } catch (error) {
      console.error('An error occurred when receiving a message');
      console.error(error);
    }
  };

  const setOnMessage = (
    onMessage: ((data: TypingEventResponse) => void) | null,
    socketIdToRemove?: string,
  ) => {
    const socketId = uuidv4();
    if (onMessage) {
      onMessages.current = {
        ...onMessages.current,
        [socketId]: onMessage,
      };
      receiveMessage();
      return socketId;
    } else {
      if (socketIdToRemove) {
        const { [socketIdToRemove]: oldOnMessage, ...rest } =
          onMessages.current;
        onMessages.current = rest;
      }
    }
  };

  const sendTypingEvent = async (data: TypingEventPayload) => {
    try {
      if (connection.current && connection.current.readyState === 1) {
        const message = {
          message: 'typing',
          clinicToken,
          contentLength: data.content.length || 0,
          messageId: data.messageId,
          user: data.user,
        };
        connection.current.send(JSON.stringify(message));
      } else {
        await websocketConnect();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isTypingEvent = (data: any): data is TypingEventResponse => {
    return (
      typeof data === 'object' && data !== null && data['event'] === 'Typing'
    );
  };

  const heartbeat = () => {
    try {
      if (connection.current) {
        connection.current.send('heartbeat');
        const timeout = setTimeout(heartbeat, 60000);
        const currentTimeout = timeoutRef.current;
        clearTimeout(currentTimeout as NodeJS.Timer);
        timeoutRef.current = timeout;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RealTimeMessagingConnectionContext.Provider
      value={{
        connected,
        sendTypingEvent,
        setOnMessage,
        websocketConnect,
        websocketDisconnect,
      }}
      children={children}
    />
  );
};

export const useRealTimeMessagingContext = () =>
  useContext(RealTimeMessagingConnectionContext);
