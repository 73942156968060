import { ModuleWithProviders } from '@angular/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, } from 'ngx-perfect-scrollbar';
import { CreateAccountFormComponent } from './components/create-account-form/create-account.component';
import { EmailOTPFormComponent } from './components/email-otp-form/email-otp-form.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { CreateAccountContainer } from './containers/create-account/create-account.container';
import { EmailOTPPageContainer } from './containers/email-otp-page/email-otp.container';
import { ForgotPasswordContainer } from './containers/forgot-password/forgot-password.container';
import { LoginPageContainer } from './containers/login-page/login-page.container';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true,
};
export var COMPONENTS = [
    LoginPageContainer,
    CreateAccountContainer,
    ForgotPasswordContainer,
    EmailOTPPageContainer,
    LoginFormComponent,
    CreateAccountFormComponent,
    ForgotPasswordComponent,
    EmailOTPFormComponent,
];
var AuthModule = /** @class */ (function () {
    function AuthModule() {
    }
    AuthModule.forRoot = function () {
        return {
            ngModule: RootAuthModule,
            providers: [
                AuthService,
                AuthGuard,
                {
                    provide: PERFECT_SCROLLBAR_CONFIG,
                    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
                },
            ],
        };
    };
    return AuthModule;
}());
export { AuthModule };
var ɵ0 = {
    preload: true,
}, ɵ1 = {
    preload: true,
}, ɵ2 = {
    preload: true,
}, ɵ3 = {
    preload: true,
};
var RootAuthModule = /** @class */ (function () {
    function RootAuthModule() {
    }
    return RootAuthModule;
}());
export { RootAuthModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
