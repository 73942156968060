<div
  [formGroup]="sendToAllForm"
  class="content-edit-controls-container"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="exitForm.emit()"
    >
      {{ text.Cancel }}
    </button>
    <mat-checkbox
      *ngIf="canSendToAll && !isGlobalPortal"
      formControlName="sendtoall"
      >{{ text.SendToAll }}</mat-checkbox
    >
    <!-- <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="resetForm.emit()"
    >
      {{text.Reset}}
    </button> -->
  </div>
  <button
    mat-raised-button
    class="portal-btn btn-bright"
    [disabled]="
      introductionError || formError || !contentEditorEnabled || !canPublish
    "
    (click)="publishContent.emit()"
  >
    {{ text.Publish }}
  </button>
</div>
