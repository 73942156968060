import { IconButton, InputAdornment } from '@material-ui/core';
import { Cancel as IconCancel } from '@material-ui/icons';
import React from 'react';
import { SearchTextField } from './MessageEditor.styled';

interface Props {
  value: string;
  onChangeText: (text: string) => void;
  placeholder?: string;
}

const SearchIconButton = ({ onClick }: { onClick: () => void }) => (
  <InputAdornment position="end">
    <IconButton onClick={onClick} size="small" role="button">
      <IconCancel />
    </IconButton>
  </InputAdornment>
);

function MessageEditorSearchField({ value, onChangeText, placeholder }: Props) {
  const showClearButton = value.length > 0;

  return (
    <SearchTextField
      autoComplete="off"
      inputProps={{ 'data-testid': 'message-search', role: 'searchbox' }}
      InputProps={{
        endAdornment: showClearButton ? (
          <SearchIconButton onClick={() => onChangeText('')} />
        ) : undefined,
      }}
      margin="none"
      name="search"
      onChange={(e) => onChangeText(e.target.value)}
      placeholder={placeholder}
      size="small"
      spellCheck="false"
      type="search"
      value={value}
      variant="outlined"
    />
  );
}

export default MessageEditorSearchField;
