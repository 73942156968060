import { useQuery } from 'react-query';
import { useApiClient } from '../context';

const FIVE_MINS = 5 * 60 * 1000;

interface UseContentOptions {
  packId?: number;
  onPacksError?: () => void;
  onPackDetailError?: () => void;
  onCategoriesError?: () => void;
  onCategoryEntriesError?: () => void;
}

export const useContent = ({
  packId,
  onPacksError,
  onCategoriesError,
  onCategoryEntriesError,
  onPackDetailError,
}: UseContentOptions = {}) => {
  const apiClient = useApiClient().content;
  const queryOpts = {
    staleTime: FIVE_MINS,
    retry: false,
  };

  const packs = useQuery(['contentPacks'], () => apiClient.fetchPacks(), {
    ...queryOpts,
    onError: onPacksError,
  });
  const packDetails = useQuery(
    ['contentPacks', packId],
    () => apiClient.fetchPacks(packId as number),
    { enabled: !!packId, onError: onPackDetailError },
  );
  const categories = useQuery(
    ['contentCategories'],
    () => apiClient.fetchCategories(),
    { ...queryOpts, onError: onCategoriesError },
  );
  const categoryEntries = useQuery(
    ['contentCategoriesEntries'],
    () => apiClient.fetchCategoryEntries(),
    { ...queryOpts, onError: onCategoryEntriesError },
  );

  return {
    packs,
    categories,
    packDetails,
    categoryEntries,
  };
};
