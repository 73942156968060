/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./conversation-resolver.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./conversation-resolver.component";
import * as i3 from "../../core/services/s3.service";
import * as i4 from "@ngrx/store";
import * as i5 from "../../core/services/congito.wrapper.service";
var styles_ConversationResolverComponent = [i0.styles];
var RenderType_ConversationResolverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConversationResolverComponent, data: {} });
export { RenderType_ConversationResolverComponent as RenderType_ConversationResolverComponent };
export function View_ConversationResolverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["portalConversationResolver", 1]], null, 0, "div", [["class", "conversation react-wrapper"]], null, null, null, null, null))], null, null); }
export function View_ConversationResolverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-conversation-component", [], null, null, null, View_ConversationResolverComponent_0, RenderType_ConversationResolverComponent)), i1.ɵdid(1, 4440064, null, 0, i2.ConversationResolverComponent, [i3.FileUpload, i4.Store, i5.CognitoWrapperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConversationResolverComponentNgFactory = i1.ɵccf("portal-conversation-component", i2.ConversationResolverComponent, View_ConversationResolverComponent_Host_0, {}, {}, []);
export { ConversationResolverComponentNgFactory as ConversationResolverComponentNgFactory };
