export const translationEnGB = {
  Languages: {
    English: 'English',
    German: 'Deutsch',
    Spanish: 'Español',
    Portuguese: 'Português',
  },
  // New Content Sections
  // =====================================
  ClinicStatus: {
    SalveConnection: 'Salve Connection',
    Tooltip:
      // tslint:disable-next-line:max-line-length
      (version) =>
        `When a red dot is shown, the connection between Salve and the clinic system is temporarily offline. Patients may not receive real time med / appt updates on their app. This will be resolved shortly. If this persists, email hello@salveapp.co.uk. Salve version: ${version}`,
  },
  NewContentList: {
    ThisFolder: (folder) => `${folder} Folder`,
    SearchContent: (folder) => `Search ${folder} Content...`,
    ThisPack: (pack) => `${pack} Pack`,
    FilterPack: (pack) => `Filter the ${pack} Pack Content...`,
    AddItem: 'Add Item',
    Title: 'Content Title',
    Comments: 'Comments',
    DateChanged: 'Date Changed',
    UpdatedBy: 'Last Updated By',
    NoContent: 'No Content Found',
    Empty: 'Empty',
    Migrated: 'Migrated',
    DeleteItems: 'Delete Items',
    Actions: 'Actions',
    MoveItems: 'Move Items',
    LinkedToEMR: 'Content generated from EMR data',
    Translated: 'Content has been translated',
    TranslatedRow: (languages: string) =>
      `Current translations: ${languages}. Click on this content to add translations`,
    ViewLinks: 'View Links',
    ItemsPerPage: 'View',
    FirstPage: 'First page',
    PreviousPage: 'Previous page',
    NextPage: 'Next page',
    LastPage: 'Last page',
    ContentHasLink: 'Content has link to other content',
    NoChildren: 'No Child Content Found',
    LinkedAndSynced:
      'No Actions available for items that are both linked, and synced from EMR',
    Link: 'Link Items',
    LinkContent: 'Link Content',
    ContentReplaced:
      // tslint:disable-next-line:quotemark
      "The content of the 'Child' will be replaced with the content of the 'Parent'",
    LinkingContent: 'Linking Content...',
    LinkedSuccess: 'Content Linked Successfully!',
    LinkingError: 'Error Linking Content...',
    ContentLink: 'Linked Content',
    CtoPDesc: (parentName) =>
      `Content for ${parentName} appears instead of the following items: `,
    RemoveSelectedLinks: 'Remove Selected Links',
    RemoveLink: 'Remove Link',
    RemoveLinks: 'Remove Links',
    RemoveConfirm: 'Are you sure you want to remove this content link?',
    RemoveSomeLinks: 'Remove Some Links',
    RemoveAllLinks: 'Remove All Links',
    RemoveSomeConfirm:
      'Are you sure you want to remove these links from this piece of content?',
    RemoveAllConfirm:
      'Are you sure you want to remove all links to this piece of content?',
    RemovingLink: 'Removing Link...',
    RemovingLinks: 'Removing Links...',
    RemoveLinkSuccess: 'Removed Link Successfully!',
    RemoveLinksSuccess: 'Removed Links Successfully!',
    RemoveLinkError: 'Error Removing Link',
    RemoveLinksError: 'Error Removing Links',
    To: 'To',
    Parent: 'Parent',
    Child: 'Child',
    FilterContent: 'Filter Content...',
    MovedItemsSuccess: 'Moved Entries Successfully',
    MovingItems: 'Moving Entries...',
    ErrorMovingItems: 'Error Encountered Moving Items',
    DeleteFolder: 'Delete Folder',
    MoveConfirmation: 'Are you sure you want to move these items?',
    WhichFolder: 'Please select a folder to move these items to: ',
    ChooseAFolder: 'Choose A Folder',
    PleaseChoose: 'Please Choose a Folder',
    DeleteFolderConfirmation: 'Are you sure you want to delete this folder?',
    DeleteItemsConfirmation: 'Are you sure you want to delete these items?',
    DeleteItemsAdditionalInfo:
      'If there are any translations associated with this content, those will also be deleted.',
    DeleteItemBlock: (itemName) =>
      `You cannot delete "${itemName}" as it is linked to another content. To delete it, you need to unlink it first.`,
    Delete: 'Delete',
    Move: 'Move',
    Cancel: 'Cancel',
    OK: 'OK',
    Save: 'Save',
    FolderName: 'Folder Name',
    ChangeFolderName: 'Change Folder Name',
    FolderRequired: 'A folder name is required',
    FolderTranslationRequired: 'Existing translations can’t be left blank',
    FolderExists: 'This folder name already exists',
    FolderLength: 'Name needs to be between 3 and 50 characters',
    ChangingFolder: 'Changing Name...',
    ChangingFolderSuccess: 'Changed Successfully!',
    ChangingFolderError: 'Error changing name!',
    // Multilingual
    Translations: 'Translations',
    PressMe: 'Press this ',
    IconFolder: ' button to create your first content folder',
    // New Translations
    PackName: 'Pack Name',
    WhatPackName: 'Please provide a new, unique Pack name: ',
    PackNameRequired: 'A Pack name is required',
    PackExists: 'This Pack name already exists',
    PackNameLength: 'Pack needs to be between 3 and 50 characters',
    ContentCategory: 'Content Category',
    IsFileOnly: 'File Only',
    DeletePack: 'Delete Content Pack',
    DeletePackConfirmation: 'Are you sure you want to delete this Pack?',
    RemovePackItems: 'Remove from Pack',
    RemovePackItemsConfirmation:
      'Are you sure you want to remove these items from this Pack?',
    Remove: 'Remove',
    AddToPack: 'Add to Pack',
    AddToPackDescription:
      'The following items will be added to the Selected packs: ',
    AddingToPack: 'Adding Content to Pack...',
    AddedSuccessfully: 'Content successfully added to Pack',
    AddingToPackError: 'Error adding Content to pack!',
    SelectAPack: 'Select Content Packs',
    PleaseChoosePack: 'Please choose a Pack...',
    SendToAll: 'This Pack is automatically sent to all patients',
    SendTo: 'Send To',
    AllPatients: 'All',
  },
  NoContent: {
    PressAdd: 'Press the Add Content button to begin',
    AddContent: 'Add Content',
    // tslint:disable-next-line:quotemark
    Appear: "You don't appear to have any content",
  },
  ContentDirectory: {
    Add: 'Add',
    CreateNewFolder: 'Create a new folder',
    CreateNewTemplate: 'Create a new template',
    CreateNew: 'Create New',
    Categories: 'Folders',
    Templates: 'Templates',
    NoFolders: 'Create Your First Folder',
    NoTemplates: 'Create Your First Template',
    // New
    CreateNewPack: 'Create a new Pack',
    Packs: 'Packs',
    NoPacks: 'Create Your First Pack',
  },
  CreateCategoryOrTemplate: {
    What: 'What do you want to do?',
    CreateCategory: 'Create Folder',
    CreateTemplate: 'Create Template',
    Organise: 'Organise your content into folders.',
    MakeItEasy:
      // tslint:disable-next-line:quotemark
      "Make it easy to find, create, and distribute your clinic's content.",
    Uniformity: 'Templates allow you to create uniformity in your content.',
    UsefulFor: 'Particularly useful for Medications and Appointments!',
    CategoryButton: 'Create Content Folder',
    TemplateButton: 'Create Content Template',
  },
  CreateCategory: {
    CreateCategory: 'Please provide a new, unique folder name: ',
    AddTitle: 'Add your folder name here...',
    CurrentCategories: 'Current Folders',
    FolderRequired: 'A folder name is required',
    FolderExists: 'This folder name already exists',
    FolderLength: 'Name needs to be between 3 and 50 characters',
    CreatingFolder: 'Creating folder...',
    CreatedFolder: 'Folder created!',
    ErrorCreatingFolder: 'Error Creating Folder',
    Cancel: 'Cancel',
    Create: 'Create',
    // New Words
    CreatePack: 'Please provide a new, unique Pack name: ',
    AddPackTitle: 'Add your Pack name here...',
    CurrentPacks: 'Current Packs',
    PackRequired: 'A Pack name is required',
    PackExists: 'This Pack name already exists',
    PackLength: 'The Pack name needs to be between 3 and 50 characters',
    CreatingPack: 'Creating Pack...',
    CreatedPack: 'Pack Created!',
    ErrorCreatingPack: 'Error Creating Pack',
  },
  CreateTemplate: {
    NewTitle: 'New Content Template',
    Title: 'Content Template',
    TemplateTitle: 'Content Template Title...',
    Required: 'Required',
    AddNew: 'Add New',
    Save: 'Save',
    AddSectionTitle: 'Add Your Section Title',
    CancelConfirmation: 'Close template without saving changes?',
    DeleteConfirmation: 'Are you sure you want to delete this template?',
    Delete: 'Delete',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    Saving: 'Saving Template...',
    SavedSuccessfully: 'Saved Successfully',
    SavedError: 'Error Encountered',
  },
  CreateContentItem: {
    AddRich:
      'Add rich content such as images, video and text, using our easy to use content creator.',
    UploadPdf:
      'Upload a PDF or Word Document for the patient to easily read on their device',
    CreateRich: 'Create Rich Content',
    UsePdf: 'Use a Document',
    Recommended: 'recommended',
  },
  ContentCreatorTitle: {
    ManageLinks: 'Manage Linked Content', //
    HasChildren: 'This is linked to other content', //
    ParentExists: 'Please refer to the content for ', //
    Title: 'Title',
    AddTitle: 'Add your title here...',
    PatientTitle: 'Patient title',
    AddPatientTitle: 'Add a title that the patient should see...',
    TransferTitle: 'Use name from EMR',
    Subtitle: 'Subtitle',
    AddSubtitle: 'Add your subtitle here...',
    UseTemplate: 'Use Template',
    ChooseATemplate: 'Choose a template',
    TitleLengthError:
      'The title must be between <strong>3</strong> and <strong>50</strong> characters',
    TitleRequired: 'A title is <strong>required</strong>',
    TitleUnique:
      'The title must be <strong>unique</strong>, this title already exists.',
    SubtitleLessThan: 'The subtitle must be less than',
    TitleMust: 'The title must be',
    TitleIs: 'A title is',
    Between: 'between',
    LessThan: 'less than',
    TitleExists: ', this title already exists',
    Unique: 'unique',
    Required: 'required',
    And: 'and',
    Characters: 'characters',
    TitleChange: 'Synced Item names must be changed in Meditex',
    TitlePattern:
      'Title should contain letters, numbers, spaces and underscores only',
  },
  ContentCreatorBody: {
    Introduction: 'Introduction',
    // tslint:disable-next-line:quotemark
    AddIntroduction: "Add your content's Introduction...",
    Required: 'Required',
    TextOnly: 'Can only be text.',
    CharsRemaining: 'Characters Remaining.',
    Video: 'Video',
    AddVideo: 'Add Video',
    RemoveVideo: 'Remove Video',
    MinLength: 'The Introduction must contain some content.',
  },
  ContentCreatorControls: {
    Back: 'Back',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Publish: 'Publish',
    SendToAll: 'Send To All',
    AddTranslation: 'Add translation',
    Confirm: 'Confirm',
    Leave: 'Leave',
    PublishDialogInformation:
      'Note that any changes in other languages need to be published separately.',
    PublishDialogWithLanguageMessage: (languageName: string) =>
      `Publish your changes in ${languageName}?`,
    PublishDialogMessage: 'Publish your changes',
    SaveConfirm: 'Are you sure you want to save your changes?',
    ResetConfirm: 'Reset content without saving changes?',
    DeleteConfirmInformation:
      'This action cannot be undone. No other translations will be affected.',
    DeleteConfirmMessage: 'Are you sure you want to delete this translation?',
    DeleteDefaultConfirmInformation:
      'All of the content and any associated translations will be deleted. The action cannot be undone.',
    DeleteDefaultConfirmMessage:
      'Are you sure you want to delete this content?',
    DeleteErrorToastTitle: 'Error deleting content',
    DeleteErrorToastDescription:
      'The content was not deleted. Please try again.',
    ExitConfirm: 'Are you sure you want to leave without saving your changes?',
    OneAttachment: 'Only one attachment per piece of content.',
    TitleRequired: 'A valid title is required in order to add a file',
    ErrorToastDescription: 'The content was not published. Please try again.',
    ErrorToastTitle: 'Error publishing content',
    SuccessToastDescription: 'The content was published successfully',
    SuccessToastTitle: 'Content published',
    PublishingContent: 'Publishing content…',
    UnpublishedChanges: 'Unpublished changes',
  },
  AddVideoControls: {
    AddVideo: 'Add Video',
    YoutubeLink: 'Youtube Link',
    WebsiteLink: 'Link to website',
    Upload: 'Upload Video',
    AddLink: 'Add your link here...',
    SubmitLink: 'Submit Link',
  },
  ContentCreatorSections: {
    ConfirmDelete: 'Are you sure you want to delete this section?',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    AddSectionTitle: 'Add your section title...',
    InsertText: 'Insert text here...',
    AddSection: 'Add Section',
    MaxTitleLength: 'Max title length is 50 Characters',
    SectionTitle: 'Section Title',
    CharactersRemaining: 'characters remaining',
  },
  ContentCreatorComments: {
    Comments: 'Comments',
    OnlyVisible: 'Only Visible Internally',
  },
  ContentCreatorVersionHistory: {
    VersionHistory: 'Version History',
    ComingSoon: '...Coming Soon...',
  },
  AddPdf: {
    Title: 'Title',
    AddTitle: 'Add your title here...',
    AddFile: 'Add File',
    Current: 'Current Attachment',
    Id: 'Id',
    Name: 'Attachment Name',
    FileType: 'File Type',
    FileSize: 'File Size (Bytes)',
    Preview: 'Preview',
    CheckFile: 'Check File',
    Remove: 'Remove',
    Cancel: 'Cancel',
    RemoveConfirm: 'Are you sure you want to remove this attachment?',
  },
  UseATemplate: {
    UseATemplate: 'Use a Template',
    ChooseATemplate: 'Choose a Template',
    SelectATemplate: 'Please Select a Template',
    Append: 'Add Template Sections to the Bottom',
    Replace: 'Replace Sections with New Template',
    WillDelete: 'Replacing will delete all current sections!',
    Warning: 'Warning',
    Or: 'OR',
  },
  UseAContent: {
    Actions: 'Actions',
    DialogTitle: 'Copy from existing content',
    DialogGlobalTitle: 'Copy content from global portal',
    DialogGlobalDescription:
      'When copying content into a document-type content article, only other document-type content articles can be used. Rich content will not be searchable in the available list.',
    DialogDescription:
      'Only rich content can be copied into other content articles. Document-type content will not be searchable in the available list.',
    ChooseAContent: 'Choose a content article',
    ChooseAGlobalContent: 'Choose a global content article',
    SelectAContent: 'Please select a content article',
    SelectAGlobalContent: 'Please select a global content article',
    Copy: 'Copy',
    Cancel: 'Cancel',
    Warning: 'Warning',
    WarningText:
      'Copying the selected content to here will replace any existing patient title, introduction and video. Other sections will be added below any existing sections.',
    WarningGlobalText:
      'Anything currently entered into this content article will be overwritten by what is being copied including any existing title/patient title, subtitle, introduction, video, sections. This action cannot be undone.',
    WarningGlobalFileText:
      'Anything currently entered into this content article will be overwritten by what is being copied including any existing title/patient title, subtitle, and uploaded file. This action cannot be undone.',
    CopyGlobalContentErrorTitle: 'Unable to copy content from global portal',
    CopyGlobalContentErrorDescription:
      'Please check your connection and try again',
    CopyGlobalContentTranslationAdditionalTitle:
      'Could not copy one or more translations',
    CopyGlobalContentTranslationAdditionalDescription: (
      listOfLanguages: string,
    ) =>
      `We were unable to copy over one or more translations from the global portal as they were in a language not supported by this portal. Excluded translations: ${listOfLanguages}`,
    CopyGlobalContentTranslationMissingTitle:
      'Copied global content article missing one or more translations',
    CopyGlobalContentTranslationMissingDescription: (listOfLanguages: string) =>
      `The global content article you’ve copied is missing the following existing translation(s): ${listOfLanguages}. Please double-check your translations before publishing.`,
  },
  ContentSwing: {
    NoContentAvailable: 'You have no content',
    ToCreate: 'To create content press the button below: ',
    CreateContent: 'Create Content',
    UploadFile: 'Upload Patient File',
    SearchContent: 'Search Content...',
    SearchFiles: 'Search Files...',
    ContentType: 'Content Type',
    Folders: 'Folders',
    Packs: 'Packs',
    ViewAll: 'All',
    AvailableContent: (folderName) =>
      `${folderName ? folderName + ' ' : ''}Content`,
    SentDate: (time: string, date: string) => `Sent at ${time} on ${date}`,
    ReadDate: (time: string, date: string) => `Read at ${time} on ${date}`,
    PatientContent: 'Content For ',
    ReadyDocuments: 'Ready to send',
    PatientDocuments: (patientName: string) =>
      `Documents sent to ${patientName}`,
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    DeleteConfirm: 'Are you sure you want to delete this patients file?',
    SearchAssigned: 'Search Assigned Content...',
    Items: 'Individual Items',
    AssignPackContent: 'Assign Pack Content',
    LockItem: 'Prevent this Content being added to the patient',
    AddSelectedItems: 'Add Pack items to patient',
    AssignIndividualItem: 'Add this item to the patient',
    SeePatientView: 'View list of content from patient perspective',
    SeeClinicView: 'View list of content from clinic perspective',
    ViewInfo: 'Click to find out more about content assignment',
    PackEmpty: 'This Pack is empty',
    RemovePack: 'Remove Pack from patient',
    RemovePackItem: 'Remove this Pack item from the patient',
    ViewPackContents: 'View Pack Assignments',
    AllItemsExcluded: 'All items are excluded',
    NoAssignedPacks: 'Patient has no assigned Packs',
    ViewAssignedPacks: 'View patient assigned Packs',
    ViewIndividualItems: 'View individually assigned items',
    AllItemsAssigned: 'All items have been assigned',
    SendToAllIsAutomatic:
      'Send to all items are automatically assigned to all patients',
    NoContentToAssign: 'All available content has been assigned',
  },
  AddFile: {
    DocumentTitle: 'Document Title',
    NameRequired: 'A document name is required',
    NameLength: 'Document name should be between 3 and 50 characters',
    NameUnique: 'Document name should be unique',
    FixUnique: 'Unique name required before uploading file',
    NamePattern:
      'Document name should contain letters, numbers, spaces and underscores only',
    FirstInstruction:
      'Add a patient friendly title that will display in the app',
    AddDocTitle: 'Add document title here...',
    SecondInstruction: 'Attach your document: ',
    AttachVideo: 'Attach your video File: ',
    FileRequired: 'A file is required',
    ChooseFile: 'Choose File',
    UploadFile: 'Upload File',
    Uploading: 'Uploading...',
    Uploaded: 'Uploaded!',
    Error: 'Error',
    UploadSuccessful: 'Upload Successful!',
    ErrorUploading: 'Error Uploading File!',
    ErrorImage: 'Error Uploading Image',
    ChooseImage: 'Select An Image',
    Cancel: 'Cancel',
    Submit: 'Submit',
  },
  Login: {
    ErrorLoggingIn: 'Error Logging In',
    Username: 'Username',
    Email: 'Email',
    ForgottenPassword: 'Forgotten Password? Click Here',
    HelloUser: (user) => `Hello ${user}`,
    Login: 'Log In',
    Password: 'Password',
    PasswordMissing: 'Please enter a password',
    UsernameMissing: 'Please enter a username',
    LoginSuccess: 'Login Successful',
  },
  Toolbar: {
    // Also used for sidebar navigation
    PageTitle: (heading) =>
      ({
        Home: 'Dashboard',
        Dashboard: 'Dashboard',
        Messages: 'Messages',
        Content: 'Content',
        Medications: 'Medications',
        Patients: 'Patients',
        VideoCalls: 'Video Calls',
        Admin: 'Admin',
        SuperAdmin: 'Super Admin',
        Payments: 'Payments',
        Settings: 'Settings',
      }[heading]),
    PleaseLogIn: 'Please Log In To Continue',
    Dashboard: 'Dashboard',
    SearchPatients: 'Search Patients...',
    SignOut: 'Sign Out',
    ProfilePhoto: 'Profile photo',
  },
  Dashboard: {
    UnresolvedMessages: 'Unresolved Messages',
    Unresolved: (folder: string) => ({
      Unresolved: `${folder}`,
      Tooltip: `View ${folder} Messages`,
    }),
  },
  UpcomingDrugs: {
    UpcomingDrugs: 'Upcoming Drugs',
    NoDrugs: 'There are no upcoming drugs',
    Name: 'Name',
    Type: 'Type',
    Drug: 'Drug',
    Dosage: 'Dosage',
    Status: 'Status',
    DueAt: 'Due At',
  },
  NonTimedTriggerDrugs: {
    UpcomingDrugs: 'Non Timed Medications',
    NoDrugs: 'There are no upcoming drugs',
    Name: 'Name',
    Type: 'Type',
    Drug: 'Drug',
    Dosage: 'Dosage',
    Status: 'Status',
    DueAt: 'Due At',
    DueDate: 'Due Date',
  },
  Messages: {
    NewMessage: 'New Message',
    NewMessages: 'Show new messages',
    AllUnresolved: 'All unresolved',
    AllResolved: 'All resolved',
    AllMessages: 'All messages',
    SearchMessages: 'Search messages',
    SearchingFor: 'Searching for',
    Search: 'Search',
    Clear: 'Clear',
    Actions: 'Actions',
    Unread: 'Unread',
    SelectAMessage: 'Select a message',
    MarkUnread: 'Mark unread',
    MarkUnresolved: 'Mark unresolved',
    MarkResolved: 'Mark resolved',
    Move: 'Move',
    ChangeLabelTo: 'Move to...',
    WriteAMessage: 'Write a message',
    Enlarge: 'Enlarge',
    Minimise: 'Minimise',
    Send: 'Send',
    SeenBy: 'Seen by',
    On: 'on',
    At: 'at',
    New: 'New',
    Open: 'Open',
    OpenMessageAttachmentFileErrorMessage:
      'Please check your connection and try again',
    OpenMessageAttachmentFileErrorTitle: 'Couldn’t open file',
    SingularTyping: ' is typing...',
    PluralTyping: ' are typing...',
    SingularTyped: ' has entered text...',
    PluralTyped: ' have entered text...',
    Dismiss: 'DISMISS',
    CopyMessageHistory: 'Copy message history',
    HistoryCopied: (patientName: string) =>
      `Message history with ${patientName} sucessfully copied to clipboard`,
    MarkedResolved: 'This conversation was marked as resolved',
    ReadReceipt: (patientName: string, readDate: string) =>
      ` [Read Receipt: Seen by ${patientName} on ${readDate}]`,
    ReadBy: (name: string, time: string, date: string) =>
      `Seen by ${name} at ${time} on ${date}`,
    SentFile: (fileName: string) => `Sent a file – ${fileName}`,
    Seen: 'Seen',
    CopyError:
      // tslint:disable-next-line:max-line-length
      'Unable to copy message history to clipboard. Please check your connection, refresh your browser and try again. If this problem persists, let us know at hello@salveapp.co.uk',
    EndOfConversation: 'End of conversations',
    NoConversations: 'This folder has no unstarred conversations',
    NoStarredConversations: 'This folder has no starred conversations',
    PleaseRefineSearch:
      'Maximum messages returned. Please refine your search criteria.',
    PleaseUseSearch: 'Maximum messages returned. Please use the search box.',
    Subject: 'Subject:',
    ContentMaxLengthError: 'The maximum message length is 2000 characters',
    DeleteMessageItem: 'Delete message',
    DeleteMessageConfirmationTitle: 'Delete message?',
    DeleteMessageConfirmationBody:
      'Are you sure you want to delete this message?',
    DeleteMessageConfirmationBody2:
      'The patient may still be able to see the deleted message’s content in their device’s notifications.',
    DeleteMessageButtonDelete: 'Delete',
    DeleteMessageButtonCancel: 'Cancel',
    DeletedMessageText: (name: string, date: string, time: string) =>
      `This message was deleted from the patient's app by ${name} at ${time} on ${date}`,
    NoMessageTemplates: 'No templates to display, add them in the admin panel',
    NoMatchingMessageTemplates: 'No matching message templates found',
    InsertMessageTemplate: 'Insert message template',
    StarredTab: 'Starred',
    EverythingElseTab: 'Everything else',
    NoSearchResults: 'No search or filter results',
    FoldersChipLabel: 'Folders',
    TeamMembersFilterLabel: 'Patient’s team',
    FolderFilterLabel: 'Folder',
    ResolvedFilterLabel: 'Resolved',
    UnresolvedFilterLabel: 'Unresolved',
    StatusFilterLabel: 'Status',
    FolderFilterSearchPlaceholder: 'Search folders',
    TeamMembersFilterSearchPlaceholder: 'Search team members',
    FilterButtonAriaLabel: 'Filters',
    FilterMenuAriaLabel: 'Filter menu',
    FilterMenuApplyButton: 'Apply',
    FilterLoadingErrorTitle: 'An error occured',
    FilterLoadingErrorMessage: 'Error fetching available filters',
    NoConversationSelected: 'No conversation selected',
    PatientsTeamMember: 'Patient’s team member',
    SearchUsers: 'Search users',
    Me: 'Me',
    Assign: 'Assign',
    Unassign: 'Unassign',
    SearchAssignees: 'Search assignees',
    Assignees: 'Assignees',
    Unassigned: 'Unassigned',
    Mine: 'Mine',
  },
  NewVideoCall: {
    AddDescription: 'Enter a description e.g. First Consultation',
    AddName: 'Add the name of the staff member hosting the call',
    Cancel: 'Cancel',
    CloseCheck: 'Close without setting up a call?',
    Confirm: 'Confirm',
    ConfirmDelete: 'Are you sure you want to delete this video call?',
    Create: 'Create',
    Date: 'Date',
    DateRequired: 'A valid date is required',
    Delete: 'Delete',
    DeleteCall: 'Delete call',
    Description: 'Description',
    DescriptionLength: 'Description should be between 3 and 100 characters',
    DescriptionRequired: 'A description is required',
    Duration: 'Duration (mins)',
    DurationRequired: 'A call duration is required',
    Host: 'Host',
    HostLength: 'Host should be between 3 and 50 characters',
    HostRequired: 'A host is required',
    LocalTime: "Please enter in your clinic's local time",
    PastDate: 'The date cannot be in the past',
    PastTime: 'The time cannot be in the past',
    Patient: 'Patient',
    PatientRequired: 'A patient is required',
    PleaseSelect: 'Type a patient ID or name to search',
    Save: 'Save',
    ServerError: 'A server error has been encountered - Please try again later',
    SetDate: 'Set a date',
    StartTime: 'Start time',
    StartTimeRequired: 'A start time is required',
    TitleEdit: 'Edit Video Call',
    TitleNew: 'New Video Call',
  },
  NewMessage: {
    To: 'To',
    Patient: 'Patient',
    PleaseSelect: 'Please select a valid patient',
    Type: 'Type',
    ChooseMessageType: 'Choose a message type...',
    PatientRequired: 'A patient is required',
    ServerError: 'A server error has been encountered - Please try again later',
    MessageLabelRequired: 'A message label is required',
    WriteYourMessage: 'Write your message',
    PleaseEnterMessage: 'Please enter a message',
    Send: 'Send',
    DiscardTitle: 'Close without sending?',
    DiscardDescription: 'Any entered data will be lost.',
    Cancel: 'Keep writing',
    Confirm: 'Close',
    Subject: 'Subject',
    SubjectPlaceholder: 'Write your subject line (optional)',
    SubjectMaxLengthError: 'The maximum subject length is 256 characters',
    ContentMaxLengthError: 'The maximum message length is 2000 characters',
    Title: 'New Conversation',
    FolderFieldLabel: 'Message Folder',
    FolderFieldPlaceholder: 'Choose a message folder',
    SubjectFieldLabel: 'Subject (optional)',
    SubjectFieldPlaceholder: 'Write a subject line',
    ContentFieldLabel: 'Message',
  },
  Medication: {
    PotentialDupe: 'Potential Duplicated Medication',
    ActionRequired: 'Action Required',
    Name: 'Name',
    Type: 'Type',
    Drug: 'Drug',
    Dosage: 'Dosage',
    Status: 'Status',
    Overdue: (time) => `Overdue - scheduled for ${time}`,
    NoActionRequired: 'No Action Required',
    DueAt: (time) => `Due at ${time}`,
    DueToday: 'Due today',
    WindowClosing: (time) => `${time} minutes to window close`,
    TakenAt: (takenTime, dueTime) => `Taken at ${takenTime} (due ${dueTime})`,
    PotentialDuplicate: (dueDate, scheduledTime) =>
      `Potential duplicate: ${dueDate} ${scheduledTime}`,
    NoAction: 'No medications require action',
    NoMedications: 'No medications to view',
    NoPotentialDuplicates: 'No potentially duplicated medications',
    NoSearchResults: 'No medications match search',
    Search: 'Search',
    Id: 'ID',
    DOB: 'Date of Birth',
    ErrorTitle: 'Unable to load data',
    ErrorMessage: 'Please check your connection and try again',
  },
  ContentList: {
    All: 'All',
    Medications: 'Medications',
    Medication: 'Medication',
    Appointments: 'Appointments',
    Appointment: 'Appointment',
    SearchContent: 'Search Content...',
    Type: 'Type',
    Name: 'Name',
    LastModifiedByHeader: 'Last Modified By - (User) - Date',
    LastModifiedByRow: (user, date) => `(${user}) ${date}`,
  },
  PatientList: {
    SearchPatients: 'Search patient name, ID, DOB (DDMMYYYY)',
    PatientId: 'Patient ID',
    FirstName: 'First Name',
    LastName: 'Last Name',
    SyncDate: 'Registered Date',
    ClearFilter: 'Clear Filter',
    InviteCode: 'Invite code: ',
    DOB: 'Date of Birth',
  },
  PatientInfo: {
    NewMessage: 'New Message',
    PatientInfo: 'Patient information',
    Partner: 'Partner',
    Contact: 'Contact',
    LabResults: 'Lab results',
    ResultRange: 'Range:',
    ResultReceived: 'Result received:',
    ResultInterpretation: 'Result interpretation:',
    NoLabResults: 'No lab results available',
    ManageContent: 'Manage content',
    PatientDocuments: 'Patient documents',
    PatientId: 'Patient ID',
    SyncDate: 'Registered date',
    LastLogin: 'Last login',
    FirstName: 'First name',
    LastName: 'Last name',
    DOB: 'Date of birth',
    Age: 'Age',
    NumberOfCycles: 'Number of cycles',
    NoPartner: 'No partner selected',
    Email: 'E-mail',
    WorkPhoneLandline: 'Work phone (landline)',
    WorkPhoneMobile: 'Work phone (mobile)',
    PersonalPhoneLandline: 'Personal phone (landline)',
    PersonalPhoneMobile: 'Personal phone (mobile)',
    ComingSoon: '...Coming Soon...',
    NewVideoCall: 'New video call',
    Minutes: 'minutes',
    ClinicPhysician: 'Physician',
    Counselor: 'Counsellor',
    Nurse: 'Nurse',
    Accountant: 'Accountant',
    TreatingDr: 'Treating Dr',
  },
  PatientCalendar: {
    Calendar: 'Calendar',
    Key: 'Key',
    Hidden: 'Hidden',
    Medication: 'Medication',
    Appointment: 'Appointment',
    VideoCall: 'Video call',
    Provisional: 'Provisional',
    Back: 'Back',
    Today: 'Today',
    Next: 'Next',
    Days: () => [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    Months: () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    EventLabelAppointment: (dateTime: string, appointmentTitle: string) =>
      `${dateTime} - ${appointmentTitle}`,
    EventLabelAppointmentProvisional: (
      dateTime: string,
      appointmentTitle: string,
    ) => `${dateTime} - ${appointmentTitle} (provisional)`,
  },
  Authentication: {
    WelcomeToSalve: 'Welcome to Salve',
    Core: ' Core',
    ErrorLoggingIn: 'Error Logging In',
    Username: 'Username',
    Email: 'Email',
    ForgottenPassword: 'Forgotten Password? Click Here',
    HelloUser: (user) => `Hello ${user}`,
    Login: 'Log In',
    Password: 'Password',
    PasswordMissing: 'Please enter a password',
    UsernameMissing: 'Please enter a username',
    LoginSuccess: 'Login Successful',
    ResetTitle: 'Reset Your Password',
    ResetButton: 'Reset Password',
    EmailRequired: 'A Valid Email is Required',
    YourCode: 'Your Reset Code',
    CodeRequired: 'A Reset Code is required',
    NewPassword: 'New Password',
    PasswordRequired: 'A New Password is Required',
    PasswordsMustMatch: 'Your Passwords Must Match',
    ConfirmPassword: 'Confirm Password',
    SubmitNewPassword: 'Submit New Password',
    ConfirmNewPassword: 'Confirm New Password',
    // tslint:disable-next-line:quotemark
    CreateYourAccount: "It's time to Create your Account",
    FillInBelow: 'Please fill in the below form and click submit',
    FirstName: 'First Name',
    FirstNameRequired: 'A first name is required',
    LastName: 'Last Name',
    LastNameRequired: 'A last name is required',
    CountryCode: 'Country Code',
    CountryCodeRequired: 'A Country Code is required',
    PhoneNumber: 'Phone Number',
    PhoneNumberRequired: 'A Phone number is required',
    InvalidPhoneNumber: 'Please provide a valid Mobile Phone number',
    CreateAccount: 'Create Account',
    UserNotFoundException: 'Email address not found.',
    NotAuthorizedException: 'Incorrect email or password',
    InvalidPasswordException:
      'Passwords must be a minimum of 8 characters and contain uppercase, lowercase and numbers.',
    InvalidParameterException:
      'Passwords must be a minimum of 8 characters and contain uppercase, lowercase and numbers.',
    CodeMismatchException:
      'Invalid verification code provided.  Please try again.',
    LimitExceededException:
      'Attempt limit exceeded. Try after several minutes.',
    ResetEmail:
      'We have sent you an email with a reset code. Please enter in box below.',
    CodeCancel: 'Cancel',
    CodeMissing:
      'Please enter the valid code. It should have length of 6 characters.',
    Code: 'Enter code',
    OTPTitle: 'Check Your Email',
    OTPSubtitle:
      "We've sent a code to your registered email address. Enter it below to log in.",
    SupportEmail: 'hello@salveapp.co.uk',
    OTPInstructions:
      "The code is active for 15 minutes. Please check your spam if you can't see it in your inbox. Any issues email us at",
    InvalidCode: 'The code is invalid. Please try again.',
    OTPToastTitle: 'Please try logging in again',
    OTPToastMessage:
      'You’ve exceeded the number of login code entry attempts. Please log in to try again. If you’re stuck, email us at hello@salveapp.co.uk.',
  },
  MessageToast: {
    View: 'View',
    Undo: 'UNDO',
    Loading: 'Loading...',
    MessageResolvedTitle: 'Message Resolved',
    MessageResolvedBody: 'Message marked as resolved',
    MessageResolvedUndoneTitle: 'Message Resolved Undone',
    MessageResolvedUndoneBody: 'Message remarked as unresolved',
    MessageUnresolvedTitle: 'Message Unresolved',
    MessageUnresolvedBody: 'Message marked as unresolved',
    MessageUnresolvedUndoneTitle: 'Message Unresolved Undone',
    MessageUnresolvedUndoneBody: 'Message remarked as resolved',
    MessageUnreadTitle: 'Message Unread',
    MessageUnreadBody: 'Message has been marked as unread',
    MessageStarredTitle: 'Conversation starred',
    MessageStarredBody:
      'Conversation has been starred and moved to the Starred tab.',
    MessageStarredTitleError: 'Unable to star conversation',
    MessageStarredBodyError: 'Please check your connection and try again.',
    MessageUnstarredTitle: 'Conversation unstarred',
    MessageUnstarredBody:
      'Conversation has been unstarred and moved to the Everything else tab.',
    MessageUnstarredTitleError: 'Unable to unstar conversation',
    MessageUnstarredBodyError: 'Please check your connection and try again.',
    LabelChangedTitle: 'Label Changed',
    LabelChangedBody: (from, to) =>
      `Message label changed from ${from} to ${to}`,
    MessageMovedTitle: 'Message Moved',
    MessageMovedBody: (from: string, to: string) =>
      `Message moved from ${from} to ${to}`,
    OutboundSentTitle: 'Outbound Message Sent',
    OutboundSentBody: (patientString) =>
      `Message sent successfully to ${patientString}`,
    LabelChangedUndoneTitle: 'Label Change Undone',
    LabelChangedUndoneBody: (from, to) =>
      `Message label changed back to ${to} from ${from}`,
    MessageMovedUndoneTitle: 'Message Move Undone',
    MessageMovedUndoneBody: (from, to) =>
      `Message moved back to ${to} from ${from}`,
    UndoingActionTitle: 'Undoing Action',
    UndoingActionBody: (action) => `Undoing the '${action}' action...`,
    ErrorEncountered: 'Error Encountered',
    ErrorUnread: 'Error marking message as unread',
    ErrorUnresolved: 'Error marking message as unresolved',
    ErrorResolved: 'Error marking message as resolved',
    ErrorChanging: 'Error changing label',
    ErrorSending: (patient) => `Error sending message to ${patient}`,
    SendingOutboundTitle: 'Sending Outbound Message',
    SendingOutboundBody: (patient) => `Sending message to ${patient}`,
    ErrorDeletingMessage:
      'The patient has now read this message, so it cannot be deleted.',
    ErrorDeletingMessageTitle: 'Error deleting message',
    ErrorAssigningMessageTitle: 'Could not assign conversation',
    ErrorUnAssigningMessageTitle: 'Could not unassign conversation',
  },
  ProfilePhotoModal: {
    Title: 'Profile photo',
    SubTitle:
      'Please note that this photo will be visible to patients in the app.',
    SelectPhoto: 'Select a photo',
    DeleteConfirmation: {
      Cancel: 'Cancel',
      Delete: 'Delete',
      Content:
        'Your profile photo will be deleted from the clinic portal and the patient app.',
      Title: 'Are you sure?',
    },
    CloseConfirmation: {
      Cancel: 'Cancel',
      Close: 'Close',
      Content: 'Any changes will not be saved.',
      Title: 'Are you sure?',
    },
    ChangePhoto: 'Change photo',
    Delete: 'Delete',
    Save: 'Save',
    Loading: 'Loading',
    SuccessToast: {
      UpdatedTitle: 'Photo updated successfully',
      UpdatedDescription:
        'Your profile photo has been saved and is now visible in the clinic portal and patient app.',
      DeletedTitle: 'Photo deleted successfully',
      DeletedDescription:
        'Your profile photo has been removed from the clinic portal and patient app.',
    },
    ErrorToast: {
      UploadTitle: 'Profile photo could not be uploaded',
      UpdateTitle: 'Profile photo could not be updated',
      DeleteTitle: 'Profile photo could not be deleted',
      GenericDescription: 'Please check your connection and try again.',
    },
  },
  VideoCallsList: {
    AddFilter: 'Add Filter',
    ClearFilter: 'Clear',
    CreateVideoCall: 'Create video call',
    Description: 'Description',
    DOB: 'Date of Birth',
    Duration: 'Duration',
    Edit: 'Edit',
    HostResource: 'Host/Resource',
    Join: 'Join',
    Locked:
      'This call has been scheduled via the EMR. If you wish to reschedule or cancel this call, please do so in the EMR.',
    More: 'More',
    NoPreviousCalls: 'No previous calls to display',
    NoUpcomingCalls: 'No upcoming calls to display',
    Patient: 'Patient',
    PreviousCalls: 'PREVIOUS CALLS',
    Search: 'Search patient name, ID, DOB (DDMMYYYY)',
    Status: 'Status',
    StatusFinished: 'Finished',
    StatusOngoing: 'Ongoing',
    StatusUpcoming: 'Upcoming',
    UpcomingCalls: 'UPCOMING CALLS',
    When: 'When',
    FilterNoResults: 'No results',
    FilterSearch: 'Search',
    FilterApply: 'Apply',
    FilterCancel: 'Cancel',
    FilterHostResource: 'Host/Resource',
    RefreshTitle: 'Refresh calls list',
  },
  VideoCallsToast: {
    Deleted: 'Video call deleted',
    DeletedSuccessfully: 'The video call was deleted successfully',
    Deleting: 'Deleting video call',
    ErrorDeleting: 'Error deleting video call',
    ErrorEncountered: 'An error was encountered. Please try again.',
    ErrorSaving: 'Error saving video call',
    JoiningVideoCall: 'Joining video call',
    PleaseWait: 'Please wait...',
    PleaseWaitAndAllowAccess:
      'Please wait and allow mic and/or camera access if prompted',
    Saved: 'Video call saved',
    SavedSuccessfully: 'The video call was saved successfully',
    Saving: 'Saving video call',
    Error: 'Error',
  },
  VideoCallModal: {
    Allow: 'Allow',
    AnErrorOccured: 'An error occured',
    AttemptingReconnect: 'Attempting to reconnect to the call',
    AudioInputMenu: 'Audio input',
    AudioOutputMenu: 'Audio output',
    CallEnded: 'Call ended',
    CallNotReconnected: 'The call could not be reconnected',
    Cancel: 'Cancel',
    Connected: 'You are now connected',
    EndCall: 'End call',
    Error: 'Error',
    LeaveCall: 'Leave call',
    Mute: 'Mute',
    NeedPermissions:
      'To use video calling, we first need permission to access your microphone and camera. Please allow access if your browser prompts you.',
    NoCameraMic: 'A microphone and/or camera is required to join a call',
    NooneHere: (name) =>
      `There's no one here at the moment. Please wait until ${name} joins in.`,
    Ok: 'OK',
    ParticipantDisconnected: 'Participant disconnected',
    ParticipantDisconnectedMessage: 'A participant disconnected from the call',
    Reconnecting: 'Reconnecting',
    StartCamera: 'Start camera',
    StopCamera: 'Stop camera',
    Success: 'Success',
    SureLeave: 'Are you sure you want to leave this call?',
    Unmute: 'Unmute',
    VideoMenu: 'Video',
    Yes: 'Yes',
  },
  VideoCallsInvitePartner: {
    InviteButton: 'Invite partner',
    InvitePartner: 'Invite Partner?',
    InviteText1:
      'This will add the video call to the partner’s appointment timeline. This may take a few minutes to appear in the app for the partner.',
    InviteText2:
      'Please note you will not be able to uninvite the partner if the call is already taking place.',
    Cancel: 'Cancel',
    Invite: 'Invite',
    PartnerInvited: 'Partner invited',
    PartnerInvitedText:
      'This video call has been added to the partner’s appointment timeline. It may take a few minutes to show up in their app',
    UnInviteButton: 'Uninvite partner',
    UnInvitePartner: 'Uninvite Partner?',
    UnInviteText1:
      'This will remove the video call from the partner’s appointment timeline and they will no longer be able to join.',
    UnInviteText2:
      'Please note you will not be able to uninvite the partner if the call is already taking place.',
    UnInvite: 'Uninvite',
    PartnerUninvited: 'Partner uninvited',
    PartnerUnInvitedText:
      'The video call has been removed from the partner’s appointment timeline and they will no longer be able to join. Please note it may take up to 5 minutes to be removed.',
    InvitePartnerInCall:
      'Please note this video call will take up to 5 minutes to appear in their appointment timeline.',
    InvitePartnerInCallText:
      'Please note that the video call will take up to 5 minutes to be shown in their appointment timeline in the app. ',
    OK: 'OK',
    UnInvitePartnerInCall:
      'Please note this video call will take up to 5 minutes to be removed from their appointment timeline.',
    UnInvitePartnerInCallText1:
      'The video call has been removed from the partner’s appointment timeline and they will no longer be able to join.',
    UnInvitePartnerInCallText2:
      'Please note it may take up to 5 minutes to be removed.',
    PartnerNotFound: 'Partner not found',
    PartnerNotFoundText1:
      'There is no partner associated with this patient in Salve. ',
    PartnerNotFoundText2:
      'If this patient has a partner, their partner needs to first download the app and create their own account.',
    PartnerNotFoundText3: 'Once they’ve done this, please try again.',
    PartnerNotFoundText4:
      'If you believe this message is in error, please contact Salve support at hello@salveapp.co.uk.',
    CouldNotInvite: 'Could not invite partner',
    CouldNotInviteErrorMessage: 'Please check your connection and try again.',
    CouldNotUnInvite: 'Could not uninvite partner',
    CouldNotUnInviteErrorMessage: 'Please check your connection and try again.',
    CallInProgressUnInviteMessage: 'This call is already taking place.',
  },
  SessionExpired: {
    Title: 'You have been logged out',
    Message: 'Your session has expired. Please log in again',
  },
  MessagingNotifications: {
    BrowserTabTitle: (messagesLength: string) =>
      `(${messagesLength}) Clinic Portal`,
    NotificationBody: (messagesLength: string) =>
      `You have received ${messagesLength} new messages`,
    NotificationBodyMessage: (from: string) =>
      `You have a new message from ${from}`,
    NotificationTitle: 'New Messages Received',
  },
  Admin: {
    AppointmentTypeNavItem: 'Appointments',
    AppointmentTypeListSearchPlaceholder: 'Search appointments...',
    AppointmentTypeListTableHeaderId: 'ID #',
    AppointmentTypeListTableHeaderName: 'Name',
    AppointmentTypeListTableHeaderStatus: 'Status',
    AppointmentTypeListTableChipActive: 'Active',
    AppointmentTypeListTableChipHidden: 'Hidden',
    AppointmentTypeListTableChipProvisional: 'Provisional',
    AppointmentTypeListTableChipStatusFilter: 'EMR status filter',
    AppointmentTypeHeaderId: 'ID #',
    AppointmentTypeCaptionId: 'Appointment type ID number from the EMR',
    AppointmentTypeHeaderCreatedAt: 'Created at',
    AppointmentTypeCaptionCreatedAt:
      'When the appointment type was created in the EMR',
    AppointmentTypeHeaderActive: 'Show in Salve',
    AppointmentTypeCaptionActive:
      'Shown in patient schedules and available for content editing',
    AppointmentTypeHeaderHidden: 'Always hidden',
    AppointmentTypeCaptionHidden: 'Always hidden from patients in the app',
    AppointmentTypeHeaderProvisional: 'Provisional until',
    AppointmentTypeCaptionProvisional:
      'Will be marked as provisional in the patient app until shown time',
    AppointmentTypeValueProvisional: (duration: string) => `${duration} before`,
    AppointmentTypeHeaderStatusFilters: 'EMR status filter',
    AppointmentTypeCaptionStatusFilters:
      'Hidden until specific status applied to booked appointment in EMR',
    AppointmentTypeHeaderVideoCall: 'Convert to video call',
    AppointmentTypeCaptionVideoCall:
      'Convert all booked appointments of this type into video calls. This option should only be used on appointment types that will be booked exclusively for video calls. <b>Important:</b> This will only convert any appointments of this type that are booked <u>after</u> you turn on the setting. Any appointments of this type booked prior to your turning it on will not be converted.',
    AppointmentTypeValueYes: 'Yes',
    AppointmentTypeValueNo: 'No',
    AppointmentTypeLoadingErrorToastHeader: 'Network error',
    AppointmentTypeLoadingErrorToastMessage: 'Error loading appointment type',
    AppointmentTypeProvisionalHoursInputErrorRequired:
      'Please enter at least one digit in the field above',
    AppointmentTypeProvisionalHoursInputLabel1: 'Provisional until:',
    AppointmentTypeProvisionalHoursInputLabel2:
      'hours before scheduled appointment time',
    AppointmentTypeListErrorToastMessage: 'Error loading appointment types',
    AppointmentTypeSavingErrorHeader: 'Unable to save changes',
    AppointmentTypeSavingErrorMessage:
      'Please check your connection and try again',
    AppointmentTypeSavingSuccessHeader: 'Changes saved successfully',
    AppointmentTypeSavingSuccessMessage:
      'Your changes have been saved successfully. Please allow up to 10 minutes for these to be visible.',
    MessageTemplatesContentErrorLength: (max: number) =>
      `Template content must be within ${max} characters`,
    MessageTemplatesContentErrorRequired: 'Template content is required',
    MessageTemplatesDeleteButtonText: 'Delete',
    MessageTemplatesDeleteDialogAcceptButtonText: 'Delete',
    MessageTemplatesDeleteDialogCancelButtonText: 'Cancel',
    MessageTemplatesDeleteDialogContent:
      'If you delete this template, it will no longer be available for use in conversations. This cannot be undone.',
    MessageTemplatesDeleteDialogTitle: 'Are you sure?',
    MessageTemplatesEmptyMessage: 'You have no message templates saved',
    MessageTemplatesEmptyNonMatchingMessage:
      'No matching message templates found',
    MessageTemplatesInsertVariableButtonText:
      '{{{\u00A0\u00A0}}} Insert variable',
    MessageTemplatesLastUpdatedByLabel: 'Last updated by',
    MessageTemplatesLoadingErrorMessage:
      'There was an error loading message templates',
    MessageTemplatesLoadingErrorRetryButtonText: 'Try again',
    MessageTemplatesNameErrorLength: (min: number, max: number) =>
      `Template names must be ${min}–${max} characters`,
    MessageTemplatesNameErrorRequired: 'Template name is required',
    MessageTemplatesNameErrorUnique:
      'Name already in use. Please try something else.',
    MessageTemplatesNameLabel: 'Name',
    MessageTemplatesNamePlaceholder: 'E.g. Appointment confirmation',
    MessageTemplatesNavigationWarning:
      'Please remember to save any changes before navigating away.',
    MessageTemplatesNavItem: 'Message templates',
    MessageTemplatesNewTemplateButtonText: 'New Template',
    MessageTemplatesSaveTemplateButtonText: 'Save Template',
    MessageTemplatesSearchPlaceholder: 'Search message templates',
    MessageContentSearchPlaceholder: 'Search content',
    MessageContentSearchNoResults:
      'No content available here. Add it in the Content section.',
    MessageContentSearchSelect: 'Please select a folder or pack to the left',
    MessageContentNoFolders:
      "You don't have content folders. Add them in the Content section.",
    MessageContentNoPacks:
      "You don't have content packs. Add them in the Content section.",
    MessageContentEmptySearch: 'No results match your search',
    MessageContentLoadError: 'Error loading content data',
    MessageContentInsertLink: 'Insert content link',
    MessageTemplatesTemplateContentLabel: 'Template content',
    NoAccessButtonText: 'Back to dashboard',
    NoAccessMessage:
      'You don’t have permission to access this area of the portal. If you think this is in error, please contact your line manager.',
    SIDE: {
      NavItem: 'SIDE',
      SearchBarPlaceholder: 'Search SIDE recipes...',
      RecipeListTableHeaderId: 'ID',
      RecipeListTableHeaderName: 'Name',
      RecipeListTableHeaderStatus: 'Status',
      RecipeListTableHeaderCreatedOn: 'Created on',
      RecipeListTableHeaderLastUpdatedOn: 'Last updated on',
      RecipeListTableHeaderLastUpdatedBy: 'Last updated by',
      RecipeListLoadingErrorToastHeader: 'Network Error',
      RecipeListErrorToastMessage: 'Error loading SIDE recipes',
      RecipeListTableHeaderAction: 'Action',
      RecipeListTableHeaderEventType: 'Event type',
      AppointmentEvent: 'Appointment',
      MedicationEvent: 'Medication',
      RegistrationEvent: 'Registration',
      ActiveStatus: 'Active',
      InactiveStatus: 'Inactive',
      RecipeListEmptyMessage: 'You have no SIDE recipes set up',
      RecipeLoadingErrorToastHeader: 'Network error',
      RecipeLoadingErrorToastMessage: 'Error loading Recipe',
      SettingsHeader: 'Settings',
      RecipeNameLabel: 'Name',
      RecipeStatusLabel: 'Status',
      ActionListTableHeaderId: 'ID',
      ActionListTableHeaderContent: 'Content',
      ActionListTableHeaderActionType: 'Action type',
      ActionListTableHeaderTiming: 'Timing',
      ActionListTableHeaderMedicationStatus: 'Medication status',
      ActionListTableHeaderActionStatus: 'Action status',
      ActionListTableHeaderCreatedOn: 'Created on',
      ActionListTableHeaderLastUpdatedOn: 'Last updated on',
      ActionListTableHeaderLastUpdatedBy: 'Last updated by',
      ActionListTableHeaderAppointmentStatus: 'Appt. Status',
      ActionListTableHeaderSite: 'Site',
      ActionHeader: 'Actions',
      ActionListEmptyMessage: 'The recipe has no actions set up',
      'Not setAppointmentStatus': 'Not Set',
      AttendedAppointmentStatus: 'Attended',
      'No-ShowAppointmentStatus': 'No-Show',
      CancelledAppointmentStatus: 'Cancelled',
      'Before AppointmentAppointmentStatus': 'Before Appointment',
      'After AppointmentAppointmentStatus': 'After Appointment',
      ConfirmedAppointmentStatus: 'Confirmed',
      'Not CancelledAppointmentStatus': 'Not Cancelled',
      BookedAppointmentStatus: 'Booked',
      BookedMedicationStatus: 'Booked',
      HourPeriod: 'hour',
      MinutePeriod: 'minute',
      DayPeriod: 'day',
      Before: 'before',
      After: 'after',
      ActionListLoadingErrorToastHeader: 'Network Error',
      ActionListErrorToastMessage: 'Error loading actions',
      AppointmentTriggerHeader: 'Affected appointment types',
      MedicationTriggerHeader: 'Affected medication types',
      TriggerListLoadingErrorToastHeader: 'Network Error',
      TriggerListErrorToastMessage: 'Error loading triggers',
      NoTriggersAvailable: 'No triggers available',
      ExportCSV: 'Export CSV',
      ExportDialogTitle: 'Export All SIDE data?',
      ExportDialogDescription:
        'Please note that no filters applied will be present in the CSV export',
      ExportDialogCancel: 'Cancel',
      ExportDialogDownload: 'Download',
      SIDEExport: 'SIDE Export',
      ExportErrorTitle: 'Error downloading export',
      ExportErrorDescription: 'Please check your connection and try again',
      AddFilter: 'Add Filter',
      FilterApply: 'Apply',
      FilterTabEventType: 'Event type',
      FilterTabStatus: 'Status',
      FilterTabAppointmentType: 'Appointment types',
      FilterTabDrugType: 'Drug types',
      FilterApptSearchBarPlaceholder: 'Search appointment types',
      FilterDrugSearchBarPlaceholder: 'Search drug types',
      ClearAllFilter: 'Clear all',
      RecipeNameRequiredErrorMessage: 'Recipe name is required.',
      RecipesSavingErrorHeader: 'Error updating recipe',
      RecipesSavingErrorMessage: 'Please check your connection and try again.',
      RecipesSavingSuccessHeader: 'SIDE recipe updated successfully',
      RecipesSavingSuccessMessage: 'Recipe has been successfully updated',
      RecipeNameErrorLength: (min: number, max: number) =>
        `Recipe names must be ${min}-${max} characters`,
      RecipeSaveButtonText: 'Save',
      RecipeTriggerInfo:
        'Affected appointment types cannot be edited for auditing and accountability purposes. If you need to make changes to these, please mark this recipe as inactive and create a new one.',
      RecipeUniqueNameError: 'Recipe name already in use.',
    },
    Users: {
      NavItem: 'Users',
      SearchBarPlaceholder: 'Search users',
      UsersListEmptyMessage: 'No users found',
      UsersListEmptyActiveUsers: 'You have no active users',
      UsersListEmptyInvitedUsers: 'You have no invited users',
      UsersListEmptyInactiveUsers: 'You have no inactive users',
      UsersListEmptyUsers: 'No results found',
      ActiveStatus: 'Active',
      DeactivatedStatus: 'Inactive',
      InvitedStatus: 'Invited',
      UsersListTableHeaderFirstName: 'First Name',
      UsersListTableHeaderLastName: 'Last Name',
      UsersListTableHeaderEmail: 'Email',
      UsersListTableHeaderStatus: 'Status',
      UsersListTableHeaderLastLogIn: 'Last log in',
      UsersListTableHeaderRegistered: 'Registered',
      UsersLoadingErrorToastHeader: 'Network error',
      UsersLoadingErrorToastMessage: 'Error loading Users',
      AddFilter: 'Add Filter',
      FilterApply: 'Apply',
      FilterTabStatus: 'Status',
      UsersExport: 'Salve Users Export',
      ExportCSV: 'Export CSV',
      ExportDialogTitle: 'Export all users?',
      ExportDialogDescription:
        'Please note that this CSV export will contain the latest sync of all staff members that have been recorded in your EMR, even those who have not created a Salve account.',
      ExportDialogCancel: 'Cancel',
      ExportDialogDownload: 'Export',
      ExportErrorTitle: 'Could not export CSV',
      ExportErrorDescription: 'Please check your connection and try again',
      ExportSuccessTitle: 'Exported successfully',
      ExportSuccessDescription: 'Please see CSV in your downloads',
      InviteUserAction: 'Invite User',
      DeactivateUserAction: 'Remove access to Salve',
      InviteUserErrorTitle: 'Could not perform action',
      InviteUserErrorDescription: 'Please check your connection and try again.',
      InviteUserSuccessTitle: 'Success Title',
      InviteUserSuccessDescription: 'Success Description',
      DeactivateUserErrorTitle: 'Could not perform action',
      DeactivateUserErrorDescription:
        'Please check your connection and try again.',
      InviteUserModalCancel: 'Cancel',
      InviteUserModalAccept: 'Invite',
      DeactivateUserModalTitle:
        'Are you sure you would like to remove access to Salve for this user?',
      DeactivateUserModalText: `Please note that this will prevent the users from being able to log in to the portal.
      Should you wish to give the users access to the portal again, they will need to be invited again.`,
      DeactivateUserModalCancel: 'Cancel',
      DeactivateUserModalAccept: 'Remove access',
      TooltipActive: 'Staff member has access to the Salve portal.',
      TooltipInactive: 'Staff member does not have access to the Salve portal.',
      TooltipInvited: 'Staff member has been invited to the Salve portal.',
      InviteUser: 'Invite user',
      User: 'User',
      WelcomeMessage: 'Welcome message',
      Send: 'Send',
      Cancel: 'Cancel',
      InviteSentTitle: 'Invite sent',
      InviteSentMessage: 'This invite has been sent to the user.',
      InviteSentErrorTitle: 'Could not invite user',
      InviteSentErrorMessage:
        'Please check your connection and try again. Please also ensure the user’s email is correct.',
      DeactivateSentTitle: 'Inactive user',
      DeactivateSentMessage: 'The user has been inactivated.',
      DeactivateSentErrorTitle: 'Could not deactivate user',
      DeactivateSentErrorMessage: 'Please check your connection and try again.',
      InviteMessage: (clinicURL) => `
Hi there,\n
Welcome to Salve! If you already have a Salve account for a different clinic portal, please use your existing log in details when accessing this portal.\n
If you are new to Salve, we've sent you a separate email with your username and temporary password. Please use those to log in and create your permanent account in your portal: ${clinicURL}\n
Your temporary password will expire in three days. If you do not set up your account within those three days, you will need to request a new temporary password from us.\n
Please note that you need to access the Salve portal via Google Chrome or Microsoft Edge. Salve is not compatible with Internet Explorer.
      `,
      SendTemporaryPassword: 'Resend new temporary password',
      SendTemporaryPasswordModalTitle: 'Send new temporary password?',
      SendTemporaryPasswordModalText:
        'Clicking ‘Send’ will send the staff member a new temporary password to their email.',
      SendTemporaryPasswordErrorTitle: 'Could not send new temporary password',
      SendTemporaryPasswordErrorDescription:
        'Please check your connection and try again.',
      SendTemporaryPasswordSuccessTitle: 'New temporary password sent',
      SendTemporaryPasswordSuccessDescription: `The staff member has been sent a new temporary password.`,
    },
  },
  SuperAdmin: {
    DataExport: {
      Title: 'Data Export',
      StartDateLabel: 'Start Date',
      EndDateLabel: 'End Date',
      TimeFrameLabel: 'Time Frame',
      RecipeLabel: 'Recipe',
      Today: 'Today',
      Yesterday: 'Yesterday',
      Last7Days: 'Last 7 days',
      LastWeek: 'Last week',
      ThisMonth: 'This month',
      LastMonth: 'Last month',
      Last12Months: 'Last 12 months',
      CustomDates: 'Custom dates',
      MessagesTitle: 'Messages',
      MessagesSubtitle:
        'Export will contain details of conversations between clinic staff and patients in a selected time frame',
      MessagesFilenameSuffix: 'Salve Messaging Data',
      SIDERecipeTitle: 'SIDE Data',
      SIDERecipeSubtitle:
        'Export will contain data based on the selected SIDE recipe and its contained actions',
      SIDERecipeFilenameSuffix: 'Salve Recipe Data',
      SIDERecipeInactive: 'Inactive',
      ToastSuccessTitle: 'Export successful',
      ToastSuccessDescription:
        'Requested data has been exported successfully. Please check your downloads folder.',
      ToastFailureTitle: 'Export failed',
      ToastFailureDescription: 'Please check your connection and try again',
    },
    NoAccessButtonText: 'Back to dashboard',
    NoAccessMessage:
      'You don’t have permission to access this area of the portal. If you think this is in error, please contact your line manager.',
  },
  General: {
    OpenAttachmentFileErrorTitle: 'Couldn’t open file',
    OpenAttachmentFileErrorMessage:
      'Please check your connection and try again',
    ConnectionError: 'Please check your connection and try again',
  },
  GlobalCMS: {
    Back: 'Back',
  },
};
