import { CognitoWrapperService } from 'src/app/core/services/congito.wrapper.service';

export interface HttpClientBaseOptions {
  clinicToken: string;
  authService: CognitoWrapperService;
  onUnauthorized: () => void;
  baseUrl: string;
}

export class HttpClientBase {
  protected _clinicToken: HttpClientBaseOptions['clinicToken'];
  protected _authService: HttpClientBaseOptions['authService'];
  protected _onUnauthorized: HttpClientBaseOptions['onUnauthorized'];
  protected _baseUrl: HttpClientBaseOptions['baseUrl'];

  constructor(options: HttpClientBaseOptions) {
    this._clinicToken = options.clinicToken;
    this._authService = options.authService;
    this._onUnauthorized = options.onUnauthorized;
    this._baseUrl = options.baseUrl;
  }

  /**
   * Essentially a wrapper around `fetch` that sets some mandatory headers
   * and behavior.
   */
  protected async fetch(
    path: string,
    /** Force `headers` to be a simple object for ease of use */
    options?: RequestInit & { headers?: Record<string, string> },
  ) {
    const authUser = await this._authService.getAuthSession();

    // Merge headers for the individual request with mandatory ones
    const headers: Record<string, string> = {
      ...(options != null && options.headers != null ? options.headers : {}),
      accept: 'application/json',
      authorization: authUser.getIdToken().getJwtToken(),
      'x-salve-clinic-token': this._clinicToken,
    };
    const response = await fetch(`${this._baseUrl}${path}`, {
      ...options,
      headers,
    });

    if (response.status === 401) {
      this._onUnauthorized();
      throw new Error('Unauthorized');
    }

    if (response.ok) {
      try {
        const content = await response.text();
        return content.length > 0 ? JSON.parse(content) : undefined;
      } catch (err) {
        throw new Error(`Error parsing body as JSON: ${err.message}`);
      }
    }

    throw new Error('Network response was not ok');
  }
}
