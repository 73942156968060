import styled from '@emotion/styled';
import { MessageEditor } from '@react/components';
import { useTranslations } from '@react/lib/i18n';
import { Conversation } from '@react/types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  EMPTY_CONVERSATION_STATE,
  MESSAGE_MAX_LENGTH,
  MESSAGE_MIN_LENGTH,
} from '../constants';
import { ConversationCache } from '../hooks';

export interface ChatInputProps {
  conversation: Conversation;
  onSubmit: (value: string) => Promise<void>;
  onChange: (value: string) => void;
  disabled?: boolean;
  getInitialConversationState: ConversationCache['getInitialConversationState'];
  setConversationState: (messageId: number, message: string) => void;
}

export const ChatInput: FunctionComponent<ChatInputProps> = ({
  conversation,
  onSubmit,
  onChange,
  disabled,
  getInitialConversationState,
  setConversationState,
}: ChatInputProps) => {
  const { t } = useTranslations();
  const [messageValue, setMessageValue] = useState(EMPTY_CONVERSATION_STATE);

  useEffect(() => {
    setMessageValue(getInitialConversationState(conversation.messageid));
  }, [conversation.messageid]);

  const handleChange = useCallback(
    (value: string) => {
      setMessageValue(value);
      setConversationState(conversation.messageid, value);
      onChange(value);
    },
    [conversation.messageid, onChange, setMessageValue, setConversationState],
  );

  const handleSubmit = useCallback(async () => {
    setConversationState(conversation.messageid, EMPTY_CONVERSATION_STATE);
    setMessageValue(EMPTY_CONVERSATION_STATE);
    await onSubmit(messageValue);
    onChange(EMPTY_CONVERSATION_STATE);
  }, [
    messageValue,
    conversation.messageid,
    setConversationState,
    setMessageValue,
  ]);

  return (
    <ChatInputStyled className="chat-input">
      <MessageEditor
        maxLength={MESSAGE_MAX_LENGTH}
        onActionClick={handleSubmit}
        actionText={t.Messages.Send}
        isActionActive={messageValue.length >= MESSAGE_MIN_LENGTH}
        onChange={handleChange}
        value={messageValue}
        patientId={conversation.patientid}
        disabled={disabled}
      />
    </ChatInputStyled>
  );
};

const ChatInputStyled = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
