<ng-template #customHeaderTemplate let-days="days" let-locale="locale">
  <div class="cal-cell-row cal-header">
    <div
      class="cal-cell"
      *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
      [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture"
      [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass"
    >
      {{ getDate(day.date) }}
    </div>
  </div>
</ng-template>

<div class="calendar-container" fxLayout="column" fxLayoutAlign="start stretch">
  <div
    class="calendar-controls"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h4 class="calendar-month">{{ dateDisplayed }}</h4>
    <div class="controls">
      <button mat-stroked-button class="calendar-button" (click)="decrement()">
        {{ text.Back }}
      </button>
      <button mat-stroked-button class="calendar-button" (click)="today()">
        {{ text.Today }}
      </button>
      <button mat-stroked-button class="calendar-button" (click)="increment()">
        {{ text.Next }}
      </button>
    </div>
  </div>
  <mwl-calendar-month-view
    [headerTemplate]="customHeaderTemplate"
    [activeDayIsOpen]="activeDayIsOpen"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [dayModifier]="addBadgeTotal"
    [excludeDays]="exclude"
    weekStartsOn="1"
    (dayClicked)="dayClicked($event.day)"
  >
  </mwl-calendar-month-view>
  <div
    class="key"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <div>{{ text.Key }}:</div>
    <div class="key-hidden">{{ text.Hidden }}</div>
    <div class="key-medicine">{{ text.Medication }}</div>
    <div class="key-appointment">{{ text.Appointment }}</div>
    <div *ngIf="videoCallsEnabled" class="key-video-call">
      {{ text.VideoCall }}
    </div>
    <div *ngIf="hasProvisionalAppointments" class="key-provisional">
      {{ text.Provisional }}
    </div>
  </div>
</div>
