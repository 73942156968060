export const translationDe = {
  Languages: {
    English: 'English',
    German: 'Deutsch',
    Spanish: 'Español',
    Portuguese: 'Português',
  },
  // New Content Sections
  // =====================================
  ClinicStatus: {
    SalveConnection: 'Salve Verbindung',
    Tooltip: (version) =>
      `Salve Verbindung, email hello@salveapp.co.uk. Salve versión: ${version}`,
  },
  NewContentList: {
    ThisFolder: (folder) => `${folder} Datei`,
    SearchContent: (folder) => `Inhalt ${folder} suchen...`,
    ThisPack: (pack) => `${pack} Inhaltspaket`,
    FilterPack: (pack) => `Inhaltspaket ${pack} filtern...`,
    AddItem: 'Artikel hinzufügen',
    Title: 'Titel Inhalt',
    Comments: 'Kommentare',
    DateChanged: 'Datum geändert',
    UpdatedBy: 'Zuletzt aktualisiert von',
    NoContent: 'Kein Inhalt gefunden',
    Empty: 'Leer',
    Migrated: 'Migriert',
    DeleteItems: 'Artikel löschen',
    MoveItems: 'Artikel verlegen',
    Actions: 'Maßnahmen',
    LinkedToEMR: 'Inhalte aus KAS-Daten generiert',
    Translated: 'Die Inhalte wurden übersetzt',
    TranslatedRow: (languages: string) =>
      `Derzeitige Übersetzungen: ${languages}. Zum Bearbeiten auf das Symbol klicken.`,
    ViewLinks: 'Links ansehen',
    ItemsPerPage: 'Elemente pro Seite',
    FirstPage: 'Erste Seite',
    PreviousPage: 'Vorherige Seite',
    NextPage: 'Nächste Seite',
    LastPage: 'Letzte Seite',
    ContentHasLink:
      'Der Inhalt ist mit Inhalten aus anderen Verzeichnissen verknüpft',
    NoChildren: 'Kein untergeordnetes Verzeichnis gefunden',
    // tslint:disable-next-line:max-line-length
    LinkedAndSynced:
      'Keine Aktionen verfügbar für Elemente, die mit einem Verzeichnis verknüpft und mit KAS-Daten synchronisiert wurden',
    Link: 'Elemente verknüpfen',
    LinkContent: 'Inhalte verknüpfen',
    ContentReplaced:
      // tslint:disable-next-line:quotemark
      'Der Inhalt des "Unterverzeichnisses" wird durch den Inhalt des "Hauptverzeichnisses" ersetzt.',
    LinkingContent: 'Inhalte verknüpfen...',
    LinkedSuccess: 'Inhalte erfolgreich verknüpft',
    LinkingError: 'Fehler bei der Verknüpfung von Inhalten',
    ContentLink: 'Verknüpfte Inhalte',
    CtoPDesc: (parentName) =>
      `Der Inhalt für ${parentName} erscheint anstelle der folgenden Elemente: `,
    RemoveSelectedLinks: 'Ausgewählte Verlinkung entfernen',
    RemoveLink: 'Verlinkung entfernen',
    RemoveLinks: 'Verknüpfungen entfernen',
    RemoveConfirm:
      'Sind Sie sicher, dass Sie diese Verknüpfung entfernen möchten?',
    RemoveSomeLinks: 'Einige Verknüpfungen entfernen',
    RemoveAllLinks: 'Alle Verknüpfungen entfernen',
    RemoveSomeConfirm:
      'Sind Sie sicher, dass Sie diese Verknüpfungen für diesen Inhalt entfernen möchten?',
    RemoveAllConfirm:
      'Sind Sie sicher, dass Sie alle Verknüpfungen für diesen Inhalt entfernen möchten?',
    RemovingLink: 'Verknüpfung entfernen...',
    RemovingLinks: 'Verknüpfungen entfernen...',
    RemoveLinkSuccess: 'Verknüpfung erfolgreich entfernt!',
    RemoveLinksSuccess: 'Verknüpfungen erfolgreich entfernt!',
    RemoveLinkError: 'Fehler bei der Entfernung der Verknüpfung',
    RemoveLinksError: 'Fehler bei der Entfernung der Verknüpfungen',
    To: 'Nach',
    Parent: 'Hauptverzeichnis',
    Child: 'Unterverzeichnis',
    FilterContent: 'Inhalte filtern',
    MovedItemsSuccess: 'Einträge erfolgreich verlegt',
    MovingItems: 'Einträge verlegen…',
    ErrorMovingItems: 'Fehler beim Verlegen der Artikel',
    DeleteFolder: 'Datei löschen',
    MoveConfirmation: 'Sind Sie sicher,daß Sie diese Artikel verlegen möchten?',
    WhichFolder:
      'Bitte wählen Sie eine Datei aus,in die Sie die Artikel verlegen möchten: ',
    ChooseAFolder: 'Wählen Sie eine Datei aus',
    PleaseChoose: 'Bitte wählen Sie eine Datei aus',
    DeleteFolderConfirmation:
      'Sind Sie sicher,daß Sie diese Datei löschen möchten?',
    DeleteItemsConfirmation:
      'Sind Sie sicher, daß Sie diese Artikel löschen möchten?',
    DeleteItemsAdditionalInfo:
      'Wenn mit diesem Inhalt Übersetzungen verknüpft sind, werden diese ebenfalls gelöscht.',
    DeleteItemBlock: (itemName) =>
      `Sie können "${itemName}" nicht löschen, da es mit einem anderen Inhalt verbunden ist. Um es zu löschen, müssen Sie zunächst die Verknüpfung der Inhalte trennen`,
    Delete: 'Löschen',
    Move: 'Verlegen',
    Cancel: 'Abbrechen',
    OK: 'OK',
    Save: 'Speichern',
    FolderName: 'Datei Name',
    ChangeFolderName: 'Ordnername ändern',
    FolderRequired: 'Ein Datei Name ist erforderlich',
    FolderTranslationRequired:
      'Vorhandene Übersetzungen dürfen nicht unausgefüllt bleiben',
    FolderExists: 'Dieser Datei Name existiert bereits',
    FolderLength: 'Der Name muss zwischen 3 und 50 Zeichen lang sein',
    ChangingFolder: 'Namen ändern...',
    ChangingFolderSuccess: 'Erfolgreich geändert!',
    ChangingFolderError: 'Fehler bei der Änderung des Namens',
    // Multilingual
    Translations: 'Übersetzungen',
    PressMe: 'Hier klicken ',
    IconFolder: ' Schaltfläche um Ihren erste Datei mit Inhalt zu erstellen',
    // New Translations
    PackName: 'Paket-Name',
    WhatPackName:
      'Bitte wählen Sie einen neuen, unverwechselbaren Paket-Namen: ',
    PackNameRequired: 'Ein Paket-Name ist erforderlich',
    PackExists: 'Dieser Paket-Name existiert bereits',
    PackNameLength: 'Der Paket-Name muss zwischen 3 und 50 Zeichen lang sein',
    ContentCategory: 'Kategorie des Inhalts',
    IsFileOnly: 'Nur Datei',
    DeletePack: 'Paket mit Inhalten löschen',
    DeletePackConfirmation:
      'Sind Sie sicher, dass Sie dieses Paket löschen möchten?',
    RemovePackItems: 'Aus dem Paket entfernen',
    RemovePackItemsConfirmation:
      'Sind Sie sicher, dass Sie diese Elemente aus dem Paket entfernen möchten?',
    Remove: 'Entfernen',
    AddToPack: 'Zum Paket hinzufügen',
    AddToPackDescription:
      'Die folgenden Elemente werden dem ausgewählten Paket hinzugefügt: ',
    AddingToPack: 'Inhalte werden dem Paket hinzugefügt...',
    AddedSuccessfully: 'Inhalte wurden erfolgreich dem Paket hinzugefügt',
    AddingToPackError: 'Fehler beim Hinzufügen von Inhalten in das Paket!',
    SelectAPack: 'Auswahl eines Pakets mit Inhalten: ',
    PleaseChoosePack: 'Bitte wählen Sie ein Paket...',
    SendToAll: 'Dieses Paket wird automatisch an alle Patienten versandt',
    SendTo: 'Senden an',
    AllPatients: 'Alle',
  },
  NoContent: {
    PressAdd: 'Die Inhalt hinzufügen Schaltfläche drücken,um anzufangen',
    AddContent: 'Inhalt hinzufügen',
    Appear: 'Sie scheinen keinen Inhalt zu haben',
  },
  ContentDirectory: {
    Add: 'Hinzufügen',
    CreateNewFolder: 'Eine neue Datei erstellen',
    CreateNewTemplate: 'Eine neue Vorlage erstellen',
    CreateNew: 'Neu erstellen',
    Categories: 'Dateien',
    Templates: 'Vorlagen',
    NoFolders: 'Erstellen Sie Ihre erste Datei',
    NoTemplates: 'Erstellen Sie Ihre erste Vorlage',
    // Translations Required
    CreateNewPack: 'Neues Paket erstellen',
    Packs: 'Pakete',
    NoPacks: 'Erstellen Sie Ihr erstes Paket',
  },
  CreateCategoryOrTemplate: {
    What: 'Was möchten Sie tun?',
    CreateCategory: 'Datei erstellen',
    CreateTemplate: 'Vorlage erstellen',
    Organise: 'Organisieren Sie Ihren Inhalt in Dateien',
    MakeItEasy:
      'Machen Sie es leicht, den Inhalt Ihrer Klinik zu finden,zu erstellen und zu verteilen',
    Uniformity:
      'Vorlagen helfen Ihnen dabei,ein gleichmäßiges Erscheinungsbild in Ihrem Inhalt zu erstellen',
    UsefulFor: 'Besonders nützlich für Medikamente und',
    CategoryButton: 'Datei für Inhalt erstellen',
    TemplateButton: 'Vorlage für Inhalt erstellen',
  },
  CreateCategory: {
    CreateCategory:
      'Bitte erstellen Sie einen neuen,speziellen Namen für die Datei: ',
    AddTitle: 'Fügen Sie Ihren Datei Namen hier hinzu...',
    CurrentCategories: 'Gegenwärtige Dateien',
    FolderRequired: 'Ein Datei Name ist erforderlich',
    FolderExists: 'Der Datei Name existiert bereits',
    FolderLength: 'Der Name muss zwischen 3 und 50 Zeichen lang sein',
    CreatingFolder: 'Datei wird erstellt...',
    CreatedFolder: 'Datei erstellt!',
    ErrorCreatingFolder: 'Fehler beim Erstellen der Datei',
    Cancel: 'Abbrechen',
    Create: 'Erstellen',
    // Translations Required
    CreatePack: 'Bitte wählen Sie einen neuen, unverwechselbaren Paket-Namen:',
    AddPackTitle: 'Geben Sie hier Ihren Paket-Namen ein...',
    CurrentPacks: 'Aktuelle Pakete',
    PackRequired: 'Ein Paket-Name ist erforderlich',
    PackExists: 'Dieser Paket-Name existiert bereits',
    PackLength: 'Der Paket-Name muss zwischen 3 und 50 Zeichen lang sein',
    CreatingPack: 'Das Paket wird erstellt...',
    CreatedPack: 'Paket erstellt!',
    ErrorCreatingPack: 'Fehler beim Erstellen des Pakets',
  },
  CreateTemplate: {
    NewTitle: 'Neue Vorlage für den Inhalt',
    Title: 'Vorlage für den Inhalt',
    TemplateTitle: 'Name für die Vorlage für den Inhalt...',
    Required: 'Erforderlich',
    AddNew: 'Neu hinzufügen',
    Save: 'Speichern',
    AddSectionTitle: 'Fügen Sie den Titel für den Abschnitt hinzu',
    CancelConfirmation: 'Vorlage schließem ohne die Änderungen zu speichern?',
    DeleteConfirmation:
      'Sind Sie sicher,daß Sie diese Vorlage löschen möchten?',
    Delete: 'Löschen',
    Confirm: 'Bestätigen',
    Cancel: 'Abbrechen',
    Saving: 'Vorlage wird gespeichert...',
    SavedSuccessfully: 'Erfolgrech gespeichert',
    SavedError: 'Fehler festgestellt',
  },
  CreateContentItem: {
    // tslint:disable-next-line:max-line-length
    AddRich:
      'Fügen Sie reichen Inhalt wie Bilder,Video und Text hinzu indem Sie unseren anwenderfreundlichen Gestalter für Inhalt verwenden',
    UploadPdf:
      'Laden Sie ein PDF oder Word-Dokument für den Patienten hoch, das auf dem Gerät einfach zu lesen ist',
    CreateRich: 'Erstellen Sie reichen Inhalt',
    UsePdf: 'Verwenden Sie eine PDF',
    Recommended: 'empfohlen',
  },
  ContentCreatorTitle: {
    ManageLinks: 'Verknüpfungen verwalten',
    HasChildren: 'Dies ist mit anderen Inhalten verknüpft',
    ParentExists: 'Bitte verweisen Sie auf den Inhalt von ',
    Title: 'Titel',
    AddTitle: 'Fügen Sie Ihren Titel hier hinzu...',
    Subtitle: 'Untertitel',
    PatientTitle: 'Patienten Name',
    AddPatientTitle:
      'Fügen Sie einen Titel hinzu, der für den Patienten sichtbar ist',
    TransferTitle: 'Name aus KAS-Daten synchronisieren',
    AddSubtitle: 'Fügen Sie Ihren Untertitel hier hinzu...',
    UseTemplate: 'Vorlage verwenden',
    ChooseATemplate: 'Wählen Sie eine Vorlage aus',
    TitleLengthError:
      'Der Titel muß sein zwischen <strong>3</strong> und <strong>50</strong> Zeichen',
    TitleRequired: 'Ein Titel ist <strong>erforderlich</strong>',
    TitleUnique:
      'Der Titel muss sein <strong>einzigartig</strong>, dieser Titel existiert bereits.',
    SubtitleLessThan: 'Der Untertitel muss weniger sein als',
    TitleMust: 'Der Titel muss sein',
    TitleIs: 'Ein Titel ist',
    Between: 'zwischen',
    LessThan: 'weniger als',
    TitleExists: ', dieser Titel existiert bereits',
    Unique: 'einzigartig',
    Required: 'erforderlich',
    And: 'und',
    Characters: 'Zeichen',
    TitleChange: 'Synchronisierte Namen müssen in Meditex geändert werden',
    TitlePattern:
      'Der Titel kann nur Buchstaben, Zahlen, Leerzeichen und Unterstriche enthalten',
  },
  ContentCreatorBody: {
    Introduction: 'Einführung',
    AddIntroduction: 'Fügen Sie die Einleitung für Ihren Inhalt hinzu...',
    Required: 'Erforderlich',
    TextOnly: 'Darf nur Text sein.',
    CharsRemaining: 'Verbleibende Zeichen.',
    Video: 'Video',
    AddVideo: 'Video hinzufügen',
    RemoveVideo: 'Video entfernen',
    MinLength: 'Die Einleitung muß etwas Inhalt enthalten.',
  },
  ContentCreatorControls: {
    Back: 'Zurück',
    Cancel: 'Abbrechen',
    Publish: 'Veröffentlichen',
    Delete: 'Löschen',
    Confirm: 'Bestätigen',
    Leave: 'Gehen',
    AddTranslation: 'Übersetzung hinzufügen',
    PublishDialogInformation:
      'Bitte beachten Sie, dass Änderungen in anderen Sprachen separat veröffentlicht werden müssen.',
    PublishDialogMessage: 'Änderungen veröffentlichen?',
    PublishDialogWithLanguageMessage: (languageName: string) =>
      `Änderungen auf ${languageName} veröffentlichen?`,
    SaveConfirm: 'Sind Sie sicher,daß Sie Ihre Änderungen speichern möchten?',
    ResetConfirm: 'Den Inhalt zurücksetzen ohne die Änderungen zu speichern?',
    DeleteConfirmInformation:
      'Diese Aktion kann nicht rückgängig gemacht werden. Andere Übersetzungen sind davon nicht betroffen.',
    DeleteConfirmMessage:
      'Sind Sie sicher, dass Sie diese Übersetzung löschen möchten?',
    DeleteDefaultConfirmInformation:
      'Der gesamte Inhalt und die verknüpften Übersetzungen werden dadurch gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.',
    DeleteDefaultConfirmMessage:
      'Sind Sie sicher, dass Sie diesen Inhalt löschen möchten?',
    DeleteErrorToastTitle: 'Fehler bei der Löschung von Inhalten',
    DeleteErrorToastDescription:
      'Der Inhalt wurde nicht gelöscht. Bitte versuchen Sie es noch einmal.',
    ExitConfirm:
      'Möchten Sie wirklich gehen, ohne Ihre Änderungen zu speichern?',
    OneAttachment: 'Nur einen Anhang pro Seite mit Inhalt.',
    TitleRequired: 'Ein Titel ist erforderlich um eine Datei hinzuzufügen',
    SendToAll: 'An alle senden',
    UnpublishedChanges: 'Unveröffentlichte Änderungen',
    ErrorToastDescription:
      'Der Inhalt wurde nicht veröffentlicht. Bitte versuchen Sie es erneut.',
    ErrorToastTitle: 'Fehler beim Veröffentlichen von Inhalten',
    SuccessToastDescription: 'Der Inhalt wurde erfolgreich veröffentlicht',
    SuccessToastTitle: 'Inhalt veröffentlicht',
    PublishingContent: 'Inhalt wird veröffentlicht…',
  },
  AddVideoControls: {
    AddVideo: 'Video hinzufügen',
    YoutubeLink: 'Youtube Link',
    WebsiteLink: 'Link zur Webseite',
    Upload: 'Video hochladen',
    AddLink: 'Fügen Sie Ihren Link hier hinzu...',
    SubmitLink: 'Link einreichen',
  },
  ContentCreatorSections: {
    ConfirmDelete: 'Sind Sie sicher,daß Sie diesen Abschnitt löschen möchten?',
    Cancel: 'Abbrechen',
    Confirm: 'Bestätigen',
    AddSectionTitle: 'Den Titel Ihres Abschnitts hinzufügen…',
    InsertText: 'Hier Text hinzufügen...',
    AddSection: 'Abschnitt hinzufügen',
    MaxTitleLength: 'Maximale Lämge des Titels sind 50 Zeichen',
    SectionTitle: 'Titel des Abschnitts',
    CharactersRemaining: 'Verbleibende Zeichen.',
  },
  ContentCreatorComments: {
    Comments: 'Kommentare',
    OnlyVisible: 'Nur intern sichtbar',
  },
  ContentCreatorVersionHistory: {
    VersionHistory: 'Versions Geschichte',
    ComingSoon: '...Kommt demnächst...',
  },
  AddPdf: {
    Title: 'Titel',
    AddTitle: 'Fügen Sie Ihren Titel hier hinzu...',
    AddFile: 'Datei hinzufügen',
    Current: 'Aktueller Anhang',
    Id: 'Id',
    Name: 'Name des Anhangs',
    FileType: 'Datei Typ',
    FileSize: 'Datei Grösse (Bytes)',
    Preview: 'Vorschau',
    CheckFile: 'Datei überprüfen',
    Remove: 'Entfernen',
    Cancel: 'Abbrechen',
    RemoveConfirm: 'Sind Sie sicher,dass Sie diesen Anhang entfernen möchten?',
  },
  UseATemplate: {
    UseATemplate: 'Verwenden Sie eine Vorlage',
    ChooseATemplate: 'Wählen Sie eine Vorlage aus',
    SelectATemplate: 'Bitte wählen Sie eine Vorlage aus',
    Append: 'Vorlagen Abschnitte zu der Schaltfläche hinzufügen',
    Replace: 'Die Abschnitte durch eine neue Vorlage ersetzen',
    WillDelete: 'Ersetzen wird alle gegenwärtigen Abschnitte löschen!',
    Warning: 'Warnung',
    Or: 'ODER',
  },
  UseAContent: {
    Actions: 'Maßnahmen',
    DialogTitle: 'Aus bestehenden Inhalten kopieren',
    DialogGlobalTitle: 'Inhalte vom globalen Portal kopiere',
    DialogDescription:
      'Nur reiche Inhalte können in andere Inhaltsartikel kopiert werden. Inhalte von Dokumenten können in der verfügbaren Liste nicht durchsucht werden.',
    DialogGlobalDescription:
      'When copying content into a document-type content article, only other document-type content articles can be used. Rich content will not be searchable in the available list.',
    ChooseAContent: 'Wählen Sie den Inhalt aus',
    ChooseAGlobalContent: 'Wählen Sie einen globalen Inhaltsartikel',
    SelectAContent: 'Bitte wählen Sie den Inhalt aus',
    SelectAGlobalContent: 'Bitte wählen Sie einen globalen Inhaltsartikel aus',
    Copy: 'Kopieren',
    Cancel: 'Beenden',
    Warning: 'Warnung',
    WarningText:
      'Wenn Sie den ausgewählten Inhalt hier kopieren, werden alle vorhandenen Patiententitel, Einführungen und Videos ersetzt. Zusätzliche Abschnitte werden unter allen bestehenden Abschnitten hinzugefügt.',
    WarningGlobalText:
      'Anything currently entered into this content article will be overwritten by what is being copied including any existing title/patient title, subtitle, introduction, video, sections. This action cannot be undone.',
    WarningGlobalFileText:
      'Anything currently entered into this content article will be overwritten by what is being copied including any existing title/patient title, subtitle, and uploaded file. This action cannot be undone.',
    CopyGlobalContentErrorTitle: '',
    CopyGlobalContentErrorDescription: '',
    CopyGlobalContentTranslationAdditionalTitle: '',
    CopyGlobalContentTranslationAdditionalDescription: (
      listOfLanguages: string,
    ) => '',
    CopyGlobalContentTranslationMissingTitle: '',
    CopyGlobalContentTranslationMissingDescription: (listOfLanguages: string) =>
      '',
  },
  ContentSwing: {
    NoContentAvailable: 'Sie haben keinen Inhalt',
    ToCreate: 'Um Inhalt zu erstellen,klicken Sie auf die Schaltfläche unten: ',
    CreateContent: 'Inhalt erstellen',
    UploadFile: 'Patienten Datei hochladen',
    SearchContent: 'Inhalt suchen...',
    SearchFiles: 'Dateien suchen...',
    ContentType: 'Inhalts Typ',
    Folders: 'Ordner',
    Packs: 'Packungen',
    ViewAll: 'Alle ansehen',
    AvailableContent: (folderName) =>
      `${folderName ? folderName + ' ' : ''}Inhalt`,
    SentDate: (time: string, date: string) => `Gesendet um ${time} am ${date}`,
    ReadDate: (time: string, date: string) =>
      `Lesen Sie am ${date}, um ${time} Uhr`,
    PatientContent: 'Inhalt für ',
    ReadyDocuments: 'Bereit zum versenden',
    PatientDocuments: (patientName: string) =>
      `Dokumente an ${patientName} versendet`,
    Cancel: 'Abbrechen',
    Confirm: 'Bestätigen',
    DeleteConfirm:
      'Sind Sie sicher,daß Sie die Datei dieses Patienten löschen möchten?',
    SearchAssigned: 'Zugewiesene Inhalte suchen...',
    Items: 'Individuelle Bedingungen',
    AssignPackContent: 'Inhalte dem Paket zuordnen',
    LockItem:
      'Verhindern, dass diese Inhalte dem Patienten zur Verfügung gestellt werden',
    AddSelectedItems: 'Paket-Elemente für den Patienten freigeben',
    AssignIndividualItem: 'Dieses Element dem Patienten freigeben',
    SeePatientView: 'Liste der Inhalte aus Sicht des Patientens anzeigen',
    SeeClinicView: 'Liste der Inhalte aus Sicht der Klinik anzeigen',
    ViewInfo: 'Klicken, um mehr über die zugewiesenen Inhalte zu erfahren',
    PackEmpty: 'Dieses Paket ist leer',
    RemovePack: 'Paket aus Profil des Patienten entfernen',
    RemovePackItem:
      'Dieses Paket-Element aus dem Profil des Patienten entfernen',
    ViewPackContents: 'Zugewiesene Pakete anzeigen',
    AllItemsExcluded: 'Alle Elemente sind ausgeschlossen',
    NoAssignedPacks: 'Dem Patienten wurden keine Pakete zugewiesen',
    ViewAssignedPacks: 'Zugewiesene Pakete für diesen Patienten anzeigen',
    ViewIndividualItems: 'Individuell zugewiesene Elemente anzeigen',
    AllItemsAssigned: 'Alle Elemente wurden zugewiesen',
    // need translation
    SendToAllIsAutomatic:
      '"An alle senden" - Elemente werden automatisch allen Patienten zugewiesen',
    NoContentToAssign: 'Alle verfügbaren Inhalte wurden zugewiesen',
  },
  AddFile: {
    DocumentTitle: 'Dokumenten Titel',
    NameRequired: 'Ein Dokument Name ist erforderlich',
    NameLength: 'Die Datei muss zwischen 3 und 50 Zeichen haben',
    NameUnique: 'Der Name des Dokuments sollte einzigartig sein',
    FixUnique:
      'Ein einzigartiger Name ist vor dem Hochladen der Datei erforderlich',
    NamePattern:
      'Der Dokument Name sollte nur Buchstaben,Zahlen,Leerzeichen und Unterstriche enthalten',
    FirstInstruction:
      'Fügen Sie einen patientenfreundlichen Titel für die Anzeige in der App hinzu',
    AddDocTitle: 'Fügen Sie den Titel hier ein...',
    SecondInstruction: 'Fügen Sie Ihr Dokument hinzu: ',
    AttachVideo: 'Fügen Sie Ihre Video Datei hinzu: ',
    FileRequired: 'Eine Datei ist erforderlich',
    ChooseFile: 'Dokument wählen',
    UploadFile: 'Hochladen',
    Uploading: 'Wird hochgeladen...',
    Uploaded: 'Hochgeladen!',
    Error: 'Fehler',
    UploadSuccessful: 'Erfolgreich hochgeladen!',
    ErrorUploading: 'Fehler beim Hochladen der Datei!',
    ErrorImage: 'Fehler beim Hochladen des Bilds',
    ChooseImage: 'Ein Bild auswählen',
    Cancel: 'Abbrechen',
    Submit: 'Einreichen',
  },
  // =====================================
  Login: {
    ErrorLoggingIn: 'Fehler beim Einloggen',
    Username: 'Benutzername',
    Email: 'Email',
    ForgottenPassword: 'Haben Sie Ihr Passwort vergessen?',
    HelloUser: (user) => `Hallo ${user}`,
    Login: 'Anmeldung',
    Password: 'Passwort',
    PasswordMissing: 'Bitte passwort eingeben',
    UsernameMissing: 'Bitte benutzername eingeben',
    LoginSuccess: 'Einloggen erfolgreich',
    OldStyleAccount:
      'You are using an old style account that has no access to new features. You need to update your account type.',
  },
  Toolbar: {
    PageTitle: (heading) =>
      ({
        Home: 'Home',
        Dashboard: 'Übersicht',
        Messages: 'Nachrichten',
        Content: 'Inhaltsverwaltung',
        Medications: 'Behandlungsplan',
        Patients: 'Patienten',
        VideoCalls: 'Videoanrufe',
        Admin: 'Admin',
        SuperAdmin: 'Super Admin',
        Payments: 'Zahlungen',
        Settings: 'die Einstellungen',
      }[heading]),
    PleaseLogIn: 'Bitte loggen Sie sich ein, um fortzufahren',
    Dashboard: 'Dashboard',
    SearchPatients: 'Patienten suchen...',
    SignOut: 'Ausloggen',
    ProfilePhoto: 'Profile photo',
  },
  Dashboard: {
    UnresolvedMessages: 'Nachrichten ansehen',
    Unresolved: (folder: string) => ({
      Unresolved: `${folder}`,
      Tooltip: `Nachrichten ${folder} ansehen`,
    }),
  },
  UpcomingDrugs: {
    UpcomingDrugs: 'Anstehende Einnahmen',
    NoDrugs: 'Es gibt keine bevorstehenden Medikamente',
    Name: 'Name',
    Type: 'Typ',
    Drug: 'Medikament',
    Dosage: 'Dosierung',
    Status: 'Status',
    DueAt: 'Fällig am',
  },
  NonTimedTriggerDrugs: {
    UpcomingDrugs: 'Nicht zeitgesteuerte Medikamente',
    NoDrugs: 'Es gibt keine bevorstehenden Medikamente',
    Name: 'Name',
    Type: 'Typ',
    Drug: 'Medikament',
    Dosage: 'Dosierung',
    Status: 'Status',
    DueAt: 'Fällig am',
    DueDate: 'Fällig',
  },
  Messages: {
    NewMessage: 'Neue Nachricht',
    NewMessages: 'Neue Nachrichten anzeigen',
    AllUnresolved: 'Alle offenen Nachrichten',
    AllResolved: 'Alle abgeschlossenen Nachrichten',
    AllMessages: 'Alle Nachrichten',
    SearchMessages: 'Nachrichten suchen',
    SearchingFor: 'Suchen nach',
    Search: 'Suchen',
    Unread: 'Ungelesen',
    Clear: 'Löschen',
    Actions: 'Maßnahmen',
    SelectAMessage: 'Nachricht auswählen',
    MarkUnread: 'Als ungelesen markieren',
    MarkUnresolved: 'Als offen markieren',
    MarkResolved: 'Als abgeschlossen markieren',
    Move: 'Verlegen',
    ChangeLabelTo: 'Verschieben nach',
    WriteAMessage: 'Nachricht schreiben',
    Enlarge: 'Vergrössern',
    Minimise: 'Verkleinern',
    Send: 'Senden',
    SeenBy: 'Gesehen von',
    On: 'um',
    At: 'am',
    New: 'Neu',
    Open: 'Öffnen',
    OpenMessageAttachmentFileErrorMessage:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
    OpenMessageAttachmentFileErrorTitle:
      'Die Datei konnte nicht geöffnet werden',
    SingularTyping: ' schreibt...',
    PluralTyping: ' tippen...',
    SingularTyped: ' hat Text eingegeben...',
    PluralTyped: ' haben Text eingegeben...',
    Dismiss: 'VERWERFEN',
    CopyMessageHistory: 'Nachrichtenverlauf kopieren',
    HistoryCopied: (patientName: string) =>
      `Nachrichtenverlauf mit ${patientName} wurde erfolgreich in die Zwischenablage kopiert`,
    MarkedResolved: 'Diese Konversation wurde als gelöst markiert',
    ReadReceipt: (patientName: string, readDate: string) =>
      ` [Lesebestätigung: Von ${patientName} gesehen am ${readDate}]`,
    ReadBy: (name: string, time: string, date: string) =>
      `Gesehen von ${name} um ${time} Uhr am ${date}`,
    SentFile: (fileName: string) => `Datei gesendet – ${fileName}`,
    Seen: 'Gesehen',
    CopyError:
      // tslint:disable-next-line:max-line-length
      'Nachrichtenverlauf kann nicht in die Zwischenablage kopiert werden. Bitte überprüfen Sie Ihre Verbindung, aktualisieren Sie Ihren Browser und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, senden Sie eine E-Mail an hello@salveapp.co.uk',
    EndOfConversation: 'Ende der konversationen',
    NoConversations:
      'Dieser Ordner enthält keine nicht markierten Unterhaltungen',
    NoStarredConversations:
      'Dieser Ordner enthält keine markierten Unterhaltungen',
    PleaseRefineSearch:
      'Ergebnis erreicht maximale Anzahl an Nachrichten. Bitte verfeinern Sie Ihre Suchkriterien',
    PleaseUseSearch:
      'Ergebnis erreicht maximale Anzahl an Nachrichten. Bitte verwenden Sie das Suchfenster',
    Subject: 'Gegenstand:',
    ContentMaxLengthError: 'Die maximale Nachrichtenlänge beträgt 2000 Zeichen',
    DeleteMessageItem: 'Nachricht löschen',
    DeleteMessageConfirmationTitle: 'Nachricht löschen?',
    DeleteMessageConfirmationBody:
      'Sind Sie sicher, dass Sie die Nachricht löschen möchten?',
    DeleteMessageConfirmationBody2:
      'Der/die Patient/in kann den Inhalt der gelöschten Nachricht eventuell immer noch in den Benachrichtigungen des Geräts einsehen.',
    DeleteMessageButtonDelete: 'Löschen',
    DeleteMessageButtonCancel: 'Abbrechen',
    DeletedMessageText: (name: string, date: string, time: string) =>
      `Diese Nachricht wurde aus der App des/der Patienten/Patientin durch ${name} um ${time} Uhr am ${date} gelöscht.`,
    NoMessageTemplates:
      'Keine Vorlagen verfügbar, fügen Sie diese im Admin-Panel hinzu',
    NoMatchingMessageTemplates: 'Keine passenden Nachrichtenvorlagen gefunden',
    InsertMessageTemplate: 'Nachrichtenvorlage einfügen',
    StarredTab: 'Markiert',
    EverythingElseTab: 'Alles andere',
    NoSearchResults: 'Keine Such- oder Filterergebnisse',
    FoldersChipLabel: 'Ordner',
    TeamMembersFilterLabel: 'Team des/der Patient:in',
    FolderFilterLabel: 'Ordner',
    ResolvedFilterLabel: 'Erledigt',
    UnresolvedFilterLabel: 'Noch nicht erledigt',
    StatusFilterLabel: 'Status',
    FolderFilterSearchPlaceholder: 'Ordner durchsuchen',
    TeamMembersFilterSearchPlaceholder: 'Team-Mitglieder durchsuchen',
    FilterButtonAriaLabel: 'Filter',
    FilterMenuAriaLabel: 'Filtermenü',
    FilterMenuApplyButton: 'Anwenden',
    FilterLoadingErrorTitle: 'Es ist ein Fehler aufgetreten',
    FilterLoadingErrorMessage: 'Es ist ein Fehler aufgetreten',
    NoConversationSelected: 'Keine Konversation ausgewählt',
    PatientsTeamMember: 'Team des/der Patient:in',
    SearchUsers: 'Nach Benutzer:innen suchen',
    Me: 'An mich',
    Assign: 'Zuordnen',
    Unassign: 'Zuordnung aufheben',
    SearchAssignees: 'Nach Beauftragten suchen',
    Assignees: 'Beauftragte',
    Unassigned: 'Zuordnung aufgehoben',
    Mine: 'Meine Nachrichten',
  },
  NewVideoCall: {
    AddDescription:
      'Geben Sie eine Beschreibung ein, z.B. erstes Beratungsgespräch',
    AddName:
      'Geben Sie den Namen des/der Angestellten ein, der/die den Anruf moderiert',
    Cancel: 'Stornieren',
    CloseCheck: 'Beenden, ohne einen Anruf zu vereinbaren?',
    Confirm: 'Bestätigen',
    ConfirmDelete:
      'Sind Sie sicher, dass Sie diesen Videoanruf löschen möchten?',
    Create: 'Erstellen',
    Date: 'Datum',
    DateRequired: 'Es muss ein gültiges Datum angegeben werden',
    Delete: 'Löschen',
    DeleteCall: 'Anruf löschen',
    Description: 'Beschreibung',
    DescriptionLength:
      'Die Beschreibung sollte zwischen 3 und 100 Zeichen enthalten',
    DescriptionRequired: 'Die Beschreibung ist ein Pflichtfeld',
    Duration: 'Dauer (Minuten)',
    DurationRequired: 'Die Anrufdauer ist ein Pflichtfeld',
    Host: 'Moderator',
    HostLength: 'Das Moderatorfeld sollte zwischen 3 und 50 Zeichen enthalten',
    HostRequired: 'Das Moderatorfeld ist ein Pflichtfeld',
    LocalTime: 'Bitte geben Sie die Ortszeit Ihrer Klinik ein',
    PastDate: 'Das Datum kann nicht in der Vergangenheit liegen',
    PastTime: 'Die Zeitangabe kann nicht in der Vergangenheit liegen',
    Patient: 'Patient/in',
    PatientRequired: 'Das Patientenfeld ist ein Pflichtfeld',
    PleaseSelect: 'Geben Sie die Patienten-ID oder einen Namen zum Suchen ein',
    Save: 'Speichern',
    ServerError:
      'Es ist ein Server-Fehler aufgetreten - Bitte versuchen Sie es später noch einmal',
    SetDate: 'Datum festlegen',
    StartTime: 'Startzeit',
    StartTimeRequired: 'Startzeit ist ein Pflichtfeld',
    TitleEdit: 'Videoanruf bearbeiten',
    TitleNew: 'Neuer Videoanruf',
  },
  NewMessage: {
    To: 'An',
    Patient: 'Patient',
    PleaseSelect: 'Bitte wählen Sie einen gültigen Patienten aus',
    Type: 'Typ',
    ChooseMessageType: 'Nachrichtentyp auswählen',
    PatientRequired: 'ein Patientenname ist erforderlich',
    ServerError:
      'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    MessageLabelRequired: 'Eine Kennzeichnung für die Nachricht wird benötigt',
    WriteYourMessage: 'Schreiben Sie Ihre Nachricht',
    PleaseEnterMessage: 'Bitte geben Sie eine Nachricht ein',
    Send: 'Senden',
    DiscardTitle: 'Schließen ohne zu senden?',
    DiscardDescription: 'Alle eingegebenen Daten gehen verloren.',
    Cancel: 'Weiterschreiben',
    Confirm: 'Schließen',
    Subject: 'Gegenstand',
    SubjectPlaceholder: 'Schreiben Sie Ihre Betreffzeile (optional)',
    SubjectMaxLengthError: 'Die maximale Betrefflänge beträgt 256 Zeichen',
    ContentMaxLengthError: 'Die maximale Nachrichtenlänge beträgt 2000 Zeichen',
    Title: 'Neue Unterhaltung',
    FolderFieldLabel: 'Nachrichtenordner',
    FolderFieldPlaceholder: 'Wählen Sie einen Nachrichtenordner',
    SubjectFieldLabel: 'Betreff (optional)',
    SubjectFieldPlaceholder: 'Schreiben Sie eine Betreffzeile',
    ContentFieldLabel: 'Nachricht',
  },
  Medication: {
    PotentialDupe: 'Mögliche doppelte Medikation',
    ActionRequired: 'Maßnahme erforderlich',
    Name: 'Name',
    Type: 'Typ',
    Drug: 'Medikament',
    Dosage: 'Dosierung',
    Status: 'Status',
    Overdue: (time) => `Überfällig - vorgesehen für ${time}`,
    NoActionRequired: 'Keine Maßnahme erforderlich',
    DueAt: (time) => `Fällig um ${time}`,
    DueToday: 'Heute fällig',
    WindowClosing: (time) => `${time} Minuten bis zum Schließen des Fensters`,
    TakenAt: (takenTime, dueTime) =>
      `Genommen um ${takenTime} (fällig ${dueTime})`,
    PotentialDuplicate: (dueDate, scheduledTime) =>
      `Mögliches Duplikat: ${dueDate} ${scheduledTime}`,
    NoAction: 'Keine Medikamente erfordern Maßnahmen',
    NoMedications: 'Keine Medikamente zum Anzeigen',
    NoPotentialDuplicates: 'Keine möglicherweise duplizierten Medikamente',
    NoSearchResults: 'Keine Medikamente entsprechen der Suche',
    Search: 'Suchen',
    Id: 'ID',
    DOB: 'Geburtsdatum',
    ErrorTitle: 'Daten können nicht geladen werden',
    ErrorMessage:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
  },
  ContentList: {
    All: 'Alle',
    Medications: 'Medikamente',
    Medication: 'Medikament',
    Appointments: 'Termine',
    Appointment: 'Geplanter Termin',
    SearchContent: 'Suchen...',
    Type: 'Typ',
    Name: 'Name',
    LastModifiedByHeader: 'Zuletzt geändert von  - (User) - Date',
    LastModifiedByRow: (user, date) => `(${user}) ${date}`,
  },
  PatientList: {
    SearchPatients: 'Nach Patientennamen, ID, Geburtsdatum TTMMJJJJ suchen',
    PatientId: 'Patienten ID',
    FirstName: 'Vorname',
    LastName: 'Nachname',
    SyncDate: 'Registrierungsdatum',
    ClearFilter: 'Filter löschen',
    InviteCode: 'Einladungscode: ',
    DOB: 'Geburtsdatum',
  },
  PatientInfo: {
    NewMessage: 'Neue Nachricht',
    PatientInfo: 'Patienten Information',
    Partner: 'Partner',
    Contact: 'Kontakt',
    LabResults: 'Laborergebnisse',
    ResultRange: 'Referenzbereiche:',
    ResultReceived: 'Ergebnis erhalten:',
    ResultInterpretation: 'Interpretation:',
    NoLabResults: 'Keine Laborergebnisse verfügbar',
    ManageContent: 'Inhalte verwalten',
    PatientDocuments: 'Patientendokumente',
    PatientId: 'Patientin ID',
    SyncDate: 'Registrierungsdatum',
    LastLogin: 'Letzter Login',
    FirstName: 'Vorname',
    LastName: 'Nachname',
    DOB: 'Geburtsdatum',
    Age: 'Alter',
    NumberOfCycles: 'Anzahl der Behandlungszyklen',
    NoPartner: 'Kein Partner ausgewählt',
    Email: 'Email',
    WorkPhoneLandline: 'Arbeiten Telefonnummer (Festnetz)',
    WorkPhoneMobile: 'Arbeiten Telefonnummer (Mobil)',
    PersonalPhoneLandline: 'Private Telefonnummer (Festnetz)',
    PersonalPhoneMobile: 'Private Telefonnummer (Mobil)',
    ComingSoon: '...demnächst...',
    NewVideoCall: 'Neuer Videoanruf',
    Minutes: 'Minuten',
    ClinicPhysician: 'Arzt',
    Counselor: 'Betreuer',
    Nurse: 'Krankenpfleger',
    Accountant: 'Buchhalterin',
    TreatingDr: 'Behandelnder Arzt',
  },
  PatientCalendar: {
    Calendar: 'Kalender',
    Key: 'Schlüssel',
    Hidden: 'Versteckt',
    Medication: 'Medikament',
    Appointment: 'Termin',
    VideoCall: 'Videoanruf',
    Provisional: 'Provisorisch',
    Back: 'Zurück',
    Today: 'Heute',
    Next: 'Als nächstes',
    Days: () => [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
    Months: () => [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    EventLabelAppointment: (dateTime: string, appointmentTitle: string) =>
      `${dateTime} - ${appointmentTitle}`,
    EventLabelAppointmentProvisional: (
      dateTime: string,
      appointmentTitle: string,
    ) => `${dateTime} - ${appointmentTitle} (provisional)`,
  },
  Authentication: {
    WelcomeToSalve: 'Willkommen in Salve',
    Core: ' Core',
    ErrorLoggingIn: 'Fehler beim Einloggen',
    Username: 'Benutzername',
    Email: 'Email',
    ForgottenPassword: 'Haben Sie Ihr Passwort vergessen?',
    HelloUser: (user) => `Hallo ${user}`,
    Login: 'Anmeldung',
    Password: 'Passwort',
    PasswordMissing: 'Bitte passwort eingeben',
    UsernameMissing: 'Bitte benutzername eingeben',
    LoginSuccess: 'Einloggen erfolgreich',
    ResetTitle: 'Passwort zurücksetzen',
    ResetButton: 'Passwort zurücksetzen',
    EmailRequired: 'Eine Email ist erforderlich',
    YourCode: 'Ihr Reset-Code',
    CodeRequired: 'Ein Reset-Code ist erforderlich',
    NewPassword: 'Neues Passwort',
    PasswordRequired: 'Eine Passwort ist erforderlich',
    PasswordsMustMatch: 'Die Passwörter stimmen nicht überein',
    ConfirmPassword: 'Bestätigen Sie Ihr Passwort',
    SubmitNewPassword: 'Geben Sie Ihr Passwort ein',
    ConfirmNewPassword: 'Neues Passwort bestätigen',
    // tslint:disable-next-line:quotemark
    CreateYourAccount: 'Es ist Zeit, Ihr Profil zu erstellen',
    FillInBelow:
      'Bitte füllen Sie das Formular aus und klicken dann auf Absenden',
    FirstName: 'Vorname',
    FirstNameRequired: 'Ein Vorname ist erforderlich',
    LastName: 'Nachname',
    LastNameRequired: 'Ein Nachname ist erforderlich',
    CountryCode: 'Ländervorwahl',
    CountryCodeRequired: 'Die Ländervorwahl ist erforderlich',
    PhoneNumber: 'Telefonnummer',
    PhoneNumberRequired: 'Eine Telefonnummer ist erforderlich',
    InvalidPhoneNumber: 'Bitte geben Sie eine gültige Mobiltelefonnummer an',
    CreateAccount: 'Profil erstellen',
    UserNotFoundException: 'Die E-Mail Adresse konnte nicht gefunden werden.',
    NotAuthorizedException: 'Fehlerhafte E-Mail oder Passwort.',
    InvalidPasswordException:
      'Das Passwort muss aus mindestens 8 Zeichen bestehen und sollte Groß-, Kleinbuchstaben und Ziffern enthalten.',
    InvalidParameterException:
      'Das Passwort muss aus mindestens 8 Zeichen bestehen und sollte Groß-, Kleinbuchstaben und Ziffern enthalten.',
    CodeMismatchException:
      'Der Verifizierungscode ist fehlerhaft. Bitte versuchen Sie es erneut.',
    LimitExceededException:
      'Die Anzahl der Versuche wurde überschritten. Bitte versuchen Sie es in wenigen Minuten erneut.',
    ResetEmail:
      'Wir haben Ihnen eine Email mit einem Bestätigungs Code geschickt.',
    CodeCancel: 'Abbrechen',
    CodeMissing:
      'Bitte geben Sie einen gültigen Code ein. Dieser sollte aus 6 zeichen bestehen.',
    Code: 'Code eingeben:',
    OTPTitle: 'Überprüfen Sie Ihre E-Mail',
    OTPSubtitle:
      'Wir haben einen Code an Ihre registrierte E-Mail-Adresse gesendet. Geben Sie diesen unten ein, um sich anzumelden:',
    SupportEmail: 'hello@salveapp.co.uk',
    OTPInstructions:
      'Der Code ist 15 Minuten lang aktiv. Bitte überprüfen Sie Ihren Spam-Ordner, wenn Sie den Code nicht in Ihrem Posteingang sehen können. Bei Problemen schicken Sie uns bitte eine E-Mail an ',
    InvalidCode: 'Der Code ist ungültig. Bitte versuchen Sie es erneut.',
    OTPToastTitle: 'Bitte versuchen Sie erneut, sich einzuloggen',
    OTPToastMessage:
      'Sie haben die Anzahl der Eingabeversuche für den Anmeldecode überschritten. Bitte melden Sie sich an, um es erneut zu versuchen. Wenn Sie nicht weiterkommen, senden Sie uns eine E-Mail an hello@salveapp.co.uk.',
  },
  MessageToast: {
    Undo: 'RÜCKGÄNGIG',
    View: 'Ansehen',
    Loading: 'die Beladung',
    MessageResolvedTitle: 'Abgeschlossene Nachricht',
    MessageResolvedBody: 'Nachrichten als abgeschlossen markiert',
    MessageResolvedUndoneTitle: 'Nachricht gelöst wurde rückgängig gemacht',
    MessageResolvedUndoneBody: 'Nachricht als ungelöst gemeldet',
    MessageUnresolvedTitle: 'Offene Nachricht',
    MessageUnresolvedBody: 'Nachrichten als offen markiert',
    MessageUnresolvedUndoneTitle: 'Nachricht nicht gelöst rückgängig gemacht',
    MessageUnresolvedUndoneBody: 'Nachricht als gelöst kommentiert',
    MessageUnreadTitle: 'Ungelesene Nachricht',
    MessageUnreadBody: 'Nachricht als ungelesen markiert',
    MessageStarredTitle: 'Gespräch markiert',
    MessageStarredBody:
      'Das Gespräch wurde markiert und auf das Tab „Markiert“ verschoben.',
    MessageStarredTitleError: 'Das Gespräch kann nicht markiert werden',
    MessageStarredBodyError:
      'Bitte prüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
    MessageUnstarredTitle: 'Markierung für Gespräch aufgehoben',
    MessageUnstarredBody:
      'Die Markierung für das Gespräch wurde aufgehoben und die Konversation in das Tab "Alles andere" verschoben',
    MessageUnstarredTitleError:
      'Die Markierung für das Gespräch kann nicht aufgehoben werden',
    MessageUnstarredBodyError:
      'Bitte prüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
    LabelChangedTitle: 'Kennzeichnung geändert',
    LabelChangedBody: (from, to) =>
      `Nachrichten kennzeichnung geändert von ${from} zu ${to}`,
    MessageMovedTitle: 'Nachricht verschoben',
    MessageMovedBody: (from: string, to: string) =>
      `Nachricht verschoben von ${from} zu ${to}`,
    OutboundSentTitle: 'Ausgehende Nachricht gesendet',
    OutboundSentBody: (patientString) =>
      `Nachricht erfolgreich versendet an ${patientString}`,
    LabelChangedUndoneTitle: 'Änderung der Kennzeichnung rückgängig gemacht',
    LabelChangedUndoneBody: (from, to) =>
      `Nachricht zurück verschoben zu ${to} von ${from}`,
    MessageMovedUndoneTitle: 'Änderung der Kennzeichnung rückgängig gemacht',
    MessageMovedUndoneBody: (from, to) =>
      `Nachricht zurück verschoben zu ${to} von ${from}`,
    UndoingActionTitle: 'Aktion rückgängig machen',
    UndoingActionBody: (action) =>
      `Die Aktion '${action}' wird rückgängig gemacht...`,
    ErrorEncountered: 'Fehler aufgetreten',
    ErrorUnread: 'Fehler beim Markieren der Nachricht als ungelesen',
    ErrorUnresolved: 'Fehler beim Markieren der Nachricht als gelöst',
    ErrorResolved: 'Fehler beim Markieren der Nachricht als behoben',
    ErrorChanging: 'Fehler beim Ändern des Labels',
    ErrorSending: (patient) => `Fehler beim Senden der Nachricht an ${patient}`,
    SendingOutboundTitle: 'Ausgehende Nachricht senden',
    SendingOutboundBody: (patient) => `Nachricht an ${patient} senden`,
    ErrorDeletingMessage:
      'Der/die Patient/in hat die Nachricht gelesen, daher kann diese nicht gelöscht werden.',
    ErrorDeletingMessageTitle: 'Fehler beim Löschen der Nachricht',
    ErrorAssigningMessageTitle: 'Gespräch konnte nicht zugewiesen werden',
    ErrorUnAssigningMessageTitle:
      'Die Gesprächszuordnung konnte nicht aufgehoben werden',
  },
  VideoCallsList: {
    AddFilter: 'Filter hinzufügen',
    ClearFilter: 'Löschen',
    CreateVideoCall: 'Videoanruf erstellen',
    Description: 'Beschreibung',
    DOB: 'Geburtsdatum',
    Duration: 'Dauer',
    Edit: 'Bearbeiten',
    HostResource: 'Moderieren/Ressource',
    Join: 'Beitreten',
    Locked:
      'Dieser Anruf wurde mittels EMR terminiert. Wenn Sie diesen Anruf verschieben oder absagen möchten, nutzen Sie bitte die EMR.',
    More: 'Mehr',
    NoPreviousCalls: 'Keine vorig Anrufe anzuzeigen',
    NoUpcomingCalls: 'Keine anstehenden Anrufe anzuzeigen',
    Patient: 'Patient/in',
    PreviousCalls: 'VORIGE ANRUFE',
    Search: 'Nach Patientennamen, ID, Geburtsdatum TTMMJJJJ suchen',
    Status: 'Status',
    StatusFinished: 'Beendet',
    StatusOngoing: 'Laufend',
    StatusUpcoming: 'Geplant',
    UpcomingCalls: 'GEPLANTE ANRUFE',
    When: 'Wann',
    FilterNoResults: 'Keine Einträge gefunden',
    FilterSearch: 'Suchen',
    FilterApply: 'Anwenden',
    FilterCancel: 'Entfernen',
    FilterHostResource: 'Moderieren/Ressource',
    RefreshTitle: 'Anrufliste aktualisieren',
  },
  ProfilePhotoModal: {
    Title: 'Profilfoto',
    SubTitle:
      'Bitte beachten Sie, dass dieses Foto für Patient:innen in der App sichtbar ist.',
    SelectPhoto: 'Wählen Sie ein Foto aus',
    DeleteConfirmation: {
      Cancel: 'Abbrechen',
      Delete: 'Löschen',
      Content:
        'Ihr Profilfoto wird aus dem Klinikportal und der Patienten-App gelöscht.',
      Title: 'Sind Sie sicher?',
    },
    CloseConfirmation: {
      Cancel: 'Abbrechen',
      Close: 'Schließen',
      Content: 'Änderungen werden nicht gespeichert',
      Title: 'Sind Sie sicher?',
    },
    ChangePhoto: 'Foto ändern',
    Delete: 'Löschen',
    Save: 'Speichern',
    Loading: 'Wird geladen',
    SuccessToast: {
      UpdatedTitle: 'Das Foto wurde erfolgreich aktualisiert',
      UpdatedDescription:
        'Ihr Profilfoto wurde gespeichert und ist nun im Klinikportal und in der Patienten-App sichtbar.',
      DeletedTitle: 'Das Foto wurde erfolgreich gelöscht',
      DeletedDescription:
        'Ihr Profilfoto wurde aus dem Klinikportal und der Patienten-App entfernt.',
    },
    ErrorToast: {
      UploadTitle: 'Das Profilfoto konnte nicht hochgeladen werden',
      UpdateTitle: 'Das Profilfoto konnte nicht aktualisiert werden',
      DeleteTitle: 'Das Profilfoto konnte nicht gelöscht werden',
      GenericDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut. ',
    },
  },
  VideoCallsToast: {
    Deleted: 'Der Videoanruf wurde gelöscht',
    DeletedSuccessfully: 'Der Videoanruf wurde erfolgreich gelöscht',
    Deleting: 'Der Videoanruf wird gelöscht',
    ErrorDeleting: 'Fehler beim Löschen des Videoanrufs',
    ErrorEncountered:
      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    ErrorSaving: 'Fehler beim Speichern des Videoanrufs',
    JoiningVideoCall: 'Dem Videoanruf beitreten',
    PleaseWait: 'Bitte warten...',
    PleaseWaitAndAllowAccess:
      'Bitte warten Sie und erlauben Sie bei Anfrage Zugriff auf das Mikrofon und/ oder die Kamera',
    Saved: 'Videoanruf gespeichert',
    SavedSuccessfully: 'Der Videoanruf wurde erfolgreich gespeichert',
    Saving: 'Der Videoanruf wird gespeichert',
    Error: 'Fehler',
  },
  VideoCallModal: {
    Allow: 'Erlauben',
    AnErrorOccured: 'Es ist ein Fehler aufgetreten.  ',
    AttemptingReconnect:
      'Es wird versucht, die Verbindung zum Anruf wieder herzustellen',
    AudioInputMenu: 'Audio-Eingang',
    AudioOutputMenu: 'Audio-Ausgang',
    CallEnded: 'Anruf beendet',
    CallNotReconnected:
      'Die Verbindung zum Anruf konnte nicht wieder hergestellt werden',
    Cancel: 'Abbrechen',
    Connected: 'Sie sind jetzt verbunden',
    EndCall: 'Anruf beenden',
    Error: 'Fehler',
    LeaveCall: 'Anruf verlassen',
    Mute: 'Stummschaltung aktivieren',
    NeedPermissions:
      'Um die Videoanruf-Funktion nutzen zu können, benötigen wir zunächst die Berechtigung, auf Ihr Mikrofon und Ihre Kamera zuzugreifen. Bitte erlauben Sie den Zugriff, wenn Ihr Browser Sie dazu auffordert.',
    NoCameraMic:
      'Um den Anruf beitreten zu können, benötigen Sie ein Mikrofon und/ oder eine Kamera',
    NooneHere: (name) =>
      `Im Moment ist niemand da. Bitte warten Sie, bis ${name} beigetreten ist.`,
    Ok: 'OK',
    ParticipantDisconnected: 'Die Verbindung zum Teilnehmer wurde abgebrochen',
    ParticipantDisconnectedMessage:
      'Ein Teilnehmer hat die Verbindung zum Anruf abgebrochen',
    Reconnecting: 'Erneut verbinden',
    StartCamera: 'Kamera starten',
    StopCamera: 'Kamera anhalten',
    Success: 'Verbunden',
    SureLeave: 'Sind Sie sicher, dass Sie diesen Anruf verlassen möchten?',
    Unmute: 'Stummschaltung deaktivieren',
    VideoMenu: 'Video',
    Yes: 'Ja',
  },
  VideoCallsInvitePartner: {
    InviteButton: 'Partner einladen',
    InvitePartner: 'Partner einladen?',
    InviteText1:
      'Hierdurch wird der Videoanruf zur Termin-Timeline des Partners hinzugefügt. Es kann einige Minuten dauern, bis dies in der App beim Partner angezeigt wird.',
    InviteText2:
      'Bitte beachten Sie, dass Sie Teilnehmer nicht ausladen können, wenn der Anruf bereits stattfindet.',
    Cancel: 'Abbrechen',
    Invite: 'Einladen',
    PartnerInvited: 'Partner wirde eingeladen',
    PartnerInvitedText:
      'Dieser Videoanruf wurde der Termin-Timeline des Partners hinzugefügt. Es kann einige Minuten dauern, bis dieser in ihrer App angezeigt wird',
    UnInviteButton: 'Einladung für Partner aufheben',
    UnInvitePartner: 'Einladung für Partner aufheben?',
    UnInviteText1:
      'Dadurch wird der Videoanruf aus der Termin-Timeline des Partners entfernt und dieser kann nicht mehr daran teilnehmen.',
    UnInviteText2:
      'Bitte beachten Sie, dass Sie Teilnehmer nicht ausladen können, wenn der Anruf bereits stattfindet.',
    UnInvite: 'Einladung aufheben',
    PartnerUninvited: 'Die Einladung für den Partner wurde aufgehoben',
    PartnerUnInvitedText:
      'Der Videoanruf wurde aus der Termin-Timeline des Partners entfernt und dieser kann nicht mehr daran teilnehmen. Bitte beachten Sie, dass das Entfernen bis zu 5 Minuten dauern kann.',
    InvitePartnerInCall:
      'Bitte beachten Sie, dass es bis zu 5 Minuten dauern kann, bis dieser Videoanruf in der Termin-Timeline erscheint.',
    InvitePartnerInCallText:
      'Bitte beachten Sie, dass es bis zu 5 Minuten dauern kann, bis der Videoanruf in der Termin-Timeline der App angezeigt wird.',
    OK: 'OK',
    UnInvitePartnerInCall:
      'Bitte beachten Sie, dass es bis zu 5 Minuten dauern kann, bis dieser Videoanruf aus der Termin-Timeline entfernt wird.',
    UnInvitePartnerInCallText1:
      'Der Videoanruf wurde aus der Termin-Timeline des Partners entfernt und dieser kann nicht mehr daran teilnehmen.',
    UnInvitePartnerInCallText2:
      'Bitte beachten Sie, dass das Entfernen bis zu 5 Minuten dauern kann.',
    PartnerNotFound: 'Partner konnte nicht gefunden werden',
    PartnerNotFoundText1:
      'In Salve ist diesem Patienten kein Partner zugeordnet.',
    PartnerNotFoundText2:
      'Wenn dieser Patient einen Partner hat, muss der Partner zuerst die App herunterladen und ein eigenes Konto erstellen.',
    PartnerNotFoundText3:
      'Sobald dies geschehen ist, versuchen Sie es bitte erneut.',
    PartnerNotFoundText4:
      'Wenn Sie der Meinung sind, dass diese Nachricht fehlerhaft verschickt wurde, wenden Sie sich bitte an den Salve-Kundendienst unter hello@salveapp.co.uk.',
    CouldNotInvite: 'Partner konnte nicht eingeladen werden',
    CouldNotInviteErrorMessage:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
    CouldNotUnInvite:
      'Die Einladung für den Partner konnte nicht aufgehoben werden',
    CouldNotUnInviteErrorMessage:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
    CallInProgressUnInviteMessage: 'Dieser Anruf findet bereits statt.',
  },
  SessionExpired: {
    Title: 'Sie wurden ausgeloggt',
    Message: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an',
  },
  MessagingNotifications: {
    BrowserTabTitle: (messagesLength: string) =>
      `(${messagesLength}) Klinik-Portal`,
    NotificationBody: (messagesLength: string) =>
      `Sie haben ${messagesLength} neue Nachricht(en)`,
    NotificationBodyMessage: (from: string) =>
      `Sie haben eine neue Nachricht von ${from}`,
    NotificationTitle: 'Neue Nachricht(en) erhalten',
  },
  Admin: {
    AppointmentTypeNavItem: 'Termine',
    AppointmentTypeListSearchPlaceholder: 'Termine durchsuchen...',
    AppointmentTypeListTableHeaderId: 'ID #',
    AppointmentTypeListTableHeaderName: 'Name',
    AppointmentTypeListTableHeaderStatus: 'Status',
    AppointmentTypeListTableChipActive: 'Aktiv',
    AppointmentTypeListTableChipHidden: 'Niemals sichtbar',
    AppointmentTypeListTableChipProvisional: 'Provisorisch',
    AppointmentTypeListTableChipStatusFilter: 'Nach Status gefiltert',
    AppointmentTypeHeaderId: 'ID #',
    AppointmentTypeCaptionId: 'Terminart ID-Nummer aus der EMR',
    AppointmentTypeHeaderCreatedAt: 'Erstellt am',
    AppointmentTypeCaptionCreatedAt: 'Wann die Terminart im EMR erstellt wurde',
    AppointmentTypeHeaderActive: 'Anzeige in Salve',
    AppointmentTypeCaptionActive:
      'Wird in den Patientenzeitplänen angezeigt und ist für die Inhaltsbearbeitung verfügbar',
    AppointmentTypeHeaderHidden: 'Niemals sichtbar',
    AppointmentTypeCaptionHidden: 'Niemals sichtbar für Patienten in der App',
    AppointmentTypeHeaderProvisional: 'Provisorisch bis',
    AppointmentTypeCaptionProvisional:
      'Wird in der Patienten-App bis zum angezeigten Zeitpunkt als provisorisch gekennzeichnet',
    AppointmentTypeValueProvisional: (duration: string) => `${duration} vorher`,
    AppointmentTypeHeaderStatusFilters: 'Filter nach EPA Status',
    AppointmentTypeCaptionStatusFilters:
      'Nicht sichtbar, bis dem gebuchten Termin in der EPA ein bestimmter Status zugeordnet wird',
    AppointmentTypeHeaderVideoCall: 'In Videoanruf umwandeln',
    AppointmentTypeCaptionVideoCall:
      'Wandeln Sie alle gebuchten Termine dieser Art in Videoanrufe um. Diese Option sollte nur für Termine verwendet werden, die ausschließlich für Videoanrufe gebucht werden. <b>Wichtig:</b> Hierdurch werden nur die Termine dieser Art umgewandelt, die gebucht werden, <u>nachdem</u> Sie die Einstellung aktiviert haben. Alle vor der Aktivierung gebuchten Termine dieser Art werden nicht umgewandelt.',
    AppointmentTypeValueYes: 'Ja',
    AppointmentTypeValueNo: 'Nein',
    AppointmentTypeListErrorToastMessage: 'Fehler beim Laden der Terminarten',
    AppointmentTypeLoadingErrorToastHeader: 'Netzwerkfehler',
    AppointmentTypeLoadingErrorToastMessage: 'Fehler beim Laden der Terminart',
    AppointmentTypeProvisionalHoursInputErrorRequired:
      'Bitte geben Sie in das obige Feld mindestens eine Ziffer ein',
    AppointmentTypeProvisionalHoursInputLabel1: 'Vorläufig bis:',
    AppointmentTypeProvisionalHoursInputLabel2:
      'Stunden bis zum vereinbarten Termin',
    AppointmentTypeSavingErrorHeader:
      'Änderungen können nicht gespeichert werden',
    AppointmentTypeSavingErrorMessage:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
    AppointmentTypeSavingSuccessHeader:
      'Die Änderungen wurden erfolgreich gespeichert',
    AppointmentTypeSavingSuccessMessage:
      'Ihre Änderungen wurden erfolgreich gespeichert. Es kann bis zu 10 Minuten dauern, bis diese sichtbar sind.',
    MessageTemplatesContentErrorLength: (max: number) =>
      `Der Vorlageninhalt darf ${max} Zeichen beinhalten`,
    MessageTemplatesContentErrorRequired: 'Vorlageninhalt ist erforderlich',
    MessageTemplatesDeleteButtonText: 'Löschen',
    MessageTemplatesDeleteDialogAcceptButtonText: 'Löschen',
    MessageTemplatesDeleteDialogCancelButtonText: 'Abbrechen',
    MessageTemplatesDeleteDialogContent:
      'Wenn Sie diese Vorlage löschen, kann sie nicht mehr in Unterhaltungen verwendet werden. Das kann nicht rückgängig gemacht werden.',
    MessageTemplatesDeleteDialogTitle: 'Sind Sie sicher?',
    MessageTemplatesEmptyMessage:
      'Sie haben keine Nachrichtenvorlagen gespeichert',
    MessageTemplatesEmptyNonMatchingMessage:
      'Keine passenden Nachrichtenvorlagen gefunden',
    MessageTemplatesInsertVariableButtonText:
      '{{{\u00A0\u00A0}}} Variable einfügen',
    MessageTemplatesLastUpdatedByLabel: 'Zuletzt aktualisiert von',
    MessageTemplatesLoadingErrorMessage:
      'Beim Laden der Nachrichtenvorlagen ist ein Fehler aufgetreten',
    MessageTemplatesLoadingErrorRetryButtonText: 'Versuchen Sie es erneut',
    MessageTemplatesNameErrorLength: (min: number, max: number) =>
      `Vorlagenbezeichnungen müssen ${min}–${max} Zeichen lang sein`,
    MessageTemplatesNameErrorRequired: 'Vorlagenbezeichnung ist erforderlich',
    MessageTemplatesNameErrorUnique:
      'Die Bezeichnung wird bereits verwendet. Bitte probieren Sie eine andere Bezeichnung aus.',
    MessageTemplatesNameLabel: 'Bezeichnung',
    MessageTemplatesNamePlaceholder: 'Z.B. Terminbestätigung',
    MessageTemplatesNavigationWarning:
      'Bitte denken Sie daran, alle Änderungen zu speichern, bevor Sie die Seite verlassen.',
    MessageTemplatesNavItem: 'Nachrichtenvorlagen',
    MessageTemplatesNewTemplateButtonText: 'Neue Vorlage',
    MessageTemplatesSaveTemplateButtonText: 'Vorlage speichern',
    MessageTemplatesSearchPlaceholder: 'Nachrichtenvorlagen durchsuchen…',
    MessageContentSearchPlaceholder: 'Inhalt suchen',
    MessageContentSearchNoResults:
      'Hier sind keine Inhalte verfügbar. Fügen Sie diese im Bereich INHALT hinzu.',
    MessageContentSearchSelect:
      'Bitte wählen Sie links einen Ordner oder ein Paket aus',
    MessageContentNoFolders:
      'Sie haben keine Inhaltsordner. Fügen Sie diese im Bereich INHALT hinzu.',
    MessageContentNoPacks:
      'Sie haben keine Inhaltspakete. Fügen Sie diese im Bereich INHALT hinzu.',
    MessageContentEmptySearch: 'Ihre Suchanfrage hat keine Ergebnisse erzielt',
    MessageContentLoadError: 'Fehler beim Laden der Inhaltsdaten',
    MessageContentInsertLink: 'Inhaltslink einfügen',
    MessageTemplatesTemplateContentLabel: 'Vorlageninhalt',
    NoAccessButtonText: 'Zurück zum Übersicht',
    NoAccessMessage:
      'Sie haben keine Berechtigung, auf diesen Bereich des Portals zuzugreifen. Wenn Sie denken, dass es sich hierbei um einen Fehler handelt, wenden Sie sich bitte an Ihren Vorgesetzten.',
    SIDE: {
      NavItem: 'SIDE',
      SearchBarPlaceholder: 'SIDE Regeln durchsuchen...',
      RecipeListTableHeaderId: 'ID',
      RecipeListTableHeaderName: 'Name',
      RecipeListTableHeaderStatus: 'Status',
      RecipeListTableHeaderCreatedOn: 'Erstellt am',
      RecipeListTableHeaderLastUpdatedOn: 'Zuletzt aktualisiert am',
      RecipeListTableHeaderLastUpdatedBy: 'Zuletzt aktualisiert von',
      RecipeListLoadingErrorToastHeader: 'Netzwerkfehler',
      RecipeListErrorToastMessage: 'Fehler beim Laden der SIDE Regeln',
      RecipeListTableHeaderAction: 'Aktion',
      RecipeListTableHeaderEventType: 'Ereignisart',
      AppointmentEvent: 'Termin',
      MedicationEvent: 'Verabreichung von Medikamenten',
      RegistrationEvent: 'Registrierung',
      ActiveStatus: 'Activiert',
      InactiveStatus: 'Nicht aktiviert',
      RecipeListEmptyMessage: 'Sie haben keine eingestellten SIDE Regeln',
      RecipeLoadingErrorToastHeader: 'Netzwerkfehler',
      RecipeLoadingErrorToastMessage: 'Fehler beim Laden der Regel',
      SettingsHeader: 'Einstellungen',
      RecipeNameLabel: 'Name',
      RecipeStatusLabel: 'Status',
      ActionListTableHeaderId: 'ID',
      ActionListTableHeaderContent: 'Inhalt',
      ActionListTableHeaderActionType: 'Aktionsart',
      ActionListTableHeaderTiming: 'Terminierung',
      ActionListTableHeaderMedicationStatus: 'Medikationsstatus',
      ActionListTableHeaderActionStatus: 'Aktionsstatus',
      ActionListTableHeaderCreatedOn: 'Erstellt am',
      ActionListTableHeaderLastUpdatedOn: 'Zuletzt aktualisiert am',
      ActionListTableHeaderLastUpdatedBy: 'Zuletzt aktualisiert von',
      ActionListTableHeaderAppointmentStatus: 'Termin-Status',
      ActionListTableHeaderSite: 'Ort',
      ActionHeader: 'Aktionen',
      ActionListEmptyMessage: 'Für die Regel sind keine Aktionen eingerichtet',
      'Not setAppointmentStatus': 'Nicht eingerichtet',
      AttendedAppointmentStatus: 'Teilgenommen',
      'No-ShowAppointmentStatus': 'Nicht erschienen',
      CancelledAppointmentStatus: 'Abgesagt',
      'Before AppointmentAppointmentStatus': 'Vor dem Termin',
      'After AppointmentAppointmentStatus': 'Nach dem Termin',
      ConfirmedAppointmentStatus: 'Bestätigt',
      'Not CancelledAppointmentStatus': 'Nicht abgesagt',
      BookedAppointmentStatus: 'Gebucht',
      BookedMedicationStatus: 'Gebucht',
      HourPeriod: 'Stunde',
      MinutePeriod: 'Minute',
      DayPeriod: 'Tag',
      Before: 'davor',
      After: 'danach',
      ActionListLoadingErrorToastHeader: 'Netzwerkfehler',
      ActionListErrorToastMessage: 'Fehler beim Laden der Aktionen',
      AppointmentTriggerHeader: 'Betroffene Terminarten',
      MedicationTriggerHeader: 'Betroffene Medikationsarten',
      TriggerListLoadingErrorToastHeader: 'Netzwerkfehler',
      TriggerListErrorToastMessage: 'Fehler beim Laden von Triggern',
      NoTriggersAvailable: 'Keine Trigger verfügbar',
      ExportCSV: 'Als CSV exportieren',
      ExportDialogTitle: 'Alle SIDE-Daten exportieren?',
      ExportDialogDescription:
        'Bitte beachten Sie, dass im CSV-Export keine Filter vorhanden sind',
      ExportDialogCancel: 'Abbrechen',
      ExportDialogDownload: 'Herunterladen',
      SIDEExport: 'SIDE Export',
      ExportErrorTitle: 'Fehler beim Herunterladen des Exports',
      ExportErrorDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
      AddFilter: 'Filter hinzufügen',
      FilterApply: 'Anwenden',
      FilterTabEventType: 'Ereignisart',
      FilterTabStatus: 'Status',
      FilterTabAppointmentType: 'Terminarten',
      FilterTabDrugType: 'Medikationsarten',
      FilterApptSearchBarPlaceholder: 'Terminarten suchen...',
      FilterDrugSearchBarPlaceholder: 'Medikationsarten suchen...',
      ClearAllFilter: 'Filter löschen',
      RecipeNameRequiredErrorMessage: 'Rezeptname ist erforderlich.',
      RecipesSavingErrorHeader: 'Fehler beim Aktualisieren des Rezepts',
      RecipesSavingErrorMessage:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
      RecipesSavingSuccessHeader: 'SIDE-Rezept erfolgreich aktualisiert',
      RecipesSavingSuccessMessage: 'Das Rezept wurde erfolgreich aktualisiert',
      RecipeNameErrorLength: (min: number, max: number) =>
        `Rezeptnamen müssen aus ${min}-${max} Zeichen bestehen`,
      RecipeSaveButtonText: 'Speichern',
      RecipeTriggerInfo:
        'Betroffene Termintypen können zu Prüfungs- und Rechenschaftszwecken nicht bearbeitet werden. Wenn Sie hieran Änderungen vornehmen müssen, markieren Sie dieses Rezept bitte als inaktiv und erstellen Sie ein neues.',
      RecipeUniqueNameError: 'Der Rezeptname wird bereits verwendet.',
    },
    Users: {
      NavItem: 'Innen',
      SearchBarPlaceholder: 'Innen suchen',
      UsersListEmptyMessage: 'Es wurden keine Nutzer:innen gefunden',
      UsersListEmptyActiveUsers: 'Sie haben keine aktiven Nutzer:innen',
      UsersListEmptyInvitedUsers: 'Sie haben keine eingeladenen Nutzer:innen',
      UsersListEmptyInactiveUsers: 'Sie haben keine inaktiven Nutzer:innen',
      UsersListEmptyUsers: 'Keine Ergebnisse gefunden',
      ActiveStatus: 'Aktiv',
      DeactivatedStatus: 'Inaktiv',
      InvitedStatus: 'Eingeladen',
      UsersListTableHeaderFirstName: 'Vorname',
      UsersListTableHeaderLastName: 'Nachname',
      UsersListTableHeaderEmail: 'E-Mail',
      UsersListTableHeaderStatus: 'Status',
      UsersListTableHeaderLastLogIn: 'Letzer Login',
      UsersListTableHeaderRegistered: 'Registriert',
      UsersLoadingErrorToastHeader: 'Netzwerkfehler',
      UsersLoadingErrorToastMessage: 'Fehler beim Laden der Nutzer:innen',
      AddFilter: 'Filter hinzufügen',
      FilterApply: 'Anwenden',
      FilterTabStatus: 'Status',
      UsersExport: 'Innen exportieren',
      ExportCSV: 'Als CSV exportieren',
      ExportDialogTitle: 'Innen exportieren?',
      ExportDialogDescription:
        'Bitte beachten Sie, dass dieser CSV-Export die neueste Synchronisierung aller in Ihrem EMR erfassten Mitarbeitenden enthält, auch diejenigen, die kein Salve-Konto erstellt haben.',
      ExportDialogCancel: 'Abbrechen',
      ExportDialogDownload: 'Exportieren',
      ExportErrorTitle: 'CSV konnte nicht exportiert werden',
      ExportErrorDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
      ExportSuccessTitle: 'Erfolgreich exportiert',
      ExportSuccessDescription:
        'Die CSV befindet sich in Ihrem Download-Bereich',
      InviteUserAction: 'In einladen',
      DeactivateUserAction: 'Zugang zu Salve löschen',
      InviteUserErrorTitle: 'Die Handlung konnte nicht durchgeführt werden',
      InviteUserErrorDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
      InviteUserSuccessTitle: 'Einladung gesendet',
      InviteUserSuccessDescription:
        'Diese Einladung wurde an den/ die Nutzer:in versendet',
      DeactivateUserErrorTitle: 'Die Handlung konnte nicht durchgeführt werden',
      DeactivateUserErrorDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
      InviteUserModalCancel: 'Abbrechen',
      InviteUserModalAccept: 'Einladen',
      DeactivateUserModalTitle:
        'Sind Sie sicher, dass Sie den Zugang zu Salve für diese:n Nutzer:in löschen möchten?',
      DeactivateUserModalText:
        'Bitte beachten Sie, dass sich der/ die Nutzer:in dadurch nicht am Portal anmelden können. Möchten Sie den Nutzer:innen wieder Zugriff auf das Portal gewähren, ist eine erneute Einladung erforderlich.',
      DeactivateUserModalCancel: 'Abbrechen',
      DeactivateUserModalAccept: 'Zugang löschen',
      TooltipActive: 'Der/ die Mitarbeitende hat Zugang zum Salve-Portal',
      TooltipInactive:
        'Der/ die Mitarbeitende hat keinen Zugang zum Salve-Portal',
      TooltipInvited:
        'Der/ die Mitarbeitende hat eine Einladung für das Salve-Portal erhalten',
      InviteUser: 'Nutzer:in einladen',
      User: 'Nutzer:in',
      WelcomeMessage: 'Begrüßungsnachricht',
      Send: 'Senden',
      Cancel: 'Abbrechen',
      InviteSentTitle: 'Einladung gesendet',
      InviteSentMessage:
        'Diese Einladung wurde an den/ die Nutzer:in versendet',
      InviteSentErrorTitle: 'Der/ die Nutzer:in konnte nicht eingeladen werden',
      InviteSentErrorMessage:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut. Stellen Sie bitte außerdem sicher, dass die E-Mail-Adresse der/ des Nutzer:in richtig ist.',
      DeactivateSentTitle: 'Inaktive:r Nutzer:in',
      DeactivateSentMessage: 'Der/ die Nutzer:in wurde deaktiviert.',
      DeactivateSentErrorTitle:
        'Der/ die Nutzer:in konnte nicht deaktiviert werden.',
      DeactivateSentErrorMessage:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.  ',
      InviteMessage: (clinicURL) => `Hallo,
Willkommen bei Salve! Wenn Sie bereits ein Salve-Konto für ein anderes Klinikportal haben, verwenden Sie bitte Ihre bestehenden Anmeldedaten, wenn Sie auf dieses Portal zugreifen.
Wenn Sie neu bei Salve sind, haben wir Ihnen eine separate E-Mail mit Ihrem Benutzernamen und Ihrem temporären Passwort gesendet. Bitte verwenden Sie diese Daten, um sich anzumelden und ein dauerhaftes Konto in Ihrem Portal zu erstellen: ${clinicURL}\n
Ihr temporäres Passwort läuft in drei Tagen ab. Wenn Sie Ihr Konto nicht innerhalb dieser drei Tage einrichten, müssen Sie bei uns ein neues temporäres Passwort anfordern.
Bitte beachten Sie, dass Sie über Google Chrome oder Microsoft Edge auf das Salve-Portal zugreifen müssen. Salve ist nicht mit Internet Explorer kompatibel.`,
      SendTemporaryPassword: 'Neues temporäres Passwort erneut versenden',
      SendTemporaryPasswordModalTitle: 'Neues temporäres Passwort senden?',
      SendTemporaryPasswordModalText:
        'Wenn Sie auf „Senden“ klicken, wird dem/ der Mitarbeitenden ein neues temporäres Passwort an seine/ ihre E-Mail-Adresse gesendet.',
      SendTemporaryPasswordErrorTitle:
        'Das neue temporäre Passwort konnte nicht gesendet werden',
      SendTemporaryPasswordErrorDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
      SendTemporaryPasswordSuccessTitle:
        'Ein neues temporäres Passwort wurde gesendet.',
      SendTemporaryPasswordSuccessDescription: `Dem/ der Mitarbeitenden wurde ein neues temporäres Passwort gesendet.`,
    },
  },
  SuperAdmin: {
    DataExport: {
      Title: 'Datenexport',
      StartDateLabel: 'Startdatum',
      EndDateLabel: 'Enddatum',
      TimeFrameLabel: 'Zeitraum',
      RecipeLabel: 'Rezept',
      Today: 'Heute',
      Yesterday: 'Gestern',
      Last7Days: 'Letzte 7 Tage',
      LastWeek: 'Letzte Woche',
      ThisMonth: 'Dieser Monat',
      LastMonth: 'Letzter Monat',
      Last12Months: 'Letzte 12 Monate',
      CustomDates: 'Benutzerdefinierte Daten',
      MessagesTitle: 'Nachrichten',
      MessagesSubtitle:
        'Der Export enthält Details zu den Gesprächen zwischen Klinikpersonal und Patienten in einem ausgewählten Zeitraum',
      MessagesFilenameSuffix: 'Salve Messaging-Daten',
      SIDERecipeTitle: 'SIDE-Daten',
      SIDERecipeSubtitle:
        'Der Export enthält Details zu SIDE-Statistiken basierend auf einem Rezept',
      SIDERecipeFilenameSuffix: 'Salve Rezeptdaten',
      SIDERecipeInactive: 'Inaktiv',
      ToastSuccessTitle: 'Export erfolgreich',
      ToastSuccessDescription:
        'Die angeforderten Daten wurden erfolgreich exportiert. Bitte überprüfen Sie Ihren Download-Ordner',
      ToastFailureTitle: 'Export fehlgeschlagen',
      ToastFailureDescription:
        'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
    },
    NoAccessButtonText: 'Zurück zum Übersicht',
    NoAccessMessage:
      'Sie haben keine Berechtigung, auf diesen Bereich des Portals zuzugreifen. Wenn Sie denken, dass es sich hierbei um einen Fehler handelt, wenden Sie sich bitte an Ihren Vorgesetzten.',
  },
  General: {
    OpenAttachmentFileErrorTitle: 'Die Datei konnte nicht geöffnet werden',
    OpenAttachmentFileErrorMessage:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
    ConnectionError:
      'Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut',
  },
  GlobalCMS: {
    Back: 'Zurück',
  },
};
