import { StarredStatus } from './types';

// WebSocket messages
export const MESSAGE_MAX_VALID_ELAPSED_MS = 60000;
export const MARK_MESSAGE_AS_NOT_TYPING_AFTER_MS = 30000;
export const MARK_MESSAGE_AS_TYPED_AFTER_MS = 30000;

export const POLLING_INTERVAL_MS = 30000; // 30 seconds

export const MESSAGE_MAX_LENGTH = 2500;
export const MESSAGE_MIN_LENGTH = 1;

export const SUBJECT_MAX_LENGTH = 256;

export const STARRED_DELAY_MS = 600;

export const TYPING_DELAY_MS = 1500;

export const EMPTY_CONVERSATION_STATE = '';

export const Status: Record<'STARRED' | 'OTHERS', StarredStatus> = {
  STARRED: 1,
  OTHERS: 0,
};

export const StatusTab: Record<string, StarredStatus> = {
  0: Status.STARRED,
  STARRED: 0,
  1: Status.OTHERS,
  OTHERS: 1,
};

export const StatusName = {
  STARRED: 'starred',
  OTHERS: 'others',
};

export const StatusIdMap = {
  RESOLVED: 999,
};

export const Action = {
  MESSAGE_DELETE: 'MESSAGE_DELETE',
  CONVERSATION_MARK_UNREAD: 'CONVERSATION_MARK_UNREAD',
  CONVERSATION_MARK_RESOLVED: 'CONVERSATION_MARK_RESOLVED',
  CONVERSATION_MARK_UNRESOLVED: 'CONVERSATION_MARK_UNRESOLVED',
  CONVERSATION_MOVE_TO: 'CONVERSATION_MOVE_TO',
  CONVERSATION_COPY_HISTORY: 'CONVERSATION_COPY_HISTORY',
  CONVERSATION_ASSIGN: 'CONVERSATION_ASSIGN',
  CONVERSATION_UNASSIGN: 'CONVERSATION_UNASSIGN',
};

export const queryCacheKey = {
  CONVERSATION: 'CONVERSATION',
  CONVERSATIONS: 'CONVERSATIONS',
  CONVERSATION_FOLDERS: 'CONVERSATION_FOLDERS',
  CONVERSATION_ASSIGNED_FOLDER: 'CONVERSATION_ASSIGNED_FOLDER',
  CONVERSATIONS_COUNT_BY_STATUS: 'CONVERSATIONS_COUNT_BY_STATUS',
  PATIENT_STAFF: 'PATIENT_STAFF',
  PATIENT_STAFF_INFINITE: 'PATIENT_STAFF_INFINITE',
  MESSAGES: 'MESSAGES',
  LATEST_CONVERSATIONS: 'LATEST_CONVERSATIONS',
  CLINIC_USERS: 'CLINIC_USERS',
  MYSELF: 'MYSELF',
  MYSELF_WITH_CLINICS: 'MYSELF_WITH_CLINICS',
  SIDE_RECIPES_LIST: 'SIDE_RECIPES_LIST',
};

export const DEFAULT_TOAST_OPTIONS = {
  title: '',
  timeout: 5000,
};

export enum VirtualFolders {
  'Mine',
  'AllUnresolved',
  'AllResolved',
  'AllMessages',
}
