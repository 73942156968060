import { AssignClinicUsers } from '@react/lib/api/types';
import { ClinicUserMenu } from '@react/pages/conversations/components/ClinicUserMenu';
import { uniqBy } from 'lodash';
import React from 'react';
import { DropdownMenuItem } from './DropdownMenu';
import NestedMenuItem, { NestedMenuItemProps } from './NestedMenuItem';

export interface AssignMenuItemProps {
  item: DropdownMenuItem;
  isOpen: boolean;
  nestedMenuProps?: NestedMenuItemProps['MenuProps'];
  assignClinicUsers: AssignClinicUsers;
  handleClose: (value?: DropdownMenuItem) => void;
}

export const AssignMenuItem: React.FC<AssignMenuItemProps> = ({
  item,
  isOpen,
  nestedMenuProps,
  assignClinicUsers,
  handleClose,
}) => {
  const { onClinicUserAssignItemClick, clinicUsers, me, isLoadingClinicUsers } =
    assignClinicUsers;

  if (!clinicUsers || !me) {
    return null;
  }

  function onClickUserMenu(value: number): void {
    if (onClinicUserAssignItemClick) {
      onClinicUserAssignItemClick(value);
    }
    handleClose();
  }

  const uniqueClinicUsers = uniqBy(clinicUsers, 'id');

  const assignClinicUserNestedProps: NestedMenuItemProps['MenuProps'] = {
    ...nestedMenuProps,
    anchorOrigin: { vertical: 'top', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'right' },
  };

  return (
    <NestedMenuItem
      key={item.name}
      label={item.name}
      parentMenuOpen={isOpen}
      MenuProps={assignClinicUserNestedProps}
    >
      <ClinicUserMenu
        key={`${item.name}-ClinicUserMenu`}
        onClick={onClickUserMenu}
        clinicUsers={uniqueClinicUsers}
        me={me}
        conversation={item.conversation}
        unassign={item.unassign}
        loading={isLoadingClinicUsers}
      />
    </NestedMenuItem>
  );
};
