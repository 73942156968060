import { Paper } from '@material-ui/core';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect, useState } from 'react';
import { LoadingWrapper, StyledList, StyledListItem, StyledListItemText, StyledSearchBar, StyledWrapper, } from './ClinicUserFilter.styled';
import { Loading } from './Loading';
var unassignUser = function (unassign, id) { return (unassign ? null : id); };
var ɵ0 = unassignUser;
export var UserOption = function (_a) {
    var idKey = _a.id, messageid = _a.messageid, option = _a.option, onClickUser = _a.onClickUser, _b = _a.unassign, unassign = _b === void 0 ? false : _b;
    var id = option.id, userfullname = option.userfullname;
    if (!id) {
        return null;
    }
    return (React.createElement(StyledListItem, { button: true, id: idKey + "-" + id, dense: true, onClick: function () {
            return onClickUser({
                userId: unassignUser(unassign, id),
                messageId: messageid,
            });
        }, style: { paddingTop: 8, paddingBottom: 8 } },
        React.createElement(StyledListItemText, { id: "" + id, primary: userfullname, weight: unassign ? 'bold' : 'normal' })));
};
export var ClinicUserFilter = function (_a) {
    var id = _a.id, clinicUsers = _a.clinicUsers, defaultOption = _a.defaultOption, onClick = _a.onClick, onSearchUpdate = _a.onSearchUpdate, searchState = _a.searchState, unassignOption = _a.unassignOption, messageid = _a.messageid, loading = _a.loading;
    var t = useTranslations().t;
    var _b = useState(false), showDefaults = _b[0], setShowDefaults = _b[1];
    function onSearchChange(value) {
        if (onSearchUpdate) {
            onSearchUpdate(value);
        }
    }
    function onClickUser(value) {
        if (onClick) {
            onClick(value);
        }
    }
    useEffect(function () {
        if (searchState === '') {
            setShowDefaults(true);
        }
        else {
            setShowDefaults(false);
        }
    }, [searchState]);
    if (loading) {
        return (React.createElement(StyledWrapper, { id: id },
            React.createElement(LoadingWrapper, null,
                React.createElement(Loading, { "data-testid": "assign-user-loading" }))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledWrapper, { id: id },
            React.createElement(StyledSearchBar, { value: searchState, onChange: function (value) { return onSearchChange(value); }, placeholder: t.Messages.SearchUsers, shadow: "true" }),
            React.createElement(Paper, { style: { boxShadow: 'none' } },
                React.createElement(StyledList, { dense: true },
                    showDefaults && unassignOption && (React.createElement(UserOption, { id: 'unassign', key: "unassign-" + unassignOption.id, messageid: messageid, option: unassignOption, onClickUser: onClickUser, unassign: true })),
                    showDefaults && (React.createElement(UserOption, { id: 'me', key: "me-" + defaultOption.id, messageid: messageid, option: defaultOption, onClickUser: onClickUser })),
                    !showDefaults &&
                        clinicUsers.map(function (user) {
                            return (React.createElement(UserOption, { id: 'user', key: "user-" + user.id, messageid: messageid, option: user, onClickUser: onClickUser }));
                        }))))));
};
export { ɵ0 };
