import { useApiClient } from '@react/lib/api';
import { MessagesFilterPayload } from '@react/lib/api/types';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { formatMessage } from '../lib';

export function useMessagesQuery(filters: MessagesFilterPayload) {
  const apiClient = useApiClient().portal;

  return useQuery(
    [queryCacheKey.MESSAGES, filters],
    async () => {
      const { data } = await apiClient.fetchMessages(filters);
      return data.map(formatMessage);
    },
    {
      refetchInterval: POLLING_INTERVAL_MS,
    },
  );
}
