import styled from '@emotion/styled';
import { Container as ContainerBase } from './Container';

export const Container = styled(ContainerBase)`
  height: 100%;
  overflow: auto;
  padding: 0;
`;

export const ContainerInner = styled.div`
  height: auto;
  padding-bottom: 1rem;
`;
