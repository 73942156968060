import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ContentActionTypes, } from '../../actions/content.actions';
import { MoveEntries } from '../../actions/content.actions';
var MoveEntriesComponent = /** @class */ (function () {
    function MoveEntriesComponent(_store, _updates, dialogRef, dialog, data) {
        this._store = _store;
        this._updates = _updates;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.movingEntries$ = new BehaviorSubject(false);
        this.movedEntries$ = new BehaviorSubject(false);
        this.errorEncountered$ = new BehaviorSubject(false);
        this.categories = new FormControl('', [Validators.required]);
        this._subs = new Subscription();
    }
    MoveEntriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this.categories.valueChanges.subscribe(function (c) { return (_this.selectedCategory = c); }));
        this._subs.add(this._updates
            .pipe(ofType(ContentActionTypes.MoveEntriesSuccess))
            .subscribe(function () {
            _this.movingEntries$.next(false);
            _this.movedEntries$.next(true);
            _this.exitSuccessfully();
        }));
        this._subs.add(this._updates
            .pipe(ofType(ContentActionTypes.MoveEntriesError))
            .subscribe(function () {
            _this.movingEntries$.next(false);
            _this.errorEncountered$.next(true);
            _this.exitOnError();
        }));
    };
    MoveEntriesComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    MoveEntriesComponent.prototype.onCancelClick = function () {
        this.dialogRef.close(false);
    };
    MoveEntriesComponent.prototype.onMoveClick = function () {
        this.movingEntries$.next(true);
        var newCategoryId = this.selectedCategory.id;
        this._store.dispatch(new MoveEntries({
            oldCategoryId: this.data.currentCategoryId,
            entries: this.data.entries,
            newCategoryId: newCategoryId,
        }));
    };
    MoveEntriesComponent.prototype.exitSuccessfully = function () {
        var _this = this;
        setTimeout(function () { return _this.dialogRef.close(true); }, 1000);
    };
    MoveEntriesComponent.prototype.exitOnError = function () {
        var _this = this;
        setTimeout(function () { return _this.dialogRef.close(false); }, 1000);
    };
    return MoveEntriesComponent;
}());
export { MoveEntriesComponent };
