import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromAuth from '../../auth/reducers';
var ErrorAnnotationService = /** @class */ (function () {
    function ErrorAnnotationService(_store) {
        var _this = this;
        this._store = _store;
        this._subs = new Subscription();
        this._user$ = this._store.pipe(select(fromAuth.getUser));
        this._subs.add(this._user$.subscribe(function (u) { return (_this.user = u); }));
    }
    ErrorAnnotationService.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    ErrorAnnotationService.prototype.setExtra = function (data) {
        this._extra = data;
    };
    ErrorAnnotationService.prototype.annotateError = function (error) {
        return tslib_1.__assign({}, error, { user: this.user });
    };
    ErrorAnnotationService.prototype._clearExtra = function () {
        this._extra = undefined;
    };
    return ErrorAnnotationService;
}());
export { ErrorAnnotationService };
