import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthActionsUnion, AuthActionTypes } from '../actions/auth.actions';
import { User } from '../models/user';
import convertCognitoUser from '../utils/convert-cognito-user';

// If this state gets changed be aware that an older version is stored in the
// user's LocalStorage. You may need to patch it if adding/changing keys.
// See `localStorageSyncReducer` in src/app/reducers/index.ts
export interface State {
  loggedIn: boolean;
  loggingIn: boolean;
  updatingPassword: boolean;
  updatePasswordError: any;
  user: User;
  isCognito: boolean;
  cognitoUser: CognitoUser | any;
  clinicId: string;
  isCore: boolean;
  clinicName: string;
  languageCode: string;
  availableToSelect: boolean;
  inviteCode: string;
  generatingCode: boolean;
  codeGenerated: boolean;
  resettingPassword: boolean;
  otpError: boolean;
  clinicGroupId: string;
  internalClinicId: string;
}

export const initialState: State = {
  loggedIn: false,
  loggingIn: false,
  updatingPassword: false,
  updatePasswordError: null,
  user: null,
  isCognito: false,
  cognitoUser: null,
  clinicId: '',
  isCore: null,
  clinicName: '',
  languageCode: '',
  availableToSelect: null,
  inviteCode: '',
  generatingCode: false,
  codeGenerated: false,
  resettingPassword: false,
  otpError: false,
  clinicGroupId: null,
  internalClinicId: null
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {
    case AuthActionTypes.CoreLogin: {
      return {
        ...state,
        isCognito: true,
        loggingIn: true,
        otpError: false
      };
    }
    case AuthActionTypes.GetClinicIdSuccess: {
      return {
        ...state,
        clinicId: action.payload.Token,
        isCore: action.payload.Core,
        clinicName: action.payload.Name,
        languageCode: action.payload.LanguageCode,
        availableToSelect: action.payload.AvailableToSelect,
        inviteCode: action.payload.InviteCode,
        clinicGroupId: action.payload.clinicgroupid,
        internalClinicId: action.payload.internalclinicid
      };
    }
    case AuthActionTypes.CoreLoginSuccess: {
      const { user, signInUserSession } = convertCognitoUser(
        action.payload.user
      );
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        isCognito: true,
        otpError: false,
        user,
        cognitoUser: signInUserSession
      };
    }
    case AuthActionTypes.CoreLoginFailure: {
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        otpError: false,
        isCognito: true,
        user: null,
        cognitoUser: null
      };
    }
    case AuthActionTypes.Logout: {
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        otpError: false,
        user: null,
        cognitoUser: null
      };
    }
    case AuthActionTypes.CompletePassword: {
      return {
        ...state,
        updatingPassword: true
      };
    }
    case AuthActionTypes.CompletePasswordSuccess: {
      const { user, signInUserSession } = convertCognitoUser(
        action.payload.user
      );
      return {
        ...state,
        updatingPassword: false,
        loggingIn: false,
        loggedIn: true,
        isCognito: true,
        user,
        cognitoUser: signInUserSession
      };
    }
    case AuthActionTypes.CompletePasswordFailure: {
      return {
        ...state,
        updatingPassword: false,
        updatePasswordError: action.payload,
        cognitoUser: action.payload.user
      };
    }
    case AuthActionTypes.TempSuccess: {
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        isCognito: true,
        cognitoUser: action.payload.user
      };
    }
    case AuthActionTypes.ResetPassword: {
      return {
        ...state,
        resettingPassword: true
      };
    }
    case AuthActionTypes.ResetPasswordSuccess:
    case AuthActionTypes.ResetPasswordFailure: {
      return {
        ...state,
        resettingPassword: false
      };
    }
    case AuthActionTypes.GenerateResetCode: {
      return {
        ...state,
        generatingCode: true
      };
    }
    case AuthActionTypes.GenerateResetCodeSuccess: {
      return {
        ...state,
        codeGenerated: true,
        generatingCode: false
      };
    }
    case AuthActionTypes.GenerateResetCodeFailure: {
      return {
        ...state,
        generatingCode: false
      };
    }
    case AuthActionTypes.ClearCodeGenerated: {
      return {
        ...state,
        updatingPassword: false,
        codeGenerated: false
      };
    }
    case AuthActionTypes.OTPRequired: {
      return {
        ...state,
        loggingIn: false,
        cognitoUser: action.payload.user
      };
    }
    case AuthActionTypes.VerifyOTP: {
      return {
        ...state,
        loggingIn: true,
        cognitoUser: action.payload.user
      };
    }
    case AuthActionTypes.OTPRejected: {
      return {
        ...state,
        loggingIn: false,
        cognitoUser: action.payload.user,
        otpError: true
      };
    }
    case AuthActionTypes.OTPLoginFailure: {
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        otpError: false,
        isCognito: true,
        user: null,
        cognitoUser: null
      };
    }
    default: {
      return state;
    }
  }
}

export const isLoggingIn = (state: State) => state.loggingIn;
export const OTPError = (state: State) => state.otpError;
export const isCognito = (state: State) => state.isCognito;
export const getCognitoUser = (state: State) => state.cognitoUser;
export const getLoggedIn = (state: State) => state.loggedIn;
export const getUser = (state: State) => state.user;
export const getClinicId = (state: State) => state.clinicId;
export const getPublicKey = (user: User) => (user ? user.PublicToken : null);
export const getClinicName = (state: State) => state.clinicName;
export const getLanguageCode = (state: State) => state.languageCode;
export const getAvailableToSelect = (state: State) => state.availableToSelect;
export const getInviteCode = (state: State) => state.inviteCode;
export const getCore = (state: State) => state.isCore;
export const isUpdatingPassword = (state: State) => state.updatingPassword;
export const isResettingPassword = (state: State) => state.resettingPassword;
export const isGeneratingCode = (state: State) => state.generatingCode;
export const hasGeneratedCode = (state: State) => state.codeGenerated;
export const getInternalClinicId = (state: State) => state.internalClinicId;
export const getClinicGroupId = (state: State) => state.clinicGroupId;
export const hasUpdatePasswordError = (state: State) =>
  state.updatePasswordError;
