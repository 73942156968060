var MarkMessageUnstarredRequest = /** @class */ (function () {
    function MarkMessageUnstarredRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId;
        this.Action = 'MarkMessageUnstarred';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
    }
    return MarkMessageUnstarredRequest;
}());
export { MarkMessageUnstarredRequest };
