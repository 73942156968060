import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import {
  AddToMultiplePacksBody,
  AddToMultiplePacksResponse,
  AddToPackResponse,
  AlterPackContentInterface,
  CheckContentPackNameResponse,
  CreateContentPackInterface,
  CreateContentPackResponse,
  DeletePackInterface,
  DeletePackResponse,
  EditContentPackInterface,
  EditContentPackResponse,
  GetContentPackDetailResponse,
  GetContentPacksResponse,
  RemoveFromPackResponse
} from './../models/content-packs';

@Injectable()
export class PacksService {
  constructor(
    private _httpService: HttpService,
    private _error: ErrorHandler
  ) {}

  getContentPacks(): Observable<GetContentPacksResponse> {
    return this._httpService
      .get<GetContentPacksResponse>(EndpointType.Content, `content/pack`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getContentPackDetail(
    packId: number
  ): Observable<GetContentPackDetailResponse> {
    return this._httpService
      .get<GetContentPackDetailResponse>(
        EndpointType.Content,
        `content/pack/${packId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  checkContentPackName(name: string): Observable<CheckContentPackNameResponse> {
    return this._httpService
      .get<CheckContentPackNameResponse>(
        EndpointType.Content,
        `content/pack/name`,
        { name }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createContentPack(
    createPack: CreateContentPackInterface
  ): Observable<CreateContentPackResponse> {
    return this._httpService
      .post<CreateContentPackResponse>(
        EndpointType.Content,
        `content/pack`,
        createPack
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  editContentPack(
    editPack: EditContentPackInterface
  ): Observable<EditContentPackResponse> {
    return this._httpService
      .put<EditContentPackResponse>(
        EndpointType.Content,
        `content/pack/${editPack.packId}`,
        { name: editPack.name }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteContentPack(
    deletePack: DeletePackInterface
  ): Observable<DeletePackResponse> {
    return this._httpService
      .delete<DeletePackResponse>(
        EndpointType.Content,
        `content/pack/${deletePack.packId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addToContentPack({
    packId,
    contentEntries
  }: AlterPackContentInterface): Observable<AddToPackResponse> {
    return this._httpService
      .post<AddToPackResponse>(
        EndpointType.Content,
        `content/pack/${packId}/add`,
        { contentEntries }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  addToMultipleContentPacks({
    packIds,
    contentEntries
  }: AddToMultiplePacksBody): Observable<AddToMultiplePacksResponse> {
    return this._httpService
      .post<AddToMultiplePacksResponse>(
        EndpointType.Content,
        `content/pack/add`,
        { contentEntries, packIds }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  removeFromContentPack({
    packId,
    contentEntries
  }: AlterPackContentInterface): Observable<RemoveFromPackResponse> {
    return this._httpService
      .deleteWithBody<RemoveFromPackResponse>(
        EndpointType.Content,
        `content/pack/${packId}/remove`,
        { contentEntries }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
