import { IconButton } from '@material-ui/core';
import {
  Check as CheckIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { DropdownMenu, Text } from '@react/components';
import { ProfilePhoto } from '@react/components/ProfilePhoto';
import { StaffProfiles } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import { Message } from '@react/types';

import React, { FunctionComponent } from 'react';
import { IFileUpload } from 'src/app/core/services/s3.service';
import { Action } from '../constants';
import { MessageActionMenuItem } from '../types';
import {
  ChatMessageStyled,
  ChatMessageWrapperStyled,
  Content,
  Header,
  ProfilePictureStyled,
} from './ChatMessage.styled';
import { FileDescription } from './FileDescription';

export interface ChatMessageProps {
  message: Message;
  alignDirection: 'left' | 'right';
  onActionClick: (action: MessageActionMenuItem, message: Message) => void;
  onFileClick: (message: Message) => void;
  fileUploadService: IFileUpload;
  staffProfiles: StaffProfiles;
  featureProfilePhoto: boolean;
}

export const ChatMessage: FunctionComponent<ChatMessageProps> = ({
  alignDirection,
  message,
  onActionClick,
  onFileClick,
  fileUploadService,
  staffProfiles,
  featureProfilePhoto,
}) => {
  const { t } = useTranslations();
  const dateTime = useLocaleDateTime();

  const formatSentAt = (date: string) =>
    dateTime.format(new Date(date), 'PPP - p');

  const formatDate = (date: string) => dateTime.format(new Date(date), 'PP');

  const formatTime = (date: string) => dateTime.format(new Date(date), 'p');

  const actions: MessageActionMenuItem[] = [
    {
      actionId: Action.MESSAGE_DELETE,
      name: t.Messages.DeleteMessageItem,
      ...message,
    },
  ];

  const handleActionClick = (action: MessageActionMenuItem) => {
    onActionClick(action, message);
  };

  const {
    clinicuserfirstname,
    clinicuserfullname,
    clinicuserid,
    deleteddate,
    deletedbyclinicuser,
    lastmessagegrouped,
    patientfirstname,
    patientsent,
    patientattachmentfilename,
    patientattachmentfilesize,
    read,
    readdate,
  } = message;

  const sender = patientsent ? patientfirstname : clinicuserfullname;

  const isRemoveVisible = !deleteddate && !patientsent && !read;
  const isDeletedMessageVisible = Boolean(!patientsent && deletedbyclinicuser);
  const isSeenMessageVisible = !isDeletedMessageVisible && !patientsent && read;

  const clinicUserId = !patientsent && clinicuserid ? clinicuserid : undefined;
  const hasClinicUserId = Boolean(clinicUserId);
  const showProfilePhoto =
    Boolean(lastmessagegrouped && hasClinicUserId) && featureProfilePhoto;

  return (
    <ChatMessageWrapperStyled
      className={`chat-message-wrapper__${alignDirection} ${
        showProfilePhoto ? 'grid' : 'grouped'
      }`}
    >
      {showProfilePhoto && (
        <ProfilePictureStyled className={'profile-message'}>
          <ProfilePhoto
            fileUploadService={fileUploadService}
            size="regular"
            clinicUserId={clinicUserId}
            showInitials
            staffProfiles={staffProfiles}
          />
        </ProfilePictureStyled>
      )}
      <ChatMessageStyled className={`chat-message-wrapper__${alignDirection}`}>
        <Header>
          <Text.Small bold>{sender}</Text.Small>
          <Text.Small className="timestamp">
            {formatSentAt(message.sentdate)}
          </Text.Small>
          {isRemoveVisible && (
            <DropdownMenu
              data-testid="chat-message--actions"
              menu={actions}
              onClick={handleActionClick}
            >
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
            </DropdownMenu>
          )}
        </Header>
        {patientattachmentfilename ? (
          <FileDescription
            filename={patientattachmentfilename}
            fileSize={patientattachmentfilesize}
            onClick={() => onFileClick(message)}
          />
        ) : (
          <Content>{message.content}</Content>
        )}
        {isDeletedMessageVisible && deleteddate && (
          <Text.Small className="deleted-by" block>
            {t.Messages.DeletedMessageText(
              clinicuserfirstname,
              formatDate(deleteddate),
              formatTime(deleteddate),
            )}
          </Text.Small>
        )}
        {isSeenMessageVisible && (
          <Text.Small className="read-by" block>
            <CheckIcon />{' '}
            {t.Messages.ReadBy(
              patientfirstname,
              formatTime(readdate),
              formatDate(readdate),
            )}
          </Text.Small>
        )}
      </ChatMessageStyled>
    </ChatMessageWrapperStyled>
  );
};
