export enum TimeFrames {
  Today = '0',
  Yesterday = '1',
  Last7Days = '2',
  LastWeek = '3',
  ThisMonth = '4',
  LastMonth = '5',
  Last12Months = '6',
  CustomDates = '7',
}

export enum DataExportTypes {
  PatientSignUps = 'exportPatientSignUps',
  Messages = 'exportMessages',
}

export interface IDataExportHookParams {
  readonly type: DataExportTypes;
  readonly from: string;
  readonly to: string;
  readonly onDownloadSuccess: () => void;
  readonly onDownloadFailure: () => void;
}

export interface ISIDEExportHookParams {
  readonly sideRecipeId: string;
  readonly from: string;
  readonly to: string;
  readonly onDownloadSuccess: () => void;
  readonly onDownloadFailure: () => void;
}

export interface IDataExportHookResult {
  readonly download: (fileNameSuffix: string) => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly fileUrl: string | undefined;
  readonly fileName: string | undefined;
}
