import * as tslib_1 from "tslib";
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Cancel as IconCancel, Search as IconSearch } from '@material-ui/icons';
import * as React from 'react';
import { StyledPaper } from './SearchBar.styled';
export var SearchBar = function (_a) {
    var className = _a.className, _b = _a.value, value = _b === void 0 ? '' : _b, onChange = _a.onChange, placeholder = _a.placeholder, endAdornment = _a.endAdornment, rest = tslib_1.__rest(_a, ["className", "value", "onChange", "placeholder", "endAdornment"]);
    function onCancel() {
        onChange('');
    }
    var onKeyDown = function (e) {
        e.stopPropagation();
    };
    var showClearButton = value.length > 0;
    var endAdornments = [
        showClearButton ? (React.createElement(InputAdornment, { key: "cancelAdornment", position: "end" },
            React.createElement(IconButton, { onClick: onCancel, size: "small", role: "button" },
                React.createElement(IconCancel, null)))) : undefined,
        React.createElement(React.Fragment, { key: "endAdornment" }, endAdornment),
    ];
    return (React.createElement(StyledPaper, tslib_1.__assign({ className: className }, rest),
        React.createElement(TextField, { autoComplete: "off", fullWidth: true, inputProps: { role: 'searchbox' }, InputProps: {
                disableUnderline: true,
                endAdornment: endAdornments,
                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                    React.createElement(IconSearch, null))),
            }, onKeyDown: onKeyDown, onChange: function (e) { return onChange(e.target.value); }, placeholder: placeholder, spellCheck: "false", type: "search", value: value, variant: "standard" })));
};
export default SearchBar;
