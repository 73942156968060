import { EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var EmailOTPFormComponent = /** @class */ (function () {
    function EmailOTPFormComponent(_fb) {
        this._fb = _fb;
        this.verifyOTP = new EventEmitter();
        this.cancelOTPLogin = new EventEmitter();
        this._setupForm();
    }
    Object.defineProperty(EmailOTPFormComponent.prototype, "loggingIn", {
        set: function (isLoggingIn) {
            if (isLoggingIn) {
                this.OTPForm.disable();
            }
            else {
                this.OTPForm.enable();
            }
            this.isLoggingIn = isLoggingIn;
        },
        enumerable: true,
        configurable: true
    });
    EmailOTPFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.isCore) {
            if (changes.isCore.currentValue !== changes.isCore.previousValue) {
                this._setupForm();
            }
        }
    };
    EmailOTPFormComponent.prototype._setupForm = function () {
        this.OTPForm = this._fb.group({
            code: [
                {
                    value: '',
                    disabled: false,
                },
                [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
            ],
        });
    };
    EmailOTPFormComponent.prototype.submitOTP = function () {
        var code = this.OTPForm.value.code;
        this.verifyOTP.emit({
            user: this.cognitoUser,
            code: code,
            toast: {
                title: this.text.OTPToastTitle,
                message: this.text.OTPToastMessage,
                timeout: 0,
                type: 'error',
            },
        });
    };
    EmailOTPFormComponent.prototype.cancelOTP = function () {
        this.cancelOTPLogin.emit();
    };
    return EmailOTPFormComponent;
}());
export { EmailOTPFormComponent };
