/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integration-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/material/tooltip";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/scrolling";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/common";
import * as i12 from "./integration-status.component";
var styles_IntegrationStatusComponent = [i0.styles];
var RenderType_IntegrationStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationStatusComponent, data: {} });
export { RenderType_IntegrationStatusComponent as RenderType_IntegrationStatusComponent };
function View_IntegrationStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 7, "div", [["class", "status"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"], ["fxLayoutGap", "20px"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(5, 147456, null, 0, i5.MatTooltip, [i6.Overlay, i1.ElementRef, i7.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i8.Platform, i9.AriaDescriber, i9.FocusMonitor, i5.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i4.Directionality], [2, i5.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "blob"]], [[2, "green", null], [2, "error", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "20px"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "start center"; _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.text.Tooltip(_co._version), ""); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.synchronised; var currVal_5 = !_co.synchronised; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = _co.text.SalveConnection; _ck(_v, 8, 0, currVal_6); }); }
export function View_IntegrationStatusComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.integrated; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IntegrationStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-integration-status", [], null, null, null, View_IntegrationStatusComponent_0, RenderType_IntegrationStatusComponent)), i1.ɵdid(1, 49152, null, 0, i12.IntegrationStatusComponent, [], null, null)], null, null); }
var IntegrationStatusComponentNgFactory = i1.ɵccf("portal-integration-status", i12.IntegrationStatusComponent, View_IntegrationStatusComponent_Host_0, { text: "text", integrated: "integrated", synchronised: "synchronised" }, {}, []);
export { IntegrationStatusComponentNgFactory as IntegrationStatusComponentNgFactory };
