import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Menu } from '@material-ui/core';
export var StyledMenu = styled(Menu)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  .MuiMenu-list {\n    background-color: ", ";\n  }\n\n  li {\n    padding-right: 2.5rem;\n\n    > svg {\n      position: absolute;\n      right: 0.5rem;\n    }\n  }\n\n  .MuiListItem-focusVisible,\n  .MuiListItem-button:hover {\n    background-color: ", ";\n  }\n"], ["\n  .MuiMenu-list {\n    background-color: ", ";\n  }\n\n  li {\n    padding-right: 2.5rem;\n\n    > svg {\n      position: absolute;\n      right: 0.5rem;\n    }\n  }\n\n  .MuiListItem-focusVisible,\n  .MuiListItem-button:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grayLight;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryLight;
});
var templateObject_1;
