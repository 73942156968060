import styled from '@emotion/styled';
import * as React from 'react';

const StyledSpan = styled.span<{ isAtMaxLength: boolean }>`
  color: ${({ isAtMaxLength, theme }) =>
    isAtMaxLength ? theme.colors.error : theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  user-select: none;
`;

interface Props {
  className?: string;
  length: number;
  maxLength?: number;
}

function getCharCount(
  length: Props['length'],
  maxLength?: Props['maxLength'],
): string {
  return maxLength ? `${length} / ${maxLength}` : String(length);
}

export const CharacterCount: React.VFC<Props> = ({
  className,
  length,
  maxLength,
}) => (
  <StyledSpan
    className={className}
    isAtMaxLength={maxLength ? length >= maxLength : false}
  >
    {getCharCount(length, maxLength)}
  </StyledSpan>
);
