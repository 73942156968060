import { Box } from '@material-ui/core';
import { Button } from '@react/components/Button';
import { List } from '@react/components/filter/list';
import { CognitoStatus, IClinicUserFilters } from '@react/lib/api/types';
import { useTranslations } from '@react/lib/i18n';
import React, { FunctionComponent, useState } from 'react';
import {
  StyledActionsContainer,
  StyledContainer,
  StyledIndicator,
  StyledPanel,
  StyledTitle,
} from './index.styled';

export interface IProps {
  filters: IClinicUserFilters;
  apply: (filters: IClinicUserFilters) => void;
}

const FilterPanel: FunctionComponent<IProps> = ({ filters, apply }) => {
  const [localFilters, setLocalFilters] = useState<IClinicUserFilters>(filters);

  const { t } = useTranslations();

  const setStatus = (key: keyof typeof CognitoStatus) => {
    let status: Array<keyof typeof CognitoStatus> = Array.isArray(
      localFilters.status,
    )
      ? Array.from(localFilters.status)
      : [];
    if (status.includes(key)) {
      status = Array.from(status).filter((item) => item !== key);
    } else {
      status.push(key);
    }

    setLocalFilters({
      ...localFilters,
      status,
    });
  };

  return (
    <>
      <Box>
        <StyledContainer value={0}>
          <StyledTitle>{t.Admin.Users.FilterTabStatus}</StyledTitle>
          <StyledIndicator />
        </StyledContainer>
      </Box>
      <StyledPanel>
        <List<string>
          data={Object.keys(CognitoStatus)}
          isActive={(item: string) =>
            localFilters.status
              ? localFilters.status.includes(
                  CognitoStatus[item as CognitoStatus],
                )
              : false
          }
          id="StatusFilters"
          onClick={(item: string) =>
            setStatus(CognitoStatus[item as CognitoStatus])
          }
          getLabel={(key: string) =>
            t.Admin.Users[`${key as keyof typeof CognitoStatus}Status`]
          }
        />
      </StyledPanel>
      <StyledActionsContainer>
        <Button
          data-testid="btn_apply"
          color="primary"
          variant="contained"
          onClick={() => apply(localFilters)}
        >
          {t.Admin.Users.FilterApply}
        </Button>
      </StyledActionsContainer>
    </>
  );
};

export default FilterPanel;
