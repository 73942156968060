import * as React from 'react';

export const EmptyActionListIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="64"
    height="60"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="#6538A5" fillRule="nonzero" fillOpacity=".35">
      <path d="M16.962 38.172a1.239 1.239 0 1 0-1.24-2.145 1.239 1.239 0 0 0 1.24 2.145Z" />
      <path d="m11.887 46.72 6.236 10.803c1.346 2.333 4.374 3.197 6.768 1.813a4.951 4.951 0 0 0 1.812-6.767l-3.715-6.436 3.218-1.857c.592-.343.795-1.1.453-1.692l-1.61-2.79c.192-.036 1.454-.284 23.72-4.652 2.753-.15 4.402-3.16 3.011-5.567l-4.115-7.128 2.63-3.984c.258-.392.274-.895.039-1.302l-2.477-4.29a1.241 1.241 0 0 0-1.147-.616l-4.764.285-4.57-7.914a3.684 3.684 0 0 0-3.17-1.857h-.048c-1.279 0-2.44.636-3.128 1.712l-16.178 20.61-10.517 6.072c-4.137 2.387-5.57 7.694-3.173 11.842 2.19 3.792 6.805 5.284 10.725 3.715Zm12.672 7.088a2.475 2.475 0 0 1-.908 3.383 2.48 2.48 0 0 1-3.383-.906l-6.192-10.727 4.29-2.477 6.193 10.727Zm-2.81-9.82-1.238-2.145 2.145-1.238 1.239 2.145-2.145 1.238Zm24.346-29.216 1.711 2.965-1.526 2.311-2.95-5.11 2.765-.166ZM33.102 5.84c.332-.543.86-.6 1.073-.594.211.003.738.069 1.056.62l14.404 24.949a1.238 1.238 0 0 1-1.042 1.857c-.168.003-.246.034-.679.115L32.677 6.394c.32-.41.361-.45.425-.555Zm-2.073 2.655L45.345 33.29l-21.606 4.237L17.17 26.15 31.03 8.494ZM3.307 41.766a6.184 6.184 0 0 1-.831-3.09 6.215 6.215 0 0 1 3.097-5.368l9.653-5.573 6.192 10.725-9.653 5.573a6.2 6.2 0 0 1-8.458-2.267Z" />
      <path d="M13.125 38.957a1.239 1.239 0 0 0-1.691-.453l-2.145 1.239a1.24 1.24 0 0 1-1.692-.454 1.239 1.239 0 0 0-2.145 1.239 3.72 3.72 0 0 0 5.075 1.36l2.145-1.239c.592-.342.795-1.099.453-1.692ZM61.566 5.418l-6.9 3.84a1.239 1.239 0 0 0 1.204 2.164l6.9-3.84a1.238 1.238 0 1 0-1.204-2.164ZM60.843 18.244l-4.785-1.282a1.238 1.238 0 1 0-.641 2.392l4.785 1.282a1.238 1.238 0 1 0 .64-2.392ZM48.818.918l-1.283 4.785a1.239 1.239 0 0 0 2.393.642L51.21 1.56a1.238 1.238 0 1 0-2.393-.642Z" />
    </g>
  </svg>
);
