import styled from '@emotion/styled';
import { TableRow } from '@material-ui/core';

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f0f0f7;
    cursor: pointer;
  }

  td {
    vertical-align: top;
  }
`;
