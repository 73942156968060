import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
export var ChatMessageWrapperStyled = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  width: 100%;\n\n  &.grid {\n    display: grid;\n    grid-template-columns: 10% 90%;\n  }\n\n  &.grid.chat-message-wrapper__left {\n    grid-template-columns: 90% 10%;\n\n    .profile-message {\n      order: 3;\n    }\n  }\n"], ["\n  width: 100%;\n\n  &.grid {\n    display: grid;\n    grid-template-columns: 10% 90%;\n  }\n\n  &.grid.chat-message-wrapper__left {\n    grid-template-columns: 90% 10%;\n\n    .profile-message {\n      order: 3;\n    }\n  }\n"])));
export var ProfilePictureStyled = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  order: 1;\n  display: flex;\n  align-items: flex-end;\n"], ["\n  order: 1;\n  display: flex;\n  align-items: flex-end;\n"])));
export var ChatMessageStyled = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  order: 2;\n  position: relative;\n  padding: ", "px;\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),\n    0 1px 1px 0 rgba(0, 0, 0, 0.14);\n\n  &.chat-message-wrapper__left {\n    background-color: ", ";\n    border-radius: 10px 10px 10px 0;\n  }\n\n  &.chat-message-wrapper__right {\n    background-color: ", ";\n    border-radius: 10px 10px 0 10px;\n  }\n\n  .deleted-by {\n    margin-top: 1rem;\n    border-top: solid 1px #c7c7c7;\n    padding-top: 12px;\n  }\n\n  .read-by {\n    display: flex;\n    align-items: center;\n    margin-top: 1rem;\n\n    svg {\n      font-size: 1rem;\n      margin-right: 0.25rem;\n    }\n  }\n"], ["\n  order: 2;\n  position: relative;\n  padding: ", "px;\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),\n    0 1px 1px 0 rgba(0, 0, 0, 0.14);\n\n  &.chat-message-wrapper__left {\n    background-color: ", ";\n    border-radius: 10px 10px 10px 0;\n  }\n\n  &.chat-message-wrapper__right {\n    background-color: ", ";\n    border-radius: 10px 10px 0 10px;\n  }\n\n  .deleted-by {\n    margin-top: 1rem;\n    border-top: solid 1px #c7c7c7;\n    padding-top: 12px;\n  }\n\n  .read-by {\n    display: flex;\n    align-items: center;\n    margin-top: 1rem;\n\n    svg {\n      font-size: 1rem;\n      margin-right: 0.25rem;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.grayLight;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primaryLight;
});
export var Header = styled.div(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: #4d525b;\n\n  .timestamp {\n    margin-left: auto;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  color: #4d525b;\n\n  .timestamp {\n    margin-left: auto;\n  }\n"])));
export var Content = styled.div(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  font-size: 1.2rem;\n  line-height: 1.6rem;\n  word-break: break-word;\n  overflow-wrap: break-word;\n  white-space: pre-line;\n  margin-top: 1rem;\n"], ["\n  font-size: 1.2rem;\n  line-height: 1.6rem;\n  word-break: break-word;\n  overflow-wrap: break-word;\n  white-space: pre-line;\n  margin-top: 1rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
