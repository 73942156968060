import { CircularProgress, TableBody, TableCell, TableRow, } from '@material-ui/core';
import { Button, Empty, Input, Label, Select } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import React, { useState } from 'react';
import { usePatientFormQuery, useResubmitPatientFormMutation } from './hooks';
import { Actions, EmptyContainer, FieldWrapper, LoaderWrapper, StyledForm, StyledTable, StyledTableContainer, StyledTableHead, StyledTableRow, } from './index.styled';
var SearchForPatientForms = function (_a) {
    var onSubmit = _a.onSubmit, setKey = _a.setKey, setValue = _a.setValue, searchKey = _a.searchKey, value = _a.value;
    var handleSubmit = function (e) {
        e.preventDefault();
        onSubmit();
    };
    var selectOptions = [
        {
            value: 'email',
            label: 'Email',
        },
        {
            value: 'patientId',
            label: 'Patient Id',
        },
    ];
    return (React.createElement(StyledForm, { noValidate: true, onSubmit: handleSubmit },
        React.createElement(FieldWrapper, null,
            React.createElement(Select, { id: "key", label: "Search Field", fullWidth: true, value: searchKey, onChange: function (e) { return setKey(e.target.value); }, options: selectOptions })),
        React.createElement(FieldWrapper, null,
            React.createElement(Label, { htmlFor: "value" }, "Search Value"),
            React.createElement(Input, { fullWidth: true, id: "value", name: "value", placeholder: "Search Value", value: value, onChange: function (e) { return setValue(e.target.value); } })),
        React.createElement(Actions, null,
            React.createElement(Button, { color: "primary", type: "submit", variant: "contained" }, "Submit"))));
};
var ɵ0 = SearchForPatientForms;
var FormsList = function (_a) {
    var forms = _a.forms, onResubmit = _a.onResubmit;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTable, null,
            React.createElement(StyledTableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Name"),
                    React.createElement(TableCell, null, "Assigned"),
                    React.createElement(TableCell, null, "Status"),
                    React.createElement(TableCell, null, "Completed Sections"),
                    React.createElement(TableCell, null, "Last Modified"),
                    React.createElement(TableCell, null))),
            React.createElement(TableBody, null, forms.map(function (form) { return (React.createElement(FormRow, { key: form.id, form: form, onResubmit: onResubmit })); })))));
};
var ɵ1 = FormsList;
var FormRow = function (_a) {
    var form = _a.form, onResubmit = _a.onResubmit;
    var localeDateTime = useLocaleDateTime();
    return (React.createElement(StyledTableRow, { key: form.id, tabIndex: 0 },
        React.createElement(TableCell, null, form.name),
        React.createElement(TableCell, null, localeDateTime.format(new Date(form.assignedon), 'PP, p')),
        React.createElement(TableCell, null, form.status),
        React.createElement(TableCell, null, form.progress),
        React.createElement(TableCell, null, localeDateTime.format(new Date(form.lastmodified), 'PP, p')),
        React.createElement(TableCell, null, form.issubmitted && (React.createElement(Button, { variant: "contained", onClick: function () { return onResubmit(form.id); } }, "Resubmit Form")))));
};
var ɵ2 = FormRow;
var ResubmitPatientFormLookup = function () {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var _b = useState('email'), key = _b[0], setKey = _b[1];
    var _c = usePatientFormQuery({
        key: key,
        value: value,
    }), data = _c.data, isError = _c.isError, isLoading = _c.isLoading, isFetched = _c.isFetched, refetch = _c.refetch;
    var _d = useResubmitPatientFormMutation(), resubmitForm = _d.mutate, isErrorResubmitting = _d.isError, isLoadingResubmitting = _d.isLoading, isSuccessResubmitting = _d.isSuccess;
    useErrorToast(isError, {
        title: 'Error',
        message: 'An error occurred while searching for patient forms',
    });
    useErrorToast(isErrorResubmitting, {
        title: 'Error',
        message: 'An error occurred while resubmitting the patient form',
    });
    useSuccessToast(isSuccessResubmitting, {
        title: 'Success',
        message: 'The form has been resubmitted',
    });
    var onSearchSubmit = function () {
        refetch();
    };
    var onResubmit = function (formId) {
        setValue('');
        resubmitForm(formId);
    };
    var hasForms = data && data.data && data.data.length > 0;
    var forms = data && data.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(SearchForPatientForms, { onSubmit: onSearchSubmit, setValue: setValue, setKey: setKey, searchKey: key, value: value }),
        isLoading && (React.createElement(EmptyContainer, null,
            React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                React.createElement(CircularProgress, null)))),
        !isLoading && !hasForms && isFetched && (React.createElement(EmptyContainer, null,
            React.createElement(Empty, { "data-testid": "empty_state", message: "No Patient forms found" }))),
        !isLoading && hasForms && forms && (React.createElement(StyledTableContainer, null,
            React.createElement(FormsList, { forms: forms, onResubmit: onResubmit })))));
};
var ɵ3 = ResubmitPatientFormLookup;
export default ResubmitPatientFormLookup;
export { ɵ0, ɵ1, ɵ2, ɵ3 };
