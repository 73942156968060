import { CircularProgress } from '@material-ui/core';
import { Empty } from '@react/components';
import ExportRecords from '@react/components/users/exportRecords';
import Filters from '@react/components/users/filters';
import InviteUser from '@react/components/users/inviteUser';
import InviteUserModal from '@react/components/users/inviteUser/modal';
import UsersList from '@react/components/users/usersList';
import {
  CognitoStatus,
  IClinicUserFilters,
  StaffApiValue,
} from '@react/lib/api/types';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { flatMap } from 'lodash';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useAdminClinicUsersListQuery } from '../../hooks/users';
import { EmptyActionListIcon } from '../side/icons/EmptyActionListIcon';
import {
  EmptyContainer,
  LoaderWrapper,
  StyledActionsContainer,
  StyledSearchBar,
  StyledTableContainer,
} from './index.styled';

const Users: FunctionComponent = () => {
  const { t } = useTranslations();

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [userToInvite, setUserToInvite] = useState<StaffApiValue | null>(null);
  const [filters, setFilters] = useState<IClinicUserFilters>({});
  const [searchInputValue, setSearchInputValue] = useState('');
  const searchTerm = useDebouncedState(searchInputValue, 500);

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
  } = useAdminClinicUsersListQuery({ searchTerm, filters });

  const onScrollToEnd = () => {
    const shouldFetchNextPage =
      hasNextPage && !isLoading && !isFetchingNextPage;
    if (shouldFetchNextPage) {
      fetchNextPage();
    }
  };

  useErrorToast(isError, {
    title: t.Admin.Users.UsersLoadingErrorToastHeader,
    message: t.Admin.Users.UsersLoadingErrorToastMessage,
  });

  const getEmptyUsersMessage = () => {
    switch (true) {
      case filters.status && filters.status.length > 1: {
        return t.Admin.Users.UsersListEmptyUsers;
      }
      case filters.status && filters.status.includes(CognitoStatus.Active): {
        return t.Admin.Users.UsersListEmptyActiveUsers;
      }
      case filters.status &&
        filters.status.includes(CognitoStatus.Deactivated): {
        return t.Admin.Users.UsersListEmptyInactiveUsers;
      }
      default: {
        return t.Admin.Users.UsersListEmptyMessage;
      }
    }
  };

  const loading = isLoading || isFetchingNextPage;
  const showResults = !loading && !isError;
  const renderRecords = showResults && data && data.pages[0].data.length > 0;
  const users = useMemo(
    () => data && data.pages && flatMap(data.pages, (page) => page.data),
    [data],
  );

  const onCloseModal = () => {
    setInviteModalOpen(false);
  };

  const handleClickInviteUser = (user: StaffApiValue) => {
    setUserToInvite(user);
    setInviteModalOpen(true);
  };

  return (
    <>
      <InviteUserModal open={inviteModalOpen} onClose={onCloseModal}>
        {userToInvite && (
          <InviteUser onClose={onCloseModal} user={userToInvite} />
        )}
      </InviteUserModal>

      <StyledSearchBar
        value={searchInputValue}
        onChange={setSearchInputValue}
        placeholder={t.Admin.Users.SearchBarPlaceholder}
      />

      <StyledActionsContainer>
        <ExportRecords />
        <Filters filters={filters} setFilters={setFilters} />
      </StyledActionsContainer>

      {!users && loading && (
        <EmptyContainer>
          <LoaderWrapper data-testid="Loader">
            <CircularProgress />
          </LoaderWrapper>
        </EmptyContainer>
      )}

      {!renderRecords && !loading && (
        <EmptyContainer>
          <Empty
            data-testid="empty_state"
            icon={<EmptyActionListIcon />}
            message={getEmptyUsersMessage()}
          />
        </EmptyContainer>
      )}

      {users && users.length > 0 && (
        <StyledTableContainer>
          <UsersList
            onClickInviteUser={handleClickInviteUser}
            users={users}
            loading={loading}
            onScrollToEnd={onScrollToEnd}
          />
        </StyledTableContainer>
      )}
    </>
  );
};

export default Users;
