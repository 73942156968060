var NewMessageDetail = /** @class */ (function () {
    function NewMessageDetail(_a) {
        var PatientId = _a.PatientId, MessageTypeId = _a.MessageTypeId, Content = _a.Content, Subject = _a.Subject;
        this.PatientId = PatientId;
        this.MessageTypeId = MessageTypeId;
        this.Content = Content;
        this.Subject = Subject;
    }
    return NewMessageDetail;
}());
export { NewMessageDetail };
var CreateNewThreadRequest = /** @class */ (function () {
    function CreateNewThreadRequest(_a) {
        var PublicToken = _a.PublicToken, NewMessage = _a.NewMessage;
        this.Action = 'CreateNewClinicMessage';
        this.PublicToken = PublicToken;
        this.NewMessage = NewMessage;
    }
    return CreateNewThreadRequest;
}());
export { CreateNewThreadRequest };
