import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GetContentEntries } from './../actions/content.actions';
import { AddToContentPackError, AddToContentPackSuccess, AddToMultipleContentPacksSuccess, ContentPackActionTypes, CreateContentPackError, CreateContentPackSuccess, DeleteContentPackError, DeleteContentPackSuccess, EditContentPackError, EditContentPackSuccess, GetContentPackDetailError, GetContentPackDetailSuccess, GetContentPacksError, GetContentPacksSuccess, RemoveFromContentPackError, RemoveFromContentPackSuccess, } from './../actions/packs.actions';
import { PacksService } from './../services/packs.service';
var PacksEffects = /** @class */ (function () {
    function PacksEffects(_actions$, _packsService) {
        var _this = this;
        this._actions$ = _actions$;
        this._packsService = _packsService;
        this.getContentPacks$ = this._actions$.pipe(ofType(ContentPackActionTypes.GetContentPacks), switchMap(function () {
            return _this._packsService.getContentPacks().pipe(map(function (res) { return new GetContentPacksSuccess(res); }), catchError(function (err) {
                return of(new GetContentPacksError(err));
            }));
        }));
        this.getContentPackDetail$ = this._actions$.pipe(ofType(ContentPackActionTypes.GetContentPackDetail), switchMap(function (action) {
            return _this._packsService.getContentPackDetail(action.payload).pipe(map(function (res) {
                return new GetContentPackDetailSuccess(res);
            }), catchError(function (err) {
                return of(new GetContentPackDetailError(err));
            }));
        }));
        this.createContentPack$ = this._actions$.pipe(ofType(ContentPackActionTypes.CreateContentPack), switchMap(function (action) {
            return _this._packsService.createContentPack(action.payload).pipe(map(function (res) { return new CreateContentPackSuccess(res); }), catchError(function (err) {
                return of(new CreateContentPackError(err));
            }));
        }));
        this.editContentPack$ = this._actions$.pipe(ofType(ContentPackActionTypes.EditContentPack), switchMap(function (action) {
            return _this._packsService.editContentPack(action.payload).pipe(map(function (res) { return new EditContentPackSuccess(res); }), catchError(function (err) {
                return of(new EditContentPackError(err));
            }));
        }));
        this.deleteContentPack$ = this._actions$.pipe(ofType(ContentPackActionTypes.DeleteContentPack), switchMap(function (action) {
            return _this._packsService.deleteContentPack(action.payload).pipe(map(function (res) { return new DeleteContentPackSuccess(res); }), catchError(function (err) {
                return of(new DeleteContentPackError(err));
            }));
        }));
        this.addToContentPack$ = this._actions$.pipe(ofType(ContentPackActionTypes.AddToContentPack), switchMap(function (action) {
            return _this._packsService.addToContentPack(action.payload).pipe(mergeMap(function (res) { return [
                new GetContentEntries(),
                new AddToContentPackSuccess(res),
            ]; }), catchError(function (err) {
                return of(new AddToContentPackError(err));
            }));
        }));
        this.addToContentMultiplePacks$ = this._actions$.pipe(ofType(ContentPackActionTypes.AddToMultipleContentPacks), switchMap(function (action) {
            return _this._packsService.addToMultipleContentPacks(action.payload).pipe(mergeMap(function (res) { return [
                new GetContentEntries(),
                new AddToMultipleContentPacksSuccess(res),
            ]; }), catchError(function (err) {
                return of(new AddToContentPackError(err));
            }));
        }));
        this.removeFromContentPack$ = this._actions$.pipe(ofType(ContentPackActionTypes.RemoveFromContentPack), switchMap(function (action) {
            return _this._packsService.removeFromContentPack(action.payload).pipe(map(function (res) {
                return new RemoveFromContentPackSuccess(res);
            }), catchError(function (err) {
                return of(new RemoveFromContentPackError(err));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "getContentPacks$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "getContentPackDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "createContentPack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "editContentPack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "deleteContentPack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "addToContentPack$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "addToContentMultiplePacks$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], PacksEffects.prototype, "removeFromContentPack$", void 0);
    return PacksEffects;
}());
export { PacksEffects };
