import { ConversationCreateResult } from '@react/lib/api/types';
import { useDispatch } from '@react/lib/store';
import { useHistory } from 'react-router';
import { ShowNewConvoToastReact } from 'src/app/messaging/actions/new-message.actions';
import { constructMessageDeepLink } from '../lib';
import { FilterState } from '../types';

interface NewConversationToastOptions {
  title: string;
  message: string;
  conversation: ConversationCreateResult;
  folderId: number;
  refreshPageOnLinkClicked?: boolean;
}

export function useNewConversationToast() {
  const history = useHistory();
  const dispatch = useDispatch();

  return ({
    title,
    message,
    conversation,
    folderId,
    refreshPageOnLinkClicked = false,
  }: NewConversationToastOptions) => {
    const filters: FilterState = {
      folderIds: [folderId],
      isResolved: false,
      isStarred: false,
    };

    dispatch(
      new ShowNewConvoToastReact({
        title,
        message,
        onActionClicked: () => {
          const messageLink = constructMessageDeepLink(conversation, filters);

          if (refreshPageOnLinkClicked) {
            window.location.href = messageLink;
          } else {
            history.push(messageLink);
          }
        },
      }),
    );
  };
}
