import { ErrorHandler, Injectable } from '@angular/core';
import {
  CompletePassword,
  CompletePasswordFailure,
  CompletePasswordSuccess,
  GenerateResetCode,
  GenerateResetCodeFailure,
  GenerateResetCodeSuccess,
  Logout,
  LogoutFailure,
  LogoutSuccess,
  OTPLoginFailure,
  OTPRejected,
  OTPRequired,
  ResetPassword,
  ResetPasswordSuccess,
  VerifyOTP
} from './../actions/auth.actions';

import { MatDialog } from '@angular/material';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';

import { CognitoStatuses } from './../models/CognitoStatuses';

import {
  ClearStaffProfiles,
  LoadClinicInfoSuccess
} from '../../core/actions/clinic.actions';
import { ChangeLanguage } from '../../settings/actions/settings.actions';
import {
  AuthActionTypes,
  CoreLogin,
  CoreLoginFailure,
  CoreLoginSuccess,
  GetClinicId,
  GetClinicIdError,
  GetClinicIdSuccess,
  TempSuccess
} from '../actions/auth.actions';

import { AuthService } from '../services/auth.service';

import { supportedLanguages } from '../../settings/data/supported-languages';

import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { NavigationService } from 'src/app/core/services/navigation.service';
import {
  BusinessUnitClinicInfoResponse,
  ClinicInfoResponse
} from '../models/server-response/clinic-info-response';
import convertCognitoUser from '../utils/convert-cognito-user';
import { ClinicService } from 'src/app/core/services/clinic.service';

const GLOBAL_CLINIC_TYPE = 10;
export const wrapResponseWithGlobalFlag = (
  clinicInfo: ClinicInfoResponse,
  businessUnitClinicInfo: BusinessUnitClinicInfoResponse
) => ({
  ...businessUnitClinicInfo,
  ...clinicInfo,
  IsGlobalPortal: clinicInfo.ClinicTypeId === GLOBAL_CLINIC_TYPE ? true : false
});

@Injectable()
export class AuthEffects {
  private _toastText: any;
  private _isGlobalPortal: boolean;

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private clinicService: ClinicService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler,
    private _navigationService: NavigationService,
    private dialog: MatDialog
  ) {
    this._store
      .pipe(select(fromSettings.getSectionTranslations('Login')))
      .subscribe((t) => (this._toastText = t));
    this._store
      .pipe(select(fromRoot.getIsGlobalPortal))
      .subscribe((value) => (this._isGlobalPortal = value));
  }

  @Effect()
  getClinicInfo$ = this.actions$.pipe(
    ofType<GetClinicId>(AuthActionTypes.GetClinicId),
    switchMap((action) =>
      this.authService.getClinicId().pipe(
        switchMap((clinicInfo) =>
          this.clinicService.getClinicInfo(clinicInfo.Token).pipe(
            map((businessUnitClinicInfo) => ({
              clinicInfo,
              businessUnitClinicInfo
            }))
          )
        ),
        mergeMap(({ clinicInfo, businessUnitClinicInfo }) => {
          let language = supportedLanguages['en-gb'];
          if (supportedLanguages[clinicInfo.LanguageCode]) {
            language = supportedLanguages[clinicInfo.LanguageCode];
          }
          const info = wrapResponseWithGlobalFlag(
            clinicInfo,
            businessUnitClinicInfo
          );
          return [
            new GetClinicIdSuccess(info),
            new LoadClinicInfoSuccess(info),
            // set app language to clinic default
            new ChangeLanguage(language)
          ];
        }),
        catchError((error) => {
          return of(new GetClinicIdError(error));
        })
      )
    )
  );

  @Effect()
  coreLogin$ = this.actions$.pipe(
    ofType<CoreLogin>(AuthActionTypes.CoreLogin),
    mergeMap((action) => {
      return this.authService
        .cognitoLogin(action.payload)
        .then((cognitoUser) => {
          if (
            cognitoUser.challengeName === CognitoStatuses.NEW_PASSWORD_REQUIRED
          ) {
            return new TempSuccess({
              user: cognitoUser
            });
          } else if (
            cognitoUser.challengeName === CognitoStatuses.CUSTOM_CHALLENGE
          ) {
            return new OTPRequired({
              user: cognitoUser
            });
          } else if (cognitoUser.originalError) {
            console.error(cognitoUser.originalError.message);
            return new CoreLoginFailure(cognitoUser.originalError);
          } else {
            return new CoreLoginSuccess({ user: cognitoUser });
          }
        })
        .catch((error) => {
          console.log(error);
          return new CoreLoginFailure(error);
        });
    })
  );

  @Effect()
  verifyOTP$ = this.actions$.pipe(
    ofType<VerifyOTP>(AuthActionTypes.VerifyOTP),
    mergeMap((action) => {
      return this.authService
        .verifyOTP(action.payload)
        .then((cognitoUser) => {
          if (cognitoUser.challengeName === CognitoStatuses.CUSTOM_CHALLENGE) {
            return new OTPRejected({
              user: cognitoUser
            });
          } else if (cognitoUser.originalError) {
            return new OTPLoginFailure({
              toast: action.payload.toast
            });
          } else {
            return new CoreLoginSuccess({ user: cognitoUser });
          }
        })
        .catch((error) => {
          console.log(error);
          return new CoreLoginFailure(error);
        });
    })
  );

  @Effect()
  OTPLoginFailure$ = this.actions$.pipe(
    ofType<OTPLoginFailure>(AuthActionTypes.OTPLoginFailure),
    mergeMap((action) => {
      return of(new DisplayToastAction(action.payload.toast));
    })
  );

  @Effect()
  completePassword$ = this.actions$.pipe(
    ofType<CompletePassword>(AuthActionTypes.CompletePassword),
    mergeMap((action) => {
      return this.authService
        .cognitoCompletePassword(action.payload)
        .then((cognitoUser) => {
          const { user } = convertCognitoUser(action.payload.user);
          return new CompletePasswordSuccess({
            user: cognitoUser
          });
        })
        .catch((error) => {
          return new CompletePasswordFailure(error);
        });
    })
  );

  @Effect()
  clear$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    mergeMap(() => {
      return Promise.resolve(new ClearStaffProfiles());
    })
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    switchMap(({ payload: { isCognito } }) => {
      if (isCognito) {
        return this.authService
          .cognitoLogout()
          .then(() => {
            return new LogoutSuccess({
              isCognito
            });
          })
          .catch((err) => {
            console.error(err);
            return new LogoutFailure({
              isCognito
            });
          });
      } else {
        return Promise.resolve(
          new LogoutSuccess({
            isCognito
          })
        );
      }
    })
  );

  @Effect()
  getResetCode$ = this.actions$.pipe(
    ofType<GenerateResetCode>(AuthActionTypes.GenerateResetCode),
    switchMap(({ payload }) => {
      return this.authService
        .cognitoGenerateResetCode(payload)
        .then((res) => {
          return new GenerateResetCodeSuccess(res);
        })
        .catch((err) => {
          return new GenerateResetCodeFailure(err);
        });
    })
  );

  @Effect()
  submitNewPassword$ = this.actions$.pipe(
    ofType<ResetPassword>(AuthActionTypes.ResetPassword),
    switchMap(({ payload }) => {
      return this.authService
        .cognitoResetPassword(payload)
        .then((res) => {
          return new ResetPasswordSuccess(res);
        })
        .catch((err) => {
          return new ResetPasswordSuccess(err);
        });
    })
  );

  @Effect({ dispatch: false })
  tempSuccess = this.actions$.pipe(
    ofType(AuthActionTypes.TempSuccess),
    tap(() => this._navigationService.navigate(['/create']))
  );

  @Effect({ dispatch: false })
  loginSuccess = this.actions$.pipe(
    ofType(
      AuthActionTypes.CompletePasswordSuccess,
      AuthActionTypes.CoreLoginSuccess,
      AuthActionTypes.ResetPasswordSuccess
    ),
    tap(() => this._navigationService.navigateAfterLogin(this._isGlobalPortal))
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType(
      AuthActionTypes.LoginRedirect,
      AuthActionTypes.LogoutSuccess,
      AuthActionTypes.LogoutFailure,
      AuthActionTypes.OTPLoginFailure
    ),
    tap(() => {
      this.dialog.closeAll();
      this._navigationService.navigate(['/login']);
    })
  );

  @Effect({ dispatch: false })
  otpRequired$$ = this.actions$.pipe(
    ofType(AuthActionTypes.OTPRequired),
    tap(() => {
      this._navigationService.navigate(['/email-otp']);
    })
  );
}
