import * as tslib_1 from "tslib";
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { GLOBAL_CMS_ROUTE, NavigationService } from './navigation.service';
var GlobalPortalGuard = /** @class */ (function () {
    function GlobalPortalGuard(_navigationService, _store) {
        this._navigationService = _navigationService;
        this._store = _store;
    }
    GlobalPortalGuard.prototype.canActivate = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this._store
                    .pipe(select(fromRoot.getIsGlobalPortal))
                    .subscribe(function (value) { return (_this.isGlobalPortal = value); });
                this._store
                    .pipe(select(fromRoot.getHasSetGlobalPortal))
                    .subscribe(function (value) { return (_this.hasSetGlobalPortal = value); });
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var checkIfIsGlobalPortalOnceLoaded = function () {
                            if (_this.isGlobalPortal) {
                                if (route.url[0].path === 'dashboard') {
                                    _this._navigationService.navigate(["/" + GLOBAL_CMS_ROUTE]);
                                    reject();
                                    return;
                                }
                                _this._navigationService.navigate(['/unauthorised']);
                                reject();
                                return;
                            }
                            resolve(true);
                        };
                        /**
                         * There is almost certainly a better way of waiting for our clinic information to be loaded,
                         * when running a route guard feature, but without more experience in Angular, awaiting a promise,
                         * which loops on itself until the loading flag has been set was the only way I could solve this issue.
                         * Sadly this is a symptom of the portal architecture only ever expecting to be in one "mode".
                         * Without re-architecting how the portal works, we must always wait to see if the portal is a global portal or not,
                         * via the call we do in the auth service.
                         */
                        _this.interval = setInterval(function () {
                            if (_this.hasSetGlobalPortal) {
                                clearInterval(_this.interval);
                                checkIfIsGlobalPortalOnceLoaded();
                            }
                        }, 500);
                    })];
            });
        });
    };
    return GlobalPortalGuard;
}());
export { GlobalPortalGuard };
