import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatClinicUser } from '../lib';
export function useFetchMe() {
    var _this = this;
    var apiClient = useApiClient().portal;
    return useQuery([queryCacheKey.MYSELF], function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var data;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.fetchMe()];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatClinicUser(data)];
            }
        });
    }); });
}
export function useFetchMeWithClinics() {
    var _this = this;
    var apiClient = useApiClient().portal;
    return useQuery([queryCacheKey.MYSELF_WITH_CLINICS], function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var data;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.fetchMe(true)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatClinicUser(data)];
            }
        });
    }); });
}
