import { Table, TableBody } from '@material-ui/core';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import ExportAction from './ExportAction';
import { StyledTableContainer } from './index.styled';
import SIDEExportAction from './SIDEExportAction';
import { DataExportTypes } from './types';

const DataExports: React.VFC = () => {
  const { t } = useTranslations();
  return (
    <StyledTableContainer>
      <Table>
        <TableBody>
          <ExportAction
            title={t.SuperAdmin.DataExport.MessagesTitle}
            subtitle={t.SuperAdmin.DataExport.MessagesSubtitle}
            filenameSuffix={t.SuperAdmin.DataExport.MessagesFilenameSuffix}
            type={DataExportTypes.Messages}
          />
          <SIDEExportAction
            title={t.SuperAdmin.DataExport.SIDERecipeTitle}
            subtitle={t.SuperAdmin.DataExport.SIDERecipeSubtitle}
            filenameSuffix={t.SuperAdmin.DataExport.SIDERecipeFilenameSuffix}
          />
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default DataExports;
