import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { StyledCard, StyledNav, StyledNavLink, StyledNavList, } from './Nav.styled';
var Nav = function (_a) {
    var user = _a.user;
    var t = useTranslations().t;
    var features = useFeatures();
    var enabledUserAdmin = Boolean(features &&
        features.portalAdminOptions &&
        features.portalAdminOptions.users);
    var isClinicUserAdmin = user.groups.includes('ClinicUserAdmin');
    return (React.createElement(StyledCard, null,
        React.createElement(StyledNav, null,
            React.createElement(StyledNavList, null,
                React.createElement("li", null,
                    React.createElement(StyledNavLink, { activeClassName: "active", to: "/admin/appointments" }, t.Admin.AppointmentTypeNavItem),
                    React.createElement(StyledNavLink, { activeClassName: "active", to: "/admin/message-templates" }, t.Admin.MessageTemplatesNavItem),
                    React.createElement(StyledNavLink, { activeClassName: "active", to: "/admin/side/recipes" }, t.Admin.SIDE.NavItem),
                    enabledUserAdmin && isClinicUserAdmin && (
                    // @ts-ignore */
                    React.createElement(StyledNavLink, { activeClassName: "active", to: "/admin/users" }, t.Admin.Users.NavItem)))))));
};
var ɵ0 = Nav;
export default Nav;
export { ɵ0 };
