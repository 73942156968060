import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Button, Empty, Input, Label, Select } from '@react/components';
import { ResubmittablePatientLookupForm } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { usePatientFormQuery, useResubmitPatientFormMutation } from './hooks';
import {
  Actions,
  EmptyContainer,
  FieldWrapper,
  LoaderWrapper,
  StyledForm,
  StyledTable,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
} from './index.styled';

const SearchForPatientForms = ({
  onSubmit,
  setKey,
  setValue,
  searchKey,
  value,
}: {
  onSubmit: () => void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setKey: React.Dispatch<React.SetStateAction<string>>;
  searchKey: string;
  value: string;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const selectOptions = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'patientId',
      label: 'Patient Id',
    },
  ];

  return (
    <StyledForm noValidate onSubmit={handleSubmit}>
      <FieldWrapper>
        <Select
          id="key"
          label="Search Field"
          fullWidth
          value={searchKey}
          onChange={(e) => setKey(e.target.value as string)}
          options={selectOptions}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label htmlFor="value">Search Value</Label>
        <Input
          fullWidth
          id="value"
          name="value"
          placeholder="Search Value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </FieldWrapper>
      <Actions>
        <Button color="primary" type="submit" variant="contained">
          Submit
        </Button>
      </Actions>
    </StyledForm>
  );
};

const FormsList = ({
  forms,
  onResubmit,
}: {
  forms: ResubmittablePatientLookupForm[];
  onResubmit: (id: number) => void;
}) => {
  return (
    <>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Assigned</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Completed Sections</TableCell>
            <TableCell>Last Modified</TableCell>
            <TableCell />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {forms.map((form) => (
            <FormRow key={form.id} form={form} onResubmit={onResubmit} />
          ))}
        </TableBody>
      </StyledTable>
    </>
  );
};

const FormRow = ({
  form,
  onResubmit,
}: {
  form: ResubmittablePatientLookupForm;
  onResubmit: (id: number) => void;
}) => {
  const localeDateTime = useLocaleDateTime();
  return (
    <StyledTableRow key={form.id} tabIndex={0}>
      <TableCell>{form.name}</TableCell>
      <TableCell>
        {localeDateTime.format(new Date(form.assignedon), 'PP, p')}
      </TableCell>
      <TableCell>{form.status}</TableCell>
      <TableCell>{form.progress}</TableCell>
      <TableCell>
        {localeDateTime.format(new Date(form.lastmodified), 'PP, p')}
      </TableCell>
      <TableCell>
        {form.issubmitted && (
          <Button variant="contained" onClick={() => onResubmit(form.id)}>
            Resubmit Form
          </Button>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

const ResubmitPatientFormLookup: FunctionComponent = () => {
  const [value, setValue] = useState<string>('');
  const [key, setKey] = useState('email');

  const { data, isError, isLoading, isFetched, refetch } = usePatientFormQuery({
    key,
    value,
  });

  const {
    mutate: resubmitForm,
    isError: isErrorResubmitting,
    isLoading: isLoadingResubmitting,
    isSuccess: isSuccessResubmitting,
  } = useResubmitPatientFormMutation();

  useErrorToast(isError, {
    title: 'Error',
    message: 'An error occurred while searching for patient forms',
  });

  useErrorToast(isErrorResubmitting, {
    title: 'Error',
    message: 'An error occurred while resubmitting the patient form',
  });

  useSuccessToast(isSuccessResubmitting, {
    title: 'Success',
    message: 'The form has been resubmitted',
  });

  const onSearchSubmit = () => {
    refetch();
  };

  const onResubmit = (formId: number) => {
    setValue('');
    resubmitForm(formId);
  };

  const hasForms = data && data.data && data.data.length > 0;
  const forms = data && data.data;

  return (
    <>
      <SearchForPatientForms
        onSubmit={onSearchSubmit}
        setValue={setValue}
        setKey={setKey}
        searchKey={key}
        value={value}
      />

      {isLoading && (
        <EmptyContainer>
          <LoaderWrapper data-testid="Loader">
            <CircularProgress />
          </LoaderWrapper>
        </EmptyContainer>
      )}

      {!isLoading && !hasForms && isFetched && (
        <EmptyContainer>
          <Empty data-testid="empty_state" message="No Patient forms found" />
        </EmptyContainer>
      )}

      {!isLoading && hasForms && forms && (
        <StyledTableContainer>
          <FormsList forms={forms} onResubmit={onResubmit} />
        </StyledTableContainer>
      )}
    </>
  );
};

export default ResubmitPatientFormLookup;
