var _this = this;
import * as tslib_1 from "tslib";
import { AccordionSummary, Card, CircularProgress, IconButton, MenuItem, } from '@material-ui/core';
import { ArrowBack as IconArrowBack, ExpandMore as IconExpandMore, } from '@material-ui/icons';
import { Button } from '@react/components/Button';
import { HelperText } from '@react/components/HelperText';
import { Label } from '@react/components/Label';
import { SIDERecipeEventType, SIDEStatus } from '@react/lib/api/enum';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useSideRecipeMutation, useSIDERecipeQuery, useUniqueNameValidator, } from '../../../hooks';
import ActionList from './actions';
import { ActionRow, InfoRow, LoaderWrapper, RecipeTitle, SectionTitle, StyledAccordian, StyledAccordionDetails, StyledContainer, StyledContent, StyledInput, StyledSelect, TitleWrapper, } from './index.styled';
import RecipeTriggers from './triggers';
var NAME_MIN_LENGTH = 3;
var NAME_MAX_LENGTH = 50;
var Recipe = function () {
    var id = useParams().id;
    var t = useTranslations().t;
    var uniqueNameValidator = useUniqueNameValidator({
        delay: 300,
        errorMessage: t.Admin.SIDE.RecipeUniqueNameError,
        sideRecipeId: Number(id),
    });
    var _a = useForm({
        mode: 'onBlur',
    }), control = _a.control, register = _a.register, errors = _a.errors, isDirty = _a.formState.isDirty, handleSubmit = _a.handleSubmit, reset = _a.reset;
    var _b = useSIDERecipeQuery({
        id: Number(id),
    }), data = _b.data, isLoading = _b.isLoading, isError = _b.isError;
    var _c = useSideRecipeMutation(), saveRecipe = _c.mutate, isSavingError = _c.isError, isSuccess = _c.isSuccess, isSaving = _c.isLoading;
    useErrorToast(isError, {
        title: t.Admin.SIDE.RecipeLoadingErrorToastHeader,
        message: t.Admin.SIDE.RecipeLoadingErrorToastMessage,
    });
    useErrorToast(isSavingError, {
        title: t.Admin.SIDE.RecipesSavingErrorHeader,
        message: t.Admin.SIDE.RecipesSavingErrorMessage,
    });
    useSuccessToast(isSuccess, {
        title: t.Admin.SIDE.RecipesSavingSuccessHeader,
        message: t.Admin.SIDE.RecipesSavingSuccessMessage,
    });
    var onSubmit = function (formData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var recipeBody;
        return tslib_1.__generator(this, function (_a) {
            recipeBody = tslib_1.__assign({}, formData, { id: Number(id) });
            saveRecipe(recipeBody, {
                onSuccess: function () {
                    // @ts-ignore
                    reset({
                        name: formData.name,
                        status: formData.status,
                    });
                },
            });
            return [2 /*return*/];
        });
    }); };
    if (isLoading) {
        return (React.createElement(Card, null,
            React.createElement(LoaderWrapper, null,
                React.createElement(CircularProgress, null))));
    }
    if (isError || !data) {
        return React.createElement(Card, { "data-testid": "error" });
    }
    var recipe = data.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleWrapper, null,
            React.createElement(IconButton, { component: Link, to: "/admin/side/recipes", size: "small" },
                React.createElement(IconArrowBack, null)),
            React.createElement(RecipeTitle, { variant: "h5", role: "heading" },
                recipe.name,
                " (",
                recipe.id,
                ")")),
        React.createElement(StyledAccordian, null,
            React.createElement(AccordionSummary, { expandIcon: React.createElement(IconExpandMore, null), id: "recipe-settings" },
                React.createElement(SectionTitle, null, t.Admin.SIDE.SettingsHeader)),
            React.createElement(StyledAccordionDetails, null,
                React.createElement("form", { noValidate: true, onSubmit: handleSubmit(onSubmit) },
                    React.createElement(InfoRow, null,
                        React.createElement(StyledContent, { width: 400 },
                            React.createElement(Label, null, t.Admin.SIDE.RecipeNameLabel),
                            React.createElement(StyledInput, { "data-testid": "nameInput", fullWidth: true, defaultValue: recipe.name, id: "name", name: "name", error: !!errors.name, ref: register({
                                    required: {
                                        message: t.Admin.SIDE.RecipeNameRequiredErrorMessage,
                                        value: true,
                                    },
                                    minLength: {
                                        message: t.Admin.SIDE.RecipeNameErrorLength(NAME_MIN_LENGTH, NAME_MAX_LENGTH),
                                        value: NAME_MIN_LENGTH,
                                    },
                                    maxLength: {
                                        message: t.Admin.SIDE.RecipeNameErrorLength(NAME_MIN_LENGTH, NAME_MAX_LENGTH),
                                        value: NAME_MAX_LENGTH,
                                    },
                                    validate: uniqueNameValidator,
                                }) }),
                            React.createElement(HelperText, { "aria-live": "polite", error: !!errors.name, id: "nameHelper" }, errors.name != null ? errors.name.message : null)),
                        React.createElement(StyledContent, null,
                            React.createElement(Label, null, t.Admin.SIDE.RecipeStatusLabel),
                            React.createElement(Controller, { as: React.createElement(StyledSelect, { disableUnderline: true, value: recipe.status, label: "status", labelId: "status", ref: register() },
                                    React.createElement(MenuItem, { value: SIDEStatus.Active }, t.Admin.SIDE['ActiveStatus']),
                                    React.createElement(MenuItem, { value: SIDEStatus.Inactive }, t.Admin.SIDE['InactiveStatus'])), name: "status", control: control, defaultValue: recipe.status }))),
                    recipe.eventname !== SIDERecipeEventType.Registration && (React.createElement(RecipeTriggers, { recipe: recipe })),
                    React.createElement(ActionRow, null,
                        React.createElement(StyledContent, { fontStyle: "Italic", width: 600 }, t.Admin.SIDE.RecipeTriggerInfo),
                        React.createElement(StyledContent, null,
                            React.createElement(Button, { "data-testid": "btn_save", color: "primary", type: "submit", variant: "contained", disabled: !isDirty || isSaving },
                                React.createElement(React.Fragment, null, t.Admin.SIDE.RecipeSaveButtonText))))))),
        React.createElement(StyledContainer, null,
            React.createElement(SectionTitle, null, t.Admin.SIDE.ActionHeader),
            React.createElement(ActionList, { recipe: recipe }))));
};
var ɵ0 = Recipe;
export default Recipe;
export { ɵ0 };
