import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';

import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import { UpdateVideoCall } from '../models/UpdateVideoCall';
import { ApiVideoCall, APIVideoCallResource } from '../responses';

@Injectable()
export class VideoCallsService {
  public videoCallsEnabled$: Observable<boolean>;

  constructor(
    private _httpService: HttpService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler,
  ) {
    this.videoCallsEnabled$ = this._store.pipe(
      select(fromAuth.getVideoCallsEnabled),
    );
  }

  public getVideoCalls(
    patientId?: number,
    isPast?: boolean,
    pageNumber: string = '1',
    pageSize: string = '50',
    q?: string,
    resources?: string,
  ): Observable<ApiVideoCall[]> {
    return this.videoCallsEnabled$.pipe(
      switchMap((videoCallsEnabled) => {
        if (videoCallsEnabled) {
          let path = 'videoconference';
          let params: Record<string, string> = {
            pageNumber,
            pageSize,
          };
          if (q) {
            params = {
              ...params,
              q,
            };
          }

          if (resources) {
            params = {
              ...params,
              resources,
            };
          }

          if (isPast) {
            params = {
              ...params,
              interval: 'past',
            };
          }

          if (patientId) {
            path = `patients/${patientId}/videoconference`;
          }

          return this._httpService
            .get(EndpointType.Portal, path, params)
            .pipe(map((res: any) => res.data));
        } else {
          return of([]);
        }
      }),
    );
  }

  public getVideoCallResources(): Observable<APIVideoCallResource[]> {
    return this.videoCallsEnabled$.pipe(
      switchMap((videoCallsEnabled) => {
        if (videoCallsEnabled) {
          const path = 'videoconference/resources';

          return this._httpService
            .get(EndpointType.Portal, path)
            .pipe(map((res: any) => res.data));
        } else {
          return of([]);
        }
      }),
    );
  }

  public updateVideoCall(videoCall: UpdateVideoCall): Observable<any> {
    let result: Observable<any>;
    if (videoCall.id) {
      const patchPayload = {
        ...videoCall,
        id: undefined,
        patientid: undefined,
        patient: undefined,
      };
      result = this._httpService.patch(
        EndpointType.Portal,
        `videoconference/${videoCall.id}`,
        patchPayload,
      );
    } else {
      result = this._httpService.post(EndpointType.Portal, 'videoconference', {
        ...videoCall,
        patient: undefined,
        patientid: videoCall.patient.Id,
      });
    }
    return result;
  }

  public deleteVideoCall(id: number): Observable<any> {
    return this._httpService.delete(
      EndpointType.Portal,
      `videoconference/${id}`,
    );
  }

  public joinVideoCall(id: number): Observable<any> {
    return this._httpService.get(
      EndpointType.Portal,
      `videoconference/${id}/join`,
    );
  }
}
