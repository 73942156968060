export enum SIDERecipeEventType {
  'Appointment' = 'Appointment',
  'Medication' = 'Medication',
  'Registration' = 'Registration',
}

export enum SIDEStatus {
  'Active' = 'Active',
  'Inactive' = 'Inactive',
}

export enum SIDERecipeTrigger {
  'Appointment' = 'Appointment',
  'Medication' = 'Medication',
  'Registration' = 'Registration',
}

export enum SIDEActionType {
  'Message' = 'Message',
  'Content' = 'Content',
  'Content Pack' = 'Content Pack',
}

export enum Period {
  'Hour' = 'Hour',
  'Minute' = 'Minute',
  'Day' = 'Day',
}

export enum SIDEAppointmentStatus {
  'Not set' = 'Not set',
  'Attended' = 'Attended',
  'No-Show' = 'No-Show',
  'Cancelled' = 'Cancelled',
  'Before Appointment' = 'Before Appointment',
  'After Appointment' = 'After Appointment',
  'Confirmed' = 'Confirmed',
  'Not Cancelled' = 'Not Cancelled',
  'Booked' = 'Booked',
}

export enum SIDEMedicationStatus {
  'Booked' = 'Booked',
}
