import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CloseThread } from 'src/app/messaging/actions/message-ui.actions';
import * as fromRoot from '../../reducers';

export const GLOBAL_CMS_ROUTE = 'content/global';

@Injectable()
export class NavigationService {
  constructor(private _router: Router, private _store: Store<fromRoot.State>) {}

  navigate(commands: any[], extras?: NavigationExtras) {
    if (this._router.url.toLowerCase().startsWith('/messages')) {
      this._store.dispatch(new CloseThread());
    }

    this._router.navigate(commands, extras);
  }

  navigateAfterLogin(isGlobalPortal: boolean) {
    if (isGlobalPortal) {
      this.navigate([`/${GLOBAL_CMS_ROUTE}`]);
    } else {
      this.navigate(['/']);
    }
  }

  navigateByUrl(url: string) {
    this._router.navigateByUrl(url);
  }
}
