import ZoomVideo from '@zoom/videosdk';
import React, { useMemo } from 'react';
export var ZoomContext = React.createContext(undefined);
export var ZoomProvider = function (_a) {
    var children = _a.children;
    var zoomClient = useMemo(function () {
        return ZoomVideo.createClient();
    }, []);
    return (React.createElement(ZoomContext.Provider, { value: zoomClient }, children));
};
