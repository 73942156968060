import { EventEmitter, } from '@angular/core';
import { ClinicSummaryResponse } from './../../models/responses/clinic-summary.response';
var DashboardFoldersComponent = /** @class */ (function () {
    function DashboardFoldersComponent() {
        this.goToLabel = new EventEmitter();
        this.goToMessages = new EventEmitter();
    }
    return DashboardFoldersComponent;
}());
export { DashboardFoldersComponent };
