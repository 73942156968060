import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';

export interface ConfirmActionData {
  isContent?: boolean;
  information?: string;
  message: string;
  text: {
    Cancel: string;
    Confirm: string;
  };
}

export type ConfirmActionResult = boolean;
@Component({
  selector: 'portal-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss'],
})
export class ConfirmActionComponent {
  public contentSaving$: Observable<boolean>;
  public information: string;
  public isContent: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmActionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmActionData,
  ) {
    if (data.isContent) {
      this.isContent = data.isContent;
    }
    if (data.information) {
      this.information = data.information;
    }
  }

  onYesClick() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
