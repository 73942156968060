import { useCallback, useRef } from 'react';
// leading debounce - callback is called immediately and then a delay is applied afterwards to prevent spam
export var useCallbackDebounced = function (fn, deps, delay) {
    if (delay === void 0) { delay = 300; }
    var timerRef = useRef();
    var resRef = useRef();
    var callback = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!timerRef.current) {
            // @ts-expect-error This syntax requires an imported helper named '__spreadArray' which does not exist in 'tslib'. Consider upgrading your version of 'tslib'.ts(2343)
            resRef.current = fn.apply(void 0, args);
        }
        else {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(function () {
            timerRef.current = undefined;
        }, delay);
        return resRef.current;
    };
    return useCallback(callback, deps);
};
