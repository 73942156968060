import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { POLLING_INTERVAL_MS, queryCacheKey, } from '@react/pages/conversations/constants';
import { useQuery } from 'react-query';
import { createExportDownloadHandler } from './utils';
export function useSIDERecipesQuery() {
    var apiClient = useApiClient().portal;
    return useQuery(queryCacheKey.SIDE_RECIPES_LIST, function () {
        return apiClient.fetchSIDERecipes({
            ignorePaging: true,
        });
    }, {
        refetchInterval: POLLING_INTERVAL_MS,
    });
}
export function useSIDEExportDataByRecipe(_a) {
    var _this = this;
    var sideRecipeId = _a.sideRecipeId, from = _a.from, to = _a.to, onDownloadSuccess = _a.onDownloadSuccess, onDownloadFailure = _a.onDownloadFailure;
    var apiClient = useApiClient().portal;
    var request = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiClient.exportSIDEData(sideRecipeId, from, to)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    return createExportDownloadHandler(request, onDownloadSuccess, onDownloadFailure);
}
