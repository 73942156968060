import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  DeletePatientAssignedContent,
  OpenItemList,
  OpenPackList,
  OpenPatientViewFolder,
  SetAssignedContentFilter,
} from './../../actions/content-assignment.actions';
import { AddExclusion } from './../../actions/pack-assignment.actions';
import {
  ContentTabs,
  viewAsPatientOpenFolder,
} from './../../reducers/content-assignment.reducer';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromContentAssignment from '../../reducers';

import {
  ChangeAssignedContentView,
  GetAllPatientAssignedContent,
  GetPatientAssignedContent,
  GetPatientOnlyAssignments,
  GetPatientViewOfContent,
} from '../../actions/content-assignment.actions';

import { PackAssignmentInterface } from 'src/app/content/models/content-packs';
import {
  OpenAssignedPack,
  UnassignPack,
} from '../../actions/pack-assignment.actions';
import { DetailedPatient } from '../../models/DetailedPatient';
import { ClinicPatientResponse } from '../../models/responses/clinic-patient.response';
import {
  AssignedContentPack,
  IndividuallyAssignedPatientContent,
} from '../../models/UserContent';
import { ContentEntriesByCategory } from './../../models/UserContent';

@Component({
  selector: 'portal-assigned-content-container',
  templateUrl: './assigned-content.container.html',
  styleUrls: ['./assigned-content.container.scss'],
})
export class AssignedContentContainer implements OnDestroy {
  public loadingAllPatientContent$: Observable<boolean>;
  public loadingPatientContentView$: Observable<boolean>;

  public clinicViewingAssignedPacks$: Observable<boolean>;
  public clinicViewingAssignedItems$: Observable<boolean>;

  public activeTab$: Observable<ContentTabs>;

  public activeAssignedPackId$: Observable<number>;
  public assignedPacks$: Observable<AssignedContentPack[]>;
  public assignedContent$: Observable<IndividuallyAssignedPatientContent[]>;

  public filterActive$: Observable<boolean>;

  public contentSwingText$: Observable<any>;

  public patientViewOpenFolder$: Observable<number>;

  public patientViewContent$: Observable<ContentEntriesByCategory[]>;
  public viewAsPatient$: Observable<boolean>;

  public patientId$: Observable<number>;
  public currentPatient$: Observable<DetailedPatient>;
  public patientName$: Observable<string>;

  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _route: ActivatedRoute,
  ) {
    this.patientId$ = this._route.params.pipe(
      map((params: Params) => +params.patientId),
    );
    this.contentSwingText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentSwing')),
    );

    // check router for changes to target patient
    this._subs.add(
      this.patientId$.subscribe((id) => {
        this._store.dispatch(new GetPatientAssignedContent(id));
        this._store.dispatch(new GetPatientViewOfContent(id));
        this._store.dispatch(new GetAllPatientAssignedContent(id));
        this._store.dispatch(
          new GetPatientOnlyAssignments({
            patientId: id,
          }),
        );
      }),
    );

    // fetch current patient
    this.currentPatient$ = this.patientId$.pipe(
      switchMap((id) =>
        this._store.pipe(select(fromContentAssignment.getPatientById(id))),
      ),
      filter(
        (clinicPatientResponse: ClinicPatientResponse) =>
          !!clinicPatientResponse && !!clinicPatientResponse.Patient,
      ),
      map(
        (clinicPatientResponse: ClinicPatientResponse) =>
          clinicPatientResponse.Patient,
      ),
    );
    this.patientName$ = this.currentPatient$.pipe(
      map(
        (patient: DetailedPatient) =>
          `${patient.FirstName} ${patient.LastName}`,
      ),
    );

    this.patientViewOpenFolder$ = this._store.pipe(
      select(fromContentAssignment.getViewAsPatientOpenFolder),
    );

    this.loadingAllPatientContent$ = this._store.pipe(
      select(fromContentAssignment.loadingPatientAssigned),
    );
    this.loadingPatientContentView$ = this._store.pipe(
      select(fromContentAssignment.loadingPatientViewOfContent),
    );
    this.assignedPacks$ = this._store.pipe(
      select(fromContentAssignment.getPatientAssignedPacks),
    );
    this.assignedContent$ = this._store.pipe(
      select(fromContentAssignment.getPatientAssignedContent),
    );
    this.patientViewContent$ = this._store
      .pipe(select(fromContentAssignment.patientViewOfContent))
      .pipe(
        map((p) => {
          return p;
        }),
      );
    this.filterActive$ = this._store.pipe(
      select(fromContentAssignment.isAssignedContentFilterActive),
    );
    this.viewAsPatient$ = this._store.pipe(
      select(fromContentAssignment.viewAsPatient),
    );

    this.clinicViewingAssignedPacks$ = this._store.pipe(
      select(fromContentAssignment.isClinicViewingAssignedPacks),
    );
    this.clinicViewingAssignedItems$ = this._store.pipe(
      select(fromContentAssignment.isClinicViewingAssignedItems),
    );
    this.activeAssignedPackId$ = this._store.pipe(
      select(fromContentAssignment.getActiveAssignedPackId),
    );
    this.activeTab$ = this._store.pipe(
      select(fromContentAssignment.getActiveContentTab),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public handleSwitchView() {
    this._store.dispatch(new ChangeAssignedContentView());
  }

  public handleViewInfo() {}

  public handleClinicViewSwitch(packViewOpen: boolean) {
    this._store.dispatch(
      packViewOpen ? new OpenItemList() : new OpenPackList(),
    );
  }

  public openAssignedPack(packId: number) {
    this._store.dispatch(new OpenAssignedPack(packId));
  }
  public removePack(unassignPack: PackAssignmentInterface) {
    unassignPack.pack.isTransferring = true;
    this._store.dispatch(new UnassignPack(unassignPack));
  }
  public excludeItem(addExclusion: PackAssignmentInterface) {
    addExclusion.packItem.isTransferring = true;
    this._store.dispatch(new AddExclusion(addExclusion));
  }

  public removeIndividualItem(content: IndividuallyAssignedPatientContent) {
    content.isTransferring = true;
    this._store.dispatch(
      new DeletePatientAssignedContent({
        assignmentid: content.assignmentId,
        contententryheaderid: content.contentEntryHeaderId,
      }),
    );
  }

  public openPatientFolder(folderId: number) {
    this._store.dispatch(new OpenPatientViewFolder(folderId));
  }

  public setAssignedContentFilter(filterValue: string) {
    this._store.dispatch(new SetAssignedContentFilter(filterValue));
  }
}
