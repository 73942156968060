import { Injectable } from '@angular/core';

export interface GetYoutubeIdServiceOptions {
  fuzzy: boolean;
}

@Injectable()
export class GetYoutubeIdService {
  // private _options: {
  //   fuzzy: true
  // };

  // constructor(options: GetYoutubeIdServiceOptions) {
  //   if (options &&
  //     options.fuzzy &&
  //     typeof options.fuzzy === 'boolean'
  //   ) {
  //     this._options.fuzzy = options.fuzzy;
  //   }
  // }

  public getYoutubeId(
    url: string,
    options: GetYoutubeIdServiceOptions = { fuzzy: true },
  ): string {
    if (/youtu\.?be/.test(url)) {
      // Look first for known patterns
      const patterns = [
        /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
        /\?v=([^#\&\?]{11})/, // ?v=<id>
        /\&v=([^#\&\?]{11})/, // &v=<id>
        /embed\/([^#\&\?]{11})/, // embed/<id>
        /\/v\/([^#\&\?]{11})/, // /v/<id>
      ];

      // If any pattern matches, return the ID
      for (const pattern of patterns) {
        if (pattern.test(url)) {
          return pattern.exec(url)[1];
        }
      }

      if (options.fuzzy) {
        // If that fails, break it apart by certain characters and look
        // for the 11 character key
        const tokens = url.split(/[\/\&\?=#\.\s]/g);
        for (const token of tokens) {
          if (/^[^#\&\?]{11}$/.test(token)) {
            return token;
          }
        }
      }
    }

    return null;
  }
}
