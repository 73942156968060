import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Language } from 'src/app/models/Language';
import { EndpointType, HttpService } from './http.service';
import { BusinessUnitClinicInfoResponse } from 'src/app/auth/models/server-response/clinic-info-response';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClinicService {
  constructor(
    private readonly httpService: HttpService,
    private readonly error: ErrorHandler
  ) {}

  public getLanguages(): Observable<Language[]> {
    return this.httpService
      .get<{ data: Language[] }>(EndpointType.Portal, 'clinic/languages')
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          return throwError(new Error('Error retrieving languages'));
        })
      );
  }
  public getClinicInfo(
    clinicToken: string
  ): Observable<BusinessUnitClinicInfoResponse> {
    return this.httpService
      .getRaw<{
        data: BusinessUnitClinicInfoResponse;
      }>(
        EndpointType.PortalPublic,
        'clinic',
        null,
        new HttpHeaders({
          'x-salve-clinic-token': clinicToken
        })
      )
      .pipe(
        map((res) => res.data),
        catchError((err) => {
          return throwError(new Error('Error retrieving clinic info'));
        })
      );
  }
}
