import {
  NewCategoryActionsUnion,
  NewCategoryActionTypes,
} from '../actions/new-category.actions';

export interface NewCategoryState {
  activeFilter: boolean;
  filterString: string;
}

export const initialState: NewCategoryState = {
  activeFilter: false,
  filterString: '',
};

export function reducer(
  state = initialState,
  action: NewCategoryActionsUnion,
): NewCategoryState {
  switch (action.type) {
    case NewCategoryActionTypes.FilterContent: {
      return {
        ...state,
        activeFilter: true,
        filterString: action.payload,
      };
    }
    case NewCategoryActionTypes.ClearCategoriesFilter: {
      return {
        ...state,
        activeFilter: false,
        filterString: '',
      };
    }
    default: {
      return state;
    }
  }
}

// Accessors
export const isFilterActive = (state: NewCategoryState) => state.activeFilter;
export const getFilterString = (state: NewCategoryState) => state.filterString;
