export class NewMessageDetail {
  public PatientId: number;
  public MessageTypeId: number;
  public Content: string;
  public Subject: string;

  constructor({ PatientId, MessageTypeId, Content, Subject }) {
    this.PatientId = PatientId;
    this.MessageTypeId = MessageTypeId;
    this.Content = Content;
    this.Subject = Subject;
  }
}

export class CreateNewThreadRequest {
  private Action = 'CreateNewClinicMessage';
  public PublicToken: string;
  public NewMessage: NewMessageDetail;

  constructor({ PublicToken, NewMessage }) {
    this.PublicToken = PublicToken;
    this.NewMessage = NewMessage;
  }
}
