import { SimplePatient } from 'src/app/models/SimplePatient';
import { Patient } from '../api/types';

export function simplePatientToPatient(patient: SimplePatient): Patient {
  return {
    id: patient.Id,
    firstname: patient.FirstName,
    lastname: patient.LastName,
    patientidentifier: patient.PatientIdentifier || null,
    dateofbirth: String(patient.DateOfBirth),
    internalsid: patient.InternalSid || null,
    patientaccountcreateddateutc: patient.PatientAccountCreatedDateUtc || null
  };
}
