import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  ContentPack,
  ContentPackDetail,
  PackAssignmentInterface
} from 'src/app/content/models/content-packs';

export interface ToggleLockedItem {
  id: number;
  isLocked: boolean;
}

@Component({
  selector: 'portal-pack-assignment',
  templateUrl: './pack-assignment.component.html',
  styleUrls: ['./pack-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackAssignmentComponent {
  @Input() text: any;
  @Input() patientId: number;
  @Input() selectedPack: ContentPack;
  @Input() packDetail: ContentPackDetail[] = [];
  @Input() lockedItems: number[];
  @Output() assignItemToPatient = new EventEmitter<PackAssignmentInterface>();
  @Output() assignPack = new EventEmitter<PackAssignmentInterface>();
  @Output() toggleLockedItem = new EventEmitter<ToggleLockedItem>();
  @Output() removeExclusions = new EventEmitter<PackAssignmentInterface>();

  public config: PerfectScrollbarConfigInterface = {};

  public toggleLocked(id: number, isLocked: boolean) {
    this.toggleLockedItem.emit({
      id,
      isLocked
    });
  }

  public handleAssignItemToPatient(detail: ContentPackDetail) {
    const exclusions = this.packDetail
      .filter((d) => d.contentEntryHeaderId !== detail.contentEntryHeaderId)
      .map((d) => d.contentEntryHeaderId);
    if (this.selectedPack.isAssigned) {
      this.assignItemToPatient.emit({
        packId: this.selectedPack.id,
        packName: this.selectedPack.packName,
        exclusions,
        packItem: detail,
        packDetail: this.packDetail,
        patientId: this.patientId
      });
    } else {
      this.assignPack.emit({
        packId: this.selectedPack.id,
        packName: this.selectedPack.packName,
        exclusions,
        packItem: detail,
        packDetail: this.packDetail,
        patientId: this.patientId
      });
    }
  }

  public assignPackToPatient(selectedPack: ContentPack) {
    if (
      this.packDetail &&
      this.packDetail.length > 0 &&
      this.packDetail.length - this.lockedItems.length !== 0 &&
      !this.selectedPack.isDefault
    ) {
      if (!this.selectedPack.isAssigned) {
        this.assignPack.emit({
          pack: selectedPack,
          packId: selectedPack.id,
          packName: selectedPack.packName,
          exclusions: this.lockedItems,
          packDetail: this.packDetail,
          patientId: this.patientId
        });
      } else {
        this.removeExclusions.emit({
          pack: selectedPack,
          packId: selectedPack.id,
          packName: selectedPack.packName,
          exclusions: this.lockedItems,
          packDetail: this.packDetail,
          patientId: this.patientId
        });
      }
    }
  }
}
