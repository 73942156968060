import * as tslib_1 from "tslib";
import { NewMessageActionTypes, } from '../actions/new-message.actions';
export function reducer(state, action) {
    var _a;
    switch (action.type) {
        case NewMessageActionTypes.SendNewMessage: {
            return tslib_1.__assign({}, state, { sendingMessage: action.payload.message.MessageId });
        }
        case NewMessageActionTypes.SendNewMessageError: {
            return tslib_1.__assign({}, state, { sendingMessage: null });
        }
        case NewMessageActionTypes.StoreDraft: {
            var messageId = action.payload.id;
            var content = action.payload.content;
            return tslib_1.__assign({}, state, { draftMessages: tslib_1.__assign({}, state.draftMessages, (_a = {}, _a[messageId] = content, _a)) });
        }
        default:
            return state;
    }
}
