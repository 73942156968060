import styled from '@emotion/styled';
import {
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent,
  DialogContentText,
  DialogProps as MaterialDialogProps,
  DialogTitle,
  PropTypes,
} from '@material-ui/core';
import * as React from 'react';
import { Button } from './Button';

export interface DialogProps {
  acceptButtonText: string;
  cancelButtonText: string;
  acceptButtonColour?: PropTypes.Color;
  cancelButtonColour?: PropTypes.Color;
  contentText: string;
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  titleText: string;
  maxWidth?: MaterialDialogProps['maxWidth'];
  fullWidth?: MaterialDialogProps['fullWidth'];
}

export const Dialog: React.VFC<DialogProps> = ({
  acceptButtonText,
  cancelButtonText,
  acceptButtonColour = 'secondary' as PropTypes.Color,
  cancelButtonColour = 'primary' as PropTypes.Color,
  contentText,
  isOpen,
  onAccept,
  onCancel,
  titleText,
  maxWidth,
  fullWidth,
}) => (
  <MaterialDialog
    open={isOpen}
    onClose={() => onCancel()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={maxWidth}
    fullWidth={fullWidth}
  >
    <DialogTitle id="alert-dialog-title">{titleText}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {contentText}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => onCancel()}
        color={cancelButtonColour}
        variant="contained"
      >
        {cancelButtonText}
      </Button>
      <Button
        autoFocus
        onClick={() => onAccept()}
        color={acceptButtonColour}
        variant="contained"
      >
        {acceptButtonText}
      </Button>
    </DialogActions>
  </MaterialDialog>
);

const DialogActions = styled(MaterialDialogActions)`
  padding: 16px 24px;
`;
