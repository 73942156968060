import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Dialog as MaterialDialog, DialogActions as MaterialDialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import * as React from 'react';
import { Button } from './Button';
export var Dialog = function (_a) {
    var acceptButtonText = _a.acceptButtonText, cancelButtonText = _a.cancelButtonText, _b = _a.acceptButtonColour, acceptButtonColour = _b === void 0 ? 'secondary' : _b, _c = _a.cancelButtonColour, cancelButtonColour = _c === void 0 ? 'primary' : _c, contentText = _a.contentText, isOpen = _a.isOpen, onAccept = _a.onAccept, onCancel = _a.onCancel, titleText = _a.titleText, maxWidth = _a.maxWidth, fullWidth = _a.fullWidth;
    return (React.createElement(MaterialDialog, { open: isOpen, onClose: function () { return onCancel(); }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", maxWidth: maxWidth, fullWidth: fullWidth },
        React.createElement(DialogTitle, { id: "alert-dialog-title" }, titleText),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, { id: "alert-dialog-description" }, contentText)),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: function () { return onCancel(); }, color: cancelButtonColour, variant: "contained" }, cancelButtonText),
            React.createElement(Button, { autoFocus: true, onClick: function () { return onAccept(); }, color: acceptButtonColour, variant: "contained" }, acceptButtonText))));
};
var DialogActions = styled(MaterialDialogActions)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  padding: 16px 24px;\n"], ["\n  padding: 16px 24px;\n"])));
var templateObject_1;
