/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin.component";
import * as i3 from "@ngrx/store";
import * as i4 from "../core/services/congito.wrapper.service";
var styles_AdminComponent = [i0.styles];
var RenderType_AdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["portalAdminReactContainer", 1]], null, 0, "div", [["class", "admin"]], null, null, null, null, null))], null, null); }
export function View_AdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-admin-component", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i1.ɵdid(1, 4440064, null, 0, i2.AdminComponent, [i3.Store, i4.CognitoWrapperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = i1.ɵccf("portal-admin-component", i2.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
