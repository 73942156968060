import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Category } from '../content/models/category';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  section: string;
  url: string;
  params: Params;
  queryParams: Params;
}

export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;

    // I only want the first section of the url
    // let section = url.replace(/^\/(\w+)(\/.+)?$/, '$1');
    // // let section = url.split('\/')[1];
    // switch (section) {
    //   case 'cms':
    //     section = 'CMS';
    //     break;
    //   //section will be looked out for in language, and string has to match lang fill key
    //   case 'video-calls':
    //     section = 'VideoCalls';
    //     break;
    //   //default:
    //   // if (section) {
    //   //   section = section[0].toUpperCase() + section.substring(1);
    //   // }
    // }

    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {
      section: url.split('/')[1],
      url,
      params,
      queryParams,
    };
  }
}

export function sortByKeyAlphabetically(key) {
  return (a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };
}

export function sortByKeyAlphabeticallyLowercase(key) {
  return (a, b) => {
    const aVal = a[key].toLowerCase();
    const bVal = b[key].toLowerCase();
    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
    return 0;
  };
}

export function sortAlphabetically(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function sortAlpabeticallyLowercase(a, b) {
  const aLower = a.name.toLowerCase();
  const bLower = b.name.toLowerCase();
  if (aLower < bLower) {
    return -1;
  }
  if (aLower > bLower) {
    return 1;
  }
  return 0;
}

export function filterAndSortCategories(categories: Category[]) {
  const drugsAndMeds = categories
    .filter(
      (a) =>
        a.isappointment || a.isdrug || a.issite || a.isstaff || a.istreatment,
    )
    .sort(sortAlpabeticallyLowercase);
  const others = categories
    .filter(
      (a) =>
        !(
          a.isappointment ||
          a.isdrug ||
          a.issite ||
          a.isstaff ||
          a.istreatment
        ),
    )
    .sort(sortAlpabeticallyLowercase);
  return drugsAndMeds.concat(others);
}

export function scrollToTop(element: any) {
  window.requestAnimationFrame(() => {
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
}

export function scrollToBottom(element: any, smooth: boolean) {
  window.requestAnimationFrame(() => {
    element.scrollTo({
      top: element.scrollHeight,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  });
}
