import { Action } from '@ngrx/store';

import { Label } from 'src/app/models/Label';
import { Message } from '../../models/Message';
import { ConversationListParams } from '../models/requests/conversations.request';
import { TabState } from '../models/TabState';

export enum MessageUIActionTypes {
  /**
   * These actions don't require server side actions
   */
  OpenThread = '[MessageUI] Open Thread',
  CloseThread = '[MessageUI] Close Thread',

  ChangeActiveList = '[MessageUI] Change Active List',

  // eventually search will involve server calling
  // currently it sorts through all threads.
  ActivateSearch = '[MessageUI] Activate Search',
  ClearSearch = '[MessageUI] Clear Search',
  UpdateSearchString = '[MessageUI] Update Search String',

  // change active label or search if search is
  // active
  ApplyFilter = '[MessageUI] Apply Message Filter',
  ApplyMessageTypeFilter = '[MessageUI] Apply Message Type Filter',
  SortMessageList = '[MessageUI] Sort Message List',
  SetSelectedTab = '[MessageUI] Set Selected Tab',
  StarMessage = '[MessageUI] Star Message',

  // change size and layout of message response box
  ChangeMessageResponseSize = '[MessageUI] Change Message Response Size',

  HideThreadNewMessagesButton = '[MessageUI] Hide thread new messages button',

  UpdateConversationCache = '[MessageUI] Updating conversation cache',
}

// ============== Client Side Actions ==================== //
// This action triggers LoadThread Action
export class OpenThread implements Action {
  readonly type = MessageUIActionTypes.OpenThread;

  constructor(public payload: Message) {}
}

export class CloseThread implements Action {
  readonly type = MessageUIActionTypes.CloseThread;
}

export class ApplyFilter implements Action {
  readonly type = MessageUIActionTypes.ApplyFilter;

  constructor(public payload: string) {}
}

export class ApplyMessageTypeFilter implements Action {
  readonly type = MessageUIActionTypes.ApplyMessageTypeFilter;

  constructor(public payload: Label) {}
}

export class ActivateSearch implements Action {
  readonly type = MessageUIActionTypes.ActivateSearch;
}
export class ClearSearch implements Action {
  readonly type = MessageUIActionTypes.ClearSearch;
}

export class UpdateSearchString implements Action {
  readonly type = MessageUIActionTypes.UpdateSearchString;

  constructor(public payload: string) {}
}

export class SortMessageList implements Action {
  readonly type = MessageUIActionTypes.SortMessageList;

  constructor(public payload: string) {}
}

export class ChangeMessageResponseSize implements Action {
  readonly type = MessageUIActionTypes.ChangeMessageResponseSize;

  constructor(public payload: string) {}
}

export class HideThreadNewMessagesButton implements Action {
  readonly type = MessageUIActionTypes.HideThreadNewMessagesButton;

  constructor() {}
}

export class SetSelectedTab implements Action {
  readonly type = MessageUIActionTypes.SetSelectedTab;

  constructor(
    public payload: {
      params: ConversationListParams;
      tab: TabState;
    },
  ) {}
}

export class UpdateConversationCache implements Action {
  readonly type = MessageUIActionTypes.UpdateConversationCache;

  constructor(
    public payload: {
      messageId: number;
      message: string;
    },
  ) {}
}

/**
 * MessageActionTypes
 */
export type MessageUIActionsUnion =
  /* Client side only */
  // interacting with UI
  | OpenThread
  | CloseThread
  | ApplyFilter
  | ApplyMessageTypeFilter
  | ActivateSearch
  | ClearSearch
  | UpdateSearchString
  | SortMessageList
  | ChangeMessageResponseSize
  | HideThreadNewMessagesButton
  | SetSelectedTab
  | UpdateConversationCache;
