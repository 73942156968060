import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromRoot from '../../../reducers';
import { PatientService } from '../../services/patient.service';
var PatientDetailsInternalComponent = /** @class */ (function () {
    function PatientDetailsInternalComponent(_store, _navigationService, _patientService) {
        this._store = _store;
        this._navigationService = _navigationService;
        this._patientService = _patientService;
        this._subs = new Subscription();
    }
    PatientDetailsInternalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var routerParams$ = this._store.pipe(select(fromRoot.getRouterParams));
        this._subs.add(routerParams$.pipe(take(1)).subscribe(function (params) {
            _this._patientService
                .getPatientById(params.internalsid, true)
                .pipe(take(1))
                .subscribe(function (patient) {
                if (patient.Id) {
                    _this._navigationService.navigate(['patients', patient.Id]);
                }
            });
        }));
    };
    PatientDetailsInternalComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    return PatientDetailsInternalComponent;
}());
export { PatientDetailsInternalComponent };
