import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MessagingSummaryResponse } from 'src/app/messaging/models/messagingSummaryResponse';
import { ClinicSummaryResponse } from './../../models/responses/clinic-summary.response';

@Component({
  selector: 'portal-dashboard-folders',
  templateUrl: './dashboard-folders.component.html',
  styleUrls: ['./dashboard-folders.component.scss'],
})
export class DashboardFoldersComponent {
  @Input() messagingSummary: MessagingSummaryResponse;
  @Input() labels: { name: string }[];
  @Input() text: any;
  @Input() summary: ClinicSummaryResponse;
  @Output() goToLabel = new EventEmitter<string>();
  @Output() goToMessages = new EventEmitter<number>();
}
