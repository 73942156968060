import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatConversation } from '../lib';
export function useFetchConversationById(id) {
    var _this = this;
    var apiClient = useApiClient().portal;
    return useQuery([queryCacheKey.CONVERSATION, id], function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var data;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.fetchConversationById(id)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, formatConversation(data)];
            }
        });
    }); }, { enabled: !!id });
}
