<a (click)="this.gotoNavItem.emit(menuItem)">
  <mat-list-item
    fxLayout="row"
    fxLayoutAlign="start center"
    [class.active]="activated"
  >
    <mat-icon>{{ menuItem.icon }}</mat-icon>
    <h3 class="sb-nav-header">
      {{ text.PageTitle(menuItem.key) }}
    </h3>
  </mat-list-item>
</a>
