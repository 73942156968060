import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import { SearchBar } from '@react/components';

export const StyledTableContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledSearchBar = styled(SearchBar)`
  position: sticky;
  top: 0;
  z-index: 30;
`;

export const StyledActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

export const EmptyContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  height: 400px;
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;
