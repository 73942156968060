import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import flatMap from 'lodash/flatMap';
import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatClinicUser } from '../lib';
export function useFetchAllClinicUsers() {
    var _this = this;
    var apiClient = useApiClient().portal;
    var _a = useInfiniteQuery([queryCacheKey.CLINIC_USERS], function (_a) {
        var pageNumber = _a.pageParam;
        return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, apiClient.fetchClinicUsers({ pageNumber: pageNumber })];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, tslib_1.__assign({}, response, { data: response.data.map(formatClinicUser) })];
                }
            });
        });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
        keepPreviousData: true,
    }), data = _a.data, fetchNextPage = _a.fetchNextPage, hasNextPage = _a.hasNextPage, isLoading = _a.isLoading, isFetchingNextPage = _a.isFetchingNextPage;
    useEffect(function () {
        if (!isLoading && !isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [isLoading, isFetchingNextPage, hasNextPage]);
    var users = useMemo(function () { return data && flatMap(data.pages, function (page) { return page.data; }); }, [data]);
    var loading = hasNextPage || isLoading || isFetchingNextPage;
    return { users: users, isLoading: loading };
}
