import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, delay, mergeMap, withLatestFrom } from 'rxjs/operators';
import { IBasicServerResponse } from '../../models/BasicServerResponse';
import { ServerError } from '../../models/Error';
import { ToastOptions } from '../../models/ToastOptions';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
import {
  AlterMessageActionTypes,
  ChangeLabel,
  ChangeLabelError,
  ChangeLabelSuccess,
  DeleteMessageItem,
  DeleteMessageItemError,
  DeleteMessageItemSuccess,
  MarkResolved,
  MarkResolvedError,
  MarkResolvedSuccess,
  MarkStarred,
  MarkStarredError,
  MarkStarredSuccess,
  MarkUnread,
  MarkUnreadError,
  MarkUnreadSuccess,
  MarkUnresolved,
  MarkUnresolvedError,
  MarkUnresolvedSuccess,
  MarkUnstarredError,
  MarkUnstarredSuccess,
  ToastDisplayed
} from '../actions/alter-message.actions';
import {
  LoadMessagingSummary,
  LoadNewerThreadMessages
} from '../actions/load-message.actions';
import { SetSelectedTab } from '../actions/message-ui.actions';
import { ShowRerouteToast } from '../actions/new-message.actions';
import { TabState } from '../models/TabState';
import * as fromMessages from '../reducers';
import { MessageService } from '../services/message.service';

@Injectable()
export class AlterMessageEffects {
  private _toastText: any;

  constructor(
    private actions$: Actions,
    private messageService: MessageService,
    private angulartics2: Angulartics2,
    private toastr: ToastrService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler
  ) {
    this._store
      .pipe(select(fromSettings.getSectionTranslations('MessageToast')))
      .subscribe((val) => {
        this._toastText = val;
      });
  }
  // Mark Unread
  @Effect()
  markUnread$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<MarkUnread>(AlterMessageActionTypes.MarkUnread),
      mergeMap((action) => {
        const data = action.payload.data;
        const toast = action.payload.toast;
        return this.messageService.markMessageUnread(data.id).pipe(
          mergeMap((response: boolean) => {
            this.angulartics2.eventTrack.next({
              action: 'Mark message unread',
              properties: { category: 'Messaging' }
            });
            return [
              new MarkUnreadSuccess({
                result: response,
                ...data
              }),
              new ToastDisplayed({
                toast
              })
            ];
          }),
          catchError((err) => {
            return [
              new MarkUnreadError(
                new ServerError(
                  '[AlterMessage]',
                  'Error marking message as unread',
                  ''
                )
              ),
              new ToastDisplayed({
                toast: new ToastOptions({
                  toast: null,
                  toastRef: null,
                  undoAction: null,
                  title: 'Error',
                  message: this._toastText.ErrorUnread,
                  type: 'error',
                  options: {
                    timeOut: 3000
                  }
                })
              })
            ];
          })
        );
      })
    );
  }

  // Mark Read not applicable yet

  // Mark Unresolved
  @Effect()
  markUnresolved$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<MarkUnresolved>(AlterMessageActionTypes.MarkUnresolved),
      mergeMap((action) => {
        // call message service to mark message as read
        return this.messageService
          .markMessageUnresolved(action.payload.data.id)
          .pipe(
            mergeMap((response: boolean) => {
              this.angulartics2.eventTrack.next({
                action: 'Mark message unresolved',
                properties: { category: 'Messaging' }
              });
              return [
                new MarkUnresolvedSuccess({
                  result: response,
                  ...action.payload.data
                }),
                new LoadMessagingSummary(),
                new ToastDisplayed({
                  toast: action.payload.toast
                })
              ];
            }),
            catchError((err) => {
              return [
                new MarkUnresolvedError(
                  new ServerError(
                    '[AlterMessage]',
                    'Error marking message as unresolved',
                    ''
                  )
                ),
                new ToastDisplayed({
                  toast: new ToastOptions({
                    toast: null,
                    toastRef: null,
                    undoAction: null,
                    title: this._toastText.ErrorEncountered,
                    message: this._toastText.ErrorUnresolved,
                    type: 'error',
                    options: {
                      timeOut: 3000
                    }
                  })
                })
              ];
            })
          );
      })
    );
  }

  // Mark Resolved
  @Effect()
  markResolved$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<MarkResolved>(AlterMessageActionTypes.MarkResolved),
      mergeMap((action) => {
        // call message service to mark message as read
        return this.messageService
          .markMessageResolved(action.payload.data.id)
          .pipe(
            mergeMap((response: boolean) => {
              this.angulartics2.eventTrack.next({
                action: 'Mark message resolved',
                properties: { category: 'Messaging' }
              });
              return [
                new MarkResolvedSuccess({
                  result: response,
                  ...action.payload.data
                }),
                new LoadMessagingSummary(),
                new ToastDisplayed({
                  toast: action.payload.toast
                })
              ];
            }),
            catchError((err) => {
              return [
                new MarkResolvedError(
                  new ServerError(
                    '[AlterMessage]',
                    'Error marking message as resolved',
                    ''
                  )
                ),
                new ToastDisplayed({
                  toast: new ToastOptions({
                    toast: null,
                    toastRef: null,
                    undoAction: null,
                    title: this._toastText.ErrorEncountered,
                    message: this._toastText.ErrorResolved,
                    type: 'error',
                    options: {
                      timeOut: 3000
                    }
                  })
                })
              ];
            })
          );
      })
    );
  }

  // ChangeLabel
  @Effect()
  changeLabel$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<ChangeLabel>(AlterMessageActionTypes.ChangeLabel),
      mergeMap((action) => {
        // call message service to mark message as read
        return this.messageService.updateMessageType(action.payload.data).pipe(
          mergeMap((response: IBasicServerResponse) => {
            this.angulartics2.eventTrack.next({
              action: 'Reassign message label',
              properties: { category: 'Messaging' }
            });
            return [
              new ChangeLabelSuccess({
                data: action.payload.data,
                result: response
              }),
              new ToastDisplayed({
                toast: action.payload.toast
              })
            ];
          }),
          catchError((err) => {
            return [
              new ChangeLabelError(
                new ServerError('[AlterMessage]', 'Error changing label', '')
              ),
              new ToastDisplayed({
                toast: new ToastOptions({
                  toast: null,
                  toastRef: null,
                  undoAction: null,
                  title: this._toastText.ErrorEncountered,
                  message: this._toastText.ErrorChanging,
                  type: 'error',
                  options: {
                    timeOut: 3000
                  }
                })
              })
            ];
          })
        );
      })
    );
  }

  @Effect()
  deleteMessageItem$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<DeleteMessageItem>(AlterMessageActionTypes.DeleteMessageItem),
      withLatestFrom(this._store.pipe(select(fromMessages.getActiveThread))),
      mergeMap(([action, activeThread]) => {
        return this.messageService.deleteMessageItem(action.payload).pipe(
          mergeMap((response: IBasicServerResponse) => {
            const actions: Action[] = [];
            if (activeThread.id === action.payload.messageId) {
              const patientMessages = activeThread.messages.filter(
                (m) => m.PatientSent
              );
              let latestId = 0;
              if (patientMessages.length > 0) {
                latestId =
                  patientMessages[patientMessages.length - 1].MessageItemId;
              }
              actions.push(
                new LoadNewerThreadMessages({
                  messageId: activeThread.id,
                  latestMessageItemId: latestId
                })
              );
            }
            actions.push(new DeleteMessageItemSuccess(action.payload));
            return actions;
          }),
          catchError((err) => {
            return [
              new DeleteMessageItemError(),
              new ToastDisplayed({
                toast: new ToastOptions({
                  toast: null,
                  toastRef: null,
                  undoAction: null,
                  title: this._toastText.ErrorDeletingMessageTitle,
                  message: this._toastText.ErrorDeletingMessage,
                  type: 'error',
                  options: {
                    timeOut: 7000
                  }
                })
              })
            ];
          })
        );
      })
    );
  }

  // Mark Starred
  @Effect()
  markStarred$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<MarkStarred>(AlterMessageActionTypes.MarkStarred),
      delay(500),
      mergeMap((action) => {
        const { message } = action.payload;

        return this.messageService
          .markMessageStarred(action.payload.message.MessageId)
          .pipe(
            mergeMap((response: boolean) => {
              this.angulartics2.eventTrack.next({
                action: 'Mark message starred',
                properties: { category: 'Messaging' }
              });

              const toast = this.toastr.show(
                action.payload.toast.message,
                action.payload.toast.title,
                {
                  timeOut: 0,
                  closeButton: false,
                  toastClass: `toast custom-toast info`
                }
              );

              return [
                new MarkStarredSuccess({
                  result: response,
                  message
                }),
                new ShowRerouteToast({
                  route: '/messages',
                  afterAction: new SetSelectedTab({
                    tab: TabState.Starred,
                    params: {
                      MessageTypeId: action.payload.message.MessageTypeId,
                      FetchAfterMessageItemId: null,
                      FetchBeforeMessageItemId: null
                    }
                  }),
                  toast: {
                    ...action.payload.toast,
                    toast,
                    toastRef: toast.toastRef.componentInstance
                  },
                  other: {
                    id: message.MessageId,
                    type: message.MessageType,
                    typeId: message.MessageTypeId,
                    patientFirstName: message.PatientFirstName,
                    patientLastName: message.PatientLastName,
                    patientId: message.PatientId,
                    patientIdentifier: message.PatientIdentifier,
                    patientDoB: message.DateOfBirth,
                    isClincRead: message.ClinicRead,
                    isPatientRead: message.PatientRead,
                    isStarred: message.IsStarred,
                    subject: message.MessageSubject,
                    content: message.Content
                  }
                })
              ];
            }),
            catchError((err) => {
              return [
                new MarkStarredError({
                  messageId: message.MessageId,
                  error: new ServerError(
                    '[AlterMessage]',
                    'Error marking message as starred',
                    ''
                  )
                }),
                new ToastDisplayed({
                  toast: new ToastOptions({
                    toast: null,
                    toastRef: null,
                    undoAction: null,
                    title: this._toastText.MessageStarredTitleError,
                    message: this._toastText.MessageStarredBodyError,
                    type: 'error',
                    options: {
                      timeOut: 3000
                    }
                  })
                })
              ];
            })
          );
      })
    );
  }

  // Mark Unstarred
  @Effect()
  markUnstarred$(): Observable<Action> {
    return this.actions$.pipe(
      ofType<MarkStarred>(AlterMessageActionTypes.MarkUnstarred),
      delay(500),
      mergeMap((action) => {
        const { message } = action.payload;

        return this.messageService
          .markMessageUnstarred(action.payload.message.MessageId)
          .pipe(
            mergeMap((response: boolean) => {
              const toast = this.toastr.show(
                action.payload.toast.message,
                action.payload.toast.title,
                {
                  timeOut: 0,
                  closeButton: false,
                  toastClass: `toast custom-toast info`
                }
              );

              this.angulartics2.eventTrack.next({
                action: 'Mark message unstarred',
                properties: { category: 'Messaging' }
              });

              return [
                new MarkUnstarredSuccess({
                  result: response,
                  message: action.payload.message
                }),
                new ShowRerouteToast({
                  route: '/messages',
                  afterAction: new SetSelectedTab({
                    tab: TabState.EverythingElse,
                    params: {
                      MessageTypeId: action.payload.message.MessageTypeId,
                      FetchAfterMessageItemId: null,
                      FetchBeforeMessageItemId: null
                    }
                  }),
                  toast: {
                    ...action.payload.toast,
                    toast,
                    toastRef: toast.toastRef.componentInstance
                  },
                  other: {
                    id: message.MessageId,
                    type: message.MessageType,
                    typeId: message.MessageTypeId,
                    patientFirstName: message.PatientFirstName,
                    patientLastName: message.PatientLastName,
                    patientId: message.PatientId,
                    patientIdentifier: message.PatientIdentifier,
                    patientDoB: message.DateOfBirth,
                    isClincRead: message.ClinicRead,
                    isPatientRead: message.PatientRead,
                    isStarred: message.IsStarred,
                    subject: message.MessageSubject,
                    content: message.Content
                  }
                })
              ];
            }),
            catchError((err) => {
              return [
                new MarkUnstarredError({
                  messageId: message.MessageId,
                  error: new ServerError(
                    '[AlterMessage]',
                    'Error marking message as unstarred',
                    ''
                  )
                }),
                new ToastDisplayed({
                  toast: new ToastOptions({
                    toast: null,
                    toastRef: null,
                    undoAction: null,
                    title: this._toastText.MessageUnstarredTitleError,
                    message: this._toastText.MessageUnstarredBodyError,
                    type: 'error',
                    options: {
                      timeOut: 3000
                    }
                  })
                })
              ];
            })
          );
      })
    );
  }
}
