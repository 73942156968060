import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'portal-content-edit-controls',
  templateUrl: './content-edit-controls.component.html',
  styleUrls: ['./content-edit-controls.component.scss'],
})
export class ContentEditControlsComponent implements OnInit {
  @Input() canSendToAll: boolean;
  @Input() parentExists: boolean;
  @Input() text: any;
  @Input() formError: boolean;
  @Input() introductionError: boolean;
  @Input() contentEditorEnabled: boolean;
  @Input() isGlobalPortal: boolean;
  @Input() canPublish: boolean;
  @Output() exitForm = new EventEmitter();
  @Output() publishContent = new EventEmitter();

  public sendToAllForm: FormGroup;

  constructor(private _controlContainer: ControlContainer) {}

  ngOnInit() {
    this.sendToAllForm = this._controlContainer.control as FormGroup;
  }
}
