import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[portal-toast-component]',
  templateUrl: `./portal-toast.component.html`,
  styleUrls: [`./portal-toast.component.scss`],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          display: 'none',
          opacity: 0,
        }),
      ),
      state('active', style({})),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}'),
      ),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  preserveWhitespaces: false,
})
export class PortalToastComponent extends Toast implements OnDestroy {
  public toastText$: Observable<any>;
  public toastText: any;

  private _subs = new Subscription();

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private _store: Store<fromRoot.State>,
  ) {
    super(toastrService, toastPackage);

    this.toastText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('MessageToast')),
    );

    this._subs.add(this.toastText$.subscribe((t) => (this.toastText = t)));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  // wrapper function to perform specific functions in one go
  public alterToast({
    class: { classesToRemove, classToAdd },
    title,
    message,
  }): void {
    if (title) {
      this.setTitle(title);
    }
    if (message) {
      this.setMessage(message);
    }
    if (classesToRemove || classToAdd) {
      this.changeClass(classesToRemove, classToAdd);
    }
  }

  public changeClass(classesToRemove: string[], classToAdd: string) {
    let temp = this.toastClasses;

    // remove all strings provided in classToRemove
    for (const _class of classesToRemove) {
      temp = temp.replace(_class, '');
    }

    // add the class to add.
    temp = `${temp} ${classToAdd}`;

    this.toastClasses = temp;
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public setMessage(message: string): void {
    this.message = message;
  }

  public action(event: Event): boolean {
    event.stopPropagation();
    // trigger action back where service was created
    this.toastPackage.triggerAction();
    return false;
  }
}
