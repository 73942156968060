import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../../auth/reducers';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { ContentEntryHeader } from '../..//models/content-entry';
import {
  ClearFilter,
  ContentActionTypes,
  CreateCategorySuccess,
  DeleteCategory,
  DeleteCategorySuccess,
  DeleteEntries,
  FilterContent,
  OpenDirectorySection,
} from '../../actions/content.actions';
import {
  DeleteEntriesArgs,
  MoveEntriesArgs,
} from '../../components/content-list-table/content-list-table.component';
import { EditFolderNameComponent } from '../../components/edit-folder-name/edit-folder-name.component';
import { EditPackNameComponent } from '../../components/edit-pack-name/edit-pack-name.component';
import { MoveEntriesComponent } from '../../components/move-entries/move-entries.component';
import { Category } from '../../models/category';
import { TableContentEntry } from '../../models/content-entry';
import { ContentPack } from '../../models/content-packs';
import { ContentTemplate } from '../../models/content-template';
import * as fromContent from '../../reducers';
import { CreateCategoryComponent } from './../../components/create-category/create-category.component';
import { CreatePackComponent } from './../../components/create-pack/create-pack.component';
import { ViewChildParams } from './../../models/content-entry';
import { ViewChildrenContainer } from './../view-children/view-children.container';

@Component({
  selector: 'portal-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
})
export class ContentListComponent implements OnInit, OnDestroy {
  // Observables used for text content
  public sectionText$: Observable<any>;
  public categoryText$: Observable<any>;
  public directoryText$: Observable<any>;

  public sectionText: any;
  public categoryText: any;

  // Observables used in content-directory
  public categories$: Observable<Category[]> = of([]);
  public otherCategories$: Observable<Category[]> = of([]);
  public templates$: Observable<ContentTemplate[]> = of([]);
  public packs$: Observable<ContentPack[]> = of([]);
  public categoryId$: Observable<number>;
  public routeType$: Observable<string> = of('list');

  public otherCategories: Category[] = [];

  // Observables to be used in search bar
  public isFilterActive$: Observable<boolean>;
  public currentFilter$: Observable<string>;
  public activeCategory$: Observable<string>;
  public activeCategoryInfo$: Observable<Category>;
  public openDirectory$: Observable<string>;
  public isMultiLingual$: Observable<boolean>;

  // Observables for content list
  public contentList$: Observable<ContentEntryHeader[]> = of([]);
  public contentToDisplay$: Observable<TableContentEntry[]> = of([]);

  // S3 Config
  public clinicId$: Observable<string>;

  // Content editor flag
  public contentEditorEnabled$: Observable<boolean>;
  public contentEditorEnabled: boolean;

  // Global Portal flag
  public isGlobalPortal$: Observable<boolean>;
  public isGlobalPortal: boolean;

  // Subscription to allow easy clean up
  private _subs = new Subscription();

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
    public dialog: MatDialog,
    public updates$: Actions,
  ) {}

  ngOnInit() {
    // Global Portal Observable
    this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));

    // Content editor group Observables
    this.contentEditorEnabled$ = this._store.pipe(
      select(fromContent.getContentEditorEnabled),
    );
    // Text Observables
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewContentList')),
    );
    this.directoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentDirectory')),
    );
    this.categoryText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('CreateCategory')),
    );

    this._subs.add(
      this.isGlobalPortal$.subscribe((value) => {
        this.isGlobalPortal = value;
      }),
    );

    // Content editor group, wrapped in a feature flag
    this._subs.add(
      this.contentEditorEnabled$.subscribe(
        (t) => (this.contentEditorEnabled = t),
      ),
    );

    // Capture text for use in Dialogs
    this._subs.add(this.sectionText$.subscribe((t) => (this.sectionText = t)));
    this._subs.add(
      this.categoryText$.subscribe((t) => (this.categoryText = t)),
    );

    // content-directory observables from Store and active route
    this.categories$ = this._store.pipe(
      select(fromContent.getGeneralCategories),
    );
    this.templates$ = this._store.pipe(select(fromContent.getTemplates));
    this.packs$ = this._store.pipe(select(fromContent.getPacks));
    this.categoryId$ = this._route.params.pipe(
      map((params: Params) => +params.id),
    );

    this.otherCategories$ = combineLatest(
      this.categories$,
      this.categoryId$,
    ).pipe(map(([categories, id]) => categories.filter((c) => c.id !== id)));

    // Observables for searchbar
    this.isFilterActive$ = this._store.pipe(select(fromContent.isFilterActive));
    this.currentFilter$ = this._store.pipe(
      select(fromContent.getCurrentListFilter),
    );

    // Observable for S3 setup
    this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));

    this.activeCategory$ = combineLatest(
      this.categories$,
      this._route.params,
    ).pipe(
      map(([categories, params]) => {
        const category = categories.find((c) => c.id === +params.id);
        return category ? category.name : '';
      }),
    );

    this.activeCategoryInfo$ = combineLatest(
      this.categories$,
      this._route.params,
    ).pipe(
      map(([categories, params]) => {
        const category = categories.find((c) => c.id === +params.id);
        return category;
      }),
    );

    // content list observables
    this.contentList$ = this._store.pipe(select(fromContent.getContentHeaders));
    this.contentToDisplay$ = combineLatest(
      this.contentList$,
      this.isFilterActive$,
      this.currentFilter$,
    ).pipe(
      map(([content, filterActive, currentFilter]) => {
        const rtnContent = content.map<TableContentEntry>((c) => ({
          id: c.id,
          name: c.name,
          isEmpty: c.isempty,
          isMigrated: c.wasmigrated,
          fromDataSync: c.fromdatasync,
          link: c.haschildren ? 2 : c.parentid ? 1 : 0,
          comments: c.comments,
          categoryId: c.contentcategoryid,
          category: c.contentcategory,
          hasChildren: c.haschildren,
          sendToAll: c.sendtoall,
          parentId: c.parentid,
          salveModified: c.salvemodified,
          lastModifiedBy: c.lastmodifiedby,
          translations: c.translations,
        }));
        if (filterActive) {
          return rtnContent.filter(
            (c) =>
              `${c.id}`.includes(currentFilter) ||
              c.name.toLowerCase().includes(currentFilter),
          );
        } else {
          return rtnContent;
        }
      }),
    );

    // Which section of directory is open?
    this.openDirectory$ = this._store.pipe(
      select(fromContent.activeDirectorySection),
    );

    this.isMultiLingual$ = this._store.pipe(
      select(fromRoot.getLanguages),
      map((languages) => languages.length > 0),
    );

    // watch for successful deletion of a folder
    this._subs.add(
      this.updates$
        .pipe(
          ofType<DeleteCategorySuccess>(
            ContentActionTypes.DeleteCategorySuccess,
          ),
          switchMap((action) => {
            return this.categories$.pipe(
              // TODO: Fix types
              // @ts-ignore
              filter((c: Category) => c.id !== action.payload),
            );
          }),
        )
        .subscribe((categories: Category[]) => {
          const id = categories.length > 0 ? categories[0].id : 0;
          this._navigationService.navigate(['content', 'list', id]);
        }),
    );

    // watch for successful creation of a folder
    this._subs.add(
      this.updates$
        .pipe(
          ofType<CreateCategorySuccess>(
            ContentActionTypes.CreateCategorySuccess,
          ),
        )
        .subscribe((action) => {
          const id = action.payload.id;
          this._navigationService.navigate(['content', 'list', id]);
        }),
    );

    // capture other categories to use in order to provide options
    // of which folders an item can be moved to.
    this._subs.add(
      this.otherCategories$.subscribe((c) => (this.otherCategories = c)),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  navigateTo(url: string): void {
    this._navigationService.navigateByUrl(url);
  }

  updateFilter(filterString: string) {
    if (!filterString || filterString === '') {
      this._store.dispatch(new ClearFilter());
    } else {
      this._store.dispatch(new FilterContent(filterString));
    }
  }

  goToContent(te: TableContentEntry) {
    this._navigationService.navigate([
      'content',
      'doc',
      'edit',
      te.categoryId,
      te.id,
    ]);
  }

  createEntry(categoryId: number) {
    this._navigationService.navigate([
      'content',
      'doc',
      'richorpdf',
      categoryId,
    ]);
  }

  openDirectorySection(section: string) {
    this._store.dispatch(new OpenDirectorySection(section));
  }

  public deleteEntries(deleteEntries: DeleteEntriesArgs) {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.sectionText.DeleteItemsConfirmation,
        information: this.sectionText.DeleteItemsAdditionalInfo,
        text: {
          Cancel: this.sectionText.Cancel,
          Confirm: this.sectionText.Delete,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this._store.dispatch(
          new DeleteEntries({
            categoryId: deleteEntries.categoryId,
            entriesToDelete: deleteEntries.entries,
          }),
        );
      }
    });
  }

  public moveEntries(moveEntries: MoveEntriesArgs) {
    this.dialog.open(MoveEntriesComponent, {
      data: {
        entries: moveEntries.entries,
        currentCategoryId: moveEntries.categoryId,
        otherCategories: this.otherCategories,
        text: {
          ...this.sectionText,
        },
      },
    });
  }

  public deleteCategory(id: number) {
    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message: this.sectionText.DeleteFolderConfirmation,
        text: {
          Cancel: this.sectionText.Cancel,
          Confirm: this.sectionText.Delete,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this._store.dispatch(new DeleteCategory(id));
      }
    });
  }

  public createNewFolder() {
    this.dialog.open(CreateCategoryComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public changeFolderName(category: Category) {
    this.dialog.open(EditFolderNameComponent, {
      data: {
        category,
        text: this.sectionText,
      },
      width: '550px',
    });
  }

  public openViewChildrenModal(viewChildParams: ViewChildParams) {
    const childrenModal = this.dialog.open(ViewChildrenContainer, {
      data: {
        ...viewChildParams,
      },
      width: '550px',
    });
    childrenModal
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {});
  }

  public createNewPack() {
    this.dialog.open(CreatePackComponent, {
      data: {
        text: this.categoryText,
      },
      width: '550px',
    });
  }

  public editPackName(pack: ContentPack) {
    this.dialog.open(EditPackNameComponent, {
      data: {
        pack,
        text: this.sectionText,
      },
      width: '550px',
    });
  }
}
