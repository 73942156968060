import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { getContentCategoryTypeId, getContentId } from '../../reducers';
import { Category } from '../models/category';
import { ContentPackDetail } from '../models/content-packs';
import { ContentTemplate } from '../models/content-template';
import { ContentEntryNames } from '../models/linked-content';
import { S3OptionsResponse } from '../models/responses/s3-options.response';
import { ContentEntryHeader } from './../models/content-entry';
import * as fromContentUI from './content-ui.reducer';
import * as fromContentData from './content.reducer';
import * as fromContentForms from './forms.reducer';
import * as fromLink from './link-content.reducer';
import * as fromNewCategory from './new-category.reducer';
import * as fromPacks from './packs.reducer';
import * as fromS3 from './s3.reducer';

// Interfaces
export interface ContentState {
  packs: fromPacks.State;
  data: fromContentData.State;
  ui: fromContentUI.State;
  newCategory: fromNewCategory.NewCategoryState;
  forms: fromContentForms.FormState;
  s3: fromS3.S3State;
  link: fromLink.State;
}

// Extend Core Application State
export interface State extends fromRoot.State {
  content: ContentState;
}

// Reducer Definition
export const reducers: ActionReducerMap<ContentState> = {
  packs: fromPacks.reducer,
  data: fromContentData.reducer,
  ui: fromContentUI.reducer,
  newCategory: fromNewCategory.reducer,
  forms: fromContentForms.reducer,
  s3: fromS3.reducer,
  link: fromLink.reducer,
};

// Selectors
// =================================

// filter content state from rest of application
export const selectContentState =
  createFeatureSelector<ContentState>('content');

// Forms
// ==============
export const selectForms = createSelector(
  selectContentState,
  (state: ContentState) => state.forms,
);
// takes form id and returns form values
export const selectForm = (path: string) =>
  createSelector(
    selectForms,
    (state: fromContentForms.FormState) => state[path],
  );

// Packs
// ==============
export const selectContentPacks = createSelector(
  selectContentState,
  (state: ContentState) => state.packs,
);
export const getPacks = createSelector(selectContentPacks, fromPacks.getPacks);
export const isCreatingPack = createSelector(
  selectContentPacks,
  fromPacks.isCreatingPack,
);
export const isEditingPack = createSelector(
  selectContentPacks,
  fromPacks.isEditingPack,
);
export const isDeletingPack = createSelector(
  selectContentPacks,
  fromPacks.isDeletingPack,
);
export const isLoadingPacks = createSelector(
  selectContentPacks,
  fromPacks.isLoadingPacks,
);
export const isLoadingPackDetail = createSelector(
  selectContentPacks,
  fromPacks.isLoadingPackDetail,
);
export const isAddingToPack = createSelector(
  selectContentPacks,
  fromPacks.isAddingToPack,
);
export const isRemovingFromPack = createSelector(
  selectContentPacks,
  fromPacks.isRemovingFromPack,
);
export const isPacksListFilterActive = createSelector(
  selectContentPacks,
  fromPacks.isListFilterActive,
);
export const getPacksListFilter = createSelector(
  selectContentPacks,
  fromPacks.getCurrentListFilter,
);
export const getErrors = createSelector(
  selectContentPacks,
  fromPacks.getErrors,
);
export const checkForError = (errorKey: string) =>
  createSelector(
    getErrors,
    (errors: { [key: string]: string }) => errors[errorKey],
  );
export const getContentPackDetails = createSelector(
  selectContentPacks,
  fromPacks.getContentPackDetails,
);
export const getActivePackDetails = (id: number) =>
  createSelector(
    getContentPackDetails,
    (packDetails: { [x: number]: ContentPackDetail[] }) => packDetails[id],
  );

// New Category
// ==============
export const selectNewCategory = createSelector(
  selectContentState,
  (state: ContentState) => state.newCategory,
);
export const isNewCategoryFilterActive = createSelector(
  selectNewCategory,
  fromNewCategory.isFilterActive,
);
export const getNewCategoryFilterString = createSelector(
  selectNewCategory,
  fromNewCategory.getFilterString,
);

// Links
// ==============
export const getLinkContentState = createSelector(
  selectContentState,
  (state: ContentState) => state.link,
);
export const getTargetFilter = createSelector(
  getLinkContentState,
  fromLink.getTargetFilter,
);
export const getAvailableToLinkContent = createSelector(
  getLinkContentState,
  fromLink.getAvailableToLinkContent,
);
export const getSelectedContent = createSelector(
  getLinkContentState,
  fromLink.getSelectedContent,
);
export const getFilteredAvailableContent = createSelector(
  getAvailableToLinkContent,
  getTargetFilter,
  getSelectedContent,
  (content: ContentEntryNames[], filter: string, selectedContent: number[]) => {
    const returnItems =
      filter && filter.length > 1
        ? content.filter((c) => {
            const val = c.patientname || c.clinicname || '';
            return (
              val.toLowerCase().includes(filter) &&
              !selectedContent.includes(c.id)
            );
          })
        : content;
    return returnItems;
  },
);
export const isLoadingAvailableToLinkContent = createSelector(
  getLinkContentState,
  fromLink.isLoadingAvailableToLinkContent,
);
export const isCreatingLink = createSelector(
  getLinkContentState,
  fromLink.isCreatingLink,
);
export const getLinkErrors = createSelector(
  getLinkContentState,
  fromLink.getErrors,
);
export const getLinkError = (key: string) =>
  createSelector(getLinkContentState, fromLink.getError(key));
export const isDeletingLink = createSelector(
  getLinkContentState,
  fromLink.isDeletingLink,
);
export const isDeletingLinks = createSelector(
  getLinkContentState,
  fromLink.isDeletingLinks,
);
export const isLoadingChildren = createSelector(
  getLinkContentState,
  fromLink.isLoadingChildren,
);
export const getChildren = createSelector(
  getLinkContentState,
  fromLink.getChildren,
);

// Data
// ==============
export const selectContentData = createSelector(
  selectContentState,
  (state: ContentState) => state.data,
);

export const getTemplates = createSelector(
  selectContentData,
  fromContentData.getTemplates,
);
export const getActiveContent = createSelector(
  selectContentData,
  fromContentData.getActiveContent,
);
export const getContentHeadersFromContentEntry = createSelector(
  selectContentData,
  getContentCategoryTypeId,
  (contentData: fromContentData.State, contentId: number) => {
    if (contentData.contentHeaders.hasOwnProperty(contentId)) {
      return contentData.contentHeaders[contentId];
    }
    return [];
  },
);
export const getContentHeaders = createSelector(
  selectContentData,
  getContentId,
  (contentData: fromContentData.State, contentId: number) => {
    if (contentData.contentHeaders.hasOwnProperty(contentId)) {
      return contentData.contentHeaders[contentId];
    }
    return [];
  },
);
export const getCategories = createSelector(
  selectContentData,
  fromContentData.getCategories,
);
export const getFirstCategoryId = createSelector(
  getCategories,
  (categories: Category[]) => (categories[0] && categories[0].id) || null,
);
export const getGeneralCategories = createSelector(
  selectContentData,
  fromContentData.getGeneralCategories,
);
export const getCategoryNameById = (id: number) =>
  createSelector(
    getCategories,
    (categories: Category[]) => categories.find((c) => c.id === id).name,
  );

export const getParentForModal = (id: number, catgoryId: number) =>
  createSelector(selectContentData, (contentData: fromContentData.State) => {
    const headers = contentData.contentHeaders[catgoryId];
    const parent = headers.find((h) => h.id === id) || null;
    return parent;
  });

export const getParentById = (id: number) =>
  createSelector(
    getContentHeadersFromContentEntry,
    (headers: ContentEntryHeader[]) => {
      const parent = headers.find((h) => h.id === id) || null;
      return parent;
    },
  );

export const isLoadingContentEntry = createSelector(
  selectContentData,
  fromContentData.isLoadingContentEntry,
);
export const hasLoadingContentEntryError = createSelector(
  selectContentData,
  fromContentData.loadingContentEntryError,
);
export const getIsSavingContentEntry = createSelector(
  selectContentData,
  fromContentData.getIsSavingContentEntry,
);

// UI
// ==============
export const selectContentUIState = createSelector(
  selectContentState,
  (state: ContentState) => {
    return state.ui;
  },
);
export const activeDirectorySection = createSelector(
  selectContentUIState,
  fromContentUI.getActiveDirectorySection,
);
export const isLoadingDirectory = createSelector(
  selectContentUIState,
  fromContentUI.isLoadingDirectory,
);
export const isLoadingTemplate = createSelector(
  selectContentUIState,
  fromContentUI.isLoadingTemplate,
);
export const isLoadingContent = createSelector(
  selectContentUIState,
  fromContentUI.isLoadingContent,
);
export const isSavingTemplate = createSelector(
  selectContentUIState,
  fromContentUI.isSavingTemplate,
);
export const isFilterActive = createSelector(
  selectContentUIState,
  fromContentUI.isFilterActive,
);
export const getCurrentListFilter = createSelector(
  selectContentUIState,
  fromContentUI.getCurrentListFilter,
);

export const getContentEditorEnabled = createSelector(
  selectContentUIState,
  fromContentUI.getContentEditorEnabled,
);

export const getTemplateById = (id: number) =>
  createSelector(getTemplates, (templates: ContentTemplate[]) =>
    templates.find((t) => t.id === id),
  );

// select config from high-level content state
export const getS3 = createSelector(
  selectContentState,
  (state: ContentState) => state.s3,
);

// Config Accessors
export const getConfig = createSelector(
  getS3,
  (state: fromS3.S3State) => state.config,
);

export const getRootAlbumBucket = createSelector(
  getConfig,
  (state: S3OptionsResponse) => state.albumBucketName,
);
export const getBucketRegion = createSelector(
  getConfig,
  (state: S3OptionsResponse) => state.bucketRegion,
);
export const getIdentityPoolId = createSelector(
  getConfig,
  (state: S3OptionsResponse) => state.IdentityPoolId,
);
