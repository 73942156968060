import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import { ProfilePhotoModalComponent } from '../../../react/pages/userMenu/components/ProfilePhotoModal';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
import { FileUpload } from '../../services/s3.service';

const containerElementName = 'profilePhotoModalReactWrapperComponent';

interface DialogData {
  close: () => void;
}

@Component({
  selector: 'portal-profile-photo-modal',
  template: `<div #${containerElementName}></div>`,
})
export class ProfilePhotoModalReactWrapperComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(containerElementName) containerRef!: ElementRef;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _fileUpload: FileUpload,
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
      ]).subscribe(
        ([clinicToken, clinicOptions, user, language, translations]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
          };
          this.render();
        },
      ),
    );
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.render();
  }

  private onChanged() {
    // This lets the spinner appear briefly before a page reload is initiated.
    // This is a sledgehammer way to forces all of the <img> instances using the old profile image to update.
    setTimeout(() => location.reload(), 3000);
  }

  private async render() {
    ReactDOM.render(
      React.createElement(Root, {
        ...this._props,
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        children: React.createElement(ProfilePhotoModalComponent, {
          open: false,
          dialogWrapper: false,
          onCancelled: this.data.close,
          onConfirmed: this.onChanged,
          fileUploadService: this._fileUpload,
        }),
      }),
      this.containerRef.nativeElement,
    );
  }
}
