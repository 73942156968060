import { Injectable } from '@angular/core';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import {
  de as deLocale,
  enGB as enLocale,
  enUS as usLocale,
  es as esLocale,
} from 'date-fns/locale';

const dateLocales = {
  'en-gb': enLocale,
  'en-us': usLocale,
  de: deLocale,
  es: esLocale,
};

@Injectable()
export class LocalisationService {
  private _languageCode: string;
  private _locale: Locale;
  private _timeZone: string;

  constructor() {}

  setLocale(languageCode: string) {
    this._languageCode = languageCode;
    this._locale = dateLocales[languageCode];
  }

  getTimeZone() {
    return this._timeZone;
  }

  setTimeZone(timeZone: string) {
    this._timeZone = timeZone;
  }

  getLanguageCode() {
    return this._languageCode;
  }

  parseDate(date: string | Date): Date {
    return date instanceof Date ? date : dateFns.parseISO(date);
  }

  getShortDate(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(this.parseDate(from), 'LLL dd yyyy', {
          locale: this._locale,
        });
      }
    }
    return dateFns.format(this.parseDate(from), 'dd LLL yyyy', {
      locale: this._locale,
    });
  }

  getShortDateWithTime(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(this.parseDate(from), 'LLL dd yyyy - h:mm a', {
          locale: this._locale,
        });
      }
    }
    return dateFns.format(this.parseDate(from), 'dd LLL yyyy - HH:mm', {
      locale: this._locale,
    });
  }

  getLongDate(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(this.parseDate(from), 'iiii LLL dd yyyy', {
          locale: this._locale,
        });
      }
    }
    return dateFns.format(this.parseDate(from), 'iiii dd LLL yyyy', {
      locale: this._locale,
    });
  }

  getLongDateWithTime(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(
          this.parseDate(from),
          'iiii LLL dd yyyy - HH:mm',
          { locale: this._locale },
        );
      }
    }
    return dateFns.format(this.parseDate(from), 'iiii dd LLL yyyy - HH:mm', {
      locale: this._locale,
    });
  }

  getFullDate(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(this.parseDate(from), 'iiii LLLL dd yyyy', {
          locale: this._locale,
        });
      }
    }
    return dateFns.format(this.parseDate(from), 'iiii dd LLLL yyyy', {
      locale: this._locale,
    });
  }

  getMidDateWithTime(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(this.parseDate(from), 'iii LLL dd yyyy - HH:mm', {
          locale: this._locale,
        });
      }
    }
    return dateFns.format(this.parseDate(from), 'iii dd LLL yyyy - HH:mm', {
      locale: this._locale,
    });
  }

  getShortNumericDate(from: string | Date) {
    if (!from) {
      return '';
    }
    switch (this._languageCode) {
      case 'en-us': {
        return dateFns.format(this.parseDate(from), 'LL/dd/yyyy', {
          locale: this._locale,
        });
      }
    }
    return dateFns.format(this.parseDate(from), 'dd/LL/yyyy', {
      locale: this._locale,
    });
  }

  getOnlyTime(from: string | Date) {
    if (!from) {
      return '';
    }
    return dateFns.format(this.parseDate(from), 'HH:mm', {
      locale: this._locale,
    });
  }

  /**
   * Takes an ISO8601 string or `Date` and formats to the
   * current locales date format in the current time zone.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
   */
  formatDate(
    from: string | Date,
    style: 'short' | 'medium' | 'long' | 'full' = 'short',
  ): string {
    return new Date(from).toLocaleDateString(this._languageCode, {
      // @ts-ignore
      dateStyle: style,
      timeZone: this._timeZone,
    });
  }

  /**
   * Takes an ISO8601 string or `Date` and formats to the
   * current locales date format in the UTC time zone.
   *
   * This is useful when wanting to show a DOB, for example,
   * where it should be the same date whatever the timezone.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
   */
  formatUTCDate(
    from: string | Date,
    style: 'short' | 'medium' | 'long' | 'full' = 'short',
  ): string {
    return new Date(from).toLocaleDateString(this._languageCode, {
      // @ts-ignore
      dateStyle: style,
      timeZone: 'UTC',
    });
  }

  /**
   * Takes an ISO8601 string or `Date` and formats to the
   * current locales time format in the current time zone.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
   */
  formatTime(
    from: string | Date,
    style: 'short' | 'medium' | 'long' | 'full' = 'short',
  ): string {
    return new Date(from).toLocaleTimeString(this._languageCode, {
      // @ts-ignore
      timeStyle: style,
      timeZone: this._timeZone,
    });
  }

  /**
   * Takes an ISO8601 string or `Date` and formats to the
   * current locales date and time format in the current time zone.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#using_timestyle_and_datestyle
   */
  formatDateTime(
    from: string | Date,
    dateStyle: 'short' | 'medium' | 'long' | 'full' = 'short',
    timeStyle: 'short' | 'medium' | 'long' | 'full' = 'short',
  ): string {
    return new Date(from).toLocaleString(this._languageCode, {
      // @ts-ignore
      dateStyle,
      timeStyle,
      timeZone: this._timeZone,
    });
  }

  /**
   * Returns a format suitable for matching against user input when using DOBs.
   *
   * For example, 30112000, 11302000, etc.
   */
  getShortDateDigitsOnly(from: string | Date) {
    return new Date(from)
      .toLocaleDateString(this._languageCode, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(/[^0-9]+/g, '');
  }

  getZonedToday(): Date {
    return dateFnsTz.toDate(
      dateFnsTz.formatInTimeZone(
        new Date(),
        this.getTimeZone(),
        'yyyy-MM-dd HH:mm:ss',
      ),
    );
  }

  toZonedDate(date: Date): Date {
    return dateFnsTz.utcToZonedTime(date, this.getTimeZone());
  }

  toUTCDate(date: Date): Date {
    return dateFnsTz.zonedTimeToUtc(date, this.getTimeZone());
  }
}
