<div class="list-container" fxLayout="column">
  <mat-toolbar>{{ text.AssignPackContent }}</mat-toolbar>
  <mat-toolbar
    class="packname"
    [class.highlight]="
      packDetail &&
      packDetail.length > 0 &&
      packDetail.length - lockedItems.length !== 0 &&
      !selectedPack.isDefault
    "
    fxLayout="row"
    fxLayoutAlign="space-between center"
    [attr.disabled]="selectedPack.isTransferring ? '' : null"
    [matTooltip]="
      packDetail &&
      packDetail.length > 0 &&
      packDetail.length - lockedItems.length !== 0 &&
      !selectedPack.isDefault
        ? text.AddSelectedItems
        : ''
    "
    (click)="assignPackToPatient(selectedPack)"
  >
    <div
      *ngIf="selectedPack?.isTransferring"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="2px"
    >
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <div>
      {{ selectedPack?.packName }}
    </div>
    <div
      *ngIf="
        packDetail &&
        packDetail.length > 0 &&
        packDetail.length - lockedItems.length !== 0 &&
        !selectedPack.isDefault
      "
    >
      <mat-icon>add</mat-icon>
    </div>
  </mat-toolbar>
  <div
    class="list empty"
    *ngIf="selectedPack?.empty"
    fxFlex="auto"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    {{ text.PackEmpty }}
  </div>
  <div
    class="list empty"
    *ngIf="
      !selectedPack?.empty &&
      selectedPack?.allAssigned &&
      !selectedPack?.isDefault
    "
    fxFlex="auto"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    {{ text.AllItemsAssigned }}
  </div>
  <div
    class="list empty"
    *ngIf="
      !selectedPack?.empty &&
      selectedPack?.allAssigned &&
      selectedPack?.isDefault
    "
    fxFlex="auto"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    {{ text.SendToAllIsAutomatic }}
  </div>
  <perfect-scrollbar
    *ngIf="packDetail && packDetail.length > 0"
    fxFlex="auto"
    [config]="config"
    [scrollIndicators]="true"
  >
    <div class="list">
      <div
        *ngFor="let detail of packDetail"
        class="list-item"
        [class.locked]="detail.locked"
        [attr.disabled]="
          detail.isTransferring || selectedPack.isTransferring ? '' : null
        "
        [class.add]="!detail.locked"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="15px"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div
              *ngIf="detail.isTransferring"
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="2px"
            >
              <mat-spinner diameter="20"></mat-spinner>
            </div>
            <mat-icon
              *ngIf="!detail.locked"
              portalStopClickPropagation
              (click)="toggleLocked(detail.contentEntryHeaderId, detail.locked)"
            >
              lock_open
            </mat-icon>
            <mat-icon
              *ngIf="detail.locked"
              portalStopClickPropagation
              (click)="toggleLocked(detail.contentEntryHeaderId, detail.locked)"
            >
              lock
            </mat-icon>
            <div>
              {{ detail.contentName }}
            </div>
          </div>
        </div>
        <mat-icon
          *ngIf="!detail.locked"
          [matTooltip]="text.AssignIndividualItem"
          portalStopClickPropagation
          (click)="handleAssignItemToPatient(detail)"
        >
          add
        </mat-icon>
      </div>
    </div>
  </perfect-scrollbar>
</div>
