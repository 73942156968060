import { TableCell } from '@material-ui/core';
import { StaffApiValue } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import Actions from '../actions';
import CognitoStatusChip from '../status';
import { StyledTableRow } from './index.styled';

interface Props {
  user: StaffApiValue;
  onClickInviteUser: (user: StaffApiValue) => void;
}

const UsersRow: React.FunctionComponent<Props> = ({
  user,
  onClickInviteUser,
}: Props) => {
  const localeDateTime = useLocaleDateTime();
  const { t } = useTranslations();

  return (
    <StyledTableRow
      key={user.id}
      data-testid="UserTableRow"
      role="button"
      tabIndex={0}
    >
      <TableCell>{user.firstname}</TableCell>
      <TableCell>{user.lastname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <CognitoStatusChip
          status={user.status}
          label={t.Admin.Users[`${user.status}Status`]}
        />
      </TableCell>
      <TableCell>
        {user.salvecreated &&
          localeDateTime.format(new Date(user.salvecreated), 'PP, p')}
      </TableCell>
      <TableCell>
        <Actions user={user} onClickInviteUser={onClickInviteUser} />
      </TableCell>
    </StyledTableRow>
  );
};

export default UsersRow;
