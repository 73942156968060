import styled from '@emotion/styled';
import { Tabs } from '@material-ui/core';
import SearchBar from '@react/components/SearchBar';

export const StyledTabs = styled(Tabs)`
  border-bottom: 2px;
  max-width: 400px;
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledActionsContainer = styled.div`
  border-top-color: ${({ theme }) => theme.colors.border.default};
  border-top-width: 1px;
  border-top-style: solid;
  padding: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  justify-content: end;
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;

export const StyledSearchBar = styled(SearchBar)`
  position: sticky;
  top: 0;
  z-index: 30;
  margin: ${({ theme }) => theme.spacing(1)}px;
`;
