import {
  addDays,
  addMonths,
  addWeeks,
  Duration,
  format,
  formatDuration,
  Locale,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';

interface FormatOptions {
  utc: boolean;
}

export class DateTime {
  private _locale: Locale;

  constructor(locale: Locale) {
    this._locale = locale;
  }

  /*
   * Typescript 3.1 will allow us to wrap the original functions without
   * declaring arguments again with formatDate(...args: Parameters<typeof formatDate>)
   */
  public format(
    date: Date | number,
    formatString: string,
    options?: FormatOptions,
  ): string {
    if (!options || !options.utc) {
      return format(this.parseDate(date), formatString, {
        locale: this._locale,
      });
    }

    return formatTz(utcToZonedTime(this.parseDate(date), 'UTC'), formatString, {
      timeZone: 'UTC',
      locale: this._locale,
    });
  }

  public formatDuration(date: Duration) {
    return formatDuration(date, {
      locale: this._locale,
    });
  }

  public addDays(date: Date | number, days: number): Date {
    return addDays(date, days);
  }

  public addWeeks(date: Date | number, weeks: number): Date {
    return addWeeks(date, weeks);
  }

  public addMonths(date: Date | number, months: number): Date {
    return addMonths(date, months);
  }

  public startOfWeek(date: Date | number): Date {
    return startOfWeek(date);
  }

  public startOfMonth(date: Date | number): Date {
    return startOfMonth(date);
  }

  private parseDate(date: Date | number) {
    return date instanceof Date ? date : new Date(date);
  }
}
