import { AsyncValidatorFn } from '@angular/forms';
import { of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  first,
  map,
  take,
} from 'rxjs/operators';
import { ContentService } from '../content.service';

export function validateFolderName(
  contentService: ContentService,
  loading$: Subject<boolean>,
  initialFolderName?: string,
): AsyncValidatorFn {
  return (control) => {
    if (initialFolderName != null && control.value === initialFolderName) {
      return of(null);
    }

    loading$.next(true);

    return contentService.checkCategoryName(control.value).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      take(1),
      map((names) => {
        if (names && names.length > 0) {
          return { nameExists: true };
        }

        return null;
      }),
      catchError(() => of({ serverNotConnected: true })),
      first(),
      finalize(() => {
        loading$.next(false);
      }),
    );
  };
}
