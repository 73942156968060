var ChangeMessageLabelRequest = /** @class */ (function () {
    function ChangeMessageLabelRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId, MessageTypeId = _a.MessageTypeId;
        this.Action = 'updatemessagetype';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
        this.MessageTypeId = MessageTypeId;
    }
    return ChangeMessageLabelRequest;
}());
export { ChangeMessageLabelRequest };
