import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'portal-content-list-controls',
  templateUrl: './content-list-controls.component.html',
  styleUrls: ['./content-list-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentListControlsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() text: any;
  @Input() contentType: string;
  @Input() categoryId: number;
  @Input() filter: string;
  @Input() filterActive: boolean;
  @Input() contentEditorEnabled: boolean;
  @Input() listType = 'content-list';
  @Output() setFilter = new EventEmitter<string>();
  @Output() addNew = new EventEmitter<number>();

  public searchForm: FormGroup;

  private _searchFormSub: Subscription;

  public options = {
    contentList: 'content-list',
    packList: 'pack-list',
  };

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this._fb.group({
      searchValue: [{ value: this.filter, disabled: false }],
    });

    this._searchFormSub = this.searchForm
      .get('searchValue')
      .valueChanges.pipe(debounceTime(300))
      .subscribe((val: string) => {
        this.setFilter.emit(val.toLowerCase());
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contentType) {
      if (
        changes.contentType.currentValue !== changes.contentType.previousValue
      ) {
        if (this.searchForm) {
          setTimeout(() => {
            this.clearFilter();
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this._searchFormSub) {
      this._searchFormSub.unsubscribe();
    }
  }

  clearFilter(): void {
    this.searchForm.patchValue({
      searchValue: '',
    });
  }
}
