import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import {
  EditContentPack,
  EditContentPackError,
} from '../../actions/packs.actions';
import { validateContentPackName } from '../../services/async-validators/validate-pack-name';
import { PacksService } from '../../services/packs.service';
import {
  ContentPackActionTypes,
  EditContentPackSuccess,
} from './../../actions/packs.actions';

@Component({
  selector: 'portal-edit-pack-name',
  templateUrl: './edit-pack-name.component.html',
  styleUrls: ['./edit-pack-name.component.scss'],
})
export class EditPackNameComponent implements OnInit, OnDestroy {
  public loading$ = new BehaviorSubject(false);

  public savingName$ = new BehaviorSubject(false);
  public savedName$ = new BehaviorSubject(false);
  public errorEncountered$ = new BehaviorSubject(false);

  public packName: FormControl;

  public formStatus = {
    INVALID: 'INVALID',
    PENDING: 'PENDING',
  };

  public _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates: Actions,
    public dialogRef: MatDialogRef<EditPackNameComponent>,
    public dialog: MatDialog,
    private _packService: PacksService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.packName = new FormControl(
      this.data.pack.packName,
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      [
        validateContentPackName(
          this._packService,
          this.loading$,
          this.data.pack.packName,
        ),
      ],
    );

    // watch for errors on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<EditContentPackError>(
            ContentPackActionTypes.EditContentPackError,
          ),
        )
        .subscribe(() => {
          this.savingName$.next(false);
          this.errorEncountered$.next(true);
          this.exitDialog(false, 1000);
        }),
    );

    // watch for success on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<EditContentPackSuccess>(
            ContentPackActionTypes.EditContentPackSuccess,
          ),
        )
        .subscribe(() => {
          this.savingName$.next(false);
          this.savedName$.next(true);
          this.exitDialog(true, 1000);
        }),
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  clearForm() {
    this.packName.setValue('');
  }

  savePackNameChange() {
    this.savingName$.next(true);
    const name = this.packName.value;
    this._store.dispatch(
      new EditContentPack({
        packId: this.data.pack.id,
        name,
      }),
    );
  }

  cancelNameChange() {
    this.exitDialog(false);
  }

  exitDialog(status: boolean = false, time: number = 0) {
    setTimeout(() => {
      this.dialogRef.close(status);
    }, time);
  }
}
