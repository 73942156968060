import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { ContentTemplate } from '../../models/content-template';
import { ApplyTemplate } from '../../models/content-template';

import { ContentEntryHeader } from '../../models/content-entry';
import { ParentPortal } from '../../models/global-portal';
import { GroupedContent } from '../../models/groupedContentEntries';
import {
  ChooseContentComponent,
  ChooseContentData,
  ChooseContentResult,
} from '../choose-content/choose-content.component';
import {
  ChooseGlobalContentComponent,
  ChooseGlobalContentData,
} from '../choose-global-content/choose-global-content.component';
import {
  ChooseGlobalFileContentComponent,
  ChooseGlobalFileContentData,
  ChooseGlobalFileContentResult,
} from '../choose-global-file-content/choose-global-file-content.component';
import { ChooseTemplateComponent } from '../choose-template/choose-template.component';

@Component({
  selector: 'portal-content-edit-title',
  templateUrl: './content-edit-title.component.html',
  styleUrls: ['./content-edit-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentEditTitleComponent implements OnInit {
  @Input() text: any;
  @Input() templateText: any;
  @Input() contentText: any;
  @Input() templates: ContentTemplate[];
  @Input() fileOnly: boolean;
  @Input() checkingTitle: boolean;
  @Input() fromDataSync: boolean;
  @Input() hasChildren: boolean;
  @Input() contentEditorEnabled: boolean;
  @Input() parentInfo: ContentEntryHeader;
  @Input() languageCode?: string;
  @Input() parentPortalId: ParentPortal['globalclinicid'] | null = null;
  @Input() isGlobalPortal: boolean;
  @Output() goToParent = new EventEmitter<number>();
  @Output() manageChildLink = new EventEmitter<number>();
  @Output() useTemplate = new EventEmitter<ApplyTemplate>();
  @Output() copyContent = new EventEmitter<number>();
  @Output() copyGlobalContent = new EventEmitter<number>();
  @Output() copyGlobalFileContent = new EventEmitter<number>();

  public titleForm: FormGroup;

  constructor(
    private _controlContainer: ControlContainer,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.titleForm = this._controlContainer.control as FormGroup;
    if (!this.contentEditorEnabled) {
      this.titleForm.disable();
    }
  }

  selectTemplate() {
    const dialogRef = this.dialog.open(ChooseTemplateComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      data: {
        templates: this.templates,
        text: this.templateText,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.useTemplate.emit({
          template: response.template,
          options: {
            method: response.method,
          },
        });
      }
    });
  }

  selectContent() {
    const dialogRef = this.dialog.open<
      ChooseContentComponent,
      ChooseContentData,
      ChooseContentResult
    >(ChooseContentComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      data: { languageCode: this.languageCode },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.copyContent.emit(response.id);
      }
    });
  }

  selectGlobalFileContent() {
    const dialogRef = this.dialog.open<
      ChooseGlobalFileContentComponent,
      ChooseGlobalFileContentData,
      ChooseGlobalFileContentResult
    >(ChooseGlobalFileContentComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      data: {
        globalPortalId: this.parentPortalId,
        languageCode: this.languageCode,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.copyGlobalFileContent.emit(response.id);
      }
    });
  }

  selectGlobalContent() {
    const dialogRef = this.dialog.open<
      ChooseGlobalContentComponent,
      ChooseGlobalContentData,
      ChooseContentResult
    >(ChooseGlobalContentComponent, {
      autoFocus: false,
      closeOnNavigation: true,
      data: {
        globalPortalId: this.parentPortalId,
        languageCode: this.languageCode,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.copyGlobalContent.emit(response.id);
      }
    });
  }

  clearTitle() {
    this.titleForm.patchValue({
      title: '',
    });
  }

  clearSubtitle() {
    this.titleForm.patchValue({
      subtitle: '',
    });
  }

  clearPatientTitle() {
    this.titleForm.patchValue({
      patientTitle: '',
    });
  }

  copyTitleFromEMR() {
    const title = this.titleForm.get('title').value;
    this.titleForm.patchValue({
      patientTitle: title,
    });
  }
}
