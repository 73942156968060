import { EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ContentTabsOption } from './../../reducers/content-assignment.reducer';
var AssignedPackListComponent = /** @class */ (function () {
    function AssignedPackListComponent() {
        this.removeItem = new EventEmitter();
        this.openAssignedPack = new EventEmitter();
        this.removePack = new EventEmitter();
        this.excludeItem = new EventEmitter();
        this.openPatientFolder = new EventEmitter();
        this.filterList = new EventEmitter();
        this.filterValue = new FormControl('');
        this.contentTabOptions = ContentTabsOption;
        this._subs = new Subscription();
    }
    AssignedPackListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this.filterValue.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function (filter) {
            _this.filterList.emit(filter);
        }));
    };
    AssignedPackListComponent.prototype.ngOnDestroy = function () {
        this.filterList.emit('');
        this._subs.unsubscribe();
    };
    AssignedPackListComponent.prototype.handleRemovePack = function (pack) {
        this.removePack.emit({
            pack: pack,
            patientId: this.patientId,
            packId: pack.packId,
            exclusions: [],
        });
    };
    AssignedPackListComponent.prototype.handleOpenPack = function (packId) {
        if (this.openPackId) {
            this.openAssignedPack.emit(null);
        }
        else {
            this.openAssignedPack.emit(packId);
        }
    };
    AssignedPackListComponent.prototype.addExclusion = function (packId, packItem) {
        var exclusionId = packItem.hiddenId || packItem.contentEntryHeaderId;
        this.excludeItem.emit({
            packId: packId,
            packDetail: [packItem],
            packItem: packItem,
            exclusions: [exclusionId],
            patientId: this.patientId,
        });
    };
    AssignedPackListComponent.prototype.removeIndividualItem = function (content) {
        if (!content.fromMeditex && !content.isTransferring) {
            this.removeItem.emit(content);
        }
    };
    AssignedPackListComponent.prototype.clearFilter = function () {
        this.filterValue.setValue('');
    };
    return AssignedPackListComponent;
}());
export { AssignedPackListComponent };
