import { Injectable } from '@angular/core';

interface Script {
  name: string;
  src: string;
  loaded: boolean;
}

@Injectable()
export class ScriptInjectorService {
  private scripts: Script[] = [];
  constructor() {}
  loadScript(name: string, src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      const existingScript = this.scripts.find((s) => s.name === name);
      if (existingScript) {
        if (existingScript.loaded) {
          resolve();
        } else {
          // If the script is in the process of loading, wait for it
          existingScript['promise']
            .then(() => resolve())
            .catch((error) => reject(error));
        }
        return;
      }
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = src;
      scriptElement.async = true;
      const script: Script = {
        name,
        src,
        loaded: false
      };
      scriptElement.onload = () => {
        script.loaded = true;
        resolve();
      };
      scriptElement.onerror = (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error
      ) => {
        console.error(error);
        reject(new Error(`Failed to load script: ${name}`));
      };
      document.body.appendChild(scriptElement);
      this.scripts.push(script);
    });
  }
}
