/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./participant.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../audio-video-indicators/audio-video-indicators.component.ngfactory";
import * as i3 from "../audio-video-indicators/audio-video-indicators.component";
import * as i4 from "@angular/common";
import * as i5 from "./participant.component";
import * as i6 from "../../services/meta-message.service";
import * as i7 from "../../services/video.chat.service";
var styles_ParticipantComponent = [i0.styles];
var RenderType_ParticipantComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ParticipantComponent, data: {} });
export { RenderType_ParticipantComponent as RenderType_ParticipantComponent };
function View_ParticipantComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["spinner", 1]], null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_ParticipantComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "video-placeholder-cover"]], null, null, null, null, null))], null, null); }
function View_ParticipantComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-audio-video-indicators", [["class", "audio-video-indicators"]], null, null, null, i2.View_AudioVideoIndicatorsComponent_0, i2.RenderType_AudioVideoIndicatorsComponent)), i1.ɵdid(1, 49152, null, 0, i3.AudioVideoIndicatorsComponent, [], { isAudioEnabled: [0, "isAudioEnabled"], isVideoEnabled: [1, "isVideoEnabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAudioEnabled; var currVal_1 = _co.isVideoEnabled; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ParticipantComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _videoPlaceholder: 0 }), i1.ɵqud(402653184, 2, { _audioPlaceholder: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "participant-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "video-placeholder-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["videoPlaceholder", 1]], null, 0, "div", [["class", "video-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [[2, 0], ["audioPlaceholder", 1]], null, 0, "div", [["class", "audio"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "participant-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isWaiting; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.isVideoEnabled; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.isWaiting; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.name; _ck(_v, 13, 0, currVal_3); }); }
export function View_ParticipantComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-participant", [], null, null, null, View_ParticipantComponent_0, RenderType_ParticipantComponent)), i1.ɵdid(1, 245760, null, 0, i5.ParticipantComponent, [i6.MetaMessageService, i7.VideoChatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParticipantComponentNgFactory = i1.ɵccf("portal-participant", i5.ParticipantComponent, View_ParticipantComponent_Host_0, { localIdentity: "localIdentity", localName: "localName", participant: "participant", audioOutputDeviceId: "audioOutputDeviceId" }, {}, []);
export { ParticipantComponentNgFactory as ParticipantComponentNgFactory };
