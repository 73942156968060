import { ClinicUserFilter } from '@react/components/ClinicUserFilter';
import { useTranslations } from '@react/lib/i18n';
import { useFilterByFullName } from '@react/pages/conversations/hooks';
import {
  ClinicUser,
  Conversation,
  OnClickClinicUser,
  UnAssignableClinicUser,
} from '@react/types';
import React from 'react';

interface Props {
  onClick: OnClickClinicUser;
  clinicUsers: ClinicUser[];
  me: ClinicUser;
  conversation: Conversation;
  unassign: boolean;
  loading: boolean;
}

export const ClinicUserMenu: React.FC<Props> = ({
  onClick,
  clinicUsers,
  me,
  conversation,
  unassign = false,
  loading,
}) => {
  const { t } = useTranslations();

  const [filteredUsers, searchState, setSearchState] =
    useFilterByFullName(clinicUsers);

  const defaultOption: ClinicUser = {
    ...me,
    userfullname: t.Messages.Me,
  };

  const unassignOption: UnAssignableClinicUser = {
    id: conversation.assignedclinicuserid
      ? conversation.assignedclinicuserid
      : null,
    userfullname: t.Messages.Unassign,
  };

  return (
    <ClinicUserFilter
      id={'ClinicUserFilter'}
      onClick={onClick}
      onSearchUpdate={setSearchState}
      defaultOption={defaultOption}
      unassignOption={unassign ? unassignOption : undefined}
      clinicUsers={filteredUsers}
      searchState={searchState}
      messageid={conversation.messageid}
      loading={loading}
    />
  );
};
