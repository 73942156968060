import { MessagesState } from '.';
import { Message } from '../../models/Message';

export const getMessageFromState = (
  state: MessagesState,
  messageId: number,
) => {
  return state.messageList.find((m) => m.MessageId === messageId);
};

export const replaceMessageInState = (
  state: MessagesState,
  oldMessage: Message,
  newMessage: Message,
) => {
  const messageList = [...state.messageList];
  if (oldMessage) {
    const messageIndex = state.messageList.indexOf(oldMessage);
    messageList.splice(messageIndex, 1, newMessage);
  }
  return messageList;
};

export const removeMessageFromState = (
  state: MessagesState,
  messageId: number,
) => {
  return state.messageList.filter((m) => m.MessageId !== messageId);
};

export const mergeMessageArrays = (
  existingList: Message[],
  newItems: Message[],
) => {
  const existingMessageIds = existingList.map((l) => l.MessageId);
  const newMessages = newItems.filter(
    (m) => existingMessageIds.indexOf(m.MessageId) === -1,
  );
  return [...existingList, ...newMessages];
};
