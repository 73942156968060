import styled from '@emotion/styled';
import React from 'react';

const StyledTabPanel = styled.div`
  height: 100%;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  id?: string;
}

export const TabPanel: React.FunctionComponent<TabPanelProps> = ({
  children,
  value,
  index,
  id = '',
}: TabPanelProps) => {
  return (
    <StyledTabPanel
      id={`tabpanel-${index}_${id}`}
      role="tabpanel"
      hidden={value !== index}
    >
      {value === index && children}
    </StyledTabPanel>
  );
};
