import styled from '@emotion/styled';
import { MicOff, VideocamOff } from '@material-ui/icons';

export const SelfVideoFrame = styled('div')`
  width: 262px;
  height: 150px;
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 8px;
  border: 3px solid #1f2124;
`;
export const SelfVideoIconsContainer = styled('div')`
  position: absolute;
  top: 12px;
  left: 12px;
  height: 30px;
  display: flex;
  flex-direction: row;
`;
export const SelfMicIconContainer = styled('div')<{ cameraOn: boolean }>`
  background-color: ${(props) => (props.cameraOn ? '#1f2124' : '#000000')};
  margin-right: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SelfVideoIconContainer = styled('div')`
  background-color: #000000;
  margin-right: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SelfMicOffIcon = styled(MicOff)`
  color: #ffffff;
`;
export const SelfVideocamOffIcon = styled(VideocamOff)`
  color: #ffffff;
`;
