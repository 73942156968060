import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This code has been taken and adapted from https://github.com/flauc/ng-push
 * as it's now dead and relies on Angular 6.
 */

// TODO: Remove this when our TypeScript version is updated
// Taken from: https://github.com/microsoft/TypeScript/blob/8362a0f/lib/lib.dom.d.ts#L930
export interface PushNotificationOptions extends NotificationOptions {
  requireInteraction?: boolean;
  silent?: boolean;
}

// TODO: Remove this when our TypeScript version is updated
// Taken from : https://github.com/microsoft/TypeScript/blob/8362a0f9/lib/lib.dom.d.ts#L10750
declare class PushNotification extends Notification {
  readonly permission: NotificationPermission;
}

@Injectable()
export class PushNotificationsService {
  permission: NotificationPermission;

  constructor() {
    this.permission = this.isSupported()
      ? (Notification as any as PushNotification).permission
      : 'denied';
  }

  isSupported() {
    return 'Notification' in window;
  }

  requestPermission() {
    if ('Notification' in window) {
      // TODO: The callback has been deprecated and is now a promise
      // https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission#syntax
      Notification.requestPermission((status) => (this.permission = status));
    }
  }

  create(title: string, options?: PushNotificationOptions) {
    return new Observable<{ notification: Notification; event: Event }>(
      (obs) => {
        if (!('Notification' in window)) {
          obs.error('Notifications are not available in this environment');
          obs.complete();
        }

        if (this.permission !== 'granted') {
          obs.error(
            `The user hasn't granted you permission to send push notifications`,
          );
          obs.complete();
        }

        const n = new Notification(title, options);

        n.onshow = (e) => obs.next({ notification: n, event: e });
        n.onclick = (e) => obs.next({ notification: n, event: e });
        n.onerror = (e) => obs.error({ notification: n, event: e });
        n.onclose = () => obs.complete();
      },
    );
  }
}

@NgModule({
  providers: [PushNotificationsService],
})
export class PushNotificationsModule {}
