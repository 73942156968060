import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit, } from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import get from 'lodash/get';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import { ProfilePhotoModalReactWrapperComponent } from '../../containers/profile-photo-modal/profile-photo-modal-react-wrapper.component';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(_dialog, _store) {
        this._dialog = _dialog;
        this._store = _store;
        this.logout = new EventEmitter();
        this._subs = new Subscription();
        this._clinicOptions$ = this._store.pipe(select(fromAuth.getClinicOptions));
    }
    UserProfileComponent.prototype.handleLogout = function () {
        this.logout.emit(this.isCognito);
    };
    UserProfileComponent.prototype.handleOpenProfilePhotoDialog = function () {
        if (!this._profilePhotoEnabled) {
            return;
        }
        var dialogRef;
        var dialogCommonOptions = {
            autoFocus: false,
            closeOnNavigation: true,
            disableClose: true,
        };
        dialogRef = this._dialog.open(ProfilePhotoModalReactWrapperComponent, tslib_1.__assign({ panelClass: 'reduced-padding-dialog', data: {
                close: this._dialog.closeAll.bind(this._dialog),
            } }, dialogCommonOptions));
        dialogRef.afterClosed().subscribe(function () { });
    };
    UserProfileComponent.prototype.isProfilePhotoEnabled = function () {
        return this._profilePhotoEnabled;
    };
    UserProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this._clinicOptions$.subscribe(function (opts) {
            _this._profilePhotoEnabled = get(opts, 'profilePhotoOptions.enabled');
        }));
    };
    UserProfileComponent.prototype.ngAfterViewInit = function () { };
    return UserProfileComponent;
}());
export { UserProfileComponent };
