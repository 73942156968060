import { Dialog, DialogProps } from '@react/components';
import { useTranslations } from '@react/lib/i18n';
import React, { FunctionComponent } from 'react';

export type DiscardConversationPromptProps = Partial<DialogProps> & {
  isOpen: DialogProps['isOpen'];
  onDiscard: () => void;
  onCancel: () => void;
};

export const DiscardConversationPrompt: FunctionComponent<DiscardConversationPromptProps> =
  ({ isOpen, onDiscard, onCancel }: DiscardConversationPromptProps) => {
    const { t } = useTranslations();

    return (
      <Dialog
        isOpen={isOpen}
        titleText={t.NewMessage.DiscardTitle}
        contentText={t.NewMessage.DiscardDescription}
        acceptButtonText={t.NewMessage.Confirm}
        cancelButtonText={t.NewMessage.Cancel}
        onAccept={onDiscard}
        onCancel={onCancel}
        maxWidth="xs"
        fullWidth
      />
    );
  };
