import { useApiClient } from '@react/lib/api';
import {
  POLLING_INTERVAL_MS,
  queryCacheKey,
} from '@react/pages/conversations/constants';
import { useQuery } from 'react-query';
import { IDataExportHookResult, ISIDEExportHookParams } from '../types';
import { createExportDownloadHandler } from './utils';

export function useSIDERecipesQuery() {
  const apiClient = useApiClient().portal;
  return useQuery(
    queryCacheKey.SIDE_RECIPES_LIST,
    () =>
      apiClient.fetchSIDERecipes({
        ignorePaging: true,
      }),
    {
      refetchInterval: POLLING_INTERVAL_MS,
    },
  );
}

export function useSIDEExportDataByRecipe({
  sideRecipeId,
  from,
  to,
  onDownloadSuccess,
  onDownloadFailure,
}: ISIDEExportHookParams): IDataExportHookResult {
  const apiClient = useApiClient().portal;

  const request = async () =>
    await apiClient.exportSIDEData(sideRecipeId, from, to);

  return createExportDownloadHandler(
    request,
    onDownloadSuccess,
    onDownloadFailure,
  );
}
