import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import { ChangeLanguage } from '../../actions/settings.actions';
import * as fromSettings from '../../reducers';

@Component({
  selector: 'portal-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  // Observables
  public settingSections$: Observable<string[]>;
  public languages$: Observable<string[]>;
  public activeLanguage$: Observable<string>;

  // Variables
  public cardTitle: string;

  // Component Subscriptions
  private _subs = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<fromRoot.State>,
  ) {
    this.settingSections$ = this._store.pipe(
      select(fromSettings.getSectionsList),
    );
    this.languages$ = this._store.pipe(
      select(fromSettings.getAvailableLanguages),
    );
    this.activeLanguage$ = this._store.pipe(
      select(fromSettings.getCurrentLanguage),
    );
  }

  ngOnInit() {
    this._subs.add(
      this._route.params.subscribe((params: Params) => {
        this.cardTitle = params.section;
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  changePortalLanguage(language: string) {
    this._store.dispatch(new ChangeLanguage(language));
  }
}
