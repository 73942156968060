import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as AuthActions from '../actions/auth.actions';
import { environment } from './../../../environments/environment';
var AuthService = /** @class */ (function () {
    function AuthService(_store, _errorHandler, _httpService) {
        this._store = _store;
        this._errorHandler = _errorHandler;
        this._httpService = _httpService;
        this._store.dispatch(new AuthActions.GetClinicId());
    }
    AuthService.prototype.getClinicId = function (hostname) {
        if (hostname === void 0) { hostname = window.location.hostname; }
        if (environment.environment === 'local' ||
            (environment.environment === 'test' && hostname === 'localhost')) {
            hostname = "testsalveclinic." + hostname;
            // hostname = `ccrmtest.${hostname}`;
            // hostname = `testsalvecore.${hostname}`;
            // hostname = `testsalvegermany.${hostname}`;
            // hostname = 'manchesterfertility.salvehealth.co';
            // hostname = 'springtest.salvehealthtest.net'
            // hostname = 'crghsyncv2test.${hostname}';
            // hostname = 'crgh.${hostname}';
            // hostname = `mathe-mellowood-consent.${hostname}`;
            // hostname = `vreprotest.${hostname}`;
        }
        var postParams = {
            Action: 'getclinicbysubdomain',
            Input: hostname
        };
        return this._httpService
            .performPublicPostRequest(postParams, '/clinicbysubdomain')
            .pipe(catchError(function (err) {
            return throwError(err);
        }));
    };
    AuthService.prototype.cognitoLogout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, Auth.signOut()];
            });
        });
    };
    AuthService.prototype.cognitoLogin = function (primaryLoginInfo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cognitoUser, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Auth.signIn(primaryLoginInfo)];
                    case 1:
                        cognitoUser = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, {
                                message: "Error Signing in cognito user",
                                originalError: e_1
                            }];
                    case 3: return [2 /*return*/, cognitoUser];
                }
            });
        });
    };
    AuthService.prototype.verifyOTP = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cognitoUser, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Auth.sendCustomChallengeAnswer(params.user, params.code)];
                    case 1:
                        cognitoUser = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        return [2 /*return*/, {
                                message: "Error Verify OTP",
                                originalError: e_2
                            }];
                    case 3: return [2 /*return*/, cognitoUser];
                }
            });
        });
    };
    AuthService.prototype.cognitoCompletePassword = function (submissionData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, password, requiredAttributes, cognitoUser, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = submissionData.user, password = submissionData.password, requiredAttributes = submissionData.requiredAttributes;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Auth.completeNewPassword(user, password, requiredAttributes)];
                    case 2:
                        cognitoUser = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        return [2 /*return*/, {
                                message: "Error Completing New Password",
                                originalError: e_3
                            }];
                    case 4: return [2 /*return*/, cognitoUser];
                }
            });
        });
    };
    AuthService.prototype.cognitoResetPassword = function (submissionData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, code, new_password, res, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = submissionData.email, code = submissionData.code, new_password = submissionData.new_password;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Auth.forgotPasswordSubmit(email, code, new_password)];
                    case 2:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        throw new Error("Error Completing New Password");
                    case 4: return [2 /*return*/, res];
                }
            });
        });
    };
    AuthService.prototype.cognitoGenerateResetCode = function (submissionData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, res, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = submissionData.email;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Auth.forgotPassword(email)];
                    case 2:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_5 = _a.sent();
                        console.error(e_5);
                        throw new Error("Error Generating Code");
                    case 4: return [2 /*return*/, res];
                }
            });
        });
    };
    return AuthService;
}());
export { AuthService };
