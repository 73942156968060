import * as tslib_1 from "tslib";
var ContentEntrySection = /** @class */ (function () {
    function ContentEntrySection() {
    }
    return ContentEntrySection;
}());
export { ContentEntrySection };
var ContentAttachment = /** @class */ (function () {
    function ContentAttachment() {
    }
    return ContentAttachment;
}());
export { ContentAttachment };
var ContentEntryHeader = /** @class */ (function () {
    function ContentEntryHeader() {
    }
    return ContentEntryHeader;
}());
export { ContentEntryHeader };
var ContentEntry = /** @class */ (function (_super) {
    tslib_1.__extends(ContentEntry, _super);
    function ContentEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContentEntry.createNew = function (contentCategory, contentCategoryId, isFileOnly, parentId) {
        var contentEntry = new ContentEntry();
        contentEntry.attachments = [];
        contentEntry.comments = '';
        contentEntry.contentcategory = contentCategory;
        contentEntry.contentcategoryid = contentCategoryId;
        contentEntry.fromdatasync = false;
        contentEntry.isactive = true;
        contentEntry.isempty = true;
        contentEntry.isfileonly = isFileOnly;
        contentEntry.ishidden = false;
        contentEntry.name = '';
        contentEntry.sendtoall = false;
        contentEntry.sections = [
            {
                ordernumber: 1,
                isrequired: true,
                title: 'Title',
                type: 'title',
                content: '',
            },
            {
                ordernumber: 2,
                isrequired: true,
                title: 'Subtitle',
                type: 'subtitle',
                content: '',
            },
            {
                ordernumber: 3,
                isrequired: true,
                title: 'Introduction',
                type: 'introduction',
                content: '',
            },
            {
                ordernumber: 4,
                isrequired: true,
                title: 'Video',
                type: 'video',
                content: '',
            },
        ];
        contentEntry.wasmigrated = false;
        if (parentId) {
            contentEntry.parentid = parentId;
        }
        return contentEntry;
    };
    ContentEntry.createCopy = function (contentCategory, contentCategoryId, isFileOnly, parentId, name) {
        var contentEntry = new ContentEntry();
        contentEntry.attachments = [];
        contentEntry.comments = '';
        contentEntry.contentcategory = contentCategory;
        contentEntry.contentcategoryid = contentCategoryId;
        contentEntry.fromdatasync = false;
        contentEntry.isactive = true;
        contentEntry.isempty = true;
        contentEntry.isfileonly = isFileOnly;
        contentEntry.ishidden = false;
        contentEntry.name = name;
        contentEntry.sendtoall = false;
        contentEntry.sections = [
            {
                ordernumber: 1,
                isrequired: true,
                title: 'Title',
                type: 'title',
                content: '',
            },
            {
                ordernumber: 2,
                isrequired: true,
                title: 'Subtitle',
                type: 'subtitle',
                content: '',
            },
            {
                ordernumber: 3,
                isrequired: true,
                title: 'Introduction',
                type: 'introduction',
                content: '',
            },
            {
                ordernumber: 4,
                isrequired: true,
                title: 'Video',
                type: 'video',
                content: '',
            },
        ];
        contentEntry.wasmigrated = false;
        if (parentId) {
            contentEntry.parentid = parentId;
        }
        return contentEntry;
    };
    return ContentEntry;
}(ContentEntryHeader));
export { ContentEntry };
var AssignedContentEntry = /** @class */ (function (_super) {
    tslib_1.__extends(AssignedContentEntry, _super);
    function AssignedContentEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AssignedContentEntry;
}(ContentEntry));
export { AssignedContentEntry };
var TableContentEntry = /** @class */ (function () {
    function TableContentEntry() {
    }
    return TableContentEntry;
}());
export { TableContentEntry };
var NewContentEntrySection = /** @class */ (function () {
    function NewContentEntrySection(_a) {
        var ordernumber = _a.ordernumber, _b = _a.content, content = _b === void 0 ? '' : _b, _c = _a.type, type = _c === void 0 ? 'mixed' : _c, _d = _a.title, title = _d === void 0 ? '' : _d, _e = _a.isrequired, isrequired = _e === void 0 ? false : _e;
        this.ordernumber = ordernumber;
        this.isrequired = isrequired;
        this.type = type;
        this.title = title;
        this.content = content;
    }
    return NewContentEntrySection;
}());
export { NewContentEntrySection };
