import * as React from 'react';
import { createContext, FunctionComponent, useContext } from 'react';
import { TranslationData } from 'src/app/settings/data/settings';
import { SupportedLanguage } from 'src/app/settings/data/supported-languages';
import { translationEnGB } from '../../../settings/data/lang.en.gb';

interface LanguageContextPayload {
  language: SupportedLanguage;
  translations: TranslationData;
}

export const LanguageContext = createContext<LanguageContextPayload>({
  language: 'en-gb',
  translations: translationEnGB,
});

export const LanguageProvider: FunctionComponent<LanguageContextPayload> = ({
  children,
  language,
  translations,
}) => {
  return (
    <LanguageContext.Provider
      value={{ language, translations }}
      children={children}
    />
  );
};

export function useTranslations() {
  const { translations, language } = useContext(LanguageContext);
  return { t: translations, language };
}
