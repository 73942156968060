<mat-dialog-content fxLayout="column" fxLayoutAlign="space-between stretch">
  <form
    class="document-form"
    novalidate
    [formGroup]="documentForm"
    fxLayout="column"
    fxFlex="grow"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px"
  >
    <!-- Document Name Section -->
    <div
      *ngIf="data.patientOnly"
      class="document-form-group"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <h3 class="document-form-group-title">
        {{ (sectionText$ | async)?.FirstInstruction }}
      </h3>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-form-field appearance="outline" class="new-file-name-input">
          <mat-label>{{ (sectionText$ | async)?.DocumentTitle }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="name"
            placeholder="{{ (sectionText$ | async)?.AddDocTitle }}"
          />
          <div
            *ngIf="!fromDataSync"
            matSuffix
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="15px"
          >
            <mat-spinner *ngIf="loading$ | async" diameter="20"></mat-spinner>
            <button
              *ngIf="documentForm.get('name').value.length > 0"
              mat-button
              mat-icon-button
              (click)="clearName()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <mat-error *ngIf="documentForm.get('name').hasError('required')"
            >{{ (sectionText$ | async)?.NameRequired }}
          </mat-error>
          <mat-error
            *ngIf="
              documentForm.get('name').hasError('minlength') ||
              documentForm.get('name').hasError('maxlength')
            "
            >{{ (sectionText$ | async)?.NameLength }}</mat-error
          >
          <mat-error *ngIf="documentForm.get('name').hasError('nameExists')"
            >{{ (sectionText$ | async)?.NameUnique }}
          </mat-error>
          <mat-error *ngIf="documentForm.get('name').hasError('pattern')"
            >{{ (sectionText$ | async)?.NamePattern }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- File Upload Section -->
    <div
      class="document-form-group"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <h3
        *ngIf="data.type !== 'image' && data.type !== 'video'"
        class="document-form-group-title"
      >
        {{ (sectionText$ | async)?.SecondInstruction }}
      </h3>
      <h3 *ngIf="data.type === 'video'" class="document-form-group-title">
        {{ (sectionText$ | async)?.AttachVideo }}
      </h3>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="5px"
      >
        <label class="custom-file-upload">
          <input
            #docFileInput
            class="new-category-title"
            type="file"
            accept="{{ data.accept }}"
            formControlName="file"
          />
          {{ labelText$ | async }}
        </label>
        <span class="chosen-file">{{ filename$ | async }}</span>
        <div *ngIf="!!selectedFile">
          <button
            mat-icon-button
            (click)="clearFile()"
            class="sub-control clear"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <button
          *ngIf="data.type !== 'image'"
          mat-raised-button
          class="portal-btn btn-primary"
          [disabled]="
            documentForm.get('file').invalid ||
            documentForm.get('name').invalid ||
            documentForm.get('name').pending
          "
          (click)="uploadFile()"
        >
          {{ buttonText$ | async }}
        </button>
      </div>
      <div
        *ngIf="
          documentForm.get('file').hasError('required') &&
          documentForm.get('file').dirty
        "
      >
        {{ (sectionText$ | async)?.FileRequired }}
      </div>
      <div class="error-container">
        <div
          fxLayoutAlign="end center"
          class="fix-unique-error"
          *ngIf="
            documentForm.get('name').invalid &&
            documentForm.get('name').touched &&
            documentForm.get('name').dirty
          "
        >
          {{ (sectionText$ | async)?.FixUnique }}
        </div>
      </div>
    </div>

    <!-- Image Upload section -->
    <div *ngIf="data.type === 'image'"></div>
  </form>
</mat-dialog-content>
<mat-dialog-actions
  class="dialog-controls"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button mat-raised-button class="portal-btn btn-primary" (click)="cancel()">
    {{ (sectionText$ | async)?.Cancel }}
  </button>
  <div>
    <div
      *ngIf="uploading$ | async"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
    >
      <mat-spinner diameter="24"></mat-spinner>
      <div>
        {{ (sectionText$ | async)?.Uploading }}
      </div>
    </div>
    <div *ngIf="uploadSuccess$ | async">
      {{ (sectionText$ | async)?.UploadSuccessful }}
    </div>
    <div *ngIf="uploadError$ | async">
      {{ (sectionText$ | async)?.ErrorUploading }}
    </div>
  </div>
  <button
    *ngIf="data.type !== 'image'"
    mat-raised-button
    class="portal-btn btn-bright"
    [disabled]="
      documentForm.invalid ||
      (uploadError$ | async) ||
      !(uploadSuccess$ | async)
    "
    (click)="submit()"
  >
    {{ (sectionText$ | async)?.Submit }}
  </button>
</mat-dialog-actions>
