import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'portal-folder-info',
  templateUrl: './folder-info.component.html',
  styleUrls: ['./folder-info.component.scss'],
})
export class FolderInfoComponent {
  @Input() name: string;
  @Input() messageTypeId: number;
  @Input() count: number;
  @Input() text: any;
  @Output() goto = new EventEmitter<string>();
  @Output() goToMessages = new EventEmitter<number>();
}
