/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./message-editor.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/congito.wrapper.service";
var styles_MessageEditorComponent = [];
var RenderType_MessageEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageEditorComponent, data: {} });
export { RenderType_MessageEditorComponent as RenderType_MessageEditorComponent };
export function View_MessageEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["portalMessageEditorReactContainer", 1]], null, 0, "div", [["style", "height: 100%"]], null, null, null, null, null))], null, null); }
export function View_MessageEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-message-editor", [], null, null, null, View_MessageEditorComponent_0, RenderType_MessageEditorComponent)), i0.ɵdid(1, 4898816, null, 0, i1.MessageEditorComponent, [i2.Store, i3.CognitoWrapperService], null, null)], null, null); }
var MessageEditorComponentNgFactory = i0.ɵccf("portal-message-editor", i1.MessageEditorComponent, View_MessageEditorComponent_Host_0, { actionText: "actionText", isActionActive: "isActionActive", maxLength: "maxLength", patientId: "patientId", value: "value", warningText: "warningText" }, { actionClick: "actionClick", valueChange: "valueChange" }, []);
export { MessageEditorComponentNgFactory as MessageEditorComponentNgFactory };
