import * as tslib_1 from "tslib";
import { useQuery } from 'react-query';
import { useApiClient } from '../context';
var FIVE_MINS = 5 * 60 * 1000;
export var useContent = function (_a) {
    var _b = _a === void 0 ? {} : _a, packId = _b.packId, onPacksError = _b.onPacksError, onCategoriesError = _b.onCategoriesError, onCategoryEntriesError = _b.onCategoryEntriesError, onPackDetailError = _b.onPackDetailError;
    var apiClient = useApiClient().content;
    var queryOpts = {
        staleTime: FIVE_MINS,
        retry: false,
    };
    var packs = useQuery(['contentPacks'], function () { return apiClient.fetchPacks(); }, tslib_1.__assign({}, queryOpts, { onError: onPacksError }));
    var packDetails = useQuery(['contentPacks', packId], function () { return apiClient.fetchPacks(packId); }, { enabled: !!packId, onError: onPackDetailError });
    var categories = useQuery(['contentCategories'], function () { return apiClient.fetchCategories(); }, tslib_1.__assign({}, queryOpts, { onError: onCategoriesError }));
    var categoryEntries = useQuery(['contentCategoriesEntries'], function () { return apiClient.fetchCategoryEntries(); }, tslib_1.__assign({}, queryOpts, { onError: onCategoryEntriesError }));
    return {
        packs: packs,
        categories: categories,
        packDetails: packDetails,
        categoryEntries: categoryEntries,
    };
};
