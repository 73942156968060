import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Section } from '../../models/Section';

@Component({
  selector: 'portal-patient-sections-list',
  templateUrl: './patient-sections-list.component.html',
  styleUrls: ['./patient-sections-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientSectionsListComponent {
  @Input() text: any;
  @Input() sections: Section[];
  @Input() activeSection: string;
  @Output() changeSection = new EventEmitter();
}
