import * as tslib_1 from "tslib";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { List, ListItem, ListItemText } from '@material-ui/core';
import SearchBar from './SearchBar';
export var StyledWrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  width: 240px;\n"], ["\n  width: 240px;\n"])));
export var StyledList = styled(List)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  max-height: 200px;\n  width: 240px;\n  overflow-y: auto;\n"], ["\n  max-height: 200px;\n  width: 240px;\n  overflow-y: auto;\n"])));
export var StyledListItem = styled(ListItem)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  padding-top: 8px;\n  padding-bottom: 8px;\n  cursor: pointer;\n  display: flex;\n  gap: ", "px;\n\n  &.selected {\n    color: ", ";\n  }\n\n  .MuiListItemText-root {\n    flex: none;\n  }\n"], ["\n  padding-top: 8px;\n  padding-bottom: 8px;\n  cursor: pointer;\n  display: flex;\n  gap: ", "px;\n\n  &.selected {\n    color: ", ";\n  }\n\n  .MuiListItemText-root {\n    flex: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledSearchBar = styled(SearchBar)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n  height: 32px;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n  height: 32px;\n"])));
var boldStyle = function () { return css(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"]))); };
var ɵ0 = boldStyle;
export var StyledListItemText = styled(ListItemText)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  span {\n    ", "\n  }\n"], ["\n  span {\n    ", "\n  }\n"])), function (_a) {
    var weight = _a.weight;
    return weight === 'bold' && boldStyle;
});
export var LoadingWrapper = styled.div(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  padding: 20px 0;\n"], ["\n  padding: 20px 0;\n"])));
export { ɵ0 };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
