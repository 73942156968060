import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ThemedGrid, ThemedImg, ThemedSlider } from './PhotoPicker.styled';
import { Placeholder } from './Placeholder';
var size = 200;
export var PhotoPicker = function (_a) {
    var setEditor = _a.setEditor, image = _a.image, isEditable = _a.isEditable, onHasChanged = _a.onHasChanged, onSelectClicked = _a.onSelectClicked;
    var _b = useState(1), scale = _b[0], setScale = _b[1];
    var debouncedSetHasChanged = useMemo(function () { return debounce(onHasChanged, 500); }, [onHasChanged]);
    var handleScaleChanged = function (newValue) {
        setScale(newValue);
        debouncedSetHasChanged(true);
    };
    return (React.createElement(React.Fragment, null,
        !image && React.createElement(Placeholder, { onSelectClicked: onSelectClicked }),
        image && isEditable && (React.createElement("div", { "aria-label": "avatarEditor", role: "dialog" },
            React.createElement(AvatarEditor, { borderRadius: size / 2, image: image, height: size, width: size, onPositionChange: function () { return debouncedSetHasChanged(true); }, ref: setEditor, scale: scale }),
            React.createElement(ThemedSlider, { "aria-labelledby": "slider", max: 6, min: 1, onChange: function (e, newValue) { return handleScaleChanged(Number(newValue)); }, step: 0.1, value: scale }))),
        image && !isEditable && (React.createElement(ThemedGrid, { container: true, justifyContent: "center", onClick: onSelectClicked },
            React.createElement(ThemedImg, { src: image })))));
};
