export const environment = {
  production: true,
  environment: 'test',
  dashboardRefreshIntervalSeconds: 60,
  portalAppBaseUrl: 'https://portal.salvehealth.com',
  portalDomain: 'salvehealthtest.net',
  medicationManagerUrl:
    'https://d1aad8udkfqai3.cloudfront.net/salve-medication-template-picker.js',
  api: {
    resolve: {
      endpoint: 'https://public.salvehealthtest.net/resolve'
    },
    public: {
      endpoint: 'https://public.salvehealthtest.net/public'
    },
    content: {
      endpoint: 'https://content.salvehealthtest.net/'
    },
    messagingWs: {
      endpoint: 'wss://uzwmjb1sr9.execute-api.eu-west-1.amazonaws.com/Prod'
    },
    portal: {
      endpoint: 'https://portal.salvehealthtest.net/v1/'
    },
    portalPublic: {
      endpoint: 'https://portal.salvehealthtest.net/public/v1/'
    },
    s3Proxy: {
      endpoint: 'https://s3presign.salvehealthtest.net/'
    },
    status: {
      endpoint: 'https://clinicsynchealth.salvehealthtest.net/status'
    }
  },
  auth: {
    identityPoolId: 'eu-west-1:545a995b-b5c6-41c8-bb08-21bf091263c4',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_dI6HATA1H',
    userPoolWebClientId: '7mdsh93kp57iacjfm2mi5i3gb2',
    authenticationFlowType: 'CUSTOM_AUTH'
  },
  features: {
    payment: false,
    videoCalls: true,
    assignClinicUser: false
  }
};
