import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule, MatTabsModule } from '@angular/material';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreModule } from '../core/core.module';
import { LocalisationModule } from '../localisation/localisation.module';
import { MaterialModule } from '../material/material.module';
import { TwilioModule } from '../twilio/twilio.module';
import { ApplyFilterComponent } from './components/apply-filter/apply-filter.component';
import { VideoCallsListComponent } from './components/video-calls-list/video-calls-list.component';
import { VideoCallModalContainer } from './containers/video-call-modal/video-call-modal.container';
import { VideoCallsPageComponent } from './containers/video-calls-page/video-calls-page.container';
import { VideoCallEffects } from './effects/video-call.effects';
import { JoinDisabledPipe, StatusToTextPipe } from './pipes/call.status.pipe';
import * as fromVideoCalls from './reducers';
import { PermissionsService } from './services/permissions-service';
import { VideoCallsService } from './services/video-calls.service';

const COMPONENTS = [
  VideoCallsPageComponent,
  VideoCallsListComponent,
  VideoCallModalContainer,
  ApplyFilterComponent,
];

const PIPES = [StatusToTextPipe, JoinDisabledPipe];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  imports: [
    StoreModule.forFeature(
      fromVideoCalls.featureStateName,
      fromVideoCalls.reducer,
    ),
    EffectsModule.forFeature([VideoCallEffects]),
    CommonModule,
    CoreModule,
    FlexLayoutModule,
    OverlayModule,
    MaterialModule,
    MatTabsModule,
    MatChipsModule,
    ReactiveFormsModule,
    TwilioModule.forRoot(),
    LocalisationModule.forRoot(),
  ],
  providers: [PermissionsService, VideoCallsService],
  exports: [...COMPONENTS, ...PIPES],
  entryComponents: [VideoCallModalContainer],
})
export class VideoCallsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: VideoCallsModule,
      providers: [PermissionsService, VideoCallsService],
    };
  }
}
