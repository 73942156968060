import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tab as MuiTab } from '@material-ui/core';
import React, { ComponentProps } from 'react';
import { Chip } from '../Chip';

type TabProps = ComponentProps<typeof MuiTab> & {
  count?: number | string | null;
};

const tabCountStyles = css`
  display: flex;
  &:not(.Mui-selected) {
    font-weight: normal;
    opacity: 0.75;
  }
  &.MuiTab-labelIcon {
    padding-top: 6px;
  }
  .MuiTab-wrapper {
    display: flex;
    flex-direction: row-reverse;
    .MuiChip-root {
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }
`;

const hasCount = (count: TabProps['count']) =>
  typeof count === 'number' || typeof count === 'string';

const StyledTab = styled(MuiTab)<TabProps>`
  ${({ count }) => (hasCount(count) ? tabCountStyles : '')}
  min-height: initial;
  height: 48px;
  font-size: 1rem;
  text-transform: initial;
`;

export const Tab = (props: TabProps) => {
  props = {
    icon: hasCount(props.count) ? (
      <Chip round label={props.count} />
    ) : undefined,
    ...props,
  };

  return <StyledTab role="tab" {...props} />;
};
