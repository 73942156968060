import { useApiClient } from '@react/lib/api';
import { PatientsFilters } from '@react/lib/api/types';
import { useInfiniteQuery } from 'react-query';

export function usePatientsInfiniteQuery(filters?: PatientsFilters) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    ['PATIENTS_INFINITE', filters],
    ({ pageParam: pageNumber }: { pageParam?: number }) =>
      apiClient.fetchPatients({ pageNumber, ...filters }),
    {
      getNextPageParam(lastPage, pages) {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
      keepPreviousData: true,
    },
  );
}
