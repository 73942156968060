import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CognitoUser } from 'amazon-cognito-identity-js';
import libPhone from 'google-libphonenumber';
import {
  checkMobileNumber,
  passwordComplexity,
  valuesMatch,
} from 'src/app/core/utils/validators';
import { CountryCode, countryCodes } from 'src/app/data/country-codes';
import { CompletePasswordForm } from './../../models/user';

@Component({
  selector: 'portal-create-account-form',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountFormComponent {
  @Input() text: any;
  @Input()
  set completingPassword(isUpdatingPassword: boolean) {
    if (isUpdatingPassword) {
      this.createAccountForm.disable();
    } else {
      this.createAccountForm.enable();
    }
    this.isUpdatingPassword = isUpdatingPassword;
  }
  @Input() cognitoUser: CognitoUser | any;
  @Input() error: any;
  @Output() createCoreAccount = new EventEmitter<CompletePasswordForm>();

  public isUpdatingPassword: boolean;
  public createAccountForm: FormGroup;
  public allCountryCodes: CountryCode[] = countryCodes;

  constructor(private _fb: FormBuilder) {
    this.createAccountForm = this._fb.group(
      {
        firstName: this._fb.control('', Validators.required),
        lastName: this._fb.control('', Validators.required),
        countryCode: this._fb.control('', Validators.required),
        mobileNumber: this._fb.control('', Validators.required),
        password: this._fb.control('', [
          Validators.required,
          passwordComplexity,
        ]),
        confirmPassword: this._fb.control(''),
      },
      {
        validator: [
          valuesMatch('password', 'confirmPassword'),
          checkMobileNumber('mobileNumber', 'countryCode'),
        ],
      },
    );
  }

  public submitForm() {
    const { firstName, lastName, countryCode, mobileNumber, password } =
      this.createAccountForm.value;

    const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
    const PNF = libPhone.PhoneNumberFormat;
    const number = phoneUtil.parseAndKeepRawInput(mobileNumber, countryCode);
    const E164phone = phoneUtil.format(number, PNF.E164);

    const confirmPasswordBody = {
      user: this.cognitoUser,
      password,
      requiredAttributes: {
        family_name: lastName,
        given_name: firstName,
        phone_number: E164phone,
      },
    };

    this.createCoreAccount.emit(confirmPasswordBody);
  }
}
