import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { filtersToApi } from '../lib';
export function useLatestConversationsQuery(filters) {
    var apiClient = useApiClient().portal;
    var latestConvoFilters = tslib_1.__assign({}, filtersToApi(filters), { isresolved: false, isstarred: undefined, q: undefined });
    return useQuery([queryCacheKey.LATEST_CONVERSATIONS, latestConvoFilters], function () { return apiClient.fetchConversations(tslib_1.__assign({ pageSize: 1 }, latestConvoFilters)); }, {
        refetchInterval: POLLING_INTERVAL_MS,
        refetchIntervalInBackground: true,
    });
}
