export class ClinicPatientRequest {
  private Action = 'clinicpatient';
  public PublicToken: string;
  public PatientId: number;

  constructor({ PublicToken, PatientId }) {
    this.PublicToken = PublicToken;
    this.PatientId = PatientId;
  }
}
