import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
var ChooseTemplateComponent = /** @class */ (function () {
    function ChooseTemplateComponent(dialogRef, dialog, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.text = data.text;
        this.templateForm = new FormControl('', Validators.required);
    }
    ChooseTemplateComponent.prototype.appendToContent = function () {
        var method = 'append';
        this.dialogRef.close({
            method: method,
            template: this.templateForm.value,
        });
    };
    ChooseTemplateComponent.prototype.overwriteContent = function () {
        var method = 'overwrite';
        this.dialogRef.close({
            method: method,
            template: this.templateForm.value,
        });
    };
    ChooseTemplateComponent.prototype.cancel = function () {
        this.dialogRef.close(null);
    };
    return ChooseTemplateComponent;
}());
export { ChooseTemplateComponent };
