import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ChangeLanguage } from '../../actions/settings.actions';
import * as fromSettings from '../../reducers';
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(_route, _store) {
        this._route = _route;
        this._store = _store;
        // Component Subscriptions
        this._subs = new Subscription();
        this.settingSections$ = this._store.pipe(select(fromSettings.getSectionsList));
        this.languages$ = this._store.pipe(select(fromSettings.getAvailableLanguages));
        this.activeLanguage$ = this._store.pipe(select(fromSettings.getCurrentLanguage));
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this._route.params.subscribe(function (params) {
            _this.cardTitle = params.section;
        }));
    };
    SettingsComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    SettingsComponent.prototype.changePortalLanguage = function (language) {
        this._store.dispatch(new ChangeLanguage(language));
    };
    return SettingsComponent;
}());
export { SettingsComponent };
