<mat-card class="card-container">
  <perfect-scrollbar
    class="forgot-password-form always-visible"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div class="form-container">
      <div class="form-element">
        <form
          [formGroup]="forgottenPasswordForm"
          fxLayout="column"
          fxLayoutAlign="center stretch"
        >
          <div class="title">
            <div>{{ text.ResetTitle }}</div>
          </div>
          <mat-form-field appearance="fill">
            <mat-label>{{ text.Email }}</mat-label>
            <input
              matInput
              class="main-input"
              type="text"
              autocomplete="email"
              formControlName="email"
              required
            />
            <mat-error
              *ngIf="
                (forgottenPasswordForm.get('email').touched ||
                  forgottenPasswordForm.get('email').dirty) &&
                !forgottenPasswordForm.get('email').valid &&
                !generatingCode
              "
              class="error"
            >
              {{ text.EmailRequired }}
            </mat-error>
          </mat-form-field>

          <div *ngIf="isGeneratingCode" fxLayoutAlign="center center">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>

          <div
            *ngIf="!codeGenerated"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="20px"
          >
            <button
              mat-raised-button
              type="submit"
              (click)="getResetCode()"
              (keyup.enter)="getResetCode()"
              [disabled]="forgottenPasswordForm.status === 'INVALID'"
              class="portal-btn btn-primary btn-submit"
            >
              {{ text.ResetButton }}
            </button>
          </div>
        </form>

        <form
          *ngIf="codeGenerated"
          [formGroup]="newPasswordForm"
          fxLayout="column"
          fxLayoutAlign="start stretch"
        >
          <div class="sent-email">{{ text.ResetEmail }}</div>
          <mat-form-field appearance="fill">
            <mat-label>{{ text.YourCode }}</mat-label>
            <input
              matInput
              class="main-input"
              type="text"
              formControlName="code"
              required
            />
            <mat-error
              *ngIf="
                (newPasswordForm.get('code').touched ||
                  newPasswordForm.get('code').dirty) &&
                !newPasswordForm.get('code').valid &&
                !resettingPassword
              "
              class="error"
            >
              {{ text.CodeRequired }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ text.NewPassword }}</mat-label>
            <input
              matInput
              class="main-input"
              type="password"
              formControlName="password"
              required
            />
            <mat-error
              *ngIf="
                newPasswordForm.get('password').hasError('required') &&
                (newPasswordForm.get('password').touched ||
                  newPasswordForm.get('password').dirty) &&
                !resettingPassword
              "
              class="error"
            >
              {{ text.PasswordRequired }}
            </mat-error>
            <mat-error
              *ngIf="
                !newPasswordForm.get('password').hasError('required') &&
                newPasswordForm.get('password').hasError('complexity') &&
                (newPasswordForm.get('password').touched ||
                  newPasswordForm.get('password').dirty) &&
                !resettingPassword
              "
            >
              {{ text.InvalidPasswordException }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="no-bottom">
            <mat-label>{{ text.ConfirmNewPassword }}</mat-label>
            <input
              matInput
              class="main-input"
              type="password"
              formControlName="confirmPassword"
              required
            />
          </mat-form-field>

          <div *ngIf="isResettingPassword" fxLayoutAlign="center center">
            <mat-spinner [diameter]="50"></mat-spinner>
          </div>

          <div class="main-error" fxLayout="row" fxLayoutAlign="start center">
            <mat-error
              *ngIf="
                (newPasswordForm.get('confirmPassword').touched ||
                  newPasswordForm.get('confirmPassword').dirty) &&
                newPasswordForm.hasError('mismatch') &&
                !resettingPassword
              "
              class="error"
            >
              {{ text.PasswordsMustMatch }}
            </mat-error>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button
              mat-raised-button
              type="submit"
              (click)="createNewPassword()"
              (keyup.enter)="createNewPassword()"
              [disabled]="
                newPasswordForm.status === 'INVALID' ||
                forgottenPasswordForm.status === 'INVALID'
              "
              class="portal-btn btn-primary btn-submit"
            >
              {{ text.SubmitNewPassword }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </perfect-scrollbar>
</mat-card>
