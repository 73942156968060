import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { ContentEntry } from '../../models/content-entry';
import { FileInStorage } from '../../models/file-in-storage';
import { GetYoutubeIdService } from '../../services/get-youtube-id.service';

@Component({
  selector: 'portal-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
})
export class AddVideoComponent implements OnInit {
  public text: any;
  public showOptions = new BehaviorSubject(true);
  public addForm = new BehaviorSubject(false);

  public linkForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddVideoComponent>,
    public dialog: MatDialog,
    private _getYoutubeId: GetYoutubeIdService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contentEntry: ContentEntry;
      accept: string[];
      text: any;
      fileName: string;
      public: boolean;
      patientOnly: boolean;
      patientId: number;
    },
  ) {
    this.text = data.text;
  }

  ngOnInit() {
    this.linkForm = new FormGroup({
      link: new FormControl('', [Validators.required, Validators.minLength(3)]),
      type: new FormControl(''),
    });
  }

  public showForm(formType: string) {
    this.showOptions.next(false);
    this.addForm.next(true);

    this.setType(formType);
  }

  public setType(type: string) {
    this.linkForm.patchValue({
      type,
    });
  }

  public submitLink() {
    const type = this.linkForm.get('type').value;
    const link = this.linkForm.get('link').value;
    if (!link || link.length === 0) {
      this.cancel();
    } else {
      if (type === 'youtube') {
        this.addYoutube(link);
      } else {
        this.addLink(link);
      }
    }
  }

  // https://www.youtube.com/watch?v=ouwtdH2zt9s
  public addYoutube(link: string) {
    const id = this._getYoutubeId.getYoutubeId(link, { fuzzy: false });
    const youtubeSrc = `https://www.youtube.com/embed/${id}?wmode=opaque`;
    this.dialogRef.close({
      html: /* HTML */ `
        <div class="embedded-video-container">
          <iframe
            allow="fullscreen"
            class="embedded-video"
            frameborder="0"
            src="${youtubeSrc}"
          ></iframe>
        </div>
      `.trim(),
    });
  }

  public addLink(link: string) {
    this.dialogRef.close({
      html: `<div><a href="${link}" >View Video Here (${link})</a></div>`,
    });
  }

  public async uploadVideo() {
    const attachment = await this._openFileDialog();
    if (attachment) {
      this.dialogRef.close({
        html: /* HTML */ `
          <div class="embedded-video-container">
            <iframe
              allow="fullscreen"
              class="embedded-video"
              frameborder="0"
              src="${attachment.uri}"
            ></iframe>
          </div>
        `.trim(),
      });
    }
  }

  private _openFileDialog(): Promise<FileInStorage> {
    return new Promise((res, rej) => {
      const fileDialog = this.dialog.open(AddFileComponent, {
        data: {
          accept: this.data.accept,
          fileName: this.data.fileName,
          public: this.data.public,
          type: 'video',
          patientOnly: this.data.patientOnly,
          patientId: this.data.patientId,
        },
      });

      fileDialog
        .afterClosed()
        .subscribe((file: { attachment: FileInStorage }) => {
          if (file && file.attachment) {
            res(file.attachment);
          } else {
            res(null);
          }
        });
    });
  }

  public cancel() {
    this.dialogRef.close({
      html: null,
    });
  }
}
