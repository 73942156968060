import {
  DashboardActionsUnion,
  DashboardActionTypes,
} from '../actions/dashboard.actions';

export interface State {
  summaryLoading: boolean;
  medicationsLoading: boolean;
}

export const initialState: State = {
  summaryLoading: false,
  medicationsLoading: false,
};

export function reducer(
  state = initialState,
  action: DashboardActionsUnion,
): State {
  switch (action.type) {
    case DashboardActionTypes.LoadingClinicSummary: {
      return {
        ...state,
        summaryLoading: true,
      };
    }
    case DashboardActionTypes.LoadingClinicMedication: {
      return {
        ...state,
        medicationsLoading: true,
      };
    }
    case DashboardActionTypes.LoadedClinicSummary: {
      return {
        ...state,
        summaryLoading: false,
      };
    }
    case DashboardActionTypes.LoadedClinicMedication: {
      return {
        ...state,
        medicationsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const summaryIsLoading = (state: State) => state.summaryLoading;
export const medicationsAreLoading = (state: State) => state.medicationsLoading;
