import * as tslib_1 from "tslib";
import { DashboardActionTypes, } from '../actions/dashboard.actions';
export var initialState = {
    summary: {
        MessagesAlerts: 0,
        NurseMessagesAlerts: 0,
        AdminMessagesAlerts: 0,
        LabMessagesAlerts: 0,
        MedicationAlerts: 0,
        NurseUnresolvedMessageCount: 0,
        LabUnresolvedMessageCount: 0,
        AdminUnresolvedMessageCount: 0,
    },
    medications: {
        Medication: [],
    },
    nonTimedTriggerMedications: {
        Medication: [],
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DashboardActionTypes.LoadClinicSummarySuccess: {
            return tslib_1.__assign({}, state, { summary: action.payload });
        }
        case DashboardActionTypes.LoadClinicMedicationSuccess: {
            return tslib_1.__assign({}, state, { medications: action.payload });
        }
        case DashboardActionTypes.LoadClinicNonTimedTriggerMedicationSuccess: {
            return tslib_1.__assign({}, state, { nonTimedTriggerMedications: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getSummary = function (state) { return state.summary; };
export var getMedications = function (state) { return state.medications; };
export var getNonTimedTriggerMedications = function (state) {
    return state.nonTimedTriggerMedications;
};
