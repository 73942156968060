import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Grid, Slider } from '@material-ui/core';
var size = 200;
export var ThemedSlider = styled(Slider)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text.primary;
});
export var ThemedGrid = styled(Grid)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  border-radius: 50%;\n  cursor: pointer;\n  height: ", "px;\n  overflow: hidden;\n  width: ", "px;\n"], ["\n  border-radius: 50%;\n  cursor: pointer;\n  height: ", "px;\n  overflow: hidden;\n  width: ", "px;\n"])), size, size);
export var ThemedImg = styled('img')(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  max-height: 100%;\n  max-width: 100%;\n"], ["\n  max-height: 100%;\n  max-width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
