import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { MicOff, VideocamOff } from '@material-ui/icons';
export var ParticipantDisplayNameText = styled(Typography)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  color: #ffffff;\n  margin-right: 6px;\n"], ["\n  color: #ffffff;\n  margin-right: 6px;\n"])));
export var ParticipantVideoContainer = styled('div')(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  min-width: 256px;\n  aspect-ratio: 16/9;\n  position: relative;\n  flex: 1;\n  max-height: 100vh;\n"], ["\n  min-width: 256px;\n  aspect-ratio: 16/9;\n  position: relative;\n  flex: 1;\n  max-height: 100vh;\n"])));
export var ParticipantVideoIconsContainer = styled('div')(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  position: absolute;\n  height: 30px;\n  bottom: 12px;\n  left: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  position: absolute;\n  height: 30px;\n  bottom: 12px;\n  left: 12px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var ParticipantMicIconContainer = styled('div')(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  background-color: #1f2124;\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: #1f2124;\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var ParticipantVideoIconContainer = styled('div')(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  background-color: #1f2124;\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  background-color: #1f2124;\n  margin-right: 6px;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var ParticipantMicOffIcon = styled(MicOff)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  color: #ffffff;\n"], ["\n  color: #ffffff;\n"])));
export var ParticipantVideocamOffIcon = styled(VideocamOff)(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  color: #ffffff;\n"], ["\n  color: #ffffff;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
