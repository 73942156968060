import * as tslib_1 from "tslib";
var PermissionsService = /** @class */ (function () {
    function PermissionsService(window) {
        this.window = window;
    }
    PermissionsService.prototype.hasCameraPermission = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.getPermissionResult('camera')];
            });
        });
    };
    PermissionsService.prototype.hasMicrophonePermission = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.getPermissionResult('microphone')];
            });
        });
    };
    PermissionsService.prototype.getPermissionResult = function (permission) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!('permissions' in this.window.navigator)) {
                            return [2 /*return*/, 'prompt'];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.window.navigator.permissions.query({
                                // @ts-ignore our version of TypeScript doesn't have these types
                                name: permission,
                            })];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, result.state];
                    case 3:
                        err_1 = _a.sent();
                        return [2 /*return*/, 'prompt'];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return PermissionsService;
}());
export { PermissionsService };
