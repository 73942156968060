import { Pipe, PipeTransform } from '@angular/core';
import { format, isToday, isTomorrow, parseISO, startOfDay } from 'date-fns';

@Pipe({ name: 'dateTransform' })
export class DateTransformPipe implements PipeTransform {
  transform(date: Date | string, today: string, tomorrow: string): string {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    const d = startOfDay(parsedDate);
    if (isToday(d)) {
      return today;
    } else if (isTomorrow(d)) {
      return tomorrow;
    } else {
      return format(d, 'd LLL yyyy');
    }
  }
}

@Pipe({ name: 'timeTransform' })
export class TimeTransformPipe implements PipeTransform {
  transform(date: Date | string, today: string, tomorrow: string): string {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    return format(parsedDate, 'HH:mm');
  }
}
