import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../reducers';
import { GLOBAL_CMS_ROUTE, NavigationService } from './navigation.service';

@Injectable()
export class GlobalPortalGuard implements CanActivate {
  private isGlobalPortal: boolean;
  private hasSetGlobalPortal: boolean;
  private interval: NodeJS.Timer;

  constructor(
    private _navigationService: NavigationService,
    private _store: Store<fromRoot.State>,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<any> {
    this._store
      .pipe(select(fromRoot.getIsGlobalPortal))
      .subscribe((value) => (this.isGlobalPortal = value));
    this._store
      .pipe(select(fromRoot.getHasSetGlobalPortal))
      .subscribe((value) => (this.hasSetGlobalPortal = value));

    return new Promise((resolve, reject) => {
      const checkIfIsGlobalPortalOnceLoaded = () => {
        if (this.isGlobalPortal) {
          if (route.url[0].path === 'dashboard') {
            this._navigationService.navigate([`/${GLOBAL_CMS_ROUTE}`]);
            reject();
            return;
          }
          this._navigationService.navigate(['/unauthorised']);
          reject();
          return;
        }
        resolve(true);
      };

      /**
       * There is almost certainly a better way of waiting for our clinic information to be loaded,
       * when running a route guard feature, but without more experience in Angular, awaiting a promise,
       * which loops on itself until the loading flag has been set was the only way I could solve this issue.
       * Sadly this is a symptom of the portal architecture only ever expecting to be in one "mode".
       * Without re-architecting how the portal works, we must always wait to see if the portal is a global portal or not,
       * via the call we do in the auth service.
       */
      this.interval = setInterval(() => {
        if (this.hasSetGlobalPortal) {
          clearInterval(this.interval);
          checkIfIsGlobalPortalOnceLoaded();
        }
      }, 500);
    });
  }
}
