var _this = this;
import * as tslib_1 from "tslib";
import { CircularProgress } from '@material-ui/core';
import { useCallbackDebounced } from '@react/lib/hooks/useCallbackDebounced';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect } from 'react';
import { useVideoCall } from '../../hooks/useVideoCall';
import { ZoomProvider } from '../ZoomProvider';
import { VideoCallMenuButton } from './VideoCallMenuButton';
import { CallEndButton, CallEndIcon, MicButton, MicIcon, MicOffIcon, NooneHereText, PageContainer, ParticipantsContainer, VideoActionBarContainer, VideocamButton, VideocamIcon, VideocamOffIcon } from './VideoCallModal.styled';
import ParticipantPanel from './ParticipantPanel';
import SelfPanel from './SelfPanel';
var participantsVideoPlayerContainerStyle = {
    flex: 1,
    position: 'relative',
    display: 'block'
};
var selfVideoPlayerContainerStyle = {
    width: 256,
    aspectRatio: '16/9',
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'black'
};
function withZoomProvider(Component) {
    return function WrappedComponent(props) {
        return (React.createElement(ZoomProvider, null,
            React.createElement(Component, tslib_1.__assign({ key: undefined }, props))));
    };
}
var VideoCallModalComponentBase = function (_a) {
    var displayName = _a.displayName, close = _a.close, joinResponse = _a.joinResponse, invitePartnerInCall = _a.invitePartnerInCall, partnerInviteEnabled = _a.partnerInviteEnabled, partnerInvited = _a.partnerInvited, invitingPartner = _a.invitingPartner, displayConnectedNotification = _a.displayConnectedNotification, displayErrorNotification = _a.displayErrorNotification;
    var t = useTranslations().t;
    var _b = useVideoCall(), join = _b.join, leave = _b.leave, currentUser = _b.currentUser, participants = _b.participants, microphoneList = _b.microphoneList, cameraList = _b.cameraList, speakerList = _b.speakerList, toggleMic = _b.toggleMic, toggleCamera = _b.toggleCamera, micOn = _b.micOn, cameraOn = _b.cameraOn, changeCamera = _b.changeCamera, changeMic = _b.changeMic, changeSpeaker = _b.changeSpeaker, joining = _b.joining;
    var onCloseClick = useCallbackDebounced(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, leave()];
                case 1:
                    _a.sent();
                    close();
                    return [2 /*return*/];
            }
        });
    }); }, [close]);
    useEffect(function () {
        if (!t) {
            return;
        }
        void join({
            name: displayName,
            room: joinResponse.response.room,
            token: joinResponse.response.zoomToken,
            password: joinResponse.videoCall.id.toString()
        })
            .then(function (success) {
            if (success) {
                displayConnectedNotification();
            }
        })
            .catch(function (error) {
            leave();
            close();
            if (error.message === 'camera-permisisons-error') {
                displayErrorNotification(t.VideoCallModal.NoCameraMic);
            }
            else {
                displayErrorNotification(error.message);
            }
        });
    }, [join, leave, close, t]);
    return (React.createElement(PageContainer, null,
        React.createElement("video-player-container", { style: participantsVideoPlayerContainerStyle },
            React.createElement(ParticipantsContainer, null,
                joining && React.createElement(CircularProgress, null),
                !joining && (!participants || !participants.length) && (React.createElement(NooneHereText, null, t &&
                    t.VideoCallModal.NooneHere(joinResponse.videoCall.patientname))),
                participants.map(function (participant) {
                    return (React.createElement(ParticipantPanel, { key: participant.userId, participant: participant }));
                }))),
        currentUser && (React.createElement("video-player-container", { style: selfVideoPlayerContainerStyle },
            React.createElement(SelfPanel, { participant: currentUser, cameraOn: cameraOn, micOn: micOn }))),
        React.createElement(VideoActionBarContainer, null,
            React.createElement(CallEndButton, { size: "medium", onClick: onCloseClick },
                React.createElement(CallEndIcon, null)),
            React.createElement(MicButton, { size: "medium", onClick: toggleMic }, micOn ? React.createElement(MicIcon, null) : React.createElement(MicOffIcon, null)),
            React.createElement(VideocamButton, { size: "medium", onClick: toggleCamera }, cameraOn ? React.createElement(VideocamIcon, null) : React.createElement(VideocamOffIcon, null)),
            React.createElement(VideoCallMenuButton, { cameraList: cameraList, microphoneList: microphoneList, speakerList: speakerList, changeCamera: changeCamera, changeMic: changeMic, changeSpeaker: changeSpeaker, invitePartnerInCall: invitePartnerInCall, invitingPartner: invitingPartner, partnerInvited: partnerInvited, partnerInviteEnabled: partnerInviteEnabled, disabled: joining }))));
};
var ɵ0 = VideoCallModalComponentBase;
export var VideoCallModalComponent = withZoomProvider(VideoCallModalComponentBase);
export { ɵ0 };
