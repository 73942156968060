import styled from '@emotion/styled';
import { OutlinedTextFieldProps, TextField } from '@material-ui/core';
import React from 'react';
import { CharacterCount } from './CharacterCount';
import { InputLabel } from './InputLabel';

interface Props
  extends Pick<
    OutlinedTextFieldProps,
    'onChange' | 'fullWidth' | 'ref' | 'onFocus' | 'onBlur' | 'disabled'
  > {
  id: string;
  label: string;
  placeholder?: string;
  maxLength?: number;
  showCharCount?: boolean;
  value?: string;
}

export const TextInput: React.FC<Props> = React.forwardRef(
  (
    { id, label, placeholder, value, maxLength, showCharCount, ...rest },
    ref,
  ) => {
    return (
      <>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <TextField
          inputRef={ref}
          id={id}
          placeholder={placeholder}
          value={value}
          variant="outlined"
          inputProps={{ maxLength }}
          {...rest}
        />
        {showCharCount && value != null && (
          <StyledCharCount>
            <CharacterCount length={value.length} maxLength={maxLength} />
          </StyledCharCount>
        )}
      </>
    );
  },
);

const StyledCharCount = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
`;
