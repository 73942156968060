import { CognitoUser } from 'amazon-cognito-identity-js';
import { DisplayToastPayload } from 'src/app/core/models/DisplayToastPayload';

export interface Authenticate {
  username: string;
  password: string;
  validationData: { [key: string]: string };
}

export interface CompletePasswordForm {
  user: CognitoUser | any;
  password: string;
  requiredAttributes: any;
}

export interface VerifyOTPParams {
  user: CognitoUser | any;
  code: string;
  toast: DisplayToastPayload;
}

export type UserGroup =
  | 'ClinicPortalAdmin'
  | 'ClinicPortalSuperAdmin'
  | 'ClinicContentEditor'
  | 'ClinicUserAdmin';

export class User {
  public FirstName: string;
  public LastName: string;
  public CognitoUsername: string;
  public PublicToken: string;
  public Success: boolean;
  public Consent: boolean;
  public ClinicName: string;
  public LogoutMinutes: number;
  public LanguageCode: string;
  public jwtToken: string | null;
  public groups: UserGroup[];

  constructor(
    FirstName: string,
    LastName: string,
    CognitoUsername: string,
    PublicToken: string,
    Success: boolean,
    Consent: boolean,
    ClinicName: string,
    LogoutMinutes: number,
    LanguageCode: string = 'en-gb',
    jwtToken: string,
    groups: UserGroup[],
  ) {
    this.FirstName = FirstName;
    this.LastName = LastName;
    this.CognitoUsername = CognitoUsername;
    this.PublicToken = PublicToken;
    this.Success = Success;
    this.Consent = Consent;
    this.ClinicName = ClinicName;
    this.LogoutMinutes = LogoutMinutes;
    this.LanguageCode = LanguageCode;
    this.jwtToken = jwtToken || null;
    this.groups = groups;
  }
}
