import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[numbers-only]',
})
export class NumbersOnlyDirective {
  constructor(private elRef: ElementRef) {}

  @Input() allowDecimals: boolean;
  @Output() filteringCompleted: EventEmitter<string> =
    new EventEmitter<string>();
  @HostListener('input', ['$event']) onInput() {
    const element = this.elRef.nativeElement;
    const value: string = element.value;
    let matches = [];
    if (this.allowDecimals) {
      matches = value.match(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    } else {
      matches = value.match(/^\d*/g);
    }

    if (matches) {
      element.value = matches.join('');
    } else {
      element.value = '';
    }
    this.filteringCompleted.emit(element.value);
  }
}
