import { addDays, addMonths, addWeeks, format, formatDuration, startOfMonth, startOfWeek, } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
var DateTime = /** @class */ (function () {
    function DateTime(locale) {
        this._locale = locale;
    }
    /*
     * Typescript 3.1 will allow us to wrap the original functions without
     * declaring arguments again with formatDate(...args: Parameters<typeof formatDate>)
     */
    DateTime.prototype.format = function (date, formatString, options) {
        if (!options || !options.utc) {
            return format(this.parseDate(date), formatString, {
                locale: this._locale,
            });
        }
        return formatTz(utcToZonedTime(this.parseDate(date), 'UTC'), formatString, {
            timeZone: 'UTC',
            locale: this._locale,
        });
    };
    DateTime.prototype.formatDuration = function (date) {
        return formatDuration(date, {
            locale: this._locale,
        });
    };
    DateTime.prototype.addDays = function (date, days) {
        return addDays(date, days);
    };
    DateTime.prototype.addWeeks = function (date, weeks) {
        return addWeeks(date, weeks);
    };
    DateTime.prototype.addMonths = function (date, months) {
        return addMonths(date, months);
    };
    DateTime.prototype.startOfWeek = function (date) {
        return startOfWeek(date);
    };
    DateTime.prototype.startOfMonth = function (date) {
        return startOfMonth(date);
    };
    DateTime.prototype.parseDate = function (date) {
        return date instanceof Date ? date : new Date(date);
    };
    return DateTime;
}());
export { DateTime };
