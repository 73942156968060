import * as tslib_1 from "tslib";
import { useBrowserNotifications, usePrevious } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { isBefore } from 'date-fns';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { constructMessageDeepLink } from '../lib';
import { useLatestConversationsQuery } from './useLatestConversationsQuery';
function shouldTrigger(filters, latestConvo, previousFilters, previousLatest) {
    if (!previousLatest) {
        return false;
    }
    if (filters !== previousFilters) {
        return false;
    }
    if (isBefore(new Date(latestConvo.sentdate), new Date(previousLatest.sentdate))) {
        return false;
    }
    var isSameMessage = latestConvo.messageitemid === previousLatest.messageitemid;
    return !isSameMessage && latestConvo.patientsent;
}
function focusBrowserTab() {
    window.parent.parent.focus();
}
export function useMessagingNotifications(filters) {
    var t = useTranslations().t;
    var history = useHistory();
    var showNotification = useBrowserNotifications().showNotification;
    var data = useLatestConversationsQuery(filters).data;
    var latestConvo = data && data.data[0];
    var previousLatest = usePrevious(latestConvo);
    var previousFilters = usePrevious(filters);
    var deepLinkFilters = tslib_1.__assign({}, filters, { isResolved: false, isStarred: undefined, q: undefined });
    useEffect(function () {
        if (latestConvo &&
            shouldTrigger(filters, latestConvo, previousFilters, previousLatest)) {
            showNotification({
                title: t.MessagingNotifications.NotificationTitle,
                body: t.MessagingNotifications.NotificationBodyMessage(latestConvo.patientfirstname + " " + latestConvo.patientlastname),
                onClick: function () {
                    focusBrowserTab();
                    history.push(constructMessageDeepLink(latestConvo, deepLinkFilters));
                },
            });
        }
    }, [latestConvo]);
}
