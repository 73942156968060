import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContentAssignmentActionTypes, CreatePatientAssignedContentError, CreatePatientAssignedContentSuccess, CreatePatientOnlyAssignmentsError, CreatePatientOnlyAssignmentsSuccess, DeletePatientAssignedContentError, DeletePatientAssignedContentSuccess, DeletePatientOnlyAssignmentsError, DeletePatientOnlyAssignmentsSuccess, GetAllPatientAssignedContentError, GetAllPatientAssignedContentSuccess, GetContentEntriesError, GetContentEntriesSuccess, GetContentTypesError, GetContentTypesSuccess, GetPatientAssignedContentError, GetPatientAssignedContentSuccess, GetPatientOnlyAssignmentsError, GetPatientOnlyAssignmentsSuccess, GetPatientViewOfContentError, GetPatientViewOfContentSuccess, UpdatePatientOnlyAssignmentsError, UpdatePatientOnlyAssignmentsSuccess } from '../actions/content-assignment.actions';
import { ContentAssignmentService } from '../services/content-assignment.service';
var ContentAssignmentEffects = /** @class */ (function () {
    function ContentAssignmentEffects(_actions$, _contentAssignmentService, _error) {
        var _this = this;
        this._actions$ = _actions$;
        this._contentAssignmentService = _contentAssignmentService;
        this._error = _error;
        // Get Content Entries
        this.getContentEntries$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.GetContentEntries), mergeMap(function () {
            return _this._contentAssignmentService.getContentEntryHeaders().pipe(
            // TODO: Fix types
            // @ts-ignore
            map(function (res) {
                return new GetContentEntriesSuccess(res);
            }), catchError(function (err) {
                return of(new GetContentEntriesError(err));
            }));
        }));
        // Get Content Types
        this.getTypes$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.GetContentTypes), mergeMap(function () {
            return _this._contentAssignmentService.getContentCategories().pipe(map(function (res) { return new GetContentTypesSuccess(res); }), catchError(function (err) {
                return of(new GetContentTypesError(err));
            }));
        }));
        // CRUD for 'userassignedcontent'
        // ================================================
        // Create User Assigned Content
        this.createUserAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.CreatePatientAssignedContent), mergeMap(function (action) {
            return _this._contentAssignmentService
                .createUserAssignedContent(action.payload.assignment, action.payload.patientId)
                .pipe(
            // TODO: Fix types
            // @ts-ignore
            map(function (res) {
                if (action.payload.patientContent) {
                    action.payload.patientContent.isTransferring = false;
                }
                return new CreatePatientAssignedContentSuccess(res);
            }), catchError(function (err) {
                if (action.payload.patientContent) {
                    action.payload.patientContent.isTransferring = false;
                }
                return of(new CreatePatientAssignedContentError(err));
            }));
        }));
        // Get User Assigned Content
        this.getUserAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.GetPatientAssignedContent), mergeMap(function (action) {
            return _this._contentAssignmentService
                .getUserAssignedContentById(action.payload)
                .pipe(map(function (res) {
                return new GetPatientAssignedContentSuccess(res);
            }), catchError(function (err) {
                return of(new GetPatientAssignedContentError(err));
            }));
        }));
        // Get User Assigned Content
        this.getAllUserAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.GetAllPatientAssignedContent), mergeMap(function (action) {
            return _this._contentAssignmentService
                .getAllUserAssignedContent(action.payload)
                .pipe(
            // TODO: Fix types
            // @ts-ignore
            map(function (res) {
                return new GetAllPatientAssignedContentSuccess(res);
            }), catchError(function (err) {
                return of(new GetAllPatientAssignedContentError(err));
            }));
        }));
        // Get Patient View Of Assigned Content
        this.getPatientViewOfAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.GetAllPatientAssignedContent), mergeMap(function (action) {
            return _this._contentAssignmentService
                .getPatientViewOfContent(action.payload)
                .pipe(map(function (res) {
                return new GetPatientViewOfContentSuccess(res);
            }), catchError(function (err) {
                return of(new GetPatientViewOfContentError(err));
            }));
        }));
        // Delete User Assigned Content
        this.deleteUserAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.DeletePatientAssignedContent), mergeMap(function (action) {
            return _this._contentAssignmentService
                .deleteUserAssignedContent(action.payload.assignmentid)
                .pipe(map(function (res) {
                return new DeletePatientAssignedContentSuccess(action.payload);
            }), catchError(function (err) {
                return of(new DeletePatientAssignedContentError(err));
            }));
        }));
        // CRUD for 'patient only content'
        // ================================================
        // Read
        this.getPatientOnlyAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.GetPatientOnlyAssignments), mergeMap(function (action) {
            return _this._contentAssignmentService
                .getPatientOnlyAssignedContent(action.payload.patientId)
                .pipe(map(function (res) {
                return new GetPatientOnlyAssignmentsSuccess(res);
            }), catchError(function (err) {
                return of(new GetPatientOnlyAssignmentsError(err));
            }));
        }));
        // Create
        this.createPatientOnlyAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.CreatePatientOnlyAssignments), mergeMap(function (action) {
            return _this._contentAssignmentService
                .createPatientOnlyAssignedContent(action.payload.body, action.payload.patientId)
                .pipe(map(function (res) {
                return new CreatePatientOnlyAssignmentsSuccess(res);
            }), catchError(function (err) {
                return of(new CreatePatientOnlyAssignmentsError(err));
            }));
        }));
        // Update
        this.updatePatientOnlyAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.UpdatePatientOnlyAssignments), mergeMap(function (action) {
            return _this._contentAssignmentService
                .updatePatientOnlyAssignedContent(action.payload.updateInfo, action.payload.assignmentId)
                .pipe(map(function (res) {
                return new UpdatePatientOnlyAssignmentsSuccess(res);
            }), catchError(function (err) {
                return of(new UpdatePatientOnlyAssignmentsError(err));
            }));
        }));
        // Delete
        this.deletePatientOnlyAssignedContent$ = this._actions$.pipe(ofType(ContentAssignmentActionTypes.DeletePatientOnlyAssignments), mergeMap(function (action) {
            return _this._contentAssignmentService
                .deletePatientOnlyAssignedContent(action.payload.deleteInfo)
                .pipe(map(function (res) {
                return new DeletePatientOnlyAssignmentsSuccess(action.payload.deleteInfo);
            }), catchError(function (err) {
                return of(new DeletePatientOnlyAssignmentsError(err));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "getContentEntries$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "getTypes$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "createUserAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "getUserAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "getAllUserAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "getPatientViewOfAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "deleteUserAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "getPatientOnlyAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "createPatientOnlyAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "updatePatientOnlyAssignedContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ContentAssignmentEffects.prototype, "deletePatientOnlyAssignedContent$", void 0);
    return ContentAssignmentEffects;
}());
export { ContentAssignmentEffects };
