import * as tslib_1 from "tslib";
import { ErrorHandler, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, Validators, } from '@angular/forms';
import { DateAdapter, MatDialog, MatDialogRef, } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import * as dateFns from 'date-fns';
import range from 'lodash/range';
import { Subscription } from 'rxjs';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientService } from 'src/app/patients/services/patient.service';
import { VideoCallEffectsResposeType, } from 'src/app/video-calls/responses';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as VideoCallActions from '../../../video-calls/actions/video-call.actions';
import { DeleteVideoCallAction, ResetEffectsStateAction, } from '../../../video-calls/actions/video-call.actions';
import * as fromVideoCalls from '../../../video-calls/reducers';
import { DisplayToastAction } from '../../actions/toast.actions';
import { ConfirmActionComponent } from '../../components/confirm-action/confirm-action.component';
import { PatientRequiringFormBase } from '../patient-requiring-form-base';
var NewVideoCallComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NewVideoCallComponent, _super);
    function NewVideoCallComponent(_store, angulartics2, dialogRef, dialog, _fb, _dateAdapter, _errorHandler, data, _patientService, _localisationService) {
        var _this = _super.call(this, _patientService, _localisationService) || this;
        _this._store = _store;
        _this.angulartics2 = angulartics2;
        _this.dialogRef = dialogRef;
        _this.dialog = dialog;
        _this._fb = _fb;
        _this._dateAdapter = _dateAdapter;
        _this._errorHandler = _errorHandler;
        _this.data = data;
        _this.durations = range(15, 125, 5);
        _this.isEdit = false;
        _this.minDate = dateFns.startOfDay(Date.now());
        _this._subs = new Subscription();
        _this.selectedDate = new Date(Date.now());
        _this.isEdit = !!_this.data.id;
        _this._toastText$ = _this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallsToast')));
        _this.angulartics2.eventTrack.next({
            action: 'Initialise New Video Call',
            properties: { category: 'Video Call', label: 'New Video Call' },
        });
        _this.newVideoCallText$ = _this._store.pipe(select(fromSettings.getSectionTranslations('NewVideoCall')));
        _this._languageCode$ = _this._store.pipe(select(fromAuth.getLanguageCode));
        _this.effectsCompleted$ = _this._store.pipe(select(fromVideoCalls.getEffectsCompletedResponse));
        _this._toastRef$ = _store.pipe(select(fromRoot.getToastRef));
        // if patient data passed to modal then use this for the form
        if (_this.data.patient) {
            _this._patient = data.patient;
        }
        _this.isFutureDate = _this.isFutureDate.bind(_this);
        return _this;
    }
    NewVideoCallComponent.prototype.isFutureDate = function (date) {
        return (dateFns.isSameDay(date, this._localisationService.getZonedToday()) ||
            dateFns.isAfter(date, this._localisationService.getZonedToday()));
    };
    NewVideoCallComponent.prototype.dateInPastValidator = function () {
        var _this = this;
        return function (control) {
            var date = control.value;
            if (date == null) {
                return null;
            }
            // Since the value of this input affects the startTime input
            // we should request that is revalidated.
            if (control.parent != null) {
                control.parent.get('startTime').updateValueAndValidity({
                    onlySelf: true,
                });
            }
            return !_this.isFutureDate(date) ? { inPast: true } : null;
        };
    };
    NewVideoCallComponent.prototype.startTimeInPastValidator = function () {
        var _this = this;
        return function (control) {
            if (control.parent == null) {
                return null;
            }
            var appointmentTime = _this.createDateFromFields(control.parent);
            if (!appointmentTime) {
                return null;
            }
            return appointmentTime < _this._localisationService.getZonedToday()
                ? { inPast: true }
                : null;
        };
    };
    NewVideoCallComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this.newVideoCallText$.subscribe(function (val) { return (_this.newVideoCallText = val); }));
        this._subs.add(this._languageCode$.subscribe(function (val) {
            _this._dateAdapter.setLocale(val || 'en-GB');
        }));
        this._subs.add(this.effectsCompleted$.subscribe(function (payload) {
            _this.onEffectsCompleted(payload);
        }));
        this._subs.add(this._toastText$.subscribe(function (val) {
            _this._toastText = val;
        }));
        this.setupSearchObservables(this._errorHandler);
        this.setupForm();
        this.setupSearchBox(this.newVideoCallForm);
        this._subs.add(this._toastRef$.subscribe(function (tr) {
            _this._toastRef = tr;
        }));
    };
    NewVideoCallComponent.prototype.setupForm = function () {
        this.newVideoCallForm = this._fb.group({
            patientName: this.getFormPatient(),
            host: [
                { value: this.data.host || '', disabled: false },
                [Validators.required, Validators.minLength(3)],
            ],
            description: [
                { value: this.data.description || '', disabled: false },
                [Validators.required, Validators.minLength(3)],
            ],
            date: [
                { value: this.data.date || null, disabled: false },
                [Validators.required, this.dateInPastValidator()],
            ],
            startTime: [
                { value: this.data.startTime || '', disabled: false },
                [Validators.required, this.startTimeInPastValidator()],
            ],
            duration: [
                { value: this.data.duration || null, disabled: false },
                [Validators.required],
            ],
        });
    };
    NewVideoCallComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    NewVideoCallComponent.prototype.closeDialog = function () {
        var _this = this;
        if (this.newVideoCallForm.dirty) {
            var confirmDialog = this.dialog.open(ConfirmActionComponent, {
                data: {
                    message: this.newVideoCallText.CloseCheck,
                    text: {
                        Cancel: this.newVideoCallText.Cancel,
                        Confirm: this.newVideoCallText.Confirm,
                    },
                },
            });
            confirmDialog.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.doCloseDialog();
                }
            });
        }
        else {
            this.doCloseDialog();
        }
    };
    NewVideoCallComponent.prototype.confirmDeleteDialog = function () {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.newVideoCallText.ConfirmDelete,
                text: {
                    Cancel: this.newVideoCallText.Cancel,
                    Confirm: this.newVideoCallText.Delete,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (result) {
            if (result) {
                _this.deleteVideoCall();
            }
        });
    };
    NewVideoCallComponent.prototype.saveVideoCall = function () {
        this._store.dispatch(new DisplayToastAction({
            message: this._toastText.PleaseWait,
            title: this._toastText.Saving,
            timeout: 0,
            type: 'info',
        }));
        var patient = this._patient || this.newVideoCallForm.get('patientName').value;
        var newVideoCall = {
            id: this.data.id,
            patient: patient,
            description: this.newVideoCallForm.get('description').value,
            duration: this.newVideoCallForm.get('duration').value,
            host: this.newVideoCallForm.get('host').value,
            scheduleddate: this._localisationService
                .toUTCDate(this.createDateFromFields(this.newVideoCallForm))
                .toISOString(),
        };
        this._store.dispatch(new VideoCallActions.UpdateVideoCallAction(newVideoCall));
    };
    NewVideoCallComponent.prototype.onEffectsCompleted = function (payload) {
        if (payload) {
            switch (payload.type) {
                case VideoCallEffectsResposeType.UpdateSuccess:
                    this.onSavedSuccessfully();
                    break;
                case VideoCallEffectsResposeType.DeleteSuccess:
                    this.onDeletedSuccessfully();
                    break;
                case VideoCallEffectsResposeType.DeleteError:
                case VideoCallEffectsResposeType.UpdateError:
                    this.onUDerror(payload.type);
                    break;
            }
            this._store.dispatch(new ResetEffectsStateAction());
        }
    };
    NewVideoCallComponent.prototype.createDateFromFields = function (group) {
        var startTime = group.get('startTime').value;
        if (startTime == null || startTime === '') {
            return null;
        }
        var _a = startTime.split(':'), hours = _a[0], minutes = _a[1];
        var dateValue = group.get('date').value;
        if (!dateValue || hours == null || minutes == null) {
            return null;
        }
        dateValue = dateFns.setHours(dateValue, Number(hours));
        dateValue = dateFns.setMinutes(dateValue, Number(minutes));
        return dateValue;
    };
    NewVideoCallComponent.prototype.deleteVideoCall = function () {
        this._store.dispatch(new DisplayToastAction({
            message: this._toastText.PleaseWait,
            title: this._toastText.Deleting,
            timeout: 0,
            type: 'info',
        }));
        this._store.dispatch(new DeleteVideoCallAction({ id: this.data.id }));
    };
    NewVideoCallComponent.prototype.onSavedSuccessfully = function () {
        this._store.dispatch(new DisplayToastAction({
            toastRef: this._toastRef,
            message: this._toastText.SavedSuccessfully,
            title: this._toastText.Saved,
            timeout: 3000,
            type: 'success',
        }));
        this.doCloseDialog();
    };
    NewVideoCallComponent.prototype.onUDerror = function (type) {
        if (!this._toastRef) {
            return;
        }
        this._store.dispatch(new DisplayToastAction({
            toastRef: this._toastRef,
            message: this._toastText.ErrorEncountered,
            title: type === VideoCallEffectsResposeType.UpdateError
                ? this._toastText.ErrorSaving
                : this._toastText.ErrorDeleting,
            timeout: 0,
            type: 'error',
        }));
        this._store.dispatch(new ResetEffectsStateAction());
    };
    NewVideoCallComponent.prototype.onDeletedSuccessfully = function () {
        this._store.dispatch(new DisplayToastAction({
            toastRef: this._toastRef,
            message: this._toastText.DeletedSuccessfully,
            title: this._toastText.Deleted,
            timeout: 3000,
            type: 'success',
        }));
        this.doCloseDialog();
        this._store.dispatch(new ResetEffectsStateAction());
    };
    NewVideoCallComponent.prototype.doCloseDialog = function () {
        this.dialogRef.close({
            content: null,
            patientId: null,
        });
    };
    return NewVideoCallComponent;
}(PatientRequiringFormBase));
export { NewVideoCallComponent };
