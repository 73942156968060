import * as tslib_1 from "tslib";
import { AuthActionTypes } from '../actions/auth.actions';
export var initialState = {
    error: null,
    pending: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthActionTypes.CoreLogin: {
            return tslib_1.__assign({}, state, { error: null, pending: true });
        }
        case AuthActionTypes.EnableForm: {
            return tslib_1.__assign({}, state, { pending: false });
        }
        case AuthActionTypes.CoreLoginSuccess: {
            return tslib_1.__assign({}, state, { error: null, pending: false });
        }
        case AuthActionTypes.CoreLoginFailure: {
            return tslib_1.__assign({}, state, { error: action.payload, pending: false });
        }
        case AuthActionTypes.ClearLoginError: {
            return tslib_1.__assign({}, state, { error: null });
        }
        default: {
            return state;
        }
    }
}
export var getError = function (state) { return state.error; };
export var getPending = function (state) { return state.pending; };
