import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ContentActionTypes, CreateCategory } from './../../actions/content.actions';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as fromSettings from '../../../settings/reducers';
import { validateFolderName } from '../../services/async-validators/validate-folder-name';
import { ContentService } from '../../services/content.service';
var CreateCategoryComponent = /** @class */ (function () {
    function CreateCategoryComponent(_store, _updates, dialogRef, dialog, _contentService, data) {
        this._store = _store;
        this._updates = _updates;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this._contentService = _contentService;
        this.data = data;
        this.checkingFolderName$ = new BehaviorSubject(false);
        this.savingFolder$ = new BehaviorSubject(false);
        this.savedFolder$ = new BehaviorSubject(false);
        this.errorEncountered$ = new BehaviorSubject(false);
        this._subs = new Subscription();
    }
    CreateCategoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.folderName = new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50)
        ]), [validateFolderName(this._contentService, this.checkingFolderName$)]);
        // watch for errors on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentActionTypes.CreateCategoryError))
            .subscribe(function () {
            _this.savingFolder$.next(false);
            _this.errorEncountered$.next(true);
            _this.exitDialog(false, 1000);
        }));
        // watch for success on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentActionTypes.CreateCategorySuccess))
            .subscribe(function () {
            _this.savingFolder$.next(false);
            _this.savedFolder$.next(true);
            _this.exitDialog(true, 1000);
        }));
        this.contentCreatorText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorSections')));
        this._subs.add(this.contentCreatorText$.subscribe(function (t) { return (_this.contentCreatorText = t); }));
    };
    CreateCategoryComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    CreateCategoryComponent.prototype.clearForm = function () {
        this.folderName.setValue('');
    };
    CreateCategoryComponent.prototype.createNewFolder = function () {
        this.savingFolder$.next(true);
        var newFolderName = this.folderName.value;
        this._store.dispatch(new CreateCategory({
            name: newFolderName,
            patientonly: false,
            isactive: true,
            isdrug: false,
            isappointment: false,
            issite: false,
            isstaff: false,
            istreatment: false
        }));
    };
    CreateCategoryComponent.prototype.cancelNameChange = function () {
        this.exitDialog(false);
    };
    CreateCategoryComponent.prototype.exitDialog = function (status, time) {
        var _this = this;
        if (status === void 0) { status = false; }
        if (time === void 0) { time = 0; }
        setTimeout(function () {
            _this.dialogRef.close(status);
        }, time);
    };
    return CreateCategoryComponent;
}());
export { CreateCategoryComponent };
