export class Label {
  public messageTypeId?: number;
  public includeResolved = false;
  public includeUnresolved = true;
  public unresolvedCount?: number;
  public filter: string;
  public displayName: string;
  public isGroup: boolean;
  public isUncategorised: boolean;
  public canClinicReply: boolean;

  constructor(init?: Partial<Label>) {
    Object.assign(this, init);
  }
}
