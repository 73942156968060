import { EventEmitter, OnInit } from '@angular/core';
import { MedicationStatus } from '../patient-list/patient-list.component.enums';
var PatientMedicationsComponent = /** @class */ (function () {
    function PatientMedicationsComponent() {
        this.displayedColumns = ['type', 'drug', 'dosage', 'status'];
        this.rowSelected = new EventEmitter();
    }
    PatientMedicationsComponent.prototype.ngOnInit = function () { };
    PatientMedicationsComponent.prototype.onClick = function (row) {
        this.rowSelected.emit({
            id: row.id,
            date: new Date(row.dueDate),
        });
    };
    PatientMedicationsComponent.prototype.matIcon = function (status) {
        var _a;
        var symbols = (_a = {},
            _a[MedicationStatus.Overdue] = 'query_builder',
            _a[MedicationStatus.WindowClosing] = 'query_builder',
            _a[MedicationStatus.PotentialDuplicate] = 'error',
            _a[MedicationStatus.Taken] = 'check_circle',
            _a[MedicationStatus.DueAt] = 'error',
            _a);
        return symbols[status];
    };
    return PatientMedicationsComponent;
}());
export { PatientMedicationsComponent };
