import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PhotoSize } from '@react/components/ProfilePhoto.styled';
import Root from '@react/components/Root';
import { StaffProfiles } from '@react/lib/api/types/clinicUsers';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import { ProfilePhoto } from '../../../react/components/ProfilePhoto';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
import { FileUpload } from '../../services/s3.service';

const containerElementName = 'profilePhotoReactComponent';

@Component({
  selector: 'portal-profile-photo',
  template: `<div #${containerElementName}></div>`,
})
export class ProfilePhotoReactWrapperComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(containerElementName) containerRef!: ElementRef;

  @Input() clinicUserId?: number;
  @Input() size: PhotoSize;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
    staffProfiles: StaffProfiles;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _fileUpload: FileUpload,
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
        this._store.pipe(select(fromRoot.getStaffProfiles)),
      ]).subscribe(
        ([
          clinicToken,
          clinicOptions,
          user,
          language,
          translations,
          staffProfiles,
        ]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
            staffProfiles,
          };
          this.render();
        },
      ),
    );
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  ngAfterViewInit() {
    this.render();
  }

  private async render() {
    ReactDOM.render(
      React.createElement(Root, {
        ...this._props,
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        children: React.createElement(ProfilePhoto, {
          clinicUserId: this.clinicUserId,
          fileUploadService: this._fileUpload,
          size: this.size,
          staffProfiles: this._props.staffProfiles,
        }),
      }),
      this.containerRef.nativeElement,
    );
  }
}
