// Models
// =============================================
export var ContentAssignmentActionTypes;
(function (ContentAssignmentActionTypes) {
    // Server Side
    ContentAssignmentActionTypes["GetContentEntries"] = "[Patient-CA] Get Content Entries";
    ContentAssignmentActionTypes["GetContentEntriesSuccess"] = "[Patient-CA] Get Content Entries Success";
    ContentAssignmentActionTypes["GetContentEntriesError"] = "[Patient-CA] Get Content Entries Error";
    ContentAssignmentActionTypes["GetContentTypes"] = "[Patient-CA] Get Content Types";
    ContentAssignmentActionTypes["GetContentTypesSuccess"] = "[Patient-CA] Get Content Types Success";
    ContentAssignmentActionTypes["GetContentTypesError"] = "[Patient-CA] Get Content Types Error";
    ContentAssignmentActionTypes["GetPatientAssignedContent"] = "[Patient-CA] Get Patient Assigned Content";
    ContentAssignmentActionTypes["GetPatientAssignedContentSuccess"] = "[Patient-CA] Get Patient Assigned Content Success";
    ContentAssignmentActionTypes["GetPatientAssignedContentError"] = "[Patient-CA] Get Patient Assigned Content Error";
    ContentAssignmentActionTypes["GetAllPatientAssignedContent"] = "[Patient-CA] Get All Patient Assigned Content";
    ContentAssignmentActionTypes["GetAllPatientAssignedContentSuccess"] = "[Patient-CA] Get All Patient Assigned Content Success";
    ContentAssignmentActionTypes["GetAllPatientAssignedContentError"] = "[Patient-CA] Get All Patient Assigned Content Error";
    ContentAssignmentActionTypes["GetPatientViewOfContent"] = "[Patient-CA] Get All Patient View Of Content";
    ContentAssignmentActionTypes["GetPatientViewOfContentSuccess"] = "[Patient-CA] Get All Patient View Of Content Success";
    ContentAssignmentActionTypes["GetPatientViewOfContentError"] = "[Patient-CA] Get All Patient View Of Content Error";
    ContentAssignmentActionTypes["CreatePatientAssignedContent"] = "[Patient-CA] Create Patient Assigned Content";
    ContentAssignmentActionTypes["CreatePatientAssignedContentSuccess"] = "[Patient-CA] Create Patient Assigned Content Success";
    ContentAssignmentActionTypes["CreatePatientAssignedContentError"] = "[Patient-CA] Create Patient Assigned Content Error";
    ContentAssignmentActionTypes["DeletePatientAssignedContent"] = "[Patient-CA] Delete Patient Assigned Content";
    ContentAssignmentActionTypes["DeletePatientAssignedContentSuccess"] = "[Patient-CA] Delete Patient Assigned Content Success";
    ContentAssignmentActionTypes["DeletePatientAssignedContentError"] = "[Patient-CA] Delete Patient Assigned Content Error";
    // Patient Only Content Assignments
    ContentAssignmentActionTypes["GetPatientOnlyAssignments"] = "[Patient-CA] Get Patient Only Assigned Content";
    ContentAssignmentActionTypes["GetPatientOnlyAssignmentsSuccess"] = "[Patient-CA] Get Patient Only Assigned Content Success";
    ContentAssignmentActionTypes["GetPatientOnlyAssignmentsError"] = "[Patient-CA] Get Patient Only Assigned Content Error";
    ContentAssignmentActionTypes["CreatePatientOnlyAssignments"] = "[Patient-CA] Create Patient Only Assigned Content";
    ContentAssignmentActionTypes["CreatePatientOnlyAssignmentsSuccess"] = "[Patient-CA] Create Patient Only Assigned Content Success";
    ContentAssignmentActionTypes["CreatePatientOnlyAssignmentsError"] = "[Patient-CA] Create Patient Only Assigned Content Error";
    ContentAssignmentActionTypes["UpdatePatientOnlyAssignments"] = "[Patient-CA] Update Patient Only Assigned Content";
    ContentAssignmentActionTypes["UpdatePatientOnlyAssignmentsSuccess"] = "[Patient-CA] Update Patient Only Assigned Content Success";
    ContentAssignmentActionTypes["UpdatePatientOnlyAssignmentsError"] = "[Patient-CA] Update Patient Only Assigned Content Error";
    ContentAssignmentActionTypes["DeletePatientOnlyAssignments"] = "[Patient-CA] Delete Patient Only Assigned Content";
    ContentAssignmentActionTypes["DeletePatientOnlyAssignmentsSuccess"] = "[Patient-CA] Delete Patient Only Assigned Content Success";
    ContentAssignmentActionTypes["DeletePatientOnlyAssignmentsError"] = "[Patient-CA] Delete Patient Only Assigned Content Error";
    // UI
    ContentAssignmentActionTypes["SetContentTypeToPatientDocs"] = "[Patient-CA] Set Active Content Type To Patient Documents Category";
    ContentAssignmentActionTypes["SetContentTypeToNonPatientDocs"] = "[Patient-CA] Set Active Content Type To Non Patient Documents Category";
    ContentAssignmentActionTypes["SetActiveContentType"] = "[Patient-CA] Set Active Content Type in content picker";
    ContentAssignmentActionTypes["SetContentSelectFilter"] = "[Patient-CA] Set Content Select Filter String";
    ContentAssignmentActionTypes["SetAssignedContentFilter"] = "[Patient-CA] Set Assigned Content Filter String";
    ContentAssignmentActionTypes["ChangeAssignedContentView"] = "[Patient-CA] Change Assigned Content View";
    ContentAssignmentActionTypes["OpenPatientViewFolder"] = "[Patient-CA] Open Patient View Folder";
    ContentAssignmentActionTypes["OpenItemList"] = "[Patient-CA] Open Item List";
    ContentAssignmentActionTypes["OpenPackList"] = "[Patient-CA] Open Pack List";
    ContentAssignmentActionTypes["ChangeContentTab"] = "[Patient-CA] Change Content Tab";
    ContentAssignmentActionTypes["SetPatientId"] = "[Patient-CA] Set Current Patient Id";
})(ContentAssignmentActionTypes || (ContentAssignmentActionTypes = {}));
// Action Creators
// ===============================================
// Server Side
var GetContentEntries = /** @class */ (function () {
    function GetContentEntries() {
        this.type = ContentAssignmentActionTypes.GetContentEntries;
    }
    return GetContentEntries;
}());
export { GetContentEntries };
var GetContentEntriesSuccess = /** @class */ (function () {
    function GetContentEntriesSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetContentEntriesSuccess;
    }
    return GetContentEntriesSuccess;
}());
export { GetContentEntriesSuccess };
var GetContentEntriesError = /** @class */ (function () {
    function GetContentEntriesError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetContentEntriesError;
    }
    return GetContentEntriesError;
}());
export { GetContentEntriesError };
var GetContentTypes = /** @class */ (function () {
    function GetContentTypes() {
        this.type = ContentAssignmentActionTypes.GetContentTypes;
    }
    return GetContentTypes;
}());
export { GetContentTypes };
var GetContentTypesSuccess = /** @class */ (function () {
    function GetContentTypesSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetContentTypesSuccess;
    }
    return GetContentTypesSuccess;
}());
export { GetContentTypesSuccess };
var GetContentTypesError = /** @class */ (function () {
    function GetContentTypesError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetContentTypesError;
    }
    return GetContentTypesError;
}());
export { GetContentTypesError };
var GetPatientAssignedContent = /** @class */ (function () {
    function GetPatientAssignedContent(
    // patient Id
    payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientAssignedContent;
    }
    return GetPatientAssignedContent;
}());
export { GetPatientAssignedContent };
var GetPatientAssignedContentSuccess = /** @class */ (function () {
    function GetPatientAssignedContentSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientAssignedContentSuccess;
    }
    return GetPatientAssignedContentSuccess;
}());
export { GetPatientAssignedContentSuccess };
var GetPatientAssignedContentError = /** @class */ (function () {
    function GetPatientAssignedContentError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientAssignedContentError;
    }
    return GetPatientAssignedContentError;
}());
export { GetPatientAssignedContentError };
var GetAllPatientAssignedContent = /** @class */ (function () {
    function GetAllPatientAssignedContent(
    // patient Id
    payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetAllPatientAssignedContent;
    }
    return GetAllPatientAssignedContent;
}());
export { GetAllPatientAssignedContent };
var GetAllPatientAssignedContentSuccess = /** @class */ (function () {
    function GetAllPatientAssignedContentSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetAllPatientAssignedContentSuccess;
    }
    return GetAllPatientAssignedContentSuccess;
}());
export { GetAllPatientAssignedContentSuccess };
var GetAllPatientAssignedContentError = /** @class */ (function () {
    function GetAllPatientAssignedContentError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetAllPatientAssignedContentError;
    }
    return GetAllPatientAssignedContentError;
}());
export { GetAllPatientAssignedContentError };
var GetPatientViewOfContent = /** @class */ (function () {
    function GetPatientViewOfContent(
    // patient Id
    payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientViewOfContent;
    }
    return GetPatientViewOfContent;
}());
export { GetPatientViewOfContent };
var GetPatientViewOfContentSuccess = /** @class */ (function () {
    function GetPatientViewOfContentSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientViewOfContentSuccess;
    }
    return GetPatientViewOfContentSuccess;
}());
export { GetPatientViewOfContentSuccess };
var GetPatientViewOfContentError = /** @class */ (function () {
    function GetPatientViewOfContentError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientViewOfContentError;
    }
    return GetPatientViewOfContentError;
}());
export { GetPatientViewOfContentError };
var CreatePatientAssignedContent = /** @class */ (function () {
    function CreatePatientAssignedContent(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.CreatePatientAssignedContent;
    }
    return CreatePatientAssignedContent;
}());
export { CreatePatientAssignedContent };
var CreatePatientAssignedContentSuccess = /** @class */ (function () {
    function CreatePatientAssignedContentSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.CreatePatientAssignedContentSuccess;
    }
    return CreatePatientAssignedContentSuccess;
}());
export { CreatePatientAssignedContentSuccess };
var CreatePatientAssignedContentError = /** @class */ (function () {
    function CreatePatientAssignedContentError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.CreatePatientAssignedContentError;
    }
    return CreatePatientAssignedContentError;
}());
export { CreatePatientAssignedContentError };
var DeletePatientAssignedContent = /** @class */ (function () {
    function DeletePatientAssignedContent(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.DeletePatientAssignedContent;
    }
    return DeletePatientAssignedContent;
}());
export { DeletePatientAssignedContent };
var DeletePatientAssignedContentSuccess = /** @class */ (function () {
    function DeletePatientAssignedContentSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.DeletePatientAssignedContentSuccess;
    }
    return DeletePatientAssignedContentSuccess;
}());
export { DeletePatientAssignedContentSuccess };
var DeletePatientAssignedContentError = /** @class */ (function () {
    function DeletePatientAssignedContentError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.DeletePatientAssignedContentError;
    }
    return DeletePatientAssignedContentError;
}());
export { DeletePatientAssignedContentError };
// Patient Only Content Related Actions
// ===============================================================
var GetPatientOnlyAssignments = /** @class */ (function () {
    function GetPatientOnlyAssignments(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientOnlyAssignments;
    }
    return GetPatientOnlyAssignments;
}());
export { GetPatientOnlyAssignments };
var GetPatientOnlyAssignmentsSuccess = /** @class */ (function () {
    function GetPatientOnlyAssignmentsSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientOnlyAssignmentsSuccess;
    }
    return GetPatientOnlyAssignmentsSuccess;
}());
export { GetPatientOnlyAssignmentsSuccess };
var GetPatientOnlyAssignmentsError = /** @class */ (function () {
    function GetPatientOnlyAssignmentsError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.GetPatientOnlyAssignmentsError;
    }
    return GetPatientOnlyAssignmentsError;
}());
export { GetPatientOnlyAssignmentsError };
var CreatePatientOnlyAssignments = /** @class */ (function () {
    function CreatePatientOnlyAssignments(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.CreatePatientOnlyAssignments;
    }
    return CreatePatientOnlyAssignments;
}());
export { CreatePatientOnlyAssignments };
var CreatePatientOnlyAssignmentsSuccess = /** @class */ (function () {
    function CreatePatientOnlyAssignmentsSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.CreatePatientOnlyAssignmentsSuccess;
    }
    return CreatePatientOnlyAssignmentsSuccess;
}());
export { CreatePatientOnlyAssignmentsSuccess };
var CreatePatientOnlyAssignmentsError = /** @class */ (function () {
    function CreatePatientOnlyAssignmentsError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.CreatePatientOnlyAssignmentsError;
    }
    return CreatePatientOnlyAssignmentsError;
}());
export { CreatePatientOnlyAssignmentsError };
var UpdatePatientOnlyAssignments = /** @class */ (function () {
    function UpdatePatientOnlyAssignments(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.UpdatePatientOnlyAssignments;
    }
    return UpdatePatientOnlyAssignments;
}());
export { UpdatePatientOnlyAssignments };
var UpdatePatientOnlyAssignmentsSuccess = /** @class */ (function () {
    function UpdatePatientOnlyAssignmentsSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.UpdatePatientOnlyAssignmentsSuccess;
    }
    return UpdatePatientOnlyAssignmentsSuccess;
}());
export { UpdatePatientOnlyAssignmentsSuccess };
var UpdatePatientOnlyAssignmentsError = /** @class */ (function () {
    function UpdatePatientOnlyAssignmentsError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.UpdatePatientOnlyAssignmentsError;
    }
    return UpdatePatientOnlyAssignmentsError;
}());
export { UpdatePatientOnlyAssignmentsError };
var DeletePatientOnlyAssignments = /** @class */ (function () {
    function DeletePatientOnlyAssignments(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.DeletePatientOnlyAssignments;
    }
    return DeletePatientOnlyAssignments;
}());
export { DeletePatientOnlyAssignments };
var DeletePatientOnlyAssignmentsSuccess = /** @class */ (function () {
    function DeletePatientOnlyAssignmentsSuccess(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.DeletePatientOnlyAssignmentsSuccess;
    }
    return DeletePatientOnlyAssignmentsSuccess;
}());
export { DeletePatientOnlyAssignmentsSuccess };
var DeletePatientOnlyAssignmentsError = /** @class */ (function () {
    function DeletePatientOnlyAssignmentsError(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.DeletePatientOnlyAssignmentsError;
    }
    return DeletePatientOnlyAssignmentsError;
}());
export { DeletePatientOnlyAssignmentsError };
// UI
// =================================================================
var SetContentTypeToPatientDocs = /** @class */ (function () {
    function SetContentTypeToPatientDocs() {
        this.type = ContentAssignmentActionTypes.SetContentTypeToPatientDocs;
    }
    return SetContentTypeToPatientDocs;
}());
export { SetContentTypeToPatientDocs };
var SetContentTypeToNonPatientDocs = /** @class */ (function () {
    function SetContentTypeToNonPatientDocs() {
        this.type = ContentAssignmentActionTypes.SetContentTypeToNonPatientDocs;
    }
    return SetContentTypeToNonPatientDocs;
}());
export { SetContentTypeToNonPatientDocs };
var SetActiveContentType = /** @class */ (function () {
    function SetActiveContentType(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.SetActiveContentType;
    }
    return SetActiveContentType;
}());
export { SetActiveContentType };
var SetContentSelectFilter = /** @class */ (function () {
    function SetContentSelectFilter(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.SetContentSelectFilter;
    }
    return SetContentSelectFilter;
}());
export { SetContentSelectFilter };
var SetAssignedContentFilter = /** @class */ (function () {
    function SetAssignedContentFilter(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.SetAssignedContentFilter;
    }
    return SetAssignedContentFilter;
}());
export { SetAssignedContentFilter };
var ChangeAssignedContentView = /** @class */ (function () {
    function ChangeAssignedContentView() {
        this.type = ContentAssignmentActionTypes.ChangeAssignedContentView;
    }
    return ChangeAssignedContentView;
}());
export { ChangeAssignedContentView };
var OpenItemList = /** @class */ (function () {
    function OpenItemList() {
        this.type = ContentAssignmentActionTypes.OpenItemList;
    }
    return OpenItemList;
}());
export { OpenItemList };
var OpenPackList = /** @class */ (function () {
    function OpenPackList() {
        this.type = ContentAssignmentActionTypes.OpenPackList;
    }
    return OpenPackList;
}());
export { OpenPackList };
var ChangeContentTab = /** @class */ (function () {
    function ChangeContentTab(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.ChangeContentTab;
    }
    return ChangeContentTab;
}());
export { ChangeContentTab };
var SetPatientId = /** @class */ (function () {
    function SetPatientId(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.SetPatientId;
    }
    return SetPatientId;
}());
export { SetPatientId };
var OpenPatientViewFolder = /** @class */ (function () {
    function OpenPatientViewFolder(payload) {
        this.payload = payload;
        this.type = ContentAssignmentActionTypes.OpenPatientViewFolder;
    }
    return OpenPatientViewFolder;
}());
export { OpenPatientViewFolder };
