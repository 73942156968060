import { Card, CardContent, CircularProgress, Popover, } from '@material-ui/core';
import { FilterChip, Label } from '@react/components';
import { SIDERecipeEventType } from '@react/lib/api/enum';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useSIDERecipeTriggerQuery } from '../../../../hooks';
import { LoaderWrapper, StyledContainer, StyledList } from './index.styled';
var RecipeTriggers = function (_a) {
    var recipe = _a.recipe;
    var t = useTranslations().t;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var popoverAnchor = React.useRef(null);
    var handleClick = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var _c = useSIDERecipeTriggerQuery({
        id: recipe.id,
    }), data = _c.data, isLoading = _c.isLoading, isError = _c.isError;
    useErrorToast(isError, {
        title: t.Admin.SIDE.TriggerListLoadingErrorToastHeader,
        message: t.Admin.SIDE.TriggerListErrorToastMessage,
    });
    if (isLoading) {
        return (React.createElement(Card, null,
            React.createElement(LoaderWrapper, null,
                React.createElement(CircularProgress, null))));
    }
    var triggers = data.data;
    var initials = triggers.slice(0, 5);
    var rest = triggers.slice(5);
    return (React.createElement(React.Fragment, null,
        triggers.length === 0 && React.createElement("span", null, t.Admin.SIDE.NoTriggersAvailable),
        triggers.length > 0 && (React.createElement(CardContent, null,
            React.createElement(Label, null, recipe.eventname === SIDERecipeEventType.Appointment
                ? t.Admin.SIDE.AppointmentTriggerHeader
                : t.Admin.SIDE.MedicationTriggerHeader),
            React.createElement(StyledContainer, null,
                initials.map(function (item) {
                    return (React.createElement(FilterChip, { bgColor: '#f7f7fb', label: item.name, key: item.id }));
                }),
                rest.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(FilterChip, { bgColor: '#f7f7fb', ref: popoverAnchor, label: "...", clickable: true, onClick: handleClick }),
                    React.createElement(Popover, { id: "Trigger-Chip-Popver", open: open, anchorEl: popoverAnchor.current, onClose: handleClose, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        } },
                        React.createElement(StyledList, null, rest.map(function (item) { return (React.createElement("li", { key: item.id }, item.name)); }))))))))));
};
var ɵ0 = RecipeTriggers;
export default RecipeTriggers;
export { ɵ0 };
