import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, switchMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../auth/reducers';
import { Message } from '../../models/Message';
import { ToastOptions } from '../../models/ToastOptions';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
import {
  AlterMessageActionTypes,
  ToastDismissed,
  ToastDisplayed,
} from '../actions/alter-message.actions';
import { ApplyFilter, OpenThread } from '../actions/message-ui.actions';
import {
  NewMessageActionTypes,
  SendNewThread,
  SendOutboundMessage,
  ShowNewConvoToastReact,
  ShowRerouteToast,
} from '../actions/new-message.actions';

@Injectable()
export class ToastEffects {
  @Effect()
  sendOutboundMessage$ = this.actions$.pipe(
    ofType<SendOutboundMessage>(NewMessageActionTypes.SendOutboundMessage),
    switchMap((action) => {
      return new Promise((res) => {
        const { newMessage, info } = action.payload;
        const toast = this.toastr.show(
          this._toastText.SendingOutboundBody(newMessage.patientName),
          this._toastText.SendingOutboundTitle,
          {
            timeOut: 0,
            closeButton: false,
            toastClass: `toast custom-toast info`,
          },
        );

        const success = new ToastOptions({
          toast,
          toastRef: toast.toastRef.componentInstance,
          undoAction: null,
          title: this._toastText.OutboundSentTitle,
          message: this._toastText.OutboundSentBody(newMessage.patientName),
          type: 'success',
          options: {},
        });

        const error = new ToastOptions({
          toast,
          toastRef: toast.toastRef.componentInstance,
          undoAction: null,
          title: this._toastText.ErrorEncountered,
          message: this._toastText.ErrorSending(newMessage.patientName),
          type: 'error',
          options: {},
        });

        res(
          new SendNewThread({
            messageStarringEnabled: action.payload.messageStarringEnabled,
            data: {
              newMessage,
              messageInfo: info,
            },
            toast: {
              success,
              error,
            },
          }),
        );
      });
    }),
  );

  @Effect()
  displayRerouteToast$ = this.actions$.pipe(
    ofType<ShowRerouteToast>(NewMessageActionTypes.ShowRerouteToast),
    mergeMap((action) => {
      return new Promise((res) => {
        const t = action.payload.toast;
        const toast = t.toast;
        const toastRef = t.toastRef;
        const route = action.payload.route;

        toastRef.alterToast({
          class: {
            classesToRemove: ['info'],
            classToAdd: `success route`,
          },
          title: t.title,
          message: t.message,
        });

        if (route) {
          // show success
          let reroute = false;

          // triggered if button is pressed
          toast.onAction.subscribe(() => {
            // trigger reroute
            const messageInfo = action.payload.other;
            reroute = true;
            const latestMessage: Message =
              this.createMessageFromMessageInfo(messageInfo);
            if (latestMessage) {
              this._navigationService.navigate([route]);

              toastRef.remove();

              this._store.dispatch(
                new ApplyFilter(latestMessage.MessageType.toLowerCase()),
              );
              this._store.dispatch(new OpenThread(latestMessage));

              if (action.payload.afterAction) {
                this._store.dispatch(action.payload.afterAction);
              }

              res(new ToastDismissed());
            } else {
              this._error.handleError(new Error('message not found'));
              this._navigationService.navigate([route]);
              res(new ToastDismissed());
            }
          });

          // triggered when toast disappears
          toast.onHidden.subscribe(() => {
            res(new ToastDismissed());
          });

          setTimeout(() => {
            if (!reroute) {
              toastRef.remove();
            }
          }, 5000);
        } else {
          // show error
          toastRef.alterToast({
            class: {
              classesToRemove: ['info'],
              classToAdd: `${t.type} + final`,
            },
            title: t.title,
            message: t.message,
          });
          setTimeout(() => {
            toastRef.remove();
            res(new ToastDismissed());
          }, 3000);
        }
      });
    }),
  );

  @Effect()
  displayToast$ = this.actions$.pipe(
    ofType<ToastDisplayed>(AlterMessageActionTypes.ToastDisplayed),
    switchMap((action) => {
      return new Promise((res) => {
        const t = action.payload.toast;
        let toastRef = t.toastRef;
        let timeout = 3000;

        if (t.options && t.options.timeOut) {
          timeout = t.options.timeOut;
        }

        // if toast has no undo Action and no toast reference
        // show toast with a close button and auto exit after 3
        // seconds
        if (!t.undoAction && !toastRef) {
          // show toast
          const toast = this.toastr.show(t.message, t.title, {
            toastClass: `toast custom-toast ${t.type}`,
            timeOut: timeout,
          });

          toast.onHidden.subscribe(() => {
            res(new ToastDismissed());
          });
        }

        // if toast has has no undo Action and has a toast reference
        // amend existing toast instance with passed props and
        // manually remove toast from screen
        if (!t.undoAction && toastRef) {
          // amend toast to show und
          toastRef.alterToast({
            class: {
              classesToRemove: ['undo'],
              classToAdd: `${t.type} + final`,
            },
            title: t.title,
            message: t.message,
          });

          // remove manually
          setTimeout(() => {
            toastRef.remove();
            res(new ToastDismissed());
          }, timeout);
        }

        // if toast has an undo action display with undo button
        // and no close button, manage closing independently
        if (t.undoAction) {
          // capture if undo button is pressed prior to manual timeout
          let undoActivated = false;

          // show toast
          const activeToast = this.toastr.show(t.message, t.title, {
            toastClass: `toast custom-toast ${t.type} undoable`,
            closeButton: false,
            disableTimeOut: true,
            timeOut: 0,
          });

          // capture reference to toast instance
          toastRef = activeToast.toastRef.componentInstance;

          // subscribe to undo button press
          activeToast.onAction.subscribe(() => {
            // record click in higher scoped variable
            undoActivated = true;

            // alter toast to show action is being undone
            toastRef.alterToast({
              class: {
                classesToRemove: [t.type, 'undoable'],
                classToAdd: 'undo',
              },
              title: this._toastText.UndoingActionTitle,
              message: this._toastText.UndoingActionBody(t.title),
            });

            // assign toast reference to toastRef in undoAction
            t.undoAction.payload.toast.toastRef = toastRef;

            // create new changelabel action to undo prior
            // action
            res(t.undoAction);
          });

          activeToast.onHidden.subscribe(() => {
            if (!undoActivated) {
              res(new ToastDismissed());
            }
          });

          // remove toast if nothing happens within 5 seconds
          setTimeout(() => {
            if (!undoActivated) {
              toastRef.remove();
            }
          }, 5000);
        }
      });
    }),
  );

  @Effect()
  displayNewConvoToastReact$ = this.actions$.pipe(
    ofType<ShowNewConvoToastReact>(
      NewMessageActionTypes.ShowNewConvoToastReact,
    ),
    switchMap((action) => {
      return new Promise((res) => {
        const { title, message, onActionClicked } = action.payload;
        const toast = this.toastr.show(message, title, {
          timeOut: 5000,
          closeButton: false,
          toastClass: `toast custom-toast success route`,
        });
        const toastRef = toast.toastRef.componentInstance;

        // triggered if button is pressed
        toast.onAction.subscribe(() => {
          onActionClicked();
          toastRef.remove();
          res(new ToastDismissed());
        });

        // triggered when toast disappears
        toast.onHidden.subscribe(() => {
          res(new ToastDismissed());
        });
      });
    }),
  );

  private _toastText: any;
  private _sender: string;

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler,
    private _navigationService: NavigationService,
  ) {
    this._store
      .pipe(select(fromSettings.getSectionTranslations('MessageToast')))
      .subscribe((val) => {
        this._toastText = val;
      });
    this._store.pipe(select(fromAuth.getUserName)).subscribe((s) => {
      this._sender = s;
    });
  }

  private createMessageFromMessageInfo(messageInfo): Message {
    return {
      MessageId: messageInfo.id,
      MessageStatusId: 0,
      MessageTypeId: messageInfo.typeId,
      MessageType: messageInfo.type,
      Content: messageInfo.content,
      ContentType: 'text',
      SentDate: new Date(),
      Read: false,
      PatientSent: false,
      PatientFirstName: messageInfo.patientFirstName,
      PatientLastName: messageInfo.patientLastName,
      PatientIdentifier: messageInfo.patientId,
      DateOfBirth: messageInfo.patientDoB || null,
      Sender: this._sender,
      ReadDate: null,
      ClinicRead: messageInfo.ClinicRead,
      PatientRead: messageInfo.PatientRead,
      IsStarred: messageInfo.IsStarred,
      MessageSubject: messageInfo.subject,

      // TODO: refactor so that sending and receiving use different models
      MessageItemId: 0,
      PatientId: 0,
    };
  }
}
