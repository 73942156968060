import { ContactInfo } from '../../models/ContactInfo';
import { PartnerInfo } from '../../models/PartnerInfo';
import { PatientInfo } from '../../models/PatientInfo';
export var PatientSection;
(function (PatientSection) {
    PatientSection["patientInformation"] = "patientInformation";
    PatientSection["partner"] = "partner";
    PatientSection["contact"] = "contact";
    PatientSection["labResults"] = "labResults";
    PatientSection["patientDocuments"] = "patientDocuments";
    PatientSection["manageContent"] = "manageContent";
})(PatientSection || (PatientSection = {}));
var PatientInfoComponent = /** @class */ (function () {
    function PatientInfoComponent() {
        this.patientSection = PatientSection;
        this.config = {};
    }
    return PatientInfoComponent;
}());
export { PatientInfoComponent };
