import * as React from 'react';

export const EmptyRecipeListIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="60"
    height="61"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="#6538A5" fillRule="nonzero" fillOpacity=".35">
      <path d="M16.834 43.152a1.17 1.17 0 1 0 1.657-1.657 1.17 1.17 0 1 0-1.657 1.657ZM5.029 54.97c.458.458 1.2.457 1.657 0l8.491-8.505a1.172 1.172 0 0 0-1.658-1.656l-8.491 8.504c-.458.458-.457 1.2 0 1.657Z" />
      <path d="M1.718 58.292a5.853 5.853 0 0 0 8.285 0l.003-.003-.004.004 19.264-19.276a3.507 3.507 0 0 0 1.023-2.665c.33-.05.656-.146.968-.293L53.44 25.6l.045-.023c4.302-2.26 6.794-6.493 6.49-11.799-.218-3.808-1.885-7.25-4.193-9.562-6.257-6.258-17.62-5.605-21.384 2.34-10.884 23.208-10.608 22.199-10.752 23.15-1-.05-1.956.315-2.667 1.025L1.72 50.006a5.854 5.854 0 0 0-.001 8.286Zm50.699-34.803-22.16 10.45c-.446.21-.98.118-1.328-.232l-.25-.25 22.369-13.19.036-.024c2.395-1.533 5.221-4.2 6.49-7.289.568 4.32-1.093 8.389-5.157 10.535Zm3.453-13.142c-.357 3.004-3.373 6.203-6.032 7.911L34.133 27.52 54.892 6.762c.81 1.113 1.131 2.293.978 3.585Zm-2.636-5.241L32.477 25.86l9.261-15.703c1.708-2.658 4.906-5.674 7.91-6.031 1.291-.154 2.471.167 3.586.979ZM26.058 29.739 36.506 7.58c2.132-4.036 6.184-5.729 10.536-5.156-2.957 1.214-5.963 4.214-7.311 6.525l-13.192 22.37c-.097-.097-.172-.17-.266-.266a1.176 1.176 0 0 1-.215-1.313Zm-3.422 2.648a1.172 1.172 0 0 1 1.658 0l3.314 3.315c.457.457.457 1.2 0 1.657l-.828.829-4.972-4.971.828-.83ZM3.376 51.664l16.775-16.79 4.972 4.971-16.777 16.79a3.51 3.51 0 0 1-4.971-.001 3.511 3.511 0 0 1 0-4.97Z" />
    </g>
  </svg>
);
