import {
  SettingsActionsUnion,
  SettingsActionTypes,
} from '../actions/settings.actions';
import { translations } from '../data/settings';
import { Translation } from '../models/transalation';

export interface State {
  language: string;
  languageSet: boolean;
  // text object follows following model: Language => Section => Key => (string | function)
  text: Translation;
}

export const initialState: State = {
  language: 'en-gb',
  languageSet: false,
  text: translations,
};

export function reducer(state = initialState, action: SettingsActionsUnion) {
  switch (action.type) {
    case SettingsActionTypes.ChangeLanguage: {
      return {
        ...state,
        language: action.payload,
        languageSet: true,
      };
    }
    default:
      return state;
  }
}

// Selectors
export const getCurrentLanguage = (state: State) => state.language;
export const getLanguageSet = (state: State) => state.languageSet;
