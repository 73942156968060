import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromContent from '../../reducers';
var UseTemplateComponent = /** @class */ (function () {
    function UseTemplateComponent(_store, _navigationService, _route) {
        var _this = this;
        this._store = _store;
        this._navigationService = _navigationService;
        this._route = _route;
        this.chooseTemplateVisible = false;
        this.templates$ = this._store.pipe(select(fromContent.getTemplates));
        this._sub = this._route.params
            .pipe(map(function (_a) {
            var id = _a.id, type = _a.type;
            return ({ id: +id, type: type });
        }))
            .subscribe(function (_a) {
            var id = _a.id, type = _a.type;
            _this.categoryId = id;
            _this.type = type;
        });
    }
    UseTemplateComponent.prototype.ngOnDestroy = function () {
        this._sub.unsubscribe();
    };
    UseTemplateComponent.prototype.useTemplate = function (id) {
        this._navigationService.navigate([
            'content',
            'doc',
            'create',
            this.type,
            this.categoryId,
            id,
        ]);
    };
    UseTemplateComponent.prototype.hideTemplateSelector = function () {
        this.chooseTemplateVisible = false;
    };
    UseTemplateComponent.prototype.showTemplateSelector = function () {
        this.chooseTemplateVisible = true;
    };
    UseTemplateComponent.prototype.useBlank = function () {
        this._navigationService.navigate([
            'content',
            'doc',
            'create',
            this.type,
            this.categoryId,
            0,
        ]);
    };
    return UseTemplateComponent;
}());
export { UseTemplateComponent };
