import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import Root from '@react/components/Root';
export var StyledRoot = styled(Root)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
export var PageContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  min-height: 100%;\n  padding: ", "px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  min-height: 100%;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var LoadingErrorContainer = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var Main = styled.main(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  min-height: 100%;\n  padding-left: ", "px;\n"], ["\n  flex: 1;\n  min-height: 100%;\n  padding-left: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
