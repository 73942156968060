import { Action } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { DisplayToastPayload } from 'src/app/core/models/DisplayToastPayload';
import {
  ForgottenPasswordForm,
  SubmitNewPasswordForm
} from '../models/LoginSubmission';
import {
  BusinessUnitClinicInfoResponse,
  ClinicInfoResponse
} from '../models/server-response/clinic-info-response';
import { Authenticate, User } from '../models/user';
import { CompletePasswordForm } from './../models/user';

// Action Types
// =============================================
export enum AuthActionTypes {
  GetClinicId = '[Auth] Get Clinic Id',
  GetClinicIdSuccess = '[Auth] Get Clinic Id Success',
  GetClinicIdError = '[Auth] Get Clinic Id Failure',
  EnableForm = '[Auth] Enable Form',
  CoreLogin = '[Auth] Core Login',
  CoreLoginSuccess = '[Auth] Core Login Success',
  CoreLoginFailure = '[Auth] Core Login Failure',
  CompletePassword = '[Auth] Core Complete Password',
  CompletePasswordSuccess = '[Auth] Core Complete Password Success',
  CompletePasswordFailure = '[Auth] Core Complete Password Failure',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFailure = '[Auth] Logout Failure',
  LoginRedirect = '[Auth] Login Redirect',
  ClearCodeGenerated = '[Auth] Set Code Generated',
  GenerateResetCode = '[Auth] Generate Reset Code',
  GenerateResetCodeSuccess = '[Auth] Generate Reset Code Success',
  GenerateResetCodeFailure = '[Auth] Generate Reset Code Failure',
  ResetPassword = '[Auth] Reset Password Code',
  ResetPasswordSuccess = '[Auth] Reset Password Code Success',
  ResetPasswordFailure = '[Auth] Reset Password Code Failure',
  TempSuccess = '[Auth] Temp Password Success',
  ClearLoginError = '[Auth] Clear Login Error',
  OTPRequired = '[Auth] OTP Required',
  OTPRejected = '[Auth] OTP Rejected',
  VerifyOTP = '[Auth] Verify OTP',
  OTPLoginFailure = '[Auth] OTP Login Failed'
}

// Action Creators
// =============================================
export class TempSuccess implements Action {
  readonly type = AuthActionTypes.TempSuccess;

  constructor(public payload: { user: CognitoUser }) {}
}
export class GetClinicId implements Action {
  readonly type = AuthActionTypes.GetClinicId;
}
export class EnableForm implements Action {
  readonly type = AuthActionTypes.EnableForm;
}
export class GetClinicIdSuccess implements Action {
  readonly type = AuthActionTypes.GetClinicIdSuccess;

  constructor(
    public payload: ClinicInfoResponse & BusinessUnitClinicInfoResponse
  ) {}
}
export class GetClinicIdError implements Action {
  readonly type = AuthActionTypes.GetClinicIdError;

  constructor(public payload: any) {}
}

export class CoreLogin implements Action {
  readonly type = AuthActionTypes.CoreLogin;

  constructor(public payload: Authenticate) {}
}
export class CoreLoginSuccess implements Action {
  readonly type = AuthActionTypes.CoreLoginSuccess;

  constructor(public payload: { user: CognitoUser }) {}
}
export class CoreLoginFailure implements Action {
  readonly type = AuthActionTypes.CoreLoginFailure;

  constructor(public payload: any) {}
}

export class GenerateResetCode implements Action {
  readonly type = AuthActionTypes.GenerateResetCode;

  constructor(public payload: ForgottenPasswordForm) {}
}
export class GenerateResetCodeSuccess implements Action {
  readonly type = AuthActionTypes.GenerateResetCodeSuccess;

  constructor(public payload: any) {}
}
export class GenerateResetCodeFailure implements Action {
  readonly type = AuthActionTypes.GenerateResetCodeFailure;

  constructor(public payload: any) {}
}

export class ClearCodeGenerated implements Action {
  readonly type = AuthActionTypes.ClearCodeGenerated;
}

export class ResetPassword implements Action {
  readonly type = AuthActionTypes.ResetPassword;

  constructor(public payload: SubmitNewPasswordForm) {}
}
export class ResetPasswordSuccess implements Action {
  readonly type = AuthActionTypes.ResetPasswordSuccess;

  constructor(public payload: any) {}
}
export class ResetPasswordFailure implements Action {
  readonly type = AuthActionTypes.ResetPasswordFailure;

  constructor(public payload: any) {}
}

export class CompletePassword implements Action {
  readonly type = AuthActionTypes.CompletePassword;

  constructor(public payload: CompletePasswordForm) {}
}
export class CompletePasswordSuccess implements Action {
  readonly type = AuthActionTypes.CompletePasswordSuccess;

  constructor(public payload: { user: CognitoUser }) {}
}
export class CompletePasswordFailure implements Action {
  readonly type = AuthActionTypes.CompletePasswordFailure;

  constructor(public payload: any) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;

  constructor(
    public payload: {
      isCognito: boolean;
    }
  ) {}
}
export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;

  constructor(
    public payload: {
      isCognito: boolean;
    }
  ) {}
}
export class LogoutFailure implements Action {
  readonly type = AuthActionTypes.LogoutFailure;

  constructor(
    public payload: {
      isCognito: boolean;
    }
  ) {}
}
export class ClearLoginError implements Action {
  readonly type = AuthActionTypes.ClearLoginError;
}

export class OTPRequired implements Action {
  readonly type = AuthActionTypes.OTPRequired;

  constructor(public payload: { user: CognitoUser }) {}
}

export class VerifyOTP implements Action {
  readonly type = AuthActionTypes.VerifyOTP;

  constructor(
    public payload: {
      user: CognitoUser;
      code: string;
      toast: DisplayToastPayload;
    }
  ) {}
}

export class OTPRejected implements Action {
  readonly type = AuthActionTypes.OTPRejected;

  constructor(public payload: { user: CognitoUser }) {}
}

export class OTPLoginFailure implements Action {
  readonly type = AuthActionTypes.OTPLoginFailure;

  constructor(public payload: { toast: DisplayToastPayload }) {}
}

// Auth Actions Union
// =============================================
export type AuthActionsUnion =
  | EnableForm
  | GetClinicId
  | GetClinicIdSuccess
  | GetClinicIdError
  | CoreLogin
  | CoreLoginSuccess
  | CoreLoginFailure
  | CompletePassword
  | CompletePasswordSuccess
  | CompletePasswordFailure
  | TempSuccess
  | LoginRedirect
  | ClearCodeGenerated
  | GenerateResetCode
  | GenerateResetCodeSuccess
  | GenerateResetCodeFailure
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | Logout
  | LogoutSuccess
  | LogoutFailure
  | ClearLoginError
  | OTPRequired
  | OTPRejected
  | VerifyOTP
  | OTPLoginFailure;
