import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, } from 'rxjs/operators';
import * as fromSettings from '../../../settings/reducers';
import { ContentService } from '../../services/content.service';
var ChooseContentComponent = /** @class */ (function () {
    function ChooseContentComponent(_store, _dialogRef, _contentService, data) {
        this._store = _store;
        this._dialogRef = _dialogRef;
        this._contentService = _contentService;
        this.data = data;
        this.contentForm = new FormControl('', Validators.required);
    }
    ChooseContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.useAContentText$ = this._store.pipe(select(fromSettings.getSectionTranslations('UseAContent')));
        this._contentList$ = this._contentService.getGroupedEntryList(this.data.languageCode);
        this.filteredContent$ = combineLatest(this.contentForm.valueChanges.pipe(startWith(''), distinctUntilChanged(), debounceTime(250)), this._contentList$).pipe(map(function (_a) {
            var value = _a[0], groupedContent = _a[1];
            return _this._filter(value, groupedContent);
        }));
    };
    ChooseContentComponent.prototype._filter = function (value, groupedContent) {
        var val = typeof value === 'string' ? value : value.key;
        var results = groupedContent
            .map(function (item) {
            var filteredEntries = item.entries.filter(function (entry) {
                return entry.key.toLowerCase().includes(val.toLowerCase().trim());
            });
            return {
                id: item.id,
                name: item.name,
                entries: filteredEntries,
            };
        })
            .filter(function (item) { return item.entries.length > 0; });
        return results;
    };
    ChooseContentComponent.prototype.copyContent = function () {
        this._dialogRef.close({
            id: this._selectedValue,
        });
    };
    ChooseContentComponent.prototype.cancel = function () {
        this._dialogRef.close(null);
    };
    ChooseContentComponent.prototype.setSelectedValue = function (item) {
        this._selectedValue = item.value;
    };
    ChooseContentComponent.prototype.formatName = function (item) {
        return item.key;
    };
    return ChooseContentComponent;
}());
export { ChooseContentComponent };
