import * as React from 'react';

type FeaturesContextPayload = Readonly<object>;

export const FeaturesContext = React.createContext<FeaturesContextPayload>({});

export const FeaturesProvider: React.FC<{
  features: FeaturesContextPayload;
}> = ({ children, features }) => (
  <FeaturesContext.Provider value={features} children={children} />
);

export const useFeatures = () => React.useContext(FeaturesContext);
