import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { ConfirmActionComponent } from 'src/app/core/components/confirm-action/confirm-action.component';
import { Md5HashService } from 'src/app/core/services/md5-hash.service';
import { FileUpload } from 'src/app/core/services/s3.service';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { AddVideoComponent } from '../add-video/add-video.component';
import { ContentSectionErrorState } from './../../utils/content-section-error-state';
var ContentEditSectionsComponent = /** @class */ (function () {
    function ContentEditSectionsComponent(_controlContainer, dialog, _MD5, _S3) {
        this._controlContainer = _controlContainer;
        this.dialog = dialog;
        this._MD5 = _MD5;
        this._S3 = _S3;
        this.addSection = new EventEmitter();
        this.removeSection = new EventEmitter();
        this.setOrder = new EventEmitter();
        this.matcher = new ContentSectionErrorState();
        this.editorConfig = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ align: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ color: [] }],
                ['link', 'image', 'video'],
            ],
        };
    }
    Object.defineProperty(ContentEditSectionsComponent.prototype, "sections", {
        get: function () {
            return this.quillSectionsForm.get('sections');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentEditSectionsComponent.prototype, "attachments", {
        get: function () {
            return this.quillSectionsForm.get('attachments');
        },
        enumerable: true,
        configurable: true
    });
    ContentEditSectionsComponent.prototype.ngOnInit = function () {
        this.quillSectionsForm = this._controlContainer.control;
        if (!this.contentEditorEnabled) {
            this.editorConfig = {
                toolbar: [],
            };
        }
    };
    ContentEditSectionsComponent.prototype.setFocus = function (event) {
        event.target.focus();
    };
    ContentEditSectionsComponent.prototype.editorCreated = function (quill) {
        var editorInstance = quill;
        var toolbar = editorInstance.getModule('toolbar');
        toolbar.addHandler('image', this._handleImage.bind(this, editorInstance));
        toolbar.addHandler('video', this._handleVideo.bind(this, editorInstance));
    };
    ContentEditSectionsComponent.prototype.dropped = function () {
        var entriesCopy = [];
        for (var i = 0; i < this.sections.controls.length; i++) {
            var entry = this.sections.controls[i];
            var entryVal = tslib_1.__assign({}, entry.value, { ordernumber: i + 5 });
            entriesCopy.push(entryVal);
        }
        this.sections.reset(entriesCopy);
    };
    ContentEditSectionsComponent.prototype.handleRemoveSection = function (section, id) {
        var _this = this;
        var isEmpty = section.value.title.length === 0 && section.value.content.length === 0;
        if (!isEmpty) {
            var confirmDialog = this.dialog.open(ConfirmActionComponent, {
                data: {
                    message: this.text.ConfirmDelete,
                    text: {
                        Cancel: this.text.Cancel,
                        Confirm: this.text.Confirm,
                    },
                },
            });
            confirmDialog
                .afterClosed()
                .pipe(take(1))
                .subscribe(function (val) {
                if (val) {
                    _this.removeSection.emit(id);
                }
            });
        }
        else {
            this.removeSection.emit(id);
        }
    };
    ContentEditSectionsComponent.prototype._openVideoDialog = function () {
        var _this = this;
        return new Promise(function (res, rej) {
            var validAccepts = _this._S3.fetchAccepts('video');
            var fileName = _this._MD5.generateFileName();
            var videoDialog = _this.dialog.open(AddVideoComponent, {
                data: {
                    accept: validAccepts,
                    text: _this.videoText,
                    fileName: fileName,
                    type: 'video',
                    public: true,
                    patientOnly: false,
                    patientId: null,
                },
            });
            videoDialog
                .afterClosed()
                .pipe(take(1))
                .subscribe(function (file) {
                if (file && file.html) {
                    res(file.html);
                }
            });
        });
    };
    ContentEditSectionsComponent.prototype._openImageDialog = function () {
        var _this = this;
        return new Promise(function (res, rej) {
            var validAccepts = _this._S3.fetchAccepts('image');
            var fileName = _this._MD5.generateFileName();
            var fileDialog = _this.dialog.open(AddFileComponent, {
                data: {
                    accept: validAccepts,
                    text: _this.imageText,
                    fileName: fileName,
                    type: 'image',
                    public: true,
                    patientOnly: false,
                    patientId: null,
                },
            });
            fileDialog
                .afterClosed()
                .pipe(take(1))
                .subscribe(function (file) {
                if (file && file.attachment) {
                    res(file.attachment);
                }
                else {
                    res(null);
                }
            });
        });
    };
    ContentEditSectionsComponent.prototype._handleImage = function (editor) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var range, attachment;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        range = editor.getSelection(true);
                        return [4 /*yield*/, this._openImageDialog()];
                    case 1:
                        attachment = _a.sent();
                        if (attachment) {
                            editor.insertEmbed(range.index, 'image', attachment.uri, 'user');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ContentEditSectionsComponent.prototype._handleVideo = function (editor) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var range, html;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        range = editor.getSelection();
                        return [4 /*yield*/, this._openVideoDialog()];
                    case 1:
                        html = _a.sent();
                        if (html) {
                            editor.clipboard.dangerouslyPasteHTML(range.index, html, 'user');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return ContentEditSectionsComponent;
}());
export { ContentEditSectionsComponent };
