import * as tslib_1 from "tslib";
import { Label } from 'src/app/models/Label';
import { LoadMessageActionTypes, } from '../actions/load-message.actions';
import { mergeMessageArrays, replaceMessageInState } from './utils';
export function reducer(state, action) {
    switch (action.type) {
        case LoadMessageActionTypes.LoadMessagingSummarySuccess: {
            return tslib_1.__assign({}, state, { messagingSummary: action.payload });
        }
        case LoadMessageActionTypes.LoadingThread: {
            return tslib_1.__assign({}, state, { loadingThread: true });
        }
        case LoadMessageActionTypes.LoadedThread: {
            return tslib_1.__assign({}, state, { loadingThread: false });
        }
        case LoadMessageActionTypes.UpdateLatestMessage: {
            return tslib_1.__assign({}, state, { latestMessage: tslib_1.__assign({}, state.messageList[0]) });
        }
        case LoadMessageActionTypes.LoadMessages: {
            return tslib_1.__assign({}, state, { isLoadingConversations: true });
        }
        case LoadMessageActionTypes.LoadThreadSuccess: {
            var id_1 = action.payload.id;
            var messages = state.messageList.map(function (m) {
                return m.MessageId !== id_1 ? m : tslib_1.__assign({}, m, { Read: true });
            });
            return tslib_1.__assign({}, state, { loadingThread: false, activeThreadFirstLoad: false, messageList: messages, activeThread: action.payload, isReadOnly: action.payload.isReadOnly });
        }
        case LoadMessageActionTypes.LoadNewerThreadMessagesSuccess: {
            if (action.payload.threadId === state.activeThreadId &&
                action.payload.messages.length > 0) {
                // Update message in messages list
                var messages = state.messageList;
                var existingMessage = state.messageList.find(function (m) { return m.MessageId === action.payload.threadId; });
                if (existingMessage) {
                    messages = replaceMessageInState(state, existingMessage, action.payload.messages[action.payload.messages.length - 1]);
                }
                // Remove existing message items from state
                var threadMessages_1 = state.activeThread.messages.slice();
                action.payload.messages.forEach(function (newMessage) {
                    var existingStateMessage = threadMessages_1.find(function (m) { return m.MessageItemId === newMessage.MessageItemId; });
                    if (existingStateMessage) {
                        var existingMessageIndex = threadMessages_1.indexOf(existingStateMessage);
                        threadMessages_1.splice(existingMessageIndex, 1, newMessage);
                    }
                    else {
                        threadMessages_1.push(newMessage);
                    }
                });
                // Add message to thread
                return tslib_1.__assign({}, state, { activeThread: tslib_1.__assign({}, state.activeThread, { messages: threadMessages_1, hasNewMessages: !!action.payload.messages.find(function (m) { return m.PatientSent; }) }) });
            }
            return tslib_1.__assign({}, state);
        }
        case LoadMessageActionTypes.LoadMessageTypesSuccess: {
            var labels_1 = [];
            labels_1.push(new Label({
                messageTypeId: -1,
                isGroup: true,
                filter: 'unresolved',
                displayName: 'AllUnresolved',
                unresolvedCount: 0,
            }));
            action.payload.results.forEach(function (messageType) {
                labels_1.push(new Label({
                    displayName: messageType.MessageType,
                    messageTypeId: messageType.MessageTypeId,
                    filter: messageType.MessageType.toLowerCase(),
                    isGroup: false,
                    isUncategorised: messageType.Uncategorised,
                    canClinicReply: messageType.CanClinicReply,
                }));
            });
            labels_1.push(new Label({
                messageTypeId: -2,
                includeResolved: true,
                includeUnresolved: false,
                isGroup: true,
                filter: 'resolved',
                displayName: 'AllResolved',
                unresolvedCount: 0,
            }), new Label({
                messageTypeId: -3,
                includeResolved: true,
                isGroup: true,
                filter: 'all',
                displayName: 'AllMessages',
                unresolvedCount: 0,
            }));
            return tslib_1.__assign({}, state, { labels: labels_1 });
        }
        case LoadMessageActionTypes.AddMessageToThread: {
            var _a = action.payload, sentDate = _a.sentDate, newMessage = _a.newMessage, message = _a.message;
            // Only update active thread if messageId still matches the
            // active thread
            if (state.activeThread.id === newMessage.MessageId) {
                var messageToAdd = tslib_1.__assign({}, message, { PatientSent: false, PatientRead: false, Read: false, Content: newMessage.MessageContent, SentDate: sentDate, DeletedDate: null });
                return tslib_1.__assign({}, state, { sendingMessage: null, activeThread: tslib_1.__assign({}, state.activeThread, { messages: state.activeThread.messages.concat([messageToAdd]) }) });
            }
            else {
                return tslib_1.__assign({}, state, { sendingMessage: null });
            }
        }
        // ---------------------- Refactoring --------------------
        case LoadMessageActionTypes.LoadMessagesSuccess: {
            var messages = action.payload.messages;
            var counts = action.payload.counts;
            var mergedMessages = mergeMessageArrays(state.messageList, messages);
            var minimumNumberOfMessagesOnPage = 25;
            var reachedEndOfMessages = void 0;
            // Remove once feature is enabled in production.
            if (state.tab === 'None') {
                reachedEndOfMessages = messages.length === 0;
            }
            else {
                reachedEndOfMessages =
                    state.tab === 'Starred'
                        ? messages.filter(function (m) { return m.IsStarred === true; }).length === 0
                        : messages.filter(function (m) { return m.IsStarred === false; }).length === 0;
            }
            return tslib_1.__assign({}, state, { isLoadingConversations: false, messageCountSummary: tslib_1.__assign({}, counts), messageList: mergedMessages, reachedEndOfMessages: reachedEndOfMessages, autoLoadNextPage: (messages.length > 0 &&
                    mergedMessages.length === state.messageList.length) ||
                    mergedMessages.length < minimumNumberOfMessagesOnPage
                    ? Math.random()
                    : 0, nextPageToLoad: tslib_1.__assign({}, state.nextPageToLoad, { FetchBeforeMessageItemId: messages.length > 0
                        ? messages[messages.length - 1].MessageItemId
                        : null }), latestMessage: tslib_1.__assign({}, messages[0]) });
        }
        case LoadMessageActionTypes.LoadNewestMessagesSuccess: {
            if (action.payload.messages.length === 0) {
                return tslib_1.__assign({}, state);
            }
            var indicesToRemove_1 = [];
            var existingMessages_1 = state.messageList.slice();
            action.payload.messages
                .map(function (m) { return m.MessageId; })
                .forEach(function (id) {
                var message = existingMessages_1.find(function (m) { return m.MessageId === id; });
                if (message) {
                    indicesToRemove_1.push(existingMessages_1.indexOf(message));
                }
            });
            indicesToRemove_1.forEach(function (i) {
                existingMessages_1.splice(i, 1);
            });
            var mergedMessages = action.payload.messages.concat(existingMessages_1);
            return tslib_1.__assign({}, state, { messageList: mergedMessages });
        }
        case LoadMessageActionTypes.SetIsLoadingConversations: {
            return tslib_1.__assign({}, state, { isLoadingConversations: action.payload.isLoading });
        }
        default:
            return state;
    }
}
