import {
  Card,
  CardContent,
  CircularProgress,
  Popover,
} from '@material-ui/core';
import { FilterChip, Label } from '@react/components';
import { SIDERecipeEventType } from '@react/lib/api/enum';
import { IRecipe, IRecipeTrigger } from '@react/lib/api/types';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useSIDERecipeTriggerQuery } from '../../../../hooks';
import { LoaderWrapper, StyledContainer, StyledList } from './index.styled';

interface IProps {
  recipe: IRecipe;
}

const RecipeTriggers: React.FunctionComponent<IProps> = ({ recipe }) => {
  const { t } = useTranslations();
  const [open, setOpen] = React.useState<boolean>(false);
  const popoverAnchor = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, isLoading, isError } = useSIDERecipeTriggerQuery({
    id: recipe.id,
  });

  useErrorToast(isError, {
    title: t.Admin.SIDE.TriggerListLoadingErrorToastHeader,
    message: t.Admin.SIDE.TriggerListErrorToastMessage,
  });

  if (isLoading) {
    return (
      <Card>
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      </Card>
    );
  }

  const { data: triggers } = data as NonNullable<typeof data>;
  const initials: IRecipeTrigger[] = triggers.slice(0, 5);
  const rest: IRecipeTrigger[] = triggers.slice(5);

  return (
    <>
      {triggers.length === 0 && <span>{t.Admin.SIDE.NoTriggersAvailable}</span>}
      {triggers.length > 0 && (
        <CardContent>
          <Label>
            {recipe.eventname === SIDERecipeEventType.Appointment
              ? t.Admin.SIDE.AppointmentTriggerHeader
              : t.Admin.SIDE.MedicationTriggerHeader}
          </Label>
          <StyledContainer>
            {initials.map((item: IRecipeTrigger) => {
              return (
                <FilterChip
                  bgColor={'#f7f7fb'}
                  label={item.name}
                  key={item.id}
                />
              );
            })}
            {rest.length > 0 && (
              <>
                <FilterChip
                  bgColor={'#f7f7fb'}
                  ref={popoverAnchor}
                  label="..."
                  clickable={true}
                  onClick={handleClick}
                />
                <Popover
                  id="Trigger-Chip-Popver"
                  open={open}
                  anchorEl={popoverAnchor.current}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <StyledList>
                    {rest.map((item: IRecipeTrigger) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                  </StyledList>
                </Popover>
              </>
            )}
          </StyledContainer>
        </CardContent>
      )}
    </>
  );
};

export default RecipeTriggers;
