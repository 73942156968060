import * as tslib_1 from "tslib";
import { PatientActionTypes, } from '../actions/patient.actions';
export var initialState = {
    patients: {},
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    switch (action.type) {
        case PatientActionTypes.LoadPatientInformationSuccess: {
            return tslib_1.__assign({}, state, { patients: tslib_1.__assign({}, state.patients, (_a = {}, _a[action.payload.patientId] = action.payload.response, _a)) });
        }
        case PatientActionTypes.ClearAllPatientInformation: {
            return initialState;
        }
        default:
            return state;
    }
}
// Selectors
export var getPatients = function (state) { return state.patients; };
