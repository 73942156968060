import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import _ from 'lodash';
import { ActiveToast } from 'ngx-toastr';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ClinicOptions } from 'src/app/auth/models/ClinicOptions';
import { JoinVideoCallResponse } from 'src/app/video-calls/responses';
import { User } from '../../../auth/models/user';
import * as fromAuth from '../../../auth/reducers';
import { VideoCallModalComponent } from '../../../react/pages/videoCall/components/VideoCallModal';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import * as fromVideoCalls from '../../../video-calls/reducers';
import { DisplayToastAction } from '../../actions/toast.actions';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';

const containerElementName = 'videoCallModalReactWrapperComponent';

interface DialogData {
  close: () => void;
  joinResponse: JoinVideoCallResponse;
  invitePartnerInCall: () => void;
  partnerInviteEnabled: boolean;
}

@Component({
  selector: 'portal-video-call-modal',
  template: `<div #${containerElementName}></div>`
})
export class VideoCallModalReactWrapperComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild(containerElementName) containerRef!: ElementRef;

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
  };
  private _inCallInviteFlag: boolean;
  private _invitingInCall: boolean;

  private _subscriptions = new Subscription();
  private _toastRef$: Observable<any>;
  private _toastRef: ActiveToast<any>;
  private _videoCallModalText$: Observable<any>;
  private _videoCallModalText: any;

  constructor(
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData
  ) {
    this._toastRef$ = _store.pipe(select(fromRoot.getToastRef));
    this._videoCallModalText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallModal'))
    );
  }

  ngOnInit() {
    this.render();
    this._subscriptions.add(
      this._toastRef$.subscribe((tr) => {
        this._toastRef = tr;
      })
    );
    this._subscriptions.add(
      this._videoCallModalText$.subscribe((t) => {
        this._videoCallModalText = t;
      })
    );
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
        this._store.pipe(select(fromVideoCalls.getInCallInviteFlagSelector)),
        this._store.pipe(select(fromVideoCalls.getInvitingPartnerInCall))
      ]).subscribe(
        ([
          clinicToken,
          clinicOptions,
          user,
          language,
          translations,
          inCallInviteFlag,
          invitingInCall
        ]: [
          string,
          ClinicOptions,
          User,
          string,
          TranslationData,
          boolean,
          boolean
        ]) => {
          this._inCallInviteFlag = inCallInviteFlag;
          this._invitingInCall = invitingInCall;
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user
          };
          this.render();
        }
      )
    );
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.render();
  }

  displayConnectedNotification = () => {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: this._videoCallModalText.Connected,
        title: this._videoCallModalText.Success,
        timeout: 3000,
        type: 'success'
      })
    );
  };

  displayErrorNotification = (errorMessage: string) => {
    this._store.dispatch(
      new DisplayToastAction({
        toastRef: this._toastRef,
        message: errorMessage,
        title: this._videoCallModalText.Error,
        timeout: 0,
        type: 'error'
      })
    );
  };

  private async render() {
    const displayName = `${_.upperFirst(
      _.get(this._props, 'user.FirstName', '')
    )} ${_.upperFirst(_.get(this._props, 'user.LastName', ''))}`.trim();
    ReactDOM.render(
      React.createElement(Root, {
        ...this._props,
        authService: this._cognito,
        dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        children: React.createElement(VideoCallModalComponent, {
          displayName,
          close: this.data.close,
          joinResponse: this.data.joinResponse,
          partnerInvited: this._inCallInviteFlag,
          invitePartnerInCall: this.data.invitePartnerInCall,
          invitingPartner: this._invitingInCall,
          partnerInviteEnabled: this.data.partnerInviteEnabled,
          displayConnectedNotification: this.displayConnectedNotification,
          displayErrorNotification: this.displayErrorNotification
        })
      }),
      this.containerRef.nativeElement
    );
  }
}
