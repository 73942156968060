// Constants
export var NewCategoryActionTypes;
(function (NewCategoryActionTypes) {
    NewCategoryActionTypes["FilterContent"] = "[Content][NewCategory] Filter Categories";
    NewCategoryActionTypes["ClearCategoriesFilter"] = "[Content][NewCategory] Filter Categories";
})(NewCategoryActionTypes || (NewCategoryActionTypes = {}));
var FilterContent = /** @class */ (function () {
    function FilterContent(payload) {
        this.payload = payload;
        this.type = NewCategoryActionTypes.FilterContent;
    }
    return FilterContent;
}());
export { FilterContent };
var ClearCategoriesFilter = /** @class */ (function () {
    function ClearCategoriesFilter(payload) {
        this.payload = payload;
        this.type = NewCategoryActionTypes.ClearCategoriesFilter;
    }
    return ClearCategoriesFilter;
}());
export { ClearCategoriesFilter };
