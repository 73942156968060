import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  RemoteAudioTrack,
  RemoteDataTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
} from 'twilio-video';
import { MetaMessageService } from '../../services/meta-message.service';
import { VideoChatService } from '../../services/video.chat.service';

@Component({
  selector: 'portal-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParticipantComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlaceholder')
  private _videoPlaceholder: ElementRef<HTMLDivElement>;
  @ViewChild('audioPlaceholder')
  private _audioPlaceholder: ElementRef<HTMLDivElement>;

  @Input() localIdentity: string;
  @Input() localName: string;
  @Input() participant: RemoteParticipant;
  @Input() set audioOutputDeviceId(value: string | undefined) {
    this._audioOutputDeviceId = value;

    const audioElement =
      this._audioPlaceholder.nativeElement.querySelector('audio');
    if (audioElement) {
      this.setAudioSink(audioElement);
    }
  }

  name = '';

  private _audioOutputDeviceId: string | undefined;
  private readonly _metaMessageService: MetaMessageService;
  private readonly _videoChatService: VideoChatService;
  private _remoteAudioTrack?: RemoteAudioTrack;
  private _remoteDataTrack?: RemoteDataTrack;
  private _remoteVideoTrack?: RemoteVideoTrack;

  isWaiting = false;
  get isAudioEnabled() {
    return this._remoteAudioTrack != null
      ? this._remoteAudioTrack.isEnabled
      : true;
  }
  get isVideoEnabled() {
    return this._remoteVideoTrack != null
      ? this._remoteVideoTrack.isEnabled
      : true;
  }

  constructor(
    metaMessageService: MetaMessageService,
    videoChatService: VideoChatService,
  ) {
    this._metaMessageService = metaMessageService;
    this._videoChatService = videoChatService;
  }

  ngOnInit() {
    this.participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        const track = publication.track;
        this.attachTrack(track);
      }
    });
    this.participant.on('trackSubscribed', (track) => {
      this.attachTrack(track);
    });
  }

  ngOnDestroy() {
    try {
      if (this.participant) {
        this.participant.removeAllListeners();
      }
      try {
        if (this._remoteAudioTrack) {
          this._remoteAudioTrack.detach();
          this._remoteAudioTrack = undefined;
        }
        if (this._remoteDataTrack) {
          this._remoteDataTrack.removeListener(
            'message',
            this.onDataTrackMessage,
          );
          this._remoteDataTrack = undefined;
        }
        if (this._remoteVideoTrack) {
          this._remoteVideoTrack.detach();
          this._remoteVideoTrack = undefined;
        }
      } catch {}
    } catch (error) {
      console.error('Error detaching');
      console.error(error);
    }
  }

  private attachTrack(track: RemoteTrack) {
    switch (track.kind) {
      case 'audio': {
        if (
          this._remoteAudioTrack &&
          this._remoteAudioTrack.sid === track.sid
        ) {
          return;
        }
        const audioElement = track.attach();
        this._audioPlaceholder.nativeElement.appendChild(audioElement);
        this.setAudioSink(audioElement);
        this._remoteAudioTrack = track;
        break;
      }
      case 'video': {
        this.isWaiting = true;
        track.on('started', () => {
          this.isWaiting = false;
          track.removeAllListeners();
        });
        if (
          this._remoteVideoTrack &&
          this._remoteVideoTrack.sid === track.sid
        ) {
          return;
        }

        // Remove all previous videos attached to avoid multiple
        // videos appearing from the same participant
        this._videoPlaceholder.nativeElement.childNodes.forEach((node) => {
          this._videoPlaceholder.nativeElement.removeChild(node);
        });

        this._videoPlaceholder.nativeElement.appendChild(track.attach());
        this._remoteVideoTrack = track;
        break;
      }
      case 'data': {
        this._remoteDataTrack = track;

        track.on('message', this.onDataTrackMessage);

        const nameMessage = this._metaMessageService.createMessage({
          identity: this.localIdentity,
          name: this.localName,
          type: 'ParticipantIdentity',
        });

        this._videoChatService.sendMessage(nameMessage);
      }
    }
  }

  private async setAudioSink(audioElement: HTMLAudioElement) {
    if (this._audioOutputDeviceId != null && 'setSinkId' in audioElement) {
      try {
        // @ts-ignore
        await audioElement.setSinkId(this._audioOutputDeviceId);
      } catch (err) {
        console.error(err);
      }
    }
  }

  private onDataTrackMessage = (
    data: string | ArrayBuffer,
    track: RemoteDataTrack,
  ) => {
    if (!(typeof data === 'string')) {
      return;
    }

    const metaMessage = this._metaMessageService.parseMessage(data);

    if (metaMessage == null) {
      return;
    }

    switch (metaMessage.type) {
      case 'ParticipantIdentity': {
        this.name = metaMessage.name;
        break;
      }
    }
  };
}
