import { ToastActions, ToastActionsUnion } from '../actions/toast.actions';

export const featureStateName = 'toasts';

export interface ToastsState {
  toastRef: any;
}

export const initialState: ToastsState = {
  toastRef: null,
};

export function reducer(
  state: ToastsState = initialState,
  action: ToastActionsUnion,
): ToastsState {
  switch (action.type) {
    case ToastActions.ToastDisplayed:
      return {
        ...state,
        toastRef: action.payload.toastRef,
      };
    case ToastActions.ToastRemoved:
      return {
        ...state,
        toastRef: null,
      };
    default:
      return state;
  }
}

// Selectors
export const getToastRef = (state: ToastsState) => {
  if (state) {
    return state.toastRef;
  }
};
