import { Action } from '@ngrx/store';

import { ServerError } from '../../models/Error';
import { Message, MessageResponse } from '../../models/Message';
import { NewMessageModel } from '../../models/NewMessageModel';
import { Thread } from '../../models/Thread';
import { MessageTypeResponse } from '../models/messageTypeResponse';
import { MessagingSummaryResponse } from '../models/messagingSummaryResponse';
import { ConversationListParams } from '../models/requests/conversations.request';
import { TabState } from '../models/TabState';

export enum LoadMessageActionTypes {
  /* ========= Server Side ========== */
  LoadMessagingSummary = '[LoadMessage] Load Messaging Summary',
  LoadMessagingSummarySuccess = '[LoadMessage] Load Messaging Summary Success',
  LoadMessagingSummaryError = '[LoadMessage] Load Messaging Summary Error',

  LoadMessages = '[LoadMessage] Load Messages',
  LoadMessagesSuccess = '[LoadMessage] Load Messages Success',
  LoadMessagesError = '[LoadMessage] Load Messages Error',

  LoadThread = '[LoadMessage] Load Thread',
  LoadThreadSuccess = '[LoadMessage] Load Thread Success',
  LoadThreadError = '[LoadMessage] Load Thread Error',

  LoadNewerThreadMessages = '[LoadMessage] Load Newer Thread Messages',
  LoadNewerThreadMessagesSuccess = '[LoadMessage] Load Newer Thread Messages Success',

  RefreshMessaging = '[LoadMessage] Refresh Core Message Data',
  RefreshAllMessages = '[LoadMessage] Refresh Data In Messages Tab',

  LoadNewestMessages = '[LoadMessage] Load newest messages',
  LoadNewestMessagesSuccess = '[LoadMessage] Load newest messages success',
  LoadNewestMessagesError = '[LoadMessage] Load newest messages error',

  /* ========= Client Side ========== */
  LoadMessageTypes = '[LoadMessage] Load Message Types',
  LoadMessageTypesSuccess = '[LoadMessage] Load Message Types Success',
  LoadMessageTypesFailure = '[LoadMessage] Load Message Types Failure',

  LoadingList = '[LoadMessage] Loading Message List',
  LoadedList = '[LoadMessage] Loaded Message List',
  LoadingThread = '[LoadMessage] Loading Message Thread',
  LoadedThread = '[LoadMessage] Loaded Message Thread',

  AddMessageToThread = '[LoadMessage] Add Message To Thread',

  ShowPushNotification = '[LoadMessage] Show Push Notification',
  ClosedPushNotification = '[LoadMessage] Closed Push Notification',
  ClickedPushNotification = '[LoadMessage] Click Push Notification',

  UpdateLatestMessage = '[LoadMessage] Update Latest Message',
  UpdateLastSeen = '[LoadMessage] Update Last Seen Message',

  SetIsLoadingConversations = '[LoadMessage] Set Loading',
}

/**
 * Action Creators
 */

// ============== Server Side Actions ==================== //
export class LoadMessagingSummary implements Action {
  readonly type = LoadMessageActionTypes.LoadMessagingSummary;
}

export class LoadMessagingSummarySuccess implements Action {
  readonly type = LoadMessageActionTypes.LoadMessagingSummarySuccess;

  constructor(public payload: MessagingSummaryResponse) {}
}

export class LoadMessagingSummaryError implements Action {
  readonly type = LoadMessageActionTypes.LoadMessagesError;

  constructor(public payload: ServerError) {}
}

export class LoadMessages implements Action {
  readonly type = LoadMessageActionTypes.LoadMessages;
  constructor(public payload: ConversationListParams) {}
}

export class LoadMessagesSuccess implements Action {
  readonly type = LoadMessageActionTypes.LoadMessagesSuccess;

  constructor(
    public payload: {
      messages: Message[];
      counts?: {
        starred: number;
        unstarred: number;
      };
    },
  ) {}
}

export class LoadMessagesError implements Action {
  readonly type = LoadMessageActionTypes.LoadMessagesError;

  constructor(public payload: ServerError) {}
}

export class LoadNewestMessages implements Action {
  readonly type = LoadMessageActionTypes.LoadNewestMessages;
  constructor(
    public payload: {
      fetchParams: ConversationListParams;
      showPushNotification: boolean;
    },
  ) {}
}

export class LoadNewestMessagesSuccess implements Action {
  readonly type = LoadMessageActionTypes.LoadNewestMessagesSuccess;
  constructor(public payload: { messages: Message[] }) {}
}

export class LoadNewestMessagesError implements Action {
  readonly type = LoadMessageActionTypes.LoadNewestMessagesError;
  constructor(public payload: ServerError) {}
}

export class LoadThread implements Action {
  readonly type = LoadMessageActionTypes.LoadThread;

  // Load Messages require id of message thread to load
  constructor(public payload: Message) {}
}
export class LoadThreadSuccess implements Action {
  readonly type = LoadMessageActionTypes.LoadThreadSuccess;

  // returns array of messages without patient info
  constructor(public payload: Thread) {}
}
export class LoadThreadError implements Action {
  readonly type = LoadMessageActionTypes.LoadThreadError;

  constructor(public payload: ServerError) {}
}

export class LoadNewerThreadMessages implements Action {
  readonly type = LoadMessageActionTypes.LoadNewerThreadMessages;
  constructor(
    public payload: { messageId: number; latestMessageItemId: number },
  ) {}
}

export class LoadNewerThreadMessagesSuccess implements Action {
  readonly type = LoadMessageActionTypes.LoadNewerThreadMessagesSuccess;
  constructor(
    public payload: {
      threadId: number;
      messages: Message[];
    },
  ) {}
}

export class RefreshMessaging implements Action {
  readonly type = LoadMessageActionTypes.RefreshMessaging;
}

// ============== Client Side Actions ==================== //
export class LoadMessageTypes implements Action {
  readonly type = LoadMessageActionTypes.LoadMessageTypes;
}
export class LoadMessageTypesSuccess implements Action {
  readonly type = LoadMessageActionTypes.LoadMessageTypesSuccess;

  constructor(public payload: MessageTypeResponse) {}
}
export class LoadMessageTypesFailure implements Action {
  readonly type = LoadMessageActionTypes.LoadMessageTypesFailure;

  constructor(public payload: any) {}
}

export class LoadingList implements Action {
  readonly type = LoadMessageActionTypes.LoadingList;
}
export class LoadedList implements Action {
  readonly type = LoadMessageActionTypes.LoadedList;
}

export class LoadingThread implements Action {
  readonly type = LoadMessageActionTypes.LoadingThread;
}
export class LoadedThread implements Action {
  readonly type = LoadMessageActionTypes.LoadedThread;
}

export class AddMessageToThread implements Action {
  readonly type = LoadMessageActionTypes.AddMessageToThread;

  constructor(public payload: NewMessageModel) {}
}

export class ShowPushNotification implements Action {
  readonly type = LoadMessageActionTypes.ShowPushNotification;

  constructor(
    public payload: {
      newMessages: Message[];
      showPushNotification: boolean;
    },
  ) {}
}
export class ClosedPushNotification implements Action {
  readonly type = LoadMessageActionTypes.ClosedPushNotification;
}
export class ClickedPushNotification implements Action {
  readonly type = LoadMessageActionTypes.ClickedPushNotification;
}

export class UpdateLatestMessage implements Action {
  readonly type = LoadMessageActionTypes.UpdateLatestMessage;
}

export class UpdateLastSeen implements Action {
  readonly type = LoadMessageActionTypes.UpdateLastSeen;
}

export class SetIsLoadingConversations implements Action {
  readonly type = LoadMessageActionTypes.SetIsLoadingConversations;
  constructor(public payload: { isLoading: boolean }) {}
}

/**
 * LoadMessageActions Type Union
 */
export type LoadMessageActionsUnion =
  /* Server Side */
  | LoadMessagingSummary
  | LoadMessagingSummarySuccess
  | LoadMessagingSummaryError
  | LoadMessages
  | LoadMessagesSuccess
  | LoadMessagesError
  | LoadNewestMessages
  | LoadNewestMessagesSuccess
  | LoadNewestMessagesError
  | LoadThread
  | LoadThreadSuccess
  | LoadThreadError
  | LoadNewerThreadMessages
  | LoadNewerThreadMessagesSuccess
  /* Client Side */
  | LoadMessageTypes
  | LoadMessageTypesSuccess
  | LoadMessageTypesFailure
  | LoadingList
  | LoadedList
  | LoadingThread
  | LoadedThread
  | AddMessageToThread
  | ShowPushNotification
  | ClosedPushNotification
  | ClickedPushNotification
  | UpdateLatestMessage
  | UpdateLastSeen
  | SetIsLoadingConversations;
