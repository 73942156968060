export class ContentTemplateEntry {
  public id?: number;
  public ordernumber: number;
  public isrequired: boolean;
  public type: string;
  public title: string;
}

export class ContentTemplate {
  public id?: number;
  public name: string;
  public lastModifiedBy: string;
  public dateModified: Date;
  public entries: ContentTemplateEntry[];
}

export class NewContentTemplate {
  public dateModified = new Date();
  public entries: ContentTemplateEntry[] = [
    {
      ordernumber: 1,
      isrequired: true,
      type: 'title',
      title: 'Title',
    },
    {
      ordernumber: 2,
      isrequired: true,
      type: 'subtitle',
      title: 'Subtitle',
    },
    {
      ordernumber: 3,
      isrequired: true,
      type: 'introduction',
      title: 'Introduction',
    },
    {
      ordernumber: 4,
      isrequired: true,
      type: 'video',
      title: 'Video',
    },
  ];

  constructor(public name: string, public isactive: boolean) {}
}

export class NewTemplateEntry {
  public ordernumber: number;
  public isrequired: boolean;
  public type: string;
  public title: string;

  constructor({ ordernumber, isrequired = false, type = 'mixed', title = '' }) {
    this.ordernumber = ordernumber;
    this.isrequired = isrequired;
    this.type = type;
    this.title = title;
  }
}

export class NewContentEntry {
  public id?: number;
  public orderNumber: number;
  public isRequired: boolean;
  public type: string;
  public title: string;

  constructor({ orderNumber, isRequired = false, type = 'mixed', title = '' }) {
    this.orderNumber = orderNumber;
    this.isRequired = isRequired;
    this.type = type;
    this.title = title;
  }
}

export type ApplyTemplateMethod = 'overwrite' | 'append';

export class ApplyTemplate {
  public template: ContentTemplate;
  public options: {
    method: ApplyTemplateMethod;
  };
}
