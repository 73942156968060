import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfirmActionComponent } from 'src/app/core/components/confirm-action/confirm-action.component';
import { VideoCallContainerComponent } from '../../../twilio/containers/video-call.container/video-call.container.component';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromVideoCalls from '../../reducers';
import { PermissionsService } from '../../services/permissions-service';
var VideoCallModalContainer = /** @class */ (function () {
    function VideoCallModalContainer(_permissionsService, _store, _store1, _dialog, _dialogRef, data) {
        this._permissionsService = _permissionsService;
        this._store = _store;
        this._store1 = _store1;
        this._dialog = _dialog;
        this._dialogRef = _dialogRef;
        this._subs = new Subscription();
        this._joinResponse = data.joinResponse;
        this.invitePartnerInCall = data.invitePartnerInCall;
        this.unInvitePartnerInCall = data.unInvitePartnerInCall;
        this.videoCall = data.joinResponse.videoCall;
        this._toastRef$ = this._store.pipe(select(fromRoot.getToastRef));
        this._videoCallModalText$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallModal')));
        this._isPartnerInvitedSub$ = this._store1.pipe(select(fromVideoCalls.getInCallInviteFlagSelector));
    }
    VideoCallModalContainer.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this._videoCallModalText$.subscribe(function (t) {
            _this._videoCallModalText = t;
        }));
        this._subs.add(this._isPartnerInvitedSub$.subscribe(function (t) {
            _this.isPartnerInvited = t;
        }));
    };
    VideoCallModalContainer.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._videoCallComponent.localIdentity =
            this._joinResponse.response.participant.identity;
        this._videoCallComponent.roomName = this._joinResponse.response.room;
        this._videoCallComponent.token = this._joinResponse.response.token;
        this._videoCallComponent.leaveConfirmationCallback = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.displaySureLeaveDialog()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        this._subs.add(this._videoCallComponent.errorConnecting.subscribe(function (e) {
            _this.displayOtherErrorNotification();
            _this.closeModal();
        }));
        this._subs.add(this._videoCallComponent.errorDevices.subscribe(function (e) {
            _this.displayNoCameraMicErrorNotification();
            _this.closeModal();
        }));
        this._subs.add(this._videoCallComponent.leftCall.subscribe(function (e) {
            if (_this._videoCallComponent.isReconnecting.getValue()) {
                _this.displayConnectionErrorNotification();
            }
            _this.closeModal();
        }));
        this._subs.add(this._videoCallComponent.connected.subscribe(function (c) {
            _this.displayConnectedNotification();
        }));
        this._subs.add(this._videoCallComponent.participantDisconnected.subscribe(function (p) {
            _this.displayParticipantDisconnectedNotification();
        }));
        this._subs.add(this._videoCallComponent.isReconnecting.subscribe(function (isReconnecting) {
            if (isReconnecting) {
                _this.displayReconnectingNotification();
            }
        }));
        this._subs.add(this._toastRef$.subscribe(function (tr) {
            _this._toastRef = tr;
        }));
        setTimeout(function () {
            _this.displayPermissionsDialog();
            _this._videoCallComponent.nooneHereText =
                _this._videoCallModalText.NooneHere(_this._joinResponse.videoCall.patientname);
        }, 0);
    };
    VideoCallModalContainer.prototype.displayPermissionsDialog = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, cameraPermission, microphonePermission, confirmDialog;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this._permissionsService.hasCameraPermission(),
                            this._permissionsService.hasMicrophonePermission(),
                        ])];
                    case 1:
                        _a = _b.sent(), cameraPermission = _a[0], microphonePermission = _a[1];
                        if (cameraPermission === 'granted' && microphonePermission === 'granted') {
                            this.initializeCall();
                            return [2 /*return*/];
                        }
                        confirmDialog = this._dialog.open(ConfirmActionComponent, {
                            panelClass: 'reduced-padding-dialog',
                            width: '40vw',
                            data: {
                                message: this._videoCallModalText.NeedPermissions,
                                text: {
                                    Cancel: this._videoCallModalText.Cancel,
                                    Confirm: this._videoCallModalText.Allow,
                                },
                            },
                        });
                        confirmDialog.afterClosed().subscribe(function (result) {
                            if (!result) {
                                _this.closeModal();
                                _this.displayNoCameraMicErrorNotification();
                            }
                            else {
                                _this.initializeCall();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoCallModalContainer.prototype.displaySureLeaveDialog = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var confirmDialog = _this._dialog.open(ConfirmActionComponent, {
                data: {
                    message: _this._videoCallModalText.SureLeave,
                    text: {
                        Cancel: _this._videoCallModalText.Cancel,
                        Confirm: _this._videoCallModalText.Yes,
                    },
                },
            });
            confirmDialog.afterClosed().subscribe(function (result) {
                resolve(!!result);
            });
        });
    };
    VideoCallModalContainer.prototype.initializeCall = function () {
        this._videoCallComponent.initialize();
    };
    VideoCallModalContainer.prototype.closeModal = function () {
        this._dialogRef.close({});
    };
    VideoCallModalContainer.prototype.displayOtherErrorNotification = function () {
        this.displayErrorNotification(this._videoCallModalText.Error);
    };
    VideoCallModalContainer.prototype.displayNoCameraMicErrorNotification = function () {
        this.displayErrorNotification(this._videoCallModalText.NoCameraMic);
    };
    VideoCallModalContainer.prototype.displayConnectionErrorNotification = function () {
        this._store.dispatch(new DisplayToastAction({
            toastRef: null,
            message: this._videoCallModalText.CallNotReconnected,
            title: this._videoCallModalText.CallEnded,
            timeout: 5000,
            type: 'warning',
        }));
    };
    VideoCallModalContainer.prototype.displayReconnectingNotification = function () {
        this._store.dispatch(new DisplayToastAction({
            toastRef: null,
            message: this._videoCallModalText.AttemptingReconnect,
            title: this._videoCallModalText.Reconnecting,
            timeout: 5000,
            type: 'info',
        }));
    };
    VideoCallModalContainer.prototype.displayParticipantDisconnectedNotification = function () {
        this._store.dispatch(new DisplayToastAction({
            toastRef: null,
            message: this._videoCallModalText.ParticipantDisconnectedMessage,
            title: this._videoCallModalText.ParticipantDisconnected,
            timeout: 5000,
            type: 'info',
        }));
    };
    VideoCallModalContainer.prototype.displayErrorNotification = function (errorMessage) {
        this._store.dispatch(new DisplayToastAction({
            toastRef: this._toastRef,
            message: errorMessage,
            title: this._videoCallModalText.Error,
            timeout: 0,
            type: 'error',
        }));
    };
    VideoCallModalContainer.prototype.displayConnectedNotification = function () {
        this._store.dispatch(new DisplayToastAction({
            toastRef: this._toastRef,
            message: this._videoCallModalText.Connected,
            title: this._videoCallModalText.Success,
            timeout: 3000,
            type: 'success',
        }));
    };
    return VideoCallModalContainer;
}());
export { VideoCallModalContainer };
