var ContentTemplateEntry = /** @class */ (function () {
    function ContentTemplateEntry() {
    }
    return ContentTemplateEntry;
}());
export { ContentTemplateEntry };
var ContentTemplate = /** @class */ (function () {
    function ContentTemplate() {
    }
    return ContentTemplate;
}());
export { ContentTemplate };
var NewContentTemplate = /** @class */ (function () {
    function NewContentTemplate(name, isactive) {
        this.name = name;
        this.isactive = isactive;
        this.dateModified = new Date();
        this.entries = [
            {
                ordernumber: 1,
                isrequired: true,
                type: 'title',
                title: 'Title',
            },
            {
                ordernumber: 2,
                isrequired: true,
                type: 'subtitle',
                title: 'Subtitle',
            },
            {
                ordernumber: 3,
                isrequired: true,
                type: 'introduction',
                title: 'Introduction',
            },
            {
                ordernumber: 4,
                isrequired: true,
                type: 'video',
                title: 'Video',
            },
        ];
    }
    return NewContentTemplate;
}());
export { NewContentTemplate };
var NewTemplateEntry = /** @class */ (function () {
    function NewTemplateEntry(_a) {
        var ordernumber = _a.ordernumber, _b = _a.isrequired, isrequired = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? 'mixed' : _c, _d = _a.title, title = _d === void 0 ? '' : _d;
        this.ordernumber = ordernumber;
        this.isrequired = isrequired;
        this.type = type;
        this.title = title;
    }
    return NewTemplateEntry;
}());
export { NewTemplateEntry };
var NewContentEntry = /** @class */ (function () {
    function NewContentEntry(_a) {
        var orderNumber = _a.orderNumber, _b = _a.isRequired, isRequired = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? 'mixed' : _c, _d = _a.title, title = _d === void 0 ? '' : _d;
        this.orderNumber = orderNumber;
        this.isRequired = isRequired;
        this.type = type;
        this.title = title;
    }
    return NewContentEntry;
}());
export { NewContentEntry };
var ApplyTemplate = /** @class */ (function () {
    function ApplyTemplate() {
    }
    return ApplyTemplate;
}());
export { ApplyTemplate };
