var GetConversationRequest = /** @class */ (function () {
    function GetConversationRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId, AfterMessageItemId = _a.AfterMessageItemId;
        this.Action = 'GetClinicPatientMessage';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
        this.AfterMessageItemId = AfterMessageItemId;
    }
    return GetConversationRequest;
}());
export { GetConversationRequest };
