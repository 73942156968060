import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatExpansionModule,
  MatIconModule,
  MatTableModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CoreModule } from '../core/core.module';
import { GlobalPortalGuard } from '../core/services/global-guard.service';
import { LocalisationModule } from '../localisation/localisation.module';
import { MaterialModule } from '../material/material.module';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { PatientMedicationsComponent } from './components/patient-medication/patient-medications.component';
import { MedicationsComponent } from './containers/medications/medications.component';
import { MedicationEffects } from './effects/medications.effects';
// import { reducers } from './reducers';
import { MedicationsService } from './services/medications.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};
// No Components being used

export const COMPONENTS = [
  MedicationsComponent,
  PatientListComponent,
  PatientMedicationsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    MatTableModule,
    MatIconModule,
    MatExpansionModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    LocalisationModule.forRoot(),
    CoreModule.forFeature(),
    EffectsModule.forFeature([MedicationEffects]),
    RouterModule.forChild([
      {
        path: 'medications',
        canActivate: [AuthGuard, GlobalPortalGuard],
        component: MedicationsComponent,
      },
    ]),
  ],
  declarations: COMPONENTS,
  providers: [
    MedicationsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MedicationsModule {}
