import { ErrorHandler, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DeleteWithChildrenIdArrayResponse } from './../models/content-entry';
import { ContentEntryNames } from './../models/linked-content';

import { EndpointType, HttpService } from 'src/app/core/services/http.service';

import { Observable, throwError } from 'rxjs';
import {
  ChildInfo,
  DeleteWithChildIdResponse,
  DeleteWithParentIdResponse
} from '../models/content-entry';
import { LinkedItemSuccessResponse } from '../models/linked-content';

@Injectable()
export class LinkContentService {
  constructor(
    private _httpService: HttpService,
    private _error: ErrorHandler
  ) {}

  public linkContent(contentToLinkTo: number, itemsToLink: number[]) {
    return this._httpService
      .post<LinkedItemSuccessResponse>(
        EndpointType.Content,
        `content/link/${contentToLinkTo}`,
        { itemsToLink }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public fetchAvailableToLinkContent(categoryId: number) {
    return this._httpService
      .get<ContentEntryNames>(
        EndpointType.Content,
        `content/link/${categoryId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public loadChildren(headerId: number) {
    return this._httpService
      .get<ChildInfo[]>(
        EndpointType.Content,
        `content/entries/${headerId}/children`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public deleteContentLink(
    childId: number
  ): Observable<DeleteWithChildIdResponse> {
    return this._httpService
      .delete<DeleteWithChildIdResponse>(
        EndpointType.Content,
        `content/link/${childId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public deleteSomeContentLinks(
    children: number[]
  ): Observable<DeleteWithChildrenIdArrayResponse> {
    return this._httpService
      .deleteWithBody<DeleteWithChildrenIdArrayResponse>(
        EndpointType.Content,
        `content/link/children`,
        { children }
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  public deleteAllContentLinks(
    parentId: number
  ): Observable<DeleteWithParentIdResponse> {
    return this._httpService
      .delete<DeleteWithParentIdResponse>(
        EndpointType.Content,
        `content/link/${parentId}/children`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}
