import omit from 'lodash/omit';
import {
  filterAndSortCategories,
  sortAlphabetically,
} from '../../shared/utils';
import {
  ContentActionsUnion,
  ContentActionTypes,
} from '../actions/content.actions';
import { Category } from '../models/category';
import { ContentEntry, ContentEntryHeader } from '../models/content-entry';
import { ContentTemplate } from '../models/content-template';

export interface State {
  templates: ContentTemplate[] | null;
  loadingContentEntry: boolean;
  activeContent: Record<'default' | string, ContentEntry>;
  errors: {
    loadingContentEntry: boolean;
  };
  contentHeaders: {
    [key: string]: ContentEntryHeader[];
  };
  categories: Category[] | null;
  isSavingContentEntry: boolean;
}

export const initialState: State = {
  templates: [],
  loadingContentEntry: false,
  errors: {
    loadingContentEntry: false,
  },
  activeContent: {},
  contentHeaders: {},
  categories: [],
  isSavingContentEntry: false,
};

export function reducer(
  state = initialState,
  action: ContentActionsUnion,
): State {
  switch (action.type) {
    // Templates
    // ================================================
    case ContentActionTypes.GetTemplatesSuccess: {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case ContentActionTypes.CreateTemplateSuccess: {
      return {
        ...state,
        templates: state.templates.concat(action.payload),
      };
    }
    case ContentActionTypes.SavingTemplateSuccess: {
      return {
        ...state,
        templates: state.templates.map((t) =>
          t.id === action.payload.id ? action.payload : t,
        ),
      };
    }
    case ContentActionTypes.DeleteTemplateSuccess: {
      return {
        ...state,
        templates: state.templates.filter((t) => t.id !== action.payload),
      };
    }
    // Content Entries
    // ================================================
    case ContentActionTypes.GetContentEntry: {
      return {
        ...state,
        activeContent: {},
        loadingContentEntry: true,
        errors: {
          loadingContentEntry: false,
        },
      };
    }
    case ContentActionTypes.GetContentEntrySuccess: {
      return {
        ...state,
        loadingContentEntry: false,
        activeContent: action.payload,
        errors: {
          loadingContentEntry: false,
        },
      };
    }
    case ContentActionTypes.GetContentEntryError: {
      return {
        ...state,
        loadingContentEntry: false,
        errors: {
          loadingContentEntry: true,
        },
      };
    }

    case ContentActionTypes.GetContentEntriesSuccess: {
      return {
        ...state,
        contentHeaders: action.payload,
      };
    }
    case ContentActionTypes.CreateEntry: {
      return {
        ...state,
        isSavingContentEntry: true,
      };
    }
    case ContentActionTypes.CreateEntrySuccess: {
      return {
        ...state,
        activeContent: {
          ...state.activeContent,
          [action.payload.languageCode]: action.payload.contentEntry,
        },
        isSavingContentEntry: false,
      };
    }
    case ContentActionTypes.CreateEntryError: {
      return {
        ...state,
        isSavingContentEntry: false,
      };
    }
    case ContentActionTypes.CreateNewContentEntry: {
      return {
        ...state,
        activeContent: {
          ...state.activeContent,
          [action.payload.languageCode]: action.payload.contentEntry,
        },
      };
    }
    case ContentActionTypes.SaveEntry: {
      return {
        ...state,
        isSavingContentEntry: true,
      };
    }
    case ContentActionTypes.SaveEntrySuccess: {
      return {
        ...state,
        activeContent: {
          ...state.activeContent,
          [action.payload.languageCode]: action.payload.contentEntry,
        },
        isSavingContentEntry: false,
      };
    }
    case ContentActionTypes.SaveEntryError: {
      return {
        ...state,
        isSavingContentEntry: false,
      };
    }
    case ContentActionTypes.DeleteEntrySuccess: {
      return {
        ...state,
        activeContent: omit(state.activeContent, action.payload.languageCode),
      };
    }
    case ContentActionTypes.DeleteEntriesSuccess: {
      const { entries: removedEntries, categoryId } = action.payload;
      const changedEntries = state.contentHeaders[categoryId].filter(
        (c) => removedEntries.indexOf(c.id) === -1,
      );
      return {
        ...state,
        contentHeaders: {
          ...state.contentHeaders,
          [categoryId]: changedEntries,
        },
      };
    }
    case ContentActionTypes.MoveEntriesSuccess: {
      const { entries, newCategoryId, oldCategoryId } = action.payload;
      const { toKeep, toRemove } = splitEntries(
        state.contentHeaders[oldCategoryId],
        entries,
      );
      return {
        ...state,
        contentHeaders: {
          ...state.contentHeaders,
          [oldCategoryId]: toKeep,
          [newCategoryId]: state.contentHeaders[newCategoryId].concat(toRemove),
        },
      };
    }
    // Content Categories
    // ================================================
    case ContentActionTypes.GetCategoriesSuccess: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case ContentActionTypes.CreateCategorySuccess: {
      return {
        ...state,
        categories: [...state.categories, action.payload],
        contentHeaders: {
          ...state.contentHeaders,
          [action.payload.id]: [],
        },
      };
    }
    case ContentActionTypes.SavingCategorySuccess: {
      const updatedCategory = action.payload;
      return {
        ...state,
        categories: state.categories.map((c) =>
          c.id === updatedCategory.id ? { ...c, ...updatedCategory } : c,
        ),
      };
    }
    case ContentActionTypes.DeleteCategorySuccess: {
      const id = action.payload;
      const newContentHeaders = { ...state.contentHeaders };
      delete newContentHeaders[id];
      return {
        ...state,
        categories: state.categories.filter((c) => c.id !== id),
        contentHeaders: newContentHeaders,
      };
    }
    case ContentActionTypes.ContentEntryPageUnload: {
      return {
        ...state,
        activeContent: {},
      };
    }
    case ContentActionTypes.CopyGlobalContent: {
      const activeContent = Object.keys(action.payload).reduce(
        (agg, key) => {
          return {
            ...agg,
            [key]: {
              ...state.activeContent[key],
              ...(!state.activeContent[key].fromdatasync && {
                name: action.payload[key].name,
              }),
              sections: action.payload[key].sections,
            },
          };
        },
        { ...state.activeContent },
      );

      return {
        ...state,
        activeContent,
      };
    }
    case ContentActionTypes.CopyGlobalFileContent: {
      const activeContent = Object.keys(action.payload).reduce(
        (agg, key) => {
          return {
            ...agg,
            [key]: {
              ...state.activeContent[key],
              ...(!state.activeContent[key].fromdatasync && {
                name: action.payload[key].name,
              }),
              sections: action.payload[key].sections,
              attachments: action.payload[key].attachments,
            },
          };
        },
        { ...state.activeContent },
      );

      return {
        ...state,
        activeContent,
      };
    }
    default: {
      return state;
    }
  }
}

export const getTemplates = (state: State) =>
  state.templates.sort(sortAlphabetically);
export const getContentHeaders = (state: State) => state.contentHeaders;
export const getActiveContent = (state: State) => state.activeContent;
export const getCategories = (state: State) =>
  filterAndSortCategories(state.categories);
export const getGeneralCategories = (state: State) =>
  filterAndSortCategories(state.categories.filter((c) => !c.patientonly));
export const isLoadingContentEntry = (state: State) =>
  state.loadingContentEntry;
export const getIsSavingContentEntry = (state: State) =>
  state.isSavingContentEntry;
export const getLoadingErrors = (state: State) => state.errors;
export const loadingContentEntryError = (state: State) =>
  state.errors.loadingContentEntry;

function splitEntries(
  entries: ContentEntryHeader[],
  entriesToRemove: number[],
) {
  const toRemove = [];
  const toKeep = [];

  for (const entry of entries) {
    if (entriesToRemove.indexOf(entry.id) === -1) {
      toKeep.push(entry);
    } else {
      toRemove.push(entry);
    }
  }

  return {
    toRemove,
    toKeep,
  };
}
