export function parseInteger(value: string | number) {
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  return value;
}

export function removeNonNumbers(value: string): string {
  return value.replace(/[^\d]/gi, '');
}

export function zeroPad(value: number, maxLength: number) {
  if (value == null) {
    return value;
  }
  let s = value + '';
  while (s.length < maxLength) {
    s = '0' + s;
  }
  return s;
}
