import { Table, TableBody, TableCell, TableContainer, TableRow, } from '@material-ui/core';
import { Button } from '@react/components/Button';
import { Empty } from '@react/components/Empty';
import { MessageIcon } from '@react/components/icons/MessageIcon';
import { Loading } from '@react/components/Loading';
import { LoadingError } from '@react/components/LoadingError';
import { useMessageTemplatesQuery } from '@react/lib/api/hooks';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ActionsContainer, Container } from './MessageTemplatesList.styled';
import { FullHeightPaper, StyledLink, StyledSearchBar, StyledTableHead, StyledTableRow, } from './MessageTemplatesList.styled';
var MessageTemplatesList = function () {
    var t = useTranslations().t;
    var _a = useMessageTemplatesQuery(), data = _a.data, isError = _a.isError, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = React.useState(''), searchInputValue = _b[0], setSearchInputValue = _b[1];
    var debouncedSearchTerm = useDebouncedState(searchInputValue, 500);
    // If we don't do this we're back to every keystroke causing the
    // the list to be filtered.
    var results = React.useMemo(function () {
        if (data == null || data.data.length === 0) {
            return [];
        }
        var searchTermLower = debouncedSearchTerm.trim().toLowerCase();
        if (searchTermLower === '') {
            return data.data;
        }
        return data.data.filter(function (_a) {
            var content = _a.content, name = _a.name;
            return name.toLowerCase().includes(searchTermLower) ||
                content.toLowerCase().includes(searchTermLower);
        });
    }, [debouncedSearchTerm, data]);
    var noResults = data == null || data.data.length === 0;
    var noMatchingResults = !noResults && results.length === 0;
    return (React.createElement(Container, null,
        React.createElement(ActionsContainer, null,
            React.createElement(StyledSearchBar, { onChange: setSearchInputValue, placeholder: t.Admin.MessageTemplatesSearchPlaceholder, value: searchInputValue }),
            React.createElement(Button, { color: "primary", 
                /* @ts-ignore */
                component: Link, disabled: isLoading, to: "/admin/message-templates/new", variant: "contained" }, t.Admin.MessageTemplatesNewTemplateButtonText)),
        React.createElement(FullHeightPaper, null, isLoading ? (React.createElement(Loading, null)) : isError ? (React.createElement(LoadingError, { icon: React.createElement(MessageIcon, null), message: t.Admin.MessageTemplatesLoadingErrorMessage },
            React.createElement(Button, { color: "primary", onClick: function () { return refetch(); }, variant: "contained" }, t.Admin.MessageTemplatesLoadingErrorRetryButtonText))) : noResults ? (React.createElement(Empty, { icon: React.createElement(MessageIcon, null), message: t.Admin.MessageTemplatesEmptyMessage })) : noMatchingResults ? (React.createElement(Empty, { icon: React.createElement(MessageIcon, null), message: t.Admin.MessageTemplatesEmptyNonMatchingMessage })) : (React.createElement(TableContainer, null,
            React.createElement(Table, null,
                React.createElement(StyledTableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t.Admin.MessageTemplatesNameLabel),
                        React.createElement(TableCell, null, t.Admin.MessageTemplatesLastUpdatedByLabel))),
                React.createElement(TableBody, null, results.map(function (mt) { return (React.createElement(StyledTableRow, { key: mt.id, "data-testid": "MessageTemplateTableRow", tabIndex: 0 },
                    React.createElement(TableCell, { component: "th", scope: "row", padding: "none" },
                        React.createElement(StyledLink, { to: "/admin/message-templates/" + mt.id }, mt.name)),
                    React.createElement(TableCell, { padding: "none" },
                        React.createElement(StyledLink, { to: "/admin/message-templates/" + mt.id }, mt.modifiedBy.name)))); }))))))));
};
var ɵ0 = MessageTemplatesList;
export default MessageTemplatesList;
export { ɵ0 };
