import * as tslib_1 from "tslib";
import { LayoutActionTypes, } from '../actions/layout.actions';
export var initialState = {
    showSidenav: false,
    menuItems: [],
    activeMenuItem: null,
};
// Main layout reducer
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    // console.log(action);
    switch (action.type) {
        case LayoutActionTypes.CloseSidenav:
            return tslib_1.__assign({}, state, { showSidenav: false });
        case LayoutActionTypes.OpenSidenav:
            return tslib_1.__assign({}, state, { showSidenav: true });
        case LayoutActionTypes.ActivateMenuItem:
            return tslib_1.__assign({}, state, { activeMenuItem: action.payload });
        default:
            return state;
    }
}
// Selectors
export var getShowSidenav = function (state) { return state.showSidenav; };
export var getMenuItems = function (state) { return state.menuItems; };
export var getActiveMenuItem = function (state) { return state.activeMenuItem; };
