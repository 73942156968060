<div
  class="video-call-window"
  [ngClass]="['participant-count-' + callParticipants.length]"
>
  <ng-container *ngFor="let participant of callParticipants">
    <portal-participant
      [localIdentity]="localIdentity"
      [localName]="userFirstName"
      [participant]="participant.participant"
      [audioOutputDeviceId]="currentAudioOutputDeviceId"
      class="preview-video"
      [ngClass]="{
        'dominant-video': participant.isDominant
      }"
    ></portal-participant>
  </ng-container>
  <portal-camera
    class="preview-video preview-camera"
    #camera
    [isAudioEnabled]="isAudioEnabled"
    [isVideoEnabled]="isVideoEnabled"
  ></portal-camera>
  <div *ngIf="callParticipants.length === 0" class="no-participants">
    <p>{{ nooneHereText }}</p>
  </div>
  <div *ngIf="isReconnecting | async" class="loader">
    <div class="spinner" #spinner></div>
  </div>
  <div class="control-container">
    <div *ngIf="isConnecting" class="loader">
      <div class="spinner" #spinner></div>
    </div>
    <button
      *ngIf="isConnected"
      [attr.aria-label]="videoCallModalText.EndCall"
      class="leave-button"
      mat-icon-button
      (click)="leaveRoom()"
    >
      <mat-icon [inline]="true">call_end</mat-icon>
    </button>
    <button
      *ngIf="isConnected"
      [attr.aria-label]="
        isAudioEnabled ? videoCallModalText.Mute : videoCallModalText.Unmute
      "
      class="toggle-button"
      mat-icon-button
      (click)="toggleMute()"
    >
      <mat-icon [inline]="true">{{
        isAudioEnabled ? 'mic' : 'mic_off'
      }}</mat-icon>
    </button>
    <button
      *ngIf="isConnected"
      [attr.aria-label]="
        isVideoEnabled
          ? videoCallModalText.StopCamera
          : videoCallModalText.StartCamera
      "
      class="toggle-button"
      mat-icon-button
      (click)="toggleCamera()"
    >
      <mat-icon [inline]="true">{{
        isVideoEnabled ? 'videocam' : 'videocam_off'
      }}</mat-icon>
    </button>
    <button
      *ngIf="isConnected"
      class="toggle-button"
      mat-icon-button
      [matMenuTriggerFor]="inputMenu"
    >
      <mat-icon [inline]="true">more_vert</mat-icon>
    </button>
  </div>
  <mat-menu class="menu-panel" #inputMenu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="audioInputMenu">
      {{ videoCallModalText.AudioInputMenu }}
    </button>
    <button
      *ngIf="isAudioOutputEnabled"
      mat-menu-item
      [matMenuTriggerFor]="audioOutputMenu"
    >
      {{ videoCallModalText.AudioOutputMenu }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="videoMenu">
      {{ videoCallModalText.VideoMenu }}
    </button>
    <button
      mat-menu-item
      *ngIf="partnerInviteEnabled && !isPartnerInvited"
      (click)="invitePartnerInCall.emit()"
    >
      {{ invitePartnerText.InviteButton }}
    </button>
    <button
      mat-menu-item
      *ngIf="
        (videoCall | unInviteEnabled) &&
        partnerInviteEnabled &&
        isPartnerInvited
      "
      (click)="unInvitePartnerInCall.emit()"
    >
      {{ invitePartnerText.UnInviteButton }}
    </button>
  </mat-menu>
  <mat-menu class="menu-panel" #audioInputMenu="matMenu">
    <button mat-menu-item *ngFor="let audioInputDevice of audioInputDevices">
      <mat-radio-group
        [ngModel]="currentAudioInputDeviceId"
        (change)="changeAudioInputDevice($event)"
      >
        <mat-radio-button [value]="audioInputDevice.deviceId">{{
          audioInputDevice.label
        }}</mat-radio-button>
      </mat-radio-group>
    </button>
  </mat-menu>
  <mat-menu class="menu-panel" #audioOutputMenu="matMenu">
    <button mat-menu-item *ngFor="let audioOutputDevice of audioOutputDevices">
      <mat-radio-group
        [ngModel]="currentAudioOutputDeviceId"
        (change)="changeAudioOutputDevice($event)"
      >
        <mat-radio-button [value]="audioOutputDevice.deviceId">{{
          audioOutputDevice.label
        }}</mat-radio-button>
      </mat-radio-group>
    </button>
  </mat-menu>
  <mat-menu class="menu-panel" #videoMenu="matMenu">
    <button mat-menu-item *ngFor="let videoDevice of videoDevices">
      <mat-radio-group
        [ngModel]="currentVideoDeviceId"
        (change)="changeVideoDevice($event)"
      >
        <mat-radio-button [value]="videoDevice.deviceId">{{
          videoDevice.label
        }}</mat-radio-button>
      </mat-radio-group>
    </button>
  </mat-menu>
</div>
