import * as tslib_1 from "tslib";
import { ElementRef, ErrorHandler, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSelectChange, } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import get from 'lodash/get';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientService } from 'src/app/patients/services/patient.service';
import * as fromAuth from '../../../auth/reducers';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import * as NewMessageActions from '../../../messaging/actions/new-message.actions';
import * as fromMessages from '../../../messaging/reducers';
import * as fromSettings from '../../../settings/reducers';
import { PatientRequiringFormBase } from '../patient-requiring-form-base';
// Deprecated.
// Moved away from Angular version to the React one `./new-message-react.component.ts`
var NewMessageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NewMessageComponent, _super);
    function NewMessageComponent(_store, _fb, _dialogRef, _dialog, angulartics2, _errorHandler, _data, _patientService, _localisationService) {
        var _this = _super.call(this, _patientService, _localisationService) || this;
        _this._store = _store;
        _this._fb = _fb;
        _this._dialogRef = _dialogRef;
        _this._dialog = _dialog;
        _this.angulartics2 = angulartics2;
        _this._errorHandler = _errorHandler;
        _this._data = _data;
        _this.currentText$ = new BehaviorSubject('');
        _this.contentLength = 0;
        _this.maxContentLength = 2000;
        _this._subs = new Subscription();
        _this.angulartics2.eventTrack.next({
            action: 'Initialise New Message',
            properties: { category: 'Messaging', label: 'New Message' },
        });
        _this.newMessageText$ = _this._store.pipe(select(fromSettings.getSectionTranslations('NewMessage')));
        _this.labels$ = _this._store.pipe(select(fromMessages.getAssignableLabels));
        // if patient data passed to modal then use this for the form
        if (_this._data.patient) {
            _this._patient = _data.patient;
        }
        _this.clinicOptions$ = _this._store.pipe(select(fromAuth.getClinicOptions));
        return _this;
    }
    NewMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this.labels$.subscribe(function (labels) { return (_this.labels = labels); }));
        this._subs.add(this.newMessageText$.subscribe(function (val) { return (_this.newMessageText = val); }));
        this._subs.add(this.clinicOptions$.subscribe(function (opts) {
            _this._messageStarringEnabled = get(opts, 'messagingOptions.messageStarring');
        }));
        this.setupSearchObservables(this._errorHandler);
        this.setupForm();
        this.setupSearchBox(this.newMessageForm);
    };
    NewMessageComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    NewMessageComponent.prototype.setMessageTypeId = function (event) {
        this.messageTypeId = event.value;
    };
    NewMessageComponent.prototype.closeDialog = function () {
        var _this = this;
        var patientLength = this.newMessageForm.get('patientName').value.length;
        var messageTypeLength = this.newMessageForm.get('messageType').value.length;
        var contentLength = this.currentText$.value.length;
        var isEmpty = contentLength + patientLength + messageTypeLength === 0;
        if (!isEmpty) {
            var confirmDialog = this._dialog.open(ConfirmActionComponent, {
                data: {
                    message: this.newMessageText.DiscardTitle,
                    text: {
                        Cancel: this.newMessageText.Cancel,
                        Confirm: this.newMessageText.Confirm,
                    },
                },
            });
            confirmDialog.afterClosed().subscribe(function (result) {
                if (!result) {
                    _this.patientName.nativeElement.focus();
                }
                else {
                    _this._dialogRef.close({
                        content: null,
                        patientId: null,
                    });
                }
            });
        }
        else {
            this._dialogRef.close({
                content: null,
                patientId: null,
            });
        }
    };
    NewMessageComponent.prototype.currentTextChange = function (value) {
        this.currentText$.next(value);
    };
    NewMessageComponent.prototype.saveNewMessage = function () {
        var _this = this;
        var patient = this._patient || this.newMessageForm.get('patientName').value;
        var id = patient.Id;
        var patientName = patient.FirstName + " " + patient.LastName;
        var content = this.currentText$.value;
        var subject = this.newMessageForm.get('subject').value;
        var newMessage = {
            patientName: patientName,
            patientId: id,
            messageTypeId: this.messageTypeId,
            content: content,
            subject: subject,
        };
        var label = this.labels.find(function (l) { return l.messageTypeId === _this.messageTypeId; });
        var messageType = label.displayName;
        var info = {
            id: null,
            typeId: this.messageTypeId,
            type: messageType,
            patientName: patientName,
            patientFirstName: patient.FirstName,
            patientLastName: patient.LastName,
            patientId: patient.PatientIdentifier,
            patientDoB: patient.DateOfBirth,
            patientSent: false,
            isResolved: false,
            isUnread: false,
        };
        this._store.dispatch(new NewMessageActions.SendOutboundMessage({
            messageStarringEnabled: this._messageStarringEnabled,
            newMessage: newMessage,
            info: info,
        }));
        this._dialogRef.close({
            content: content,
            patientId: id,
        });
    };
    NewMessageComponent.prototype.getSubjectValid = function () {
        return ((this.newMessageForm.get('subject').touched ||
            this.newMessageForm.get('subject').dirty) &&
            !this.newMessageForm.get('subject').valid);
    };
    NewMessageComponent.prototype.setupForm = function () {
        var controls = {
            patientName: this.getFormPatient(),
            messageType: [{ value: '', disabled: false }, [Validators.required]],
            subject: [
                { value: '', disabled: false },
                [Validators.minLength(1), Validators.maxLength(256)],
            ],
        };
        this.newMessageForm = this._fb.group(controls);
    };
    return NewMessageComponent;
}(PatientRequiringFormBase));
export { NewMessageComponent };
