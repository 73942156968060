/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./new-message-react.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/congito.wrapper.service";
import * as i4 from "@angular/material/dialog";
var styles_NewMessageReactComponent = [];
var RenderType_NewMessageReactComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewMessageReactComponent, data: {} });
export { RenderType_NewMessageReactComponent as RenderType_NewMessageReactComponent };
export function View_NewMessageReactComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["newMessageComponentReact", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_NewMessageReactComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-new-message-component-react", [], null, null, null, View_NewMessageReactComponent_0, RenderType_NewMessageReactComponent)), i0.ɵdid(1, 4440064, null, 0, i1.NewMessageReactComponent, [i2.Store, i3.CognitoWrapperService, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewMessageReactComponentNgFactory = i0.ɵccf("portal-new-message-component-react", i1.NewMessageReactComponent, View_NewMessageReactComponent_Host_0, {}, {}, []);
export { NewMessageReactComponentNgFactory as NewMessageReactComponentNgFactory };
