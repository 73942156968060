import { EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
var GeneralSettingsComponent = /** @class */ (function () {
    function GeneralSettingsComponent(_fb) {
        this._fb = _fb;
        this.setLanguage = new EventEmitter();
        this._subs = new Subscription();
    }
    GeneralSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsForm = this._fb.group({
            language: [
                {
                    value: this.activeLanguage,
                    disabled: false,
                },
            ],
        });
        this._subs.add(this.settingsForm.get('language').valueChanges.subscribe(function (language) {
            _this.setLanguage.emit(language);
        }));
    };
    GeneralSettingsComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    return GeneralSettingsComponent;
}());
export { GeneralSettingsComponent };
