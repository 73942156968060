var _this = this;
import * as tslib_1 from "tslib";
import { ExpandMore } from '@material-ui/icons';
import { Button, DropdownMenu, Loading, Text, } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useErrorToast, useFileViewer } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect, useRef } from 'react';
import { scrollToBottom } from 'src/app/shared/utils';
import { useGetConversationActionHandler, useGetConversationActions, useMessagesQuery, } from '../hooks';
import { useMessageCreate } from '../hooks/useMessageCreate';
import { ChatStyled, Header, PatientDetails, PatientDoB, Subject, } from './Chat.styled';
import { ChatInput } from './ChatInput';
import { ChatMessage } from './ChatMessage';
import { TypingIndicator } from './ui';
var nestedMenuProps = {
    anchorOrigin: {
        vertical: 'center',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'center',
        horizontal: 'right',
    },
};
var assignLastMessageFlag = function (messages) {
    if (messages === void 0) { messages = []; }
    var lastClinicUserId = null;
    var clone = messages.slice();
    var assignedLastMessage = clone
        .reverse()
        .map(function (message) {
        var lastMessage = Boolean(message.clinicuserid !== lastClinicUserId);
        lastClinicUserId = message.clinicuserid;
        return tslib_1.__assign({}, message, { lastmessagegrouped: lastMessage });
    })
        .reverse();
    return assignedLastMessage;
};
var ɵ0 = assignLastMessageFlag;
export var Chat = function (_a) {
    var conversation = _a.conversation, folders = _a.folders, getTypingConversationMessage = _a.getTypingConversationMessage, getInitialConversationState = _a.getInitialConversationState, setConversationState = _a.setConversationState, onChange = _a.onChange, assignClinicUsers = _a.assignClinicUsers, fileUploadService = _a.fileUploadService, staffProfiles = _a.staffProfiles;
    var t = useTranslations().t;
    var dateTime = useLocaleDateTime();
    var handleAction = useGetConversationActionHandler();
    var getActions = useGetConversationActions('chat');
    var fileViewer = useFileViewer();
    var messagesInnerRef = useRef(null);
    var features = useFeatures();
    var featureProfilePhoto = checkFeature(features, 'profilePhotoOptions.enabled');
    var _b = useMessagesQuery({
        messageid: conversation.messageid,
        pageSize: 1000,
    }), messages = _b.data, isError = _b.isError, isLoading = _b.isLoading;
    var _c = useMessageCreate(), createMessage = _c.mutateAsync, isSendingError = _c.isError, isSendingLoading = _c.isLoading;
    useErrorToast(isError, {
        title: t.MessageToast.ErrorEncountered,
        message: t.MessageToast.MessageStarredBodyError,
    });
    useErrorToast(isSendingError, {
        title: t.MessageToast.ErrorEncountered,
        message: t.MessageToast.ErrorSending(conversation.patientfirstname + " " + conversation.patientlastname),
    });
    useEffect(function () {
        if (messagesInnerRef && messagesInnerRef.current) {
            scrollToBottom(messagesInnerRef.current, false);
        }
    }, [messages]);
    var formatPatientName = function (_a) {
        var patientfirstname = _a.patientfirstname, patientlastname = _a.patientlastname, patientidentifier = _a.patientidentifier;
        return [
            patientlastname + ',',
            patientfirstname,
            "(" + patientidentifier + ")",
        ].join(' ');
    };
    var formatPatientDoB = function (_a) {
        var patientdateofbirth = _a.patientdateofbirth;
        return dateTime.format(new Date(patientdateofbirth), 'P', {
            utc: true,
        });
    };
    var handleActionClick = function (action) {
        handleAction(action, conversation, messages);
    };
    var handleFileClick = function (message) {
        if (message.patientattachmenturl) {
            fileViewer.previewFile(message.patientattachmenturl);
        }
    };
    var handleSendMessage = function (content) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createMessage({
                        messageid: conversation.messageid,
                        content: content,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    if (isLoading) {
        return React.createElement(Loading, { fullHeight: true });
    }
    var actions = getActions(conversation, folders);
    var userTypingMessage = getTypingConversationMessage(conversation.messageid);
    var updatedMessages = assignLastMessageFlag(messages);
    return (React.createElement(ChatStyled, null,
        React.createElement(Header, null,
            React.createElement("div", null,
                React.createElement(PatientDetails, null,
                    React.createElement("a", { href: "/patients/" + conversation.patientid }, formatPatientName(conversation))),
                React.createElement(PatientDoB, null,
                    " - ",
                    formatPatientDoB(conversation)),
                React.createElement(Subject, null,
                    React.createElement(Text.Small, { bold: true }, t.Messages.Subject),
                    ' ',
                    React.createElement(Text.Small, null, conversation.messagesubject))),
            React.createElement("div", null,
                React.createElement(DropdownMenu, { menu: actions, onClick: handleActionClick, NestedMenuProps: nestedMenuProps, assignClinicUsers: assignClinicUsers },
                    React.createElement(Button, { color: "primary", variant: "contained", endIcon: React.createElement(ExpandMore, null) }, t.Messages.Actions)))),
        messages && (React.createElement("div", { className: "messages-container" },
            React.createElement("div", { ref: messagesInnerRef, className: "messages-inner" }, updatedMessages.map(function (message) { return (React.createElement(ChatMessage, { key: message.id, message: message, alignDirection: message.patientsent ? 'left' : 'right', onActionClick: handleActionClick, onFileClick: handleFileClick, fileUploadService: fileUploadService, staffProfiles: staffProfiles, featureProfilePhoto: featureProfilePhoto })); })))),
        userTypingMessage && (React.createElement(TypingIndicator, null, userTypingMessage)),
        conversation.canclinicreply && (React.createElement(ChatInput, { conversation: conversation, onSubmit: handleSendMessage, onChange: onChange, disabled: isSendingLoading, getInitialConversationState: getInitialConversationState, setConversationState: setConversationState }))));
};
export { ɵ0 };
