import { CircularProgress, TableBody, TableCell, TableRow, } from '@material-ui/core';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import UsersRow from '../usersRow';
import { LoaderWrapper, StyledTable, StyledTableFooter, StyledTableHead, } from './index.styled';
var UsersList = function (_a) {
    var users = _a.users, loading = _a.loading, onScrollToEnd = _a.onScrollToEnd, onClickInviteUser = _a.onClickInviteUser;
    var t = useTranslations().t;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTable, null,
            React.createElement(StyledTableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, t.Admin.Users.UsersListTableHeaderFirstName),
                    React.createElement(TableCell, null, t.Admin.Users.UsersListTableHeaderLastName),
                    React.createElement(TableCell, { className: "emailCol" }, t.Admin.Users.UsersListTableHeaderEmail),
                    React.createElement(TableCell, null, t.Admin.Users.UsersListTableHeaderStatus),
                    React.createElement(TableCell, null, t.Admin.Users.UsersListTableHeaderRegistered),
                    React.createElement(TableCell, { className: "fixedcol" }))),
            React.createElement(TableBody, null, users.map(function (user) { return (React.createElement(UsersRow, { key: user.id, user: user, onClickInviteUser: onClickInviteUser })); })),
            React.createElement(StyledTableFooter, null,
                React.createElement(TableRow, null, loading && (React.createElement(TableCell, { colSpan: 7 },
                    React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                        React.createElement(CircularProgress, null))))))),
        React.createElement(Waypoint, { onEnter: onScrollToEnd })));
};
var ɵ0 = UsersList;
export default UsersList;
export { ɵ0 };
