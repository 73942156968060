import { ErrorHandler, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FileUpload } from 'src/app/core/services/s3.service';
import { Subscription } from 'rxjs';
var ContentAssignedComponent = /** @class */ (function () {
    function ContentAssignedComponent(_fb, _s3, _errorHandler) {
        var _this = this;
        this._fb = _fb;
        this._s3 = _s3;
        this._errorHandler = _errorHandler;
        this.filterActive = false;
        this.filterString = '';
        this.content = [];
        this.setSearchString = new EventEmitter();
        this.removeAppContent = new EventEmitter();
        this.removePatientOnlyFile = new EventEmitter();
        this.config = {};
        this._subs = new Subscription();
        this.searchForm = this._fb.group({
            searchValue: this.filterString || ''
        });
        this._subs.add(this.searchForm
            .get('searchValue')
            .valueChanges.subscribe(function (val) { return _this.setSearchString.emit(val); }));
    }
    ContentAssignedComponent.prototype.ngOnDestroy = function () {
        this.setSearchString.emit('');
        this._subs.unsubscribe();
    };
    ContentAssignedComponent.prototype.viewFile = function (entry) {
        var _this = this;
        this._s3
            .readFileFromBucket({
            url: entry.uri
        })
            .then(function (res) {
            var fileWindow = window.open(res.url, '_blank');
            try {
                fileWindow.focus();
            }
            catch (err) {
                _this._errorHandler.handleError({
                    originalError: new Error("Pop-up Blocking is enabled - may restrict viewing files")
                });
            }
        });
    };
    ContentAssignedComponent.prototype.removeContent = function (entry) {
        if (!entry.frommeditex) {
            this.removeAppContent.emit({
                contententryheaderid: entry.id,
                assignmentid: entry.assignmentid
            });
        }
    };
    ContentAssignedComponent.prototype.isReadDateMocked = function (entryDate) {
        var date = new Date(entryDate);
        var year = date.getFullYear();
        // Mocked date was set to start of time (< 1970) in DB
        // Choice to compare against 1980 is arbitry but should be safe
        return year > 1980 ? true : false;
    };
    ContentAssignedComponent.prototype.clearFilter = function () {
        this.searchForm.patchValue({
            searchValue: ''
        });
    };
    ContentAssignedComponent.prototype.onClicked = function (item) {
        if (!item.isTransferring) {
            this.removePatientOnlyFile.emit(item);
        }
    };
    return ContentAssignedComponent;
}());
export { ContentAssignedComponent };
