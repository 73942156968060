import { ClinicUser } from '@react/types';
import React, { useEffect, useState } from 'react';

export function useFilterByFullName(
  users: ClinicUser[],
): [ClinicUser[], string, React.Dispatch<React.SetStateAction<string>>] {
  const filterByFullName = (value: string) =>
    users.filter(
      (user) => user.userfullname.toLowerCase().indexOf(value) !== -1,
    );

  const [searchState, setSearchState] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    if (searchState !== '') {
      setFilteredUsers(filterByFullName(searchState.toLowerCase()));
    }
  }, [searchState]);

  return [filteredUsers, searchState, setSearchState];
}
