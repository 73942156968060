import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GetContentEntries } from '../actions/content.actions';
import { DeleteAllContentLinksError, DeleteAllContentLinksSuccess, DeleteContentLinkError, DeleteContentLinkSuccess, DeleteSomeContentLinksError, DeleteSomeContentLinksSuccess, GetAvailableToLinkContentError, GetAvailableToLinkContentSuccess, LinkContentActionTypes, LinkContentError, LinkContentSuccess, LoadChildrenError, LoadChildrenSuccess, } from '../actions/link-content.actions';
import { LinkContentService } from '../services/link-content.service';
var LinkContentEffects = /** @class */ (function () {
    function LinkContentEffects(actions$, linkContentService, _error) {
        var _this = this;
        this.actions$ = actions$;
        this.linkContentService = linkContentService;
        this._error = _error;
        this.linkContent$ = this.actions$.pipe(ofType(LinkContentActionTypes.LinkContent), switchMap(function (action) {
            var _a = action.payload, contentToLink = _a.contentToLink, contentToShow = _a.contentToShow;
            return _this.linkContentService
                .linkContent(contentToShow, contentToLink)
                .pipe(
            // TODO: Fix types
            // @ts-ignore
            mergeMap(function (res) {
                return [new LinkContentSuccess(res), new GetContentEntries()];
            }), catchError(function (err) {
                console.error(err);
                return of(new LinkContentError(err));
            }));
        }));
        this.fetchAvailableToLink$ = this.actions$.pipe(ofType(LinkContentActionTypes.GetAvailableToLinkContent), switchMap(function (action) {
            var contentCategory = action.payload.contentCategory;
            return _this.linkContentService
                .fetchAvailableToLinkContent(contentCategory)
                .pipe(
            // TODO: Fix types
            // @ts-ignore
            map(function (res) {
                return new GetAvailableToLinkContentSuccess(res);
            }), catchError(function (err) {
                console.error(err);
                return of(new GetAvailableToLinkContentError(err));
            }));
        }));
        this.deleteContentLink$ = this.actions$.pipe(ofType(LinkContentActionTypes.DeleteContentLink), switchMap(function (action) {
            return _this.linkContentService.deleteContentLink(action.payload).pipe(mergeMap(function (res) {
                return [new DeleteContentLinkSuccess(res), new GetContentEntries()];
            }), catchError(function (err) {
                console.error(err);
                return of(new DeleteContentLinkError(err));
            }));
        }));
        this.deleteSomeContentLinks$ = this.actions$.pipe(ofType(LinkContentActionTypes.DeleteSomeContentLinks), switchMap(function (action) {
            return _this.linkContentService
                .deleteSomeContentLinks(action.payload)
                .pipe(mergeMap(function (res) {
                return [
                    new DeleteSomeContentLinksSuccess(res),
                    new GetContentEntries(),
                ];
            }), catchError(function (err) {
                console.error(err);
                return of(new DeleteSomeContentLinksError(err));
            }));
        }));
        this.deleteAllContentLinks$ = this.actions$.pipe(ofType(LinkContentActionTypes.DeleteAllContentLinks), switchMap(function (action) {
            return _this.linkContentService.deleteAllContentLinks(action.payload).pipe(mergeMap(function (res) {
                return [
                    new DeleteAllContentLinksSuccess(res),
                    new GetContentEntries(),
                ];
            }), catchError(function (err) {
                console.error(err);
                return of(new DeleteAllContentLinksError(err));
            }));
        }));
        this.fetchChildren$ = this.actions$.pipe(ofType(LinkContentActionTypes.LoadChildren), switchMap(function (action) {
            var headerId = action.payload.headerId;
            return _this.linkContentService.loadChildren(headerId).pipe(map(function (res) {
                return new LoadChildrenSuccess(res);
            }), catchError(function (err) {
                console.error(err);
                return of(new LoadChildrenError(err));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LinkContentEffects.prototype, "linkContent$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LinkContentEffects.prototype, "fetchAvailableToLink$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LinkContentEffects.prototype, "deleteContentLink$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LinkContentEffects.prototype, "deleteSomeContentLinks$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LinkContentEffects.prototype, "deleteAllContentLinks$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], LinkContentEffects.prototype, "fetchChildren$", void 0);
    return LinkContentEffects;
}());
export { LinkContentEffects };
