<div class="time-picker">
  <input
    aria-label="Hours"
    class="time-picker-input"
    maxlength="2"
    [disabled]="disabled"
    [min]="minHours"
    [max]="maxHours"
    [value]="hours"
    (blur)="handleHoursBlur()"
    (focus)="selectAll($event)"
    (input)="handleHoursInput($event)"
  />
  <span class="time-picker-divider">:</span>
  <input
    aria-label="Minutes"
    class="time-picker-input"
    maxlength="2"
    max="59"
    [disabled]="disabled"
    [value]="minutes"
    (blur)="handleMinutesBlur()"
    (focus)="selectAll($event)"
    (input)="handleMinutesInput($event)"
  />
  <select
    *ngIf="hoursType === '12H'"
    class="time-picker-period"
    [disabled]="disabled"
    [value]="period"
    (change)="handlePeriodChange($event)"
  >
    <option value="AM">AM</option>
    <option value="PM">PM</option>
  </select>
  <mat-button-toggle-group
    class="time-picker-hours-type"
    [disabled]="disabled"
    [value]="hoursType"
    (change)="handleHoursTypeChange($event)"
  >
    <mat-button-toggle class="time-picker-hours-type-button" value="12H"
      >12H</mat-button-toggle
    >
    <mat-button-toggle class="time-picker-hours-type-button" value="24H"
      >24H</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
