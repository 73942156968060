import { Action } from '@ngrx/store';
import { ActiveTab } from '../models/active-tab';
import {
  Category,
  UpdateCategoryRequest,
  UpdateCategoryResponse,
} from '../models/category';
import { ContentEntry, GroupedContentEntries } from '../models/content-entry';
import { ContentTemplate } from '../models/content-template';
import { ContentType } from '../models/content-type';
import { DeleteEntriesRequest } from '../models/requests/delete-entries.request';
import { MoveEntriesResponse } from '../models/requests/move-entries.response';
import { DeleteEntriesResponse } from '../models/responses/delete-entries.response';
import { MoveEntriesRequest } from '../models/responses/move-entries.response';

export enum ContentActionTypes {
  GetContentTypes = '[Content] Get Content Types',
  GetContentTypesSuccess = '[Content] Get Content Types Success',
  GetContentTypesError = '[Content] Get Content Types Error',

  GetTemplates = '[Content] Get Content Template',
  GetTemplatesSuccess = '[Content] Get Content Template Success',
  GetTemplatesError = '[Content] Get Content Template Error',

  GetContentEntries = '[Content] Get Content Entries',
  GetContentEntriesSuccess = '[Content] Get Content Entries Success',
  GetContentEntriesError = '[Content] Get Content Entries Error',

  GetContentEntry = '[Content] Get Content Entry',
  GetContentEntrySuccess = '[Content] Get Content Entry Success',
  GetContentEntryError = '[Content] Get Content Entry Error',

  CreateEntry = '[Content] Create Content Entry',
  CreateEntrySuccess = '[Content] Create Content Entry Success',
  CreateEntryError = '[Content] Create Content Entry Error',

  CreateNewContentEntry = '[Content] Create New Content Entry',

  SaveEntry = '[Content] Save Content Entry',
  SaveEntrySuccess = '[Content] Save Content Entry Success',
  SaveEntryError = '[Content] Save Content Entry Error',

  DeleteEntry = '[Content] Delete Content Entry',
  DeleteEntrySuccess = '[Content] Delete Content Entry Success',
  DeleteEntryError = '[Content] Delete Content Entry Error',

  DeleteEntries = '[Content] Delete Content Entries',
  DeleteEntriesSuccess = '[Content] Delete Content Entries Success',
  DeleteEntriesError = '[Content] Delete Content Entries Entries',

  MoveEntries = '[Content] Move Content Entries',
  MoveEntriesSuccess = '[Content] Move Content Entries Success',
  MoveEntriesError = '[Content] Move Content Entries Entries',

  // Templates
  // ===================================================
  SavingTemplate = '[Content] Saving Template',
  SavingTemplateSuccess = '[Content] Saving Template Success',
  SavingTemplateError = '[Content] Saving Template Error',

  CreateTemplate = '[Content] Create Template',
  CreateTemplateSuccess = '[Content] Create Template Success',
  CreateTemplateError = '[Content] Create Template Error',

  CreatedTypeAndTemplateSuccess = '[Content] Create Type and Template Success',

  DeleteTemplate = '[Content] Deleting Template',
  DeleteTemplateSuccess = '[Content] Deleting Template Success',
  DeleteTemplateError = '[Content] Deleting Template Error',

  SavingContent = '[Content] Saving Content',
  SavingContentSuccess = '[Content] Saving Content Success',
  SavingContentError = '[Content] Saving Content Error',

  // Category
  // ===================================================
  GetCategories = '[Content] Get Content Categories',
  GetCategoriesSuccess = '[Content] Get Content Categories Success',
  GetCategoriesError = '[Content] Get Content Categories Error',

  SavingCategory = '[Content] Saving Category',
  SavingCategorySuccess = '[Content] Saving Category Success',
  SavingCategoryError = '[Content] Saving Category Error',

  CreateCategory = '[Content] Create Category',
  CreateCategorySuccess = '[Content] Create Category Success',
  CreateCategoryError = '[Content] Create Category Error',

  DeleteCategory = '[Content] Deleting Category',
  DeleteCategorySuccess = '[Content] Deleting Category Success',
  DeleteCategoryError = '[Content] Deleting Category Error',

  // Client Side
  LoadingDirectory = '[Content] Loading Items for Content Directory',
  LoadedDirectory = '[Content] Loaded Items for Content Directory',

  LoadingTemplate = '[Content] Loading Template',
  LoadedTemplate = '[Content] Loaded Template',

  LoadingContent = '[Content] Loading Content',
  LoadedContent = '[Content] Loaded Content',

  OpenDirectorySection = '[Content] Open Directory Section',

  FilterContent = '[Content] Filter Content List',
  ClearFilter = '[Content] Clear Content List Filter',

  ChangeTab = '[Content] Change Current Content Tab',

  ContentEntryPageUnload = '[Content] Content Entry Page Unload',

  RefreshContent = '[Content-All] Refresh core content data',

  GetContentEditorEnabled = '[Content] Get Content Editor Enabled',
  GetContentEditorEnabledSuccess = '[Content] Get Content Editor Enabled Success',
  GetContentEditorEnabledError = '[Content] Get Content Editor Enabled Error',

  CopyGlobalContent = '[Content] Copy Global Content',
  CopyGlobalContentSuccessMissingTranslations = '[Content] Copy Global Content Success Missing Translations',
  CopyGlobalContentSuccessAdditionalTranslations = '[Content] Copy Global Content Success Additional Translations',
  CopyGlobalContentError = '[Content] Copy Global Content Error',

  CopyGlobalFileContent = '[Content] Copy Global File Content',
  CopyGlobalFileContentSuccessMissingTranslations = '[Content] Copy Global File Content Success Missing Translations',
  CopyGlobalFileContentSuccessAdditionalTranslations = '[Content] Copy Global File Content Success Additional Translations',
  CopyGlobalFileContentError = '[Content] Copy Global File Content Error',
}

// Action Creators
// ==================================================
// Server Side

export class GetCategories implements Action {
  readonly type = ContentActionTypes.GetCategories;

  constructor(public payload?: any) {}
}
export class GetCategoriesSuccess implements Action {
  readonly type = ContentActionTypes.GetCategoriesSuccess;

  constructor(public payload: Category[]) {}
}
export class GetCategoriesError implements Action {
  readonly type = ContentActionTypes.GetCategoriesError;

  constructor(public payload: any) {}
}

export class GetContentTypes implements Action {
  readonly type = ContentActionTypes.GetContentTypes;

  constructor(public payload?: any) {}
}
export class GetContentTypesSuccess implements Action {
  readonly type = ContentActionTypes.GetContentTypesSuccess;

  constructor(public payload: ContentType[]) {}
}
export class GetContentTypesError implements Action {
  readonly type = ContentActionTypes.GetContentTypesError;

  constructor(public payload: any) {}
}

export class GetTemplates implements Action {
  readonly type = ContentActionTypes.GetTemplates;

  constructor(public payload?: any) {}
}
export class GetTemplatesSuccess implements Action {
  readonly type = ContentActionTypes.GetTemplatesSuccess;

  constructor(public payload: ContentTemplate[]) {}
}
export class GetTemplatesError implements Action {
  readonly type = ContentActionTypes.GetTemplatesError;

  constructor(public payload: any) {}
}

export class GetContentEntries implements Action {
  readonly type = ContentActionTypes.GetContentEntries;

  constructor(public payload?: any) {}
}
export class GetContentEntriesSuccess implements Action {
  readonly type = ContentActionTypes.GetContentEntriesSuccess;

  constructor(public payload: GroupedContentEntries) {}
}
export class GetContentEntriesError implements Action {
  readonly type = ContentActionTypes.GetContentEntriesError;

  constructor(public payload: any) {}
}

export class GetContentEntry implements Action {
  readonly type = ContentActionTypes.GetContentEntry;

  constructor(public payload: number) {}
}
export class GetContentEntrySuccess implements Action {
  readonly type = ContentActionTypes.GetContentEntrySuccess;

  constructor(
    public payload: {
      [languageCode: string]: ContentEntry;
    },
  ) {}
}
export class GetContentEntryError implements Action {
  readonly type = ContentActionTypes.GetContentEntryError;

  constructor(public payload: any) {}
}

export class CreateEntry implements Action {
  readonly type = ContentActionTypes.CreateEntry;

  constructor(
    /**
     * `languageCode` and `parentId` are only required if the
     * ContentEntry is translated content.
     */
    public payload:
      | {
          contentEntry: ContentEntry;
        }
      | {
          contentEntry: ContentEntry;
          languageCode: string;
          parentId: number;
        },
  ) {}
}
export class CreateEntrySuccess implements Action {
  readonly type = ContentActionTypes.CreateEntrySuccess;

  constructor(
    public payload: { contentEntry: ContentEntry; languageCode: string },
  ) {}
}
export class CreateEntryError implements Action {
  readonly type = ContentActionTypes.CreateEntryError;

  constructor(public payload: any) {}
}

export class CreateNewContentEntry implements Action {
  readonly type = ContentActionTypes.CreateNewContentEntry;

  constructor(
    public payload: {
      contentEntry: ContentEntry;
      languageCode: string;
    },
  ) {}
}

export class SaveEntry implements Action {
  readonly type = ContentActionTypes.SaveEntry;

  constructor(
    public payload: {
      contentEntry: ContentEntry;
      languageCode: string;
    },
  ) {}
}
export class SaveEntrySuccess implements Action {
  readonly type = ContentActionTypes.SaveEntrySuccess;

  constructor(
    public payload: { contentEntry: ContentEntry; languageCode?: string },
  ) {}
}
export class SaveEntryError implements Action {
  readonly type = ContentActionTypes.SaveEntryError;

  constructor(public payload: any) {}
}

export class DeleteEntry implements Action {
  readonly type = ContentActionTypes.DeleteEntry;

  constructor(
    public payload: {
      contentEntry: ContentEntry;
      languageCode: string;
    },
  ) {}
}
export class DeleteEntrySuccess implements Action {
  readonly type = ContentActionTypes.DeleteEntrySuccess;

  constructor(
    public payload: {
      contentEntry: ContentEntry;
      languageCode: string;
    },
  ) {}
}
export class DeleteEntryError implements Action {
  readonly type = ContentActionTypes.DeleteEntryError;

  constructor(public payload: any) {}
}

export class MoveEntries implements Action {
  readonly type = ContentActionTypes.MoveEntries;

  constructor(public payload: MoveEntriesRequest) {}
}
export class MoveEntriesSuccess implements Action {
  readonly type = ContentActionTypes.MoveEntriesSuccess;

  constructor(public payload: MoveEntriesResponse) {}
}
export class MoveEntriesError implements Action {
  readonly type = ContentActionTypes.MoveEntriesError;

  constructor(public payload: any) {}
}

export class DeleteEntries implements Action {
  readonly type = ContentActionTypes.DeleteEntries;

  constructor(public payload: DeleteEntriesRequest) {}
}
export class DeleteEntriesSuccess implements Action {
  readonly type = ContentActionTypes.DeleteEntriesSuccess;

  constructor(public payload: DeleteEntriesResponse) {}
}
export class DeleteEntriesError implements Action {
  readonly type = ContentActionTypes.DeleteEntriesError;

  constructor(public payload: any) {}
}

// Category Actions
// ============================================
export class SavingCategory implements Action {
  readonly type = ContentActionTypes.SavingCategory;

  constructor(public payload: UpdateCategoryRequest) {}
}
export class SavingCategorySuccess implements Action {
  readonly type = ContentActionTypes.SavingCategorySuccess;

  constructor(public payload: UpdateCategoryResponse) {}
}
export class SavingCategoryError implements Action {
  readonly type = ContentActionTypes.SavingCategoryError;

  constructor(public payload: any) {}
}

export class CreateCategory implements Action {
  readonly type = ContentActionTypes.CreateCategory;

  constructor(public payload: Category) {}
}
export class CreateCategorySuccess implements Action {
  readonly type = ContentActionTypes.CreateCategorySuccess;

  constructor(public payload: Category) {}
}
export class CreateCategoryError implements Action {
  readonly type = ContentActionTypes.CreateCategoryError;

  constructor(public payload: any) {}
}

export class DeleteCategory implements Action {
  readonly type = ContentActionTypes.DeleteCategory;

  constructor(public payload?: number) {}
}
export class DeleteCategorySuccess implements Action {
  readonly type = ContentActionTypes.DeleteCategorySuccess;

  constructor(public payload: number) {}
}
export class DeleteCategoryError implements Action {
  readonly type = ContentActionTypes.DeleteCategoryError;

  constructor(public payload: any) {}
}

// Templates
// ===================================================
export class CreateTemplate implements Action {
  readonly type = ContentActionTypes.CreateTemplate;

  constructor(public payload: ContentTemplate) {}
}
export class CreateTemplateSuccess implements Action {
  readonly type = ContentActionTypes.CreateTemplateSuccess;

  constructor(public payload: ContentTemplate) {}
}
export class CreateTemplateError implements Action {
  readonly type = ContentActionTypes.CreateTemplateError;

  constructor(public payload: any) {}
}

export class CreatedTypeAndTemplateSuccess implements Action {
  readonly type = ContentActionTypes.CreatedTypeAndTemplateSuccess;

  constructor(
    // type id
    public payload: number,
  ) {}
}

export class DeleteTemplate implements Action {
  readonly type = ContentActionTypes.DeleteTemplate;

  constructor(public payload: number) {}
}
export class DeleteTemplateSuccess implements Action {
  readonly type = ContentActionTypes.DeleteTemplateSuccess;

  constructor(public payload: number) {}
}
export class DeleteTemplateError implements Action {
  readonly type = ContentActionTypes.DeleteTemplateError;

  constructor(public payload: any) {}
}

export class SavingTemplate implements Action {
  readonly type = ContentActionTypes.SavingTemplate;

  constructor(public payload: ContentTemplate) {}
}
export class SavingTemplateSuccess implements Action {
  readonly type = ContentActionTypes.SavingTemplateSuccess;

  constructor(public payload: ContentTemplate) {}
}
export class SavingTemplateError implements Action {
  readonly type = ContentActionTypes.SavingTemplateError;

  constructor(public payload: any) {}
}

// Client Side
export class SavingContent implements Action {
  readonly type = ContentActionTypes.SavingContent;

  constructor(public payload?: any) {}
}
export class SavingContentSuccess implements Action {
  readonly type = ContentActionTypes.SavingContentSuccess;

  constructor(public payload?: any) {}
}
export class SavingContentError implements Action {
  readonly type = ContentActionTypes.SavingContentError;

  constructor(public payload?: any) {}
}

export class OpenDirectorySection implements Action {
  readonly type = ContentActionTypes.OpenDirectorySection;

  constructor(public payload: string) {}
}

export class LoadingDirectory implements Action {
  readonly type = ContentActionTypes.LoadingDirectory;

  constructor(public payload?: any) {}
}
export class LoadedDirectory implements Action {
  readonly type = ContentActionTypes.LoadedDirectory;

  constructor(public payload?: any) {}
}

export class LoadingTemplate implements Action {
  readonly type = ContentActionTypes.LoadingTemplate;

  constructor(public payload?: any) {}
}
export class LoadedTemplate implements Action {
  readonly type = ContentActionTypes.LoadedTemplate;

  constructor(public payload?: any) {}
}

export class LoadingContent implements Action {
  readonly type = ContentActionTypes.LoadingContent;

  constructor(public payload?: any) {}
}
export class LoadedContent implements Action {
  readonly type = ContentActionTypes.LoadedContent;

  constructor(public payload?: any) {}
}

export class FilterContent implements Action {
  readonly type = ContentActionTypes.FilterContent;

  constructor(public payload: string) {}
}
export class ClearFilter implements Action {
  readonly type = ContentActionTypes.ClearFilter;

  constructor(public payload?: any) {}
}
export class ChangeTab implements Action {
  readonly type = ContentActionTypes.ChangeTab;

  constructor(public payload: ActiveTab) {}
}

export class ContentEntryPageUnload implements Action {
  readonly type = ContentActionTypes.ContentEntryPageUnload;
}

export class GetContentEditorEnabled implements Action {
  readonly type = ContentActionTypes.GetContentEditorEnabled;

  constructor(public payload?) {}
}
export class GetContentEditorEnabledSuccess implements Action {
  readonly type = ContentActionTypes.GetContentEditorEnabledSuccess;

  constructor(public payload: boolean) {}
}
export class GetContentEditorEnabledError implements Action {
  readonly type = ContentActionTypes.GetContentEditorEnabledError;

  constructor(public payload: boolean) {}
}

export class CopyGlobalContent implements Action {
  readonly type = ContentActionTypes.CopyGlobalContent;

  constructor(public payload: any) {}
}

export class CopyGlobalContentSuccessMissingTranslations implements Action {
  readonly type =
    ContentActionTypes.CopyGlobalContentSuccessMissingTranslations;

  constructor(public payload: string) {}
}

export class CopyGlobalContentSuccessAdditionalTranslations implements Action {
  readonly type =
    ContentActionTypes.CopyGlobalContentSuccessAdditionalTranslations;

  constructor(public payload: string) {}
}

export class CopyGlobalContentError implements Action {
  readonly type = ContentActionTypes.CopyGlobalContentError;

  constructor(public payload: boolean) {}
}

export class CopyGlobalFileContent implements Action {
  readonly type = ContentActionTypes.CopyGlobalFileContent;

  constructor(public payload: any) {}
}

export class CopyGlobalFileContentError implements Action {
  readonly type = ContentActionTypes.CopyGlobalFileContentError;

  constructor(public payload: boolean) {}
}

export class CopyGlobalFileContentSuccessMissingTranslations implements Action {
  readonly type =
    ContentActionTypes.CopyGlobalFileContentSuccessMissingTranslations;

  constructor(public payload: string) {}
}

export class CopyGlobalFileContentSuccessAdditionalTranslations
  implements Action
{
  readonly type =
    ContentActionTypes.CopyGlobalFileContentSuccessAdditionalTranslations;

  constructor(public payload: string) {}
}

export type ContentActionsUnion =
  // Server Side
  // Entries
  | GetContentEntries
  | GetContentEntriesSuccess
  | GetContentEntriesError
  | GetContentEntry
  | GetContentEntrySuccess
  | GetContentEntryError
  | CreateEntry
  | CreateEntrySuccess
  | CreateEntryError
  | CreateNewContentEntry
  | SaveEntry
  | SaveEntrySuccess
  | SaveEntryError
  | DeleteEntry
  | DeleteEntrySuccess
  | DeleteEntryError
  | DeleteEntries
  | DeleteEntriesSuccess
  | DeleteEntriesError
  | MoveEntries
  | MoveEntriesSuccess
  | MoveEntriesError
  // Categories
  | GetCategories
  | GetCategoriesSuccess
  | GetCategoriesError
  | CreateCategory
  | CreateCategorySuccess
  | CreateCategoryError
  | SavingCategory
  | SavingCategorySuccess
  | SavingCategoryError
  | DeleteCategory
  | DeleteCategorySuccess
  | DeleteCategoryError
  // Templates
  | GetTemplates
  | GetTemplatesSuccess
  | GetTemplatesError
  | CreateTemplate
  | CreateTemplateSuccess
  | CreateTemplateError
  | SavingTemplate
  | SavingTemplateSuccess
  | SavingTemplateError
  | DeleteTemplate
  | DeleteTemplateSuccess
  | DeleteTemplateError
  // UI
  // Loading
  | SavingContent
  | SavingContentSuccess
  | SavingContentError
  | LoadingDirectory
  | LoadedDirectory
  | LoadingTemplate
  | LoadedTemplate
  | LoadingContent
  | LoadedContent
  // Active Sections
  | OpenDirectorySection
  // Filtering
  | FilterContent
  | ClearFilter
  | ChangeTab
  | ContentEntryPageUnload
  // Content Editing
  | GetContentEditorEnabled
  | GetContentEditorEnabledSuccess
  | GetContentEditorEnabledError
  // Global Portal
  | CopyGlobalContent
  | CopyGlobalContentSuccessMissingTranslations
  | CopyGlobalContentSuccessAdditionalTranslations
  | CopyGlobalContentError
  | CopyGlobalFileContent
  | CopyGlobalFileContentError
  | CopyGlobalFileContentSuccessAdditionalTranslations
  | CopyGlobalFileContentSuccessMissingTranslations;
