<div class="medications-container" fxLayout="column" fxLayoutGap="10px">
  <portal-patient-list
    [medications]="potentialDuplicate"
    [title]="(medicationTableText$ | async).PotentialDupe"
    [text]="medicationTableText$ | async"
    [emptyMessage]="(medicationTableText$ | async).NoPotentialDuplicates"
    [noSearchResultsMessage]="(medicationTableText$ | async).NoSearchResults"
    [warning]="true"
    [loading]="potentialDuplicatesLoading"
    (medicationSelected)="gotoPatient($event)"
    (searchTermChanged)="onPotentialDuplicatesSearchTermChange($event)"
  ></portal-patient-list>

  <portal-patient-list
    [medications]="actionRequired"
    [title]="(medicationTableText$ | async).ActionRequired"
    [text]="medicationTableText$ | async"
    [emptyMessage]="(medicationTableText$ | async).NoAction"
    [noSearchResultsMessage]="(medicationTableText$ | async).NoSearchResults"
    [warning]="true"
    [medicationType]="medicationType.ActionRequired"
    [loading]="actionRequiredLoading"
    (medicationSelected)="gotoPatient($event)"
    (searchTermChanged)="onActionMedicationSearchTermChange($event)"
  ></portal-patient-list>

  <portal-patient-list
    [medications]="noActionRequired"
    [title]="(medicationTableText$ | async).NoActionRequired"
    [text]="medicationTableText$ | async"
    [emptyMessage]="(medicationTableText$ | async).NoMedications"
    [noSearchResultsMessage]="(medicationTableText$ | async).NoSearchResults"
    [loading]="noActionRequiredLoading"
    (medicationSelected)="gotoPatient($event)"
    (searchTermChanged)="onNoActionMedicationSearchTermChange($event)"
  ></portal-patient-list>
</div>
