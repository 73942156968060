import { Popover } from '@material-ui/core';
import { Button, FilterChip } from '@react/components';
import { CognitoStatus, IClinicUserFilters } from '@react/lib/api/types';
import { useTranslations } from '@react/lib/i18n';
import React, { useRef, useState } from 'react';
import FilterPanel from '../filterPanel';

export interface FilterProps {
  filters: IClinicUserFilters;
  setFilters: (localFilters: IClinicUserFilters) => void;
}

const Filters: React.FunctionComponent<FilterProps> = ({
  filters,
  setFilters,
}) => {
  const { t } = useTranslations();

  const filterPopoverAnchor = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const apply = (localFilters: IClinicUserFilters) => {
    setFilters(localFilters);
    setOpen(false);
  };

  const deleteStatusFilter = (item: keyof typeof CognitoStatus) => {
    let status: Array<keyof typeof CognitoStatus> = Array.isArray(
      filters.status,
    )
      ? Array.from(filters.status)
      : [];
    status = status.filter((key) => item !== key);

    setFilters({
      ...filters,
      status,
    });
  };

  return (
    <>
      <Button
        data-testid="btn_filter"
        color="primary"
        variant="contained"
        ref={filterPopoverAnchor}
        onClick={() => setOpen(true)}
      >
        + {t.Admin.Users.AddFilter}
      </Button>
      <Popover
        id="filter-Popover"
        open={open}
        anchorEl={filterPopoverAnchor.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FilterPanel filters={filters} apply={apply} />
      </Popover>

      {/* Chips */}
      {/* Status */}
      {Array.isArray(filters.status) &&
        filters.status.map((item) => (
          <FilterChip
            key={item}
            data-testid={`filterChip${t.Admin.Users[`${item}Status`]}`}
            label={t.Admin.Users[`${item}Status`]}
            onDelete={() => deleteStatusFilter(item)}
          />
        ))}
    </>
  );
};

export default Filters;
