import { Offline } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing/dist/browser';
import { setLogger } from 'react-query';
import { environment } from '../../environments/environment';
import { versions } from '../../environments/versions';

export const setup = () => {
  Sentry.init({
    dsn: 'https://3217f81fa9aa4d198d1b48d3549e5ffd@sentry.io/1366588',
    environment: environment.environment,
    integrations: [new BrowserTracing(), new Offline()],
    release: `salve-portal:${versions.branch}@${versions.version}.${versions.revision}`,
    tracesSampleRate: 1.0,
    // ignore these errors because they seem to fire on the main.js bundle every time a user loads the clinic portal. that bundle size isnt going to reduce in the current state of the app
    ignoreErrors: ['Uncompressed Asset', 'Large Render Blocking Asset']
  });

  Sentry.configureScope((scope) => {
    scope.setTag('framework', 'react');
    scope.setTag('version', versions.version);
    scope.setTag('branch', versions.branch);
    scope.setTag('hostname', window.location.hostname);
    scope.setTag('clinic', window.location.hostname.split('.')[0]);
  });

  // Hook react-query logger with Sentry
  setLogger({
    log: (message) => {
      console.log(message);
    },
    warn: (message) => {
      console.warn(message);
    },
    error: (error) => {
      console.error(error);
      Sentry.captureException(error);
    }
  });
};
