export class GetConversationRequest {
  private Action = 'GetClinicPatientMessage';
  public PublicToken: string;
  public MessageId: number;
  public AfterMessageItemId?: number;

  constructor({ PublicToken, MessageId, AfterMessageItemId }) {
    this.PublicToken = PublicToken;
    this.MessageId = MessageId;
    this.AfterMessageItemId = AfterMessageItemId;
  }
}
