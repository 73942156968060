var _this = this;
import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { debounceAsync } from '@react/lib/debounceAsync';
import { useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient, } from 'react-query';
export function useSIDERecipeListQuery(_a) {
    var searchTerm = _a.searchTerm, filters = _a.filters;
    var apiClient = useApiClient().portal;
    return useInfiniteQuery(['sideRecipes', { searchTerm: searchTerm, filters: filters }], function (_a) {
        var pageParam = _a.pageParam;
        return apiClient.fetchSIDERecipes({ pageParam: pageParam, query: searchTerm, filters: filters });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
    });
}
export function useSIDERecipeQuery(_a) {
    var id = _a.id;
    var apiClient = useApiClient().portal;
    return useQuery(['sideRecipe', id], function () { return apiClient.fetchSIDERecipe(id); });
}
export function useSIDERecipeTriggerQuery(_a) {
    var id = _a.id;
    var apiClient = useApiClient().portal;
    return useQuery(['sideRecipeTrigger', id], function () {
        return apiClient.fetchSIDERecipeTriggers(id);
    });
}
export function useSideRecipeMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (data) {
        return apiClient.updateSideRecipe(data);
    }, {
        onSuccess: function (data) {
            queryClient.invalidateQueries(['sideRecipe', Number(data.data.id)]);
        },
    });
}
export function useSIDERecipeActionListQuery(_a) {
    var id = _a.id;
    var apiClient = useApiClient().portal;
    return useInfiniteQuery(['sideRecipeActions', id], function (_a) {
        var pageParam = _a.pageParam;
        return apiClient.fetchSIDERecipeActions({ pageParam: pageParam, id: id });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
    });
}
export var useUniqueNameValidator = function (_a) {
    var delay = _a.delay, errorMessage = _a.errorMessage, sideRecipeId = _a.sideRecipeId;
    var portalApiClient = useApiClient().portal;
    return useMemo(function () {
        return debounceAsync(function (value) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data, recipe, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, portalApiClient.fetchSIDERecipes({
                                query: value,
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        if (data.length === 0) {
                            return [2 /*return*/, undefined];
                        }
                        recipe = data.find(function (item) {
                            return item.id !== sideRecipeId &&
                                item.name.toLowerCase() === value.toLowerCase();
                        });
                        return [2 /*return*/, recipe ? errorMessage : undefined];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, errorMessage];
                    case 3: return [2 /*return*/];
                }
            });
        }); }, delay);
    }, [delay, errorMessage, portalApiClient, sideRecipeId]);
};
