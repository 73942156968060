import { select, Store } from '@ngrx/store';
import * as fromSettings from '../../../settings/reducers';
import * as AuthActions from '../../actions/auth.actions';
import * as fromAuth from '../../reducers';
var ForgotPasswordContainer = /** @class */ (function () {
    function ForgotPasswordContainer(_store, _storeRoot) {
        this._store = _store;
        this._storeRoot = _storeRoot;
        this.authText$ = this._storeRoot.pipe(select(fromSettings.getSectionTranslations('Authentication')));
        this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
        this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
        this.error$ = this._store.pipe(select(fromAuth.getLoginPageError));
        this.generatingCode$ = this._store.pipe(select(fromAuth.isGeneratingCode));
        this.resettingPassword$ = this._store.pipe(select(fromAuth.isResettingPassword));
        this.codeGenerated$ = this._store.pipe(select(fromAuth.hasGeneratedCode));
    }
    ForgotPasswordContainer.prototype.submitNewPassword = function (formData) {
        this._store.dispatch(new AuthActions.ResetPassword(formData));
    };
    ForgotPasswordContainer.prototype.requestNewPasswordCode = function (formData) {
        this._store.dispatch(new AuthActions.GenerateResetCode(formData));
    };
    return ForgotPasswordContainer;
}());
export { ForgotPasswordContainer };
