import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { User } from '../models/user';

const COGNITO_CLINIC_CONTENT_EDITOR_GROUP = 'ClinicContentEditor';

interface Payload {
  aud: string;
  auth_time: number;
  'cognito:groups': string[];
  'cognito:username': string;
  'custom:clinicname': string;
  'custom:clinictoken': string;
  'custom:languagecode': string;
  'custom:publictoken': string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  phone_number: string;
  sub: string;
  token_use: string;
}
export interface ICognitoUserSession {
  idToken: {
    jwtToken: string;
    payload: Payload;
  };
  accessToken: {
    jwtToken: string;
    payload: Payload;
  };
  refreshToken: {
    token: string;
  };
}

export const cognitoContentEditorState = (
  session: ICognitoUserSession,
): boolean => {
  return Boolean(
    session &&
      session.accessToken &&
      session.accessToken.payload['cognito:groups'] &&
      session.accessToken.payload['cognito:groups'].includes(
        COGNITO_CLINIC_CONTENT_EDITOR_GROUP,
      ),
  );
};

export default function convertCognitoUser(user: CognitoUser): {
  user: User;
  signInUserSession: CognitoUserSession;
} {
  const signInUserSession = user.getSignInUserSession();
  const idToken = signInUserSession.getIdToken();
  const jwtToken = idToken.getJwtToken();
  const payload = idToken.payload;

  const newUser = new User(
    payload.given_name,
    payload.family_name,
    payload['cognito:username'],
    payload['custom:publictoken'],
    true,
    false,
    payload['custom:clinicname'],
    0,
    payload['custom:languagecode'] || null,
    jwtToken,
    payload['cognito:groups'] || [],
  );

  return {
    user: newUser,
    signInUserSession,
  };
}
