import styled from '@emotion/styled';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import {
  Button,
  InputLabel,
  MessageEditor,
  PatientInput,
  PatientInputProps,
  Select,
  TextInput,
} from '@react/components';
import { Patient } from '@react/lib/api/types';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useMemo, useRef, useState } from 'react';
import { MESSAGE_MAX_LENGTH, SUBJECT_MAX_LENGTH } from '../constants';
import { useFoldersListQuery } from '../hooks';
import { useCreateConversationMutation } from '../hooks/useCreateConversationMutation';
import { useNewConversationToast } from '../hooks/useToastMessages';

export interface NewMessageModalProps {
  open: boolean;
  onClose: (state: { hasValues: boolean; hasChanged: boolean }) => void;
  dialogWrapper?: boolean;
  refreshPageOnLinkClicked?: boolean;
  patientInputProps?: Partial<PatientInputProps>;
}

export const NewMessageModal: React.FC<NewMessageModalProps> = ({
  open,
  onClose,
  dialogWrapper = true,
  refreshPageOnLinkClicked,
  patientInputProps = {},
}) => {
  const { t } = useTranslations();
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(
    patientInputProps.defaultPatient || null,
  );
  const [selectedFolder, setSelectedFolder] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [messageValue, setMessageValue] = useState('');

  const cacheValues = useRef({
    selectedPatient,
    selectedFolder,
    subjectValue,
    messageValue,
  }).current;

  const foldersResult = useFoldersListQuery();
  const folders = foldersResult.data && foldersResult.data.data;

  const {
    mutateAsync: createConversation,
    isError: isSendingError,
    reset: resetQuery,
  } = useCreateConversationMutation();
  const showNewConversationToast = useNewConversationToast();

  useErrorToast(isSendingError, {
    title: t.MessageToast.ErrorEncountered,
    message: t.MessageToast.ErrorSending(
      selectedPatient
        ? `${selectedPatient.firstname} ${selectedPatient.lastname}`
        : '',
    ),
  });

  function resetState() {
    setSelectedPatient(null);
    setSelectedFolder('');
    setSubjectValue('');
    setMessageValue('');
    resetQuery();
  }

  function handleClose() {
    onClose({ hasValues: hasValues(), hasChanged: hasChanged() });
  }

  function onPatientChange(patient: Patient | null) {
    setSelectedPatient(patient);
  }

  function onMessageChange(value: string) {
    setMessageValue(value);
  }

  function onSelectedFolderChange(
    event: React.ChangeEvent<{ value: unknown }>,
  ) {
    setSelectedFolder(String(event.target.value));
  }

  function onSubjectChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSubjectValue(event.target.value);
  }

  async function onSendClicked() {
    if (selectedFolder === '' || selectedPatient == null) {
      return;
    }

    const newConversationResponse = await createConversation({
      patientid: selectedPatient.id,
      messagetypeid: Number(selectedFolder),
      messagesubject: subjectValue.length > 0 ? subjectValue : null,
      content: messageValue,
    });

    showNewConversationToast({
      title: t.MessageToast.OutboundSentTitle,
      message: t.MessageToast.OutboundSentBody(
        `${selectedPatient.firstname} ${selectedPatient.lastname}`,
      ),
      folderId: Number(selectedFolder),
      conversation: newConversationResponse.data,
      refreshPageOnLinkClicked,
    });

    resetState();
    onClose({ hasValues: false, hasChanged: false });
  }

  const validPatient = selectedPatient != null;
  const validFolder = selectedFolder.length > 0;
  const validSubject = subjectValue.length <= SUBJECT_MAX_LENGTH;
  const validMessage =
    messageValue.length > 0 && messageValue.length <= MESSAGE_MAX_LENGTH;

  function isFormValid() {
    return validPatient && validFolder && validSubject && validMessage;
  }

  function hasValues() {
    return (
      validPatient ||
      validFolder ||
      (validSubject && subjectValue.length > 0) ||
      validMessage
    );
  }

  function hasChanged() {
    return (
      selectedPatient !== cacheValues.selectedPatient ||
      selectedFolder !== cacheValues.selectedFolder ||
      subjectValue !== cacheValues.subjectValue ||
      messageValue !== cacheValues.messageValue
    );
  }

  const folderSelectionOptions = useMemo(
    () =>
      folders
        ? folders.map((folder) => ({
            value: String(folder.id),
            label: folder.name,
          }))
        : [],
    [folders],
  );

  const wrapperProps = {
    open,
    dialogWrapper,
    onClose: handleClose,
  };

  return (
    <Wrapper {...wrapperProps}>
      <StyledContainer>
        <Typography className="newMessageModal-title" variant="subtitle1">
          {t.NewMessage.Title}
        </Typography>
        <IconButton
          className="newMessageModal-close"
          aria-label="Close dialog"
          onClick={handleClose}
          size="small"
          role="button"
        >
          <IconClose color="primary" />
        </IconButton>
        <div className="newMessageModal-input">
          <PatientInput
            id="newMessageModal-patient"
            label={t.NewMessage.Patient}
            onChange={onPatientChange}
            {...patientInputProps}
          />
        </div>
        <div className="newMessageModal-input">
          <Select
            id="newMessageModal-folders"
            label={t.NewMessage.FolderFieldLabel}
            value={selectedFolder}
            onChange={onSelectedFolderChange}
            fullWidth
            placeholder={t.NewMessage.FolderFieldPlaceholder}
            options={folderSelectionOptions}
          />
        </div>
        <div className="newMessageModal-input newMessageModal-withCharCount">
          <TextInput
            id="newMessageModal-subject"
            label={t.NewMessage.SubjectFieldLabel}
            placeholder={t.NewMessage.SubjectFieldPlaceholder}
            value={subjectValue}
            onChange={onSubjectChange}
            maxLength={SUBJECT_MAX_LENGTH}
            fullWidth
            showCharCount
          />
        </div>
        <div className="newMessageModal-input">
          <InputLabel htmlFor="newMessageModal-content">
            {t.NewMessage.ContentFieldLabel}
          </InputLabel>
          <MessageEditor
            inputId="newMessageModal-content"
            patientId={selectedPatient && selectedPatient.id}
            value={messageValue}
            onChange={onMessageChange}
            maxLength={MESSAGE_MAX_LENGTH}
            maxRows={6}
          />
        </div>
        <div className="newMessageModal-sendButton">
          <Button
            onClick={onSendClicked}
            color="primary"
            size="small"
            variant="contained"
            disabled={!isFormValid()}
          >
            {t.NewMessage.Send}
          </Button>
        </div>
      </StyledContainer>
    </Wrapper>
  );
};

const Wrapper: React.FunctionComponent<NewMessageModalProps> = ({
  children,
  dialogWrapper,
  onClose,
  open,
}) => {
  if (!dialogWrapper) {
    return <>{children}</>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      {children}
    </Dialog>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 20px;
  position: relative;

  .newMessageModal-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .newMessageModal-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .newMessageModal-input {
    margin-bottom: 20px;

    &.newMessageModal-withCharCount {
      margin-bottom: 5px;
    }
  }

  .newMessageModal-sendButton {
    display: flex;
    justify-content: flex-end;
  }
`;
