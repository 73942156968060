import styled from '@emotion/styled';
import { IconButton, Menu } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

export const MenuDeviceRowContainer = styled('div')`
  pointer-events: auto;
`;

export const ClickThroughMenu = styled(Menu)`
  pointer-events: none;
`;
export const MoreVertIcon = styled(MoreVert)`
  font-size: 28px;
  color: #3a3a3a;
`;

export const MenuButton = styled(IconButton)`
  background-color: #ffffff;
  margin: 16px;
  &:hover {
    background-color: #ffffff;
  }
  &:disabled {
    background-color: #626262;
  }
`;
