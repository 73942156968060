/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./profile-photo-modal-react-wrapper.component";
import * as i2 from "../../services/s3.service";
import * as i3 from "@ngrx/store";
import * as i4 from "../../services/congito.wrapper.service";
import * as i5 from "@angular/material/dialog";
var styles_ProfilePhotoModalReactWrapperComponent = [];
var RenderType_ProfilePhotoModalReactWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfilePhotoModalReactWrapperComponent, data: {} });
export { RenderType_ProfilePhotoModalReactWrapperComponent as RenderType_ProfilePhotoModalReactWrapperComponent };
export function View_ProfilePhotoModalReactWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["profilePhotoModalReactWrapperComponent", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_ProfilePhotoModalReactWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-profile-photo-modal", [], null, null, null, View_ProfilePhotoModalReactWrapperComponent_0, RenderType_ProfilePhotoModalReactWrapperComponent)), i0.ɵdid(1, 4440064, null, 0, i1.ProfilePhotoModalReactWrapperComponent, [i2.FileUpload, i3.Store, i4.CognitoWrapperService, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfilePhotoModalReactWrapperComponentNgFactory = i0.ɵccf("portal-profile-photo-modal", i1.ProfilePhotoModalReactWrapperComponent, View_ProfilePhotoModalReactWrapperComponent_Host_0, {}, {}, []);
export { ProfilePhotoModalReactWrapperComponentNgFactory as ProfilePhotoModalReactWrapperComponentNgFactory };
