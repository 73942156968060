<mat-toolbar
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="10px"
>
  <div
    class="view-all"
    (click)="switchView.emit()"
    fxLayoutAlign="start center"
  >
    <mat-icon *ngIf="!patientView" [matTooltip]="text.SeePatientView">
      assignment_ind
    </mat-icon>
    <mat-icon *ngIf="patientView" [matTooltip]="text.SeeClinicView">
      assignment
    </mat-icon>
  </div>
  <div>
    {{ text.PatientContent + patientName }}
  </div>
  <div
    class="view-info"
    [matTooltip]="text.ViewInfo"
    (click)="viewInfo.emit()"
    fxLayoutAlign="end center"
  >
    <mat-icon>info</mat-icon>
  </div>
</mat-toolbar>
