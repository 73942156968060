import { Paper } from '@material-ui/core';
import { useTranslations } from '@react/lib/i18n';
import {
  ClinicUser,
  Conversation,
  OnClickClinicUser,
  OnClickClinicUserValue,
  UnAssignableClinicUser,
} from '@react/types';
import React, { useEffect, useState } from 'react';
import {
  LoadingWrapper,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledSearchBar,
  StyledWrapper,
} from './ClinicUserFilter.styled';
import { Loading } from './Loading';

interface Props {
  id: string;
  clinicUsers: ClinicUser[];
  defaultOption: ClinicUser;
  messageid: Conversation['messageid'];
  onClick: OnClickClinicUser;
  onSearchUpdate?: (value: string) => void;
  searchState: string;
  unassignOption?: UnAssignableClinicUser;
  loading: boolean;
}

interface UserOptionProps {
  id: string;
  messageid: Conversation['messageid'];
  onClickUser: OnClickClinicUser;
  option: ClinicUser | UnAssignableClinicUser;
  unassign?: boolean;
}

const unassignUser = (unassign: boolean, id: number) => (unassign ? null : id);

export const UserOption = ({
  id: idKey,
  messageid,
  option,
  onClickUser,
  unassign = false,
}: UserOptionProps) => {
  const { id, userfullname } = option;

  if (!id) {
    return null;
  }

  return (
    <StyledListItem
      button={true}
      id={`${idKey}-${id}`}
      dense
      onClick={() =>
        onClickUser({
          userId: unassignUser(unassign, id),
          messageId: messageid,
        })
      }
      style={{ paddingTop: 8, paddingBottom: 8 }}
    >
      <StyledListItemText
        id={`${id}`}
        primary={userfullname}
        weight={unassign ? 'bold' : 'normal'}
      />
    </StyledListItem>
  );
};

export const ClinicUserFilter: React.FC<Props> = ({
  id,
  clinicUsers,
  defaultOption,
  onClick,
  onSearchUpdate,
  searchState,
  unassignOption,
  messageid,
  loading,
}) => {
  const { t } = useTranslations();
  const [showDefaults, setShowDefaults] = useState(false);

  function onSearchChange(value: string) {
    if (onSearchUpdate) {
      onSearchUpdate(value);
    }
  }

  function onClickUser(value: OnClickClinicUserValue) {
    if (onClick) {
      onClick(value);
    }
  }

  useEffect(() => {
    if (searchState === '') {
      setShowDefaults(true);
    } else {
      setShowDefaults(false);
    }
  }, [searchState]);

  if (loading) {
    return (
      <StyledWrapper id={id}>
        <LoadingWrapper>
          <Loading data-testid="assign-user-loading" />
        </LoadingWrapper>
      </StyledWrapper>
    );
  }

  return (
    <>
      <StyledWrapper id={id}>
        <StyledSearchBar
          value={searchState}
          onChange={(value: string) => onSearchChange(value)}
          placeholder={t.Messages.SearchUsers}
          shadow="true"
        />
        <Paper style={{ boxShadow: 'none' }}>
          <StyledList dense>
            {showDefaults && unassignOption && (
              <UserOption
                id={'unassign'}
                key={`unassign-${unassignOption.id}`}
                messageid={messageid}
                option={unassignOption}
                onClickUser={onClickUser}
                unassign={true}
              />
            )}
            {showDefaults && (
              <UserOption
                id={'me'}
                key={`me-${defaultOption.id}`}
                messageid={messageid}
                option={defaultOption}
                onClickUser={onClickUser}
              />
            )}
            {!showDefaults &&
              clinicUsers.map((user) => {
                return (
                  <UserOption
                    id={'user'}
                    key={`user-${user.id}`}
                    messageid={messageid}
                    option={user}
                    onClickUser={onClickUser}
                  />
                );
              })}
          </StyledList>
        </Paper>
      </StyledWrapper>
    </>
  );
};
