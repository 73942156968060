import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ContentPackActionTypes, CreateContentPack, } from './../../actions/packs.actions';
import { validateContentPackName } from '../../services/async-validators/validate-pack-name';
import { PacksService } from '../../services/packs.service';
var CreatePackComponent = /** @class */ (function () {
    function CreatePackComponent(_store, _updates, dialogRef, dialog, _packsService, data) {
        this._store = _store;
        this._updates = _updates;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this._packsService = _packsService;
        this.data = data;
        this.checkingPackName$ = new BehaviorSubject(false);
        this.savingPack$ = new BehaviorSubject(false);
        this.savedPack$ = new BehaviorSubject(false);
        this.errorEncountered$ = new BehaviorSubject(false);
        this.status = {
            INVALID: 'INVALID',
            PENDING: 'PENDING',
        };
        this._subs = new Subscription();
    }
    CreatePackComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.packName = new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50),
        ]), [validateContentPackName(this._packsService, this.checkingPackName$, '')]);
        // watch for errors on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentPackActionTypes.CreateContentPackError))
            .subscribe(function () {
            _this.savingPack$.next(false);
            _this.errorEncountered$.next(true);
        }));
        // watch for success on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentPackActionTypes.CreateContentPackSuccess))
            .subscribe(function () {
            _this.savingPack$.next(false);
            _this.savedPack$.next(true);
            _this.exitDialog(true, 1000);
        }));
    };
    CreatePackComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    CreatePackComponent.prototype.clearForm = function () {
        this.packName.setValue('');
    };
    CreatePackComponent.prototype.createNewPack = function () {
        this.errorEncountered$.next(false);
        this.savingPack$.next(true);
        var name = this.packName.value;
        this._store.dispatch(new CreateContentPack({
            name: name,
        }));
    };
    CreatePackComponent.prototype.cancelNameChange = function () {
        this.exitDialog(false);
    };
    CreatePackComponent.prototype.exitDialog = function (status, time) {
        var _this = this;
        if (status === void 0) { status = false; }
        if (time === void 0) { time = 0; }
        setTimeout(function () {
            _this.dialogRef.close(status);
        }, time);
    };
    return CreatePackComponent;
}());
export { CreatePackComponent };
