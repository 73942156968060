import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  DisplayToastAction,
  ToastActions,
  ToastDisplayedAction,
  ToastRemovedAction,
} from '../actions/toast.actions';

@Injectable()
export class ToastEffects {
  @Effect() displayToast: Observable<Action> = this._actions$.pipe(
    ofType<DisplayToastAction>(ToastActions.DisplayToast),
    switchMap((action) => {
      return new Promise((resolve) => {
        const requestedToast = action.payload.toastRef;
        const requestedToastActive =
          requestedToast &&
          requestedToast.toastRef.componentInstance.state.value === 'active';
        let newToast;

        if (!requestedToast || !requestedToastActive) {
          newToast = this._toastrService.show(
            action.payload.message,
            action.payload.title,
            {
              toastClass: `toast custom-toast ${action.payload.type}`,
              timeOut: action.payload.timeout,
            },
          );
        } else {
          requestedToast.toastRef.componentInstance.alterToast({
            class: {
              classesToRemove: ['info', 'success', 'error'],
              classToAdd: action.payload.type,
            },
            title: action.payload.title,
            message: action.payload.message,
          });
          if (action.payload.timeout > 0) {
            setTimeout(() => {
              requestedToast.toastRef.componentInstance.remove();
            }, action.payload.timeout);
          }
        }

        // In some cases toastrService.show can return null
        const toast = newToast || requestedToast;
        if (toast) {
          toast.onHidden.subscribe(() => {
            resolve(new ToastRemovedAction());
          });
          resolve(new ToastDisplayedAction({ toastRef: toast }));
        }
      });
    }),
  );

  constructor(
    private _actions$: Actions,
    private _toastrService: ToastrService,
  ) {}
}
