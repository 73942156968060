import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import {
  IActionMedication,
  IMedication,
} from '../../services/medications.service.interfaces';
import {
  DataState,
  MedicationStatus,
  MedicationType,
} from './patient-list.component.enums';
import {
  IPatientList,
  IRowSelection,
} from './patient-list.component.interfaces';

@Component({
  selector: 'portal-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit {
  @Input()
  public set medications(
    medications: Array<IMedication> | Array<IActionMedication>,
  ) {
    this.patientList = this._groupMeds(medications);
  }

  public get state(): DataState {
    if (
      this.patientList !== undefined &&
      Object.keys(this.patientList).length > 0
    ) {
      return DataState.Results;
    }

    if (this.searchControl.value !== '' && this.searchControl.value !== null) {
      return DataState.NoSearchResults;
    }

    return DataState.NoResults;
  }

  public get dataStatus(): typeof DataState {
    return DataState;
  }

  @Input()
  public emptyMessage: string;

  @Input()
  public loading = true;

  @Output()
  public medicationSelected: EventEmitter<IRowSelection> = new EventEmitter<IRowSelection>();

  @Input()
  public medicationType: MedicationType;

  @Input()
  public noSearchResultsMessage: string;

  public patientList: IPatientList = {};

  public searchControl = new FormControl();

  @Input()
  public text: any;

  @Input()
  public title: string;

  @Input()
  public warning = false;

  @Output()
  public searchTermChanged: EventEmitter<string> = new EventEmitter<string>();

  private _searchDebounce: Subject<string> = new Subject<string>();

  public constructor(
    private readonly _localisationService: LocalisationService,
  ) {}

  public ngOnInit(): void {
    this._searchDebounce
      .pipe(debounceTime(1000))
      .subscribe((searchTerm) => this.searchTermChanged.emit(searchTerm));

    this.searchControl.valueChanges.subscribe((searchTerm) =>
      this._searchDebounce.next(searchTerm),
    );
  }

  public onRowSelected(selection: IRowSelection): void {
    this.medicationSelected.emit(selection);
  }

  private _groupMeds(
    meds: Array<IMedication> | Array<IActionMedication>,
  ): IPatientList {
    const patients: IPatientList = {};

    if (meds == null) {
      return;
    }

    meds.forEach((med: IMedication | IActionMedication): void => {
      const name: string = `${med.firstName} ${med.lastName}`.toUpperCase();
      const dob: Date = new Date(med.dateOfBirth);

      if (!patients[med.patientId]) {
        patients[med.patientId] = {
          id: med.patientIdentifier,
          name,
          meds: [],
          dob,
        };
      }

      patients[med.patientId].meds.push({
        id: med.patientId,
        name,
        type: med.type,
        drug: med.drugName,
        dosage:
          this.medicationType === MedicationType.Duplicate
            ? `${med.drugDosage}${med.unit}`
            : med.drugDosage,
        status: this._getStatus(med),
        statusId: med.statusId,
        dob,
        dueDate: new Date(med.dueDate),
      });
    });

    return patients;
  }

  private _getStatus(item: IMedication | IActionMedication): string {
    switch (item.statusId) {
      case MedicationStatus.Taken:
        let adherenceDate = '';
        if (this._instanceOfIActionMedication(item)) {
          if (item.adherenceDate !== null) {
            adherenceDate = this._localisationService.formatTime(
              item.adherenceDate,
              'short',
            );
          }
        }
        return this.text.TakenAt(
          adherenceDate,
          this._localisationService.formatTime(item.scheduledTime, 'short'),
        );
      case MedicationStatus.DueToday:
        return this.text.DueToday;
      case MedicationStatus.DueAt:
        return this.text.DueAt(
          this._localisationService.formatTime(item.scheduledTime, 'short'),
        );
      case MedicationStatus.WindowClosing:
        const now = new Date(item.now);
        const windowCloseTime = this._getWindowCloseTime(
          item.scheduledTime,
          now,
        );
        const minutesRemaining = differenceInMinutes(
          windowCloseTime,
          now,
        ).toString();
        return this.text.WindowClosing(minutesRemaining);
      case MedicationStatus.Overdue:
        return this.text.Overdue(
          this._localisationService.formatTime(item.scheduledTime, 'short'),
        );
      case MedicationStatus.PotentialDuplicate:
        return this.text.PotentialDuplicate(
          this._localisationService.formatDate(item.dueDate, 'short'),
          this._localisationService.formatTime(item.scheduledTime, 'short'),
        );
    }
  }

  private _getWindowCloseTime(scheduledTime: string, now: Date): Date {
    const windowCloseTime = addMinutes(new Date(scheduledTime), 60);

    // this is needed to stop windows from the past from giving negative numbers
    windowCloseTime.setFullYear(now.getFullYear());
    windowCloseTime.setMonth(now.getMonth());
    windowCloseTime.setDate(now.getDate());

    return windowCloseTime;
  }

  private _instanceOfIActionMedication(
    object: any,
  ): object is IActionMedication {
    return 'adherenceDate' in object;
  }
}
