import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import { Subject } from 'rxjs';

import { MyEvent } from '../../containers/patients-detail/patients-detail.component';

type CalendarPeriod = 'day' | 'week' | 'month';

@Component({
  selector: 'portal-patient-calendar',
  templateUrl: './patient-calendar.component.html',
  styleUrls: ['./patient-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCalendarComponent implements OnChanges {
  @Input() text: any;
  @Input() events: MyEvent[] = [];
  @Input() videoCallsEnabled: boolean;

  public view: CalendarPeriod = 'month';

  @Input()
  public viewDate: Date = new Date();

  // = 'August 2017';
  public dateDisplayed: string;

  public clickedDate: Date;

  public yesterday: Date = new Date();

  public exclude: number[] = [];

  public activeDayIsOpen = true;

  public refresh: Subject<any> = new Subject();

  public hasProvisionalAppointments = false;

  constructor() {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && changes.text.currentValue) {
      this.displayDate();
    }

    if (changes.events && changes.events.currentValue) {
      this.hasProvisionalAppointments = changes.events.currentValue.some(
        (event) => event.isProvisional,
      );
    }
  }

  // creates day translation for column header
  getDate(date: Date): string {
    return this.text.Days()[date.getDay()];
  }

  decrement() {
    this.viewDate.setMonth(this.viewDate.getMonth() - 1);
    this.displayDate();
  }

  today() {
    this.viewDate = new Date();
    this.displayDate();
  }

  increment() {
    this.viewDate.setMonth(this.viewDate.getMonth() + 1);
    this.displayDate();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (events && events.length === 0) {
      this.activeDayIsOpen = false;
    } else if (this.viewDate.valueOf() === date.valueOf()) {
      this.activeDayIsOpen = !this.activeDayIsOpen;
    } else {
      this.activeDayIsOpen = true;
    }

    this.viewDate = date;
    this.displayDate();
  }

  displayDate() {
    const monthNames = this.text ? [...this.text.Months()] : [];

    this.dateDisplayed =
      monthNames[this.viewDate.getMonth()] + ' ' + this.viewDate.getFullYear();

    this.refresh.next();
  }

  addBadgeTotal(day: CalendarMonthViewDay): void {
    day.badgeTotal = day.events.filter(
      (event) => event['incrementsBadgeTotal'],
    ).length;
  }
}
