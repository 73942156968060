import { EventEmitter, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
var ContentEditControlsComponent = /** @class */ (function () {
    function ContentEditControlsComponent(_controlContainer) {
        this._controlContainer = _controlContainer;
        this.exitForm = new EventEmitter();
        this.publishContent = new EventEmitter();
    }
    ContentEditControlsComponent.prototype.ngOnInit = function () {
        this.sendToAllForm = this._controlContainer.control;
    };
    return ContentEditControlsComponent;
}());
export { ContentEditControlsComponent };
