import { ElementRef, EventEmitter, } from '@angular/core';
var NumbersOnlyDirective = /** @class */ (function () {
    function NumbersOnlyDirective(elRef) {
        this.elRef = elRef;
        this.filteringCompleted = new EventEmitter();
    }
    NumbersOnlyDirective.prototype.onInput = function () {
        var element = this.elRef.nativeElement;
        var value = element.value;
        var matches = [];
        if (this.allowDecimals) {
            matches = value.match(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
        }
        else {
            matches = value.match(/^\d*/g);
        }
        if (matches) {
            element.value = matches.join('');
        }
        else {
            element.value = '';
        }
        this.filteringCompleted.emit(element.value);
    };
    return NumbersOnlyDirective;
}());
export { NumbersOnlyDirective };
