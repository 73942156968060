import { Inject, Pipe, PipeTransform } from '@angular/core';
import { LocalisationService } from './localisation.service';

@Pipe({
  name: 'shortDate',
})
export class ShortDate implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getShortDate(value);
  }
}

@Pipe({
  name: 'shortDateWithTime',
})
export class ShortDateWithTime implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getShortDateWithTime(value);
  }
}

@Pipe({
  name: 'shortNumericDate',
})
export class ShortNumericDate implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getShortNumericDate(value);
  }
}

@Pipe({
  name: 'longDate',
})
export class LongDate implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getLongDate(value);
  }
}

@Pipe({
  name: 'fullDate',
})
export class FullDate implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getFullDate(value);
  }
}

@Pipe({
  name: 'longDateWithTime',
})
export class LongDateWithTime implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getLongDateWithTime(value);
  }
}

@Pipe({
  name: 'midDateWithTime',
})
export class MidDateWithTime implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getMidDateWithTime(value);
  }
}

@Pipe({
  name: 'onlyTime',
})
export class JustTime implements PipeTransform {
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(value: string | Date): any {
    return this._localisationService.getOnlyTime(value);
  }
}
