import * as tslib_1 from "tslib";
import { Button, Dialog } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { useExportAdminList } from '@react/pages/admin/hooks';
import { DownloadLists } from '@react/pages/admin/types';
import React, { useState } from 'react';
var ExportRecords = function () {
    var t = useTranslations().t;
    var localeDateTime = useLocaleDateTime();
    var _a = useState(false), isExportDialogOpen = _a[0], setExportDialogOpen = _a[1];
    var _b = useState(false), isExportError = _b[0], setExportError = _b[1];
    var _c = useState(false), isExportSuccess = _c[0], setExportSuccess = _c[1];
    var onDownloadSuccess = function () {
        setExportDialogOpen(false);
        setExportSuccess(true);
    };
    var onDownloadFailure = function () {
        setExportError(true);
        setExportDialogOpen(false);
    };
    var _d = useExportAdminList({
        type: DownloadLists.Users,
        onDownloadSuccess: onDownloadSuccess,
        onDownloadFailure: onDownloadFailure,
    }), download = _d.download, fileUrl = _d.fileUrl, ref = _d.ref, fileName = _d.fileName;
    function saveToCSV() {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileNameSuffix;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileNameSuffix = t.Admin.Users.UsersExport + " " + localeDateTime.format(new Date(), 'yyyy/MM/dd');
                        return [4 /*yield*/, download(fileNameSuffix, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    useErrorToast(isExportError, {
        title: t.Admin.Users.ExportErrorTitle,
        message: t.Admin.Users.ExportErrorDescription,
    }, 0);
    useSuccessToast(isExportSuccess, {
        title: t.Admin.Users.ExportSuccessTitle,
        message: t.Admin.Users.ExportSuccessDescription,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { "data-testid": "btn_export", color: "primary", onClick: function () { return setExportDialogOpen(true); }, variant: "contained" }, t.Admin.Users.ExportCSV),
        React.createElement("a", { href: fileUrl, download: fileName, hidden: true, ref: ref }),
        React.createElement(Dialog, { acceptButtonText: t.Admin.Users.ExportDialogDownload, cancelButtonText: t.Admin.Users.ExportDialogCancel, contentText: t.Admin.Users.ExportDialogDescription, onAccept: saveToCSV, onCancel: function () { return setExportDialogOpen(false); }, isOpen: isExportDialogOpen, titleText: t.Admin.Users.ExportDialogTitle })));
};
var ɵ0 = ExportRecords;
export default ExportRecords;
export { ɵ0 };
