import { useApiClient } from '@react/lib/api';
import { ClinicUser } from '@react/types';
import flatMap from 'lodash/flatMap';
import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatClinicUser } from '../lib';

interface Data {
  data: ClinicUser[];
}

export function useFetchAllClinicUsers() {
  const apiClient = useApiClient().portal;

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(
      [queryCacheKey.CLINIC_USERS],
      async ({ pageParam: pageNumber }: { pageParam?: number }) => {
        const response = await apiClient.fetchClinicUsers({ pageNumber });
        return { ...response, data: response.data.map(formatClinicUser) };
      },
      {
        getNextPageParam(lastPage, pages) {
          if (lastPage.data.length === 0) {
            return undefined;
          }
          return pages.length + 1;
        },
        keepPreviousData: true,
      },
    );

  useEffect(() => {
    if (!isLoading && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isLoading, isFetchingNextPage, hasNextPage]);

  const users = useMemo(
    () => data && flatMap(data.pages, (page) => page.data),
    [data],
  );

  const loading = hasNextPage || isLoading || isFetchingNextPage;

  return { users, isLoading: loading };
}
