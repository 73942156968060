import {
  BasePoint,
  BaseRange,
  Descendant,
  Editor,
  Text,
  Transforms,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { isParagraphElement, ParagraphElement } from './TemplateEditor.types';

export const serialize = (value: Descendant[]) => {
  if (Text.isText(value)) {
    return value.text;
  }

  if (isParagraphElement(value)) {
    return (
      value.children
        // @ts-ignore
        .map((c) => serialize(c))
        .join('')
        .concat('\n')
    );
  }

  return (
    value
      // @ts-ignore
      .map((n) => serialize(n))
      .join('')
      .trim()
  );
};

export const deserialize = (value: string): Descendant[] =>
  value.split('\n').map((line) => {
    const descendant: ParagraphElement = {
      children: [],
      type: 'paragraph',
    };

    descendant.children.push({ text: line });
    return descendant;
  });

export const insertVariable = (editor: ReactEditor, variable: string) => {
  Transforms.insertText(editor, `{{{${variable}}}}`);
};

export const getLastThreeCharactersRange = (
  editor: Editor,
  start: BasePoint,
): BaseRange | undefined => {
  const before = Editor.before(editor, start, {
    distance: 3,
    unit: 'character',
  });

  return before && Editor.range(editor, before, start);
};

export const getLastThreeCharacters = (
  editor: Editor,
  start: BasePoint,
): string | undefined => {
  const lastThreeRange = getLastThreeCharactersRange(editor, start);

  return lastThreeRange && Editor.string(editor, lastThreeRange);
};
