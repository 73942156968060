<mat-card
  fxFlexFill
  class="nocontent-container"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
    <img
      class="large-icon"
      src="assets/imgs/sharing-content.svg"
      alt="hand offering different forms of content"
    />
    <h3 class="mat-card-option-font">{{ (sectionText$ | async)?.Appear }}</h3>
    <h3 class="mat-card-option-font">{{ (sectionText$ | async)?.PressAdd }}</h3>
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="goToAddContent()"
    >
      {{ (sectionText$ | async)?.AddContent }}
    </button>
  </div>
</mat-card>
