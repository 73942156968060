import {
  Component,
  ElementRef,
  ErrorHandler,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { format, parseISO } from 'date-fns';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  Subscription,
  throwError
} from 'rxjs';
import {
  catchError,
  debounceTime,
  mergeMap,
  scan,
  shareReplay,
  tap
} from 'rxjs/operators';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { InvitePartnerComponent } from 'src/app/core/components/invite-partner/invite-partner.component';
import { NewVideoCallComponent } from 'src/app/core/containers/new-video-call/new-video-call.component';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { SimplePatient } from 'src/app/models/SimplePatient';
import * as fromAuth from '../../../auth/reducers';
import { VideoCallModalReactWrapperComponent } from '../../../core/containers/video-call-modal/video-call-modal-react-wrapper.component';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as VideoCallActions from '../../../video-calls/actions/video-call.actions';
import {
  InviteInCallSuccessAction,
  JoinVideoCallAction,
  ResetEffectsStateAction,
  SetCurrentTabAction,
  UnInviteInCallSuccessAction,
  UpdateListAction
} from '../../actions/video-call.actions';
import { TabsState } from '../../models/TabsState';
import { UpdateVideoCall } from '../../models/UpdateVideoCall';
import * as fromVideoCalls from '../../reducers';
import {
  ApiVideoCall,
  APIVideoCallResource,
  JoinVideoCallResponse,
  VideoCallEffectsCompletedResponse,
  VideoCallEffectsResposeType
} from '../../responses';
import { VideoCallsService } from '../../services/video-calls.service';
import { VideoCallModalContainer } from '../video-call-modal/video-call-modal.container';

export interface ParamsTrigger {
  pageNumber: number;
  q?: string;
  resources?: string;
}

@Component({
  selector: 'portal-video-calls-page',
  templateUrl: 'video-calls-page.container.html',
  styleUrls: ['./video-calls-page.container.scss']
})
export class VideoCallsPageComponent implements OnInit, OnDestroy {
  public videoCallsListText$: Observable<any>;
  public videoCallsInvitePartner$: Observable<any>;
  public loading$ = new Subject<boolean>();
  public listIsFiltered$ = new BehaviorSubject<boolean>(false);
  public filter$: Observable<string>;
  public selectedTab$: Observable<TabsState>;
  public partnerInviteEnabled$: Observable<any>;
  public videoCallsListText: any;
  public videoCallsInvitePartner: Record<string, string>;
  public filterForm: FormGroup;
  public noCallsText: string;
  public videoCallsList$: Observable<ApiVideoCall[]>;
  public videoCallResources$: Observable<APIVideoCallResource[]>;
  public partnerInviteEnabled: boolean;
  public currentStateIsPast: boolean;

  private _videoCallDialogRef: MatDialogRef<VideoCallModalReactWrapperComponent>;
  private _subs = new Subscription();
  private _effectsCompleted$: Observable<VideoCallEffectsCompletedResponse>;
  private _toastText$: Observable<any>;
  private _toastRef$: Observable<any>;

  private _past: boolean;
  private _hasMoreItems = true;
  private _toastText: any;
  private _toastRef: any;
  private _pageNumber = 0;
  private _resources: string;
  private _searchTrigger$ = new Subject<ParamsTrigger>();

  constructor(
    private _store: Store<fromVideoCalls.AppState>,
    private _fb: FormBuilder,
    private _error: ErrorHandler,
    private _elementRef: ElementRef,
    private readonly _videoCallsService: VideoCallsService,
    public _localisationService: LocalisationService,
    public dialog: MatDialog
  ) {
    this._effectsCompleted$ = this._store.pipe(
      select(fromVideoCalls.getEffectsCompletedResponse)
    );
    this._toastText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallsToast'))
    );
    this._toastRef$ = this._store.pipe(select(fromRoot.getToastRef));
    this.partnerInviteEnabled$ = this._store.pipe(
      select(fromAuth.getVideoCallsPartnerInvite)
    );
  }

  ngOnInit() {
    this.videoCallsListText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallsList'))
    );

    this.videoCallsInvitePartner$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('VideoCallsInvitePartner'))
    );

    this.videoCallsList$ = this._searchTrigger$.pipe(
      mergeMap((searchTrigger) => {
        this.loading$.next(true);
        return this._getVideoCalls$(
          searchTrigger.pageNumber,
          searchTrigger.q,
          searchTrigger.resources
        );
      }),
      tap((next) => {
        this.loading$.next(false);
      }),
      scan((accumulator, value) => {
        this._hasMoreItems = value.length > 0;
        return this._pageNumber > 1 ? [...accumulator, ...value] : value;
      }),
      catchError((err) => {
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this._toastText.ErrorEncountered,
            title: this._toastText.Error,
            timeout: 0
          })
        );
        return throwError(err);
      }),
      shareReplay(1)
    );

    this.videoCallResources$ = this._videoCallsService.getVideoCallResources();
    this.selectedTab$ = this._store.pipe(
      select(fromVideoCalls.getCurrentTabSelector)
    );

    this._subs.add(
      this.videoCallsListText$.subscribe((t) => {
        this.videoCallsListText = t;
      })
    );

    this._subs.add(
      this.videoCallsInvitePartner$.subscribe((t) => {
        this.videoCallsInvitePartner = t;
      })
    );

    this._subs.add(
      this._toastText$.subscribe((val) => {
        this._toastText = val;
      })
    );

    this._subs.add(
      this._effectsCompleted$.subscribe((p) => {
        this.onEffectsCompleted(p);
      })
    );

    this._subs.add(
      this._toastRef$.subscribe((tr) => {
        this._toastRef = tr;
      })
    );

    this._subs.add(
      this.selectedTab$.subscribe((val) => {
        this._past = val === TabsState.Previous;
        this.currentStateIsPast = val === TabsState.Previous;
        this.noCallsText =
          val === TabsState.Previous
            ? this.videoCallsListText.NoPreviousCalls
            : this.videoCallsListText.NoUpcomingCalls;
      })
    );

    this._subs.add(
      this.partnerInviteEnabled$.subscribe((pi) => {
        this.partnerInviteEnabled = pi;
      })
    );

    this.filterForm = this._fb.group({
      filter: [{ value: '', disabled: false }]
    });

    this.filterForm
      .get('filter')
      .valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this._resetPage();
        this.triggerNextPage();
      });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public filtersApplied(filters: APIVideoCallResource[]) {
    this._resources = filters.map((item) => item.id.toString()).join(',');
    this._resetPage();
    this.triggerNextPage();
  }

  public openVideoCall(videoCall?: ApiVideoCall): void {
    const data: any = {};
    if (videoCall) {
      // TODO: The SuperHack!!
      const nameSplit = videoCall.patientname.split(' ');
      let lastName = '';
      let firstName = videoCall.patientname;
      if (nameSplit.length > 1) {
        lastName = nameSplit.pop();
        firstName = nameSplit.join(' ');
      }
      // ^^ Might want to fix this

      const patient: SimplePatient = {
        FirstName: firstName,
        LastName: lastName,
        PatientIdentifier: videoCall.patientidentifier,
        Id: videoCall.patientid,
        DateOfBirth: new Date(videoCall.patientdateofbirth)
      };

      data.id = videoCall.id;
      data.host = videoCall.host;
      data.description = videoCall.description;
      data.duration = videoCall.duration;
      data.date = this._localisationService.toZonedDate(
        parseISO(videoCall.scheduleddate)
      );
      data.startTime = format(
        this._localisationService.toZonedDate(
          parseISO(videoCall.scheduleddate)
        ),
        'HH:mm'
      );
      data.patient = patient;
    }

    const dialogSize = this._getDialogSize();
    const dialogRef = this.dialog.open(NewVideoCallComponent, {
      panelClass: 'reduced-padding-dialog',
      width: `${dialogSize.width}vw`,
      height: '500px',
      autoFocus: false,
      closeOnNavigation: true,
      data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((val) => {});
  }

  public triggerNextPage() {
    this._pageNumber = this._pageNumber + 1;
    const formValue = this.filterForm.value;
    this._searchTrigger$.next({
      pageNumber: this._pageNumber,
      q: formValue.filter,
      resources: this._resources
    });

    this.listIsFiltered$.next(formValue.filter ? true : false);
  }

  joinVideoCall(videoCall: ApiVideoCall) {
    this._store.dispatch(
      new DisplayToastAction({
        type: 'info',
        message: this._toastText.PleaseWaitAndAllowAccess,
        title: this._toastText.JoiningVideoCall,
        timeout: 5000
      })
    );
    this._store.dispatch(
      new JoinVideoCallAction({
        videoCall,
        partnerInviteEnabled: this.partnerInviteEnabled,
        videoCallsInvitePartner: this.videoCallsInvitePartner
      })
    );
  }

  connectVideoCall(call: JoinVideoCallResponse) {
    this._videoCallDialogRef = this.dialog.open(
      VideoCallModalReactWrapperComponent,
      {
        panelClass: 'full-screen-dialog',
        maxWidth: '100vw',
        width: `100vw`,
        height: '100vh',
        autoFocus: false,
        closeOnNavigation: true,
        data: {
          close: () => {
            this._videoCallDialogRef.close();
          },
          joinResponse: call,
          partnerInviteEnabled: this.partnerInviteEnabled,
          invitePartnerInCall: this.invitePartnerInCall.bind(this, call)
        },
        disableClose: true
      }
    );
  }

  editVideoCall(videoCall: ApiVideoCall) {
    this.openVideoCall(videoCall);
  }

  getUpdatableVideoCallWithInvite(videoCall: ApiVideoCall, invited = true) {
    const patient: SimplePatient = {
      FirstName: videoCall.firstname,
      LastName: videoCall.lastname,
      PatientIdentifier: videoCall.patientidentifier,
      Id: videoCall.patientid
    };

    const invitedVideoCall: UpdateVideoCall = {
      id: videoCall.id,
      host: videoCall.host,
      description: videoCall.description,
      duration: videoCall.duration,
      patient,
      scheduleddate: this._localisationService
        .toUTCDate(new Date(videoCall.scheduleddate))
        .toISOString(),
      ispartnerinvited: invited
    };

    return invitedVideoCall;
  }

  invitePartner(videoCall: ApiVideoCall) {
    const dialogRef = this.dialog.open(InvitePartnerComponent, {
      maxWidth: '500px',
      data: {
        title: this.videoCallsInvitePartner.InvitePartner,
        message1: this.videoCallsInvitePartner.InviteText1,
        message2: this.videoCallsInvitePartner.InviteText2,
        text: {
          Cancel: this.videoCallsInvitePartner.Cancel,
          Confirm: this.videoCallsInvitePartner.Invite
        }
      }
    });

    dialogRef.afterClosed().subscribe((val?: boolean) => {
      if (val) {
        const invitedVideoCall =
          this.getUpdatableVideoCallWithInvite(videoCall);
        this._store.dispatch(
          new VideoCallActions.InvitePartnerToVideoCallAction(invitedVideoCall)
        );
      }
    });
  }

  invitePartnerInCall(call: JoinVideoCallResponse) {
    const dialogRef = this.dialog.open(InvitePartnerComponent, {
      maxWidth: '500px',
      data: {
        title: this.videoCallsInvitePartner.InvitePartner,
        message1: this.videoCallsInvitePartner.InvitePartnerInCall,
        text: {
          Cancel: this.videoCallsInvitePartner.Cancel,
          Confirm: this.videoCallsInvitePartner.Invite
        }
      }
    });

    dialogRef.afterClosed().subscribe((val?: boolean) => {
      if (val) {
        const invitedVideoCall = this.getUpdatableVideoCallWithInvite(
          call.videoCall
        );
        this._store.dispatch(
          new VideoCallActions.InvitePartnerInCallToVideoCallAction(
            invitedVideoCall
          )
        );
      }
    });
  }

  unInvitePartner(videoCall: ApiVideoCall) {
    const dialogRef = this.dialog.open(InvitePartnerComponent, {
      maxWidth: '500px',
      data: {
        title: this.videoCallsInvitePartner.UnInvitePartner,
        message1: this.videoCallsInvitePartner.UnInviteText1,
        message2: this.videoCallsInvitePartner.UnInviteText2,
        text: {
          Cancel: this.videoCallsInvitePartner.Cancel,
          Confirm: this.videoCallsInvitePartner.UnInvite
        }
      }
    });

    dialogRef.afterClosed().subscribe((val?: boolean) => {
      if (val) {
        const unInvitedVideoCall = this.getUpdatableVideoCallWithInvite(
          videoCall,
          false
        );
        this._store.dispatch(
          new VideoCallActions.UnInvitePartnerToVideoCallAction(
            unInvitedVideoCall
          )
        );
      }
    });
  }

  unInvitePartnerInCall(call: JoinVideoCallResponse) {
    const dialogRef = this.dialog.open(InvitePartnerComponent, {
      maxWidth: '500px',
      data: {
        title: this.videoCallsInvitePartner.UnInvitePartner,
        message1: this.videoCallsInvitePartner.UnInvitePartnerInCall,
        text: {
          Cancel: this.videoCallsInvitePartner.Cancel,
          Confirm: this.videoCallsInvitePartner.UnInvite
        }
      }
    });

    dialogRef.afterClosed().subscribe((val?: boolean) => {
      if (val) {
        const unInvitedVideoCall = this.getUpdatableVideoCallWithInvite(
          call.videoCall,
          false
        );
        this._store.dispatch(
          new VideoCallActions.UnInvitePartnerInCallToVideoCallAction(
            unInvitedVideoCall
          )
        );
      }
    });
  }

  clearFilter() {
    this.filterForm.patchValue({ filter: '' });
  }

  tabChanged(index: number) {
    this._store.dispatch(
      new SetCurrentTabAction(
        index === 0 ? TabsState.Upcoming : TabsState.Previous
      )
    );
    this._past = index === 1;
    this._resetPage();
    this.triggerNextPage();
  }

  reload() {
    this._resetPage();
    this.triggerNextPage();
  }

  onEffectsCompleted(payload: VideoCallEffectsCompletedResponse) {
    if (!payload) {
      return;
    }

    switch (payload.type) {
      case VideoCallEffectsResposeType.UpdateSuccess:
      case VideoCallEffectsResposeType.DeleteSuccess:
        this._resetPage();
        this.triggerNextPage();

        break;
      case VideoCallEffectsResposeType.JoinSuccess:
        this._store.dispatch(
          new UpdateListAction({
            videoCall: null,
            isNew: false,
            joinedVideoCallId: payload.data.videoCall.id,
            inCallInviteFlag: payload.data.videoCall.ispartnerinvited
          })
        );
        this.connectVideoCall(payload.data);
        break;
      case VideoCallEffectsResposeType.JoinError:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this._toastText.ErrorEncountered,
            title: this._toastText.JoiningVideoCall,
            timeout: 5000
          })
        );
        break;
      case VideoCallEffectsResposeType.InviteSuccess:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'success',
            message: this.videoCallsInvitePartner.PartnerInvitedText,
            title: this.videoCallsInvitePartner.PartnerInvited,
            timeout: 5000
          })
        );
        this._resetPage();
        this.triggerNextPage();
        break;
      case VideoCallEffectsResposeType.NoPartnerFoundError:
        const noPartnerDialogRef = this.dialog.open(InvitePartnerComponent, {
          maxWidth: '500px',
          data: {
            title: this.videoCallsInvitePartner.PartnerNotFound,
            message1: this.videoCallsInvitePartner.PartnerNotFoundText1,
            message2: this.videoCallsInvitePartner.PartnerNotFoundText2,
            message3: this.videoCallsInvitePartner.PartnerNotFoundText3,
            message4: this.videoCallsInvitePartner.PartnerNotFoundText4,
            text: {
              Confirm: this.videoCallsInvitePartner.OK
            }
          }
        });
        noPartnerDialogRef.afterClosed().subscribe((val) => {});
        break;
      case VideoCallEffectsResposeType.InviteError:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this.videoCallsInvitePartner.CouldNotInviteErrorMessage,
            title: this.videoCallsInvitePartner.CouldNotInvite,
            timeout: 5000
          })
        );
        break;
      case VideoCallEffectsResposeType.UnInviteSuccess:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'success',
            message: this.videoCallsInvitePartner.PartnerUnInvitedText,
            title: this.videoCallsInvitePartner.PartnerUninvited,
            timeout: 5000
          })
        );
        this._resetPage();
        this.triggerNextPage();
        break;
      case VideoCallEffectsResposeType.UnInviteError:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this.videoCallsInvitePartner.CouldNotUnInviteErrorMessage,
            title: this.videoCallsInvitePartner.CouldNotUnInvite,
            timeout: 5000
          })
        );
        break;
      case VideoCallEffectsResposeType.UnInviteActiveCallError:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this.videoCallsInvitePartner.CallInProgressUnInviteMessage,
            title: this.videoCallsInvitePartner.CouldNotUnInvite,
            timeout: 5000
          })
        );
        break;
      case VideoCallEffectsResposeType.InviteInCallSuccess:
        this._store.dispatch(
          new InviteInCallSuccessAction({
            inCallInviteFlag: true
          })
        );
        const inviteInCallDialogRef = this.dialog.open(InvitePartnerComponent, {
          maxWidth: '500px',
          data: {
            title: this.videoCallsInvitePartner.PartnerInvited,
            message1: this.videoCallsInvitePartner.InvitePartnerInCallText,
            text: {
              Confirm: this.videoCallsInvitePartner.OK
            }
          }
        });
        inviteInCallDialogRef.afterClosed().subscribe((val) => {});
        break;
      case VideoCallEffectsResposeType.InviteInCallError:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this.videoCallsInvitePartner.CouldNotInviteErrorMessage,
            title: this.videoCallsInvitePartner.CouldNotInvite,
            timeout: 5000
          })
        );
        break;
      case VideoCallEffectsResposeType.UnInviteInCallSuccess:
        this._store.dispatch(
          new UnInviteInCallSuccessAction({
            inCallInviteFlag: false
          })
        );
        const unInviteInCallDialogRef = this.dialog.open(
          InvitePartnerComponent,
          {
            maxWidth: '500px',
            data: {
              title: this.videoCallsInvitePartner.PartnerUninvited,
              message1: this.videoCallsInvitePartner.UnInvitePartnerInCallText1,
              message2: this.videoCallsInvitePartner.UnInvitePartnerInCallText2,
              text: {
                Confirm: this.videoCallsInvitePartner.OK
              }
            }
          }
        );
        unInviteInCallDialogRef.afterClosed().subscribe((val) => {});
        break;
      case VideoCallEffectsResposeType.UnInviteInCallError:
        this._store.dispatch(
          new DisplayToastAction({
            toastRef: this._toastRef,
            type: 'error',
            message: this.videoCallsInvitePartner.CouldNotUnInviteErrorMessage,
            title: this.videoCallsInvitePartner.CouldNotUnInvite,
            timeout: 5000
          })
        );
        break;
    }
    this._store.dispatch(new ResetEffectsStateAction());
  }

  private _getDialogSize() {
    const target = this._elementRef.nativeElement.parentElement;
    const screenWidth = target.scrollWidth;
    let width = 65;

    if (screenWidth > 1100) {
      width = 50;
    } else if (screenWidth > 900) {
      width = 65;
    }

    return {
      width
    };
  }

  private _getVideoDialogSize() {
    const target = this._elementRef.nativeElement.parentElement;
    const screenWidth = target.scrollWidth;
    let width = 100;

    if (screenWidth > 900) {
      width = 80;
    }

    return {
      width
    };
  }

  private _resetPage() {
    this._pageNumber = 0;
    this._hasMoreItems = true;
  }

  private _getVideoCalls$(
    pageNumber: number,
    searchText: string,
    resources: string
  ) {
    const result = this._hasMoreItems
      ? this._videoCallsService.getVideoCalls(
          null,
          this._past,
          pageNumber.toString(),
          '50',
          searchText,
          resources
        )
      : of(new Array<ApiVideoCall>());
    return result;
  }
}
