import * as tslib_1 from "tslib";
import qs from 'qs-lite';
import { Status } from '../constants';
export function resolvedFilterApplied(filters) {
    return filters.isResolved != null;
}
export function folderFiltersApplied(filters) {
    return filters.folderIds && filters.folderIds.length > 0;
}
export function staffFiltersApplied(filters) {
    return filters.staffIds && filters.staffIds.length > 0;
}
export function assignedFiltersApplied(filters) {
    return filters.assignedIds && filters.assignedIds.length > 0;
}
export function unAssignedFiltersApplied(filters) {
    return filters.isUnassigned != null;
}
export function filtersApplied(filters) {
    return (resolvedFilterApplied(filters) ||
        folderFiltersApplied(filters) ||
        staffFiltersApplied(filters) ||
        assignedFiltersApplied(filters) ||
        unAssignedFiltersApplied(filters));
}
var trimAndJoin = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args.filter(Boolean).join(' ');
};
var ɵ0 = trimAndJoin;
export function formatStaff(staff) {
    return tslib_1.__assign({}, staff, { firstnameinitial: (staff.firstname || '').slice(0, 1) + '.' });
}
export function formatConversation(conversation) {
    return tslib_1.__assign({}, conversation, { patientfullname: trimAndJoin(conversation.patientfirstname, conversation.patientlastname), clinicuserfullname: trimAndJoin(conversation.clinicuserfirstname, conversation.clinicuserlastname), staff: conversation.staff ? conversation.staff.map(formatStaff) : null });
}
export function formatMessage(message) {
    return tslib_1.__assign({}, message, { patientfullname: trimAndJoin(message.patientfirstname, message.patientlastname), clinicuserfullname: trimAndJoin(message.clinicuserfirstname, message.clinicuserlastname) });
}
export function formatClinicUser(staff) {
    return tslib_1.__assign({}, staff, { userfullname: trimAndJoin(staff.firstname, staff.lastname), firstnameinitial: (staff.firstname || '').slice(0, 1) + '.' });
}
export function filtersToApi(filters) {
    return tslib_1.__assign({}, (filters.folderIds.length > 0 && { messagetypeids: filters.folderIds }), { isstarred: filters.isStarred ? Status.STARRED : Status.OTHERS, q: typeof filters.q === 'string' && filters.q.length > 0
            ? filters.q
            : undefined, isresolved: filters.isResolved, staffids: filters.staffIds, assignedids: filters.assignedIds, isunassigned: filters.isUnassigned });
}
export function constructMessageDeepLink(conversation, filters) {
    var conversationId = 'messageid' in conversation ? conversation.messageid : conversation.id;
    var query = qs.stringify(tslib_1.__assign({}, filters, { conversationId: conversationId, isStarred: conversation.isstarred }));
    return "/messages/" + conversationId + "?" + query;
}
export { ɵ0 };
