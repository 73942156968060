import { ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointType, HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
var ClinicService = /** @class */ (function () {
    function ClinicService(httpService, error) {
        this.httpService = httpService;
        this.error = error;
    }
    ClinicService.prototype.getLanguages = function () {
        return this.httpService
            .get(EndpointType.Portal, 'clinic/languages')
            .pipe(map(function (res) { return res.data; }), catchError(function (err) {
            return throwError(new Error('Error retrieving languages'));
        }));
    };
    ClinicService.prototype.getClinicInfo = function (clinicToken) {
        return this.httpService
            .getRaw(EndpointType.PortalPublic, 'clinic', null, new HttpHeaders({
            'x-salve-clinic-token': clinicToken
        }))
            .pipe(map(function (res) { return res.data; }), catchError(function (err) {
            return throwError(new Error('Error retrieving clinic info'));
        }));
    };
    return ClinicService;
}());
export { ClinicService };
