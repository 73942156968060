import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
var PackDetailTableComponent = /** @class */ (function () {
    function PackDetailTableComponent(dialog, change) {
        this.dialog = dialog;
        this.change = change;
        this.packDetail = [];
        this.goto = new EventEmitter();
        this.deletePack = new EventEmitter();
        this.removePackItems = new EventEmitter();
        this.config = {};
        this.displayedColumns = [
            'select',
            'name',
            'contentCategory',
            'isFileOnly',
            'isEmpty',
            'lastModifiedBy',
            'salveModified',
        ];
    }
    PackDetailTableComponent.prototype.ngOnInit = function () {
        this._updateDataSource();
    };
    PackDetailTableComponent.prototype.ngOnChanges = function (changes) {
        var packDetail = changes.packDetail, packDetailId = changes.packDetailId;
        if (packDetail && changes.packDetail.currentValue) {
            this._updateDataSource();
        }
        if (packDetailId &&
            this.paginator &&
            packDetailId.currentValue &&
            packDetailId.previousValue) {
            if (packDetailId.currentValue !== packDetailId.previousValue) {
                this.paginator.firstPage();
            }
        }
    };
    PackDetailTableComponent.prototype.someSelected = function () {
        return this.selection.selected.length > 0;
    };
    PackDetailTableComponent.prototype.isAllSelected = function () {
        return this.selection.selected.length === this.dataSource.data.length;
    };
    PackDetailTableComponent.prototype.clearSelection = function () {
        this.selection.clear();
        this.change.markForCheck();
        this.change.detectChanges();
    };
    PackDetailTableComponent.prototype._updateDataSource = function () {
        var _this = this;
        this.dataSource = new MatTableDataSource(this.packDetail);
        setTimeout(function () {
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.sort = _this.sort;
        });
        this.selection = new SelectionModel(true, []);
    };
    PackDetailTableComponent.prototype._selectedItems = function () {
        return this.selection.selected.map(function (r) { return r.contentEntryHeaderId; });
    };
    PackDetailTableComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected()
            ? this.clearSelection()
            : this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    PackDetailTableComponent.prototype.goToContent = function (row) {
        this.goto.emit(row);
    };
    PackDetailTableComponent.prototype.removeItems = function () {
        this.removePackItems.emit({
            packId: this.packDetailId,
            contentEntries: this._selectedItems(),
        });
    };
    return PackDetailTableComponent;
}());
export { PackDetailTableComponent };
