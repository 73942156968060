import { Table, TableBody } from '@material-ui/core';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import ExportAction from './ExportAction';
import { StyledTableContainer } from './index.styled';
import SIDEExportAction from './SIDEExportAction';
import { DataExportTypes } from './types';
var DataExports = function () {
    var t = useTranslations().t;
    return (React.createElement(StyledTableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableBody, null,
                React.createElement(ExportAction, { title: t.SuperAdmin.DataExport.MessagesTitle, subtitle: t.SuperAdmin.DataExport.MessagesSubtitle, filenameSuffix: t.SuperAdmin.DataExport.MessagesFilenameSuffix, type: DataExportTypes.Messages }),
                React.createElement(SIDEExportAction, { title: t.SuperAdmin.DataExport.SIDERecipeTitle, subtitle: t.SuperAdmin.DataExport.SIDERecipeSubtitle, filenameSuffix: t.SuperAdmin.DataExport.SIDERecipeFilenameSuffix })))));
};
var ɵ0 = DataExports;
export default DataExports;
export { ɵ0 };
