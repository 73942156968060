import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { FilterChip, PopoverChip } from '@react/components';
import { useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { usePatientStaffListQuery } from '../hooks/usePatientStaffListQuery';
import { assignedFiltersApplied, folderFiltersApplied, resolvedFilterApplied, staffFiltersApplied, unAssignedFiltersApplied, } from '../lib';
import { unassigned_option } from '../utils';
export var FilterChips = function (_a) {
    var filters = _a.filters, folders = _a.folders, clinicUsers = _a.clinicUsers, onChange = _a.onChange;
    var t = useTranslations().t;
    var staffQueryResult = usePatientStaffListQuery(filters.staffIds);
    var staff = staffQueryResult.data && staffQueryResult.data.data;
    var hasResolvedFilter = resolvedFilterApplied(filters);
    var hasFolderFilter = folderFiltersApplied(filters);
    var hasStaffFilter = staffFiltersApplied(filters);
    var hasAssignedFilter = assignedFiltersApplied(filters);
    var hasUnAssignedFilter = unAssignedFiltersApplied(filters);
    var filtersApplied = hasResolvedFilter ||
        hasFolderFilter ||
        hasStaffFilter ||
        hasAssignedFilter ||
        hasUnAssignedFilter;
    var unAssignedOption = unassigned_option(t.Messages.Unassigned);
    var selectedFolders = hasFolderFilter
        ? filters.folderIds.map(function (id) {
            var folder = folders && folders.find(function (f) { return f.id === id; });
            var key = folder ? folder.name : '';
            return { key: key, value: id };
        })
        : {};
    var selectedStaff = hasStaffFilter
        ? // tslint:disable-next-line:no-non-null-assertion
            filters.staffIds.map(function (id) {
                var person = staff && staff.find(function (s) { return s.clinicuserid === id; });
                var key = person ? person.firstname + " " + person.lastname : '';
                return { key: key, value: id };
            })
        : {};
    var selectedAssignedValues = function () {
        var assigned = [];
        if (hasUnAssignedFilter) {
            assigned = assigned.concat([{ key: unAssignedOption.label }]);
        }
        if (hasAssignedFilter && clinicUsers) {
            // tslint:disable-next-line:no-non-null-assertion
            var selected = filters.assignedIds.map(function (id) {
                var clinicUser = clinicUsers.find(function (u) { return u.id === id; });
                var key = clinicUser ? clinicUser.userfullname : '';
                return { key: key, value: id };
            });
            assigned = assigned.concat(selected);
        }
        return assigned;
    };
    var selectedAssigned = (hasAssignedFilter && Array.isArray(filters.assignedIds)) ||
        hasUnAssignedFilter
        ? selectedAssignedValues()
        : {};
    function onResolvedFilterDelete() {
        onChange(tslib_1.__assign({}, filters, { isResolved: undefined }));
    }
    function onFolderFilterDelete(value) {
        var folderIds = filters.folderIds
            ? filters.folderIds.filter(function (id) { return id !== value; })
            : [];
        onChange(tslib_1.__assign({}, filters, { folderIds: folderIds.length > 0 ? folderIds : [] }));
    }
    function onFolderFiltersClear() {
        onChange(tslib_1.__assign({}, filters, { folderIds: [] }));
    }
    function onStaffFilterDelete(value) {
        var staffIds = filters.staffIds
            ? filters.staffIds.filter(function (id) { return id !== value; })
            : [];
        onChange(tslib_1.__assign({}, filters, { staffIds: staffIds.length > 0 ? staffIds : undefined }));
    }
    function onStaffFiltersClear() {
        onChange(tslib_1.__assign({}, filters, { staffIds: undefined }));
    }
    function onAssigneeFilterDelete(value) {
        var clinicUserIds = filters.assignedIds
            ? filters.assignedIds.filter(function (id) { return id !== value; })
            : [];
        var isUnassigned = filters.isUnassigned && value !== undefined ? true : undefined;
        onChange(tslib_1.__assign({}, filters, { assignedIds: clinicUserIds.length > 0 ? clinicUserIds : undefined, isUnassigned: isUnassigned }));
    }
    function onAssigneeFiltersClear() {
        onChange(tslib_1.__assign({}, filters, { assignedIds: undefined, isUnassigned: undefined }));
    }
    useErrorToast(staffQueryResult.isError, {
        title: t.Messages.FilterLoadingErrorTitle,
        message: t.Messages.FilterLoadingErrorMessage,
    });
    if (!filtersApplied) {
        return null;
    }
    return (React.createElement(StyledContainer, null,
        hasFolderFilter && (React.createElement(PopoverChip, { data: selectedFolders, label: t.Messages.FoldersChipLabel, onDelete: onFolderFilterDelete, clearAll: onFolderFiltersClear })),
        hasStaffFilter && (React.createElement(PopoverChip, { data: selectedStaff, label: t.Messages.TeamMembersFilterLabel, onDelete: onStaffFilterDelete, clearAll: onStaffFiltersClear, loading: staffQueryResult.isLoading })),
        hasResolvedFilter && (React.createElement(FilterChip, { label: filters.isResolved
                ? t.Messages.ResolvedFilterLabel
                : t.Messages.UnresolvedFilterLabel, onDelete: onResolvedFilterDelete })),
        (hasAssignedFilter || hasUnAssignedFilter) && (React.createElement(PopoverChip, { data: selectedAssigned, label: t.Messages.Assignees, onDelete: onAssigneeFilterDelete, clearAll: onAssigneeFiltersClear }))));
};
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  padding: ", "px;\n  padding-top: ", "px;\n  margin-top: -", "px;\n  background-color: #f0f0f7;\n  border-radius: 5px;\n  display: flex;\n  gap: 4px;\n"], ["\n  padding: ", "px;\n  padding-top: ", "px;\n  margin-top: -", "px;\n  background-color: #f0f0f7;\n  border-radius: 5px;\n  display: flex;\n  gap: 4px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1;
