import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LocalVideoTrack } from 'twilio-video';
import { VideoChatService } from '../../services/video.chat.service';

@Component({
  selector: 'portal-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CameraComponent {
  private _videoTrack: LocalVideoTrack;
  @ViewChild('cameraContainer') previewElement: ElementRef;
  @ViewChild('spinner') spinner: ElementRef;
  @Input() isAudioEnabled: boolean;
  @Input() isVideoEnabled: boolean;

  set videoTrack(value: LocalVideoTrack) {
    if (value) {
      this._videoTrack = value;
      this.finalizePreview();
      this.showPreview();
    }
  }
  get videoTrack(): LocalVideoTrack {
    return this._videoTrack;
  }

  isWaiting = false;

  constructor(
    private readonly renderer: Renderer2,
    private readonly videoChatService: VideoChatService,
  ) {}

  finalizePreview() {
    try {
      if (this.videoTrack) {
        this.videoTrack.detach().forEach((element) => element.remove());
      }
    } catch (e) {
      console.error(e);
    }
  }

  showPreview() {
    try {
      if (this.videoTrack) {
        this.isWaiting = true;
        this.videoTrack.on('started', () => {
          this.isWaiting = false;
        });
        const videoElement = this.videoTrack.attach();
        this.renderer.appendChild(
          this.previewElement.nativeElement,
          videoElement,
        );
      }
    } catch (error) {}
  }
}
