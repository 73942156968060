import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
export var StyledList = styled.ul(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  list-style: none;\n  min-width: 200px;\n  max-height: 330px;\n  overflow-y: auto;\n  padding: 0px;\n  margin-bottom: 0px;\n\n  li {\n    display: flex;\n    justify-content: space-between;\n    gap: ", "px;\n    padding-top: ", "px;\n    padding-bottom: ", "px;\n    padding-right: ", "px;\n\n    .close {\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n\n      &:hover {\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  list-style: none;\n  min-width: 200px;\n  max-height: 330px;\n  overflow-y: auto;\n  padding: 0px;\n  margin-bottom: 0px;\n\n  li {\n    display: flex;\n    justify-content: space-between;\n    gap: ", "px;\n    padding-top: ", "px;\n    padding-bottom: ", "px;\n    padding-right: ", "px;\n\n    .close {\n      cursor: pointer;\n      width: 20px;\n      height: 20px;\n\n      &:hover {\n        color: ", ";\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledLink = styled.a(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n"], ["\n  color: ", ";\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledContainer = styled(Box)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  padding: ", "px;\n  padding-right: 0px;\n  padding-bottom: ", "px;\n  max-height: 350px;\n  overflow: hidden;\n"], ["\n  padding: ", "px;\n  padding-right: 0px;\n  padding-bottom: ", "px;\n  max-height: 350px;\n  overflow: hidden;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1, templateObject_2, templateObject_3;
