import React, { createContext, useContext } from 'react';
// tslint:disable-next-line: no-non-null-assertion
export var RootConfigContext = createContext(null);
export var RootConfigProvider = function (_a) {
    var children = _a.children, value = _a.value;
    return React.createElement(RootConfigContext.Provider, { value: value, children: children });
};
export function useRootConfig() {
    return useContext(RootConfigContext);
}
