import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 500px;

  & > * {
    margin: ${({ theme }) => theme.spacing(1)}px 0;
  }
`;

export const IconContainer = styled.div`
  width: 75px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.hint};
  text-align: center;
`;
