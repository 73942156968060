/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-folders.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../folder-info/folder-info.component.ngfactory";
import * as i3 from "../folder-info/folder-info.component";
import * as i4 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/common";
import * as i7 from "./dashboard-folders.component";
var styles_DashboardFoldersComponent = [i0.styles];
var RenderType_DashboardFoldersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardFoldersComponent, data: {} });
export { RenderType_DashboardFoldersComponent as RenderType_DashboardFoldersComponent };
function View_DashboardFoldersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-folder-info", [], null, [[null, "goto"], [null, "goToMessages"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goto" === en)) {
        var pd_0 = (_co.goToLabel.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("goToMessages" === en)) {
        var pd_1 = (_co.goToMessages.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FolderInfoComponent_0, i2.RenderType_FolderInfoComponent)), i1.ɵdid(1, 49152, null, 0, i3.FolderInfoComponent, [], { name: [0, "name"], messageTypeId: [1, "messageTypeId"], count: [2, "count"], text: [3, "text"] }, { goto: "goto", goToMessages: "goToMessages" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.MessageType; var currVal_1 = _v.context.$implicit.MessageTypeId; var currVal_2 = _v.context.$implicit.Unresolved; var currVal_3 = _co.text["Unresolved"](_v.context.$implicit.MessageType); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DashboardFoldersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "folder-info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "mat-card", [["class", "mat-card"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i5.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i5.MatCardTitle, [], null, null), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i5.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardFoldersComponent_1)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messagingSummary.results; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text["UnresolvedMessages"]; _ck(_v, 5, 0, currVal_0); }); }
export function View_DashboardFoldersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-dashboard-folders", [], null, null, null, View_DashboardFoldersComponent_0, RenderType_DashboardFoldersComponent)), i1.ɵdid(1, 49152, null, 0, i7.DashboardFoldersComponent, [], null, null)], null, null); }
var DashboardFoldersComponentNgFactory = i1.ɵccf("portal-dashboard-folders", i7.DashboardFoldersComponent, View_DashboardFoldersComponent_Host_0, { messagingSummary: "messagingSummary", labels: "labels", text: "text", summary: "summary" }, { goToLabel: "goToLabel", goToMessages: "goToMessages" }, []);
export { DashboardFoldersComponentNgFactory as DashboardFoldersComponentNgFactory };
