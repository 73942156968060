import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
var InviteUserModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, children = _a.children;
    var _b = useState(open), isOpen = _b[0], setIsOpen = _b[1];
    useEffect(function () {
        setIsOpen(open);
    }, [open]);
    return (React.createElement(Dialog, { open: isOpen, onClose: onClose }, children));
};
var ɵ0 = InviteUserModal;
export default InviteUserModal;
export { ɵ0 };
