import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import React from 'react';
export var ItemText = styled('span')(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: block;\n  width: 270px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  display: block;\n  width: 270px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
export function ListingTooltip(_a) {
    var title = _a.title, children = _a.children;
    var enterDelay = 1500;
    return (React.createElement(Tooltip, { title: title, placement: "top-start", enterDelay: enterDelay, enterNextDelay: enterDelay, leaveDelay: 250 }, children));
}
var templateObject_1;
