<div fxLayout="column" fxLayoutAlign="space-between stretch">
  <!-- Title And Description -->
  <h3 mat-dialog-title class="title">
    {{ data.text.AddToPack }}
  </h3>
  <div class="description">
    {{ data.text.AddToPackDescription }}
  </div>

  <!-- Content list and pack selection -->
  <div
    class="dialog-content"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px"
  >
    <!-- header -->
    <div
      class="list-header"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div>{{ data.text.Title }}</div>
      <div>{{ data.text.Remove }}</div>
    </div>
    <!-- list of content to be added with remove button -->
    <perfect-scrollbar class="entry-list">
      <div
        *ngFor="let entry of contentEntries"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="option"
      >
        <div class="option-name">{{ entry.name }}</div>
        <button
          mat-icon-button
          type="button"
          (click)="removeFromContentEntries(entry.id)"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </perfect-scrollbar>
    <!-- Select a Pack -->
    <div class="selection" fxLayout="column" fxLayoutAlign="start stretch">
      <div
        class="list-header"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="isAllSelected()"
        >
        </mat-checkbox>
        <div>
          {{ data.text.SelectAPack }}
        </div>
      </div>
      <perfect-scrollbar class="entry-list">
        <div
          *ngFor="let pack of availablePacks"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
          class="option"
        >
          <mat-checkbox
            (change)="$event ? togglePack(pack.id) : null"
            [checked]="pack.toAssign"
          >
          </mat-checkbox>
          <div>{{ pack.packName }}</div>
        </div>
      </perfect-scrollbar>
      <!-- <div
        class="selection-option"
        fxFlex="grow"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <mat-select
          [formControl]="selectedPack"
          [placeholder]="data.text.PleaseChoosePack"
        >
          <mat-option *ngFor="let pack of packs$ | async" [value]="pack.id">
            {{ pack.packName }}
          </mat-option>
        </mat-select>
      </div> -->
    </div>
  </div>

  <!-- Buttons and status -->
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <div
      fxFlex="1 1 calc(100% - 100px)"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <button
        autofocus
        mat-raised-button
        class="portal-btn btn-primary"
        (click)="cancel()"
      >
        {{ data.text.Cancel }}
      </button>
      <div
        *ngIf="addingToPack$ | async"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <mat-spinner diameter="30"></mat-spinner>
        <div>
          {{ data.text.AddingToPack }}
        </div>
      </div>
      <div *ngIf="success" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon class="success">check_circle</mat-icon>
        <div>
          {{ data.text.AddedSuccessfully }}
        </div>
      </div>
      <div
        *ngIf="errorEncountered$ | async"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <mat-icon class="error">highlight_off</mat-icon>
        <div>
          {{ data.text.AddingToPackError }}
        </div>
      </div>
    </div>

    <button
      [disabled]="disableSubmission()"
      mat-raised-button
      class="portal-btn btn-bright"
      (click)="addToPack()"
    >
      {{ data.text.Save }}
    </button>
  </div>
</div>
