import { Box, List, Popover, PopoverProps, Tab } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { Button, Loading } from '@react/components';
import { useTranslations } from '@react/lib/i18n';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import {
  StyledActionsContainer,
  StyledListItem,
  StyledListItemText,
  StyledSearchBar,
  StyledTabPanel,
  StyledTabs,
} from './FilterMenuPopover.styled';

export interface FilterOption {
  id: string;
  label: string;
  active: boolean;
  name?: string;
  userfullname?: string;
  hidden?: boolean;
  weight?: string;
}

export interface ITab {
  id: string;
  label: string;
  options: FilterOption[];
  searchEnabled?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  loading?: boolean;
}

interface Props {
  id?: PopoverProps['id'];
  anchorEl: PopoverProps['anchorEl'];
  open: boolean;
  onClose?: PopoverProps['onClose'];
  tabs: ITab[];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  onChange: (updateOptions: FilterMenuPopoverState) => void;
  onTabEndReached?: (tabId: ITab['id']) => void;
  onSearchUpdate?: (tabId: ITab['id'], value: string) => void;
  onOptionClicked: (tabId: ITab['id'], value: string) => void;
  optionsState: FilterMenuPopoverState;
}

export type FilterMenuPopoverState = Record<string, Set<string>>;

export function getOptionsStateFromTabs(tabs: ITab[]): FilterMenuPopoverState {
  return tabs.reduce((tabsState, tab) => {
    return {
      ...tabsState,
      [tab.id]: new Set(
        tab.options
          .filter((option) => option.active)
          .map((option) => option.id),
      ),
    };
  }, {});
}

export const FilterMenuPopover: React.FC<Props> = ({
  id,
  anchorEl,
  open,
  onClose,
  tabs,
  anchorOrigin,
  transformOrigin,
  onChange,
  onTabEndReached,
  onSearchUpdate,
  onOptionClicked,
  optionsState,
}) => {
  const { t } = useTranslations();
  const [selectedTab, setSelectedTab] = useState(0);
  const [transitionEnded, setTransitionEnded] = useState(false);

  function onTabChange(event: React.ChangeEvent<{}>, value: number) {
    setSelectedTab(value);
  }

  function onApplyClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChange(optionsState);
    if (onClose) {
      onClose(e, 'escapeKeyDown');
    }
  }

  function onCloseInner(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClose) {
      onClose(e, 'escapeKeyDown');
    }
  }

  function onSearchChange(tabId: ITab['id'], value: string) {
    if (onSearchUpdate) {
      onSearchUpdate(tabId, value);
    }
  }

  function onEndReached(tabId: ITab['id']) {
    if (onTabEndReached) {
      onTabEndReached(tabId);
    }
  }

  return (
    <Popover
      id={id}
      aria-modal="true"
      aria-label={t.Messages.FilterMenuAriaLabel}
      open={open}
      anchorEl={anchorEl}
      TransitionProps={{
        onEntered: () => setTransitionEnded(true),
      }}
      onClose={onCloseInner}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Box>
        <StyledTabs
          // Hack to fix issue where tab scroll does not init
          // properly during transition
          key={`open-${transitionEnded}`}
          value={selectedTab}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.id} role="tab" label={tab.label} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => {
        return (
          <div
            key={tab.id}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`tabpanel-${index}`}
          >
            {selectedTab === index && (
              <StyledTabPanel>
                {tab.searchEnabled && (
                  <StyledSearchBar
                    value={tab.searchValue}
                    onChange={(value) => onSearchChange(tab.id, value)}
                    placeholder={tab.searchPlaceholder}
                  />
                )}
                <List dense>
                  {tab.options
                    .filter((option) => !option.hidden)
                    .map((option) => {
                      const selected = optionsState[tab.id].has(option.id);
                      return (
                        <StyledListItem
                          className={clsx({ selected })}
                          button={true}
                          key={option.id}
                          dense
                          onClick={() => onOptionClicked(tab.id, option.id)}
                          style={{ paddingTop: 8, paddingBottom: 8 }}
                        >
                          <StyledListItemText
                            id={option.id}
                            primary={option.label}
                            weight={option.weight}
                          />
                          {selected && <Done />}
                        </StyledListItem>
                      );
                    })}
                  <Waypoint onEnter={() => onEndReached(tab.id)} />
                  {tab.loading && <Loading />}
                </List>
              </StyledTabPanel>
            )}
          </div>
        );
      })}
      <StyledActionsContainer>
        <Button
          data-testid="btn_apply"
          color="primary"
          variant="contained"
          onClick={onApplyClicked}
        >
          {t.Messages.FilterMenuApplyButton}
        </Button>
      </StyledActionsContainer>
    </Popover>
  );
};

FilterMenuPopover.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};
