/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/button-toggle";
import * as i5 from "../../../../../node_modules/@angular/material/button-toggle/typings/index.ngfactory";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "./time-picker.component";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
function View_TimePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "select", [["class", "time-picker-period"]], [[8, "disabled", 0], [8, "value", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handlePeriodChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "option", [["value", "AM"]], null, null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(3, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["AM"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "option", [["value", "PM"]], null, null, null, null, null)), i1.ɵdid(6, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(7, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["PM"]))], function (_ck, _v) { var currVal_2 = "AM"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "AM"; _ck(_v, 3, 0, currVal_3); var currVal_4 = "PM"; _ck(_v, 6, 0, currVal_4); var currVal_5 = "PM"; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.period; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "time-picker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["aria-label", "Hours"], ["class", "time-picker-input"], ["maxlength", "2"]], [[8, "disabled", 0], [8, "min", 0], [8, "max", 0], [8, "value", 0]], [[null, "blur"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.handleHoursBlur() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.selectAll($event) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (_co.handleHoursInput($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "time-picker-divider"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [":"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["aria-label", "Minutes"], ["class", "time-picker-input"], ["max", "59"], ["maxlength", "2"]], [[8, "disabled", 0], [8, "value", 0]], [[null, "blur"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.handleMinutesBlur() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.selectAll($event) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (_co.handleMinutesInput($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 10, "mat-button-toggle-group", [["class", "time-picker-hours-type mat-button-toggle-group"], ["role", "group"]], [[1, "aria-disabled", 0], [2, "mat-button-toggle-vertical", null], [2, "mat-button-toggle-group-appearance-standard", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleHoursTypeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatButtonToggleGroup]), i1.ɵprd(6144, null, i4.MatButtonToggleGroupMultiple, null, [i4.MatButtonToggleGroup]), i1.ɵdid(10, 1130496, null, 1, i4.MatButtonToggleGroup, [i1.ChangeDetectorRef, [2, i4.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { value: [0, "value"], disabled: [1, "disabled"] }, { change: "change" }), i1.ɵqud(603979776, 1, { _buttonToggles: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 2, "mat-button-toggle", [["class", "time-picker-hours-type-button mat-button-toggle"], ["value", "12H"]], [[2, "mat-button-toggle-standalone", null], [2, "mat-button-toggle-checked", null], [2, "mat-button-toggle-disabled", null], [2, "mat-button-toggle-appearance-standard", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButtonToggle_0, i5.RenderType_MatButtonToggle)), i1.ɵdid(13, 245760, [[1, 4]], 0, i4.MatButtonToggle, [[2, i4.MatButtonToggleGroup], i1.ChangeDetectorRef, i1.ElementRef, i6.FocusMonitor, [8, null], [2, i4.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, 0, ["12H"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "mat-button-toggle", [["class", "time-picker-hours-type-button mat-button-toggle"], ["value", "24H"]], [[2, "mat-button-toggle-standalone", null], [2, "mat-button-toggle-checked", null], [2, "mat-button-toggle-disabled", null], [2, "mat-button-toggle-appearance-standard", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButtonToggle_0, i5.RenderType_MatButtonToggle)), i1.ɵdid(16, 245760, [[1, 4]], 0, i4.MatButtonToggle, [[2, i4.MatButtonToggleGroup], i1.ChangeDetectorRef, i1.ElementRef, i6.FocusMonitor, [8, null], [2, i4.MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, 0, ["24H"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.hoursType === "12H"); _ck(_v, 6, 0, currVal_6); var currVal_10 = _co.hoursType; var currVal_11 = _co.disabled; _ck(_v, 10, 0, currVal_10, currVal_11); var currVal_18 = "12H"; _ck(_v, 13, 0, currVal_18); var currVal_25 = "24H"; _ck(_v, 16, 0, currVal_25); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.minHours; var currVal_2 = _co.maxHours; var currVal_3 = _co.hours; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.disabled; var currVal_5 = _co.minutes; _ck(_v, 4, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 10).disabled; var currVal_8 = i1.ɵnov(_v, 10).vertical; var currVal_9 = (i1.ɵnov(_v, 10).appearance === "standard"); _ck(_v, 7, 0, currVal_7, currVal_8, currVal_9); var currVal_12 = !i1.ɵnov(_v, 13).buttonToggleGroup; var currVal_13 = i1.ɵnov(_v, 13).checked; var currVal_14 = i1.ɵnov(_v, 13).disabled; var currVal_15 = (i1.ɵnov(_v, 13).appearance === "standard"); var currVal_16 = (0 - 1); var currVal_17 = i1.ɵnov(_v, 13).id; _ck(_v, 12, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_19 = !i1.ɵnov(_v, 16).buttonToggleGroup; var currVal_20 = i1.ɵnov(_v, 16).checked; var currVal_21 = i1.ɵnov(_v, 16).disabled; var currVal_22 = (i1.ɵnov(_v, 16).appearance === "standard"); var currVal_23 = (0 - 1); var currVal_24 = i1.ɵnov(_v, 16).id; _ck(_v, 15, 0, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24); }); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "portal-time-picker", [], null, null, null, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.TimePickerComponent]), i1.ɵdid(2, 49152, null, 0, i7.TimePickerComponent, [], null, null)], null, null); }
var TimePickerComponentNgFactory = i1.ɵccf("portal-time-picker", i7.TimePickerComponent, View_TimePickerComponent_Host_0, {}, {}, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
