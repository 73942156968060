import styled from '@emotion/styled';
import { Grid, Slider } from '@material-ui/core';

const size = 200;

export const ThemedSlider = styled(Slider)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const ThemedGrid = styled(Grid)`
  border-radius: 50%;
  cursor: pointer;
  height: ${size}px;
  overflow: hidden;
  width: ${size}px;
`;

export const ThemedImg = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;
