
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import { CognitoWrapperService } from './congito.wrapper.service';

export class HttpDefaultOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
}

@Injectable()
export class ContentApiCallService {
  // Observables, select values from ngrx store
  private _userToken$: Observable<string>;
  private _clinicToken$: Observable<string>;
  private _apiEndpoint$: Observable<string>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
  ) {
    this._userToken$ = this._store.pipe(select(fromAuth.getPublicKey));
    this._clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
    this._apiEndpoint$ = of(environment.api.content.endpoint);
  }

  /**
   * Takes an array of observables and returns Observable array that
   * defines the content api endpoint, the default options to accompany the
   * http request (which consists of query string parameters and default
   * request headers), and any other observables necessary for the api call
   * can be added to the array of observables passed as the only parameter,
   * and will be appended to the end of the observable array that is returned.
   * containing [apiEndpoint, defaultParams, ...args]
   */
  public callApi(args: Observable<any>[] = []): Observable<any[]> {
    return combineLatest(
      this._apiEndpoint$,
      this._userToken$,
      this._clinicToken$,
      this._cognito.getAuthSession(),
      ...args,
    ).pipe(
      filter(
        ([endpoint, userToken, clinicToken]) =>
          !!userToken && !!endpoint && !!clinicToken,
      ),
      mergeMap(
        ([endpoint, userToken, clinicToken, cognitoUserSession, ...rest]) => {
          const defaultHttpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: cognitoUserSession.getIdToken().jwtToken,
            }),
            params: {
              userToken,
              clinicToken,
            },
          };
          return of([endpoint, defaultHttpOptions, ...rest]);
        },
      ),
    );
  }
}
