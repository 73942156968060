import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
var VideoCallsService = /** @class */ (function () {
    function VideoCallsService(_httpService, _store, _error) {
        this._httpService = _httpService;
        this._store = _store;
        this._error = _error;
        this.videoCallsEnabled$ = this._store.pipe(select(fromAuth.getVideoCallsEnabled));
    }
    VideoCallsService.prototype.getVideoCalls = function (patientId, isPast, pageNumber, pageSize, q, resources) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = '1'; }
        if (pageSize === void 0) { pageSize = '50'; }
        return this.videoCallsEnabled$.pipe(switchMap(function (videoCallsEnabled) {
            if (videoCallsEnabled) {
                var path = 'videoconference';
                var params = {
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                };
                if (q) {
                    params = tslib_1.__assign({}, params, { q: q });
                }
                if (resources) {
                    params = tslib_1.__assign({}, params, { resources: resources });
                }
                if (isPast) {
                    params = tslib_1.__assign({}, params, { interval: 'past' });
                }
                if (patientId) {
                    path = "patients/" + patientId + "/videoconference";
                }
                return _this._httpService
                    .get(EndpointType.Portal, path, params)
                    .pipe(map(function (res) { return res.data; }));
            }
            else {
                return of([]);
            }
        }));
    };
    VideoCallsService.prototype.getVideoCallResources = function () {
        var _this = this;
        return this.videoCallsEnabled$.pipe(switchMap(function (videoCallsEnabled) {
            if (videoCallsEnabled) {
                var path = 'videoconference/resources';
                return _this._httpService
                    .get(EndpointType.Portal, path)
                    .pipe(map(function (res) { return res.data; }));
            }
            else {
                return of([]);
            }
        }));
    };
    VideoCallsService.prototype.updateVideoCall = function (videoCall) {
        var result;
        if (videoCall.id) {
            var patchPayload = tslib_1.__assign({}, videoCall, { id: undefined, patientid: undefined, patient: undefined });
            result = this._httpService.patch(EndpointType.Portal, "videoconference/" + videoCall.id, patchPayload);
        }
        else {
            result = this._httpService.post(EndpointType.Portal, 'videoconference', tslib_1.__assign({}, videoCall, { patient: undefined, patientid: videoCall.patient.Id }));
        }
        return result;
    };
    VideoCallsService.prototype.deleteVideoCall = function (id) {
        return this._httpService.delete(EndpointType.Portal, "videoconference/" + id);
    };
    VideoCallsService.prototype.joinVideoCall = function (id) {
        return this._httpService.get(EndpointType.Portal, "videoconference/" + id + "/join");
    };
    return VideoCallsService;
}());
export { VideoCallsService };
