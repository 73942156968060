import styled from '@emotion/styled';
import Root from '@react/components/Root';

export const StyledRoot = styled(Root)`
  height: 100%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const LoadingErrorContainer = styled.div`
  flex: 1;
`;

export const Main = styled.main`
  flex: 1;
  min-height: 100%;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;
