import * as tslib_1 from "tslib";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tab as MuiTab } from '@material-ui/core';
import React from 'react';
import { Chip } from '../Chip';
var tabCountStyles = css(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  &:not(.Mui-selected) {\n    font-weight: normal;\n    opacity: 0.75;\n  }\n  &.MuiTab-labelIcon {\n    padding-top: 6px;\n  }\n  .MuiTab-wrapper {\n    display: flex;\n    flex-direction: row-reverse;\n    .MuiChip-root {\n      margin-left: 0.5rem;\n      margin-bottom: 0;\n    }\n  }\n"], ["\n  display: flex;\n  &:not(.Mui-selected) {\n    font-weight: normal;\n    opacity: 0.75;\n  }\n  &.MuiTab-labelIcon {\n    padding-top: 6px;\n  }\n  .MuiTab-wrapper {\n    display: flex;\n    flex-direction: row-reverse;\n    .MuiChip-root {\n      margin-left: 0.5rem;\n      margin-bottom: 0;\n    }\n  }\n"])));
var hasCount = function (count) {
    return typeof count === 'number' || typeof count === 'string';
};
var ɵ0 = hasCount;
var StyledTab = styled(MuiTab)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  ", "\n  min-height: initial;\n  height: 48px;\n  font-size: 1rem;\n  text-transform: initial;\n"], ["\n  ", "\n  min-height: initial;\n  height: 48px;\n  font-size: 1rem;\n  text-transform: initial;\n"])), function (_a) {
    var count = _a.count;
    return (hasCount(count) ? tabCountStyles : '');
});
export var Tab = function (props) {
    props = tslib_1.__assign({ icon: hasCount(props.count) ? (React.createElement(Chip, { round: true, label: props.count })) : undefined }, props);
    return React.createElement(StyledTab, tslib_1.__assign({ role: "tab" }, props));
};
export { ɵ0 };
var templateObject_1, templateObject_2;
