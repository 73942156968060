import { Component, ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientService } from 'src/app/patients/services/patient.service';
import { getInviteCode } from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { PatientSearchBase } from '../../PatientSearchBase';

@Component({
  selector: 'portal-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent
  extends PatientSearchBase
  implements OnInit, OnDestroy
{
  public inviteCode$: Observable<string>;

  public patientListText$: Observable<any>;

  public patientListText: any;

  public filterForm: FormGroup;

  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private _navigationService: NavigationService,
    private _errorHandler: ErrorHandler,
    _patientService: PatientService,
    _localisationService: LocalisationService,
  ) {
    super(_patientService, _localisationService);
  }

  ngOnInit() {
    this.inviteCode$ = this._store.pipe(select(getInviteCode));
    this.patientListText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('PatientList')),
    );

    this.setupSearchObservables(this._errorHandler);

    this._subs.add(
      this.patientListText$.subscribe((t) => (this.patientListText = t)),
    );

    this.setupSearchInput();
  }

  private setupSearchInput() {
    this.filterForm = this.fb.group({
      filter: [
        {
          value: null,
          disabled: false,
        },
      ],
    });

    this.observeSearchInput(this.filterForm.get('filter'));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  clearSearch() {
    this.filterForm.patchValue({
      filter: '',
    });
  }

  gotoPatient(id: number) {
    this._navigationService.navigate(['patients', id]);
  }
}
