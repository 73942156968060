/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "./nav-message.component";
var styles_NavMessageComponent = [i0.styles];
var RenderType_NavMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavMessageComponent, data: {} });
export { RenderType_NavMessageComponent as RenderType_NavMessageComponent };
export function View_NavMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵted(5, 2, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.tempMessage; _ck(_v, 5, 0, currVal_2); }); }
export function View_NavMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-nav-message", [], null, null, null, View_NavMessageComponent_0, RenderType_NavMessageComponent)), i1.ɵdid(1, 49152, null, 0, i4.NavMessageComponent, [], null, null)], null, null); }
var NavMessageComponentNgFactory = i1.ɵccf("portal-nav-message", i4.NavMessageComponent, View_NavMessageComponent_Host_0, { tempMessage: "tempMessage" }, {}, []);
export { NavMessageComponentNgFactory as NavMessageComponentNgFactory };
