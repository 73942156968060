import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import { HttpClientBase } from './httpClientBase';
var S3Client = /** @class */ (function (_super) {
    tslib_1.__extends(S3Client, _super);
    function S3Client(options) {
        return _super.call(this, tslib_1.__assign({}, options, { baseUrl: environment.api.s3Proxy.endpoint })) || this;
    }
    S3Client.prototype.getSignedUrl = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("clinic/read", {
                        body: JSON.stringify({ url: url }),
                        headers: { 'content-type': 'application/json' },
                        method: 'POST',
                    })];
            });
        });
    };
    return S3Client;
}(HttpClientBase));
export { S3Client };
