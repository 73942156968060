<div class="video-calls-page">
  <!-- Header with search -->
  <div>
    <section fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card
        class="search-card"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxFlex="grow"
        flexLayoutGap="12px"
      >
        <form
          class="patient-filter-form"
          novalidate
          [formGroup]="filterForm"
          fxFlex="grow"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon>search</mat-icon>
          <input
            class="filter-input"
            id="filter"
            formControlName="filter"
            placeholder="{{videoCallsListText.Search}}"
            fxFlexFill
          />
        </form>
        <div *ngIf="listIsFiltered$ | async">
          <button
            mat-button
            class="portal-btn btn-primary"
            (click)="clearFilter()"
          >
            {{videoCallsListText.ClearFilter}}
          </button>
        </div>
      </mat-card>
      <div>
        <button
          mat-raised-button
          class="portal-btn btn-primary"
          (click)="openVideoCall()"
        >
          {{videoCallsListText.CreateVideoCall}}
        </button>
      </div>
    </section>
    <portal-apply-filter
      [filterButtonText]="videoCallsListText.AddFilter"
      [noResultsText]="videoCallsListText.FilterNoResults"
      [applyButtonText]="videoCallsListText.FilterApply"
      [cancelButtonText]="videoCallsListText.FilterCancel"
      [searchPlaceholderText]="videoCallsListText.FilterSearch"
      [headerText]="videoCallsListText.FilterHostResource"
      [items]="videoCallResources$ | async"
      (filtersApplied)="filtersApplied($event)"
    ></portal-apply-filter>
  </div>

  <!-- Main content -->
  <mat-card class="call-list-container">
    <section fxLayout="row" fxLayoutAlign="space-between center">
      <mat-tab-group
        [selectedIndex]="selectedTab$ | async"
        (selectedIndexChange)="tabChanged($event)"
      >
        <mat-tab [label]="videoCallsListText.UpcomingCalls"> </mat-tab>
        <mat-tab [label]="videoCallsListText.PreviousCalls"> </mat-tab>
      </mat-tab-group>
      <button class="reload" mat-icon-button (click)="reload()">
        <mat-icon
          [inline]="true"
          class="reload-icon"
          [class.rotate]="loading$|async"
          [title]="videoCallsListText.RefreshTitle"
          >sync</mat-icon
        >
      </button>
    </section>

    <portal-video-calls-list
      [items]="videoCallsList$ | async"
      [text]="videoCallsListText"
      [invitePartnerText]="videoCallsInvitePartner"
      (joinVideoCall)="joinVideoCall($event)"
      (editVideoCall)="editVideoCall($event)"
      (invitePartner)="invitePartner($event)"
      (unInvitePartner)="unInvitePartner($event)"
      (reachedScrollEnd)="triggerNextPage()"
      [partnerInviteEnabled]="partnerInviteEnabled"
      [currentStateIsPast]="currentStateIsPast"
    ></portal-video-calls-list>
    <div
      *ngIf="loading$ | async"
      class="loading-container"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div
      *ngIf="!(loading$ | async) && (videoCallsList$ | async)?.length === 0"
      class="empty-container"
    >
      {{ noCallsText }}
    </div>
  </mat-card>
</div>
