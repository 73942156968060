
import { sortByKeyAlphabeticallyLowercase } from 'src/app/shared/utils';
import { Category } from '../../content/models/category';
import { filterAndSortCategories } from '../../shared/utils';
import {
  ContentAssignmentActionsUnion,
  ContentAssignmentActionTypes,
} from '../actions/content-assignment.actions';
import {
  PackAssignmentActionsUnion,
  PackAssignmentActionTypes,
} from '../actions/pack-assignment.actions';
import {
  GetAllPatientAssignedContentResponse,
  PatientOnlyAssignContentItem,
} from '../models/UserContent';
import { ContentEntryHeader } from './../models/Content';
import {
  AssignedContentPack,
  ContentEntriesByCategory,
} from './../models/UserContent';

export interface ContentAssignmentFilterableList {
  filterActive: boolean;
  filterString: string;
}

export type ContentTabs = 'folders' | 'all' | 'packs';

export enum ContentTabsOption {
  PACKS = 'packs',
  FOLDERS = 'folders',
}

export enum ClinicViewAccordionOption {
  PACKS = 'PACKS',
  ITEMS = 'ITEMS',
}

export interface State {
  currentPatientId: number;
  contentTab: ContentTabs;
  activeContentType: number;
  patientOnlyContentTypeId: number;
  patientOnlyAssignedContent: PatientOnlyAssignContentItem[];
  viewAsPatient: boolean;
  viewAsPatientOpenFolder: number;
  patientViewOfContent: ContentEntriesByCategory[];
  clinicViewAccordion: ClinicViewAccordionOption;
  allPatientAssignedContent: GetAllPatientAssignedContentResponse;
  contentEntries: {
    [key: string]: ContentEntryHeader[];
  };
  contentTypes: Category[];
  contentSelect: ContentAssignmentFilterableList;
  assignedContent: ContentAssignmentFilterableList;
  error: {
    patientView: boolean;
    allPatientAssigned: boolean;
  };
  loading: {
    patientView: boolean;
    allPatientAssigned: boolean;
    contentPacks: boolean;
    packDetail: boolean;
    contentEntries: boolean;
    contentTypes: boolean;
    userAssignedContent: boolean;
  };
}

export const initialState: State = {
  currentPatientId: null,
  contentTab: 'folders',
  activeContentType: null,
  patientOnlyContentTypeId: null,
  patientOnlyAssignedContent: [],
  viewAsPatient: false,
  viewAsPatientOpenFolder: null,
  patientViewOfContent: [],
  clinicViewAccordion: ClinicViewAccordionOption.PACKS,
  allPatientAssignedContent: {
    assignedContent: [],
    assignedPacks: [],
  },
  contentEntries: {},
  contentTypes: [],
  contentSelect: {
    filterActive: false,
    filterString: '',
  },
  assignedContent: {
    filterActive: false,
    filterString: '',
  },
  error: {
    patientView: false,
    allPatientAssigned: false,
  },
  loading: {
    patientView: false,
    allPatientAssigned: false,
    contentPacks: false,
    packDetail: false,
    contentEntries: false,
    contentTypes: false,
    userAssignedContent: false,
  },
};

export function reducer(
  state = initialState,
  action: ContentAssignmentActionsUnion | PackAssignmentActionsUnion,
): State {
  switch (action.type) {
    case ContentAssignmentActionTypes.ChangeContentTab: {
      return {
        ...state,
        contentTab: action.payload,
      };
    }
    case ContentAssignmentActionTypes.OpenPatientViewFolder: {
      return {
        ...state,
        viewAsPatientOpenFolder:
          state.viewAsPatientOpenFolder === action.payload
            ? null
            : action.payload,
      };
    }
    // get types success
    case ContentAssignmentActionTypes.GetContentTypesSuccess: {
      const activeContentTypeId =
        action.payload.length > 0 ? action.payload[0].id : 0;
      const patientOnlyContentType = action.payload.find((t) => t.patientonly);
      let patientOnlyContentTypeId;
      if (patientOnlyContentType) {
        patientOnlyContentTypeId = patientOnlyContentType.id;
      }
      return {
        ...state,
        patientOnlyContentTypeId,
        activeContentType:
          state.activeContentType === null
            ? activeContentTypeId
            : state.activeContentType,
        contentTypes: action.payload.filter((t) => !t.patientonly),
      };
    }
    // get entries success
    case ContentAssignmentActionTypes.GetContentEntriesSuccess: {
      return {
        ...state,
        contentEntries: action.payload,
      };
    }
    // create userassignedcontentsuccess
    case ContentAssignmentActionTypes.CreatePatientAssignedContentSuccess: {
      const assignedContent = [
        ...state.allPatientAssignedContent.assignedContent,
        action.payload,
      ].sort(sortByKeyAlphabeticallyLowercase('contentName'));
      return {
        ...state,
        allPatientAssignedContent: {
          ...state.allPatientAssignedContent,
          assignedContent,
        },
      };
    }
    // delete userassignedcontentsuccess
    case ContentAssignmentActionTypes.DeletePatientAssignedContentSuccess: {
      const { contententryheaderid } = action.payload;

      return {
        ...state,
        allPatientAssignedContent: {
          ...state.allPatientAssignedContent,
          assignedContent:
            state.allPatientAssignedContent.assignedContent.filter(
              (c) => c.contentEntryHeaderId !== contententryheaderid,
            ),
        },
      };
    }
    // get patient only content success
    case ContentAssignmentActionTypes.GetPatientOnlyAssignmentsSuccess: {
      return {
        ...state,
        patientOnlyAssignedContent: action.payload,
      };
    }
    // create patient only content success
    case ContentAssignmentActionTypes.CreatePatientOnlyAssignmentsSuccess: {
      return {
        ...state,
        patientOnlyAssignedContent: [
          ...state.patientOnlyAssignedContent,
          action.payload,
        ],
      };
    }
    // delete patient only content success
    case ContentAssignmentActionTypes.DeletePatientOnlyAssignmentsSuccess: {
      return {
        ...state,
        patientOnlyAssignedContent: state.patientOnlyAssignedContent.filter(
          (c) => c.assignmentid !== action.payload.assignmentid,
        ),
      };
    }
    // update patient only content success
    case ContentAssignmentActionTypes.UpdatePatientOnlyAssignmentsSuccess: {
      const updatedContent = state.patientOnlyAssignedContent.map((c) =>
        c.assignmentid === action.payload.assignmentid ? action.payload : c,
      );
      return {
        ...state,
        patientOnlyAssignedContent: updatedContent,
      };
    }
    // set active content type
    case ContentAssignmentActionTypes.SetActiveContentType: {
      return {
        ...state,
        activeContentType: action.payload,
      };
    }
    // set content select filter
    case ContentAssignmentActionTypes.SetContentSelectFilter: {
      const str = action.payload;
      return {
        ...state,
        contentSelect:
          str === ''
            ? {
                filterActive: false,
                filterString: '',
              }
            : {
                filterActive: true,
                filterString: str,
              },
      };
    }
    // set assigned content filter
    case ContentAssignmentActionTypes.SetAssignedContentFilter: {
      const str = action.payload;
      return {
        ...state,
        assignedContent:
          str === ''
            ? {
                filterActive: false,
                filterString: '',
              }
            : {
                filterActive: true,
                filterString: str,
              },
      };
    }

    // Set Content Type to Patient Only type
    case ContentAssignmentActionTypes.SetContentTypeToPatientDocs: {
      return {
        ...state,
        activeContentType: state.patientOnlyContentTypeId,
      };
    }
    // Set Content Type to Non Patient Only type
    case ContentAssignmentActionTypes.SetContentTypeToNonPatientDocs: {
      let activeContentType = 0;
      if (state.contentTypes.length > 0) {
        activeContentType = state.contentTypes[0].id;
      }
      return {
        ...state,
        activeContentType,
      };
    }

    case ContentAssignmentActionTypes.GetAllPatientAssignedContent: {
      return {
        ...state,
        error: {
          ...state.error,
          allPatientAssigned: false,
        },
        loading: {
          ...state.loading,
          allPatientAssigned: true,
        },
      };
    }
    case ContentAssignmentActionTypes.GetAllPatientAssignedContentSuccess: {
      const { assignedPacks, assignedContent } = action.payload;
      return {
        ...state,
        allPatientAssignedContent: {
          assignedPacks,
          assignedContent: assignedContent.sort(
            sortByKeyAlphabeticallyLowercase('contentName'),
          ),
        },
        loading: {
          ...state.loading,
          allPatientAssigned: false,
        },
      };
    }
    case ContentAssignmentActionTypes.GetAllPatientAssignedContentError: {
      return {
        ...state,
        error: {
          ...state.error,
          allPatientAssigned: true,
        },
        loading: {
          ...state.loading,
          allPatientAssigned: false,
        },
      };
    }

    case ContentAssignmentActionTypes.GetPatientViewOfContent: {
      return {
        ...state,
        error: {
          ...state.error,
          patientView: false,
        },
        loading: {
          ...state.loading,
          patientView: true,
        },
      };
    }
    case ContentAssignmentActionTypes.GetPatientViewOfContentSuccess: {
      return {
        ...state,
        patientViewOfContent: action.payload.patientContent,
        loading: {
          ...state.loading,
          patientView: false,
        },
      };
    }
    case ContentAssignmentActionTypes.GetPatientViewOfContentError: {
      return {
        ...state,
        error: {
          ...state.error,
          patientView: true,
        },
        loading: {
          ...state.loading,
          patientView: false,
        },
      };
    }

    case ContentAssignmentActionTypes.ChangeAssignedContentView: {
      return {
        ...state,
        viewAsPatient: !state.viewAsPatient,
      };
    }

    case ContentAssignmentActionTypes.OpenItemList: {
      return {
        ...state,
        clinicViewAccordion: ClinicViewAccordionOption.ITEMS,
      };
    }
    case ContentAssignmentActionTypes.OpenPackList: {
      return {
        ...state,
        clinicViewAccordion: ClinicViewAccordionOption.PACKS,
      };
    }

    case ContentAssignmentActionTypes.SetPatientId: {
      return {
        ...state,
        currentPatientId: action.payload,
      };
    }

    // Pack assignment Actions
    case PackAssignmentActionTypes.AddExclusionSuccess: {
      const { packId, exclusions } = action.payload;
      const assignedPacks = state.allPatientAssignedContent.assignedPacks.map(
        (pack) =>
          +pack.packId === +packId
            ? {
                ...pack,
                contents: pack.contents.filter(
                  (c) =>
                    !exclusions.includes(c.hiddenId || c.contentEntryHeaderId),
                ),
              }
            : pack,
      );
      return {
        ...state,
        allPatientAssignedContent: {
          ...state.allPatientAssignedContent,
          assignedPacks,
        },
      };
    }

    case PackAssignmentActionTypes.AssignPackSuccess: {
      const { packId, packName, isDefault, packDetail, exclusions } =
        action.payload;
      const newPack: AssignedContentPack = {
        packId,
        packName,
        isDefault,
        contents: packDetail.filter(
          (d) => !exclusions.includes(d.contentEntryHeaderId),
        ),
      };

      return {
        ...state,
        allPatientAssignedContent: {
          ...state.allPatientAssignedContent,
          assignedPacks: state.allPatientAssignedContent.assignedPacks
            .concat([newPack])
            .sort(sortByKeyAlphabeticallyLowercase('packName')),
        },
      };
    }

    case PackAssignmentActionTypes.UnassignPackSuccess: {
      const { patientId, contentPackHeaderId } =
        action.payload.deletedAssignment;
      if (+state.currentPatientId === +patientId) {
        const assignedPacks =
          state.allPatientAssignedContent.assignedPacks.filter(
            (pack) => +pack.packId !== +contentPackHeaderId,
          );
        return {
          ...state,
          allPatientAssignedContent: {
            ...state.allPatientAssignedContent,
            assignedPacks,
          },
        };
      } else {
        return state;
      }
    }

    case PackAssignmentActionTypes.RemoveExclusionSuccess: {
      const { packId, packDetail, remainingExclusions } = action.payload;
      const detailToInsert = packDetail.filter(
        (p) => !remainingExclusions.includes(p.contentEntryHeaderId),
      );
      const assignedPacks = state.allPatientAssignedContent.assignedPacks.map(
        (p) => {
          if (+p.packId === +packId) {
            const contents = p.contents
              .concat(detailToInsert)
              .sort(sortByKeyAlphabeticallyLowercase('contentName'));
            return {
              ...p,
              contents,
            };
          } else {
            return p;
          }
        },
      );
      return {
        ...state,
        allPatientAssignedContent: {
          ...state.allPatientAssignedContent,
          assignedPacks,
        },
      };
    }

    // Default
    default: {
      return state;
    }
  }
}

// Selectors
export const getActiveContentType = (state: State) => state.activeContentType;
export const getContentEntries = (state: State) => state.contentEntries;
export const getContentTypes = (state: State) =>
  filterAndSortCategories(state.contentTypes);
export const getPatientOnlyContentTypeId = (state: State) =>
  state.patientOnlyContentTypeId;
export const getContentSelect = (state: State) => state.contentSelect;
export const getAssignedContent = (state: State) => state.assignedContent;
export const getLoading = (state: State) => state.loading;
export const getPatientOnlyContent = (state: State) =>
  state.patientOnlyAssignedContent;
export const getContentTab = (state: State) => state.contentTab;
export const viewAsPatient = (state: State) => state.viewAsPatient;
export const getPatientViewOfContent = (state: State) =>
  state.patientViewOfContent;
export const viewAsPatientOpenFolder = (state: State) =>
  state.viewAsPatientOpenFolder;
export const getPatientAssignedContent = (state: State) =>
  state.allPatientAssignedContent.assignedContent;
export const getPatientAssignedPacks = (state: State) =>
  state.allPatientAssignedContent.assignedPacks;
export const loadingPatientViewOfContent = (state: State) =>
  state.loading.patientView;
export const loadingPatientAssigned = (state: State) =>
  state.loading.allPatientAssigned;
export const isClinicViewingAssignedPacks = (state: State) =>
  state.clinicViewAccordion === ClinicViewAccordionOption.PACKS;
export const isClinicViewingAssignedItems = (state: State) =>
  state.clinicViewAccordion === ClinicViewAccordionOption.ITEMS;
