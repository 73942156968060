import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
var DeviceService = /** @class */ (function () {
    function DeviceService() {
        var _this = this;
        this.deviceBroadcast = new ReplaySubject();
        if (navigator && navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = function (_) {
                _this.deviceBroadcast.next(_this.getDeviceOptions());
            };
        }
        this.$devicesUpdated = this.deviceBroadcast.asObservable();
        this.deviceBroadcast.next(this.getDeviceOptions());
    }
    DeviceService.prototype.isGrantedMediaPermissions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result_1, isGranted, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(navigator && navigator['permissions'])) return [3 /*break*/, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, navigator['permissions'].query({ name: 'camera' })];
                    case 2:
                        result_1 = _a.sent();
                        if (!result_1) return [3 /*break*/, 5];
                        if (!(result_1.state === 'granted')) return [3 /*break*/, 3];
                        return [2 /*return*/, true];
                    case 3: return [4 /*yield*/, new Promise(function (resolve) {
                            result_1.onchange = function (_) {
                                var granted = _.target['state'] === 'granted';
                                if (granted) {
                                    resolve(true);
                                }
                            };
                        })];
                    case 4:
                        isGranted = _a.sent();
                        return [2 /*return*/, isGranted];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        // This is only currently supported in Chrome.
                        // https://stackoverflow.com/a/53155894/2410379
                        return [2 /*return*/, true];
                    case 7: return [2 /*return*/, false];
                }
            });
        });
    };
    DeviceService.prototype.getDeviceOptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isGranted, devices;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isGrantedMediaPermissions()];
                    case 1:
                        isGranted = _a.sent();
                        if (!(navigator && navigator.mediaDevices && isGranted)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.tryGetDevices()];
                    case 2:
                        devices = _a.sent();
                        if (!devices.every(function (d) { return !d.label; })) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.tryGetDevices()];
                    case 3:
                        devices = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, devices];
                    case 5: return [2 /*return*/, null];
                }
            });
        });
    };
    DeviceService.prototype.tryGetDevices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mediaDevices, devices;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
                    case 1:
                        mediaDevices = _a.sent();
                        devices = ['audioinput', 'audiooutput', 'videoinput'].reduce(function (options, kind) {
                            return (options[kind] = mediaDevices.filter(function (device) { return device.kind === kind; }));
                        }, []);
                        return [2 /*return*/, devices];
                }
            });
        });
    };
    DeviceService.ngInjectableDef = i0.defineInjectable({ factory: function DeviceService_Factory() { return new DeviceService(); }, token: DeviceService, providedIn: "root" });
    return DeviceService;
}());
export { DeviceService };
