import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NewMessageModalProps } from '@react/pages/conversations/components';
import { CalendarEvent } from 'angular-calendar';
import { format, parseISO } from 'date-fns';
import { combineLatest, Observable, of, Subscription, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NewVideoCallComponent } from 'src/app/core/containers/new-video-call/new-video-call.component';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { ScriptInjectorService } from 'src/app/core/services/script-injector.service';
import * as fromAuth from '../../../auth/reducers';
import { DiscardMessagePromptReactComponent } from '../../../core/containers/new-message/discard-message-prompt.component';
import { NewMessageReactComponent } from '../../../core/containers/new-message/new-message-react.component';
import * as fromMessages from '../../../messaging/reducers';
import { Message } from '../../../models/Message';
import { SimplePatient } from '../../../models/SimplePatient';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as ContentActions from '../../actions/content-assignment.actions';
import * as PatientsActions from '../../actions/patient.actions';
import { PatientSection } from '../../components/patient-info/patient-info.component';
import { ContactInfo } from '../../models/ContactInfo';
import { LabResult } from '../../models/LabResult';
import { PartnerInfo } from '../../models/PartnerInfo';
import { PatientInfo } from '../../models/PatientInfo';
import { ClinicPatientResponse } from '../../models/responses/clinic-patient.response';
import { Section } from '../../models/Section';
import { TopLevelPatientData } from '../../models/TopLevelPatientData';
import { Auth } from 'aws-amplify';
import * as fromPatients from '../../reducers';
import { environment } from 'src/environments/environment';

export interface MyEvent extends CalendarEvent {
  incrementsBadgeTotal?: boolean;
}

@Component({
  selector: 'portal-patients-detail',
  templateUrl: './patients-detail.component.html',
  styleUrls: ['./patients-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientsDetailComponent implements OnInit, OnDestroy {
  @ViewChild('patientCard', { read: ElementRef })
  private messageContainer: ElementRef;
  private patientInfoText: any;

  private newMessageDialogRef: MatDialogRef<
    NewMessageReactComponent,
    MatDialogConfig
  > = null;

  // general observables
  public patientInfoText$: Observable<any>;
  public patientCalendarText$: Observable<any>;
  public patientLoading$: Observable<boolean>;
  public patientId$: Observable<number>;
  public activePatient$: Observable<ClinicPatientResponse>;
  public activeSection$: Observable<string>;
  public isCoreClinic$: Observable<boolean>;
  public loggedInToken$: Observable<string>;
  public medicationManagerLoaded$: Observable<boolean>;
  public medicationManagerEnabled$: Observable<boolean>;

  public labResultsEnabled$: Observable<boolean>;

  // For left most section of patient detail
  public topLevelPatientData$: Observable<TopLevelPatientData>;

  // Items for patient detail sections
  public patientInfo$: Observable<PatientInfo>;
  public partnerInfo$: Observable<PartnerInfo>;
  public contactInfo$: Observable<ContactInfo>;
  public labResults$: Observable<LabResult[]>;

  // Simple patient info to pass to outbound messaging
  public selectedPatient: SimplePatient;

  public calendarText: any;

  public viewDate: Date = new Date();

  // messages
  public messages$: Observable<Message[]>;
  public messages: Message[];

  // For sections list displayed in patient detail section
  public sectionsToShow$: Observable<Section[]>;
  public sections$: Observable<Section[]> = of([
    {
      type: PatientSection.patientInformation,
      Name: 'PatientInfo',
      isCore: true
    },
    {
      type: PatientSection.partner,
      Name: 'Partner',
      isCore: false
    },
    {
      type: PatientSection.contact,
      Name: 'Contact',
      isCore: true
    },
    {
      type: PatientSection.labResults,
      Name: 'LabResults',
      isCore: false
    },
    {
      type: PatientSection.manageContent,
      Name: 'ManageContent',
      isCore: true
    },
    {
      type: PatientSection.patientDocuments,
      Name: 'PatientDocuments',
      isCore: true
    }
  ]);

  // Items for the calendar component
  public events$: Observable<MyEvent[]>;

  // Options
  public videoCallsEnabled$: Observable<boolean>;
  public internalClinicId$: Observable<string>;
  public clinicGroupId$: Observable<string>;

  // Component Subscriptions
  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    public dialog: MatDialog,
    private _localisationService: LocalisationService,
    private _scriptInjectorService: ScriptInjectorService,
    private _route: ActivatedRoute
  ) {
    this._route.queryParams.subscribe((params) => {
      if (params.date) {
        this.viewDate = new Date(params.date);
      }
    });
    this.medicationManagerLoaded$ = from(
      _scriptInjectorService
        .loadScript(
          'salve-medication-template-picker',
          environment.medicationManagerUrl
        )
        .then(() => true)
        .catch(() => false)
    );
    this.loggedInToken$ = from(
      Auth.currentSession().then((session) => {
        const token = session.getIdToken().getJwtToken();
        return token;
      })
    );

    // General
    // ================================================
    this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
    this.labResultsEnabled$ = this._store.pipe(
      select(fromAuth.getLabResultsEnabled)
    );
    this.patientInfoText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('PatientInfo'))
    );
    this.patientCalendarText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('PatientCalendar'))
    );
    this._subs.add(
      this.patientCalendarText$.subscribe((t) => (this.calendarText = t))
    );

    this.patientLoading$ = this._store.pipe(
      select(fromPatients.isPatientLoading)
    );

    this.sectionsToShow$ = combineLatest(
      this.sections$,
      this.isCoreClinic$,
      this.labResultsEnabled$
    ).pipe(
      map(([sections, isCore, labResultsEnabled]) => {
        // initially filter based on whether clinic is Core
        const initial = isCore ? sections.filter((s) => s.isCore) : sections;
        // filter - skip section check if it's not lab result, else check show value
        const filtered = initial.filter((s) => {
          const skipSection = s.Name !== 'LabResults';
          const result = skipSection || (!skipSection && labResultsEnabled);
          return result;
        });
        return filtered;
      })
    );

    this.patientId$ = this._store.pipe(select(fromRoot.getRouterPatientId));
    this.activeSection$ = this._store.pipe(
      select(fromPatients.getActiveSection)
    );
    this.activePatient$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      })
    );
    this.topLevelPatientData$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      }),
      map((patient) => {
        if (patient) {
          // assign patient data to selectedPatient for use in outbound
          this.selectedPatient = {
            Id: patient.Patient.Id,
            FirstName: patient.Patient.FirstName,
            LastName: patient.Patient.LastName,
            PatientIdentifier: patient.Patient.PatientIdentifier,
            DateOfBirth: patient.Patient.DateOfBirth
          };
          return {
            id: patient.Patient.Id,
            name: `${patient.Patient.FirstName} ${patient.Patient.LastName}`,
            patientIdentifier: patient.Patient.PatientIdentifier
          };
        }
      })
    );

    this.messages$ = this._store.pipe(select(fromMessages.getMessageList));
    this.messages$.subscribe((messages) => (this.messages = [...messages]));

    // For Patient Detail Sections
    // ================================================
    this.patientInfo$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      }),
      map((patient) => {
        if (patient) {
          const p = patient.Patient;
          return {
            id: p.Id,
            firstName: p.FirstName,
            lastName: p.LastName,
            dateOfBirth: p.DateOfBirth,
            age: p.Age,
            numberOfCycles: p.NumberOfCycles,
            patientIdentifier: p.PatientIdentifier,
            patientAccountCreated: p.PatientAccountCreatedDateUtc,
            latestLogin: p.LatestLoginUtc,
            clinicPhysician: p.ClinicPhysician,
            assignedProfessionals: p.AssignedProfessionals
          };
        }
      })
    );
    this.partnerInfo$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      }),
      map((patient) => {
        if (patient) {
          const p = patient.Patient;
          if (
            p.PartnerFirstName ||
            p.PartnerLastName ||
            p.PartnerDateOfBirth ||
            p.PartnerAge
          ) {
            return {
              firstName: p.PartnerFirstName,
              lastName: p.PartnerLastName,
              dateOfBirth: p.PartnerDateOfBirth,
              age: p.PartnerAge
            };
          } else {
            return null;
          }
        }
      })
    );
    this.contactInfo$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      }),
      map((patient) => {
        if (patient) {
          const p = patient.Patient;
          const patientInfo: ContactInfo = {
            email: p.Email || ' '
          };
          if (p.PhoneWork || p.MobileWork) {
            patientInfo.workPhones = {
              mobile: p.MobileWork || ' ',
              landline: p.PhoneWork || ' '
            };
          }
          if (p.PhonePrivate || p.MobilePrivate) {
            patientInfo.personalPhones = {
              mobile: p.MobilePrivate || ' ',
              landline: p.PhonePrivate || ' '
            };
          }

          return patientInfo;
        }
      })
    );

    // For Patient Lab Results
    // ================================================

    this.labResults$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      }),
      map((patient) => {
        if (patient) {
          return [
            ...patient.LabTests.map((r) => {
              return {
                Date: `${this._localisationService.getShortDateWithTime(
                  r.Date
                )}`,
                ResultDate: `${this._localisationService.getShortDate(
                  r.ResultDate
                )}`,
                Investigation: r.Investigation,
                Remarks: r.Remarks,
                Result: r.Result === null ? null : r.Result.toString(),
                Unit: r.Unit,
                ResultText: r.ResultText,
                ReferenceRange: r.ReferenceRange
              };
            })
          ];
        }
      })
    );

    // For Patient Calendar
    // ================================================
    this.events$ = this.patientId$.pipe(
      switchMap((id) => {
        return this._store.pipe(select(fromPatients.getPatientById(id)));
      }),
      map((patient) => {
        if (patient) {
          return [
            ...patient.Medication.map((m) => ({
              start: new Date(m.DueDate),
              end: new Date(m.DueDate),
              title:
                `${this._localisationService.getShortDateWithTime(
                  m.DueDate
                )} -` +
                m.DrugName +
                ' (' +
                m.Dosage +
                m.DosageUnit +
                ')',
              incrementsBadgeTotal: !m.Taken,
              cssClass: `medicine ${m.Taken ? 'taken' : 'not-taken'}`
            })),
            ...patient.Appointments.map((a) => {
              const localisedStartDate =
                this._localisationService.getShortDateWithTime(a.StartDate);
              return {
                start: new Date(a.StartDate),
                title: a.IsProvisional
                  ? this.calendarText.EventLabelAppointmentProvisional(
                      localisedStartDate,
                      a.AppointmentType
                    )
                  : this.calendarText.EventLabelAppointment(
                      localisedStartDate,
                      a.AppointmentType
                    ),
                cssClass: `appointment ${
                  a.IsProvisional ? 'provisional' : 'not-provisional'
                }
                ${a.Active && a.DisplayToPatient ? '' : 'hidden'}`,
                isProvisional: a.IsProvisional
              };
            }),
            ...patient.VideoCalls.map((c) => ({
              start: new Date(c.scheduleddate),
              title: `${format(parseISO(c.scheduleddate), 'HH:mm - ')} ${
                c.description
              } (${c.host}) (${c.duration} ${this.patientInfoText.Minutes})`,
              cssClass: 'video-call'
            }))
          ];
        }
      })
    );

    // For options
    this.videoCallsEnabled$ = this._store.pipe(
      select(fromAuth.getVideoCallsEnabled)
    );
    this.internalClinicId$ = this._store.pipe(
      select(fromAuth.getInternalClinicId)
    );
    this.clinicGroupId$ = this._store.pipe(select(fromAuth.getClinicGroupId));

    this.medicationManagerEnabled$ = this._store.pipe(
      select(fromAuth.medicationManagerEnabled)
    );
  }

  ngOnInit() {
    this._store.dispatch(new PatientsActions.LoadingPatientInformation());

    this._subs.add(
      this.patientId$.subscribe((id) => {
        if (id) {
          this._store.dispatch(new ContentActions.SetPatientId(id));
          this._store.dispatch(new PatientsActions.LoadPatientInformation(id));
        }
      })
    );

    this._subs.add(
      this.patientInfoText$.subscribe((t) => (this.patientInfoText = t))
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public changeActiveSection(section: string) {
    this._store.dispatch(new PatientsActions.ChangeActiveSection(section));
    if (section === 'patientDocuments') {
      this._store.dispatch(new ContentActions.SetContentTypeToPatientDocs());
    }
    if (section === 'manageContent') {
      this._store.dispatch(new ContentActions.SetContentTypeToNonPatientDocs());
    }
  }

  private openDiscardMessageConfirmationPrompt() {
    const dialogRef = this.dialog.open(DiscardMessagePromptReactComponent, {
      panelClass: 'reduced-padding-dialog',
      maxWidth: '100%',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        onDiscard: () => this.dialog.closeAll(),
        onCancel: () => dialogRef.close()
      }
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  private closeNewMessage: NewMessageModalProps['onClose'] = ({
    hasChanged
  }) => {
    if (hasChanged) {
      this.openDiscardMessageConfirmationPrompt();
      return;
    }

    this.newMessageDialogRef.close();
  };

  public openNewMessage(): void {
    this.newMessageDialogRef = this.dialog.open(NewMessageReactComponent, {
      panelClass: 'reduced-padding-dialog',
      maxWidth: '100%',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        patient: this.selectedPatient,
        close: this.closeNewMessage
      }
    });

    this.newMessageDialogRef.afterClosed().subscribe(() => {});
  }

  public openVideoCall(): void {
    const dialogSize = this._getDialogSize();
    const dialogRef = this.dialog.open(NewVideoCallComponent, {
      panelClass: 'reduced-padding-dialog',
      width: `${dialogSize.width}vw`,
      height: '500px',
      autoFocus: false,
      closeOnNavigation: true,
      data: { patient: this.selectedPatient },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((val) => {});
  }

  private _getDialogSize() {
    const target = this.messageContainer.nativeElement;
    const screenWidth = target.scrollWidth;
    let width = 65;

    if (screenWidth > 1100) {
      width = 50;
    } else if (screenWidth > 900) {
      width = 65;
    }

    return {
      width
    };
  }
}
