import get from 'lodash/get';

export const checkFeature = (
  features: object,
  feature: string | ((features: Readonly<any>) => boolean),
) => {
  let hasFeature = false;
  if (typeof feature === 'string') {
    const featureResult = get(features, feature);

    hasFeature = typeof featureResult === 'boolean' && featureResult;
  } else {
    hasFeature = feature(features);
  }

  return hasFeature;
};
