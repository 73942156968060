import { EventEmitter, OnInit, } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FileUpload } from 'src/app/core/services/s3.service';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
var AttachmentsTableComponent = /** @class */ (function () {
    function AttachmentsTableComponent(_dialog, _s3) {
        this._dialog = _dialog;
        this._s3 = _s3;
        this.removeAttachment = new EventEmitter();
        this.displayedColumns = [
            'id',
            'documentname',
            'filetype',
            'filesize',
            'preview',
        ];
    }
    AttachmentsTableComponent.prototype.ngOnInit = function () {
        var attachment = this.attachments[0];
        if (attachment && attachment.isGlobalFile) {
            this.displayedColumns = ['id', 'documentname', 'filetype', 'filesize'];
        }
    };
    AttachmentsTableComponent.prototype.readFile = function (file) {
        this._s3
            .readFileFromBucket({
            url: file.uri,
        })
            .then(function (res) {
            window.open(res.url, '_blank');
        });
    };
    AttachmentsTableComponent.prototype.handleRemoveAttachment = function (id) {
        var _this = this;
        var dialog = this._dialog.open(ConfirmActionComponent, {
            data: {
                message: this.text.RemoveConfirm,
                text: {
                    Cancel: this.text.Cancel,
                    Confirm: this.text.Remove,
                },
            },
        });
        dialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this.removeAttachment.emit(id);
            }
        });
    };
    return AttachmentsTableComponent;
}());
export { AttachmentsTableComponent };
