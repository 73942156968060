import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslations } from '../i18n';
import { useErrorToast } from './hooks';
var CACHE_KEY = 'FILE_VIEWER';
var CACHE_TIME_MS = 5 * 60 * 1000; // 5 mins
export function useFileViewer() {
    var t = useTranslations().t;
    var s3Client = useApiClient().s3Proxy;
    var _a = useState(), fileUrl = _a[0], setFileUrl = _a[1];
    var _b = useState(false), openFileWhenLoaded = _b[0], setOpenFileWhenLoaded = _b[1];
    var result = useQuery([CACHE_KEY, fileUrl], 
    // tslint:disable-next-line: no-non-null-assertion
    function () { return s3Client.getSignedUrl(fileUrl); }, {
        cacheTime: CACHE_TIME_MS,
        enabled: Boolean(fileUrl),
        retry: false,
    });
    useErrorToast(result.isError, {
        title: t.General.OpenAttachmentFileErrorTitle,
        message: t.General.OpenAttachmentFileErrorMessage,
    });
    function previewFile(url) {
        setFileUrl(url);
        setOpenFileWhenLoaded(true);
    }
    useEffect(function () {
        if (!result.isLoading &&
            openFileWhenLoaded &&
            result.data &&
            result.data.url) {
            setOpenFileWhenLoaded(false);
            window.open(result.data.url, '_blank');
        }
    }, [result.isLoading, openFileWhenLoaded]);
    return tslib_1.__assign({ previewFile: previewFile }, result);
}
