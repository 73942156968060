import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import pick from 'lodash/pick';
import { useQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { filtersToApi } from '../lib';
export function useConversationsCountByStatusQuery(filters, q) {
    var apiClient = useApiClient().portal;
    var apiFilters = filtersToApi(tslib_1.__assign({}, filters, { q: q }));
    // Guard against extra filters being passed in.
    var trimmedFilters = pick(apiFilters, [
        'q',
        'messagetypeids',
        'isresolved',
        'staffids',
        'assignedids',
        'isunassigned',
    ]);
    var response = useQuery([queryCacheKey.CONVERSATIONS_COUNT_BY_STATUS, trimmedFilters], function () { return apiClient.fetchConversationsCountByStatus(trimmedFilters); }, {
        refetchInterval: POLLING_INTERVAL_MS,
    });
    if (response.data && response.data.data) {
        var items = response.data.data;
        return items.reduce(function (acc, item) {
            var _a;
            return (tslib_1.__assign({}, acc, (_a = {}, _a[item.name] = item.count, _a)));
        }, {});
    }
    return {};
}
