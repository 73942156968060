import { IconButton, InputAdornment, Omit, TextField } from '@material-ui/core';
import { Cancel as IconCancel, Search as IconSearch } from '@material-ui/icons';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { StyledPaper } from './SearchBar.styled';

type Props = Omit<React.ComponentProps<typeof StyledPaper>, 'onChange'> & {
  className?: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  endAdornment?: React.ReactNode;
};

export const SearchBar: FunctionComponent<Props> = ({
  className,
  value = '',
  onChange,
  placeholder,
  endAdornment,
  ...rest
}) => {
  function onCancel() {
    onChange('');
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const showClearButton = value.length > 0;

  const endAdornments = [
    showClearButton ? (
      <InputAdornment key="cancelAdornment" position="end">
        <IconButton onClick={onCancel} size="small" role="button">
          <IconCancel />
        </IconButton>
      </InputAdornment>
    ) : undefined,
    <React.Fragment key="endAdornment">{endAdornment}</React.Fragment>,
  ];

  return (
    <StyledPaper className={className} {...rest}>
      <TextField
        autoComplete="off"
        fullWidth
        inputProps={{ role: 'searchbox' }}
        InputProps={{
          disableUnderline: true,
          endAdornment: endAdornments,
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          ),
        }}
        onKeyDown={onKeyDown}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        spellCheck="false"
        type="search"
        value={value}
        variant="standard"
      />
    </StyledPaper>
  );
};

export default SearchBar;
