import { EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { DataState, MedicationStatus, MedicationType, } from './patient-list.component.enums';
var PatientListComponent = /** @class */ (function () {
    function PatientListComponent(_localisationService) {
        this._localisationService = _localisationService;
        this.loading = true;
        this.medicationSelected = new EventEmitter();
        this.patientList = {};
        this.searchControl = new FormControl();
        this.warning = false;
        this.searchTermChanged = new EventEmitter();
        this._searchDebounce = new Subject();
    }
    Object.defineProperty(PatientListComponent.prototype, "medications", {
        set: function (medications) {
            this.patientList = this._groupMeds(medications);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientListComponent.prototype, "state", {
        get: function () {
            if (this.patientList !== undefined &&
                Object.keys(this.patientList).length > 0) {
                return DataState.Results;
            }
            if (this.searchControl.value !== '' && this.searchControl.value !== null) {
                return DataState.NoSearchResults;
            }
            return DataState.NoResults;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PatientListComponent.prototype, "dataStatus", {
        get: function () {
            return DataState;
        },
        enumerable: true,
        configurable: true
    });
    PatientListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._searchDebounce
            .pipe(debounceTime(1000))
            .subscribe(function (searchTerm) { return _this.searchTermChanged.emit(searchTerm); });
        this.searchControl.valueChanges.subscribe(function (searchTerm) {
            return _this._searchDebounce.next(searchTerm);
        });
    };
    PatientListComponent.prototype.onRowSelected = function (selection) {
        this.medicationSelected.emit(selection);
    };
    PatientListComponent.prototype._groupMeds = function (meds) {
        var _this = this;
        var patients = {};
        if (meds == null) {
            return;
        }
        meds.forEach(function (med) {
            var name = (med.firstName + " " + med.lastName).toUpperCase();
            var dob = new Date(med.dateOfBirth);
            if (!patients[med.patientId]) {
                patients[med.patientId] = {
                    id: med.patientIdentifier,
                    name: name,
                    meds: [],
                    dob: dob,
                };
            }
            patients[med.patientId].meds.push({
                id: med.patientId,
                name: name,
                type: med.type,
                drug: med.drugName,
                dosage: _this.medicationType === MedicationType.Duplicate
                    ? "" + med.drugDosage + med.unit
                    : med.drugDosage,
                status: _this._getStatus(med),
                statusId: med.statusId,
                dob: dob,
                dueDate: new Date(med.dueDate),
            });
        });
        return patients;
    };
    PatientListComponent.prototype._getStatus = function (item) {
        switch (item.statusId) {
            case MedicationStatus.Taken:
                var adherenceDate = '';
                if (this._instanceOfIActionMedication(item)) {
                    if (item.adherenceDate !== null) {
                        adherenceDate = this._localisationService.formatTime(item.adherenceDate, 'short');
                    }
                }
                return this.text.TakenAt(adherenceDate, this._localisationService.formatTime(item.scheduledTime, 'short'));
            case MedicationStatus.DueToday:
                return this.text.DueToday;
            case MedicationStatus.DueAt:
                return this.text.DueAt(this._localisationService.formatTime(item.scheduledTime, 'short'));
            case MedicationStatus.WindowClosing:
                var now = new Date(item.now);
                var windowCloseTime = this._getWindowCloseTime(item.scheduledTime, now);
                var minutesRemaining = differenceInMinutes(windowCloseTime, now).toString();
                return this.text.WindowClosing(minutesRemaining);
            case MedicationStatus.Overdue:
                return this.text.Overdue(this._localisationService.formatTime(item.scheduledTime, 'short'));
            case MedicationStatus.PotentialDuplicate:
                return this.text.PotentialDuplicate(this._localisationService.formatDate(item.dueDate, 'short'), this._localisationService.formatTime(item.scheduledTime, 'short'));
        }
    };
    PatientListComponent.prototype._getWindowCloseTime = function (scheduledTime, now) {
        var windowCloseTime = addMinutes(new Date(scheduledTime), 60);
        // this is needed to stop windows from the past from giving negative numbers
        windowCloseTime.setFullYear(now.getFullYear());
        windowCloseTime.setMonth(now.getMonth());
        windowCloseTime.setDate(now.getDate());
        return windowCloseTime;
    };
    PatientListComponent.prototype._instanceOfIActionMedication = function (object) {
        return 'adherenceDate' in object;
    };
    return PatientListComponent;
}());
export { PatientListComponent };
