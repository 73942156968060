import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { sortByKeyAlphabetically } from 'src/app/shared/utils';
import { ConfirmActionComponent, } from '../../../core/components/confirm-action/confirm-action.component';
import { DialogWithDismissComponent, } from '../../../core/components/dialog-with-dismiss/dialog-with-dismiss.component';
import { LoadingDialogComponent, } from '../../../core/components/loading-dialog/loading-dialog.component';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { ContentActionTypes, ContentEntryPageUnload, CopyGlobalContent, CopyGlobalContentError, CopyGlobalContentSuccessAdditionalTranslations, CopyGlobalContentSuccessMissingTranslations, CopyGlobalFileContent, CreateNewContentEntry, DeleteEntry, GetContentEntry, } from '../../actions/content.actions';
import { ContentEntry, } from '../../models/content-entry';
import * as fromContent from '../../reducers';
import { ContentService } from '../../services/content.service';
var DEFAULT_LANGUAGE_STRING = 'default';
var ContentEditComponent = /** @class */ (function () {
    function ContentEditComponent(_store, _route, _updates$, _navigationService, _contentService, dialog) {
        var _this = this;
        this._store = _store;
        this._route = _route;
        this._updates$ = _updates$;
        this._navigationService = _navigationService;
        this._contentService = _contentService;
        this.dialog = dialog;
        this.selectedLanguage$ = new BehaviorSubject(undefined);
        this.selectedTab$ = new BehaviorSubject(0);
        this.parentPortalId = null;
        this._subs = new Subscription();
        this._isLeaving = false;
        this._contentEntryStatuses$ = new BehaviorSubject({});
        this.trackContentEntryBy = function (index, item) { return item.key; };
        this.trackTabsBy = function (index, item) { return item[0]; };
        // Global Portal
        this._contentService.getGlobalPortal().subscribe(function (res) {
            if (res && res.globalclinicid) {
                _this.parentPortalId = res.globalclinicid;
            }
            return;
        });
        // Content editor group Observable
        this.contentEditorEnabled$ = this._store.pipe(select(fromContent.getContentEditorEnabled));
        this.clinicLanguages$ = this._store.pipe(select(fromRoot.getLanguages), map(function (languages) { return languages.sort(sortByKeyAlphabetically('name')); }));
        this.defaultClinicLanguage$ = this._store.pipe(select(fromRoot.getDefaultLanguage));
        this.contentEntries$ = this._store.pipe(select(fromContent.getActiveContent));
        this.contentTemplates$ = this._store.pipe(select(fromContent.getTemplates));
        this.controlsText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorControls')));
        this._subs.add(this._store
            .pipe(select(fromSettings.getSectionTranslations('NewContentList')))
            .subscribe(function (t) {
            _this.newContentListText = t;
        }));
        this._subs.add(this._store.pipe(select(fromRoot.getLanguages)).subscribe(function (t) {
            _this.clinicLanguages = t;
        }));
        this._subs.add(this._store.pipe(select(fromRoot.getDefaultLanguage)).subscribe(function (t) {
            _this.defaultClinicLanguage = t;
        }));
        this.contentCategories$ = this._store.pipe(select(fromContent.getCategories));
        this.loadingContentEntry$ = this._store.pipe(select(fromContent.isLoadingContentEntry));
        this.loadingContentEntryError$ = this._store.pipe(select(fromContent.hasLoadingContentEntryError));
        this._subs.add(this.contentEditorEnabled$.subscribe(function (t) { return (_this.contentEditorEnabled = t); }));
        this.tabs$ = combineLatest(this.contentEntries$, this.clinicLanguages$, this._contentEntryStatuses$).pipe(map(function (_a) {
            var contentEntries = _a[0], languages = _a[1], contentEntryStatuses = _a[2];
            var defaultLanguage = languages.find(function (language) { return language.isdefault; });
            return Object.keys(contentEntries).map(function (contentEntry) {
                var name = contentEntry === 'default'
                    ? defaultLanguage.name
                    : languages.find(function (language) { return language.code === contentEntry; })
                        .name;
                return [name, contentEntryStatuses[contentEntry] || 'PRISTINE'];
            });
        }));
        this.defaultContentEntry$ = this.contentEntries$.pipe(map(function (contentEntries) { return contentEntries['default']; }));
        this.selectedContentEntry$ = combineLatest(this.contentEntries$, this.selectedTab$, this.clinicLanguages$, this.defaultClinicLanguage$).pipe(map(function (_a) {
            var contentEntries = _a[0], selectedTab = _a[1], clinicLanguages = _a[2], defaultClinicLanguage = _a[3];
            var key = Object.keys(contentEntries)[selectedTab];
            // For single language clinics the first (default) is always selected
            if (clinicLanguages.length === 0) {
                return contentEntries[key];
            }
            _this.selectedLanguage$.next(clinicLanguages.find(function (l) { return l.code === key; }) ||
                defaultClinicLanguage);
            return contentEntries[key];
        }));
        this.availableTranslations$ = combineLatest(this.contentEntries$, this.clinicLanguages$, this.defaultClinicLanguage$).pipe(map(function (_a) {
            var contentEntries = _a[0], clinicLanguages = _a[1], defaultClinicLanguage = _a[2];
            if (defaultClinicLanguage == null) {
                return [];
            }
            var usedLanguages = Object.keys(contentEntries).map(function (contentEntry) {
                return contentEntry === 'default'
                    ? defaultClinicLanguage.code
                    : contentEntry;
            });
            return clinicLanguages
                .filter(function (clinicLanguage) {
                return usedLanguages.findIndex(function (usedLanguage) { return usedLanguage === clinicLanguage.code; }) === -1;
            })
                .map(function (clinicLanguage) { return ({
                languageCode: clinicLanguage.code,
                name: clinicLanguage.name,
            }); });
        }));
        this._subs.add(this.controlsText$.subscribe(function (t) { return (_this.controlsText = t); }));
        this._subs.add(this._route.params.subscribe(function (params) {
            if (params.id) {
                _this._resetState();
                _this._store.dispatch(new GetContentEntry(params.id));
            }
        }));
        this._subs.add(combineLatest(this._route.params, this.contentCategories$)
            .pipe(
        // Need to wait for Content Categories to load
        filter(function (_a) {
            var categories = _a[1];
            return categories.length > 0;
        }), take(1))
            .subscribe(function (_a) {
            var routeParams = _a[0], categories = _a[1];
            if (routeParams.id) {
                return;
            }
            // content is new, create new entry on the fly
            var categoryId = +routeParams.categoryid;
            var type = routeParams.type;
            var selectedCategory = categories.find(function (c) { return c.id === categoryId; }).name;
            _this._store.dispatch(new CreateNewContentEntry({
                languageCode: 'default',
                contentEntry: ContentEntry.createNew(selectedCategory, categoryId, type === 'pdf'),
            }));
        }));
        this._subs.add(this.defaultContentEntry$.subscribe(function (defaultContentEntry) {
            _this._defaultContentEntry = defaultContentEntry;
        }));
    }
    ContentEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subs.add(this._store
            .pipe(select(fromContent.getIsSavingContentEntry))
            .subscribe(function (isSavingContentEntry) {
            if (isSavingContentEntry) {
                _this._loadingDialog = _this.dialog.open(LoadingDialogComponent, {
                    data: {
                        loadingText: _this.controlsText.PublishingContent,
                    },
                    width: '300px',
                });
            }
            else {
                if (_this._loadingDialog != null) {
                    _this._loadingDialog.close();
                    _this._loadingDialog = null;
                }
            }
        }));
        // When the main (default) Content Entry has been deleted then go back to the
        // list for this same category.
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.DeleteEntrySuccess))
            .subscribe(function (action) {
            _this._isLeaving = true;
            if (action.payload.languageCode === 'default') {
                _this._navigationService.navigate([
                    'content',
                    'list',
                    action.payload.contentEntry.contentcategoryid,
                ]);
            }
        }));
        // When there is only one language then go back to the
        // list for this same category.
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.CreateEntrySuccess, ContentActionTypes.SaveEntrySuccess), withLatestFrom(this.clinicLanguages$))
            .subscribe(function (_a) {
            var action = _a[0], clinicLanguages = _a[1];
            if (clinicLanguages.length === 0) {
                _this._isLeaving = true;
                _this._navigationService.navigate([
                    'content',
                    'list',
                    action.payload.contentEntry.contentcategoryid,
                ]);
            }
        }));
    };
    ContentEditComponent.prototype.ngOnDestroy = function () {
        this._store.dispatch(new ContentEntryPageUnload());
        this._subs.unsubscribe();
    };
    ContentEditComponent.prototype.tabChanged = function (e) {
        this.selectedTab$.next(e);
    };
    ContentEditComponent.prototype.addTranslation = function (languageCode) {
        this._store.dispatch(new CreateNewContentEntry({
            languageCode: languageCode,
            contentEntry: ContentEntry.createNew(this._defaultContentEntry.contentcategory, this._defaultContentEntry.contentcategoryid, this._defaultContentEntry.isfileonly, this._defaultContentEntry.parentid),
        }));
    };
    ContentEditComponent.prototype.canDeactivate = function () {
        // The default Content Entry has been deleted so no need to
        // warn of any changes.
        if (this._isLeaving) {
            return true;
        }
        var hasChanges = Object.values(this._contentEntryStatuses$.value).some(function (status) { return status === 'DIRTY'; });
        if (!hasChanges) {
            return true;
        }
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.controlsText.ExitConfirm,
                text: {
                    Cancel: this.controlsText.Cancel,
                    Confirm: this.controlsText.Leave,
                },
            },
        });
        return confirmDialog.afterClosed();
    };
    ContentEditComponent.prototype.delete = function () {
        var _this = this;
        var selectedLanguage = this.selectedLanguage$.value;
        if (selectedLanguage == null) {
            return;
        }
        var isDefaultContent = selectedLanguage.isdefault;
        // Prevent linked content being deleted
        if (isDefaultContent) {
            var isLinked = (this._defaultContentEntry.children || []).length > 0 ||
                this._defaultContentEntry.parentid != null;
            if (isLinked) {
                var deleteItemsDialog = this.dialog.open(DialogWithDismissComponent, {
                    data: {
                        message: this.newContentListText.DeleteItemBlock(this._defaultContentEntry.name),
                        text: {
                            Dismiss: this.newContentListText.OK,
                        },
                    },
                });
                return;
            }
        }
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                information: isDefaultContent
                    ? this.controlsText.DeleteDefaultConfirmInformation
                    : this.controlsText.DeleteConfirmInformation,
                message: isDefaultContent
                    ? this.controlsText.DeleteDefaultConfirmMessage
                    : this.controlsText.DeleteConfirmMessage,
                text: {
                    Cancel: this.controlsText.Cancel,
                    Confirm: this.controlsText.Delete,
                },
            },
        });
        this._subs.add(confirmDialog
            .afterClosed()
            .pipe(withLatestFrom(this.selectedContentEntry$))
            .subscribe(function (_a) {
            var confirmResult = _a[0], selectedContentEntry = _a[1];
            if (confirmResult) {
                _this._store.dispatch(new DeleteEntry({
                    contentEntry: selectedContentEntry,
                    languageCode: selectedLanguage.isdefault
                        ? 'default'
                        : selectedLanguage.code,
                }));
            }
        }));
    };
    ContentEditComponent.prototype.statusChange = function (languageCode, status) {
        var _this = this;
        // A slight timeout is needed as Angular complains about tracked
        // bindings changing in development.
        setTimeout(function () {
            var _a;
            _this._contentEntryStatuses$.next(tslib_1.__assign({}, _this._contentEntryStatuses$.value, (_a = {}, _a[languageCode] = status, _a)));
        }, 0);
    };
    ContentEditComponent.prototype._resetState = function () {
        this._contentEntryStatuses$.next({});
    };
    ContentEditComponent.prototype.copyGlobalContent = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, defaultLanguageCode_1, languageCodes, availableCopyLanguages_1, availableLanguagesWithoutDefault_1, missingLanguages, additionalLanguages, sections, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._contentService
                                .getGlobalContentEntryById(this.parentPortalId, id)
                                .toPromise()];
                    case 1:
                        res = _a.sent();
                        defaultLanguageCode_1 = this.defaultClinicLanguage.code;
                        languageCodes = Object.values(this.clinicLanguages).map(function (language) { return language.code; });
                        availableCopyLanguages_1 = Object.keys(res);
                        availableLanguagesWithoutDefault_1 = [
                            DEFAULT_LANGUAGE_STRING
                        ].concat(languageCodes).filter(function (value) { return value !== defaultLanguageCode_1; });
                        missingLanguages = availableLanguagesWithoutDefault_1.filter(function (value) { return !availableCopyLanguages_1.includes(value); });
                        additionalLanguages = availableCopyLanguages_1.filter(function (value) { return !availableLanguagesWithoutDefault_1.includes(value); });
                        // Create new translation content entries in Redux, and overwrite any already created.
                        Object.entries(res).forEach(function (_a) {
                            var key = _a[0], value = _a[1];
                            if (key !== DEFAULT_LANGUAGE_STRING &&
                                availableLanguagesWithoutDefault_1.includes(key)) {
                                _this._store.dispatch(new CreateNewContentEntry({
                                    languageCode: key,
                                    contentEntry: ContentEntry.createCopy(_this._defaultContentEntry.contentcategory, _this._defaultContentEntry.contentcategoryid, _this._defaultContentEntry.isfileonly, _this._defaultContentEntry.parentid, value.name),
                                }));
                            }
                        });
                        sections = Object.entries(res).reduce(function (agg, _a) {
                            var key = _a[0], value = _a[1];
                            var _b;
                            if (availableLanguagesWithoutDefault_1.includes(key)) {
                                return tslib_1.__assign({}, agg, (_b = {}, _b[key] = tslib_1.__assign({}, (agg[key] && agg[key]), { name: value.name, sections: value.sections }), _b));
                            }
                            return agg;
                        }, {});
                        // Dispatch section (content data) for each translation (including the default) to populate the new content fields.
                        this._store.dispatch(new CopyGlobalContent(sections));
                        this.statusChange(DEFAULT_LANGUAGE_STRING, 'DIRTY');
                        // Dispatch any user warning for missing or additional translations.
                        if (missingLanguages.length) {
                            this._store.dispatch(new CopyGlobalContentSuccessMissingTranslations(missingLanguages.join(', ').toUpperCase()));
                        }
                        if (additionalLanguages.length) {
                            this._store.dispatch(new CopyGlobalContentSuccessAdditionalTranslations(additionalLanguages.join(', ').toUpperCase()));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this._store.dispatch(new CopyGlobalContentError(e_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ContentEditComponent.prototype.copyGlobalFileContent = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, defaultLanguageCode_2, languageCodes, availableCopyLanguages_2, availableLanguagesWithoutDefault_2, missingLanguages, additionalLanguages, content, e_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._contentService
                                .getGlobalContentEntryById(this.parentPortalId, id)
                                .toPromise()];
                    case 1:
                        res = _a.sent();
                        defaultLanguageCode_2 = this.defaultClinicLanguage.code;
                        languageCodes = Object.values(this.clinicLanguages).map(function (language) { return language.code; });
                        availableCopyLanguages_2 = Object.keys(res);
                        availableLanguagesWithoutDefault_2 = [
                            DEFAULT_LANGUAGE_STRING
                        ].concat(languageCodes).filter(function (value) { return value !== defaultLanguageCode_2; });
                        missingLanguages = availableLanguagesWithoutDefault_2.filter(function (value) { return !availableCopyLanguages_2.includes(value); });
                        additionalLanguages = availableCopyLanguages_2.filter(function (value) { return !availableLanguagesWithoutDefault_2.includes(value); });
                        // Create new translation content entries in Redux, and overwrite any already created.
                        Object.entries(res).forEach(function (_a) {
                            var key = _a[0], value = _a[1];
                            if (key !== DEFAULT_LANGUAGE_STRING &&
                                availableLanguagesWithoutDefault_2.includes(key)) {
                                _this._store.dispatch(new CreateNewContentEntry({
                                    languageCode: key,
                                    contentEntry: ContentEntry.createCopy(_this._defaultContentEntry.contentcategory, _this._defaultContentEntry.contentcategoryid, _this._defaultContentEntry.isfileonly, _this._defaultContentEntry.parentid, value.name),
                                }));
                            }
                        });
                        content = {};
                        // Setup sections for content
                        content = Object.entries(res).reduce(function (agg, _a) {
                            var key = _a[0], value = _a[1];
                            var _b;
                            if (availableLanguagesWithoutDefault_2.includes(key)) {
                                return tslib_1.__assign({}, agg, (_b = {}, _b[key] = tslib_1.__assign({}, (agg[key] && agg[key]), { name: value.name, sections: value.sections }), _b));
                            }
                            return agg;
                        }, content);
                        // Setup attachments
                        content = Object.entries(res).reduce(function (agg, _a) {
                            var key = _a[0], value = _a[1];
                            var _b;
                            if (availableLanguagesWithoutDefault_2.includes(key)) {
                                return tslib_1.__assign({}, agg, (_b = {}, _b[key] = tslib_1.__assign({}, (agg[key] && agg[key]), { attachments: value.attachments.map(function (attachment) { return (tslib_1.__assign({}, attachment, { isGlobalFile: true })); }) }), _b));
                            }
                            return agg;
                        }, content);
                        // Dispatch section (content data) for each translation (including the default) to populate the new content fields.
                        this._store.dispatch(new CopyGlobalFileContent(content));
                        this.statusChange(DEFAULT_LANGUAGE_STRING, 'DIRTY');
                        // Dispatch any user warning for missing or additional translations.
                        if (missingLanguages.length) {
                            this._store.dispatch(new CopyGlobalContentSuccessMissingTranslations(missingLanguages.join(', ').toUpperCase()));
                        }
                        if (additionalLanguages.length) {
                            this._store.dispatch(new CopyGlobalContentSuccessAdditionalTranslations(additionalLanguages.join(', ').toUpperCase()));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        this._store.dispatch(new CopyGlobalContentError(e_2));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ContentEditComponent;
}());
export { ContentEditComponent };
