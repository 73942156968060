import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import { AuthGuard } from '../auth/services/auth-guard.service';
import { CognitoWrapperService } from '../core/services/congito.wrapper.service';
import { GlobalPortalGuard } from '../core/services/global-guard.service';
import { SuperAdminComponent } from './superadmin.component';

@NgModule({
  declarations: [SuperAdminComponent],
  imports: [
    StoreModule,
    RouterModule.forChild([
      {
        path: 'superadmin',
        children: [
          {
            path: '**',
            canActivate: [AuthGuard, GlobalPortalGuard],
            component: SuperAdminComponent,
          },
        ],
      },
    ]),
  ],
  providers: [CognitoWrapperService],
})
export class SuperAdminModule {}
