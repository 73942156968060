<div
  #contentList
  class="list-container"
  [class.patientonly]="patientOnly"
  fxLayout="column"
>
  <mat-toolbar *ngIf="!patientOnly" fxLayout="row" fxLayoutAlign="start center">
    {{ text.AvailableContent(folderName) }}
  </mat-toolbar>
  <mat-toolbar *ngIf="patientOnly" fxLayout="row" fxLayoutAlign="start center">
    {{ text.ReadyDocuments }}
  </mat-toolbar>
  <mat-toolbar
    class="search"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <form
      novalidate
      [formGroup]="searchForm"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <mat-icon>search</mat-icon>
      <input
        type="text"
        placeholder="{{ !patientOnly ? text.SearchContent : text.SearchFiles }}"
        id="searchValue"
        formControlName="searchValue"
      />
      <mat-icon *ngIf="filterActive" (click)="clearFilter()">clear</mat-icon>
    </form>
  </mat-toolbar>
  <perfect-scrollbar fxFlex="auto" [config]="config" [scrollIndicators]="true">
    <div *ngIf="!patientOnly" class="list">
      <div
        *ngIf="content && content.length === 0"
        class="empty"
        fxFlexFill
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        {{ text.NoContentToAssign }}
      </div>
      <div
        *ngFor="let entry of content"
        class="list-item add"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        [attr.disabled]="entry.isTransferring ? '' : null"
        (click)="onClickedAddAppContent(entry)"
      >
        <div
          *ngIf="entry.isTransferring"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="2px"
        >
          <mat-spinner diameter="20"></mat-spinner>
        </div>
        <div>
          {{ entry.contentName }}
        </div>
        <mat-icon>add</mat-icon>
      </div>
    </div>
    <div *ngIf="patientOnly" class="list">
      <div
        *ngFor="let entry of patientOnlyContent"
        class="list-item add"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        [attr.disabled]="entry.isTransferring ? '' : null"
        (click)="onClickedActivePatientOnlyFile(entry)"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div
            *ngIf="entry.isTransferring"
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="2px"
          >
            <mat-spinner diameter="20"></mat-spinner>
          </div>
          <mat-icon portalStopClickPropagation (click)="removeFile(entry)">
            delete_forever
          </mat-icon>
          <div>
            {{ entry.documentname }}
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <a portalStopClickPropagation (click)="viewFile(entry)">
            <mat-icon>cloud_download</mat-icon>
          </a>
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
<mat-toolbar
  *ngIf="patientOnly"
  class="trigger-file-upload"
  (click)="openFileDialog.emit()"
>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-around center">
    {{ text.UploadFile }}
    <mat-icon>cloud_upload</mat-icon>
  </div>
</mat-toolbar>
