<div class="searchbar" fxLayout="row" fxLayoutAlign="center center">
  <label>
    <mat-icon>search</mat-icon>
  </label>
  <input
    type="text"
    placeholder="{{ text.SearchPatients }}"
    [matAutocomplete]="patientDropdown"
    [formControl]="patientControl"
    angulartics2On="click"
    angularticsAction="Search Patients"
  />
  <mat-autocomplete
    #patientDropdown="matAutocomplete"
    (optionSelected)="patientSelected($event.option.value)"
  >
    <mat-option *ngFor="let patient of patients$ | async" [value]="patient">
      <div
        class="option-container"
        style="display: flex; flex-direction: column"
      >
        <div>
          {{ transformPatientNameIdToString(patient) }}
        </div>
        <div class="subtitle">
          {{ transformPatientDOBToString(patient) }}
        </div>
      </div>
    </mat-option>
    <div
      *ngIf="isLoading$ | async"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="spinner-container"
    >
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div
      style="height: 1px; margin: 0; padding: 0"
      intersection-observer
      [observeFirstChildElement]="false"
      observeMargin="200px"
      (observed)="triggerNextPage()"
    ></div>
  </mat-autocomplete>
  <button mat-icon-button *ngIf="searchActive" (click)="this.clearSearch()">
    <mat-icon>close</mat-icon>
  </button>
</div>
