import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import { SearchBar } from '@react/components';
export var StyledTableContainer = styled(Paper)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledSearchBar = styled(SearchBar)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n"])));
export var StyledActionsContainer = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  display: flex;\n  gap: ", "px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  display: flex;\n  gap: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var EmptyContainer = styled(Paper)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n  height: 400px;\n"], ["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  padding: ", "px;\n  height: 400px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var LoaderWrapper = styled.div(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"], ["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
