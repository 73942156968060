import { Typography } from '@material-ui/core';
import {
  Button,
  CharacterCount,
  InputLabel,
  TextInput,
} from '@react/components';
import {
  Container,
  StatusBar,
  TextArea,
  TextAreaContainer,
} from '@react/components/MessageEditor.styled';
import { StaffApiValue } from '@react/lib/api/types';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { useInviteClinicUserMutation } from '@react/pages/admin/hooks/users';
import { MESSAGE_MAX_LENGTH } from '@react/pages/conversations/constants';
import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { ButtonRow, StyledContainer } from './index.styled';

const clinicURL = location.origin;

export interface InviteUserProps {
  onClose: () => void;
  user: StaffApiValue;
}

const InviteUser: React.FunctionComponent<InviteUserProps> = ({
  onClose,
  user,
}) => {
  const { t } = useTranslations();
  const [message, setMessage] = useState(
    t.Admin.Users.InviteMessage(clinicURL),
  );

  const {
    mutateAsync: inviteClinicUser,
    isError: isInvitingError,
    isSuccess: isInvitingSuccess,
  } = useInviteClinicUserMutation();

  useErrorToast(isInvitingError, {
    title: t.Admin.Users.InviteSentErrorTitle,
    message: t.Admin.Users.InviteSentErrorMessage,
  });

  useSuccessToast(isInvitingSuccess, {
    title: t.Admin.Users.InviteSentTitle,
    message: t.Admin.Users.InviteSentMessage,
  });

  const handleMessageChange = useCallback<
    ChangeEventHandler<HTMLTextAreaElement>
  >(
    (e) => {
      setMessage(e.target.value);
    },
    [setMessage],
  );

  const handleClose = () => {
    onClose();
  };

  const onSendClicked = async () => {
    await inviteClinicUser({ staffId: user.id, message });
    onClose();
  };

  const isFormValid = () => {
    return validMessage;
  };

  const validMessage =
    message.length > 0 && message.length <= MESSAGE_MAX_LENGTH;

  return (
    <StyledContainer>
      <Typography className="newMessageModal-title" variant="subtitle1">
        {t.Admin.Users.InviteUser}
      </Typography>
      <div className="newMessageModal-input">
        <TextInput
          id="email"
          label={t.Admin.Users.User}
          fullWidth
          value={`${user.firstname} ${user.lastname} (${user.email})`}
          disabled
        />
      </div>
      <div className="newMessageModal-input">
        <InputLabel htmlFor="newMessageModal-content">
          {t.Admin.Users.WelcomeMessage}
        </InputLabel>
        <Container>
          <TextAreaContainer>
            <TextArea
              id="message"
              autoComplete="off"
              disableUnderline
              inputProps={{
                maxLength: MESSAGE_MAX_LENGTH,
              }}
              minRows={4}
              maxRows={6}
              multiline
              onChange={handleMessageChange}
              value={message}
            />
            <StatusBar>
              <span></span>
              <CharacterCount
                length={message.length}
                maxLength={MESSAGE_MAX_LENGTH}
              />
            </StatusBar>
          </TextAreaContainer>
        </Container>
      </div>

      <ButtonRow>
        <Button
          onClick={handleClose}
          color="secondary"
          size="small"
          variant="contained"
        >
          {t.Admin.Users.Cancel}
        </Button>

        <Button
          onClick={onSendClicked}
          color="primary"
          size="small"
          variant="contained"
          disabled={!isFormValid()}
        >
          {t.Admin.Users.Send}
        </Button>
      </ButtonRow>
    </StyledContainer>
  );
};

export default InviteUser;
