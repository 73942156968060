import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { AppointmentType } from '@react/lib/api/types';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useAppointmentTypesQuery } from '../hooks';
import {
  LoaderWrapper,
  StyledChip,
  StyledSearchBar,
  StyledTableContainer,
  StyledTableFooter,
  StyledTableHead,
  StyledTableRow
} from './AppointmentTypeList.styled';

type ChipRule = Array<[boolean, string]>;

const AppointmentRuleChip: FunctionComponent<{
  visible: boolean;
  label: string;
}> = (props) => {
  return props.visible ? <StyledChip label={props.label} /> : null;
};

const AppointmentTypeList: FunctionComponent = () => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const searchTerm = useDebouncedState(searchInputValue, 500);

  const history = useHistory();
  const { t } = useTranslations();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError
  } = useAppointmentTypesQuery({ searchTerm });

  useErrorToast(isError, {
    title: t.Admin.AppointmentTypeLoadingErrorToastHeader,
    message: t.Admin.AppointmentTypeListErrorToastMessage
  });

  function onScrollToEnd() {
    const shouldFetchNextPage =
      hasNextPage && !isLoading && !isFetchingNextPage;
    if (shouldFetchNextPage) {
      fetchNextPage();
    }
  }

  function onRowClick(id: number) {
    history.push(`/admin/appointments/${id}`);
  }

  const loading = isLoading || isFetchingNextPage;
  const showResults = !isLoading && !isError;

  const ruleChips = (appt: AppointmentType): ChipRule => [
    [!!appt.active, t.Admin.AppointmentTypeListTableChipActive],
    [!appt.displaytopatient, t.Admin.AppointmentTypeListTableChipHidden],
    [!!appt.embargo, t.Admin.AppointmentTypeListTableChipProvisional],
    [appt.hasstatusfilters, t.Admin.AppointmentTypeListTableChipStatusFilter],
    [appt.appointmenttype === 'video-call', t.PatientCalendar.VideoCall]
  ];

  return (
    <>
      <StyledSearchBar
        value={searchInputValue}
        onChange={setSearchInputValue}
        placeholder={t.Admin.AppointmentTypeListSearchPlaceholder}
      />
      <StyledTableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>{t.Admin.AppointmentTypeListTableHeaderId}</TableCell>
              <TableCell>
                {t.Admin.AppointmentTypeListTableHeaderName}
              </TableCell>
              <TableCell>
                {t.Admin.AppointmentTypeListTableHeaderStatus}
              </TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {showResults &&
              data &&
              data.pages.map((page) =>
                page.data.map((appt) => (
                  <StyledTableRow
                    key={appt.id}
                    onClick={() => onRowClick(appt.id)}
                    data-testid="TableRow"
                    role="button"
                    tabIndex={0}
                  >
                    <TableCell component="th" scope="row">
                      {appt.internalsid}
                    </TableCell>
                    <TableCell>{appt.name}</TableCell>
                    <TableCell>
                      {ruleChips(appt).map(([visible, label], index) => (
                        <AppointmentRuleChip
                          key={index}
                          {...{ visible, label }}
                        />
                      ))}
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
          </TableBody>
          <StyledTableFooter>
            <TableRow>
              {loading && (
                <TableCell colSpan={3}>
                  <LoaderWrapper data-testid="Loader">
                    <CircularProgress />
                  </LoaderWrapper>
                </TableCell>
              )}
            </TableRow>
          </StyledTableFooter>
        </Table>
      </StyledTableContainer>
      <Waypoint onEnter={onScrollToEnd} />
    </>
  );
};

export default AppointmentTypeList;
