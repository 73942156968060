export const detectPreferredHoursType = () => {
  let hoursType: '12H' | '24H' | undefined;

  if (hoursType == null) {
    hoursType = (() => {
      try {
        return Intl.DateTimeFormat(undefined, {
          hour: 'numeric',
        }).resolvedOptions().hour12;
      } catch (err) {
        return false;
      }
    })()
      ? '12H'
      : '24H';
  }

  return hoursType;
};

export const formatHour = (hour: number, hoursType: '12H' | '24H'): string =>
  hoursType === '12H' ? hour.toString() : hour.toString().padStart(2, '0');

export const to12Hour = (hour: number): [number, 'AM' | 'PM'] => {
  if (hour < 1) {
    return [hour + 12, 'AM'];
  }

  if (hour < 12) {
    return [hour, 'AM'];
  }

  if (hour === 12) {
    return [hour, 'PM'];
  }

  return [hour - 12, 'PM'];
};

export const to24Hour = (hour: number, fromPeriod: 'AM' | 'PM'): number => {
  if (fromPeriod === 'AM') {
    if (hour === 12) {
      return 0;
    }

    return hour;
  }

  if (hour === 12) {
    return hour;
  }

  return hour + 12;
};
