import { IconButton, InputAdornment } from '@material-ui/core';
import { Cancel as IconCancel } from '@material-ui/icons';
import React from 'react';
import { SearchTextField } from './MessageEditor.styled';
var SearchIconButton = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(InputAdornment, { position: "end" },
        React.createElement(IconButton, { onClick: onClick, size: "small", role: "button" },
            React.createElement(IconCancel, null))));
};
var ɵ0 = SearchIconButton;
function MessageEditorSearchField(_a) {
    var value = _a.value, onChangeText = _a.onChangeText, placeholder = _a.placeholder;
    var showClearButton = value.length > 0;
    return (React.createElement(SearchTextField, { autoComplete: "off", inputProps: { 'data-testid': 'message-search', role: 'searchbox' }, InputProps: {
            endAdornment: showClearButton ? (React.createElement(SearchIconButton, { onClick: function () { return onChangeText(''); } })) : undefined,
        }, margin: "none", name: "search", onChange: function (e) { return onChangeText(e.target.value); }, placeholder: placeholder, size: "small", spellCheck: "false", type: "search", value: value, variant: "outlined" }));
}
export default MessageEditorSearchField;
export { ɵ0 };
