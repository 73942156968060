import { ElementRef, ErrorHandler, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FileUpload } from 'src/app/core/services/s3.service';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
var ContentSelectComponent = /** @class */ (function () {
    function ContentSelectComponent(_fb, dialog, _s3, _error) {
        var _this = this;
        this._fb = _fb;
        this.dialog = dialog;
        this._s3 = _s3;
        this._error = _error;
        this.filterActive = false;
        this.filterString = '';
        this.content = [];
        this.setSearchString = new EventEmitter();
        this.addAppContent = new EventEmitter();
        this.openFileDialog = new EventEmitter();
        this.activatePatientOnlyFile = new EventEmitter();
        this.deletePatientOnlyFile = new EventEmitter();
        this.config = {};
        this._subs = new Subscription();
        this.isSlidingItem = false;
        this.searchForm = this._fb.group({
            searchValue: this.filterString || ''
        });
        this._subs.add(this.searchForm
            .get('searchValue')
            .valueChanges.subscribe(function (val) { return _this.setSearchString.emit(val); }));
    }
    ContentSelectComponent.prototype.ngOnDestroy = function () {
        this.setSearchString.emit('');
        this._subs.unsubscribe();
    };
    ContentSelectComponent.prototype.viewFile = function (entry) {
        this._s3
            .readFileFromBucket({
            url: entry.uri
        })
            .then(function (res) {
            window.open(res.url, '_blank');
        });
    };
    ContentSelectComponent.prototype.removeFile = function (entry) {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.text.DeleteConfirm,
                text: {
                    Confirm: this.text.Confirm,
                    Cancel: this.text.Cancel
                }
            }
        });
        confirmDialog.afterClosed().subscribe(function (result) {
            if (!result) {
                _this.contentList.nativeElement.focus();
            }
            else {
                _this.deletePatientOnlyFile.emit(entry);
            }
        });
    };
    ContentSelectComponent.prototype.clearFilter = function () {
        this.searchForm.patchValue({
            searchValue: ''
        });
    };
    ContentSelectComponent.prototype.onClickedActivePatientOnlyFile = function (item) {
        if (!item.isTransferring) {
            this.activatePatientOnlyFile.emit(item);
        }
    };
    ContentSelectComponent.prototype.onClickedAddAppContent = function (item) {
        if (!item.isTransferring) {
            // addAppContent.emit(entry.contentEntryHeaderId)
            this.addAppContent.emit(item);
        }
    };
    return ContentSelectComponent;
}());
export { ContentSelectComponent };
