import { Action } from '@ngrx/store';

export enum MedicationsActionTypes {
  LoadPotentialDuplicatesError = '[Medications] Load Potential Duplicates Medication Error',
  LoadActionMedicationsError = '[Medications] Load Action Required Medications Error',
  LoadNoActionMedicationsError = '[Medications] Load No Action Required Medications Error',
}

// Action Creators
// ==================================================

export interface MedicationErrorAction extends Action {
  type: string;
  payload: Error;
}

export class LoadPotentialDuplicatesError implements MedicationErrorAction {
  readonly type = MedicationsActionTypes.LoadPotentialDuplicatesError;

  constructor(public payload: Error) {}
}

export class LoadActionMedicationsError implements MedicationErrorAction {
  readonly type = MedicationsActionTypes.LoadActionMedicationsError;

  constructor(public payload: Error) {}
}

export class LoadNoActionMedicationsError implements MedicationErrorAction {
  readonly type = MedicationsActionTypes.LoadNoActionMedicationsError;

  constructor(public payload: Error) {}
}

// Medications Actions Union
// ==================================================
export type MedicationsActionsUnion =
  | LoadPotentialDuplicatesError
  | LoadActionMedicationsError
  | LoadNoActionMedicationsError;
