import styled from '@emotion/styled';
import { Paper, TableFooter, TableHead, TableRow } from '@material-ui/core';
import { Chip } from '@react/components/Chip';
import SearchBar from '@react/components/SearchBar';

export const StyledTableContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTableHead = styled(TableHead)`
  th {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: #757575;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f0f0f7;
    cursor: pointer;
  }
`;

export const StyledTableFooter = styled(TableFooter)`
  td {
    border-bottom: 0;
  }
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;

export const StyledChip = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledSearchBar = styled(SearchBar)`
  position: sticky;
  top: 0;
`;
