import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
export var Container = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"])));
export var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  max-width: 500px;\n\n  & > * {\n    margin: ", "px 0;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  max-width: 500px;\n\n  & > * {\n    margin: ", "px 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var IconContainer = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  width: 75px;\n"], ["\n  width: 75px;\n"])));
export var StyledText = styled(Typography)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n"], ["\n  color: ", ";\n  text-align: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text.hint;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
