import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/services/auth-guard.service';
import { PageNotFoundComponent } from './core/containers/page-not-found/page-not-found.component';
import { UnauthorisedReactWrapperComponent } from './core/containers/unauthorised/unauthorised-react-wrapper.component';
import { FeatureGuard } from './core/services/feature-guard.service';
import { GlobalPortalGuard } from './core/services/global-guard.service';
import { SelectiveStrategy } from './core/services/selective-strategy.service';
import { VideoCallsPageComponent } from './video-calls/containers/video-calls-page/video-calls-page.container';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'unauthorised',
    component: UnauthorisedReactWrapperComponent,
    pathMatch: 'full',
  },
  {
    path: 'video-calls',
    component: VideoCallsPageComponent,
    canActivate: [AuthGuard, FeatureGuard, GlobalPortalGuard],
    data: { optionsPath: 'videoCallOptions.enabled' },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectiveStrategy,
    }),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    FeatureGuard,
    GlobalPortalGuard,
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  declarations: [PageNotFoundComponent],
})
export class AppRoutingModule {}
