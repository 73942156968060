export class ChangeMessageLabelRequest {
  private Action = 'updatemessagetype';
  public PublicToken: string;
  public MessageId: number;
  public MessageTypeId: number;

  constructor({ PublicToken, MessageId, MessageTypeId }) {
    this.PublicToken = PublicToken;
    this.MessageId = MessageId;
    this.MessageTypeId = MessageTypeId;
  }
}
