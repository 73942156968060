import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import * as fromSettings from '../../../settings/reducers';
import {
  ContentEntryItem,
  GroupedContent,
} from '../../models/groupedContentEntries';
import { ContentService } from '../../services/content.service';

export interface ChooseGlobalFileContentData {
  languageCode?: string;
  globalPortalId: number;
}

export interface ChooseGlobalFileContentResult {
  id: number;
}

@Component({
  selector: 'portal-choose-global-file-content',
  templateUrl: './choose-global-file-content.component.html',
  styleUrls: ['./choose-global-file-content.component.scss'],
})
export class ChooseGlobalFileContentComponent implements OnInit {
  public contentForm: FormControl;
  public useAContentText$: Observable<any>;
  public filteredContent$: Observable<GroupedContent[]>;
  private _contentList$: Observable<GroupedContent[]>;
  private _selectedValue: number;

  constructor(
    private _store: Store<fromSettings.State>,
    private _dialogRef: MatDialogRef<ChooseGlobalFileContentComponent>,
    private _contentService: ContentService,
    @Inject(MAT_DIALOG_DATA) public data: ChooseGlobalFileContentData,
  ) {
    this.contentForm = new FormControl('', Validators.required);
  }

  ngOnInit() {
    this.useAContentText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('UseAContent')),
    );

    this._contentList$ =
      this._contentService.getGlobalPortalGroupedFileEntryList(
        this.data.globalPortalId,
        this.data.languageCode,
      );
    this.filteredContent$ = combineLatest(
      (
        this.contentForm.valueChanges as Observable<string | ContentEntryItem>
      ).pipe(startWith(''), distinctUntilChanged(), debounceTime(250)),
      this._contentList$,
    ).pipe(
      map(([value, groupedContent]) => this._filter(value, groupedContent)),
    );
  }

  private _filter(
    value: string | ContentEntryItem,
    groupedContent: GroupedContent[],
  ): GroupedContent[] {
    const val = typeof value === 'string' ? value : value.key;
    const results = groupedContent
      .map((item) => {
        const filteredEntries = item.entries.filter((entry: ContentEntryItem) =>
          entry.key.toLowerCase().includes(val.toLowerCase().trim()),
        );
        return {
          id: item.id,
          name: item.name,
          entries: filteredEntries,
        };
      })
      .filter((item) => item.entries.length > 0);

    return results;
  }

  copyContent() {
    this._dialogRef.close({
      id: this._selectedValue,
    });
  }

  cancel(): void {
    this._dialogRef.close(null);
  }

  setSelectedValue(item: ContentEntryItem) {
    this._selectedValue = item.value;
  }

  formatName(item: ContentEntryItem) {
    return item.key;
  }
}
