import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnDestroy, OnInit, QueryList, } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/reducers';
import * as fromSettings from '../../../settings/reducers';
import { CameraComponent } from '../../components/camera/camera.component';
import { ParticipantComponent } from '../../components/participant/participant.component';
import { LocalConnectionStatus, VideoChatService, } from '../../services/video.chat.service';
var ParticipantsHostDirective = /** @class */ (function () {
    function ParticipantsHostDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    return ParticipantsHostDirective;
}());
export { ParticipantsHostDirective };
var VideoCallContainerComponent = /** @class */ (function () {
    function VideoCallContainerComponent(_store, _videoChatService, dialog) {
        var _this = this;
        this._store = _store;
        this._videoChatService = _videoChatService;
        this.dialog = dialog;
        this._subs = new Subscription();
        this.callParticipants = new Array();
        this.leftCall = new Subject();
        this.errorConnecting = new Subject();
        this.errorDevices = new Subject();
        this.connected = new Subject();
        this.isReconnecting = new BehaviorSubject(false);
        this.participantDisconnected = new Subject();
        this.invitePartnerInCall = new EventEmitter();
        this.unInvitePartnerInCall = new EventEmitter();
        this.isConnected = false;
        this.isConnecting = true;
        this.hasParticipants = false;
        this.isAudioEnabled = true;
        this.isAudioOutputEnabled = false;
        this.isVideoEnabled = true;
        this.audioInputDevices = [];
        this.audioOutputDevices = [];
        this.videoDevices = [];
        this.userFirstName = '';
        this.partnerInviteEnabled$ = this._store.pipe(select(fromAuth.getVideoCallsPartnerInvite));
        this._subs.add(_videoChatService.localConnectionStatusChanged$.subscribe(function (s) {
            _this.isConnected = s === LocalConnectionStatus.Connected;
            _this.isConnecting =
                !_this.isConnected && s !== LocalConnectionStatus.Disconnected;
            if (_this.isConnected) {
                _this.connected.next();
            }
            else if (!_this.isConnected && !_this.isConnecting) {
                _this.leftCall.next();
            }
        }));
        this._subs.add(_videoChatService.errorConnecting.subscribe(function (e) {
            _this.errorConnecting.next(e);
        }));
        this._subs.add(_videoChatService.errorDevices.subscribe(function (e) {
            _this.errorDevices.next(e);
        }));
        this._subs.add(_videoChatService.roomReconnecting.subscribe(function (e) {
            _this.isReconnecting.next(e);
        }));
        this._videoCallModalText$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallModal')));
        this._userFirstName$ = this._store.pipe(select(fromAuth.getFirstName));
        this.invitePartnerText$ = this._store.pipe(select(fromSettings.getSectionTranslations('VideoCallsInvitePartner')));
    }
    VideoCallContainerComponent.prototype.beforeUnloadHandler = function () {
        this.leaveRoom();
    };
    VideoCallContainerComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this._subs.add(this.invitePartnerText$.subscribe(function (t) {
                    _this.invitePartnerText = t;
                }));
                this._subs.add(this.partnerInviteEnabled$.subscribe(function (pi) {
                    _this.partnerInviteEnabled = pi;
                }));
                this._subs.add(this._videoCallModalText$.subscribe(function (t) {
                    _this.videoCallModalText = t;
                }));
                this._subs.add(this._userFirstName$.subscribe(function (t) {
                    _this.userFirstName = t;
                }));
                return [2 /*return*/];
            });
        });
    };
    VideoCallContainerComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    VideoCallContainerComponent.prototype.ngOnDestroy = function () {
        this._videoChatService.leaveRoom();
        this._subs.unsubscribe();
    };
    VideoCallContainerComponent.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._videoChatService.initializeMedia()];
                    case 1:
                        _a.sent();
                        this.isAudioOutputEnabled = this._videoChatService.audioOutputEnabled;
                        if (!this._videoChatService.tracks) return [3 /*break*/, 3];
                        this._camera.videoTrack = this._videoChatService.tracks.find(function (t) { return t.kind === 'video'; });
                        this._videoChatService.participantConnected$.subscribe(function (p) {
                            var participant = {
                                participant: p,
                                isDominant: true,
                            };
                            _this.callParticipants.push(participant);
                            _this.setDominantParticipant(participant);
                        });
                        this._subs.add(this._videoChatService.participantDisconnected$.subscribe(function (p) {
                            _this.callParticipants = _this.callParticipants.filter(function (item) { return item.participant.sid !== p.sid; });
                            if (_this.callParticipants.length > 0) {
                                _this.setDominantParticipant(_this.callParticipants[0]);
                            }
                            _this.participantDisconnected.next(p);
                        }));
                        this._subs.add(this._videoChatService.localConnectionStatusChanged$.subscribe(function (s) {
                            if (s === LocalConnectionStatus.Disconnected) {
                                _this._participantComponents.forEach(function (c) {
                                    c.isWaiting = false;
                                });
                                _this._camera.finalizePreview();
                                _this._videoChatService.stopMedia();
                            }
                        }));
                        this._subs.add(this._videoChatService.dominantParticipantChanged$.subscribe(function (p) {
                            if (p) {
                                var participant = _this.callParticipants.find(function (c) { return c.participant.sid === p.sid; });
                                _this.setDominantParticipant(participant);
                            }
                        }));
                        this._subs.add(this._videoChatService.audioInputDevicesChanged$.subscribe(function (devices) {
                            _this.audioInputDevices = devices;
                        }));
                        this._subs.add(this._videoChatService.audioInputDeviceChanged$.subscribe(function (device) {
                            _this.currentAudioInputDeviceId =
                                device != null ? device.deviceId : undefined;
                        }));
                        this._subs.add(this._videoChatService.audioOutputDevicesChanged$.subscribe(function (devices) {
                            _this.audioOutputDevices = devices;
                        }));
                        this._subs.add(this._videoChatService.audioOutputDeviceChanged$.subscribe(function (device) {
                            _this.currentAudioOutputDeviceId =
                                device != null ? device.deviceId : undefined;
                        }));
                        this._subs.add(this._videoChatService.videoDevicesChanged$.subscribe(function (devices) {
                            _this.videoDevices = devices;
                        }));
                        this._subs.add(this._videoChatService.videoDeviceChanged$.subscribe(function (device) {
                            _this.currentVideoDeviceId =
                                device != null ? device.deviceId : undefined;
                        }));
                        return [4 /*yield*/, this.joinRoom(this.roomName)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VideoCallContainerComponent.prototype.joinRoom = function (roomName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!roomName) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._videoChatService.joinOrCreateRoom(this.roomName, this.token)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VideoCallContainerComponent.prototype.leaveRoom = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.leaveConfirmationCallback) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.leaveConfirmationCallback()];
                    case 1:
                        if (_a.sent()) {
                            this._videoChatService.leaveRoom();
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this._videoChatService.leaveRoom();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VideoCallContainerComponent.prototype.toggleCamera = function () {
        this.isVideoEnabled = this._videoChatService.toggleCamera();
    };
    VideoCallContainerComponent.prototype.toggleMute = function () {
        this.isAudioEnabled = this._videoChatService.toggleMute();
    };
    VideoCallContainerComponent.prototype.changeAudioInputDevice = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var audioInputDeviceId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        audioInputDeviceId = e.value;
                        return [4 /*yield*/, this._videoChatService.changeAudioInputDevice(audioInputDeviceId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoCallContainerComponent.prototype.changeAudioOutputDevice = function (e) {
        var audioOutputDeviceId = e.value;
        this._videoChatService.changeAudioOutputDevice(audioOutputDeviceId);
    };
    VideoCallContainerComponent.prototype.changeVideoDevice = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var videoDeviceId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        videoDeviceId = e.value;
                        return [4 /*yield*/, this._videoChatService.changeVideoDevice(videoDeviceId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    VideoCallContainerComponent.prototype.setDominantParticipant = function (participant) {
        for (var _i = 0, _a = this.callParticipants; _i < _a.length; _i++) {
            var p = _a[_i];
            p.isDominant = p === participant;
        }
    };
    return VideoCallContainerComponent;
}());
export { VideoCallContainerComponent };
