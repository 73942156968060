/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./video-call-modal-react-wrapper.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/congito.wrapper.service";
import * as i4 from "@angular/material/dialog";
var styles_VideoCallModalReactWrapperComponent = [];
var RenderType_VideoCallModalReactWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoCallModalReactWrapperComponent, data: {} });
export { RenderType_VideoCallModalReactWrapperComponent as RenderType_VideoCallModalReactWrapperComponent };
export function View_VideoCallModalReactWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { containerRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["videoCallModalReactWrapperComponent", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_VideoCallModalReactWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-video-call-modal", [], null, null, null, View_VideoCallModalReactWrapperComponent_0, RenderType_VideoCallModalReactWrapperComponent)), i0.ɵdid(1, 4440064, null, 0, i1.VideoCallModalReactWrapperComponent, [i2.Store, i3.CognitoWrapperService, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoCallModalReactWrapperComponentNgFactory = i0.ɵccf("portal-video-call-modal", i1.VideoCallModalReactWrapperComponent, View_VideoCallModalReactWrapperComponent_Host_0, {}, {}, []);
export { VideoCallModalReactWrapperComponentNgFactory as VideoCallModalReactWrapperComponentNgFactory };
