import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import {
  ClinicActionTypes,
  LoadClinicInfoSuccess,
  LoadClinicLanguages,
  LoadClinicLanguagesError,
  LoadClinicLanguagesSuccess
} from '../actions/clinic.actions';
import { ClinicService } from '../services/clinic.service';

@Injectable()
export class ClinicEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly clinicService: ClinicService,
    private readonly error: ErrorHandler,
    private readonly _localisationService: LocalisationService
  ) {}

  @Effect()
  loadLanguages$ = this.actions$.pipe(
    ofType<LoadClinicLanguages>(ClinicActionTypes.LoadClinicLanguages),
    switchMap(() => {
      return this.clinicService.getLanguages().pipe(
        map((languages) => {
          return new LoadClinicLanguagesSuccess(languages);
        }),
        catchError((error) => {
          return of(new LoadClinicLanguagesError(error));
        })
      );
    })
  );

  @Effect()
  setTimeZone$ = this.actions$.pipe(
    ofType<LoadClinicInfoSuccess>(ClinicActionTypes.LoadClinicInfoSuccess),
    switchMap((action) => {
      this._localisationService.setTimeZone(action.payload.DefaultTimezone);

      return of();
    })
  );
}
