
    <portal-forgot-password
      [isCore]="isCoreClinic$ | async"
      [text]="authText$ | async"
      [generatingCode]="generatingCode$ | async"
      [resettingPassword]="resettingPassword$ | async"
      [codeGenerated]="codeGenerated$ | async"
      [errorMessage]="error$ | async"
      [clinicId]="clinicId$ | async"
      (requestPasswordCode)="requestNewPasswordCode($event)"
      (submitNewPassword)="submitNewPassword($event)"
    ></portal-forgot-password>
  