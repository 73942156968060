import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Label } from '../../models/Label';
import { Message } from '../../models/Message';
import { Thread } from '../../models/Thread';
import { AlterMessageActionsUnion } from '../actions/alter-message.actions';
import { LoadMessageActionsUnion } from '../actions/load-message.actions';
import { MessageUIActionsUnion } from '../actions/message-ui.actions';
import { NewMessageActionsUnion } from '../actions/new-message.actions';
import { RealTimeActionsUnion } from '../actions/real-time.actions';
import { TypingInfo } from '../models/messaging';
import { MessagingSummaryResponse } from '../models/messagingSummaryResponse';
import { ConversationListParams } from '../models/requests/conversations.request';
import { TabState } from '../models/TabState';
import * as fromAlterMessage from './alter-message.reducer';
import * as fromLoadMessage from './load-message.reducer';
import * as fromMessageUI from './message-ui.reducer';
import * as fromNewMessage from './new-message.reducer';
import * as fromRealTime from './real-time.reducer';

/* Combine action types into one check */
export type MessageAction =
  | AlterMessageActionsUnion
  | NewMessageActionsUnion
  | LoadMessageActionsUnion
  | MessageUIActionsUnion
  | RealTimeActionsUnion;

export interface MessagesState {
  loadingThread: boolean;
  sendingMessage: number;
  messageCountSummary: {
    starred: number | null;
    unstarred: number | null;
  };
  messageList: Message[];
  filter: string;
  messageTypeFilter: Label;
  labels: Label[];
  activeThreadFirstLoad: boolean;
  activeThreadId: number;
  activeThread: Thread | null;
  activeThreadOpen: boolean;
  searchActive: boolean;
  searchString: string;
  messageResponseSize: string;
  latestMessage: Message;
  typingInfo: {
    [key: number]: TypingInfo[];
  };
  forceRefreshTyping: Date;
  draftMessages: {
    [key: number]: string;
  };
  messagingSummary: MessagingSummaryResponse;
  isReadOnly: boolean;
  // REFACTORING
  nextPageToLoad: ConversationListParams;
  reachedEndOfMessages: boolean;
  autoLoadNextPage: number;
  isLoadingConversations: boolean;
  tab: TabState;
  conversationCache: Record<number, string>;
}

export const initialState: MessagesState = {
  loadingThread: false,
  sendingMessage: null,
  messageList: [],
  messageCountSummary: {
    starred: null,
    unstarred: null,
  },
  filter: 'unresolved',
  messageTypeFilter: new Label({
    messageTypeId: -1,
    isGroup: true,
    filter: 'unresolved',
    displayName: 'AllUnresolved',
    unresolvedCount: 0,
  }),
  labels: [],
  activeThreadFirstLoad: null,
  activeThreadId: null,
  activeThread: null,
  activeThreadOpen: false,
  searchActive: false,
  searchString: '',
  messageResponseSize: 'normal',
  latestMessage: null,
  typingInfo: {},
  forceRefreshTyping: null,
  draftMessages: {},
  messagingSummary: { results: [] },
  isReadOnly: true,
  // REFACTORING
  nextPageToLoad: {
    MessageTypeId: null,
    Resolved: false,
    Count: 100,
  },
  reachedEndOfMessages: false,
  autoLoadNextPage: 0,
  isLoadingConversations: false,
  tab: TabState.None,
  conversationCache: {},
};

export function reducer(state = initialState, action): MessagesState {
  // console.log(action.type, action.payload, state);

  const rightSquareBracket = action.type.indexOf(']');
  const subtype = action.type.substring(1, rightSquareBracket);

  switch (subtype) {
    // route to appropriate reducer
    case 'AlterMessage':
      return fromAlterMessage.reducer(state, action);

    case 'LoadMessage':
      return fromLoadMessage.reducer(state, action);

    case 'NewMessage':
      return fromNewMessage.reducer(state, action);

    case 'MessageUI':
      return fromMessageUI.reducer(state, action);

    case 'MessagingAPI':
      return fromRealTime.reducer(state, action);

    default:
      return state;
  }
}

/* extract messages state from root state */
export const getMessagesState =
  createFeatureSelector<MessagesState>('messages');

/* simple selectors */
export const getMessagingSummary = createSelector(
  getMessagesState,
  (state) => state.messagingSummary,
);

export const getMessageList = createSelector(getMessagesState, (state) => {
  if (state.tab === TabState.None) {
    return state.messageList;
  }

  if (state.tab === TabState.Starred) {
    return state.messageList.filter((m) => m.IsStarred === true);
  } else {
    return state.messageList.filter((m) => m.IsStarred === false);
  }
});

export const getAllMessageList = createSelector(getMessagesState, (state) => {
  return state.messageList;
});

export const getDraftMessages = createSelector(
  getMessagesState,
  (state) => state.draftMessages,
);

export const getTyping = createSelector(
  getMessagesState,
  (state) => state.typingInfo,
);

export const getCurrentTab = createSelector(
  getMessagesState,
  (state) => state.tab,
);

export const getForceRefreshTyping = createSelector(
  getMessagesState,
  (state) => state.forceRefreshTyping,
);

export const isMessageSending = createSelector(
  getMessagesState,
  (state): boolean =>
    state.sendingMessage && state.sendingMessage === state.activeThreadId,
);

export const getLatestMessage = createSelector(
  getMessagesState,
  (state) => state.latestMessage,
);

export const isThreadLoading = createSelector(
  getMessagesState,
  (state) => state.loadingThread,
);

export const getFilter = createSelector(
  getMessagesState,
  (state) => state.filter,
);

export const getStarredCount = createSelector(
  getMessagesState,
  (state) => state.messageCountSummary.starred,
);

export const getUnstarredCount = createSelector(
  getMessagesState,
  (state) => state.messageCountSummary.unstarred,
);

export const getMessageTypeFilter = createSelector(
  getMessagesState,
  (state) => state.messageTypeFilter,
);

export const getLabels = createSelector(
  getMessagesState,
  (state) => state.labels,
);

export const getActiveThreadId = createSelector(
  getMessagesState,
  (state) => state.activeThreadId,
);
export const isActivethreadFirstLoad = createSelector(
  getMessagesState,
  (state) => state.activeThreadFirstLoad,
);

export const getActiveThread = createSelector(
  getMessagesState,
  (state) => state.activeThread,
);

export const getIsReadOnly = createSelector(
  getMessagesState,
  (state) => state.isReadOnly,
);

export const getPatientId = createSelector(getActiveThread, (state) => {
  return state ? state.patientId : null;
});

export const isActiveThreadOpen = createSelector(
  getMessagesState,
  (state) => state.activeThreadOpen,
);

export const getActiveThreadMessages = createSelector(
  getActiveThread,
  (state) => {
    return state ? state.messages : null;
  },
);

export const getThreadsLatestMessage = createSelector(
  getActiveThreadMessages,
  (state) => {
    return state ? state[state.length - 1] : null;
  },
);

export const groupFilter = createSelector(
  getFilter,
  getLabels,
  (filter: string, labelsArr: Label[]) => {
    const activeLabel = labelsArr.filter((l) => l.filter === filter);
    if (activeLabel && activeLabel.length > 0) {
      return activeLabel[0].isGroup;
    }
  },
);

export const isSearchActive = createSelector(
  getMessagesState,
  (state) => state.searchActive,
);

export const getSearchString = createSelector(
  getMessagesState,
  (state) => state.searchString,
);

export const getNonGroupLabels = createSelector(getLabels, (allLabels) =>
  allLabels.filter((l) => !l.isGroup),
);

export const getAssignableLabels = createSelector(getLabels, (allLabels) =>
  allLabels.filter(
    (l) => !l.isGroup && !l.isUncategorised && !!l.canClinicReply,
  ),
);

export const getLabelNames = createSelector(
  getNonGroupLabels,
  (labels: Label[]) => labels.map((l) => ({ name: l.displayName })),
);

export const getUnusedLabels = createSelector(
  getActiveThread,
  getAssignableLabels,
  (activeThread: Thread, labels: Label[]) => {
    const activeThreadTypeId = (activeThread && activeThread.typeId) || null;
    return activeThreadTypeId
      ? labels.filter((l) => l.messageTypeId !== activeThreadTypeId)
      : labels;
  },
);

export const getMessageResponseSize = createSelector(
  getMessagesState,
  (state: MessagesState) => state.messageResponseSize,
);

export const getLoadingThreadId = createSelector(
  isActivethreadFirstLoad,
  getActiveThreadId,
  (firstLoad: boolean, id: number) => firstLoad && id,
);

export const getActiveMessageTypeId = createSelector(
  getMessageTypeFilter,
  (state: Label) => state.messageTypeId,
);

// Refactoring
export const getNextPageToLoad = createSelector(
  getMessagesState,
  (state: MessagesState) => state.nextPageToLoad,
);

export const getReachedEndOfMessages = createSelector(
  getMessagesState,
  (state: MessagesState) => state.reachedEndOfMessages,
);

export const autoLoadNextPage = createSelector(
  getMessagesState,
  (state: MessagesState) => state.autoLoadNextPage,
);

export const isLoadingConversations = createSelector(
  getMessagesState,
  (state: MessagesState) => state.isLoadingConversations,
);

export const activeThreadHasNewMessages = createSelector(
  getActiveThread,
  (activeThread) => {
    return !!activeThread ? activeThread.hasNewMessages : false;
  },
);

export const getSelectedTab = createSelector(
  getMessagesState,
  (state: MessagesState) => {
    return state.tab === TabState.EverythingElse ? 1 : 0;
  },
);

export const getConversationCache = (state: MessagesState) =>
  state.conversationCache;
