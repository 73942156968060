import { CircularProgress } from '@material-ui/core';
import { Empty } from '@react/components';
import ExportRecords from '@react/components/users/exportRecords';
import Filters from '@react/components/users/filters';
import InviteUser from '@react/components/users/inviteUser';
import InviteUserModal from '@react/components/users/inviteUser/modal';
import UsersList from '@react/components/users/usersList';
import { CognitoStatus, } from '@react/lib/api/types';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { flatMap } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useAdminClinicUsersListQuery } from '../../hooks/users';
import { EmptyActionListIcon } from '../side/icons/EmptyActionListIcon';
import { EmptyContainer, LoaderWrapper, StyledActionsContainer, StyledSearchBar, StyledTableContainer, } from './index.styled';
var Users = function () {
    var t = useTranslations().t;
    var _a = useState(false), inviteModalOpen = _a[0], setInviteModalOpen = _a[1];
    var _b = useState(null), userToInvite = _b[0], setUserToInvite = _b[1];
    var _c = useState({}), filters = _c[0], setFilters = _c[1];
    var _d = useState(''), searchInputValue = _d[0], setSearchInputValue = _d[1];
    var searchTerm = useDebouncedState(searchInputValue, 500);
    var _e = useAdminClinicUsersListQuery({ searchTerm: searchTerm, filters: filters }), data = _e.data, isLoading = _e.isLoading, fetchNextPage = _e.fetchNextPage, hasNextPage = _e.hasNextPage, isFetchingNextPage = _e.isFetchingNextPage, isError = _e.isError;
    var onScrollToEnd = function () {
        var shouldFetchNextPage = hasNextPage && !isLoading && !isFetchingNextPage;
        if (shouldFetchNextPage) {
            fetchNextPage();
        }
    };
    useErrorToast(isError, {
        title: t.Admin.Users.UsersLoadingErrorToastHeader,
        message: t.Admin.Users.UsersLoadingErrorToastMessage,
    });
    var getEmptyUsersMessage = function () {
        switch (true) {
            case filters.status && filters.status.length > 1: {
                return t.Admin.Users.UsersListEmptyUsers;
            }
            case filters.status && filters.status.includes(CognitoStatus.Active): {
                return t.Admin.Users.UsersListEmptyActiveUsers;
            }
            case filters.status &&
                filters.status.includes(CognitoStatus.Deactivated):
                {
                    return t.Admin.Users.UsersListEmptyInactiveUsers;
                }
            default: {
                return t.Admin.Users.UsersListEmptyMessage;
            }
        }
    };
    var loading = isLoading || isFetchingNextPage;
    var showResults = !loading && !isError;
    var renderRecords = showResults && data && data.pages[0].data.length > 0;
    var users = useMemo(function () { return data && data.pages && flatMap(data.pages, function (page) { return page.data; }); }, [data]);
    var onCloseModal = function () {
        setInviteModalOpen(false);
    };
    var handleClickInviteUser = function (user) {
        setUserToInvite(user);
        setInviteModalOpen(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InviteUserModal, { open: inviteModalOpen, onClose: onCloseModal }, userToInvite && (React.createElement(InviteUser, { onClose: onCloseModal, user: userToInvite }))),
        React.createElement(StyledSearchBar, { value: searchInputValue, onChange: setSearchInputValue, placeholder: t.Admin.Users.SearchBarPlaceholder }),
        React.createElement(StyledActionsContainer, null,
            React.createElement(ExportRecords, null),
            React.createElement(Filters, { filters: filters, setFilters: setFilters })),
        !users && loading && (React.createElement(EmptyContainer, null,
            React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                React.createElement(CircularProgress, null)))),
        !renderRecords && !loading && (React.createElement(EmptyContainer, null,
            React.createElement(Empty, { "data-testid": "empty_state", icon: React.createElement(EmptyActionListIcon, null), message: getEmptyUsersMessage() }))),
        users && users.length > 0 && (React.createElement(StyledTableContainer, null,
            React.createElement(UsersList, { onClickInviteUser: handleClickInviteUser, users: users, loading: loading, onScrollToEnd: onScrollToEnd })))));
};
var ɵ0 = Users;
export default Users;
export { ɵ0 };
