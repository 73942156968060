import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { CompletePasswordFailure, CompletePasswordSuccess, GenerateResetCodeFailure, GenerateResetCodeSuccess, LogoutFailure, LogoutSuccess, OTPLoginFailure, OTPRejected, OTPRequired, ResetPasswordSuccess } from './../actions/auth.actions';
import { MatDialog } from '@angular/material';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
import { CognitoStatuses } from './../models/CognitoStatuses';
import { ClearStaffProfiles, LoadClinicInfoSuccess } from '../../core/actions/clinic.actions';
import { ChangeLanguage } from '../../settings/actions/settings.actions';
import { AuthActionTypes, CoreLoginFailure, CoreLoginSuccess, GetClinicIdError, GetClinicIdSuccess, TempSuccess } from '../actions/auth.actions';
import { AuthService } from '../services/auth.service';
import { supportedLanguages } from '../../settings/data/supported-languages';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { NavigationService } from 'src/app/core/services/navigation.service';
import convertCognitoUser from '../utils/convert-cognito-user';
import { ClinicService } from 'src/app/core/services/clinic.service';
var GLOBAL_CLINIC_TYPE = 10;
export var wrapResponseWithGlobalFlag = function (clinicInfo, businessUnitClinicInfo) { return (tslib_1.__assign({}, businessUnitClinicInfo, clinicInfo, { IsGlobalPortal: clinicInfo.ClinicTypeId === GLOBAL_CLINIC_TYPE ? true : false })); };
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions$, authService, clinicService, _store, _error, _navigationService, dialog) {
        var _this = this;
        this.actions$ = actions$;
        this.authService = authService;
        this.clinicService = clinicService;
        this._store = _store;
        this._error = _error;
        this._navigationService = _navigationService;
        this.dialog = dialog;
        this.getClinicInfo$ = this.actions$.pipe(ofType(AuthActionTypes.GetClinicId), switchMap(function (action) {
            return _this.authService.getClinicId().pipe(switchMap(function (clinicInfo) {
                return _this.clinicService.getClinicInfo(clinicInfo.Token).pipe(map(function (businessUnitClinicInfo) { return ({
                    clinicInfo: clinicInfo,
                    businessUnitClinicInfo: businessUnitClinicInfo
                }); }));
            }), mergeMap(function (_a) {
                var clinicInfo = _a.clinicInfo, businessUnitClinicInfo = _a.businessUnitClinicInfo;
                var language = supportedLanguages['en-gb'];
                if (supportedLanguages[clinicInfo.LanguageCode]) {
                    language = supportedLanguages[clinicInfo.LanguageCode];
                }
                var info = wrapResponseWithGlobalFlag(clinicInfo, businessUnitClinicInfo);
                return [
                    new GetClinicIdSuccess(info),
                    new LoadClinicInfoSuccess(info),
                    // set app language to clinic default
                    new ChangeLanguage(language)
                ];
            }), catchError(function (error) {
                return of(new GetClinicIdError(error));
            }));
        }));
        this.coreLogin$ = this.actions$.pipe(ofType(AuthActionTypes.CoreLogin), mergeMap(function (action) {
            return _this.authService
                .cognitoLogin(action.payload)
                .then(function (cognitoUser) {
                if (cognitoUser.challengeName === CognitoStatuses.NEW_PASSWORD_REQUIRED) {
                    return new TempSuccess({
                        user: cognitoUser
                    });
                }
                else if (cognitoUser.challengeName === CognitoStatuses.CUSTOM_CHALLENGE) {
                    return new OTPRequired({
                        user: cognitoUser
                    });
                }
                else if (cognitoUser.originalError) {
                    console.error(cognitoUser.originalError.message);
                    return new CoreLoginFailure(cognitoUser.originalError);
                }
                else {
                    return new CoreLoginSuccess({ user: cognitoUser });
                }
            })
                .catch(function (error) {
                console.log(error);
                return new CoreLoginFailure(error);
            });
        }));
        this.verifyOTP$ = this.actions$.pipe(ofType(AuthActionTypes.VerifyOTP), mergeMap(function (action) {
            return _this.authService
                .verifyOTP(action.payload)
                .then(function (cognitoUser) {
                if (cognitoUser.challengeName === CognitoStatuses.CUSTOM_CHALLENGE) {
                    return new OTPRejected({
                        user: cognitoUser
                    });
                }
                else if (cognitoUser.originalError) {
                    return new OTPLoginFailure({
                        toast: action.payload.toast
                    });
                }
                else {
                    return new CoreLoginSuccess({ user: cognitoUser });
                }
            })
                .catch(function (error) {
                console.log(error);
                return new CoreLoginFailure(error);
            });
        }));
        this.OTPLoginFailure$ = this.actions$.pipe(ofType(AuthActionTypes.OTPLoginFailure), mergeMap(function (action) {
            return of(new DisplayToastAction(action.payload.toast));
        }));
        this.completePassword$ = this.actions$.pipe(ofType(AuthActionTypes.CompletePassword), mergeMap(function (action) {
            return _this.authService
                .cognitoCompletePassword(action.payload)
                .then(function (cognitoUser) {
                var user = convertCognitoUser(action.payload.user).user;
                return new CompletePasswordSuccess({
                    user: cognitoUser
                });
            })
                .catch(function (error) {
                return new CompletePasswordFailure(error);
            });
        }));
        this.clear$ = this.actions$.pipe(ofType(AuthActionTypes.Logout), mergeMap(function () {
            return Promise.resolve(new ClearStaffProfiles());
        }));
        this.logout$ = this.actions$.pipe(ofType(AuthActionTypes.Logout), switchMap(function (_a) {
            var isCognito = _a.payload.isCognito;
            if (isCognito) {
                return _this.authService
                    .cognitoLogout()
                    .then(function () {
                    return new LogoutSuccess({
                        isCognito: isCognito
                    });
                })
                    .catch(function (err) {
                    console.error(err);
                    return new LogoutFailure({
                        isCognito: isCognito
                    });
                });
            }
            else {
                return Promise.resolve(new LogoutSuccess({
                    isCognito: isCognito
                }));
            }
        }));
        this.getResetCode$ = this.actions$.pipe(ofType(AuthActionTypes.GenerateResetCode), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.authService
                .cognitoGenerateResetCode(payload)
                .then(function (res) {
                return new GenerateResetCodeSuccess(res);
            })
                .catch(function (err) {
                return new GenerateResetCodeFailure(err);
            });
        }));
        this.submitNewPassword$ = this.actions$.pipe(ofType(AuthActionTypes.ResetPassword), switchMap(function (_a) {
            var payload = _a.payload;
            return _this.authService
                .cognitoResetPassword(payload)
                .then(function (res) {
                return new ResetPasswordSuccess(res);
            })
                .catch(function (err) {
                return new ResetPasswordSuccess(err);
            });
        }));
        this.tempSuccess = this.actions$.pipe(ofType(AuthActionTypes.TempSuccess), tap(function () { return _this._navigationService.navigate(['/create']); }));
        this.loginSuccess = this.actions$.pipe(ofType(AuthActionTypes.CompletePasswordSuccess, AuthActionTypes.CoreLoginSuccess, AuthActionTypes.ResetPasswordSuccess), tap(function () { return _this._navigationService.navigateAfterLogin(_this._isGlobalPortal); }));
        this.loginRedirect$ = this.actions$.pipe(ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.LogoutSuccess, AuthActionTypes.LogoutFailure, AuthActionTypes.OTPLoginFailure), tap(function () {
            _this.dialog.closeAll();
            _this._navigationService.navigate(['/login']);
        }));
        this.otpRequired$$ = this.actions$.pipe(ofType(AuthActionTypes.OTPRequired), tap(function () {
            _this._navigationService.navigate(['/email-otp']);
        }));
        this._store
            .pipe(select(fromSettings.getSectionTranslations('Login')))
            .subscribe(function (t) { return (_this._toastText = t); });
        this._store
            .pipe(select(fromRoot.getIsGlobalPortal))
            .subscribe(function (value) { return (_this._isGlobalPortal = value); });
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "getClinicInfo$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "coreLogin$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "verifyOTP$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "OTPLoginFailure$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "completePassword$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "clear$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "logout$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "getResetCode$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "submitNewPassword$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "tempSuccess", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "loginSuccess", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "loginRedirect$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "otpRequired$$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
