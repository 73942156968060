import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import equal from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as fromAuth from '../../../auth/reducers';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import { NavigationService } from '../../../core/services/navigation.service';
import { FileUpload } from '../../../core/services/s3.service';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as ContentActions from '../../actions/content.actions';
import { ContentActionTypes } from '../../actions/content.actions';
import { LinkContentActionTypes, } from '../../actions/link-content.actions';
import { ContentEntry, NewContentEntrySection, } from '../../models/content-entry';
import * as fromContent from '../../reducers';
import { validateContentName } from '../../services/async-validators/validate-content-name';
import { ContentService } from '../../services/content.service';
import { ViewChildrenContainer } from '../view-children/view-children.container';
var ContentEditFormComponent = /** @class */ (function () {
    function ContentEditFormComponent(_store, _fb, _navigationService, _updates$, _contentService, _S3, dialog, change) {
        var _this = this;
        this._store = _store;
        this._fb = _fb;
        this._navigationService = _navigationService;
        this._updates$ = _updates$;
        this._contentService = _contentService;
        this._S3 = _S3;
        this.dialog = dialog;
        this.change = change;
        this.status = new EventEmitter();
        this.copyGlobalContent = new EventEmitter();
        this.copyGlobalFileContent = new EventEmitter();
        this.config = {};
        this.introductionError = new BehaviorSubject(false);
        this.checkingTitle$ = new BehaviorSubject(false);
        this.titleInvalid$ = new BehaviorSubject(false);
        this.attachmentExists$ = new BehaviorSubject(false);
        this.addFile = false;
        this.parentPortalId = null;
        this._destroyed$ = new Subject();
        this._subs = new Subscription();
        // Global Portal Observable
        this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        this._contentService.getGlobalPortal().subscribe(function (res) {
            if (res && res.globalclinicid) {
                _this.parentPortalId = res.globalclinicid;
            }
            return;
        });
        // Set up Text Observables
        this.titleText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorTitle')));
        this.bodyText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorBody')));
        this.controlsText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorControls')));
        this.videoText$ = this._store.pipe(select(fromSettings.getSectionTranslations('AddVideoControls')));
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorSections')));
        this.commentsText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorComments')));
        this.versionHistoryText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentCreatorVersionHistory')));
        this.addPdfText$ = this._store.pipe(select(fromSettings.getSectionTranslations('AddPdf')));
        this.useATemplateText$ = this._store.pipe(select(fromSettings.getSectionTranslations('UseATemplate')));
        this.useAContentText$ = this._store.pipe(select(fromSettings.getSectionTranslations('UseAContent')));
        this._subs.add(this.isGlobalPortal$.subscribe(function (value) {
            _this.isGlobalPortal = value;
        }));
        // Capture controlsText value for use in ConfirmDialogs
        this._subs.add(this.controlsText$.subscribe(function (t) { return (_this.controlsText = t); }));
        // Set up observables
        this.user$ = this._store.pipe(select(fromAuth.getUser));
        // set up dummy form until data is ready
        this.contentEntryForm = this._fb.group({
            title: [''],
            patientTitle: [''],
            subtitle: [''],
            category: [''],
            comments: [''],
            sendtoall: [false],
            requiredSections: this._fb.array([]),
            sections: this._fb.array([]),
            attachments: this._fb.array([]),
        });
    }
    Object.defineProperty(ContentEditFormComponent.prototype, "requiredSections", {
        get: function () {
            return this.contentEntryForm.get('requiredSections');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentEditFormComponent.prototype, "entrySections", {
        get: function () {
            return this.contentEntryForm.get('sections');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentEditFormComponent.prototype, "attachments", {
        get: function () {
            return this.contentEntryForm.get('attachments');
        },
        enumerable: true,
        configurable: true
    });
    ContentEditFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // fetch user info and categories
        this._subs.add(this.user$.subscribe(function (user) { return (_this.user = user); }));
        // Only allow a single attachment-
        this._subs.add(this.attachments.valueChanges.subscribe(function (v) {
            _this.attachmentExists$.next(_this.attachments.length > 0);
        }));
        // watch for status changes on the title field
        this._subs.add(this.contentEntryForm.get('title').statusChanges.subscribe(function (status) {
            switch (status) {
                case 'VALID':
                    _this.titleInvalid$.next(false);
                    break;
                case 'PENDING':
                    break; // no change previously assigned validity while checking
                case 'INVALID':
                    _this.titleInvalid$.next(true);
                    break;
                default:
                    _this.titleInvalid$.next(true);
            }
        }));
        this._subs.add(this._updates$
            .pipe(ofType(ContentActionTypes.CreateEntrySuccess, ContentActionTypes.SaveEntrySuccess))
            .subscribe(function (action) {
            if (action.payload.languageCode === _this.languageCode) {
                _this._updateStatus('PRISTINE');
            }
        }));
    };
    ContentEditFormComponent.prototype.ngOnChanges = function (changes) {
        var contentEntryChanges = changes.contentEntry;
        if (contentEntryChanges && contentEntryChanges.currentValue) {
            if (contentEntryChanges.previousValue == null) {
                this.setupForm();
            }
            else {
                this.activeEntry.id = this.contentEntry.id;
            }
        }
    };
    ContentEditFormComponent.prototype.ngOnDestroy = function () {
        // remove all component subscriptions
        this._subs.unsubscribe();
        // stop watching for Successful updates of Save
        this._destroyed$.next(true);
        this._destroyed$.complete();
    };
    ContentEditFormComponent.prototype.setupForm = function () {
        var _this = this;
        if (this.contentEntry) {
            this.parentInfo$ = this._store.pipe(select(fromContent.getParentById(this.contentEntry.parentid)));
            this.subtitle = this.contentEntry.sections.find(function (e) { return e.type === 'subtitle'; });
            this.addFile = this.contentEntry.isfileonly;
            this.activeEntry = cloneDeep(this.contentEntry);
            this.sections = cloneDeep(this.contentEntry.sections);
            var patientTitleValidators = this.activeEntry.fromdatasync
                ? [Validators.minLength(3), Validators.maxLength(50)]
                : [];
            this.contentEntryForm = this._fb.group({
                title: [
                    {
                        value: this.activeEntry.name || '',
                        disabled: this.activeEntry.fromdatasync,
                    },
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(3),
                        Validators.maxLength(50),
                    ]),
                    [
                        validateContentName(this._contentService, this.checkingTitle$, this.activeEntry.name),
                    ],
                ],
                patientTitle: [
                    {
                        value: this._getTitleSectionValue(this.sections),
                        disabled: !this.activeEntry.fromdatasync,
                    },
                    // note html in content-edit-title.component
                    // is expecting to handle 'required' and 'nameExists' errors from validateContentName
                    // but the below validator only checks length.
                    patientTitleValidators,
                ],
                subtitle: [
                    {
                        value: this.subtitle.content,
                        disabled: false,
                    },
                    [Validators.maxLength(60)],
                ],
                category: [
                    {
                        value: this.activeEntry.contentcategory,
                        disabled: false,
                    },
                ],
                comments: [
                    {
                        value: this.activeEntry.comments,
                        disabled: false,
                    },
                    [Validators.maxLength(300)],
                ],
                sendtoall: [
                    {
                        value: this.activeEntry.sendtoall,
                        disabled: false,
                    },
                ],
                requiredSections: this._fb.array([]),
                sections: this._fb.array([]),
                attachments: this._fb.array(this.contentEntry.attachments.map(function (a) { return _this._buildAttachment(a); })),
            });
            if (!this.contentEntryForm.get('title').valid) {
                this.titleInvalid$.next(true);
            }
            // process sections for content form
            this._processSections(this.sections);
            // does an attachment already exist
            this.attachmentExists$.next(this.attachments.length > 0);
            if (this.contentEntry.id == null) {
                this._updateStatus('DIRTY');
            }
            this._subs.add(this.contentEntryForm.valueChanges
                .pipe(debounceTime(500))
                .subscribe(function () {
                _this._updateStatus(_this._formHasChanged() ? 'DIRTY' : 'PRISTINE');
            }));
        }
    };
    ContentEditFormComponent.prototype._updateStatus = function (newStatus) {
        if (this._currentStatus !== newStatus) {
            this._currentStatus = newStatus;
            this.status.emit(newStatus);
        }
    };
    ContentEditFormComponent.prototype._getTitleSectionValue = function (sections) {
        return sections.find(function (s) { return s.type === 'title'; }).content || null;
    };
    ContentEditFormComponent.prototype._goToList = function (id) {
        this._navigationService.navigate(['content', 'list', id]);
    };
    // types mixed, intro and video
    ContentEditFormComponent.prototype._buildSection = function (section) {
        return this._fb.group(tslib_1.__assign({}, (section.id ? { id: section.id } : {}), { content: section.content, title: [section.title, Validators.maxLength(50)], type: section.type, ordernumber: section.ordernumber, isrequired: section.isrequired }));
    };
    ContentEditFormComponent.prototype._buildAttachment = function (attachment) {
        return this._fb.group(tslib_1.__assign({}, attachment));
    };
    ContentEditFormComponent.prototype._processRequiredSections = function (sections) {
        var _this = this;
        var reqSections = sections.map(function (s) { return _this._buildSection(s); });
        this.contentEntryForm.setControl('requiredSections', this._fb.array(reqSections));
    };
    ContentEditFormComponent.prototype._processNonRequiredSections = function (sections) {
        var _this = this;
        var nonReqSections = sections
            .filter(function (s) { return !s.isrequired; })
            .map(function (s) { return _this._buildSection(s); });
        this.contentEntryForm.setControl('sections', this._fb.array(nonReqSections));
    };
    ContentEditFormComponent.prototype._processSections = function (sections) {
        // build required
        this._processRequiredSections(this.sections.filter(function (s) { return s.isrequired && s.type !== 'subtitle'; }));
        // build non-required
        this._processNonRequiredSections(this.sections.filter(function (s) { return !s.isrequired; }));
    };
    ContentEditFormComponent.prototype._getOrderNumber = function () {
        return this.entrySections.length + 4 + 1;
    };
    ContentEditFormComponent.prototype._formHasChanged = function () {
        var _this = this;
        var fV = this.contentEntryForm.value;
        // Title and sections need to be updated to reflect subtitle
        var title = this.activeEntry.fromdatasync
            ? this.activeEntry.name // original title, as can't be changed
            : fV.title; // form title as can be changed
        var sections = this._constructSections(fV).map(function (s) {
            if (s.type === 'title') {
                return tslib_1.__assign({}, s, { content: _this.activeEntry.fromdatasync
                        ? fV.patientTitle // if from datasync patient title exists
                        : title });
            }
            else {
                return s;
            }
        });
        // only pick the relevant sections of content that might
        // be changed
        var currentValues = tslib_1.__assign({}, pick(fV, [
            'title',
            'comments',
            'category',
            'attachments',
            'sendtoall',
        ]), { sections: sections });
        var originalValues = {
            category: this.activeEntry.contentcategory,
            comments: this.activeEntry.comments,
            title: this.activeEntry.name,
            sections: this.activeEntry.sections,
            attachments: this.activeEntry.attachments,
            sendtoall: this.activeEntry.sendtoall || false,
        };
        // don't compare title if fromdatasync as title is locked
        // patient friendly title exists within sections so is checked in both scenarios
        var toCompare = this.activeEntry.fromdatasync
            ? ['comments', 'category', 'attachments', 'sections', 'sendtoall']
            : [
                'title',
                'comments',
                'category',
                'attachments',
                'sections',
                'sendtoall',
            ];
        return toCompare.some(function (key) { return !equal(currentValues[key], originalValues[key]); });
    };
    ContentEditFormComponent.prototype.checkIntroError = function (status) {
        this.introductionError.next(status);
    };
    ContentEditFormComponent.prototype.setOrderOfSections = function (contentSections) {
        var _this = this;
        var mappedSections = contentSections.map(function (s, i) { return (tslib_1.__assign({}, s, { ordernumber: i + 4 + 1 })); });
        var nonReqSections = mappedSections.map(function (s) { return _this._buildSection(s); });
        this.contentEntryForm.setControl('sections', this._fb.array(nonReqSections));
    };
    ContentEditFormComponent.prototype.exitForm = function () {
        this._navigationService.navigate([
            'content',
            'list',
            this.activeEntry.contentcategoryid,
        ]);
    };
    ContentEditFormComponent.prototype.removeForm = function (id) {
        this.entrySections.removeAt(id);
    };
    ContentEditFormComponent.prototype.removeAttachment = function (id) {
        this.attachments.removeAt(id);
        this.attachmentExists$.next(this.attachments.length > 0);
        this.change.detectChanges();
    };
    ContentEditFormComponent.prototype.addEntry = function () {
        this.entrySections.push(this._buildSection(new NewContentEntrySection({
            ordernumber: this._getOrderNumber(),
        })));
    };
    ContentEditFormComponent.prototype._getCategoryId = function (type) {
        for (var _i = 0, _a = this.categories; _i < _a.length; _i++) {
            var category = _a[_i];
            if (category.name === type) {
                return category.id;
            }
        }
        // if not found return 0
        return 0;
    };
    ContentEditFormComponent.prototype.save = function () {
        var _this = this;
        var language = this.languageCode === 'default'
            ? this.languages.find(function (l) { return l.isdefault; })
            : this.languages.find(function (l) { return l.code === _this.languageCode; });
        var messageText = this.isMultiLingual
            ? this.controlsText.PublishDialogWithLanguageMessage(language.name)
            : this.controlsText.PublishDialogMessage;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                information: this.isMultiLingual
                    ? this.controlsText.PublishDialogInformation
                    : undefined,
                message: messageText,
                text: {
                    Cancel: this.controlsText.Cancel,
                    Confirm: this.controlsText.Publish,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this._performSave();
            }
        });
    };
    // If either the title or introduction is empty then the entry should be saved as isEmpty
    ContentEditFormComponent.prototype._isEmpty = function (entry) {
        if (entry.isfileonly) {
            return (!entry.name || entry.name.length === 0 || entry.attachments.length === 0);
        }
        else {
            return entry.sections.every(function (s) {
                return s.type === 'title' || s.type === 'subtitle'
                    ? true
                    : s.content.length === 0 || s.content === null;
            });
        }
    };
    ContentEditFormComponent.prototype._constructSections = function (newEntry) {
        var requiredSections = newEntry.requiredSections;
        var nonrequiredSections = newEntry.sections;
        var subtitle = tslib_1.__assign({}, this.subtitle, { content: newEntry.subtitle });
        var allRequired = requiredSections
            .concat([subtitle])
            .sort(function (a, b) { return a.ordernumber - b.ordernumber; });
        var allSections = allRequired.concat(nonrequiredSections);
        return allSections;
    };
    ContentEditFormComponent.prototype._performSave = function () {
        var _this = this;
        var newEntry = this.contentEntryForm.value;
        var categoryId = this._getCategoryId(newEntry.category);
        var updatedSections = this._constructSections(newEntry);
        var attachments = newEntry.attachments;
        // Check introduction section is less than 1000 characters
        var introduction = updatedSections.find(function (s) { return s.title === 'Introduction'; });
        if (!this.activeEntry.isfileonly &&
            !this.activeEntry.parentid &&
            (introduction.content.length === 0 || !introduction.content)) {
            this.introductionError.next(true);
        }
        else {
            var title_1 = this.activeEntry.fromdatasync
                ? this.activeEntry.name
                : newEntry.title;
            var updatedSectionsWithTitle = updatedSections.map(function (s) {
                if (s.type === 'title') {
                    return tslib_1.__assign({}, s, { content: _this.activeEntry.fromdatasync
                            ? newEntry.patientTitle
                            : title_1 });
                }
                else {
                    return s;
                }
            });
            var entryToSave = {
                id: this.activeEntry.id,
                contentcategoryid: categoryId,
                contentcategory: newEntry.category,
                // if fromdatasync, name is disabled so need
                // to use the original name
                name: title_1,
                comments: newEntry.comments,
                // if fromdatasync is undefined it is false
                fromdatasync: this.activeEntry.fromdatasync || false,
                // automatically remove wasmigrated once amended
                wasmigrated: false,
                isfileonly: this.activeEntry.isfileonly,
                ishidden: this.activeEntry.ishidden,
                // isempty is calculated below
                isempty: false,
                isactive: this.activeEntry.isactive,
                sendtoall: newEntry.sendtoall,
                attachments: attachments,
                sections: updatedSectionsWithTitle,
            };
            this.activeEntry = tslib_1.__assign({}, entryToSave, { isempty: this._isEmpty(entryToSave) });
            for (var i = 0; i < this.activeEntry.sections.length; i++) {
                var section = this.activeEntry.sections[i];
                section.ordernumber = i + 1;
            }
            if (this.activeEntry.id == null) {
                this._store.dispatch(new ContentActions.CreateEntry({
                    contentEntry: this.activeEntry,
                    languageCode: this.languageCode,
                    parentId: this.parentId,
                }));
            }
            else {
                this._store.dispatch(new ContentActions.SaveEntry({
                    contentEntry: this.activeEntry,
                    languageCode: this.languageCode,
                }));
            }
        }
    };
    ContentEditFormComponent.prototype.applyTemplate = function (applyTemplateOptions) {
        var method = applyTemplateOptions.options.method;
        var sections = applyTemplateOptions.template.entries;
        method === 'append'
            ? this._appendTemplate(sections)
            : this._overwriteTemplate(sections);
    };
    // This appends each section from the chosen template to the current form.
    ContentEditFormComponent.prototype._appendTemplate = function (sections) {
        var _this = this;
        sections
            .filter(function (s) { return !s.isrequired; })
            .map(function (s, i) {
            return new NewContentEntrySection({
                ordernumber: _this._getOrderNumber() + i,
                title: s.title,
            });
        })
            .forEach(function (e) {
            _this.entrySections.push(_this._buildSection(e));
        });
    };
    // this clears the current form sections and then adds the sections from
    // the chosen template
    ContentEditFormComponent.prototype._overwriteTemplate = function (sections) {
        var sectionsToAdd = sections
            .filter(function (s) { return !s.isrequired; })
            .map(function (s, i) {
            return new NewContentEntrySection({
                ordernumber: s.ordernumber,
                title: s.title,
                type: s.type,
            });
        });
        this._processNonRequiredSections(sectionsToAdd);
    };
    // add file to content
    ContentEditFormComponent.prototype.openFileDialog = function (isEmbedded, isFileOnly, type) {
        var _this = this;
        // had a valid title been added?
        var titleIsInvalid = this.contentEntryForm.get('title').invalid;
        if (!titleIsInvalid) {
            var title = this.contentEntryForm.get('title').value;
            if (this.attachments.length === 0) {
                var validAccepts = this._S3.fetchAccepts(type);
                var fileDialog = this.dialog.open(AddFileComponent, {
                    data: {
                        contentEntry: this.contentEntry,
                        accept: validAccepts,
                        fileName: title,
                        public: false,
                        patientOnly: false,
                    },
                });
                fileDialog
                    .afterClosed()
                    .subscribe(function (file) {
                    if (file && file.attachment) {
                        _this.attachments.push(_this._buildAttachment(file.attachment));
                        _this.attachmentExists$.next(_this.attachments.length > 0);
                        _this.change.markForCheck();
                        _this.change.detectChanges();
                    }
                });
            }
        }
    };
    ContentEditFormComponent.prototype.openViewChildrenModal = function () {
        var _this = this;
        this.dialog.open(ViewChildrenContainer, {
            data: {
                parentId: this.contentEntry.id,
                contentId: null,
                categoryId: this.contentEntry.contentcategoryid,
            },
            width: '550px',
        });
        this._subs.add(this._updates$
            .pipe(ofType(LinkContentActionTypes.DeleteAllContentLinksSuccess))
            .subscribe(function () {
            _this.contentEntry.children = [];
            _this.change.markForCheck();
            _this.change.detectChanges();
        }));
    };
    ContentEditFormComponent.prototype.navigateToParent = function (parentId) {
        this._navigationService.navigate([
            'content',
            'doc',
            'edit',
            this.activeEntry.contentcategoryid,
            parentId,
        ]);
    };
    ContentEditFormComponent.prototype.copyContent = function (id) {
        var _this = this;
        this._contentService
            .getContentEntryById(id)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(function (res) {
            _this._copyFields(res.sections);
            _this._copyRequiredSections(res.sections);
            return _this._appendSections(res.sections);
        });
    };
    // This function copy the source subtitle and patient title to destination content
    ContentEditFormComponent.prototype._copyFields = function (sections) {
        var sourceSubtitle = sections.find(function (item) { return item.type === 'subtitle'; });
        this.contentEntryForm.patchValue({
            subtitle: sourceSubtitle.content,
        });
        this.contentEntryForm.patchValue({
            patientTitle: this._getTitleSectionValue(sections),
        });
    };
    // This function cherry picks and changes the content of the required sections
    ContentEditFormComponent.prototype._copyRequiredSections = function (sections) {
        var rawRequiredSections = this.requiredSections.value;
        var introIdx = rawRequiredSections.findIndex(function (item) { return item.type === 'introduction'; });
        var videoIdx = rawRequiredSections.findIndex(function (item) { return item.type === 'video'; });
        var sourceIntroSection = sections.find(function (item) { return item.type === 'introduction'; });
        var sourceVideoSection = sections.find(function (item) { return item.type === 'video'; });
        if (sourceIntroSection && introIdx > -1) {
            var Intro = this.requiredSections.at(introIdx);
            Intro.patchValue({
                content: sourceIntroSection.content,
            });
        }
        if (sourceVideoSection && videoIdx > -1) {
            var Video = this.requiredSections.at(videoIdx);
            Video.patchValue({
                content: sourceVideoSection.content,
            });
        }
    };
    // This appends each section from the source Content to the destination content.
    ContentEditFormComponent.prototype._appendSections = function (sections) {
        var _this = this;
        sections
            .filter(function (section) { return !section.isrequired; })
            .map(function (section, index) {
            return new NewContentEntrySection({
                ordernumber: _this._getOrderNumber() + index,
                title: section.title,
                content: section.content,
                type: section.type,
            });
        })
            .forEach(function (section) {
            _this.entrySections.push(_this._buildSection(section));
        });
    };
    return ContentEditFormComponent;
}());
export { ContentEditFormComponent };
