/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/dashboard-folders/dashboard-folders.component.ngfactory";
import * as i3 from "../../components/dashboard-folders/dashboard-folders.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../core/components/drugs-table/drugs-table.component.ngfactory";
import * as i6 from "../../../core/components/drugs-table/drugs-table.component";
import * as i7 from "./dashboard.component";
import * as i8 from "@ngrx/store";
import * as i9 from "../../../core/services/navigation.service";
import * as i10 from "angulartics2";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "dashboard-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "portal-dashboard-folders", [], null, [[null, "goToLabel"], [null, "goToMessages"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("goToLabel" === en)) {
        var pd_0 = (_co.gotoMessages($event) !== false);
        ad = (pd_0 && ad);
    } if (("goToMessages" === en)) {
        var pd_1 = (_co.goToMessages($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DashboardFoldersComponent_0, i2.RenderType_DashboardFoldersComponent)), i1.ɵdid(2, 49152, null, 0, i3.DashboardFoldersComponent, [], { messagingSummary: [0, "messagingSummary"], labels: [1, "labels"], text: [2, "text"], summary: [3, "summary"] }, { goToLabel: "goToLabel", goToMessages: "goToMessages" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "portal-drugs-table", [], null, [[null, "gotoPatient"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gotoPatient" === en)) {
        var pd_0 = (_co.gotoPatient($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DrugsTableComponent_0, i5.RenderType_DrugsTableComponent)), i1.ɵdid(8, 49152, null, 0, i6.DrugsTableComponent, [], { title: [0, "title"], emptyMessage: [1, "emptyMessage"], medications: [2, "medications"], text: [3, "text"], displayedColumns: [4, "displayedColumns"] }, { gotoPatient: "gotoPatient" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.messagingSummary$)); var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.labels$)); var currVal_2 = i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 5).transform(_co.sectionText$)); var currVal_3 = i1.ɵunv(_v, 2, 3, i1.ɵnov(_v, 6).transform(_co.summary$)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.nonTimedTriggerTableText.UpcomingDrugs; var currVal_5 = _co.nonTimedTriggerTableText.NoDrugs; var currVal_6 = i1.ɵunv(_v, 8, 2, i1.ɵnov(_v, 9).transform(_co.nonTimedTriggerMedication$)); var currVal_7 = _co.nonTimedTriggerTableText; var currVal_8 = _co.displayedColumnList; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 245760, null, 0, i7.DashboardComponent, [i8.Store, i9.NavigationService, i10.Angulartics2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("portal-dashboard", i7.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
