var MarkMessageUnreadRequest = /** @class */ (function () {
    function MarkMessageUnreadRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId;
        this.Action = 'MarkMessageUnread';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
    }
    return MarkMessageUnreadRequest;
}());
export { MarkMessageUnreadRequest };
