import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Category } from '../../models/category';
import { ContentPack } from '../../models/content-packs';
import { ContentTemplate } from '../../models/content-template';

@Component({
  selector: 'portal-content-directory',
  templateUrl: './content-directory.component.html',
  styleUrls: ['./content-directory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentDirectoryComponent {
  @Input() text: any;
  @Input() categories: Category[];
  @Input() packs: ContentPack[] = [];
  @Input() templates: ContentTemplate[];
  @Input() activeSection: string; // template, list or pack
  @Input() selectedId: number; // id of category or template
  @Input() activeDirectory = '';
  @Input() contentEditorEnabled: boolean;
  @Input() isGlobalPortal: boolean;
  @Output() goToPage = new EventEmitter<string>(); // trigger navigation
  @Output() addNew = new EventEmitter(); // create new template or category type
  @Output() createFolder = new EventEmitter();
  @Output() editFolderName = new EventEmitter<Category>();
  @Output() createPack = new EventEmitter();
  @Output() editPackName = new EventEmitter<ContentPack>();
  @Output() openSection = new EventEmitter<string>();

  public directoryOptions: {
    folders: string;
    templates: string;
    packs: string;
  } = {
    folders: 'folders',
    templates: 'templates',
    packs: 'packs',
  };

  public sectionOptions: {
    list: string;
    packs: string;
    template: string;
  } = {
    list: 'list',
    packs: 'packs',
    template: 'template',
  };

  public config: PerfectScrollbarConfigInterface = {};

  public goToContentPack(contentPack: ContentPack) {
    this.goToPage.emit(`/content/pack/${contentPack.id}`);
  }

  public goToCategoryList(category: Category) {
    this.goToPage.emit(`/content/list/${category.id}`);
  }

  public goToTemplateEdit(template: ContentTemplate) {
    this.goToPage.emit(`/content/template/edit/${template.id}`);
  }

  public goToCreateTemplate(): void {
    this.goToPage.emit('/content/template/create');
  }

  public openDirectorySection(sectionToOpen: string): void {
    this.openSection.emit(sectionToOpen);
  }
}
