import { FileInStorage } from './file-in-storage';

export class ContentEntrySection {
  public id?: number;
  public ordernumber: number;
  public isrequired: boolean;
  public title: string;
  public type: string;
  public content: string;
}

export class ContentAttachment {
  public id?: number;
  public documentname: string;
  public filetype: string;
  public filesize: number;
  public uri: string;
  public isGlobalFile?: boolean;
}

export class ContentEntryHeader {
  public id?: number;
  public contentcategoryid: number;
  public contentcategory: string;
  public name: string;
  public comments: string;
  public fromdatasync: boolean;
  public wasmigrated: boolean;
  public isfileonly: boolean;
  public ishidden: boolean;
  public contentName?: string;
  public isempty: boolean;
  public sendtoall: boolean;
  public haschildren?: boolean;
  public parentid?: number;
  public lastmodifiedby?: string;
  public salvemodified?: Date;
  public translations: string[] | null;
}

export interface ContentTranslation {
  cliniclanguagename: string;
  contententryheaderid: number;
  languagecode: string;
  languagename: string;
}

export interface ChildInfo {
  id: number;
  clinicname: string;
  linkid: number;
}

export interface GlobalPortalContentEntry {
  [key: string]: ContentEntry;
}

export class ContentEntry extends ContentEntryHeader {
  public isactive?: boolean;
  public versionid?: number;
  public children?: ChildInfo[];
  // @ts-ignore complaining about `translations` in ContentEntryHeader
  public translations?: ContentTranslation[];
  public sections: ContentEntrySection[];
  public attachments: FileInStorage[];

  static createNew(
    contentCategory: string,
    contentCategoryId: number,
    isFileOnly: boolean,
    parentId?: number,
  ): ContentEntry {
    const contentEntry = new ContentEntry();
    contentEntry.attachments = [];
    contentEntry.comments = '';
    contentEntry.contentcategory = contentCategory;
    contentEntry.contentcategoryid = contentCategoryId;
    contentEntry.fromdatasync = false;
    contentEntry.isactive = true;
    contentEntry.isempty = true;
    contentEntry.isfileonly = isFileOnly;
    contentEntry.ishidden = false;
    contentEntry.name = '';
    contentEntry.sendtoall = false;
    contentEntry.sections = [
      {
        ordernumber: 1,
        isrequired: true,
        title: 'Title',
        type: 'title',
        content: '',
      },
      {
        ordernumber: 2,
        isrequired: true,
        title: 'Subtitle',
        type: 'subtitle',
        content: '',
      },
      {
        ordernumber: 3,
        isrequired: true,
        title: 'Introduction',
        type: 'introduction',
        content: '',
      },
      {
        ordernumber: 4,
        isrequired: true,
        title: 'Video',
        type: 'video',
        content: '',
      },
    ];
    contentEntry.wasmigrated = false;

    if (parentId) {
      contentEntry.parentid = parentId;
    }

    return contentEntry;
  }

  static createCopy(
    contentCategory: string,
    contentCategoryId: number,
    isFileOnly: boolean,
    parentId?: number,
    name?: string,
  ): ContentEntry {
    const contentEntry = new ContentEntry();
    contentEntry.attachments = [];
    contentEntry.comments = '';
    contentEntry.contentcategory = contentCategory;
    contentEntry.contentcategoryid = contentCategoryId;
    contentEntry.fromdatasync = false;
    contentEntry.isactive = true;
    contentEntry.isempty = true;
    contentEntry.isfileonly = isFileOnly;
    contentEntry.ishidden = false;
    contentEntry.name = name;
    contentEntry.sendtoall = false;
    contentEntry.sections = [
      {
        ordernumber: 1,
        isrequired: true,
        title: 'Title',
        type: 'title',
        content: '',
      },
      {
        ordernumber: 2,
        isrequired: true,
        title: 'Subtitle',
        type: 'subtitle',
        content: '',
      },
      {
        ordernumber: 3,
        isrequired: true,
        title: 'Introduction',
        type: 'introduction',
        content: '',
      },
      {
        ordernumber: 4,
        isrequired: true,
        title: 'Video',
        type: 'video',
        content: '',
      },
    ];
    contentEntry.wasmigrated = false;

    if (parentId) {
      contentEntry.parentid = parentId;
    }

    return contentEntry;
  }
}

export interface GroupedContentEntries {
  [key: string]: ContentEntryHeader[];
}

export class AssignedContentEntry extends ContentEntry {
  public frommeditex: boolean;
  public assignmentid: number;
}

export class TableContentEntry {
  public id?: number;
  public name: string;
  public isEmpty: boolean;
  public isMigrated: boolean;
  public fromDataSync: boolean;
  public comments: string;
  public category: string;
  public categoryId: number;
  public link: number;
  public hasChildren: boolean;
  public parentId: number;
  public sendToAll: boolean;
  public lastModifiedBy: string;
  public salveModified: Date;
  public translations: string[] | null;
}

export class NewContentEntrySection {
  public ordernumber: number;
  public isrequired: boolean;
  public title: string;
  public type: string;
  public content: string;

  constructor({
    ordernumber,
    content = '',
    type = 'mixed',
    title = '',
    isrequired = false,
  }) {
    this.ordernumber = ordernumber;
    this.isrequired = isrequired;
    this.type = type;
    this.title = title;
    this.content = content;
  }
}

export interface ViewChildParams {
  contentId: number;
  parentId: number;
  categoryId: number;
}

export interface DeleteWithChildIdResponse {
  status: string;
  params: {
    childId: number;
  };
}

export interface DeleteWithParentIdResponse {
  status: string;
  params: {
    parentId: number;
  };
}

export interface DeleteWithChildrenIdArrayResponse {
  status: string;
  params: {
    children: number[];
  };
}
