import { Routes } from '@angular/router';
import { AuthGuard } from './auth/services/auth-guard.service';
import { PageNotFoundComponent } from './core/containers/page-not-found/page-not-found.component';
import { UnauthorisedReactWrapperComponent } from './core/containers/unauthorised/unauthorised-react-wrapper.component';
import { FeatureGuard } from './core/services/feature-guard.service';
import { GlobalPortalGuard } from './core/services/global-guard.service';
import { VideoCallsPageComponent } from './video-calls/containers/video-calls-page/video-calls-page.container';
var ɵ0 = { optionsPath: 'videoCallOptions.enabled' };
var routes = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'unauthorised',
        component: UnauthorisedReactWrapperComponent,
        pathMatch: 'full',
    },
    {
        path: 'video-calls',
        component: VideoCallsPageComponent,
        canActivate: [AuthGuard, FeatureGuard, GlobalPortalGuard],
        data: ɵ0,
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
