var _this = this;
import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { MessageEditor } from '@react/components';
import { useTranslations } from '@react/lib/i18n';
import React, { useCallback, useEffect, useState, } from 'react';
import { EMPTY_CONVERSATION_STATE, MESSAGE_MAX_LENGTH, MESSAGE_MIN_LENGTH, } from '../constants';
export var ChatInput = function (_a) {
    var conversation = _a.conversation, onSubmit = _a.onSubmit, onChange = _a.onChange, disabled = _a.disabled, getInitialConversationState = _a.getInitialConversationState, setConversationState = _a.setConversationState;
    var t = useTranslations().t;
    var _b = useState(EMPTY_CONVERSATION_STATE), messageValue = _b[0], setMessageValue = _b[1];
    useEffect(function () {
        setMessageValue(getInitialConversationState(conversation.messageid));
    }, [conversation.messageid]);
    var handleChange = useCallback(function (value) {
        setMessageValue(value);
        setConversationState(conversation.messageid, value);
        onChange(value);
    }, [conversation.messageid, onChange, setMessageValue, setConversationState]);
    var handleSubmit = useCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setConversationState(conversation.messageid, EMPTY_CONVERSATION_STATE);
                    setMessageValue(EMPTY_CONVERSATION_STATE);
                    return [4 /*yield*/, onSubmit(messageValue)];
                case 1:
                    _a.sent();
                    onChange(EMPTY_CONVERSATION_STATE);
                    return [2 /*return*/];
            }
        });
    }); }, [
        messageValue,
        conversation.messageid,
        setConversationState,
        setMessageValue,
    ]);
    return (React.createElement(ChatInputStyled, { className: "chat-input" },
        React.createElement(MessageEditor, { maxLength: MESSAGE_MAX_LENGTH, onActionClick: handleSubmit, actionText: t.Messages.Send, isActionActive: messageValue.length >= MESSAGE_MIN_LENGTH, onChange: handleChange, value: messageValue, patientId: conversation.patientid, disabled: disabled })));
};
var ChatInputStyled = styled.form(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
var templateObject_1;
