import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import * as fromRoot from '../../reducers';
import * as fromContent from '../reducers';
import { UpdateForm } from '../reducers/forms.reducer';

const FORM_SUBMIT_SUCCESS = '[Form] FORM_SUBMIT_SUCCESS';
const FORM_SUBMIT_ERROR = '[Form] FORM_SUBMIT_ERROR';

class FormSubmitSuccess implements Action {
  readonly type = FORM_SUBMIT_SUCCESS;

  constructor(
    public payload: {
      path: string;
      id: number;
    },
  ) {}
}
class FormSubmitError implements Action {
  readonly type = FORM_SUBMIT_ERROR;

  constructor(
    public payload: {
      path: string;
      error: any;
    },
  ) {}
}

export const formSuccessAction = (path, id): FormSubmitSuccess => ({
  type: FORM_SUBMIT_SUCCESS,
  payload: {
    path,
    id,
  },
});

export const formErrorAction = (path, error): FormSubmitError => ({
  type: FORM_SUBMIT_ERROR,
  payload: {
    path,
    error,
  },
});

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[connectForm]',
})
export class ConnectFormDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('connectForm') path: string;
  @Input() 'debounce' = 300;
  @Output() error = new EventEmitter<any>();
  @Output() success = new EventEmitter<number>();

  private _subs = new Subscription();
  // formChange: Subscription;
  // formSuccess: Subscription;
  // formError: Subscription;

  constructor(
    private _formGroupDirective: FormGroupDirective,
    private _action$: Actions,
    private _store: Store<fromRoot.State>,
  ) {}

  ngOnInit() {
    // Update form based on current value of the application store
    this._store
      .pipe(select(fromContent.selectForm(this.path)), take(1))
      .subscribe((formValue) => {
        if (formValue) {
          this._formGroupDirective.form.patchValue(formValue);
        }
      });

    // update form when valueChanges updates
    this._subs.add(
      this._formGroupDirective.form.valueChanges
        .pipe(debounceTime(this.debounce))
        .subscribe((value) => {
          this._store.dispatch(new UpdateForm({ path: this.path, value }));
        }),
    );

    // watch to see when form is successfully submitted
    this._subs.add(
      this._action$
        // TODO: Fix (should `pipe` and `ofType` be switched around?)
        // @ts-ignore
        .ofType<FormSubmitSuccess>(FORM_SUBMIT_SUCCESS)
        .pipe(filter(({ payload }) => payload.path === this.path))
        .subscribe(({ payload }) => {
          // Reset Store Value
          this._store.dispatch(
            new UpdateForm({
              path: this.path,
              value: {
                category: '',
              },
            }),
          );
          // Reset Form fields
          this._formGroupDirective.form.reset();
          // pass on id to trigger navigation
          this.success.emit(payload.id);
        }),
    );

    // watch for errors on form submission
    this._subs.add(
      this._action$
        // TODO: Fix (should `pipe` and `ofType` be switched around?)
        // @ts-ignore
        .ofType<FormSubmitError>(FORM_SUBMIT_ERROR)
        .pipe(filter(({ payload }) => payload.path === this.path))
        .subscribe(({ payload }) => {
          this.error.emit(payload.error);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
