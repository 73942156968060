import { Inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';

export class CustomMatPaginator extends MatPaginatorIntl {
  public sectionText: any;

  // Set default english values
  public itemsPerPageLabel = 'Items per page';
  public firstPageLabel = 'First page';
  public previousPageLabel = 'Previous page';
  public nextPageLabel = 'Next page';
  public lastPageLabel = 'Last page';

  constructor(@Inject(Store) public _store: Store<fromRoot.State>) {
    super();
    // Watch translations object and replace text for paginator as
    // required, depending on language
    this._store
      .pipe(select(fromSettings.getSectionTranslations('NewContentList')))
      .subscribe((t: any) => {
        this.itemsPerPageLabel = t.ItemsPerPage;
        this.firstPageLabel = t.FirstPage;
        this.previousPageLabel = t.PreviousPage;
        this.nextPageLabel = t.NextPage;
        this.lastPageLabel = t.LastPage;
      });
  }
}
