import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { sortByKeyAlphabetically } from 'src/app/shared/utils';
import * as fromRoot from '../../../reducers';
import { SavingCategory, } from '../../actions/content.actions';
import { validateFolderName } from '../../services/async-validators/validate-folder-name';
import { ContentActionTypes, } from './../../actions/content.actions';
import { ContentService } from './../../services/content.service';
var EditFolderNameComponent = /** @class */ (function () {
    function EditFolderNameComponent(_fb, _store, _updates, dialogRef, _contentService, data) {
        this._fb = _fb;
        this._store = _store;
        this._updates = _updates;
        this.dialogRef = dialogRef;
        this._contentService = _contentService;
        this.data = data;
        this.scrollConfig = {
            suppressScrollX: true,
        };
        this.checkingName$ = new BehaviorSubject(false);
        this.loadingCategory$ = new BehaviorSubject(false);
        this.dataReady$ = new BehaviorSubject(false);
        this.savingName$ = new BehaviorSubject(false);
        this.savedName$ = new BehaviorSubject(false);
        this.errorEncountered$ = new BehaviorSubject(false);
        this.maxNameLength = 50;
        this._subs = new Subscription();
        this._sharedValidators = [
            Validators.minLength(3),
            Validators.maxLength(this.maxNameLength),
        ];
        this.categoryForm = this._fb.group({});
    }
    EditFolderNameComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._loadFormData();
        // watch for errors on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentActionTypes.SavingCategoryError))
            .subscribe(function () {
            _this.savingName$.next(false);
            _this.errorEncountered$.next(true);
        }));
        // watch for success on savingCategory Action
        this._subs.add(this._updates
            .pipe(ofType(ContentActionTypes.SavingCategorySuccess))
            .subscribe(function () {
            _this.savingName$.next(false);
            _this.savedName$.next(true);
            _this.exitDialog(true, 1000);
        }));
    };
    EditFolderNameComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    EditFolderNameComponent.prototype.clearField = function (field) {
        field.setValue('');
        field.markAsDirty();
        field.markAsTouched();
    };
    EditFolderNameComponent.prototype.saveNameChange = function () {
        var _this = this;
        this.errorEncountered$.next(false);
        this.savingName$.next(true);
        var newFolderName = this.categoryName.value;
        var requestData = {
            id: this.category.id,
            name: newFolderName,
            translations: (this.languages || [])
                .filter(function (l) {
                var field = _this.categoryForm.get(l.code);
                return field.value !== '' && field.dirty;
            })
                .map(function (l) { return ({
                languagecode: l.code,
                name: _this.categoryForm.get(l.code).value,
            }); }),
        };
        this._store.dispatch(new SavingCategory(requestData));
    };
    EditFolderNameComponent.prototype.cancelNameChange = function () {
        this.exitDialog(false);
    };
    EditFolderNameComponent.prototype.exitDialog = function (status, time) {
        var _this = this;
        if (status === void 0) { status = false; }
        if (time === void 0) { time = 0; }
        setTimeout(function () {
            _this.dialogRef.close(status);
        }, time);
    };
    EditFolderNameComponent.prototype._loadFormData = function () {
        var _this = this;
        this.loadingCategory$.next(true);
        this.languages$ = this._store.pipe(select(fromRoot.getLanguages), map(function (languages) {
            return languages
                .filter(function (language) { return !language.isdefault; })
                .sort(sortByKeyAlphabetically('name'));
        }));
        this.category$ = this._contentService.getContentCategory(this.data.category.id);
        // Retrieve clinic languages and category data ta set up form
        this._subs.add(combineLatest(this.languages$, this.category$).subscribe(function (_a) {
            var l = _a[0], c = _a[1];
            _this.languages = l;
            _this.category = c;
            // Prepare form based on data
            _this._prepareForm(l, c);
            _this.loadingCategory$.next(false);
        }));
    };
    EditFolderNameComponent.prototype._prepareForm = function (languages, category) {
        this.initialFolderName = category.name;
        // Prepare control for category name
        this.categoryName = new FormControl(this.initialFolderName, [Validators.required].concat(this._sharedValidators), validateFolderName(this._contentService, this.checkingName$, this.initialFolderName));
        // This is necessary because the Material form field only shows errors
        // when a field is touched. If we don't do this then the user will see no
        // errors until they leave the field the first time.
        this.categoryName.markAsTouched();
        // Add control to form
        this.categoryForm.addControl(category.name, this.categoryName);
        var categoryTranslations = {};
        // Prepare translation only if there are languages to translate into
        if (languages) {
            var _a = category.translations, translations = _a === void 0 ? [] : _a;
            for (var _i = 0, languages_1 = languages; _i < languages_1.length; _i++) {
                var language = languages_1[_i];
                categoryTranslations[language.code] = '';
                // Populate translation if translations exist
                for (var _b = 0, translations_1 = translations; _b < translations_1.length; _b++) {
                    var translation = translations_1[_b];
                    if (translation.languagecode === language.code) {
                        categoryTranslations[language.code] = translation.name;
                    }
                }
            }
        }
        for (var _c = 0, _d = Object.keys(categoryTranslations); _c < _d.length; _c++) {
            var key = _d[_c];
            var value = categoryTranslations[key];
            var hasExistingValue = value !== '';
            var newControl = new FormControl(categoryTranslations[key], hasExistingValue
                ? [Validators.required].concat(this._sharedValidators) : this._sharedValidators);
            this.categoryForm.addControl(key, newControl);
            // This is necessary because the Material form field only shows errors
            // when a field is touched. If we don't do this then the user will see no
            // errors until they leave the field the first time.
            newControl.markAsTouched();
        }
        this.dataReady$.next(true);
    };
    return EditFolderNameComponent;
}());
export { EditFolderNameComponent };
