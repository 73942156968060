<mat-card class="patient-list">
  <div class="header">
    <mat-card-title>{{ title }}</mat-card-title>
    <div class="search">
      <mat-icon>search</mat-icon>
      <input
        matInput
        [formControl]="searchControl"
        [placeholder]="text.Search"
      />
    </div>
  </div>

  <ng-container *ngIf="state === dataStatus.Results">
    <p class="table-header">
      <span class="name mat-header-cell">{{ text.Name }}</span>
      <span class="id mat-header-cell">{{ text.Id }}</span>
      <span class="dob mat-header-cell">{{ text.DOB }}</span>
      <span class="count mat-header-cell">#</span>
    </p>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let patient of patientList | keyvalue">
        <mat-expansion-panel-header class="patient-details">
          <p class="name">{{ patient.value.name }}</p>
          <p class="id">{{ patient.value.id }}</p>
          <p class="dob">{{ patient.value.dob | shortNumericDate }}</p>
          <p class="count" [class.warning]="!!warning">
            {{ patient.value.meds.length }}
          </p>
        </mat-expansion-panel-header>
        <portal-patient-medications
          [medications]="patient.value.meds"
          [text]="text"
          (rowSelected)="onRowSelected($event)"
        ></portal-patient-medications>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <div *ngIf="state === dataStatus.NoResults" class="empty-message">
    {{ emptyMessage }}
  </div>

  <div *ngIf="state === dataStatus.NoSearchResults" class="empty-message">
    {{ noSearchResultsMessage }}
  </div>

  <div *ngIf="loading" class="spinner">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</mat-card>
