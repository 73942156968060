import styled from '@emotion/styled';

export const Input = styled.input<{ error?: boolean; fullWidth?: boolean }>`
  -webkit-appearance: none;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.error : theme.colors.text.primary};
  border-radius: 2px;
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.text.primary};
  font-size: 1rem;
  outline: none;
  padding: 0.5rem;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'initial')};

  &::placeholder {
    color: ${({ error, theme }) =>
      error ? theme.colors.error : theme.colors.border.default};
  }

  &:hover,
  &:focus {
    border: ${({ error, theme }) =>
      `1px solid ${error ? theme.colors.error : theme.colors.primary}`};
  }
`;
