var ToastOptions = /** @class */ (function () {
    function ToastOptions(_a) {
        var toast = _a.toast, toastRef = _a.toastRef, undoAction = _a.undoAction, title = _a.title, message = _a.message, type = _a.type, options = _a.options;
        (this.toast = toast || null), (this.toastRef = toastRef);
        this.undoAction = undoAction;
        this.title = title;
        this.message = message;
        this.type = type;
        this.options = options;
    }
    return ToastOptions;
}());
export { ToastOptions };
