import styled from '@emotion/styled';
import { MenuItem, Select as MuiSelect, SelectProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { InputLabel } from './InputLabel';

interface Props extends SelectProps {
  id: string;
  label: string;
  placeholder?: string;
  options: { value: string; label: string }[];
}

export const Select: React.FC<Props> = ({
  id,
  label,
  placeholder,
  value,
  options,
  ...otherProps
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const hasValue = value != null && value !== '';

  const renderValue = (selectedValue: unknown) => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue,
    );
    return selectedOption ? selectedOption.label : placeholder;
  };

  useEffect(() => {
    if (anchorRef.current) {
      setAnchorEl(anchorRef.current);
    }
  }, [anchorRef.current]);

  return (
    <>
      <InputLabel id={id}>{label}</InputLabel>
      <div ref={anchorRef}>
        <StyledSelect
          className={clsx({ 'select-empty': !hasValue })}
          labelId={id}
          renderValue={renderValue}
          value={value}
          MenuProps={{
            anchorEl,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            style: {
              maxHeight: 220,
            },
          }}
          {...otherProps}
        >
          {options.map((option) => (
            <StyledMenuItem
              className="newMessageModal-menuItem"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </div>
    </>
  );
};

Select.defaultProps = {
  displayEmpty: true,
  variant: 'outlined',
};

const StyledSelect = styled(MuiSelect)`
  &.select-empty {
    color: ${({ theme }) => theme.colors.brownGrey};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.paleGray};
  }
`;
