import { useApiClient } from '@react/lib/api';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';
export function useMessageDelete() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (_a) {
        var conversationId = _a.conversationId, messageId = _a.messageId;
        return apiClient.removeMessage(conversationId, messageId);
    }, {
        onSuccess: function () {
            Object.keys(queryCacheKey).forEach(function (cacheKey) {
                queryClient.invalidateQueries(cacheKey);
            });
        },
    });
}
