import { Action } from '@ngrx/store';
import {
  GetContentPackDetailResponse,
  GetContentPacksResponse,
  ServerError,
} from 'src/app/content/models/content-packs';
import {
  AddExclusionResponse,
  PackAssignmentInterface,
  PackAssignmentResponse,
  RemoveExclusionResponse,
  UnassignPackResponse,
} from './../../content/models/content-packs';

export enum PackAssignmentActionTypes {
  GetContentPacks = '[Pack-Assignment] Get Content Packs',
  GetContentPacksSuccess = '[Pack-Assignment] Get Content Packs Success',
  GetContentPacksError = '[Pack-Assignment] Get Content Packs Error',

  GetContentPackDetail = '[Pack-Assignment] Get Content Pack Detail',
  GetContentPackDetailSuccess = '[Pack-Assignment] Get Content Pack Detail Success',
  GetContentPackDetailError = '[Pack-Assignment] Get Content Pack Detail Error',

  SelectActivePackId = '[Pack-Assignment] Select Active Pack Id',
  LockPackItem = '[Pack-Assignment] Lock Pack Item',
  UnlockPackItem = '[Pack-Assignment] Unlock Pack Item',

  AssignPack = '[Pack-Assignment] Assign pack to patient',
  AssignPackSuccess = '[Pack-Assignment] Assign pack to patient Success',
  AssignPackError = '[Pack-Assignment] Assign pack to patient Error',

  UnassignPack = '[Pack-Assignment] Unassign pack to patient',
  UnassignPackSuccess = '[Pack-Assignment] Unassign pack to patient Success',
  UnassignPackError = '[Pack-Assignment] Unassign pack to patient Error',

  AddExclusion = '[Pack-Assignment] Add pack exclusion to patient',
  AddExclusionSuccess = '[Pack-Assignment] Add pack exclusion to patient Success',
  AddExclusionError = '[Pack-Assignment] Add pack exclusion to patient Error',

  RemoveExclusion = '[Pack-Assignment] Remove pack exclusion to patient',
  RemoveExclusionSuccess = '[Pack-Assignment] Remove pack exclusion to patient Success',
  RemoveExclusionError = '[Pack-Assignment] Remove pack exclusion to patient Error',

  OpenAssignedPack = '[Pack-Assignment] Open Assigned Pack',
}

export class GetContentPacks implements Action {
  readonly type = PackAssignmentActionTypes.GetContentPacks;
}
export class GetContentPacksSuccess implements Action {
  readonly type = PackAssignmentActionTypes.GetContentPacksSuccess;

  constructor(public payload: GetContentPacksResponse) {}
}
export class GetContentPacksError implements Action {
  readonly type = PackAssignmentActionTypes.GetContentPacksError;

  constructor(public payload: ServerError) {}
}

export class GetContentPackDetail implements Action {
  readonly type = PackAssignmentActionTypes.GetContentPackDetail;

  constructor(public payload: number) {}
}
export class GetContentPackDetailSuccess implements Action {
  readonly type = PackAssignmentActionTypes.GetContentPackDetailSuccess;

  constructor(public payload: GetContentPackDetailResponse) {}
}
export class GetContentPackDetailError implements Action {
  readonly type = PackAssignmentActionTypes.GetContentPackDetailError;

  constructor(public payload: ServerError) {}
}

export class SelectActivePackId implements Action {
  readonly type = PackAssignmentActionTypes.SelectActivePackId;

  constructor(public payload: number) {}
}

export class LockPackItem implements Action {
  readonly type = PackAssignmentActionTypes.LockPackItem;

  constructor(public payload: number) {}
}
export class UnlockPackItem implements Action {
  readonly type = PackAssignmentActionTypes.UnlockPackItem;

  constructor(public payload: number) {}
}

export class AssignPack implements Action {
  readonly type = PackAssignmentActionTypes.AssignPack;

  constructor(public payload: PackAssignmentInterface) {}
}
export class AssignPackSuccess implements Action {
  readonly type = PackAssignmentActionTypes.AssignPackSuccess;

  constructor(public payload: PackAssignmentResponse) {}
}
export class AssignPackError implements Action {
  readonly type = PackAssignmentActionTypes.AssignPackError;

  constructor(public payload: ServerError) {}
}

export class AddExclusion implements Action {
  readonly type = PackAssignmentActionTypes.AddExclusion;

  constructor(public payload: PackAssignmentInterface) {}
}
export class AddExclusionSuccess implements Action {
  readonly type = PackAssignmentActionTypes.AddExclusionSuccess;

  constructor(public payload: AddExclusionResponse) {}
}
export class AddExclusionError implements Action {
  readonly type = PackAssignmentActionTypes.AddExclusionError;

  constructor(public payload: ServerError) {}
}

export class RemoveExclusion implements Action {
  readonly type = PackAssignmentActionTypes.RemoveExclusion;

  constructor(public payload: PackAssignmentInterface) {}
}
export class RemoveExclusionSuccess implements Action {
  readonly type = PackAssignmentActionTypes.RemoveExclusionSuccess;

  constructor(public payload: RemoveExclusionResponse) {}
}
export class RemoveExclusionError implements Action {
  readonly type = PackAssignmentActionTypes.RemoveExclusionError;

  constructor(public payload: ServerError) {}
}

export class UnassignPack implements Action {
  readonly type = PackAssignmentActionTypes.UnassignPack;

  constructor(public payload: PackAssignmentInterface) {}
}
export class UnassignPackSuccess implements Action {
  readonly type = PackAssignmentActionTypes.UnassignPackSuccess;

  constructor(public payload: UnassignPackResponse) {}
}
export class UnassignPackError implements Action {
  readonly type = PackAssignmentActionTypes.UnassignPackError;

  constructor(public payload: ServerError) {}
}

export class OpenAssignedPack implements Action {
  readonly type = PackAssignmentActionTypes.OpenAssignedPack;

  constructor(public payload: number | null) {}
}

export type PackAssignmentActionsUnion =
  | GetContentPacks
  | GetContentPacksSuccess
  | GetContentPacksError
  | GetContentPackDetail
  | GetContentPackDetailSuccess
  | GetContentPackDetailError
  | SelectActivePackId
  | LockPackItem
  | UnlockPackItem
  | UnassignPack
  | UnassignPackSuccess
  | UnassignPackError
  | AssignPack
  | AssignPackSuccess
  | AssignPackError
  | AddExclusion
  | AddExclusionSuccess
  | AddExclusionError
  | RemoveExclusion
  | RemoveExclusionSuccess
  | RemoveExclusionError
  | OpenAssignedPack;
