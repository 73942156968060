import { EventEmitter, OnDestroy, OnInit, QueryList, } from '@angular/core';
import { IntersectionObserverDirective } from 'src/app/core/directives/interesection-observer';
var VideoCallsListComponent = /** @class */ (function () {
    function VideoCallsListComponent() {
        this.joinVideoCall = new EventEmitter();
        this.editVideoCall = new EventEmitter();
        this.invitePartner = new EventEmitter();
        this.unInvitePartner = new EventEmitter();
        this.reachedScrollEnd = new EventEmitter();
        this.displayedColumns = [
            'patientId',
            'patientName',
            'patientDOB',
            'host',
            'description',
            'when',
            'duration',
            // 'status',
            'join-button',
            'more-button',
            'invite',
        ];
    }
    Object.defineProperty(VideoCallsListComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (value) {
            this._items = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoCallsListComponent.prototype, "partnerInviteEnabled", {
        get: function () {
            return this._partnerInviteEnabled;
        },
        set: function (value) {
            this._partnerInviteEnabled = value;
        },
        enumerable: true,
        configurable: true
    });
    VideoCallsListComponent.prototype.ngOnInit = function () { };
    VideoCallsListComponent.prototype.ngOnDestroy = function () {
        this.detachObservers();
    };
    VideoCallsListComponent.prototype.detachObservers = function () {
        this.isos.forEach(function (iso) { return iso.DetachObserver(); });
    };
    VideoCallsListComponent.prototype.onObserved = function () {
        this.reachedScrollEnd.emit();
    };
    return VideoCallsListComponent;
}());
export { VideoCallsListComponent };
