import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Chip } from '@react/components/Chip';
export var ActiveStyledChip = styled(Chip)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  margin-right: ", "px;\n  background-color: #b8e4de;\n"], ["\n  margin-right: ", "px;\n  background-color: #b8e4de;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var InactiveStyledChip = styled(Chip)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  margin-right: ", "px;\n  background-color: #ffd0d2;\n"], ["\n  margin-right: ", "px;\n  background-color: #ffd0d2;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1, templateObject_2;
