import * as tslib_1 from "tslib";
import { CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useAppointmentTypesQuery } from '../hooks';
import { LoaderWrapper, StyledChip, StyledSearchBar, StyledTableContainer, StyledTableFooter, StyledTableHead, StyledTableRow } from './AppointmentTypeList.styled';
var AppointmentRuleChip = function (props) {
    return props.visible ? React.createElement(StyledChip, { label: props.label }) : null;
};
var ɵ0 = AppointmentRuleChip;
var AppointmentTypeList = function () {
    var _a = useState(''), searchInputValue = _a[0], setSearchInputValue = _a[1];
    var searchTerm = useDebouncedState(searchInputValue, 500);
    var history = useHistory();
    var t = useTranslations().t;
    var _b = useAppointmentTypesQuery({ searchTerm: searchTerm }), data = _b.data, isLoading = _b.isLoading, fetchNextPage = _b.fetchNextPage, hasNextPage = _b.hasNextPage, isFetchingNextPage = _b.isFetchingNextPage, isError = _b.isError;
    useErrorToast(isError, {
        title: t.Admin.AppointmentTypeLoadingErrorToastHeader,
        message: t.Admin.AppointmentTypeListErrorToastMessage
    });
    function onScrollToEnd() {
        var shouldFetchNextPage = hasNextPage && !isLoading && !isFetchingNextPage;
        if (shouldFetchNextPage) {
            fetchNextPage();
        }
    }
    function onRowClick(id) {
        history.push("/admin/appointments/" + id);
    }
    var loading = isLoading || isFetchingNextPage;
    var showResults = !isLoading && !isError;
    var ruleChips = function (appt) { return [
        [!!appt.active, t.Admin.AppointmentTypeListTableChipActive],
        [!appt.displaytopatient, t.Admin.AppointmentTypeListTableChipHidden],
        [!!appt.embargo, t.Admin.AppointmentTypeListTableChipProvisional],
        [appt.hasstatusfilters, t.Admin.AppointmentTypeListTableChipStatusFilter],
        [appt.appointmenttype === 'video-call', t.PatientCalendar.VideoCall]
    ]; };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledSearchBar, { value: searchInputValue, onChange: setSearchInputValue, placeholder: t.Admin.AppointmentTypeListSearchPlaceholder }),
        React.createElement(StyledTableContainer, null,
            React.createElement(Table, null,
                React.createElement(StyledTableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t.Admin.AppointmentTypeListTableHeaderId),
                        React.createElement(TableCell, null, t.Admin.AppointmentTypeListTableHeaderName),
                        React.createElement(TableCell, null, t.Admin.AppointmentTypeListTableHeaderStatus))),
                React.createElement(TableBody, null, showResults &&
                    data &&
                    data.pages.map(function (page) {
                        return page.data.map(function (appt) { return (React.createElement(StyledTableRow, { key: appt.id, onClick: function () { return onRowClick(appt.id); }, "data-testid": "TableRow", role: "button", tabIndex: 0 },
                            React.createElement(TableCell, { component: "th", scope: "row" }, appt.internalsid),
                            React.createElement(TableCell, null, appt.name),
                            React.createElement(TableCell, null, ruleChips(appt).map(function (_a, index) {
                                var visible = _a[0], label = _a[1];
                                return (React.createElement(AppointmentRuleChip, tslib_1.__assign({ key: index }, { visible: visible, label: label })));
                            })))); });
                    })),
                React.createElement(StyledTableFooter, null,
                    React.createElement(TableRow, null, loading && (React.createElement(TableCell, { colSpan: 3 },
                        React.createElement(LoaderWrapper, { "data-testid": "Loader" },
                            React.createElement(CircularProgress, null)))))))),
        React.createElement(Waypoint, { onEnter: onScrollToEnd })));
};
var ɵ1 = AppointmentTypeList;
export default AppointmentTypeList;
export { ɵ0, ɵ1 };
