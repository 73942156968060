import { Action } from '@ngrx/store';
import { SideBarMenuItem } from 'src/app/models/SideBarMenuItem';

/**
 * Enumerated, namespaced layout action types
 */
export enum LayoutActionTypes {
  OpenSidenav = '[Layout] Open Sidenav',
  CloseSidenav = '[Layout] Close Sidenav',
  ActivateMenuItem = '[Layout] Activate Menu Item',
}

/**
 * Action Creators
 */
export class OpenSidenav implements Action {
  readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = LayoutActionTypes.CloseSidenav;
}

export class ActivateMenuItemAction implements Action {
  readonly type = LayoutActionTypes.ActivateMenuItem;
  constructor(public payload: SideBarMenuItem) {}
}

/**
 * Layout Actions Type Checker
 */
export type LayoutActionsUnion =
  | OpenSidenav
  | CloseSidenav
  | ActivateMenuItemAction;
