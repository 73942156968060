import { useApiClient } from '@react/lib/api';
import { useMutation, useQuery } from 'react-query';
export function usePatientFormQuery(_a) {
    var key = _a.key, value = _a.value;
    var apiClient = useApiClient().portal;
    return useQuery(['ResubmittablePatientLookupForm', key, value], function () { return apiClient.fetchResubmittablePatientLookupForms(key, value); }, {
        enabled: false,
    });
}
export function useResubmitPatientFormMutation() {
    var apiClient = useApiClient().portal;
    return useMutation(function (formId) {
        return apiClient.resubmitPatientForm(formId);
    });
}
