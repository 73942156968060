import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { checkFeature } from './check';
import { useFeatures } from './context';

type Props = RouteProps & {
  feature: string | ((features: Readonly<any>) => boolean);
  /**
   * If the feature check fails then this is where the user will be sent to.
   *
   * This is needed within a `<Switch />` as it will see the `FeatureRoute` and
   * count it as a successful hit so it won't skip it.
   */
  redirectToOnFailure: string;
};

export const FeatureRoute: React.FC<Props> = ({
  feature,
  redirectToOnFailure,
  ...rest
}) => {
  const features = useFeatures();

  const shouldRender = checkFeature(features, feature);

  if (!shouldRender) {
    return <Redirect to={redirectToOnFailure} />;
  }

  return <Route {...rest} />;
};
