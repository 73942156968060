<div class="confirm-dialog" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start center">
    <p class="message">{{ data.message }}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <p *ngIf="information" class="information">{{ information }}</p>
  </div>
  <div mat-dialog-actions fxLayoutAlign="space-between center">
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="onNoClick()"
      cdkFocusInitial
    >
      {{ data.text.Cancel }}
    </button>
    <button
      mat-raised-button
      class="portal-btn btn-bright"
      (click)="onYesClick()"
    >
      {{ data.text.Confirm }}
    </button>
  </div>
</div>
