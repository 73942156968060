<div class="content-assignment-main">
  <div
    *ngIf="(contentTypes$ | async)?.length === 0"
    fxFlexFill
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
  >
    <div class="no-content">
      {{ (contentSwingText$ | async)?.NoContentAvailable }}
    </div>
    <div class="no-content">
      {{ (contentSwingText$ | async)?.ToCreate }}
    </div>
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="createContent()"
    >
      {{ (contentSwingText$ | async)?.CreateContent }}
    </button>
  </div>
  <div *ngIf="(contentTypes$ | async)?.length > 0" fxFlexFill fxLayout="row">
    <portal-content-type-filter
      fxFlex="1.2 1.2 calc(128px)"
      [text]="contentSwingText$ | async"
      [contentTypes]="contentTypes$ | async"
      [selectedTab]="activeTab$ | async"
      [selectedPackId]="selectedPackId$ | async"
      [contentPacks]="contentPacks$ | async"
      [activeContentTypeId]="activeContentTypeId$ | async"
      (setPackId)="selectActivePackId($event)"
      (selectTab)="selectTab($event)"
      (setContentTypeId)="setActiveContentTypeId($event)"
    >
    </portal-content-type-filter>
    <portal-content-select
      *ngIf="(activeTab$ | async) === tabOptions.FOLDERS"
      fxFlex="2 2 calc(130px)"
      [text]="contentSwingText$ | async"
      [selectedTab]="activeTab$ | async"
      [filterActive]="csFilterActive$ | async"
      [filterString]="csFilterString$ | async"
      [content]="csContent$ | async"
      [folderName]="selectedFolderName$ | async"
      [patientOnly]="isCategoryPatientOnly$ | async"
      (setSearchString)="setContentSelectFilter($event)"
      (addAppContent)="addToPatientApp($event)"
    >
    </portal-content-select>
    <portal-pack-assignment
      *ngIf="(activeTab$ | async) === tabOptions.PACKS"
      fxFlex="2 2 calc(130px)"
      [text]="contentSwingText$ | async"
      [patientId]="patientId$ | async"
      [lockedItems]="lockedItems$ | async"
      [selectedPack]="selectedPack$ | async"
      [packDetail]="selectedPackFilteredDetail$ | async"
      (assignItemToPatient)="addPackItemToPatient($event)"
      (assignPack)="assignPack($event)"
      (toggleLockedItem)="toggleLockedItem($event)"
      (removeExclusions)="removeExclusions($event)"
    >
    </portal-pack-assignment>
    <portal-assigned-content-container></portal-assigned-content-container>
  </div>
</div>
