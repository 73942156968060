var User = /** @class */ (function () {
    function User(FirstName, LastName, CognitoUsername, PublicToken, Success, Consent, ClinicName, LogoutMinutes, LanguageCode, jwtToken, groups) {
        if (LanguageCode === void 0) { LanguageCode = 'en-gb'; }
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.CognitoUsername = CognitoUsername;
        this.PublicToken = PublicToken;
        this.Success = Success;
        this.Consent = Consent;
        this.ClinicName = ClinicName;
        this.LogoutMinutes = LogoutMinutes;
        this.LanguageCode = LanguageCode;
        this.jwtToken = jwtToken || null;
        this.groups = groups;
    }
    return User;
}());
export { User };
