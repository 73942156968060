import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Input } from '@angular/core';
import { SideBarMenuItem } from '../../../models/SideBarMenuItem';

@Component({
  selector: 'portal-nav-item',
  templateUrl: `./nav-item.component.html`,
  styleUrls: [`./nav-item.component.scss`],
})
export class NavItemComponent {
  @Input() menuItem: SideBarMenuItem;
  @Input() activated = false;
  @Input() text: any;
  @Output() gotoNavItem = new EventEmitter<SideBarMenuItem>();
}
