import * as tslib_1 from "tslib";
import { MessageUIActionTypes, } from '../actions/message-ui.actions';
export function reducer(state, action) {
    var _a;
    switch (action.type) {
        case MessageUIActionTypes.OpenThread: {
            var m = action.payload;
            return tslib_1.__assign({}, state, { loadingThread: true, activeThreadFirstLoad: true, activeThreadId: m.MessageId, activeThread: {
                    id: m.MessageId,
                    typeId: m.MessageTypeId,
                    type: m.MessageType,
                    patientName: m.PatientFirstName + " " + m.PatientLastName,
                    patientFirstName: m.PatientFirstName,
                    patientId: m.PatientId,
                    patientIdentifier: m.PatientIdentifier,
                    patientDoB: m.DateOfBirth,
                    patientSent: m.PatientSent,
                    isResolved: m.MessageStatusId === 999,
                    isUnread: m.Read && !m.PatientSent,
                    isReadOnly: true,
                    messages: [
                        tslib_1.__assign({}, m, { ReadDate: null }),
                    ],
                    hasNewMessages: false,
                    subject: m.MessageSubject,
                }, activeThreadOpen: true });
        }
        case MessageUIActionTypes.CloseThread: {
            return tslib_1.__assign({}, state, { activeThreadOpen: false, activeThreadId: null, activeThread: null });
        }
        case MessageUIActionTypes.ApplyFilter: {
            return tslib_1.__assign({}, state, { filter: action.payload });
        }
        // change label and reset count of previous list to default of 25
        case MessageUIActionTypes.ApplyMessageTypeFilter: {
            return tslib_1.__assign({}, state, { messageList: [], reachedEndOfMessages: false, messageTypeFilter: action.payload, nextPageToLoad: tslib_1.__assign({}, state.nextPageToLoad, { SearchText: null, FetchAfterMessageItemId: null, FetchBeforeMessageItemId: null, MessageTypeId: action.payload.messageTypeId }), messageCountSummary: {
                    starred: null,
                    unstarred: null,
                } });
        }
        case MessageUIActionTypes.UpdateSearchString: {
            return tslib_1.__assign({}, state, { messageList: [], searchActive: true, searchString: action.payload, nextPageToLoad: tslib_1.__assign({}, state.nextPageToLoad, { FetchAfterMessageItemId: null, FetchBeforeMessageItemId: null, MessageTypeId: null, SearchText: action.payload, Resolved: null }), reachedEndOfMessages: false });
        }
        case MessageUIActionTypes.ActivateSearch: {
            return tslib_1.__assign({}, state, { searchActive: true });
        }
        case MessageUIActionTypes.ClearSearch: {
            return tslib_1.__assign({}, state, { searchActive: false, searchString: null, messageList: [], nextPageToLoad: tslib_1.__assign({}, state.nextPageToLoad, { MessageTypeId: state.messageTypeFilter.messageTypeId, FetchAfterMessageItemId: null, FetchBeforeMessageItemId: null, Resolved: false, SearchText: null }), reachedEndOfMessages: false });
        }
        case MessageUIActionTypes.ChangeMessageResponseSize: {
            return tslib_1.__assign({}, state, { messageResponseSize: action.payload });
        }
        case MessageUIActionTypes.HideThreadNewMessagesButton: {
            return tslib_1.__assign({}, state, { activeThread: tslib_1.__assign({}, state.activeThread, { hasNewMessages: false }) });
        }
        case MessageUIActionTypes.SetSelectedTab: {
            return tslib_1.__assign({}, state, { messageList: [], reachedEndOfMessages: false, nextPageToLoad: tslib_1.__assign({}, state.nextPageToLoad, { FetchAfterMessageItemId: null, FetchBeforeMessageItemId: null }, action.payload.params), tab: action.payload.tab });
        }
        case MessageUIActionTypes.UpdateConversationCache: {
            return tslib_1.__assign({}, state, { conversationCache: tslib_1.__assign({}, state.conversationCache, (_a = {}, _a[action.payload.messageId] = action.payload.message, _a)) });
        }
        default:
            return state;
    }
}
