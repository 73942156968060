export class ConversationsRequest {
  Action = 'conversations';
  // tslint:disable-next-line: no-shadowed-variable
  constructor(public ConversationListParams: ConversationListParams) {}
}

export class ConversationsRequestV2 {
  Action = 'conversations.v2';
  // tslint:disable-next-line: no-shadowed-variable
  constructor(public ConversationListParams: ConversationListParams) {}
}

export interface ConversationListParams {
  Count?: number;
  SearchText?: string;
  MessageTypeId: number;
  Resolved?: boolean;
  FetchAfterMessageItemId?: number;
  FetchBeforeMessageItemId?: number;
}
