<div class="list-container" fxLayout="column">
  <mat-toolbar
    class="content-type-filter"
    fxLayout="row"
    fxLayoutAlign="space-evenly center"
  >
    <div
      class="tab"
      [class.active-tab]="selectedTab === tabOptions.FOLDERS"
      fxLayoutAlign="center center"
      fxFlex="1 1 100%"
      (click)="selectTab.emit(tabOptions.FOLDERS)"
    >
      <div class="tab-text">
        {{ text.Folders }}
      </div>
    </div>
    <div
      class="tab"
      [class.active-tab]="selectedTab === tabOptions.PACKS"
      fxLayoutAlign="center center"
      fxFlex="1 1 100%"
      (click)="selectTab.emit(tabOptions.PACKS)"
    >
      <div class="tab-text">
        {{ text.Packs }}
      </div>
    </div>
  </mat-toolbar>
  <perfect-scrollbar
    class="bordered"
    fxFlex="auto"
    [config]="config"
    [scrollIndicators]="true"
  >
    <div class="list" *ngIf="selectedTab === tabOptions.FOLDERS">
      <div
        *ngFor="let type of contentTypes"
        class="list-item"
        [class.active]="type.id === activeContentTypeId"
        (click)="setContentTypeId.emit(type.id)"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        {{ type.name }}
      </div>
    </div>
    <div class="list" *ngIf="selectedTab === tabOptions.PACKS">
      <div
        *ngFor="let pack of contentPacks"
        class="list-item"
        [class.active]="pack.id === selectedPackId"
        (click)="setPackId.emit(pack.id)"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        {{ pack.packName }}
      </div>
    </div>
  </perfect-scrollbar>
</div>
