import * as tslib_1 from "tslib";
import { ContentActionTypes, } from '../actions/content.actions';
export var initialState = {
    loadingDirectory: false,
    loadingTemplate: false,
    loadingContent: false,
    savingCategory: false,
    savingTemplate: false,
    savingContent: false,
    activeDirectorySection: 'folders',
    activeListFilter: false,
    currentListFilter: '',
    contentEditorEnabled: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ContentActionTypes.LoadingDirectory: {
            return tslib_1.__assign({}, state, { loadingDirectory: true });
        }
        case ContentActionTypes.LoadingTemplate: {
            return tslib_1.__assign({}, state, { loadingTemplate: true });
        }
        case ContentActionTypes.LoadedContent: {
            return tslib_1.__assign({}, state, { loadingContent: true });
        }
        case ContentActionTypes.LoadedDirectory: {
            return tslib_1.__assign({}, state, { loadingDirectory: false });
        }
        case ContentActionTypes.LoadedTemplate: {
            return tslib_1.__assign({}, state, { loadingTemplate: false });
        }
        case ContentActionTypes.LoadedContent: {
            return tslib_1.__assign({}, state, { loadingContent: false });
        }
        case ContentActionTypes.OpenDirectorySection: {
            return tslib_1.__assign({}, state, { activeDirectorySection: action.payload });
        }
        case ContentActionTypes.SavingTemplate: {
            return tslib_1.__assign({}, state, { savingTemplate: true });
        }
        case ContentActionTypes.SavingTemplateSuccess ||
            ContentActionTypes.SavingTemplateError:
            {
                return tslib_1.__assign({}, state, { savingTemplate: false });
            }
        case ContentActionTypes.SavingContent: {
            return tslib_1.__assign({}, state, { savingContent: true });
        }
        case ContentActionTypes.SavingContentSuccess ||
            ContentActionTypes.SavingContentError:
            {
                return tslib_1.__assign({}, state, { savingContent: false });
            }
        case ContentActionTypes.ClearFilter: {
            return tslib_1.__assign({}, state, { activeListFilter: false, currentListFilter: '' });
        }
        case ContentActionTypes.FilterContent: {
            return tslib_1.__assign({}, state, { activeListFilter: true, currentListFilter: action.payload });
        }
        case ContentActionTypes.GetContentEditorEnabledSuccess ||
            ContentActionTypes.GetContentEditorEnabledError:
            {
                return tslib_1.__assign({}, state, { contentEditorEnabled: action.payload });
            }
        default: {
            return state;
        }
    }
}
export var isLoadingDirectory = function (state) { return state.loadingDirectory; };
export var isLoadingTemplate = function (state) { return state.loadingTemplate; };
export var isLoadingContent = function (state) { return state.loadingContent; };
export var isSavingTemplate = function (state) { return state.savingTemplate; };
export var isFilterActive = function (state) { return state.activeListFilter; };
export var getCurrentListFilter = function (state) { return state.currentListFilter; };
export var getActiveDirectorySection = function (state) {
    return state.activeDirectorySection;
};
export var getContentEditorEnabled = function (state) {
    return state.contentEditorEnabled;
};
