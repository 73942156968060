import { Component } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CompletePassword, EnableForm } from './../../actions/auth.actions';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromAuth from '../../reducers';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'portal-create-account',
  template: `
    <portal-create-account-form
      [text]="authText$ | async"
      [completingPassword]="isCompletingPassword$ | async"
      [cognitoUser]="cognitoUser$ | async"
      [error]="createAccountError$ | async"
      (createCoreAccount)="handleAccountSubmission($event)"
    >
    </portal-create-account-form>
  `,
})
export class CreateAccountContainer {
  public createAccountError$: Observable<boolean>;
  public isCompletingPassword$: Observable<boolean>;
  public cognitoUser$: Observable<CognitoUser | any>;

  public authText$: Observable<any>;

  constructor(private _store: Store<fromRoot.State>) {
    this.isCompletingPassword$ = this._store.pipe(
      select(fromAuth.isUpdatingPassword),
    );
    this.cognitoUser$ = this._store.pipe(select(fromAuth.getCognitoUser));
    this.createAccountError$ = this._store.pipe(
      select(fromAuth.hasUpdatePasswordError),
    );
    this.authText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('Authentication')),
    );
    this._store.dispatch(new EnableForm());
  }

  public handleAccountSubmission(args) {
    this._store.dispatch(new CompletePassword(args));
  }
}
