
import { Action } from '@ngrx/store';
import { ServerError } from 'src/app/models/Error';
import { Category } from '../../content/models/category';
import {
  CreatePatientOnlyAssignment,
  DeletePatientOnlyAssignment,
  GetAllPatientAssignedContentResponse,
  GetPatientViewResponse,
  IndividuallyAssignedPatientContent,
  PatientAssignedCreation,
  PatientOnlyAssignContentItem,
  UpdatePatientOnlyAssignment,
} from '../models/UserContent';
import { ContentTabs } from '../reducers/content-assignment.reducer';
import { ContentEntryHeader } from './../models/Content';

// Models
// =============================================

export enum ContentAssignmentActionTypes {
  // Server Side
  GetContentEntries = '[Patient-CA] Get Content Entries',
  GetContentEntriesSuccess = '[Patient-CA] Get Content Entries Success',
  GetContentEntriesError = '[Patient-CA] Get Content Entries Error',

  GetContentTypes = '[Patient-CA] Get Content Types',
  GetContentTypesSuccess = '[Patient-CA] Get Content Types Success',
  GetContentTypesError = '[Patient-CA] Get Content Types Error',

  GetPatientAssignedContent = '[Patient-CA] Get Patient Assigned Content',
  GetPatientAssignedContentSuccess = '[Patient-CA] Get Patient Assigned Content Success',
  GetPatientAssignedContentError = '[Patient-CA] Get Patient Assigned Content Error',

  GetAllPatientAssignedContent = '[Patient-CA] Get All Patient Assigned Content',
  GetAllPatientAssignedContentSuccess = '[Patient-CA] Get All Patient Assigned Content Success',
  GetAllPatientAssignedContentError = '[Patient-CA] Get All Patient Assigned Content Error',

  GetPatientViewOfContent = '[Patient-CA] Get All Patient View Of Content',
  GetPatientViewOfContentSuccess = '[Patient-CA] Get All Patient View Of Content Success',
  GetPatientViewOfContentError = '[Patient-CA] Get All Patient View Of Content Error',

  CreatePatientAssignedContent = '[Patient-CA] Create Patient Assigned Content',
  CreatePatientAssignedContentSuccess = '[Patient-CA] Create Patient Assigned Content Success',
  CreatePatientAssignedContentError = '[Patient-CA] Create Patient Assigned Content Error',

  DeletePatientAssignedContent = '[Patient-CA] Delete Patient Assigned Content',
  DeletePatientAssignedContentSuccess = '[Patient-CA] Delete Patient Assigned Content Success',
  DeletePatientAssignedContentError = '[Patient-CA] Delete Patient Assigned Content Error',

  // Patient Only Content Assignments
  GetPatientOnlyAssignments = '[Patient-CA] Get Patient Only Assigned Content',
  GetPatientOnlyAssignmentsSuccess = '[Patient-CA] Get Patient Only Assigned Content Success',
  GetPatientOnlyAssignmentsError = '[Patient-CA] Get Patient Only Assigned Content Error',

  CreatePatientOnlyAssignments = '[Patient-CA] Create Patient Only Assigned Content',
  CreatePatientOnlyAssignmentsSuccess = '[Patient-CA] Create Patient Only Assigned Content Success',
  CreatePatientOnlyAssignmentsError = '[Patient-CA] Create Patient Only Assigned Content Error',

  UpdatePatientOnlyAssignments = '[Patient-CA] Update Patient Only Assigned Content',
  UpdatePatientOnlyAssignmentsSuccess = '[Patient-CA] Update Patient Only Assigned Content Success',
  UpdatePatientOnlyAssignmentsError = '[Patient-CA] Update Patient Only Assigned Content Error',

  DeletePatientOnlyAssignments = '[Patient-CA] Delete Patient Only Assigned Content',
  DeletePatientOnlyAssignmentsSuccess = '[Patient-CA] Delete Patient Only Assigned Content Success',
  DeletePatientOnlyAssignmentsError = '[Patient-CA] Delete Patient Only Assigned Content Error',

  // UI
  SetContentTypeToPatientDocs = '[Patient-CA] Set Active Content Type To Patient Documents Category',
  SetContentTypeToNonPatientDocs = '[Patient-CA] Set Active Content Type To Non Patient Documents Category',
  SetActiveContentType = '[Patient-CA] Set Active Content Type in content picker',
  SetContentSelectFilter = '[Patient-CA] Set Content Select Filter String',

  SetAssignedContentFilter = '[Patient-CA] Set Assigned Content Filter String',

  ChangeAssignedContentView = '[Patient-CA] Change Assigned Content View',

  OpenPatientViewFolder = '[Patient-CA] Open Patient View Folder',

  OpenItemList = '[Patient-CA] Open Item List',
  OpenPackList = '[Patient-CA] Open Pack List',

  ChangeContentTab = '[Patient-CA] Change Content Tab',

  SetPatientId = '[Patient-CA] Set Current Patient Id',
}

// Action Creators
// ===============================================

// Server Side
export class GetContentEntries implements Action {
  readonly type = ContentAssignmentActionTypes.GetContentEntries;
}
export class GetContentEntriesSuccess implements Action {
  readonly type = ContentAssignmentActionTypes.GetContentEntriesSuccess;

  constructor(
    public payload: {
      [key: string]: ContentEntryHeader[];
    },
  ) {}
}
export class GetContentEntriesError implements Action {
  readonly type = ContentAssignmentActionTypes.GetContentEntriesError;

  constructor(public payload: any) {}
}

export class GetContentTypes implements Action {
  readonly type = ContentAssignmentActionTypes.GetContentTypes;
}
export class GetContentTypesSuccess implements Action {
  readonly type = ContentAssignmentActionTypes.GetContentTypesSuccess;

  constructor(public payload: Category[]) {}
}
export class GetContentTypesError implements Action {
  readonly type = ContentAssignmentActionTypes.GetContentTypesError;

  constructor(public payload: any) {}
}

export class GetPatientAssignedContent implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientAssignedContent;

  constructor(
    // patient Id
    public payload: number,
  ) {}
}
export class GetPatientAssignedContentSuccess implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientAssignedContentSuccess;

  constructor(public payload: IndividuallyAssignedPatientContent[]) {}
}
export class GetPatientAssignedContentError implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientAssignedContentError;

  constructor(public payload: any) {}
}

export class GetAllPatientAssignedContent implements Action {
  readonly type = ContentAssignmentActionTypes.GetAllPatientAssignedContent;

  constructor(
    // patient Id
    public payload: number,
  ) {}
}
export class GetAllPatientAssignedContentSuccess implements Action {
  readonly type =
    ContentAssignmentActionTypes.GetAllPatientAssignedContentSuccess;

  constructor(public payload: GetAllPatientAssignedContentResponse) {}
}
export class GetAllPatientAssignedContentError implements Action {
  readonly type =
    ContentAssignmentActionTypes.GetAllPatientAssignedContentError;

  constructor(public payload: ServerError) {}
}

export class GetPatientViewOfContent implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientViewOfContent;

  constructor(
    // patient Id
    public payload: number,
  ) {}
}
export class GetPatientViewOfContentSuccess implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientViewOfContentSuccess;

  constructor(public payload: GetPatientViewResponse) {}
}
export class GetPatientViewOfContentError implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientViewOfContentError;

  constructor(public payload: ServerError) {}
}

export class CreatePatientAssignedContent implements Action {
  readonly type = ContentAssignmentActionTypes.CreatePatientAssignedContent;

  constructor(
    public payload: {
      patientId: number;
      assignment: PatientAssignedCreation;
      patientContent?: IndividuallyAssignedPatientContent;
    },
  ) {}
}
export class CreatePatientAssignedContentSuccess implements Action {
  readonly type =
    ContentAssignmentActionTypes.CreatePatientAssignedContentSuccess;

  constructor(public payload: IndividuallyAssignedPatientContent) {}
}
export class CreatePatientAssignedContentError implements Action {
  readonly type =
    ContentAssignmentActionTypes.CreatePatientAssignedContentError;

  constructor(public payload: any) {}
}

export class DeletePatientAssignedContent implements Action {
  readonly type = ContentAssignmentActionTypes.DeletePatientAssignedContent;

  constructor(
    public payload: {
      assignmentid: number;
      contententryheaderid: number;
      content?: IndividuallyAssignedPatientContent;
    },
  ) {}
}
export class DeletePatientAssignedContentSuccess implements Action {
  readonly type =
    ContentAssignmentActionTypes.DeletePatientAssignedContentSuccess;

  constructor(
    public payload: {
      assignmentid: number;
      contententryheaderid: number;
      content?: IndividuallyAssignedPatientContent;
    },
  ) {}
}
export class DeletePatientAssignedContentError implements Action {
  readonly type =
    ContentAssignmentActionTypes.DeletePatientAssignedContentError;

  constructor(public payload: any) {}
}

// Patient Only Content Related Actions
// ===============================================================
export class GetPatientOnlyAssignments implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientOnlyAssignments;

  constructor(
    public payload: {
      patientId: number;
    },
  ) {}
}
export class GetPatientOnlyAssignmentsSuccess implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientOnlyAssignmentsSuccess;

  constructor(public payload: PatientOnlyAssignContentItem[]) {}
}
export class GetPatientOnlyAssignmentsError implements Action {
  readonly type = ContentAssignmentActionTypes.GetPatientOnlyAssignmentsError;

  constructor(public payload: any) {}
}

export class CreatePatientOnlyAssignments implements Action {
  readonly type = ContentAssignmentActionTypes.CreatePatientOnlyAssignments;

  constructor(
    public payload: {
      patientId: number;
      body: CreatePatientOnlyAssignment;
    },
  ) {}
}

export class CreatePatientOnlyAssignmentsSuccess implements Action {
  readonly type =
    ContentAssignmentActionTypes.CreatePatientOnlyAssignmentsSuccess;

  constructor(public payload: PatientOnlyAssignContentItem) {}
}
export class CreatePatientOnlyAssignmentsError implements Action {
  readonly type =
    ContentAssignmentActionTypes.CreatePatientOnlyAssignmentsError;

  constructor(public payload: any) {}
}

export class UpdatePatientOnlyAssignments implements Action {
  readonly type = ContentAssignmentActionTypes.UpdatePatientOnlyAssignments;

  constructor(
    public payload: {
      assignmentId: number;
      updateInfo: UpdatePatientOnlyAssignment;
    },
  ) {}
}
export class UpdatePatientOnlyAssignmentsSuccess implements Action {
  readonly type =
    ContentAssignmentActionTypes.UpdatePatientOnlyAssignmentsSuccess;

  constructor(public payload: PatientOnlyAssignContentItem) {}
}
export class UpdatePatientOnlyAssignmentsError implements Action {
  readonly type =
    ContentAssignmentActionTypes.UpdatePatientOnlyAssignmentsError;

  constructor(public payload: any) {}
}

export class DeletePatientOnlyAssignments implements Action {
  readonly type = ContentAssignmentActionTypes.DeletePatientOnlyAssignments;

  constructor(
    public payload: {
      deleteInfo: DeletePatientOnlyAssignment;
    },
  ) {}
}
export class DeletePatientOnlyAssignmentsSuccess implements Action {
  readonly type =
    ContentAssignmentActionTypes.DeletePatientOnlyAssignmentsSuccess;

  constructor(public payload: DeletePatientOnlyAssignment) {}
}
export class DeletePatientOnlyAssignmentsError implements Action {
  readonly type =
    ContentAssignmentActionTypes.DeletePatientOnlyAssignmentsError;

  constructor(public payload: any) {}
}

// UI
// =================================================================
export class SetContentTypeToPatientDocs implements Action {
  readonly type = ContentAssignmentActionTypes.SetContentTypeToPatientDocs;
}
export class SetContentTypeToNonPatientDocs implements Action {
  readonly type = ContentAssignmentActionTypes.SetContentTypeToNonPatientDocs;
}
export class SetActiveContentType implements Action {
  readonly type = ContentAssignmentActionTypes.SetActiveContentType;

  constructor(public payload: number) {}
}

export class SetContentSelectFilter implements Action {
  readonly type = ContentAssignmentActionTypes.SetContentSelectFilter;

  constructor(public payload: string) {}
}
export class SetAssignedContentFilter implements Action {
  readonly type = ContentAssignmentActionTypes.SetAssignedContentFilter;

  constructor(public payload: string) {}
}

export class ChangeAssignedContentView implements Action {
  readonly type = ContentAssignmentActionTypes.ChangeAssignedContentView;
}
export class OpenItemList implements Action {
  readonly type = ContentAssignmentActionTypes.OpenItemList;
}
export class OpenPackList implements Action {
  readonly type = ContentAssignmentActionTypes.OpenPackList;
}
export class ChangeContentTab implements Action {
  readonly type = ContentAssignmentActionTypes.ChangeContentTab;

  constructor(public payload: ContentTabs) {}
}

export class SetPatientId implements Action {
  readonly type = ContentAssignmentActionTypes.SetPatientId;

  constructor(public payload: number) {}
}
export class OpenPatientViewFolder implements Action {
  readonly type = ContentAssignmentActionTypes.OpenPatientViewFolder;

  constructor(public payload: number) {}
}

// ContentAssignment Actions Union
// ===============================================
export type ContentAssignmentActionsUnion =
  | GetPatientAssignedContent
  | GetPatientAssignedContentSuccess
  | GetPatientAssignedContentError
  | CreatePatientAssignedContent
  | CreatePatientAssignedContentSuccess
  | CreatePatientAssignedContentError
  | DeletePatientAssignedContent
  | DeletePatientAssignedContentSuccess
  | DeletePatientAssignedContentError
  | GetPatientOnlyAssignments
  | GetPatientOnlyAssignmentsSuccess
  | GetPatientOnlyAssignmentsError
  | GetAllPatientAssignedContent
  | GetAllPatientAssignedContentSuccess
  | GetAllPatientAssignedContentError
  | GetPatientViewOfContent
  | GetPatientViewOfContentSuccess
  | GetPatientViewOfContentError
  | UpdatePatientOnlyAssignments
  | UpdatePatientOnlyAssignmentsSuccess
  | UpdatePatientOnlyAssignmentsError
  | CreatePatientOnlyAssignments
  | CreatePatientOnlyAssignmentsSuccess
  | CreatePatientOnlyAssignmentsError
  | DeletePatientOnlyAssignments
  | DeletePatientOnlyAssignmentsSuccess
  | DeletePatientOnlyAssignmentsError
  | GetContentEntries
  | GetContentEntriesSuccess
  | GetContentEntriesError
  | GetContentTypes
  | GetContentTypesSuccess
  | GetContentTypesError
  | SetActiveContentType
  | SetContentTypeToPatientDocs
  | SetContentTypeToNonPatientDocs
  | SetContentSelectFilter
  | SetAssignedContentFilter
  | OpenItemList
  | OpenPackList
  | ChangeContentTab
  | ChangeAssignedContentView
  | SetPatientId
  | OpenPatientViewFolder;
