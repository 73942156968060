import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/reducers';
import { ProfilePhotoModalComponent } from '../../../react/pages/userMenu/components/ProfilePhotoModal';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
import { FileUpload } from '../../services/s3.service';
var containerElementName = 'profilePhotoModalReactWrapperComponent';
var ProfilePhotoModalReactWrapperComponent = /** @class */ (function () {
    function ProfilePhotoModalReactWrapperComponent(_fileUpload, _store, _cognito, data) {
        this._fileUpload = _fileUpload;
        this._store = _store;
        this._cognito = _cognito;
        this.data = data;
        this._subscriptions = new Subscription();
    }
    ProfilePhotoModalReactWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4];
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user,
            };
            _this.render();
        }));
    };
    ProfilePhotoModalReactWrapperComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
        this._subscriptions.unsubscribe();
    };
    ProfilePhotoModalReactWrapperComponent.prototype.ngAfterViewInit = function () {
        this.render();
    };
    ProfilePhotoModalReactWrapperComponent.prototype.onChanged = function () {
        // This lets the spinner appear briefly before a page reload is initiated.
        // This is a sledgehammer way to forces all of the <img> instances using the old profile image to update.
        setTimeout(function () { return location.reload(); }, 3000);
    };
    ProfilePhotoModalReactWrapperComponent.prototype.render = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                ReactDOM.render(React.createElement(Root, tslib_1.__assign({}, this._props, { authService: this._cognito, dispatch: this._store.dispatch.bind(this._store), children: React.createElement(ProfilePhotoModalComponent, {
                        open: false,
                        dialogWrapper: false,
                        onCancelled: this.data.close,
                        onConfirmed: this.onChanged,
                        fileUploadService: this._fileUpload,
                    }) })), this.containerRef.nativeElement);
                return [2 /*return*/];
            });
        });
    };
    return ProfilePhotoModalReactWrapperComponent;
}());
export { ProfilePhotoModalReactWrapperComponent };
