import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { StaffApiValue } from '@react/lib/api/types';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import UsersRow from '../usersRow';
import {
  LoaderWrapper,
  StyledTable,
  StyledTableFooter,
  StyledTableHead,
} from './index.styled';

interface Props {
  users: StaffApiValue[];
  loading: boolean;
  onScrollToEnd: () => void;
  onClickInviteUser: (user: StaffApiValue) => void;
}

const UsersList: React.FunctionComponent<Props> = ({
  users,
  loading,
  onScrollToEnd,
  onClickInviteUser,
}: Props) => {
  const { t } = useTranslations();

  return (
    <>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>{t.Admin.Users.UsersListTableHeaderFirstName}</TableCell>
            <TableCell>{t.Admin.Users.UsersListTableHeaderLastName}</TableCell>
            <TableCell className="emailCol">
              {t.Admin.Users.UsersListTableHeaderEmail}
            </TableCell>
            <TableCell>{t.Admin.Users.UsersListTableHeaderStatus}</TableCell>
            <TableCell>
              {t.Admin.Users.UsersListTableHeaderRegistered}
            </TableCell>
            <TableCell className="fixedcol" />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {users.map((user) => (
            <UsersRow
              key={user.id}
              user={user}
              onClickInviteUser={onClickInviteUser}
            />
          ))}
        </TableBody>
        <StyledTableFooter>
          <TableRow>
            {loading && (
              <TableCell colSpan={7}>
                <LoaderWrapper data-testid="Loader">
                  <CircularProgress />
                </LoaderWrapper>
              </TableCell>
            )}
          </TableRow>
        </StyledTableFooter>
      </StyledTable>
      <Waypoint onEnter={onScrollToEnd} />
    </>
  );
};

export default UsersList;
