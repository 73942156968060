import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import equal from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import { ContentTemplate, NewTemplateEntry, } from '../../models/content-template';
var TemplateEditFormComponent = /** @class */ (function () {
    function TemplateEditFormComponent(_fb, dialog) {
        this._fb = _fb;
        this.dialog = dialog;
        this.config = {};
        this.saveTemplate = new EventEmitter();
        this.deleteTemplate = new EventEmitter();
    }
    Object.defineProperty(TemplateEditFormComponent.prototype, "templateEntries", {
        get: function () {
            return this.templateForm.get('entries');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplateEditFormComponent.prototype, "requiredEntries", {
        get: function () {
            return this.templateForm.get('requiredEntries');
        },
        enumerable: true,
        configurable: true
    });
    TemplateEditFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.template && changes.template.currentValue) {
            this.activeTemplate = cloneDeep(this.template);
            this.entries = cloneDeep(this.template.entries);
            this.templateForm = this._fb.group({
                id: this.template.id,
                title: this.template.name,
                requiredEntries: this._fb.array([]),
                entries: this._fb.array([]),
            });
            this._processEntries(this.entries);
            if (!this.contentEditorEnabled) {
                this.templateForm.disable();
            }
        }
    };
    TemplateEditFormComponent.prototype.dropped = function () {
        var entriesCopy = [];
        for (var _i = 0, _a = this.templateEntries.controls; _i < _a.length; _i++) {
            var entry = _a[_i];
            entriesCopy.push(entry.value);
        }
        this.templateEntries.reset(entriesCopy);
    };
    TemplateEditFormComponent.prototype._buildEntry = function (entry) {
        return this._fb.group({
            id: { value: entry.id },
            title: { value: entry.title, disabled: entry.isrequired },
            ordernumber: { value: entry.ordernumber },
            type: { value: entry.type },
        });
    };
    TemplateEditFormComponent.prototype._processEntries = function (entries) {
        var _this = this;
        // required Entries
        var reqEntries = entries
            .filter(function (e) { return e.isrequired && e.type !== 'title'; })
            .map(function (e) { return _this._buildEntry(e); });
        this.templateForm.setControl('requiredEntries', this._fb.array(reqEntries));
        // non-required Entries
        var nonReqEntries = entries
            .filter(function (e) { return !e.isrequired; })
            .map(function (e) { return _this._buildEntry(e); });
        this.templateForm.setControl('entries', this._fb.array(nonReqEntries));
    };
    TemplateEditFormComponent.prototype._getOrderNumber = function () {
        return this.templateEntries.length + 1;
    };
    TemplateEditFormComponent.prototype._formHasChanged = function () {
        var formEntries = this.templateEntries.value.map(function (e) { return ({
            id: e.id.value,
            title: e.title,
            ordernumber: e.ordernumber.value,
        }); });
        var originalTemplateEntries = this.template.entries.slice(4).map(function (e) { return ({
            id: e.id,
            title: e.title,
            ordernumber: e.ordernumber,
        }); });
        return (!equal(formEntries, originalTemplateEntries) &&
            this.templateForm.get('title').value === this.template.name);
    };
    TemplateEditFormComponent.prototype.canDeactivate = function () {
        if (this._formHasChanged()) {
            var confirmDialog = this.dialog.open(ConfirmActionComponent, {
                data: {
                    message: this.text.CancelConfirmation,
                    text: {
                        Confirm: this.text.Confirm,
                        Cancel: this.text.Cancel,
                    },
                },
            });
            return confirmDialog.afterClosed();
        }
        else {
            return true;
        }
    };
    TemplateEditFormComponent.prototype.removeEntry = function (id) {
        this.templateEntries.removeAt(id);
    };
    TemplateEditFormComponent.prototype.addEntry = function () {
        this.templateEntries.push(this._buildEntry(new NewTemplateEntry({
            ordernumber: this._getOrderNumber(),
        })));
    };
    TemplateEditFormComponent.prototype.delete = function () {
        var _this = this;
        var confirmDialog = this.dialog.open(ConfirmActionComponent, {
            data: {
                message: this.text.DeleteConfirmation,
                text: {
                    Confirm: this.text.Confirm,
                    Cancel: this.text.Cancel,
                },
            },
        });
        confirmDialog.afterClosed().subscribe(function (val) {
            if (val) {
                _this.deleteTemplate.emit(_this.templateForm.get('id').value);
            }
        });
    };
    TemplateEditFormComponent.prototype.save = function () {
        var entries = this.entries.slice(0, 4);
        var editableEntries = this.templateEntries.value;
        var updatedEntries = entries.concat(editableEntries.map(function (e, i) { return (tslib_1.__assign({}, (e.id.value ? { id: e.id.value } : {}), { ordernumber: i + 4 + 1, content: e.content, type: e.type.value, title: e.title, isrequired: false })); }));
        var template = tslib_1.__assign({}, this.activeTemplate, { name: this.templateForm.get('title').value, entries: updatedEntries });
        this.saveTemplate.emit(template);
    };
    return TemplateEditFormComponent;
}());
export { TemplateEditFormComponent };
