import { useApiClient } from '@react/lib/api';
import { IClinicUserFilters } from '@react/lib/api/types';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

export function useAdminClinicUsersListQuery({
  searchTerm,
  filters,
}: {
  searchTerm: string;
  filters: IClinicUserFilters;
}) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    ['adminClinicUsers', { searchTerm, filters }],
    ({ pageParam }: { pageParam?: number }) =>
      apiClient.fetchAdminClinicUsers({
        pageParam,
        query: searchTerm,
        filters,
        excludeInternal: true,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    },
  );
}

export function useInviteClinicUserMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    ({ staffId, message }: { staffId: number; message: string }) =>
      apiClient.inviteStaff(staffId, message),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminClinicUsers');
      },
    },
  );
}

export function useDeactivateClinicUserMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    ({ staffId }: { staffId: number }) => apiClient.deactivateStaff(staffId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminClinicUsers');
      },
    },
  );
}

export function useTemporaryPasswordMutation() {
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;

  return useMutation(
    ({ staffId }: { staffId: number }) =>
      apiClient.sendTemporaryPassword(staffId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminClinicUsers');
      },
    },
  );
}
