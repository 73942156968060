import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import * as fromMessages from '../../messaging/reducers';
import { ServerError } from '../../models/Error';
import { MessageResult } from '../../models/Message';
import { Thread } from '../../models/Thread';
import * as fromRoot from '../../reducers';
import {
  LoadMessageActionTypes,
  LoadMessages,
  LoadMessagesSuccess,
  LoadMessageTypes,
  LoadThreadError,
  LoadThreadSuccess
} from '../actions/load-message.actions';
import { ConversationListParams } from '../models/requests/conversations.request';
import { MessageService } from '../services/message.service';
import {
  LoadMessageTypesSuccess,
  LoadMessagingSummary,
  LoadMessagingSummaryError,
  LoadMessagingSummarySuccess,
  LoadNewerThreadMessages,
  LoadNewerThreadMessagesSuccess,
  LoadNewestMessages,
  LoadNewestMessagesError,
  LoadNewestMessagesSuccess,
  ShowPushNotification
} from './../actions/load-message.actions';
import {
  MessageUIActionTypes,
  OpenThread
} from './../actions/message-ui.actions';

@Injectable()
export class LoadMessageEffects {
  constructor(
    private _actions$: Actions,
    private _messageService: MessageService,
    private _error: ErrorHandler,
    private _store: Store<fromRoot.State>
  ) {}

  @Effect()
  refreshMessagingSummary$(): Observable<Action> {
    return this._actions$.pipe(
      ofType<LoadMessagingSummary>(LoadMessageActionTypes.LoadMessagingSummary),
      mergeMap(() => {
        return this._messageService.getMessagingSummary().pipe(
          map((response) => {
            return new LoadMessagingSummarySuccess(response);
          }),
          catchError((error) => {
            return of(new LoadMessagingSummaryError(error));
          })
        );
      })
    );
  }

  @Effect()
  loadThread$(): Observable<Action> {
    return this._actions$.pipe(
      ofType<OpenThread>(MessageUIActionTypes.OpenThread),
      switchMap((action) => {
        return this._messageService
          .getConversation(action.payload.MessageId)
          .pipe(
            map(
              (messageResult: MessageResult) => {
                const thread = new Thread(
                  {
                    ...action.payload,
                    Read: true,
                    MessageSubject: action.payload.MessageSubject
                  },
                  messageResult.Messages,
                  messageResult.IsReadOnly
                );
                return new LoadThreadSuccess(thread);
              },
              catchError((err) => {
                return of(
                  new LoadThreadError(
                    new ServerError(
                      '[LoadMessage]',
                      'Error loading message thread',
                      action.type
                    )
                  )
                );
              })
            )
          );
      })
    );
  }

  @Effect()
  loadMessageTypes$(): Observable<Action> {
    return this._actions$.pipe(
      ofType<LoadMessageTypes>(LoadMessageActionTypes.LoadMessageTypes),
      switchMap((action) => {
        return this._messageService.getMessageTypes().pipe(
          map((res) => {
            return new LoadMessageTypesSuccess(res);
          }),
          catchError((err) => {
            return of(
              new LoadMessagingSummaryError(
                new ServerError(
                  '[LoadMessageTypes]',
                  'Error loading message types',
                  action.type
                )
              )
            );
          })
        );
      })
    );
  }

  @Effect()
  loadNewerThreadMessages$(): Observable<Action> {
    return this._actions$.pipe(
      ofType<LoadNewerThreadMessages>(
        LoadMessageActionTypes.LoadNewerThreadMessages
      ),
      switchMap((action) => {
        return this._messageService
          .getConversation(
            action.payload.messageId,
            action.payload.latestMessageItemId
          )
          .pipe(
            map(
              (res) => {
                return new LoadNewerThreadMessagesSuccess({
                  threadId: action.payload.messageId,
                  messages: res.Messages
                });
              },
              catchError((err) => {
                return of(
                  new LoadThreadError(
                    new ServerError(
                      '[LoadMessage]',
                      'Error loading message thread',
                      action.type
                    )
                  )
                );
              })
            )
          );
      })
    );
  }

  private getPayloadFromResolvedAndMessageTypeId(
    payload: ConversationListParams
  ) {
    let { Resolved, MessageTypeId } = payload;
    switch (payload.MessageTypeId) {
      case -1:
        // All Unresolved
        MessageTypeId = undefined;
        break;
      case -2:
        // All Resolved
        MessageTypeId = undefined;
        Resolved = true;
        break;
      case -3:
        // All Messages
        MessageTypeId = undefined;
        Resolved = null;
        break;
    }

    return Object.assign(
      {},
      {
        ...payload,
        MessageTypeId,
        Resolved
      }
    );
  }

  @Effect()
  loadMessages$(): Observable<Action> {
    return this._actions$.pipe(
      ofType<LoadMessages>(LoadMessageActionTypes.LoadMessages),
      withLatestFrom(
        this._store.pipe(select(fromMessages.getCurrentTab)),
        this._store.pipe(select(fromAuth.getMessageStarringEnabled))
      ),
      switchMap(([action, tab, starringEnabled]) => {
        const requestPayload = this.getPayloadFromResolvedAndMessageTypeId(
          action.payload
        );
        return this._messageService
          .getMessageList(starringEnabled, requestPayload)
          .pipe(
            map((res) => {
              if (starringEnabled) {
                return new LoadMessagesSuccess({
                  messages: res.Conversations,
                  counts: {
                    starred: res.Starred,
                    unstarred: res.Unstarred
                  }
                });
              }

              return new LoadMessagesSuccess({ messages: res });
            }),
            catchError((err) => {
              return of(
                new LoadMessagingSummaryError(
                  new ServerError(
                    '[LoadMessage]',
                    'Error loading messages',
                    action.type
                  )
                )
              );
            })
          );
      })
    );
  }

  @Effect()
  loadNewestMessages$(): Observable<Action> {
    return this._actions$.pipe(
      ofType<LoadNewestMessages>(LoadMessageActionTypes.LoadNewestMessages),
      withLatestFrom(
        this._store.pipe(select(fromMessages.getCurrentTab)),
        this._store.pipe(select(fromAuth.getMessageStarringEnabled))
      ),
      switchMap(([action, tab, starringEnabled]) => {
        const requestPayload = this.getPayloadFromResolvedAndMessageTypeId(
          action.payload.fetchParams
        );

        return this._messageService
          .getMessageList(starringEnabled, requestPayload)
          .pipe(
            mergeMap((res) => {
              let messages;
              let newPatientMessages;

              // TODO: SA-2906: Once feature approved promote below code.
              if (starringEnabled) {
                messages = res.Conversations;

                newPatientMessages = res.Conversations.filter(
                  (m) => m.PatientSent
                );
              } else {
                messages = res;
                newPatientMessages = res.filter((m) => m.PatientSent);
              }

              const pushNotificationActions =
                action.payload.showPushNotification &&
                newPatientMessages.length > 0
                  ? [
                      new ShowPushNotification({
                        newMessages: newPatientMessages,
                        showPushNotification: true
                      })
                    ]
                  : [];

              return [
                new LoadNewestMessagesSuccess({ messages }),
                ...pushNotificationActions
              ];
            }),
            catchError((err) => {
              return of(
                new LoadNewestMessagesError(
                  new ServerError(
                    '[LoadNewestMessage]',
                    'Error loading messages',
                    action.type
                  )
                )
              );
            })
          );
      })
    );
  }
}
