import pick from 'lodash/pick';
import qs from 'qs-lite';
import { CognitoWrapperService } from 'src/app/core/services/congito.wrapper.service';
import { MessageResponse } from 'src/app/models/Message';
import { environment } from '../../../../environments/environment';
import {
  AppointmentTypeDetailsResponse,
  AppointmentTypeListResponse,
  ConversationApiValue,
  ConversationCreate,
  ConversationCreateResponse,
  ConversationFilters,
  ConversationFolderResponse,
  ConversationResponse,
  ConversationsCountFilters,
  ConversationsCountResponse,
  ConversationsResponse,
  ConversationStaffResponse,
  ConversationUpdate,
  CreateMessageTemplateRequest,
  CreateMessageTemplateResponse,
  CUResponse,
  DeleteMessageTemplateResponse,
  DrugTypeListResponse,
  GetMessageTemplateMessageRequest,
  GetMessageTemplateMessageResponse,
  GetMessageTemplateVariablesResponse,
  IClinicUserFilters,
  IFilters,
  IListResponse,
  IRecipe,
  IRecipeAction,
  IRecipeListItem,
  IRecipeTrigger,
  IResponse,
  MessageApiValue,
  MessageCreatePayload,
  MessagesFilterPayload,
  MessagesResponse,
  MessageTemplateResponse,
  MessageTemplatesListResponse,
  MessageUpdatePayload,
  PatientsFilters,
  PatientsResponse,
  ResubmittablePatientForms,
  ResubmittablePatientLookupForm,
  StaffExportResponse,
  StaffListResponse,
  StaffResponse,
  UpdateAppointmentTypeRequest,
  UpdateAppointmentTypeResponse,
  UpdateMessageTemplateRequest,
  UpdateMessageTemplateResponse,
  UpdateSideRecipeRequest,
} from './types';

export class PortalApiClient {
  private _clinicToken: string;
  private _authService: CognitoWrapperService;
  private _onUnauthorized: () => void;

  constructor(props: {
    clinicToken: string;
    authService: CognitoWrapperService;
    onUnauthorized: () => void;
  }) {
    this._clinicToken = props.clinicToken;
    this._authService = props.authService;
    this._onUnauthorized = props.onUnauthorized;
  }

  public async fetchAppointmentTypes({
    pageParam = 1,
    query = '',
  }): Promise<AppointmentTypeListResponse> {
    return this.fetch(
      `appointment/types?pageNumber=${pageParam}&pageSize=25${
        query && `&q=${query}`
      }`,
    );
  }

  public async fetchAppointmentTypeDetails({
    id,
  }: {
    id: number;
  }): Promise<AppointmentTypeDetailsResponse> {
    return this.fetch(`appointment/types/${id}`);
  }

  public async updateAppointmentTypeDetails({
    id,
    ...rest
  }: UpdateAppointmentTypeRequest): Promise<UpdateAppointmentTypeResponse> {
    return this.fetch(`appointment/types/${id}`, {
      body: JSON.stringify(rest),
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  public async fetchMessageTemplate({
    id,
  }: {
    id: number;
  }): Promise<MessageTemplateResponse> {
    return this.fetch(`conversations/message-templates/${id}`);
  }

  public async fetchMessageTemplates(): Promise<MessageTemplatesListResponse> {
    return this.fetch('conversations/message-templates');
  }

  public async createMessageTemplate(
    data: CreateMessageTemplateRequest,
  ): Promise<CreateMessageTemplateResponse> {
    return this.fetch('conversations/message-templates', {
      body: JSON.stringify(data),
      headers: { 'content-type': 'application/json' },
      method: 'POST',
    });
  }

  public async deleteMessageTemplate({
    id,
  }: {
    id: number;
  }): Promise<DeleteMessageTemplateResponse> {
    return this.fetch(`conversations/message-templates/${id}`, {
      method: 'DELETE',
    });
  }

  public async updateMessageTemplate({
    id,
    ...rest
  }: UpdateMessageTemplateRequest): Promise<UpdateMessageTemplateResponse> {
    return this.fetch(`conversations/message-templates/${id}`, {
      body: JSON.stringify(rest),
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  public async fetchMessageTemplateMessage({
    id,
    patientId,
  }: GetMessageTemplateMessageRequest): Promise<GetMessageTemplateMessageResponse> {
    return this.fetch(
      `conversations/message-templates/${id}/message?patientId=${patientId}`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'GET',
      },
    );
  }

  public async fetchMessageTemplateVariables(): Promise<GetMessageTemplateVariablesResponse> {
    return this.fetch('conversations/message-templates/variables');
  }

  /** Operations associated with SIDE */
  public async fetchSIDERecipes({
    pageParam = 1,
    query = '',
    filters = {} as IFilters,
    ignorePaging = false,
  }): Promise<IListResponse<IRecipeListItem>> {
    const appointmentTypes = Array.isArray(filters.appointmentTypes)
      ? filters.appointmentTypes.map((item) => item.value).join(',')
      : '';

    const drugTypes = Array.isArray(filters.drugTypes)
      ? filters.drugTypes.map((item) => item.value).join(',')
      : '';

    let url = 'side/recipes';
    const queryString = `${
      !ignorePaging ? `pageNumber=${pageParam}&pageSize=50` : ''
    }${query && `&q=${query}`}${
      filters.eventType ? `&eventType=${filters.eventType}` : ''
    }${
      Array.isArray(filters.status) && filters.status.length === 1
        ? `&status=${filters.status[0]}`
        : ''
    }${
      Array.isArray(filters.appointmentTypes) &&
      filters.appointmentTypes.length > 0
        ? `&appointmentTypes=${appointmentTypes}`
        : ''
    }${
      Array.isArray(filters.drugTypes) && filters.drugTypes.length > 0
        ? `&drugTypes=${drugTypes}`
        : ''
    }`;

    if (queryString !== '') {
      url = `${url}?${queryString}`;
    }

    return this.fetch(url);
  }

  public async fetchSIDERecipe(id: number): Promise<IResponse<IRecipe>> {
    return this.fetch(`side/recipes/${id}`);
  }

  public async fetchSIDERecipeTriggers(
    id: number,
  ): Promise<IListResponse<IRecipeTrigger>> {
    return this.fetch(`side/recipes/${id}/triggers`);
  }

  public async fetchSIDERecipeActions({
    id,
    pageParam = 1,
  }: {
    id: number;
    pageParam?: number;
  }): Promise<IListResponse<IRecipeAction>> {
    return this.fetch(
      `side/recipes/${id}/actions?pageNumber=${pageParam}&pageSize=10`,
    );
  }

  public async exportSIDERecipes() {
    return this.fetch(`side/export/recipes`);
  }

  public async updateSideRecipe({
    id,
    ...rest
  }: UpdateSideRecipeRequest): Promise<IResponse<CUResponse>> {
    return this.fetch(`side/recipes/${id}`, {
      body: JSON.stringify(rest),
      headers: { 'content-type': 'application/json' },
      method: 'PUT',
    });
  }

  /** Operations Associated with Medications */
  public async fetchDrugTypes({
    pageParam = 1,
    query = '',
  }): Promise<DrugTypeListResponse> {
    return this.fetch(
      `medications/types?pageNumber=${pageParam}&pageSize=25${
        query && `&q=${query}`
      }`,
    );
  }

  /** Operations Associated with Conversations */

  public async fetchConversations(
    filters: ConversationFilters,
  ): Promise<ConversationsResponse> {
    const q = qs.stringify({
      ...this.getDefaultPaginationQuery(),
      ...filters,
    });
    return this.fetch(`conversations/?${q}`);
  }

  public async updateConversation(
    { messageid, ...conversation }: ConversationUpdate,
    partial?: boolean,
  ): Promise<ConversationResponse> {
    const payload = {
      ...(partial && conversation),
      ...(!partial &&
        pick(conversation, [
          'messageid',
          'messagetypeid',
          'messagestatusid',
          'isstarred',
        ])),
    };

    return this.fetch(`conversations/${messageid}`, {
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  public async createConversation(
    conversation: ConversationCreate,
  ): Promise<ConversationCreateResponse> {
    const payload = pick(conversation, [
      'patientid',
      'messagetypeid',
      'messagesubject',
      'content',
      'metadata',
    ]);
    return this.fetch(`conversations/`, {
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      method: 'POST',
    });
  }

  public async fetchConversationsCountByStatus(
    filters: ConversationsCountFilters,
  ): Promise<ConversationsCountResponse> {
    const q = qs.stringify(filters);
    return this.fetch(`conversations/count?${q}`);
  }

  public async fetchConversationFolders(): Promise<ConversationFolderResponse> {
    return this.fetch(`conversations/folders`);
  }

  public async fetchConversationAssignedFolder(): Promise<IResponse<number>> {
    return this.fetch(`conversations/count/my-assigned`);
  }

  public async fetchPatientStaff(filters: {
    staffIds?: number[];
    pageNumber?: number;
    q?: string;
  }): Promise<ConversationStaffResponse> {
    const q = qs.stringify({ ...filters, staffids: filters.staffIds });
    return this.fetch(`patients/staff?${q}`);
  }

  public async fetchConversationById(
    id: ConversationApiValue['messageid'],
  ): Promise<ConversationResponse> {
    return this.fetch(`conversations/${id}`);
  }

  public async fetchMessages({
    messageid,
    ...filters
  }: MessagesFilterPayload): Promise<MessagesResponse> {
    const q = qs.stringify({
      ...this.getDefaultPaginationQuery(),
      ...filters,
    });
    return this.fetch(`conversations/${messageid}/messages?${q}`);
  }

  public async createMessage(
    message: MessageCreatePayload,
  ): Promise<MessageResponse> {
    const payload = JSON.stringify(message);
    return this.fetch(`conversations/${message.messageid}/messages`, {
      body: payload,
      headers: { 'content-type': 'application/json' },
      method: 'POST',
    });
  }

  public async updateMessage({
    id,
    messageid,
    ...message
  }: MessageUpdatePayload): Promise<MessageResponse> {
    const payload = pick(message, ['read']);
    return this.fetch(`conversations/${messageid}/messages/${id}`, {
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  public async removeMessage(
    conversationId: ConversationApiValue['messageid'],
    messageId: MessageApiValue['id'],
  ): Promise<MessageResponse> {
    return this.fetch(`conversations/${conversationId}/messages/${messageId}`, {
      method: 'DELETE',
    });
  }

  /** Operations Associated with ClinicUsers / My User */

  /** Operations associated with Clinic Users */
  public async fetchAdminClinicUsers({
    pageParam = 1,
    query = '',
    filters = {} as IClinicUserFilters,
    excludeInternal = false,
  }): Promise<StaffListResponse> {
    const statusValues =
      Array.isArray(filters.status) && filters.status.length > 0
        ? filters.status.map((status) => status).join(',')
        : null;
    const statusFilter = statusValues ? `&status=${statusValues}` : '';
    const queryParam = query ? `&q=${query}` : '';
    const excludeParam = excludeInternal ? '&excludeInternal=true' : '';
    return this.fetch(
      `staff?pageNumber=${pageParam}&pageSize=50&sortType=lastname${queryParam}${statusFilter}${excludeParam}`,
    );
  }

  public async fetchClinicUsers(filters: {
    pageNumber?: number;
  }): Promise<StaffListResponse> {
    const q = qs.stringify({ ...filters });
    return this.fetch(`staff?${q}`);
  }

  public async fetchMe(clinics?: boolean): Promise<StaffResponse> {
    const clinicsParam = clinics ? '?clinics=true' : '';
    return this.fetch(`staff/me${clinicsParam}`);
  }

  public async exportAdminUsers(): Promise<StaffExportResponse> {
    return this.fetch(`staff/export`);
  }

  public async inviteStaff(staffId: number, message: string) {
    const payload = {
      message,
    };
    return this.fetch(`staff/invite/${staffId}`, {
      body: JSON.stringify(payload),
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  public async deactivateStaff(staffId: number) {
    return this.fetch(`staff/deactivate/${staffId}`, {
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  public async sendTemporaryPassword(staffId: number) {
    return this.fetch(`staff/temporary-password/${staffId}`, {
      headers: { 'content-type': 'application/json' },
      method: 'PATCH',
    });
  }

  /** Operations Associated with Patients */

  public async fetchPatients(
    filters: PatientsFilters,
  ): Promise<PatientsResponse> {
    const q = qs.stringify({
      ...this.getDefaultPaginationQuery(),
      ...filters,
    });
    return this.fetch(`patients/?${q}`);
  }

  /** Operations Associated with Staff */

  public async fetchStaff(staffId: number): Promise<StaffResponse> {
    return this.fetch(`staff/${staffId}`);
  }

  public async fetchStaffMe(): Promise<StaffResponse> {
    return this.fetch(`staff/me`);
  }

  public async updateStaff(url: string | null): Promise<StaffResponse> {
    const body = {
      profilephotouri: url,
    };

    return this.fetch(`staff/me`, {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(body),
    });
  }

  /** Operations associated with Super Admin Export */
  public async exportMessages(from: string, to: string) {
    return this.fetch(`conversations/export/?from=${from}&to=${to}`);
  }

  /** Operations Associated with Forms */
  public async fetchResubmittablePatientForms(
    key: string,
    value: string,
  ): Promise<IResponse<ResubmittablePatientForms[]>> {
    return this.fetch(`forms/list?${key}=${encodeURIComponent(value)}`);
  }

  public async fetchResubmittablePatientLookupForms(
    key: string,
    value: string,
  ): Promise<IResponse<ResubmittablePatientLookupForm[]>> {
    return this.fetch(`forms/lookup?${key}=${encodeURIComponent(value)}`);
  }

  public async resubmitPatientForm(formId: number): Promise<IResponse<string>> {
    return this.fetch(`forms/${formId}/resubmit`, {
      headers: { 'content-type': 'application/json' },
      method: 'PUT',
    });
  }

  public async exportSIDEData(sideRecipeId: string, from: string, to: string) {
    return this.fetch(
      `side/export/recipe/${sideRecipeId}/?from=${from}&to=${to}`,
    );
  }
  /** Operations associated with Super Admin Export - End */

  private getDefaultPaginationQuery() {
    return { pageNumber: 1, pageSize: 25 };
  }

  /**
   * Essentially a wrapper around `fetch` that sets some mandatory headers
   * and behavior.
   */
  private async getDefaultFetchHeaders(
    options?: RequestInit & { headers?: Record<string, string> },
  ) {
    const authUser = await this._authService.getAuthSession();

    // Merge headers for the individual request with mandatory ones
    const headers: Record<string, string> = {
      ...(options != null && options.headers != null ? options.headers : {}),
      accept: 'application/json',
      authorization: authUser.getIdToken().getJwtToken(),
      'x-salve-clinic-token': this._clinicToken,
    };

    return headers;
  }

  private async handleFetchResponse(response: Response) {
    if (response.status === 401) {
      this._onUnauthorized();
      throw new Error('Unauthorized');
    }

    if (response.ok) {
      try {
        const content = await response.text();
        return content.length > 0 ? JSON.parse(content) : undefined;
      } catch (err) {
        throw new Error(`Error parsing body as JSON: ${err.message}`);
      }
    }

    throw new Error('Network response was not ok');
  }

  private async fetch(
    path: string,
    /** Force `headers` to be a simple object for ease of use */
    options?: RequestInit & { headers?: Record<string, string> },
  ) {
    // Merge headers for the individual request with mandatory ones
    const headers: Record<string, string> = await this.getDefaultFetchHeaders(
      options,
    );
    const response = await fetch(`${environment.api.portal.endpoint}${path}`, {
      ...options,
      headers,
    });

    return this.handleFetchResponse(response);
  }
}
