import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
export function useMessagesToString() {
    var t = useTranslations().t;
    var dateTime = useLocaleDateTime();
    return function (conversation, messages) {
        var messageToString = function (message) {
            var sentAt = dateTime.format(new Date(message.sentdate), 'P');
            var readAt = !message.patientsent &&
                message.read &&
                message.readdate &&
                dateTime.format(new Date(message.readdate), 'P');
            var sender = message.patientsent
                ? message.patientfirstname
                : message.clinicuserfullname;
            return [
                "[" + sentAt + "]",
                sender,
                "(" + (message.patientsent
                    ? "ID: " + conversation.patientidentifier
                    : 'Staff') + "):",
                message.patientattachmentfilename
                    ? t.Messages.SentFile(message.patientattachmentfilename)
                    : message.content,
                readAt ? t.Messages.ReadReceipt(message.patientfullname, readAt) : '',
            ].join(' ');
        };
        var clipboardMessages = messages.reduce(function (output, message) { return output.concat([
            messageToString(message),
        ]); }, []);
        if (conversation.resolved) {
            clipboardMessages.push("** " + t.Messages.MarkedResolved + " **");
        }
        return clipboardMessages.join('\n\n');
    };
}
