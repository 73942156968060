import { EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import * as fromContent from '../reducers';
import { UpdateForm } from '../reducers/forms.reducer';
var FORM_SUBMIT_SUCCESS = '[Form] FORM_SUBMIT_SUCCESS';
var FORM_SUBMIT_ERROR = '[Form] FORM_SUBMIT_ERROR';
var FormSubmitSuccess = /** @class */ (function () {
    function FormSubmitSuccess(payload) {
        this.payload = payload;
        this.type = FORM_SUBMIT_SUCCESS;
    }
    return FormSubmitSuccess;
}());
var FormSubmitError = /** @class */ (function () {
    function FormSubmitError(payload) {
        this.payload = payload;
        this.type = FORM_SUBMIT_ERROR;
    }
    return FormSubmitError;
}());
export var formSuccessAction = function (path, id) { return ({
    type: FORM_SUBMIT_SUCCESS,
    payload: {
        path: path,
        id: id,
    },
}); };
export var formErrorAction = function (path, error) { return ({
    type: FORM_SUBMIT_ERROR,
    payload: {
        path: path,
        error: error,
    },
}); };
var ConnectFormDirective = /** @class */ (function () {
    // formChange: Subscription;
    // formSuccess: Subscription;
    // formError: Subscription;
    function ConnectFormDirective(_formGroupDirective, _action$, _store) {
        this._formGroupDirective = _formGroupDirective;
        this._action$ = _action$;
        this._store = _store;
        this['debounce'] = 300;
        this.error = new EventEmitter();
        this.success = new EventEmitter();
        this._subs = new Subscription();
    }
    ConnectFormDirective.prototype.ngOnInit = function () {
        var _this = this;
        // Update form based on current value of the application store
        this._store
            .pipe(select(fromContent.selectForm(this.path)), take(1))
            .subscribe(function (formValue) {
            if (formValue) {
                _this._formGroupDirective.form.patchValue(formValue);
            }
        });
        // update form when valueChanges updates
        this._subs.add(this._formGroupDirective.form.valueChanges
            .pipe(debounceTime(this.debounce))
            .subscribe(function (value) {
            _this._store.dispatch(new UpdateForm({ path: _this.path, value: value }));
        }));
        // watch to see when form is successfully submitted
        this._subs.add(this._action$
            // TODO: Fix (should `pipe` and `ofType` be switched around?)
            // @ts-ignore
            .ofType(FORM_SUBMIT_SUCCESS)
            .pipe(filter(function (_a) {
            var payload = _a.payload;
            return payload.path === _this.path;
        }))
            .subscribe(function (_a) {
            var payload = _a.payload;
            // Reset Store Value
            _this._store.dispatch(new UpdateForm({
                path: _this.path,
                value: {
                    category: '',
                },
            }));
            // Reset Form fields
            _this._formGroupDirective.form.reset();
            // pass on id to trigger navigation
            _this.success.emit(payload.id);
        }));
        // watch for errors on form submission
        this._subs.add(this._action$
            // TODO: Fix (should `pipe` and `ofType` be switched around?)
            // @ts-ignore
            .ofType(FORM_SUBMIT_ERROR)
            .pipe(filter(function (_a) {
            var payload = _a.payload;
            return payload.path === _this.path;
        }))
            .subscribe(function (_a) {
            var payload = _a.payload;
            _this.error.emit(payload.error);
        }));
    };
    ConnectFormDirective.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    return ConnectFormDirective;
}());
export { ConnectFormDirective };
