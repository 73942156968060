import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import { ClinicMedicationRequest } from '../models/requests/clinic-medication.request';
import { ClinicNonTimedTriggerMedicationRequest } from '../models/requests/clinic-non-timed-trigger.request';
import { ClinicSummaryRequest } from '../models/requests/clinic-summary.request';
var DashboardService = /** @class */ (function () {
    function DashboardService(_httpService, _store, _error) {
        this._httpService = _httpService;
        this._store = _store;
        this._error = _error;
        this.publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
    }
    DashboardService.prototype.getClinicSummary = function () {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            return _this._httpService
                .performResolvePostRequest(new ClinicSummaryRequest({ PublicToken: pk }))
                .pipe(map(function (res) { return res; }), catchError(function (err) {
                return throwError(new Error('Error retrieving summary'));
            }));
        }));
    };
    DashboardService.prototype.getClinicMedications = function () {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            return _this._httpService
                .performResolvePostRequest(new ClinicMedicationRequest({ PublicToken: pk }))
                .pipe(map(function (res) { return res; }), catchError(function (err) {
                return throwError(new Error('Error Retrieving Medications'));
            }));
        }));
    };
    DashboardService.prototype.getClinicNonTimedTriggerMedications = function () {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            return _this._httpService
                .performResolvePostRequest(new ClinicNonTimedTriggerMedicationRequest({ PublicToken: pk }))
                .pipe(map(function (res) { return res; }), catchError(function (err) {
                return throwError(new Error('Error Retrieving Medications'));
            }));
        }));
    };
    return DashboardService;
}());
export { DashboardService };
