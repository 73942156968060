import styled from '@emotion/styled';
import { IconButton, Input, TextField } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

export const Container = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TextAreaContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
`;

export const LoadingContainer = styled.div`
  background-color: white;
  height: 100%;
  opacity: 0.75;
  position: absolute;
  width: 100%;
`;

export const TextArea = styled(Input)`
  flex: 1;
  min-height: 6rem;
  padding: ${({ theme }) => theme.spacing(1)}px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: #9b9b9b;
  }
`;

export const StatusBar = styled.div`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0.75)}px;
  width: 100%;
`;

export const WarningText = styled.span`
  align-items: center;
  color: #9b9b9b;
  display: flex;
  font-size: smaller;
`;

export const WarningIcon = styled(Warning)`
  color: orange;
  margin-right: 4px;
  position: relative;
  top: -1px;
`;

export const CharacterCount = styled.span<{ maxLengthReached?: boolean }>`
  color: ${({ maxLengthReached, theme }) =>
    maxLengthReached ? theme.colors.error : theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export const Toolbar = styled.div`
  align-items: center;
  background-color: #f7f7fb;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0.75)}px;
`;

export const ToolbarIconButton = styled(IconButton)`
  color: #4a4a4a;
`;

export const MessageTemplates = styled.div`
  height: 450px;
  width: 300px;
`;

export const SearchTextField = styled(TextField)`
  background: #fff;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;
