import { IconButton, MenuItem, TableCell, TextField } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { Label } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSIDEExportDataByRecipe, useSIDERecipesQuery } from './hooks';
import {
  RecipesContainer,
  StyledSelect,
  StyledTableRow,
} from './SIDEExportAction.styled';
import { TimeFrames } from './types';
import { getDateFieldValue, getTimeFrameDates } from './utils';

const SIDEExportAction: FunctionComponent<{
  filenameSuffix: string;
  title: string;
  subtitle: string;
}> = (props) => {
  const { t } = useTranslations();
  const localeDateTime = useLocaleDateTime();
  const [selectedSIDERecipeId, setSelectedSIDERecipeId] = useState('');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(TimeFrames.Today);
  const [startDate, setStartDate] = useState(getDateFieldValue(localeDateTime));
  const [endDate, setEndDate] = useState(getDateFieldValue(localeDateTime));
  const [startDateDisabled, setStartDateDisabled] = useState(true);
  const [endDateDisabled, setEndDateDisabled] = useState(true);
  const [exportSuccessToastVisible, setExportSuccessToastVisible] =
    useState(false);
  const [exportErrorToastVisible, setExportErrorToastVisible] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data, isLoading: recipesLoading } = useSIDERecipesQuery();
  const sideRecipes = ((data && data.data) || []).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const onDownloadSuccess = () => {
    setExportSuccessToastVisible(true);
    setIsDownloading(false);
  };

  const onDownloadFailure = () => {
    setExportErrorToastVisible(true);
    setIsDownloading(false);
  };

  const { download, fileUrl, ref, fileName } = useSIDEExportDataByRecipe({
    sideRecipeId: selectedSIDERecipeId,
    from: startDate,
    to: endDate,
    onDownloadSuccess,
    onDownloadFailure,
  });

  const updateTimeFrame = () => {
    setStartDateDisabled(selectedTimeFrame !== TimeFrames.CustomDates);
    setEndDateDisabled(selectedTimeFrame !== TimeFrames.CustomDates);

    if (selectedTimeFrame !== TimeFrames.CustomDates) {
      const { start, end } = getTimeFrameDates(
        localeDateTime,
        selectedTimeFrame,
      );
      setStartDate(getDateFieldValue(localeDateTime, start));
      setEndDate(getDateFieldValue(localeDateTime, end));
    }
  };

  useEffect(() => {
    if (selectedTimeFrame) {
      updateTimeFrame();
    }
  }, [selectedTimeFrame]);

  useEffect(() => {
    setIsDownloadEnabled(!!selectedSIDERecipeId && startDate <= endDate);
  }, [selectedSIDERecipeId, startDate, endDate]);

  useErrorToast(exportErrorToastVisible, {
    title: t.SuperAdmin.DataExport.ToastFailureTitle,
    message: t.SuperAdmin.DataExport.ToastFailureDescription,
  });

  useSuccessToast(exportSuccessToastVisible, {
    title: t.SuperAdmin.DataExport.ToastSuccessTitle,
    message: t.SuperAdmin.DataExport.ToastSuccessDescription,
  });

  const onChangeSIDERecipe = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setSelectedSIDERecipeId(String(event.target.value));
  };

  const onChangeTimeFrame = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setSelectedTimeFrame(event.target.value as TimeFrames);
  };

  const onChangeStartDate = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setStartDate(String(event.target.value));
  };

  const onChangeEndDate = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setEndDate(String(event.target.value));
  };

  const saveToCSV = async (): Promise<void> => {
    const fileNameSuffix = `${props.filenameSuffix} ${startDate} ${endDate}`;
    await download(fileNameSuffix);
  };

  return (
    <StyledTableRow data-testid="side_row">
      <TableCell>
        <h2>{props.title}</h2>
        <p>{props.subtitle}</p>
        <RecipesContainer>
          {!recipesLoading && sideRecipes.length > 0 && (
            <>
              <Label>{t.SuperAdmin.DataExport.RecipeLabel}</Label>
              <StyledSelect
                disableUnderline
                label={t.SuperAdmin.DataExport.RecipeLabel}
                onChange={onChangeSIDERecipe}
                value={selectedSIDERecipeId}
                data-testid="side_recipe"
              >
                {sideRecipes.map((recipe) => (
                  <MenuItem
                    key={`side-${recipe.id}`}
                    value={recipe.id.toString()}
                  >
                    {`${recipe.id} - ${recipe.name}${
                      recipe.status === 'Inactive'
                        ? ` [${t.SuperAdmin.DataExport.SIDERecipeInactive}]`
                        : ''
                    }`}
                  </MenuItem>
                ))}
              </StyledSelect>
            </>
          )}
        </RecipesContainer>
      </TableCell>
      <TableCell>
        <Label>{t.SuperAdmin.DataExport.TimeFrameLabel}</Label>
        <StyledSelect
          disableUnderline
          value={selectedTimeFrame}
          label={t.SuperAdmin.DataExport.TimeFrameLabel}
          onChange={onChangeTimeFrame}
          data-testid="side_timeFrame"
        >
          <MenuItem value={TimeFrames.Today}>
            {t.SuperAdmin.DataExport.Today}
          </MenuItem>
          <MenuItem value={TimeFrames.Yesterday}>
            {t.SuperAdmin.DataExport.Yesterday}
          </MenuItem>
          <MenuItem value={TimeFrames.Last7Days}>
            {t.SuperAdmin.DataExport.Last7Days}
          </MenuItem>
          <MenuItem value={TimeFrames.LastWeek}>
            {t.SuperAdmin.DataExport.LastWeek}
          </MenuItem>
          <MenuItem value={TimeFrames.ThisMonth}>
            {t.SuperAdmin.DataExport.ThisMonth}
          </MenuItem>
          <MenuItem value={TimeFrames.LastMonth}>
            {t.SuperAdmin.DataExport.LastMonth}
          </MenuItem>
          <MenuItem value={TimeFrames.Last12Months}>
            {t.SuperAdmin.DataExport.Last12Months}
          </MenuItem>
          <MenuItem value={TimeFrames.CustomDates}>
            {t.SuperAdmin.DataExport.CustomDates}
          </MenuItem>
        </StyledSelect>
      </TableCell>
      <TableCell>
        <TextField
          type="date"
          label={t.SuperAdmin.DataExport.StartDateLabel}
          disabled={startDateDisabled}
          value={startDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeStartDate}
          data-testid="side_startDate"
        />
      </TableCell>
      <TableCell>
        <TextField
          type="date"
          label={t.SuperAdmin.DataExport.EndDateLabel}
          disabled={endDateDisabled}
          value={endDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeEndDate}
          data-testid="side_endDate"
        />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={saveToCSV}
          disabled={isDownloading || !isDownloadEnabled}
          data-testid="side_btnSaveToCSV"
        >
          <GetApp />
        </IconButton>
        <a href={fileUrl} download={fileName} hidden ref={ref} />
      </TableCell>
    </StyledTableRow>
  );
};

export default SIDEExportAction;
