import {
  Component,
  ElementRef,
  ErrorHandler,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSelectChange,
} from '@angular/material';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import get from 'lodash/get';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientService } from 'src/app/patients/services/patient.service';
import { TranslationData } from 'src/app/settings/data/settings';
import { ClinicOptions } from '../../../auth/models/ClinicOptions';
import * as fromAuth from '../../../auth/reducers';
import { ConfirmActionComponent } from '../../../core/components/confirm-action/confirm-action.component';
import * as NewMessageActions from '../../../messaging/actions/new-message.actions';
import * as fromMessages from '../../../messaging/reducers';
import { Label } from '../../../models/Label';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import { PatientRequiringFormBase } from '../patient-requiring-form-base';

// Deprecated.
// Moved away from Angular version to the React one `./new-message-react.component.ts`
@Component({
  selector: 'portal-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
})
export class NewMessageComponent
  extends PatientRequiringFormBase
  implements OnInit, OnDestroy
{
  @ViewChild('patientName') patientName: ElementRef;

  public newMessageForm: FormGroup;

  currentText$ = new BehaviorSubject<string>('');
  public labels$: Observable<Label[]>;
  public newMessageText$: Observable<any>;

  public messageTypeId: number;
  public patientString: string;
  public labels: Label[];
  public newMessageText: TranslationData['NewMessage'];
  public clinicOptions$: Observable<ClinicOptions>;

  contentLength = 0;
  maxContentLength = 2000;

  private _messageStarringEnabled: boolean;
  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<NewMessageComponent>,
    private _dialog: MatDialog,
    private angulartics2: Angulartics2,
    private _errorHandler: ErrorHandler,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    _patientService: PatientService,
    _localisationService: LocalisationService,
  ) {
    super(_patientService, _localisationService);

    this.angulartics2.eventTrack.next({
      action: 'Initialise New Message',
      properties: { category: 'Messaging', label: 'New Message' },
    });

    this.newMessageText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewMessage')),
    );
    this.labels$ = this._store.pipe(select(fromMessages.getAssignableLabels));

    // if patient data passed to modal then use this for the form
    if (this._data.patient) {
      this._patient = _data.patient;
    }

    this.clinicOptions$ = this._store.pipe(select(fromAuth.getClinicOptions));
  }

  ngOnInit(): void {
    this._subs.add(this.labels$.subscribe((labels) => (this.labels = labels)));
    this._subs.add(
      this.newMessageText$.subscribe((val) => (this.newMessageText = val)),
    );

    this._subs.add(
      this.clinicOptions$.subscribe((opts) => {
        this._messageStarringEnabled = get(
          opts,
          'messagingOptions.messageStarring',
        );
      }),
    );

    this.setupSearchObservables(this._errorHandler);
    this.setupForm();
    this.setupSearchBox(this.newMessageForm);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public setMessageTypeId(event: MatSelectChange): void {
    this.messageTypeId = event.value;
  }

  public closeDialog(): void {
    const patientLength: number =
      this.newMessageForm.get('patientName').value.length;
    const messageTypeLength: number =
      this.newMessageForm.get('messageType').value.length;
    const contentLength = this.currentText$.value.length;

    const isEmpty = contentLength + patientLength + messageTypeLength === 0;

    if (!isEmpty) {
      const confirmDialog = this._dialog.open(ConfirmActionComponent, {
        data: {
          message: this.newMessageText.DiscardTitle,
          text: {
            Cancel: this.newMessageText.Cancel,
            Confirm: this.newMessageText.Confirm,
          },
        },
      });
      confirmDialog.afterClosed().subscribe((result) => {
        if (!result) {
          this.patientName.nativeElement.focus();
        } else {
          this._dialogRef.close({
            content: null,
            patientId: null,
          });
        }
      });
    } else {
      this._dialogRef.close({
        content: null,
        patientId: null,
      });
    }
  }

  currentTextChange(value: string) {
    this.currentText$.next(value);
  }

  public saveNewMessage(): void {
    const patient =
      this._patient || this.newMessageForm.get('patientName').value;
    const id = patient.Id;
    const patientName = `${patient.FirstName} ${patient.LastName}`;
    const content = this.currentText$.value;
    const subject: string = this.newMessageForm.get('subject').value;

    const newMessage = {
      patientName,
      patientId: id,
      messageTypeId: this.messageTypeId,
      content,
      subject,
    };

    const label = this.labels.find(
      (l) => l.messageTypeId === this.messageTypeId,
    );
    const messageType = label.displayName;
    const info = {
      id: null,
      typeId: this.messageTypeId,
      type: messageType,
      patientName,
      patientFirstName: patient.FirstName,
      patientLastName: patient.LastName,
      patientId: patient.PatientIdentifier,
      patientDoB: patient.DateOfBirth,
      patientSent: false,
      isResolved: false,
      isUnread: false,
    };

    this._store.dispatch(
      new NewMessageActions.SendOutboundMessage({
        messageStarringEnabled: this._messageStarringEnabled,
        newMessage,
        info,
      }),
    );
    this._dialogRef.close({
      content,
      patientId: id,
    });
  }

  public getSubjectValid() {
    return (
      (this.newMessageForm.get('subject').touched ||
        this.newMessageForm.get('subject').dirty) &&
      !this.newMessageForm.get('subject').valid
    );
  }

  private setupForm() {
    const controls: Record<string, any> = {
      patientName: this.getFormPatient(),
      messageType: [{ value: '', disabled: false }, [Validators.required]],
      subject: [
        { value: '', disabled: false },
        [Validators.minLength(1), Validators.maxLength(256)],
      ],
    };

    this.newMessageForm = this._fb.group(controls);
  }
}
