<div fxLayout="column" fxLayoutAlign="space-between stretch">
  <h3 mat-dialog-title class="title">
    {{ text.ContentLink }}
  </h3>
  <mat-dialog-content>
    <div class="link-description">
      {{ text.CtoPDesc(parentInfo?.name) }}
    </div>
    <div
      *ngIf="children.length === 0 && loadingChildren"
      class="loading-container"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-spinner diameter="150"></mat-spinner>
    </div>
    <div *ngIf="children.length === 0 && !loadingChildren">
      {{ text.NoChildren }}
    </div>
    <div *ngIf="children && children.length > 0" class="table-container">
      <perfect-scrollbar
        fxFlex="auto"
        [config]="config"
        [scrollIndicators]="true"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          class="content-table"
          matSort
        >
          <!-- Selection Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              class="selection-row"
              (click)="$event.stopPropagation()"
            >
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ text.Title }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.clinicname }}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            class="content-list-table-row"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </perfect-scrollbar>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div
      class="actions-container"
      fxLayout="column"
      fxLayoutAlign="end stretch"
      fxLayoutGap="20px"
    >
      <div fxLayout="row" fxLayoutAlign="end start">
        <div
          *ngIf="deletingLink || deletingLinks"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <mat-spinner diameter="30"></mat-spinner>
          <div *ngIf="howManySelected() === 1">
            {{ text.RemovingLink }}
          </div>
          <div *ngIf="howManySelected() > 1">
            {{ text.RemovingLinks }}
          </div>
        </div>
        <div
          *ngIf="deleteLinkSuccess || deleteLinksSuccess"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <mat-icon *ngIf="howManySelected() > 0" class="success">
            check_circle
          </mat-icon>
          <div *ngIf="howManySelected() === 1">
            {{ text.RemoveLinkSuccess }}
          </div>
          <div *ngIf="howManySelected() > 1">
            {{ text.RemoveLinksSuccess }}
          </div>
        </div>
        <div
          *ngIf="deleteLinkError || deleteLinksError"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <mat-icon *ngIf="howManySelected() > 0" class="error">
            highlight_off
          </mat-icon>
          <div *ngIf="howManySelected() === 1">
            {{ text.RemoveLinkError }}
          </div>
          <div *ngIf="howManySelected() > 1">
            {{ text.RemoveLinksError }}
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
        <button
          mat-raised-button
          class="portal-btn btn-primary"
          (click)="cancel.emit()"
        >
          {{ text.Cancel }}
        </button>
        <button
          mat-raised-button
          class="portal-btn btn-bright"
          [disabled]="howManySelected() === 0"
          (click)="handleDeleteLinks()"
        >
          {{ text.RemoveSelectedLinks }}
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
