/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/email-otp-form/email-otp-form.component.ngfactory";
import * as i2 from "../../components/email-otp-form/email-otp-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./email-otp.container";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../core/services/navigation.service";
var styles_EmailOTPPageContainer = [];
var RenderType_EmailOTPPageContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailOTPPageContainer, data: {} });
export { RenderType_EmailOTPPageContainer as RenderType_EmailOTPPageContainer };
export function View_EmailOTPPageContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "portal-email-otp-form", [], null, [[null, "verifyOTP"], [null, "cancelOTPLogin"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("verifyOTP" === en)) {
        var pd_0 = (_co.verifyOTP($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelOTPLogin" === en)) {
        var pd_1 = (_co.cancelOTPLogin() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_EmailOTPFormComponent_0, i1.RenderType_EmailOTPFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.EmailOTPFormComponent, [i3.FormBuilder], { text: [0, "text"], loggingIn: [1, "loggingIn"], CodeError: [2, "CodeError"], cognitoUser: [3, "cognitoUser"] }, { verifyOTP: "verifyOTP", cancelOTPLogin: "cancelOTPLogin" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.toastText$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.loggingIn$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.OTPError$)); var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 5).transform(_co.cognitoUser$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_EmailOTPPageContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-email-otp-page", [], null, null, null, View_EmailOTPPageContainer_0, RenderType_EmailOTPPageContainer)), i0.ɵdid(1, 114688, null, 0, i5.EmailOTPPageContainer, [i6.Store, i6.Store, i7.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailOTPPageContainerNgFactory = i0.ɵccf("portal-email-otp-page", i5.EmailOTPPageContainer, View_EmailOTPPageContainer_Host_0, {}, {}, []);
export { EmailOTPPageContainerNgFactory as EmailOTPPageContainerNgFactory };
