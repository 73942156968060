import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit, } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Root from '@react/components/Root';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/reducers';
import { Unauthorised } from '../../../react/components/Unauthorised';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
import { GLOBAL_CMS_ROUTE } from '../../services/navigation.service';
var containerElementName = 'unauthorisedReactComponent';
var UnauthorisedReactWrapperComponent = /** @class */ (function () {
    function UnauthorisedReactWrapperComponent(_store, _cognito) {
        this._store = _store;
        this._cognito = _cognito;
        this._subscriptions = new Subscription();
    }
    UnauthorisedReactWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._subscriptions.add(combineLatest([
            this._store.pipe(select(fromAuth.getClinicId)),
            this._store.pipe(select(fromAuth.getClinicOptions)),
            this._store.pipe(select(fromAuth.getUser)),
            this._store.pipe(select(fromSettings.getCurrentLanguage)),
            this._store.pipe(select(fromSettings.getLanguageTranslations)),
        ]).subscribe(function (_a) {
            var clinicToken = _a[0], clinicOptions = _a[1], user = _a[2], language = _a[3], translations = _a[4];
            _this._props = {
                clinicToken: clinicToken,
                features: clinicOptions,
                language: language,
                translations: translations,
                user: user,
            };
            _this.render();
        }));
    };
    UnauthorisedReactWrapperComponent.prototype.ngOnDestroy = function () {
        ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    };
    UnauthorisedReactWrapperComponent.prototype.ngAfterViewInit = function () {
        this.render();
    };
    UnauthorisedReactWrapperComponent.prototype.render = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                ReactDOM.render(React.createElement(Root, tslib_1.__assign({}, this._props, { className: 'portal-unauthorised', authService: this._cognito, dispatch: this._store.dispatch.bind(this._store), children: React.createElement(Unauthorised, {
                        container: true,
                        url: "/" + GLOBAL_CMS_ROUTE,
                        text: 'GlobalCMS.Back',
                    }) })), this.containerRef.nativeElement);
                return [2 /*return*/];
            });
        });
    };
    return UnauthorisedReactWrapperComponent;
}());
export { UnauthorisedReactWrapperComponent };
