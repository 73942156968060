/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./patient-details-internal.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../../core/services/navigation.service";
import * as i4 from "../../services/patient.service";
var styles_PatientDetailsInternalComponent = [];
var RenderType_PatientDetailsInternalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PatientDetailsInternalComponent, data: {} });
export { RenderType_PatientDetailsInternalComponent as RenderType_PatientDetailsInternalComponent };
export function View_PatientDetailsInternalComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_PatientDetailsInternalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-patients-detail", [], null, null, null, View_PatientDetailsInternalComponent_0, RenderType_PatientDetailsInternalComponent)), i0.ɵdid(1, 245760, null, 0, i1.PatientDetailsInternalComponent, [i2.Store, i3.NavigationService, i4.PatientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PatientDetailsInternalComponentNgFactory = i0.ɵccf("portal-patients-detail", i1.PatientDetailsInternalComponent, View_PatientDetailsInternalComponent_Host_0, {}, {}, []);
export { PatientDetailsInternalComponentNgFactory as PatientDetailsInternalComponentNgFactory };
