import { css } from '@emotion/react';
import styled from '@emotion/styled';

export type PhotoSize = 'small' | 'regular' | 'medium';

interface IStyledDivProps {
  size: PhotoSize;
}

const styledDivProps = (props: IStyledDivProps) => {
  let sizePixels: number;

  switch (props.size) {
    case 'small':
      sizePixels = 16;
      break;
    case 'regular':
      sizePixels = 30;
      break;
    default:
      sizePixels = 40;
      break;
  }

  return css`
    width: ${sizePixels}px;
    height: ${sizePixels}px;
  `;
};

export const StyledDiv = styled('div')`
  ${styledDivProps}
`;

export const StyledWrapper = styled.div<IStyledDivProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-right: ${({ size, theme }) =>
    size === 'regular' ? `${theme.spacing(1)}px` : '0'};
`;

export const StyledImg = styled('img')`
  border-radius: 50%;
  max-height: 100%;
  max-width: 100%;
`;

export const StyledInitials = styled.div`
  background: ${({ theme }) => theme.colors.purpleDark};
  width: 100%;
  height: 100%;
  border-radius: 100%;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  color: #fff;
`;
