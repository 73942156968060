import * as tslib_1 from "tslib";
import { select, Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import { AddMessageToThreadRequest } from '../models/requests/add-message-to-thread.request';
import { ChangeMessageLabelRequest } from '../models/requests/change-message-label.request';
import { ConversationsRequest, ConversationsRequestV2, } from '../models/requests/conversations.request';
import { CreateNewThreadRequest, NewMessageDetail, } from '../models/requests/create-new-thread.request';
import { DeleteMessageItemRequest } from '../models/requests/delete-message-item.request';
import { GetClinicMessagesRequest } from '../models/requests/get-clinic-messages.request';
import { GetConversationRequest } from '../models/requests/get-conversation.request';
import { MarkMessageResolvedRequest } from '../models/requests/mark-message-resolved.request';
import { MarkMessageStarredRequest } from '../models/requests/mark-message-starred';
import { MarkMessageUnreadRequest } from '../models/requests/mark-message-unread.request';
import { MarkMessageUnresolvedRequest } from '../models/requests/mark-message-unresolved.request';
import { MarkMessageUnstarredRequest } from '../models/requests/mark-message-unstarred';
import { MessageTypesRequest } from '../models/requests/message-types-request';
import { MessagingSummaryRequest } from '../models/requests/messaging-summary-request';
var MessageService = /** @class */ (function () {
    function MessageService(_httpService, _store) {
        this._httpService = _httpService;
        this._store = _store;
        this.publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
    }
    MessageService.prototype.getMessagingSummary = function () {
        return this._httpService.performResolvePostRequest(new MessagingSummaryRequest());
    };
    MessageService.prototype.getMessageTypes = function () {
        return this._httpService.performResolvePostRequest(new MessageTypesRequest());
    };
    MessageService.prototype.getClinicMessages = function () {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new GetClinicMessagesRequest({
                PublicToken: pk,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.getConversation = function (id, afterMessageItemId) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new GetConversationRequest({
                PublicToken: pk,
                MessageId: id,
                AfterMessageItemId: afterMessageItemId,
            });
            return _this._httpService.performResolvePostRequest(body).pipe(map(function (response) {
                var result = {
                    IsReadOnly: response.IsReadOnly,
                    Messages: response.Messages.map(function (_a) {
                        var Now = _a.Now, rest = tslib_1.__rest(_a, ["Now"]);
                        return (tslib_1.__assign({}, rest, { Now: undefined }));
                    }),
                };
                return result;
            }));
        }));
    };
    MessageService.prototype.markMessageUnread = function (id) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new MarkMessageUnreadRequest({
                PublicToken: pk,
                MessageId: id,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.markMessageResolved = function (id) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new MarkMessageResolvedRequest({
                PublicToken: pk,
                MessageId: id,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.markMessageUnresolved = function (id) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new MarkMessageUnresolvedRequest({
                PublicToken: pk,
                MessageId: id,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.markMessageStarred = function (id) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new MarkMessageStarredRequest({
                PublicToken: pk,
                MessageId: id,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.markMessageUnstarred = function (id) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new MarkMessageUnstarredRequest({
                PublicToken: pk,
                MessageId: id,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.updateMessageType = function (data) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new ChangeMessageLabelRequest({
                PublicToken: pk,
                MessageId: data.messageId,
                MessageTypeId: data.messageTypeId,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.addMessageToThread = function (message) {
        var _this = this;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new AddMessageToThreadRequest({
                PublicToken: pk,
                MessageId: message.MessageId,
                MessageContent: message.MessageContent,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.createNewThread = function (_a) {
        var _this = this;
        var patientId = _a.patientId, messageTypeId = _a.messageTypeId, subject = _a.subject, content = _a.content;
        var newMessage = new NewMessageDetail({
            PatientId: patientId,
            MessageTypeId: messageTypeId,
            Content: content,
            Subject: subject,
        });
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new CreateNewThreadRequest({
                PublicToken: pk,
                NewMessage: newMessage,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.deleteMessageItem = function (_a) {
        var _this = this;
        var messageId = _a.messageId, messageItemId = _a.messageItemId;
        return this.publicKey$.pipe(take(1), switchMap(function (pk) {
            var body = new DeleteMessageItemRequest({
                PublicToken: pk,
                DeleteMessageItemId: messageItemId,
                MessageId: messageId,
            });
            return _this._httpService.performResolvePostRequest(body);
        }));
    };
    MessageService.prototype.getMessageList = function (useV2, params) {
        if (useV2) {
            return this._httpService.performResolvePostRequest(new ConversationsRequestV2(params));
        }
        return this._httpService.performResolvePostRequest(new ConversationsRequest(params));
    };
    return MessageService;
}());
export { MessageService };
