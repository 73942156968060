import { useEffect } from 'react';
function requestPermission() {
    if (Notification.permission === 'default') {
        Notification.requestPermission();
    }
}
function showNotification(_a) {
    var title = _a.title, body = _a.body, onClick = _a.onClick;
    if (Notification.permission === 'granted') {
        var notification = new Notification(title, {
            body: body,
            icon: 'assets/imgs/logo.png',
        });
        if (onClick) {
            notification.onclick = onClick;
        }
    }
}
export function useBrowserNotifications() {
    useEffect(function () {
        requestPermission();
    }, []);
    return { showNotification: showNotification };
}
