import * as tslib_1 from "tslib";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
var stickyStyles = function () { return css(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n"]))); };
var ɵ0 = stickyStyles;
var shadowStyles = function () { return css(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);\n"], ["\n  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);\n"]))); };
var ɵ1 = shadowStyles;
export var StyledPaper = styled(Paper, {
    shouldForwardProp: function (prop) { return prop !== 'sticky'; },
})(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 42px;\n  border-radius: 5px;\n  padding: 0 ", "px;\n  ", "\n  ", "\n\n  svg {\n    font-size: 1.2rem;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  height: 42px;\n  border-radius: 5px;\n  padding: 0 ", "px;\n  ", "\n  ", "\n\n  svg {\n    font-size: 1.2rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var sticky = _a.sticky;
    return sticky && stickyStyles;
}, function (_a) {
    var shadow = _a.shadow;
    return shadow && shadowStyles;
});
export { ɵ0, ɵ1 };
var templateObject_1, templateObject_2, templateObject_3;
