import * as tslib_1 from "tslib";
import { ToastActions } from '../actions/toast.actions';
export var featureStateName = 'toasts';
export var initialState = {
    toastRef: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ToastActions.ToastDisplayed:
            return tslib_1.__assign({}, state, { toastRef: action.payload.toastRef });
        case ToastActions.ToastRemoved:
            return tslib_1.__assign({}, state, { toastRef: null });
        default:
            return state;
    }
}
// Selectors
export var getToastRef = function (state) {
    if (state) {
        return state.toastRef;
    }
};
