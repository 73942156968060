import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ClearChildren,
  DeleteAllContentLinks,
  DeleteAllContentLinksSuccess,
  DeleteContentLink,
  DeleteContentLinkSuccess,
  DeleteSomeContentLinks,
  DeleteSomeContentLinksSuccess,
  LinkContentActionTypes,
  LoadChildren,
} from './../../actions/link-content.actions';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as fromContent from '../../reducers';

import {
  ChildInfo,
  ContentEntryHeader,
  ViewChildParams,
} from '../../models/content-entry';
import { LinkDeletionParams } from '../../models/linked-content';

import { ConfirmActionComponent } from 'src/app/core/components/confirm-action/confirm-action.component';

@Component({
  selector: 'portal-view-children-container',
  template: `
    <portal-view-children
      [text]="text$ | async"
      [children]="children$ | async"
      [childSelected]="data.contentId"
      [parentInfo]="parentInfo$ | async"
      [loadingChildren]="loadingChildren$ | async"
      [deletingLink]="deletingLink$ | async"
      [deleteLinkError]="deleteLinkError$ | async"
      [deleteLinkSuccess]="deleteLinkSuccess$ | async"
      [deletingLinks]="deletingLinks$ | async"
      [deleteLinksError]="deleteLinksError$ | async"
      [deleteLinksSuccess]="deleteLinksSuccess$ | async"
      (deleteLinks)="handleLinkDeletion($event)"
      (cancel)="closeModal()"
    ></portal-view-children>
  `,
})
export class ViewChildrenContainer implements OnInit, OnDestroy {
  public text$: Observable<any>;
  public parentInfo$: Observable<ContentEntryHeader>;

  public loadingChildren$: Observable<boolean>;
  public children$: Observable<ChildInfo[]>;

  public deletingLink$: Observable<boolean>;
  public deleteLinkError$: Observable<boolean>;
  public deleteLinkSuccess$ = new BehaviorSubject(false);

  public deletingLinks$: Observable<boolean>;
  public deleteLinksError$: Observable<boolean>;
  public deleteLinksSuccess$ = new BehaviorSubject(false);

  public text: any;
  public children: ChildInfo[];

  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates$: Actions,
    public dialogRef: MatDialogRef<ViewChildrenContainer>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ViewChildParams,
  ) {
    this._store.dispatch(new LoadChildren({ headerId: data.parentId }));
  }

  ngOnInit(): void {
    this.text$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NewContentList')),
    );
    this.parentInfo$ = this._store.pipe(
      select(
        fromContent.getParentForModal(this.data.parentId, this.data.categoryId),
      ),
    );
    this.loadingChildren$ = this._store.pipe(
      select(fromContent.isLoadingChildren),
    );
    this.children$ = this._store.pipe(
      select(fromContent.getChildren),
      map((children: ChildInfo[]) => {
        if (this.data.contentId) {
          const sortedArray = [];

          // put chosen child at top of list
          for (const child of children) {
            if (child.id === this.data.contentId) {
              sortedArray.unshift(child);
            } else {
              sortedArray.push(child);
            }
          }

          return sortedArray;
        } else {
          return children;
        }
      }),
    );
    this.deletingLink$ = this._store.pipe(select(fromContent.isDeletingLink));
    this.deleteLinkError$ = this._store.pipe(
      select(fromContent.getLinkError('deletingLink')),
    );
    this.deletingLinks$ = this._store.pipe(select(fromContent.isDeletingLinks));
    this.deleteLinksError$ = this._store.pipe(
      select(fromContent.getLinkError('deletingLinks')),
    );

    this._subs.add(this.text$.subscribe((t) => (this.text = t)));

    // Watch for individual link deletion success
    this._subs.add(
      this._updates$
        .pipe(
          ofType<DeleteContentLinkSuccess>(
            LinkContentActionTypes.DeleteContentLinkSuccess,
          ),
        )
        .subscribe(() => {
          this.deleteLinkSuccess$.next(true);
          this._store.dispatch(
            new LoadChildren({ headerId: this.data.parentId }),
          );
          setTimeout(() => {
            this.deleteLinkSuccess$.next(false);
          }, 1000);
        }),
    );

    // Watch for some links deletion success
    this._subs.add(
      this._updates$
        .pipe(
          ofType<DeleteSomeContentLinksSuccess>(
            LinkContentActionTypes.DeleteSomeContentLinksSuccess,
          ),
        )
        .subscribe(() => {
          this.deleteLinksSuccess$.next(true);
          this._store.dispatch(
            new LoadChildren({ headerId: this.data.parentId }),
          );
          setTimeout(() => {
            this.deleteLinksSuccess$.next(false);
          }, 1000);
        }),
    );

    // Watch for all links deletion success
    this._subs.add(
      this._updates$
        .pipe(
          ofType<DeleteAllContentLinksSuccess>(
            LinkContentActionTypes.DeleteAllContentLinksSuccess,
          ),
        )
        .subscribe(() => {
          this.deleteLinksSuccess$.next(true);
          setTimeout(() => {
            this.closeModal();
          }, 1000);
        }),
    );

    this._subs.add(this.children$.subscribe((c) => (this.children = c)));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public closeModal() {
    this.dialogRef.close(this.children);
    this._store.dispatch(new ClearChildren());
  }

  public handleLinkDeletion(linkDeletionParams: LinkDeletionParams) {
    const { type, ids } = linkDeletionParams;
    const { message, confirm } = this._defineConfirmText(type, this.text);

    const confirmDialog = this.dialog.open(ConfirmActionComponent, {
      data: {
        message,
        text: {
          Cancel: this.text.Cancel,
          Confirm: confirm,
        },
      },
    });
    confirmDialog.afterClosed().subscribe((val) => {
      if (val) {
        this._triggerDelete(type, ids);
      }
    });
  }

  public deleteLink(childId: number) {
    this._store.dispatch(new DeleteContentLink(childId));
  }

  public deleteSomeLinks(childIds: number[]) {
    this._store.dispatch(new DeleteSomeContentLinks(childIds));
  }

  public deleteAllLinks(parentId: number) {
    this._store.dispatch(new DeleteAllContentLinks(parentId));
  }

  private _triggerDelete(type, ids) {
    switch (type) {
      case 'one': {
        const id = ids[0];
        this.deleteLink(id);
        break;
      }
      case 'some': {
        this.deleteSomeLinks(ids);
        break;
      }
      case 'all': {
        const id = ids[0];
        this.deleteAllLinks(id);
      }
    }
  }

  private _defineConfirmText(type, text) {
    switch (type) {
      case 'one': {
        return {
          message: text.RemoveConfirm,
          confirm: text.RemoveLink,
        };
      }
      case 'some': {
        return {
          message: text.RemoveSomeConfirm,
          confirm: text.RemoveLinks,
        };
      }
      case 'all': {
        return {
          message: text.RemoveAllConfirm,
          confirm: text.RemoveLinks,
        };
      }
    }
  }
}
