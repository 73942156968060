import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as fromContent from '../../reducers';
import { AddToMultipleContentPacks, ContentPackActionTypes, } from './../../actions/packs.actions';
var AddToPackComponent = /** @class */ (function () {
    function AddToPackComponent(_store, _updates$, dialogRef, dialog, data) {
        var _this = this;
        this._store = _store;
        this._updates$ = _updates$;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.errorEncountered$ = new BehaviorSubject(false);
        this.success = false;
        this.availablePacks = [];
        this._subs = new Subscription();
        this.contentEntries = data.selectedItems.slice();
        // watch for successful adding to pack
        this._subs.add(this._updates$
            .pipe(ofType(ContentPackActionTypes.AddToMultipleContentPacksSuccess))
            .subscribe(function () {
            _this.success = true;
            _this.exitDialog(true, 1000);
        }));
        // watch for error adding to pack
        this._subs.add(this._updates$
            .pipe(ofType(ContentPackActionTypes.AddToMultipleContentPacksError))
            .subscribe(function () {
            _this.errorEncountered$.next(true);
        }));
    }
    AddToPackComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedPack = new FormControl('', Validators.required);
        this.packs$ = this._store.pipe(select(fromContent.getPacks));
        this.addingToPack$ = this._store.pipe(select(fromContent.isAddingToPack));
        this.addingToPackError$ = this._store.pipe(select(fromContent.checkForError('addingToPack')));
        this._subs.add(this.packs$.subscribe(function (p) { return (_this.availablePacks = p); }));
    };
    AddToPackComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    AddToPackComponent.prototype.togglePack = function (packId) {
        this.availablePacks = this.availablePacks.map(function (pack) {
            return pack.id === packId
                ? tslib_1.__assign({}, pack, { toAssign: !pack.toAssign }) : pack;
        });
    };
    AddToPackComponent.prototype.isAllSelected = function () {
        return this.availablePacks.every(function (pack) { return pack.toAssign; });
    };
    AddToPackComponent.prototype.masterToggle = function () {
        this.isAllSelected() ? this.clearSelection() : this.selectAll();
    };
    AddToPackComponent.prototype.selectAll = function () {
        this.availablePacks = this.availablePacks.map(function (pack) { return (tslib_1.__assign({}, pack, { toAssign: true })); });
    };
    AddToPackComponent.prototype.clearSelection = function () {
        this.availablePacks = this.availablePacks.map(function (pack) { return (tslib_1.__assign({}, pack, { toAssign: false })); });
    };
    AddToPackComponent.prototype._getSelectedItems = function () {
        return this.availablePacks.filter(function (pack) { return pack.toAssign; });
    };
    AddToPackComponent.prototype._getSelectedItemIds = function () {
        return this._getSelectedItems().map(function (pack) { return pack.id; });
    };
    AddToPackComponent.prototype.removeFromContentEntries = function (id) {
        this.contentEntries = this.contentEntries.filter(function (c) { return c.id !== id; });
    };
    AddToPackComponent.prototype.exitDialog = function (status, timeout) {
        var _this = this;
        if (status === void 0) { status = false; }
        if (timeout === void 0) { timeout = 0; }
        setTimeout(function () {
            _this.dialogRef.close(status);
        }, timeout);
    };
    AddToPackComponent.prototype.cancel = function () {
        this.exitDialog();
    };
    AddToPackComponent.prototype.disableSubmission = function () {
        return (this._getSelectedItems().length === 0 || this.contentEntries.length === 0);
    };
    AddToPackComponent.prototype.addToPack = function () {
        this.errorEncountered$.next(false);
        var packIds = this._getSelectedItemIds();
        var contentEntries = this.contentEntries.map(function (c) { return c.id; });
        var addItemToPack = {
            packIds: packIds,
            contentEntries: contentEntries,
        };
        this._store.dispatch(new AddToMultipleContentPacks(addItemToPack));
    };
    return AddToPackComponent;
}());
export { AddToPackComponent };
