export class MarkMessageStarredRequest {
  private Action = 'MarkMessageStarred';
  public PublicToken: string;
  public MessageId: number;

  constructor({ PublicToken, MessageId }) {
    this.PublicToken = PublicToken;
    this.MessageId = MessageId;
  }
}
