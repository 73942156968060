import * as tslib_1 from "tslib";
import { ClinicActionTypes } from '../actions/clinic.actions';
export var featureStateName = 'clinic';
export var initialState = {
    languages: [],
    defaultDiallingCode: '',
    defaultTimezone: '',
    staffProfiles: {},
    isGlobalPortal: false,
    hasSetGlobalPortal: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    switch (action.type) {
        case ClinicActionTypes.LoadClinicLanguagesSuccess: {
            return tslib_1.__assign({}, state, { languages: action.payload });
        }
        case ClinicActionTypes.LoadClinicInfoSuccess: {
            return tslib_1.__assign({}, state, { defaultDiallingCode: action.payload.DefaultDiallingCode, defaultTimezone: action.payload.DefaultTimezone, isGlobalPortal: action.payload.IsGlobalPortal, hasSetGlobalPortal: true });
        }
        case ClinicActionTypes.LoadStaffProfile: {
            return tslib_1.__assign({}, state, { staffProfiles: tslib_1.__assign({}, state.staffProfiles, (_a = {}, _a[action.payload.id] = action.payload, _a)) });
        }
        case ClinicActionTypes.ClearStaffProfiles: {
            return tslib_1.__assign({}, state, { staffProfiles: {} });
        }
        default:
            return state;
    }
}
export var getLanguages = function (state) { return state.languages; };
export var getDefaultLanguage = function (state) {
    return state.languages.find(function (language) { return language.isdefault; });
};
export var getDefaultDiallingCode = function (state) {
    return state.defaultDiallingCode;
};
export var getDefaultTimezone = function (state) { return state.defaultTimezone; };
export var getStaffProfiles = function (state) { return state.staffProfiles; };
export var getIsGlobalPortal = function (state) { return state.isGlobalPortal; };
export var getHasSetGlobalPortal = function (state) { return state.hasSetGlobalPortal; };
