import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { ControlContainer, } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Md5HashService } from 'src/app/core/services/md5-hash.service';
import { FileUpload } from 'src/app/core/services/s3.service';
import { AddVideoComponent } from '../add-video/add-video.component';
var ContentEditBodyComponent = /** @class */ (function () {
    function ContentEditBodyComponent(_controlContainer, dialog, change, _s3, _MD5) {
        this._controlContainer = _controlContainer;
        this.dialog = dialog;
        this.change = change;
        this._s3 = _s3;
        this._MD5 = _MD5;
        this.introductionError = new EventEmitter();
        // Introduction edior configuration
        this.editorConfig = {
            toolbar: [
                ['bold'],
                [{ align: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
            ],
        };
        this.introError$ = new BehaviorSubject(false);
        this.introText = '';
        this.videoPreview$ = new BehaviorSubject('');
        this.showRemoveVideoButton$ = new BehaviorSubject(false);
        this._subs = new Subscription();
    }
    Object.defineProperty(ContentEditBodyComponent.prototype, "requiredSections", {
        get: function () {
            return this.sectionsForm.get('requiredSections');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentEditBodyComponent.prototype, "introduction", {
        get: function () {
            return this.requiredSections.value.find(function (s) { return s.title === 'Introduction'; });
        },
        enumerable: true,
        configurable: true
    });
    ContentEditBodyComponent.prototype.ngOnInit = function () {
        this.sectionsForm = this._controlContainer.control;
        if (!this.contentEditorEnabled) {
            this.editorConfig = {
                toolbar: [],
            };
        }
        var video = this.requiredSections.value.find(function (f) { return f.type === 'video'; });
        var html = video.content;
        if (html) {
            this.submitHTML(html);
        }
    };
    ContentEditBodyComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.introIsEmpty) {
            this.introError$.next(changes.introIsEmpty.currentValue);
        }
    };
    ContentEditBodyComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    ContentEditBodyComponent.prototype.getIntroductionText = function (editorContent) {
        this._validateIntroduction(editorContent.text);
    };
    ContentEditBodyComponent.prototype.editorCreated = function (editorCreatedEvt) {
        var introText = editorCreatedEvt.container.innerText;
        this._validateIntroduction(introText);
    };
    ContentEditBodyComponent.prototype._validateIntroduction = function (introduction) {
        this.introText = introduction;
        if (introduction.length > 1000 ||
            introduction.length === 0 ||
            !introduction) {
            this.introError$.next(true);
            this.introductionError.emit(true);
        }
        else {
            this.introError$.next(false);
            this.introductionError.emit(false);
        }
        this.change.markForCheck();
        this.change.detectChanges();
    };
    ContentEditBodyComponent.prototype.openVideoDialog = function (type) {
        var _this = this;
        return new Promise(function (res, rej) {
            var validAccepts = _this._s3.fetchAccepts(type);
            var fileName = _this._MD5.generateFileName();
            var videoDialog = _this.dialog.open(AddVideoComponent, {
                data: {
                    fileName: fileName,
                    accept: validAccepts,
                    text: _this.videoText,
                    public: true,
                    patientOnly: false,
                    patientId: null,
                },
            });
            videoDialog.afterClosed().subscribe(function (file) {
                if (file && file.html) {
                    res(file.html);
                }
                else {
                    res(null);
                }
            });
        });
    };
    ContentEditBodyComponent.prototype.addVideo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var html;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.openVideoDialog('video')];
                    case 1:
                        html = _a.sent();
                        if (html) {
                            this.requiredSections.at(2).patchValue({
                                content: html,
                            });
                            this.submitHTML(html);
                            this.change.markForCheck();
                            this.change.detectChanges();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ContentEditBodyComponent.prototype.removeVideo = function () {
        this.requiredSections.at(2).patchValue({
            content: '',
        });
        this.submitHTML('');
        this.change.markForCheck();
        this.change.detectChanges();
    };
    ContentEditBodyComponent.prototype.submitHTML = function (html) {
        this.showRemoveVideoButton$.next(html.length > 0);
        this.videoPreview$.next(html);
    };
    ContentEditBodyComponent.prototype.setFocus = function (event) {
        event.target.focus();
    };
    return ContentEditBodyComponent;
}());
export { ContentEditBodyComponent };
