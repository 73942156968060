import { ThemeProvider } from '@emotion/react';
import { NoSsr, StylesProvider, ThemeProvider as MuiThemeProvider, } from '@material-ui/core';
import { RootConfigProvider } from '@react/lib/context/rootConfig';
import { RealTimeMessagingConnectionProvider } from '@react/lib/context/websocket';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { Logout } from 'src/app/auth/actions/auth.actions';
import { ApiClientProvider, ContentApiClient, PortalApiClient, S3Client, } from '../../react/lib/api';
import { FeaturesProvider } from '../lib/features/context';
import { LanguageProvider } from '../lib/i18n';
import { DispatchProvider } from '../lib/store';
import * as sentry from '../sentry';
import { materialTheme, portalTheme } from '../theme';
import { Container } from './Root.styled';
// Don't set up Sentry when running tests
if (process.env.NODE_ENV !== 'test') {
    sentry.setup();
}
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
var withRouter = function (props) {
    var children = props.children;
    if (!props.router) {
        return React.createElement(Router, null, children);
    }
    return React.cloneElement(props.router, {
        children: props.children,
    });
};
var ɵ0 = withRouter;
var Root = function (props) {
    function onApiUnauthorized() {
        props.dispatch(new Logout({ isCognito: false }));
    }
    var clientArgs = {
        clinicToken: props.clinicToken,
        authService: props.authService,
        onUnauthorized: onApiUnauthorized,
    };
    var portalApiClient = new PortalApiClient(clientArgs);
    var s3ProxyApiClient = new S3Client(clientArgs);
    var contentApiClient = new ContentApiClient(clientArgs);
    return (React.createElement(NoSsr, null,
        React.createElement(FeaturesProvider, { features: props.features },
            React.createElement(DispatchProvider, { dispatch: props.dispatch },
                React.createElement(StylesProvider, { injectFirst: true },
                    React.createElement(MuiThemeProvider, { theme: materialTheme },
                        React.createElement(ThemeProvider, { theme: portalTheme },
                            React.createElement(QueryClientProvider, { client: props.queryClient || queryClient },
                                React.createElement(ApiClientProvider, { portal: portalApiClient, s3Proxy: s3ProxyApiClient, content: contentApiClient },
                                    React.createElement(RealTimeMessagingConnectionProvider, { clinicToken: clientArgs.clinicToken },
                                        React.createElement(LanguageProvider, { language: props.language, translations: props.translations },
                                            React.createElement(Container, { className: props.className },
                                                React.createElement(RootConfigProvider, { value: {
                                                        clinicToken: props.clinicToken,
                                                        user: props.user,
                                                    } }, withRouter(props))))))))))))));
};
var ɵ1 = Root;
export default Root;
export { ɵ0, ɵ1 };
