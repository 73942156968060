export var S3ActionTypes;
(function (S3ActionTypes) {
    // Server Side
    S3ActionTypes["GetS3Options"] = "[Content] Get S3 Options";
    S3ActionTypes["GetS3OptionsSuccess"] = "[Content] Get S3 Options Succes";
    S3ActionTypes["GetS3OptionsError"] = "[Content] Get S3 Options Error";
})(S3ActionTypes || (S3ActionTypes = {}));
// Action Creators
// ==================================================
// Server Side
var GetS3Options = /** @class */ (function () {
    function GetS3Options(payload) {
        this.payload = payload;
        this.type = S3ActionTypes.GetS3Options;
    }
    return GetS3Options;
}());
export { GetS3Options };
var GetS3OptionsSuccess = /** @class */ (function () {
    function GetS3OptionsSuccess(payload) {
        this.payload = payload;
        this.type = S3ActionTypes.GetS3OptionsSuccess;
    }
    return GetS3OptionsSuccess;
}());
export { GetS3OptionsSuccess };
var GetS3OptionsError = /** @class */ (function () {
    function GetS3OptionsError(payload) {
        this.payload = payload;
        this.type = S3ActionTypes.GetS3OptionsError;
    }
    return GetS3OptionsError;
}());
export { GetS3OptionsError };
