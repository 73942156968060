<div class="participant-container">
  <div *ngIf="isWaiting" class="loader">
    <div class="spinner" #spinner></div>
  </div>
  <div class="video-placeholder-container">
    <div #videoPlaceholder class="video-placeholder"></div>
    <div *ngIf="!isVideoEnabled" class="video-placeholder-cover"></div>
  </div>
  <div #audioPlaceholder class="audio"></div>
  <portal-audio-video-indicators
    *ngIf="!isWaiting"
    class="audio-video-indicators"
    [isAudioEnabled]="isAudioEnabled"
    [isVideoEnabled]="isVideoEnabled"
  ></portal-audio-video-indicators>
  <span class="participant-name">{{ name }}</span>
</div>
