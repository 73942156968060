import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery } from 'react-query';
import { POLLING_INTERVAL_MS, queryCacheKey } from '../constants';
import { filtersToApi, formatConversation } from '../lib';
export function useConversationListQuery(filters, q) {
    var _this = this;
    var apiClient = useApiClient().portal;
    var apiFilters = filtersToApi(tslib_1.__assign({}, filters, { q: q }));
    return useInfiniteQuery([queryCacheKey.CONVERSATIONS, apiFilters], function (_a) {
        var pageNumber = _a.pageParam;
        return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, apiClient.fetchConversations(tslib_1.__assign({ pageNumber: pageNumber }, apiFilters))];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, tslib_1.__assign({}, response, { data: response.data.map(formatConversation) })];
                }
            });
        });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
        refetchInterval: POLLING_INTERVAL_MS,
    });
}
