import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import * as fromDashboard from '../../../dashboard/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';

@Component({
  selector: 'portal-integration-container',
  template: `
    <portal-integration-status
      [text]="text$ | async"
      [integrated]="isIntegrated$ | async"
      [synchronised]="isSynchronised$ | async"
    ></portal-integration-status>
  `,
})
export class IntegrationStatusContainer {
  public isIntegrated$: Observable<boolean>;
  public isSynchronised$: Observable<boolean>;

  public text$: Observable<any>;

  constructor(private _store: Store<fromRoot.State>) {
    this.text$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ClinicStatus')),
    );
    this.isIntegrated$ = this._store.pipe(
      select(fromDashboard.clinicStatusIntegrated),
    );
    this.isSynchronised$ = this._store.pipe(
      select(fromDashboard.clinicStatusSynchronised),
    );
  }
}
