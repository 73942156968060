import * as tslib_1 from "tslib";
import { Box, CircularProgress, List, ListItem, ListItemText, Tooltip, } from '@material-ui/core';
import { useApiClient } from '@react/lib/api';
import { useMessageTemplatesQuery } from '@react/lib/api/hooks';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Empty } from './Empty';
import { MessageIcon } from './icons/MessageIcon';
import { Loading } from './Loading';
import { MessageTemplates } from './MessageEditor.styled';
import MessageEditorSearchField from './MessageEditorSearchField';
var MAX_TOOLTIP_LENGTH = 150;
var clampText = function (text) {
    return text.length <= MAX_TOOLTIP_LENGTH
        ? text
        : text.slice(0, MAX_TOOLTIP_LENGTH - 1) + "\u2026";
};
var ɵ0 = clampText;
var MessageAddTemplate = function (_a) {
    var onAddTemplate = _a.onAddTemplate, patientId = _a.patientId;
    var t = useTranslations().t;
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    var _b = useState(''), searchInputValue = _b[0], setSearchInputValue = _b[1];
    var debouncedSearchTerm = useDebouncedState(searchInputValue, 500);
    var _c = useMessageTemplatesQuery(), data = _c.data, isLoading = _c.isLoading, isSuccess = _c.isSuccess;
    var getMessageTemplate = useMutation(function (requestData) {
        return apiClient.fetchMessageTemplateMessage(requestData);
    }, {
        onSuccess: function (result, requestArgs) {
            Promise.resolve(queryClient.setQueryData(['messageTemplate', requestArgs], result));
        },
    });
    var messageTemplates = data ? data.data : undefined;
    // If we don't do this we're back to every keystroke causing the
    // the list to be filtered.
    var results = useMemo(function () {
        if (!messageTemplates || !messageTemplates.length) {
            return [];
        }
        var searchTermLower = debouncedSearchTerm.trim().toLowerCase();
        if (searchTermLower === '') {
            return messageTemplates;
        }
        return messageTemplates.filter(function (_a) {
            var content = _a.content, name = _a.name;
            return name.toLowerCase().includes(searchTermLower) ||
                content.toLowerCase().includes(searchTermLower);
        });
    }, [debouncedSearchTerm, messageTemplates]);
    var handleAddMessageTemplate = function (templateId) {
        var matchingTemplate = (messageTemplates || []).find(function (mt) { return mt.id === templateId; });
        if (!matchingTemplate) {
            return;
        }
        getMessageTemplate
            .mutateAsync({
            patientId: patientId,
            id: matchingTemplate.id,
        })
            .then(function (_a) {
            var message = _a.data.message;
            onAddTemplate(message);
        });
    };
    var noResults = data != null && data.data.length === 0;
    var noMatchingResults = !noResults && results.length === 0;
    return (React.createElement(MessageTemplates, null,
        React.createElement(MessageEditorSearchField, { onChangeText: setSearchInputValue, value: searchInputValue, placeholder: t.Admin.MessageTemplatesSearchPlaceholder }),
        isLoading ? (React.createElement(Loading, null)) : noResults ? (React.createElement(Empty, { icon: React.createElement(MessageIcon, null), message: t.Messages.NoMessageTemplates })) : noMatchingResults ? (React.createElement(Empty, { icon: React.createElement(MessageIcon, null), message: t.Messages.NoMatchingMessageTemplates })) : isSuccess ? (React.createElement(List, null, results.map(function (_a) {
            var id = _a.id, content = _a.content, name = _a.name;
            var onClick = getMessageTemplate.isLoading
                ? undefined
                : function () { return handleAddMessageTemplate(id); };
            var disabled = getMessageTemplate.isLoading;
            var isCurrentlyAdding = getMessageTemplate.isLoading &&
                getMessageTemplate.variables &&
                getMessageTemplate.variables.id === id;
            return (React.createElement(ListItem, tslib_1.__assign({ button: true, "data-id": id, key: id }, { disabled: disabled, onClick: onClick }, { disabled: getMessageTemplate.isLoading, onClick: onClick }),
                React.createElement(Box, { display: "flex", justifyContent: "space-between", flex: 1, alignItems: "center" },
                    React.createElement(Tooltip, { title: clampText(content) },
                        React.createElement(ListItemText, null, name)),
                    isCurrentlyAdding && React.createElement(CircularProgress, { size: 14 }))));
        }))) : null));
};
var ɵ1 = MessageAddTemplate;
export default MessageAddTemplate;
export { ɵ0, ɵ1 };
