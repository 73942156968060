/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./folder-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./folder-info.component";
var styles_FolderInfoComponent = [i0.styles];
var RenderType_FolderInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FolderInfoComponent, data: {} });
export { RenderType_FolderInfoComponent as RenderType_FolderInfoComponent };
export function View_FolderInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "div", [["class", "folder-info"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.goToMessages.emit(_co.messageTypeId) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text.Tooltip; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text.Unresolved; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.count; _ck(_v, 6, 0, currVal_2); }); }
export function View_FolderInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-folder-info", [], null, null, null, View_FolderInfoComponent_0, RenderType_FolderInfoComponent)), i1.ɵdid(1, 49152, null, 0, i9.FolderInfoComponent, [], null, null)], null, null); }
var FolderInfoComponentNgFactory = i1.ɵccf("portal-folder-info", i9.FolderInfoComponent, View_FolderInfoComponent_Host_0, { name: "name", messageTypeId: "messageTypeId", count: "count", text: "text" }, { goto: "goto", goToMessages: "goToMessages" }, []);
export { FolderInfoComponentNgFactory as FolderInfoComponentNgFactory };
