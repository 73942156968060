import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery } from 'react-query';

export function useDrugTypesQuery({ searchTerm }: { searchTerm: string }) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    ['drugTypes', { searchTerm }],
    ({ pageParam }: { pageParam?: number }) =>
      apiClient.fetchDrugTypes({ pageParam, query: searchTerm }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
    },
  );
}
