<!-- Person Information -->
<!-- 
  Section reqs:
    - image
    - FirstName
    - LastName
    - Id
-->
<div
  class="person-top-level"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <!-- image -->
  <!-- <div
    class="avatar"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-icon>person_outline</mat-icon>
  </div> -->

  <!-- Name and Id -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="15px"
    class="patient-name"
  >
    {{ patient?.name || '' }}
    <span *ngIf="!!(patient && patient.patientIdentifier)">
      <mat-icon>remove</mat-icon>
      {{ patient.patientIdentifier }}
    </span>
  </div>

  <!-- Message and call buttons -->
  <div
    class="new-message"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    <button
      *ngIf="videoCallsEnabled"
      mat-raised-button
      class="portal-btn btn-primary"
      [disabled]="!patient"
      (click)="openNewVideoCall.emit()"
    >
      {{ text.NewVideoCall }}
    </button>
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      [disabled]="!patient"
      (click)="openNewMessage.emit()"
    >
      {{ text.NewMessage }}
    </button>
  </div>
</div>
