import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { SettingsSection } from '../models/section';
import * as fromLanguage from './language.reducer';
import * as fromSections from './sections.reducer';

// Interfaces
export interface SettingsState {
  languages: fromLanguage.State;
  sections: fromSections.State;
}

export interface State extends fromRoot.State {
  settings: SettingsState;
}

// Reducer definition
export const reducers: ActionReducerMap<SettingsState> = {
  languages: fromLanguage.reducer,
  sections: fromSections.reducer,
};

// Selectors
export const getSettingsState =
  createFeatureSelector<SettingsState>('settings');

// Language Selectors
export const getLanguages = createSelector(
  getSettingsState,
  (state: SettingsState) => state.languages,
);
export const isLanguageSet = createSelector(
  getLanguages,
  fromLanguage.getLanguageSet,
);
export const getCurrentLanguage = createSelector(
  getLanguages,
  (state: fromLanguage.State) => state.language,
);
export const getLanguageTranslations = createSelector(
  getCurrentLanguage,
  getLanguages,
  (language: string, state: fromLanguage.State) => {
    return state.text[language];
  },
);
export const getSectionTranslations = (section: string) =>
  createSelector(
    getLanguageTranslations,
    // tslint:disable-next-line: ban-types
    (sections: { [key: string]: { [key: string]: string | Function } }) => {
      return sections[section];
    },
  );
export const getAvailableLanguages = createSelector(
  getLanguages,
  (state: fromLanguage.State) => Object.keys(state.text),
);

// Sections Selectors
export const getSettingsSections = createSelector(
  getSettingsState,
  (state: SettingsState) => state.sections,
);
export const getSections = createSelector(
  getSettingsSections,
  fromSections.getSectionsArray,
);
export const getSectionsList = createSelector(
  getSections,
  (sections: SettingsSection[]) => sections.map((s) => s.name),
);
