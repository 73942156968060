import { Action, Store } from '@ngrx/store';
import { Message } from './../../models/Message';

import { OutboundMessage } from 'src/app/core/models/OutboundMessage';
import { IBasicServerResponse } from '../../models/BasicServerResponse';
import { ServerError } from '../../models/Error';
import { DraftMessage, NewMessageModel } from '../../models/NewMessageModel';
import { NewMessageThread } from '../../models/NewMessageThread';
import { ToastOptions } from '../../models/ToastOptions';
import { TabState } from '../models/TabState';

export enum NewMessageActionTypes {
  /**
   * these actions allow us to trigger the toast
   * before sending the message and reflecting
   * message set status in the displayed toast
   */
  SendOutboundMessage = '[NewMessage] Send new outbound message',

  // Toast to allow rerouting
  ShowRerouteToast = '[Toast] Showing Reroute Toast',

  StoreDraft = '[NewMessage] Store Draft',
  /**
   * new message involves the sending a new message
   * to an existing conversation
   */
  SendNewMessage = '[NewMessage] Send New Message',

  /**
   * A new thread involves sending a new message in
   * a brand new thread / conversation.
   */
  SendNewThread = '[NewMessage] Send New Thread',

  SendNewMessageSuccess = '[NewMessage] Send New Message Or Thread Success',
  SendNewMessageError = '[NewMessage] Send New Message Or Thread Error',

  // React compatible toast
  ShowNewConvoToastReact = '[Toast] Showing New Convo Toast React',
}
// =============== Client Side Actions =============== //
export class SendOutboundMessage implements Action {
  readonly type = NewMessageActionTypes.SendOutboundMessage;

  constructor(public payload: OutboundMessage) {}
}

export class ShowNewConvoToastReact implements Action {
  readonly type = NewMessageActionTypes.ShowNewConvoToastReact;

  constructor(
    public payload: {
      title: string;
      message: string;
      onActionClicked: () => void;
    },
  ) {}
}

export class ShowRerouteToast implements Action {
  readonly type = NewMessageActionTypes.ShowRerouteToast;

  constructor(
    public payload: {
      toast: ToastOptions;
      route?: string;
      afterAction?: Action;
      actions?: [any]; // array of actions to dispatch upon triggering of reroute
      other?: any;
    },
  ) {}
}

// =============== Server Side Actions =============== //
export class StoreDraft implements Action {
  readonly type = NewMessageActionTypes.StoreDraft;

  constructor(public payload: DraftMessage) {}
}

export class SendNewMessage implements Action {
  readonly type = NewMessageActionTypes.SendNewMessage;

  constructor(public payload: NewMessageModel) {}
}

export class SendNewThread implements Action {
  readonly type = NewMessageActionTypes.SendNewThread;

  constructor(
    public payload: {
      messageStarringEnabled: boolean;
      data: {
        newMessage;
        messageInfo;
      };
      toast: {
        success: ToastOptions;
        error: ToastOptions;
      };
    },
  ) {}
}
export class SendNewMessageSuccess implements Action {
  readonly type = NewMessageActionTypes.SendNewMessageSuccess;

  constructor(
    public payload: {
      mesageStarringEnabled: boolean;
    },
  ) {}
}
export class SendNewMessageError implements Action {
  readonly type = NewMessageActionTypes.SendNewMessageError;

  constructor(public payload: ServerError) {}
}

export type NewMessageActionsUnion =
  | SendOutboundMessage
  | ShowRerouteToast
  | SendNewMessage
  | SendNewMessageSuccess
  | SendNewMessageError
  | SendNewThread
  | StoreDraft;
