import { Action } from '@ngrx/store';
import { ActiveToast } from 'ngx-toastr';
import { ToastOptions } from 'src/app/models/ToastOptions';
import { DisplayToastPayload } from '../models/DisplayToastPayload';

export enum ToastActions {
  DisplayToast = '[Toast] Display',
  ToastDisplayed = '[Toast] Displayed',
  ToastRemoved = '[Toast] Removed',
}

export class DisplayToastAction implements Action {
  readonly type = ToastActions.DisplayToast;
  constructor(public payload: DisplayToastPayload) {}
}

export class ToastDisplayedAction implements Action {
  readonly type = ToastActions.ToastDisplayed;
  constructor(public payload: { toastRef: any }) {}
}

export class ToastRemovedAction implements Action {
  readonly type = ToastActions.ToastRemoved;
  constructor() {}
}

export type ToastActionsUnion =
  | DisplayToastAction
  | ToastRemovedAction
  | ToastDisplayedAction;
