import * as React from 'react';
import { Container, InnerContainer, StyledText } from './Empty.styled';
/**
 * Can be used to provide a message to the user that there is no loaded data
 * available.
 */
export var Empty = function (_a) {
    var _b = _a["data-testid"], dataTestId = _b === void 0 ? 'Empty' : _b, icon = _a.icon, message = _a.message, className = _a.className;
    return (React.createElement(Container, { className: className },
        React.createElement(InnerContainer, { "data-testid": dataTestId },
            icon && React.createElement("div", null, icon),
            React.createElement(StyledText, { component: "span", variant: "h6" }, message))));
};
