import { select, Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface, } from 'ngx-perfect-scrollbar';
import { combineLatest, Subscription } from 'rxjs';
import * as fromAuth from '../auth/reducers';
import * as fromRoot from '../reducers';
import { LoadMessageTypes } from './actions/load-message.actions';
import { ConversationResolverComponent } from './containers/conversation-resolver.component';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelPropagation: true,
};
export var COMPONENTS = [ConversationResolverComponent];
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var MessagingModule = /** @class */ (function () {
    function MessagingModule(_store) {
        var _this = this;
        this._store = _store;
        var loggedIn$ = this._store.pipe(select(fromAuth.getLoggedIn));
        var isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        var subs = new Subscription().add(combineLatest(loggedIn$, isGlobalPortal$).subscribe(function (_a) {
            var loggedIn = _a[0], isGlobalPortal = _a[1];
            if (loggedIn && !isGlobalPortal) {
                _this._store.dispatch(new LoadMessageTypes());
            }
        }));
    }
    return MessagingModule;
}());
export { MessagingModule };
export { ɵ0 };
