import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Tabs } from '@material-ui/core';
import SearchBar from '@react/components/SearchBar';
export var StyledTabs = styled(Tabs)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  border-bottom: 2px;\n  max-width: 400px;\n  & .MuiTabs-indicator {\n    background-color: ", ";\n  }\n"], ["\n  border-bottom: 2px;\n  max-width: 400px;\n  & .MuiTabs-indicator {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var StyledActionsContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  border-top-color: ", ";\n  border-top-width: 1px;\n  border-top-style: solid;\n  padding: ", "px;\n  display: flex;\n  justify-content: end;\n"], ["\n  border-top-color: ", ";\n  border-top-width: 1px;\n  border-top-style: solid;\n  padding: ", "px;\n  display: flex;\n  justify-content: end;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border.default;
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var LoaderWrapper = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"], ["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"])));
export var StyledSearchBar = styled(SearchBar)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n  margin: ", "px;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 30;\n  margin: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
