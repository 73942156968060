import * as React from 'react';
import { createContext, FunctionComponent, useContext } from 'react';
import { Dispatch } from 'redux';

export const DispatchContext = createContext<Dispatch>((action) => action);

export const DispatchProvider: FunctionComponent<{ dispatch: Dispatch }> = ({
  children,
  dispatch,
}) => {
  return <DispatchContext.Provider value={dispatch} children={children} />;
};

export function useDispatch() {
  return useContext(DispatchContext);
}
