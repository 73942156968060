export interface CountryCode {
  value: string;
  sectionText?: string;
  text: string;
}

export const countryCodes = [
  { value: 'GB', sectionText: 'United Kingdom', text: ' (+44)' },
  { value: 'DE', sectionText: 'Germany', text: ' (+49)' },
  { value: 'AF', text: 'Afghanistan (+93)' },
  { value: 'AL', text: 'Albania (+355)' },
  { value: 'DZ', text: 'Algeria (+213)' },
  { value: 'AS', text: 'American Samoa (+1-684)' },
  { value: 'AD', text: 'Andorra (+376)' },
  { value: 'AO', text: 'Angola (+244)' },
  { value: 'AI', text: 'Anguilla (+1-264)' },
  { value: 'AQ', text: 'Antarctica (+672)' },
  { value: 'AG', text: 'Antigua and Barbuda (+1-268)' },
  { value: 'AR', text: 'Argentina (+54)' },
  { value: 'AM', text: 'Armenia (+374)' },
  { value: 'AW', text: 'Aruba (+297)' },
  { value: 'AU', text: 'Australia (+61)' },
  { value: 'AT', text: 'Austria (+43)' },
  { value: 'AZ', text: 'Azerbaijan (+994)' },
  { value: 'BS', text: 'Bahamas (+1-242)' },
  { value: 'BH', text: 'Bahrain (+973)' },
  { value: 'BD', text: 'Bangladesh (+880)' },
  { value: 'BB', text: 'Barbados (+1-246)' },
  { value: 'BY', text: 'Belarus (+375)' },
  { value: 'BE', text: 'Belgium (+32)' },
  { value: 'BZ', text: 'Belize (+501)' },
  { value: 'BJ', text: 'Benin (+229)' },
  { value: 'BM', text: 'Bermuda (+1-441)' },
  { value: 'BT', text: 'Bhutan (+975)' },
  { value: 'BO', text: 'Bolivia (+591)' },
  { value: 'BA', text: 'Bosnia and Herzegovina (+387)' },
  { value: 'BW', text: 'Botswana (+267)' },
  { value: 'BR', text: 'Brazil (+55)' },
  { value: 'IO', text: 'British Indian Ocean Territory (+246)' },
  { value: 'VG', text: 'British Virgin Islands (+1-284)' },
  { value: 'BN', text: 'Brunei (+673)' },
  { value: 'BG', text: 'Bulgaria (+359)' },
  { value: 'BF', text: 'Burkina Faso (+226)' },
  { value: 'BI', text: 'Burundi (+257)' },
  { value: 'KH', text: 'Cambodia (+855)' },
  { value: 'CM', text: 'Cameroon (+237)' },
  { value: 'CA', text: 'Canada (+1)' },
  { value: 'CV', text: 'Cape Verde (+238)' },
  { value: 'KY', text: 'Cayman Islands (+1-345)' },
  { value: 'CF', text: 'Central African Republic (+236)' },
  { value: 'TD', text: 'Chad (+235)' },
  { value: 'CL', text: 'Chile (+56)' },
  { value: 'CN', text: 'China (+86)' },
  { value: 'CX', text: 'Christmas Island (+61)' },
  { value: 'CC', text: 'Cocos Islands (+61)' },
  { value: 'CO', text: 'Colombia (+57)' },
  { value: 'KM', text: 'Comoros (+269)' },
  { value: 'CK', text: 'Cook Islands (+682)' },
  { value: 'CR', text: 'Costa Rica (+506)' },
  { value: 'HR', text: 'Croatia (+385)' },
  { value: 'CU', text: 'Cuba (+53)' },
  { value: 'CW', text: 'Curacao (+599)' },
  { value: 'CY', text: 'Cyprus (+357)' },
  { value: 'CZ', text: 'Czech Republic (+420)' },
  { value: 'CD', text: 'Democratic Republic of the Congo (+243)' },
  { value: 'DK', text: 'Denmark (+45)' },
  { value: 'DJ', text: 'Djibouti (+253)' },
  { value: 'DM', text: 'Dominica (+1-767)' },
  { value: 'DO', text: 'Dominican Republic (+1-809, 1-829, 1-849)' },
  { value: 'TL', text: 'East Timor (+670)' },
  { value: 'EC', text: 'Ecuador (+593)' },
  { value: 'EG', text: 'Egypt (+20)' },
  { value: 'SV', text: 'El Salvador (+503)' },
  { value: 'GQ', text: 'Equatorial Guinea (+240)' },
  { value: 'ER', text: 'Eritrea (+291)' },
  { value: 'EE', text: 'Estonia (+372)' },
  { value: 'ET', text: 'Ethiopia (+251)' },
  { value: 'FK', text: 'Falkland Islands (+500)' },
  { value: 'FO', text: 'Faroe Islands (+298)' },
  { value: 'FJ', text: 'Fiji (+679)' },
  { value: 'FI', text: 'Finland (+358)' },
  { value: 'FR', text: 'France (+33)' },
  { value: 'PF', text: 'French Polynesia (+689)' },
  { value: 'GA', text: 'Gabon (+241)' },
  { value: 'GM', text: 'Gambia (+220)' },
  { value: 'GE', text: 'Georgia (+995)' },
  { value: 'GH', text: 'Ghana (+233)' },
  { value: 'GI', text: 'Gibraltar (+350)' },
  { value: 'GR', text: 'Greece (+30)' },
  { value: 'GL', text: 'Greenland (+299)' },
  { value: 'GD', text: 'Grenada (+1-473)' },
  { value: 'GU', text: 'Guam (+1-671)' },
  { value: 'GT', text: 'Guatemala (+502)' },
  { value: 'GG', text: 'Guernsey (+44-1481)' },
  { value: 'GN', text: 'Guinea (+224)' },
  { value: 'GW', text: 'Guinea-Bissau (+245)' },
  { value: 'GY', text: 'Guyana (+592)' },
  { value: 'HT', text: 'Haiti (+509)' },
  { value: 'HN', text: 'Honduras (+504)' },
  { value: 'HK', text: 'Hong Kong (+852)' },
  { value: 'HU', text: 'Hungary (+36)' },
  { value: 'IS', text: 'Iceland (+354)' },
  { value: 'IN', text: 'India (+91)' },
  { value: 'ID', text: 'Indonesia (+62)' },
  { value: 'IR', text: 'Iran (+98)' },
  { value: 'IQ', text: 'Iraq (+964)' },
  { value: 'IE', text: 'Ireland (+353)' },
  { value: 'IM', text: 'Isle of Man (+44-1624)' },
  { value: 'IL', text: 'Israel (+972)' },
  { value: 'IT', text: 'Italy (+39)' },
  { value: 'CI', text: 'Ivory Coast (+225)' },
  { value: 'JM', text: 'Jamaica (+1-876)' },
  { value: 'JP', text: 'Japan (+81)' },
  { value: 'JE', text: 'Jersey (+44-1534)' },
  { value: 'JO', text: 'Jordan (+962)' },
  { value: 'KZ', text: 'Kazakhstan (+7)' },
  { value: 'KE', text: 'Kenya (+254)' },
  { value: 'KI', text: 'Kiribati (+686)' },
  { value: 'XK', text: 'Kosovo (+383)' },
  { value: 'KW', text: 'Kuwait (+965)' },
  { value: 'KG', text: 'Kyrgyzstan (+996)' },
  { value: 'LA', text: 'Laos (+856)' },
  { value: 'LV', text: 'Latvia (+371)' },
  { value: 'LB', text: 'Lebanon (+961)' },
  { value: 'LS', text: 'Lesotho (+266)' },
  { value: 'LR', text: 'Liberia (+231)' },
  { value: 'LY', text: 'Libya (+218)' },
  { value: 'LI', text: 'Liechtenstein (+423)' },
  { value: 'LT', text: 'Lithuania (+370)' },
  { value: 'LU', text: 'Luxembourg (+352)' },
  { value: 'MO', text: 'Macau (+853)' },
  { value: 'MK', text: 'Macedonia (+389)' },
  { value: 'MG', text: 'Madagascar (+261)' },
  { value: 'MW', text: 'Malawi (+265)' },
  { value: 'MY', text: 'Malaysia (+60)' },
  { value: 'MV', text: 'Maldives (+960)' },
  { value: 'ML', text: 'Mali (+223)' },
  { value: 'MT', text: 'Malta (+356)' },
  { value: 'MH', text: 'Marshall Islands (+692)' },
  { value: 'MR', text: 'Mauritania (+222)' },
  { value: 'MU', text: 'Mauritius (+230)' },
  { value: 'YT', text: 'Mayotte (+262)' },
  { value: 'MX', text: 'Mexico (+52)' },
  { value: 'FM', text: 'Micronesia (+691)' },
  { value: 'MD', text: 'Moldova (+373)' },
  { value: 'MC', text: 'Monaco (+377)' },
  { value: 'MN', text: 'Mongolia (+976)' },
  { value: 'ME', text: 'Montenegro (+382)' },
  { value: 'MS', text: 'Montserrat (+1-664)' },
  { value: 'MA', text: 'Morocco (+212)' },
  { value: 'MZ', text: 'Mozambique (+258)' },
  { value: 'MM', text: 'Myanmar (+95)' },
  { value: 'NA', text: 'Namibia (+264)' },
  { value: 'NR', text: 'Nauru (+674)' },
  { value: 'NP', text: 'Nepal (+977)' },
  { value: 'NL', text: 'Netherlands (+31)' },
  { value: 'AN', text: 'Netherlands Antilles (+599)' },
  { value: 'NC', text: 'New Caledonia (+687)' },
  { value: 'NZ', text: 'New Zealand (+64)' },
  { value: 'NI', text: 'Nicaragua (+505)' },
  { value: 'NE', text: 'Niger (+227)' },
  { value: 'NG', text: 'Nigeria (+234)' },
  { value: 'NU', text: 'Niue (+683)' },
  { value: 'KP', text: 'North Korea (+850)' },
  { value: 'MP', text: 'Northern Mariana Islands (+1-670)' },
  { value: 'NO', text: 'Norway (+47)' },
  { value: 'OM', text: 'Oman (+968)' },
  { value: 'PK', text: 'Pakistan (+92)' },
  { value: 'PW', text: 'Palau (+680)' },
  { value: 'PS', text: 'Palestine (+970)' },
  { value: 'PA', text: 'Panama (+507)' },
  { value: 'PG', text: 'Papua New Guinea (+675)' },
  { value: 'PY', text: 'Paraguay (+595)' },
  { value: 'PE', text: 'Peru (+51)' },
  { value: 'PH', text: 'Philippines (+63)' },
  { value: 'PN', text: 'Pitcairn (+64)' },
  { value: 'PL', text: 'Poland (+48)' },
  { value: 'PT', text: 'Portugal (+351)' },
  { value: 'PR', text: 'Puerto Rico (+1-787, 1-939)' },
  { value: 'QA', text: 'Qatar (+974)' },
  { value: 'CG', text: 'Republic of the Congo (+242)' },
  { value: 'RE', text: 'Reunion (+262)' },
  { value: 'RO', text: 'Romania (+40)' },
  { value: 'RU', text: 'Russia (+7)' },
  { value: 'RW', text: 'Rwanda (+250)' },
  { value: 'BL', text: 'Saint Barthelemy (+590)' },
  { value: 'SH', text: 'Saint Helena (+290)' },
  { value: 'KN', text: 'Saint Kitts and Nevis (+1-869)' },
  { value: 'LC', text: 'Saint Lucia (+1-758)' },
  { value: 'MF', text: 'Saint Martin (+590)' },
  { value: 'PM', text: 'Saint Pierre and Miquelon (+508)' },
  { value: 'VC', text: 'Saint Vincent and the Grenadines (+1-784)' },
  { value: 'WS', text: 'Samoa (+685)' },
  { value: 'SM', text: 'San Marino (+378)' },
  { value: 'ST', text: 'Sao Tome and Principe (+239)' },
  { value: 'SA', text: 'Saudi Arabia (+966)' },
  { value: 'SN', text: 'Senegal (+221)' },
  { value: 'RS', text: 'Serbia (+381)' },
  { value: 'SC', text: 'Seychelles (+248)' },
  { value: 'SL', text: 'Sierra Leone (+232)' },
  { value: 'SG', text: 'Singapore (+65)' },
  { value: 'SX', text: 'Sint Maarten (+1-721)' },
  { value: 'SK', text: 'Slovakia (+421)' },
  { value: 'SI', text: 'Slovenia (+386)' },
  { value: 'SB', text: 'Solomon Islands (+677)' },
  { value: 'SO', text: 'Somalia (+252)' },
  { value: 'ZA', text: 'South Africa (+27)' },
  { value: 'KR', text: 'South Korea (+82)' },
  { value: 'SS', text: 'South Sudan (+211)' },
  { value: 'ES', text: 'Spain (+34)' },
  { value: 'LK', text: 'Sri Lanka (+94)' },
  { value: 'SD', text: 'Sudan (+249)' },
  { value: 'SR', text: 'Suriname (+597)' },
  { value: 'SJ', text: 'Svalbard and Jan Mayen (+47)' },
  { value: 'SZ', text: 'Swaziland (+268)' },
  { value: 'SE', text: 'Sweden (+46)' },
  { value: 'CH', text: 'Switzerland (+41)' },
  { value: 'SY', text: 'Syria (+963)' },
  { value: 'TW', text: 'Taiwan (+886)' },
  { value: 'TJ', text: 'Tajikistan (+992)' },
  { value: 'TZ', text: 'Tanzania (+255)' },
  { value: 'TH', text: 'Thailand (+66)' },
  { value: 'TG', text: 'Togo (+228)' },
  { value: 'TK', text: 'Tokelau (+690)' },
  { value: 'TO', text: 'Tonga (+676)' },
  { value: 'TT', text: 'Trinidad and Tobago (+1-868)' },
  { value: 'TN', text: 'Tunisia (+216)' },
  { value: 'TR', text: 'Turkey (+90)' },
  { value: 'TM', text: 'Turkmenistan (+993)' },
  { value: 'TC', text: 'Turks and Caicos Islands (+1-649)' },
  { value: 'TV', text: 'Tuvalu (+688)' },
  { value: 'VI', text: 'U.S. Virgin Islands (+1-340)' },
  { value: 'UG', text: 'Uganda (+256)' },
  { value: 'UA', text: 'Ukraine (+380)' },
  { value: 'AE', text: 'United Arab Emirates (+971)' },
  { value: 'US', text: 'United States (+1)' },
  { value: 'UY', text: 'Uruguay (+598)' },
  { value: 'UZ', text: 'Uzbekistan (+998)' },
  { value: 'VU', text: 'Vanuatu (+678)' },
  { value: 'VA', text: 'Vatican (+379)' },
  { value: 'VE', text: 'Venezuela (+58)' },
  { value: 'VN', text: 'Vietnam (+84)' },
  { value: 'WF', text: 'Wallis and Futuna (+681)' },
  { value: 'EH', text: 'Western Sahara (+212)' },
  { value: 'YE', text: 'Yemen (+967)' },
  { value: 'ZM', text: 'Zambia (+260)' },
  { value: 'ZW', text: 'Zimbabwe (+263)' },
];
