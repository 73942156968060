import { Button } from '@react/components/Button';
import { LoadingError } from '@react/components/LoadingError';
import Root from '@react/components/Root';
import { useTranslations } from '@react/lib/i18n';
import React, { ComponentProps, FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DataExports from './components/DataExports';
import Nav from './components/Nav';
import { NoPermissionIcon } from './components/NoPermissionIcon';
import ResubmitPatientFormLookup from './components/PatientFormsLookup';
import {
  LoadingErrorContainer,
  Main,
  PageContainer,
  StyledRoot,
} from './SuperAdminPage.styled';

interface Props extends ComponentProps<typeof Root> {}

export const SuperAdminPage: FunctionComponent<Props> = (props) => {
  const { t } = useTranslations();
  const isSuperAdminUser = props.user.groups.includes('ClinicPortalSuperAdmin');

  return (
    <StyledRoot {...props}>
      <PageContainer>
        {!isSuperAdminUser ? (
          <LoadingErrorContainer>
            <LoadingError
              icon={<NoPermissionIcon />}
              message={t.Admin.NoAccessMessage}
            >
              <Button color="primary" href="/" variant="contained">
                {t.Admin.NoAccessButtonText}
              </Button>
            </LoadingError>
          </LoadingErrorContainer>
        ) : (
          <>
            <Nav />
            <Main>
              <Switch>
                <Route exact path="/superadmin">
                  <Redirect to="/superadmin/data-exports" />
                </Route>
                <Route exact path="/superadmin/data-exports">
                  <DataExports />
                </Route>
                <Route exact path="/superadmin/form-lookup">
                  <ResubmitPatientFormLookup />
                </Route>
                {/* Redirect any other routes */}
                <Route exact path="*">
                  <Redirect to="/superadmin" />
                </Route>
              </Switch>
            </Main>
          </>
        )}
      </PageContainer>
    </StyledRoot>
  );
};
