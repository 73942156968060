import { ModuleWithProviders } from '@angular/core';
import { ApplyFilterComponent } from './components/apply-filter/apply-filter.component';
import { VideoCallsListComponent } from './components/video-calls-list/video-calls-list.component';
import { VideoCallModalContainer } from './containers/video-call-modal/video-call-modal.container';
import { VideoCallsPageComponent } from './containers/video-calls-page/video-calls-page.container';
import { JoinDisabledPipe, StatusToTextPipe } from './pipes/call.status.pipe';
import { PermissionsService } from './services/permissions-service';
import { VideoCallsService } from './services/video-calls.service';
var COMPONENTS = [
    VideoCallsPageComponent,
    VideoCallsListComponent,
    VideoCallModalContainer,
    ApplyFilterComponent,
];
var PIPES = [StatusToTextPipe, JoinDisabledPipe];
var VideoCallsModule = /** @class */ (function () {
    function VideoCallsModule() {
    }
    VideoCallsModule.forRoot = function () {
        return {
            ngModule: VideoCallsModule,
            providers: [PermissionsService, VideoCallsService],
        };
    };
    return VideoCallsModule;
}());
export { VideoCallsModule };
