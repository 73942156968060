import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'portal-toolbar-title',
  templateUrl: './toolbar-title.component.html',
  styleUrls: ['./toolbar-title.component.scss'],
})
export class ToolbarTitleComponent {
  @Input() text: any;
  @Input() patientName: string;
  @Input() patientView: boolean;
  @Output() switchView = new EventEmitter();
  @Output() viewInfo = new EventEmitter();
}
