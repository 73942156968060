var _this = this;
import * as tslib_1 from "tslib";
import { IconButton } from '@material-ui/core';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';
import { Button } from '@react/components/Button';
import { Dialog } from '@react/components/Dialog';
import { Empty } from '@react/components/Empty';
import { HelperText } from '@react/components/HelperText';
import { MessageIcon } from '@react/components/icons/MessageIcon';
import { Input } from '@react/components/Input';
import { Label } from '@react/components/Label';
import { Loading } from '@react/components/Loading';
import { TemplateEditor } from '@react/components/TemplateEditor';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { useDeleteMessageTemplateMutation, useMessageTemplateQuery, useMessageTemplatesMutation, useMessageTemplateVariablesQuery, } from '../hooks';
import { useMessageTemplateUniqueNameValidator } from '../hooks/useMessageTemplateUniqueNameValidator';
import { AppointmentTypeTitle, TitleWrapper } from './AppointmentType.styled';
import { Actions, ButtonProgress, Container, FieldWrapper, FullHeightPaper, StyledForm, WarningIcon, WarningText, } from './MessageTemplate.styled';
var CONTENT_MAX_LENGTH = 2500;
var NAME_MIN_LENGTH = 5;
var NAME_MAX_LENGTH = 30;
var NAME_UNIQUE_NAME_DEBOUNCE_TIME = 350;
var MessageTemplateEdit = function (_a) {
    var id = _a.id;
    var _b = useHistory(), push = _b.push, replace = _b.replace;
    var _c = React.useState(false), isDeleteDialogOpen = _c[0], setIsDeleteDialogOpen = _c[1];
    var t = useTranslations().t;
    var _d = useMessageTemplateQuery({
        id: id,
    }), data = _d.data, isLoading = _d.isLoading, isError = _d.isError;
    var _e = useMessageTemplateVariablesQuery(), variablesData = _e.data, isLoadingVariables = _e.isLoading;
    var _f = useMessageTemplatesMutation(), save = _f.mutate, isSaving = _f.isLoading;
    var _g = useDeleteMessageTemplateMutation(), _delete = _g.mutate, isDeleting = _g.isLoading;
    var handleDeleteRequest = React.useCallback(function () {
        setIsDeleteDialogOpen(true);
    }, []);
    var handleDeleteAcceptClick = React.useCallback(function () {
        setIsDeleteDialogOpen(false);
        _delete(id, {
            onSuccess: function () {
                replace('/admin/message-templates');
            },
        });
    }, []);
    var handleDeleteCancelClick = React.useCallback(function () {
        setIsDeleteDialogOpen(false);
    }, []);
    var onSubmit = function (formData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            save({
                id: data != null ? data.data.id : undefined,
                content: formData.content,
                name: formData.name,
            }, {
                onSuccess: function () {
                    push('/admin/message-templates');
                },
            });
            return [2 /*return*/];
        });
    }); };
    React.useEffect(function () {
        if (isError) {
            replace('/admin/message-templates');
        }
    }, [data, isError]);
    return (React.createElement(Container, null,
        React.createElement(TitleWrapper, null,
            React.createElement(IconButton, { component: Link, to: "/admin/message-templates", size: "small" },
                React.createElement(IconArrowBack, null)),
            data != null && (React.createElement(AppointmentTypeTitle, { variant: "h5", role: "heading" }, data.data.name))),
        React.createElement(FullHeightPaper, null, isLoading || isLoadingVariables ? (React.createElement(Loading, null)) : data == null ? (React.createElement(Empty, { icon: MessageIcon, message: t.Admin.MessageTemplatesEmptyMessage })) : (React.createElement(MessageTemplateForm, { currentId: data.data.id, defaultContentValue: data.data.content, defaultNameValue: data.data.name, hasDeleteButton: true, isDeleting: isDeleting, isSaving: isSaving, onDeleteRequest: handleDeleteRequest, onSubmit: onSubmit, variables: variablesData != null ? variablesData.data : [] }))),
        React.createElement(Dialog, { acceptButtonText: t.Admin.MessageTemplatesDeleteDialogAcceptButtonText, cancelButtonText: t.Admin.MessageTemplatesDeleteDialogCancelButtonText, contentText: t.Admin.MessageTemplatesDeleteDialogContent, onAccept: handleDeleteAcceptClick, onCancel: handleDeleteCancelClick, isOpen: isDeleteDialogOpen, titleText: t.Admin.MessageTemplatesDeleteDialogTitle })));
};
var ɵ0 = MessageTemplateEdit;
var MessageTemplateNew = function () {
    var replace = useHistory().replace;
    var t = useTranslations().t;
    var _a = useMessageTemplateVariablesQuery(), variablesData = _a.data, isLoadingVariables = _a.isLoading;
    var _b = useMessageTemplatesMutation(), save = _b.mutate, isSaving = _b.isLoading;
    var onSubmit = function (formData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            save({
                content: formData.content,
                name: formData.name,
            }, {
                onSuccess: function () {
                    replace('/admin/message-templates');
                },
            });
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Container, null,
        React.createElement(TitleWrapper, null,
            React.createElement(IconButton, { component: Link, to: "/admin/message-templates", size: "small" },
                React.createElement(IconArrowBack, null)),
            React.createElement(AppointmentTypeTitle, { variant: "h5", role: "heading" }, t.Admin.MessageTemplatesNewTemplateButtonText)),
        React.createElement(FullHeightPaper, null, isLoadingVariables ? (React.createElement(Loading, null)) : (React.createElement(MessageTemplateForm, { isSaving: isSaving, onSubmit: onSubmit, variables: variablesData != null ? variablesData.data : [] })))));
};
var ɵ1 = MessageTemplateNew;
var MessageTemplateForm = function (_a) {
    var currentId = _a.currentId, isDeleting = _a.isDeleting, isSaving = _a.isSaving, _b = _a.defaultContentValue, defaultContentValue = _b === void 0 ? '' : _b, _c = _a.defaultNameValue, defaultNameValue = _c === void 0 ? '' : _c, hasDeleteButton = _a.hasDeleteButton, onDeleteRequest = _a.onDeleteRequest, onSubmit = _a.onSubmit, variables = _a.variables;
    var t = useTranslations().t;
    var _d = useForm({ mode: 'onChange' }), control = _d.control, errors = _d.errors, _e = _d.formState, isDirty = _e.isDirty, isValid = _e.isValid, register = _d.register, handleSubmit = _d.handleSubmit;
    // Needed so that we call the same `debounceAsync` instance across
    // different renders.
    var debouncedUniqueNameValidator = useMessageTemplateUniqueNameValidator({
        delay: NAME_UNIQUE_NAME_DEBOUNCE_TIME,
        errorMessage: t.Admin.MessageTemplatesNameErrorUnique,
        existingMessageTemplateId: currentId,
    });
    return (React.createElement(StyledForm, { noValidate: true, onSubmit: handleSubmit(onSubmit) },
        React.createElement(WarningText, { color: "textSecondary", variant: "body2" },
            React.createElement(WarningIcon, null),
            t.Admin.MessageTemplatesNavigationWarning),
        React.createElement(FieldWrapper, null,
            React.createElement(Label, { error: !!errors.name, htmlFor: "name" }, t.Admin.MessageTemplatesNameLabel),
            React.createElement(Input, { "aria-describedby": "nameHelper", "data-testid": "NameInput", defaultValue: defaultNameValue, error: !!errors.name, fullWidth: true, id: "name", name: "name", placeholder: t.Admin.MessageTemplatesNamePlaceholder, ref: register({
                    minLength: {
                        message: t.Admin.MessageTemplatesNameErrorLength(NAME_MIN_LENGTH, NAME_MAX_LENGTH),
                        value: NAME_MIN_LENGTH,
                    },
                    maxLength: {
                        message: t.Admin.MessageTemplatesNameErrorLength(NAME_MIN_LENGTH, NAME_MAX_LENGTH),
                        value: NAME_MAX_LENGTH,
                    },
                    required: {
                        message: t.Admin.MessageTemplatesNameErrorRequired,
                        value: true,
                    },
                    validate: {
                        uniqueName: debouncedUniqueNameValidator,
                    },
                }) }),
            React.createElement(HelperText, { "aria-live": "polite", error: !!errors.name, id: "nameHelper" }, errors.name != null ? errors.name.message : null)),
        React.createElement(FieldWrapper, null,
            React.createElement(Label, { error: !!errors.content, htmlFor: "content" }, t.Admin.MessageTemplatesTemplateContentLabel),
            React.createElement(Controller, { control: control, defaultValue: defaultContentValue, name: "content", render: 
                // @ts-ignore Need to update TypeScript
                function (_a) {
                    var onChange = _a.onChange, onBlur = _a.onBlur, value = _a.value;
                    return (React.createElement(TemplateEditor, { "aria-describedby": "contentHelperText", "data-testid": "ContentInput", error: !!errors.content, id: "content", insertVariablesButtonText: t.Admin.MessageTemplatesInsertVariableButtonText, maxLength: CONTENT_MAX_LENGTH, onBlur: function () { return onBlur(); }, onChange: function (newValue) { return onChange(newValue); }, value: value, variables: variables }));
                }, rules: {
                    maxLength: {
                        message: t.Admin.MessageTemplatesContentErrorLength(CONTENT_MAX_LENGTH),
                        value: CONTENT_MAX_LENGTH,
                    },
                    required: {
                        message: t.Admin.MessageTemplatesContentErrorRequired,
                        value: true,
                    },
                } }),
            React.createElement(HelperText, { "aria-live": "polite", error: !!errors.content, id: "contentHelperText" }, errors.content != null ? errors.content.message : null)),
        React.createElement(Actions, null,
            hasDeleteButton && (React.createElement(Button, { color: "secondary", disabled: isDeleting || isSaving, onClick: onDeleteRequest, type: "button", variant: "contained" },
                React.createElement(React.Fragment, null,
                    t.Admin.MessageTemplatesDeleteButtonText,
                    isDeleting && React.createElement(ButtonProgress, { size: 24 })))),
            React.createElement(Button, { color: "primary", disabled: !isDirty || isSaving || !isValid, type: "submit", variant: "contained" },
                React.createElement(React.Fragment, null,
                    t.Admin.MessageTemplatesSaveTemplateButtonText,
                    isSaving && React.createElement(ButtonProgress, { size: 24 }))))));
};
var ɵ2 = MessageTemplateForm;
var MessageTemplate = function () {
    var id = useParams().id;
    if (id == null) {
        return React.createElement(MessageTemplateNew, null);
    }
    var parsedId = parseInt(id, 10);
    var hasValidId = !isNaN(parsedId);
    if (!hasValidId) {
        return React.createElement(Redirect, { to: "/admin/message-templates" });
    }
    return React.createElement(MessageTemplateEdit, { id: parsedId });
};
var ɵ3 = MessageTemplate;
export default MessageTemplate;
export { ɵ0, ɵ1, ɵ2, ɵ3 };
