<mat-toolbar
  color="app-bg-light"
  class="topbar"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <!-- Menu Toggle -->
    <div class="menu-toggle">
      <button
        mat-icon-button
        (click)="open ? closeMenu.emit() : openMenu.emit()"
      >
        <mat-icon aria-label="Menu Open / Close Button">menu</mat-icon>
      </button>
    </div>

    <!-- section title -->
    <div class="page-title">
      {{ text.PageTitle(pageTitle) }}
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <!-- Branding Section -->
    <div>
      <span *ngIf="!clinic || !loggedIn" class="navbar-brand">Salve</span>
      <portal-switch *ngIf="loggedIn" [clinic]="clinic"></portal-switch>
    </div>
  </div>

  <!-- Searchbar and User profile -->
  <div class="topbar-actions" fxLayout="row" fxLayoutAlign="end center">
    <ng-content></ng-content>
  </div>
</mat-toolbar>
