import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import { ClinicMedicationRequest } from '../models/requests/clinic-medication.request';
import { ClinicNonTimedTriggerMedicationRequest } from '../models/requests/clinic-non-timed-trigger.request';
import { ClinicSummaryRequest } from '../models/requests/clinic-summary.request';
import { ClinicMedicationResponse } from '../models/responses/clinic-medication.response';
import { ClinicSummaryResponse } from '../models/responses/clinic-summary.response';

@Injectable()
export class DashboardService {
  private publicKey$: Observable<string>;
  constructor(
    private _httpService: HttpService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler
  ) {
    this.publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
  }

  public getClinicSummary(): Observable<ClinicSummaryResponse> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        return this._httpService
          .performResolvePostRequest(
            new ClinicSummaryRequest({ PublicToken: pk })
          )
          .pipe(
            map((res: ClinicSummaryResponse) => res),
            catchError((err) => {
              return throwError(new Error('Error retrieving summary'));
            })
          );
      })
    );
  }

  public getClinicMedications(): Observable<ClinicMedicationResponse> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        return this._httpService
          .performResolvePostRequest(
            new ClinicMedicationRequest({ PublicToken: pk })
          )
          .pipe(
            map((res: ClinicMedicationResponse) => res),
            catchError((err) => {
              return throwError(new Error('Error Retrieving Medications'));
            })
          );
      })
    );
  }

  public getClinicNonTimedTriggerMedications(): Observable<ClinicMedicationResponse> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        return this._httpService
          .performResolvePostRequest(
            new ClinicNonTimedTriggerMedicationRequest({ PublicToken: pk })
          )
          .pipe(
            map((res: ClinicMedicationResponse) => res),
            catchError((err) => {
              return throwError(new Error('Error Retrieving Medications'));
            })
          );
      })
    );
  }
}
