import styled from '@emotion/styled';
import { List, ListItem, Paper } from '@material-ui/core';
import { usePatientsInfiniteQuery } from '@react/lib/api/hooks';
import { Patient } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Loading } from './Loading';
import { TextInput } from './TextInput';

export interface PatientInputProps {
  id: string;
  label: string;
  onChange: (patient: Patient | null) => void;
  defaultPatient?: Patient;
  disabled?: boolean;
}

export const PatientInput: React.FC<PatientInputProps> = ({
  id,
  label,
  onChange,
  defaultPatient = null,
  disabled = false,
}) => {
  const { t } = useTranslations();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebouncedState(inputValue, 500);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(
    defaultPatient,
  );
  const patientsResult = usePatientsInfiniteQuery({
    q: debouncedInputValue.length > 0 ? debouncedInputValue : undefined,
  });
  const localeDateTime = useLocaleDateTime();

  const data = patientsResult.data;

  function buildPatientLabel(patient: Patient | null) {
    if (!patient) {
      return null;
    }
    let patientLabel = `${patient.firstname} ${patient.lastname}`;

    if (patient.patientidentifier) {
      patientLabel = `${patientLabel} (${patient.patientidentifier})`;
    }

    if (patient.dateofbirth) {
      patientLabel = `${patientLabel} - ${localeDateTime.format(
        new Date(patient.dateofbirth),
        'PPP',
        {
          utc: true,
        },
      )}`;
    }

    return patientLabel;
  }

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
    if (e.target.value === '') {
      setSelectedPatient(null);
    }
  }

  function onInputFocus() {
    setAutocompleteOpen(true);
  }

  function onPatientClick(patient: Patient) {
    setSelectedPatient(patient);
    setInputValue(buildPatientLabel(patient) || '');
    if (inputRef.current) {
      inputRef.current.blur();
    }
    setAutocompleteOpen(false);
  }

  function onAutocompleteClose() {
    if (inputRef.current) {
      inputRef.current.blur();
    }
    setAutocompleteOpen(false);
  }

  function onAutocompleteScrollEnd() {
    if (
      patientsResult.hasNextPage &&
      !patientsResult.isFetchingNextPage &&
      !patientsResult.isLoading
    ) {
      patientsResult.fetchNextPage();
    }
  }

  useEffect(() => {
    if (selectedPatient) {
      onPatientClick(selectedPatient);
    }
    onChange(selectedPatient);
  }, [selectedPatient]);

  return (
    <StyledContainer>
      {autocompleteOpen && (
        <div className="patientInput-clickAway" onClick={onAutocompleteClose} />
      )}
      <div className="patientInput-inputWrapper">
        <TextInput
          ref={inputRef}
          id={id}
          label={label}
          placeholder={t.PatientList.SearchPatients}
          fullWidth
          value={inputValue}
          onChange={onInputChange}
          onFocus={onInputFocus}
          disabled={disabled}
        />
        {autocompleteOpen && (
          <Paper
            className="patientInput-autocomplete"
            style={{
              width: inputRef.current
                ? inputRef.current.offsetWidth
                : undefined,
            }}
            elevation={8}
          >
            <List>
              {data &&
                data.pages.map((page) =>
                  page.data.map((patient) => (
                    <ListItem
                      key={patient.id}
                      className={'patientInput-listItem'}
                      button
                      onClick={() => onPatientClick(patient)}
                    >
                      {buildPatientLabel(patient)}
                    </ListItem>
                  )),
                )}
            </List>
            <Waypoint onEnter={onAutocompleteScrollEnd} />
            {patientsResult.isLoading ||
              (patientsResult.isFetchingNextPage && <Loading />)}
          </Paper>
        )}
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .patientInput-clickAway {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .patientInput-inputWrapper {
    position: relative;
  }

  .patientInput-autocomplete {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 200px;
    overflow: auto;
    padding-right: 2px;
    z-index: 2;
  }

  .patientInput-listItem {
    color: ${({ theme }) => theme.colors.greyishBrown};

    &:hover {
      background-color: ${({ theme }) => theme.colors.paleGray};
    }
  }

  input.MuiInputBase-input:disabled {
    background-color: #f7f7fb;
  }
`;
