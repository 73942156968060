
import {
  DashboardActionsUnion,
  DashboardActionTypes,
} from '../actions/dashboard.actions';
import { ClinicMedicationResponse } from '../models/responses/clinic-medication.response';
import { ClinicSummaryResponse } from '../models/responses/clinic-summary.response';

export interface State {
  summary: ClinicSummaryResponse;
  medications: ClinicMedicationResponse;
  nonTimedTriggerMedications: ClinicMedicationResponse;
}

export const initialState: State = {
  summary: {
    MessagesAlerts: 0,
    NurseMessagesAlerts: 0,
    AdminMessagesAlerts: 0,
    LabMessagesAlerts: 0,
    MedicationAlerts: 0,
    NurseUnresolvedMessageCount: 0,
    LabUnresolvedMessageCount: 0,
    AdminUnresolvedMessageCount: 0,
  },
  medications: {
    Medication: [],
  },
  nonTimedTriggerMedications: {
    Medication: [],
  },
};

export function reducer(
  state = initialState,
  action: DashboardActionsUnion,
): State {
  switch (action.type) {
    case DashboardActionTypes.LoadClinicSummarySuccess: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case DashboardActionTypes.LoadClinicMedicationSuccess: {
      return {
        ...state,
        medications: action.payload,
      };
    }
    case DashboardActionTypes.LoadClinicNonTimedTriggerMedicationSuccess: {
      return {
        ...state,
        nonTimedTriggerMedications: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getSummary = (state: State) => state.summary;
export const getMedications = (state: State) => state.medications;
export const getNonTimedTriggerMedications = (state: State) =>
  state.nonTimedTriggerMedications;
