import { StaffProfiles } from '@react/lib/api/types/clinicUsers';
import { Language } from '../../models/Language';
import { ClinicActions, ClinicActionTypes } from '../actions/clinic.actions';

export const featureStateName = 'clinic';

export interface State {
  languages: Language[];
  defaultDiallingCode: string;
  defaultTimezone: string;
  staffProfiles: StaffProfiles;
  isGlobalPortal: boolean;
  hasSetGlobalPortal: boolean;
}

export const initialState: State = {
  languages: [],
  defaultDiallingCode: '',
  defaultTimezone: '',
  staffProfiles: {},
  isGlobalPortal: false,
  hasSetGlobalPortal: false,
};

export function reducer(
  state: State = initialState,
  action: ClinicActions,
): State {
  switch (action.type) {
    case ClinicActionTypes.LoadClinicLanguagesSuccess: {
      return {
        ...state,
        languages: action.payload,
      };
    }
    case ClinicActionTypes.LoadClinicInfoSuccess: {
      return {
        ...state,
        defaultDiallingCode: action.payload.DefaultDiallingCode,
        defaultTimezone: action.payload.DefaultTimezone,
        isGlobalPortal: action.payload.IsGlobalPortal,
        hasSetGlobalPortal: true,
      };
    }
    case ClinicActionTypes.LoadStaffProfile: {
      return {
        ...state,
        staffProfiles: {
          ...state.staffProfiles,
          [action.payload.id]: action.payload,
        },
      };
    }
    case ClinicActionTypes.ClearStaffProfiles: {
      return {
        ...state,
        staffProfiles: {},
      };
    }
    default:
      return state;
  }
}

export const getLanguages = (state: State) => state.languages;
export const getDefaultLanguage = (state: State) =>
  state.languages.find((language) => language.isdefault);
export const getDefaultDiallingCode = (state: State) =>
  state.defaultDiallingCode;
export const getDefaultTimezone = (state: State) => state.defaultTimezone;
export const getStaffProfiles = (state: State) => state.staffProfiles;
export const getIsGlobalPortal = (state: State) => state.isGlobalPortal;
export const getHasSetGlobalPortal = (state: State) => state.hasSetGlobalPortal;
