import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { GetContentTypes } from 'src/app/content/actions/content.actions';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { FileUpload } from '../../../core/services/s3.service';
import * as fromSettings from '../../../settings/reducers';
import { CreatePatientOnlyAssignments, DeletePatientOnlyAssignments, GetContentEntries, GetPatientAssignedContent, GetPatientOnlyAssignments, SetContentSelectFilter, UpdatePatientOnlyAssignments, } from '../../actions/content-assignment.actions';
import * as fromContentAssignment from '../../reducers';
var PatientDocumentsComponent = /** @class */ (function () {
    function PatientDocumentsComponent(_route, _store, dialog, change, _s3) {
        var _this = this;
        this._route = _route;
        this._store = _store;
        this.dialog = dialog;
        this.change = change;
        this._s3 = _s3;
        this.config = {};
        // Subscriptions container
        this._subs = new Subscription();
        // fetch user assigned content and update types and entries
        this._store.dispatch(new GetContentEntries());
        this._store.dispatch(new GetContentTypes());
        this.patientId$ = this._route.params.pipe(map(function (params) { return +params.patientId; }));
        // check router for changes to target patient
        this._subs.add(this.patientId$.subscribe(function (id) {
            _this._patientId = id;
            _this._store.dispatch(new GetPatientAssignedContent(_this._patientId));
            _this._store.dispatch(new GetPatientOnlyAssignments({
                patientId: _this._patientId,
            }));
        }));
        // fetch text from store
        this.contentSwingText$ = this._store.pipe(select(fromSettings.getSectionTranslations('ContentSwing')));
        // define observables to fetch data from store
        this.activeContentTypeId$ = this._store.pipe(select(fromContentAssignment.getActiveContentType));
        this.csFilterActive$ = this._store.pipe(select(fromContentAssignment.isContentSelectFilterActive));
        this.csFilterString$ = this._store.pipe(select(fromContentAssignment.getContentSelectFilterString));
        this.acFilterActive$ = this._store.pipe(select(fromContentAssignment.isAssignedContentFilterActive));
        this.acFilterString$ = this._store.pipe(select(fromContentAssignment.getAssignedContentFilterString));
        // Patient specific files
        this.activePatientOnlyItems$ = this._store.pipe(select(fromContentAssignment.getActiveFilteredPatientOnlyContent));
        this.inactivePatientOnlyItems$ = this._store.pipe(select(fromContentAssignment.getInactiveFilteredPatientOnlyContent));
        this.isCategoryPatientOnly$ = this._store.pipe(select(fromContentAssignment.isCategoryPatientOnly));
        // fetch current patient
        this.currentPatient$ = this.patientId$.pipe(switchMap(function (id) {
            return _this._store.pipe(select(fromContentAssignment.getPatientById(id)));
        }), filter(function (clinicPatientResponse) {
            return !!clinicPatientResponse && !!clinicPatientResponse.Patient;
        }), map(function (clinicPatientResponse) {
            return clinicPatientResponse.Patient;
        }));
        this.patientName$ = this.currentPatient$.pipe(map(function (patient) {
            return patient.FirstName + " " + patient.LastName;
        }));
    }
    PatientDocumentsComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    PatientDocumentsComponent.prototype.setContentSelectFilter = function (str) {
        this._store.dispatch(new SetContentSelectFilter(str.toLowerCase()));
    };
    PatientDocumentsComponent.prototype.createNewFile = function () {
        var _this = this;
        var validAccepts = this._s3.fetchAccepts('file');
        var fileDialog = this.dialog.open(AddFileComponent, {
            data: {
                accept: validAccepts,
                public: false,
                patientOnly: true,
                patientId: this._patientId,
            },
        });
        fileDialog
            .afterClosed()
            .subscribe(function (file) {
            if (file && file.attachment) {
                _this._store.dispatch(new CreatePatientOnlyAssignments({
                    patientId: _this._patientId,
                    body: {
                        contentfileid: file.attachment.id,
                        origintypeid: 1,
                    },
                }));
            }
        });
    };
    PatientDocumentsComponent.prototype.setPatientFileToActive = function (file) {
        file.isTransferring = true;
        this._store.dispatch(new UpdatePatientOnlyAssignments({
            assignmentId: file.assignmentid,
            updateInfo: {
                isactive: true,
            },
        }));
    };
    PatientDocumentsComponent.prototype.setPatientFileToInactive = function (file) {
        file.isTransferring = true;
        this._store.dispatch(new UpdatePatientOnlyAssignments({
            assignmentId: file.assignmentid,
            updateInfo: {
                isactive: false,
            },
        }));
    };
    PatientDocumentsComponent.prototype.deletePatientFile = function (file) {
        this._store.dispatch(new DeletePatientOnlyAssignments({
            deleteInfo: {
                assignmentid: file.assignmentid,
            },
        }));
    };
    return PatientDocumentsComponent;
}());
export { PatientDocumentsComponent };
