export var SettingsActionTypes;
(function (SettingsActionTypes) {
    SettingsActionTypes["ChangeLanguage"] = "[Settings] Change Language";
})(SettingsActionTypes || (SettingsActionTypes = {}));
// Client Side Actions
// ===================================================
var ChangeLanguage = /** @class */ (function () {
    function ChangeLanguage(payload) {
        this.payload = payload;
        this.type = SettingsActionTypes.ChangeLanguage;
    }
    return ChangeLanguage;
}());
export { ChangeLanguage };
