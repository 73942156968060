import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { GetYoutubeIdService } from '../../services/get-youtube-id.service';
var AddVideoComponent = /** @class */ (function () {
    function AddVideoComponent(dialogRef, dialog, _getYoutubeId, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this._getYoutubeId = _getYoutubeId;
        this.data = data;
        this.showOptions = new BehaviorSubject(true);
        this.addForm = new BehaviorSubject(false);
        this.text = data.text;
    }
    AddVideoComponent.prototype.ngOnInit = function () {
        this.linkForm = new FormGroup({
            link: new FormControl('', [Validators.required, Validators.minLength(3)]),
            type: new FormControl(''),
        });
    };
    AddVideoComponent.prototype.showForm = function (formType) {
        this.showOptions.next(false);
        this.addForm.next(true);
        this.setType(formType);
    };
    AddVideoComponent.prototype.setType = function (type) {
        this.linkForm.patchValue({
            type: type,
        });
    };
    AddVideoComponent.prototype.submitLink = function () {
        var type = this.linkForm.get('type').value;
        var link = this.linkForm.get('link').value;
        if (!link || link.length === 0) {
            this.cancel();
        }
        else {
            if (type === 'youtube') {
                this.addYoutube(link);
            }
            else {
                this.addLink(link);
            }
        }
    };
    // https://www.youtube.com/watch?v=ouwtdH2zt9s
    AddVideoComponent.prototype.addYoutube = function (link) {
        var id = this._getYoutubeId.getYoutubeId(link, { fuzzy: false });
        var youtubeSrc = "https://www.youtube.com/embed/" + id + "?wmode=opaque";
        this.dialogRef.close({
            html: /* HTML */ ("\n        <div class=\"embedded-video-container\">\n          <iframe\n            allow=\"fullscreen\"\n            class=\"embedded-video\"\n            frameborder=\"0\"\n            src=\"" + youtubeSrc + "\"\n          ></iframe>\n        </div>\n      ").trim(),
        });
    };
    AddVideoComponent.prototype.addLink = function (link) {
        this.dialogRef.close({
            html: "<div><a href=\"" + link + "\" >View Video Here (" + link + ")</a></div>",
        });
    };
    AddVideoComponent.prototype.uploadVideo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attachment;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._openFileDialog()];
                    case 1:
                        attachment = _a.sent();
                        if (attachment) {
                            this.dialogRef.close({
                                html: /* HTML */ ("\n          <div class=\"embedded-video-container\">\n            <iframe\n              allow=\"fullscreen\"\n              class=\"embedded-video\"\n              frameborder=\"0\"\n              src=\"" + attachment.uri + "\"\n            ></iframe>\n          </div>\n        ").trim(),
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AddVideoComponent.prototype._openFileDialog = function () {
        var _this = this;
        return new Promise(function (res, rej) {
            var fileDialog = _this.dialog.open(AddFileComponent, {
                data: {
                    accept: _this.data.accept,
                    fileName: _this.data.fileName,
                    public: _this.data.public,
                    type: 'video',
                    patientOnly: _this.data.patientOnly,
                    patientId: _this.data.patientId,
                },
            });
            fileDialog
                .afterClosed()
                .subscribe(function (file) {
                if (file && file.attachment) {
                    res(file.attachment);
                }
                else {
                    res(null);
                }
            });
        });
    };
    AddVideoComponent.prototype.cancel = function () {
        this.dialogRef.close({
            html: null,
        });
    };
    return AddVideoComponent;
}());
export { AddVideoComponent };
