import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

interface Props {
  label: string;
  caption?: string;
}

const DataRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .label-title,
  .value {
    color: #505050;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  .label {
    width: 450px;
    padding-right: 30px;
  }

  .label-caption {
    display: inline-block;
    margin-top: ${({ theme }) => theme.spacing(1)}px;
    height: 2rem;
  }
`;

export const DataRow: React.FC<Props> = ({
  children,
  label,
  caption,
  ...rest
}) => {
  return (
    <DataRowWrapper {...rest}>
      <div className="label">
        <Typography variant="body1" className="label-title">
          {label}
        </Typography>
        {caption && (
          <Typography variant="caption" className="label-caption">
            {/* caption comes from local i18n and it's only used by AppointmentType */}
            <span dangerouslySetInnerHTML={{ __html: caption }} />
          </Typography>
        )}
      </div>
      {children}
    </DataRowWrapper>
  );
};

export default DataRow;
