import React, { CSSProperties, FC, memo, useEffect, useRef } from 'react';
import { useZoom } from '../../hooks/useZoom';
import { VideoPlayer, VideoQuality, Participant } from '@zoom/videosdk';
import {
  SelfMicIconContainer,
  SelfVideoFrame,
  SelfVideoIconsContainer,
  SelfVideoIconContainer,
  SelfMicOffIcon,
  SelfVideocamOffIcon
} from './SelfPanel.styled';

const selfVideoPlayerStyle: CSSProperties = {
  width: 256,
  aspectRatio: '16/9',
  top: 20,
  right: 20,
  borderRadius: 4
};

const SelfPanel: FC<{
  micOn: boolean;
  cameraOn: boolean;
  participant: Participant;
}> = ({ micOn, cameraOn, participant }) => {
  const { zoomClient } = useZoom();
  const videoAttachedRef = useRef(false);
  const videoPlayerRef = useRef<VideoPlayer>(null);
  useEffect(() => {
    if (!zoomClient) {
      return;
    }
    const mediaStream = zoomClient.getMediaStream();
    if (!mediaStream || !videoPlayerRef.current) {
      return;
    }
    if (!videoAttachedRef.current && participant.bVideoOn) {
      videoAttachedRef.current = true;
      mediaStream.attachVideo(
        participant.userId,
        VideoQuality.Video_360P,
        videoPlayerRef.current
      );
      return;
    }
    if (videoAttachedRef.current && !participant.bVideoOn) {
      videoAttachedRef.current = false;
      mediaStream.detachVideo(participant.userId, videoPlayerRef.current);
      return;
    }
  }, [zoomClient, participant, videoAttachedRef]);
  return (
    <>
      <video-player style={selfVideoPlayerStyle} ref={videoPlayerRef} />
      <SelfVideoFrame />
      <SelfVideoIconsContainer>
        {!micOn && (
          <SelfMicIconContainer cameraOn={cameraOn}>
            <SelfMicOffIcon />
          </SelfMicIconContainer>
        )}
        {!cameraOn && (
          <SelfVideoIconContainer>
            <SelfVideocamOffIcon />
          </SelfVideoIconContainer>
        )}
      </SelfVideoIconsContainer>
    </>
  );
};

export default memo(SelfPanel);
