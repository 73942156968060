import * as tslib_1 from "tslib";
import { ClinicUserFilter } from '@react/components/ClinicUserFilter';
import { useTranslations } from '@react/lib/i18n';
import { useFilterByFullName } from '@react/pages/conversations/hooks';
import React from 'react';
export var ClinicUserMenu = function (_a) {
    var onClick = _a.onClick, clinicUsers = _a.clinicUsers, me = _a.me, conversation = _a.conversation, _b = _a.unassign, unassign = _b === void 0 ? false : _b, loading = _a.loading;
    var t = useTranslations().t;
    var _c = useFilterByFullName(clinicUsers), filteredUsers = _c[0], searchState = _c[1], setSearchState = _c[2];
    var defaultOption = tslib_1.__assign({}, me, { userfullname: t.Messages.Me });
    var unassignOption = {
        id: conversation.assignedclinicuserid
            ? conversation.assignedclinicuserid
            : null,
        userfullname: t.Messages.Unassign,
    };
    return (React.createElement(ClinicUserFilter, { id: 'ClinicUserFilter', onClick: onClick, onSearchUpdate: setSearchState, defaultOption: defaultOption, unassignOption: unassign ? unassignOption : undefined, clinicUsers: filteredUsers, searchState: searchState, messageid: conversation.messageid, loading: loading }));
};
