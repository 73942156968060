<table mat-table [dataSource]="items" class="video-calls-list-table">
  <ng-container matColumnDef="patientId">
    <th mat-header-cell *matHeaderCellDef class="patient-id-header">ID</th>
    <td mat-cell *matCellDef="let item" title="{{ item.patientidentifier }}">
      {{ item.patientidentifier }}
    </td>
  </ng-container>
  <ng-container matColumnDef="patientName">
    <th mat-header-cell *matHeaderCellDef class="patient-header">
      {{ text.Patient }}
    </th>
    <td
      mat-cell
      *matCellDef="let item"
      class="patient-column"
      title="{{ item.patientname }}"
    >
      <div class="patient-partner">
        <div class="patient">{{ item.patientname }}</div>
        <div
          class="partner"
          *ngIf="partnerInviteEnabled && item.ispartnerinvited"
        >
          <img
            class="regular-icon"
            src="assets/imgs/group_black_24dp.svg"
            alt="Pdf File Icon"
          />
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="patientDOB">
    <th mat-header-cell *matHeaderCellDef class="patient-dob">
      {{ text.DOB }}
    </th>
    <td
      mat-cell
      *matCellDef="let item"
      class="patient-dob-column"
      title="{{ item.patientdateofbirth }}"
    >
      {{ item.patientdateofbirth | shortDate }}
    </td>
  </ng-container>
  <ng-container matColumnDef="host">
    <th mat-header-cell *matHeaderCellDef class="host-header">
      {{ text.HostResource }}
    </th>
    <td
      mat-cell
      *matCellDef="let item"
      class="host-column"
      title="{{ item.host }}"
    >
      {{ item.host }}
    </td>
  </ng-container>
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef class="description-header">
      {{ text.Description }}
    </th>
    <td
      mat-cell
      *matCellDef="let item"
      class="description-column"
      title="{{ item.description }}"
    >
      {{ item.description }}
    </td>
  </ng-container>
  <ng-container matColumnDef="when">
    <th mat-header-cell *matHeaderCellDef class="when-header">
      {{ text.When }}
    </th>
    <td mat-cell *matCellDef="let item">
      {{ item.scheduleddate | shortDateWithTime }}
    </td>
  </ng-container>
  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef class="duration-header">
      {{ text.Duration }}
    </th>
    <td mat-cell *matCellDef="let item">{{ item.duration }}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>{{ text.Status }}</th>
    <td mat-cell *matCellDef="let item">
      {{ item.status | statusToText: text }}
    </td>
  </ng-container>
  <ng-container matColumnDef="join-button">
    <th mat-header-cell *matHeaderCellDef class="join-header cell-narrow"></th>
    <td mat-cell class="cell-narrow" *matCellDef="let item">
      <button
        mat-icon-button
        class="join"
        (click)="joinVideoCall.emit(item)"
        [disabled]="item | joinDisabled"
        title="{{ text.Join }}"
      >
        <mat-icon>call</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="more-button">
    <th mat-header-cell *matHeaderCellDef class="more-header cell-narrow"></th>
    <td mat-cell class="cell-narrow" *matCellDef="let item">
      <button
        *ngIf="!item.iseditable"
        mat-icon-button
        class="edit"
        disabled
        title="{{ text.Locked }}"
      >
        <mat-icon>lock</mat-icon>
      </button>
      <button
        *ngIf="item.iseditable"
        mat-icon-button
        class="more-button"
        title="More"
        [matMenuTriggerFor]="moreMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu class="menu-panel" #moreMenu="matMenu">
        <button
          [disabled]="item | editDisabled"
          mat-menu-item
          (click)="editVideoCall.emit(item)"
        >
          {{ text.Edit }}
        </button>
        <button
          *ngIf="
            partnerInviteEnabled &&
            !currentStateIsPast &&
            !item.ispartnerinvited
          "
          mat-menu-item
          (click)="invitePartner.emit(item)"
        >
          {{ invitePartnerText.InviteButton }}
        </button>
        <button
          *ngIf="
            (item | unInviteEnabled) &&
            partnerInviteEnabled &&
            !currentStateIsPast &&
            item.ispartnerinvited
          "
          mat-menu-item
          (click)="unInvitePartner.emit(item)"
        >
          {{ invitePartnerText.UnInviteButton }}
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <ng-container matColumnDef="invite">
    <th mat-header-cell *matHeaderCellDef class="more-header cell-narrow"></th>
    <td mat-cell *matCellDef="let item">
      <div
        *ngIf="!item.iseditable && !currentStateIsPast && partnerInviteEnabled"
      >
        <button
          *ngIf="!item.iseditable"
          mat-icon-button
          class="more-button"
          title="More"
          [matMenuTriggerFor]="moreMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu class="menu-panel" #moreMenu="matMenu">
          <button
            *ngIf="!item.ispartnerinvited"
            mat-menu-item
            (click)="invitePartner.emit(item)"
          >
            {{ invitePartnerText.InviteButton }}
          </button>
          <button
            *ngIf="(item | unInviteEnabled) && item.ispartnerinvited"
            mat-menu-item
            (click)="unInvitePartner.emit(item)"
          >
            {{ invitePartnerText.UnInviteButton }}
          </button>
        </mat-menu>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    class="video-calls-list-table-row"
    mat-row
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table>
<div
  intersection-observer
  [observeFirstChildElement]="false"
  observeMargin="200px"
  (observed)="onObserved()"
></div>
