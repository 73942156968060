import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { ClinicActionTypes, LoadClinicLanguagesError, LoadClinicLanguagesSuccess } from '../actions/clinic.actions';
import { ClinicService } from '../services/clinic.service';
var ClinicEffects = /** @class */ (function () {
    function ClinicEffects(actions$, clinicService, error, _localisationService) {
        var _this = this;
        this.actions$ = actions$;
        this.clinicService = clinicService;
        this.error = error;
        this._localisationService = _localisationService;
        this.loadLanguages$ = this.actions$.pipe(ofType(ClinicActionTypes.LoadClinicLanguages), switchMap(function () {
            return _this.clinicService.getLanguages().pipe(map(function (languages) {
                return new LoadClinicLanguagesSuccess(languages);
            }), catchError(function (error) {
                return of(new LoadClinicLanguagesError(error));
            }));
        }));
        this.setTimeZone$ = this.actions$.pipe(ofType(ClinicActionTypes.LoadClinicInfoSuccess), switchMap(function (action) {
            _this._localisationService.setTimeZone(action.payload.DefaultTimezone);
            return of();
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClinicEffects.prototype, "loadLanguages$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], ClinicEffects.prototype, "setTimeZone$", void 0);
    return ClinicEffects;
}());
export { ClinicEffects };
