import React, { memo, useEffect, useRef } from 'react';
import { useZoom } from '../../hooks/useZoom';
import { VideoQuality } from '@zoom/videosdk';
import { SelfMicIconContainer, SelfVideoFrame, SelfVideoIconsContainer, SelfVideoIconContainer, SelfMicOffIcon, SelfVideocamOffIcon } from './SelfPanel.styled';
var selfVideoPlayerStyle = {
    width: 256,
    aspectRatio: '16/9',
    top: 20,
    right: 20,
    borderRadius: 4
};
var SelfPanel = function (_a) {
    var micOn = _a.micOn, cameraOn = _a.cameraOn, participant = _a.participant;
    var zoomClient = useZoom().zoomClient;
    var videoAttachedRef = useRef(false);
    var videoPlayerRef = useRef(null);
    useEffect(function () {
        if (!zoomClient) {
            return;
        }
        var mediaStream = zoomClient.getMediaStream();
        if (!mediaStream || !videoPlayerRef.current) {
            return;
        }
        if (!videoAttachedRef.current && participant.bVideoOn) {
            videoAttachedRef.current = true;
            mediaStream.attachVideo(participant.userId, VideoQuality.Video_360P, videoPlayerRef.current);
            return;
        }
        if (videoAttachedRef.current && !participant.bVideoOn) {
            videoAttachedRef.current = false;
            mediaStream.detachVideo(participant.userId, videoPlayerRef.current);
            return;
        }
    }, [zoomClient, participant, videoAttachedRef]);
    return (React.createElement(React.Fragment, null,
        React.createElement("video-player", { style: selfVideoPlayerStyle, ref: videoPlayerRef }),
        React.createElement(SelfVideoFrame, null),
        React.createElement(SelfVideoIconsContainer, null,
            !micOn && (React.createElement(SelfMicIconContainer, { cameraOn: cameraOn },
                React.createElement(SelfMicOffIcon, null))),
            !cameraOn && (React.createElement(SelfVideoIconContainer, null,
                React.createElement(SelfVideocamOffIcon, null))))));
};
var ɵ0 = SelfPanel;
export default memo(SelfPanel);
export { ɵ0 };
