var DeleteMessageItemRequest = /** @class */ (function () {
    function DeleteMessageItemRequest(_a) {
        var PublicToken = _a.PublicToken, MessageId = _a.MessageId, DeleteMessageItemId = _a.DeleteMessageItemId;
        this.Action = 'DeleteClinicMessageItem';
        this.PublicToken = PublicToken;
        this.MessageId = MessageId;
        this.DeleteMessageItemId = DeleteMessageItemId;
    }
    return DeleteMessageItemRequest;
}());
export { DeleteMessageItemRequest };
