import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'portal-button',
  template: `
    <button
      mat-raised-button
      type="type"
      (click)="clicked.emit()"
      [class]="btnStyle"
      [disabled]="disabled"
    >
      {{ text }} Hello
    </button>
  `,
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() btnStyle = 'portal-btn';
  @Input() text = 'button';
  @Input() disabled = false;
  @Output() clicked = new EventEmitter();
}
