<div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div class="add-filter-button">
      <button
        (click)="onFilterToggle($event)"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        mat-raised-button
        class="portal-btn btn-primary"
      >
        + {{ filterButtonText }}
      </button>
    </div>
    <div
      class="filter-list"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <mat-chip-list #filters>
        <mat-chip
          class="applied-filter"
          [removable]="true"
          *ngFor="let filter of appliedFilters"
          (removed)="onFilterRemove(filter)"
        >
          {{ filter.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <div class="filter-container" fxLayout="column">
      <mat-tab-group>
        <mat-tab [label]="headerText"></mat-tab>
      </mat-tab-group>
      <div class="filter-search-input-container">
        <div class="filter-search-input-header" fxLayout="row">
          <span matPrefix class="filter-form-icon"
            ><mat-icon>search</mat-icon></span
          >
          <mat-form-field class="filter-form-field" fxFlex>
            <input
              class="filter-form-field-input"
              matInput
              type="text"
              autocomplete="none"
              id="filter"
              placeholder="{{ searchPlaceholderText }}"
              (input)="onFilterSearch($event)"
              [value]="searchTerm"
            />
          </mat-form-field>
        </div>
        <div>
          <div
            *ngIf="filteredItems.length > 0; else noResults"
            class="filter-list-container"
          >
            <div class="filter-list">
              <mat-selection-list
                (selectionChange)="onFilterSelected($event)"
                #list
              >
                <mat-list-option
                  [selected]="isSelected(item)"
                  [value]="item"
                  *ngFor="let item of filteredItems"
                  class="item"
                >
                  <p class="filter-result-label">{{ item.name }}</p>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
          <ng-template #noResults
            ><p class="no-results">{{ noResultsText }}</p></ng-template
          >
          <div
            class="filter-actions"
            fxLayout="row"
            fxLayoutAlign="space-between none"
          >
            <button
              (click)="isOpen = false"
              mat-raised-button
              class="portal-btn btn-cancel"
              (click)="onFilterCancel($event)"
            >
              {{ cancelButtonText }}
            </button>
            <button
              (click)="onFilterApply($event)"
              mat-raised-button
              class="portal-btn btn-primary"
            >
              {{ applyButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
