import * as tslib_1 from "tslib";
import { sortByKeyAlphabeticallyLowercase } from 'src/app/shared/utils';
import { filterAndSortCategories } from '../../shared/utils';
import { ContentAssignmentActionTypes, } from '../actions/content-assignment.actions';
import { PackAssignmentActionTypes, } from '../actions/pack-assignment.actions';
export var ContentTabsOption;
(function (ContentTabsOption) {
    ContentTabsOption["PACKS"] = "packs";
    ContentTabsOption["FOLDERS"] = "folders";
})(ContentTabsOption || (ContentTabsOption = {}));
export var ClinicViewAccordionOption;
(function (ClinicViewAccordionOption) {
    ClinicViewAccordionOption["PACKS"] = "PACKS";
    ClinicViewAccordionOption["ITEMS"] = "ITEMS";
})(ClinicViewAccordionOption || (ClinicViewAccordionOption = {}));
export var initialState = {
    currentPatientId: null,
    contentTab: 'folders',
    activeContentType: null,
    patientOnlyContentTypeId: null,
    patientOnlyAssignedContent: [],
    viewAsPatient: false,
    viewAsPatientOpenFolder: null,
    patientViewOfContent: [],
    clinicViewAccordion: ClinicViewAccordionOption.PACKS,
    allPatientAssignedContent: {
        assignedContent: [],
        assignedPacks: [],
    },
    contentEntries: {},
    contentTypes: [],
    contentSelect: {
        filterActive: false,
        filterString: '',
    },
    assignedContent: {
        filterActive: false,
        filterString: '',
    },
    error: {
        patientView: false,
        allPatientAssigned: false,
    },
    loading: {
        patientView: false,
        allPatientAssigned: false,
        contentPacks: false,
        packDetail: false,
        contentEntries: false,
        contentTypes: false,
        userAssignedContent: false,
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ContentAssignmentActionTypes.ChangeContentTab: {
            return tslib_1.__assign({}, state, { contentTab: action.payload });
        }
        case ContentAssignmentActionTypes.OpenPatientViewFolder: {
            return tslib_1.__assign({}, state, { viewAsPatientOpenFolder: state.viewAsPatientOpenFolder === action.payload
                    ? null
                    : action.payload });
        }
        // get types success
        case ContentAssignmentActionTypes.GetContentTypesSuccess: {
            var activeContentTypeId = action.payload.length > 0 ? action.payload[0].id : 0;
            var patientOnlyContentType = action.payload.find(function (t) { return t.patientonly; });
            var patientOnlyContentTypeId = void 0;
            if (patientOnlyContentType) {
                patientOnlyContentTypeId = patientOnlyContentType.id;
            }
            return tslib_1.__assign({}, state, { patientOnlyContentTypeId: patientOnlyContentTypeId, activeContentType: state.activeContentType === null
                    ? activeContentTypeId
                    : state.activeContentType, contentTypes: action.payload.filter(function (t) { return !t.patientonly; }) });
        }
        // get entries success
        case ContentAssignmentActionTypes.GetContentEntriesSuccess: {
            return tslib_1.__assign({}, state, { contentEntries: action.payload });
        }
        // create userassignedcontentsuccess
        case ContentAssignmentActionTypes.CreatePatientAssignedContentSuccess: {
            var assignedContent = state.allPatientAssignedContent.assignedContent.concat([
                action.payload,
            ]).sort(sortByKeyAlphabeticallyLowercase('contentName'));
            return tslib_1.__assign({}, state, { allPatientAssignedContent: tslib_1.__assign({}, state.allPatientAssignedContent, { assignedContent: assignedContent }) });
        }
        // delete userassignedcontentsuccess
        case ContentAssignmentActionTypes.DeletePatientAssignedContentSuccess: {
            var contententryheaderid_1 = action.payload.contententryheaderid;
            return tslib_1.__assign({}, state, { allPatientAssignedContent: tslib_1.__assign({}, state.allPatientAssignedContent, { assignedContent: state.allPatientAssignedContent.assignedContent.filter(function (c) { return c.contentEntryHeaderId !== contententryheaderid_1; }) }) });
        }
        // get patient only content success
        case ContentAssignmentActionTypes.GetPatientOnlyAssignmentsSuccess: {
            return tslib_1.__assign({}, state, { patientOnlyAssignedContent: action.payload });
        }
        // create patient only content success
        case ContentAssignmentActionTypes.CreatePatientOnlyAssignmentsSuccess: {
            return tslib_1.__assign({}, state, { patientOnlyAssignedContent: state.patientOnlyAssignedContent.concat([
                    action.payload,
                ]) });
        }
        // delete patient only content success
        case ContentAssignmentActionTypes.DeletePatientOnlyAssignmentsSuccess: {
            return tslib_1.__assign({}, state, { patientOnlyAssignedContent: state.patientOnlyAssignedContent.filter(function (c) { return c.assignmentid !== action.payload.assignmentid; }) });
        }
        // update patient only content success
        case ContentAssignmentActionTypes.UpdatePatientOnlyAssignmentsSuccess: {
            var updatedContent = state.patientOnlyAssignedContent.map(function (c) {
                return c.assignmentid === action.payload.assignmentid ? action.payload : c;
            });
            return tslib_1.__assign({}, state, { patientOnlyAssignedContent: updatedContent });
        }
        // set active content type
        case ContentAssignmentActionTypes.SetActiveContentType: {
            return tslib_1.__assign({}, state, { activeContentType: action.payload });
        }
        // set content select filter
        case ContentAssignmentActionTypes.SetContentSelectFilter: {
            var str = action.payload;
            return tslib_1.__assign({}, state, { contentSelect: str === ''
                    ? {
                        filterActive: false,
                        filterString: '',
                    }
                    : {
                        filterActive: true,
                        filterString: str,
                    } });
        }
        // set assigned content filter
        case ContentAssignmentActionTypes.SetAssignedContentFilter: {
            var str = action.payload;
            return tslib_1.__assign({}, state, { assignedContent: str === ''
                    ? {
                        filterActive: false,
                        filterString: '',
                    }
                    : {
                        filterActive: true,
                        filterString: str,
                    } });
        }
        // Set Content Type to Patient Only type
        case ContentAssignmentActionTypes.SetContentTypeToPatientDocs: {
            return tslib_1.__assign({}, state, { activeContentType: state.patientOnlyContentTypeId });
        }
        // Set Content Type to Non Patient Only type
        case ContentAssignmentActionTypes.SetContentTypeToNonPatientDocs: {
            var activeContentType = 0;
            if (state.contentTypes.length > 0) {
                activeContentType = state.contentTypes[0].id;
            }
            return tslib_1.__assign({}, state, { activeContentType: activeContentType });
        }
        case ContentAssignmentActionTypes.GetAllPatientAssignedContent: {
            return tslib_1.__assign({}, state, { error: tslib_1.__assign({}, state.error, { allPatientAssigned: false }), loading: tslib_1.__assign({}, state.loading, { allPatientAssigned: true }) });
        }
        case ContentAssignmentActionTypes.GetAllPatientAssignedContentSuccess: {
            var _a = action.payload, assignedPacks = _a.assignedPacks, assignedContent = _a.assignedContent;
            return tslib_1.__assign({}, state, { allPatientAssignedContent: {
                    assignedPacks: assignedPacks,
                    assignedContent: assignedContent.sort(sortByKeyAlphabeticallyLowercase('contentName')),
                }, loading: tslib_1.__assign({}, state.loading, { allPatientAssigned: false }) });
        }
        case ContentAssignmentActionTypes.GetAllPatientAssignedContentError: {
            return tslib_1.__assign({}, state, { error: tslib_1.__assign({}, state.error, { allPatientAssigned: true }), loading: tslib_1.__assign({}, state.loading, { allPatientAssigned: false }) });
        }
        case ContentAssignmentActionTypes.GetPatientViewOfContent: {
            return tslib_1.__assign({}, state, { error: tslib_1.__assign({}, state.error, { patientView: false }), loading: tslib_1.__assign({}, state.loading, { patientView: true }) });
        }
        case ContentAssignmentActionTypes.GetPatientViewOfContentSuccess: {
            return tslib_1.__assign({}, state, { patientViewOfContent: action.payload.patientContent, loading: tslib_1.__assign({}, state.loading, { patientView: false }) });
        }
        case ContentAssignmentActionTypes.GetPatientViewOfContentError: {
            return tslib_1.__assign({}, state, { error: tslib_1.__assign({}, state.error, { patientView: true }), loading: tslib_1.__assign({}, state.loading, { patientView: false }) });
        }
        case ContentAssignmentActionTypes.ChangeAssignedContentView: {
            return tslib_1.__assign({}, state, { viewAsPatient: !state.viewAsPatient });
        }
        case ContentAssignmentActionTypes.OpenItemList: {
            return tslib_1.__assign({}, state, { clinicViewAccordion: ClinicViewAccordionOption.ITEMS });
        }
        case ContentAssignmentActionTypes.OpenPackList: {
            return tslib_1.__assign({}, state, { clinicViewAccordion: ClinicViewAccordionOption.PACKS });
        }
        case ContentAssignmentActionTypes.SetPatientId: {
            return tslib_1.__assign({}, state, { currentPatientId: action.payload });
        }
        // Pack assignment Actions
        case PackAssignmentActionTypes.AddExclusionSuccess: {
            var _b = action.payload, packId_1 = _b.packId, exclusions_1 = _b.exclusions;
            var assignedPacks = state.allPatientAssignedContent.assignedPacks.map(function (pack) {
                return +pack.packId === +packId_1
                    ? tslib_1.__assign({}, pack, { contents: pack.contents.filter(function (c) {
                            return !exclusions_1.includes(c.hiddenId || c.contentEntryHeaderId);
                        }) }) : pack;
            });
            return tslib_1.__assign({}, state, { allPatientAssignedContent: tslib_1.__assign({}, state.allPatientAssignedContent, { assignedPacks: assignedPacks }) });
        }
        case PackAssignmentActionTypes.AssignPackSuccess: {
            var _c = action.payload, packId = _c.packId, packName = _c.packName, isDefault = _c.isDefault, packDetail = _c.packDetail, exclusions_2 = _c.exclusions;
            var newPack = {
                packId: packId,
                packName: packName,
                isDefault: isDefault,
                contents: packDetail.filter(function (d) { return !exclusions_2.includes(d.contentEntryHeaderId); }),
            };
            return tslib_1.__assign({}, state, { allPatientAssignedContent: tslib_1.__assign({}, state.allPatientAssignedContent, { assignedPacks: state.allPatientAssignedContent.assignedPacks
                        .concat([newPack])
                        .sort(sortByKeyAlphabeticallyLowercase('packName')) }) });
        }
        case PackAssignmentActionTypes.UnassignPackSuccess: {
            var _d = action.payload.deletedAssignment, patientId = _d.patientId, contentPackHeaderId_1 = _d.contentPackHeaderId;
            if (+state.currentPatientId === +patientId) {
                var assignedPacks = state.allPatientAssignedContent.assignedPacks.filter(function (pack) { return +pack.packId !== +contentPackHeaderId_1; });
                return tslib_1.__assign({}, state, { allPatientAssignedContent: tslib_1.__assign({}, state.allPatientAssignedContent, { assignedPacks: assignedPacks }) });
            }
            else {
                return state;
            }
        }
        case PackAssignmentActionTypes.RemoveExclusionSuccess: {
            var _e = action.payload, packId_2 = _e.packId, packDetail = _e.packDetail, remainingExclusions_1 = _e.remainingExclusions;
            var detailToInsert_1 = packDetail.filter(function (p) { return !remainingExclusions_1.includes(p.contentEntryHeaderId); });
            var assignedPacks = state.allPatientAssignedContent.assignedPacks.map(function (p) {
                if (+p.packId === +packId_2) {
                    var contents = p.contents
                        .concat(detailToInsert_1)
                        .sort(sortByKeyAlphabeticallyLowercase('contentName'));
                    return tslib_1.__assign({}, p, { contents: contents });
                }
                else {
                    return p;
                }
            });
            return tslib_1.__assign({}, state, { allPatientAssignedContent: tslib_1.__assign({}, state.allPatientAssignedContent, { assignedPacks: assignedPacks }) });
        }
        // Default
        default: {
            return state;
        }
    }
}
// Selectors
export var getActiveContentType = function (state) { return state.activeContentType; };
export var getContentEntries = function (state) { return state.contentEntries; };
export var getContentTypes = function (state) {
    return filterAndSortCategories(state.contentTypes);
};
export var getPatientOnlyContentTypeId = function (state) {
    return state.patientOnlyContentTypeId;
};
export var getContentSelect = function (state) { return state.contentSelect; };
export var getAssignedContent = function (state) { return state.assignedContent; };
export var getLoading = function (state) { return state.loading; };
export var getPatientOnlyContent = function (state) {
    return state.patientOnlyAssignedContent;
};
export var getContentTab = function (state) { return state.contentTab; };
export var viewAsPatient = function (state) { return state.viewAsPatient; };
export var getPatientViewOfContent = function (state) {
    return state.patientViewOfContent;
};
export var viewAsPatientOpenFolder = function (state) {
    return state.viewAsPatientOpenFolder;
};
export var getPatientAssignedContent = function (state) {
    return state.allPatientAssignedContent.assignedContent;
};
export var getPatientAssignedPacks = function (state) {
    return state.allPatientAssignedContent.assignedPacks;
};
export var loadingPatientViewOfContent = function (state) {
    return state.loading.patientView;
};
export var loadingPatientAssigned = function (state) {
    return state.loading.allPatientAssigned;
};
export var isClinicViewingAssignedPacks = function (state) {
    return state.clinicViewAccordion === ClinicViewAccordionOption.PACKS;
};
export var isClinicViewingAssignedItems = function (state) {
    return state.clinicViewAccordion === ClinicViewAccordionOption.ITEMS;
};
