import React, { CSSProperties, FC, memo, useEffect, useRef } from 'react';
import { Participant, VideoPlayer, VideoQuality } from '@zoom/videosdk';
import { useZoom } from '../../hooks/useZoom';
import {
  ParticipantDisplayNameText,
  ParticipantMicIconContainer,
  ParticipantMicOffIcon,
  ParticipantVideocamOffIcon,
  ParticipantVideoContainer,
  ParticipantVideoIconContainer,
  ParticipantVideoIconsContainer
} from './ParticipantPanel.styled';

const participantVideoPlayerStyle: CSSProperties = {
  position: 'absolute',
  display: 'block',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
};

const ParticipantPanel: FC<{ participant: Participant }> = ({
  participant
}) => {
  const { zoomClient } = useZoom();
  const videoAttachedRef = useRef(false);
  const videoPlayerRef = useRef<VideoPlayer>(null);
  useEffect(() => {
    if (!zoomClient) {
      return;
    }
    const mediaStream = zoomClient.getMediaStream();
    if (!mediaStream || !videoPlayerRef.current) {
      return;
    }
    if (!videoAttachedRef.current && participant.bVideoOn) {
      videoAttachedRef.current = true;
      mediaStream.attachVideo(
        participant.userId,
        VideoQuality.Video_360P,
        videoPlayerRef.current
      );
      return;
    }
    if (videoAttachedRef.current && !participant.bVideoOn) {
      videoAttachedRef.current = false;
      mediaStream.detachVideo(participant.userId, videoPlayerRef.current);
      return;
    }
  }, [zoomClient, participant, videoAttachedRef]);

  return (
    <ParticipantVideoContainer key={participant.userId}>
      <video-player style={participantVideoPlayerStyle} ref={videoPlayerRef} />
      <ParticipantVideoIconsContainer>
        {participant.muted && (
          <ParticipantMicIconContainer>
            <ParticipantMicOffIcon />
          </ParticipantMicIconContainer>
        )}
        {!participant.bVideoOn && (
          <ParticipantVideoIconContainer>
            <ParticipantVideocamOffIcon />
          </ParticipantVideoIconContainer>
        )}
        <ParticipantDisplayNameText>
          {participant.displayName}
        </ParticipantDisplayNameText>
      </ParticipantVideoIconsContainer>
    </ParticipantVideoContainer>
  );
};

export default memo(ParticipantPanel);
