/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./video-preview.component";
import * as i4 from "@angular/platform-browser";
var styles_VideoPreviewComponent = [i0.styles];
var RenderType_VideoPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoPreviewComponent, data: {} });
export { RenderType_VideoPreviewComponent as RenderType_VideoPreviewComponent };
function View_VideoPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "video-preview"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoPreview; _ck(_v, 0, 0, currVal_0); }); }
function View_VideoPreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "video-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "video", [["controls", ""], ["preload", "metadata"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.videoSrc, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_VideoPreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoPreviewComponent_3)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videoSrc; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VideoPreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoPreviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["video", 2]], null, 0, null, View_VideoPreviewComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.videoSrc == null); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_VideoPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "portal-video-preview", [], null, null, null, View_VideoPreviewComponent_0, RenderType_VideoPreviewComponent)), i1.ɵdid(1, 573440, null, 0, i3.VideoPreviewComponent, [i4.DomSanitizer], null, null)], null, null); }
var VideoPreviewComponentNgFactory = i1.ɵccf("portal-video-preview", i3.VideoPreviewComponent, View_VideoPreviewComponent_Host_0, { html: "html" }, {}, []);
export { VideoPreviewComponentNgFactory as VideoPreviewComponentNgFactory };
