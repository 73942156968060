import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import {
  LoadActionMedicationsError,
  LoadNoActionMedicationsError,
  LoadPotentialDuplicatesError,
} from '../actions/medications.actions';
import {
  IActionMedication,
  IMedication,
  IMedicationResponse,
} from './medications.service.interfaces';

@Injectable()
export class MedicationsService {
  private publicKey$: Observable<string>;

  constructor(
    private _httpService: HttpService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler,
  ) {
    this.publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
  }

  public getActionRequiredMedications(
    searchTerm: string,
  ): Promise<Array<IActionMedication>> {
    return new Promise<Array<IActionMedication>>((resolve, reject): void => {
      const params = this._getParams(searchTerm);
      this._httpService
        .get<IMedicationResponse<IActionMedication>>(
          EndpointType.Portal,
          'medications/action-required',
          params,
        )
        .subscribe(
          (response: IMedicationResponse<IActionMedication>): void => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
            this._store.dispatch(new LoadActionMedicationsError(error));
          },
        );
    });
  }

  public getNoActionRequiredMedications(
    searchTerm: string,
  ): Promise<Array<IActionMedication>> {
    return new Promise<Array<IActionMedication>>((resolve, reject): void => {
      const params = this._getParams(searchTerm);
      this._httpService
        .get<IMedicationResponse<IActionMedication>>(
          EndpointType.Portal,
          'medications/no-action-required',
          params,
        )
        .subscribe(
          (response: IMedicationResponse<IActionMedication>): void => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
            this._store.dispatch(new LoadNoActionMedicationsError(error));
          },
        );
    });
  }

  public getPotentialDuplicateMedications(
    searchTerm: string,
  ): Promise<Array<IMedication>> {
    return new Promise<Array<IMedication>>((resolve, reject): void => {
      const params = this._getParams(searchTerm);
      this._httpService
        .get<IMedicationResponse<IActionMedication>>(
          EndpointType.Portal,
          'medications/potential-duplicates',
          params,
        )
        .subscribe(
          (response: IMedicationResponse<IMedication>): void => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
            this._store.dispatch(new LoadPotentialDuplicatesError(error));
          },
        );
    });
  }

  private _getParams(searchTerm: string): any {
    if (searchTerm === undefined || searchTerm === '') {
      return {};
    }

    return {
      q: searchTerm,
    };
  }
}
