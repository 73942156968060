import { IResponse } from '.';

export enum CognitoStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export interface StaffApiValue {
  id: number;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  profilephotouri: string | null;
  status: CognitoStatus;
  latestlogin: Date | null;
  salvecreated: Date | null;
}

export interface StaffClinic {
  id: number;
  token: string;
  name: string;
  subdomain: string;
}

export type StaffWithClinics = StaffApiValue & {
  clinics?: StaffClinic[];
};

export type StaffListResponse = IResponse<StaffApiValue[]>;

export type StaffResponse = IResponse<StaffWithClinics>;

export type StaffExportResponse = IResponse<string>;

export interface StaffProfile extends StaffApiValue {
  hasAWSPhoto: boolean;
}

export type StaffProfiles = Record<StaffApiValue['id'], StaffProfile>;

export interface IClinicUserFilters {
  status?: Array<keyof typeof CognitoStatus>;
}
