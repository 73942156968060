import { ErrorHandler, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import { ClinicStatusResponse } from '../models/responses/clinic-status.response';

@Injectable()
export class ClinicStatusService {
  private _clinicToken$: Observable<string>;

  constructor(
    private _httpService: HttpService,
    private _store: Store<fromRoot.State>,
    private _error: ErrorHandler
  ) {
    this._clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
  }

  public getClinicStatus(): Observable<ClinicStatusResponse> {
    return this._clinicToken$.pipe(
      switchMap((clinicToken) => {
        return this._httpService.performStatusRequest(clinicToken);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
