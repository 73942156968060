import styled from '@emotion/styled';

export const HelperText = styled.span<{
  error?: boolean;
}>`
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.text.primary};
  font-size: 0.75rem;
  margin-top: 2px;
`;
