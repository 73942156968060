export var LinkContentActionTypes;
(function (LinkContentActionTypes) {
    // Server requests
    LinkContentActionTypes["GetAvailableToLinkContent"] = "[Link-Content] Get Content Available To Link";
    LinkContentActionTypes["GetAvailableToLinkContentSuccess"] = "[Link-Content] Get Content Available To Link Success";
    LinkContentActionTypes["GetAvailableToLinkContentError"] = "[Link-Content] Get Content Available To Link Error";
    LinkContentActionTypes["LinkContent"] = "[Link-Content] Link Content";
    LinkContentActionTypes["LinkContentSuccess"] = "[Link-Content] Link Content Success";
    LinkContentActionTypes["LinkContentError"] = "[Link-Content] Link Content Error";
    LinkContentActionTypes["DeleteContentLink"] = "[Link-Content] Delete Content Link";
    LinkContentActionTypes["DeleteContentLinkSuccess"] = "[Link-Content] Delete Content Link Success";
    LinkContentActionTypes["DeleteContentLinkError"] = "[Link-Content] Delete Content Link Error";
    LinkContentActionTypes["DeleteSomeContentLinks"] = "[Link-Content] Delete Some Content Links";
    LinkContentActionTypes["DeleteSomeContentLinksSuccess"] = "[Link-Content] Delete Some Content Links Success";
    LinkContentActionTypes["DeleteSomeContentLinksError"] = "[Link-Content] Delete Some Content Links Error";
    LinkContentActionTypes["DeleteAllContentLinks"] = "[Link-Content] Delete All Content Links";
    LinkContentActionTypes["DeleteAllContentLinksSuccess"] = "[Link-Content] Delete All Content Links Success";
    LinkContentActionTypes["DeleteAllContentLinksError"] = "[Link-Content] Delete All Content Links Error";
    LinkContentActionTypes["LoadChildren"] = "[Link-Content] Load Children of Parent";
    LinkContentActionTypes["LoadChildrenSuccess"] = "[Link-Content] Load Children of Parent Success";
    LinkContentActionTypes["LoadChildrenError"] = "[Link-Content] Load Children of Parent Error";
    // UI Only
    LinkContentActionTypes["ClearChildren"] = "[Link-Content] Clear Children";
    LinkContentActionTypes["ClearErrors"] = "[Link-Content] Clear Errors";
    LinkContentActionTypes["FilterTargetLink"] = "[Link-Content] Filter Content To Target";
    LinkContentActionTypes["SelectedContentForLinking"] = "[Link-Content] Selected Content For Linking";
})(LinkContentActionTypes || (LinkContentActionTypes = {}));
var FilterTargetLink = /** @class */ (function () {
    function FilterTargetLink(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.FilterTargetLink;
    }
    return FilterTargetLink;
}());
export { FilterTargetLink };
var ClearErrors = /** @class */ (function () {
    function ClearErrors() {
        this.type = LinkContentActionTypes.ClearErrors;
    }
    return ClearErrors;
}());
export { ClearErrors };
var GetAvailableToLinkContent = /** @class */ (function () {
    function GetAvailableToLinkContent(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.GetAvailableToLinkContent;
    }
    return GetAvailableToLinkContent;
}());
export { GetAvailableToLinkContent };
var GetAvailableToLinkContentSuccess = /** @class */ (function () {
    function GetAvailableToLinkContentSuccess(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.GetAvailableToLinkContentSuccess;
    }
    return GetAvailableToLinkContentSuccess;
}());
export { GetAvailableToLinkContentSuccess };
var GetAvailableToLinkContentError = /** @class */ (function () {
    function GetAvailableToLinkContentError(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.GetAvailableToLinkContentError;
    }
    return GetAvailableToLinkContentError;
}());
export { GetAvailableToLinkContentError };
var LinkContent = /** @class */ (function () {
    function LinkContent(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.LinkContent;
    }
    return LinkContent;
}());
export { LinkContent };
var LinkContentSuccess = /** @class */ (function () {
    function LinkContentSuccess(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.LinkContentSuccess;
    }
    return LinkContentSuccess;
}());
export { LinkContentSuccess };
var LinkContentError = /** @class */ (function () {
    function LinkContentError(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.LinkContentError;
    }
    return LinkContentError;
}());
export { LinkContentError };
var LoadChildren = /** @class */ (function () {
    function LoadChildren(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.LoadChildren;
    }
    return LoadChildren;
}());
export { LoadChildren };
var LoadChildrenSuccess = /** @class */ (function () {
    function LoadChildrenSuccess(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.LoadChildrenSuccess;
    }
    return LoadChildrenSuccess;
}());
export { LoadChildrenSuccess };
var LoadChildrenError = /** @class */ (function () {
    function LoadChildrenError(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.LoadChildrenError;
    }
    return LoadChildrenError;
}());
export { LoadChildrenError };
var ClearChildren = /** @class */ (function () {
    function ClearChildren() {
        this.type = LinkContentActionTypes.ClearChildren;
    }
    return ClearChildren;
}());
export { ClearChildren };
var DeleteContentLink = /** @class */ (function () {
    function DeleteContentLink(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteContentLink;
    }
    return DeleteContentLink;
}());
export { DeleteContentLink };
var DeleteContentLinkSuccess = /** @class */ (function () {
    function DeleteContentLinkSuccess(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteContentLinkSuccess;
    }
    return DeleteContentLinkSuccess;
}());
export { DeleteContentLinkSuccess };
var DeleteContentLinkError = /** @class */ (function () {
    function DeleteContentLinkError(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteContentLinkError;
    }
    return DeleteContentLinkError;
}());
export { DeleteContentLinkError };
var DeleteSomeContentLinks = /** @class */ (function () {
    function DeleteSomeContentLinks(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteSomeContentLinks;
    }
    return DeleteSomeContentLinks;
}());
export { DeleteSomeContentLinks };
var DeleteSomeContentLinksSuccess = /** @class */ (function () {
    function DeleteSomeContentLinksSuccess(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteSomeContentLinksSuccess;
    }
    return DeleteSomeContentLinksSuccess;
}());
export { DeleteSomeContentLinksSuccess };
var DeleteSomeContentLinksError = /** @class */ (function () {
    function DeleteSomeContentLinksError(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteSomeContentLinksError;
    }
    return DeleteSomeContentLinksError;
}());
export { DeleteSomeContentLinksError };
var DeleteAllContentLinks = /** @class */ (function () {
    function DeleteAllContentLinks(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteAllContentLinks;
    }
    return DeleteAllContentLinks;
}());
export { DeleteAllContentLinks };
var DeleteAllContentLinksSuccess = /** @class */ (function () {
    function DeleteAllContentLinksSuccess(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteAllContentLinksSuccess;
    }
    return DeleteAllContentLinksSuccess;
}());
export { DeleteAllContentLinksSuccess };
var DeleteAllContentLinksError = /** @class */ (function () {
    function DeleteAllContentLinksError(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.DeleteAllContentLinksError;
    }
    return DeleteAllContentLinksError;
}());
export { DeleteAllContentLinksError };
var SelectedContentForLinking = /** @class */ (function () {
    function SelectedContentForLinking(payload) {
        this.payload = payload;
        this.type = LinkContentActionTypes.SelectedContentForLinking;
    }
    return SelectedContentForLinking;
}());
export { SelectedContentForLinking };
