import * as tslib_1 from "tslib";
export var UPDATE_FORM = '[Content][Forms] Update Form';
var UpdateForm = /** @class */ (function () {
    function UpdateForm(payload) {
        this.payload = payload;
        this.type = UPDATE_FORM;
    }
    return UpdateForm;
}());
export { UpdateForm };
export var initialState = {
    newCategory: {
        category: '',
    },
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    switch (action.type) {
        case UPDATE_FORM: {
            return tslib_1.__assign({}, state, (_a = {}, _a[action.payload.path] = action.payload.value, _a));
        }
        default: {
            return state;
        }
    }
}
export var getForm = function (state, path) { return state[path]; };
