import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromAuth from '../../auth/reducers';
import * as fromMessages from '../../messaging/reducers';
import { ServerError } from '../../models/Error';
import { Thread } from '../../models/Thread';
import { LoadMessageActionTypes, LoadMessagesSuccess, LoadThreadError, LoadThreadSuccess } from '../actions/load-message.actions';
import { MessageService } from '../services/message.service';
import { LoadMessageTypesSuccess, LoadMessagingSummaryError, LoadMessagingSummarySuccess, LoadNewerThreadMessagesSuccess, LoadNewestMessagesError, LoadNewestMessagesSuccess, ShowPushNotification } from './../actions/load-message.actions';
import { MessageUIActionTypes } from './../actions/message-ui.actions';
var LoadMessageEffects = /** @class */ (function () {
    function LoadMessageEffects(_actions$, _messageService, _error, _store) {
        this._actions$ = _actions$;
        this._messageService = _messageService;
        this._error = _error;
        this._store = _store;
    }
    LoadMessageEffects.prototype.refreshMessagingSummary$ = function () {
        var _this = this;
        return this._actions$.pipe(ofType(LoadMessageActionTypes.LoadMessagingSummary), mergeMap(function () {
            return _this._messageService.getMessagingSummary().pipe(map(function (response) {
                return new LoadMessagingSummarySuccess(response);
            }), catchError(function (error) {
                return of(new LoadMessagingSummaryError(error));
            }));
        }));
    };
    LoadMessageEffects.prototype.loadThread$ = function () {
        var _this = this;
        return this._actions$.pipe(ofType(MessageUIActionTypes.OpenThread), switchMap(function (action) {
            return _this._messageService
                .getConversation(action.payload.MessageId)
                .pipe(map(function (messageResult) {
                var thread = new Thread(tslib_1.__assign({}, action.payload, { Read: true, MessageSubject: action.payload.MessageSubject }), messageResult.Messages, messageResult.IsReadOnly);
                return new LoadThreadSuccess(thread);
            }, catchError(function (err) {
                return of(new LoadThreadError(new ServerError('[LoadMessage]', 'Error loading message thread', action.type)));
            })));
        }));
    };
    LoadMessageEffects.prototype.loadMessageTypes$ = function () {
        var _this = this;
        return this._actions$.pipe(ofType(LoadMessageActionTypes.LoadMessageTypes), switchMap(function (action) {
            return _this._messageService.getMessageTypes().pipe(map(function (res) {
                return new LoadMessageTypesSuccess(res);
            }), catchError(function (err) {
                return of(new LoadMessagingSummaryError(new ServerError('[LoadMessageTypes]', 'Error loading message types', action.type)));
            }));
        }));
    };
    LoadMessageEffects.prototype.loadNewerThreadMessages$ = function () {
        var _this = this;
        return this._actions$.pipe(ofType(LoadMessageActionTypes.LoadNewerThreadMessages), switchMap(function (action) {
            return _this._messageService
                .getConversation(action.payload.messageId, action.payload.latestMessageItemId)
                .pipe(map(function (res) {
                return new LoadNewerThreadMessagesSuccess({
                    threadId: action.payload.messageId,
                    messages: res.Messages
                });
            }, catchError(function (err) {
                return of(new LoadThreadError(new ServerError('[LoadMessage]', 'Error loading message thread', action.type)));
            })));
        }));
    };
    LoadMessageEffects.prototype.getPayloadFromResolvedAndMessageTypeId = function (payload) {
        var Resolved = payload.Resolved, MessageTypeId = payload.MessageTypeId;
        switch (payload.MessageTypeId) {
            case -1:
                // All Unresolved
                MessageTypeId = undefined;
                break;
            case -2:
                // All Resolved
                MessageTypeId = undefined;
                Resolved = true;
                break;
            case -3:
                // All Messages
                MessageTypeId = undefined;
                Resolved = null;
                break;
        }
        return Object.assign({}, tslib_1.__assign({}, payload, { MessageTypeId: MessageTypeId,
            Resolved: Resolved }));
    };
    LoadMessageEffects.prototype.loadMessages$ = function () {
        var _this = this;
        return this._actions$.pipe(ofType(LoadMessageActionTypes.LoadMessages), withLatestFrom(this._store.pipe(select(fromMessages.getCurrentTab)), this._store.pipe(select(fromAuth.getMessageStarringEnabled))), switchMap(function (_a) {
            var action = _a[0], tab = _a[1], starringEnabled = _a[2];
            var requestPayload = _this.getPayloadFromResolvedAndMessageTypeId(action.payload);
            return _this._messageService
                .getMessageList(starringEnabled, requestPayload)
                .pipe(map(function (res) {
                if (starringEnabled) {
                    return new LoadMessagesSuccess({
                        messages: res.Conversations,
                        counts: {
                            starred: res.Starred,
                            unstarred: res.Unstarred
                        }
                    });
                }
                return new LoadMessagesSuccess({ messages: res });
            }), catchError(function (err) {
                return of(new LoadMessagingSummaryError(new ServerError('[LoadMessage]', 'Error loading messages', action.type)));
            }));
        }));
    };
    LoadMessageEffects.prototype.loadNewestMessages$ = function () {
        var _this = this;
        return this._actions$.pipe(ofType(LoadMessageActionTypes.LoadNewestMessages), withLatestFrom(this._store.pipe(select(fromMessages.getCurrentTab)), this._store.pipe(select(fromAuth.getMessageStarringEnabled))), switchMap(function (_a) {
            var action = _a[0], tab = _a[1], starringEnabled = _a[2];
            var requestPayload = _this.getPayloadFromResolvedAndMessageTypeId(action.payload.fetchParams);
            return _this._messageService
                .getMessageList(starringEnabled, requestPayload)
                .pipe(mergeMap(function (res) {
                var messages;
                var newPatientMessages;
                // TODO: SA-2906: Once feature approved promote below code.
                if (starringEnabled) {
                    messages = res.Conversations;
                    newPatientMessages = res.Conversations.filter(function (m) { return m.PatientSent; });
                }
                else {
                    messages = res;
                    newPatientMessages = res.filter(function (m) { return m.PatientSent; });
                }
                var pushNotificationActions = action.payload.showPushNotification &&
                    newPatientMessages.length > 0
                    ? [
                        new ShowPushNotification({
                            newMessages: newPatientMessages,
                            showPushNotification: true
                        })
                    ]
                    : [];
                return [
                    new LoadNewestMessagesSuccess({ messages: messages })
                ].concat(pushNotificationActions);
            }), catchError(function (err) {
                return of(new LoadNewestMessagesError(new ServerError('[LoadNewestMessage]', 'Error loading messages', action.type)));
            }));
        }));
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], LoadMessageEffects.prototype, "refreshMessagingSummary$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], LoadMessageEffects.prototype, "loadThread$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], LoadMessageEffects.prototype, "loadMessageTypes$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], LoadMessageEffects.prototype, "loadNewerThreadMessages$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], LoadMessageEffects.prototype, "loadMessages$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], LoadMessageEffects.prototype, "loadNewestMessages$", null);
    return LoadMessageEffects;
}());
export { LoadMessageEffects };
