import * as tslib_1 from "tslib";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MaterialChip from '@material-ui/core/Chip';
var CUSTOM_COLORS = [
    'gray',
    'purple',
    'green',
    'red',
    'yellow',
];
var PRIVATE_PROPERTIES = ['round'].concat(CUSTOM_COLORS);
var isAnyCustomColorSet = function (props) {
    return CUSTOM_COLORS.some(function (color) { return props[color] === true; });
};
var ɵ0 = isAnyCustomColorSet;
var colorStyle = function (_a) {
    var theme = _a.theme, gray = _a.gray, purple = _a.purple, green = _a.green, red = _a.red, yellow = _a.yellow;
    return css(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  color: #4d4d4d;\n  span {\n    font-weight: normal;\n  }\n"], ["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  color: #4d4d4d;\n  span {\n    font-weight: normal;\n  }\n"])), gray ? "background-color: " + theme.colors.grayLight + ";" : '', purple ? "background-color: " + theme.colors.purple + ";" : '', green ? "background-color: " + theme.colors.green + ";" : '', red ? "background-color: " + theme.colors.red + ";" : '', yellow ? "background-color: " + theme.colors.yellowLight + ";" : '');
};
var ɵ1 = colorStyle;
export var Chip = styled(MaterialChip, {
    shouldForwardProp: function (prop) {
        return !(typeof prop === 'string' && PRIVATE_PROPERTIES.includes(prop));
    },
})(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  height: 28px;\n  border-radius: ", ";\n  ", "\n"], ["\n  height: 28px;\n  border-radius: ", ";\n  ", "\n"])), function (_a) {
    var round = _a.round;
    return (round ? '1rem' : '3px');
}, function (props) { return (isAnyCustomColorSet(props) ? colorStyle : ''); });
export { ɵ0, ɵ1 };
var templateObject_1, templateObject_2;
