/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/integration-status/integration-status.component.ngfactory";
import * as i2 from "../../components/integration-status/integration-status.component";
import * as i3 from "@angular/common";
import * as i4 from "./integration-status.container";
import * as i5 from "@ngrx/store";
var styles_IntegrationStatusContainer = [];
var RenderType_IntegrationStatusContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_IntegrationStatusContainer, data: {} });
export { RenderType_IntegrationStatusContainer as RenderType_IntegrationStatusContainer };
export function View_IntegrationStatusContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "portal-integration-status", [], null, null, null, i1.View_IntegrationStatusComponent_0, i1.RenderType_IntegrationStatusComponent)), i0.ɵdid(1, 49152, null, 0, i2.IntegrationStatusComponent, [], { text: [0, "text"], integrated: [1, "integrated"], synchronised: [2, "synchronised"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.text$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.isIntegrated$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.isSynchronised$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_IntegrationStatusContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "portal-integration-container", [], null, null, null, View_IntegrationStatusContainer_0, RenderType_IntegrationStatusContainer)), i0.ɵdid(1, 49152, null, 0, i4.IntegrationStatusContainer, [i5.Store], null, null)], null, null); }
var IntegrationStatusContainerNgFactory = i0.ɵccf("portal-integration-container", i4.IntegrationStatusContainer, View_IntegrationStatusContainer_Host_0, {}, {}, []);
export { IntegrationStatusContainerNgFactory as IntegrationStatusContainerNgFactory };
