import { isNull } from '@angular/compiler/src/output/output_ast';
import { ErrorHandler, Inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  scan,
  shareReplay,
  tap
} from 'rxjs/operators';
import { LocalisationService } from '../localisation/localisation.service';
import { SimplePatient } from '../models/SimplePatient';
import { SortParams } from './interfaces/patient-list.component.interfaces';
import { PatientService } from './services/patient.service';

export interface PagedSearchTrigger {
  pageNumber: number;
  searchText: string;
}

export class PatientSearchBase {
  private _currentPageNumber = 0;
  private _searchTrigger$ = new Subject<PagedSearchTrigger>();
  private _formControl: AbstractControl;
  private _sortParams: SortParams = { active: '', direction: undefined };

  private _hasMoreItems = true;

  private _searchQuery: string;

  public patients: Array<SimplePatient>;

  public patients$: Observable<SimplePatient[]>;
  public isLoading$ = new Subject<boolean>();
  public isSearchMode$ = new Subject<boolean>();

  constructor(
    readonly _patientService: PatientService,
    readonly _localisationService: LocalisationService
  ) {}

  protected setupSearchObservables(errorHandler: ErrorHandler) {
    const getPatients$ = (pageNumber, searchText, sortType, sortDirection) => {
      const result = this._hasMoreItems
        ? this._patientService.getClinicPatients({
            pageNumber,
            query: searchText,
            sortType,
            sortDirection
          })
        : of(new Array<SimplePatient>());
      return result;
    };

    this.patients$ = this._searchTrigger$.pipe(
      mergeMap((searchTrigger) => {
        this.isLoading$.next(true);
        return getPatients$(
          searchTrigger.pageNumber,
          searchTrigger.searchText,
          this._sortParams.active,
          this._sortParams.direction
        );
      }),
      tap((next) => {
        this.isLoading$.next(false);
      }),
      scan((acc, value) => {
        this._hasMoreItems = value.length > 0;
        return this._currentPageNumber > 1 ? [...acc, ...value] : value;
      }),
      catchError((err) => {
        return throwError(err);
      }),
      shareReplay(1)
    );
  }

  protected observeSearchInput(formControl: AbstractControl) {
    this._formControl = formControl;
    this._formControl.valueChanges
      .pipe(
        map((val) => {
          this.isSearchMode$.next(val.length > 0);
        }),
        debounceTime(500)
      )
      .subscribe(() => {
        this._hasMoreItems = true;
        this.pageReset();
        this.triggerNextPage();
      });
  }

  public triggerNextPage() {
    const searchText = this._formControl.value || '';
    if (typeof searchText === 'string' || searchText.toString() === '') {
      this._searchTrigger$.next({
        pageNumber: ++this._currentPageNumber,
        searchText
      });
    }
  }

  private pageReset() {
    this._currentPageNumber = 0;
  }

  // Using arrow function as mat-autocomplete [displayWith] runs bound to MatAutocomplete
  public transformPatientToString = (patient: SimplePatient) => {
    if (patient) {
      return `${this.transformPatientNameIdToString(
        patient
      )} - ${this.transformPatientDOBToString(patient)}`;
    }
  };

  public transformPatientNameIdToString = (patient: SimplePatient) => {
    if (patient) {
      return `${patient.FirstName} ${patient.LastName} - ${patient.PatientIdentifier}`;
    }
  };

  public transformPatientDOBToString = (patient: SimplePatient) => {
    if (patient) {
      return `${this._localisationService.formatUTCDate(
        patient.DateOfBirth,
        'medium'
      )}`;
    }
  };

  public onSortChanged(params: SortParams): void {
    this._sortParams = params;
    this.pageReset();
    this._searchTrigger$.next({
      pageNumber: ++this._currentPageNumber,
      searchText: this._formControl.value || 0
    });
  }
}
