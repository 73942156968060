import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { select, Store, StoreModule } from '@ngrx/store';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { Observable, Subscription } from 'rxjs';
import * as fromAuth from '../auth/reducers';
import { CoreModule } from '../core/core.module';
import { LocalisationModule } from '../localisation/localisation.module';
import { LocalisationService } from '../localisation/localisation.service';
import { MaterialModule } from '../material/material.module';
import * as fromRoot from '../reducers';
import * as fromSettings from '../settings/reducers';
import * as ContentActions from './actions/content.actions';
import * as ContentPackActions from './actions/packs.actions';
import { AddToPackComponent } from './components/add-to-pack/add-to-pack.component';
import { AddVideoComponent } from './components/add-video/add-video.component';
import { AttachmentsTableComponent } from './components/attachments-table/attachments-table.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { ChooseContentComponent } from './components/choose-content/choose-content.component';
import { ChooseGlobalContentComponent } from './components/choose-global-content/choose-global-content.component';
import { ChooseGlobalFileContentComponent } from './components/choose-global-file-content/choose-global-file-content.component';
import { ChooseTemplateComponent } from './components/choose-template/choose-template.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ContentDirectoryComponent } from './components/content-directory/content-directory.component';
import { ContentEditBodyComponent } from './components/content-edit-body/content-edit-body.component';
import { ContentEditControlsComponent } from './components/content-edit-controls/content-edit-controls.component';
import { ContentEditSectionsComponent } from './components/content-edit-sections/content-edit-sections.component';
import { ContentEditTitleComponent } from './components/content-edit-title/content-edit-title.component';
import { ContentListControlsComponent } from './components/content-list-controls/content-list-controls.component';
import { ContentListTableComponent } from './components/content-list-table/content-list-table.component';
import { CreateCategoryComponent } from './components/create-category/create-category.component';
import { CreatePackComponent } from './components/create-pack/create-pack.component';
import { EditFolderNameComponent } from './components/edit-folder-name/edit-folder-name.component';
import { EditPackNameComponent } from './components/edit-pack-name/edit-pack-name.component';
import { LinkContentComponent } from './components/link-content/link-content.component';
import { MoveEntriesComponent } from './components/move-entries/move-entries.component';
import { PackDetailTableComponent } from './components/pack-detail-table/pack-detail-table.component';
import { TemplateEditFormComponent } from './components/template-edit-form/template-edit-form.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';
import { VideoPreviewComponent } from './components/video-preview/video-preview.component';
import { ViewChildrenComponent } from './components/view-children/view-children.component';
import { ContentEditFormComponent } from './containers/content-edit-form/content-edit-form.component';
import { ContentEditComponent } from './containers/content-edit/content-edit.component';
import { ContentListComponent } from './containers/content-list/content-list.component';
import { ContentPackDetailContainer } from './containers/content-pack-detail/content-pack-detail.container';
import { GlobalDashboardComponent } from './containers/global-dashboard/global-dashboard.component';
import { NoContentComponent } from './containers/no-content/no-content.component';
import { RichOrPdfComponent } from './containers/rich-or-pdf/rich-or-pdf.component';
import { TemplateEditComponent } from './containers/template-edit/template-edit.component';
import { TemplateNewComponent } from './containers/template-new/template-new.component';
import { UseTemplateComponent } from './containers/use-template/use-template.component';
import { ViewChildrenContainer } from './containers/view-children/view-children.container';
import { routes } from './content-routing';
import { ConnectFormDirective } from './directives/connect-form.directive';
import { ContentEffects } from './effects/content.effects';
import { LinkContentEffects } from './effects/link-content.effects';
import { PacksEffects } from './effects/packs.effects';
import { reducers } from './reducers';
import { ContentService } from './services/content.service';
import { GetYoutubeIdService } from './services/get-youtube-id.service';
import { LinkContentService } from './services/link-content.service';
import { PacksService } from './services/packs.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
};

const COMPONENTS = [
  ConnectFormDirective,
  ContentListComponent,
  GlobalDashboardComponent,
  TemplateEditComponent,
  TemplateNewComponent,
  NoContentComponent,
  ContentEditComponent,
  ContentDirectoryComponent,
  ContentListControlsComponent,
  ContentListTableComponent,
  TemplateEditFormComponent,
  CategoryListComponent,
  TemplateEditFormComponent,
  CommentsComponent,
  ContentEditBodyComponent,
  ContentEditControlsComponent,
  ContentEditSectionsComponent,
  ContentEditTitleComponent,
  VersionHistoryComponent,
  ContentEditFormComponent,
  RichOrPdfComponent,
  UseTemplateComponent,
  ChooseTemplateComponent,
  ChooseContentComponent,
  ChooseGlobalContentComponent,
  ChooseGlobalFileContentComponent,
  AddVideoComponent,
  VideoPreviewComponent,
  AttachmentsTableComponent,
  MoveEntriesComponent,
  EditFolderNameComponent,
  CreateCategoryComponent,
  LinkContentComponent,
  ViewChildrenContainer,
  ViewChildrenComponent,
  ContentPackDetailContainer,
  PackDetailTableComponent,
  EditPackNameComponent,
  CreatePackComponent,
  AddToPackComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    MatTabsModule,
    PerfectScrollbarModule,
    QuillModule,
    NgxDnDModule,
    LocalisationModule.forRoot(),
    CoreModule.forFeature(),
    StoreModule.forFeature('content', reducers),
    EffectsModule.forFeature([
      ContentEffects,
      LinkContentEffects,
      PacksEffects,
    ]),
    RouterModule.forChild(routes),
  ],
  declarations: COMPONENTS,
  entryComponents: [
    ChooseTemplateComponent,
    ChooseContentComponent,
    ChooseGlobalContentComponent,
    ChooseGlobalFileContentComponent,
    AddVideoComponent,
    MoveEntriesComponent,
    EditFolderNameComponent,
    CreateCategoryComponent,
    LinkContentComponent,
    ViewChildrenContainer,
    EditPackNameComponent,
    CreatePackComponent,
    AddToPackComponent,
  ],
  providers: [
    GetYoutubeIdService,
    ContentService,
    LinkContentService,
    PacksService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentModule {
  public selectedLanguage$: Observable<any>;

  public _subs = new Subscription();

  private _loggedIn$: Observable<any>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _localisationService: LocalisationService,
  ) {
    this.selectedLanguage$ = this._store.pipe(
      select(fromSettings.getCurrentLanguage),
    );

    this._loggedIn$ = this._store.pipe(select(fromAuth.getLoggedIn));

    // Subscribe to language changes
    this._subs.add(
      this.selectedLanguage$.subscribe((language) => {
        this._localisationService.setLocale(language);
      }),
    );

    this._subs.add(
      this._loggedIn$.subscribe((loggedIn) => {
        if (loggedIn) {
          this._store.dispatch(new ContentActions.GetCategories());
          this._store.dispatch(new ContentActions.GetTemplates());
          this._store.dispatch(new ContentActions.GetContentEntries());
          this._store.dispatch(new ContentPackActions.GetContentPacks());
          this._store.dispatch(new ContentActions.GetContentEditorEnabled());
        }
      }),
    );
  }
}
