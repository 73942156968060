export var VideoCallActionTypes;
(function (VideoCallActionTypes) {
    VideoCallActionTypes["UpdateVideoCall"] = "[VideoCalls] Update";
    VideoCallActionTypes["LoadVideoCalls"] = "[VideoCalls] Get All";
    VideoCallActionTypes["ActivateListFilter"] = "[VideoCalls] Activate List Filter";
    VideoCallActionTypes["SetListFilter"] = "[VideoCalls] Set List Filter";
    VideoCallActionTypes["DeactivateListFilter"] = "[VideoCalls] Deactivate List Filter";
    VideoCallActionTypes["SetCurrentTab"] = "[VideoCalls] Set Current Tab";
    VideoCallActionTypes["DeleteVideoCall"] = "[VideoCalls] Delete";
    VideoCallActionTypes["ResetVideoCallEffects"] = "[VideoCalls] Reset";
    VideoCallActionTypes["EffectsCompleted"] = "[VideoCalls] Effects Completed";
    VideoCallActionTypes["UpdateList"] = "[VideoCalls] Update List";
    VideoCallActionTypes["Join"] = "[VideoCalls] Join";
    VideoCallActionTypes["InvitePartnerToVideoCall"] = "[VideoCalls] Invite Partner";
    VideoCallActionTypes["UnInvitePartnerToVideoCall"] = "[VideoCalls] Uninvite Partner";
    VideoCallActionTypes["InvitePartnerInCallToVideoCall"] = "[VideoCalls] Invite Partner In Call";
    VideoCallActionTypes["UnInvitePartnerInCallToVideoCall"] = "[VideoCalls] Uninvite Partner In Call";
    VideoCallActionTypes["InviteInCallSuccess"] = "[VideoCalls] Invite In Call Success";
    VideoCallActionTypes["UnInviteInCallSuccess"] = "[VideoCalls] UnInvite In Call Success";
})(VideoCallActionTypes || (VideoCallActionTypes = {}));
var UpdateVideoCallAction = /** @class */ (function () {
    function UpdateVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.UpdateVideoCall;
    }
    return UpdateVideoCallAction;
}());
export { UpdateVideoCallAction };
var SetListFilterAction = /** @class */ (function () {
    function SetListFilterAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.SetListFilter;
    }
    return SetListFilterAction;
}());
export { SetListFilterAction };
var SetCurrentTabAction = /** @class */ (function () {
    function SetCurrentTabAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.SetCurrentTab;
    }
    return SetCurrentTabAction;
}());
export { SetCurrentTabAction };
var ResetEffectsStateAction = /** @class */ (function () {
    function ResetEffectsStateAction() {
        this.type = VideoCallActionTypes.ResetVideoCallEffects;
    }
    return ResetEffectsStateAction;
}());
export { ResetEffectsStateAction };
var DeleteVideoCallAction = /** @class */ (function () {
    function DeleteVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.DeleteVideoCall;
    }
    return DeleteVideoCallAction;
}());
export { DeleteVideoCallAction };
var InvitePartnerToVideoCallAction = /** @class */ (function () {
    function InvitePartnerToVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.InvitePartnerToVideoCall;
    }
    return InvitePartnerToVideoCallAction;
}());
export { InvitePartnerToVideoCallAction };
var UnInvitePartnerToVideoCallAction = /** @class */ (function () {
    function UnInvitePartnerToVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.UnInvitePartnerToVideoCall;
    }
    return UnInvitePartnerToVideoCallAction;
}());
export { UnInvitePartnerToVideoCallAction };
var InvitePartnerInCallToVideoCallAction = /** @class */ (function () {
    function InvitePartnerInCallToVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.InvitePartnerInCallToVideoCall;
    }
    return InvitePartnerInCallToVideoCallAction;
}());
export { InvitePartnerInCallToVideoCallAction };
var UnInvitePartnerInCallToVideoCallAction = /** @class */ (function () {
    function UnInvitePartnerInCallToVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.UnInvitePartnerInCallToVideoCall;
    }
    return UnInvitePartnerInCallToVideoCallAction;
}());
export { UnInvitePartnerInCallToVideoCallAction };
var EffectsCompletedAction = /** @class */ (function () {
    function EffectsCompletedAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.EffectsCompleted;
    }
    return EffectsCompletedAction;
}());
export { EffectsCompletedAction };
var UpdateListAction = /** @class */ (function () {
    function UpdateListAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.UpdateList;
    }
    return UpdateListAction;
}());
export { UpdateListAction };
var InviteInCallSuccessAction = /** @class */ (function () {
    function InviteInCallSuccessAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.InviteInCallSuccess;
    }
    return InviteInCallSuccessAction;
}());
export { InviteInCallSuccessAction };
var UnInviteInCallSuccessAction = /** @class */ (function () {
    function UnInviteInCallSuccessAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.UnInviteInCallSuccess;
    }
    return UnInviteInCallSuccessAction;
}());
export { UnInviteInCallSuccessAction };
var JoinVideoCallAction = /** @class */ (function () {
    function JoinVideoCallAction(payload) {
        this.payload = payload;
        this.type = VideoCallActionTypes.Join;
    }
    return JoinVideoCallAction;
}());
export { JoinVideoCallAction };
