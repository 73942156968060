import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
  ContentPackActionTypes,
  CreateContentPack,
  CreateContentPackError,
  CreateContentPackSuccess,
} from './../../actions/packs.actions';

import * as fromRoot from '../../../reducers';

import { validateContentPackName } from '../../services/async-validators/validate-pack-name';
import { PacksService } from '../../services/packs.service';

@Component({
  selector: 'portal-create-pack',
  templateUrl: './create-pack.component.html',
  styleUrls: ['./create-pack.component.scss'],
})
export class CreatePackComponent implements OnInit, OnDestroy {
  public checkingPackName$ = new BehaviorSubject(false);

  public savingPack$ = new BehaviorSubject(false);
  public savedPack$ = new BehaviorSubject(false);
  public errorEncountered$ = new BehaviorSubject(false);

  public packName: FormControl;

  public status = {
    INVALID: 'INVALID',
    PENDING: 'PENDING',
  };

  public _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates: Actions,
    public dialogRef: MatDialogRef<CreatePackComponent>,
    public dialog: MatDialog,
    private _packsService: PacksService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.packName = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      [validateContentPackName(this._packsService, this.checkingPackName$, '')],
    );

    // watch for errors on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<CreateContentPackError>(
            ContentPackActionTypes.CreateContentPackError,
          ),
        )
        .subscribe(() => {
          this.savingPack$.next(false);
          this.errorEncountered$.next(true);
        }),
    );

    // watch for success on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<CreateContentPackSuccess>(
            ContentPackActionTypes.CreateContentPackSuccess,
          ),
        )
        .subscribe(() => {
          this.savingPack$.next(false);
          this.savedPack$.next(true);
          this.exitDialog(true, 1000);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public clearForm() {
    this.packName.setValue('');
  }

  public createNewPack() {
    this.errorEncountered$.next(false);
    this.savingPack$.next(true);
    const name = this.packName.value;
    this._store.dispatch(
      new CreateContentPack({
        name,
      }),
    );
  }

  public cancelNameChange() {
    this.exitDialog(false);
  }

  public exitDialog(status: boolean = false, time: number = 0) {
    setTimeout(() => {
      this.dialogRef.close(status);
    }, time);
  }
}
