import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portal-layout',
  templateUrl: `./layout.component.html`,
  styleUrls: [`./layout.component.scss`],
})
export class LayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
