<div class="choose-content" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3 class="header">{{ (useAContentText$ | async).DialogGlobalTitle }}</h3>
    <button mat-icon-button class="icon-size" (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <p>{{ (useAContentText$ | async).DialogGlobalDescription }}</p>
  <div>
    <mat-form-field fxFlexFill appearance="outline">
      <input
        matInput
        [formControl]="contentForm"
        [matAutocomplete]="autoGroup"
        [placeholder]="(useAContentText$ | async).ChooseAGlobalContent"
      />
      <mat-autocomplete
        #autoGroup="matAutocomplete"
        (optionSelected)="setSelectedValue($event.option.value)"
        [displayWith]="formatName"
      >
        <mat-optgroup
          *ngFor="let content of filteredContent$ | async"
          [label]="content.name"
        >
          <mat-option *ngFor="let entry of content.entries" [value]="entry">
            {{ entry.key }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-error *ngIf="contentForm.hasError('required')">
        {{ (useAContentText$ | async).SelectAGlobalContent }}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
    <div *ngIf="!contentForm.hasError('required')" class="warning">
      <span class="warningtitle"
        >{{ (useAContentText$ | async).Warning }}:&nbsp;</span
      >{{ (useAContentText$ | async).WarningGlobalFileText }}
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="25px">
      <button
        mat-raised-button
        class="portal-btn btn-bright"
        [disabled]="contentForm.hasError('required')"
        (click)="copyContent()"
      >
        {{ (useAContentText$ | async).Copy }}
      </button>
    </div>
  </div>
</div>
