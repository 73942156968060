import { of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, scan, shareReplay, tap } from 'rxjs/operators';
var PatientSearchBase = /** @class */ (function () {
    function PatientSearchBase(_patientService, _localisationService) {
        var _this = this;
        this._patientService = _patientService;
        this._localisationService = _localisationService;
        this._currentPageNumber = 0;
        this._searchTrigger$ = new Subject();
        this._sortParams = { active: '', direction: undefined };
        this._hasMoreItems = true;
        this.isLoading$ = new Subject();
        this.isSearchMode$ = new Subject();
        // Using arrow function as mat-autocomplete [displayWith] runs bound to MatAutocomplete
        this.transformPatientToString = function (patient) {
            if (patient) {
                return _this.transformPatientNameIdToString(patient) + " - " + _this.transformPatientDOBToString(patient);
            }
        };
        this.transformPatientNameIdToString = function (patient) {
            if (patient) {
                return patient.FirstName + " " + patient.LastName + " - " + patient.PatientIdentifier;
            }
        };
        this.transformPatientDOBToString = function (patient) {
            if (patient) {
                return "" + _this._localisationService.formatUTCDate(patient.DateOfBirth, 'medium');
            }
        };
    }
    PatientSearchBase.prototype.setupSearchObservables = function (errorHandler) {
        var _this = this;
        var getPatients$ = function (pageNumber, searchText, sortType, sortDirection) {
            var result = _this._hasMoreItems
                ? _this._patientService.getClinicPatients({
                    pageNumber: pageNumber,
                    query: searchText,
                    sortType: sortType,
                    sortDirection: sortDirection
                })
                : of(new Array());
            return result;
        };
        this.patients$ = this._searchTrigger$.pipe(mergeMap(function (searchTrigger) {
            _this.isLoading$.next(true);
            return getPatients$(searchTrigger.pageNumber, searchTrigger.searchText, _this._sortParams.active, _this._sortParams.direction);
        }), tap(function (next) {
            _this.isLoading$.next(false);
        }), scan(function (acc, value) {
            _this._hasMoreItems = value.length > 0;
            return _this._currentPageNumber > 1 ? acc.concat(value) : value;
        }), catchError(function (err) {
            return throwError(err);
        }), shareReplay(1));
    };
    PatientSearchBase.prototype.observeSearchInput = function (formControl) {
        var _this = this;
        this._formControl = formControl;
        this._formControl.valueChanges
            .pipe(map(function (val) {
            _this.isSearchMode$.next(val.length > 0);
        }), debounceTime(500))
            .subscribe(function () {
            _this._hasMoreItems = true;
            _this.pageReset();
            _this.triggerNextPage();
        });
    };
    PatientSearchBase.prototype.triggerNextPage = function () {
        var searchText = this._formControl.value || '';
        if (typeof searchText === 'string' || searchText.toString() === '') {
            this._searchTrigger$.next({
                pageNumber: ++this._currentPageNumber,
                searchText: searchText
            });
        }
    };
    PatientSearchBase.prototype.pageReset = function () {
        this._currentPageNumber = 0;
    };
    PatientSearchBase.prototype.onSortChanged = function (params) {
        this._sortParams = params;
        this.pageReset();
        this._searchTrigger$.next({
            pageNumber: ++this._currentPageNumber,
            searchText: this._formControl.value || 0
        });
    };
    return PatientSearchBase;
}());
export { PatientSearchBase };
