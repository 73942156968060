import { Divider, Typography } from '@material-ui/core';
import { ConversationFolder } from '@react/lib/api/types';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import { ClinicUser } from '@react/types';
import clsx from 'clsx';
import * as React from 'react';
import { VirtualFolders } from '../constants';
import { FilterState } from '../types';
import { MenuItem, MenuList } from './ui';

export interface Props {
  folders: ConversationFolder[];
  filters: FilterState;
  onFolderClick?: (
    id?: number,
    isResolved?: boolean,
    assignedId?: number,
  ) => void;
  className?: string;
  me: ClinicUser;
  assignedFolderCount?: number;
}

export const Folders: React.FunctionComponent<Props> = ({
  filters,
  folders,
  onFolderClick = () => undefined,
  className,
  assignedFolderCount,
  me,
  ...rest
}: Props) => {
  const { t } = useTranslations();
  const features: { [key: string]: any } = useFeatures();
  const featureAssignConversation = checkFeature(
    features,
    'messagingOptions.assignConversation',
  );
  const { folderIds: selectedIds, assignedIds, isUnassigned } = filters;
  const assignedIdIsMine = assignedIds && assignedIds.includes(me.id);

  const isFolderSelected = React.useCallback(
    (folderId?: number) =>
      selectedIds != null &&
      selectedIds.length === 1 &&
      folderId === selectedIds[0],
    [selectedIds],
  );

  const isVirtualFolderSelected = React.useCallback(
    (type: VirtualFolders, isResolved?: boolean) => {
      const isNotSelected = selectedIds == null || selectedIds.length !== 1;
      const localIsResolved = filters.isResolved === isResolved;
      const isNotAssigned = assignedIds == null || assignedIds.length !== 1;

      switch (type) {
        case VirtualFolders.Mine: {
          return (
            isNotSelected &&
            localIsResolved &&
            !isNotAssigned &&
            !isUnassigned &&
            assignedIdIsMine
          );
        }
        case VirtualFolders.AllUnresolved:
        case VirtualFolders.AllResolved:
        case VirtualFolders.AllMessages: {
          return (
            isNotSelected && localIsResolved && !isUnassigned && isNotAssigned
          );
        }
        default: {
          return false;
        }
      }
    },
    [selectedIds, filters.isResolved, assignedIds],
  );

  return (
    <MenuList className={clsx('folders', className)} {...rest}>
      {featureAssignConversation && (
        <MenuItem
          key={t.Messages.Mine}
          onClick={() => onFolderClick(undefined, false, me.id)}
          selected={isVirtualFolderSelected(VirtualFolders.Mine, false)}
        >
          <Typography variant="inherit">{t.Messages.Mine}</Typography>
          <Typography variant="inherit" className="count">
            {assignedFolderCount || 0}
          </Typography>
        </MenuItem>
      )}
      <MenuItem
        key={t.Messages.AllUnresolved}
        onClick={() => onFolderClick(undefined, false)}
        selected={isVirtualFolderSelected(VirtualFolders.AllUnresolved, false)}
      >
        <Typography variant="inherit">{t.Messages.AllUnresolved}</Typography>
      </MenuItem>
      {folders.map((folder) => (
        <MenuItem
          key={folder.id}
          onClick={() => onFolderClick(folder.id, false)}
          selected={isFolderSelected(folder.id)}
        >
          <Typography variant="inherit">{folder.name}</Typography>
          <Typography variant="inherit" className="count">
            {folder.unresolvedtotal || null}
          </Typography>
        </MenuItem>
      ))}
      <Divider />
      <MenuItem
        key={t.Messages.AllResolved}
        onClick={() => onFolderClick(undefined, true)}
        selected={isVirtualFolderSelected(VirtualFolders.AllResolved, true)}
        fontWeight="lighter"
      >
        <Typography variant="inherit">{t.Messages.AllResolved}</Typography>
      </MenuItem>
      <MenuItem
        key={t.Messages.AllMessages}
        onClick={() => onFolderClick(undefined, undefined)}
        selected={isVirtualFolderSelected(
          VirtualFolders.AllMessages,
          undefined,
        )}
        fontWeight="lighter"
      >
        <Typography variant="inherit">{t.Messages.AllMessages}</Typography>
      </MenuItem>
    </MenuList>
  );
};
