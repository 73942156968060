import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import { Conversation, Message } from '@react/types';

export function useMessagesToString() {
  const { t } = useTranslations();
  const dateTime = useLocaleDateTime();

  return (conversation: Conversation, messages: Message[]) => {
    const messageToString = (message: Message) => {
      const sentAt = dateTime.format(new Date(message.sentdate), 'P');
      const readAt =
        !message.patientsent &&
        message.read &&
        message.readdate &&
        dateTime.format(new Date(message.readdate), 'P');
      const sender = message.patientsent
        ? message.patientfirstname
        : message.clinicuserfullname;

      return [
        `[${sentAt}]`,
        sender,
        `(${
          message.patientsent
            ? `ID: ${conversation.patientidentifier}`
            : 'Staff'
        }):`,
        message.patientattachmentfilename
          ? t.Messages.SentFile(message.patientattachmentfilename)
          : message.content,
        readAt ? t.Messages.ReadReceipt(message.patientfullname, readAt) : '',
      ].join(' ');
    };

    const clipboardMessages = messages.reduce(
      (output: string[], message: Message) => [
        ...output,
        messageToString(message),
      ],
      [],
    );

    if (conversation.resolved) {
      clipboardMessages.push(`** ${t.Messages.MarkedResolved} **`);
    }

    return clipboardMessages.join('\n\n');
  };
}
