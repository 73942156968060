import * as tslib_1 from "tslib";
import { BehaviorSubject, Subject } from 'rxjs';
import { connect, createLocalTracks, LocalDataTrack, } from 'twilio-video';
export var LocalConnectionStatus;
(function (LocalConnectionStatus) {
    LocalConnectionStatus[LocalConnectionStatus["Connected"] = 0] = "Connected";
    LocalConnectionStatus[LocalConnectionStatus["Disconnected"] = 1] = "Disconnected";
})(LocalConnectionStatus || (LocalConnectionStatus = {}));
var VideoChatService = /** @class */ (function () {
    function VideoChatService() {
        this.room = null;
        this.audioOutputEnabled = 'setSinkId' in HTMLAudioElement.prototype;
        this.localConnectionStatusChanged$ = new Subject();
        this.audioInputDeviceChanged$ = new BehaviorSubject(undefined);
        this.audioInputDevicesChanged$ = new BehaviorSubject([]);
        this.audioOutputDeviceChanged$ = new BehaviorSubject(undefined);
        this.audioOutputDevicesChanged$ = new BehaviorSubject([]);
        this.videoDeviceChanged$ = new BehaviorSubject(undefined);
        this.videoDevicesChanged$ = new BehaviorSubject([]);
        this.participantConnected$ = new Subject();
        this.participantDisconnected$ = new Subject();
        this.dominantParticipantChanged$ = new Subject();
        this.errorConnecting = new Subject();
        this.roomReconnecting = new Subject();
        this.errorDevices = new Subject();
        this.onMediaDevicesChanged = this.onMediaDevicesChanged.bind(this);
    }
    VideoChatService.prototype.initializeMedia = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, createLocalTracks({ audio: true, video: true })];
                    case 1:
                        _a.tracks = (_b.sent()).concat([
                            new LocalDataTrack(),
                        ]);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        this.errorDevices.next(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VideoChatService.prototype.stopMedia = function () {
        try {
            this.tracks.forEach(function (t) {
                if ('stop' in t) {
                    t.stop();
                }
            });
        }
        catch (error) {
            console.error('Failed to stop tracks');
            console.error(error);
        }
    };
    VideoChatService.prototype.joinOrCreateRoom = function (name, token) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.leaveRoom();
                        navigator.mediaDevices.addEventListener('devicechange', this.onMediaDevicesChanged);
                        return [4 /*yield*/, this.updateMediaDevices()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, 5, 6]);
                        _a = this;
                        return [4 /*yield*/, connect(token, {
                                name: name,
                                tracks: this.tracks,
                                dominantSpeaker: true,
                                preferredVideoCodecs: ['H264', 'VP8'],
                            })];
                    case 3:
                        _a.room = _b.sent();
                        if (this.room) {
                            this.room.on('participantConnected', function (p) {
                                _this.onParticipantConnected(p);
                            });
                            this.room.on('participantDisconnected', function (p) {
                                _this.onParticipantDisconnected(p);
                            });
                            this.room.on('disconnected', function (r) {
                                _this.onDisconnected(r);
                            });
                            this.room.on('reconnecting', function () {
                                _this.onRoomReconnecting();
                            });
                            this.room.on('reconnected', function () {
                                _this.onRoomReconnected();
                            });
                            this.room.on('dominantSpeakerChanged', function (p) {
                                _this.onDominantSpeakerChanged(p);
                            });
                            this.room.participants.forEach(function (p) {
                                _this.onParticipantConnected(p);
                            });
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        error_2 = _b.sent();
                        console.error("Unable to connect to Room: " + error_2.message);
                        this.errorConnecting.next(error_2.message);
                        return [3 /*break*/, 6];
                    case 5:
                        this.localConnectionStatusChanged$.next(this.room
                            ? LocalConnectionStatus.Connected
                            : LocalConnectionStatus.Disconnected);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    VideoChatService.prototype.leaveRoom = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.roomReconnecting.next(false);
                if (this.room) {
                    this.room.disconnect();
                    this.room = null;
                }
                navigator.mediaDevices.removeEventListener('devicechange', this.onMediaDevicesChanged);
                return [2 /*return*/];
            });
        });
    };
    VideoChatService.prototype.sendMessage = function (message) {
        this.room.localParticipant.dataTracks.forEach(function (_a) {
            var track = _a.track;
            track.send(message);
        });
    };
    VideoChatService.prototype.toggleCamera = function () {
        this.room.localParticipant.videoTracks.forEach(function (_a) {
            var track = _a.track;
            if (track.isEnabled) {
                track.disable();
            }
            else {
                track.enable();
            }
        });
        return Array.from(this.room.localParticipant.videoTracks.values()).every(function (_a) {
            var track = _a.track;
            return track.isEnabled;
        });
    };
    VideoChatService.prototype.toggleMute = function () {
        this.room.localParticipant.audioTracks.forEach(function (_a) {
            var track = _a.track;
            if (track.isEnabled) {
                track.disable();
            }
            else {
                track.enable();
            }
        });
        return Array.from(this.room.localParticipant.audioTracks.values()).every(function (_a) {
            var track = _a.track;
            return track.isEnabled;
        });
    };
    VideoChatService.prototype.changeAudioInputDevice = function (audioInputDeviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var audioInputTrack, audioInputDevice, constraints;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        audioInputTrack = this.tracks.find(function (t) { return t.kind === 'audio'; });
                        audioInputDevice = this.audioInputDevicesChanged$.value.find(function (d) { return d.deviceId === audioInputDeviceId; });
                        if (!(audioInputTrack && audioInputDevice)) return [3 /*break*/, 2];
                        constraints = {
                            deviceId: { exact: audioInputDevice.deviceId },
                        };
                        return [4 /*yield*/, audioInputTrack.restart(constraints)];
                    case 1:
                        _a.sent();
                        this.audioInputDeviceChanged$.next(audioInputDevice);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VideoChatService.prototype.changeAudioOutputDevice = function (audioOutputDeviceId) {
        var audioOutputDevice = this.audioOutputDevicesChanged$.value.find(function (a) { return a.deviceId === audioOutputDeviceId; });
        if (audioOutputDevice) {
            this.audioOutputDeviceChanged$.next(audioOutputDevice);
        }
    };
    VideoChatService.prototype.changeVideoDevice = function (videoDeviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var videoTrack, videoDevice, constraints;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        videoTrack = this.tracks.find(function (t) { return t.kind === 'video'; });
                        videoDevice = this.videoDevicesChanged$.value.find(function (d) { return d.deviceId === videoDeviceId; });
                        if (!videoTrack) return [3 /*break*/, 2];
                        constraints = {
                            deviceId: { exact: videoDevice.deviceId },
                        };
                        return [4 /*yield*/, videoTrack.restart(constraints)];
                    case 1:
                        _a.sent();
                        this.videoDeviceChanged$.next(videoDevice);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    VideoChatService.prototype.onParticipantConnected = function (participant) {
        this.participantConnected$.next(participant);
    };
    VideoChatService.prototype.onParticipantDisconnected = function (participant) {
        this.participantDisconnected$.next(participant);
    };
    VideoChatService.prototype.onDisconnected = function (room) {
        room.localParticipant.tracks.forEach(function (track) {
            if (track.detach) {
                track.detach();
            }
        });
        this.localConnectionStatusChanged$.next(LocalConnectionStatus.Disconnected);
    };
    VideoChatService.prototype.onRoomReconnecting = function () {
        this.roomReconnecting.next(true);
    };
    VideoChatService.prototype.onRoomReconnected = function () {
        this.roomReconnecting.next(false);
    };
    VideoChatService.prototype.onDominantSpeakerChanged = function (participant) {
        this.dominantParticipantChanged$.next(participant);
    };
    VideoChatService.prototype.onMediaDevicesChanged = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.updateMediaDevices();
                return [2 /*return*/];
            });
        });
    };
    /**
     * Called during initialization or whenever the user's devices change.
     *
     * This method is responsible for obtaining an up to date list of devices
     * and then reconciling this
     */
    VideoChatService.prototype.updateMediaDevices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var devices, audioInputDevices, audioOutputDevices, videoDevices, audioTrack, audioTrackDeviceId, currentAudioInputDevice, videoTrack, videoTrackDeviceId, currentVideoDevice;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
                    case 1:
                        devices = _a.sent();
                        audioInputDevices = devices.filter(function (d) { return d.kind === 'audioinput'; });
                        audioOutputDevices = devices.filter(function (d) { return d.kind === 'audiooutput'; });
                        videoDevices = devices.filter(function (d) { return d.kind === 'videoinput'; });
                        audioTrack = this.tracks.find(function (t) { return t.kind === 'audio'; });
                        audioTrackDeviceId = audioTrack.mediaStreamTrack.getSettings().deviceId;
                        currentAudioInputDevice = audioInputDevices.find(function (d) { return d.deviceId === audioTrackDeviceId; });
                        this.audioInputDevicesChanged$.next(audioInputDevices);
                        // Despite the fact this might not be needed there are cases
                        // where a user may remove a device but the sound doesn't
                        // automatically transfer back to the default device properly
                        // If we just restart the local audio track regardless we can
                        // fix this and it causes minimum disruption to the user.
                        if (currentAudioInputDevice != null) {
                            this.changeAudioInputDevice(currentAudioInputDevice.deviceId);
                        }
                        // Audio output
                        this.audioOutputDevicesChanged$.next(audioOutputDevices);
                        // Twilio Video is not concerned with audio output as it's
                        // our job to make sure any remote participant's <audio/>
                        // elements send audio to the correct device. So when we get
                        // updated devices we need to check for two different things:
                        //
                        // 1. Is this our first time?
                        // 2. Has our previously chosen device been removed?
                        //
                        // If any of those things are true then we need to revert back
                        // to the default device (first in array of devices).
                        if (this.audioOutputEnabled &&
                            audioOutputDevices.length > 0 &&
                            (this.audioOutputDeviceChanged$.value == null ||
                                !audioOutputDevices.find(function (d) { return d.deviceId === _this.audioOutputDeviceChanged$.value.deviceId; }))) {
                            this.audioOutputDeviceChanged$.next(audioOutputDevices[0]);
                        }
                        videoTrack = this.tracks.find(function (t) { return t.kind === 'video'; });
                        videoTrackDeviceId = videoTrack.mediaStreamTrack.getSettings().deviceId;
                        currentVideoDevice = videoDevices.find(function (d) { return d.deviceId === videoTrackDeviceId; });
                        this.videoDevicesChanged$.next(videoDevices);
                        if (currentVideoDevice != null) {
                            // If it's the first time then default device will have been
                            // chosen so we can just capture that.
                            if (this.videoDeviceChanged$.value == null) {
                                this.videoDeviceChanged$.next(currentVideoDevice);
                            }
                            else if (this.videoDeviceChanged$.value.deviceId !== currentVideoDevice.deviceId) {
                                this.changeVideoDevice(currentVideoDevice.deviceId);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return VideoChatService;
}());
export { VideoChatService };
