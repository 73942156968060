export var ClinicActionTypes;
(function (ClinicActionTypes) {
    ClinicActionTypes["LoadClinicLanguages"] = "[Dashboard] Load Clinic Languages";
    ClinicActionTypes["LoadClinicLanguagesSuccess"] = "[Dashboard] Load Clinic Languages Success";
    ClinicActionTypes["LoadClinicLanguagesError"] = "[Dashboard] Load Clinic Languages Error";
    ClinicActionTypes["LoadClinicInfoSuccess"] = "[Dashboard] Load Clinic Info Success";
    ClinicActionTypes["LoadStaffProfile"] = "[Dashboard] Load Staff Profile";
    ClinicActionTypes["ClearStaffProfiles"] = "[Dashboard] Clear Staff Profiles";
})(ClinicActionTypes || (ClinicActionTypes = {}));
var LoadClinicLanguages = /** @class */ (function () {
    function LoadClinicLanguages() {
        this.type = ClinicActionTypes.LoadClinicLanguages;
    }
    return LoadClinicLanguages;
}());
export { LoadClinicLanguages };
var LoadClinicLanguagesSuccess = /** @class */ (function () {
    function LoadClinicLanguagesSuccess(payload) {
        this.payload = payload;
        this.type = ClinicActionTypes.LoadClinicLanguagesSuccess;
    }
    return LoadClinicLanguagesSuccess;
}());
export { LoadClinicLanguagesSuccess };
var LoadClinicLanguagesError = /** @class */ (function () {
    function LoadClinicLanguagesError(payload) {
        this.payload = payload;
        this.type = ClinicActionTypes.LoadClinicLanguagesError;
    }
    return LoadClinicLanguagesError;
}());
export { LoadClinicLanguagesError };
var LoadClinicInfoSuccess = /** @class */ (function () {
    function LoadClinicInfoSuccess(payload) {
        this.payload = payload;
        this.type = ClinicActionTypes.LoadClinicInfoSuccess;
    }
    return LoadClinicInfoSuccess;
}());
export { LoadClinicInfoSuccess };
var LoadStaffProfile = /** @class */ (function () {
    function LoadStaffProfile(payload) {
        this.payload = payload;
        this.type = ClinicActionTypes.LoadStaffProfile;
    }
    return LoadStaffProfile;
}());
export { LoadStaffProfile };
var ClearStaffProfiles = /** @class */ (function () {
    function ClearStaffProfiles() {
        this.type = ClinicActionTypes.ClearStaffProfiles;
    }
    return ClearStaffProfiles;
}());
export { ClearStaffProfiles };
