import { useDispatch } from '@react/lib/store';
import { useHistory } from 'react-router';
import { ShowNewConvoToastReact } from 'src/app/messaging/actions/new-message.actions';
import { constructMessageDeepLink } from '../lib';
export function useNewConversationToast() {
    var history = useHistory();
    var dispatch = useDispatch();
    return function (_a) {
        var title = _a.title, message = _a.message, conversation = _a.conversation, folderId = _a.folderId, _b = _a.refreshPageOnLinkClicked, refreshPageOnLinkClicked = _b === void 0 ? false : _b;
        var filters = {
            folderIds: [folderId],
            isResolved: false,
            isStarred: false,
        };
        dispatch(new ShowNewConvoToastReact({
            title: title,
            message: message,
            onActionClicked: function () {
                var messageLink = constructMessageDeepLink(conversation, filters);
                if (refreshPageOnLinkClicked) {
                    window.location.href = messageLink;
                }
                else {
                    history.push(messageLink);
                }
            },
        }));
    };
}
