import * as tslib_1 from "tslib";
import { Divider, Typography } from '@material-ui/core';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import clsx from 'clsx';
import * as React from 'react';
import { VirtualFolders } from '../constants';
import { MenuItem, MenuList } from './ui';
export var Folders = function (_a) {
    var filters = _a.filters, folders = _a.folders, _b = _a.onFolderClick, onFolderClick = _b === void 0 ? function () { return undefined; } : _b, className = _a.className, assignedFolderCount = _a.assignedFolderCount, me = _a.me, rest = tslib_1.__rest(_a, ["filters", "folders", "onFolderClick", "className", "assignedFolderCount", "me"]);
    var t = useTranslations().t;
    var features = useFeatures();
    var featureAssignConversation = checkFeature(features, 'messagingOptions.assignConversation');
    var selectedIds = filters.folderIds, assignedIds = filters.assignedIds, isUnassigned = filters.isUnassigned;
    var assignedIdIsMine = assignedIds && assignedIds.includes(me.id);
    var isFolderSelected = React.useCallback(function (folderId) {
        return selectedIds != null &&
            selectedIds.length === 1 &&
            folderId === selectedIds[0];
    }, [selectedIds]);
    var isVirtualFolderSelected = React.useCallback(function (type, isResolved) {
        var isNotSelected = selectedIds == null || selectedIds.length !== 1;
        var localIsResolved = filters.isResolved === isResolved;
        var isNotAssigned = assignedIds == null || assignedIds.length !== 1;
        switch (type) {
            case VirtualFolders.Mine: {
                return (isNotSelected &&
                    localIsResolved &&
                    !isNotAssigned &&
                    !isUnassigned &&
                    assignedIdIsMine);
            }
            case VirtualFolders.AllUnresolved:
            case VirtualFolders.AllResolved:
            case VirtualFolders.AllMessages: {
                return (isNotSelected && localIsResolved && !isUnassigned && isNotAssigned);
            }
            default: {
                return false;
            }
        }
    }, [selectedIds, filters.isResolved, assignedIds]);
    return (React.createElement(MenuList, tslib_1.__assign({ className: clsx('folders', className) }, rest),
        featureAssignConversation && (React.createElement(MenuItem, { key: t.Messages.Mine, onClick: function () { return onFolderClick(undefined, false, me.id); }, selected: isVirtualFolderSelected(VirtualFolders.Mine, false) },
            React.createElement(Typography, { variant: "inherit" }, t.Messages.Mine),
            React.createElement(Typography, { variant: "inherit", className: "count" }, assignedFolderCount || 0))),
        React.createElement(MenuItem, { key: t.Messages.AllUnresolved, onClick: function () { return onFolderClick(undefined, false); }, selected: isVirtualFolderSelected(VirtualFolders.AllUnresolved, false) },
            React.createElement(Typography, { variant: "inherit" }, t.Messages.AllUnresolved)),
        folders.map(function (folder) { return (React.createElement(MenuItem, { key: folder.id, onClick: function () { return onFolderClick(folder.id, false); }, selected: isFolderSelected(folder.id) },
            React.createElement(Typography, { variant: "inherit" }, folder.name),
            React.createElement(Typography, { variant: "inherit", className: "count" }, folder.unresolvedtotal || null))); }),
        React.createElement(Divider, null),
        React.createElement(MenuItem, { key: t.Messages.AllResolved, onClick: function () { return onFolderClick(undefined, true); }, selected: isVirtualFolderSelected(VirtualFolders.AllResolved, true), fontWeight: "lighter" },
            React.createElement(Typography, { variant: "inherit" }, t.Messages.AllResolved)),
        React.createElement(MenuItem, { key: t.Messages.AllMessages, onClick: function () { return onFolderClick(undefined, undefined); }, selected: isVirtualFolderSelected(VirtualFolders.AllMessages, undefined), fontWeight: "lighter" },
            React.createElement(Typography, { variant: "inherit" }, t.Messages.AllMessages))));
};
