var _this = this;
import * as tslib_1 from "tslib";
import { IconButton, MenuItem, TableCell, TextField } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { Label } from '@react/components';
import { useLocaleDateTime } from '@react/lib/date';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StyledSelect, StyledTableRow } from './ExportAction.styled';
import { useExportData } from './hooks';
import { TimeFrames } from './types';
import { getDateFieldValue, getTimeFrameDates } from './utils';
var ExportAction = function (props) {
    var t = useTranslations().t;
    var localeDateTime = useLocaleDateTime();
    var _a = useState(TimeFrames.Today), selectedTimeFrame = _a[0], setSelectedTimeFrame = _a[1];
    var _b = useState(getDateFieldValue(localeDateTime)), startDate = _b[0], setStartDate = _b[1];
    var _c = useState(getDateFieldValue(localeDateTime)), endDate = _c[0], setEndDate = _c[1];
    var _d = useState(true), startDateDisabled = _d[0], setStartDateDisabled = _d[1];
    var _e = useState(true), endDateDisabled = _e[0], setEndDateDisabled = _e[1];
    var _f = useState(false), exportSuccessToastVisible = _f[0], setExportSuccessToastVisible = _f[1];
    var _g = useState(false), exportErrorToastVisible = _g[0], setExportErrorToastVisible = _g[1];
    var _h = useState(true), isDownloadEnabled = _h[0], setIsDownloadEnabled = _h[1];
    var _j = useState(false), isDownloading = _j[0], setIsDownloading = _j[1];
    var onDownloadSuccess = function () {
        setExportSuccessToastVisible(true);
        setIsDownloading(false);
    };
    var onDownloadFailure = function () {
        setExportErrorToastVisible(true);
        setIsDownloading(false);
    };
    var _k = useExportData({
        type: props.type,
        from: startDate,
        to: endDate,
        onDownloadSuccess: onDownloadSuccess,
        onDownloadFailure: onDownloadFailure,
    }), download = _k.download, fileUrl = _k.fileUrl, ref = _k.ref, fileName = _k.fileName;
    var updateTimeFrame = function () {
        setStartDateDisabled(selectedTimeFrame !== TimeFrames.CustomDates);
        setEndDateDisabled(selectedTimeFrame !== TimeFrames.CustomDates);
        if (selectedTimeFrame !== TimeFrames.CustomDates) {
            var _a = getTimeFrameDates(localeDateTime, selectedTimeFrame), start = _a.start, end = _a.end;
            setStartDate(getDateFieldValue(localeDateTime, start));
            setEndDate(getDateFieldValue(localeDateTime, end));
        }
    };
    useEffect(function () {
        if (selectedTimeFrame) {
            updateTimeFrame();
        }
    }, [selectedTimeFrame]);
    useEffect(function () {
        setIsDownloadEnabled(startDate <= endDate);
    }, [startDate, endDate]);
    useErrorToast(exportErrorToastVisible, {
        title: t.SuperAdmin.DataExport.ToastFailureTitle,
        message: t.SuperAdmin.DataExport.ToastFailureDescription,
    });
    useSuccessToast(exportSuccessToastVisible, {
        title: t.SuperAdmin.DataExport.ToastSuccessTitle,
        message: t.SuperAdmin.DataExport.ToastSuccessDescription,
    });
    var onChangeTimeFrame = function (event) {
        setSelectedTimeFrame(event.target.value);
    };
    var onChangeStartDate = function (event) {
        setStartDate(String(event.target.value));
    };
    var onChangeEndDate = function (event) {
        setEndDate(String(event.target.value));
    };
    var saveToCSV = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var fileNameSuffix;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fileNameSuffix = props.filenameSuffix + " " + startDate + " " + endDate;
                    return [4 /*yield*/, download(fileNameSuffix)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(StyledTableRow, { "data-testid": props.type + "_row" },
        React.createElement(TableCell, null,
            React.createElement("h2", null, props.title),
            React.createElement("p", null, props.subtitle)),
        React.createElement(TableCell, null,
            React.createElement(Label, null, t.SuperAdmin.DataExport.TimeFrameLabel),
            React.createElement(StyledSelect, { disableUnderline: true, value: selectedTimeFrame, label: t.SuperAdmin.DataExport.TimeFrameLabel, onChange: onChangeTimeFrame, "data-testid": props.type + "_timeFrame" },
                React.createElement(MenuItem, { value: TimeFrames.Today }, t.SuperAdmin.DataExport.Today),
                React.createElement(MenuItem, { value: TimeFrames.Yesterday }, t.SuperAdmin.DataExport.Yesterday),
                React.createElement(MenuItem, { value: TimeFrames.Last7Days }, t.SuperAdmin.DataExport.Last7Days),
                React.createElement(MenuItem, { value: TimeFrames.LastWeek }, t.SuperAdmin.DataExport.LastWeek),
                React.createElement(MenuItem, { value: TimeFrames.ThisMonth }, t.SuperAdmin.DataExport.ThisMonth),
                React.createElement(MenuItem, { value: TimeFrames.LastMonth }, t.SuperAdmin.DataExport.LastMonth),
                React.createElement(MenuItem, { value: TimeFrames.Last12Months }, t.SuperAdmin.DataExport.Last12Months),
                React.createElement(MenuItem, { value: TimeFrames.CustomDates }, t.SuperAdmin.DataExport.CustomDates))),
        React.createElement(TableCell, null,
            React.createElement(TextField, { type: "date", label: t.SuperAdmin.DataExport.StartDateLabel, "data-testid": props.type + "_startDate", disabled: startDateDisabled, value: startDate, InputLabelProps: {
                    shrink: true,
                }, onChange: onChangeStartDate })),
        React.createElement(TableCell, null,
            React.createElement(TextField, { type: "date", label: t.SuperAdmin.DataExport.EndDateLabel, "data-testid": props.type + "_endDate", disabled: endDateDisabled, value: endDate, InputLabelProps: {
                    shrink: true,
                }, onChange: onChangeEndDate })),
        React.createElement(TableCell, null,
            React.createElement(IconButton, { "data-testid": props.type + "_btnSaveToCSV", onClick: saveToCSV, disabled: isDownloading || !isDownloadEnabled },
                React.createElement(GetApp, null)),
            React.createElement("a", { href: fileUrl, download: fileName, hidden: true, ref: ref }))));
};
var ɵ0 = ExportAction;
export default ExportAction;
export { ɵ0 };
