<div
  class="edit-form-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <p>{{ data.text.WhatPackName }}</p>
  </div>
  <div class="form-field-container" fxLayout="column">
    <mat-form-field fxFlex="grow">
      <input
        matInput
        type="text"
        placeholder="{{ data.text.PackName }}"
        [formControl]="packName"
      />
      <div matSuffix fxLayout="row" fxLayoutAlign="end center">
        <mat-spinner matSuffix *ngIf="loading$ | async" [diameter]="14">
        </mat-spinner>
        <button
          mat-button
          mat-icon-button
          aria-label="Clear"
          (click)="clearForm()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-form-field>
    <div class="errors">
      <mat-error *ngIf="packName.hasError('required') && packName.dirty">{{
        data.text.PackNameRequired
      }}</mat-error>
      <mat-error
        *ngIf="
          (packName.hasError('minlength') || packName.hasError('maxlength')) &&
          !packName.hasError('required') &&
          packName.dirty
        "
        >{{ data.text.PackNameLength }}</mat-error
      >
      <mat-error *ngIf="packName.hasError('nameExists') && packName.dirty">{{
        data.text.PackExists
      }}</mat-error>
    </div>
  </div>
  <div
    fxFlex="grow"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="15px"
  >
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="cancelNameChange()"
    >
      {{ data.text.Cancel }}
    </button>
    <div class="status" fxFlex="grow">
      <div
        *ngIf="savingName$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-spinner [diameter]="36"></mat-spinner>
        <div class="status-text">
          {{ data.text.ChangingFolder }}
        </div>
      </div>
      <div
        *ngIf="savedName$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-icon class="success">check_circle</mat-icon>
        <div class="status-text">
          {{ data.text.ChangingFolderSuccess }}
        </div>
      </div>
      <div
        *ngIf="errorEncountered$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-icon class="error">highlight_off</mat-icon>
        <div class="status-text">
          {{ data.text.ChangingFolderError }}
        </div>
      </div>
    </div>
    <button
      mat-raised-button
      class="portal-btn btn-bright"
      [disabled]="
        packName.status === formStatus.INVALID ||
        packName.status === formStatus.PENDING
      "
      (click)="savePackNameChange()"
    >
      {{ data.text.Save }}
    </button>
  </div>
</div>
