import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { IconButton, Input, TextField } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
export var Container = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 2px;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 2px;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var TextAreaContainer = styled.div(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  position: relative;\n"], ["\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  position: relative;\n"])));
export var LoadingContainer = styled.div(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  background-color: white;\n  height: 100%;\n  opacity: 0.75;\n  position: absolute;\n  width: 100%;\n"], ["\n  background-color: white;\n  height: 100%;\n  opacity: 0.75;\n  position: absolute;\n  width: 100%;\n"])));
export var TextArea = styled(Input)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  min-height: 6rem;\n  padding: ", "px;\n  resize: none;\n  width: 100%;\n\n  &::placeholder {\n    color: #9b9b9b;\n  }\n"], ["\n  flex: 1;\n  min-height: 6rem;\n  padding: ", "px;\n  resize: none;\n  width: 100%;\n\n  &::placeholder {\n    color: #9b9b9b;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
export var StatusBar = styled.div(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  box-sizing: border-box;\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: ", "px;\n  width: 100%;\n"], ["\n  box-sizing: border-box;\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: ", "px;\n  width: 100%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(0.75);
});
export var WarningText = styled.span(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  color: #9b9b9b;\n  display: flex;\n  font-size: smaller;\n"], ["\n  align-items: center;\n  color: #9b9b9b;\n  display: flex;\n  font-size: smaller;\n"])));
export var WarningIcon = styled(Warning)(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  color: orange;\n  margin-right: 4px;\n  position: relative;\n  top: -1px;\n"], ["\n  color: orange;\n  margin-right: 4px;\n  position: relative;\n  top: -1px;\n"])));
export var CharacterCount = styled.span(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ",
    ";\n  font-size: ", ";\n"])), function (_a) {
    var maxLengthReached = _a.maxLengthReached, theme = _a.theme;
    return maxLengthReached ? theme.colors.error : theme.colors.text.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.typography.body2.fontSize;
});
export var Toolbar = styled.div(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["\n  align-items: center;\n  background-color: #f7f7fb;\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  display: flex;\n  justify-content: space-between;\n  padding: ", "px;\n"], ["\n  align-items: center;\n  background-color: #f7f7fb;\n  border-top: 1px solid rgba(0, 0, 0, 0.1);\n  display: flex;\n  justify-content: space-between;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(0.75);
});
export var ToolbarIconButton = styled(IconButton)(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["\n  color: #4a4a4a;\n"], ["\n  color: #4a4a4a;\n"])));
export var MessageTemplates = styled.div(templateObject_11 || (templateObject_11 = tslib_1.__makeTemplateObject(["\n  height: 450px;\n  width: 300px;\n"], ["\n  height: 450px;\n  width: 300px;\n"])));
export var SearchTextField = styled(TextField)(templateObject_12 || (templateObject_12 = tslib_1.__makeTemplateObject(["\n  background: #fff;\n  box-sizing: border-box;\n  padding: ", "px;\n  padding-bottom: 0;\n  position: sticky;\n  top: 0;\n  width: 100%;\n  z-index: 100;\n"], ["\n  background: #fff;\n  box-sizing: border-box;\n  padding: ", "px;\n  padding-bottom: 0;\n  position: sticky;\n  top: 0;\n  width: 100%;\n  z-index: 100;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1.5);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
