import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
export var Input = styled.input(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  -webkit-appearance: none;\n  border: 1px solid\n    ", ";\n  border-radius: 2px;\n  color: ", ";\n  font-size: 1rem;\n  outline: none;\n  padding: 0.5rem;\n  width: ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:hover,\n  &:focus {\n    border: ", ";\n  }\n"], ["\n  -webkit-appearance: none;\n  border: 1px solid\n    ",
    ";\n  border-radius: 2px;\n  color: ",
    ";\n  font-size: 1rem;\n  outline: none;\n  padding: 0.5rem;\n  width: ", ";\n\n  &::placeholder {\n    color: ",
    ";\n  }\n\n  &:hover,\n  &:focus {\n    border: ",
    ";\n  }\n"])), function (_a) {
    var error = _a.error, theme = _a.theme;
    return error ? theme.colors.error : theme.colors.text.primary;
}, function (_a) {
    var error = _a.error, theme = _a.theme;
    return error ? theme.colors.error : theme.colors.text.primary;
}, function (_a) {
    var fullWidth = _a.fullWidth;
    return (fullWidth ? '100%' : 'initial');
}, function (_a) {
    var error = _a.error, theme = _a.theme;
    return error ? theme.colors.error : theme.colors.border.default;
}, function (_a) {
    var error = _a.error, theme = _a.theme;
    return "1px solid " + (error ? theme.colors.error : theme.colors.primary);
});
var templateObject_1;
