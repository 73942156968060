import { useApiClient } from '@react/lib/api';
import {
  DataExportTypes,
  IDataExportHookParams,
  IDataExportHookResult,
} from '../types';
import { createExportDownloadHandler } from './utils';

export function useExportData({
  type,
  from,
  to,
  onDownloadSuccess,
  onDownloadFailure,
}: IDataExportHookParams): IDataExportHookResult {
  const apiClient = useApiClient().portal;

  const request = async () => {
    switch (type) {
      case DataExportTypes.Messages: {
        return apiClient.exportMessages(from, to);
      }
      default: {
        throw new Error(`No request has been found for type: ${type}`);
      }
    }
  };

  return createExportDownloadHandler(
    request,
    onDownloadSuccess,
    onDownloadFailure,
  );
}
