import { ErrorHandler, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import {
  PushNotificationOptions,
  PushNotificationsService
} from '../../core/services/push-notifications.service';
import { WindowRefService } from '../../core/services/window-ref.service';
import { Message } from '../../models/Message';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';
import {
  ClickedPushNotification,
  ClosedPushNotification,
  LoadMessageActionTypes,
  ShowPushNotification,
  UpdateLastSeen
} from '../actions/load-message.actions';
import { ApplyFilter, OpenThread } from '../actions/message-ui.actions';

@Injectable()
export class MessageNotificationEffects {
  private _notificationIsShowing = false;
  private _currentNotification: any;
  private _text: any;

  constructor(
    private _store: Store<fromRoot.State>,
    private _actions$: Actions,
    private _push: PushNotificationsService,
    private _navigationService: NavigationService,
    private windowService: WindowRefService,
    private titleService: Title,
    private _error: ErrorHandler
  ) {
    this._store
      .pipe(
        select(fromSettings.getSectionTranslations('MessagingNotifications'))
      )
      .subscribe((t) => {
        this._text = t;
      });
  }

  @Effect()
  openNotification$ = this._actions$.pipe(
    ofType<ShowPushNotification>(LoadMessageActionTypes.ShowPushNotification),
    mergeMap((action) => {
      return new Promise((res) => {
        const { newMessages } = action.payload;
        if (newMessages.length === 0) {
          return;
        }
        let message: Message;
        if (newMessages.length === 1) {
          message = newMessages[0];
        }

        // change tab title to show number of new messages
        this.titleService.setTitle(
          this._text.BrowserTabTitle(newMessages.length)
        );

        if (action.payload.showPushNotification) {
          // Only show notification if permission is granted
          if (this._push.permission) {
            // set message body of notification
            let body = this._text.NotificationBody(newMessages.length);
            if (message) {
              body = this._text.NotificationBodyMessage(
                `${message.PatientFirstName} ${message.PatientLastName}`
              );
            }
            const title = this._text.NotificationTitle;
            // retrieve default options
            const options: PushNotificationOptions = {
              body,
              silent: false,
              requireInteraction: true,
              icon: 'https://salveportaltest.s3.amazonaws.com/assets/imgs/logo.png'
            };

            // Check if a notification already exists
            if (this._notificationIsShowing) {
              this._currentNotification.notification.close();
            }

            // create push notification
            this._push.create(title, options).subscribe((notif) => {
              if (notif.event.type === 'show') {
                this._setNotification(notif);
              }
              if (notif.event.type === 'click') {
                this._setNotification();
                this._navigationService.navigate(['messages']);
                this.windowService.nativeWindow.focus();
                notif.notification.close();
                if (message) {
                  // this._store.dispatch(new LoadThread(message));
                  this._store.dispatch(new OpenThread(message));
                }
                this._store.dispatch(new UpdateLastSeen());
                this._store.dispatch(new ApplyFilter('unresolved'));
                res(new ClickedPushNotification());
              }
              if (notif.event.type === 'close') {
                this._setNotification();
                res(new ClosedPushNotification());
              }
            });
          } else {
            this._error.handleError({
              originalError: new Error(
                'permission to show notifications not granted.'
              )
            });
          }
        }
      });
    })
  );

  private _setNotification(notif?: any) {
    if (notif) {
      this._notificationIsShowing = true;
      this._currentNotification = notif;
    } else {
      this._notificationIsShowing = false;
      this._currentNotification = null;
    }
  }
}
