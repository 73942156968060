import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Box, CircularProgress, List, ListItem, ListItemText, Tab, Tabs, } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useContent } from '@react/lib/api/hooks';
import { useEnvVariables, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useState } from 'react';
import { Empty } from './Empty';
import { ListingTooltip } from './ListingTooltip';
import MessageAddContentLinkListing from './MessageAddContentLinkListing';
import { TabPanel } from './tabs';
var ItemText = styled('span')(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  display: block;\n  width: 250px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  display: block;\n  width: 250px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
var ListItemRender = function (_a) {
    var label = _a.label, isSelected = _a.isSelected, onClick = _a.onClick;
    return (React.createElement(ListItem, { button: true, onClick: function () { return onClick(); } },
        React.createElement(ListingTooltip, { title: label },
            React.createElement(ListItemText, { primaryTypographyProps: {
                    color: isSelected ? 'primary' : undefined,
                } },
                React.createElement(Box, { display: "flex", flexDirection: "row", width: 320 },
                    React.createElement(ItemText, null, label),
                    isSelected && React.createElement(Check, null))))));
};
var ɵ0 = ListItemRender;
var TabsContainer = styled(Box)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  border-right: 1px solid ", ";\n"], ["\n  border-right: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border.dark;
});
var PatientContentCategoryListing = function (_a) {
    var onAddContentLink = _a.onAddContentLink;
    var portalAppBaseUrl = useEnvVariables().portalAppBaseUrl;
    var t = useTranslations().t;
    var _b = useState(0), activeIndex = _b[0], setActiveIndex = _b[1];
    var _c = useState(), selectedPack = _c[0], setSelectedPack = _c[1];
    var _d = useState(), selectedFolder = _d[0], setSelectedFolder = _d[1];
    var _e = useContent({
        packId: selectedPack ? selectedPack.id : undefined,
    }), packs = _e.packs, categories = _e.categories, packDetails = _e.packDetails, categoryEntries = _e.categoryEntries;
    var hasContentFetchError = [
        packs,
        categories,
        packDetails,
        categoryEntries,
    ].some(function (queryResult) { return queryResult.isError; });
    useErrorToast(hasContentFetchError, {
        title: t.Admin.MessageContentLoadError,
        message: t.General.ConnectionError,
    }, 0);
    var renderPacksList = function () {
        var packsData = packs.data && packs.data.contentPacks;
        if (!packsData || !packsData.length) {
            return React.createElement("p", null, t.Admin.MessageContentNoPacks);
        }
        return packsData.map(function (pack) {
            var isSelected = pack === selectedPack;
            var onClick = function () {
                if (isSelected) {
                    setSelectedPack(undefined);
                }
                else {
                    setSelectedPack(pack);
                    setSelectedFolder(undefined);
                }
            };
            return (React.createElement(React.Fragment, { key: pack.id },
                React.createElement(ListItemRender, tslib_1.__assign({}, { isSelected: isSelected, onClick: onClick }, { label: pack.packName }))));
        });
    };
    var renderCategoryList = function () {
        var categoryData = categories.data;
        if (!categoryData || !categoryData.length) {
            return React.createElement("p", null, t.Admin.MessageContentNoFolders);
        }
        return categoryData.map(function (folder) {
            var isSelected = folder === selectedFolder;
            var onClick = function () {
                if (isSelected) {
                    setSelectedFolder(undefined);
                }
                else {
                    setSelectedFolder(folder);
                    setSelectedPack(undefined);
                }
            };
            return (React.createElement(React.Fragment, { key: folder.id },
                React.createElement(ListItemRender, tslib_1.__assign({}, { isSelected: isSelected, onClick: onClick }, { label: folder.name }))));
        });
    };
    var onSelectContentItem = function (_a) {
        var type = _a.type, id = _a.id;
        var contentRootId = type === 'folder'
            ? selectedFolder && selectedFolder.id
            : selectedPack && selectedPack.id;
        var contentPath = [contentRootId, id];
        onAddContentLink(portalAppBaseUrl + "/information/" + contentPath.join('/'));
    };
    var onTabChange = function (_, newIndex) {
        setActiveIndex(newIndex);
        setSelectedPack(undefined);
        setSelectedFolder(undefined);
    };
    return (React.createElement(Box, { flexDirection: "row", display: "flex", width: 670 },
        React.createElement(TabsContainer, { flex: 1 },
            React.createElement(Tabs, { variant: "fullWidth", value: activeIndex, onChange: onTabChange, indicatorColor: "primary" },
                React.createElement(Tab, { label: t.ContentDirectory.Categories }),
                React.createElement(Tab, { label: t.ContentDirectory.Packs })),
            React.createElement(Box, null,
                React.createElement(TabPanel, { value: activeIndex, index: 0 },
                    categories.isLoading && React.createElement(CircularProgress, { thickness: 1 }),
                    categories.isSuccess && (React.createElement(List, null,
                        React.createElement(Box, { overflow: "auto", maxHeight: 450 }, renderCategoryList())))),
                React.createElement(TabPanel, { value: activeIndex, index: 1 },
                    packs.isLoading && React.createElement(CircularProgress, { thickness: 1 }),
                    packs.isSuccess && (React.createElement(List, null,
                        React.createElement(Box, { overflow: "auto", maxHeight: 450 }, renderPacksList())))))),
        React.createElement(Box, { flex: 1, height: 500, overflow: "auto" }, packDetails.isError || categoryEntries.isError ? (React.createElement(Box, { textAlign: "center" },
            React.createElement(Empty, { message: t.Admin.MessageContentLoadError }))) : (React.createElement(MessageAddContentLinkListing, { mode: activeIndex === 0 ? 'folder' : 'pack', folders: categoryEntries.data && selectedFolder && selectedFolder.id
                ? categoryEntries.data[selectedFolder.id]
                : undefined, loading: packDetails.isLoading, packDetail: packDetails, onSelectContent: onSelectContentItem })))));
};
var ɵ1 = PatientContentCategoryListing;
export default PatientContentCategoryListing;
export { ɵ0, ɵ1 };
var templateObject_1, templateObject_2;
