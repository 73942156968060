<mat-card class="content-container">
  <div fxLayout="row" fxLayoutAlign="start stretch" fxFill>
    <portal-content-directory
      [text]="directoryText$ | async"
      [categories]="categories$ | async"
      [templates]="templates$ | async"
      [activeSection]="routeType$ | async"
      [packs]="packs$ | async"
      [selectedId]="categoryId$ | async"
      [activeDirectory]="openDirectory$ | async"
      [contentEditorEnabled]="contentEditorEnabled"
      (goToPage)="navigateTo($event)"
      (addNew)="createNewCategoryOrTemplate()"
      (createFolder)="createNewFolder()"
      (editFolderName)="changeFolderName($event)"
      (openSection)="openDirectorySection($event)"
      (createPack)="createNewPack()"
      (editPackName)="editPackName($event)"
      [isGlobalPortal]="isGlobalPortal$ | async"
    >
    </portal-content-directory>
    <div fxFlex="grow">
      <portal-template-edit-form
        [text]="templateEditText$ | async"
        [template]="selectedTemplate$ | async"
        [creating]="creatingTemplate$ | async"
        [creatingSuccess]="creatingTemplateSuccess$ | async"
        [creatingError]="creatingTemplateError$ | async"
        [contentEditorEnabled]="contentEditorEnabled"
        (saveTemplate)="saveTemplate($event)"
        (deleteTemplate)="deleteTemplate($event)"
      >
      </portal-template-edit-form>
    </div>
  </div>
</mat-card>
