import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Category } from '../../models/category';
import { TableContentEntry, ViewChildParams } from '../../models/content-entry';
import { AddToPackComponent } from '../add-to-pack/add-to-pack.component';
import {
  DialogWithDismissComponent,
  DismissComponentInterface,
} from './../../../core/components/dialog-with-dismiss/dialog-with-dismiss.component';
import { LinkContentComponent } from './../link-content/link-content.component';

export class MoveEntriesArgs {
  public entries: number[];
  public categoryId: number;
}

export class DeleteEntriesArgs {
  public entries: number[];
  public categoryId: number;
}

@Component({
  selector: 'portal-content-list-table',
  templateUrl: './content-list-table.component.html',
  styleUrls: ['./content-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentListTableComponent implements OnInit, OnChanges {
  @Input() text: any;
  @Input() content: TableContentEntry[];
  @Input() filterActive: boolean;
  @Input() categoryId: number;
  @Input() isMultiLingual: Observable<boolean>;
  @Input() category: Category;
  @Input() contentEditorEnabled: boolean;
  @Input() isGlobalPortal$: Observable<boolean>;
  @Output() createFolder = new EventEmitter();
  @Output() goto = new EventEmitter<TableContentEntry>();
  @Output() deleteAllSelected = new EventEmitter<DeleteEntriesArgs>();
  @Output() moveAllSelected = new EventEmitter<MoveEntriesArgs>();
  @Output() deleteCategory = new EventEmitter<number>();
  @Output() viewChildren = new EventEmitter<ViewChildParams>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: MatTableDataSource<TableContentEntry>;
  public config: PerfectScrollbarConfigInterface = {};
  public selection: SelectionModel<TableContentEntry>;
  public displayedColumns$: Observable<string[]>;
  public isGlobalPortal: boolean;

  constructor(public dialog: MatDialog, public change: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._updateDataSource();

    this.displayedColumns$ = combineLatest(
      this.isMultiLingual,
      this.isGlobalPortal$,
    ).pipe(
      map(([isMultiLingual, isGlobalPortal]) =>
        [
          'select',
          !isGlobalPortal && 'fromDataSync',
          isMultiLingual && 'translations',
          !isGlobalPortal && 'link',
          'name',
          'isEmpty',
          !isGlobalPortal && 'sendToAll',
          'salveModified',
          'lastModifiedBy',
        ].filter((x) => !!x),
      ),
    );

    this.isGlobalPortal$.subscribe((t) => (this.isGlobalPortal = t));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { content, categoryId } = changes;

    if (content && changes.content.currentValue) {
      this._updateDataSource();
    }
    if (
      categoryId &&
      this.paginator &&
      categoryId.currentValue &&
      categoryId.previousValue
    ) {
      if (categoryId.currentValue !== categoryId.previousValue) {
        this.paginator.firstPage();
      }
    }
  }

  public noLinkedItemsSelected(): boolean {
    // do not show link content option if the folder is associated with site or staff
    if (this.category.issite === true || this.category.isstaff === true) {
      return false;
    }
    return this.selection.selected.every((r) => !(r.hasChildren || r.parentId));
  }

  public noSyncedItemsSelected(): boolean {
    return this.selection.selected.every((r) => !r.fromDataSync);
  }

  public noLinkedOrSyncedItems(): boolean {
    return this.selection.selected.every(
      (r) => !(r.fromDataSync && (r.hasChildren || r.parentId)),
    );
  }

  public someSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  public selectTooltip(row): string {
    return row.fromDataSync && (row.hasChildren || row.parentId)
      ? this.text.LinkedAndSynced
      : '';
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public clearSelection(): void {
    this.selection.clear();
    this.change.markForCheck();
    this.change.detectChanges();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.clearSelection()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  private _updateDataSource() {
    this.dataSource = new MatTableDataSource(this.content);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    this.selection = new SelectionModel(true, []);
  }

  private _selectedItems(): number[] {
    return this.selection.selected.map((r) => r.id);
  }

  // Event Emitters
  // ===========================
  public goToContent(row) {
    this.goto.emit(row);
  }

  public deleteItems() {
    // Check if any of selected elements is a linked element
    for (const selectedItem of this.selection.selected) {
      if (selectedItem.hasChildren) {
        this.blockDeletion(selectedItem.name);
        // return would end the loop
        return;
      }
    }
    // If no selected element is linked, emit for deletion
    this.deleteAllSelected.emit({
      entries: this._selectedItems(),
      categoryId: this.categoryId,
    });
  }

  public moveItems() {
    this.moveAllSelected.emit({
      entries: this._selectedItems(),
      categoryId: this.categoryId,
    });
  }

  public linkItems() {
    const linkEntriesDialog = this.dialog.open(LinkContentComponent, {
      data: {
        text: this.text,
        selectedItems: this.selection.selected,
        contentCategoryId: this.categoryId,
      },
      width: '600px',
    });
    linkEntriesDialog
      .afterClosed()
      .pipe(
        tap((outcome: boolean) => {
          if (outcome) {
            this.clearSelection();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  public blockDeletion(contentName: string) {
    const data: DismissComponentInterface = {
      message: this.text.DeleteItemBlock(contentName),
      text: {
        Dismiss: this.text.OK,
      },
    };
    const deleteItemsDialog = this.dialog.open(DialogWithDismissComponent, {
      data,
    });
    deleteItemsDialog.afterClosed().subscribe(() => this.clearSelection());
  }

  public deleteFolder() {
    this.deleteCategory.emit(this.categoryId);
  }

  public handleLinkClick(row: TableContentEntry) {
    if (row.link !== 0) {
      this.viewChildren.emit({
        // if child selected, show parent with child at top of list
        // and selected, else just pass parent id and load all children,
        // all of which are unselected
        parentId: row.link === 1 ? row.parentId : row.id,
        contentId: row.link === 1 ? row.id : null,
        categoryId: row.categoryId,
      });
    }
  }

  public addToContentPack() {
    const addToPackDialog = this.dialog.open(AddToPackComponent, {
      data: {
        text: this.text,
        selectedItems: this.selection.selected,
      },
      width: '600px',
    });
    addToPackDialog
      .afterClosed()
      .pipe(
        tap((outcome: boolean) => {
          if (outcome) {
            this.clearSelection();
          }
        }),
        take(1),
      )
      .subscribe();
  }
}
