import { Pipe, PipeTransform } from '@angular/core';
import {
  addMinutes,
  isAfter,
  isBefore,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import { ApiVideoCall } from '../responses';

@Pipe({ name: 'statusToText' })
export class StatusToTextPipe implements PipeTransform {
  transform(input: string, text: any): string {
    switch (input) {
      case 'upcoming':
        return text.StatusUpcoming;
      case 'in progress':
        return text.StatusOngoing;
      case 'finished':
        return text.StatusFinished;
    }
  }
}

@Pipe({ name: 'joinDisabled' })
export class JoinDisabledPipe implements PipeTransform {
  transform(input: ApiVideoCall): boolean {
    const windowStart = addMinutes(parseISO(input.scheduleddate), -10);
    const windowEnd = addMinutes(
      parseISO(input.scheduleddate),
      input.allowedduration,
    );
    return !isWithinInterval(Date.now(), {
      start: windowStart,
      end: windowEnd,
    });
  }
}

@Pipe({ name: 'unInviteEnabled' })
export class UnInviteEnabledPipe implements PipeTransform {
  transform(input: ApiVideoCall): boolean {
    const windowStart = addMinutes(parseISO(input.scheduleddate), -10);
    return !isAfter(Date.now(), windowStart);
  }
}

@Pipe({ name: 'editDisabled' })
export class EditDisabledPipe implements PipeTransform {
  transform(input: ApiVideoCall): boolean {
    if (!input.iseditable) {
      return true;
    }
    const windowStart = addMinutes(parseISO(input.scheduleddate), -10);
    return !isBefore(Date.now(), windowStart);
  }
}
