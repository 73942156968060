import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
export var StyledTabPanel = styled(Box)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  padding: ", "px;\n  margin-bottom: ", "px;\n  height: 300px;\n  overflow: hidden;\n"], ["\n  padding: ", "px;\n  margin-bottom: ", "px;\n  height: 300px;\n  overflow: hidden;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
});
var templateObject_1;
