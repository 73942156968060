var ClinicPatientRequest = /** @class */ (function () {
    function ClinicPatientRequest(_a) {
        var PublicToken = _a.PublicToken, PatientId = _a.PatientId;
        this.Action = 'clinicpatient';
        this.PublicToken = PublicToken;
        this.PatientId = PatientId;
    }
    return ClinicPatientRequest;
}());
export { ClinicPatientRequest };
