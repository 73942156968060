var GetYoutubeIdService = /** @class */ (function () {
    function GetYoutubeIdService() {
    }
    // private _options: {
    //   fuzzy: true
    // };
    // constructor(options: GetYoutubeIdServiceOptions) {
    //   if (options &&
    //     options.fuzzy &&
    //     typeof options.fuzzy === 'boolean'
    //   ) {
    //     this._options.fuzzy = options.fuzzy;
    //   }
    // }
    GetYoutubeIdService.prototype.getYoutubeId = function (url, options) {
        if (options === void 0) { options = { fuzzy: true }; }
        if (/youtu\.?be/.test(url)) {
            // Look first for known patterns
            var patterns = [
                /youtu\.be\/([^#\&\?]{11})/,
                /\?v=([^#\&\?]{11})/,
                /\&v=([^#\&\?]{11})/,
                /embed\/([^#\&\?]{11})/,
                /\/v\/([^#\&\?]{11})/,
            ];
            // If any pattern matches, return the ID
            for (var _i = 0, patterns_1 = patterns; _i < patterns_1.length; _i++) {
                var pattern = patterns_1[_i];
                if (pattern.test(url)) {
                    return pattern.exec(url)[1];
                }
            }
            if (options.fuzzy) {
                // If that fails, break it apart by certain characters and look
                // for the 11 character key
                var tokens = url.split(/[\/\&\?=#\.\s]/g);
                for (var _a = 0, tokens_1 = tokens; _a < tokens_1.length; _a++) {
                    var token = tokens_1[_a];
                    if (/^[^#\&\?]{11}$/.test(token)) {
                        return token;
                    }
                }
            }
        }
        return null;
    };
    return GetYoutubeIdService;
}());
export { GetYoutubeIdService };
