import * as tslib_1 from "tslib";
import { Button } from '@react/components/Button';
import { LoadingError } from '@react/components/LoadingError';
import { useTranslations } from '@react/lib/i18n';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DataExports from './components/DataExports';
import Nav from './components/Nav';
import { NoPermissionIcon } from './components/NoPermissionIcon';
import ResubmitPatientFormLookup from './components/PatientFormsLookup';
import { LoadingErrorContainer, Main, PageContainer, StyledRoot, } from './SuperAdminPage.styled';
export var SuperAdminPage = function (props) {
    var t = useTranslations().t;
    var isSuperAdminUser = props.user.groups.includes('ClinicPortalSuperAdmin');
    return (React.createElement(StyledRoot, tslib_1.__assign({}, props),
        React.createElement(PageContainer, null, !isSuperAdminUser ? (React.createElement(LoadingErrorContainer, null,
            React.createElement(LoadingError, { icon: React.createElement(NoPermissionIcon, null), message: t.Admin.NoAccessMessage },
                React.createElement(Button, { color: "primary", href: "/", variant: "contained" }, t.Admin.NoAccessButtonText)))) : (React.createElement(React.Fragment, null,
            React.createElement(Nav, null),
            React.createElement(Main, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/superadmin" },
                        React.createElement(Redirect, { to: "/superadmin/data-exports" })),
                    React.createElement(Route, { exact: true, path: "/superadmin/data-exports" },
                        React.createElement(DataExports, null)),
                    React.createElement(Route, { exact: true, path: "/superadmin/form-lookup" },
                        React.createElement(ResubmitPatientFormLookup, null)),
                    React.createElement(Route, { exact: true, path: "*" },
                        React.createElement(Redirect, { to: "/superadmin" })))))))));
};
