var Thread = /** @class */ (function () {
    function Thread(m, messages, isReadonly) {
        this.id = m.MessageId;
        this.typeId = m.MessageTypeId;
        this.type = m.MessageType;
        this.patientName = m.PatientFirstName + ' ' + m.PatientLastName;
        this.patientFirstName = m.PatientFirstName;
        this.patientId = m.PatientId;
        this.patientIdentifier = m.PatientIdentifier;
        this.patientDoB = m.DateOfBirth;
        this.patientSent = m.PatientSent;
        this.isResolved = m.MessageStatusId === 999;
        this.isUnread = !m.Read;
        this.messages = messages.slice();
        this.isReadOnly = isReadonly;
        this.hasNewMessages = false;
        this.subject = m.MessageSubject;
    }
    return Thread;
}());
export { Thread };
