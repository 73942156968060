import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import * as Sentry from '@sentry/browser';
import { useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient, } from 'react-query';
import { DownloadLists } from '../types';
export function useAppointmentTypesQuery(_a) {
    var searchTerm = _a.searchTerm;
    var apiClient = useApiClient().portal;
    return useInfiniteQuery(['appointmentTypes', { searchTerm: searchTerm }], function (_a) {
        var pageParam = _a.pageParam;
        return apiClient.fetchAppointmentTypes({ pageParam: pageParam, query: searchTerm });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
    });
}
export function useAppointmentTypeQuery(_a) {
    var id = _a.id;
    var apiClient = useApiClient().portal;
    return useQuery(['appointmentType', id], function () {
        return apiClient.fetchAppointmentTypeDetails({ id: id });
    });
}
export function useAppointmentTypeMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (args) {
        return apiClient.updateAppointmentTypeDetails(args);
    }, {
        onSuccess: function (data) {
            queryClient.invalidateQueries('appointmentTypes');
            queryClient.setQueryData(['appointmentType', data.data.id], data);
        },
    });
}
export function useMessageTemplateQuery(_a) {
    var id = _a.id;
    var apiClient = useApiClient().portal;
    return useQuery(['messageTemplate', id], function () {
        return apiClient.fetchMessageTemplate({ id: id });
    });
}
export function useMessageTemplatesMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (args) {
        return 'id' in args && args.id != null
            ? apiClient.updateMessageTemplate(args)
            : apiClient.createMessageTemplate(args);
    }, {
        onSuccess: function (data) {
            queryClient.invalidateQueries('messageTemplates');
            queryClient.setQueryData(['messageTemplate', data.data.id], data);
        },
    });
}
export function useDeleteMessageTemplateMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (id) { return apiClient.deleteMessageTemplate({ id: id }); }, {
        onSuccess: function (data, id) {
            queryClient.invalidateQueries('messageTemplates');
            queryClient.removeQueries(['messageTemplate', id]);
        },
    });
}
export function useMessageTemplateVariablesQuery(_a) {
    var _b = (_a === void 0 ? {} : _a).enabled, enabled = _b === void 0 ? true : _b;
    var apiClient = useApiClient().portal;
    return useQuery(['messageTemplateVariables'], function () { return apiClient.fetchMessageTemplateVariables(); }, { enabled: enabled });
}
export function useExportAdminList(_a) {
    var _this = this;
    var type = _a.type, onDownloadSuccess = _a.onDownloadSuccess, onDownloadFailure = _a.onDownloadFailure;
    var apiClient = useApiClient().portal;
    var ref = useRef(null);
    var _b = useState(), fileUrl = _b[0], setFileUrl = _b[1];
    var _c = useState(), fileName = _c[0], setFileName = _c[1];
    var request = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (type) {
                case DownloadLists.SIDERecipes: {
                    return [2 /*return*/, apiClient.exportSIDERecipes()];
                }
                case DownloadLists.Users: {
                    return [2 /*return*/, apiClient.exportAdminUsers()];
                }
                default: {
                    throw new Error("No request has been found for type: " + type);
                }
            }
            return [2 /*return*/];
        });
    }); };
    function download(fileNameSuffix, hideDataName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, blob, url, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, request()];
                    case 1:
                        data = (_a.sent()).data;
                        blob = new Blob([data.csv], { type: 'text/csv' });
                        url = URL.createObjectURL(blob);
                        setFileUrl(url);
                        setFileName(hideDataName ? fileNameSuffix : data.filename + " " + fileNameSuffix);
                        if (ref.current) {
                            ref.current.click();
                        }
                        else {
                            throw new Error('ref is required');
                        }
                        onDownloadSuccess();
                        URL.revokeObjectURL(url);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        Sentry.captureException(error_1);
                        onDownloadFailure();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return { download: download, fileUrl: fileUrl, ref: ref, fileName: fileName };
}
export * from './side';
export * from './medications';
