import { UpdateVideoCall } from '../models/UpdateVideoCall';

export interface ApiResource {
  id: string;
  name: string;
}

export interface ApiVideoCall {
  id: number;
  appointmentid: number | null;
  patientdateofbirth: string;
  patientid: number;
  patientidentifier: string;
  patientname: string;
  host: string;
  resources: ApiResource[];
  description: string;
  scheduleddate: string;
  scheduleddateutc: string;
  duration: number;
  allowedduration: number;
  iseditable: boolean;
  firstname?: string;
  lastname?: string;
  ispartnerinvited?: boolean;
}

export interface APIVideoCallResource {
  id: number;
  name: string;
}

export interface UpdateVideoCallResponse {
  requestData: UpdateVideoCall;
  responseData: {
    id: number;
    status: string;
  };
}

export enum VideoCallEffectsResposeType {
  LoadSuccess,
  LoadError,
  UpdateSuccess,
  UpdateError,
  DeleteSuccess,
  DeleteError,
  JoinSuccess,
  JoinError,
  InviteSuccess,
  InviteError,
  UnInviteSuccess,
  UnInviteError,
  InviteInCallSuccess,
  InviteInCallError,
  UnInviteInCallSuccess,
  UnInviteInCallError,
  NoPartnerFoundError,
  UnInviteActiveCallError,
}

export interface VideoCallEffectsCompletedResponse {
  type: VideoCallEffectsResposeType;
  data: any;
  error: any;
}

export interface JoinVideoCallResponse {
  videoCall: ApiVideoCall;
  response: {
    token: string;
    zoomToken: string;
    room: string;
    participant: {
      identity: string;
      host: string;
    };
  };
}
