import { IconButton } from '@material-ui/core';
import {
  Check as CheckIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { Chip, DropdownMenu } from '@react/components';
import { AssignClinicUsers } from '@react/lib/api/types';
import { useLocaleDateTime } from '@react/lib/date';
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useTranslations } from '@react/lib/i18n';
import { ClinicUser, Conversation } from '@react/types';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ConversationActionMenuItem } from '../types';
import {
  ChipList,
  Header,
  ReadIndicator,
  Star,
  StyledChip,
  StyledConversationCard,
} from './ConversationCard.styled';
import { FileDescription } from './FileDescription';
import { TypingIndicator } from './ui';

export interface ConversationCardProps extends Conversation {
  actions: ConversationActionMenuItem[];
  onClick: (conversation: Conversation) => void;
  onStarClick: (conversation: Conversation) => void;
  onActionClick: (
    conversation: Conversation,
    action: ConversationActionMenuItem,
  ) => void;
  selected?: boolean;
  showType?: boolean;
  userTypingMessage?: string;
  key?: number;
  assignClinicUsers: AssignClinicUsers;
}

const getClinicUserById = (
  id: number,
  users: AssignClinicUsers['clinicUsers'],
) => users && users.find((user) => user.id === id);
const getClinicUserLabel = (user?: ClinicUser) =>
  user ? `${user.firstnameinitial} ${user.lastname}` : '';

export const ConversationCard: React.FunctionComponent<ConversationCardProps> =
  ({
    actions,
    onClick,
    onStarClick,
    onActionClick,
    selected,
    showType,
    userTypingMessage,
    assignClinicUsers,
    ...conversation
  }: ConversationCardProps) => {
    const { t } = useTranslations();
    const dateTime = useLocaleDateTime();
    const features: { [key: string]: any } = useFeatures();
    const featureAssignConversation = checkFeature(
      features,
      'messagingOptions.assignConversation',
    );
    // Create a copy to reflect local changes ASAP, i.e.: toggling `isstarred`
    const [localConversation, setLocalConversation] = useState(conversation);

    const formatPatientName = ({
      patientfirstname,
      patientlastname,
      patientidentifier,
    }: Conversation) => {
      return [
        patientlastname + ', ' + patientfirstname,
        patientidentifier,
      ].join(' - ');
    };

    const formatPatientDoB = ({ patientdateofbirth }: Conversation) => {
      return dateTime.format(new Date(patientdateofbirth), 'PP', {
        utc: true,
      });
    };

    const formatCreatedAt = ({ sentdate }: Conversation) => {
      return dateTime.format(new Date(sentdate), 'P @ p');
    };

    const handleStarClick = () => {
      const updatedConversation = {
        ...conversation,
        isstarred: !localConversation.isstarred,
      };

      setLocalConversation(updatedConversation);
      onStarClick(updatedConversation);
    };

    const handleActionClick = (action: ConversationActionMenuItem) => {
      onActionClick(conversation, action);
    };

    const { isstarred } = localConversation;
    const {
      content,
      messagesubject,
      messagetype,
      patientfilename,
      patientfilesize,
      assignedclinicuserid,
    } = conversation;

    const hasType = Boolean(showType && messagetype);
    const hasStatusBadge = conversation.patientsent && !conversation.clinicread;
    const hasChips =
      hasType ||
      (Array.isArray(conversation.staff) && conversation.staff.length > 0);

    const { clinicUsers } = assignClinicUsers;
    const isAssigned = Boolean(assignedclinicuserid);
    const assignedClinicUser = assignedclinicuserid
      ? getClinicUserById(assignedclinicuserid, clinicUsers)
      : undefined;
    const assignedClinicUseLabel = getClinicUserLabel(assignedClinicUser);

    return (
      <StyledConversationCard
        aria-selected={selected}
        className={clsx('conversation-card', {
          'conversation-card__selected': selected,
          'conversation-card__highlight': hasStatusBadge,
        })}
        onClick={() => onClick(conversation)}
      >
        <Header>
          <Star
            aria-hidden={false}
            role="checkbox"
            aria-checked={isstarred}
            active={isstarred}
            onClick={handleStarClick}
          />
          <span className="patient-name">
            {formatPatientName(conversation)}
          </span>
          <span className="created-at">{formatCreatedAt(conversation)}</span>
          <DropdownMenu
            data-testid="conversation-card--actions"
            menu={actions}
            onClick={handleActionClick}
            assignClinicUsers={assignClinicUsers}
          >
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
          </DropdownMenu>
          <ReadIndicator
            role="status"
            aria-label="read status"
            read={conversation.read}
            badge={hasStatusBadge}
          >
            {!hasStatusBadge && <CheckIcon aria-checked={conversation.read} />}
          </ReadIndicator>
        </Header>
        <span className="patient-dob">{formatPatientDoB(conversation)}</span>

        {patientfilename ? (
          <FileDescription
            filename={patientfilename}
            fileSize={patientfilesize}
          />
        ) : (
          <div className="content">
            {messagesubject && <span>{messagesubject}:</span>} {content}
          </div>
        )}

        {featureAssignConversation && isAssigned && (
          <ChipList>
            <StyledChip
              key={assignedClinicUseLabel}
              yellow
              label={assignedClinicUseLabel}
              title={assignedClinicUseLabel}
            />
          </ChipList>
        )}

        {hasChips && (
          <ChipList>
            {hasType && <Chip purple label={messagetype} />}
            {conversation.staff &&
              conversation.staff.map((person) => (
                <StyledChip
                  key={person.clinicuserid}
                  green
                  label={`${person.firstnameinitial} ${person.lastname}`}
                  title={t.Messages.PatientsTeamMember}
                />
              ))}
          </ChipList>
        )}

        {userTypingMessage && (
          <TypingIndicator>{userTypingMessage}</TypingIndicator>
        )}
      </StyledConversationCard>
    );
  };
