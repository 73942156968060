import * as tslib_1 from "tslib";
import * as React from 'react';
import { createContext, useContext } from 'react';
var ApiClientContext = createContext({
    portal: null,
    s3Proxy: null,
    content: null,
});
export var ApiClientProvider = function (_a) {
    var children = _a.children, rest = tslib_1.__rest(_a, ["children"]);
    return React.createElement(ApiClientContext.Provider, { value: rest, children: children });
};
export function useApiClient() {
    return useContext(ApiClientContext);
}
