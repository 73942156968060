import styled from '@emotion/styled';

export const StyledList = styled.div`
  list-style: none;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  overflow: auto;
  max-height: 300px;

  div.listItem {
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    padding-top: ${({ theme }) => theme.spacing(2)}px;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.body2.fontSize};

    &.active {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledKey = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
`;
