import { EventEmitter, OnInit, } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ContentEntryHeader } from '../../models/content-entry';
import { ChooseContentComponent, } from '../choose-content/choose-content.component';
import { ChooseGlobalContentComponent, } from '../choose-global-content/choose-global-content.component';
import { ChooseGlobalFileContentComponent, } from '../choose-global-file-content/choose-global-file-content.component';
import { ChooseTemplateComponent } from '../choose-template/choose-template.component';
var ContentEditTitleComponent = /** @class */ (function () {
    function ContentEditTitleComponent(_controlContainer, dialog) {
        this._controlContainer = _controlContainer;
        this.dialog = dialog;
        this.parentPortalId = null;
        this.goToParent = new EventEmitter();
        this.manageChildLink = new EventEmitter();
        this.useTemplate = new EventEmitter();
        this.copyContent = new EventEmitter();
        this.copyGlobalContent = new EventEmitter();
        this.copyGlobalFileContent = new EventEmitter();
    }
    ContentEditTitleComponent.prototype.ngOnInit = function () {
        this.titleForm = this._controlContainer.control;
        if (!this.contentEditorEnabled) {
            this.titleForm.disable();
        }
    };
    ContentEditTitleComponent.prototype.selectTemplate = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ChooseTemplateComponent, {
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                templates: this.templates,
                text: this.templateText,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.useTemplate.emit({
                    template: response.template,
                    options: {
                        method: response.method,
                    },
                });
            }
        });
    };
    ContentEditTitleComponent.prototype.selectContent = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ChooseContentComponent, {
            autoFocus: false,
            closeOnNavigation: true,
            data: { languageCode: this.languageCode },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.copyContent.emit(response.id);
            }
        });
    };
    ContentEditTitleComponent.prototype.selectGlobalFileContent = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ChooseGlobalFileContentComponent, {
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                globalPortalId: this.parentPortalId,
                languageCode: this.languageCode,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.copyGlobalFileContent.emit(response.id);
            }
        });
    };
    ContentEditTitleComponent.prototype.selectGlobalContent = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ChooseGlobalContentComponent, {
            autoFocus: false,
            closeOnNavigation: true,
            data: {
                globalPortalId: this.parentPortalId,
                languageCode: this.languageCode,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.copyGlobalContent.emit(response.id);
            }
        });
    };
    ContentEditTitleComponent.prototype.clearTitle = function () {
        this.titleForm.patchValue({
            title: '',
        });
    };
    ContentEditTitleComponent.prototype.clearSubtitle = function () {
        this.titleForm.patchValue({
            subtitle: '',
        });
    };
    ContentEditTitleComponent.prototype.clearPatientTitle = function () {
        this.titleForm.patchValue({
            patientTitle: '',
        });
    };
    ContentEditTitleComponent.prototype.copyTitleFromEMR = function () {
        var title = this.titleForm.get('title').value;
        this.titleForm.patchValue({
            patientTitle: title,
        });
    };
    return ContentEditTitleComponent;
}());
export { ContentEditTitleComponent };
