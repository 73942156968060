var _this = this;
import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { Button } from '@react/components';
import { Dialog as SalveDialog } from '@react/components/Dialog';
import { Loading } from '@react/components/Loading';
import { useApiClient } from '@react/lib/api';
import { useErrorToast, useSuccessToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect, useState } from 'react';
import PhotoPicker from './PhotoPicker';
export var ProfilePhotoModalComponent = function (_a) {
    var _b = _a.dialogWrapper, dialogWrapper = _b === void 0 ? true : _b, fileUploadService = _a.fileUploadService, onCancelled = _a.onCancelled, onConfirmed = _a.onConfirmed, open = _a.open;
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), isEditable = _d[0], setIsEditable = _d[1];
    var _e = useState(null), image = _e[0], setImage = _e[1];
    var _f = useState(false), isDeleteConfirmationOpen = _f[0], setIsDeleteConfirmationOpen = _f[1];
    var _g = useState(false), isClosingConfirmationOpen = _g[0], setIsClosingConfirmationOpen = _g[1];
    var _h = useState(false), hasChanged = _h[0], setHasChanged = _h[1];
    var _j = useState(null), errorToast = _j[0], setErrorToast = _j[1];
    var _k = useState(null), successToast = _k[0], setSuccessToast = _k[1];
    var t = useTranslations().t;
    var client = useApiClient().portal;
    var _l = useState(null), editor = _l[0], setEditor = _l[1];
    useErrorToast(!!errorToast, {
        title: errorToast ? errorToast.title : '',
        message: errorToast ? errorToast.message : '',
    });
    useSuccessToast(!!successToast, {
        title: successToast ? successToast.title : '',
        message: successToast ? successToast.message : '',
    });
    var wrapperProps = {
        open: open,
        dialogWrapper: dialogWrapper,
        fileUploadService: fileUploadService,
        onCancelled: onCancelled,
        onConfirmed: onConfirmed,
    };
    var handleSelectClicked = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var input;
        return tslib_1.__generator(this, function (_a) {
            input = document.createElement('input');
            input.type = 'file';
            input.accept = '.jpg, .jpeg, .png';
            input.onchange = function (e) {
                if (e == null) {
                    return;
                }
                var target = e.target;
                if (target.files && target.files.length) {
                    var file = target.files[0];
                    setHasChanged(true);
                    setIsEditable(true);
                    setImage(file);
                }
            };
            input.click();
            return [2 /*return*/];
        });
    }); };
    var writeFileToBucket = function (file) {
        var filename = image.name;
        return new Promise(function (res, rej) {
            fileUploadService
                .writeFileToBucket('profilephoto', filename, file, false)
                .subscribe(function (r) {
                if (r.complete && (r.body || r.error)) {
                    if (r.body && !(r.error instanceof Error)) {
                        res(r.body.Location);
                    }
                    else if (r.error instanceof Error) {
                        rej(r.error);
                    }
                    else {
                        rej(new Error("Unexpected response from file service"));
                    }
                }
            }, function (e) {
                rej(e);
            });
        });
    };
    var getEditedImageBlob = function () {
        var editorObj = editor;
        var canvas = editorObj.getImageScaledToCanvas();
        return new Promise(function (resolve) {
            canvas.toBlob(function (blob) {
                resolve(blob);
            });
        });
    };
    var handleSaveClicked = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var blob, result, _a;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!editor) {
                        setErrorToast({
                            title: t.ProfilePhotoModal.ErrorToast.UpdateTitle,
                            message: t.ProfilePhotoModal.ErrorToast.GenericDescription,
                        });
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, getEditedImageBlob()];
                case 2:
                    blob = _b.sent();
                    return [4 /*yield*/, writeFileToBucket(blob)];
                case 3:
                    result = _b.sent();
                    if (!result) {
                        throw new Error('File url was not returned');
                    }
                    return [4 /*yield*/, client.updateStaff(result)];
                case 4:
                    _b.sent();
                    setLoading(true);
                    setSuccessToast({
                        title: t.ProfilePhotoModal.SuccessToast.UpdatedTitle,
                        message: t.ProfilePhotoModal.SuccessToast.UpdatedDescription,
                    });
                    onConfirmed();
                    return [3 /*break*/, 6];
                case 5:
                    _a = _b.sent();
                    setErrorToast({
                        title: t.ProfilePhotoModal.ErrorToast.UpdateTitle,
                        message: t.ProfilePhotoModal.ErrorToast.GenericDescription,
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteClicked = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var _a;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, client.updateStaff(null)];
                case 1:
                    _b.sent();
                    setLoading(true);
                    setIsDeleteConfirmationOpen(false);
                    setSuccessToast({
                        title: t.ProfilePhotoModal.SuccessToast.DeletedTitle,
                        message: t.ProfilePhotoModal.SuccessToast.DeletedDescription,
                    });
                    onConfirmed();
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    setErrorToast({
                        title: t.ProfilePhotoModal.ErrorToast.UpdateTitle,
                        message: t.ProfilePhotoModal.ErrorToast.GenericDescription,
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseClicked = function () {
        onCancelled();
    };
    useEffect(function () {
        client
            .fetchStaffMe()
            .then(function (staffResult) {
            var profilePhotoUri = null;
            if (staffResult.data) {
                profilePhotoUri = staffResult.data.profilephotouri;
            }
            if (!profilePhotoUri) {
                setImage(null);
                setLoading(false);
                return;
            }
            fileUploadService
                .readProfilePhotoFromBucket({
                url: profilePhotoUri,
            })
                .then(function (fileResult) {
                setImage(fileResult.url);
                setIsEditable(false);
                setLoading(false);
            });
        })
            .catch(function (err) {
            setErrorToast(err);
        });
    }, []);
    useEffect(function () {
        // This lets the error toast re-show when there are repeated errors,
        // e.g. if the user retries an action such as delete after a network failure.
        if (errorToast) {
            setErrorToast(null);
        }
    }, [setErrorToast]);
    return (React.createElement(Wrapper, tslib_1.__assign({}, wrapperProps),
        React.createElement(StyledContainer, null,
            React.createElement(Typography, { className: "profilePhotoModal-title", variant: "h6" }, t.ProfilePhotoModal.Title),
            !loading && (React.createElement(Typography, { className: "profilePhotoModal-title", variant: "subtitle1" }, t.ProfilePhotoModal.SubTitle)),
            React.createElement(IconButton, { className: "profilePhotoModal-close", onClick: function () { return setIsClosingConfirmationOpen(true); }, size: "small", role: "button" },
                React.createElement(IconClose, { color: "primary" })),
            loading && (React.createElement(Grid, { container: true, justifyContent: "center" },
                React.createElement(Loading, null),
                React.createElement(Typography, { style: { marginTop: 20 } }, t.ProfilePhotoModal.Loading))),
            !loading && (React.createElement(Grid, { container: true },
                React.createElement(Grid, { container: true, alignItems: "center", direction: "column" },
                    React.createElement(Grid, { item: true, style: { margin: '30px 0' } },
                        React.createElement(PhotoPicker, { image: image, isEditable: isEditable, onHasChanged: function (val) { return setHasChanged; }, onSelectClicked: handleSelectClicked, setEditor: setEditor })),
                    React.createElement(Button, { "aria-label": "change", onClick: handleSelectClicked, color: "default", size: "small", variant: "contained" }, isEditable
                        ? t.ProfilePhotoModal.SelectPhoto
                        : t.ProfilePhotoModal.ChangePhoto)),
                React.createElement(Grid, { container: true, justifyContent: "space-between", direction: "row-reverse" },
                    React.createElement(Button, { "aria-label": "save", onClick: handleSaveClicked, color: "primary", size: "medium", variant: "contained", disabled: !hasChanged }, t.ProfilePhotoModal.Save),
                    !isEditable && (React.createElement(Button, { "aria-label": "delete", onClick: function () { return setIsDeleteConfirmationOpen(true); }, color: "secondary", size: "medium", variant: "contained" }, t.ProfilePhotoModal.Delete))))),
            React.createElement(SalveDialog, { acceptButtonText: t.ProfilePhotoModal.DeleteConfirmation.Delete, cancelButtonText: t.ProfilePhotoModal.DeleteConfirmation.Cancel, cancelButtonColour: "default", contentText: t.ProfilePhotoModal.DeleteConfirmation.Content, isOpen: isDeleteConfirmationOpen, onAccept: handleDeleteClicked, onCancel: function () { return setIsDeleteConfirmationOpen(false); }, titleText: t.ProfilePhotoModal.DeleteConfirmation.Title }),
            React.createElement(SalveDialog, { acceptButtonText: t.ProfilePhotoModal.CloseConfirmation.Close, cancelButtonText: t.ProfilePhotoModal.CloseConfirmation.Cancel, cancelButtonColour: "default", contentText: t.ProfilePhotoModal.CloseConfirmation.Content, isOpen: isClosingConfirmationOpen, onAccept: handleCloseClicked, onCancel: function () { return setIsClosingConfirmationOpen(false); }, titleText: t.ProfilePhotoModal.CloseConfirmation.Title }))));
};
var Wrapper = function (_a) {
    var children = _a.children, dialogWrapper = _a.dialogWrapper, fileUploadService = _a.fileUploadService, onCancelled = _a.onCancelled, onConfirmed = _a.onConfirmed, open = _a.open;
    if (!dialogWrapper) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(Dialog, { open: open, onClose: onCancelled }, children));
};
var ɵ0 = Wrapper;
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  box-sizing: border-box;\n  width: 600px;\n  padding: 20px;\n  position: relative;\n\n  .profilePhotoModal-title {\n    text-align: center;\n    margin-bottom: 16px;\n  }\n\n  .profilePhotoModal-close {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n  }\n\n  .newMessageModal-sendButton {\n    display: flex;\n    justify-content: flex-end;\n  }\n"], ["\n  box-sizing: border-box;\n  width: 600px;\n  padding: 20px;\n  position: relative;\n\n  .profilePhotoModal-title {\n    text-align: center;\n    margin-bottom: 16px;\n  }\n\n  .profilePhotoModal-close {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n  }\n\n  .newMessageModal-sendButton {\n    display: flex;\n    justify-content: flex-end;\n  }\n"])));
export { ɵ0 };
var templateObject_1;
