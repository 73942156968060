import { select, Store } from '@ngrx/store';
import * as fromDashboard from '../../../dashboard/reducers';
import * as fromSettings from '../../../settings/reducers';
var IntegrationStatusContainer = /** @class */ (function () {
    function IntegrationStatusContainer(_store) {
        this._store = _store;
        this.text$ = this._store.pipe(select(fromSettings.getSectionTranslations('ClinicStatus')));
        this.isIntegrated$ = this._store.pipe(select(fromDashboard.clinicStatusIntegrated));
        this.isSynchronised$ = this._store.pipe(select(fromDashboard.clinicStatusSynchronised));
    }
    return IntegrationStatusContainer;
}());
export { IntegrationStatusContainer };
