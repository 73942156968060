import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromClinicStatus from './clinic-status.reducer';
import * as fromDashboard from './dashboard.reducer';
import * as fromStatus from './status.reducer';

// Interfaces
export interface DashboardState {
  dashboardData: fromDashboard.State;
  status: fromStatus.State;
  clinicStatus: fromClinicStatus.State;
}

export interface State extends fromRoot.State {
  dashboard: DashboardState;
}

// Reducer Definition
export const reducers: ActionReducerMap<DashboardState> = {
  dashboardData: fromDashboard.reducer,
  status: fromStatus.reducer,
  clinicStatus: fromClinicStatus.reducer,
};

// Selectors
export const selectDashboardState =
  createFeatureSelector<DashboardState>('dashboard');

export const selectDashboardDataState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.dashboardData,
);

export const getDashboardSummary = createSelector(
  selectDashboardDataState,
  fromDashboard.getSummary,
);

export const getClinicStatus = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.clinicStatus,
);

export const getDashboardMedications = createSelector(
  selectDashboardDataState,
  fromDashboard.getMedications,
);

export const getDashboardNonTimedTriggerMedications = createSelector(
  selectDashboardDataState,
  fromDashboard.getNonTimedTriggerMedications,
);

export const selectDashboardStatusState = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.status,
);

export const isDashboardSummaryLoading = createSelector(
  selectDashboardStatusState,
  fromStatus.summaryIsLoading,
);

export const areDashboardMedicationsLoading = createSelector(
  selectDashboardStatusState,
  fromStatus.medicationsAreLoading,
);

export const clinicStatusIntegrated = createSelector(
  getClinicStatus,
  (state: fromClinicStatus.State) => state.status.integrated,
);

export const clinicStatusSynchronised = createSelector(
  getClinicStatus,
  (state: fromClinicStatus.State) => state.status.synchronised,
);
