import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Quill } from 'quill';
import { take } from 'rxjs/operators';
import { ConfirmActionComponent } from 'src/app/core/components/confirm-action/confirm-action.component';
import { Md5HashService } from 'src/app/core/services/md5-hash.service';
import { FileUpload } from 'src/app/core/services/s3.service';
import { AddFileComponent } from '../../../core/components/add-file/add-file.component';
import { ContentEntrySection } from '../../models/content-entry';
import { FileInStorage } from '../../models/file-in-storage';
import { AddVideoComponent } from '../add-video/add-video.component';
import { ContentSectionErrorState } from './../../utils/content-section-error-state';

@Component({
  selector: 'portal-content-edit-sections',
  templateUrl: './content-edit-sections.component.html',
  styleUrls: ['./content-edit-sections.component.scss'],
})
export class ContentEditSectionsComponent implements OnInit {
  @Input() text: any;
  @Input() videoText: any;
  @Input() imageText: any;
  @Input() contentEditorEnabled: boolean;
  @Output() addSection = new EventEmitter();
  @Output() removeSection = new EventEmitter<number>();
  @Output() setOrder = new EventEmitter<ContentEntrySection[]>();

  public quillSectionsForm: FormGroup;
  public matcher = new ContentSectionErrorState();

  public editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }],
      ['link', 'image', 'video'],
    ],
  };

  get sections(): FormArray {
    return this.quillSectionsForm.get('sections') as FormArray;
  }

  get attachments(): FormArray {
    return this.quillSectionsForm.get('attachments') as FormArray;
  }

  constructor(
    private _controlContainer: ControlContainer,
    public dialog: MatDialog,
    private _MD5: Md5HashService,
    private _S3: FileUpload,
  ) {}

  ngOnInit() {
    this.quillSectionsForm = this._controlContainer.control as FormGroup;
    if (!this.contentEditorEnabled) {
      this.editorConfig = {
        toolbar: [],
      };
    }
  }

  public setFocus(event) {
    event.target.focus();
  }

  public editorCreated(quill: Quill): void {
    const editorInstance = quill;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this._handleImage.bind(this, editorInstance));
    toolbar.addHandler('video', this._handleVideo.bind(this, editorInstance));
  }

  public dropped() {
    const entriesCopy = [];
    for (let i = 0; i < this.sections.controls.length; i++) {
      const entry = this.sections.controls[i];
      const entryVal = {
        ...entry.value,
        ordernumber: i + 5,
      };
      entriesCopy.push(entryVal);
    }
    this.sections.reset(entriesCopy);
  }

  public handleRemoveSection(section: FormGroup, id: number) {
    const isEmpty =
      section.value.title.length === 0 && section.value.content.length === 0;
    if (!isEmpty) {
      const confirmDialog = this.dialog.open(ConfirmActionComponent, {
        data: {
          message: this.text.ConfirmDelete,
          text: {
            Cancel: this.text.Cancel,
            Confirm: this.text.Confirm,
          },
        },
      });
      confirmDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((val) => {
          if (val) {
            this.removeSection.emit(id);
          }
        });
    } else {
      this.removeSection.emit(id);
    }
  }

  private _openVideoDialog(): Promise<string> {
    return new Promise((res, rej) => {
      const validAccepts = this._S3.fetchAccepts('video');
      const fileName = this._MD5.generateFileName();
      const videoDialog = this.dialog.open(AddVideoComponent, {
        data: {
          accept: validAccepts,
          text: this.videoText,
          fileName,
          type: 'video',
          public: true,
          patientOnly: false,
          patientId: null,
        },
      });

      videoDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((file: { html: string }) => {
          if (file && file.html) {
            res(file.html);
          }
        });
    });
  }

  private _openImageDialog(): Promise<FileInStorage> {
    return new Promise((res, rej) => {
      const validAccepts = this._S3.fetchAccepts('image');
      const fileName = this._MD5.generateFileName();
      const fileDialog = this.dialog.open(AddFileComponent, {
        data: {
          accept: validAccepts,
          text: this.imageText,
          fileName,
          type: 'image',
          public: true,
          patientOnly: false,
          patientId: null,
        },
      });

      fileDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((file: { attachment: FileInStorage }) => {
          if (file && file.attachment) {
            res(file.attachment);
          } else {
            res(null);
          }
        });
    });
  }

  private async _handleImage(editor: Quill) {
    const range = editor.getSelection(true);
    const attachment = await this._openImageDialog();
    if (attachment) {
      editor.insertEmbed(range.index, 'image', attachment.uri, 'user');
    }
  }

  private async _handleVideo(editor: Quill) {
    const range = editor.getSelection();
    const html = await this._openVideoDialog();
    if (html) {
      editor.clipboard.dangerouslyPasteHTML(range.index, html, 'user');
    }
  }
}
