import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ServerError } from '../../models/Error';
import * as fromSettings from '../../settings/reducers';
import { AddMessageToThread, LoadNewestMessages, } from '../actions/load-message.actions';
import { SetSelectedTab } from '../actions/message-ui.actions';
import { NewMessageActionTypes, SendNewMessageError, SendNewMessageSuccess, ShowRerouteToast, } from '../actions/new-message.actions';
import * as fromMessages from '../reducers';
import { MessageService } from '../services/message.service';
var NewMessageEffects = /** @class */ (function () {
    function NewMessageEffects(actions$, toastr, messageService, _store) {
        var _this = this;
        this.actions$ = actions$;
        this.toastr = toastr;
        this.messageService = messageService;
        this._store = _store;
        this._store
            .pipe(select(fromSettings.getSectionTranslations('MessageToast')))
            .subscribe(function (val) {
            _this._toastText = val;
        });
    }
    // Send New Message
    NewMessageEffects.prototype.sendNewMessage$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(NewMessageActionTypes.SendNewMessage), switchMap(function (action) {
            return _this.messageService
                .addMessageToThread(action.payload.newMessage)
                .pipe(mergeMap(function (response) {
                var result = tslib_1.__assign({}, action.payload, { message: tslib_1.__assign({}, action.payload.message, { MessageItemId: response }) });
                return [
                    new AddMessageToThread(result),
                    new SendNewMessageSuccess({
                        mesageStarringEnabled: action.payload.messageStarringEnabled,
                    }),
                ];
            }), catchError(function () {
                _this.toastr.show(_this._toastText.ErrorSending(action.payload.message.PatientFirstName +
                    ' ' +
                    action.payload.message.PatientLastName), _this._toastText.ErrorEncountered, {
                    timeOut: 0,
                    closeButton: true,
                    toastClass: "toast custom-toast error",
                });
                return [
                    new SendNewMessageError(new ServerError('[NewMessage]', 'Error sending new message', action.type)),
                ];
            }));
        }));
    };
    // Create new Thread (outbound messaging)
    NewMessageEffects.prototype.sendNewThread$ = function () {
        var _this = this;
        return this.actions$.pipe(ofType(NewMessageActionTypes.SendNewThread), withLatestFrom(this._store.pipe(select(fromMessages.getCurrentTab))), mergeMap(function (_a) {
            var action = _a[0], tab = _a[1];
            var _b = action.payload.data, newMessage = _b.newMessage, messageInfo = _b.messageInfo;
            var patientName = newMessage.patientName, rest = tslib_1.__rest(newMessage, ["patientName"]);
            var toast = action.payload.toast;
            return _this.messageService.createNewThread(rest).pipe(mergeMap(function (newThread) {
                return [
                    new ShowRerouteToast({
                        toast: toast.success,
                        route: '/messages',
                        other: tslib_1.__assign({}, rest, newMessage, messageInfo, { id: newThread.NewMessageId }),
                        afterAction: new SetSelectedTab({
                            tab: tab,
                            params: {
                                MessageTypeId: newMessage.MessageTypeId,
                                FetchAfterMessageItemId: null,
                                FetchBeforeMessageItemId: null,
                            },
                        }),
                    }),
                    new SendNewMessageSuccess({
                        mesageStarringEnabled: action.payload.messageStarringEnabled,
                    }),
                ];
            }), catchError(function (err) {
                return [
                    new SendNewMessageError(new ServerError('[NewMessage]', 'Error sending new message to new conversation', action.type)),
                    new ShowRerouteToast({
                        toast: toast.error,
                    }),
                ];
            }));
        }));
    };
    NewMessageEffects.prototype.sendNewMessageSuccess = function () {
        return this.actions$.pipe(ofType(NewMessageActionTypes.SendNewMessageSuccess), withLatestFrom(this._store.pipe(select(fromMessages.getMessageList)), this._store.pipe(select(fromMessages.getNextPageToLoad))), switchMap(function (_a) {
            var action = _a[0], messages = _a[1], nextPageToLoad = _a[2];
            var latestMessageItemId = messages.length > 0 ? messages[0].MessageItemId : 0;
            return [
                new LoadNewestMessages({
                    fetchParams: tslib_1.__assign({}, nextPageToLoad, { FetchBeforeMessageItemId: null, FetchAfterMessageItemId: latestMessageItemId }),
                    showPushNotification: false,
                }),
            ];
        }));
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], NewMessageEffects.prototype, "sendNewMessage$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], NewMessageEffects.prototype, "sendNewThread$", null);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], NewMessageEffects.prototype, "sendNewMessageSuccess", null);
    return NewMessageEffects;
}());
export { NewMessageEffects };
