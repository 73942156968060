import { Action } from '@ngrx/store';
import { ClinicMedicationResponse } from '../models/responses/clinic-medication.response';
import { ClinicStatusResponse } from '../models/responses/clinic-status.response';
import { ClinicSummaryResponse } from '../models/responses/clinic-summary.response';

export enum DashboardActionTypes {
  // Server Side

  LoadClinicStatus = '[Dashboard] Load Clinic Status',
  LoadClinicStatusSuccess = '[Dashboard] Load Clinic Status Success',
  LoadClinicStatusError = '[Dashboard] Load Clinic Status Error',

  LoadClinicSummary = '[Dashboard] Load Clinic Summary',
  LoadClinicSummarySuccess = '[Dashboard] Load Clinic Summary Success',
  LoadClinicSummaryError = '[Dashboard] Load Clinic Summary Error',

  LoadClinicMedication = '[Dashboard] Load Clinic Medication',
  LoadClinicMedicationSuccess = '[Dashboard] Load Clinic Medication Success',
  LoadClinicMedicationError = '[Dashboard] Load Clinic Medication Error',

  LoadClinicNonTimedTriggerMedication = '[Dashboard] Load Clinic Non Timed Trigger Medication',
  LoadClinicNonTimedTriggerMedicationSuccess = '[Dashboard] Load Clinic Non Timed Trigger Medication Success',
  LoadClinicNonTimedTriggerMedicationError = '[Dashboard] Load Clinic Non Timed Trigger Medication Error',

  // Client Side
  RefreshClinicDashboard = '[Dashboard] Refresh Clinic Dashboard',
  RefreshClinicStatus = '[Dashboard] Refresh Clinic Status',

  LoadingClinicMessagingSummary = '[Dashboard] Loading Clinic Messaging Summary',
  LoadedClinicMessagingSummary = '[Dashboard] Loaded Clinic Messaging Summary',

  LoadingClinicSummary = '[Dashboard] Loading Clinic Summary',
  LoadedClinicSummary = '[Dashboard] Loaded Clinic Summary',

  LoadingClinicMedication = '[Dashboard] Loading Clinic Medication',
  LoadedClinicMedication = '[Dashboard] Loaded Clinic Medication',

  LoadingClinicNonTimedTriggerMedication = '[Dashboard] Loading Clinic Non Timed Trigger Medication',
  LoadedClinicNonTimedTriggerMedication = '[Dashboard] Loaded Clinic Non Timed Trigger Medication',
}

// Action Creators
// ==================================================

// Server Side
export class LoadClinicStatus implements Action {
  readonly type = DashboardActionTypes.LoadClinicStatus;
}

export class LoadClinicStatusSuccess implements Action {
  readonly type = DashboardActionTypes.LoadClinicStatusSuccess;

  constructor(public payload: ClinicStatusResponse) {}
}

export class LoadClinicStatusError implements Action {
  readonly type = DashboardActionTypes.LoadClinicStatusError;

  constructor(public payload: Error) {}
}

export class LoadClinicSummary implements Action {
  readonly type = DashboardActionTypes.LoadClinicSummary;
}

export class LoadClinicSummarySuccess implements Action {
  readonly type = DashboardActionTypes.LoadClinicSummarySuccess;

  constructor(public payload: ClinicSummaryResponse) {}
}
export class LoadClinicSummaryError implements Action {
  readonly type = DashboardActionTypes.LoadClinicSummaryError;

  constructor(public payload: Error) {}
}

export class LoadClinicMedication implements Action {
  readonly type = DashboardActionTypes.LoadClinicMedication;
}
export class LoadClinicMedicationSuccess implements Action {
  readonly type = DashboardActionTypes.LoadClinicMedicationSuccess;

  constructor(public payload: ClinicMedicationResponse) {}
}
export class LoadClinicMedicationError implements Action {
  readonly type = DashboardActionTypes.LoadClinicMedicationError;

  constructor(public payload: Error) {}
}

export class LoadClinicNonTimedTriggerMedication implements Action {
  readonly type = DashboardActionTypes.LoadClinicNonTimedTriggerMedication;
}
export class LoadClinicNonTimedTriggerMedicationSuccess implements Action {
  readonly type =
    DashboardActionTypes.LoadClinicNonTimedTriggerMedicationSuccess;

  constructor(public payload: ClinicMedicationResponse) {}
}
export class LoadClinicNonTimedTriggerMedicationError implements Action {
  readonly type = DashboardActionTypes.LoadClinicNonTimedTriggerMedicationError;

  constructor(public payload: Error) {}
}
export class RefreshClinicStatus implements Action {
  readonly type = DashboardActionTypes.RefreshClinicStatus;
}

// Client Side
export class RefreshClinicDashboard implements Action {
  readonly type = DashboardActionTypes.RefreshClinicDashboard;
}

export class LoadingClinicSummary implements Action {
  readonly type = DashboardActionTypes.LoadingClinicSummary;
}
export class LoadedClinicSummary implements Action {
  readonly type = DashboardActionTypes.LoadedClinicSummary;
}

export class LoadingClinicMessagingSummary implements Action {
  readonly type = DashboardActionTypes.LoadingClinicMessagingSummary;
}
export class LoadedClinicMessagingSummary implements Action {
  readonly type = DashboardActionTypes.LoadedClinicMessagingSummary;
}

export class LoadingClinicMedication implements Action {
  readonly type = DashboardActionTypes.LoadingClinicMedication;
}
export class LoadedClinicMedication implements Action {
  readonly type = DashboardActionTypes.LoadedClinicMedication;
}

export class LoadingClinicNonTimedTriggerMedication implements Action {
  readonly type = DashboardActionTypes.LoadingClinicNonTimedTriggerMedication;
}
export class LoadedClinicNonTimedTriggerMedication implements Action {
  readonly type = DashboardActionTypes.LoadedClinicNonTimedTriggerMedication;
}

// Dashboard Actions Union
// ==================================================
export type DashboardActionsUnion =
  // Server Side
  | LoadClinicStatus
  | LoadClinicStatusSuccess
  | LoadClinicStatusError
  | LoadClinicSummary
  | LoadClinicSummarySuccess
  | LoadClinicSummaryError
  | LoadClinicMedication
  | LoadClinicMedicationSuccess
  | LoadClinicMedicationError
  | LoadClinicNonTimedTriggerMedication
  | LoadClinicNonTimedTriggerMedicationSuccess
  | LoadClinicNonTimedTriggerMedicationError
  // Client Side
  | RefreshClinicStatus
  | RefreshClinicDashboard
  | LoadingClinicSummary
  | LoadedClinicSummary
  | LoadingClinicMessagingSummary
  | LoadedClinicMessagingSummary
  | LoadingClinicMedication
  | LoadedClinicMedication
  | LoadingClinicNonTimedTriggerMedication
  | LoadedClinicNonTimedTriggerMedication;
