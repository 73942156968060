import * as tslib_1 from "tslib";
import { ErrorHandler, EventEmitter, OnInit, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { PatientSearchBase } from 'src/app/patients/PatientSearchBase';
import { PatientService } from 'src/app/patients/services/patient.service';
var SearchbarComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SearchbarComponent, _super);
    function SearchbarComponent(_patientService, _localisationService, _errorHandler) {
        var _this = _super.call(this, _patientService, _localisationService) || this;
        _this._errorHandler = _errorHandler;
        _this.gotoPatient = new EventEmitter();
        _this.patientControl = new FormControl();
        return _this;
    }
    SearchbarComponent.prototype.ngOnInit = function () {
        this.setupSearchObservables(this._errorHandler);
        this.observeSearchInput(this.patientControl);
    };
    SearchbarComponent.prototype.patientSelected = function (patient) {
        if (patient && patient.Id) {
            this.gotoPatient.emit(patient.Id);
            this.clearSearch();
        }
    };
    SearchbarComponent.prototype.clearSearch = function () {
        this.patientControl.patchValue('');
    };
    return SearchbarComponent;
}(PatientSearchBase));
export { SearchbarComponent };
