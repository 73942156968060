<div class="new-message-modal" fxLayout="column" fxLayoutAlign="start stretch">
  <!-- Close button -->
  <div fxLayout="row" fxLayoutAlign="end start">
    <button mat-icon-button (click)="closeDialog()" color="primary">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- form -->
  <form
    fxFlex="grow"
    class="message-form"
    novalidate
    (ngSubmit)="saveNewMessage()"
    [formGroup]="newMessageForm"
  >
    <div fxLayout="column" fxLayoutAlign="start stretch" class="form-layout">
      <!-- To -->
      <div
        class="nm-input form-group patient"
        [class.has-error]="
          (newMessageForm.get('patientName').touched ||
            newMessageForm.get('patientName').dirty) &&
          !newMessageForm.get('patientName').valid
        "
      >
        <div class="nm-to form-group" fxLayout="row">
          <label for="patientName" class="field-label">{{
            newMessageText.To
          }}</label>
          <input
            class="form-control nm-entry"
            #patientName
            id="patientName"
            formControlName="patientName"
            type="text"
            placeholder="{{ newMessageText.Patient }}"
            fxFlex="grow"
            [matAutocomplete]="patientAuto"
            cdkFocusInitial
          />
          <mat-autocomplete
            #patientAuto="matAutocomplete"
            [displayWith]="transformPatientToString"
          >
            <mat-option
              *ngFor="let patient of patients$ | async"
              [value]="patient"
            >
              {{ transformPatientToString(patient) }}
            </mat-option>
            <div
              class="spinner-container"
              *ngIf="isLoading$ | async"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="20px"
            >
              <mat-spinner diameter="40"></mat-spinner>
            </div>
            <div
              style="height: 1px; margin: 0; padding: 0"
              intersection-observer
              [observeFirstChildElement]="false"
              observeMargin="200px"
              (observed)="triggerNextPage()"
            ></div>
          </mat-autocomplete>
        </div>
        <div
          class="error"
          *ngIf="
            (newMessageForm.get('patientName').touched ||
              newMessageForm.get('patientName').dirty) &&
            !newMessageForm.get('patientName').valid
          "
        >
          <mat-error
            *ngIf="newMessageForm.get('patientName').hasError('notPatient')"
          >
            {{ newMessageText.PleaseSelect }}
          </mat-error>
          <!-- NEED TRANSLATION HERE -->
          <mat-error
            *ngIf="newMessageForm.get('patientName').hasError('required')"
          >
            {{ newMessageText.PatientRequired }}
          </mat-error>
          <!-- NEED TRANSLATION HERE -->
          <mat-error
            *ngIf="newMessageForm.get('patientName').hasError('serverError')"
          >
            {{ newMessageText.ServerError }}
          </mat-error>
        </div>
      </div>

      <!-- From -->
      <div
        class="nm-input type"
        [class.has-error]="
          newMessageForm.get('messageType').touched &&
          !newMessageForm.get('messageType').valid
        "
      >
        <div class="nm-type form-group" fxLayout="row">
          <label for="messageType" class="field-label">{{
            newMessageText.Type
          }}</label>
          <mat-select
            class="form-control nm-entry"
            id="messageType"
            formControlName="messageType"
            fxFlex="grow"
            placeholder="{{ newMessageText.ChooseMessageType }}"
            (selectionChange)="setMessageTypeId($event)"
          >
            <mat-option
              *ngFor="let label of labels$ | async"
              [value]="label.messageTypeId"
              >{{ label.displayName }}
            </mat-option>
          </mat-select>
        </div>
        <div
          class="error"
          *ngIf="
            newMessageForm.get('messageType').touched &&
            !newMessageForm.get('messageType').valid
          "
        >
          <mat-error
            *ngIf="newMessageForm.get('messageType').hasError('required')"
          >
            {{ newMessageText.MessageLabelRequired }}
          </mat-error>
        </div>
      </div>

      <!-- Subject -->
      <div class="nm-input content" [class.has-error]="getSubjectValid()">
        <div class="nm-type form-group" fxLayout="row">
          <label for="subject" class="field-label">{{
            newMessageText.Subject
          }}</label>
          <input
            type="text"
            placeholder="{{ newMessageText.SubjectPlaceholder }}"
            fxFlex="grow"
            id="subject"
            class="form-control nm-entry"
            formControlName="subject"
          />
        </div>
        <div class="error" *ngIf="getSubjectValid()">
          <mat-error>
            {{ newMessageText.SubjectMaxLengthError }}
          </mat-error>
        </div>
      </div>

      <!-- message body -->
      <portal-message-editor
        class="message-editor"
        [maxLength]="2000"
        [patientId]="newMessageForm.get('patientName').value?.Id"
        [value]="currentText$ | async"
        (valueChange)="currentTextChange($event)"
      ></portal-message-editor>

      <!-- controls -->
      <div class="controls">
        <div fxLayout="row" fxLayoutAlign="end center">
          <!--
            // Attach button to be added when attachments 
            // feature is enabled
            <button
              mat-raised-button
              type="button"
              class="btn btn-md btn-outline"
              disabled
            >
              <ion-icon name="attach" style="transform: rotate(45deg);"></ion-icon>
              Attach
            </button>
          -->
          <button
            mat-raised-button
            type="submit"
            class="portal-btn btn-bright"
            [disabled]="newMessageForm.status === 'INVALID'"
          >
            {{ newMessageText.Send }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
