import styled from '@emotion/styled';
import { FormControl, Radio, Typography } from '@material-ui/core';

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const AppointmentTypeTitle = styled(Typography)`
  color: #505050;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledFormControl = styled(FormControl)`
  top: -10px;
`;

export const StyledFormControlLabel = styled(Typography)`
  color: #505050;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const StyledRadio = styled(Radio)`
  color: #505050;

  &.Mui-checked {
    color: #505050;
  }
`;

export const ProvisionalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProvisionalHoursContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding-left: ${({ theme }) => theme.spacing(3.5)}px;
`;

export const ProvisionalInput = styled.input`
  max-width: 60px;
`;

export const FormActions = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)}px 0;
  justify-content: flex-end;
`;
