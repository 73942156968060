import { IconButton, InputAdornment } from '@material-ui/core';
import { Tune as IconTune } from '@material-ui/icons';
import {
  Button,
  Empty,
  Loading,
  MailOpenIcon,
  SearchBar,
} from '@react/components';
import { AssignClinicUsers, StaffProfiles } from '@react/lib/api/types';
import { useRootConfig } from '@react/lib/context/rootConfig';
import { useErrorToast, useQuery } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import { Conversation, OnClickClinicUserValue } from '@react/types';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import qs from 'qs-lite';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { IFileUpload } from 'src/app/core/services/s3.service';
import {
  ActionContainer,
  Chat,
  ChatProps,
  Conversations,
  DiscardConversationPrompt,
  FilterChips,
  FilterMenu,
  FilterMenuPopoverState,
  Folders,
  LeftPanel,
  Main,
  MiddlePanel,
  NewMessageModal,
  NewMessageModalProps,
  PageContainer,
  RightPanel,
  StyledRoot,
} from './components';
import {
  IConversationCache,
  useConversationCache,
  useConversationPartialUpdateMutation,
  useFetchAllClinicUsers,
  useFetchConversationById,
  useFetchMe,
  useFoldersAssignedQuery,
  useFoldersListQuery,
  useRealTimeMessaging,
} from './hooks';
import { filtersApplied } from './lib';
import { FilterState } from './types';

export interface ConversationPageRawProps {
  conversationCache: IConversationCache;
  fileUploadService: IFileUpload;
  staffProfiles: StaffProfiles;
}

interface State {
  folderIds: number[];
  isStarred: boolean;
  conversationId?: number;
  isResolved?: boolean;
  staffIds?: number[];
  assignedIds?: number[];
  isUnassigned?: boolean;
}

interface Query {
  [key: string]: string;
  isStarred: string;
  folderIds: string;
  isResolved: string;
  staffIds: string;
  assignedIds: string;
  isUnassigned: string;
}

function getStateFromQuery(): State {
  const query = useQuery<Query>();

  return useMemo(
    () => ({
      folderIds: query.folderIds
        ? query.folderIds.split(',').map((id) => Number(id))
        : [],
      isStarred: query.isStarred != null ? JSON.parse(query.isStarred) : true,
      isResolved:
        query.isResolved != null ? JSON.parse(query.isResolved) : undefined,
      staffIds: query.staffIds
        ? query.staffIds.split(',').map((id) => Number(id))
        : undefined,
      ...(query.conversationId != null && {
        conversationId: Number(query.conversationId),
      }),
      assignedIds: query.assignedIds
        ? query.assignedIds.split(',').map((id) => Number(id))
        : undefined,
      isUnassigned:
        query.isUnassigned != null ? JSON.parse(query.isUnassigned) : undefined,
    }),
    [query],
  );
}

export const ConversationPageRaw: FunctionComponent<ConversationPageRawProps> =
  ({ conversationCache, fileUploadService, staffProfiles }) => {
    const { t } = useTranslations();
    const history = useHistory();
    const { user } = useRootConfig();
    const filterPopoverAnchor = React.useRef<HTMLButtonElement>(null);

    const { mutateAsync: updateConversationAsync, isError: isErrorAssigning } =
      useConversationPartialUpdateMutation();

    useErrorToast(isErrorAssigning, {
      title: t.MessageToast.ErrorAssigningMessageTitle,
      message: t.MessageToast.MessageStarredBodyError,
    });

    const state = getStateFromQuery();
    const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
    const [newMessageModalOpen, setNewMessageModalOpen] = useState(false);
    const [discardConversationPromptOpen, setDiscardConversationPromptOpen] =
      useState(false);
    const [searchQuery, setSearchQuery] = useState<string>();

    const conversationResult = useFetchConversationById(state.conversationId);
    const selectedConversation = conversationResult.data;

    const { users: clinicUsers, isLoading: isLoadingClinicUsers } =
      useFetchAllClinicUsers();

    const meResult = useFetchMe();
    const me = meResult.data;

    const foldersResult = useFoldersListQuery();
    const folders = foldersResult.data && foldersResult.data.data;

    const { getInitialConversationState, setConversationState } =
      useConversationCache(conversationCache);

    const assignedFolderResult = useFoldersAssignedQuery();
    const assignedFolderCount =
      assignedFolderResult.data && assignedFolderResult.data.data;

    function setStateInURL(newState: State) {
      const pathname = newState.conversationId
        ? `/messages/${newState.conversationId}`
        : `/messages`;

      if (!isEqual(newState, state)) {
        history.push({
          pathname,
          search: `${qs.stringify(newState)}`,
        });
      }
    }

    // Default to unresolved on first load
    function setInitialState() {
      setStateInURL({
        ...state,
        isResolved: false,
      });
    }

    useEffect(setInitialState, []);

    const { getTypingConversationMessage, sendTypingEvent } =
      useRealTimeMessaging(user);

    const handleChatTyping: ChatProps['onChange'] = useCallback(
      (content) => {
        if (selectedConversation) {
          sendTypingEvent({
            content,
            messageId: selectedConversation.messageid,
            user: `${user.FirstName} ${user.LastName}`,
          });
        }
      },
      [selectedConversation],
    );

    const isError = conversationResult.isError || foldersResult.isError;

    useErrorToast(isError, {
      title: t.MessageToast.ErrorEncountered,
      message: t.MessageToast.MessageStarredBodyError,
    });

    if (foldersResult.isLoading) {
      return <Loading fullHeight />;
    }

    function handleConversationClick(conversation: Conversation) {
      setStateInURL({
        ...state,
        conversationId: conversation.messageid,
      });
    }

    function updateSearchTerm(q: string) {
      setSearchQuery(q);
    }

    function handleFolderClick(
      folderId?: number,
      isResolved?: boolean,
      assignedId?: number,
    ) {
      setStateInURL({
        ...omit(state, ['conversationId', 'assignedIds', 'isUnassigned']),
        folderIds: folderId ? [folderId] : [],
        isResolved,
        isStarred: true,
        assignedIds: assignedId ? [assignedId] : undefined,
      });
    }

    function handleTabChange(tabIndex: number) {
      setStateInURL({
        ...omit(state, ['conversationId']),
        isStarred: tabIndex === 0,
      });
    }

    function onFilterButtonClick() {
      setFilterPopoverOpen(!filterPopoverOpen);
    }

    function onFilterPopoverClose() {
      setFilterPopoverOpen(false);
    }

    function onFiltersUpdate(filterState: FilterMenuPopoverState) {
      const activeFolders = Array.from(filterState.folder).map((folderId) =>
        Number(folderId),
      );
      const activeStaff = Array.from(filterState.staff || []).map(
        (clinicUserId) => Number(clinicUserId),
      );

      const activeClinicUsers = Array.from(filterState.clinicUsers || [])
        .filter((clinicUserId) => clinicUserId !== 'unassigned')
        .map((clinicUserId) => Number(clinicUserId));

      const isUnassigned = Array.from(filterState.clinicUsers || [])
        .filter((clinicUserId) => clinicUserId === 'unassigned')
        .reduce<boolean | undefined>(
          (_acc, next) => (next ? true : undefined),
          undefined,
        );

      let isResolved: boolean | undefined;
      if (filterState.status.size === 2) {
        isResolved = undefined;
      } else if (filterState.status.has('resolved')) {
        isResolved = true;
      } else if (filterState.status.has('unresolved')) {
        isResolved = false;
      } else {
        isResolved = undefined;
      }

      setStateInURL({
        ...state,
        folderIds: activeFolders.length > 0 ? activeFolders : [],
        staffIds: activeStaff.length > 0 ? activeStaff : undefined,
        assignedIds:
          activeClinicUsers.length > 0 ? activeClinicUsers : undefined,
        isUnassigned,
        isResolved,
      });
    }

    function onNewMessageClick() {
      setNewMessageModalOpen(true);
    }

    const onNewMessageModalClose: NewMessageModalProps['onClose'] = ({
      hasValues,
    }) => {
      if (hasValues) {
        setDiscardConversationPromptOpen(true);
      } else {
        setNewMessageModalOpen(false);
      }
    };

    const discardDraftConversation = () => {
      setDiscardConversationPromptOpen(false);
      setNewMessageModalOpen(false);
    };

    const closeDiscardConversationPrompt = () => {
      setDiscardConversationPromptOpen(false);
    };

    function onFilterChipsChanged(changedFilters: FilterState) {
      setStateInURL({
        ...state,
        ...changedFilters,
      });
    }

    function onClinicUserAssignItemClick({
      userId,
      messageId,
    }: OnClickClinicUserValue) {
      const conversation = {
        messageid: messageId,
        assignedclinicuserid: userId,
      };
      updateConversationAsync(conversation);
    }

    const filters: FilterState = {
      q: searchQuery,
      ...state,
    };

    const assignClinicUsers: AssignClinicUsers = {
      onClinicUserAssignItemClick,
      clinicUsers,
      isLoadingClinicUsers,
      me,
    };

    return (
      <PageContainer>
        <LeftPanel>
          <ActionContainer className="action-container">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={onNewMessageClick}
            >
              {t.Messages.NewMessage}
            </Button>
          </ActionContainer>
          {/* Force unmount to clear component's state */}
          {newMessageModalOpen && (
            <NewMessageModal
              open={newMessageModalOpen}
              onClose={onNewMessageModalClose}
            />
          )}
          <DiscardConversationPrompt
            isOpen={discardConversationPromptOpen}
            onDiscard={discardDraftConversation}
            onCancel={closeDiscardConversationPrompt}
          />
          {folders && me && (
            <div className="folders-container">
              <Folders
                data-testid="conversation-folders"
                folders={folders}
                filters={filters}
                onFolderClick={handleFolderClick}
                assignedFolderCount={assignedFolderCount}
                me={me}
              />
            </div>
          )}
        </LeftPanel>
        <Main>
          <MiddlePanel>
            <SearchBar
              value={searchQuery}
              onChange={updateSearchTerm}
              placeholder={t.Messages.SearchMessages}
              sticky
              elevation={0}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t.Messages.FilterButtonAriaLabel}
                    ref={filterPopoverAnchor}
                    onClick={onFilterButtonClick}
                    size="small"
                    role="button"
                    color={
                      filterPopoverOpen || filtersApplied(filters)
                        ? 'primary'
                        : 'default'
                    }
                  >
                    <IconTune />
                  </IconButton>
                </InputAdornment>
              }
            />
            <FilterMenu
              folders={folders}
              filters={filters}
              menuAnchor={filterPopoverAnchor.current}
              open={filterPopoverOpen}
              onClose={onFilterPopoverClose}
              onChange={onFiltersUpdate}
              clinicUsers={clinicUsers}
            />
            <FilterChips
              filters={filters}
              folders={folders}
              clinicUsers={clinicUsers}
              onChange={onFilterChipsChanged}
            />
            {folders && (
              <Conversations
                folders={folders}
                getTypingConversationMessage={getTypingConversationMessage}
                selectedTab={state.isStarred ? 0 : 1}
                onTabChange={handleTabChange}
                filters={filters}
                selectedConversationId={state.conversationId}
                onConversationClick={handleConversationClick}
                assignClinicUsers={assignClinicUsers}
              />
            )}
          </MiddlePanel>
          <RightPanel>
            <Switch>
              <Route exact path="/messages">
                <Empty
                  icon={<MailOpenIcon />}
                  className="message-no-conversation-selected"
                  message={t.Messages.NoConversationSelected}
                />
              </Route>
              <Route path="/messages/:conversationId">
                {conversationResult.isLoading && <Loading fullHeight />}
                {selectedConversation && folders && (
                  <Chat
                    folders={folders}
                    getTypingConversationMessage={getTypingConversationMessage}
                    conversation={selectedConversation}
                    onChange={handleChatTyping}
                    assignClinicUsers={assignClinicUsers}
                    getInitialConversationState={getInitialConversationState}
                    setConversationState={setConversationState}
                    fileUploadService={fileUploadService}
                    staffProfiles={staffProfiles}
                  />
                )}
              </Route>
            </Switch>
          </RightPanel>
        </Main>
      </PageContainer>
    );
  };

interface Props extends React.ComponentProps<typeof StyledRoot> {
  conversationCache: IConversationCache;
  fileUploadService: IFileUpload;
  staffProfiles: StaffProfiles;
}

export const ConversationPage: FunctionComponent<Props> = ({
  conversationCache,
  fileUploadService,
  staffProfiles,
  ...props
}) => {
  return (
    <StyledRoot {...props}>
      <Switch>
        <Route path="/messages">
          <ConversationPageRaw
            conversationCache={conversationCache}
            fileUploadService={fileUploadService}
            staffProfiles={staffProfiles}
          />
        </Route>
        <Route path="*">
          <Redirect to="/messages" />
        </Route>
      </Switch>
    </StyledRoot>
  );
};
