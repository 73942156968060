import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';
import { CallEnd, Mic, MicOff, Videocam, VideocamOff } from '@material-ui/icons';
export var PageContainer = styled('div')(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  position: relative;\n  background-color: #1f2124;\n"], ["\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  position: relative;\n  background-color: #1f2124;\n"])));
export var ParticipantsContainer = styled('div')(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  align-items: center;\n"])));
export var NooneHereText = styled(Typography)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  color: #686868;\n"], ["\n  color: #686868;\n"])));
export var CallEndIcon = styled(CallEnd)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  font-size: 38px;\n  color: #ffffff;\n"], ["\n  font-size: 38px;\n  color: #ffffff;\n"])));
export var MicIcon = styled(Mic)(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  font-size: 28px;\n  color: #3a3a3a;\n"], ["\n  font-size: 28px;\n  color: #3a3a3a;\n"])));
export var MicOffIcon = styled(MicOff)(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  font-size: 28px;\n  color: #3a3a3a;\n"], ["\n  font-size: 28px;\n  color: #3a3a3a;\n"])));
export var VideocamIcon = styled(Videocam)(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  font-size: 28px;\n  color: #3a3a3a;\n"], ["\n  font-size: 28px;\n  color: #3a3a3a;\n"])));
export var VideocamOffIcon = styled(VideocamOff)(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  font-size: 28px;\n  color: #3a3a3a;\n"], ["\n  font-size: 28px;\n  color: #3a3a3a;\n"])));
export var VideocamButton = styled(IconButton)(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["\n  background-color: #ffffff;\n  margin: 16px;\n  &:hover {\n    background-color: #ffffff;\n  }\n"], ["\n  background-color: #ffffff;\n  margin: 16px;\n  &:hover {\n    background-color: #ffffff;\n  }\n"])));
export var MicButton = styled(IconButton)(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["\n  background-color: #ffffff;\n  margin: 16px;\n  &:hover {\n    background-color: #ffffff;\n  }\n"], ["\n  background-color: #ffffff;\n  margin: 16px;\n  &:hover {\n    background-color: #ffffff;\n  }\n"])));
export var CallEndButton = styled(IconButton)(templateObject_11 || (templateObject_11 = tslib_1.__makeTemplateObject(["\n  background-color: #d0021b;\n  margin: 16px;\n  &:hover {\n    background-color: #d0021b;\n  }\n"], ["\n  background-color: #d0021b;\n  margin: 16px;\n  &:hover {\n    background-color: #d0021b;\n  }\n"])));
export var VideoActionBarContainer = styled('div')(templateObject_12 || (templateObject_12 = tslib_1.__makeTemplateObject(["\n  height: 100px;\n  width: 100vw;\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  height: 100px;\n  width: 100vw;\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
