import * as tslib_1 from "tslib";
import { PatientActionTypes, } from '../actions/patient.actions';
export var initialState = {
    patientLoading: true,
    filterActive: false,
    filter: '',
    activeSection: 'patientInformation',
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PatientActionTypes.LoadingPatientInformation: {
            return tslib_1.__assign({}, state, { patientLoading: true });
        }
        case PatientActionTypes.LoadedPatientInformation: {
            return tslib_1.__assign({}, state, { patientLoading: false });
        }
        case PatientActionTypes.ActivatePatientListFilter: {
            return tslib_1.__assign({}, state, { filterActive: true });
        }
        case PatientActionTypes.DeactivatePatientListFilter: {
            return tslib_1.__assign({}, state, { filterActive: false });
        }
        case PatientActionTypes.SetPatientListFilter: {
            return tslib_1.__assign({}, state, { filter: action.payload });
        }
        case PatientActionTypes.ChangeActiveSection: {
            return tslib_1.__assign({}, state, { activeSection: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var patientIsLoading = function (state) { return state.patientLoading; };
export var patientListFiltered = function (state) { return state.filterActive; };
export var getPatientListFilter = function (state) { return state.filter; };
export var getActiveSection = function (state) { return state.activeSection; };
