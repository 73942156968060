<div class="confirm-dialog" fxLayout="column" fxLayoutAlign="start stretch">
  <div>
    <h6 class="title MuiTypography-subtitle1">{{ data.title }}</h6>
  </div>
  <div>
    <p *ngIf="data.message1" class="message">{{ data.message1 }}</p>
    <p *ngIf="data.message2" class="message">{{ data.message2 }}</p>
    <p *ngIf="data.message3" class="message">{{ data.message3 }}</p>
    <p *ngIf="data.message4" class="message">{{ data.message4 }}</p>
  </div>
  <div mat-dialog-actions fxLayoutAlign="space-between center">
    <button
      mat-raised-button
      class="portal-btn btn-warn btn-primary"
      (click)="onNoClick()"
      cdkFocusInitial
    >
      {{ data.text.Cancel }}
    </button>
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="onYesClick()"
    >
      {{ data.text.Confirm }}
    </button>
  </div>
</div>
