import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import libphonenumber from 'google-libphonenumber';
import { Observable } from 'rxjs';
import { first, last, map } from 'rxjs/operators';
import { SimplePatient } from 'src/app/models/SimplePatient';

let phoneUtil;
if (libphonenumber) {
  phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
}

// Validator function to figure out if patient entry is valid
export function isPatient(
  patients$: Observable<SimplePatient[]>,
): AsyncValidatorFn {
  return (c: AbstractControl): Observable<ValidationErrors | null> => {
    return patients$.pipe(
      map((patients) => {
        if (!c.value || !patients.find((p) => p.Id === c.value.Id)) {
          return { notPatient: true };
        }
        return null;
      }),
      first(),
    );
  };
}

export function checkMobileNumber(
  mobileFieldName: string,
  countryCodeFieldName: string,
): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {
    const mobileNumber = `${group.controls[mobileFieldName].value}`;
    const countryCode = group.controls[countryCodeFieldName].value;

    if (
      mobileNumber &&
      mobileNumber.length > 0 &&
      countryCode &&
      countryCode.length > 0
    ) {
      // Attempt to convert mobileNumber and countryCode into number
      let number;
      try {
        number = phoneUtil.parseAndKeepRawInput(mobileNumber, countryCode);
      } catch (e) {
        return { 'invalid-phone': true };
      }

      // check is valid and possible
      let isPossible;
      let isValid;

      try {
        isPossible = phoneUtil.isPossibleNumber(number);
        isValid = phoneUtil.isValidNumber(number);
      } catch (e) {
        return { 'invalid-phone': true };
      }
      if (!isPossible || !isValid) {
        return { 'invalid-phone': true };
      }

      // check phone number is a mobile number
      let phoneType;
      try {
        phoneType = phoneUtil.getNumberType(number);
      } catch (e) {
        return { 'not-mobile': true };
      }
      // 1 = MOBILE, 2 = FIXED_LINE_OR_MOBILE
      if (phoneType !== 1 && phoneType !== 2) {
        return { 'not-mobile': true };
      }
    } else {
      return { 'no-number': true };
    }

    // no errors!
    return null;
  };
}

export function valuesMatch(
  targetKey: string,
  toMatchKey: string,
): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {
    const target = group.controls[targetKey];
    const toMatch = group.controls[toMatchKey];
    const isMatch = target.value === toMatch.value;
    if (!isMatch) {
      return { mismatch: true };
    }
    return null;
  };
}

export function passwordComplexity(
  control: AbstractControl,
): { [key: string]: boolean } | null {
  const currentValue = control.value;
  const errors = [];

  // contains one number
  if (!/[0-9]/g.test(currentValue)) {
    errors.push('no-number');
  }

  // contains one uppercase letter
  if (!/[A-Z]/g.test(currentValue)) {
    errors.push('no-uppercase');
  }

  // contains one lowercase character
  if (!/[a-z]/g.test(currentValue)) {
    errors.push('no-lowercase');
  }

  // is at least 8 characters
  if (currentValue.length < 8) {
    errors.push('minlength');
  }

  if (errors.length === 0) {
    return null;
  }

  return { complexity: true };
}

export function setErrors(
  error: { [key: string]: any },
  control: AbstractControl,
) {
  control.setErrors({ ...control.errors, ...error });
}

export function removeErrors(keys: string[], control: AbstractControl) {
  const remainingErrors = keys.reduce(
    (errors, key) => {
      delete errors[key];
      return errors;
    },
    { ...control.errors },
  );
  control.setErrors(
    Object.keys(remainingErrors).length > 0 ? remainingErrors : null,
  );
}
