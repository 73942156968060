import styled from '@emotion/styled';
import { Paper, Select, TableRow } from '@material-ui/core';

export const StyledTableContainer = styled(Paper)`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f0f0f7;
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  border: 1px solid;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  padding: 0.1rem;
  padding-left: 10px;
  width: 100%;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }
`;
