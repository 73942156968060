import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import { TableContentEntry } from '../../models/content-entry';
import { ContentPack } from '../../models/content-packs';
import * as fromContent from '../../reducers';
import {
  AddToMultipleContentPacks,
  AddToMultipleContentPacksError,
  AddToMultipleContentPacksSuccess,
  ContentPackActionTypes,
} from './../../actions/packs.actions';
import { AddToMultiplePacksBody } from './../../models/content-packs';

export interface AddToPackComponentData {
  text: any;
  selectedItems: TableContentEntry[];
}

@Component({
  selector: 'portal-add-to-pack',
  templateUrl: './add-to-pack.component.html',
  styleUrls: ['./add-to-pack.component.scss'],
})
export class AddToPackComponent implements OnInit, OnDestroy {
  public packs$: Observable<ContentPack[]>;
  public addingToPack$: Observable<boolean>;
  public addingToPackError$: Observable<string>;

  public errorEncountered$ = new BehaviorSubject(false);

  public contentEntries: TableContentEntry[];
  public text: any;
  public selectedPack: FormControl;
  public success = false;

  public availablePacks: ContentPack[] = [];

  public selection: SelectionModel<ContentPack>;

  private _subs = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates$: Actions,
    public dialogRef: MatDialogRef<AddToPackComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AddToPackComponentData,
  ) {
    this.contentEntries = [...data.selectedItems];

    // watch for successful adding to pack
    this._subs.add(
      this._updates$
        .pipe(
          ofType<AddToMultipleContentPacksSuccess>(
            ContentPackActionTypes.AddToMultipleContentPacksSuccess,
          ),
        )
        .subscribe(() => {
          this.success = true;
          this.exitDialog(true, 1000);
        }),
    );

    // watch for error adding to pack
    this._subs.add(
      this._updates$
        .pipe(
          ofType<AddToMultipleContentPacksError>(
            ContentPackActionTypes.AddToMultipleContentPacksError,
          ),
        )
        .subscribe(() => {
          this.errorEncountered$.next(true);
        }),
    );
  }

  ngOnInit() {
    this.selectedPack = new FormControl('', Validators.required);
    this.packs$ = this._store.pipe(select(fromContent.getPacks));
    this.addingToPack$ = this._store.pipe(select(fromContent.isAddingToPack));
    this.addingToPackError$ = this._store.pipe(
      select(fromContent.checkForError('addingToPack')),
    );

    this._subs.add(this.packs$.subscribe((p) => (this.availablePacks = p)));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public togglePack(packId: number) {
    this.availablePacks = this.availablePacks.map((pack) =>
      pack.id === packId
        ? {
            ...pack,
            toAssign: !pack.toAssign,
          }
        : pack,
    );
  }

  public isAllSelected(): boolean {
    return this.availablePacks.every((pack) => pack.toAssign);
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.clearSelection() : this.selectAll();
  }

  public selectAll(): void {
    this.availablePacks = this.availablePacks.map((pack) => ({
      ...pack,
      toAssign: true,
    }));
  }

  public clearSelection(): void {
    this.availablePacks = this.availablePacks.map((pack) => ({
      ...pack,
      toAssign: false,
    }));
  }

  private _getSelectedItems(): ContentPack[] {
    return this.availablePacks.filter((pack) => pack.toAssign);
  }

  private _getSelectedItemIds(): number[] {
    return this._getSelectedItems().map((pack) => pack.id);
  }

  public removeFromContentEntries(id: number) {
    this.contentEntries = this.contentEntries.filter((c) => c.id !== id);
  }

  public exitDialog(status = false, timeout = 0) {
    setTimeout(() => {
      this.dialogRef.close(status);
    }, timeout);
  }

  public cancel() {
    this.exitDialog();
  }

  public disableSubmission(): boolean {
    return (
      this._getSelectedItems().length === 0 || this.contentEntries.length === 0
    );
  }

  public addToPack() {
    this.errorEncountered$.next(false);
    const packIds = this._getSelectedItemIds();
    const contentEntries = this.contentEntries.map((c) => c.id);
    const addItemToPack: AddToMultiplePacksBody = {
      packIds,
      contentEntries,
    };
    this._store.dispatch(new AddToMultipleContentPacks(addItemToPack));
  }
}
