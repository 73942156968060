<div class="move-entries-dialog" fxLayout="column">
  <p class="which">
    {{ data.text.WhichFolder }}
  </p>
  <mat-form-field>
    <mat-select
      matForm
      placeholder="{{ data.text.ChooseAFolder }}"
      [formControl]="categories"
    >
      <mat-option
        *ngFor="let category of data.otherCategories"
        [value]="category"
        >{{ category.name }}</mat-option
      >
    </mat-select>
    <mat-error *ngIf="categories.hasError('required')">{{
      data.text.PleaseChoose
    }}</mat-error>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <button
      mat-raised-button
      class="portal-btn btn-primary"
      (click)="onCancelClick()"
    >
      {{ data.text.Cancel }}
    </button>
    <div class="status" fxFlex="grow">
      <div
        *ngIf="movingEntries$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-spinner [diameter]="36"></mat-spinner>
        <div class="status-text">
          {{ data.text.MovingItems }}
        </div>
      </div>
      <div
        *ngIf="movedEntries$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-icon class="success">check_circle</mat-icon>
        <div class="status-text">
          {{ data.text.MovedItemsSuccess }}
        </div>
      </div>
      <div
        *ngIf="errorEncountered$ | async"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
      >
        <mat-icon class="error">highlight_off</mat-icon>
        <div class="status-text">
          {{ data.text.ErrorMovingItems }}
        </div>
      </div>
    </div>
    <button
      mat-raised-button
      class="portal-btn btn-bright"
      disabled="{{ !selectedCategory }}"
      (click)="onMoveClick()"
    >
      {{ data.text.Move }}
    </button>
  </div>
</div>
