import debounce from 'lodash/debounce';
import prettyBytes from 'pretty-bytes';
import { useContext, useRef } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { DisplayToastAction } from 'src/app/core/actions/toast.actions';
import { LanguageContext } from 'src/app/react/lib/i18n';
import { environment } from 'src/environments/environment';
import { useDispatch } from '../store';
export function useDebouncedState(outerState, delay) {
    var _a = useState(outerState), value = _a[0], setValue = _a[1];
    var debouncedSetValue = useMemo(function () { return debounce(setValue, delay); }, [delay]);
    useEffect(function () {
        debouncedSetValue(outerState);
    }, [outerState]);
    // Make sure any pending debounce operation is cancelled
    // Note: this is separate as the above `useEffect` would
    // call its cleanup function when `outerState` changes at all
    useEffect(function () {
        return debouncedSetValue.cancel;
    }, []);
    return value;
}
export function useErrorToast(isError, _a, timeout) {
    var title = _a.title, message = _a.message;
    if (timeout === void 0) { timeout = 5000; }
    var dispatch = useDispatch();
    useEffect(function () {
        if (isError) {
            dispatch(new DisplayToastAction({
                title: title,
                message: message,
                timeout: timeout,
                type: 'error',
            }));
        }
    }, [isError]);
}
export function useSuccessToast(isSuccess, _a) {
    var title = _a.title, message = _a.message;
    var dispatch = useDispatch();
    useEffect(function () {
        if (isSuccess) {
            dispatch(new DisplayToastAction({
                title: title,
                message: message,
                timeout: 5000,
                type: 'success',
            }));
        }
    }, [isSuccess]);
}
export function useQuery() {
    var search = useLocation().search;
    return useMemo(function () {
        var searchParams = new URLSearchParams(search);
        var query = {};
        searchParams.forEach(function (value, key) { return (query[key] = value); });
        return query;
    }, [search]);
}
export function useBytesFormatter() {
    var language = useContext(LanguageContext).language;
    return function (value) {
        return prettyBytes(value, {
            locale: language,
        });
    };
}
export function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    }, [value]);
    return ref.current;
}
export function useEnvVariables() {
    return environment;
}
