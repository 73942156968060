import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Inject,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[intersection-observer]',
})
export class IntersectionObserverDirective implements AfterViewInit {
  private observer: any;

  @Input() observeFirstChildElement: boolean;
  @Input() observeMargin: string;
  @Output() startedSettingUp = new EventEmitter<void>();
  @Output() observed = new EventEmitter<void>();

  private get elementToObserve(): any {
    if (this.elementRef) {
      return this.observeFirstChildElement
        ? this.elementRef.nativeElement.children[0]
        : this.elementRef.nativeElement;
    }
  }

  constructor(@Inject(ElementRef) private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.SetupObserver();
  }

  SetupObserver() {
    if (this.observer) {
      this.DetachObserver();
    }
    if ('IntersectionObserver' in window) {
      if (this.startedSettingUp) {
        this.startedSettingUp.emit();
      }
      const config = {
        root: null,
        rootMargin: this.observeMargin || '0px',
        threshold: 0.5,
      };
      this.observer = new IntersectionObserver(
        this.OnObserverChanges.bind(this),
        config,
      );
      this.observer.observe(this.elementToObserve);
    }
  }

  DetachObserver() {
    if (this.observer) {
      try {
        this.observer.unobserve(this.elementToObserve);
        this.observer = null;
      } catch {}
    }
  }

  private OnObserverChanges(changes, observer) {
    for (const change of changes) {
      if (change.intersectionRatio > 0) {
        if (this.observer) {
          try {
            if (this.observed) {
              this.observed.emit();
            }
          } catch {}
        }
      }
    }
  }
}
