import { Description as DescriptionIcon } from '@material-ui/icons';
import { useBytesFormatter } from '@react/lib/hooks';
import React, { FunctionComponent } from 'react';
import { FileDescriptionStyled } from './FileDescription.styled';

interface Props {
  filename: string;
  fileSize: number;
  onClick?: () => void;
}

export const FileDescription: FunctionComponent<Props> = ({
  filename,
  fileSize,
  onClick,
}: Props) => {
  const formatBytes = useBytesFormatter();

  return (
    <FileDescriptionStyled onClick={onClick}>
      <DescriptionIcon /> {filename} ({formatBytes(fileSize)})
    </FileDescriptionStyled>
  );
};
