import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { FilterTargetLink, GetAvailableToLinkContent, LinkContent, LinkContentActionTypes, SelectedContentForLinking, } from '../../actions/link-content.actions';
import * as fromContent from '../../reducers';
var LinkContentComponent = /** @class */ (function () {
    function LinkContentComponent(_store, _updates$, dialogRef, dialog, data) {
        var _this = this;
        this._store = _store;
        this._updates$ = _updates$;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.scrollConfig = {
            wheelPropagation: true,
            suppressScrollX: true,
        };
        this.availableItemsToLinkTo$ = of([]);
        this._subs = new Subscription();
        this._store.dispatch(new GetAvailableToLinkContent({
            contentCategory: data.contentCategoryId,
        }));
        this.contentToLinkForm = new FormGroup({
            contentBeingLinked: new FormControl(data.selectedItems),
            contentToLinkTo: new FormControl('', Validators.required),
        });
        this.filterForm = new FormGroup({
            target: new FormControl(''),
        });
        this._subs.add(this._updates$
            .pipe(ofType(LinkContentActionTypes.LinkContentSuccess))
            .subscribe(function () {
            _this.success = true;
            _this.closeOnSuccess();
        }));
        this._subs.add(this.filterForm
            .get('target')
            .valueChanges.subscribe(function (val) { return _this.updateTarget(val); }));
        this._subs.add(this.contentToLinkForm.valueChanges.subscribe());
    }
    LinkContentComponent.prototype.ngOnInit = function () {
        this.availableItemsToLinkTo$ = this._store.pipe(select(fromContent.getFilteredAvailableContent));
        this.loadingAvailableItems$ = this._store.pipe(select(fromContent.isLoadingAvailableToLinkContent));
        this.loadingAvailableItemsError$ = this._store.pipe(select(fromContent.getLinkError('availableContentToLinkTo')));
        this.creatingLink$ = this._store.pipe(select(fromContent.isCreatingLink));
        this.creatingLinkError$ = this._store.pipe(select(fromContent.getLinkError('creatingLink')));
        this._store.dispatch(new SelectedContentForLinking(this.data.selectedItems.map(function (item) { return item.id; })));
    };
    LinkContentComponent.prototype.ngOnDestroy = function () {
        this._subs.unsubscribe();
    };
    LinkContentComponent.prototype.clearTarget = function () {
        this.filterForm.setValue({
            target: '',
        });
    };
    LinkContentComponent.prototype.updateTarget = function (val) {
        this._store.dispatch(new FilterTargetLink(val));
    };
    LinkContentComponent.prototype.cancel = function () {
        this._store.dispatch(new FilterTargetLink(''));
        this.dialogRef.close(false);
    };
    LinkContentComponent.prototype.closeOnSuccess = function () {
        var _this = this;
        setTimeout(function () {
            _this._store.dispatch(new FilterTargetLink(''));
            _this.dialogRef.close(true);
        }, 1500);
    };
    LinkContentComponent.prototype.linkContent = function () {
        var _a = this.contentToLinkForm.value, contentBeingLinked = _a.contentBeingLinked, contentToLinkTo = _a.contentToLinkTo;
        var payload = {
            contentToShow: contentToLinkTo,
            contentToLink: contentBeingLinked.map(function (c) { return c.id; }),
        };
        this._store.dispatch(new LinkContent(payload));
    };
    LinkContentComponent.prototype.selectItem = function (entry) {
        this.itemSelected = entry.patientname || entry.clinicname;
        this.contentToLinkForm.patchValue({
            contentToLinkTo: entry.id,
        });
    };
    LinkContentComponent.prototype.clearSelectedItem = function () {
        this.itemSelected = null;
        this.contentToLinkForm.patchValue({
            contentToLinkTo: '',
        });
    };
    return LinkContentComponent;
}());
export { LinkContentComponent };
