import styled from '@emotion/styled';
import { Button as MaterialButton } from '@material-ui/core';
import * as React from 'react';

const StyledButton = styled(MaterialButton)`
  text-transform: none;
`;

type Props = React.ComponentProps<typeof MaterialButton>;

export const Button: React.FC<Props> = React.forwardRef(
  ({ children, ...rest }, ref) => (
    <StyledButton ref={ref} {...rest}>
      {children}
    </StyledButton>
  ),
);
