import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, ErrorHandler, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Auth, Hub, Logger } from 'aws-amplify';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalisationService } from 'src/app/localisation/localisation.service';
import { environment } from 'src/environments/environment';
import * as AuthActions from '../../../auth/actions/auth.actions';
import * as fromAuth from '../../../auth/reducers';
import * as fromContent from '../../../content/reducers';
import * as DashboardActions from '../../../dashboard/actions/dashboard.actions';
import * as fromMessages from '../../../messaging/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as ClinicActions from '../../actions/clinic.actions';
import * as LayoutActions from '../../actions/layout.actions';
import { ActivateMenuItemAction } from '../../actions/layout.actions';
import { NavigationService } from '../../services/navigation.service';
import { PushNotificationsService } from '../../services/push-notifications.service';
import { getMenuItems } from './menu-items';
Auth.configure({
    Auth: environment.auth
});
var AppComponent = /** @class */ (function () {
    function AppComponent(_store, _push, _error, _localisationService, _navigationService, mediaMatcher, breakpointObserver, angulartics2GoogleAnalytics, changeDetection, _title) {
        var _this = this;
        this._store = _store;
        this._push = _push;
        this._error = _error;
        this._localisationService = _localisationService;
        this._navigationService = _navigationService;
        this.mediaMatcher = mediaMatcher;
        this.breakpointObserver = breakpointObserver;
        this.angulartics2GoogleAnalytics = angulartics2GoogleAnalytics;
        this.changeDetection = changeDetection;
        this._title = _title;
        this.permissionRequested = false;
        this._logger = new Logger('auth_logger');
        this.tempMessage$ = new BehaviorSubject('');
        // Manage component Subscriptions
        this._subs = new Subscription();
        // render if logged in, could be retrieved from server,
        // upon verification of available modules to client.
        this.menuItems$ = of([]);
        this.isGlobalPortal$ = this._store.pipe(select(fromRoot.getIsGlobalPortal));
        this.hasSetGlobalPortal$ = this._store.pipe(select(fromRoot.getHasSetGlobalPortal));
        this.isCoreClinic$ = this._store.pipe(select(fromAuth.getCore));
        this.clinicToken$ = this._store.pipe(select(fromAuth.getClinicId));
        this.categories$ = this._store.pipe(select(fromContent.getCategories));
        this.firstCategoryId$ = this._store.pipe(select(fromContent.getFirstCategoryId));
        this.angulartics2GoogleAnalytics.setUserProperties({ dimension1: 'test' });
        this.angulartics2GoogleAnalytics.startTracking();
        this.menuItems$ = this._store.pipe(select(fromRoot.getMenuItems));
        this.showSidenav$ = this._store.pipe(select(fromRoot.getShowSidenav));
        this._store.dispatch(new AuthActions.GetClinicId());
        // subscribe to store
        this.activeMenuItem$ = this._store.pipe(select(fromRoot.getActiveMenuItem));
        this._activated$ = this._store.pipe(select(fromRoot.getRouteHead));
        this.loggedIn$ = this._store.pipe(select(fromAuth.getLoggedIn));
        this.user$ = this._store.pipe(select(fromAuth.getUser));
        this.clinic$ = this._store.pipe(select(fromAuth.getClinicName));
        this.selectedLanguage$ = this._store.pipe(select(fromSettings.getCurrentLanguage));
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('Toolbar')));
        this.clinic$.subscribe(function (nextValue) {
            return _this._title.setTitle(nextValue + " | " + _this._title.getTitle());
        });
        this._subs.add(this.sectionText$.subscribe(function (t) {
            _this.sectionText = t;
        }));
        this.isCognito$ = this._store.pipe(select(fromAuth.isUserCognito));
        this._logger.onHubCapsule = function (capsule) {
            switch (capsule.payload.event) {
                case 'signIn':
                    _this._logger.log('user signed in'); // [ERROR] Alexander_the_auth_watcher - user signed in
                    break;
                case 'signUp':
                    _this._logger.log('user signed up');
                    break;
                case 'signOut':
                    _this._logger.log('user signed out');
                    break;
                case 'signIn_failure':
                    _this._logger.error('user sign in failed');
                    break;
                case 'configured':
                    _this._logger.log('the Auth module is configured');
            }
        };
        Hub.listen('auth', this._logger);
        this._subs.add(this.loggedIn$.subscribe(function (l) {
            _this.loggedIn = l;
        }));
        this._subs.add(this.isGlobalPortal$.subscribe(function (value) {
            _this.isGlobalPortal = value;
        }));
        this._subs.add(this.hasSetGlobalPortal$.subscribe(function (value) {
            _this.hasSetGlobalPortal = value;
        }));
        this._subs.add(combineLatest(this.isCoreClinic$, this.isCognito$, this._store.pipe(select(fromAuth.getClinicOptions)), this.user$, this.isGlobalPortal$, this.hasSetGlobalPortal$).subscribe(function (_a) {
            var isCoreClinic = _a[0], isUserCognito = _a[1], clinicOptions = _a[2], user = _a[3], isGlobalPortal = _a[4], hasSetGlobalPortal = _a[5];
            if (hasSetGlobalPortal) {
                _this.menuItems = getMenuItems(isCoreClinic, isUserCognito, isGlobalPortal, clinicOptions, user != null ? user.groups : []);
            }
        }));
    }
    AppComponent.prototype.hostClick = function () {
        if (!this.permissionRequested) {
            this._push.requestPermission();
            this.permissionRequested = true;
        }
    };
    AppComponent.prototype.checkForDraftMessages = function ($event) {
        var draftMessages = this._store.pipe(select(fromMessages.getDraftMessages));
        var hasDraftMessages = false;
        var sub = draftMessages.subscribe(function (dm) {
            for (var key in dm) {
                if (Object.hasOwnProperty.apply(dm, key)) {
                    var value = dm[key];
                    if (value.length > 0) {
                        hasDraftMessages = true;
                    }
                }
            }
        });
        sub.unsubscribe();
        if (hasDraftMessages) {
            $event.returnValue = 'You have unsent messages';
        }
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // screen width functions start ===========================
        var small$ = this.breakpointObserver.observe(['(max-width: 768px)']);
        var medium$ = this.breakpointObserver.observe(['(max-width: 1365px)']);
        // If status of either breakpoint observer has changed the
        // screensize width has crossed a breakpoint
        combineLatest([small$, medium$])
            .pipe(map(function (o) { return o.map(function (r) { return r.matches; }); }))
            .subscribe(function (result) {
            _this.changeScreenWidth(result);
        });
        // screen width functions end =============================
        this._subs.add(combineLatest(this.clinicToken$, this.isGlobalPortal$).subscribe(function (_a) {
            var _clinicToken = _a[0], isGlobalPortal = _a[1];
            if (!isGlobalPortal) {
                setTimeout(function () {
                    _this.refreshStatus();
                    _this.interval = setInterval(function () {
                        _this.refreshStatus();
                    }, 60 * 1000);
                });
            }
        }));
        this._subs.add(combineLatest(this.clinicToken$, this.user$, this.isGlobalPortal$).subscribe(function (_a) {
            var clinicToken = _a[0], user = _a[1], isGlobalPortal = _a[2];
            if (user && user.PublicToken && clinicToken) {
                _this.clearTempMessage();
                _this._store.dispatch(new ClinicActions.LoadClinicLanguages());
            }
        }));
        // Subscribe to language changes
        this._subs.add(this.selectedLanguage$.subscribe(function (language) {
            _this._localisationService.setLocale(language);
        }));
        this._subs.add(this.firstCategoryId$.subscribe(function (id) { return (_this.firstCategoryId = id); }));
        this._subs.add(this.activeMenuItem$.subscribe(function (item) {
            if (item) {
                _this.activePageKey = item.key;
            }
        }));
        this._subs.add(this._activated$.subscribe(function (path) {
            if (_this.menuItems) {
                _this._store.dispatch(new ActivateMenuItemAction(_this.menuItems.find(function (i) { return i.path === path; })));
            }
        }));
    };
    AppComponent.prototype.ngOnDestroy = function () {
        // Remove all subscriptions
        this._subs.unsubscribe();
    };
    AppComponent.prototype.refreshStatus = function () {
        if (!this.isGlobalPortal) {
            this._store.dispatch(new DashboardActions.RefreshClinicStatus());
        }
    };
    // This sets the screen width size in the app store
    AppComponent.prototype.changeScreenWidth = function (_a) {
        var small = _a[0], medium = _a[1];
        var size = small ? 'sm' : medium ? 'md' : 'lg';
        if (size !== 'lg') {
            this.closeSidenav();
        }
        else {
            this.openSidenav();
        }
    };
    // These functions manage state of side menu
    AppComponent.prototype.closeSidenav = function () {
        this._store.dispatch(new LayoutActions.CloseSidenav());
    };
    AppComponent.prototype.openSidenav = function () {
        this._store.dispatch(new LayoutActions.OpenSidenav());
    };
    AppComponent.prototype.logout = function (isCognito) {
        this._store.dispatch(new AuthActions.Logout({
            isCognito: isCognito
        }));
    };
    AppComponent.prototype.gotoPatient = function (id) {
        this._navigationService.navigate(['patients', id]);
    };
    AppComponent.prototype.clearTempMessage = function () {
        this.tempMessage$.next('');
    };
    AppComponent.prototype.showTempMessage = function (message) {
        var _this = this;
        this.tempMessage$.next(message);
        setTimeout(function () {
            _this.clearTempMessage();
        }, 3000);
    };
    AppComponent.prototype.goToMenuItem = function (menuItem) {
        if (this.loggedIn) {
            if (menuItem.key === 'Content') {
                this._navigationService.navigate([
                    menuItem.path,
                    'list',
                    this.firstCategoryId
                ]);
            }
            else {
                this._navigationService.navigate([menuItem.path]);
            }
        }
        else {
            this.showTempMessage(this.sectionText.PleaseLogIn);
        }
    };
    return AppComponent;
}());
export { AppComponent };
