import styled from '@emotion/styled';

export const Container = styled('div')<{ fullHeight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${({ fullHeight }) => (fullHeight ? `flex-grow: 1` : ``)};
`;
