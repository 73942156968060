<div *ngIf="patientInfo">
  <!-- Patient Information -->
  <div
    class="section"
    *ngIf="activeSection === patientSection.patientInformation"
  >
    <mat-list class="result-list" fxLayout="column">
      <mat-list-item *ngIf="patientInfo.patientIdentifier" class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.PatientId }}
        </div>
        <div class="result-line info">
          {{ patientInfo.patientIdentifier || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.SyncDate }}
        </div>
        <div class="result-line info">
          {{ patientInfo?.patientAccountCreated | shortDateWithTime }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.LastLogin }}
        </div>
        <div class="result-line info">
          {{ patientInfo?.latestLogin | shortDateWithTime }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.FirstName }}
        </div>
        <div class="result-line info">
          {{ patientInfo?.firstName || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.LastName }}
        </div>
        <div class="result-line info">
          {{ patientInfo?.lastName || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.DOB }}
        </div>
        <div class="result-line info">
          {{ patientInfo?.dateOfBirth | shortDate }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.Age }}
        </div>
        <div class="result-line info">
          {{ patientInfo?.age || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item *ngIf="patientInfo.numberOfCycles" class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.NumberOfCycles }}
        </div>
        <div class="result-line info">
          {{ patientInfo.numberOfCycles }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="patientInfo.assignedProfessionals.ClinicPhysician"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.ClinicPhysician }}
        </div>
        <div class="result-line info">
          {{ patientInfo.assignedProfessionals.ClinicPhysician }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="patientInfo.assignedProfessionals.Counselor"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.Counselor }}
        </div>
        <div class="result-line info">
          {{ patientInfo.assignedProfessionals.Counselor }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="patientInfo.assignedProfessionals.Nurse"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.Nurse }}
        </div>
        <div class="result-line info">
          {{ patientInfo.assignedProfessionals.Nurse }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="patientInfo.assignedProfessionals.Accountant"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.Accountant }}
        </div>
        <div class="result-line info">
          {{ patientInfo.assignedProfessionals.Accountant }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="patientInfo.assignedProfessionals.TreatingDr"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.TreatingDr }}
        </div>
        <div class="result-line info">
          {{ patientInfo.assignedProfessionals.TreatingDr }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>

  <!-- Partner Information -->
  <div
    class="section"
    *ngIf="!isCore && activeSection === patientSection.partner"
  >
    <div *ngIf="!partnerInfo" class="information-missing">
      {{ text.NoPartner }}
    </div>
    <mat-list *ngIf="partnerInfo" class="result-list" fxLayout="column">
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.FirstName }}
        </div>
        <div class="result-line info">
          {{ partnerInfo?.firstName || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.LastName }}
        </div>
        <div class="result-line info">
          {{ partnerInfo?.lastName || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.DOB }}
        </div>
        <div class="result-line info">
          {{ partnerInfo?.dateOfBirth | shortDate }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.Age }}
        </div>
        <div class="result-line info">
          {{ partnerInfo?.age || '' }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>

  <!-- Contact Information -->
  <div class="section" *ngIf="activeSection === patientSection.contact">
    <mat-list class="result-list" fxLayout="column">
      <mat-list-item class="result-item">
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.Email }}
        </div>
        <div class="result-line info">
          {{ contactInfo?.email }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="contactInfo && contactInfo.personalPhones"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.PersonalPhoneMobile }}
        </div>
        <div class="result-line info">
          {{ contactInfo?.personalPhones?.mobile }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="contactInfo && contactInfo.personalPhones"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.PersonalPhoneLandline }}
        </div>
        <div class="result-line info">
          {{ contactInfo?.personalPhones?.landline }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="contactInfo && contactInfo.workPhones"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.WorkPhoneMobile }}
        </div>
        <div class="result-line info">
          {{ contactInfo?.workPhones?.mobile }}
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-list-item
        *ngIf="contactInfo && contactInfo.workPhones"
        class="result-item"
      >
        <div fxFlex="50%" class="result-line result-title info">
          {{ text.WorkPhoneLandline }}
        </div>
        <div class="result-line info">
          <p>{{ contactInfo?.workPhones?.landline }}</p>
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>

  <!-- Lab Results -->
  <div class="section list" *ngIf="activeSection === patientSection.labResults">
    <div
      *ngIf="!labResults || labResults.length === 0"
      class="information-missing"
    >
      {{ text.NoLabResults }}
    </div>

    <div *ngIf="labResults.length > 0" class="list-container" fxLayout="column">
      <perfect-scrollbar [config]="config">
        <mat-list class="result-list">
          <mat-list-item *ngFor="let lr of labResults" class="result-item">
            <div
              *ngIf="lr.Date && lr.Investigation"
              mat-line
              class="result-line result-title"
            >
              {{ lr.Date }} - {{ lr.Investigation }}
            </div>
            <div
              *ngIf="lr.Date && !lr.Investigation"
              mat-line
              class="result-line result-title"
            >
              {{ lr.Date }}
            </div>
            <div
              *ngIf="!lr.Date && lr.Investigation"
              mat-line
              class="result-line result-title"
            >
              {{ lr.Investigation }}
            </div>
            <div *ngIf="lr.ResultDate" mat-line class="result-line">
              {{ text.ResultReceived }} {{ lr.ResultDate }}
            </div>
            <div *ngIf="lr.Result || lr.Unit" mat-line class="result-line">
              {{ lr.Result === null ? '' : lr.Result
              }}{{ !!lr.Result ? ' ' : '' }}{{ lr.Unit }}
            </div>
            <div *ngIf="lr.ReferenceRange" mat-line class="result-line">
              {{ text.ResultRange }} {{ lr.ReferenceRange }}
            </div>
            <div *ngIf="lr.ResultText" mat-line class="result-line">
              {{ text.ResultInterpretation }} {{ lr.ResultText }}
            </div>
            <div *ngIf="lr.Remarks" mat-line class="result-line">
              {{ lr.Remarks }}
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- Lab Results section end -->

  <!-- Manage Content -->
  <div *ngIf="activeSection === patientSection.manageContent">
    <portal-content-assignment fxFlexFill> </portal-content-assignment>
  </div>
  <!-- Manage Content section end-->

  <!-- Patient Documents -->
  <div *ngIf="activeSection === patientSection.patientDocuments">
    <portal-patient-documents fxFlexFill> </portal-patient-documents>
  </div>
  <!-- Patient Documents section end-->
</div>
