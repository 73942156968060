import { select, Store } from '@ngrx/store';
import { NavigationService } from 'src/app/core/services/navigation.service';
import * as fromAuth from '../../../auth/reducers';
import * as fromSettings from '../../../settings/reducers';
var NoContentComponent = /** @class */ (function () {
    function NoContentComponent(_navigationService, _store) {
        this._navigationService = _navigationService;
        this._store = _store;
        this.clinicId$ = this._store.pipe(select(fromAuth.getClinicId));
        this.sectionText$ = this._store.pipe(select(fromSettings.getSectionTranslations('NoContent')));
    }
    NoContentComponent.prototype.goToAddContent = function () {
        this._navigationService.navigate(['content', 'list', 0]);
    };
    return NoContentComponent;
}());
export { NoContentComponent };
