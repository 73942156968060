import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  CardContent,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { Input } from '@react/components/Input';

export const LoaderWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const RecipeTitle = styled(Typography)`
  color: #505050;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledAccordian = styled(Accordion)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled(Typography)`
  color: #505050;
  font-weight: 500;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const ActionRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledContent = styled(CardContent)<{
  width?: number;
  fontStyle?: string;
}>`
  width: ${(props) => (props.width ? props.width : 150)}px;
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : 'normal')};
`;

export const StyledInput = styled(Input)`
  border-radius: 4px;
  outline: none;
`;

export const StyledSelect = styled(Select)`
  border: 1px solid;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  padding: 0.1rem;
  padding-left: 10px;
  width: 100%;
  &:hover,
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }
`;
