import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import MaterialChip from '@material-ui/core/Chip';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof MaterialChip> & {
  round?: boolean;
  gray?: boolean;
  purple?: boolean;
  green?: boolean;
  red?: boolean;
  yellow?: boolean;
};

const CUSTOM_COLORS: (keyof Props)[] = [
  'gray',
  'purple',
  'green',
  'red',
  'yellow',
];

const PRIVATE_PROPERTIES = ['round', ...CUSTOM_COLORS];

const isAnyCustomColorSet = (props: Props) => {
  return CUSTOM_COLORS.some((color) => props[color] === true);
};

const colorStyle = ({
  theme,
  gray,
  purple,
  green,
  red,
  yellow,
}: Props & { theme: Theme }) => css`
  ${gray ? `background-color: ${theme.colors.grayLight};` : ''}
  ${purple ? `background-color: ${theme.colors.purple};` : ''}
  ${green ? `background-color: ${theme.colors.green};` : ''}
  ${red ? `background-color: ${theme.colors.red};` : ''}
  ${yellow ? `background-color: ${theme.colors.yellowLight};` : ''}
  color: #4d4d4d;
  span {
    font-weight: normal;
  }
`;

export const Chip = styled(MaterialChip, {
  shouldForwardProp: (prop) =>
    !(typeof prop === 'string' && PRIVATE_PROPERTIES.includes(prop)),
})<Props>`
  height: 28px;
  border-radius: ${({ round }) => (round ? '1rem' : '3px')};
  ${(props) => (isAnyCustomColorSet(props) ? colorStyle : '')}
`;
