import { ErrorHandler } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as dateFns from 'date-fns';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { EndpointType, HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import { ClinicPatientRequest } from '../models/requests/clinic-patient.request';
var PatientService = /** @class */ (function () {
    function PatientService(_store, _error, _httpService) {
        this._store = _store;
        this._error = _error;
        this._httpService = _httpService;
        this._publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
    }
    PatientService.prototype.getClinicPatients = function (payload) {
        var PAGE_SIZE = 25;
        var params = {
            pageNumber: payload.pageNumber.toString(),
            pageSize: PAGE_SIZE.toString()
        };
        if (payload.query) {
            params.q = payload.query;
        }
        if (payload.sortType) {
            params.sortType = payload.sortType.toLowerCase();
        }
        if (payload.sortDirection) {
            params.sortDirection = payload.sortDirection;
        }
        return this._httpService
            .get(EndpointType.Portal, 'patients', params)
            .pipe(
        // TODO: Fix types
        // @ts-ignore
        map(function (response) {
            var result = response.data.map(function (p) {
                return ({
                    FirstName: p.firstname,
                    Id: p.id,
                    LastName: p.lastname,
                    PatientIdentifier: p.patientidentifier,
                    InternalSid: p.internalsid,
                    PatientAccountCreatedDateUtc: p.patientaccountcreateddateutc,
                    DateOfBirth: dateFns.parseISO(p.dateofbirth)
                });
            });
            return result;
        }), catchError(function (err) {
            return throwError(new Error('Error Retrieving Clinic Patients'));
        }));
    };
    PatientService.prototype.getPatientById = function (id, isInternal) {
        return this._httpService
            .get(EndpointType.Portal, "patients/" + id, isInternal ? { type: 'internalsid' } : {})
            .pipe(map(function (response) {
            var p = response.data;
            return {
                FirstName: p.firstname,
                Id: p.id,
                LastName: p.lastname,
                InternalSid: p.internalsid
            };
        }), catchError(function (err) {
            return throwError(new Error('Error Retrieving Clinic Patients'));
        }));
    };
    PatientService.prototype.getPatientInformation = function (patientId) {
        var _this = this;
        return this._publicKey$.pipe(take(1), switchMap(function (pk) {
            return _this._httpService.performResolvePostRequest(new ClinicPatientRequest({
                PatientId: patientId,
                PublicToken: pk
            }));
        }));
    };
    return PatientService;
}());
export { PatientService };
