import { ThemeProvider } from '@emotion/react';
import {
  NoSsr,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { RootConfigProvider } from '@react/lib/context/rootConfig';
import { RealTimeMessagingConnectionProvider } from '@react/lib/context/websocket';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Logout } from 'src/app/auth/actions/auth.actions';
import { User } from 'src/app/auth/models/user';
import { CognitoWrapperService } from 'src/app/core/services/congito.wrapper.service';
import { TranslationData } from 'src/app/settings/data/settings';
import { SupportedLanguage } from 'src/app/settings/data/supported-languages';
import {
  ApiClientProvider,
  ContentApiClient,
  PortalApiClient,
  S3Client,
} from '../../react/lib/api';
import { FeaturesProvider } from '../lib/features/context';
import { LanguageProvider } from '../lib/i18n';
import { DispatchProvider } from '../lib/store';
import * as sentry from '../sentry';
import { materialTheme, portalTheme } from '../theme';
import { Container } from './Root.styled';

// Don't set up Sentry when running tests
if (process.env.NODE_ENV !== 'test') {
  sentry.setup();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const withRouter: FunctionComponent<Props> = (props) => {
  const children = props.children;

  if (!props.router) {
    return <Router>{children}</Router>;
  }

  return React.cloneElement(props.router, {
    children: props.children,
  });
};

interface Props {
  children?: React.ReactNode;
  authService: CognitoWrapperService;
  className?: string;
  clinicToken: string;
  dispatch: Dispatch;
  features: object;
  language: string;
  queryClient?: QueryClient;
  translations: TranslationData;
  user: User;
  router?: React.ReactElement;
}

const Root: FunctionComponent<Props> = (props) => {
  function onApiUnauthorized() {
    props.dispatch(new Logout({ isCognito: false }));
  }

  const clientArgs = {
    clinicToken: props.clinicToken,
    authService: props.authService,
    onUnauthorized: onApiUnauthorized,
  };
  const portalApiClient = new PortalApiClient(clientArgs);
  const s3ProxyApiClient = new S3Client(clientArgs);
  const contentApiClient = new ContentApiClient(clientArgs);

  return (
    <NoSsr>
      <FeaturesProvider features={props.features}>
        <DispatchProvider dispatch={props.dispatch}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={materialTheme}>
              <ThemeProvider theme={portalTheme}>
                <QueryClientProvider client={props.queryClient || queryClient}>
                  <ApiClientProvider
                    portal={portalApiClient}
                    s3Proxy={s3ProxyApiClient}
                    content={contentApiClient}
                  >
                    <RealTimeMessagingConnectionProvider
                      clinicToken={clientArgs.clinicToken}
                    >
                      <LanguageProvider
                        language={props.language as SupportedLanguage}
                        translations={props.translations}
                      >
                        <Container className={props.className}>
                          <RootConfigProvider
                            value={{
                              clinicToken: props.clinicToken,
                              user: props.user,
                            }}
                          >
                            {withRouter(props)}
                          </RootConfigProvider>
                        </Container>
                      </LanguageProvider>
                    </RealTimeMessagingConnectionProvider>
                  </ApiClientProvider>
                </QueryClientProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </DispatchProvider>
      </FeaturesProvider>
    </NoSsr>
  );
};

export default Root;
