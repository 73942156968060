import { Omit } from '@material-ui/core';
import { useApiClient } from '@react/lib/api';
import { ConversationCreate } from '@react/lib/api/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryCacheKey } from '../constants';
import { useMessageContentLinkExtraction } from './useMessageContentLinkExtraction';

export function useCreateConversationMutation() {
  const queryClient = useQueryClient();
  const { getContentLinkIds } = useMessageContentLinkExtraction();
  const apiClient = useApiClient().portal;

  return useMutation(
    (conversation: Omit<ConversationCreate, 'metadata'>) => {
      const contentLinkIds = getContentLinkIds(conversation.content);
      return apiClient.createConversation({
        ...conversation,
        metadata: { contentLinkIds },
      });
    },
    {
      onSuccess() {
        Object.keys(queryCacheKey).forEach((cacheKey) => {
          queryClient.invalidateQueries(cacheKey);
        });
      },
    },
  );
}
