import { ModuleWithProviders } from '@angular/core';
import { EditDisabledPipe, UnInviteEnabledPipe, } from '../video-calls/pipes/call.status.pipe';
import { AudioVideoIndicatorsComponent } from './components/audio-video-indicators/audio-video-indicators.component';
import { CameraComponent } from './components/camera/camera.component';
import { ParticipantComponent } from './components/participant/participant.component';
import { ParticipantsHostDirective, VideoCallContainerComponent, } from './containers/video-call.container/video-call.container.component';
import { DeviceService } from './services/device.service';
import { MetaMessageService } from './services/meta-message.service';
import { VideoChatService } from './services/video.chat.service';
var COMPONENTS = [
    AudioVideoIndicatorsComponent,
    CameraComponent,
    ParticipantComponent,
    VideoCallContainerComponent,
];
var DIRECTIVES = [ParticipantsHostDirective];
var PIPES = [UnInviteEnabledPipe, EditDisabledPipe];
var TwilioModule = /** @class */ (function () {
    function TwilioModule() {
    }
    TwilioModule.forRoot = function () {
        return {
            ngModule: TwilioModule,
            providers: [DeviceService, MetaMessageService, VideoChatService],
        };
    };
    return TwilioModule;
}());
export { TwilioModule };
