import styled from '@emotion/styled';

interface SmallProp {
  bold?: boolean;
  block?: boolean;
}

const Small = styled('span', {
  shouldForwardProp: (prop) => ['bold', 'block'].every((item) => prop !== item),
})<SmallProp>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  ${({ block }) => (block ? 'display: block;' : '')};
`;

export const Text = {
  Small,
};
