import React, { ReactElement } from 'react';

interface Prop {
  className?: string;
  width: number;
  height: number;
  children: ReactElement;
}

export const SvgWrapper: React.FC<Prop> = ({
  className,
  width,
  height,
  children,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);
