import { ElementRef, } from '@angular/core';
var InputMaxLengthDirective = /** @class */ (function () {
    function InputMaxLengthDirective(elRef) {
        this.elRef = elRef;
    }
    InputMaxLengthDirective.prototype.onInput = function (e) {
        var element = this.elRef.nativeElement;
        if (element.value.length > this.inputMaxLength) {
            element.value = element.value.substring(0, this.inputMaxLength);
        }
    };
    return InputMaxLengthDirective;
}());
export { InputMaxLengthDirective };
