import styled from '@emotion/styled';

export const Label = styled.label<{
  error?: boolean;
}>`
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.text.primary};
  display: block;
  font-size: 0.75rem;
  margin-bottom: 2px;
  user-select: none;
`;
