import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AddToPackComponent } from '../add-to-pack/add-to-pack.component';
import { DialogWithDismissComponent, } from './../../../core/components/dialog-with-dismiss/dialog-with-dismiss.component';
import { LinkContentComponent } from './../link-content/link-content.component';
var MoveEntriesArgs = /** @class */ (function () {
    function MoveEntriesArgs() {
    }
    return MoveEntriesArgs;
}());
export { MoveEntriesArgs };
var DeleteEntriesArgs = /** @class */ (function () {
    function DeleteEntriesArgs() {
    }
    return DeleteEntriesArgs;
}());
export { DeleteEntriesArgs };
var ContentListTableComponent = /** @class */ (function () {
    function ContentListTableComponent(dialog, change) {
        this.dialog = dialog;
        this.change = change;
        this.createFolder = new EventEmitter();
        this.goto = new EventEmitter();
        this.deleteAllSelected = new EventEmitter();
        this.moveAllSelected = new EventEmitter();
        this.deleteCategory = new EventEmitter();
        this.viewChildren = new EventEmitter();
        this.config = {};
    }
    ContentListTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._updateDataSource();
        this.displayedColumns$ = combineLatest(this.isMultiLingual, this.isGlobalPortal$).pipe(map(function (_a) {
            var isMultiLingual = _a[0], isGlobalPortal = _a[1];
            return [
                'select',
                !isGlobalPortal && 'fromDataSync',
                isMultiLingual && 'translations',
                !isGlobalPortal && 'link',
                'name',
                'isEmpty',
                !isGlobalPortal && 'sendToAll',
                'salveModified',
                'lastModifiedBy',
            ].filter(function (x) { return !!x; });
        }));
        this.isGlobalPortal$.subscribe(function (t) { return (_this.isGlobalPortal = t); });
    };
    ContentListTableComponent.prototype.ngOnChanges = function (changes) {
        var content = changes.content, categoryId = changes.categoryId;
        if (content && changes.content.currentValue) {
            this._updateDataSource();
        }
        if (categoryId &&
            this.paginator &&
            categoryId.currentValue &&
            categoryId.previousValue) {
            if (categoryId.currentValue !== categoryId.previousValue) {
                this.paginator.firstPage();
            }
        }
    };
    ContentListTableComponent.prototype.noLinkedItemsSelected = function () {
        // do not show link content option if the folder is associated with site or staff
        if (this.category.issite === true || this.category.isstaff === true) {
            return false;
        }
        return this.selection.selected.every(function (r) { return !(r.hasChildren || r.parentId); });
    };
    ContentListTableComponent.prototype.noSyncedItemsSelected = function () {
        return this.selection.selected.every(function (r) { return !r.fromDataSync; });
    };
    ContentListTableComponent.prototype.noLinkedOrSyncedItems = function () {
        return this.selection.selected.every(function (r) { return !(r.fromDataSync && (r.hasChildren || r.parentId)); });
    };
    ContentListTableComponent.prototype.someSelected = function () {
        return this.selection.selected.length > 0;
    };
    ContentListTableComponent.prototype.selectTooltip = function (row) {
        return row.fromDataSync && (row.hasChildren || row.parentId)
            ? this.text.LinkedAndSynced
            : '';
    };
    /** Whether the number of selected elements matches the total number of rows. */
    ContentListTableComponent.prototype.isAllSelected = function () {
        return this.selection.selected.length === this.dataSource.data.length;
    };
    ContentListTableComponent.prototype.clearSelection = function () {
        this.selection.clear();
        this.change.markForCheck();
        this.change.detectChanges();
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    ContentListTableComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected()
            ? this.clearSelection()
            : this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    ContentListTableComponent.prototype._updateDataSource = function () {
        var _this = this;
        this.dataSource = new MatTableDataSource(this.content);
        setTimeout(function () {
            _this.dataSource.paginator = _this.paginator;
            _this.dataSource.sort = _this.sort;
        });
        this.selection = new SelectionModel(true, []);
    };
    ContentListTableComponent.prototype._selectedItems = function () {
        return this.selection.selected.map(function (r) { return r.id; });
    };
    // Event Emitters
    // ===========================
    ContentListTableComponent.prototype.goToContent = function (row) {
        this.goto.emit(row);
    };
    ContentListTableComponent.prototype.deleteItems = function () {
        // Check if any of selected elements is a linked element
        for (var _i = 0, _a = this.selection.selected; _i < _a.length; _i++) {
            var selectedItem = _a[_i];
            if (selectedItem.hasChildren) {
                this.blockDeletion(selectedItem.name);
                // return would end the loop
                return;
            }
        }
        // If no selected element is linked, emit for deletion
        this.deleteAllSelected.emit({
            entries: this._selectedItems(),
            categoryId: this.categoryId,
        });
    };
    ContentListTableComponent.prototype.moveItems = function () {
        this.moveAllSelected.emit({
            entries: this._selectedItems(),
            categoryId: this.categoryId,
        });
    };
    ContentListTableComponent.prototype.linkItems = function () {
        var _this = this;
        var linkEntriesDialog = this.dialog.open(LinkContentComponent, {
            data: {
                text: this.text,
                selectedItems: this.selection.selected,
                contentCategoryId: this.categoryId,
            },
            width: '600px',
        });
        linkEntriesDialog
            .afterClosed()
            .pipe(tap(function (outcome) {
            if (outcome) {
                _this.clearSelection();
            }
        }), take(1))
            .subscribe();
    };
    ContentListTableComponent.prototype.blockDeletion = function (contentName) {
        var _this = this;
        var data = {
            message: this.text.DeleteItemBlock(contentName),
            text: {
                Dismiss: this.text.OK,
            },
        };
        var deleteItemsDialog = this.dialog.open(DialogWithDismissComponent, {
            data: data,
        });
        deleteItemsDialog.afterClosed().subscribe(function () { return _this.clearSelection(); });
    };
    ContentListTableComponent.prototype.deleteFolder = function () {
        this.deleteCategory.emit(this.categoryId);
    };
    ContentListTableComponent.prototype.handleLinkClick = function (row) {
        if (row.link !== 0) {
            this.viewChildren.emit({
                // if child selected, show parent with child at top of list
                // and selected, else just pass parent id and load all children,
                // all of which are unselected
                parentId: row.link === 1 ? row.parentId : row.id,
                contentId: row.link === 1 ? row.id : null,
                categoryId: row.categoryId,
            });
        }
    };
    ContentListTableComponent.prototype.addToContentPack = function () {
        var _this = this;
        var addToPackDialog = this.dialog.open(AddToPackComponent, {
            data: {
                text: this.text,
                selectedItems: this.selection.selected,
            },
            width: '600px',
        });
        addToPackDialog
            .afterClosed()
            .pipe(tap(function (outcome) {
            if (outcome) {
                _this.clearSelection();
            }
        }), take(1))
            .subscribe();
    };
    return ContentListTableComponent;
}());
export { ContentListTableComponent };
