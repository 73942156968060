import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as fromAuth from '../reducers';

import * as AuthActions from '../actions/auth.actions';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _store: Store<fromAuth.State>) {}

  canActivate(): Observable<boolean> {
    return this._store.pipe(
      select(fromAuth.getUser),
      map((user) => {
        if (!user) {
          this._store.dispatch(new AuthActions.LoginRedirect());
          return false;
        }
        if (!user.jwtToken) {
          this._store.dispatch(new AuthActions.Logout({ isCognito: false }));
          return false;
        }
        return true;
      }),
      take(1),
    );
  }
}
