import { Box, CircularProgress, Tab } from '@material-ui/core';
import { Button } from '@react/components/Button';
import { InfiniteList, List } from '@react/components/filter/list';
import TabPanel from '@react/components/filter/tabPanel';
import { SIDERecipeEventType, SIDEStatus } from '@react/lib/api/enum';
import {
  AppointmentType,
  DrugType,
  IFilters,
  IKeyNumVal,
} from '@react/lib/api/types';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import {
  useAppointmentTypesQuery,
  useDrugTypesQuery,
} from '../../../../admin/hooks';
import {
  LoaderWrapper,
  StyledActionsContainer,
  StyledSearchBar,
  StyledTabs,
} from './index.styled';

interface IProps {
  filters: IFilters;
  apply: (filters: IFilters) => void;
}

const RecipeListFilters: FunctionComponent<IProps> = ({ filters, apply }) => {
  const [localFilters, setLocalFilters] = useState<IFilters>(filters);
  const [selectedTab, setSelectedTab] = useState(0);
  const [apptSearchInputValue, setApptSearchInputValue] = useState('');
  const apptSearchTerm = useDebouncedState(apptSearchInputValue, 500);
  const [drugSearchInputValue, setDrugSearchInputValue] = useState('');
  const drugSearchTerm = useDebouncedState(drugSearchInputValue, 500);

  const {
    data: appointmentTypes,
    isLoading: isApptTypesLoading,
    fetchNextPage: fetchNextApptTypes,
    hasNextPage: hasNextPageForApptTypes,
    isFetchingNextPage: isFetchingNextApptTypes,
  } = useAppointmentTypesQuery({ searchTerm: apptSearchTerm });

  const {
    data: drugTypes,
    isLoading: isDrugTypesLoading,
    fetchNextPage: fetchNextDrugTypes,
    hasNextPage: hasNextPageForDrugTypes,
    isFetchingNextPage: isFetchingNextDrugTypes,
  } = useDrugTypesQuery({ searchTerm: drugSearchTerm });

  const { t } = useTranslations();

  function handleChange(event: React.ChangeEvent<{}>, value: number) {
    setSelectedTab(value);
  }

  function loadMoreAppointmentTypes() {
    const shouldFetchNextPage =
      hasNextPageForApptTypes &&
      !isApptTypesLoading &&
      !isFetchingNextApptTypes;
    if (shouldFetchNextPage) {
      fetchNextApptTypes();
    }
  }

  function loadMoreDrugTypes() {
    const shouldFetchNextPage =
      hasNextPageForDrugTypes &&
      !isDrugTypesLoading &&
      !isFetchingNextDrugTypes;
    if (shouldFetchNextPage) {
      fetchNextDrugTypes();
    }
  }

  function setEventType(key: keyof typeof SIDERecipeEventType) {
    const val = localFilters.eventType === key ? undefined : key;
    setLocalFilters({
      ...localFilters,
      eventType: val,
      drugTypes: undefined,
      appointmentTypes: undefined,
    });
  }

  function setSideStatus(key: keyof typeof SIDEStatus) {
    let status: Array<keyof typeof SIDEStatus> = Array.isArray(
      localFilters.status,
    )
      ? Array.from(localFilters.status)
      : [];
    if (status.includes(key)) {
      status = Array.from(status).filter((item) => item !== key);
    } else {
      status.push(key);
    }

    setLocalFilters({
      ...localFilters,
      status,
    });
  }

  function setAppointmentTypes(type: AppointmentType) {
    let typeList: IKeyNumVal[] = Array.isArray(localFilters.appointmentTypes)
      ? Array.from(localFilters.appointmentTypes)
      : [];
    const data = typeList.find((item) => item.value === type.id);
    if (data) {
      typeList = Array.from(typeList).filter((item) => item.value !== type.id);
    } else {
      typeList.push({
        key: type.name || '',
        value: type.id,
      });
    }

    setLocalFilters({
      ...localFilters,
      drugTypes: undefined,
      eventType: SIDERecipeEventType.Appointment,
      appointmentTypes: typeList,
    });
  }

  function setDrugTypes(type: DrugType) {
    let typeList: IKeyNumVal[] = Array.isArray(localFilters.drugTypes)
      ? Array.from(localFilters.drugTypes)
      : [];
    const data = typeList.find((item) => item.value === type.id);
    if (data) {
      typeList = Array.from(typeList).filter((item) => item.value !== type.id);
    } else {
      typeList.push({
        key: type.name || '',
        value: type.id,
      });
    }

    setLocalFilters({
      ...localFilters,
      drugTypes: typeList,
      eventType: SIDERecipeEventType.Medication,
      appointmentTypes: undefined,
    });
  }

  return (
    <>
      <Box>
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab role="tab" label={t.Admin.SIDE.FilterTabEventType} />
          <Tab role="tab" label={t.Admin.SIDE.FilterTabStatus} />
          <Tab role="tab" label={t.Admin.SIDE.FilterTabAppointmentType} />
          <Tab role="tab" label={t.Admin.SIDE.FilterTabDrugType} />
        </StyledTabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <List<string>
          data={Object.keys(SIDERecipeEventType)}
          isActive={(item: string) => item === localFilters.eventType}
          id="EventTypeFilters"
          onClick={(item: string) =>
            setEventType(SIDERecipeEventType[item as SIDERecipeEventType])
          }
          getLabel={(key: string) =>
            t.Admin.SIDE[`${key as keyof typeof SIDERecipeEventType}Event`]
          }
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <List<string>
          data={Object.keys(SIDEStatus)}
          isActive={(item: string) =>
            localFilters.status
              ? localFilters.status.includes(SIDEStatus[item as SIDEStatus])
              : false
          }
          id="SideStatusFilters"
          onClick={(item: string) =>
            setSideStatus(SIDEStatus[item as SIDEStatus])
          }
          getLabel={(key: string) =>
            t.Admin.SIDE[`${key as keyof typeof SIDEStatus}Status`]
          }
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <StyledSearchBar
          value={apptSearchInputValue}
          onChange={setApptSearchInputValue}
          placeholder={t.Admin.SIDE.FilterApptSearchBarPlaceholder}
        />
        {isApptTypesLoading && (
          <LoaderWrapper data-testid="AppointmentType-Loader">
            <CircularProgress />
          </LoaderWrapper>
        )}
        {appointmentTypes && appointmentTypes.pages && !isApptTypesLoading && (
          <InfiniteList<AppointmentType>
            data={appointmentTypes.pages}
            isActive={(item: AppointmentType) => {
              const apptType = Array.isArray(localFilters.appointmentTypes)
                ? localFilters.appointmentTypes.find(
                    (type) => type.value === item.id,
                  )
                : null;

              return apptType ? true : false;
            }}
            id="SideAppointmentTypeFilters"
            loading={isFetchingNextApptTypes}
            onClick={(item: AppointmentType) => setAppointmentTypes(item)}
            getLabel={(item: AppointmentType) => item.name || ''}
            loadMore={loadMoreAppointmentTypes}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <StyledSearchBar
          value={drugSearchInputValue}
          onChange={setDrugSearchInputValue}
          placeholder={t.Admin.SIDE.FilterDrugSearchBarPlaceholder}
        />
        {isDrugTypesLoading && (
          <LoaderWrapper data-testid="DrugTypes-Loader">
            <CircularProgress />
          </LoaderWrapper>
        )}
        {drugTypes && drugTypes.pages && !isDrugTypesLoading && (
          <InfiniteList<DrugType>
            data={drugTypes.pages}
            isActive={(item: DrugType) => {
              const drugType = Array.isArray(localFilters.drugTypes)
                ? localFilters.drugTypes.find((type) => type.value === item.id)
                : null;

              return drugType ? true : false;
            }}
            id="SideDrugTypeFilters"
            loading={isFetchingNextDrugTypes}
            onClick={(item: DrugType) => setDrugTypes(item)}
            getLabel={(item: DrugType) => item.name || ''}
            loadMore={loadMoreDrugTypes}
          />
        )}
      </TabPanel>
      <StyledActionsContainer>
        <Button
          data-testid="btn_apply"
          color="primary"
          variant="contained"
          onClick={() => apply(localFilters)}
        >
          {t.Admin.SIDE.FilterApply}
        </Button>
      </StyledActionsContainer>
    </>
  );
};

export default RecipeListFilters;
