var _this = this;
import * as tslib_1 from "tslib";
import React, { useContext, useRef, useState } from 'react';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
export var RealTimeMessagingConnectionContext = React.createContext({
    connected: false,
    sendTypingEvent: null,
    setOnMessage: null,
    websocketConnect: null,
    websocketDisconnect: null,
});
export var RealTimeMessagingConnectionProvider = function (_a) {
    var children = _a.children, clinicToken = _a.clinicToken;
    var _b = useState(false), connected = _b[0], setConnected = _b[1];
    var connection = useRef(null);
    var onMessages = useRef({});
    var timeoutRef = useRef();
    var websocketConnect = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    if (connection.current && connection.current.readyState === 1) {
                        resolve(connection.current);
                        return;
                    }
                    // Clearing the connection object probably overzealously, but just to be sure any old websocket connections are not still attached.
                    connection.current = null;
                    var endpoint = environment.api.messagingWs.endpoint + "?clinicToken=" + clinicToken;
                    connection.current = new WebSocket(endpoint);
                    resolve(connection.current);
                    connection.current.onopen = function () {
                        if (connection.current) {
                            setConnected(true);
                            heartbeat();
                        }
                    };
                })];
        });
    }); };
    var websocketDisconnect = function () {
        clearTimeout(timeoutRef.current);
        if (connection.current) {
            connection.current.close();
        }
    };
    var receiveMessage = function () {
        if (!connection.current) {
            return;
        }
        try {
            connection.current.onmessage = function (event) {
                var data = JSON.parse(event.data);
                if (!isTypingEvent(data)) {
                    return;
                }
                Object.values(onMessages.current).map(function (onMessage) {
                    if (onMessage) {
                        onMessage(data);
                    }
                });
            };
        }
        catch (error) {
            console.error('An error occurred when receiving a message');
            console.error(error);
        }
    };
    var setOnMessage = function (onMessage, socketIdToRemove) {
        var _a;
        var socketId = uuidv4();
        if (onMessage) {
            onMessages.current = tslib_1.__assign({}, onMessages.current, (_a = {}, _a[socketId] = onMessage, _a));
            receiveMessage();
            return socketId;
        }
        else {
            if (socketIdToRemove) {
                var _b = onMessages.current, _c = socketIdToRemove, oldOnMessage = _b[_c], rest = tslib_1.__rest(_b, [typeof _c === "symbol" ? _c : _c + ""]);
                onMessages.current = rest;
            }
        }
    };
    var sendTypingEvent = function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var message, e_1;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    if (!(connection.current && connection.current.readyState === 1)) return [3 /*break*/, 1];
                    message = {
                        message: 'typing',
                        clinicToken: clinicToken,
                        contentLength: data.content.length || 0,
                        messageId: data.messageId,
                        user: data.user,
                    };
                    connection.current.send(JSON.stringify(message));
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, websocketConnect()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var isTypingEvent = function (data) {
        return (typeof data === 'object' && data !== null && data['event'] === 'Typing');
    };
    var heartbeat = function () {
        try {
            if (connection.current) {
                connection.current.send('heartbeat');
                var timeout = setTimeout(heartbeat, 60000);
                var currentTimeout = timeoutRef.current;
                clearTimeout(currentTimeout);
                timeoutRef.current = timeout;
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    return (React.createElement(RealTimeMessagingConnectionContext.Provider, { value: {
            connected: connected,
            sendTypingEvent: sendTypingEvent,
            setOnMessage: setOnMessage,
            websocketConnect: websocketConnect,
            websocketDisconnect: websocketDisconnect,
        }, children: children }));
};
export var useRealTimeMessagingContext = function () {
    return useContext(RealTimeMessagingConnectionContext);
};
