import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import * as React from 'react';
var StyledSpan = styled.span(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  user-select: none;\n"], ["\n  color: ",
    ";\n  font-size: ", ";\n  user-select: none;\n"])), function (_a) {
    var isAtMaxLength = _a.isAtMaxLength, theme = _a.theme;
    return isAtMaxLength ? theme.colors.error : theme.colors.text.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.typography.caption.fontSize;
});
function getCharCount(length, maxLength) {
    return maxLength ? length + " / " + maxLength : String(length);
}
export var CharacterCount = function (_a) {
    var className = _a.className, length = _a.length, maxLength = _a.maxLength;
    return (React.createElement(StyledSpan, { className: className, isAtMaxLength: maxLength ? length >= maxLength : false }, getCharCount(length, maxLength)));
};
var templateObject_1;
