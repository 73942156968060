import { Component, Input } from '@angular/core';

@Component({
  selector: 'portal-audio-video-indicators',
  templateUrl: './audio-video-indicators.component.html',
  styleUrls: ['./audio-video-indicators.component.scss'],
})
export class AudioVideoIndicatorsComponent {
  @Input() isAudioEnabled: boolean;
  @Input() isVideoEnabled: boolean;
}
