import { EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import libPhone from 'google-libphonenumber';
import { checkMobileNumber, passwordComplexity, valuesMatch, } from 'src/app/core/utils/validators';
import { countryCodes } from 'src/app/data/country-codes';
var CreateAccountFormComponent = /** @class */ (function () {
    function CreateAccountFormComponent(_fb) {
        this._fb = _fb;
        this.createCoreAccount = new EventEmitter();
        this.allCountryCodes = countryCodes;
        this.createAccountForm = this._fb.group({
            firstName: this._fb.control('', Validators.required),
            lastName: this._fb.control('', Validators.required),
            countryCode: this._fb.control('', Validators.required),
            mobileNumber: this._fb.control('', Validators.required),
            password: this._fb.control('', [
                Validators.required,
                passwordComplexity,
            ]),
            confirmPassword: this._fb.control(''),
        }, {
            validator: [
                valuesMatch('password', 'confirmPassword'),
                checkMobileNumber('mobileNumber', 'countryCode'),
            ],
        });
    }
    Object.defineProperty(CreateAccountFormComponent.prototype, "completingPassword", {
        set: function (isUpdatingPassword) {
            if (isUpdatingPassword) {
                this.createAccountForm.disable();
            }
            else {
                this.createAccountForm.enable();
            }
            this.isUpdatingPassword = isUpdatingPassword;
        },
        enumerable: true,
        configurable: true
    });
    CreateAccountFormComponent.prototype.submitForm = function () {
        var _a = this.createAccountForm.value, firstName = _a.firstName, lastName = _a.lastName, countryCode = _a.countryCode, mobileNumber = _a.mobileNumber, password = _a.password;
        var phoneUtil = libPhone.PhoneNumberUtil.getInstance();
        var PNF = libPhone.PhoneNumberFormat;
        var number = phoneUtil.parseAndKeepRawInput(mobileNumber, countryCode);
        var E164phone = phoneUtil.format(number, PNF.E164);
        var confirmPasswordBody = {
            user: this.cognitoUser,
            password: password,
            requiredAttributes: {
                family_name: lastName,
                given_name: firstName,
                phone_number: E164phone,
            },
        };
        this.createCoreAccount.emit(confirmPasswordBody);
    };
    return CreateAccountFormComponent;
}());
export { CreateAccountFormComponent };
