import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery, useQuery } from 'react-query';
import { queryCacheKey } from '../constants';
import { formatStaff } from '../lib';

export function usePatientStaffListQuery(staffIds?: number[]) {
  const apiClient = useApiClient().portal;

  return useQuery([queryCacheKey.PATIENT_STAFF, staffIds], async () => {
    const response = await apiClient.fetchPatientStaff({ staffIds });
    return { ...response, data: response.data.map(formatStaff) };
  });
}

export function usePatientStaffListInfiniteQuery(filters: { q?: string }) {
  const apiClient = useApiClient().portal;

  return useInfiniteQuery(
    [queryCacheKey.PATIENT_STAFF_INFINITE, filters],
    async ({ pageParam: pageNumber }: { pageParam?: number }) => {
      const response = await apiClient.fetchPatientStaff({
        pageNumber,
        ...filters,
      });
      return { ...response, data: response.data.map(formatStaff) };
    },
    {
      getNextPageParam(lastPage, pages) {
        if (lastPage.data.length === 0) {
          return undefined;
        }
        return pages.length + 1;
      },
      keepPreviousData: true,
    },
  );
}
