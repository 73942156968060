// https://www.twilio.com/docs/video
// https://www.twilio.com/docs/video/javascript-v2-getting-started
// https://www.twilio.com/blog/video-chat-app-asp-net-core-3-angular-8-twilio
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import {
  EditDisabledPipe,
  UnInviteEnabledPipe,
} from '../video-calls/pipes/call.status.pipe';
import { AudioVideoIndicatorsComponent } from './components/audio-video-indicators/audio-video-indicators.component';
import { CameraComponent } from './components/camera/camera.component';
import { ParticipantComponent } from './components/participant/participant.component';
import {
  ParticipantsHostDirective,
  VideoCallContainerComponent,
} from './containers/video-call.container/video-call.container.component';
import { DeviceService } from './services/device.service';
import { MetaMessageService } from './services/meta-message.service';
import { VideoChatService } from './services/video.chat.service';

const COMPONENTS = [
  AudioVideoIndicatorsComponent,
  CameraComponent,
  ParticipantComponent,
  VideoCallContainerComponent,
];

const DIRECTIVES = [ParticipantsHostDirective];

const PIPES = [UnInviteEnabledPipe, EditDisabledPipe];

@NgModule({
  imports: [CommonModule, HttpClientModule, FormsModule, MaterialModule],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
  providers: [DeviceService, MetaMessageService, VideoChatService],
  exports: [...COMPONENTS, ...PIPES],
})
export class TwilioModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: TwilioModule,
      providers: [DeviceService, MetaMessageService, VideoChatService],
    };
  }
}
