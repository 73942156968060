import { Action } from '@ngrx/store';
import { S3OptionsResponse } from '../models/responses/s3-options.response';

export enum S3ActionTypes {
  // Server Side
  GetS3Options = '[Content] Get S3 Options',
  GetS3OptionsSuccess = '[Content] Get S3 Options Succes',
  GetS3OptionsError = '[Content] Get S3 Options Error',
}

// Action Creators
// ==================================================
// Server Side

export class GetS3Options implements Action {
  readonly type = S3ActionTypes.GetS3Options;

  constructor(public payload?: any) {}
}
export class GetS3OptionsSuccess implements Action {
  readonly type = S3ActionTypes.GetS3OptionsSuccess;

  constructor(public payload: S3OptionsResponse) {}
}
export class GetS3OptionsError implements Action {
  readonly type = S3ActionTypes.GetS3OptionsError;

  constructor(public payload: any) {}
}

export type S3ActionsUnion =
  // Server Side
  GetS3Options | GetS3OptionsSuccess | GetS3OptionsError;
