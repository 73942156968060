import * as tslib_1 from "tslib";
import { Empty, InfiniteScroll, SearchIcon, Tab, TabPanel, Tabs, } from '@react/components';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useEffect } from 'react';
import { STARRED_DELAY_MS, Status, StatusName } from '../constants';
import { useConversationListQuery, useConversationsCountByStatusQuery, useConversationUpdateMutation, useGetConversationActionHandler, useGetConversationActions, useMessageMutation, useMessagingNotifications, } from '../hooks';
import { ConversationCard } from './ConversationCard';
import { ConversationsContainer } from './ui';
export var Conversations = function (_a) {
    var filters = _a.filters, folders = _a.folders, getTypingConversationMessage = _a.getTypingConversationMessage, onTabChange = _a.onTabChange, onConversationClick = _a.onConversationClick, selectedConversationId = _a.selectedConversationId, selectedTab = _a.selectedTab, assignClinicUsers = _a.assignClinicUsers;
    var t = useTranslations().t;
    var handleAction = useGetConversationActionHandler();
    var getActions = useGetConversationActions('conversation');
    var debouncedQuery = useDebouncedState(filters.q, 500);
    useMessagingNotifications(filters);
    var counts = useConversationsCountByStatusQuery(filters, debouncedQuery);
    var conversationsQueryResult = useConversationListQuery(filters, debouncedQuery);
    var updateMessageAsync = useMessageMutation().mutateAsync;
    var updateConversationAsync = useConversationUpdateMutation().mutateAsync;
    var showConversationType = filters.folderIds == null || filters.folderIds.length !== 1;
    var handleChange = function (_, value) {
        onTabChange(value);
    };
    var handleConversationClick = function (conversation) {
        onConversationClick(conversation);
        // Mark as read by the clinic
        if (conversation.patientsent && !conversation.clinicread) {
            updateMessageAsync({
                id: conversation.messageitemid,
                messageid: conversation.messageid,
                read: true,
            });
        }
    };
    var handleActionClick = function (conversation, action) {
        handleAction(action, conversation);
    };
    var timeoutId;
    var toggleStarConversation = function (conversation) {
        timeoutId = setTimeout(function () {
            updateConversationAsync(tslib_1.__assign({}, conversation, { isstarred: conversation.isstarred }));
        }, STARRED_DELAY_MS);
    };
    useEffect(function () {
        return function () { return clearTimeout(timeoutId); };
    }, []);
    var getConversationCardProps = function (conversation) { return (tslib_1.__assign({}, conversation, { key: conversation.messageid, onClick: handleConversationClick, onStarClick: toggleStarConversation, onActionClick: handleActionClick, showType: showConversationType, actions: getActions(conversation, folders), selected: conversation.messageid === selectedConversationId, userTypingMessage: getTypingConversationMessage(conversation.messageid), assignClinicUsers: assignClinicUsers })); };
    var conversationsContainerProps = {
        infiniteQueryResult: conversationsQueryResult,
        endOfResults: (React.createElement("div", { className: "conversation-end-of-list-message" },
            React.createElement(Empty, { message: t.Messages.EndOfConversation }))),
        noResults: (React.createElement(Empty, { icon: React.createElement(SearchIcon, null), message: t.Messages.NoSearchResults })),
    };
    return (React.createElement(ConversationsContainer, null,
        React.createElement(Tabs, { value: selectedTab, onChange: handleChange, scrollButtons: "auto", variant: "scrollable" },
            React.createElement(Tab, { count: counts[StatusName.STARRED] || null, label: t.Messages.StarredTab, tabIndex: Status.STARRED }),
            React.createElement(Tab, { count: counts[StatusName.OTHERS] || null, label: t.Messages.EverythingElseTab, tabIndex: Status.OTHERS })),
        React.createElement(TabPanel, { value: selectedTab, index: Status.STARRED },
            React.createElement(InfiniteScroll, tslib_1.__assign({}, conversationsContainerProps, { renderItem: function (conversation) { return (React.createElement(ConversationCard, tslib_1.__assign({}, getConversationCardProps(conversation)))); } }))),
        React.createElement(TabPanel, { value: selectedTab, index: Status.OTHERS },
            React.createElement(InfiniteScroll, tslib_1.__assign({}, conversationsContainerProps, { renderItem: function (conversation) { return (React.createElement(ConversationCard, tslib_1.__assign({}, getConversationCardProps(conversation)))); } })))));
};
