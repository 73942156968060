import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 20px;
  position: relative;

  .newMessageModal-title {
    text-align: center;
    margin-bottom: 16px;
  }

  .newMessageModal-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .newMessageModal-input {
    margin-bottom: 20px;

    &.newMessageModal-withCharCount {
      margin-bottom: 5px;
    }
  }

  .newMessageModal-sendButton {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
