import { Inject, Pipe, PipeTransform } from '@angular/core';
import prettyBytes from 'pretty-bytes';
import { LocalisationService } from 'src/app/localisation/localisation.service';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  /**
   *
   */
  constructor(
    @Inject(LocalisationService)
    private _localisationService: LocalisationService,
  ) {}

  transform(fileSize: number): string {
    return prettyBytes(fileSize, {
      locale: this._localisationService.getLanguageCode(),
      maximumFractionDigits: 2,
    });
  }
}
