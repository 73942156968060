import ZoomVideo, { VideoClient } from '@zoom/videosdk';
import React, { FC, useContext, useMemo } from 'react';

export const ZoomContext = React.createContext<typeof VideoClient | undefined>(
  undefined
);

export const ZoomProvider: FC = ({ children }) => {
  const zoomClient = useMemo(() => {
    return ZoomVideo.createClient();
  }, []);
  return (
    <ZoomContext.Provider value={zoomClient}>{children}</ZoomContext.Provider>
  );
};
