import * as tslib_1 from "tslib";
import pick from 'lodash/pick';
import qs from 'qs-lite';
import { environment } from '../../../../environments/environment';
var PortalApiClient = /** @class */ (function () {
    function PortalApiClient(props) {
        this._clinicToken = props.clinicToken;
        this._authService = props.authService;
        this._onUnauthorized = props.onUnauthorized;
    }
    PortalApiClient.prototype.fetchAppointmentTypes = function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b, _c = _a.query, query = _c === void 0 ? '' : _c;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_d) {
                return [2 /*return*/, this.fetch("appointment/types?pageNumber=" + pageParam + "&pageSize=25" + (query && "&q=" + query))];
            });
        });
    };
    PortalApiClient.prototype.fetchAppointmentTypeDetails = function (_a) {
        var id = _a.id;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("appointment/types/" + id)];
            });
        });
    };
    PortalApiClient.prototype.updateAppointmentTypeDetails = function (_a) {
        var id = _a.id, rest = tslib_1.__rest(_a, ["id"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("appointment/types/" + id, {
                        body: JSON.stringify(rest),
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    PortalApiClient.prototype.fetchMessageTemplate = function (_a) {
        var id = _a.id;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("conversations/message-templates/" + id)];
            });
        });
    };
    PortalApiClient.prototype.fetchMessageTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch('conversations/message-templates')];
            });
        });
    };
    PortalApiClient.prototype.createMessageTemplate = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch('conversations/message-templates', {
                        body: JSON.stringify(data),
                        headers: { 'content-type': 'application/json' },
                        method: 'POST',
                    })];
            });
        });
    };
    PortalApiClient.prototype.deleteMessageTemplate = function (_a) {
        var id = _a.id;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("conversations/message-templates/" + id, {
                        method: 'DELETE',
                    })];
            });
        });
    };
    PortalApiClient.prototype.updateMessageTemplate = function (_a) {
        var id = _a.id, rest = tslib_1.__rest(_a, ["id"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("conversations/message-templates/" + id, {
                        body: JSON.stringify(rest),
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    PortalApiClient.prototype.fetchMessageTemplateMessage = function (_a) {
        var id = _a.id, patientId = _a.patientId;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("conversations/message-templates/" + id + "/message?patientId=" + patientId, {
                        headers: { 'content-type': 'application/json' },
                        method: 'GET',
                    })];
            });
        });
    };
    PortalApiClient.prototype.fetchMessageTemplateVariables = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch('conversations/message-templates/variables')];
            });
        });
    };
    /** Operations associated with SIDE */
    PortalApiClient.prototype.fetchSIDERecipes = function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b, _c = _a.query, query = _c === void 0 ? '' : _c, _d = _a.filters, filters = _d === void 0 ? {} : _d, _e = _a.ignorePaging, ignorePaging = _e === void 0 ? false : _e;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appointmentTypes, drugTypes, url, queryString;
            return tslib_1.__generator(this, function (_f) {
                appointmentTypes = Array.isArray(filters.appointmentTypes)
                    ? filters.appointmentTypes.map(function (item) { return item.value; }).join(',')
                    : '';
                drugTypes = Array.isArray(filters.drugTypes)
                    ? filters.drugTypes.map(function (item) { return item.value; }).join(',')
                    : '';
                url = 'side/recipes';
                queryString = "" + (!ignorePaging ? "pageNumber=" + pageParam + "&pageSize=50" : '') + (query && "&q=" + query) + (filters.eventType ? "&eventType=" + filters.eventType : '') + (Array.isArray(filters.status) && filters.status.length === 1
                    ? "&status=" + filters.status[0]
                    : '') + (Array.isArray(filters.appointmentTypes) &&
                    filters.appointmentTypes.length > 0
                    ? "&appointmentTypes=" + appointmentTypes
                    : '') + (Array.isArray(filters.drugTypes) && filters.drugTypes.length > 0
                    ? "&drugTypes=" + drugTypes
                    : '');
                if (queryString !== '') {
                    url = url + "?" + queryString;
                }
                return [2 /*return*/, this.fetch(url)];
            });
        });
    };
    PortalApiClient.prototype.fetchSIDERecipe = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("side/recipes/" + id)];
            });
        });
    };
    PortalApiClient.prototype.fetchSIDERecipeTriggers = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("side/recipes/" + id + "/triggers")];
            });
        });
    };
    PortalApiClient.prototype.fetchSIDERecipeActions = function (_a) {
        var id = _a.id, _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_c) {
                return [2 /*return*/, this.fetch("side/recipes/" + id + "/actions?pageNumber=" + pageParam + "&pageSize=10")];
            });
        });
    };
    PortalApiClient.prototype.exportSIDERecipes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("side/export/recipes")];
            });
        });
    };
    PortalApiClient.prototype.updateSideRecipe = function (_a) {
        var id = _a.id, rest = tslib_1.__rest(_a, ["id"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                return [2 /*return*/, this.fetch("side/recipes/" + id, {
                        body: JSON.stringify(rest),
                        headers: { 'content-type': 'application/json' },
                        method: 'PUT',
                    })];
            });
        });
    };
    /** Operations Associated with Medications */
    PortalApiClient.prototype.fetchDrugTypes = function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b, _c = _a.query, query = _c === void 0 ? '' : _c;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_d) {
                return [2 /*return*/, this.fetch("medications/types?pageNumber=" + pageParam + "&pageSize=25" + (query && "&q=" + query))];
            });
        });
    };
    /** Operations Associated with Conversations */
    PortalApiClient.prototype.fetchConversations = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var q;
            return tslib_1.__generator(this, function (_a) {
                q = qs.stringify(tslib_1.__assign({}, this.getDefaultPaginationQuery(), filters));
                return [2 /*return*/, this.fetch("conversations/?" + q)];
            });
        });
    };
    PortalApiClient.prototype.updateConversation = function (_a, partial) {
        var messageid = _a.messageid, conversation = tslib_1.__rest(_a, ["messageid"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload;
            return tslib_1.__generator(this, function (_b) {
                payload = tslib_1.__assign({}, (partial && conversation), (!partial &&
                    pick(conversation, [
                        'messageid',
                        'messagetypeid',
                        'messagestatusid',
                        'isstarred',
                    ])));
                return [2 /*return*/, this.fetch("conversations/" + messageid, {
                        body: JSON.stringify(payload),
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    PortalApiClient.prototype.createConversation = function (conversation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload;
            return tslib_1.__generator(this, function (_a) {
                payload = pick(conversation, [
                    'patientid',
                    'messagetypeid',
                    'messagesubject',
                    'content',
                    'metadata',
                ]);
                return [2 /*return*/, this.fetch("conversations/", {
                        body: JSON.stringify(payload),
                        headers: { 'content-type': 'application/json' },
                        method: 'POST',
                    })];
            });
        });
    };
    PortalApiClient.prototype.fetchConversationsCountByStatus = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var q;
            return tslib_1.__generator(this, function (_a) {
                q = qs.stringify(filters);
                return [2 /*return*/, this.fetch("conversations/count?" + q)];
            });
        });
    };
    PortalApiClient.prototype.fetchConversationFolders = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("conversations/folders")];
            });
        });
    };
    PortalApiClient.prototype.fetchConversationAssignedFolder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("conversations/count/my-assigned")];
            });
        });
    };
    PortalApiClient.prototype.fetchPatientStaff = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var q;
            return tslib_1.__generator(this, function (_a) {
                q = qs.stringify(tslib_1.__assign({}, filters, { staffids: filters.staffIds }));
                return [2 /*return*/, this.fetch("patients/staff?" + q)];
            });
        });
    };
    PortalApiClient.prototype.fetchConversationById = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("conversations/" + id)];
            });
        });
    };
    PortalApiClient.prototype.fetchMessages = function (_a) {
        var messageid = _a.messageid, filters = tslib_1.__rest(_a, ["messageid"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var q;
            return tslib_1.__generator(this, function (_b) {
                q = qs.stringify(tslib_1.__assign({}, this.getDefaultPaginationQuery(), filters));
                return [2 /*return*/, this.fetch("conversations/" + messageid + "/messages?" + q)];
            });
        });
    };
    PortalApiClient.prototype.createMessage = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload;
            return tslib_1.__generator(this, function (_a) {
                payload = JSON.stringify(message);
                return [2 /*return*/, this.fetch("conversations/" + message.messageid + "/messages", {
                        body: payload,
                        headers: { 'content-type': 'application/json' },
                        method: 'POST',
                    })];
            });
        });
    };
    PortalApiClient.prototype.updateMessage = function (_a) {
        var id = _a.id, messageid = _a.messageid, message = tslib_1.__rest(_a, ["id", "messageid"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload;
            return tslib_1.__generator(this, function (_b) {
                payload = pick(message, ['read']);
                return [2 /*return*/, this.fetch("conversations/" + messageid + "/messages/" + id, {
                        body: JSON.stringify(payload),
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    PortalApiClient.prototype.removeMessage = function (conversationId, messageId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("conversations/" + conversationId + "/messages/" + messageId, {
                        method: 'DELETE',
                    })];
            });
        });
    };
    /** Operations Associated with ClinicUsers / My User */
    /** Operations associated with Clinic Users */
    PortalApiClient.prototype.fetchAdminClinicUsers = function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b, _c = _a.query, query = _c === void 0 ? '' : _c, _d = _a.filters, filters = _d === void 0 ? {} : _d, _e = _a.excludeInternal, excludeInternal = _e === void 0 ? false : _e;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var statusValues, statusFilter, queryParam, excludeParam;
            return tslib_1.__generator(this, function (_f) {
                statusValues = Array.isArray(filters.status) && filters.status.length > 0
                    ? filters.status.map(function (status) { return status; }).join(',')
                    : null;
                statusFilter = statusValues ? "&status=" + statusValues : '';
                queryParam = query ? "&q=" + query : '';
                excludeParam = excludeInternal ? '&excludeInternal=true' : '';
                return [2 /*return*/, this.fetch("staff?pageNumber=" + pageParam + "&pageSize=50&sortType=lastname" + queryParam + statusFilter + excludeParam)];
            });
        });
    };
    PortalApiClient.prototype.fetchClinicUsers = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var q;
            return tslib_1.__generator(this, function (_a) {
                q = qs.stringify(tslib_1.__assign({}, filters));
                return [2 /*return*/, this.fetch("staff?" + q)];
            });
        });
    };
    PortalApiClient.prototype.fetchMe = function (clinics) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clinicsParam;
            return tslib_1.__generator(this, function (_a) {
                clinicsParam = clinics ? '?clinics=true' : '';
                return [2 /*return*/, this.fetch("staff/me" + clinicsParam)];
            });
        });
    };
    PortalApiClient.prototype.exportAdminUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("staff/export")];
            });
        });
    };
    PortalApiClient.prototype.inviteStaff = function (staffId, message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payload;
            return tslib_1.__generator(this, function (_a) {
                payload = {
                    message: message,
                };
                return [2 /*return*/, this.fetch("staff/invite/" + staffId, {
                        body: JSON.stringify(payload),
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    PortalApiClient.prototype.deactivateStaff = function (staffId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("staff/deactivate/" + staffId, {
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    PortalApiClient.prototype.sendTemporaryPassword = function (staffId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("staff/temporary-password/" + staffId, {
                        headers: { 'content-type': 'application/json' },
                        method: 'PATCH',
                    })];
            });
        });
    };
    /** Operations Associated with Patients */
    PortalApiClient.prototype.fetchPatients = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var q;
            return tslib_1.__generator(this, function (_a) {
                q = qs.stringify(tslib_1.__assign({}, this.getDefaultPaginationQuery(), filters));
                return [2 /*return*/, this.fetch("patients/?" + q)];
            });
        });
    };
    /** Operations Associated with Staff */
    PortalApiClient.prototype.fetchStaff = function (staffId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("staff/" + staffId)];
            });
        });
    };
    PortalApiClient.prototype.fetchStaffMe = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("staff/me")];
            });
        });
    };
    PortalApiClient.prototype.updateStaff = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body;
            return tslib_1.__generator(this, function (_a) {
                body = {
                    profilephotouri: url,
                };
                return [2 /*return*/, this.fetch("staff/me", {
                        method: 'PUT',
                        headers: { 'content-type': 'application/json' },
                        body: JSON.stringify(body),
                    })];
            });
        });
    };
    /** Operations associated with Super Admin Export */
    PortalApiClient.prototype.exportMessages = function (from, to) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("conversations/export/?from=" + from + "&to=" + to)];
            });
        });
    };
    /** Operations Associated with Forms */
    PortalApiClient.prototype.fetchResubmittablePatientForms = function (key, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("forms/list?" + key + "=" + encodeURIComponent(value))];
            });
        });
    };
    PortalApiClient.prototype.fetchResubmittablePatientLookupForms = function (key, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("forms/lookup?" + key + "=" + encodeURIComponent(value))];
            });
        });
    };
    PortalApiClient.prototype.resubmitPatientForm = function (formId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("forms/" + formId + "/resubmit", {
                        headers: { 'content-type': 'application/json' },
                        method: 'PUT',
                    })];
            });
        });
    };
    PortalApiClient.prototype.exportSIDEData = function (sideRecipeId, from, to) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.fetch("side/export/recipe/" + sideRecipeId + "/?from=" + from + "&to=" + to)];
            });
        });
    };
    /** Operations associated with Super Admin Export - End */
    PortalApiClient.prototype.getDefaultPaginationQuery = function () {
        return { pageNumber: 1, pageSize: 25 };
    };
    /**
     * Essentially a wrapper around `fetch` that sets some mandatory headers
     * and behavior.
     */
    PortalApiClient.prototype.getDefaultFetchHeaders = function (options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var authUser, headers;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._authService.getAuthSession()];
                    case 1:
                        authUser = _a.sent();
                        headers = tslib_1.__assign({}, (options != null && options.headers != null ? options.headers : {}), { accept: 'application/json', authorization: authUser.getIdToken().getJwtToken(), 'x-salve-clinic-token': this._clinicToken });
                        return [2 /*return*/, headers];
                }
            });
        });
    };
    PortalApiClient.prototype.handleFetchResponse = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var content, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (response.status === 401) {
                            this._onUnauthorized();
                            throw new Error('Unauthorized');
                        }
                        if (!response.ok) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, response.text()];
                    case 2:
                        content = _a.sent();
                        return [2 /*return*/, content.length > 0 ? JSON.parse(content) : undefined];
                    case 3:
                        err_1 = _a.sent();
                        throw new Error("Error parsing body as JSON: " + err_1.message);
                    case 4: throw new Error('Network response was not ok');
                }
            });
        });
    };
    PortalApiClient.prototype.fetch = function (path, 
    /** Force `headers` to be a simple object for ease of use */
    options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headers, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDefaultFetchHeaders(options)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, fetch("" + environment.api.portal.endpoint + path, tslib_1.__assign({}, options, { headers: headers }))];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, this.handleFetchResponse(response)];
                }
            });
        });
    };
    return PortalApiClient;
}());
export { PortalApiClient };
