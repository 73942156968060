import styled from '@emotion/styled';
import { Tabs as MuiTabs } from '@material-ui/core';

export const Tabs = styled(MuiTabs)`
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.dark};
  width: 100%;
  height: 48px;

  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  & ~ [role='tabpanel'] {
    height: calc(100% - 48px);
  }
`;
