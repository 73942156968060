import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import get from 'lodash/get';
import set from 'lodash/set';

import { localStorageSync } from 'ngrx-store-localstorage';

import { RouterStateUrl } from '../shared/utils';

import * as fromRouter from '@ngrx/router-store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import { AuthState } from '../auth/reducers';
import * as fromClinic from '../core/reducers/clinic.reducer';
import * as fromLayout from '../core/reducers/layout.reducer';
import * as fromToast from '../core/reducers/toast.reducer';
import * as fromMessages from '../messaging/reducers';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  clinic: fromClinic.State;
  layout: fromLayout.State;
  messages: fromMessages.MessagesState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  toasts: fromToast.ToastsState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  clinic: fromClinic.reducer,
  layout: fromLayout.reducer,
  messages: fromMessages.reducer,
  router: fromRouter.routerReducer,
  toasts: fromToast.reducer,
};

// sync local storage
export function localStorageSyncReducer(
  reducer: ActionReducer<State>,
): ActionReducer<State> {
  return localStorageSync({
    keys: [
      'cms',
      {
        auth: {
          // SA-2966 - `status.user.groups` has been added and because state is saved
          // to LocalStorage this won't exist the first time it's rehydrated.
          // We can add it in if it doesn't exist so that we can consider it
          // to always have a value.
          deserialize: (json: AuthState) =>
            get(json, 'status.user') != null
              ? set(
                  json,
                  'status.user.groups',
                  get(json, 'status.user.groups') || [],
                )
              : json,
        },
      },
    ],
    rehydrate: true,
  })(reducer);
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers = [localStorageSyncReducer];

/**
 * Clinic Accessors
 */
export const getClinicState = createFeatureSelector<fromClinic.State>('clinic');
export const getLanguages = createSelector(
  getClinicState,
  fromClinic.getLanguages,
);
export const getDefaultLanguage = createSelector(
  getClinicState,
  fromClinic.getDefaultLanguage,
);
export const getDefaultDiallingCode = createSelector(
  getClinicState,
  fromClinic.getDefaultDiallingCode,
);
export const getDefaultTimezone = createSelector(
  getClinicState,
  fromClinic.getDefaultTimezone,
);

export const getIsGlobalPortal = createSelector(
  getClinicState,
  fromClinic.getIsGlobalPortal,
);

export const getHasSetGlobalPortal = createSelector(
  getClinicState,
  fromClinic.getHasSetGlobalPortal,
);

/**
 * Layout Accessors
 */
export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');

export const getShowSidenav = createSelector(
  getLayoutState,
  fromLayout.getShowSidenav,
);

export const getMenuItems = createSelector(
  getLayoutState,
  fromLayout.getMenuItems,
);

export const getActiveMenuItem = createSelector(
  getLayoutState,
  fromLayout.getActiveMenuItem,
);

/**
 * Toast Accessors
 */

export const toastsState = createFeatureSelector<fromToast.ToastsState>(
  fromToast.featureStateName,
);
export const getToastRef = createSelector(toastsState, fromToast.getToastRef);

/**
 * Router Accessors
 */
export const getRouterState =
  createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>(
    'router',
  );

export const getCurrentRouterState = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => state.state,
);

export const getRouteHead = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) =>
    state.state && state.state.section,
);

export const getRouterParams = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) =>
    state.state && state.state.params,
);

export const getRouterPatientId = createSelector(
  getRouterParams,
  (state) => state.patientId,
);

export const getContentId = createSelector(
  getRouterParams,
  (state) => +state.id,
);

export const getContentCategoryTypeId = createSelector(
  getRouterParams,
  (state) => +state.type,
);

export const getRouterUrl = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) =>
    state.state && state.state.url,
);

export const getContentType = createSelector(getRouterUrl, (url: string) => {
  if (/\/cms\/content\/template/.test(url)) {
    return 'template';
  }
  if (/\/cms\/content\/list/.test(url)) {
    return 'list';
  }
  return 'na';
});

export const getMessagesState =
  createFeatureSelector<fromMessages.MessagesState>('messages');

export const getConversationCache = createSelector(
  getMessagesState,
  fromMessages.getConversationCache,
);

export const getStaffProfiles = createSelector(
  getClinicState,
  fromClinic.getStaffProfiles,
);
