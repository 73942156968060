import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export interface InviteUserModalProps {
  open: boolean;
  onClose: () => void;
}

const InviteUserModal: React.FunctionComponent<InviteUserModalProps> = ({
  open,
  onClose,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {children}
    </Dialog>
  );
};

export default InviteUserModal;
