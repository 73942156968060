import * as tslib_1 from "tslib";
import omit from 'lodash/omit';
import { filterAndSortCategories, sortAlphabetically, } from '../../shared/utils';
import { ContentActionTypes, } from '../actions/content.actions';
export var initialState = {
    templates: [],
    loadingContentEntry: false,
    errors: {
        loadingContentEntry: false,
    },
    activeContent: {},
    contentHeaders: {},
    categories: [],
    isSavingContentEntry: false,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b, _c, _d, _e, _f;
    switch (action.type) {
        // Templates
        // ================================================
        case ContentActionTypes.GetTemplatesSuccess: {
            return tslib_1.__assign({}, state, { templates: action.payload });
        }
        case ContentActionTypes.CreateTemplateSuccess: {
            return tslib_1.__assign({}, state, { templates: state.templates.concat(action.payload) });
        }
        case ContentActionTypes.SavingTemplateSuccess: {
            return tslib_1.__assign({}, state, { templates: state.templates.map(function (t) {
                    return t.id === action.payload.id ? action.payload : t;
                }) });
        }
        case ContentActionTypes.DeleteTemplateSuccess: {
            return tslib_1.__assign({}, state, { templates: state.templates.filter(function (t) { return t.id !== action.payload; }) });
        }
        // Content Entries
        // ================================================
        case ContentActionTypes.GetContentEntry: {
            return tslib_1.__assign({}, state, { activeContent: {}, loadingContentEntry: true, errors: {
                    loadingContentEntry: false,
                } });
        }
        case ContentActionTypes.GetContentEntrySuccess: {
            return tslib_1.__assign({}, state, { loadingContentEntry: false, activeContent: action.payload, errors: {
                    loadingContentEntry: false,
                } });
        }
        case ContentActionTypes.GetContentEntryError: {
            return tslib_1.__assign({}, state, { loadingContentEntry: false, errors: {
                    loadingContentEntry: true,
                } });
        }
        case ContentActionTypes.GetContentEntriesSuccess: {
            return tslib_1.__assign({}, state, { contentHeaders: action.payload });
        }
        case ContentActionTypes.CreateEntry: {
            return tslib_1.__assign({}, state, { isSavingContentEntry: true });
        }
        case ContentActionTypes.CreateEntrySuccess: {
            return tslib_1.__assign({}, state, { activeContent: tslib_1.__assign({}, state.activeContent, (_a = {}, _a[action.payload.languageCode] = action.payload.contentEntry, _a)), isSavingContentEntry: false });
        }
        case ContentActionTypes.CreateEntryError: {
            return tslib_1.__assign({}, state, { isSavingContentEntry: false });
        }
        case ContentActionTypes.CreateNewContentEntry: {
            return tslib_1.__assign({}, state, { activeContent: tslib_1.__assign({}, state.activeContent, (_b = {}, _b[action.payload.languageCode] = action.payload.contentEntry, _b)) });
        }
        case ContentActionTypes.SaveEntry: {
            return tslib_1.__assign({}, state, { isSavingContentEntry: true });
        }
        case ContentActionTypes.SaveEntrySuccess: {
            return tslib_1.__assign({}, state, { activeContent: tslib_1.__assign({}, state.activeContent, (_c = {}, _c[action.payload.languageCode] = action.payload.contentEntry, _c)), isSavingContentEntry: false });
        }
        case ContentActionTypes.SaveEntryError: {
            return tslib_1.__assign({}, state, { isSavingContentEntry: false });
        }
        case ContentActionTypes.DeleteEntrySuccess: {
            return tslib_1.__assign({}, state, { activeContent: omit(state.activeContent, action.payload.languageCode) });
        }
        case ContentActionTypes.DeleteEntriesSuccess: {
            var _g = action.payload, removedEntries_1 = _g.entries, categoryId = _g.categoryId;
            var changedEntries = state.contentHeaders[categoryId].filter(function (c) { return removedEntries_1.indexOf(c.id) === -1; });
            return tslib_1.__assign({}, state, { contentHeaders: tslib_1.__assign({}, state.contentHeaders, (_d = {}, _d[categoryId] = changedEntries, _d)) });
        }
        case ContentActionTypes.MoveEntriesSuccess: {
            var _h = action.payload, entries = _h.entries, newCategoryId = _h.newCategoryId, oldCategoryId = _h.oldCategoryId;
            var _j = splitEntries(state.contentHeaders[oldCategoryId], entries), toKeep = _j.toKeep, toRemove = _j.toRemove;
            return tslib_1.__assign({}, state, { contentHeaders: tslib_1.__assign({}, state.contentHeaders, (_e = {}, _e[oldCategoryId] = toKeep, _e[newCategoryId] = state.contentHeaders[newCategoryId].concat(toRemove), _e)) });
        }
        // Content Categories
        // ================================================
        case ContentActionTypes.GetCategoriesSuccess: {
            return tslib_1.__assign({}, state, { categories: action.payload });
        }
        case ContentActionTypes.CreateCategorySuccess: {
            return tslib_1.__assign({}, state, { categories: state.categories.concat([action.payload]), contentHeaders: tslib_1.__assign({}, state.contentHeaders, (_f = {}, _f[action.payload.id] = [], _f)) });
        }
        case ContentActionTypes.SavingCategorySuccess: {
            var updatedCategory_1 = action.payload;
            return tslib_1.__assign({}, state, { categories: state.categories.map(function (c) {
                    return c.id === updatedCategory_1.id ? tslib_1.__assign({}, c, updatedCategory_1) : c;
                }) });
        }
        case ContentActionTypes.DeleteCategorySuccess: {
            var id_1 = action.payload;
            var newContentHeaders = tslib_1.__assign({}, state.contentHeaders);
            delete newContentHeaders[id_1];
            return tslib_1.__assign({}, state, { categories: state.categories.filter(function (c) { return c.id !== id_1; }), contentHeaders: newContentHeaders });
        }
        case ContentActionTypes.ContentEntryPageUnload: {
            return tslib_1.__assign({}, state, { activeContent: {} });
        }
        case ContentActionTypes.CopyGlobalContent: {
            var activeContent = Object.keys(action.payload).reduce(function (agg, key) {
                var _a;
                return tslib_1.__assign({}, agg, (_a = {}, _a[key] = tslib_1.__assign({}, state.activeContent[key], (!state.activeContent[key].fromdatasync && {
                    name: action.payload[key].name,
                }), { sections: action.payload[key].sections }), _a));
            }, tslib_1.__assign({}, state.activeContent));
            return tslib_1.__assign({}, state, { activeContent: activeContent });
        }
        case ContentActionTypes.CopyGlobalFileContent: {
            var activeContent = Object.keys(action.payload).reduce(function (agg, key) {
                var _a;
                return tslib_1.__assign({}, agg, (_a = {}, _a[key] = tslib_1.__assign({}, state.activeContent[key], (!state.activeContent[key].fromdatasync && {
                    name: action.payload[key].name,
                }), { sections: action.payload[key].sections, attachments: action.payload[key].attachments }), _a));
            }, tslib_1.__assign({}, state.activeContent));
            return tslib_1.__assign({}, state, { activeContent: activeContent });
        }
        default: {
            return state;
        }
    }
}
export var getTemplates = function (state) {
    return state.templates.sort(sortAlphabetically);
};
export var getContentHeaders = function (state) { return state.contentHeaders; };
export var getActiveContent = function (state) { return state.activeContent; };
export var getCategories = function (state) {
    return filterAndSortCategories(state.categories);
};
export var getGeneralCategories = function (state) {
    return filterAndSortCategories(state.categories.filter(function (c) { return !c.patientonly; }));
};
export var isLoadingContentEntry = function (state) {
    return state.loadingContentEntry;
};
export var getIsSavingContentEntry = function (state) {
    return state.isSavingContentEntry;
};
export var getLoadingErrors = function (state) { return state.errors; };
export var loadingContentEntryError = function (state) {
    return state.errors.loadingContentEntry;
};
function splitEntries(entries, entriesToRemove) {
    var toRemove = [];
    var toKeep = [];
    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
        var entry = entries_1[_i];
        if (entriesToRemove.indexOf(entry.id) === -1) {
            toKeep.push(entry);
        }
        else {
            toRemove.push(entry);
        }
    }
    return {
        toRemove: toRemove,
        toKeep: toKeep,
    };
}
