import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MessageEditor } from '@react/components';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dispatch } from 'redux';
import { combineLatest, Subscription } from 'rxjs';
import { User } from 'src/app/auth/models/user';
import Root from 'src/app/react/components/Root';
import * as fromAuth from '../../../auth/reducers';
import * as fromRoot from '../../../reducers';
import { TranslationData } from '../../../settings/data/settings';
import * as fromSettings from '../../../settings/reducers';
import { CognitoWrapperService } from '../../services/congito.wrapper.service';
const containerElementName = 'portalMessageEditorReactContainer';

@Component({
  selector: 'portal-message-editor',
  template: `<div #${containerElementName} style="height: 100%"></div>`,
  encapsulation: ViewEncapsulation.None,
})
export class MessageEditorComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild(containerElementName) containerRef!: ElementRef;
  @Input() actionText?: string;
  @Input() isActionActive?: boolean;
  @Input() maxLength = 2000;
  @Input() patientId?: number;
  @Input() value = '';
  @Input() warningText?: string;
  @Output() actionClick = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<string>();

  private _props!: {
    clinicToken: string;
    features: object;
    language: string;
    translations: TranslationData;
    user: User;
  };

  private _subscriptions = new Subscription();

  constructor(
    private _store: Store<fromRoot.State>,
    private _cognito: CognitoWrapperService,
  ) {
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  ngAfterViewInit() {
    this._subscriptions.add(
      combineLatest([
        this._store.pipe(select(fromAuth.getClinicId)),
        this._store.pipe(select(fromAuth.getClinicOptions)),
        this._store.pipe(select(fromAuth.getUser)),
        this._store.pipe(select(fromSettings.getCurrentLanguage)),
        this._store.pipe(select(fromSettings.getLanguageTranslations)),
      ]).subscribe(
        ([clinicToken, clinicOptions, user, language, translations]) => {
          this._props = {
            clinicToken,
            features: clinicOptions,
            language,
            translations,
            user,
          };
          this.render();
        },
      ),
    );
  }

  ngOnChanges() {
    this.render();
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
    this._subscriptions.unsubscribe();
  }

  handleActionClick() {
    this.actionClick.emit();
  }

  handleOnChange(value: string) {
    this.valueChange.emit(value);
  }

  private render() {
    if (this._props == null) {
      return;
    }

    ReactDOM.render(
      React.createElement(
        Root,
        {
          ...this._props,
          authService: this._cognito,
          dispatch: this._store.dispatch.bind(this._store) as Dispatch,
        },
        React.createElement(MessageEditor, {
          actionText: this.actionText,
          isActionActive: this.isActionActive,
          maxLength: this.maxLength,
          onActionClick: this.handleActionClick,
          onChange: this.handleOnChange,
          patientId: this.patientId,
          value: this.value,
          warningText: this.warningText,
        }),
      ),
      this.containerRef.nativeElement,
    );
  }
}
