import { Category } from 'src/app/content/models/category';
import { CognitoWrapperService } from 'src/app/core/services/congito.wrapper.service';
import { environment } from '../../../../environments/environment';
import { ContentEntry } from './../../../content/models/content-entry';
import {
  GetContentPackDetailResponse,
  GetContentPacksResponse,
} from './../../../content/models/content-packs';

export class ContentApiClient {
  private _clinicToken: string;
  private _authService: CognitoWrapperService;
  private _onUnauthorized: () => void;

  constructor(props: {
    clinicToken: string;
    authService: CognitoWrapperService;
    onUnauthorized: () => void;
  }) {
    this._clinicToken = props.clinicToken;
    this._authService = props.authService;
    this._onUnauthorized = props.onUnauthorized;
  }

  public async fetchPacks(): Promise<GetContentPacksResponse>;
  public async fetchPacks(id: number): Promise<GetContentPackDetailResponse>;
  public async fetchPacks(
    id?: number,
  ): Promise<GetContentPackDetailResponse | GetContentPacksResponse> {
    if (id) {
      return this.fetch(`content/pack/${id}`);
    } else {
      return this.fetch(`content/pack`);
    }
  }

  public async fetchCategories(): Promise<Category[]> {
    return this.fetch(`content/categories`);
  }

  public async fetchCategoryEntries(): Promise<{
    [categoryId: number]: ContentEntry[];
  }> {
    return this.fetch(`content/groupedentries/assign`);
  }

  /**
   * Essentially a wrapper around `fetch` that sets some mandatory headers
   * and behavior.
   */
  private async getDefaultFetchHeaders(
    options?: RequestInit & { headers?: Record<string, string> },
  ) {
    const authUser = await this._authService.getAuthSession();

    // Merge headers for the individual request with mandatory ones
    const headers: Record<string, string> = {
      ...(options != null && options.headers != null ? options.headers : {}),
      accept: 'application/json',
      authorization: authUser.getIdToken().getJwtToken(),
      'x-salve-clinic-token': this._clinicToken,
    };

    return headers;
  }

  private async handleFetchResponse(response: Response) {
    if (response.status === 401) {
      this._onUnauthorized();
      throw new Error('Unauthorized');
    }

    if (response.ok) {
      try {
        const content = await response.text();
        return content.length > 0 ? JSON.parse(content) : undefined;
      } catch (err) {
        throw new Error(`Error parsing body as JSON: ${err.message}`);
      }
    }

    throw new Error('Network response was not ok');
  }

  private async fetch(
    path: string,
    /** Force `headers` to be a simple object for ease of use */
    options?: RequestInit & { headers?: Record<string, string> },
  ) {
    // Merge headers for the individual request with mandatory ones
    const headers: Record<string, string> = await this.getDefaultFetchHeaders(
      options,
    );
    const response = await fetch(`${environment.api.content.endpoint}${path}`, {
      ...options,
      headers,
    });

    return this.handleFetchResponse(response);
  }
}
