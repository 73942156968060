import { useApiClient } from '@react/lib/api';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
export function useAdminClinicUsersListQuery(_a) {
    var searchTerm = _a.searchTerm, filters = _a.filters;
    var apiClient = useApiClient().portal;
    return useInfiniteQuery(['adminClinicUsers', { searchTerm: searchTerm, filters: filters }], function (_a) {
        var pageParam = _a.pageParam;
        return apiClient.fetchAdminClinicUsers({
            pageParam: pageParam,
            query: searchTerm,
            filters: filters,
            excludeInternal: true,
        });
    }, {
        getNextPageParam: function (lastPage, pages) {
            if (lastPage.data.length === 0) {
                return undefined;
            }
            return pages.length + 1;
        },
    });
}
export function useInviteClinicUserMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (_a) {
        var staffId = _a.staffId, message = _a.message;
        return apiClient.inviteStaff(staffId, message);
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries('adminClinicUsers');
        },
    });
}
export function useDeactivateClinicUserMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (_a) {
        var staffId = _a.staffId;
        return apiClient.deactivateStaff(staffId);
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries('adminClinicUsers');
        },
    });
}
export function useTemporaryPasswordMutation() {
    var queryClient = useQueryClient();
    var apiClient = useApiClient().portal;
    return useMutation(function (_a) {
        var staffId = _a.staffId;
        return apiClient.sendTemporaryPassword(staffId);
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries('adminClinicUsers');
        },
    });
}
