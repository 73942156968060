import { select, Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as AuthActions from '../../auth/actions/auth.actions';
import * as fromSettings from '../../settings/reducers';
var CognitoWrapperService = /** @class */ (function () {
    function CognitoWrapperService(_store, _toastrService) {
        var _this = this;
        this._store = _store;
        this._toastrService = _toastrService;
        this._subs = new Subscription();
        this._subs.add(this._store
            .pipe(select(fromSettings.getSectionTranslations('SessionExpired')))
            .subscribe(function (t) {
            _this._autoLogoutText = t;
        }));
    }
    CognitoWrapperService.prototype.getAuthSession = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            Auth.currentSession()
                .then(function (c) {
                if (_this._sessionTimeoutToast) {
                    _this._toastrService.remove(_this._sessionTimeoutToast.toastId);
                    _this._sessionTimeoutToast = undefined;
                }
                resolve(c);
            })
                .catch(function (err) {
                _this._store.dispatch(new AuthActions.Logout({
                    isCognito: true,
                }));
                setTimeout(function () {
                    _this._sessionTimeoutToast = _this._toastrService.show(_this._autoLogoutText.Message, _this._autoLogoutText.Title, {
                        toastClass: "toast custom-toast warning",
                    });
                }, 1000);
            });
        });
    };
    return CognitoWrapperService;
}());
export { CognitoWrapperService };
