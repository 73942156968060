import { EventEmitter } from '@angular/core';
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent() {
        this.type = 'button';
        this.btnStyle = 'portal-btn';
        this.text = 'button';
        this.disabled = false;
        this.clicked = new EventEmitter();
    }
    return ButtonComponent;
}());
export { ButtonComponent };
