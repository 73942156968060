import {
  Component,
  ErrorHandler,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileUpload } from 'src/app/core/services/s3.service';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';

import { ContentListItem } from '../../models/ContentListItem';
import { PatientOnlyAssignContentItem } from '../../models/UserContent';

@Component({
  selector: 'portal-content-assigned',
  templateUrl: './content-assigned.component.html',
  styleUrls: ['./content-assigned.component.scss']
})
export class ContentAssignedComponent implements OnDestroy {
  @Input() text: any;
  @Input() filterActive = false;
  @Input() filterString = '';
  @Input() content: ContentListItem[] = [];
  @Input() patientName: string;
  @Input() patientOnly: boolean;
  @Input() patientOnlyContent: PatientOnlyAssignContentItem[];
  @Output() setSearchString = new EventEmitter<string>();
  @Output() removeAppContent = new EventEmitter<{
    assignmentid: number;
    contententryheaderid: number;
  }>();
  @Output() removePatientOnlyFile =
    new EventEmitter<PatientOnlyAssignContentItem>();

  public config: PerfectScrollbarConfigInterface = {};

  public searchForm: FormGroup;

  private _subs = new Subscription();

  constructor(
    private _fb: FormBuilder,
    private _s3: FileUpload,
    private _errorHandler: ErrorHandler
  ) {
    this.searchForm = this._fb.group({
      searchValue: this.filterString || ''
    });

    this._subs.add(
      this.searchForm
        .get('searchValue')
        .valueChanges.subscribe((val) => this.setSearchString.emit(val))
    );
  }

  ngOnDestroy(): void {
    this.setSearchString.emit('');
    this._subs.unsubscribe();
  }

  viewFile(entry: PatientOnlyAssignContentItem) {
    this._s3
      .readFileFromBucket({
        url: entry.uri
      })
      .then((res) => {
        const fileWindow = window.open(res.url, '_blank');
        try {
          fileWindow.focus();
        } catch (err) {
          this._errorHandler.handleError({
            originalError: new Error(
              `Pop-up Blocking is enabled - may restrict viewing files`
            )
          });
        }
      });
  }

  removeContent(entry) {
    if (!entry.frommeditex) {
      this.removeAppContent.emit({
        contententryheaderid: entry.id,
        assignmentid: entry.assignmentid
      });
    }
  }

  public isReadDateMocked(entryDate) {
    const date = new Date(entryDate);
    const year = date.getFullYear();
    // Mocked date was set to start of time (< 1970) in DB
    // Choice to compare against 1980 is arbitry but should be safe
    return year > 1980 ? true : false;
  }

  public clearFilter() {
    this.searchForm.patchValue({
      searchValue: ''
    });
  }

  onClicked(item: PatientOnlyAssignContentItem) {
    if (!item.isTransferring) {
      this.removePatientOnlyFile.emit(item);
    }
  }
}
