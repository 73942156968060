import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Table, TableFooter, TableHead } from '@material-ui/core';
export var LoaderWrapper = styled.div(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"], ["\n  flex: 1;\n  text-align: center;\n  align-items: center;\n"])));
export var StyledTable = styled(Table)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  table-layout: fixed;\n"], ["\n  table-layout: fixed;\n"])));
export var StyledTableHead = styled(TableHead)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  th {\n    font-size: 16px;\n    font-weight: ", ";\n    color: #757575;\n    vertical-align: top;\n\n    &.emailCol {\n      width: 30%;\n    }\n\n    &.fixedcol {\n      width: 100px;\n    }\n  }\n"], ["\n  th {\n    font-size: 16px;\n    font-weight: ", ";\n    color: #757575;\n    vertical-align: top;\n\n    &.emailCol {\n      width: 30%;\n    }\n\n    &.fixedcol {\n      width: 100px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typography.fontWeightMedium;
});
export var StyledTableFooter = styled(TableFooter)(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  td {\n    border-bottom: 0;\n  }\n"], ["\n  td {\n    border-bottom: 0;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
