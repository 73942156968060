import { SettingsActionsUnion } from '../actions/settings.actions';
import { sections } from '../data/settings';
import { SettingsSection } from '../models/section';

export interface State {
  sections: SettingsSection[];
}

export const initialState: State = {
  sections,
};

export function reducer(state = initialState, action: SettingsActionsUnion) {
  switch (action.type) {
    default:
      return state;
  }
}

// Selectors
export const getSectionsArray = (state: State) => state.sections;
