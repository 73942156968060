import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import * as fromAuth from '../../auth/reducers';
import { IChangeLabel } from '../../models/ChangeLabel';
import { Message, MessageResult } from '../../models/Message';
import { SendNewMessageModel } from '../../models/NewMessageModel';
import * as fromRoot from '../../reducers';
import { MessageTypeResponse } from '../models/messageTypeResponse';
import { MessagingSummaryResponse } from '../models/messagingSummaryResponse';
import { AddMessageToThreadRequest } from '../models/requests/add-message-to-thread.request';
import { ChangeMessageLabelRequest } from '../models/requests/change-message-label.request';
import {
  ConversationListParams,
  ConversationsRequest,
  ConversationsRequestV2,
} from '../models/requests/conversations.request';
import {
  CreateNewThreadRequest,
  NewMessageDetail,
} from '../models/requests/create-new-thread.request';
import { DeleteMessageItemRequest } from '../models/requests/delete-message-item.request';
import { GetClinicMessagesRequest } from '../models/requests/get-clinic-messages.request';
import { GetConversationRequest } from '../models/requests/get-conversation.request';
import { MarkMessageResolvedRequest } from '../models/requests/mark-message-resolved.request';
import { MarkMessageStarredRequest } from '../models/requests/mark-message-starred';
import { MarkMessageUnreadRequest } from '../models/requests/mark-message-unread.request';
import { MarkMessageUnresolvedRequest } from '../models/requests/mark-message-unresolved.request';
import { MarkMessageUnstarredRequest } from '../models/requests/mark-message-unstarred';
import { MessageTypesRequest } from '../models/requests/message-types-request';
import { MessagingSummaryRequest } from '../models/requests/messaging-summary-request';

@Injectable()
export class MessageService {
  private publicKey$: Observable<string>;

  constructor(
    private _httpService: HttpService,
    private _store: Store<fromRoot.State>,
  ) {
    this.publicKey$ = this._store.pipe(select(fromAuth.getPublicKey));
  }

  getMessagingSummary(): Observable<MessagingSummaryResponse> {
    return this._httpService.performResolvePostRequest(
      new MessagingSummaryRequest(),
    );
  }

  getMessageTypes(): Observable<MessageTypeResponse> {
    return this._httpService.performResolvePostRequest(
      new MessageTypesRequest(),
    );
  }

  getClinicMessages(): Observable<Message[]> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new GetClinicMessagesRequest({
          PublicToken: pk,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  getConversation(
    id: number,
    afterMessageItemId?: number,
  ): Observable<MessageResult> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new GetConversationRequest({
          PublicToken: pk,
          MessageId: id,
          AfterMessageItemId: afterMessageItemId,
        });

        return this._httpService.performResolvePostRequest(body).pipe(
          map((response) => {
            const result: MessageResult = {
              IsReadOnly: response.IsReadOnly,
              Messages: response.Messages.map(({ Now, ...rest }) => ({
                ...rest,
                Now: undefined,
              })),
            };
            return result;
          }),
        );
      }),
    );
  }

  markMessageUnread(id: number): Observable<boolean> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new MarkMessageUnreadRequest({
          PublicToken: pk,
          MessageId: id,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  markMessageResolved(id: number): Observable<boolean> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new MarkMessageResolvedRequest({
          PublicToken: pk,
          MessageId: id,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  markMessageUnresolved(id: number): Observable<boolean> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new MarkMessageUnresolvedRequest({
          PublicToken: pk,
          MessageId: id,
        });

        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  markMessageStarred(id: number): Observable<boolean> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new MarkMessageStarredRequest({
          PublicToken: pk,
          MessageId: id,
        });

        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  markMessageUnstarred(id: number): Observable<boolean> {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new MarkMessageUnstarredRequest({
          PublicToken: pk,
          MessageId: id,
        });

        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  updateMessageType(data: IChangeLabel) {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new ChangeMessageLabelRequest({
          PublicToken: pk,
          MessageId: data.messageId,
          MessageTypeId: data.messageTypeId,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  addMessageToThread(message: SendNewMessageModel) {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new AddMessageToThreadRequest({
          PublicToken: pk,
          MessageId: message.MessageId,
          MessageContent: message.MessageContent,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  createNewThread({ patientId, messageTypeId, subject, content }) {
    const newMessage = new NewMessageDetail({
      PatientId: patientId,
      MessageTypeId: messageTypeId,
      Content: content,
      Subject: subject,
    });

    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new CreateNewThreadRequest({
          PublicToken: pk,
          NewMessage: newMessage,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  deleteMessageItem({ messageId, messageItemId }) {
    return this.publicKey$.pipe(
      take(1),
      switchMap((pk) => {
        const body = new DeleteMessageItemRequest({
          PublicToken: pk,
          DeleteMessageItemId: messageItemId,
          MessageId: messageId,
        });
        return this._httpService.performResolvePostRequest(body);
      }),
    );
  }

  getMessageList(useV2: boolean, params: ConversationListParams) {
    if (useV2) {
      return this._httpService.performResolvePostRequest(
        new ConversationsRequestV2(params),
      );
    }

    return this._httpService.performResolvePostRequest(
      new ConversationsRequest(params),
    );
  }
}
