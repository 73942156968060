<!-- main form area -->
<div
  class="content-edit-container"
  fxLayout="row"
  [formGroup]="contentEntryForm"
>
  <div
    *ngIf="isLoading"
    fxFlex="grow"
    class="card-height loading"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-spinner diameter="100"></mat-spinner>
  </div>
  <div
    *ngIf="!isLoading && contentEntry"
    fxFlex="grow"
    class="main-form card-height"
  >
    <div
      [ngClass]="{
        'scroll-container': true,
        'is-multi-lingual': isMultiLingual
      }"
    >
      <perfect-scrollbar
        fxFlex="auto"
        [config]="config"
        [scrollIndicators]="true"
        fxLayoutGap="50px"
      >
        <portal-content-edit-title
          [text]="titleText$ | async"
          [templateText]="useATemplateText$ | async"
          [contentText]="useAContentText$ | async"
          [FormGroup]="contentEntryForm"
          [templates]="templates"
          [fromDataSync]="contentEntry.fromdatasync"
          [fileOnly]="contentEntry.isfileonly"
          [checkingTitle]="checkingTitle$ | async"
          [hasChildren]="this.contentEntry?.children?.length > 0"
          [parentInfo]="parentInfo$ | async"
          [languageCode]="
            isMultiLingual && languageCode !== 'default'
              ? languageCode
              : undefined
          "
          [contentEditorEnabled]="contentEditorEnabled"
          (goToParent)="navigateToParent($event)"
          (manageChildLink)="openViewChildrenModal()"
          (useTemplate)="applyTemplate($event)"
          (copyContent)="copyContent($event)"
          (copyGlobalContent)="copyGlobalContent.emit($event)"
          (copyGlobalFileContent)="copyGlobalFileContent.emit($event)"
          [parentPortalId]="parentPortalId"
          [isGlobalPortal]="isGlobalPortal"
        >
        </portal-content-edit-title>
        <div
          *ngIf="!contentEntry.isfileonly && !contentEntry.parentid"
          fxLayout="column"
          fxLayoutGap="15px"
        >
          <portal-content-edit-body
            [text]="bodyText$ | async"
            [videoText]="videoText$ | async"
            [FormGroup]="contentEntryForm"
            [introLength]="1000"
            [introIsEmpty]="introductionError | async"
            [contentEditorEnabled]="contentEditorEnabled"
            (introductionError)="checkIntroError($event)"
          >
          </portal-content-edit-body>
          <portal-content-edit-sections
            [text]="sectionText$ | async"
            [videoText]="videoText$ | async"
            [FormGroup]="contentEntryForm"
            [contentEditorEnabled]="contentEditorEnabled"
            (addSection)="addEntry($event)"
            (setOrder)="setOrderOfSections($event)"
            (removeSection)="removeForm($event)"
          >
          </portal-content-edit-sections>
        </div>
        <div
          *ngIf="contentEntry.isfileonly && !contentEntry.parentid"
          class="is-file-only-container"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <button
              mat-raised-button
              class="portal-btn btn-primary"
              [disabled]="
                (attachmentExists$ | async) ||
                (titleInvalid$ | async) ||
                !contentEditorEnabled
              "
              (click)="openFileDialog(false, contentEntry.isfileonly, 'file')"
            >
              {{ (addPdfText$ | async)?.AddFile }}
            </button>
            <div *ngIf="attachmentExists$ | async">
              {{ controlsText.OneAttachment }}
            </div>
            <div *ngIf="titleInvalid$ | async">
              {{ controlsText.TitleRequired }}
            </div>
          </div>
          <div class="attachment-list-header">
            {{ (addPdfText$ | async)?.Current }}
          </div>
          <portal-attachments-table
            [text]="addPdfText$ | async"
            [attachments]="this.contentEntryForm.get('attachments').value"
            [contentEditorEnabled]="contentEditorEnabled"
            (removeAttachment)="removeAttachment($event)"
          >
          </portal-attachments-table>
        </div>
      </perfect-scrollbar>
    </div>
    <portal-content-edit-controls
      [canSendToAll]="languageCode === 'default'"
      [parentExists]="!!contentEntry.parentid"
      [text]="controlsText$ | async"
      [formError]="
        contentEntryForm.invalid ||
        contentEntryForm.status === 'PENDING' ||
        (addFile && attachments.length === 0)
      "
      [introductionError]="introductionError | async"
      [FormGroup]="contentEntryForm"
      [contentEditorEnabled]="contentEditorEnabled"
      (exitForm)="exitForm()"
      (publishContent)="save()"
      [isGlobalPortal]="isGlobalPortal"
      [canPublish]="languageCode === 'default' || defaultContentEntryId"
    >
    </portal-content-edit-controls>
  </div>

  <!-- sidebar
  <div
    class="sidebar card-height"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="30px"
  >
    <portal-preview>
    </portal-preview>
    <portal-comments
      [text]="commentsText$ | async"
      [maxLength]="300"
      [FormGroup]="contentEntryForm"
    >
    </portal-comments>
    <portal-version-history
      [text]="versionHistoryText$ | async"
      [FormGroup]="contentEntryForm"
    >
    </portal-version-history>
  </div> -->
</div>
