import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as AuthActions from '../../auth/actions/auth.actions';
import * as fromRoot from '../../reducers';
import * as fromSettings from '../../settings/reducers';

@Injectable()
export class CognitoWrapperService {
  private _autoLogoutText: any;
  private _subs = new Subscription();
  private _sessionTimeoutToast: ActiveToast<any>;

  constructor(
    private _store: Store<fromRoot.State>,
    private _toastrService: ToastrService,
  ) {
    this._subs.add(
      this._store
        .pipe(select(fromSettings.getSectionTranslations('SessionExpired')))
        .subscribe((t) => {
          this._autoLogoutText = t;
        }),
    );
  }

  getAuthSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((c) => {
          if (this._sessionTimeoutToast) {
            this._toastrService.remove(this._sessionTimeoutToast.toastId);
            this._sessionTimeoutToast = undefined;
          }
          resolve(c);
        })
        .catch((err) => {
          this._store.dispatch(
            new AuthActions.Logout({
              isCognito: true,
            }),
          );

          setTimeout(() => {
            this._sessionTimeoutToast = this._toastrService.show(
              this._autoLogoutText.Message,
              this._autoLogoutText.Title,
              {
                toastClass: `toast custom-toast warning`,
              },
            );
          }, 1000);
        });
    });
  }
}
