import * as React from 'react';
import { Container, IconContainer, InnerContainer, StyledText, } from './LoadingError.styled';
/**
 * Can be used to provide a message to the user that there has been
 * an error loading data.
 */
export var LoadingError = function (_a) {
    var children = _a.children, _b = _a["data-testid"], dataTestId = _b === void 0 ? 'LoadingError' : _b, icon = _a.icon, message = _a.message;
    return (React.createElement(Container, { "data-testid": dataTestId },
        React.createElement(InnerContainer, null,
            React.createElement(IconContainer, null, icon),
            React.createElement(StyledText, { component: "span", variant: "h6" }, message),
            children)));
};
