import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface LoadingDialogData {
  loadingText: string;
}

@Component({
  selector: 'portal-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LoadingDialogData) {}
}
