import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { PushNotificationsService } from '../../core/services/push-notifications.service';
import { WindowRefService } from '../../core/services/window-ref.service';
import * as fromSettings from '../../settings/reducers';
import { ClickedPushNotification, ClosedPushNotification, LoadMessageActionTypes, UpdateLastSeen } from '../actions/load-message.actions';
import { ApplyFilter, OpenThread } from '../actions/message-ui.actions';
var MessageNotificationEffects = /** @class */ (function () {
    function MessageNotificationEffects(_store, _actions$, _push, _navigationService, windowService, titleService, _error) {
        var _this = this;
        this._store = _store;
        this._actions$ = _actions$;
        this._push = _push;
        this._navigationService = _navigationService;
        this.windowService = windowService;
        this.titleService = titleService;
        this._error = _error;
        this._notificationIsShowing = false;
        this.openNotification$ = this._actions$.pipe(ofType(LoadMessageActionTypes.ShowPushNotification), mergeMap(function (action) {
            return new Promise(function (res) {
                var newMessages = action.payload.newMessages;
                if (newMessages.length === 0) {
                    return;
                }
                var message;
                if (newMessages.length === 1) {
                    message = newMessages[0];
                }
                // change tab title to show number of new messages
                _this.titleService.setTitle(_this._text.BrowserTabTitle(newMessages.length));
                if (action.payload.showPushNotification) {
                    // Only show notification if permission is granted
                    if (_this._push.permission) {
                        // set message body of notification
                        var body = _this._text.NotificationBody(newMessages.length);
                        if (message) {
                            body = _this._text.NotificationBodyMessage(message.PatientFirstName + " " + message.PatientLastName);
                        }
                        var title = _this._text.NotificationTitle;
                        // retrieve default options
                        var options = {
                            body: body,
                            silent: false,
                            requireInteraction: true,
                            icon: 'https://salveportaltest.s3.amazonaws.com/assets/imgs/logo.png'
                        };
                        // Check if a notification already exists
                        if (_this._notificationIsShowing) {
                            _this._currentNotification.notification.close();
                        }
                        // create push notification
                        _this._push.create(title, options).subscribe(function (notif) {
                            if (notif.event.type === 'show') {
                                _this._setNotification(notif);
                            }
                            if (notif.event.type === 'click') {
                                _this._setNotification();
                                _this._navigationService.navigate(['messages']);
                                _this.windowService.nativeWindow.focus();
                                notif.notification.close();
                                if (message) {
                                    // this._store.dispatch(new LoadThread(message));
                                    _this._store.dispatch(new OpenThread(message));
                                }
                                _this._store.dispatch(new UpdateLastSeen());
                                _this._store.dispatch(new ApplyFilter('unresolved'));
                                res(new ClickedPushNotification());
                            }
                            if (notif.event.type === 'close') {
                                _this._setNotification();
                                res(new ClosedPushNotification());
                            }
                        });
                    }
                    else {
                        _this._error.handleError({
                            originalError: new Error('permission to show notifications not granted.')
                        });
                    }
                }
            });
        }));
        this._store
            .pipe(select(fromSettings.getSectionTranslations('MessagingNotifications')))
            .subscribe(function (t) {
            _this._text = t;
        });
    }
    MessageNotificationEffects.prototype._setNotification = function (notif) {
        if (notif) {
            this._notificationIsShowing = true;
            this._currentNotification = notif;
        }
        else {
            this._notificationIsShowing = false;
            this._currentNotification = null;
        }
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], MessageNotificationEffects.prototype, "openNotification$", void 0);
    return MessageNotificationEffects;
}());
export { MessageNotificationEffects };
