import * as tslib_1 from "tslib";
import { checkFeature } from '@react/lib/features/check';
import { useFeatures } from '@react/lib/features/context';
import { useDebouncedState, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import flatten from 'lodash/flatten';
import React, { useEffect, useMemo, useState } from 'react';
import { usePatientStaffListInfiniteQuery } from '../hooks/usePatientStaffListQuery';
import { unassigned_option } from '../utils';
import { FilterMenuPopover, getOptionsStateFromTabs, } from './FilterMenuPopover';
var setToArray = function (set) {
    return set && set.size ? Array.from(set) : [];
};
var ɵ0 = setToArray;
export var FilterMenu = function (_a) {
    var menuAnchor = _a.menuAnchor, open = _a.open, onClose = _a.onClose, onChange = _a.onChange, folders = _a.folders, filters = _a.filters, clinicUsers = _a.clinicUsers;
    var t = useTranslations().t;
    var features = useFeatures();
    var featureAssignConversation = checkFeature(features, 'messagingOptions.assignConversation');
    var _b = useState({
        folder: '',
        staff: '',
        clinicUsers: '',
    }), searchState = _b[0], setSearchState = _b[1];
    var _c = useState({
        folder: new Set(),
        status: new Set(),
        clinicUsers: new Set(),
    }), optionsState = _c[0], setOptionsState = _c[1];
    var debouncedSearch = useDebouncedState(searchState, 500);
    var staffResult = usePatientStaffListInfiniteQuery({
        q: debouncedSearch['staff'],
    });
    var hasStaff = staffResult.data && staffResult.data.pages[0].data.length > 0;
    var _d = useState(hasStaff), showStaff = _d[0], setShowStaff = _d[1];
    useEffect(function () {
        setOptionsState(function (state) {
            var tabState = getOptionsStateFromTabs(tabs);
            return Object.keys(tabState).reduce(function (agg, key) {
                var _a;
                var tabStateSet = tabState[key];
                var stateSet = state[key];
                return tslib_1.__assign({}, agg, (_a = {}, _a[key] = new Set(setToArray(tabStateSet).concat(setToArray(stateSet))), _a));
            }, state);
        });
    }, [filters]);
    function onTabEndReached(tabId) {
        if (tabId === 'staff') {
            var shouldFetchNextPage = staffResult.hasNextPage &&
                !staffResult.isLoading &&
                !staffResult.isFetchingNextPage;
            if (shouldFetchNextPage) {
                staffResult.fetchNextPage();
            }
        }
    }
    function onSearchUpdate(tabId, value) {
        setSearchState(function (current) {
            var _a;
            return (tslib_1.__assign({}, current, (_a = {}, _a[tabId] = value, _a)));
        });
    }
    // Show staff tab even if filters reduce results to 0
    useEffect(function () {
        if (hasStaff) {
            setShowStaff(true);
        }
    }, [hasStaff]);
    useErrorToast(staffResult.isError, {
        title: t.Messages.FilterLoadingErrorTitle,
        message: t.Messages.FilterLoadingErrorMessage,
    });
    function getFoldersTab() {
        var mapOption = function (folder) { return ({
            label: folder.name,
            id: String(folder.id),
            active: filters.folderIds ? filters.folderIds.includes(folder.id) : false,
        }); };
        var options = Array.from(folders || []);
        var filteredOptions = options
            .filter(function (folder) {
            return folder.name.toLocaleLowerCase().includes(searchState['folder']);
        })
            .map(mapOption);
        return {
            id: 'folder',
            label: t.Messages.FolderFilterLabel,
            options: filteredOptions,
            searchEnabled: true,
            searchPlaceholder: t.Messages.FolderFilterSearchPlaceholder,
            searchValue: searchState['folder'],
        };
    }
    function getStatusTab() {
        return {
            id: 'status',
            label: t.Messages.StatusFilterLabel,
            options: [
                {
                    id: 'resolved',
                    label: t.Messages.ResolvedFilterLabel,
                    active: filters.isResolved === true,
                },
                {
                    id: 'unresolved',
                    label: t.Messages.UnresolvedFilterLabel,
                    active: filters.isResolved === false,
                },
            ],
        };
    }
    function getClinicUsersTab() {
        var unassignedOption = tslib_1.__assign({}, unassigned_option(t.Messages.Unassigned), { active: filters.isUnassigned ? filters.isUnassigned : false });
        var mapOption = function (user) { return ({
            label: user.userfullname,
            id: String(user.id),
            userfullname: user.userfullname,
            active: filters.assignedIds
                ? filters.assignedIds.includes(user.id)
                : false,
        }); };
        var clinicUserOptions = Array.from(clinicUsers || []).filter(function (user) { return user.id !== null; });
        var options = clinicUserOptions.map(mapOption);
        var clinicUserOptionsWithUnassigned = [unassignedOption].concat(options);
        var filteredClinicOptions = clinicUserOptionsWithUnassigned.filter(function (user) {
            return user.userfullname
                .toLocaleLowerCase()
                .includes(searchState['clinicUsers'].toLocaleLowerCase());
        });
        return {
            id: 'clinicUsers',
            label: t.Messages.Assignees,
            options: filteredClinicOptions,
            searchEnabled: true,
            searchPlaceholder: t.Messages.SearchAssignees,
            searchValue: searchState['clinicUsers'],
        };
    }
    var tabs = useMemo(function () {
        var foldersTab = getFoldersTab();
        var statusTab = getStatusTab();
        var clinicUsersTab = getClinicUsersTab();
        var computedTabs = [foldersTab, statusTab];
        if (featureAssignConversation) {
            computedTabs.push(clinicUsersTab);
        }
        if (showStaff) {
            var options_1 = flatten(
            // tslint:disable-next-line:no-non-null-assertion
            staffResult.data.pages.map(function (page) {
                return page.data.map(function (person) { return ({
                    label: person.firstname + " " + person.lastname,
                    id: String(person.clinicuserid),
                    active: filters.staffIds
                        ? filters.staffIds.includes(person.clinicuserid)
                        : false,
                    hidden: false,
                }); });
            }));
            // Add dummy results for currently selected filters
            if (filters.staffIds) {
                filters.staffIds.forEach(function (staffId) {
                    var hasSelectedStaff = options_1.some(function (option) { return option.id === String(staffId); });
                    if (!hasSelectedStaff) {
                        options_1.push({
                            id: String(staffId),
                            label: String(staffId),
                            active: true,
                            hidden: true,
                        });
                    }
                });
            }
            var staffTab = {
                id: 'staff',
                label: t.Messages.TeamMembersFilterLabel,
                options: options_1,
                searchEnabled: true,
                searchPlaceholder: t.Messages.TeamMembersFilterSearchPlaceholder,
                searchValue: searchState['staff'],
                // @ts-ignore
                loading: staffResult.isLoading || staffResult.isFetchingNextPage,
            };
            computedTabs.push(staffTab);
        }
        return computedTabs;
    }, [t, folders, searchState, filters, showStaff, staffResult]);
    var onOptionClicked = function (tabId, optionId) {
        var _a;
        var newTabOptions = new Set(optionsState[tabId]);
        if (newTabOptions.has(optionId)) {
            newTabOptions.delete(optionId);
        }
        else {
            newTabOptions.add(optionId);
        }
        setOptionsState(tslib_1.__assign({}, optionsState, (_a = {}, _a[tabId] = newTabOptions, _a)));
    };
    var onCloseMenu = function () {
        setOptionsState(getOptionsStateFromTabs(tabs));
        onClose();
    };
    return (React.createElement(FilterMenuPopover, { id: "FilterMenuPopover-" + (staffResult.isLoading ? 'Loading' : 'Loaded'), anchorEl: menuAnchor, open: open, tabs: tabs, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, onClose: onCloseMenu, onChange: onChange, onTabEndReached: onTabEndReached, onSearchUpdate: onSearchUpdate, onOptionClicked: onOptionClicked, optionsState: optionsState }));
};
export { ɵ0 };
