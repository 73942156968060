var _this = this;
import * as tslib_1 from "tslib";
export var debounceAsync = function (callback, wait) {
    // The `@types/node` conflicts with the browser type for this function
    // that we actually want.
    var browserSetTimeout = setTimeout;
    var timeoutId = null;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        return new Promise(function (resolveOuter) {
            var timeoutPromise = new Promise(function (resolveInner) {
                timeoutId = browserSetTimeout(resolveInner, wait);
            });
            timeoutPromise.then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var _a;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = resolveOuter;
                            return [4 /*yield*/, callback.apply(void 0, args)];
                        case 1:
                            _a.apply(void 0, [_b.sent()]);
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
};
