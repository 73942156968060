import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ContentPack } from 'src/app/content/models/content-packs';
import { Category } from '../../../content/models/category';
import { TabOptions } from '../../models/ContentPacks';
import { ContentTabs } from '../../reducers/content-assignment.reducer';

@Component({
  selector: 'portal-content-type-filter',
  templateUrl: './content-type-filter.component.html',
  styleUrls: ['./content-type-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTypeFilterComponent {
  @Input() text: any;
  @Input() contentTypes: Category[];
  @Input() activeContentTypeId: number;
  @Input() selectedPackId: number;
  @Input() selectedTab: ContentTabs;
  @Input() contentPacks: ContentPack[];
  @Output() setPackId = new EventEmitter<number>();
  @Output() setContentTypeId = new EventEmitter<number>();
  @Output() selectTab = new EventEmitter<ContentTabs>();

  public tabOptions = TabOptions;
  public config: PerfectScrollbarConfigInterface = {};
}
