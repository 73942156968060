import styled from '@emotion/styled';

export const ChatStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;

  > .messages-container {
    flex: 1;
    overflow: hidden;

    > .messages-inner {
      height: 100%;
      overflow-y: auto;
      padding: ${({ theme }) => theme.spacing(0, 2)};

      // messages
      > div {
        margin: 10px 2px 10px;
        width: 80%;

        &.chat-message-wrapper__right {
          width: 72%;
        }

        &.chat-message-wrapper__right.grid {
          width: 80%;
        }

        &:first-of-type {
          margin-top: 30px;
        }

        &:last-of-type {
          margin-bottom: 30px;
        }
      }

      .chat-message-wrapper__left.grid,
      .chat-message-wrapper__left.grouped {
        margin-right: auto;
      }

      .chat-message-wrapper__right.grid,
      .chat-message-wrapper__right.grouped {
        margin-left: auto;
      }
    }
  }

  > .typing-indicator {
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }

  > .chat-input {
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    padding: ${({ theme }) => theme.spacing(2, 1)};

    &:first-of-type {
      padding-left: ${({ theme }) => theme.spacing(2)}px;
    }

    &:last-of-type {
      padding-right: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`;

export const PatientDetails = styled.span`
  font-weight: bold;
`;

export const PatientDoB = styled.span`
  font-weight: normal;
`;

export const Subject = styled.p`
  margin: 10px 0 0 0;
`;
