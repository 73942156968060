import styled from '@emotion/styled';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useContent } from '@react/lib/api/hooks';
import { useEnvVariables, useErrorToast } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useState } from 'react';
import { Category } from 'src/app/content/models/category';
import { ContentPack } from 'src/app/content/models/content-packs';
import { Empty } from './Empty';
import { ListingTooltip } from './ListingTooltip';
import MessageAddContentLinkListing, {
  ContentItem,
} from './MessageAddContentLinkListing';
import { TabPanel } from './tabs';

export interface FullContentItem extends ContentItem {
  contentRootId: string | number;
}

interface Props {
  onAddContentLink: (contentLink: string) => void;
}

const ItemText = styled('span')`
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItemRender = ({
  label,
  isSelected,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}) => {
  return (
    <ListItem button onClick={() => onClick()}>
      <ListingTooltip title={label}>
        <ListItemText
          primaryTypographyProps={{
            color: isSelected ? 'primary' : undefined,
          }}
        >
          <Box display="flex" flexDirection="row" width={320}>
            <ItemText>{label}</ItemText>
            {isSelected && <Check />}
          </Box>
        </ListItemText>
      </ListingTooltip>
    </ListItem>
  );
};

const TabsContainer = styled(Box)`
  border-right: 1px solid ${({ theme }) => theme.colors.border.dark};
`;

const PatientContentCategoryListing = ({ onAddContentLink }: Props) => {
  const { portalAppBaseUrl } = useEnvVariables();
  const { t } = useTranslations();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedPack, setSelectedPack] = useState<ContentPack>();
  const [selectedFolder, setSelectedFolder] = useState<Category>();
  const { packs, categories, packDetails, categoryEntries } = useContent({
    packId: selectedPack ? selectedPack.id : undefined,
  });
  const hasContentFetchError = [
    packs,
    categories,
    packDetails,
    categoryEntries,
  ].some((queryResult) => queryResult.isError);

  useErrorToast(
    hasContentFetchError,
    {
      title: t.Admin.MessageContentLoadError,
      message: t.General.ConnectionError,
    },
    0,
  );
  const renderPacksList = () => {
    const packsData = packs.data && packs.data.contentPacks;

    if (!packsData || !packsData.length) {
      return <p>{t.Admin.MessageContentNoPacks}</p>;
    }

    return packsData.map((pack) => {
      const isSelected = pack === selectedPack;
      const onClick = () => {
        if (isSelected) {
          setSelectedPack(undefined);
        } else {
          setSelectedPack(pack);
          setSelectedFolder(undefined);
        }
      };

      return (
        <React.Fragment key={pack.id}>
          <ListItemRender {...{ isSelected, onClick }} label={pack.packName} />
        </React.Fragment>
      );
    });
  };

  const renderCategoryList = () => {
    const categoryData = categories.data;

    if (!categoryData || !categoryData.length) {
      return <p>{t.Admin.MessageContentNoFolders}</p>;
    }

    return categoryData.map((folder) => {
      const isSelected = folder === selectedFolder;
      const onClick = () => {
        if (isSelected) {
          setSelectedFolder(undefined);
        } else {
          setSelectedFolder(folder);
          setSelectedPack(undefined);
        }
      };
      return (
        <React.Fragment key={folder.id}>
          <ListItemRender {...{ isSelected, onClick }} label={folder.name} />
        </React.Fragment>
      );
    });
  };

  const onSelectContentItem = ({ type, id }: ContentItem) => {
    const contentRootId =
      type === 'folder'
        ? selectedFolder && (selectedFolder.id as any)
        : selectedPack && selectedPack.id;
    const contentPath = [contentRootId, id];
    onAddContentLink(
      `${portalAppBaseUrl}/information/${contentPath.join('/')}`,
    );
  };

  const onTabChange = (_, newIndex: number) => {
    setActiveIndex(newIndex);
    setSelectedPack(undefined);
    setSelectedFolder(undefined);
  };

  return (
    <Box flexDirection="row" display="flex" width={670}>
      <TabsContainer flex={1}>
        <Tabs
          variant="fullWidth"
          value={activeIndex}
          onChange={onTabChange}
          indicatorColor="primary"
        >
          <Tab label={t.ContentDirectory.Categories} />
          <Tab label={t.ContentDirectory.Packs} />
        </Tabs>
        <Box>
          <TabPanel value={activeIndex} index={0}>
            {categories.isLoading && <CircularProgress thickness={1} />}
            {categories.isSuccess && (
              <List>
                <Box overflow="auto" maxHeight={450}>
                  {renderCategoryList()}
                </Box>
              </List>
            )}
          </TabPanel>
          <TabPanel value={activeIndex} index={1}>
            {packs.isLoading && <CircularProgress thickness={1} />}
            {packs.isSuccess && (
              <List>
                <Box overflow="auto" maxHeight={450}>
                  {renderPacksList()}
                </Box>
              </List>
            )}
          </TabPanel>
        </Box>
      </TabsContainer>
      <Box flex={1} height={500} overflow="auto">
        {packDetails.isError || categoryEntries.isError ? (
          <Box textAlign="center">
            <Empty message={t.Admin.MessageContentLoadError} />
          </Box>
        ) : (
          <MessageAddContentLinkListing
            mode={activeIndex === 0 ? 'folder' : 'pack'}
            folders={
              categoryEntries.data && selectedFolder && selectedFolder.id
                ? categoryEntries.data[selectedFolder.id]
                : undefined
            }
            loading={packDetails.isLoading}
            packDetail={packDetails}
            onSelectContent={onSelectContentItem}
          />
        )}
      </Box>
    </Box>
  );
};

export default PatientContentCategoryListing;
