import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonToggleChange, MatSelectChange } from '@angular/material';
import clamp from 'lodash/clamp';
import { removeNonNumbers } from '../../utils/generic';
import {
  detectPreferredHoursType,
  formatHour,
  to12Hour,
  to24Hour,
} from '../../utils/time';

const MIN_MINUTES = 0;
const MAX_MINUTES = 59;

type HoursType = '12H' | '24H';
type PeriodType = 'AM' | 'PM';

/**
 * Allows the user to set a time that is returned in the form "hh:mm".
 */
@Component({
  selector: 'portal-time-picker',
  templateUrl: 'time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimePickerComponent,
    },
  ],
})
export class TimePickerComponent implements ControlValueAccessor {
  /**
   * The lowest acceptable hour for the current clock (mainly for template)
   */
  get minHours() {
    return this.hoursType === '12H' ? 1 : 0;
  }

  /**
   * The highest acceptable hour for the current clock (mainly for template)
   */
  get maxHours() {
    return this.hoursType === '12H' ? 12 : 23;
  }

  constructor() {}

  disabled = false;
  /**
   * The text currently entered in the "hours" input
   */
  hours: string | undefined | null;
  /**
   * Whether or not the control is using 12 or 24 hours clock
   */
  hoursType: HoursType = detectPreferredHoursType();

  /**
   * The text currently entered in the "minutes" input
   */
  minutes: string | undefined | null;
  period: PeriodType = 'AM';
  touched = false;
  /**
   * The current formatted ("00:00") value
   */
  value: string | undefined | null;
  private onChange = (value: string) => {};
  private onTouched = () => {};

  /**
   * When the user leaves the hours field we can replace the value
   * they've entered to fit with the 12/24 hours clock better.
   * For example when using the 24 hour clock and they leave a "9"
   * we can format this to be "09".
   */
  handleHoursBlur() {
    let hoursAsNumber = parseInt(this.hours, 10);

    // If they removed all input we can just put whatever
    // makes sense for current clock
    if (isNaN(hoursAsNumber)) {
      hoursAsNumber = this.hoursType === '12H' ? 12 : 0;
    }

    if (this.hoursType === '12H' && hoursAsNumber === 0) {
      hoursAsNumber = 12;
    }

    this.hours = formatHour(hoursAsNumber, this.hoursType);
    this.updateValue();
    this.markAsTouched();
    this.onChange(this.value);
  }

  /**
   * Responsible for parsing the hours inputted by the user.
   * It attempts to try and preserve what the user has entered
   * unless it is drastically wrong.
   */
  handleHoursInput(e: Event) {
    const input = e.target as HTMLInputElement;
    // Strip out anything that isn't a number
    input.value = removeNonNumbers(input.value);

    // If the user has removed everything just wait for now
    if (input.value.length === 0) {
      this.hours = input.value;
      return;
    }

    let valueAsNumber = parseInt(input.value, 10);

    // If somehow we have non-numbers reset to the minimum
    if (isNaN(valueAsNumber)) {
      // Using "12" here for 12 hours clock as that's actually the
      // first usable hour.
      valueAsNumber = this.hoursType === '12H' ? 12 : 0;
    }

    // When the user enters the second digit we can check if it's
    // over the maximum amount of hours and cap it.
    if (valueAsNumber >= 10) {
      valueAsNumber = Math.min(valueAsNumber, this.maxHours);
      this.hours = valueAsNumber.toString();
    } else {
      // A single digit is OK for now
      this.hours = input.value;
    }

    this.updateValue();
    this.onChange(this.value);
  }

  handleHoursTypeChange(e: MatButtonToggleChange) {
    this.hoursType = e.value as HoursType;

    if (this.hours == null || this.hours === '') {
      return;
    }

    const hoursAsNumber = parseInt(this.hours, 10);

    if (this.hoursType === '12H') {
      const [newHour, newPeriod] = to12Hour(hoursAsNumber);

      this.hours = newHour.toString();
      this.period = newPeriod;
    } else {
      const newHour = to24Hour(hoursAsNumber, this.period);

      this.hours = newHour.toString().padStart(2, '0');
    }

    this.updateValue();
    this.markAsTouched();
    this.onChange(this.value);
  }

  /**
   * Responsible for formatting what the user entered when they leave
   * the field to make it look "nicer" and fit the currently used
   * clock better. For example "07" would be changed to "7".
   */
  handleMinutesBlur() {
    let minutesAsNumber = parseInt(this.minutes, 10);

    // If they removed all input we can just put "0" minutes
    if (isNaN(minutesAsNumber)) {
      minutesAsNumber = 0;
    }

    this.minutes = this.formatMinutes(minutesAsNumber);
    this.updateValue();
    this.markAsTouched();
    this.onChange(this.value);
  }

  /**
   * Responsible for parsing the minutes inputted by the user.
   * It attempts to try and preserve what the user has entered
   * unless it is drastically wrong.
   */
  handleMinutesInput(e: Event) {
    const input = e.target as HTMLInputElement;
    // Strip out anything that isn't a number
    input.value = removeNonNumbers(input.value);

    if (input.value.length === 0) {
      this.minutes = input.value;
      return;
    }

    let valueAsNumber = parseInt(input.value, 10);

    if (isNaN(valueAsNumber)) {
      valueAsNumber = 0;
    }

    // As they type the second digit clamp to maximum possible hours
    // for better UX
    if (valueAsNumber >= 10) {
      valueAsNumber = clamp(valueAsNumber, MIN_MINUTES, MAX_MINUTES);
      this.minutes = valueAsNumber.toString();
    } else {
      this.minutes = input.value;
    }

    this.updateValue();
    this.onChange(this.value);
  }

  handlePeriodChange(e: Event) {
    this.period = (e.target as HTMLSelectElement).value as PeriodType;
    this.updateValue();
    this.markAsTouched();
    this.onChange(this.value);
  }

  /**
   * Parses and corrects the value passed by any form the control is in.
   */
  writeValue(value: string): void {
    // Ignore any strange input. The form will still see the initial
    // value returned in validation but as soon as the user touches
    // our control we can correct it
    if (value == null || value === '' || !value.match(/^\d\d:\d\d$/)) {
      this.hours = '';
      this.minutes = '';
      this.value = null;
      return;
    }

    const newValue = value.trim();

    const [hours, minutes] = newValue.split(':');
    const hoursAsNumber = parseInt(hours, 10);
    const minsAsNumber = parseInt(minutes, 10);

    if (this.hoursType === '12H') {
      const [hour, period] = to12Hour(hoursAsNumber);
      this.hours = formatHour(hour, this.hoursType);
      this.period = period;
    } else {
      this.hours = formatHour(hoursAsNumber, this.hoursType);
    }

    this.minutes = this.formatMinutes(minsAsNumber);

    this.updateValue();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  selectAll(e: Event) {
    (e.target as HTMLInputElement).select();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private formatMinutes(minutes: number): string {
    return clamp(minutes, MIN_MINUTES, MAX_MINUTES).toString().padStart(2, '0');
  }

  private updateValue() {
    // If we have something we can't put a value together for
    // just hold on until we do.
    if (
      this.hours == null ||
      this.hours === '' ||
      this.minutes == null ||
      this.minutes === ''
    ) {
      this.value = null;
      return;
    }

    let hoursAsNumber = parseInt(this.hours, 10);
    let minutesAsNumber = parseInt(this.minutes, 10);

    // Shouldn't happen but we may have junk for both of these.
    if (isNaN(hoursAsNumber)) {
      hoursAsNumber = this.hoursType === '12H' ? 12 : 0;
    }

    if (isNaN(minutesAsNumber)) {
      minutesAsNumber = 0;
    }

    const hoursStr = (
      this.hoursType === '12H'
        ? to24Hour(hoursAsNumber, this.period)
        : hoursAsNumber
    )
      .toString()
      .padStart(2, '0');
    const minsStr = this.formatMinutes(minutesAsNumber);

    this.value = `${hoursStr}:${minsStr}`;
  }
}
