import * as tslib_1 from "tslib";
import { createFeatureSelector, createSelector, } from '@ngrx/store';
import { compareDesc, parseISO } from 'date-fns';
import { sortByKeyAlphabeticallyLowercase } from 'src/app/shared/utils';
import { TabOptions } from '../models/ContentPacks';
import * as fromContentAssignment from './content-assignment.reducer';
import * as fromPackAssignment from './pack-assignment.reducer';
import * as fromPatient from './patient.reducer';
import * as fromStatus from './status.reducer';
// Reducer Definition
export var reducers = {
    patientData: fromPatient.reducer,
    status: fromStatus.reducer,
    contentAssignment: fromContentAssignment.reducer,
    packAssignment: fromPackAssignment.reducer,
};
// Selectors
export var getPatientInfoState = createFeatureSelector('patientInfo');
export var getPatientData = createSelector(getPatientInfoState, function (state) { return state.patientData; });
export var getPatients = createSelector(getPatientData, fromPatient.getPatients);
export var getPatientById = function (id) {
    return createSelector(getPatients, function (state) { return state[id]; });
};
export var getPatientLoadingStatus = createSelector(getPatientInfoState, function (state) { return state.status; });
export var isPatientLoading = createSelector(getPatientLoadingStatus, fromStatus.patientIsLoading);
export var isPatientListFilterActive = createSelector(getPatientLoadingStatus, fromStatus.patientListFiltered);
export var getPatientListFilter = createSelector(getPatientLoadingStatus, fromStatus.getPatientListFilter);
export var getActiveSection = createSelector(getPatientLoadingStatus, fromStatus.getActiveSection);
// Pack Assignment Accessors
// ========================================
export var getPackAssignmentState = createSelector(getPatientInfoState, function (state) { return state.packAssignment; });
export var getContentPacks = createSelector(getPackAssignmentState, fromPackAssignment.getContentPacks);
export var getActivePackId = createSelector(getPackAssignmentState, fromPackAssignment.getActivePackId);
export var getSelectedPack = createSelector(getActivePackId, getContentPacks, fromPackAssignment.getSelectedPack);
export var getPackDetail = createSelector(getPackAssignmentState, fromPackAssignment.getPackDetail);
export var getLockedItems = createSelector(getPackAssignmentState, fromPackAssignment.getLockedItems);
export var getActiveAssignedPackId = createSelector(getPackAssignmentState, fromPackAssignment.getActiveAssignedPackId);
export var getSelectedPackDetail = createSelector(getActivePackId, getPackDetail, getLockedItems, fromPackAssignment.getSelectedPackDetail);
// Content Assignment Accessors
// ========================================
export var getContentAssignmentState = createSelector(getPatientInfoState, function (state) { return state.contentAssignment; });
// top level
export var getActiveContentTab = createSelector(getContentAssignmentState, fromContentAssignment.getContentTab);
export var getActiveContentType = createSelector(getContentAssignmentState, fromContentAssignment.getActiveContentType);
export var getPatientOnlyContentTypeId = createSelector(getContentAssignmentState, fromContentAssignment.getPatientOnlyContentTypeId);
export var getViewAsPatientOpenFolder = createSelector(getContentAssignmentState, fromContentAssignment.viewAsPatientOpenFolder);
export var getContentEntries = createSelector(getContentAssignmentState, fromContentAssignment.getContentEntries);
export var getContentTypes = createSelector(getContentAssignmentState, fromContentAssignment.getContentTypes);
export var getContentSelect = createSelector(getContentAssignmentState, fromContentAssignment.getContentSelect);
export var getAssignedContent = createSelector(getContentAssignmentState, fromContentAssignment.getAssignedContent);
export var getLoading = createSelector(getContentAssignmentState, fromContentAssignment.getLoading);
export var getPatientOnlyAssignedContent = createSelector(getContentAssignmentState, fromContentAssignment.getPatientOnlyContent);
export var viewAsPatient = createSelector(getContentAssignmentState, fromContentAssignment.viewAsPatient);
export var getPatientViewOfContent = createSelector(getContentAssignmentState, fromContentAssignment.getPatientViewOfContent);
export var getPatientAssignedContent = createSelector(getContentAssignmentState, fromContentAssignment.getPatientAssignedContent);
export var getPatientAssignedPacks = createSelector(getContentAssignmentState, fromContentAssignment.getPatientAssignedPacks);
export var loadingPatientViewOfContent = createSelector(getContentAssignmentState, fromContentAssignment.loadingPatientViewOfContent);
export var loadingPatientAssigned = createSelector(getContentAssignmentState, fromContentAssignment.loadingPatientAssigned);
export var isClinicViewingAssignedPacks = createSelector(getContentAssignmentState, fromContentAssignment.isClinicViewingAssignedPacks);
export var isClinicViewingAssignedItems = createSelector(getContentAssignmentState, fromContentAssignment.isClinicViewingAssignedItems);
export var getSelectedPatientAssignedPack = createSelector(getContentAssignmentState, fromContentAssignment.getPatientAssignedPacks);
// second level
export var isCategoryPatientOnly = createSelector(getActiveContentType, getPatientOnlyContentTypeId, function (activeCategoryId, patientOnlyId) { return activeCategoryId === patientOnlyId; });
export var isContentSelectFilterActive = createSelector(getContentSelect, function (state) { return state.filterActive; });
export var getContentSelectFilterString = createSelector(getContentSelect, function (state) { return state.filterString; });
export var isAssignedContentFilterActive = createSelector(getAssignedContent, function (state) { return state.filterActive; });
export var getAssignedContentFilterString = createSelector(getAssignedContent, function (state) { return state.filterString; });
export var getSelectdFolderName = createSelector(getActiveContentTab, getActiveContentType, getContentTypes, function (selectedOption, typeId, contentTypes) {
    var activeFolder = selectedOption === TabOptions.FOLDERS
        ? contentTypes.find(function (category) { return typeId === category.id; }) || null
        : null;
    if (activeFolder) {
        return activeFolder.name;
    }
    else {
        return null;
    }
});
// Filter Patient Specific documents if filter is active
var filterPatientDocs = function (patientFiles, filterActive, filterString) {
    return filterActive
        ? patientFiles.filter(function (e) {
            return e.documentname.toLowerCase().includes(filterString);
        })
        : patientFiles;
};
var ɵ0 = filterPatientDocs;
// Patient related documents in the content swing
// Active
export var getActivePatientOnlyContent = createSelector(getPatientOnlyAssignedContent, function (patientFiles) {
    return patientFiles
        .filter(function (file) { return file.isactive; })
        .sort(function (a, b) {
        return compareDesc(parseISO(a.salvemodified), parseISO(b.salvemodified));
    });
});
// Active with Filter
export var getActiveFilteredPatientOnlyContent = createSelector(getActivePatientOnlyContent, isAssignedContentFilterActive, getAssignedContentFilterString, filterPatientDocs);
// Inactive
// Inactive with Filter
export var getInactivePatientOnlyContent = createSelector(getPatientOnlyAssignedContent, function (patientFiles) {
    return patientFiles.filter(function (file) { return !file.isactive; });
});
export var getInactiveFilteredPatientOnlyContent = createSelector(getInactivePatientOnlyContent, isContentSelectFilterActive, getContentSelectFilterString, filterPatientDocs);
// Function can be used for filtering content entries into both
// the content-select and the assigned content lists
var filterEntriesWithListSearch = function (entries, filterActive, filterString) {
    if (filterActive) {
        return entries.filter(function (e) {
            return e.contentName.toLowerCase().includes(filterString);
        });
    }
    else {
        return entries;
    }
};
var ɵ1 = filterEntriesWithListSearch;
// Filter content entries select for the selected content type
export var getContentEntriesByType = createSelector(getActiveContentType, getContentEntries, function (contentTypeId, entries) {
    return entries.hasOwnProperty(contentTypeId) ? entries[contentTypeId] : [];
});
// Content Flow for content-select list
// ===========================================================
// filter remaining content entries removing those that are already
// assigned to the patient app, and remove entries that are empty
export var getNonAssignedFilteredContent = createSelector(getContentEntriesByType, getPatientAssignedContent, function (entries, assignedContent) {
    return entries.filter(function (e) {
        return !assignedContent.some(function (c) { return +c.contentEntryHeaderId === +e.contentEntryHeaderId; }) && !e.isEmpty;
    });
});
export var applySearchToFilteredCsContent = createSelector(getNonAssignedFilteredContent, isContentSelectFilterActive, getContentSelectFilterString, filterEntriesWithListSearch);
// Content Flow for assign content list
// ===========================================================
// Filter pack content by pack items that are already assigned to a patient
export var getSelectedPackFilteredDetail = createSelector(getSelectedPack, getSelectedPackDetail, getSelectedPatientAssignedPack, function (contentPack, packDetail, assignedPacks) {
    var selectedAssignedPack = assignedPacks.find(function (p) {
        return +p.packId === +contentPack.id;
    });
    var rtnPackDetail = !selectedAssignedPack
        ? packDetail
        : packDetail.filter(function (p) {
            return selectedAssignedPack.contents.every(function (c) {
                return c.contentEntryHeaderId !== p.contentEntryHeaderId &&
                    c.hiddenId !== p.contentEntryHeaderId;
            });
        });
    return rtnPackDetail;
});
// alter pack to add boolean property if it is already assigned
export var selectedPackWithAssignment = createSelector(getSelectedPack, getSelectedPatientAssignedPack, getSelectedPackDetail, function (selectedPack, assignedPacks, packDetail) {
    var assignedPack = assignedPacks.find(function (p) { return +p.packId === +selectedPack.id; });
    var sPack = tslib_1.__assign({}, selectedPack, { isAssigned: !!assignedPack, empty: packDetail.length === 0, allAssigned: assignedPack && assignedPack.contents.length === packDetail.length });
    return sPack;
});
export var flatAssignedContentPacks = createSelector(getPatientAssignedPacks, function (packs) {
    return packs.reduce(function (a, p) { return a.concat(p.contents); }, []);
});
export var allPatientAssignedContent = createSelector(getPatientAssignedContent, flatAssignedContentPacks, function (assignedContent, contentFromPacks) {
    var contentArr = assignedContent.concat(contentFromPacks);
    var seen = [];
    return contentArr.filter(function (i) {
        var notSeen = seen.indexOf(i.contentEntryHeaderId) === -1;
        if (notSeen) {
            seen.push(i.contentEntryHeaderId);
        }
        return notSeen;
    });
});
export var filteredAssignedContentPacks = createSelector(allPatientAssignedContent, isAssignedContentFilterActive, getAssignedContentFilterString, function (assignedItems, filterActive, filterString) {
    return filterActive
        ? assignedItems.filter(function (i) {
            return i.contentName.toLowerCase().includes(filterString.toLowerCase());
        })
        : assignedItems;
});
export var patientViewOfContent = createSelector(filteredAssignedContentPacks, function (contentArr) {
    var seenContent = [];
    var contentHash = contentArr.reduce(function (hash, b) {
        var _a, _b;
        if (!seenContent.includes(b.contentName)) {
            seenContent.push(b.contentName);
            if (Object.hasOwnProperty.call(hash, b.contentCategory)) {
                return tslib_1.__assign({}, hash, (_a = {}, _a[b.contentCategory] = {
                    categoryName: b.contentCategory,
                    categoryId: b.contentCategoryId,
                    contents: hash[b.contentCategory].contents.concat([b]),
                }, _a));
            }
            else {
                return tslib_1.__assign({}, hash, (_b = {}, _b[b.contentCategory] = {
                    categoryName: b.contentCategory,
                    categoryId: b.contentCategoryId,
                    contents: [b],
                }, _b));
            }
        }
    }, {});
    return Object.keys(contentHash)
        .reduce(function (arr, key) {
        var currentCategory = contentHash[key];
        return arr.concat([currentCategory]);
    }, [])
        .sort(sortByKeyAlphabeticallyLowercase('categoryName'));
});
export { ɵ0, ɵ1 };
