/**
 * Enumerated, namespaced layout action types
 */
export var LayoutActionTypes;
(function (LayoutActionTypes) {
    LayoutActionTypes["OpenSidenav"] = "[Layout] Open Sidenav";
    LayoutActionTypes["CloseSidenav"] = "[Layout] Close Sidenav";
    LayoutActionTypes["ActivateMenuItem"] = "[Layout] Activate Menu Item";
})(LayoutActionTypes || (LayoutActionTypes = {}));
/**
 * Action Creators
 */
var OpenSidenav = /** @class */ (function () {
    function OpenSidenav() {
        this.type = LayoutActionTypes.OpenSidenav;
    }
    return OpenSidenav;
}());
export { OpenSidenav };
var CloseSidenav = /** @class */ (function () {
    function CloseSidenav() {
        this.type = LayoutActionTypes.CloseSidenav;
    }
    return CloseSidenav;
}());
export { CloseSidenav };
var ActivateMenuItemAction = /** @class */ (function () {
    function ActivateMenuItemAction(payload) {
        this.payload = payload;
        this.type = LayoutActionTypes.ActivateMenuItem;
    }
    return ActivateMenuItemAction;
}());
export { ActivateMenuItemAction };
