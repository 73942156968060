import {
  TypingEventPayload,
  TypingEventResponse,
  useRealTimeMessagingContext,
} from '@react/lib/context/websocket';
import { useTranslations } from '@react/lib/i18n';
import { differenceInMilliseconds } from 'date-fns';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { User } from 'src/app/auth/models/user';
import {
  MARK_MESSAGE_AS_NOT_TYPING_AFTER_MS,
  MARK_MESSAGE_AS_TYPED_AFTER_MS,
  MESSAGE_MAX_VALID_ELAPSED_MS,
  TYPING_DELAY_MS,
} from '../constants';

interface TypingDetails {
  status: 'typing' | 'typed';
  users: TypingEventResponse['user'][];
}

function isMyOwnMessage(event: TypingEventResponse, user: User): boolean {
  return event.user === `${user.FirstName} ${user.LastName}`;
}

function isEventTimeInRange(event: TypingEventResponse): boolean {
  const now = new Date();
  return (
    differenceInMilliseconds(now, new Date(event.lastEdited)) <
    MESSAGE_MAX_VALID_ELAPSED_MS
  );
}

function isMessageRemoved(event: TypingEventResponse): boolean {
  return event.lastEdited.includes('1970-01-01');
}

function shouldMarkAsTyped(event: TypingEventResponse): boolean {
  const now = new Date();
  return (
    differenceInMilliseconds(now, new Date(event.lastEdited)) >
    MARK_MESSAGE_AS_TYPED_AFTER_MS
  );
}

export function useRealTimeMessaging(currentUser: User) {
  const { t } = useTranslations();
  const {
    connected,
    sendTypingEvent: websocketSendTypingEvent,
    setOnMessage,
    websocketDisconnect,
    websocketConnect,
  } = useRealTimeMessagingContext();

  const timeoutIds = useRef<{ [messageId: string]: number }>({});
  const [conversationTypingIdMap, setConversationTypingIdMap] = useState<{
    [messageId: string]: TypingDetails | undefined;
  }>({});

  useEffect(() => {
    websocketConnect();
    return () => {
      websocketDisconnect();
    };
  }, []);

  const getTypingConversationMessage = useCallback(
    (conversationId: number): string | undefined => {
      const typingDetails = conversationTypingIdMap[conversationId];

      if (typingDetails) {
        const { users, status } = typingDetails;
        let message = users.join(', ');

        if (status === 'typed') {
          message +=
            users.length === 1
              ? t.Messages.SingularTyped
              : t.Messages.PluralTyped;
        } else {
          message +=
            users.length === 1
              ? t.Messages.SingularTyping
              : t.Messages.PluralTyping;
        }

        return message;
      }
    },
    [conversationTypingIdMap],
  );

  const sendTypingEvent = ({
    messageId,
    user,
    content,
  }: TypingEventPayload) => {
    websocketSendTypingEvent({
      content,
      messageId,
      user,
    });
  };

  const throttledSendTypingEvent = useCallback(
    throttle(sendTypingEvent, TYPING_DELAY_MS),
    [conversationTypingIdMap, websocketSendTypingEvent, connected],
  );

  useEffect(() => {
    if (connected) {
      const onMessage = (event: TypingEventResponse) => {
        clearTimeout(timeoutIds.current[event.messageId]);

        const typingDetails = conversationTypingIdMap[event.messageId] || {
          users: [],
          status: 'typing',
        };

        if (isMyOwnMessage(event, currentUser) || isMessageRemoved(event)) {
          typingDetails.users = typingDetails.users.filter(
            (userName) => userName !== event.user,
          );
        } else if (
          isEventTimeInRange(event) &&
          !typingDetails.users.includes(event.user)
        ) {
          typingDetails.users.push(event.user);

          if (shouldMarkAsTyped(event)) {
            typingDetails.status = 'typed';
          }
        }

        // Filter details without users
        conversationTypingIdMap[event.messageId] =
          typingDetails.users.length === 0 ? undefined : typingDetails;
        setConversationTypingIdMap({ ...conversationTypingIdMap });

        const autoSetTypingStatus = (delay: number) => {
          const details = conversationTypingIdMap[event.messageId];
          if (!details) {
            return;
          }

          // @ts-ignore-nextline
          timeoutIds.current[event.messageId] = setTimeout(() => {
            if (details.status === 'typing') {
              details.status = 'typed';
              setConversationTypingIdMap({
                [event.messageId]: details,
                ...conversationTypingIdMap,
              });
              autoSetTypingStatus(MARK_MESSAGE_AS_NOT_TYPING_AFTER_MS);
            } else {
              delete conversationTypingIdMap[event.messageId];
              setConversationTypingIdMap({ ...conversationTypingIdMap });
            }
          }, delay);
        };

        autoSetTypingStatus(MARK_MESSAGE_AS_TYPED_AFTER_MS);
      };

      const socketId = setOnMessage(onMessage);

      return () => {
        Object.keys(timeoutIds.current).forEach((key) => {
          clearTimeout(timeoutIds.current[key]);
        });
        setOnMessage(null, socketId);
      };
    }
  }, [connected]);

  return {
    getTypingConversationMessage,
    sendTypingEvent: throttledSendTypingEvent,
  };
}
