import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { MessagingSummaryResponse } from 'src/app/messaging/models/messagingSummaryResponse';
import { Label } from 'src/app/models/Label';
import { environment } from 'src/environments/environment';
import * as LoadMessageActions from '../../../messaging/actions/load-message.actions';
import * as MessageActions from '../../../messaging/actions/message-ui.actions';
import * as fromMessages from '../../../messaging/reducers';
import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';
import * as DashboardActions from '../../actions/dashboard.actions';
import { TableMedicationAction } from '../../models/MedicationAction';
import { ClinicSummaryResponse } from '../../models/responses/clinic-summary.response';
import * as fromDashboard from '../../reducers';

@Component({
  selector: 'portal-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public summary$: Observable<ClinicSummaryResponse>;
  public messagingSummary$: Observable<MessagingSummaryResponse>;
  public labels$: Observable<{ name: string }[]>;
  public nonTimedTriggerMedication$: Observable<TableMedicationAction[]>;
  public medication$: Observable<TableMedicationAction[]>;
  public sectionText$: Observable<any>;
  public tableText$: Observable<any>;

  public nonTimedTriggerTableText$: Observable<any>;
  public nonTimedTriggerTableText: any;

  public tableText: any;
  public sectionText: any;

  // timer for refreshing dashboard
  private interval;

  private _subs = new Subscription();
  public displayedColumnList: string[] = ['name', 'type', 'drug', 'dueDate'];

  constructor(
    private _store: Store<fromRoot.State>,
    private _navigationService: NavigationService,
    private angulartics2: Angulartics2,
  ) {
    // Setup Observables
    this.labels$ = this._store.pipe(select(fromMessages.getLabelNames));
    this.sectionText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('Dashboard')),
    );
    this.tableText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('UpcomingDrugs')),
    );
    this.nonTimedTriggerTableText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('NonTimedTriggerDrugs')),
    );
    this.summary$ = this._store.pipe(select(fromDashboard.getDashboardSummary));
    this.messagingSummary$ = this._store.pipe(
      select(fromMessages.getMessagingSummary),
    );
    this.medication$ = this._store.pipe(
      select(fromDashboard.getDashboardMedications),
      map((meds) => {
        return meds.Medication.filter((med) => med.StatusId === 2).map(
          (med) => ({
            id: med.PatientId,
            name: `${med.FirstName} ${med.LastName} (${med.PatientIdentifier})`.toUpperCase(),
            type: med.Type,
            drug: med.DrugName,
            dosage: med.DrugDosage,
            status: med.Status,
            statusId: med.StatusId,
            dueDate: med.DueDate,
          }),
        );
      }),
    );

    this.nonTimedTriggerMedication$ = this._store.pipe(
      select(fromDashboard.getDashboardNonTimedTriggerMedications),
      map((meds) => {
        return meds.Medication.filter((med) => true).map((med) => ({
          id: med.PatientId,
          name: `${med.FirstName} ${med.LastName} (${med.PatientIdentifier})`.toUpperCase(),
          type: med.Type,
          drug: med.DrugName,
          dosage: med.DrugDosage,
          status: med.Status,
          statusId: med.StatusId,
          dueDate: med.DueDate,
        }));
      }),
    );

    // capture text entries to feed into components
    this._subs.add(
      this.sectionText$.subscribe((val) => (this.sectionText = val)),
    );
    this._subs.add(this.tableText$.subscribe((val) => (this.tableText = val)));
    this._subs.add(
      this.nonTimedTriggerTableText$.subscribe(
        (val) => (this.nonTimedTriggerTableText = val),
      ),
    );
  }

  ngOnInit() {
    // reload dashboard straight away and then
    // every 30 seconds
    this.load();
    this.interval = setInterval(() => {
      this.load();
    }, 1000 * environment.dashboardRefreshIntervalSeconds);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this._subs.unsubscribe();
  }

  public gotoMessages(label: string): void {
    // Track event
    const action = 'Navigate to ' + label + ' messages';
    this.angulartics2.eventTrack.next({
      action,
      properties: { category: 'Messaging' },
    });
    // set label of messages to view
    this._store.dispatch(new MessageActions.ApplyFilter(label.toLowerCase()));
    // then open messages
    this._navigationService.navigate(['/messages']);
  }

  public goToMessages(messageTypeId: number): void {
    this._store.dispatch(
      new MessageActions.ApplyMessageTypeFilter(
        new Label({
          messageTypeId,
          displayName: '',
          filter: '',
          isGroup: false,
        }),
      ),
    );
    // then open messages
    this._navigationService.navigate(['/messages']);
  }

  public gotoPatient(number: string): void {
    this._navigationService.navigate([`/patients/${number}`]);
  }

  public load(): void {
    this._store.dispatch(new DashboardActions.RefreshClinicDashboard());
    this._store.dispatch(new LoadMessageActions.LoadMessagingSummary());
  }
}
