import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { useLocaleDateTime } from '@react/lib/date';
import { useTranslations } from '@react/lib/i18n';
import * as React from 'react';
import SIDEStatusChip from '../../SIDEStatusChip';
import { StyledTableHead, StyledTableRow } from './index.styled';
var RegistrationRecipeActions = function (_a) {
    var data = _a.data;
    var t = useTranslations().t;
    var localeDateTime = useLocaleDateTime();
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTableHead, { "data-testid": "TableHead" },
            React.createElement(TableRow, null,
                React.createElement(TableCell, { className: "idcol" }, t.Admin.SIDE.ActionListTableHeaderId),
                React.createElement(TableCell, { className: "contentcol" }, t.Admin.SIDE.ActionListTableHeaderContent),
                React.createElement(TableCell, null, t.Admin.SIDE.ActionListTableHeaderActionType),
                React.createElement(TableCell, null, t.Admin.SIDE.ActionListTableHeaderTiming),
                React.createElement(TableCell, null, t.Admin.SIDE.ActionListTableHeaderActionStatus),
                React.createElement(TableCell, null, t.Admin.SIDE.ActionListTableHeaderCreatedOn),
                React.createElement(TableCell, null, t.Admin.SIDE.ActionListTableHeaderLastUpdatedOn),
                React.createElement(TableCell, null, t.Admin.SIDE.ActionListTableHeaderLastUpdatedBy))),
        React.createElement(TableBody, null, data &&
            data.pages.map(function (page) {
                return page.data.map(function (item) { return (React.createElement(StyledTableRow, { key: item.id, "data-testid": "TableRow", role: "button", tabIndex: 0 },
                    React.createElement(TableCell, null, item.id),
                    React.createElement(TableCell, { className: "truncate", title: item.content }, item.content),
                    React.createElement(TableCell, null, item.actiontype),
                    React.createElement(TableCell, null, item.period + " " + t.Admin.SIDE[item.periodname + "Period"] + " " + (item.before === true
                        ? t.Admin.SIDE.Before
                        : t.Admin.SIDE.After)),
                    React.createElement(TableCell, null,
                        React.createElement(SIDEStatusChip, { status: item.status, label: t.Admin.SIDE[item.status + "Status"] })),
                    React.createElement(TableCell, null, localeDateTime.format(new Date(item.createdon), 'PP, p')),
                    React.createElement(TableCell, null, localeDateTime.format(new Date(item.lastmodifiedon), 'PP, p')),
                    React.createElement(TableCell, null, item.lastupdatedby))); });
            }))));
};
var ɵ0 = RegistrationRecipeActions;
export default RegistrationRecipeActions;
export { ɵ0 };
