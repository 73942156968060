import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  ContentActionTypes,
  CreateCategory,
  CreateCategoryError,
  CreateCategorySuccess
} from './../../actions/content.actions';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../reducers';
import * as fromSettings from '../../../settings/reducers';

import { validateFolderName } from '../../services/async-validators/validate-folder-name';
import { ContentService } from '../../services/content.service';

@Component({
  selector: 'portal-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit, OnDestroy {
  public checkingFolderName$ = new BehaviorSubject(false);

  public savingFolder$ = new BehaviorSubject(false);
  public savedFolder$ = new BehaviorSubject(false);
  public errorEncountered$ = new BehaviorSubject(false);

  public folderName: FormControl;

  public _subs = new Subscription();

  public contentCreatorText$: Observable<any>;
  public contentCreatorText: any;

  constructor(
    private _store: Store<fromRoot.State>,
    private _updates: Actions,
    public dialogRef: MatDialogRef<CreateCategoryComponent>,
    public dialog: MatDialog,
    private _contentService: ContentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.folderName = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      [validateFolderName(this._contentService, this.checkingFolderName$)]
    );

    // watch for errors on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<CreateCategoryError>(ContentActionTypes.CreateCategoryError)
        )
        .subscribe(() => {
          this.savingFolder$.next(false);
          this.errorEncountered$.next(true);
          this.exitDialog(false, 1000);
        })
    );

    // watch for success on savingCategory Action
    this._subs.add(
      this._updates
        .pipe(
          ofType<CreateCategorySuccess>(
            ContentActionTypes.CreateCategorySuccess
          )
        )
        .subscribe(() => {
          this.savingFolder$.next(false);
          this.savedFolder$.next(true);
          this.exitDialog(true, 1000);
        })
    );

    this.contentCreatorText$ = this._store.pipe(
      select(fromSettings.getSectionTranslations('ContentCreatorSections'))
    );
    this._subs.add(
      this.contentCreatorText$.subscribe((t) => (this.contentCreatorText = t))
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public clearForm() {
    this.folderName.setValue('');
  }

  public createNewFolder() {
    this.savingFolder$.next(true);
    const newFolderName = this.folderName.value;
    this._store.dispatch(
      new CreateCategory({
        name: newFolderName,
        patientonly: false,
        isactive: true,
        isdrug: false,
        isappointment: false,
        issite: false,
        isstaff: false,
        istreatment: false
      })
    );
  }

  public cancelNameChange() {
    this.exitDialog(false);
  }

  public exitDialog(status: boolean = false, time: number = 0) {
    setTimeout(() => {
      this.dialogRef.close(status);
    }, time);
  }
}
