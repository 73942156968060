import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { compareDesc, parseISO } from 'date-fns';
import { sortByKeyAlphabeticallyLowercase } from 'src/app/shared/utils';
import * as fromRoot from '../../reducers';
import { ContentEntryHeader } from '../models/Content';
import { TabOptions } from '../models/ContentPacks';
import {
  AssignedContentPack,
  PatientOnlyAssignContentItem,
} from '../models/UserContent';
import * as fromContentAssignment from './content-assignment.reducer';
import * as fromPackAssignment from './pack-assignment.reducer';
import * as fromPatient from './patient.reducer';
import * as fromStatus from './status.reducer';

// Interfaces
export interface PatientState {
  patientData: fromPatient.State;
  status: fromStatus.State;
  contentAssignment: fromContentAssignment.State;
  packAssignment: fromPackAssignment.State;
}

export interface State extends fromRoot.State {
  patientInfo: PatientState;
}

// Reducer Definition
export const reducers: ActionReducerMap<PatientState> = {
  patientData: fromPatient.reducer,
  status: fromStatus.reducer,
  contentAssignment: fromContentAssignment.reducer,
  packAssignment: fromPackAssignment.reducer,
};

// Selectors
export const getPatientInfoState =
  createFeatureSelector<PatientState>('patientInfo');

export const getPatientData = createSelector(
  getPatientInfoState,
  (state: PatientState) => state.patientData,
);

export const getPatients = createSelector(
  getPatientData,
  fromPatient.getPatients,
);

export const getPatientById = (id: number) =>
  createSelector(getPatients, (state: fromPatient.PatientHash) => state[id]);

export const getPatientLoadingStatus = createSelector(
  getPatientInfoState,
  (state: PatientState) => state.status,
);

export const isPatientLoading = createSelector(
  getPatientLoadingStatus,
  fromStatus.patientIsLoading,
);

export const isPatientListFilterActive = createSelector(
  getPatientLoadingStatus,
  fromStatus.patientListFiltered,
);

export const getPatientListFilter = createSelector(
  getPatientLoadingStatus,
  fromStatus.getPatientListFilter,
);

export const getActiveSection = createSelector(
  getPatientLoadingStatus,
  fromStatus.getActiveSection,
);

// Pack Assignment Accessors
// ========================================
export const getPackAssignmentState = createSelector(
  getPatientInfoState,
  (state: PatientState) => state.packAssignment,
);
export const getContentPacks = createSelector(
  getPackAssignmentState,
  fromPackAssignment.getContentPacks,
);

export const getActivePackId = createSelector(
  getPackAssignmentState,
  fromPackAssignment.getActivePackId,
);
export const getSelectedPack = createSelector(
  getActivePackId,
  getContentPacks,
  fromPackAssignment.getSelectedPack,
);
export const getPackDetail = createSelector(
  getPackAssignmentState,
  fromPackAssignment.getPackDetail,
);
export const getLockedItems = createSelector(
  getPackAssignmentState,
  fromPackAssignment.getLockedItems,
);
export const getActiveAssignedPackId = createSelector(
  getPackAssignmentState,
  fromPackAssignment.getActiveAssignedPackId,
);
export const getSelectedPackDetail = createSelector(
  getActivePackId,
  getPackDetail,
  getLockedItems,
  fromPackAssignment.getSelectedPackDetail,
);

// Content Assignment Accessors
// ========================================
export const getContentAssignmentState = createSelector(
  getPatientInfoState,
  (state: PatientState) => state.contentAssignment,
);

// top level
export const getActiveContentTab = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getContentTab,
);
export const getActiveContentType = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getActiveContentType,
);
export const getPatientOnlyContentTypeId = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getPatientOnlyContentTypeId,
);
export const getViewAsPatientOpenFolder = createSelector(
  getContentAssignmentState,
  fromContentAssignment.viewAsPatientOpenFolder,
);
export const getContentEntries = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getContentEntries,
);
export const getContentTypes = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getContentTypes,
);
export const getContentSelect = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getContentSelect,
);
export const getAssignedContent = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getAssignedContent,
);
export const getLoading = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getLoading,
);
export const getPatientOnlyAssignedContent = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getPatientOnlyContent,
);

export const viewAsPatient = createSelector(
  getContentAssignmentState,
  fromContentAssignment.viewAsPatient,
);
export const getPatientViewOfContent = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getPatientViewOfContent,
);
export const getPatientAssignedContent = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getPatientAssignedContent,
);
export const getPatientAssignedPacks = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getPatientAssignedPacks,
);
export const loadingPatientViewOfContent = createSelector(
  getContentAssignmentState,
  fromContentAssignment.loadingPatientViewOfContent,
);
export const loadingPatientAssigned = createSelector(
  getContentAssignmentState,
  fromContentAssignment.loadingPatientAssigned,
);
export const isClinicViewingAssignedPacks = createSelector(
  getContentAssignmentState,
  fromContentAssignment.isClinicViewingAssignedPacks,
);
export const isClinicViewingAssignedItems = createSelector(
  getContentAssignmentState,
  fromContentAssignment.isClinicViewingAssignedItems,
);

export const getSelectedPatientAssignedPack = createSelector(
  getContentAssignmentState,
  fromContentAssignment.getPatientAssignedPacks,
);
// second level
export const isCategoryPatientOnly = createSelector(
  getActiveContentType,
  getPatientOnlyContentTypeId,
  (activeCategoryId, patientOnlyId) => activeCategoryId === patientOnlyId,
);
export const isContentSelectFilterActive = createSelector(
  getContentSelect,
  (state) => state.filterActive,
);
export const getContentSelectFilterString = createSelector(
  getContentSelect,
  (state) => state.filterString,
);
export const isAssignedContentFilterActive = createSelector(
  getAssignedContent,
  (state) => state.filterActive,
);
export const getAssignedContentFilterString = createSelector(
  getAssignedContent,
  (state) => state.filterString,
);

export const getSelectdFolderName = createSelector(
  getActiveContentTab,
  getActiveContentType,
  getContentTypes,
  (selectedOption, typeId, contentTypes) => {
    const activeFolder =
      selectedOption === TabOptions.FOLDERS
        ? contentTypes.find((category) => typeId === category.id) || null
        : null;
    if (activeFolder) {
      return activeFolder.name;
    } else {
      return null;
    }
  },
);

// Filter Patient Specific documents if filter is active
const filterPatientDocs = (
  patientFiles: PatientOnlyAssignContentItem[],
  filterActive: boolean,
  filterString: string,
) =>
  filterActive
    ? patientFiles.filter((e) =>
        e.documentname.toLowerCase().includes(filterString),
      )
    : patientFiles;
// Patient related documents in the content swing
// Active
export const getActivePatientOnlyContent = createSelector(
  getPatientOnlyAssignedContent,
  (patientFiles: PatientOnlyAssignContentItem[]) =>
    patientFiles
      .filter((file) => file.isactive)
      .sort((a, b) =>
        compareDesc(parseISO(a.salvemodified), parseISO(b.salvemodified)),
      ),
);
// Active with Filter
export const getActiveFilteredPatientOnlyContent = createSelector(
  getActivePatientOnlyContent,
  isAssignedContentFilterActive,
  getAssignedContentFilterString,
  filterPatientDocs,
);
// Inactive
// Inactive with Filter
export const getInactivePatientOnlyContent = createSelector(
  getPatientOnlyAssignedContent,
  (patientFiles: PatientOnlyAssignContentItem[]) =>
    patientFiles.filter((file) => !file.isactive),
);
export const getInactiveFilteredPatientOnlyContent = createSelector(
  getInactivePatientOnlyContent,
  isContentSelectFilterActive,
  getContentSelectFilterString,
  filterPatientDocs,
);

// Function can be used for filtering content entries into both
// the content-select and the assigned content lists
const filterEntriesWithListSearch = (
  entries: ContentEntryHeader[],
  filterActive: boolean,
  filterString: string,
) => {
  if (filterActive) {
    return entries.filter((e) =>
      e.contentName.toLowerCase().includes(filterString),
    );
  } else {
    return entries;
  }
};

// Filter content entries select for the selected content type
export const getContentEntriesByType = createSelector(
  getActiveContentType,
  getContentEntries,
  (contentTypeId: number, entries: { [key: string]: ContentEntryHeader[] }) => {
    return entries.hasOwnProperty(contentTypeId) ? entries[contentTypeId] : [];
  },
);

// Content Flow for content-select list
// ===========================================================
// filter remaining content entries removing those that are already
// assigned to the patient app, and remove entries that are empty
export const getNonAssignedFilteredContent = createSelector(
  getContentEntriesByType,
  getPatientAssignedContent,
  (entries, assignedContent) =>
    entries.filter(
      (e) =>
        !assignedContent.some(
          (c) => +c.contentEntryHeaderId === +e.contentEntryHeaderId,
        ) && !e.isEmpty,
    ),
);
export const applySearchToFilteredCsContent = createSelector(
  getNonAssignedFilteredContent,
  isContentSelectFilterActive,
  getContentSelectFilterString,
  filterEntriesWithListSearch,
);

// Content Flow for assign content list
// ===========================================================
// Filter pack content by pack items that are already assigned to a patient
export const getSelectedPackFilteredDetail = createSelector(
  getSelectedPack,
  getSelectedPackDetail,
  getSelectedPatientAssignedPack,
  (contentPack, packDetail, assignedPacks: AssignedContentPack[]) => {
    const selectedAssignedPack = assignedPacks.find((p) => {
      return +p.packId === +contentPack.id;
    });
    const rtnPackDetail = !selectedAssignedPack
      ? packDetail
      : packDetail.filter((p) =>
          selectedAssignedPack.contents.every(
            (c) =>
              c.contentEntryHeaderId !== p.contentEntryHeaderId &&
              c.hiddenId !== p.contentEntryHeaderId,
          ),
        );
    return rtnPackDetail;
  },
);

// alter pack to add boolean property if it is already assigned
export const selectedPackWithAssignment = createSelector(
  getSelectedPack,
  getSelectedPatientAssignedPack,
  getSelectedPackDetail,
  (selectedPack, assignedPacks, packDetail) => {
    const assignedPack = assignedPacks.find(
      (p) => +p.packId === +selectedPack.id,
    );
    const sPack = {
      ...selectedPack,
      isAssigned: !!assignedPack,
      empty: packDetail.length === 0,
      allAssigned:
        assignedPack && assignedPack.contents.length === packDetail.length,
    };
    return sPack;
  },
);

export const flatAssignedContentPacks = createSelector(
  getPatientAssignedPacks,
  (packs: AssignedContentPack[]) => {
    return packs.reduce((a, p) => a.concat(p.contents), []);
  },
);

export const allPatientAssignedContent = createSelector(
  getPatientAssignedContent,
  flatAssignedContentPacks,
  (assignedContent, contentFromPacks) => {
    const contentArr = assignedContent.concat(contentFromPacks);
    const seen = [];
    return contentArr.filter((i) => {
      const notSeen = seen.indexOf(i.contentEntryHeaderId) === -1;
      if (notSeen) {
        seen.push(i.contentEntryHeaderId);
      }
      return notSeen;
    });
  },
);

export const filteredAssignedContentPacks = createSelector(
  allPatientAssignedContent,
  isAssignedContentFilterActive,
  getAssignedContentFilterString,
  (assignedItems, filterActive, filterString) =>
    filterActive
      ? assignedItems.filter((i) =>
          i.contentName.toLowerCase().includes(filterString.toLowerCase()),
        )
      : assignedItems,
);

export const patientViewOfContent = createSelector(
  filteredAssignedContentPacks,
  (contentArr) => {
    const seenContent = [];
    const contentHash = contentArr.reduce((hash, b) => {
      if (!seenContent.includes(b.contentName)) {
        seenContent.push(b.contentName);
        if (Object.hasOwnProperty.call(hash, b.contentCategory)) {
          return {
            ...hash,
            [b.contentCategory]: {
              categoryName: b.contentCategory,
              categoryId: b.contentCategoryId,
              contents: [...hash[b.contentCategory].contents, b],
            },
          };
        } else {
          return {
            ...hash,
            [b.contentCategory]: {
              categoryName: b.contentCategory,
              categoryId: b.contentCategoryId,
              contents: [b],
            },
          };
        }
      }
    }, {});
    return Object.keys(contentHash)
      .reduce((arr, key) => {
        const currentCategory = contentHash[key];
        return [...arr, currentCategory];
      }, [])
      .sort(sortByKeyAlphabeticallyLowercase('categoryName'));
  },
);
