import * as tslib_1 from "tslib";
import { useApiClient } from '@react/lib/api';
import { DataExportTypes, } from '../types';
import { createExportDownloadHandler } from './utils';
export function useExportData(_a) {
    var _this = this;
    var type = _a.type, from = _a.from, to = _a.to, onDownloadSuccess = _a.onDownloadSuccess, onDownloadFailure = _a.onDownloadFailure;
    var apiClient = useApiClient().portal;
    var request = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (type) {
                case DataExportTypes.Messages: {
                    return [2 /*return*/, apiClient.exportMessages(from, to)];
                }
                default: {
                    throw new Error("No request has been found for type: " + type);
                }
            }
            return [2 /*return*/];
        });
    }); };
    return createExportDownloadHandler(request, onDownloadSuccess, onDownloadFailure);
}
