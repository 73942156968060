import * as tslib_1 from "tslib";
import styled from '@emotion/styled';
import { Paper, Select, TableRow } from '@material-ui/core';
export var StyledTableContainer = styled(Paper)(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  margin: ", "px 0;\n  padding: ", "px;\n"], ["\n  margin: ", "px 0;\n  padding: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing(1);
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing(2);
});
export var StyledTableRow = styled(TableRow)(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  &:hover {\n    background-color: #f0f0f7;\n    cursor: pointer;\n  }\n"], ["\n  &:hover {\n    background-color: #f0f0f7;\n    cursor: pointer;\n  }\n"])));
export var StyledSelect = styled(Select)(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  border: 1px solid;\n  border-radius: 4px;\n  font-size: 1rem;\n  outline: none;\n  padding: 0.1rem;\n  padding-left: 10px;\n  width: 100%;\n  &:hover,\n  &:focus {\n    border: ", ";\n  }\n"], ["\n  border: 1px solid;\n  border-radius: 4px;\n  font-size: 1rem;\n  outline: none;\n  padding: 0.1rem;\n  padding-left: 10px;\n  width: 100%;\n  &:hover,\n  &:focus {\n    border: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "1px solid " + theme.colors.primary;
});
var templateObject_1, templateObject_2, templateObject_3;
