import { EventEmitter } from '@angular/core';
var PackAssignmentComponent = /** @class */ (function () {
    function PackAssignmentComponent() {
        this.packDetail = [];
        this.assignItemToPatient = new EventEmitter();
        this.assignPack = new EventEmitter();
        this.toggleLockedItem = new EventEmitter();
        this.removeExclusions = new EventEmitter();
        this.config = {};
    }
    PackAssignmentComponent.prototype.toggleLocked = function (id, isLocked) {
        this.toggleLockedItem.emit({
            id: id,
            isLocked: isLocked
        });
    };
    PackAssignmentComponent.prototype.handleAssignItemToPatient = function (detail) {
        var exclusions = this.packDetail
            .filter(function (d) { return d.contentEntryHeaderId !== detail.contentEntryHeaderId; })
            .map(function (d) { return d.contentEntryHeaderId; });
        if (this.selectedPack.isAssigned) {
            this.assignItemToPatient.emit({
                packId: this.selectedPack.id,
                packName: this.selectedPack.packName,
                exclusions: exclusions,
                packItem: detail,
                packDetail: this.packDetail,
                patientId: this.patientId
            });
        }
        else {
            this.assignPack.emit({
                packId: this.selectedPack.id,
                packName: this.selectedPack.packName,
                exclusions: exclusions,
                packItem: detail,
                packDetail: this.packDetail,
                patientId: this.patientId
            });
        }
    };
    PackAssignmentComponent.prototype.assignPackToPatient = function (selectedPack) {
        if (this.packDetail &&
            this.packDetail.length > 0 &&
            this.packDetail.length - this.lockedItems.length !== 0 &&
            !this.selectedPack.isDefault) {
            if (!this.selectedPack.isAssigned) {
                this.assignPack.emit({
                    pack: selectedPack,
                    packId: selectedPack.id,
                    packName: selectedPack.packName,
                    exclusions: this.lockedItems,
                    packDetail: this.packDetail,
                    patientId: this.patientId
                });
            }
            else {
                this.removeExclusions.emit({
                    pack: selectedPack,
                    packId: selectedPack.id,
                    packName: selectedPack.packName,
                    exclusions: this.lockedItems,
                    packDetail: this.packDetail,
                    patientId: this.patientId
                });
            }
        }
    };
    return PackAssignmentComponent;
}());
export { PackAssignmentComponent };
