import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { useApiClient } from '@react/lib/api';
import { useMessageTemplatesQuery } from '@react/lib/api/hooks';
import { useDebouncedState } from '@react/lib/hooks';
import { useTranslations } from '@react/lib/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Empty } from './Empty';
import { MessageIcon } from './icons/MessageIcon';
import { Loading } from './Loading';
import { MessageTemplates } from './MessageEditor.styled';
import MessageEditorSearchField from './MessageEditorSearchField';

interface Props {
  onAddTemplate: (messageTemplate: string) => void;
  patientId: number;
}

const MAX_TOOLTIP_LENGTH = 150;

const clampText = (text: string) =>
  text.length <= MAX_TOOLTIP_LENGTH
    ? text
    : `${text.slice(0, MAX_TOOLTIP_LENGTH - 1)}…`;

const MessageAddTemplate = ({ onAddTemplate, patientId }: Props) => {
  const { t } = useTranslations();
  const queryClient = useQueryClient();
  const apiClient = useApiClient().portal;
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchTerm = useDebouncedState(searchInputValue, 500);
  const { data, isLoading, isSuccess } = useMessageTemplatesQuery();
  const getMessageTemplate = useMutation(
    (requestData: { patientId: number; id: number }) =>
      apiClient.fetchMessageTemplateMessage(requestData),
    {
      onSuccess: (result, requestArgs) => {
        Promise.resolve(
          queryClient.setQueryData(['messageTemplate', requestArgs], result),
        );
      },
    },
  );
  const messageTemplates = data ? data.data : undefined;

  // If we don't do this we're back to every keystroke causing the
  // the list to be filtered.
  const results = useMemo(() => {
    if (!messageTemplates || !messageTemplates.length) {
      return [];
    }

    const searchTermLower = debouncedSearchTerm.trim().toLowerCase();
    if (searchTermLower === '') {
      return messageTemplates;
    }

    return messageTemplates.filter(
      ({ content, name }) =>
        name.toLowerCase().includes(searchTermLower) ||
        content.toLowerCase().includes(searchTermLower),
    );
  }, [debouncedSearchTerm, messageTemplates]);

  const handleAddMessageTemplate = (templateId: number) => {
    const matchingTemplate = (messageTemplates || []).find(
      (mt) => mt.id === templateId,
    );

    if (!matchingTemplate) {
      return;
    }

    getMessageTemplate
      .mutateAsync({
        patientId,
        id: matchingTemplate.id,
      })
      .then(({ data: { message } }) => {
        onAddTemplate(message);
      });
  };

  const noResults = data != null && data.data.length === 0;
  const noMatchingResults = !noResults && results.length === 0;

  return (
    <MessageTemplates>
      <MessageEditorSearchField
        onChangeText={setSearchInputValue}
        value={searchInputValue}
        placeholder={t.Admin.MessageTemplatesSearchPlaceholder}
      />
      {isLoading ? (
        <Loading />
      ) : noResults ? (
        <Empty icon={<MessageIcon />} message={t.Messages.NoMessageTemplates} />
      ) : noMatchingResults ? (
        <Empty
          icon={<MessageIcon />}
          message={t.Messages.NoMatchingMessageTemplates}
        />
      ) : isSuccess ? (
        <List>
          {results.map(({ id, content, name }) => {
            const onClick = getMessageTemplate.isLoading
              ? undefined
              : () => handleAddMessageTemplate(id);
            const disabled = getMessageTemplate.isLoading;
            const isCurrentlyAdding =
              getMessageTemplate.isLoading &&
              getMessageTemplate.variables &&
              getMessageTemplate.variables.id === id;
            return (
              <ListItem
                button
                data-id={id}
                key={id}
                {...{ disabled, onClick }}
                disabled={getMessageTemplate.isLoading}
                onClick={onClick}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  alignItems="center"
                >
                  <Tooltip title={clampText(content)}>
                    <ListItemText>{name}</ListItemText>
                  </Tooltip>
                  {isCurrentlyAdding && <CircularProgress size={14} />}
                </Box>
              </ListItem>
            );
          })}
        </List>
      ) : null}
    </MessageTemplates>
  );
};

export default MessageAddTemplate;
